import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IBillingInfoPopup {
  open: boolean;
  type: BillingInfoPopupType;
  isLoading?: boolean;
  onContinueDowngradeCb?: () => void;
  handleClose?: () => void;
  hideActionButtons?: boolean;
}

const ProsAndConsItem = ({ pro = false, con = false, children }) => (
  <Text variant={TextSize.XS} extraSmallCaps bold className={styles.disadvantage}>
    {pro && <Icon name={IconVariant.GREEN1_CHECKMARK} width={14} height={14} />}
    {con && <Icon name={IconVariant.X_MARK_CIRCLE} width={14} height={14} />}
    {children}
  </Text>
);

const downgradingToFreeCons = [
  'Writing Style',
  'Clarity',
  'Confidence',
  'Inclusivity',
  'Plagiarism',
  'Healthy Communication',
  'Snippets',
];

const DowngradingToFree = () => (
  <div className={styles.content}>
    <Text variant={TextSize.M}>You’ll miss out on improving your writing with these features:</Text>
    <div className={styles.prosAndConsHolder}>
      {downgradingToFreeCons.map((con, i) => (
        <ProsAndConsItem key={`${con}${i}`} con>
          {con}
        </ProsAndConsItem>
      ))}
    </div>
    <Text variant={TextSize.M}>And your usage limits will be much lower:</Text>
    <Text variant={TextSize.S} className={styles.dangerText}>
      <Icon name={IconVariant.ARROW_RIGHT_ALT} />
      Only 100K words per month, instead of 250K per month
    </Text>
  </div>
);

const endingATeamPlanConsCons = ['generate content', 'check content', 'use extensions'];
const endingATeamPlanConsPros = ['DOCS', 'TERMS', 'SNIPPETS'];

const EndingATeamPlan = () => (
  <div className={styles.content}>
    <Text variant={TextSize.M} className={styles.prosAndConsHolderTitle}>
      When your Team plan ends, you’ll no longer be able to use these features.
    </Text>
    <div className={styles.prosAndConsHolder}>
      {endingATeamPlanConsCons.map((con, i) => (
        <ProsAndConsItem key={`${con}${i}`} con>
          {con}
        </ProsAndConsItem>
      ))}
    </div>
    <div className={styles.prosAndConsHolderSeparator} />
    <Text variant={TextSize.M} className={styles.prosAndConsHolderTitle}>
      You and your team will still have access to your content after your Team plan ends:
    </Text>
    <div className={styles.prosAndConsHolder}>
      {endingATeamPlanConsPros.map((pro, i) => (
        <ProsAndConsItem key={`${pro}${i}`} pro>
          {pro}
        </ProsAndConsItem>
      ))}
    </div>
    <div className={styles.prosAndConsHolderSeparatorTransparent} />
  </div>
);

export enum BillingInfoPopupType {
  DOWNGRADE_TO_FREE = 'DOWNGRADE_TO_FREE',
  ENDING_A_TEAM_PLAN = 'ENDING_A_TEAM_PLAN',
}

const Popup = {
  [BillingInfoPopupType.DOWNGRADE_TO_FREE]: {
    title: 'Are you sure you want to cancel your Team plan?',
    informationalTitle: 'Downgrading to Free',
    content: <DowngradingToFree />,
    footerClass: '',
  },
  [BillingInfoPopupType.ENDING_A_TEAM_PLAN]: {
    title: 'Are you sure you want to cancel your Team plan?',
    informationalTitle: 'Ending a Team plan',
    content: <EndingATeamPlan />,
    footerClass: styles.actionButtonsHolderAlt,
  },
};

export const BillingInfoPopup: React.FC<IBillingInfoPopup> = ({
  open,
  type,
  isLoading,
  onContinueDowngradeCb,
  handleClose,
  hideActionButtons,
}) => {
  const _title = hideActionButtons ? Popup[type].informationalTitle : Popup[type].title;

  return (
    <Modal open={open} title={_title} className={styles.modalContainer} handleClose={handleClose}>
      <div>
        {Popup[type].content}
        {!hideActionButtons && (
          <div className={cx(styles.actionButtonsHolder, Popup[type].footerClass)}>
            <Button
              type={ButtonTypes.TRANSPARENT}
              size={SizeTypes.XS}
              className={styles.downgradeButton}
              onClick={onContinueDowngradeCb}
              content="Continue with downgrade"
              isLoading={isLoading}
            />
            <Button
              type={ButtonTypes.BLACK}
              className={styles.currentPlanButton}
              onClick={handleClose}
              content="Keep my current plan"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BillingInfoPopup;
