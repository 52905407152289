import { ApiErrorResponse, BEError } from '@writercolab/common-utils';

import first from 'lodash/first';

import { BackendErrorCodeKey } from '../constants/BackendErrorCodeKey';

/**
 * Extracts error message from backend error response.
 *
 * This function tries to extract the error message from various properties of the error object,
 * in the order of their precedence.
 *
 * @param {any} e - The error object from which to extract the error message.
 * @param messageDefault
 * @returns {string} The error message.
 */
export const extractBackendResponseErrorMessage = (e: any, messageDefault: string = ''): string => {
  const errorResponse: ApiErrorResponse = e?.response?.data || e?.error;

  const error = first<BEError>(errorResponse?.errors);

  return error?.description || error?.userMessage || e?.message || messageDefault;
};

/**
 * Extracts the backend error key from the given error object.
 * @param e The error object.
 * @returns The backend error key or `undefined` if not found.
 */
export const extractBackendErrorKey = (e: any): BackendErrorCodeKey | string | undefined =>
  e?.response?.data?.errors && first<BEError>(e.response.data.errors)?.key;

/**
 * Checks if the given error corresponds to a term duplicate error.
 * @param e The error object.
 * @returns `true` if the error is a term duplicate error, `false` otherwise.
 */
export const isTermDuplicateError = (e: any): boolean => {
  const errorKey = extractBackendErrorKey(e);

  return !!(
    errorKey &&
    [BackendErrorCodeKey.TERM_DUPLICATE_CASE, BackendErrorCodeKey.TERM_DUPLICATE].includes(
      errorKey as BackendErrorCodeKey,
    )
  );
};

export const extractApiServiceErrorKey = (e: unknown): BackendErrorCodeKey => {
  if (typeof e === 'object' && e !== null) {
    const error = 'error' in e ? (e as { [key: string]: any }).error : undefined;

    if (error && Array.isArray(error.errors) && error.errors.length > 0) {
      return error.errors[0].key as BackendErrorCodeKey;
    }
  }

  return BackendErrorCodeKey.GENERAL_ERROR;
};

export const isApiServiceGenericResourceError = (e: unknown): boolean =>
  extractApiServiceErrorKey(e) === BackendErrorCodeKey.GENERAL_RESOURCE_ERROR;
