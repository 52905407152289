import { Request } from '@writercolab/common-utils';

import { TMediaFileDetails } from '@web/types';

export const getMediaFileDetails = async (
  organizationId: string,
  teamId: string,
  fileId: string,
): Promise<TMediaFileDetails> => {
  const { data } = await Request.getAxiosInstance().get(
    `/media/organization/${organizationId}/team/${teamId}/file/${fileId}`,
  );

  return data;
};

export const getMediaFile = async (organizationId: string, teamId: string, fileId: string): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get(
    `/media/organization/${organizationId}/team/${teamId}/file/${fileId}/download`,
    {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    },
  );

  return data;
};

export const getMediaFileTranscript = async (organizationId: string, teamId: string, fileId: string): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get(
    `/media/organization/${organizationId}/team/${teamId}/file/${fileId}/download/diarized`,
    {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
      responseType: 'blob',
    },
  );

  return data;
};

export const getMediaFileSummary = async (organizationId: string, teamId: string, fileId: string): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get(
    `/media/organization/${organizationId}/team/${teamId}/file/${fileId}/download/summary`,
    {
      headers: {
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
      responseType: 'blob',
    },
  );

  return data;
};
