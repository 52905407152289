import type Quill from 'quill';
import styles from './formatter.module.css';
import { Aligner, VideoAlign } from './aligner';
import { VideoActions } from './actions';
import { WidthStyleAttributor } from '../../attributors';

interface IFormatterModuleOptions {
  onAfterShareVideoClick?: () => void;
}

export class Formatter {
  overlay: HTMLElement;
  currentElement: HTMLElement | null = null;
  isFocused = false;
  leftHandle: HTMLElement;
  rightHandle: HTMLElement;
  dragHandle: HTMLElement | null;
  dragStartX: number;
  preDragWidth: number;
  ratio = 9 / 16;
  minWidth = 200;
  maxWidth: number;
  maxOverlayHeight: number;
  breakpoints: HTMLElement[];
  currentBreakpointIndex: number;
  aligner: Aligner;
  actions: VideoActions;

  constructor(
    public quill: Quill,
    public options: IFormatterModuleOptions,
  ) {
    this.options = options;
    this.dragHandle = null;
    this.dragStartX = 0;
    this.preDragWidth = 0;
    this.currentBreakpointIndex = -1;
    this.breakpoints = [];

    this.maxWidth = quill.root.clientWidth;
    this.maxOverlayHeight = this.maxWidth * this.ratio;

    this.leftHandle = this.createHandle();
    this.rightHandle = this.createHandle();
    this.overlay = this.createOverlay();
    this.breakpoints = this.createBreakpoints();

    this.aligner = new Aligner(this);
    this.actions = new VideoActions(this);

    const parent = this.quill.container.parentNode as HTMLElement;

    parent.prepend(this.overlay);

    parent.style.position = parent.style.position || 'relative';

    this.quill.root.addEventListener('click', this.hideOverlay);
    this.quill.root.addEventListener('mouseover', this.onMouseOver);
    this.quill.root.addEventListener('scroll', this.repositionOverlay);
  }

  createOverlay() {
    const overlay = document.createElement('div');
    overlay.classList.add(styles.overlay);

    overlay.appendChild(this.leftHandle);
    overlay.appendChild(this.rightHandle);

    return overlay;
  }

  createBreakpoints() {
    const breakpoints: HTMLDivElement[] = [];

    for (let index = 0; index < 11; index++) {
      const breakpoint = document.createElement('div');
      breakpoint.classList.add(styles.videoBreakpoint);
      breakpoint.style.left = `${index * 10}%`;

      breakpoints.push(breakpoint);
      this.overlay.appendChild(breakpoint);
    }

    return breakpoints;
  }

  createHandle(): HTMLElement {
    const box = document.createElement('div');
    box.classList.add(styles.videoResizeHandle);

    box.addEventListener('mousedown', this.onMouseDown);
    // box.addEventListener('mouseout', this.onMouseElementMouseout);

    return box;
  }

  onFocus = () => {
    this.isFocused = true;
    this.aligner.show();
    this.actions.hide();
  };

  onMouseOut = () => {
    if (this.isFocused) {
      return;
    }

    this.hideOverlay();
  };

  hideOverlay = () => {
    this.setCursor('');
    this.currentElement = null;
    this.isFocused = false;
    this.aligner.hide();
    this.actions.hide();
    this.overlay.classList.remove(styles.visible);
  };

  onMouseOver = (ev: MouseEvent) => {
    if (this.currentElement && this.isFocused) {
      return;
    }

    const el = ev.target;

    if (!(el instanceof HTMLElement)) {
      return;
    }

    if (el.tagName.toLowerCase() === 'section' && el.classList.contains('ql-video')) {
      this.currentElement = el;
    }

    if (el.tagName.toLowerCase() === 'mux-player') {
      this.currentElement = el.closest('section.ql-video') as HTMLElement;
    }

    if (this.currentElement) {
      this.showOverlay();
      // this.currentElement.addEventListener('mouseout', this.onMouseElementMouseout);
    }
  };

  // onMouseElementMouseout = (e: MouseEvent) => {
  //   if (!this.isFocused) {
  //     const isCorrectTarget = ![this.leftHandle, this.rightHandle, this.actions.container].includes(
  //       e.relatedTarget as HTMLElement,
  //     );

  //     if (isCorrectTarget) {
  //       this.hideOverlay();
  //     }
  //   }
  // };

  showOverlay() {
    this.overlay.classList.add(styles.visible);
    this.actions.show();
    this.repositionOverlay();
  }

  repositionOverlay = () => {
    if (!this.currentElement) {
      return;
    }

    const parent: HTMLElement = this.quill.container.parentNode as HTMLElement;
    const elementRect = this.currentElement.getBoundingClientRect();
    const parentRect = parent.getBoundingClientRect();

    Object.assign(this.overlay.style, {
      top: `${elementRect.top - parentRect.top + parent.scrollTop}px`,
      height: `${this.maxOverlayHeight}px`,
    });

    Object.assign(this.leftHandle.style, {
      top: `${elementRect.height / 2}px`,
      left: `${elementRect.left - parentRect.left - 21}px`,
    });

    Object.assign(this.rightHandle.style, {
      top: `${elementRect.height / 2}px`,
      right: `${parentRect.right - elementRect.right - 21}px`,
    });

    Object.assign(this.actions.container.style, {
      top: 0,
      right: `${parentRect.right - elementRect.right - 10}px`,
    });

    Object.assign(this.aligner.container.style, {
      left: `${elementRect.left - parentRect.left + elementRect.width / 2}px`,
    });
  };

  onMouseDown = (event: MouseEvent) => {
    if (!(event.target instanceof HTMLElement)) {
      return;
    }

    this.onFocus();
    this.dragHandle = event.target;
    this.overlay.classList.add(styles.dragging);
    this.setCursor(this.dragHandle.style.cursor);
    this.setUserSelect('none');

    const target = this.currentElement;

    if (!target) {
      return;
    }

    const rect = target.getBoundingClientRect();

    this.aligner.refresh();
    this.dragStartX = event.clientX;
    this.preDragWidth = rect.width;

    this.quill.disable();

    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.onMouseUp);
  };

  onDrag = (event: MouseEvent) => {
    const target = this.currentElement;

    if (!target) {
      return;
    }

    const deltaX = event.clientX - this.dragStartX;
    let newWidth = 0;

    if (this.dragHandle === this.leftHandle) {
      newWidth = Math.round(this.preDragWidth - deltaX);
    } else {
      newWidth = Math.round(this.preDragWidth + deltaX);
    }

    if (newWidth < this.minWidth) {
      newWidth = this.minWidth;
    }

    if (newWidth > this.maxWidth) {
      newWidth = this.maxWidth;
    }

    const newWidthPercent = (newWidth / this.maxWidth) * 100;
    WidthStyleAttributor.add(target, `${newWidthPercent}%`);

    this.refreshBreakpointHighlights(newWidthPercent);
    this.repositionOverlay();
  };

  refreshBreakpointHighlights(elementWidthPercentage?: number) {
    if (!this.currentElement) {
      return;
    }

    const width = elementWidthPercentage || parseFloat(WidthStyleAttributor.value(this.currentElement));

    const breakpointsLength = this.breakpoints.length - 1;
    let leftIndex = 0;
    let rightIndex = breakpointsLength;

    switch (this.aligner.currentAlign) {
      case VideoAlign.LEFT:
        rightIndex = Math.round((width * breakpointsLength) / 100);
        break;
      case VideoAlign.MIDDLE: {
        const shift = Math.round((100 - width) / 10 / 2);
        leftIndex = shift;
        rightIndex = breakpointsLength - shift;
        break;
      }
      case VideoAlign.RIGHT:
        leftIndex = breakpointsLength - Math.round((width * breakpointsLength) / 100);
        break;
      default:
        break;
    }

    this.breakpoints.forEach(b => b.classList.remove(styles.selectedBreakpoint));

    this.breakpoints[leftIndex]?.classList.add(styles.selectedBreakpoint);
    this.breakpoints[rightIndex]?.classList.add(styles.selectedBreakpoint);
  }

  onMouseUp = () => {
    this.setCursor('');
    this.setUserSelect('');
    this.overlay.classList.remove(styles.dragging);

    if (this.currentElement) {
      const currentPercentage = parseFloat(WidthStyleAttributor.value(this.currentElement));
      const rounded = Math.round(currentPercentage / 10) * 10;
      WidthStyleAttributor.add(this.currentElement, `${rounded}%`);
    }

    this.quill.enable();
    this.repositionOverlay();
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onMouseUp);
  };

  setCursor(value: string) {
    if (document.body) {
      document.body.style.cursor = value;
    }

    const target = this.currentElement;

    if (target) {
      target.style.cursor = value;
    }
  }

  setUserSelect(value: string) {
    const props: string[] = ['userSelect', 'mozUserSelect', 'webkitUserSelect', 'msUserSelect'];

    props.forEach((prop: string) => {
      // set on contenteditable element and <html>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.quill.root.style[prop as any] = value;

      if (document.documentElement) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        document.documentElement.style[prop as any] = value;
      }
    });
  }
}

export default Formatter;
