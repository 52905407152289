import React, { useEffect } from 'react';

import { LoadingPage } from '@web/component-library';
import useQuery from 'hooks/useQuery';
import { useParams } from 'react-router';

export const KnowledgeGraphConnectorRedirectPage = () => {
  const query = useQuery();
  const { connectorId } = useParams();

  useEffect(() => {
    const code = query.get('code');

    if (code) {
      window.opener.postMessage({ source: 'connector', connectorId, code }, window.location.origin);
      window.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
};

export default KnowledgeGraphConnectorRedirectPage;
