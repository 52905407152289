import type React from 'react';
import { useCallback, useMemo } from 'react';

import cx from 'classnames';

import { IconsCommonAppsGrid, IconsCommonTemplate } from '@writercolab/assets';
import { ContentEditorPageMode, openNewTab } from '@writercolab/common-utils';
import { Button, SimpleTooltip } from '@writercolab/fe.wds';
import { EApplicationType, NotificationQueueItemType } from '@writercolab/types';
import { useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import { EMPTY_EDITOR_DOC_ID } from '@writercolab/utils';

import { IconLink } from 'components/molecules/IconLink';
import { WidgetCard } from 'components/molecules/WidgetCard';
import { ChatAppHomeWidget } from 'components/organisms/ChatAppHomeWidget';
import { ShortcutsHomeWidget } from 'components/organisms/ShortcutsHomeWidget';

import {
  ASK_WRITER_APP_ID,
  BLOG_BUILDER_APP_ID,
  ChatModes,
  RECAPS_APP_ID,
  RecentWorkWidgetTabId,
  TBriefApplication,
  THomeWidgetsState,
} from '@web/types';
import type { TApplicationBriefResponse, TBaseApplicationType } from '@web/types';
import { WRITER_ACADEMY_URI } from 'constants/LearningCenter';
import { AnalyticsActivity } from 'constants/analytics';
import { CirclePlus, SquareArrowOutUpRight } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { PromptLibraryEventSources } from '../../../constants/PromptLibrary';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import PageTitle from '../../molecules/PageTitle';
import { AcademyHomeWidget } from '../../organisms/AcademyHomeWidget';
import { AiStudioHomeWidget } from '../../organisms/AiStudioHomeWidget';
import { AppsHomeWidget } from '../../organisms/AppsHomeWidget';
import { ExtensionsHomeWidget } from '../../organisms/ExtensionsHomeWidget';
import { PromptLibraryModal } from '../../organisms/PromptLibraryModal';
import { RecentWorkHomeWidget } from '../../organisms/RecentWorkHomeWidget';
import { HomePageUIModel } from './HomePageModel.ui';

import styles from './HomePage.module.css';

export const HomePage: React.FC = observer(() => {
  usePageTitle('Home');
  const { appState, appModel } = useAppState();
  const navigate = useNavigate();
  const { organizationId, teamId } = appState;
  const { analyticsService } = appModel;

  const model = useMemo(() => {
    if (!appModel.organizationId || !appModel.teamId) {
      return undefined;
    }

    return new HomePageUIModel({
      appModel,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appModel, appModel?.organizationId, appModel?.teamId]);

  useQueueWorkerNotifications(model?.notificationQueue);

  const onStartWithAppLibrary = (
    origin: 'apps_widget' | 'home_page_header' | 'recent_work_widget' | 'other' = 'apps_widget',
  ) => {
    analyticsService.track(AnalyticsActivity.openAppLibraryClicked, { origin });
    navigate(ROUTE.toEditorWithCoWrite(organizationId, teamId));
  };

  const onOpenAiStudio = () => {
    analyticsService.track(AnalyticsActivity.openAIStudioPageClicked, { origin: 'ai_studio_widget' });
    openNewTab(ROUTE.toAiStudioHome(`${organizationId}`));
  };

  const onCreateNewApp = async (appType: TBaseApplicationType) => {
    const appDetails = await model?.consoleApplicationsApiModel.createApplicationAndVersion({
      type: appType,
    });

    analyticsService.track(AnalyticsActivity.buildAnAppTypeSelected, { app_type: appType, origin: 'ai_studio_widget' });

    if (!appDetails || !organizationId) {
      throw new Error('Failed to create app');
    }

    openNewTab(ROUTE.toAiStudioBuild(Number(organizationId), appDetails.id));
  };

  const onOpenAppInBuilder = (app: TApplicationBriefResponse) => {
    analyticsService.track(AnalyticsActivity.draftAppClicked, {
      app_id: app.id,
      app_type: app.type,
    });

    if (app.type === EApplicationType.enum.framework) {
      openNewTab(ROUTE.toFrameworkAppDeploy(`${organizationId}`, `${app.id}`));
    } else {
      openNewTab(ROUTE.toAiStudioBuild(`${organizationId}`, `${app.id}`));
    }
  };

  const onOpenDocument = (documentId: string) => {
    analyticsService.track(AnalyticsActivity.docSelected, { doc_id: documentId, origin: 'recent_work_widget' });

    navigate(ROUTE.toEditorPage(organizationId, teamId, documentId), {
      state: {
        backTo: ROUTE.toHome(organizationId, teamId),
      },
    });
  };

  const onOpenSession = (appId: string, sessionId: string) => {
    analyticsService.track(AnalyticsActivity.sessionSelected, { session_id: sessionId, origin: 'recent_work_widget' });

    navigate(ROUTE.toChatApp(organizationId, teamId, appId, sessionId), {
      state: {
        backTo: ROUTE.toHome(organizationId, teamId),
      },
    });
  };

  const onNewSession = async () => {
    const session = await model?.sessionsApiModel.createSession(ChatModes.enum.chat, ASK_WRITER_APP_ID);

    if (!session) {
      model?.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Could not create a new session',
      });

      return;
    }

    navigate(ROUTE.toChatApp(organizationId, teamId, session.applicationId, session.id));

    analyticsService.track(AnalyticsActivity.newSessionCreated, {
      session_id: session.id,
      origin: 'recent_work_widget',
    });
  };

  const onNewDoc = async () => {
    if (!organizationId || !teamId) {
      return;
    }

    const doc = await model?.documentApi.createDocument(organizationId, teamId, '');

    if (!doc) {
      model?.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Could not create a new document',
      });

      return;
    }

    analyticsService.track(AnalyticsActivity.newDocCreated, {
      origin: 'recent_work_widget',
      doc_id: String(doc.id),
    });

    navigate(ROUTE.toEditorPage(organizationId, teamId, String(doc.id)));
  };

  const onOpenDocs = () => {
    analyticsService.track(AnalyticsActivity.openDocsPageClicked, { origin: 'recent_work_widget' });

    navigate(ROUTE.toDocs(`${organizationId}`, `${teamId}`));
  };

  const onOpenSessions = () => {
    analyticsService.track(AnalyticsActivity.openAllSessionsClicked, { origin: 'recent_work_widget' });

    navigate(ROUTE.toSessions(`${organizationId}`, `${teamId}`));
  };

  const onOpenWriterAcademy = async () => {
    const token = await model?.learningCenterModel.getWriterAcademyAuthToken();
    model?.learningCenterModel.refreshUserSettings();

    analyticsService.track(AnalyticsActivity.openWriterAcademyPageClicked, { origin: 'writer_academy_widget' });

    openNewTab(`${WRITER_ACADEMY_URI}${token}`);
  };

  const onOpenWriterAcademyLink = async (url: string, type: 'certification' | 'tip') => {
    const token = await model?.learningCenterModel.getWriterAcademyAuthToken();

    if (type === 'certification') {
      analyticsService.track(AnalyticsActivity.certificationClicked, {
        certification_id: url,
        origin: 'writer_academy_widget',
      });
    } else {
      analyticsService.track(AnalyticsActivity.tipsClicked, { url, origin: 'writer_academy_widget' });
    }

    openNewTab(`${WRITER_ACADEMY_URI}${token}&returnTo=${encodeURIComponent(url)}`);
  };

  const onOpenPromptLibrary = () => {
    model?.setPromptLibraryModalOpen(true);
  };

  const onGenerationAppSelected = (applicationId: string) => {
    if (applicationId === BLOG_BUILDER_APP_ID) {
      navigate(
        ROUTE.toEditorWithModeAndTemplate(
          `${organizationId}`,
          `${teamId}`,
          EMPTY_EDITOR_DOC_ID,
          ContentEditorPageMode.BLOG,
          applicationId,
        ),
      );
    } else if (applicationId === RECAPS_APP_ID) {
      navigate(
        ROUTE.toEditorWithModeAndTemplate(
          `${organizationId}`,
          `${teamId}`,
          EMPTY_EDITOR_DOC_ID,
          ContentEditorPageMode.EVENT_TAKEAWAYS,
          applicationId,
        ),
      );
    } else if (applicationId === ASK_WRITER_APP_ID) {
      navigate(ROUTE.toAskWriter(organizationId, teamId!));
    } else {
      navigate(
        ROUTE.toEditorWithModeAndTemplate(
          `${organizationId}`,
          `${teamId}`,
          EMPTY_EDITOR_DOC_ID,
          ContentEditorPageMode.CO_WRITE,
          applicationId,
        ),
      );
    }
  };

  const onChatSelected = (chatId: string) => {
    if (chatId === ASK_WRITER_APP_ID) {
      navigate(ROUTE.toAskWriter(organizationId, teamId!));
    } else {
      navigate(ROUTE.toChatApp(organizationId, teamId, chatId));
    }
  };

  const onResearchAssistantSelected = (chatId: string) => {
    navigate(ROUTE.toResearchApp(organizationId, teamId, chatId));
  };

  const onFrameworkAppSelected = (applicationId: string) => {
    navigate(ROUTE.toFrameworkAppPage(`${organizationId}`, `${teamId}`, applicationId));
  };

  const _onOpenApp = useCallback(
    (appType: typeof EApplicationType.type, appId: string) => {
      EApplicationType.match(
        appType,
        {
          [EApplicationType.enum.framework]: () => onFrameworkAppSelected(appId),
          [EApplicationType.enum.chat]: () => onChatSelected(appId),
          [EApplicationType.enum.generation]: () => onGenerationAppSelected(appId),
          [EApplicationType.enum.research]: () => onResearchAssistantSelected(appId),
        },
        null,
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, teamId],
  );

  const onOpenApp = useCallback(
    ({ type, id, name, access }: Pick<TBriefApplication, 'id' | 'type' | 'access' | 'name'>) => {
      analyticsService.track(AnalyticsActivity.appOpened, {
        app_id: id,
        origin: 'home',
        source: 'apps_widget',
        app_name: name,
        app_type: type,
        built_by_writer: access === 'public',
      });
      _onOpenApp(type, id);
    },
    [_onOpenApp, analyticsService],
  );

  const onShortcutClick = useCallback(
    ({ type, id, name, access }: Pick<TBriefApplication, 'id' | 'type' | 'access' | 'name'>) => {
      analyticsService.track(AnalyticsActivity.appOpened, {
        app_id: id,
        origin: 'home',
        source: 'shortcuts_widget',
        app_name: name,
        app_type: type,
        built_by_writer: access === 'public',
      });

      _onOpenApp(type, id);
    },
    [_onOpenApp, analyticsService],
  );

  if (!model) {
    return null;
  }

  return (
    <div
      className={cx(styles.pageWrapper, {
        [styles.withTopPadding]: model.currentMode === THomeWidgetsState.enum.adminEdit,
      })}
    >
      {model.currentMode === THomeWidgetsState.enum.adminEdit && (
        <div className={styles.topBar} data-homepage-bar>
          <p className={cx(styles.description, 'font-medium text-base')}>Customize your team's default home view</p>
          <div className={styles.buttons}>
            <Button variant="outline" size="sm" onClick={model.switchToViewMode}>
              Cancel
            </Button>
            <Button size="sm" onClick={model.bulkSaveWidgets}>
              Save changes
            </Button>
          </div>
        </div>
      )}

      <div className={styles.contentBar}>
        <div className={styles.headLine}>
          <div className={styles.titleAndSearch}>
            <PageTitle title="Home" teamName={model.teamNameInHeader} />
            <div className={styles.buttons}>
              {model.hasAccessToHomePageCustomize && model.currentMode === THomeWidgetsState.enum.view && (
                <Button variant="outline" onClick={model.switchToAdminEdit} leftIcon={<IconsCommonTemplate />}>
                  Customize
                </Button>
              )}
              {model.currentMode !== THomeWidgetsState.enum.adminEdit && (
                <Button
                  variant="primary"
                  onClick={() => onStartWithAppLibrary('home_page_header')}
                  leftIcon={<IconsCommonAppsGrid />}
                >
                  App Library
                </Button>
              )}
            </div>
          </div>
        </div>

        {model.isPromptLibraryModalOpen && (
          <PromptLibraryModal
            model={model.promptLibraryModalModel}
            teamName={appModel.team?.name || ''}
            invocationContext={PromptLibraryEventSources.ASK_WRITER}
            onClose={() => model.setPromptLibraryModalOpen(false)}
            onUsePrompt={prompt => {
              model?.featuredChatAppModel?.insertPrompt(prompt);
              model.setPromptLibraryModalOpen(false);
            }}
            showContactText={!appModel.permissionsModel?.isTeamAdmin}
            onNavigateToLearningCenter={() => {}}
          />
        )}

        {!model.featuredChatAppModel.isWidgetHiddenForUser && (
          <WidgetCard
            className={cx(styles.chatAppWidget, {
              [styles.noPadding]: model.currentMode === 'view',
              [styles.paddingBottom]: model.currentMode === 'adminEdit',
            })}
            mode={model.currentMode}
            model={model.featuredChatAppModel}
            withShadow={false}
            withBorder={false}
          >
            <ChatAppHomeWidget
              model={model.featuredChatAppModel}
              onChangeChatApp={model.changeChatApp}
              onClickOpenPromptLibrary={onOpenPromptLibrary}
            />
          </WidgetCard>
        )}

        {!appModel.assistantSubscription.isFree && (
          <WidgetCard
            mode={model.currentMode}
            model={model.shortcutsWidgetModel}
            withShadow={false}
            withBorder={false}
            className={cx({
              [styles.noPadding]: model.currentMode === 'view',
              [styles.paddingBottom]: model.currentMode === 'adminEdit',
            })}
          >
            <ShortcutsHomeWidget
              model={model.shortcutsWidgetModel}
              onOpenApp={onShortcutClick}
              onAddUserApp={model.addUserShortcut}
              onRemoveUserApp={model.removeUserShortcut}
            />
          </WidgetCard>
        )}

        <div className={styles.widgetsGrid}>
          <WidgetCard mode={model.currentMode} model={model.recentWorkWidgetModel} withShadow title="Recent work">
            <RecentWorkHomeWidget
              model={model.recentWorkWidgetModel}
              onOpenDocument={onOpenDocument}
              onOpenSession={onOpenSession}
              onStartNewDocument={onNewDoc}
              onStartNewSession={onNewSession}
              onOpenAllApps={() => onStartWithAppLibrary('recent_work_widget')}
            />
            <div className={styles.rowLinks}>
              {model.recentWorkWidgetModel.activeTab === RecentWorkWidgetTabId.enum.sessions && (
                <div className="flex justify-between flex-grow">
                  <SimpleTooltip
                    trigger={
                      <IconLink
                        text="New session"
                        icon={<CirclePlus size={16} />}
                        onClick={onNewSession}
                        iconPosition="left"
                      />
                    }
                  >
                    New Ask Writer session
                  </SimpleTooltip>
                  <IconLink className={styles.linkContainer} text="All sessions" onClick={onOpenSessions} />
                </div>
              )}
              {model.recentWorkWidgetModel.activeTab === RecentWorkWidgetTabId.enum.docs && (
                <div className="flex justify-between flex-grow">
                  <IconLink text="New doc" icon={<CirclePlus size={16} />} onClick={onNewDoc} iconPosition="left" />
                  <IconLink className={styles.linkContainer} text="All docs" onClick={onOpenDocs} />
                </div>
              )}
            </div>
          </WidgetCard>

          <WidgetCard mode={model.currentMode} model={model.appsWidgetModel} withShadow title="Apps">
            <AppsHomeWidget
              model={model.appsWidgetModel}
              hideRecentOptions={!appModel.featureFlags.get('recentlyUsedAvailable', false)}
              onOpenApp={onOpenApp}
              onOpenAppLibrary={() => onStartWithAppLibrary('apps_widget')}
            />
            <div
              className={cx(styles.rowLinks, {
                [styles.invisible]: model.appsWidgetModel.hasInvisibleFooter,
              })}
            >
              <IconLink
                className={styles.linkContainer}
                text="App library"
                onClick={() => onStartWithAppLibrary('apps_widget')}
              />
            </div>
          </WidgetCard>
        </div>

        {appModel.permissionsModel?.hasAccessToBuilderRole && (
          <WidgetCard mode={model.currentMode} model={model.aiStudioWidgetModel} withShadow>
            <AiStudioHomeWidget
              model={model.aiStudioWidgetModel}
              onAppClick={onOpenAppInBuilder}
              onAppCreate={onCreateNewApp}
            />
            <div className={styles.rowLinks}>
              <IconLink text="AI Studio" icon={<SquareArrowOutUpRight size={16} />} onClick={onOpenAiStudio} />
            </div>
          </WidgetCard>
        )}

        {!appModel.assistantSubscription.isFree && (
          <WidgetCard mode={model.currentMode} model={model.academyWidgetModel} withShadow title="Writer AI Academy">
            <AcademyHomeWidget
              showAiStudioCertifications={appModel.permissionsModel?.hasAccessToConsole}
              onOpenLink={onOpenWriterAcademyLink}
            />
            <div className={styles.rowLinks}>
              <IconLink
                text="Writer AI Academy"
                icon={<SquareArrowOutUpRight size={16} />}
                onClick={onOpenWriterAcademy}
              />
            </div>
          </WidgetCard>
        )}
        <WidgetCard mode={model.currentMode} model={model.extensionsWidgetModel} withShadow={false} passThrough>
          <ExtensionsHomeWidget
            organizationId={`${organizationId}`}
            model={model.extensionsWidgetModel}
            onHideExtension={model.hideUserExtension}
          />
        </WidgetCard>
      </div>
    </div>
  );
});

HomePage.displayName = 'HomePage';
