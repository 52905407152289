import { useMemo, useState } from 'react';

import { Button } from '@writercolab/fe.wds';
import { ReactiveQueue } from '@writercolab/mobx';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';
import { Icon, IconVariant } from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import { useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';

import { HeaderActionsUiModel } from '../HeaderActionsModel.ui';

interface IBtnProps {
  organizationId: number | undefined;
  teamId: number | undefined;
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
}

export const NewDocumentBtn = ({ organizationId, teamId, notificationQueue }: IBtnProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    appModel: { analyticsService },
  } = useAppState();
  const navigate = useNavigate();

  const model = useMemo(() => {
    if (!organizationId || !teamId) {
      return null;
    }

    return new HeaderActionsUiModel({
      request: requestService.api,
      organizationId,
      teamId,
    });
  }, [organizationId, teamId]);

  const handleButtonClick = async () => {
    if (!organizationId || !teamId) return;

    setIsLoading(true);

    const createdDoc = await model?.documentApi.createDocument(organizationId, teamId, '');

    if (!createdDoc) {
      notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Could not create a new document',
      });

      setIsLoading(false);

      return;
    }

    analyticsService.track(AnalyticsActivity.newDocButtonClicked, {
      origin: 'my_work',
      doc_id: String(createdDoc.id),
    });

    setIsLoading(false);

    navigate(ROUTE.toEditorPage(organizationId, teamId, createdDoc?.id, location.search), {
      state: {
        backTo: ROUTE.toDocs(`${organizationId}`, `${teamId}`),
      },
    });
  };

  return (
    <Button leftIcon={<Icon name={IconVariant.ADD} />} onClick={handleButtonClick} loading={isLoading}>
      New doc
    </Button>
  );
};
