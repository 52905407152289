import React from 'react';

import { ISuggestionExampleConfig } from '@writercolab/common-utils';
import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import { ExampleBlockHeader } from './generic/ExampleBlockHeader';
import ExampleBlockList from './generic/ExampleBlockList';

import commonStyles from '../styles.module.css';
import styles from './styles.module.css';

interface WordinessExampleProps {}

const exampleConfig: ISuggestionExampleConfig[] = [
  {
    id: '1',
    title: 'Example 1',
    contents: [
      {
        id: '1',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              They received{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
              an advance warning.
            </Text>
          </div>
        ),
      },
      {
        id: '2',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              They received{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
              a warning.
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    id: '2',
    title: 'Example 2',
    contents: [
      {
        id: '1',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              We should consider{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
              the way in which
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              most companies hire.
            </Text>
          </div>
        ),
      },
      {
        id: '2',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              We should consider{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
              how
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              most companies hire.
            </Text>
          </div>
        ),
      },
    ],
  },
  {
    id: '3',
    title: 'Example 3',
    contents: [
      {
        id: '1',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              The debate did not{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
              come to a conclusion
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              until yesterday.
            </Text>
          </div>
        ),
      },
      {
        id: '2',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              The debate did not{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
              conclude
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              until yesterday.
            </Text>
          </div>
        ),
      },
    ],
  },
];

export const WordinessExample: React.FC<WordinessExampleProps> = () => (
  <div className={commonStyles.tooltipContent}>
    {exampleConfig.map(example => (
      <div className={styles.exampleBlockContainer} key={example.id}>
        <ExampleBlockHeader key={example.id}>{example.title}</ExampleBlockHeader>
        <ExampleBlockList blocks={example.contents} />
      </div>
    ))}
  </div>
);

export default WordinessExample;
