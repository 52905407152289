import React, { ReactNode } from 'react';

import cx from 'classnames';

import { Input as WDSInput, type InputProps as WDSInputProps } from '@writercolab/fe.wds';
import { FieldModel } from '@writercolab/mobx';
import { Input, TInputVariant } from '@writercolab/ui-molecules';

import { observer } from 'mobx-react-lite';

import styles from './InputField.module.css';

type TErrorPosition = 'top' | 'bottom';

interface IInputFieldProps {
  children?: ReactNode;
  field: FieldModel<string>;
  containerClassName?: string;
  className?: string;
  variant?: typeof TInputVariant.type;
  disabled?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  hideText?: boolean;
  disableAutofill?: boolean;
  errorPosition?: TErrorPosition;

  /**
   * Preview new WDS input field.
   */
  preview?: boolean;

  /**
   * When preview is true, set the label of the input field.
   */
  label?: string;

  /**
   * When preview is true, set the tooltip of the input field.
   */
  labelTooltip?: string;
}

const FieldError = ({
  field,
  hasError,
  errorPosition,
  children,
}: {
  field: FieldModel<string>;
  hasError: boolean;
  errorPosition?: TErrorPosition;
  children: ReactNode;
}) => {
  return (
    hasError && (
      <div
        data-testid="input-field-error"
        className={cx(styles.errorContainer, {
          [styles.noLabel]: !children,
          [styles.top]: errorPosition === 'top',
          [styles.bottom]: errorPosition === 'bottom',
        })}
      >
        <p className="uppercase text-sm text-orange-500">{field.error}</p>
      </div>
    )
  );
};

export const InputField = observer<IInputFieldProps>(
  ({
    className,
    containerClassName,
    variant = TInputVariant.enum.small,
    field,
    disabled,
    placeholder,
    hideText,
    onKeyPress,
    disableAutofill,
    errorPosition = 'top',
    children,
    preview = false,
    label,
    labelTooltip,
    ...props
  }) => {
    const hasError = !!field.error && !disabled && field.touched;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      field.value = e.target.value;
    };

    const commonProps = {
      className,
      placeholder,
      disabled,
      value: field.value,
      type: hideText ? 'password' : 'text',
      autoComplete: disableAutofill ? 'new-password' : 'on',
      onChange: handleChange,
      onKeyDown: onKeyPress,
    } satisfies React.InputHTMLAttributes<HTMLInputElement>;

    if (preview) {
      return (
        <WDSInput
          label={label}
          tooltip={labelTooltip}
          error={field.touched && field.error ? field.error : undefined}
          size={variant === TInputVariant.enum.small ? 'sm' : ('md' as any)}
          labelClassName="flex items-center"
          outerClassName="mb-0"
          tooltipProps={{
            className: 'max-w-[220px]',
          }}
          auto
          {...commonProps}
          {...props}
        />
      );
    }

    const renderErrorMessage = (errorPosition: TErrorPosition, placement: TErrorPosition) => {
      if (errorPosition !== placement) return null;

      return (
        <FieldError field={field} hasError={hasError} errorPosition={errorPosition}>
          {children}
        </FieldError>
      );
    };

    return (
      <div className={cx(styles.container, containerClassName)}>
        {renderErrorMessage(errorPosition, 'top')}
        {children && <div>{children}</div>}
        <Input {...commonProps} variant={variant} error={!!field.error && field.touched} {...props} />
        {renderErrorMessage(errorPosition, 'bottom')}
      </div>
    );
  },
);
