import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import { IUserProfile, copyTextToClipboard, stopEventPropagation } from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { snackbarMessages } from '@web/component-library';
import isEmpty from 'lodash/isEmpty';

import { getServiceAccountTokens, issueServiceAccountToken } from '../../../../services/request/serviceAccount';
import { ServiceAccountActionType, ServiceAccountListItem } from './ServiceAccountListItem';

import styles from '../styles.module.css';

interface IServiceAccountListProps {
  accountsList: IUserProfile[];
  organizationId: number | undefined;
  onAccountRemove?: (account: IUserProfile) => void;
}

interface IListItem {
  id: number;
  profile: IUserProfile;
  open: boolean;
  loading: boolean;
  token: string;
}

interface IListItemTokenLoading {
  items: IListItem[];
  profileId: number;
  loading: boolean;
  token?: string | null;
}

const setItemProfileLoading = ({ items, profileId, loading, token }: IListItemTokenLoading): IListItem[] =>
  items.map(item => {
    const _i = { ...item };

    if (item.profile.id === profileId) {
      _i.loading = loading;
      _i.token = token || '';
    }

    return _i;
  });

export const ServiceAccountsList: React.FC<IServiceAccountListProps> = ({
  accountsList,
  onAccountRemove,
  organizationId,
}) => {
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();
  const [items, setItems] = useState<IListItem[]>([]);

  useEffect(() => {
    const items = accountsList.map((account, i) => ({
      id: i + 1,
      profile: account,
      open: false,
      token: '',
      loading: false,
    }));

    setItems(items);
  }, [accountsList]);

  const _handleClickOnAccountRemoveButton = (e: React.MouseEvent<HTMLElement>, profile: IUserProfile) => {
    stopEventPropagation(e);
    onAccountRemove && onAccountRemove(profile);
  };

  const _handleClickOnOpenListItemButton = (e: React.MouseEvent<HTMLElement>, id: number) => {
    stopEventPropagation(e);
    setItems(
      items.map(item => {
        const _item = item;

        if (item.id === id) {
          _item.open = !_item.open;
        }

        return _item;
      }),
    );
  };

  const _handleIssueServiceAccountToken = useCallback(
    async (profileId: number, action: ServiceAccountActionType) => {
      const item = items.find(item => item.profile.id === profileId);

      try {
        if (item) {
          if (action === ServiceAccountActionType.RELOAD) {
            setItems(setItemProfileLoading({ items, profileId, loading: true }));
            item.token = await issueServiceAccountToken(organizationId, profileId);
          } else if (isEmpty(item.token)) {
            setItems(setItemProfileLoading({ items, profileId, loading: true }));
            item.token = await getServiceAccountTokens(organizationId, profileId);
          }

          if (action === ServiceAccountActionType.COPY) {
            copyTextToClipboard(item.token);
            enqueueSuccessSnackbar(snackbarMessages.copy.text);
          }

          setItems(setItemProfileLoading({ items, profileId, loading: false, token: item.token }));
        }
      } catch {
        setItems(setItemProfileLoading({ items, profileId, loading: false }));
        enqueueErrorSnackbar(snackbarMessages.error.standard);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, organizationId],
  );

  return (
    <div
      className={cx(styles.serviceAccountListContainer, styles.containerFormWide, {
        [styles.serviceAccountListContainerNoBorder]: accountsList.length === 0,
      })}
    >
      {items &&
        items.map(item => (
          <ServiceAccountListItem
            key={item.profile.id}
            item={item}
            handleClickOnOpenListItemButton={_handleClickOnOpenListItemButton}
            handleIssueServiceAccountToken={_handleIssueServiceAccountToken}
            handleClickOnAccountRemoveButton={_handleClickOnAccountRemoveButton}
          />
        ))}
    </div>
  );
};

export default ServiceAccountsList;
