import { useCallback, useEffect, useMemo } from 'react';

import cx from 'classnames';

import { IconsCommonAppsGrid } from '@writercolab/assets';
import {
  Button,
  TabsList,
  Tabs as TabsRoot,
  TabsTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@writercolab/fe.wds';
import { Icon, IconVariant } from '@writercolab/ui-atoms';
import { SortingHeader } from '@writercolab/ui-molecules';

import EllipsisTooltip from 'components/molecules/EllipsisTooltip/EllipsisTooltip';

import { AppsWidgetTabId, TApplicationSortOrder, TBriefApplication } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Skeleton from 'react-loading-skeleton';
import { getAppIcon } from 'utils/applications';

import type { AppsHomeWidgetModelUi } from './AppsHomeWidgetModel.ui';

import styles from './AppsHomeWidget.module.css';

interface IAppsHomeWidgetProps {
  model: AppsHomeWidgetModelUi;
  hideRecentOptions?: boolean;
  onOpenApp: (app: Pick<TBriefApplication, 'id' | 'type' | 'access' | 'name'>) => void;
  onOpenAppLibrary: () => void;
}

const ListRow: React.FC<{
  icon: React.ReactNode;
  title: string;
  description: string;
  isFavorite?: boolean;
  onClick: () => void;
  onInfoIconHover: (open: boolean) => void;
}> = ({ icon, title, description, isFavorite, onClick, onInfoIconHover }) => (
  <div className={cx(styles.row, styles.clickable)} onClick={onClick}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.title}>
      <EllipsisTooltip text={title} textClassName={styles.titleWidth} />
      {isFavorite && (
        <div className={styles.favourite}>
          <Icon name={IconVariant.FAVORITE_FILLED} width={10} height={10} />
        </div>
      )}
    </div>

    <TooltipProvider>
      <Tooltip onOpenChange={onInfoIconHover}>
        <TooltipTrigger asChild>
          <span className={styles.infoIcon}>
            <Icon name={IconVariant.INFO_OUTLINED} />
          </span>
        </TooltipTrigger>
        <TooltipContent side="left">
          <div className={styles.tooltip}>
            <b className={styles.tootlipTitle}>{title}</b>
            {description}
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  </div>
);

const AppsList: React.FC<{
  hasNext: AppsHomeWidgetModelUi['customAppsPagination']['hasNext'];
  next: AppsHomeWidgetModelUi['customAppsPagination']['next'];
  value: AppsHomeWidgetModelUi['customAppsPagination']['value'];
  status: AppsHomeWidgetModelUi['customAppsPagination']['status'];
  isFavorite?: boolean;
  onOpenApp: IAppsHomeWidgetProps['onOpenApp'];
  onInfoIconHover: (appId: string, open: boolean) => void;
}> = observer(({ status, hasNext, next, value, isFavorite, onOpenApp, onInfoIconHover }) => {
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: status === 'pending',
    hasNextPage: hasNext,
    onLoadMore: next,
    rootMargin: '0px 0px 10px 0px',
  });

  return (
    <div className={styles.listContainer} ref={rootRef}>
      {value?.map(app => (
        <ListRow
          key={app.id}
          onClick={() => onOpenApp(app)}
          title={app.name || ''}
          description={app.description || ''}
          isFavorite={isFavorite}
          onInfoIconHover={isOpen => onInfoIconHover(app.id, isOpen)}
          icon={
            <Icon name={getAppIcon(String(app.icon)) || IconVariant.CONSOLE_APP_SHORT_FORM} width={23} height={23} />
          }
        />
      ))}

      {hasNext && (
        <div className={styles.loaderWrapper} ref={sentryRef}>
          <Skeleton count={1} height={20} style={{ marginBottom: '32px' }} />
        </div>
      )}
    </div>
  );
});

const EmptyState: React.FC<{ title: string; onOpenAllApps: () => void }> = ({ title, onOpenAllApps }) => (
  <div className={styles.emptyStateContainer}>
    <p className={cx(styles.emptyStateText, 'text-base')}>{title}</p>
    <Button onClick={onOpenAllApps} leftIcon={<IconsCommonAppsGrid />}>
      App library
    </Button>
  </div>
);

export const AppsHomeWidget = observer<IAppsHomeWidgetProps>(
  ({ model, onOpenApp, onOpenAppLibrary, hideRecentOptions }) => {
    const {
      customAppsPagination,
      favouriteAppsPagination,
      recentAppsPagination,
      recentAppsFiltered,
      favouriteAppsFiltered,
      isLoading,
      hasCustomApps,
      hasFavouriteApps,
      hasRecentApps,
      activeTab,
      setActiveTab,
      sorting,
      setSorting,
    } = model;

    const tabs = useMemo(() => {
      const defaultTabs: { id: typeof AppsWidgetTabId.type; title: string }[] = [
        { id: AppsWidgetTabId.enum.favorite, title: 'Favorites' },
      ];

      if (!hideRecentOptions) {
        defaultTabs.unshift({ id: AppsWidgetTabId.enum.recent, title: 'Recent' });
      }

      if (hasCustomApps) {
        return [...defaultTabs, { id: AppsWidgetTabId.enum.custom, title: 'Custom' }];
      } else {
        return defaultTabs;
      }
    }, [hasCustomApps, hideRecentOptions]);

    const sortingOptions = useMemo(() => {
      const options = [
        {
          id: `name_${TApplicationSortOrder.enum.asc}`,
          name: 'A-Z',
        },
        {
          id: `name_${TApplicationSortOrder.enum.desc}`,
          name: 'Z-A',
        },
      ];

      if (!hideRecentOptions) {
        options.push({
          id: `recentlyUsed_${TApplicationSortOrder.enum.desc}`,
          name: 'Last opened',
        });
      }

      return options;
    }, [hideRecentOptions]);

    useEffect(() => {
      if (hideRecentOptions && activeTab === AppsWidgetTabId.enum.recent) {
        setActiveTab(AppsWidgetTabId.enum.favorite);
      }
    }, [hideRecentOptions, activeTab, setActiveTab]);

    const onInfoIconHover = useCallback(
      (app_id: string, isTooltipOpen: boolean) => {
        if (isTooltipOpen) {
          model.analyticsService.track(AnalyticsActivity.appInfoTooltipHovered, { app_id });
        }
      },
      [model.analyticsService],
    );

    const content = AppsWidgetTabId.match(
      activeTab,
      {
        [AppsWidgetTabId.enum.favorite]: () => (
          <>
            {hasFavouriteApps ? (
              <AppsList
                status={favouriteAppsPagination.status}
                hasNext={favouriteAppsPagination.hasNext}
                next={favouriteAppsPagination.next}
                value={favouriteAppsFiltered}
                onOpenApp={onOpenApp}
                onInfoIconHover={onInfoIconHover}
                isFavorite
              />
            ) : (
              <EmptyState
                onOpenAllApps={onOpenAppLibrary}
                title="No favorites yet. Use the star icon to favorite any app in the app library"
              />
            )}
          </>
        ),
        [AppsWidgetTabId.enum.custom]: () => (
          <AppsList
            status={customAppsPagination.status}
            hasNext={customAppsPagination.hasNext}
            next={customAppsPagination.next}
            value={customAppsPagination.value}
            onInfoIconHover={onInfoIconHover}
            onOpenApp={onOpenApp}
          />
        ),
        [AppsWidgetTabId.enum.collection]: () => null,
        [AppsWidgetTabId.enum.recent]: () => (
          <>
            {hasRecentApps ? (
              <AppsList
                status={recentAppsPagination.status}
                hasNext={recentAppsPagination.hasNext}
                next={recentAppsPagination.next}
                value={recentAppsFiltered}
                onInfoIconHover={onInfoIconHover}
                onOpenApp={onOpenApp}
              />
            ) : (
              <EmptyState
                onOpenAllApps={onOpenAppLibrary}
                title="No recent apps. Discover new apps in the app library"
              />
            )}
          </>
        ),
      },
      null,
    );

    return (
      <div>
        <div className={styles.controls}>
          <TabsRoot
            variant="pill"
            size="sm"
            value={activeTab}
            onValueChange={tab => {
              setActiveTab(AppsWidgetTabId.get(tab, AppsWidgetTabId.enum.favorite));
            }}
          >
            <TabsList>
              {tabs.map(tab => (
                <TabsTrigger key={tab.id} value={tab.id} className="text-sm">
                  {tab.title}
                </TabsTrigger>
              ))}
            </TabsList>
          </TabsRoot>
          <SortingHeader
            isFillOut
            className={cx(styles.sortingHeader, { [styles.hidden]: activeTab === AppsWidgetTabId.enum.recent })}
            menuClassName={styles.sortingHeaderMenu}
            header={<></>}
            headerIcon={<Icon name={IconVariant.SORT} width={18} height={18} />}
            defaultActiveSortId={sorting}
            placement="right"
            options={sortingOptions}
            onSelect={setSorting}
          />
        </div>

        {isLoading ? (
          <div className={styles.listContainer}>
            <Skeleton count={5} height={20} style={{ marginBottom: '32px' }} />
          </div>
        ) : (
          content
        )}
      </div>
    );
  },
);

AppsHomeWidget.displayName = 'AppsHomeWidget';
