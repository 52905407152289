import type { ChangeEvent } from 'react';
import React, { useCallback, useState } from 'react';

import qs from 'qs';

import type { ITableFilter } from '@writercolab/common-utils';
import { Button, ButtonTypes, SearchBar, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';
import { FilterIndicator } from '@writercolab/ui-molecules';

import type { TOAuthAppsSortOrder, TOAuthAppsTypes, TPaginatedOAuthAppsExtraArgs } from '@web/types';
import { OAuthAppsSortField } from '@web/types';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';

import { useFilterCount } from '../../../hooks/useFilterCount';
import { OAuthAppsTable } from '../../organisms/OAuthAppsTable';
import type { OAuthAppsPageUIModel } from './OAuthAppsPageModel.ui';

import styles from './OAuthAppsPage.module.css';

export const OAuthAppsEntry = observer(({ model }: { model: OAuthAppsPageUIModel }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get('search') || '');
  const { filterCount, clearFilterCount } = useFilterCount(['types']);

  const updateUrlParams = useCallback(
    (params: TPaginatedOAuthAppsExtraArgs) => {
      // Get the current URL search parameters
      const currentParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

      // Merge the new parameters with the existing ones
      const mergedParams = { ...currentParams, ...params };

      setSearchParams(qs.stringify(mergedParams, { arrayFormat: 'repeat' }));

      // call api with debounce
      model.updateExtraArgsDebounced(mergedParams);
    },
    [model, searchParams, setSearchParams],
  );

  const updateSearchValue = useCallback(
    (value: string) => {
      // update search value in input
      setSearchValue(value);
      // update url params
      // need to remove search param if value is empty
      updateUrlParams({
        search: value,
      });
    },
    [updateUrlParams],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      updateSearchValue(value);
    },
    [updateSearchValue],
  );

  const clearSearch = useCallback(() => {
    updateSearchValue('');
  }, [updateSearchValue]);

  const handleSortingChange = useCallback(
    (id: string | number) => {
      updateUrlParams({
        sortOrder: id as TOAuthAppsSortOrder,
        sortField: OAuthAppsSortField.enum.createdAt,
      });
    },
    [updateUrlParams],
  );

  const handleTypesFilterChange = useCallback(
    (filters: ITableFilter[]) => {
      updateUrlParams({
        types: filters.filter(i => i.isSelected).map(i => i.id) as TOAuthAppsTypes[],
      });
    },
    [updateUrlParams],
  );

  const handleOpenModal = () => {
    model.openViewerModal();
  };

  const handleClearFilters = () => {
    clearFilterCount();
    updateUrlParams({
      types: [],
    });
  };

  return (
    <div className={styles.entryContainer}>
      <div className={styles.controlsContainer}>
        <div className={styles.titleControlWrapper}>
          <Text variant={TextSize.XL} bold>
            Self-managed OAuth apps
          </Text>
        </div>
        <div className={styles.tableControlsWrapper}>
          {filterCount > 0 && <FilterIndicator filtersAmount={filterCount} onClose={handleClearFilters} />}
          <SearchBar
            placeholder="Search"
            className={styles.searchContainer}
            onChange={handleSearch}
            value={searchValue}
            handleClearInput={clearSearch}
          />
          <Button type={ButtonTypes.PRIMARY} size={SizeTypes.MEDIUM} onClick={handleOpenModal}>
            Set up a new app
          </Button>
        </div>
      </div>

      <OAuthAppsTable
        model={model.oauthAppsTableModel}
        types={searchParams.getAll('types') as TOAuthAppsTypes[]}
        sortField={searchParams.get('sortField') || ''}
        sortOrder={searchParams.get('sortOrder') || ''}
        handleSortingChange={handleSortingChange}
        handleTypeFilterChange={handleTypesFilterChange}
        isSearchParamsEmpty={!searchParams.get('search')}
      />
    </div>
  );
});
