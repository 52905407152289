import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';

import { autorun } from 'mobx';

import { AnalyticsService } from '@writercolab/analytics';
import type { ITemplateBlogBuilderInput } from '@writercolab/common-utils';
import {
  BUILDER_TEMPLATE_ID,
  ContentEditorPageMode,
  IntegrationType,
  LocalStorageKey,
  LocalStorageService,
  SearchQueryParam,
  copyToClipboard,
} from '@writercolab/common-utils';
import type { components } from '@writercolab/network';
import { ContentGenerationJobStatus, STATIC_VOICE_OPTIONS } from '@writercolab/types';
import { IconVariant, useCustomSnackbar } from '@writercolab/ui-atoms';
import { isDocumentIsValid } from '@writercolab/utils';

import {
  extractOutlineSectionNames,
  formatKeyPoints,
  generateOutlineSection,
  mapKeyPointSection,
} from '../components/organisms/GeneratorSeoBlog/utils/generateUtils';

import type { IDraftFeedbackValues } from '@web/component-library';
import { snackbarMessages } from '@web/component-library';
import type {
  ActionMap,
  IKeyPointsSection,
  IOutlineSection,
  IRecapJob,
  TContentGenerationJobStatus,
  TTemplateDraft,
} from '@web/types';
import { SeoBlogSection } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { processLlmOutputBeforeInsert } from 'utils/textUtils';

import useQuery from '../hooks/useQuery';
import requestService from '../services/request/requestService';
import { useAppState } from '../state';
import type { TAppState } from '../state/types';
import { extractBackendResponseErrorMessage } from '../utils/backendErrorUtils';
import { coWriteQuotaExceeded } from '../utils/coWriteUtils';
import { getLogger } from '../utils/logger';
import { TQueryParamBooleanStd } from '../utils/queryParamUtils';
import { saveCoWriteNextState } from '../utils/templateUtils';

const LOG = getLogger('generatorSeoBlogContext');

interface IGeneratorSeoBlogContext {
  onCloseClick: () => void;
  onShowTemplatesClick: () => void;
  onChangeBlogTitle: (blogTitle: string) => void;
  onChangeSeoKeywords: (seoKeywords: string) => void;
  onChangeBlogCta: (blogCta: string) => void;
  generateTitles: () => Promise<void>;
  generateKeyPoints: (outline: IOutlineSection[], index?: number) => Promise<IKeyPointsSection[]>;
  generateOutline: (count: number, currentList: IOutlineSection[], index?: number) => Promise<IOutlineSection[]>;
  generatorSeoBlogContext: IGeneratorSeoBlogState;
  setSection: (section: SeoBlogSection) => void;
  onDraftComment: (draftId: string) => Promise<void>;
  onDraftRemove: (draftId: string) => Promise<void>;
  onDraftCopy: (draftId: string) => Promise<void>;
  generateDraft: () => Promise<boolean>;
  fetchDocumentDrafts: () => Promise<void>;
  setOutline: (outline: IOutlineSection[], resetKeyPoints?: boolean) => Promise<void>;
  setKeyPoints: (sections: IKeyPointsSection[]) => void;
  setBlogTitleValid: (isValid: boolean) => void;
  setFeedbackModalState: (state: SeoBlogModalModalState) => void;
  setDraftActionModalState: (state: SeoBlogModalModalState) => void;
  onSubmitDraftFeedback: (values: IDraftFeedbackValues) => Promise<void>;
  setContentGenerationError: (error: string | null) => void;
  unsetDraftsPreview: () => void;
  setSelectedVoiceId: (voiceId: string) => void;
}

export enum SeoBlogModalModalState {
  OPEN = 'open',
  HIDDEN = 'hidden',
}

const GeneratorSeoBlogContext = createContext<IGeneratorSeoBlogContext>({} as IGeneratorSeoBlogContext);

interface IGeneratorSeoBlogProvider {
  children?: ReactNode;
  organizationId: number | undefined;
  teamId: TAppState['teamId'];
  onBlogClosed: () => void;
  onShowTemplate: () => void;
}

interface IGeneratorSeoBlogState {
  currentDraftId: string | null;
  isLoading: boolean;
  quotaExceeded: boolean;
  blogTitleValid: boolean | null;
  loadingDocumentDrafts: boolean;
  blogOutlineValid: boolean | null;
  blogTitle: string;
  blogSeoKeywords: string;
  blogCta: string;
  outlineGenerating: boolean;
  keyPointsGenerating: boolean;
  contentGenerating: boolean;
  titlesGenerating: boolean;
  titlesGenerated: string[];
  documentDrafts: TTemplateDraft[];
  section: SeoBlogSection;
  contentGenerationError: string | null;
  keyPointsGenerated: IKeyPointsSection[];
  outlineGenerated: IOutlineSection[];
  draftActionModalState: SeoBlogModalModalState;
  draftFeedbackModalState: SeoBlogModalModalState;
  selectedVoiceId: string;
}

enum TGeneratorSeoBlogActionType {
  SetInitialData = 'setInitialData',
  SetIsLoading = 'isLoading',
  SetQuotaExceeded = 'quotaExceeded',
  SetOutlineValid = 'outlineValid',
  SetBlogTitleValid = 'blogTitleValid',
  SetTitlesGenerating = 'titlesGenerating',
  SetOutlineGenerating = 'outlineGenerating',
  SetKeyPointsGenerating = 'keyPointsGenerating',
  SetContentGenerating = 'contentGenerating',
  SetLoadingDocumentDrafts = 'loadingDocumentDrafts',
  SetBlogTitle = 'blogTitle',
  SetTitlesGenerated = 'titlesGenerated',
  SetKeyPointsGenerated = 'keyPointsGenerated',
  SetOutlineGenerated = 'outlineGenerated',
  SetSeoKeywords = 'seoKeywords',
  SetDocumentDrafts = 'documentDrafts',
  SetBlogCta = 'blogCta',
  SetDraftFeedbackModalState = 'draftFeedbackModalState',
  SetDraftActionModalState = 'draftActionModalState',
  SetCurrentDraftId = 'draftCurrentDraftId',
  SetSection = 'section',
  SetContentGenerationError = 'ContentGenerationError',
  SetSelectedVoiceId = 'selectedVoiceId',
}

type TGeneratorSeoBlogPayload = {
  [TGeneratorSeoBlogActionType.SetIsLoading]: boolean;
  [TGeneratorSeoBlogActionType.SetTitlesGenerating]: boolean;
  [TGeneratorSeoBlogActionType.SetQuotaExceeded]: boolean;
  [TGeneratorSeoBlogActionType.SetContentGenerating]: boolean;
  [TGeneratorSeoBlogActionType.SetLoadingDocumentDrafts]: boolean;
  [TGeneratorSeoBlogActionType.SetOutlineGenerating]: boolean;
  [TGeneratorSeoBlogActionType.SetKeyPointsGenerating]: boolean;
  [TGeneratorSeoBlogActionType.SetCurrentDraftId]: string;
  [TGeneratorSeoBlogActionType.SetOutlineValid]: boolean | null;
  [TGeneratorSeoBlogActionType.SetBlogTitle]: string;
  [TGeneratorSeoBlogActionType.SetBlogTitleValid]: boolean | null;
  [TGeneratorSeoBlogActionType.SetTitlesGenerated]: string[];
  [TGeneratorSeoBlogActionType.SetSeoKeywords]: string;
  [TGeneratorSeoBlogActionType.SetBlogCta]: string;
  [TGeneratorSeoBlogActionType.SetContentGenerationError]: string | null;
  [TGeneratorSeoBlogActionType.SetInitialData]: Partial<IGeneratorSeoBlogState>;
  [TGeneratorSeoBlogActionType.SetOutlineGenerated]: IOutlineSection[];
  [TGeneratorSeoBlogActionType.SetDraftFeedbackModalState]: SeoBlogModalModalState;
  [TGeneratorSeoBlogActionType.SetDraftActionModalState]: SeoBlogModalModalState;
  [TGeneratorSeoBlogActionType.SetDocumentDrafts]: TTemplateDraft[];
  [TGeneratorSeoBlogActionType.SetSection]: SeoBlogSection;
  [TGeneratorSeoBlogActionType.SetKeyPointsGenerated]: IKeyPointsSection[];
  [TGeneratorSeoBlogActionType.SetSelectedVoiceId]: string;
};

type TGeneratorSeoBlogActions = ActionMap<TGeneratorSeoBlogPayload>[keyof ActionMap<TGeneratorSeoBlogPayload>];
type TBlogNextStateStorageRecord = {
  orgId: string | number | undefined;
  teamId: string | number | undefined;
  docId: string;
  state: Partial<IGeneratorSeoBlogState>;
};

interface CoWriteBlogNextStateStorageRecords {
  [key: string]: Partial<IGeneratorSeoBlogState>;
}

export const getCoWriteNextStateStorageKey = ({
  orgId,
  teamId,
  docId,
}: {
  orgId: string | number | undefined;
  teamId: string | number | undefined;
  docId: string;
}) => `${orgId}-${teamId}-${docId}`;

export const getBlogNextState = (
  orgId: string | number | undefined,
  teamId: string | number | undefined,
  docId: string,
): Partial<IGeneratorSeoBlogState> => {
  const key = getCoWriteNextStateStorageKey({ orgId, teamId, docId });
  const storedRecords =
    LocalStorageService.getItem<IGeneratorSeoBlogState[]>(LocalStorageKey.coWriteBlogNextState) || [];
  const storageState = storedRecords.find(r => r[key]);
  let nextState = {
    blogTitle: '',
    blogCta: '',
    blogSeoKeywords: '',
    outlineGenerated: [],
    keyPointsGenerated: [],
  };

  if (storageState && storageState[key]) {
    nextState = {
      ...nextState,
      ...storageState[key],
    };
  }

  return nextState;
};

export const setBlogNextState = async ({ orgId, teamId, docId, state }: TBlogNextStateStorageRecord) => {
  const key = getCoWriteNextStateStorageKey({ orgId, teamId, docId });
  const records: CoWriteBlogNextStateStorageRecords[] =
    LocalStorageService.getItem(LocalStorageKey.coWriteBlogNextState) || [];
  const recordIndex = records.findIndex(r => r[key]);

  if (recordIndex === -1) {
    records.push({
      [key]: state,
    });
  } else {
    records[recordIndex] = {
      [key]: state,
    };
  }

  LocalStorageService.setItem(LocalStorageKey.coWriteBlogNextState, records);
};

const generatorSeoBlogContextReducer = (state: IGeneratorSeoBlogState, action: TGeneratorSeoBlogActions) => {
  let newState: IGeneratorSeoBlogState;

  switch (action.type) {
    case TGeneratorSeoBlogActionType.SetIsLoading:
      newState = { ...state, isLoading: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetQuotaExceeded:
      newState = { ...state, quotaExceeded: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetBlogTitle:
      newState = { ...state, blogTitle: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetSeoKeywords:
      newState = { ...state, blogSeoKeywords: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetBlogCta:
      newState = { ...state, blogCta: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetTitlesGenerating:
      newState = { ...state, titlesGenerating: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetTitlesGenerated:
      newState = { ...state, titlesGenerated: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetOutlineGenerating:
      newState = { ...state, outlineGenerating: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetOutlineGenerated:
      newState = { ...state, outlineGenerated: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetSection:
      newState = { ...state, section: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetKeyPointsGenerating:
      newState = { ...state, keyPointsGenerating: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetKeyPointsGenerated:
      newState = { ...state, keyPointsGenerated: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetContentGenerating:
      newState = { ...state, contentGenerating: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetDocumentDrafts:
      newState = { ...state, documentDrafts: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetLoadingDocumentDrafts:
      newState = { ...state, loadingDocumentDrafts: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetBlogTitleValid:
      newState = { ...state, blogTitleValid: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetInitialData:
      newState = { ...state, ...action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetDraftFeedbackModalState:
      newState = { ...state, draftFeedbackModalState: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetCurrentDraftId:
      newState = { ...state, currentDraftId: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetDraftActionModalState:
      newState = { ...state, draftActionModalState: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetOutlineValid:
      newState = { ...state, blogOutlineValid: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetContentGenerationError:
      newState = { ...state, contentGenerationError: action.payload };
      break;
    case TGeneratorSeoBlogActionType.SetSelectedVoiceId:
      newState = { ...state, selectedVoiceId: action.payload };
      break;
    default:
      newState = { ...state };
  }

  return newState;
};

const initialGeneratorSeoBlog: IGeneratorSeoBlogState = {
  blogOutlineValid: null,
  isLoading: false,
  quotaExceeded: false,
  documentDrafts: [],
  blogTitleValid: null,
  titlesGenerating: false,
  loadingDocumentDrafts: false,
  contentGenerating: false,
  outlineGenerating: false,
  keyPointsGenerating: false,
  blogTitle: '',
  blogSeoKeywords: '',
  blogCta: '',
  keyPointsGenerated: [],
  titlesGenerated: [],
  outlineGenerated: [],
  draftFeedbackModalState: SeoBlogModalModalState.HIDDEN,
  draftActionModalState: SeoBlogModalModalState.HIDDEN,
  section: SeoBlogSection.TITLE,
  currentDraftId: null,
  contentGenerationError: null,
  selectedVoiceId: STATIC_VOICE_OPTIONS.DEFAULT,
};

const GeneratorSeoBlogContextProvider: React.FC<IGeneratorSeoBlogProvider> = observer(
  ({ organizationId, teamId, onBlogClosed, onShowTemplate, children }) => {
    const query = useQuery();
    const queryString = query.toString();
    const navigate = useNavigate();
    const analytics = new AnalyticsService(requestService.api, IntegrationType.DEFAULT);
    const { docId } = useParams<Record<string, string>>();
    const [generatorSeoBlogContext, dispatchSeoBlogContext] = useReducer(
      generatorSeoBlogContextReducer,
      initialGeneratorSeoBlog,
    );
    const { appState, appModel } = useAppState();
    const { enqueueCustomSnackbar, enqueueErrorSnackbar, enqueueSuccessSnackbar } = useCustomSnackbar();
    const [previousJobStatus, setPreviousJobStatus] = useState<TContentGenerationJobStatus>(
      ContentGenerationJobStatus.enum.completed,
    );

    const unsetDraftsPreview = () => {
      query.delete(SearchQueryParam.draftsPreview);
      navigate({ search: query.toString() });
    };

    const onCloseClick = useCallback(() => {
      saveCoWriteNextState(organizationId, teamId, docId, {
        mode: ContentEditorPageMode.BLOG,
        blogState: generatorSeoBlogContext.section,
      });
      onBlogClosed();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, teamId, docId, generatorSeoBlogContext.section]);
    const onShowTemplatesClick = () => onShowTemplate();

    const onChangeBlogTitle = (blogTitle: string): Promise<void> => {
      dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetBlogTitle, payload: blogTitle });

      return setOutline([]);
    };

    const onChangeSeoKeywords = (seoKeywords: string): Promise<void> => {
      dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetSeoKeywords, payload: seoKeywords });

      return setOutline([]);
    };

    const onChangeBlogCta = (blogCta: string): Promise<void> => {
      dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetBlogCta, payload: blogCta });

      return setOutline([]);
    };

    const setSection = (section: SeoBlogSection) => {
      dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetSection, payload: section });
    };

    const setOutline = async (outline: IOutlineSection[], resetKeyPoints?: boolean): Promise<void> => {
      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetOutlineGenerated,
        payload: outline,
      });

      resetKeyPoints && setKeyPoints([]);
    };

    const setBlogTitleValid = (blogTitleValid: boolean | null) =>
      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetBlogTitleValid,
        payload: blogTitleValid,
      });

    const generateTitles = async () => {
      try {
        analytics.track(AnalyticsActivity.coWriteSeoBlogBuilderGeneratedMoreTitles, {});
        const { blogTitle, blogCta, blogSeoKeywords, titlesGenerated, selectedVoiceId } = generatorSeoBlogContext;
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetTitlesGenerating, payload: true });
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: false });
        const { data } = await requestService.api.post(
          '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/cowrite/builder/{type}',
          {
            params: {
              path: {
                organizationId: Number(organizationId),
                teamId: Number(teamId),
                documentId: String(appState.documentId),
                type: 'title',
              },
              query: {
                templateId: BUILDER_TEMPLATE_ID.SEO_BLOG,
                count: 5,
              },
            },
            body: {
              type: 'outline',
              title: blogTitle,
              keywords: blogSeoKeywords,
              cta: blogCta,
              current: titlesGenerated,
              voiceId: selectedVoiceId === STATIC_VOICE_OPTIONS.DEFAULT ? undefined : selectedVoiceId,
            },
          },
        );

        const { titles } = data as components['schemas']['template_model_CoWriteBuilderTitleResponse'];
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetTitlesGenerated, payload: titles });
      } catch (error: any) {
        LOG.error(error);

        if (coWriteQuotaExceeded(error)) {
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: true });
        } else {
          enqueueErrorSnackbar(error?.response?.data?.errors?.[0]?.description || 'Something went wrong.');
        }

        throw error;
      } finally {
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetTitlesGenerating, payload: false });
      }
    };

    const generateOutline = async (
      count: number,
      currentList: IOutlineSection[],
      index?: number,
    ): Promise<IOutlineSection[]> => {
      let outlineRes: IOutlineSection[] = [];
      const isFullListGeneration = index === undefined;
      try {
        isFullListGeneration &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetOutlineGenerating, payload: true });
        analytics.track(AnalyticsActivity.coWriteSeoBlogBuilderGeneratedNewOutline, {});
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: false });
        const { blogTitle, blogCta, blogSeoKeywords, outlineGenerated, selectedVoiceId } = generatorSeoBlogContext;
        const { data } = await requestService.api.post(
          '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/cowrite/builder/{type}',
          {
            params: {
              path: {
                organizationId: Number(organizationId),
                teamId: Number(teamId),
                documentId: String(appState.documentId),
                type: 'outline',
              },
              query: {
                templateId: BUILDER_TEMPLATE_ID.SEO_BLOG,
                count,
              },
            },
            body: {
              type: 'outline',
              title: blogTitle,
              keywords: blogSeoKeywords,
              cta: blogCta,
              current: extractOutlineSectionNames(currentList),
              index,
              voiceId: selectedVoiceId === STATIC_VOICE_OPTIONS.DEFAULT ? undefined : selectedVoiceId,
            },
          },
        );

        const { outline } = data as components['schemas']['template_model_CoWriteBuilderOutlineResponse'];
        const _outlineSections = outline.map(item => ({
          ...generateOutlineSection(),
          name: item,
        }));

        if (isFullListGeneration) {
          outlineRes = _outlineSections;
        } else {
          const _outline = [...outlineGenerated];
          _outline[index] = {
            ..._outline[index],
            name: outline[index],
          };
          outlineRes = _outlineSections;
        }

        await setOutline(outlineRes, true);
      } catch (error: any) {
        LOG.error(error);

        if (coWriteQuotaExceeded(error)) {
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: true });
        } else {
          enqueueErrorSnackbar(error?.response?.data?.errors?.[0]?.description || 'Something went wrong.');
        }

        throw error;
      } finally {
        isFullListGeneration &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetOutlineGenerating, payload: false });
      }

      return outlineRes;
    };

    const setKeyPoints = (sections: IKeyPointsSection[]) => {
      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetKeyPointsGenerated,
        payload: sections.map(section => ({
          ...section,
          keyPoints: section.keyPoints.map(keyPoint => ({
            ...keyPoint,
            valid: !isEmpty(keyPoint.name),
          })),
        })),
      });
    };

    const setContentGenerationError = (err: null | string) => {
      if (isEmpty(err)) {
        query.delete('error');
        navigate({ search: query.toString() });
      } else {
        query.set('error', encodeURI(err ?? ''));
        navigate({ search: query.toString() });
      }

      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetContentGenerationError,
        payload: err,
      });
    };

    const generateKeyPoints = async (outline: IOutlineSection[], index?: number): Promise<IKeyPointsSection[]> => {
      let keyPoints: IKeyPointsSection[] = [];
      const isFullListGeneration = index === undefined;
      try {
        analytics.track(AnalyticsActivity.coWriteSeoBlogBuilderGeneratedRegeneratedPoints, {});
        const { blogTitle, blogCta, blogSeoKeywords, keyPointsGenerated, selectedVoiceId } = generatorSeoBlogContext;
        isFullListGeneration &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetKeyPointsGenerating, payload: true });
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: false });
        const { data } = await requestService.api.post(
          '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/cowrite/builder/{type}',
          {
            params: {
              path: {
                organizationId: Number(organizationId),
                teamId: Number(teamId),
                documentId: String(appState.documentId),
                type: 'point',
              },
              query: {
                templateId: BUILDER_TEMPLATE_ID.SEO_BLOG,
              },
            },
            body: {
              type: 'point',
              title: blogTitle,
              cta: blogCta,
              keywords: blogSeoKeywords,
              sections: formatKeyPoints(keyPointsGenerated),
              outline: extractOutlineSectionNames(outline),
              index,
              voiceId: selectedVoiceId === STATIC_VOICE_OPTIONS.DEFAULT ? undefined : selectedVoiceId,
            },
          },
        );

        const { sections } = data as components['schemas']['template_model_CoWriteBuilderPointResponse'];
        const _sections = mapKeyPointSection(sections);
        setKeyPoints(_sections);
        keyPoints = _sections;
      } catch (error: any) {
        LOG.error(error);

        if (coWriteQuotaExceeded(error)) {
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: true });
          dispatchSeoBlogContext({
            type: TGeneratorSeoBlogActionType.SetKeyPointsGenerated,
            payload: generatorSeoBlogContext.keyPointsGenerated.map(section => ({
              ...section,
              keyPoints: section.keyPoints.map(keyPoint => ({
                ...keyPoint,
                loading: false,
                valid: !isEmpty(keyPoint.name),
              })),
            })),
          });
        } else {
          enqueueErrorSnackbar(extractBackendResponseErrorMessage(error));
        }

        throw error;
      } finally {
        isFullListGeneration &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetKeyPointsGenerating, payload: false });
      }

      return keyPoints;
    };

    const generateDraft = useCallback(async (): Promise<boolean> => {
      let generated = false;

      try {
        const { blogTitle, blogCta, blogSeoKeywords, keyPointsGenerated, selectedVoiceId } = generatorSeoBlogContext;
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: false });
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetContentGenerating, payload: true });
        analytics.track(AnalyticsActivity.coWriteSeoBlogBuilderGeneratedGeneratedContent, {});
        await requestService.api.post(
          '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/cowrite/builder/{type}',
          {
            params: {
              path: {
                organizationId: Number(organizationId),
                teamId: Number(teamId),
                documentId: String(appState.documentId),
                type: 'post',
              },
              query: {
                templateId: BUILDER_TEMPLATE_ID.SEO_BLOG,
              },
            },
            body: {
              type: 'post',
              title: blogTitle,
              cta: blogCta,
              keywords: blogSeoKeywords,
              sections: formatKeyPoints(keyPointsGenerated),
              voiceId: selectedVoiceId === STATIC_VOICE_OPTIONS.DEFAULT ? undefined : selectedVoiceId,
            },
          },
        );
        await fetchDocumentDrafts();
        generated = true;
      } catch (error: any) {
        LOG.error(error);

        if (coWriteQuotaExceeded(error)) {
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: true });
        } else {
          enqueueErrorSnackbar(extractBackendResponseErrorMessage(error));
        }

        throw error;
      }

      return generated;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.documentId, generatorSeoBlogContext]);

    const fetchDocumentDrafts = useCallback(async () => {
      if (!isDocumentIsValid(appState.documentId) || !organizationId || !teamId) {
        return;
      }

      try {
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetLoadingDocumentDrafts, payload: true });
        const { data: drafts } = await requestService.api.get(
          '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}',
          {
            params: {
              path: {
                organizationId,
                teamId,
                documentId: `${appState.documentId}`,
              },
              query: {
                templateId: BUILDER_TEMPLATE_ID.SEO_BLOG,
                limit: 10,
                offset: 0,
              },
            },
          },
        );

        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetDocumentDrafts, payload: drafts });
      } catch (error: any) {
        LOG.error(error);

        if (coWriteQuotaExceeded(error)) {
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: true });
        } else {
          enqueueErrorSnackbar(extractBackendResponseErrorMessage(error));
        }

        throw error;
      } finally {
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetLoadingDocumentDrafts, payload: false });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.documentId, organizationId, teamId]);

    const onDraftComment = async (draftId: string) => {
      dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetCurrentDraftId, payload: draftId });
      setFeedbackModalState(SeoBlogModalModalState.OPEN);
    };

    const onDraftRemove = useCallback(
      async (draftId: string) => {
        try {
          await requestService.api.delete(
            '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/draft/{draftId}',
            {
              params: {
                path: {
                  organizationId: parseInt(`${organizationId}`, 10),
                  teamId: parseInt(`${teamId}`, 10),
                  documentId: appState.documentId,
                  draftId: parseInt(`${draftId}`, 10),
                },
              },
            },
          );

          await fetchDocumentDrafts();
        } catch (err: any) {
          LOG.error(err);
          enqueueErrorSnackbar(extractBackendResponseErrorMessage(err));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [organizationId, teamId, appState.documentId],
    );

    const onDraftCopy = async (draftId: string) => {
      const { documentDrafts } = generatorSeoBlogContext;
      const draft = documentDrafts.find(d => d.id === parseInt(draftId, 10));

      if (draft) {
        const text = draft.title ? `<h2>${draft.title}</h2>\n${draft.body}` : draft.body;
        const html = processLlmOutputBeforeInsert(text);
        const isCopied = copyToClipboard({ text, html });

        if (isCopied) {
          enqueueCustomSnackbar(snackbarMessages.copy.text, { icon: IconVariant.COPY });
        }
      }
    };

    const setFeedbackModalState = (state: SeoBlogModalModalState) =>
      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetDraftFeedbackModalState,
        payload: state,
      });

    const setDraftActionModalState = (state: SeoBlogModalModalState) =>
      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetDraftActionModalState,
        payload: state,
      });

    const setSelectedVoiceId = (id: string) =>
      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetSelectedVoiceId,
        payload: id,
      });

    const onSubmitDraftFeedback = useCallback(
      async (values: IDraftFeedbackValues) => {
        if (!organizationId || !teamId || !docId) {
          return;
        }

        setFeedbackModalState(SeoBlogModalModalState.HIDDEN);
        try {
          await requestService.api.post(
            '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/draft/{draftId}/feedback',
            {
              params: {
                path: {
                  organizationId,
                  teamId,
                  documentId: parseInt(`${docId}`, 10),
                  draftId: parseInt(`${generatorSeoBlogContext.currentDraftId}`, 10),
                },
              },
              body: {
                ...values,
              },
            },
          );

          enqueueSuccessSnackbar(snackbarMessages.feedback.success);
        } catch {
          enqueueErrorSnackbar(snackbarMessages.feedback.error);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [docId, generatorSeoBlogContext.currentDraftId, organizationId, teamId],
    );

    const isStorageEmpty = () => !!LocalStorageService.getItem(LocalStorageKey.coWriteBlogNextState);

    useEffect(() => {
      if (!organizationId || !teamId || !docId || !isDocumentIsValid(docId)) {
        return;
      }

      const nextBlogState = getBlogNextState(organizationId, teamId, docId);
      const nextBlogStatePayload = {
        ...nextBlogState,
      };

      if (!isEmpty(generatorSeoBlogContext.blogTitle)) {
        nextBlogStatePayload.blogTitle = generatorSeoBlogContext.blogTitle;
      }

      // optional property
      nextBlogStatePayload.blogSeoKeywords = generatorSeoBlogContext.blogSeoKeywords;

      // optional property
      nextBlogStatePayload.blogCta = generatorSeoBlogContext.blogCta;

      if (!isEmpty(generatorSeoBlogContext.keyPointsGenerated)) {
        nextBlogStatePayload.keyPointsGenerated = generatorSeoBlogContext.keyPointsGenerated;
      }

      if (!isEmpty(generatorSeoBlogContext.outlineGenerated)) {
        nextBlogStatePayload.outlineGenerated = generatorSeoBlogContext.outlineGenerated;
      }

      setBlogNextState({ orgId: organizationId, teamId, docId, state: nextBlogStatePayload });
    }, [
      organizationId,
      teamId,
      docId,
      generatorSeoBlogContext.blogTitle,
      generatorSeoBlogContext.blogSeoKeywords,
      generatorSeoBlogContext.blogCta,
      generatorSeoBlogContext.keyPointsGenerated,
      generatorSeoBlogContext.outlineGenerated,
    ]);

    const setInitialData = useCallback(() => {
      if (!organizationId || !teamId || !docId || !isDocumentIsValid(docId)) {
        return;
      }

      const nextBlogState = getBlogNextState(organizationId, teamId, docId);

      dispatchSeoBlogContext({
        type: TGeneratorSeoBlogActionType.SetInitialData,
        payload: nextBlogState,
      });
    }, [organizationId, teamId, docId]);

    useEffect(() => {
      if (!organizationId || !teamId || !docId || !isDocumentIsValid(docId)) {
        return;
      }

      const fetchAndPrefillTemplateDrafts = async () => {
        try {
          const { data: drafts } = await requestService.api.get(
            '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}',
            {
              params: {
                path: {
                  organizationId,
                  teamId,
                  documentId: docId,
                },
              },
            },
          );

          const draftTemplate = drafts.find(item => item.templateId === BUILDER_TEMPLATE_ID.SEO_BLOG);

          if (draftTemplate) {
            prefillBlogBuilderInputValuesFromDraftTemplate(draftTemplate);
          }
        } catch (err) {
          LOG.error('Error fetching and prefilling template drafts:', err);
        }
      };

      if (isStorageEmpty()) {
        fetchAndPrefillTemplateDrafts()
          .then(() => setInitialData())
          .catch(err => LOG.error(err));
      } else {
        setInitialData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docId, organizationId, teamId]);

    useEffect(() => {
      if (generatorSeoBlogContext.section === SeoBlogSection.TITLE) {
        analytics.track(AnalyticsActivity.coWriteViewedSeoBlogBuilderStep1, {});
      } else if (generatorSeoBlogContext.section === SeoBlogSection.OUTLINE) {
        analytics.track(AnalyticsActivity.coWriteViewedSeoBlogBuilderStep2, {});
      } else if (generatorSeoBlogContext.section === SeoBlogSection.KEYPOINTS) {
        analytics.track(AnalyticsActivity.coWriteViewedSeoBlogBuilderStep3, {});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatorSeoBlogContext.section]);

    const prefillBlogBuilderInputValuesFromQueueJob = async (job: IRecapJob): Promise<void> => {
      if (job.inputs) {
        job.inputs.title &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetBlogTitle, payload: job.inputs.title });
        job.inputs.keywords &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetSeoKeywords, payload: job.inputs.keywords });
        job.inputs.cta &&
          dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetBlogCta, payload: job.inputs.cta });

        if (job.inputs.sections) {
          setOutline(
            job.inputs.sections.map(item => ({
              ...generateOutlineSection(),
              name: item.outline,
            })),
          ).then(() => {
            if (job.inputs.sections.points) {
              dispatchSeoBlogContext({
                type: TGeneratorSeoBlogActionType.SetKeyPointsGenerated,
                payload: job.inputs.sections.map(section => ({
                  ...section,
                  keyPoints: job.inputs.sections.points.map(keyPoint => ({
                    ...keyPoint,
                    valid: !isEmpty(keyPoint.name),
                  })),
                })),
              });
            }
          });
        }
      }
    };

    useEffect(
      () =>
        autorun(() => {
          if (!docId) {
            return;
          }

          const job = appModel.notificationsModel.currentDocumentNotification as IRecapJob;
          const queryError = query.get(SearchQueryParam.error);
          const queryPreview = query.get(SearchQueryParam.draftsPreview);

          if (!job) {
            LOG.debug('Job not found by document id', docId);

            return;
          }

          if (queryError) {
            const errorString = decodeURI(queryError);

            if (!isEmpty(errorString)) {
              dispatchSeoBlogContext({
                type: TGeneratorSeoBlogActionType.SetContentGenerationError,
                payload: errorString,
              });
              setSection(SeoBlogSection.TITLE);
              prefillBlogBuilderInputValuesFromQueueJob(job);
            }
          } else if (queryPreview && queryPreview === TQueryParamBooleanStd.enum.true) {
            fetchDocumentDrafts()
              .then(() => prefillBlogBuilderInputValuesFromQueueJob(job))
              .then(() => setSection(SeoBlogSection.DRAFTS));
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [appModel.notificationsModel.currentDocumentNotification, docId, queryString],
    );

    useEffect(
      () =>
        autorun(() => {
          if (docId) {
            const job = appModel.notificationsModel.currentDocumentNotification as IRecapJob;

            if (job && job.status !== previousJobStatus) {
              switch (job.status) {
                case ContentGenerationJobStatus.enum.completed:
                  dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetContentGenerating, payload: false });
                  setSection(SeoBlogSection.DRAFTS);
                  fetchDocumentDrafts().then(() => prefillBlogBuilderInputValuesFromQueueJob(job));
                  break;
                case ContentGenerationJobStatus.enum.error:
                  prefillBlogBuilderInputValuesFromQueueJob(job).then(() => setSection(SeoBlogSection.TITLE));
                  break;
                case ContentGenerationJobStatus.enum.generating:
                  dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetContentGenerating, payload: true });
                  break;
                default:
                  break;
              }

              setPreviousJobStatus(job.status);
            }
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [appModel.notificationsModel.currentDocumentNotification, docId, previousJobStatus],
    );

    useEffect(() => {
      if (appModel.assistantSubscription.limits?.coWrite?.exceeded) {
        dispatchSeoBlogContext({ type: TGeneratorSeoBlogActionType.SetQuotaExceeded, payload: true });
      }
    }, [appModel.assistantSubscription.limits?.coWrite?.exceeded]);

    const prefillBlogBuilderInputValuesFromDraftTemplate = (template: TTemplateDraft) => {
      const templateInputs: ITemplateBlogBuilderInput = template.inputs as ITemplateBlogBuilderInput;

      if (templateInputs) {
        const nextBlogStatePayload = {
          blogTitle: templateInputs.title,
          blogSeoKeywords: templateInputs.keywords,
          blogCta: templateInputs.cta,
          keyPointsGenerated: [] as IKeyPointsSection[],
          outlineGenerated: [] as IOutlineSection[],
        };
        dispatchSeoBlogContext({
          type: TGeneratorSeoBlogActionType.SetBlogTitle,
          payload: nextBlogStatePayload.blogTitle,
        });
        dispatchSeoBlogContext({
          type: TGeneratorSeoBlogActionType.SetSeoKeywords,
          payload: nextBlogStatePayload.blogSeoKeywords,
        });
        dispatchSeoBlogContext({
          type: TGeneratorSeoBlogActionType.SetBlogCta,
          payload: nextBlogStatePayload.blogCta,
        });

        if (templateInputs.sections && docId && organizationId && teamId) {
          nextBlogStatePayload.outlineGenerated = templateInputs.sections.map(section => ({
            ...generateOutlineSection(),
            name: section.outline,
          }));
          setOutline(nextBlogStatePayload.outlineGenerated)
            .then(() => {
              if (templateInputs.sections) {
                nextBlogStatePayload.keyPointsGenerated = templateInputs.sections.map(section => ({
                  name: section.outline,
                  keyPoints: section.points.map(keyPoint => ({
                    name: keyPoint,
                    valid: !isEmpty(keyPoint),
                  })),
                })) as IKeyPointsSection[];
                setKeyPoints(nextBlogStatePayload.keyPointsGenerated);
              }
            })
            .finally(() =>
              setBlogNextState({
                orgId: organizationId,
                teamId,
                docId,
                state: nextBlogStatePayload,
              }),
            );
        }
      }
    };

    const contextValue = {
      generatorSeoBlogContext,
      setSection,
      fetchDocumentDrafts,
      generateKeyPoints,
      onCloseClick,
      onShowTemplatesClick,
      onChangeBlogTitle,
      onChangeSeoKeywords,
      onChangeBlogCta,
      generateTitles,
      generateOutline,
      onDraftComment,
      onDraftRemove,
      onDraftCopy,
      generateDraft,
      setKeyPoints,
      setBlogTitleValid,
      setOutline,
      setFeedbackModalState,
      setDraftActionModalState,
      setContentGenerationError,
      unsetDraftsPreview,
      onSubmitDraftFeedback,
      setSelectedVoiceId,
    };

    return <GeneratorSeoBlogContext.Provider value={contextValue}>{children}</GeneratorSeoBlogContext.Provider>;
  },
);

export function useGeneratorSeoBlogContext() {
  const context = useContext(GeneratorSeoBlogContext);

  if (!context) {
    throw new Error('useGeneratorSeoBlogContext must be used within the CoWriteContextProvider');
  }

  return context;
}

export default GeneratorSeoBlogContextProvider;
