import React from 'react';

import cx from 'classnames';

import { sanitize } from '@writercolab/text-utils';
import { Button, ButtonTypes, FontFace, Icon, IconVariant, Text, TextSize, Tooltip } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IHighlightBodyProps {
  content: string;
  onCopyClick: (content: string) => void;
}

export const HighlightBody: React.FC<IHighlightBodyProps> = ({ content, onCopyClick }) => (
  <div className={styles.containerHighlightText}>
    <div className={styles.containerHighlightCopy}>
      <Tooltip title="Copy" placement="bottom">
        <Button
          onClick={() => onCopyClick(content)}
          type={ButtonTypes.TRANSPARENT}
          className={cx(styles.headerContainerButtonShort)}
        >
          <Icon name={IconVariant.COPY} width={18} height={18} />
        </Button>
      </Tooltip>
    </div>
    <div className={styles.containerHighlightTextContent}>
      <Text
        className={styles.containerHighlightTextMono}
        variant={TextSize.L}
        fontFace={FontFace.monospace}
        dangerouslySetInnerHTML={{ __html: sanitize(content) || '' }}
      />
    </div>
  </div>
);

export default HighlightBody;
