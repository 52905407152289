import type React from 'react';

import { type IUserProfile } from '@writercolab/common-utils';
import { AvatarSize, Heading, HeadingVariant, Text, TextSize, UserAvatar } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IOrganizationJoinRequestProps {
  organizationName: string;
  organizationAdmins: IUserProfile[];
}

const AdminItem: React.FC<{ admin: IUserProfile }> = ({ admin }) => (
  <div className={styles.adminItem} key={admin?.id}>
    <UserAvatar avatarPath={admin?.avatar} size={AvatarSize.L} label={admin?.email || ''} fullName={admin?.fullName} />
    <Text variant={TextSize.XL} className={styles.adminEmail}>
      {admin?.email}
    </Text>
  </div>
);

export const OrganizationJoinRequest: React.FC<IOrganizationJoinRequestProps> = ({
  organizationName,
  organizationAdmins,
}) => (
  <div className={styles.panelContainer}>
    <div className={styles.container}>
      <div className={styles.section}>
        <div>
          <Heading className={styles.panelHeading} variant={HeadingVariant.H2} medium>
            Your request to join
            <br /> {organizationName} is pending
            <br /> admin approval!
          </Heading>
          <div className={styles.panelBody}>
            <Text variant={TextSize.XL} className={styles.panelBodyText}>
              Your org admins will be notified. You’ll receive an email once your request is approved.
            </Text>
            <Text variant={TextSize.XL} className={styles.panelBodyText}>
              In the meantime, you can reach out directly to an org admin on your team if you need immediate access.
            </Text>
          </div>
          <div className={styles.panelBody}>
            <Heading variant={HeadingVariant.H3} bold>
              Org admins
            </Heading>
          </div>
        </div>
        <div className={styles.scrollableContent}>
          <div className={styles.panelAdmins}>
            {organizationAdmins.map(orgAdminUser => (
              <AdminItem admin={orgAdminUser} key={orgAdminUser.id} />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default OrganizationJoinRequest;
