import type React from 'react';

import cx from 'classnames';

import { numberWithCommas } from '@writercolab/common-utils';
import { Text, TextSize, Tooltip } from '@writercolab/ui-atoms';

import SnippetsIcon from '../../../assets/icons/navIcons/snippetsUnfilled.svg?react';
import TermsIcon from '../../../assets/icons/navIcons/terms.svg?react';

import styles from './styles.module.css';

interface ITeamStatsProps extends BaseComponentsProps {
  /** Statistics of Team */
  stats: {
    users: number;
    snippets: number;
    terms: number;
    activeUsers: number;
    suggestionsAccepted: number;
    wordsChecked: number;
  };
}

const uiStatsInfo = {
  users: {
    name: 'Users',
  },
  snippets: {
    name: 'Snippets',
    icon: <SnippetsIcon />,
  },
  terms: {
    name: 'Terms',
    icon: <TermsIcon />,
  },
  activeUsers: {
    name: 'Active users',
    hint: 'In the last 30 days',
  },
  suggestionsAccepted: {
    name: 'Suggestions accepted',
    hint: 'In the last 30 days',
  },
  wordsChecked: {
    name: 'Words checked',
    hint: 'In the last 30 days',
  },
};

export const TeamStats: React.FC<ITeamStatsProps> = ({ className, stats, ...props }) => (
  <div className={cx(className, styles.teamStats)} {...props}>
    {Object.keys(stats).map(statItem => {
      const statValue = stats[statItem] > -1 ? numberWithCommas(stats[statItem]) : '-';

      return (
        <div className={styles.statItem} key={statItem}>
          <Text variant={TextSize.XS} className={styles.statsTitle}>
            {uiStatsInfo[statItem].name}
          </Text>
          {uiStatsInfo[statItem].hint ? (
            <Tooltip title={uiStatsInfo[statItem].hint}>
              <div className={styles.statsValue}>
                {uiStatsInfo[statItem]?.icon}
                {statValue}
              </div>
            </Tooltip>
          ) : (
            <div className={styles.statsValue}>
              {uiStatsInfo[statItem]?.icon}
              {statValue}
            </div>
          )}
        </div>
      );
    })}
  </div>
);

export default TeamStats;
