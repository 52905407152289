import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export const PromptCategories = new Enum(
  'advertising',
  'external-comms',
  'general',
  'marketing',
  'support',
  'product',
  'recruiting',
  'sales',
  'design',
  'brainstorming',
  'generating',
  'summarizing',
  'rewriting',
  'translating',
  'translations',
  'ads',
  'blogs',
  'emails',
  'social',
  'shortform',
  'longform',
  'healthcare',
  'financial-services',
  'retail',
  'technology',
  'analyzing',
  'transforming',
);

export const ContentType = new Enum(
  'ads',
  'blogs',
  'caseStudies',
  'defaultLongform',
  'defaultShortform',
  'general',
  'emails',
  'errors',
  'faqs',
  'lists',
  'messaging',
  'social',
  'summaries',
  'supportArticle',
  'translations',
);

export type TServerPrompt = components['schemas']['content_generation_model_Prompt'];
export type TPromptTag = components['schemas']['content_generation_model_PromptTag'];

export interface TPrompt extends TServerPrompt {
  contentType: typeof ContentType.type;
}

export const TPromptsLibraryTabsId = new Enum('team', 'user');
export const TPromptsLibraryQueryParams = new Enum('plTab');
export const TSyntheticPromptLibraryTags = new Enum('contentType');
