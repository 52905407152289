import type React from 'react';
import { useMemo, useState } from 'react';

import cx from 'classnames';

import type { TOrgTeamUserActivityParams } from '@writercolab/common-utils';
import {
  BillingProduct,
  BillingStatus,
  PlanStatusLabelColor,
  PlanStatusReadable,
  daysDiff,
  getDateFormatMMMDDYYYY,
  getDateFormatMMMMDDYYYY,
  getDateFormatMMMMYYYY,
  getNumberOfDays,
  numberWithCommas,
  wordPluralize,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  LabelledTags,
  MuiLinearProgress,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { abbreviateNumber } from '@writercolab/utils';

import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import type { IBillingContext } from '../../../../context/billingContext';
import { launchSupportEmail } from '../../../../utils/navigationUtils';
import BillingInfoPopup, { BillingInfoPopupType } from '../BillingInfoPopup';
import { CancelledDuePlanHolder } from './CancelledDuePlanHolder';
import InfoIcon from './InfoIcon';

import styles from './styles.module.css';

export interface IYourBillingPlanWidget extends BaseComponentsProps {
  billingProduct: BillingProduct;
  billingStatus: BillingStatus;
  wChecked: number;
  wIncluded: number;
  coWriteChecked: number;
  coWriteIncluded: number;
  isCowriteShown: boolean;
  paymentDate: Date;
  cancelAt?: string | null;
  renewByDate?: string | null;
  blockedUntil?: string | null;
  numOfUsers?: number;
  totalNumOfSeats?: number;
  onManageUsersCallback?: () => void;
  onManageBillingGroupsCallback?: () => void;
  onManagePlanCallback?: () => void;
  onUpgradeNowCallback?: IBillingContext['onUpdateSubscription'];
  onActivateFreeCallback?: IBillingContext['onActivateFreePlan'];
  isTrialExpired?: boolean;
  isLoading?: boolean;
  isFreeSubscription?: boolean;
  isEventTakeawaysEnabled?: boolean;
  transcriptsLimit: number;
  transcriptsValue: number;
  transcriptsResetDate?: string;
  analytics: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
}

const injectStyles = {
  barColorPrimary: cx(styles.barColorPrimary),
  colorPrimary: styles.colorPrimary,
  barColorSecondary: cx(styles.barColorSecondary),
  colorSecondary: styles.colorSecondary,
};

const billingProductLabel = {
  [BillingProduct.FREE]: 'FREE PLAN',
  [BillingProduct.PRO]: 'PRO PLAN',
  [BillingProduct.TEAM]: 'TEAM PLAN',
  [BillingProduct.ENTERPRISE]: 'ENTERPRISE PLAN',
};

const BillingPaymentInfo = ({ paymentDate, billingStatus, billingProduct, onInfoIconClick }) => {
  const _currentDate = Date.now();
  const _numOfDays = getNumberOfDays(paymentDate.getTime() - _currentDate);
  const _paymentStatus = billingStatus === BillingStatus.ACTIVE ? 'Next payment' : 'Payment failed';
  const _planLabel = billingProduct === BillingProduct.PRO ? 'Pro' : 'Team';
  const _wrappedTextClass = billingProduct === BillingProduct.TEAM ? styles.wrappedText : '';

  if (billingStatus === BillingStatus.TRIALING) {
    return (
      <div className={styles.paymentInfoHolder}>
        <Text variant={TextSize.S} className={_wrappedTextClass}>
          You have {_numOfDays} more days of access to the {_planLabel} trial.
        </Text>
        {billingProduct === BillingProduct.PRO && (
          <Text variant={TextSize.S}>
            After that, we'll automatically move you to our Free plan. <InfoIcon onClick={onInfoIconClick} />
          </Text>
        )}
      </div>
    );
  }

  return (
    <div className={styles.paymentInfoHolder}>
      <Text variant={TextSize.L} bold>
        {_paymentStatus}
      </Text>
      <Text variant={TextSize.S}>{getDateFormatMMMDDYYYY(paymentDate)}</Text>
    </div>
  );
};

export const YourBillingPlanWidget: React.FC<IYourBillingPlanWidget> = ({
  analytics,
  billingProduct,
  billingStatus,
  wChecked,
  wIncluded,
  coWriteChecked,
  coWriteIncluded,
  paymentDate,
  cancelAt,
  renewByDate,
  numOfUsers,
  totalNumOfSeats,
  onManageUsersCallback,
  onManageBillingGroupsCallback,
  onManagePlanCallback,
  onUpgradeNowCallback,
  onActivateFreeCallback,
  blockedUntil,
  isTrialExpired,
  isLoading,
  isCowriteShown,
  isFreeSubscription,
  isEventTakeawaysEnabled,
  transcriptsLimit,
  transcriptsValue,
  transcriptsResetDate,
}) => {
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const _planLabel = isTrialExpired ? PlanStatusReadable._FE_TRIAL_EXPIRED : PlanStatusReadable[billingStatus];

  const words = useMemo(
    () => ({
      checked: numberWithCommas(wChecked),
      included: abbreviateNumber(wIncluded, true),
      usagePercentage: Math.round((wChecked / wIncluded) * 100),
      rateLimitReached: wChecked >= wIncluded,
    }),
    [wChecked, wIncluded],
  );

  const coWriteWords = useMemo(
    () => ({
      checked: numberWithCommas(coWriteChecked),
      included: abbreviateNumber(coWriteIncluded, true),
      usagePercentage: Math.round((coWriteChecked / coWriteIncluded) * 100),
      rateLimitReached: coWriteChecked >= coWriteIncluded,
    }),
    [coWriteChecked, coWriteIncluded],
  );

  const limitations = useMemo(
    () => ({
      blockedUntil: blockedUntil ? daysDiff(blockedUntil, new Date().toISOString()) : 0,
    }),
    [blockedUntil],
  );

  const variation = {
    freePlan: billingProduct === BillingProduct.FREE,
    proPlan: billingProduct === BillingProduct.PRO,
    teamPlan: billingProduct === BillingProduct.TEAM,
    enterprisePlan: billingProduct === BillingProduct.ENTERPRISE,
  };

  const access = {
    showBillingFailedStates:
      !variation.enterprisePlan &&
      (cancelAt || [BillingStatus.CANCELED, BillingStatus.PAST_DUE].includes(billingStatus)),
    showBillingPaymentInfo: !(
      variation.freePlan ||
      variation.enterprisePlan ||
      cancelAt ||
      [BillingStatus.CANCELED].includes(billingStatus)
    ),
    showBillingGroup: variation.enterprisePlan,
    showUsersBox:
      !!numOfUsers && !variation.freePlan && !variation.proPlan && ![BillingStatus.CANCELED].includes(billingStatus),
    showUsageBox: ![BillingStatus.CANCELED].includes(billingStatus),
    showTotalNumOfSeats: totalNumOfSeats && billingStatus !== BillingStatus.TRIALING,
    showManagePlanButton:
      (variation.proPlan || variation.teamPlan) &&
      ![BillingStatus.TRIALING, BillingStatus.CANCELED].includes(billingStatus),
    infoPopupType: variation.proPlan ? BillingInfoPopupType.DOWNGRADE_TO_FREE : BillingInfoPopupType.ENDING_A_TEAM_PLAN,
    isLimitReached: variation.freePlan
      ? words.rateLimitReached
      : words.rateLimitReached || coWriteWords.rateLimitReached,
    showContactSupport: billingProduct === BillingProduct.ENTERPRISE || billingProduct === BillingProduct.TEAM,
  };

  const changeInfoPopupState = () => setIsInfoPopupOpen(!isInfoPopupOpen);

  const _handleOpenRequestForm = () => {
    analytics.track(AnalyticsActivity.clickedContactSales, {
      clicked_from: 'billing_page',
    });

    launchSupportEmail();
  };

  const circularProgressBarStyles = buildStyles({
    pathColor: '#000000',
    textColor: '#000000',
    trailColor: '#d4d6db',
    strokeLinecap: 'butt',
  });

  if (isTrialExpired && variation.freePlan) {
    return (
      <div className={styles.mainContainer}>
        <Heading variant={HeadingVariant.H4} className={styles.yourPlanHeading}>
          Your plan
        </Heading>
        {isLoading ? (
          <SkeletonLoader type={SkeletonLoaderType.YOUR_BILLING_PLAN_WIDGET} />
        ) : (
          <div className={styles.overviewHolder}>
            <Text bold variant={TextSize.M}>
              Activate your Free plan
            </Text>
            <Text variant={TextSize.M}>
              Your trial has ended, but we now offer a Free plan. Activate Free to continue using Writer.
            </Text>

            <Button type={ButtonTypes.PRIMARY} className={styles.activateFreeButton} onClick={onActivateFreeCallback}>
              Activate Free
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <Heading variant={HeadingVariant.H4} className={styles.yourPlanHeading}>
        Your plan
      </Heading>
      {isLoading ? (
        <SkeletonLoader type={SkeletonLoaderType.YOUR_BILLING_PLAN_WIDGET} />
      ) : (
        <>
          {/* Cancelled/Past Due Plan Holder */}
          {access.showBillingFailedStates && (
            <CancelledDuePlanHolder
              billingProduct={billingProduct}
              planStatus={billingStatus}
              onInfoIconClick={changeInfoPopupState}
              onUpgradeNowCallback={onUpgradeNowCallback}
              cancelAt={cancelAt || paymentDate}
              renewByDate={renewByDate}
              isTrialExpired={isTrialExpired}
            />
          )}

          {/* Overview Holder */}
          <div
            className={cx(styles.overviewHolder, {
              [styles.overviewHolderPro]: variation.proPlan,
              [styles.overviewHolderTeam]: variation.teamPlan,
              [styles.overviewHolderTeamTrail]: variation.teamPlan && billingStatus === BillingStatus.TRIALING,
            })}
          >
            <div className={styles.planStatusAndTypeHolder}>
              <Text caps variant={TextSize.XXXL}>
                {billingProductLabel[billingProduct]}
              </Text>
              <LabelledTags bgColor={PlanStatusLabelColor[billingStatus]}>{_planLabel}</LabelledTags>
            </div>
            {access.showBillingPaymentInfo && (
              <BillingPaymentInfo
                {...{ paymentDate, billingStatus, billingProduct }}
                onInfoIconClick={changeInfoPopupState}
              />
            )}
          </div>

          {/* Usage and Users Holder */}
          <div className={styles.gridContainer}>
            {access.showUsersBox && (
              <div className={styles.usersHolder}>
                <Text variant={TextSize.L} bold>
                  {numOfUsers} {wordPluralize(numOfUsers || 1, 'User')}
                </Text>
                {access.showTotalNumOfSeats && (
                  <Text variant={TextSize.S} caps className={styles.numOfSeatsText}>
                    {totalNumOfSeats} {wordPluralize(totalNumOfSeats || 1, 'seat')}
                  </Text>
                )}
                <Button
                  type={ButtonTypes.TRANSPARENT_LINK}
                  className={styles.manageUsersButton}
                  onClick={onManageUsersCallback}
                >
                  Manage users
                </Button>
                {access.showBillingGroup && (
                  <Button
                    type={ButtonTypes.TRANSPARENT_LINK}
                    className={styles.manageUsersButton}
                    onClick={onManageBillingGroupsCallback}
                  >
                    Manage billing groups
                  </Button>
                )}
              </div>
            )}
            {access.showUsageBox && (
              <div
                className={cx(styles.usageHolder, {
                  [styles.usageHolderLimitReached]: access.isLimitReached,
                })}
              >
                <Text variant={TextSize.L} bold>
                  Usage
                </Text>

                <div className={styles.progressHolder}>
                  <MuiLinearProgress
                    variant="determinate"
                    color={words.rateLimitReached ? 'secondary' : 'primary'}
                    classes={injectStyles}
                    value={words.usagePercentage >= 100 ? 100 : words.usagePercentage}
                    className={styles.progressBar}
                  />
                </div>

                <div className={styles.progressWrapper}>
                  <Text variant={TextSize.XXS}>Words checked</Text>

                  <Text variant={TextSize.XXS} className={styles.wordsCheckedHolder}>
                    <span
                      className={cx(styles.wordsChecked, {
                        [styles.wordsCheckedLimitHit]: words.rateLimitReached,
                      })}
                    >
                      {words.checked}
                    </span>{' '}
                    / {words.included} included
                  </Text>
                </div>

                {isCowriteShown && (
                  <>
                    <div className={styles.progressHolder}>
                      <MuiLinearProgress
                        variant="determinate"
                        color={coWriteWords.rateLimitReached ? 'secondary' : 'primary'}
                        classes={injectStyles}
                        value={coWriteWords.usagePercentage >= 100 ? 100 : coWriteWords.usagePercentage}
                        className={styles.progressBar}
                      />
                    </div>

                    <div className={styles.progressWrapper}>
                      <Text variant={TextSize.XXS}>Words generated</Text>

                      <Text variant={TextSize.XXS} className={styles.wordsCheckedHolder}>
                        <span
                          className={cx(styles.wordsChecked, {
                            [styles.wordsCheckedLimitHit]: coWriteWords.rateLimitReached,
                          })}
                        >
                          {coWriteWords.checked}
                        </span>{' '}
                        / {coWriteWords.included} included
                      </Text>
                    </div>
                  </>
                )}

                {access.isLimitReached && (
                  <>
                    {access.showContactSupport && (
                      <Button
                        type={ButtonTypes.BLACK}
                        content="Contact support"
                        className={styles.contactSupport}
                        onClick={_handleOpenRequestForm}
                      />
                    )}

                    <Text variant={TextSize.XS} className={styles.monthlyLimitText}>
                      You've reached your monthly limit.{' '}
                      {limitations.blockedUntil > 0 && (
                        <span>Your monthly usage resets in {limitations.blockedUntil} days.</span>
                      )}
                    </Text>
                  </>
                )}
              </div>
            )}

            {/* Media Holder */}
            {!isFreeSubscription && isEventTakeawaysEnabled && (
              <Tooltip
                placement="top"
                disabled={transcriptsValue < transcriptsLimit}
                title={
                  <Text className={styles.filePickerTooltip} medium color={TextColor.WHITE} variant={TextSize.XL}>
                    Resets on {getDateFormatMMMMDDYYYY(transcriptsResetDate)}
                  </Text>
                }
              >
                <div className={styles.mediaHolder}>
                  {transcriptsValue >= transcriptsLimit && (
                    <Text variant={TextSize.S} color={TextColor.WHITE} upperCase className={styles.mediaLimitReached}>
                      Limit reached
                    </Text>
                  )}
                  <Text variant={TextSize.L} bold>
                    Recaps
                  </Text>
                  <Text variant={TextSize.XXS} className={styles.mediaFilesUsed}>
                    <CircularProgressbarWithChildren
                      className={styles.mediaFilesProgressIcon}
                      value={transcriptsValue / (transcriptsLimit / 100)}
                      strokeWidth={16}
                      styles={circularProgressBarStyles}
                    />
                    {transcriptsValue}/{transcriptsLimit} transcriptions used
                  </Text>
                  <Text variant={TextSize.S} className={styles.mediaDate}>
                    {getDateFormatMMMMYYYY(new Date())}
                  </Text>
                </div>
              </Tooltip>
            )}
          </div>

          {/* Manage Plan */}
          {access.showManagePlanButton && (
            <Button
              type={ButtonTypes.TRANSPARENT_LINK}
              className={styles.managePlanButton}
              onClick={onManagePlanCallback}
            >
              Manage plan
            </Button>
          )}

          {/* InfoPopup */}
          <BillingInfoPopup
            type={access.infoPopupType}
            open={isInfoPopupOpen}
            handleClose={changeInfoPopupState}
            hideActionButtons
          />
        </>
      )}
    </div>
  );
};

export default YourBillingPlanWidget;
