import React from 'react';
import cx from 'classnames';
import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import { IconVariant } from '@writercolab/ui-atoms';
import { observer } from 'mobx-react-lite';

import styles from './EditorToolbar.module.css';
import type { EditorToolbarModel } from './UIEditorToolbarModel';
import EditorToolbarButton from '../EditorToolbarButton/EditorToolbarButton';
import { EditorToolbarFormatDropdown } from '../EditorToolbarDropdown/EditorToolbarDropdown';
import EditorToolbarAlignPanel from '../EditorToolbarAlignPanel/EditorToolbarAlignPanel';
import EditorToolbarColorPanel from '../EditorToolbarColorPanel/EditorToolbarColorPanel';

export interface IEditorToolbarProps {
  className?: string;
  model: EditorToolbarModel;
  onFormatClick: (format: QUILL_FORMAT, value?: string) => void;
}

const FormatsSeparator = () => <div className={styles.actionButtonSeparator} />;

export const EditorToolbar: React.FC<IEditorToolbarProps> = observer(({ model, className, onFormatClick }) => {
  const currentFormats = model.currentTextFormats;

  const onCleanColors = () => {
    onFormatClick(QUILL_FORMAT.BACKGROUND, '');
    onFormatClick(QUILL_FORMAT.COLOR, '');
  };

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.containerToolbar}>
        <div className={styles.containerToolbarActions}>
          <div className={styles.containerToolbarActionsLeft}>
            {model.formatsMap[QUILL_FORMAT.HEADER] && (
              <EditorToolbarFormatDropdown
                className={styles.actionMainDropdownFont}
                format={QUILL_FORMAT.HEADER}
                options={model.headingSource}
                onClick={onFormatClick}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.FONT] && (
              <EditorToolbarFormatDropdown
                className={styles.actionMainDropdownFont}
                format={QUILL_FORMAT.FONT}
                options={model.fontSource}
                onClick={onFormatClick}
              />
            )}
          </div>
          <FormatsSeparator />
          <div className={styles.containerToolbarActionsRight}>
            {model.formatsMap[QUILL_FORMAT.BOLD] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.BOLD}
                icon={IconVariant.EDITOR_FORMAT_BOLD}
                iconWidth={20}
                iconHeight={20}
                onClick={onFormatClick}
                active={!!currentFormats[QUILL_FORMAT.BOLD]}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.ITALIC] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.ITALIC}
                icon={IconVariant.EDITOR_FORMAT_ITALIC}
                onClick={onFormatClick}
                active={!!currentFormats[QUILL_FORMAT.ITALIC]}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.UNDERLINE] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.UNDERLINE}
                icon={IconVariant.EDITOR_FORMAT_UNDERLINE}
                onClick={onFormatClick}
                active={!!currentFormats[QUILL_FORMAT.UNDERLINE]}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.STRIKE] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.STRIKE}
                icon={IconVariant.EDITOR_FORMAT_STRIKETHROUGH}
                onClick={onFormatClick}
                active={!!currentFormats[QUILL_FORMAT.STRIKE]}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.CODE] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.CODE}
                icon={IconVariant.EDITOR_FORMAT_CODE}
                onClick={onFormatClick}
                active={!!currentFormats[QUILL_FORMAT.CODE]}
              />
            )}
            {(model.formatsMap[QUILL_FORMAT.COLOR] || model.formatsMap[QUILL_FORMAT.BACKGROUND]) && (
              <EditorToolbarButton
                icon={IconVariant.EDITOR_FORMAT_TYPOGRAPHY}
                active={
                  (!!currentFormats[QUILL_FORMAT.COLOR] && model.isValidColorFormatValue) ||
                  !!currentFormats[QUILL_FORMAT.BACKGROUND]
                }
                expandable
              >
                <EditorToolbarColorPanel onFormatClick={onFormatClick} onCleanColors={onCleanColors} model={model} />
              </EditorToolbarButton>
            )}
            {model.formatsMap[QUILL_FORMAT.LINK] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.LINK}
                icon={IconVariant.EDITOR_FORMAT_LINK}
                onClick={onFormatClick}
                active={!!currentFormats[QUILL_FORMAT.LINK]}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.LIST] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.LIST}
                value="bullet"
                icon={IconVariant.EDITOR_FORMAT_LIST_BULLET}
                onClick={onFormatClick}
                active={currentFormats[QUILL_FORMAT.LIST] === 'bullet'}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.LIST] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.LIST}
                icon={IconVariant.EDITOR_FORMAT_LIST_ORDERED}
                value="ordered"
                onClick={onFormatClick}
                active={currentFormats[QUILL_FORMAT.LIST] === 'ordered'}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.ALIGN] && (
              <EditorToolbarButton
                format={QUILL_FORMAT.ALIGN}
                icon={IconVariant.EDITOR_FORMAT_ALIGN_LEFT}
                active={!!currentFormats[QUILL_FORMAT.ALIGN]}
                expandable
              >
                <EditorToolbarAlignPanel
                  currentAlign={currentFormats[QUILL_FORMAT.ALIGN]}
                  onFormatClick={onFormatClick}
                />
              </EditorToolbarButton>
            )}
            {model.formatsMap[QUILL_FORMAT.INDENT] && (
              <EditorToolbarButton
                icon={IconVariant.EDITOR_FORMAT_INDENT_DECREASE}
                value="-1"
                format={QUILL_FORMAT.INDENT}
                onClick={onFormatClick}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.INDENT] && (
              <EditorToolbarButton
                icon={IconVariant.EDITOR_FORMAT_INDENT_INCREASE}
                value="+1"
                format={QUILL_FORMAT.INDENT}
                onClick={onFormatClick}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.BLOCKQUOTE] && (
              <EditorToolbarButton
                icon={IconVariant.EDITOR_FORMAT_QUOTEBLOCK}
                format={QUILL_FORMAT.BLOCKQUOTE}
                active={!!currentFormats[QUILL_FORMAT.BLOCKQUOTE]}
                onClick={onFormatClick}
              />
            )}
            {model.formatsMap[QUILL_FORMAT.CLEAN] && (
              <EditorToolbarButton
                icon={IconVariant.EDITOR_FORMAT_CLEAR}
                format={QUILL_FORMAT.CLEAN}
                onClick={onFormatClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default EditorToolbar;
