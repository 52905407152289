import type Quill from 'quill';
import type { RangeStatic } from 'quill';
import { action, computed, makeObservable, observable } from 'mobx';

import { openNewTab, stringToUrl } from '@writercolab/common-utils';
import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import { type DropdownOption, Text } from '@writercolab/ui-atoms';

import styles from './EditorToolbar.module.css';
import { FontsModule } from '../../modules/fonts';

export class EditorToolbarModel {
  currentSelection: RangeStatic | null = null;
  selectionRects: DOMRect | null = null;
  selectionLinkSource: string | null = null;
  showLinkModal = false;
  showPreviewLinkModal = false;

  constructor(
    private quill: Quill,
    private formats: QUILL_FORMAT[],
  ) {
    makeObservable(this, {
      currentSelection: observable.ref,
      selectionRects: observable.ref,
      selectionLinkSource: observable,
      showLinkModal: observable,
      showPreviewLinkModal: observable,

      currentTextFormats: computed,
      selectionLinkAnchor: computed,
      headingSource: computed,
      fontSource: computed,
      isValidColorFormatValue: computed,

      onChangeSelection: action.bound,
      onCurrentTextFormatChange: action.bound,
      onCloseLinkFormat: action.bound,
      onCloseLinkEditFormat: action.bound,
      onShowLinkModal: action.bound,
    });
  }

  get headingSource() {
    const defaultSource = [
      {
        id: '0',
        name: 'Normal',
      },
      {
        id: '1',
        customOptionName: (
          <Text bold className={styles.header1}>
            Heading 1
          </Text>
        ),
        name: 'Heading 1',
      },
      {
        id: '2',
        customOptionName: (
          <Text bold className={styles.header2}>
            Heading 2
          </Text>
        ),
        name: 'Heading 2',
      },
      {
        id: '3',
        customOptionName: (
          <Text bold className={styles.header3}>
            Heading 3
          </Text>
        ),
        name: 'Heading 3',
      },
    ];

    return this.getDropdownSource(defaultSource, QUILL_FORMAT.HEADER);
  }

  get fontSource() {
    const defaultSource = FontsModule.fontsList.map(font => ({
      id: font.name,
      customOptionName: <Text style={{ fontFamily: font.title }}>{font.title}</Text>,
      name: font.title,
    }));

    const matcher = (id: string, value?: string) => {
      if (id !== 'poppins') {
        return id === value;
      }

      return !value;
    };

    return this.getDropdownSource(defaultSource, QUILL_FORMAT.FONT, matcher);
  }

  // TODO: add types for possible format values

   
  get currentTextFormats(): Partial<Record<QUILL_FORMAT, any>> {
    const range = this.currentSelection;

    return range ? this.quill.getFormat(range) : {};
  }

  get selectionLinkAnchor() {
    return this.currentTextFormats[QUILL_FORMAT.LINK];
  }

  get formatsMap() {
    const formatSources = this.formats;

    return formatSources.reduce(
      (res, format) => {
        res[format] = true;

        return res;
      },

       
      {} as Partial<Record<QUILL_FORMAT, any>>,
    );
  }

  /**
   * Some of the color formats values shouldn't be considered as selected in editor, since they are all black
   */
  get isValidColorFormatValue() {
    return !['black', '#000', '#000000', 'rgb(0,0,0)', 'rgba(0,0,0,0)', 'var(--classic-black)'].includes(
      this.currentTextFormats[QUILL_FORMAT.COLOR],
    );
  }

  onShowLinkModal = () => {
    this.showLinkModal = true;
  };

  onChangeSelection = (range: RangeStatic | null) => {
    if (range) {
      this.currentSelection = range;

      const currentSelection = window.getSelection()?.getRangeAt(0);

      if (currentSelection) {
        this.selectionRects = currentSelection.getBoundingClientRect();
      }

      this.onCurrentTextFormatChange(range);
    }
  };

  onCurrentTextFormatChange = (range: RangeStatic) => {
    if (!this.currentTextFormats[QUILL_FORMAT.LINK]) {
      return;
    }

    const selectionBlot = this.quill.getLeaf(range.index)?.[0];

    if (selectionBlot) {
      const selectionRects = selectionBlot.domNode.parentElement?.getBoundingClientRect();

      if (selectionRects) {
        this.currentSelection = {
          index: selectionBlot.offset(this.quill.scroll),
          length: selectionBlot.length(),
        } as RangeStatic;
        this.selectionRects = selectionRects;
        this.selectionLinkSource = selectionBlot.domNode.textContent;
        this.showPreviewLinkModal = true;
        this.showLinkModal = false;
      }
    }
  };

  onApplyLinkFormat = (url: string) => {
    if (!this.currentSelection) {
      return;
    }

    this.quill.formatText(this.currentSelection, QUILL_FORMAT.COLOR, false, 'user');
    this.quill.formatText(this.currentSelection, QUILL_FORMAT.LINK, url, 'user');
    this.onCloseLinkFormat();
  };

  onCloseLinkFormat = () => {
    this.selectionRects = null;
    this.showLinkModal = false;
  };

  onCloseLinkEditFormat = () => {
    this.selectionRects = null;
    this.showPreviewLinkModal = false;
  };

  onClickRemoveLink = () => {
    if (!this.currentSelection) {
      return;
    }

    this.quill.formatText(this.currentSelection, QUILL_FORMAT.LINK, false, 'user');
    this.onCloseLinkEditFormat();
  };

  onClickOpenLink = () => {
    if (this.selectionLinkAnchor) {
      openNewTab(stringToUrl(this.selectionLinkAnchor));
    }
  };

  onLinkChangeApply = (source: string, anchor: string) => {
    const editor = this.quill;

    if (!this.currentSelection) {
      return;
    }

    const { index, length } = this.currentSelection;

    editor.deleteText(index, length, 'user');
    editor.insertText(index, source, 'user');
    editor.formatText(index, source.length, QUILL_FORMAT.LINK, anchor, 'user');

    editor.setSelection({
      index,
      length: source.length,
    });
    this.onCloseLinkEditFormat();
  };

  private getDropdownSource(
    source: DropdownOption[],
    format: QUILL_FORMAT,
    activeMatcher: (id: string, value?: string) => boolean = (id, value) => id === value,
  ) {
     
    const value: any | undefined = this.currentTextFormats[format];

    return source.map(heading => ({
      ...heading,
      active: activeMatcher(heading.id, value?.toString()),
    }));
  }
}
