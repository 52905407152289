import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IDocumentHighlightEntryPaginationProps {
  count: number;
  current: number;
  onNextClick: () => void;
  onPrevClick: () => void;
}

enum ArrowDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

const ArrowButton = ({ onClick, disabled, direction }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    icon={<Icon name={IconVariant.BREADCRUMB_ARROW} />}
    type={ButtonTypes.TRANSPARENT}
    className={cx(styles.containerPaginationArrow, {
      [styles.containerPaginationArrowDisabled]: disabled,
      [styles.containerPaginationArrowsRight]: direction === ArrowDirection.RIGHT,
      [styles.containerPaginationArrowsLeft]: direction === ArrowDirection.LEFT,
    })}
  />
);

export const DocumentHighlightEntryPagination: React.FC<IDocumentHighlightEntryPaginationProps> = ({
  count,
  current,
  onNextClick,
  onPrevClick,
}) => (
  <div className={styles.containerPagination}>
    <div className={styles.containerPaginationNumbers}>
      <Text variant={TextSize.XL}>
        {current} of {count}
      </Text>
    </div>
    <div className={styles.containerPaginationArrows}>
      <ArrowButton disabled={current === 1} onClick={onPrevClick} direction={ArrowDirection.LEFT} />
      <ArrowButton disabled={current === count} onClick={onNextClick} direction={ArrowDirection.RIGHT} />
    </div>
  </div>
);

export default DocumentHighlightEntryPagination;
