import { Inline } from 'quill';
import { QA_REWRITE_FORMAT_NAME, QA_REWRITE_CLASS_PREFIX } from '@writercolab/quill-delta-utils';

export class RephraseHighlightFormat extends Inline {
  static override tagName = 'span';
  static override blotName = QA_REWRITE_FORMAT_NAME;
  static override className = QA_REWRITE_CLASS_PREFIX;

  static override formats(domNode: HTMLElement) {
    return domNode.classList.contains(QA_REWRITE_CLASS_PREFIX);
  }
}
