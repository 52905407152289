import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

export const ResetPasswordButton = ({ onResetPassword, isLoading }) => (
  <>
    <div className={cx(styles.containerRow, styles.containerRowResetPassword, styles.containerRowTitleBlock)}>
      <Text variant={TextSize.L} bold>
        Reset password
      </Text>
      <Text variant={TextSize.M} className={styles.containerRowTitleDescription}>
        Forget your password? Don’t worry, just click to reset.
      </Text>
    </div>
    <div className={cx(styles.containerRow, styles.containerRowResetAction, styles.containerRowCentered)}>
      <Icon name={IconVariant.LOCK_LINE} className={styles.resetPasswordIcon} />
      <Button
        type={ButtonTypes.SECONDARY}
        size={SizeTypes.SMALL}
        content="Reset password"
        onClick={onResetPassword}
        className={styles.resetPasswordButton}
        isLoading={isLoading}
      />
    </div>
  </>
);
