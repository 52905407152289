import { DocumentHighlightType } from '@writercolab/common-utils';

import { TConsoleApplicationEmbedLayout } from '@web/types';
import { AnalyticEvents, AnalyticsActivity } from 'constants/analytics';

type HighlightsActivityEventsMap = {
  [k in DocumentHighlightType]: AnalyticEvents;
};

/**
 * `HighlightsActivityEventsMap` is a mapping object that associates each type of
 * document highlight (as defined in the `DocumentHighlightType` enum) with a
 * specific analytic event from the `AnalyticsActivity`.
 *
 * For instance:
 * - If a Twitter post highlight is regenerated, the corresponding analytic event
 *   `coWriteHighlightsRegeneratedTwitter` will be fired.
 * - Similarly, for a LinkedIn post highlight, its associated analytic event will be triggered.
 *
 * This ensures that every document highlight type has a dedicated analytic event.
 */
export const HighlightsGenerateActivityEventsMap: HighlightsActivityEventsMap = {
  [DocumentHighlightType.TWITTER]: AnalyticsActivity.coWriteHighlightsRegeneratedTwitter,
  [DocumentHighlightType.LINKEDIN]: AnalyticsActivity.coWriteHighlightsRegeneratedLinkedIn,
  [DocumentHighlightType.META]: AnalyticsActivity.coWriteHighlightsRegeneratedMeta,
  [DocumentHighlightType.POINTS]: AnalyticsActivity.coWriteHighlightsRegeneratedSummary,
};

/**
 * `HighlightsCopyContentActivityEventsMap` is a mapping object designed to associate
 * each type of document highlight (specified in the `DocumentHighlightType` enum) with
 * its corresponding analytics event in `AnalyticsActivity` that gets triggered when
 * the content is copied.
 *
 * For instance:
 * - When the content of a Twitter post highlight is copied, the associated analytic
 *   event `coWriteHighlightsCopiedTwitter` will be fired.
 * - Likewise, if a LinkedIn post highlight's content is copied, the event
 *   `coWriteHighlightsCopiedLinkedIn` will be triggered.
 *
 * This mapping ensures a dedicated tracking mechanism for content copying actions
 * across different types of document highlights.
 */
export const HighlightsCopyContentActivityEventsMap: HighlightsActivityEventsMap = {
  [DocumentHighlightType.TWITTER]: AnalyticsActivity.coWriteHighlightsCopiedTwitter,
  [DocumentHighlightType.LINKEDIN]: AnalyticsActivity.coWriteHighlightsCopiedLinkedIn,
  [DocumentHighlightType.META]: AnalyticsActivity.coWriteHighlightsCopiedMeta,
  [DocumentHighlightType.POINTS]: AnalyticsActivity.coWriteHighlightsCopiedSummary,
};

export const getEmbedType = (embedLayout?: typeof TConsoleApplicationEmbedLayout.type): string => {
  return embedLayout === TConsoleApplicationEmbedLayout.enum.widget
    ? TConsoleApplicationEmbedLayout.enum.widget
    : 'iframe';
};

export const getPageAnalyticsProperties = () => {
  const properties = {
    page_name: document.title.split('|')[1]?.trim() || document.title,
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
    url: window.location.href,
  };

  return properties;
};
