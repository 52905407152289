import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, SizeTypes, Text, Tooltip } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IActionButtonProps extends BaseComponentsProps {
  content?: string;
  iconSymbol?: string;
  leftIconSymbol?: string;
  arrow?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  tooltipContent?: React.ReactNode;
}

const ActionButton: React.FC<IActionButtonProps> = ({
  className,
  onClick,
  content,
  iconSymbol,
  arrow,
  disabled,
  isLoading,
  tooltipContent,
  leftIconSymbol,
}) => (
  <Tooltip placement="top" title={tooltipContent || ''} disabled={!tooltipContent}>
    <Button
      size={SizeTypes.SMALL}
      isLoading={isLoading}
      className={cx(className, {
        [styles.headerStepActionButtonArrow]: arrow,
      })}
      disabled={disabled}
      onClick={onClick}
      type={ButtonTypes.SECONDARY}
    >
      {leftIconSymbol && (
        <span className={cx(styles.headerActionButtonIcon, styles.headerActionButtonIconLeft)}>{leftIconSymbol}</span>
      )}
      {content && <Text>{content}</Text>}
      {iconSymbol && <span className={styles.headerActionButtonIcon}>{iconSymbol}</span>}
    </Button>
  </Tooltip>
);

export default ActionButton;
