import { TLearningCenterCategoryId } from '@web/types';
import {
  AI_ASSISTANT_LEARNING_CENTER_NAVIGATION,
  AI_STUDIO_LEARNING_CENTER_NAVIGATION,
} from 'constants/LearningCenter';
import { ILearningCenterVideo } from 'constants/analytics';

import config from './dynamicConfig';

const isWriterAcademyAvailable =
  !config.SINGLE_TENANCY_CONFIG || config.SINGLE_TENANCY_CONFIG?.disableWriterAcademy === false;

export const getLearningCenterCategories = () => {
  return AI_ASSISTANT_LEARNING_CENTER_NAVIGATION.filter(category => {
    if (category.id === TLearningCenterCategoryId.enum.writeracademy) {
      return isWriterAcademyAvailable;
    }

    return true;
  });
};

export const getAiAssistantCategoryIdFromPath = (path: string): typeof TLearningCenterCategoryId.type => {
  const pathSection = path.split('/').pop();
  const category = AI_ASSISTANT_LEARNING_CENTER_NAVIGATION.find(
    category => !category.link && category.id === pathSection,
  );


  if (
    !pathSection ||
    !category ||
    (category.id === TLearningCenterCategoryId.enum.writeracademy && !isWriterAcademyAvailable)
  ) {
    return TLearningCenterCategoryId.enum.videos;
  }

  return category.id;
};

export const getAiStudioCategoryIdFromPath = (path: string): typeof TLearningCenterCategoryId.type => {
  const pathSection = path.split('/').pop();
  const category = AI_STUDIO_LEARNING_CENTER_NAVIGATION.find(category => category.id === pathSection);

  if (
    !pathSection ||
    !category ||
    (category.id === TLearningCenterCategoryId.enum.writeracademy && !isWriterAcademyAvailable)
  ) {
    return TLearningCenterCategoryId.enum.docs;
  }

  return category.id;
};

export const mapVideosAsViewed = (videos: ILearningCenterVideo[], viewedVideosIds: number[]): ILearningCenterVideo[] =>
  videos.map(video => ({ ...video, viewed: viewedVideosIds.includes(video.id) }));
