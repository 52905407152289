import type { SubscriptionFeatureType, TBaseApplicationType } from '@writercolab/common-utils';
import { IconVariant } from '@writercolab/ui-atoms';

import { APP_DECORATION } from '@web/types';

export const getAppIcon = (appId: string): IconVariant | null => {
  const decorator = APP_DECORATION.find(decorator => decorator.id === appId);

  return decorator ? decorator.icon : null;
};

export const isAppFeatureFlagDisabled = (
  appId: string,
  subscriptionAccess: Record<SubscriptionFeatureType, boolean> | undefined,
  appsWithFeatureFlags: { id: string; key: string }[],
): boolean => {
  const featureFlaggedApp = appsWithFeatureFlags.find(app => app.id === appId);

  return subscriptionAccess && featureFlaggedApp ? !subscriptionAccess[featureFlaggedApp.key] : false;
};

export const getIconByAppType = (appType: TBaseApplicationType, icon?: string | null): IconVariant | null => {
  if (!icon) {
    if (appType === 'framework') return IconVariant.FRAMEWORK_APP_MONO;

    return IconVariant.CONSOLE_APP_LONG_FORM;
  }

  return getAppIcon(icon);
};
