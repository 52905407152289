import { InputType } from '@writercolab/common-utils';
import { type DropdownOption } from '@writercolab/ui-atoms';

import type { IDraft, IDraftInput, IDraftMedia, TDraftInput } from '@web/types';
import { BLOG_BUILDER_APP_ID, MEDIA_DROPDOWN_ACTION_ID, RECAPS_APP_ID } from '@web/types';

export const getInputType = (inputs: TDraftInput[], name: string): string => {
  const input = inputs.find(input => input.name === name);

  return input?.type === InputType.DROPDOWN ? (InputType.DROPDOWN as string) : 'text';
};

export const transformSeoBlogInputs = (inputs: IDraftInput[]): IDraftInput[] => {
  const inputsMap = Object.keys(inputs).map(key => {
    const input = inputs[key];

    if (Array.isArray(input)) {
      return input.map((item, i) => ({
        name: item?.outline || `outline_${i}`,
        value: item?.points || '',
      }));
    }

    return {
      name: key,
      value: Array.isArray(input) ? input : [input],
    };
  });

  const flattenMap = inputsMap.flat();

  return flattenMap as IDraftInput[];
};

export const getMediaFileIdFromInputs = (inputs: IDraftInput[]): IDraftInput | null => {
  const fileId = inputs.find(input => input.name === 'fileId');

  return fileId ?? null;
};

export const mapMediaFileDetailsToInputs = (fileId: string, filename: string, url?: string | null): IDraftMedia => ({
  fileId,
  filename,
  ...(url && { url }),
});

export const downloadAs = (data: Blob, filename: string): void => {
  const anchor = document.createElement('a');
  anchor.href = URL.createObjectURL(data);
  anchor.download = filename;
  anchor.click();
  anchor.remove();
};

export const getEventClipUrl = (
  playbackId: string,
  maxResolution: string,
  filename: string,
  isDownload: boolean = false,
): string => {
  const resolution = maxResolution === 'HD' ? 'high' : 'low';

  return `https://stream.mux.com/${playbackId}/${resolution}.mp4${isDownload ? `?download=${filename}` : ''} `;
};

export const getEventClipsFromInputs = (inputs: IDraftInput[]): string[] | undefined =>
  inputs.find(input => input.name === 'eventClips')?.value;

export const isRecapsDraft = (draft: IDraft): boolean => draft.template.id === RECAPS_APP_ID;
export const isBlogBuilderDraft = (draft: IDraft): boolean => draft.template.id === BLOG_BUILDER_APP_ID;

export const filterMediaDropdownOptions = (media: IDraftMedia, options: DropdownOption[]): DropdownOption[] => {
  if (media.url) {
    return options.filter(o => o.id === MEDIA_DROPDOWN_ACTION_ID.OPEN_YOUTUBE);
  } else {
    return options.filter(o => o.id === MEDIA_DROPDOWN_ACTION_ID.DOWNLOAD_FILE);
  }
};
