import type { ReactNode } from 'react';
import React from 'react';

import { Heading, HeadingVariant, Text } from '@writercolab/ui-atoms';
import { generateRandomString } from '@writercolab/utils';

import styles from './GlobalErrorBoundary.module.css';

interface GlobalErrorBoundaryProps {
  fallback?: (hash: string) => ReactNode;
  children: ReactNode;
  showHash?: boolean;
  onCatch?: (details: { name: string; error: Error; info: React.ErrorInfo; hash: string }) => void;
}

interface GlobalErrorBoundaryState {
  hasError: boolean;
  hash?: string;
  error?: Error;
  info?: React.ErrorInfo;
}

export class GlobalErrorBoundary extends React.Component<GlobalErrorBoundaryProps, GlobalErrorBoundaryState> {
  constructor(props: GlobalErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): GlobalErrorBoundaryState {
    const hash = generateRandomString();

    return { hasError: true, hash };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.props.onCatch?.({
      name: 'ReactRenderError',
      error,
      info,
      hash: this.state.hash || '',
    });

    this.setState({ error, info });
  }

  render(): ReactNode {
    if (this.state.hasError && this.props.showHash) {
      return <div className={styles.container}>{this.props?.fallback?.(this.state.hash || '')}</div>;
    } else if (this.state.hasError) {
      return (
        <div className={styles.stackTraceContainer}>
          <Heading className={styles.heading} variant={HeadingVariant.H3}>
            {this.state.error?.toString()}
          </Heading>
          <Text className={styles.stackTrace}>{this.state.info?.componentStack}</Text>
        </div>
      );
    }

    return this.props.children;
  }
}
