import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import { reaction, when } from 'mobx';
import type { Sources } from 'quill';

import type { IBaseSidebarConfig, ICollaborationUser, IIssue } from '@writercolab/common-utils';
import {
  BillingProduct,
  ContentEditorPageMode,
  IssueCardType,
  IssueFlag,
  NUMBER_OF_WORDS_FOR_AUTO_WRITE,
  TAssetToDelete,
  cleanFromNewLines,
  openNewTab,
  removeUnicodeSymbols,
  reportDocumentFragment,
  reportSnippetApply,
  saveDocumentContent,
  saveDocumentFragment,
  saveDocumentScore,
} from '@writercolab/common-utils';
import { getDeletionBannerInfo } from '@writercolab/data-retention-utils';
import type { TRetentionConfig } from '@writercolab/models';
import {
  QA_TEXTHIGHLIGHT_FORMAT_NAME,
  calculateChecksumOverDelta,
  hasInsertOrDelete,
  isJustOneInsert,
  normalizeAndCleanDelta,
} from '@writercolab/quill-delta-utils';
import { Heading, HeadingVariant, IconVariant, MuiCircularProgress, useCustomSnackbar } from '@writercolab/ui-atoms';
import type { CommandsModel } from '@writercolab/ui-commands';
import type { ITimelineEvent } from '@writercolab/ui-molecules';
import { CollaborationPanel, Unreachable, UnreachableType } from '@writercolab/ui-molecules';
import type { UISidebarModel } from '@writercolab/ui-sidebar';
import { Sidebar } from '@writercolab/ui-sidebar';
import type { SidebarModel } from '@writercolab/ui-sidebar-models';
import { isDocumentIsValid } from '@writercolab/utils';

import { FloatingToolbar } from 'components/molecules/FloatingToolbar';
import { VersionHistory } from 'components/organisms/VersionHistory';
import type { VersionHistoryUIModel } from 'components/organisms/VersionHistory/VersionHistoryModel.ui';

import { snackbarMessages, useBrowserReload } from '@web/component-library';
import type { IDocumentInfoPanelButtonState } from '@web/types';
import type { AutoWriteModule, IEditorForwardRef } from '@web/web-quill-editor';
import { EditorPure } from '@web/web-quill-editor';
import { AnalyticsActivity } from 'constants/analytics';
import { format } from 'date-fns';
import debounce from 'lodash/debounce';
import { observer } from 'mobx-react-lite';
import Delta from 'quill-delta';
import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';

import { useDocumentsContext } from '../../../context/documentsContext';
import { EMPTY_DOCUMENT_PLACEHOLDER } from '../../../services/config/constants';
import { ROUTE } from '../../../services/config/routes';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { getLogger } from '../../../utils/logger';
import { goToBillingNewTab, openContactSalesPage } from '../../../utils/navigationUtils';
import { processLlmOutputBeforeInsert } from '../../../utils/textUtils';
import { DocumentInfoPanel } from '../../molecules/DocumentInfoPanel';
import type { ContentEditorModel } from './ContentEditorModel';

import styles from './styles.module.css';

const LOG = getLogger('ContentEditor');

interface IContentEditorBaseConfig {
  organizationId: string;
  workspaceId: string;
  personaId: string;
  documentId: string;
}

interface IContentEditorProps {
  sidebarModel: SidebarModel;
  uiSidebarModel: UISidebarModel;
  commandsModel: CommandsModel;
  contentEditorModel: ContentEditorModel;
  baseConfig: IContentEditorBaseConfig;
  hideShareDocumentOption?: boolean;
  highlightsLoading: boolean;
  magicLinksLoading: boolean;
  focusEditorOnInit: boolean;
  claimsLocked: boolean;
  claimDetectionLoading: boolean;
  isLeftSidebarOpen: boolean;
  onOpenSidebar: () => void;
  onRefreshClaim: () => Promise<void>;
  onRefreshScore: () => Promise<void>;
  onClickHighlights: () => void;
  onClickClaimDetection: () => void;
  onClickMagicLinks: () => void;
  onCoWriteLibraryClick: () => void;
  onMagicIconClick: (amount: number, text: string, doSearch?: boolean) => void;
  onContentChange: (content: string) => void;
  contentEditorPageMode: ContentEditorPageMode;
  documentInfoPanelCoWriteButtonState: IDocumentInfoPanelButtonState;
  dataRetentionPreferences?: TRetentionConfig | null;
  versionHistoryModel: VersionHistoryUIModel;
}

export interface IContentEditorRef {
  addContentToEditor: (body: string, replaceAll?: boolean) => void;
  updateEditorTitle: (title: string) => void;
  isEditorLoaded: () => boolean;
  replaceEditorContentIfEmpty: (body: string, title: string) => void;
  refreshEditorContent: () => void;
  isEditorContentEmpty: () => boolean;
  getEditorContent: () => Delta;
  addMagicLinkUrl: (link: string) => void;
  updateSearchedLinksText: (text: string) => void;
  updateAmountOfDomains: (amount: number) => void;
}

const handleDataRetentionLearnMoreClick = () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL);

export const ContentEditor = observer(
  React.forwardRef<IContentEditorRef, IContentEditorProps>(
    (
      {
        sidebarModel,
        uiSidebarModel,
        commandsModel,
        baseConfig,
        hideShareDocumentOption,
        focusEditorOnInit,
        highlightsLoading,
        claimDetectionLoading,
        claimsLocked,
        onOpenSidebar,
        onRefreshScore,
        onRefreshClaim,
        onClickHighlights,
        onClickMagicLinks,
        onCoWriteLibraryClick,
        onClickClaimDetection,
        onContentChange,
        contentEditorPageMode,
        magicLinksLoading,
        documentInfoPanelCoWriteButtonState,
        onMagicIconClick,
        isLeftSidebarOpen,
        contentEditorModel,
        dataRetentionPreferences,
        versionHistoryModel,
      },
      contentEditorRef,
    ) => {
      const isClaimsMode = !claimsLocked && contentEditorPageMode === ContentEditorPageMode.CLAIMS_DETECTION;
      const isMagicLinksMode = contentEditorPageMode === ContentEditorPageMode.MAGIC_LINKS;
      const editorRef = useRef<IEditorForwardRef>(null);
      const { appState, appModel } = useAppState();
      const { wasReloaded, cleanUpReloadMarker } = useBrowserReload();
      const saveDocumentInProgress = useRef(false);
      const checkClaimsOnNextSave = useRef(false);
      const originalDocumentContent = useRef<Delta>();

      const periodicalCollaborationInterval = useRef<NodeJS.Timeout>();
      const [documentIsLocked, setDocumentIsLocked] = useState(false);
      const [editorIsLoading, setEditorIsLoading] = useState(true);
      const [lockedAnimation, setLockedAnimation] = useState(false);
      const [autoWriteLoading, setAutoWriteLoading] = useState(false);
      const [versionHistoryOpen, setVersionHistoryOpen] = useState(false);

      const [magicLinkUrl, setMagicLinkUrl] = useState('');
      const [magicLinksSearchText, setMagicLinksSearchText] = useState('');
      const [amountOfDomains, setAmountOfDomains] = useState(0);

      const currentContentWords = sidebarModel.documentStats.contentMetrics?.wordCount || 0;
      const showVisibleIssues = !editorIsLoading && !isMagicLinksMode && !isLeftSidebarOpen && !versionHistoryOpen;

      const { assistantSubscription, analyticsService } = appModel;

      const { organizationId, workspaceId, personaId, documentId } = baseConfig;

      const { currentDocData, currentDocLoading, handleChangeIsEditorReady, updateDocumentTitle, downloadAsDocx } =
        useDocumentsContext();

      const {
        enqueueAlertSnackbar,
        closeSnackbar,
        enqueueCustomSnackbar,
        enqueueBasicSnackbar,
        enqueueSuccessSnackbar,
      } = useCustomSnackbar();

      contentEditorModel.useContentEditor(editorRef.current, isClaimsMode, onOpenSidebar);

      useImperativeHandle(contentEditorRef, () => ({
        addContentToEditor: (body: string, replaceAll = false) => {
          const editor = getEditor();

          if (editor) {
            if (!body || body === null || body === 'null') {
              LOG.error('Empty body received as argument. Aborting addContentToEditor.');

              return;
            }

            const content = processLlmOutputBeforeInsert(body);

            if (replaceAll) {
              editor.deleteText(0, editor.getLength(), 'user');
              editor.clipboard.dangerouslyPasteHTML(0, content, 'user');
            } else {
              editor.clipboard.dangerouslyPasteHTML(editor.getLength(), content, 'user');
            }

            scheduleClaimsOnNextSave();
          }
        },
        isEditorLoaded: () => getEditor() !== undefined,
        isEditorContentEmpty: () => {
          const editor = getEditor();

          if (editor) {
            return editor.getLength() <= 1;
          }

          return true;
        },
        updateEditorTitle: (title: string) => {
          const editor = getEditor();

          // Update title only if it's empty
          if (editor && !currentDocData?.title) {
            reportDocumentUpdated.cancel();
            updateDocumentTitle(title);
          }
        },
        replaceEditorContentIfEmpty: (body: string, title: string) => {
          const editor = getEditor();

          if (editor) {
            const editorLength = editor.getLength();

            if (editorLength <= 1) {
              const content = processLlmOutputBeforeInsert(body);
              editor.clipboard.dangerouslyPasteHTML(0, content, 'user');
              reportDocumentUpdated.cancel();

              if (title) {
                updateDocumentTitle(title);
              }
            }
          }
        },
        getEditorContent: () => {
          const editor = getEditor();

          if (editor) {
            return editor.getContents();
          }

          return new Delta();
        },
        addMagicLinkUrl: (link: string) => setMagicLinkUrl(link),
        updateSearchedLinksText: (text: string) => setMagicLinksSearchText(text),
        updateAmountOfDomains: (amount: number) => setAmountOfDomains(amount),
        refreshEditorContent: async () => {
          const delta = await sidebarModel.dataModel.api?.getDocumentDelta?.();
          const editor = getEditor();

          if (editor && delta) {
            editor.setContents(delta, 'silent');
          }
        },
      }));

      const access = {
        hideDeleteDocOption: currentDocData?.createdUserId !== appState.userProfile?.id,
        showError404: !currentDocData && !currentDocLoading && isDocumentIsValid(documentId),
        hideShareDocumentOption,
      };

      const currentUser: Omit<ICollaborationUser, 'sessionId'> | undefined = useMemo(
        () =>
          appState.userProfile
            ? {
                id: appState.userProfile.id.toString(),
                avatar: appState.userProfile.avatar,
                fullName: appState.userProfile.fullName,
                email: appState.userProfile.email,
              }
            : undefined,
        [appState.userProfile],
      );

      const { shouldShowDataDeletionBanner, readableDeletionDate } = getDeletionBannerInfo(
        currentDocData?.creationTime,
        TAssetToDelete.enum.Doc,
        dataRetentionPreferences,
      );

      const onFlagSuggestionInline = useCallback(
        (issue: IIssue, flagType: IssueFlag, comment?: string | null) => {
          if (flagType === IssueFlag.WRONG) {
            return sidebarModel.issues?.onMarkIssueAsWrong(issue, IssueCardType.INLINE, comment);
          } else {
            return sidebarModel.issues?.onDeleteIssueClick(issue, IssueCardType.INLINE);
          }
        },
        [sidebarModel.issues],
      );

      const onApplySuggestionInline = (replacement: string, issue: IIssue) =>
        sidebarModel.issues?.onApplySuggestion(replacement, issue, IssueCardType.INLINE);

      const isAutoWriteAvailable =
        !assistantSubscription.isFree &&
        !assistantSubscription.limits?.coWrite?.exceeded &&
        currentContentWords >= NUMBER_OF_WORDS_FOR_AUTO_WRITE;

      const showLimitExceededSnackbar = useCallback(
        () =>
          enqueueCustomSnackbar(`You're out of words for this month! Upgrade to Enterprise to get more.`, {
            icon: IconVariant.LIGHTNING_UNION,
            iconClass: styles.lightningWarnIcon,
          }),
        [enqueueCustomSnackbar],
      );

      const showContentGenerationErrorSnackbar = useCallback(
        (error: string = `We weren't able to generate content. Please try again.`) =>
          enqueueCustomSnackbar(error, {
            icon: IconVariant.LIGHTNING_UNION,
            iconClass: styles.lightningWarnIcon,
          }),
        [enqueueCustomSnackbar],
      );

      const scheduleClaimsOnNextSave = () => {
        checkClaimsOnNextSave.current = true;
      };

      const openVersionHistory = () => {
        setVersionHistoryOpen(true);
        originalDocumentContent.current = normalizeAndCleanDelta(new Delta(getEditor()?.getContents()));
      };

      const closeVersionHistory = async (hasRestored = false) => {
        setVersionHistoryOpen(false);

        if (versionHistoryModel.selectedVersionId) {
          versionHistoryModel.onClearSelectedVersion();
          const editor = getEditor();

          if (!originalDocumentContent.current || !editor) {
            return;
          }

          if (!hasRestored) {
            editor.setContents(originalDocumentContent.current, 'silent');
          } else {
            setEditorIsLoading(true);
            editor.history.record(
              originalDocumentContent.current.diff(editor.getContents()),
              originalDocumentContent.current,
            );
            await saveFullDocumentContent();
            setEditorIsLoading(false);
          }

          originalDocumentContent.current = undefined;
        }
      };

      const onPreviewVersion = async (version: ITimelineEvent) => {
        const editor = getEditor();

        if (!editor) {
          return;
        }

        setEditorIsLoading(true);

        try {
          const delta = await versionHistoryModel.onSelectVersion(version);

          if (!delta && versionHistoryModel.isCurrentVersion(version)) {
            originalDocumentContent.current && editor.setContents(originalDocumentContent.current, 'silent');

            return;
          }

          if (delta) {
            editor.setContents(delta as Delta, 'silent');
          }
        } finally {
          setEditorIsLoading(false);
        }
      };

      const onRestoreVersion = async () => {
        await closeVersionHistory(true);
        enqueueCustomSnackbar('Your previous version was restored', {
          icon: IconVariant.HISTORY,
          iconClass: styles.historyIcon,
        });
      };

      const onClickAutoWrite = useCallback(
        async (triggeredFromShortcut: boolean, callback?: () => void) => {
          if (assistantSubscription.limits?.coWrite?.exceeded) {
            showLimitExceededSnackbar();
          }

          if (!isAutoWriteAvailable) {
            return;
          }

          if (autoWriteLoading) {
            enqueueCustomSnackbar(`Hang on! We're still generating content for you.`, {
              icon: IconVariant.LIGHTNING_UNION,
              iconClass: styles.lightningWarnIcon,
            });

            return;
          }

          const editor = getEditor();

          if (!editor) {
            return;
          }

          appModel.analyticsService.track(AnalyticsActivity.triggerAutoWrite, {
            trigger_type: triggeredFromShortcut ? 'keyboard' : 'button',
          });

          const autowriteModule = editor.getModule('autowrite') as AutoWriteModule;

          setAutoWriteLoading(true);

          try {
            const { hasContent } = await autowriteModule.keepWriting(Boolean(callback));

            if (!hasContent) {
              showContentGenerationErrorSnackbar();
            } else {
              scheduleClaimsOnNextSave();
            }
          } catch {
            showLimitExceededSnackbar();
          } finally {
            setAutoWriteLoading(false);
            callback?.();
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          assistantSubscription.limits?.coWrite?.exceeded,
          isAutoWriteAvailable,
          autoWriteLoading,
          sidebarModel.analytics,
          documentId,
          organizationId,
          workspaceId,
          showLimitExceededSnackbar,
          onRefreshClaim,
        ],
      );

      const onShareVideoClick = useCallback(
        () => enqueueBasicSnackbar('Share link copied to clipboard'),
        [enqueueBasicSnackbar],
      );

      useEffect(
        () =>
          reaction(
            () => sidebarModel.documentStats.score,
            ({ value }, { value: oldValue, available }) => {
              if (available && value !== oldValue) {
                saveDocumentScore(organizationId, workspaceId, documentId, value).catch(() => {
                  LOG.error('There was problem while score save.');
                });
              }
            },
          ),
        [documentId, sidebarModel, organizationId, workspaceId],
      );

      // we need this instead of getWords from common-utils
      // since we need to include numbers that are being ignored by getWords
      const getTextWords = (text: string) => {
        const textWihtoutUnicode = removeUnicodeSymbols(text);
        const textCleanNewLines = cleanFromNewLines(textWihtoutUnicode);

        const words = textCleanNewLines.split(' ');

        return words;
      };

      const generateDocumentTitle = useCallback(() => {
        const editor = getEditor();
        const WORDS_FOR_TITLE = 5;

        if (!editor) {
          return '';
        }

        const words = getTextWords(editor.getText({ index: 0, length: 100 }));

        if (words.length < WORDS_FOR_TITLE) {
          return '';
        }

        const title = words.slice(0, WORDS_FOR_TITLE).join(' ');

        return title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const reportDocumentUpdated = useCallback(
        debounce(() => {
          if (!currentDocData) {
            return;
          }

          const shouldGenerateTitle = !currentDocData.title || currentDocData.title === EMPTY_DOCUMENT_PLACEHOLDER;
          let title = shouldGenerateTitle ? generateDocumentTitle() : currentDocData.title;

          title = title.replace(/\n/g, ' '); // replace new lines with spaces

          updateDocumentTitle(title);
        }, 2000),
        [currentDocData?.title, updateDocumentTitle, generateDocumentTitle],
      );

      const onNetworkConnectionError = useCallback(() => {
        enqueueAlertSnackbar(
          'Network connection issue',
          "There's a network issue with connecting to docs. Check your network or VPN settings.",
        );
      }, [enqueueAlertSnackbar]);

      const onContentSaveError = useCallback(() => {
        enqueueAlertSnackbar(
          'Writer can’t connect to this doc',
          'Any changes you make won’t be saved. Refresh this page to reconnect.',
        );
      }, [enqueueAlertSnackbar]);

      const onContentSaveSuccess = useCallback(() => {
        closeSnackbar();
        enqueueAlertSnackbar('We’ve reconnected with this doc', 'Your changes are now being saved.', false, {
          persist: false,
        });
      }, [closeSnackbar, enqueueAlertSnackbar]);

      const getEditor = useCallback(() => editorRef.current?.quillRef.current, []);

      const requestDocumentContent = useCallback(
        () =>
          sidebarModel.dataModel.api
            ?.getDocumentDelta?.()
            .then(delta => {
              const editor = getEditor();

              if (delta && editor) {
                editor.setContents(delta, 'silent');
                const currentEditorContent = editor.getContents();
                const deltaDiff = delta.diff(currentEditorContent);
                const content = editor.getText(0);
                onContentChange(content);

                if (deltaDiff.ops?.length) {
                  contentEditorModel.setDeltaBuffer(deltaDiff);
                }
              }
            })
            .then(() => sidebarModel.collaboration.setCollaborationLock(currentUser))
            .then(() => {
              focusEditorOnInit && getEditor()?.focus();
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sidebarModel],
      );

      useEffect(
        () =>
          reaction(
            () => sidebarModel.collaboration.documentIsLocked,
            async (lockedNow, wasLocked) => {
              if (!lockedNow && wasLocked) {
                // request new document content and update current highlights when document lock is released
                try {
                  await requestDocumentContent();
                } catch (e) {
                  LOG.error(e);
                  onNetworkConnectionError();
                }
                editorRef.current && editorRef.current?.refreshCurrentHighlights();
              }

              setDocumentIsLocked(lockedNow);
            },
          ),
        [requestDocumentContent, sidebarModel, onNetworkConnectionError],
      );

      useEffect(() => {
        setEditorIsLoading(true);

        const maxAttempts = 3;
        let numAttempts = 0;

        const fetchData = async () => {
          try {
            if (!getEditor()) {
              throw new Error('Editor is not loaded yet.');
            }

            await requestDocumentContent();
            setEditorIsLoading(false);
            handleChangeIsEditorReady(true);

            // if page was reloaded - refresh the issues list and clean up the reload marker
            if (wasReloaded) {
              await onRefreshScore();
              cleanUpReloadMarker();
            }
          } catch (e) {
            if (numAttempts < maxAttempts) {
              numAttempts++;
              setTimeout(fetchData, 1000); // wait for 1 second before retrying
            } else {
              LOG.error(e);
              onNetworkConnectionError();
            }
          }
        };

        return when(() => !!sidebarModel.dataModel.api?.getDocumentDelta, fetchData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [requestDocumentContent, onRefreshScore]);

      const onDocumentChange = useCallback(async () => {
        if (saveDocumentInProgress.current) {
          LOG.info('Save in progress. Try next time...');
          onDocumentChangeDebounced();

          return;
        }

        const editor = getEditor();

        if (contentEditorModel.deltaBufferIsEmpty || !editor) {
          return;
        }

        saveDocumentInProgress.current = true;
        reportFragmentSaveDebounced.cancel();

        const delta = contentEditorModel.deltaBuffer;
        contentEditorModel.setDeltaBuffer(new Delta());

        LOG.info('Attempt to save delta.');

        const hash = calculateChecksumOverDelta(editor.getContents());

        try {
          const response = await saveDocumentFragment(
            organizationId,
            workspaceId,
            documentId,
            personaId,
            normalizeAndCleanDelta(delta!),
          );
          LOG.info('Successfull delta save.');

          if (response.checksum !== hash) {
            LOG.error('SHA ERROR', response.checksum);
            await saveFullDocumentContent();
          } else {
            LOG.info('SHA MATCH');
            reportFragmentSaveDebounced();
          }

          if (checkClaimsOnNextSave.current === true) {
            await onRefreshClaim();
            checkClaimsOnNextSave.current = false;
          }
        } catch (e) {
          LOG.error(e, 'Error while delta save. Trying to recover by saving the whole content.');

          onContentSaveError();
          saveFullDocumentContent();
          onContentSaveSuccess();

          LOG.info('Successfully recovered failed Delta request.');
        } finally {
          saveDocumentInProgress.current = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [onRefreshClaim, organizationId, workspaceId, documentId, personaId]);

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const onDocumentChangeDebounced = useCallback(debounce(onDocumentChange, 1000), [onDocumentChange]);

      // for exceptional case when we either have a save error or
      // the content checksum doesn't match current snapshot
      const saveFullDocumentContent = async () => {
        const editor = getEditor();

        if (!editor) {
          return;
        }

        saveDocumentInProgress.current = true;
        const currentContent = normalizeAndCleanDelta(editor.getContents());
        contentEditorModel.setDeltaBuffer(new Delta());

        try {
          await saveDocumentContent(organizationId, workspaceId, documentId, personaId, currentContent);
        } finally {
          saveDocumentInProgress.current = false;
        }
      };

      // TODO if its possible need move to model
      const checkIfIssueWasEdited = useCallback(
        (delta: Delta) => {
          const editor = getEditor();

          if (hasInsertOrDelete(delta) && editor) {
            const range = editor.getSelection();

            if (!range) {
              return;
            }

            const format = editor.getFormat(range);

            if (typeof format[QA_TEXTHIGHLIGHT_FORMAT_NAME] === 'string') {
              const highlightIds = (format[QA_TEXTHIGHLIGHT_FORMAT_NAME] as string).split(',');
              const issuesToInvalidate = highlightIds.map(contentEditorModel.findIssueById).filter(Boolean) as IIssue[];
              sidebarModel.issues?.markIssuesAsDeleted(issuesToInvalidate);
            }
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sidebarModel.issues?.markIssuesAsDeleted, contentEditorModel.findIssueById],
      );

      // eslint-disable-next-line react-hooks/exhaustive-deps
      const reportFragmentSaveDebounced = useCallback(
        debounce(() => {
          LOG.info('reportDocumentFragment called.');
          reportDocumentFragment(organizationId, workspaceId, documentId, personaId);
        }, 2000),
        [organizationId, workspaceId, documentId, personaId],
      );

      const handleCopyDocumentLink = useCallback(
        () => enqueueSuccessSnackbar(snackbarMessages.docs.copyDocumentLink),
        [enqueueSuccessSnackbar],
      );

      const onTextChange = useCallback(
        (content: string, delta: Delta, source: Sources) => {
          if (source === 'user') {
            const normalizedDelta = new Delta().compose(normalizeAndCleanDelta(delta));

            if (normalizedDelta.length() === 0) {
              return;
            }

            contentEditorModel.setDeltaBuffer(contentEditorModel.deltaBuffer?.compose(delta));
            sidebarModel.collaboration.setCollaborationLock(currentUser, true);
            reportDocumentUpdated();
            onContentChange(content);
            checkIfIssueWasEdited(delta);
            onDocumentChangeDebounced();
            reportFragmentSaveDebounced.cancel();

            if (isJustOneInsert(delta)) {
              const { insert } = delta.ops!.find(op => op.insert)!;

              // check if this change is insert and has an indicator of sentence\word ending (whitespace, exc. mark etc.)
              if (typeof insert === 'string' && insert.match(/[\s.!?\\-]/g) && !saveDocumentInProgress.current) {
                onDocumentChange();
                onDocumentChangeDebounced.cancel();
              }
            }
          }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
          sidebarModel,
          currentUser,
          checkIfIssueWasEdited,
          reportDocumentUpdated,
          onDocumentChange,
          onContentChange,
          onDocumentChangeDebounced,
          reportFragmentSaveDebounced,
        ],
      );

      useEffect(() => {
        clearInterval(periodicalCollaborationInterval.current);
        periodicalCollaborationInterval.current = setInterval(
          () => sidebarModel.collaboration.setCollaborationLock(currentUser),
          2000,
        );

        return () => clearInterval(periodicalCollaborationInterval.current);
      }, [currentUser, sidebarModel]);

      const onSearchSnippet = useCallback(
        async (shortcuts: string[]) =>
          requestService.api
            .PUT('/api/terminology/organization/{organizationId}/team/{teamId}/snippet/shortcut/get', {
              params: {
                path: {
                  organizationId: +organizationId,
                  teamId: +workspaceId,
                },
              },
              body: {
                shortcuts,
              },
            })
            .then(response => response.data),
        [organizationId, workspaceId],
      );

      const onApplySnippet = useCallback(
        (id: string) => reportSnippetApply(organizationId, workspaceId, id),
        [organizationId, workspaceId],
      );

      const onDocumentDownload = useCallback(async () => {
        const editor = getEditor();

        if (!editor) {
          enqueueAlertSnackbar(
            'Problem with file download.',
            "There's a problem with you file download. Please try again.",
          );

          return;
        }

        downloadAsDocx(editor.getContents());
      }, [downloadAsDocx, enqueueAlertSnackbar, getEditor]);

      if (access.showError404) {
        return (
          <div className={styles.noDocument}>
            <Unreachable type={UnreachableType.enum.error404docs} dashboardRouteHref={ROUTE.toHome(organizationId)} />
          </div>
        );
      }

      return currentDocData ? (
        <div className={styles.editorMainContainer}>
          {versionHistoryOpen && (
            <VersionHistory
              model={versionHistoryModel}
              onClose={() => closeVersionHistory(false)}
              onSelectVersion={onPreviewVersion}
            />
          )}
          <div className={styles.editorWrapper}>
            {versionHistoryOpen ? (
              <Heading className={styles.docTitlePreview} variant={HeadingVariant.H2} medium>
                {currentDocData.title}
              </Heading>
            ) : (
              <DocumentInfoPanel
                currentContentWords={currentContentWords}
                hideDeleteDocOption={access.hideDeleteDocOption}
                hideShareDocumentOption={access.hideShareDocumentOption}
                claimDetectionLoading={claimDetectionLoading}
                claimsIsOn={isClaimsMode}
                onClickClaimDetection={onClickClaimDetection}
                onDocumentDownload={onDocumentDownload}
                onRefreshClaim={onRefreshClaim}
                claimsCount={contentEditorModel.claimsCount}
                onClickHighlights={onClickHighlights}
                onClickAutoWrite={() => onClickAutoWrite(false)}
                onClickMagicLinks={onClickMagicLinks}
                onCoWriteLibraryClick={onCoWriteLibraryClick}
                contentEditorPageMode={contentEditorPageMode}
                highlightsLoading={highlightsLoading}
                autoWriteDisabled={!isAutoWriteAvailable}
                autoWriteLoading={autoWriteLoading}
                documentInfoPanelCoWriteButtonState={documentInfoPanelCoWriteButtonState}
                magicLinksLoading={magicLinksLoading}
                onOpenVersionHistory={openVersionHistory}
                onCopyDocumentLink={handleCopyDocumentLink}
              />
            )}
            {assistantSubscription.isModelReady && assistantSubscription.limits?.isLoaded && sidebarModel.issues && (
              <EditorPure
                ref={editorRef}
                baseConfig={baseConfig as IBaseSidebarConfig}
                analyticsService={analyticsService as any} //
                requestService={requestService.api}
                readOnly={documentIsLocked || versionHistoryOpen}
                sidebarModel={sidebarModel}
                commandsModel={commandsModel}
                onTextChange={onTextChange}
                visibleIssues={showVisibleIssues ? contentEditorModel.visibleIssues : []}
                currentIssueId={sidebarModel.issues.selectedIssueContext?.issueId || ''}
                onClaimResolve={sidebarModel.issues.onClaimResolve}
                onClaimDecline={sidebarModel.issues.onClaimDecline}
                onApplySuggestion={onApplySuggestionInline}
                onFlagSuggestion={onFlagSuggestionInline}
                onApplySnippet={onApplySnippet}
                onSearchSnippet={onSearchSnippet}
                onCmdEnterClick={onClickAutoWrite}
                onShareVideoClick={onShareVideoClick}
                onCommandsError={showContentGenerationErrorSnackbar}
                onCommandsSuccess={(message: string) => enqueueSuccessSnackbar(message)}
                canWriteToTerms={false}
                isMagicLinksMode={isMagicLinksMode}
                onMagicIconClick={onMagicIconClick}
                magicLinkUrl={magicLinkUrl}
                magicLinksSearchText={magicLinksSearchText}
                amountOfDomains={amountOfDomains}
                isRewriteEnabled={!!assistantSubscription.access?.reWrite}
                isLimitReached={!!assistantSubscription.limits?.coWrite?.exceeded}
                limit={assistantSubscription.limits?.coWrite?.limit || 0}
                onLimitReached={showLimitExceededSnackbar}
                isVoiceEnabled={!!assistantSubscription.access?.voice}
                onContactSales={openContactSalesPage}
                isCommandsEnabled={!!assistantSubscription.access?.autoWriteCommands}
                onUpgradeToTeam={() => goToBillingNewTab(Number(appState.organizationId), BillingProduct.TEAM)}
                isAskKnowledgeGraphEnabled={!!assistantSubscription.access?.knowledgeGraph}
                isKeepWritingEnabled={!!assistantSubscription.access?.autoWriteButton}
                isFree={assistantSubscription.isFree}
                shouldShowDataDeletionBanner={shouldShowDataDeletionBanner}
                readableDeletionDate={readableDeletionDate}
                handleDataRetentionLearnMoreClick={handleDataRetentionLearnMoreClick}
                isToolbarHidden={versionHistoryOpen}
              />
            )}
            <CollaborationPanel
              className={styles.collaborationPanel}
              currentUserId={currentUser?.id}
              viewers={sidebarModel.collaboration.viewers}
            />
            {editorIsLoading && (
              <div className={styles.loadingOverlay}>
                <MuiCircularProgress className={styles.circularLoader} disableShrink />
              </div>
            )}
            {versionHistoryModel.selectedVersion &&
              !versionHistoryModel.isCurrentVersion(versionHistoryModel.selectedVersion) && (
                <FloatingToolbar
                  className={styles.versionHistoryToolbar}
                  buttonText="Restore"
                  title={`Viewing ${format(new Date(versionHistoryModel.selectedVersion.date), 'MMMM d, yyyy, p')}`}
                  subtitle="If you restore this version, you’ll overwrite your current doc"
                  isLoading={false}
                  onClick={onRestoreVersion}
                />
              )}
          </div>
          {!versionHistoryOpen && (
            <div
              className={cx(styles.sidebarWrapper, {
                [styles.sidebarMiniWrapper]: sidebarModel.sidebarState.isPreviewMode,
              })}
            >
              <Sidebar model={uiSidebarModel} />
            </div>
          )}
          {documentIsLocked && (
            <div
              className={cx(styles.lockedOverlay, { [styles.lockedOverlayAnimation]: lockedAnimation })}
              onClick={() => setLockedAnimation(true)}
              onAnimationEnd={() => setLockedAnimation(false)}
            ></div>
          )}
        </div>
      ) : null;
    },
  ),
);

ContentEditor.displayName = 'ContentEditor';
