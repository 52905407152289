import {
  capitalizeWord,
  datesDifSeconds,
  extractFirstLetters,
  extractLetters,
  timeSince,
} from '@writercolab/common-utils';

import { ImportItemType } from '../../organisms/ImporterPopup/types';

export enum DOWNLOAD_TYPE {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export const headerTooltipText = {
  viewOnlyTeamMember: (importItem: ImportItemType) =>
    `Team members have view only access to ${capitalizeWord(importItem)}.`,
  viewOnlyAnotherTeam: teamName =>
    `These terms are managed by ${teamName}. Only team admins for this team can make changes.`,
  // filterBtn: 'Filter', part of Phase 2
  importBtn: 'Import via CSV or XLSX',
  assignUsersBtn: 'Assign users to groups via import',
  downloadBtn: 'Download',
  clipModeBtn: 'Clip text',
  wrapModeBtn: 'Wrap text',
  deleteBtn: 'Delete',
};

export const generateUserTimeStamp = (modifiedUser, createdUser, modificationTime) => {
  let userAbbr = modifiedUser.fullName
    ? extractFirstLetters(modifiedUser.fullName)
    : extractFirstLetters(createdUser.fullName || '');

  if (userAbbr.length === 0) {
    const email = modifiedUser.email || createdUser.email;
    userAbbr = '—';

    if (email) {
      userAbbr = extractLetters(email);
    }
  }

  const creationTime = +new Date(modificationTime);
  const createTermTimestamp = datesDifSeconds(+new Date(), creationTime) < 2 ? creationTime - 1000 : creationTime;

  return `${userAbbr.toUpperCase()} ∙ ${timeSince(createTermTimestamp)}`;
};
