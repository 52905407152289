import { useEffect } from 'react';

import { useAppState } from '../state';
import { TActionType } from '../state/types';

export const usePageTitle = (pageTitle: string) => {
  const { dispatchAppState } = useAppState();

  useEffect(() => {
    dispatchAppState({ type: TActionType.SetPageTitle, payload: pageTitle });
  }, [pageTitle]);
};
