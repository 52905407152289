import React, { useEffect } from 'react';

import type { IOrganization } from '@writercolab/common-utils';
import {
  SharedQueryParam,
  delay,
  executeDeleteOrganizationRequest,
  getOrganizations,
  unsetAuthCookie,
} from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { LoadingPage, TRIGGER_LOGOUT_IN_TABS, snackbarMessages, useLocalStorage } from '@web/component-library';
import { useNavigate } from 'react-router';

import useQuery from '../../../hooks/useQuery';
import {
  navigateToDeleteAccountFailureRoute,
  navigateToDeleteAccountSuccessRoute,
} from '../../../services/config/routes';
import { getLogger } from '../../../utils/logger';
import { goToHome } from '../../../utils/navigationUtils';

const LOG = getLogger('OrgDeleteConfirmationPage');

export const OrgDeleteConfirmationPage = () => {
  const query = useQuery();
  const navigate = useNavigate();
  const [, setTriggerLogout] = useLocalStorage<boolean | undefined>(TRIGGER_LOGOUT_IN_TABS, false);
  const { enqueueErrorSnackbar } = useCustomSnackbar();

  useEffect(() => {
    (async () => {
      const deleteAccountToken = query.get(SharedQueryParam.DELETE_TOKEN);

      if (deleteAccountToken) {
        const organizations = await getOrganizations();
        const currentOrganization = organizations.reduce((prev, current) =>
          prev.id > current.id ? prev : current,
        ) as IOrganization;

        try {
          await executeDeleteOrganizationRequest(currentOrganization.id, deleteAccountToken);
          unsetAuthCookie();
          setTriggerLogout(true);
          navigateToDeleteAccountSuccessRoute();
        } catch (e) {
          LOG.error(e);
          goToHome(navigate, currentOrganization.id);
          await delay(3000);
          enqueueErrorSnackbar(snackbarMessages.deleteOrgRequest.tokenExpired);
        }
      } else {
        navigateToDeleteAccountFailureRoute(deleteAccountToken);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoadingPage />;
};

export default OrgDeleteConfirmationPage;
