import { AnalyticsService } from '@writercolab/analytics';

import { ImportItemType } from './types';
import { AnalyticsActivity } from 'constants/analytics';

export const REFERENCE_COLUMN_NAME = 'reference';
export const INTERNAL_REFERENCE_PREFIX = '[INTERNAL_REF]';

export const TERM_COLUMN_LABELS = {
  Reference: 'Reference',
  Term: 'Term',
  Type: 'Type',
  PartOfSpeech: 'Part of speech',
  CaseSensitive: 'Case sensitive',
  CapitalizeAtSentenceStart: 'Capitalize at sentence start',
  AutocorrectWrongCapitalizations: 'Autocorrect wrong capitalizations',
  Description: 'Description',
  CommonMistake: 'Common mistake',
  CommonMistakePos: 'Common mistake POS',
  CommonMistakeCs: 'Common mistake CS',
  ReplaceWith: 'Replace with',
  Tags: 'Tags',
  DoExample: 'Do example',
  DontExample: 'Don’t example',
  EnableDontUseSuggestions: 'Enable don’t use suggestions',
  EnableUseCarefullySuggestions: 'Enable use carefully suggestions',
  EnableApprovedTermHighlighting: 'Enable approved term highlighting',
  EnableCommonMistakeCorrections: 'Enable common mistake corrections',
} as const;

export type TermColumnLabel = (typeof TERM_COLUMN_LABELS)[keyof typeof TERM_COLUMN_LABELS];

export const SNIPPETS_COLUMN_LABELS = {
  Reference: 'Reference',
  Snippet: 'Snippet',
  Description: 'Description',
  Shortcut: 'Shortcut',
  Tags: 'Tags',
};

export const BILLING_COLUMN_LABELS = {
  UserEmail: 'User email',
  Group: 'Group',
  Contact: 'Billing group contact',
};

export const baseUploadModelByType = {
  [ImportItemType.TERMS]: {
    caseSensitive: true,
    examples: [],
    mistakes: [],
    linkedTerms: [],
    tags: [],
    approvedTermExtension: {
      capitalize: true,
      fixCommonMistakes: true,
      fixCase: true,
    },
  },
  [ImportItemType.SNIPPETS]: {
    tags: [],
  },
  [ImportItemType.BILLING_GROUP]: {
    email: '',
  },
};

export const capitalizedLabelByType = {
  [ImportItemType.TERMS]: 'terms',
  [ImportItemType.SNIPPETS]: 'snippets',
  [ImportItemType.BILLING_GROUP]: 'billing groups', // done
};

export const capitalizedPreviewTitleByType = {
  [ImportItemType.TERMS]: 'terms',
  [ImportItemType.SNIPPETS]: 'snippets',
  [ImportItemType.BILLING_GROUP]: 'Billing group assignments', // done
};

export const submitButtonTextByType = {
  [ImportItemType.TERMS]: 'Import terms',
  [ImportItemType.SNIPPETS]: 'Import snippets',
  [ImportItemType.BILLING_GROUP]: 'Import', // done
};

export const beaconArticleSidebarIdByType = {
  [ImportItemType.TERMS]: '79',
  [ImportItemType.SNIPPETS]: '202',
  [ImportItemType.BILLING_GROUP]: '247',
};

type ImporterAnalyticsEventValue = (typeof AnalyticsActivity)[keyof typeof AnalyticsActivity];

export type ImporterAnalyticsEvents = {
  clickedLearnMore: ImporterAnalyticsEventValue;
  uploadedFile: ImporterAnalyticsEventValue;
  clickedImport: ImporterAnalyticsEventValue;
  importSucceeded: ImporterAnalyticsEventValue;
};

type ImporterAnalyticsEventsByType = {
  [K in ImportItemType.SNIPPETS | ImportItemType.TERMS | ImportItemType.BILLING_GROUP]: ImporterAnalyticsEvents;
};

export const importerAnalyticsEventsByType: ImporterAnalyticsEventsByType = {
  [ImportItemType.TERMS]: {
    clickedLearnMore: AnalyticsActivity.termsClickedLearnMore,
    uploadedFile: AnalyticsActivity.termsUploadedFile,
    clickedImport: AnalyticsActivity.termsClickedImport,
    importSucceeded: AnalyticsActivity.termsImportSucceeded,
  },
  [ImportItemType.SNIPPETS]: {
    clickedLearnMore: AnalyticsActivity.snippetsClickedLearnMore,
    uploadedFile: AnalyticsActivity.snippetsUploadedFile,
    clickedImport: AnalyticsActivity.snippetsClickedImport,
    importSucceeded: AnalyticsActivity.snippetsImportSucceeded,
  },
  [ImportItemType.BILLING_GROUP]: {
    clickedLearnMore: AnalyticsActivity.billingGroupClickedLearnMore,
    uploadedFile: AnalyticsActivity.billingGroupUploadedFile,
    clickedImport: AnalyticsActivity.billingGroupClickedImport,
    importSucceeded: AnalyticsActivity.billingGroupImportSucceeded,
  },
};
