import React, { useCallback, useEffect, useState } from 'react';

import { LocalStorageKey, LocalStorageService, startTrial } from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import {
  SnackTheme,
  SubscribeSnack,
} from '../components/pages/BillingPage/CheckoutElements/SnackMessage/SubscribeSnack';

import { snackbarMessages } from '@web/component-library';
import { useAppState } from 'state';

import { getLogger } from '../utils/logger';

const LOG = getLogger('useStartTeamTrialPopup');

const clearTeamTrialInLocalStorage = () => LocalStorageService.removeItem(LocalStorageKey.setTeamTrial);

export default function useStartTeamTrialPopup(orgId: string) {
  const _shouldTeamTrialBeOpen = LocalStorageService.getItem<boolean>(LocalStorageKey.setTeamTrial);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { enqueueBasicSnackbar, closeSnackbar } = useCustomSnackbar();
  const { loadSubscription } = useAppState();

  const onStartTrial = useCallback(async () => {
    try {
      await startTrial(Number(orgId));
      await loadSubscription();
      enqueueBasicSnackbar(
        snackbarMessages.billing.withComponent({
          trigger: (
            <SubscribeSnack
              bottomContent=""
              topContent="Team"
              alertContent="Trial liftoff!"
              withLogo={false}
              theme={SnackTheme.GREEN}
              style={{ width: 300 }}
              onCloseIconClick={() => closeSnackbar()}
            />
          ),
        }),
      );
      onCancelPopup();
    } catch (e) {
      LOG.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  const onCancelPopup = useCallback(() => {
    setIsPopupOpen(false);
    clearTeamTrialInLocalStorage();
  }, []);

  useEffect(() => {
    if (_shouldTeamTrialBeOpen) {
      setIsPopupOpen(true);
    }
  }, [_shouldTeamTrialBeOpen]);

  return { isPopupOpen, onStartTrial, onCancelPopup };
}
