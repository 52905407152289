import { useEffect, useState } from 'react';

import { LocalStorageKey, LocalStorageService } from '@writercolab/common-utils';

import { TAppState } from '../state/types';

export default function useEducationalModule(orgId?: number | undefined, userProfile?: TAppState['userProfile']) {
  const [termsEducational, setTermsEducational] = useState<boolean | null>(null);
  const [snippetsEducational, setSnippetsEducational] = useState<boolean | null>(null);

  useEffect(() => {
    if (orgId && userProfile) {
      const educationalTermsModuleVisible = LocalStorageService.getItem<string[] | null>(
        LocalStorageKey.educationalTermsModuleVisible,
        null,
      );
      const educationalSnippetsModuleVisible = LocalStorageService.getItem<string[] | null>(
        LocalStorageKey.educationalSnippetsModuleVisible,
        null,
      );

      if (educationalTermsModuleVisible && educationalTermsModuleVisible?.includes(`${orgId}${userProfile.id}`)) {
        setTermsEducational(false);
      }

      if (educationalSnippetsModuleVisible && educationalSnippetsModuleVisible?.includes(`${orgId}${userProfile.id}`)) {
        setSnippetsEducational(false);
      }
    }
  }, [orgId, userProfile]);

  return { termsEducational, snippetsEducational };
}
