import { useEffect, useState } from 'react';

import { isChromeBrowser } from '@writercolab/common-utils';
import { getLogger } from '@writercolab/utils';

const LOG = getLogger('useExtensionChrome');

let cachedIsInstalled: boolean | null = null;

const checkChromeExtensionInstalled = async (): Promise<boolean> => {
  const extensionId = import.meta.env.VITE_CHROME_EXTENSION_ID;

  if (cachedIsInstalled !== null) {
    LOG.debug('Returning cached extension status:', cachedIsInstalled);

    return cachedIsInstalled;
  }

  if (!extensionId) {
    LOG.debug('Chrome extension ID not defined, skipping check.');

    return false;
  }

  return new Promise<boolean>(resolve => {
    const extImgPath = `chrome-extension://${extensionId}/assets/icon/on.png`;
    const imgInstance = new Image();

    imgInstance.onload = () => {
      LOG.debug('Chrome extension found:', extImgPath);
      cachedIsInstalled = true;
      resolve(true);
    };
    imgInstance.onerror = () => {
      LOG.debug('Chrome extension not installed or accessible:', extImgPath);
      cachedIsInstalled = false;
      resolve(false);
    };

    imgInstance.src = extImgPath;
    LOG.debug('Checking Chrome extension installation:', extImgPath);
  });
};

export default function useChromeExtension(): boolean {
  const [isInstalled, setIsInstalled] = useState(false);
  const _isChromeBrowser = isChromeBrowser();

  useEffect(() => {
    let isMounted = true;

    const checkInstallation = async () => {
      if (!_isChromeBrowser) {
        LOG.debug('not a chrome browser, skipping extension check.');

        return;
      }

      try {
        const status = await checkChromeExtensionInstalled();

        if (isMounted) {
          LOG.debug('chrome extension installed status', status);
          setIsInstalled(status);
        }
      } catch (err) {
        LOG.error('error checking chrome extension', err);
      }
    };

    checkInstallation();

    return () => {
      isMounted = false;
    };
  }, [_isChromeBrowser]);

  return isInstalled;
}
