import type React from 'react';

import type { BaseComponentsProps } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { CopyToClipboardField } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

interface IScimSettingsProps extends BaseComponentsProps {
  id: string;
  endpoint: string;
  token: string;
  isLoading: boolean;
  refreshToken: () => void;
  onSubmit: () => void;
}

export const ScimSettings: React.FC<IScimSettingsProps> = ({
  id,
  endpoint,
  token,
  isLoading,
  refreshToken,
  onSubmit,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.scimHeader}>
      <Heading variant={HeadingVariant.H3} bold>
        SCIM Setup Guide
      </Heading>

      <Button type={ButtonTypes.PRIMARY} size={SizeTypes.SMALL} onClick={onSubmit}>
        Done
      </Button>
    </div>

    <div className={styles.panel}>
      <Text variant={TextSize.XXXXL}>Copy/paste from Writer into SCIM</Text>

      <div className={styles.scimField}>
        <CopyToClipboardField
          valueFieldClassName={styles.copyFieldValue}
          value={id}
          infoTooltipWidth={229}
          label="unique identifier"
          infoText="This is provided by Writer. This is a unique identifier for the user that you will provide to your IdP."
        />
      </div>
      <div className={styles.scimField}>
        <CopyToClipboardField
          valueFieldClassName={styles.copyFieldValue}
          value={endpoint}
          infoTooltipWidth={265}
          label="endpoint"
          infoText="This is provided by Writer. It is the endpoint that Okta will make requests to. You'll need to provide this value to your IdP."
        />
      </div>
      <div className={styles.scimField}>
        <CopyToClipboardField
          valueFieldClassName={styles.copyFieldValue}
          value={token}
          infoTooltipWidth={265}
          hideContent
          isLoading={isLoading}
          label="bearer token"
          infoText="This is provided by Writer. This allows Okta to authenticate its endpoint requests. You'll need to provide this value to your IdP."
        />
        <div className={styles.refreshIcon} onClick={refreshToken}>
          <Tooltip title="Refresh token" placement="left">
            <div className={styles.tooltipContainer}>
              <Icon name={IconVariant.REFRESH} width={15} height={15} />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  </div>
);

export default ScimSettings;
