import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import type { DateTime } from '@writercolab/network';
import type { TNotificationQueueItem } from '@writercolab/types';
import { NotificationQueueItemType } from '@writercolab/types';
import type { ITimelineEvent } from '@writercolab/ui-molecules';

import type { ContentHistoryApiModel } from 'models/contentHistory.api';
import { v4 as uuidv4 } from 'uuid';

interface IVersionHistoryOptions {
  api: () => ContentHistoryApiModel | undefined;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

const currentId = 'current';

export class VersionHistoryUIModel {
  selectedVersionId: string = currentId;

  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;

  constructor(private readonly opts: IVersionHistoryOptions) {
    this.notificationQueue = opts.notificationQueue || new ReactiveQueue();

    makeObservable(this, {
      selectedVersionId: observable,
      historyEntries: computed,
      selectedVersion: computed,
      isLoadingHistoryPreview: computed,
      historyPreviewFetchError: computed,
      onSelectVersion: action,
      onClearSelectedVersion: action,
    });
  }

  get historyEntries(): ITimelineEvent[] {
    const entries =
      this.opts.api()?.historyPreview?.map(entry => ({
        textPreview: entry.snippet,
        date: entry.modificationTime,
        id: uuidv4(),
      })) || [];

    const currentVersion = {
      textPreview: 'Current version',
      date: new Date().toString(),
      id: currentId,
    };

    return [currentVersion, ...entries];
  }

  get selectedVersion() {
    return this.historyEntries.find(entry => entry.id === this.selectedVersionId);
  }

  get isLoadingHistoryPreview() {
    return this.opts.api()?.isLoadingHistoryPreview;
  }

  get historyPreviewFetchError() {
    return this.opts.api()?.error;
  }

  isCurrentVersion(version: ITimelineEvent) {
    return version.id === currentId;
  }

  onSelectVersion = async (version: ITimelineEvent) => {
    try {
      const api = this.opts.api();

      if (!api) {
        return null;
      }

      runInAction(() => {
        this.selectedVersionId = version.id;
      });

      const { data } = await api.getHistoryEntry(version.date as DateTime);

      return data;
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Failed to load version content',
      });

      return null;
    }
  };

  onClearSelectedVersion = () => {
    this.selectedVersionId = currentId;
  };

  refetchHistoryPreview = () => {
    this.opts.api()?.refetchHistoryPreview();
  };
}
