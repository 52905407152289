import React from 'react';

import cx from 'classnames';

import SuggestionRuleHeader, { ISuggestionRuleHeaderProps } from '../SuggestionRuleHeader';

import styles from './styles.module.css';

interface ISuggestionRuleGroupProps extends ISuggestionRuleHeaderProps {
  borderedRules?: boolean;
}

const SuggestionRuleGroup: React.FC<ISuggestionRuleGroupProps> = ({ icon, heading, borderedRules, children }) => (
  <>
    <SuggestionRuleHeader icon={icon} heading={heading} />
    <div className={cx({ [styles.borderedRules]: borderedRules, [styles.extraTopMargin]: !icon && !heading })}>
      {children}
    </div>
  </>
);

export default SuggestionRuleGroup;
