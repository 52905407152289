export interface IGoogleAddressComponent {
  // eslint-disable-next-line
  long_name: string;
  // eslint-disable-next-line
  short_name: string;
  types: string[];
}

enum AddressComponentPart {
  POSTAL_CODE = 'postal_code',
  LOCALITY = 'locality',
  POSTAL_TOWN = 'postal_town',
  COUNTRY = 'country',
  ADMINISTRATIVE_AREA_LEVEL_1 = 'administrative_area_level_1',
  SUBLOCALITY_LEVEL_1 = 'sublocality_level_1',
  STREET_NUMBER = 'street_number',
  ROUTE = 'route',
}

type DefaultValue = string | null;

const extractAddressComponentPart = (
  addressComponents: IGoogleAddressComponent[],
  part: string,
): IGoogleAddressComponent | null => addressComponents.find(component => component.types.includes(part)) || null;

export const extractZipCode = (
  addressComponents: IGoogleAddressComponent[],
  defaultValue: DefaultValue = null,
): string | null =>
  extractAddressComponentPart(addressComponents, AddressComponentPart.POSTAL_CODE)?.short_name || defaultValue;

export const extractCity = (
  addressComponents: IGoogleAddressComponent[],
  defaultValue: DefaultValue = null,
): string | null =>
  extractAddressComponentPart(addressComponents, AddressComponentPart.LOCALITY)?.long_name ||
  extractAddressComponentPart(addressComponents, AddressComponentPart.POSTAL_TOWN)?.long_name ||
  extractAddressComponentPart(addressComponents, AddressComponentPart.SUBLOCALITY_LEVEL_1)?.long_name ||
  defaultValue;

export const extractCountryCode = (
  addressComponents: IGoogleAddressComponent[],
  defaultValue: DefaultValue = null,
): string | null =>
  extractAddressComponentPart(addressComponents, AddressComponentPart.COUNTRY)?.short_name || defaultValue;

export const extractStateCode = (
  addressComponents: IGoogleAddressComponent[],
  defaultValue: DefaultValue = null,
): string | null =>
  extractAddressComponentPart(addressComponents, AddressComponentPart.ADMINISTRATIVE_AREA_LEVEL_1)?.short_name ||
  defaultValue;

export const extractAddressLine = (addressComponents: IGoogleAddressComponent[]): string => {
  const addressLine: string[] = [];

  const streetNumber = extractAddressComponentPart(addressComponents, AddressComponentPart.STREET_NUMBER);
  const route = extractAddressComponentPart(addressComponents, AddressComponentPart.ROUTE);

  if (streetNumber) {
    addressLine.push(streetNumber.long_name);
  }

  if (route) {
    addressLine.push(route.long_name);
  }

  return addressLine.join(' ');
};
