import React from 'react';

import {
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  Modal,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import styles from './AddVoiceModal.module.css';

interface IAddVoiceModal {
  open: boolean;
  onClickAnalyze: () => void;
  onClickManuallySelect: () => void;
  onClickClose: () => void;
}

export const AddVoiceModal: React.FC<IAddVoiceModal> = ({
  open,
  onClickAnalyze,
  onClickManuallySelect,
  onClickClose,
}) => (
  <Modal
    open={open}
    hideCloseButton
    title="Tune your voice"
    className={styles.content}
    modalTitleClassName={styles.title}
    multiModalContainerClassName={styles.container}
    handleClose={onClickClose}
  >
    <>
      <div className={styles.closeButton} onClick={onClickClose}>
        <Icon name={IconVariant.CLOSE_WHITE} height={18} width={18} />
      </div>
      <Heading variant={HeadingVariant.H2} color={HeadingColor.BLACK} medium></Heading>
      <Text className={styles.label} variant={TextSize.XL} color={TextColor.GREY}>
        Set up a voice profile and apply it when generating content so your content sounds more like you
      </Text>
      <div className={styles.controls}>
        <button type="button" className={styles.selectModeButton} onClick={onClickAnalyze}>
          <Icon name={IconVariant.VOICE_STAR} width={36} height={36} />
          <Text variant={TextSize.XXXXL} color={TextColor.GREY} medium>
            Analyze example copy
          </Text>
          <Text className={styles.description} variant={TextSize.M} color={TextColor.GREY}>
            Best for teams with polished sample content
          </Text>
          <Text className={styles.label} variant={TextSize.M} color={TextColor.BLACK} upperCase>
            Recommended
          </Text>
        </button>

        <button type="button" className={styles.selectModeButton} onClick={onClickManuallySelect}>
          <Icon name={IconVariant.VOICE_SETUP} width={36} height={36} />
          <Text variant={TextSize.XXXXL} color={TextColor.GREY} medium>
            Manually select traits and options
          </Text>
          <Text className={styles.description} variant={TextSize.M} color={TextColor.GREY}>
            Best for teams that are still establishing their guidelines
          </Text>
        </button>
      </div>
    </>
  </Modal>
);
