import { action, computed, makeObservable, observable } from 'mobx';

import { BUILDER_TEMPLATE_ID } from '@writercolab/common-utils';
import { PaginatedModel, ReactiveQueue } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';
import {
  EApplicationType,
  NotificationQueueItemType,
  type TApplicationBase,
  type TApplicationBriefPaginatedTemplateResponse,
  type TGetDraftsPaginatedResponse,
  type TGetDraftsResponse,
  type TNotificationQueueItem,
  type TOrgUserActivityParams,
} from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import {
  DEFAULT_PAGE_SIZE,
  type TDraftWithInputsResponse,
  type TDraftsTableFilterAppsExtraArgs,
  type TOutputsQueryParams,
} from '@web/types';
import { IWebAppAnalyticsTrack } from 'constants/analytics';
import debounce from 'lodash/debounce';
import { ApplicationApiModel } from 'models/application.api';
import { OrganizationDocumentsApi } from 'models/organizationDocuments.api';
import { OutputsApiModel } from 'models/outputs.api';
import { VoiceApiModel } from 'models/voice.api';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY_MS } from 'services/config/constants';
import { getMediaFileDetails } from 'services/request/mediaFiles';
import { getMediaFileIdFromInputs, mapMediaFileDetailsToInputs, transformSeoBlogInputs } from 'utils/draftsUtils';
import { getLogger } from 'utils/logger';

import { getOutputsUrlParams } from './utils';

interface IOutputsTableOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: () => number;
  isVoiceEnabled: () => boolean;
  analyticsService: IWebAppAnalyticsTrack<TOrgUserActivityParams>;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

export const OutputsModalType = new Enum('deleteOutput');

const LOG = getLogger('OutputsTableModel');

export class OutputsTableUiModel {
  readonly outputsApi: OutputsApiModel;
  readonly applicationApi: ApplicationApiModel;
  readonly organizationDocumentsApi: OrganizationDocumentsApi;
  readonly voiceApi: VoiceApiModel;
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

  private $selectedOutput = observable.box<TDraftWithInputsResponse | null>(null);

  constructor(private readonly opts: IOutputsTableOptions) {
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();

    this.organizationDocumentsApi = new OrganizationDocumentsApi({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId(),
    });

    this.voiceApi = new VoiceApiModel({
      request: this.opts.request,
      organizationId: () => this.opts.organizationId,
      teamId: () => this.opts.teamId(),
      isVoiceEnabled: this.opts.isVoiceEnabled,
    });

    this.outputsApi = new OutputsApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: () => this.opts.teamId(),
    });

    this.applicationApi = new ApplicationApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: () => this.opts.teamId(),
    });

    makeObservable(this, {
      outputs: computed.struct,
      applications: computed.struct,
      hasMore: computed,
      isLoading: computed,
      setSelected: action,
      setDescription: action,
    });
  }

  private readonly $paginatedOutputs: PaginatedModel<
    TGetDraftsPaginatedResponse | null,
    TGetDraftsResponse,
    {
      offset: number;
      limit: number;
    },
    TOutputsQueryParams
  > = new PaginatedModel({
    name: '$paginatedOutputs',
    argsExtra: getOutputsUrlParams(),
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj?.pagination.offset ?? 0) + (obj?.result?.length ?? 0);

      if (offset >= (obj?.totalCount ?? 0)) {
        return this.$paginatedOutputs.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj?.result ?? [],
    load: async ({ args, extra }) => {
      const teamId = this.opts.teamId();

      if (!teamId) {
        return null;
      }

      const data = await this.outputsApi.getOutputs({
        ...args,
        ...extra,
      });

      return data;
    },
  });

  private readonly $applications: PaginatedModel<
    TApplicationBriefPaginatedTemplateResponse | null,
    TApplicationBase,
    {
      offset: number;
      limit: number;
    },
    TDraftsTableFilterAppsExtraArgs
  > = new PaginatedModel({
    name: '$applications',
    argsExtra: { appType: EApplicationType.enum.generation, sortField: 'name', sortOrder: 'asc' },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj?.pagination.offset ?? 0) + (obj?.result?.length ?? 0);

      if (offset >= (obj?.totalCount ?? 0)) {
        return this.$applications.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj?.result ?? [],
    load: async ({ args, extra }) => {
      const teamId = this.opts.teamId();

      if (!teamId) {
        return null;
      }

      const data = await this.applicationApi.getDeployedApps({
        ...args,
        ...extra,
      });

      return data;
    },
  });

  get isLoading(): boolean {
    return this.$paginatedOutputs.status === 'pending';
  }

  get isApplicationsFilterLoading(): boolean {
    return this.$applications.status === 'pending';
  }

  get outputs(): TGetDraftsResponse[] {
    return this.$paginatedOutputs.value ?? [];
  }

  get applications() {
    return this.$applications.value ?? [];
  }

  get hasMore(): boolean {
    return this.$paginatedOutputs.hasNext;
  }

  get hasMoreApplications(): boolean {
    return this.$applications.hasNext;
  }

  loadMore = async () => {
    await this.$paginatedOutputs.next();
  };

  loadMoreApplications = async () => {
    await this.$applications.next();
  };

  reload = async () => {
    this.$paginatedOutputs.reload();

    return this.$paginatedOutputs.promise;
  };

  get selectedOutput(): TDraftWithInputsResponse | null {
    return this.$selectedOutput.get();
  }

  setSelected = async (output: TGetDraftsResponse) => {
    const outputWithInputs = await this.getDescription(output.id);

    if (outputWithInputs) {
      this.$selectedOutput.set(outputWithInputs);
    }
  };

  setDescription = (output: TGetDraftsResponse) => {
    this.getDescription(output.id);
  };

  getDescription = async (outputId: number) => {
    try {
      const data = await this.outputsApi.getOutput(outputId);

      if (data.template.id === BUILDER_TEMPLATE_ID.SEO_BLOG) {
        data.inputs = transformSeoBlogInputs(data.inputs);
      } else if (data.template.id === BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS) {
        const mediaFileId = getMediaFileIdFromInputs(data.inputs);

        if (mediaFileId) {
          try {
            const teamId = this.opts.teamId();
            const mediaFileDetails = await getMediaFileDetails(
              String(this.opts.organizationId),
              String(teamId),
              mediaFileId.value[0],
            );

            data.media = mapMediaFileDetailsToInputs(mediaFileId.value[0], mediaFileDetails.name, mediaFileDetails.url);
          } catch (error) {
            LOG.error(error);
          }
        }
      }

      return data;
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'This output could not be opened',
      });

      return undefined;
    }
  };

  updateExtraArgs = (extraArgs: Partial<TOutputsQueryParams>) => {
    this.$paginatedOutputs.setExtra(extraArgs);
  };

  updateTableFilterAppsExtraArgs = debounce((extraArgs: TDraftsTableFilterAppsExtraArgs) => {
    this.$applications.setExtra(extraArgs);
  }, DEFAULT_SEARCH_DEBOUNCE_DELAY_MS);
}
