import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx';

import { components } from '@writercolab/network';
import { DropdownOption } from '@writercolab/ui-atoms';
import { Enum, getLogger } from '@writercolab/utils';

import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import { ExtensionConfigModel } from '../../../models/extensionConfig';
import { removeDuplicatesByPropertyName } from '../../../utils/arrayUtils';
import { isValidWildcardURL } from '../../../utils/validateUtils';
import { IWildcardOption } from '../../molecules/WildcardDomainsTable/WildcardDomainsTable';

export const TChromeExtensionRestrictionType = new Enum('all', 'approved');
const TChromeExtensionContentRestrictionType = new Enum('Never', 'Always', 'UserOptsIn');
export const contentRestrictionOptions: DropdownOption[] = [
  {
    id: TChromeExtensionContentRestrictionType.enum.Never,
    name: 'Don’t check content',
  },
  {
    id: TChromeExtensionContentRestrictionType.enum.Always,
    name: 'Always check content',
  },
  {
    id: TChromeExtensionContentRestrictionType.enum.UserOptsIn,
    name: 'Check content only if user opts in',
  },
];

interface IUISecurityPageModelOptions {
  model: ExtensionConfigModel;
}

const LOG = getLogger('UISecurityPageModel');

const genOptId = (index: number): string => `id_${Date.now()}_${index}`;
const isUserOptsInAvailable = (inputUrl: string): boolean => inputUrl.includes('docs.google.com');
const canUseUserOptsInOption = (inputUrl: string, optionId: string): boolean =>
  isUserOptsInAvailable(inputUrl) || optionId !== TChromeExtensionContentRestrictionType.enum.UserOptsIn;

export class UISecurityPageModel {
  public restrictionType: typeof TChromeExtensionRestrictionType.type = TChromeExtensionRestrictionType.enum.approved;
  public wildcardOptions: IWildcardOption[] = [];
  public defaultContentCheckRestriction: typeof TChromeExtensionContentRestrictionType.type =
    TChromeExtensionContentRestrictionType.enum.Never;

  constructor(private opts: IUISecurityPageModelOptions) {
    autorun(() => {
      this.wildcardOptions =
        (this.config?.patterns?.map((opt, i) => ({
          id: genOptId(i),
          name: opt.pattern,
          restriction: opt.extensionContentCheckMode as typeof TChromeExtensionContentRestrictionType.type,
          restrictionOptions: contentRestrictionOptions
            .map(restrictionOption => ({
              ...restrictionOption,
              active:
                restrictionOption.id ===
                (opt.extensionContentCheckMode as typeof TChromeExtensionContentRestrictionType.type),
            }))
            .filter(o => canUseUserOptsInOption(opt.pattern, o.id)),
        })) as IWildcardOption[]) || [];

      if (
        this.config?.defaultExtensionContentCheckMode === TChromeExtensionContentRestrictionType.enum.Always &&
        this.config?.patterns &&
        isEmpty(this.config?.patterns)
      ) {
        this.restrictionType = TChromeExtensionRestrictionType.enum.all;
      } else {
        this.restrictionType = TChromeExtensionRestrictionType.enum.approved;
      }

      this.defaultContentCheckRestriction =
        this.config?.defaultExtensionContentCheckMode || TChromeExtensionContentRestrictionType.enum.Never;
    });

    makeObservable(this, {
      restrictionType: observable,
      defaultContentCheckRestriction: observable,
      wildcardOptions: observable,

      config: computed.struct,

      onChangeRestrictionType: action.bound,
      onAddNewWildcardOption: action.bound,
      onChangeWildcardOption: action.bound,
      onRemoveWildcardOption: action.bound,
      save: action.bound,
    });
  }

  onChangeRestrictionType = (type: string) => {
    if (!TChromeExtensionRestrictionType.is(type)) {
      LOG.debug('onChangeRestrictionType invalid value chosen', type);

      return;
    }

    if (type === TChromeExtensionRestrictionType.enum.all) {
      this.wildcardOptions = [];
      this.defaultContentCheckRestriction = TChromeExtensionContentRestrictionType.enum.Always;
    }

    this.restrictionType = type as typeof TChromeExtensionRestrictionType.type;
  };

  onChangeDefaultContentCheckRestriction = (type: string) => {
    if (!TChromeExtensionContentRestrictionType.is(type)) {
      LOG.debug('onChangeDefaultContentCheckRestriction invalid value chosen', type);

      return;
    }

    runInAction(() => {
      this.defaultContentCheckRestriction = type as typeof TChromeExtensionContentRestrictionType.type;
    });
  };

  onAddNewWildcardOption(): void {
    this.wildcardOptions = [
      ...this.wildcardOptions,
      {
        id: genOptId(this.wildcardOptions.length + 1),
        name: '',
        restriction: TChromeExtensionContentRestrictionType.enum.Never,
        restrictionOptions: contentRestrictionOptions
          .map(opt => ({
            ...opt,
            active: opt.id === TChromeExtensionContentRestrictionType.enum.Never,
          }))
          .filter(o => o.id !== TChromeExtensionContentRestrictionType.enum.UserOptsIn),
      },
    ];
  }

  onRemoveWildcardOption(opt: IWildcardOption): void {
    this.wildcardOptions = this.wildcardOptions.filter(o => o.id !== opt.id);
  }

  onChangeWildcardOption(opt: IWildcardOption): void {
    this.wildcardOptions = this.wildcardOptions.map(o => {
      if (o.id === opt.id) {
        const trimmedName = trim(opt.name);
        const nameEmpty = isEmpty(trimmedName);

        const isValid = !nameEmpty ? isValidWildcardURL(trimmedName) : false;
        const isDuplicate = !nameEmpty
          ? this.wildcardOptions.some(item => item.name === trimmedName && item.id !== opt.id)
          : false;

        return {
          ...opt,
          name: opt.name,
          restriction: opt.restriction,
          valid: isValid,
          duplicate: isDuplicate,
          restrictionOptions: contentRestrictionOptions
            .map(restrictionOption => ({
              ...restrictionOption,
              active: restrictionOption.id === opt.restriction,
            }))
            .filter(o => canUseUserOptsInOption(opt.name, o.id)),
        };
      }

      return o;
    });
  }

  async save(): Promise<void> {
    await this.opts.model.updateConfig({
      defaultExtensionContentCheckMode: this.defaultContentCheckRestriction,
      patterns: removeDuplicatesByPropertyName<IWildcardOption, 'name'>(this.wildcardOptions, 'name')
        .filter(opt => !isEmpty(opt.name))
        .map(opt => ({
          extensionContentCheckMode: opt.restriction as typeof TChromeExtensionContentRestrictionType.type,
          pattern: opt.name,
        })),
    });
  }

  get contentCheckRestrictions(): DropdownOption[] {
    return contentRestrictionOptions
      .map(opt => ({
        ...opt,
        active: opt.id === this.defaultContentCheckRestriction,
      }))
      .filter(o => o.id !== TChromeExtensionContentRestrictionType.enum.UserOptsIn);
  }

  get allWebsitesRestrictionVisible(): boolean {
    return this.restrictionType === TChromeExtensionRestrictionType.enum.all;
  }

  get config(): components['schemas']['organization_model_OrganizationExtensionConfig'] | null {
    return this.opts.model.config;
  }

  get loading(): boolean {
    return this.opts.model.loading;
  }

  get canSave(): boolean {
    if (this.restrictionType === TChromeExtensionRestrictionType.enum.all) {
      return true;
    }

    const hasInvalidWildcardOptions =
      this.wildcardOptions.some(opt => opt.valid === false) || this.wildcardOptions.some(opt => opt.duplicate === true);

    return this.wildcardOptions.length === 0 || !hasInvalidWildcardOptions;
  }
}
