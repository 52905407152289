import type { IAtom } from 'mobx';
import { autorun, computed, makeObservable } from 'mobx';

import { cleanFromNewLines, wordPluralize } from '@writercolab/common-utils';
import { FormModel, createAtomSubscriber } from '@writercolab/mobx';
import type { AutocompleteOption } from '@writercolab/ui-molecules';

import { Editor } from '@tiptap/react';
import type { TTeamPromptBrief, TTeamPromptTag } from '@web/types';
import { TAppDecorationId } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import type { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { getLogger } from '../../../utils/logger';
import { parseToProseMirrorSchema } from '../../../utils/textUtils';
import { editorStartConfig, formatClipboardHtmlToVariables, parseSchemaToString } from './utils';

interface ITeamPromptFormUiModelOpts {
  teamPromptsApiModel: TeamPromptsApiModel;
  teamPrompt?: () => Partial<TTeamPromptBrief> | undefined;
}

const LOG = getLogger('TeamPromptPanelUiModel');

const MAX_TITLE_LENGTH = 200;
const MAX_DESCRIPTION_WORDS_COUNT = 200;
const MAX_PROMPT_WORDS_COUNT = 3000;

const maxLengthMessage = (max: number) => `Max length is ${max} ${wordPluralize(max, 'character')}`;
const maxWordsCountMessage = (max: number) => `Max length is ${max} ${wordPluralize(max, 'word')}`;

const getRoughWordsCount = (text: string) => text.split(/\S+/).length;

type TEditorFormSyncState = 'editor' | 'filed';

export class TeamPromptPanelUiModel {
  private readonly $promptEditor: Editor;
  private readonly atom: IAtom;

  formBody: ReturnType<typeof this.buildForm>;

  constructor(private readonly opts: ITeamPromptFormUiModelOpts) {
    let editorContentChanged: undefined | TEditorFormSyncState;

    this.formBody = this.buildForm();

    this.$promptEditor = new Editor(
      editorStartConfig({
        initialValue: parseToProseMirrorSchema(this.formBody.form.prompt.value || ''),
        onChange: (prompt: string) => {
          if (editorContentChanged !== 'filed') {
            editorContentChanged = 'editor';
            this.formBody.form.prompt.set(prompt);
          } else {
            editorContentChanged = undefined;
          }
        },
        clipboardTextSerializer: slice => `${parseSchemaToString(slice.toJSON())}`,
        transformPastedHTML: formatClipboardHtmlToVariables,
      }),
    );

    this.atom = createAtomSubscriber('atom', () => {
      return autorun(() => {
        if (editorContentChanged !== 'editor') {
          this.$promptEditor.commands.setContent(parseToProseMirrorSchema(this.formBody.form.prompt.value || ''));
        }
      });
    });

    makeObservable(this, {
      formValuesTouched: computed.struct,
    });
  }

  get promptEditor() {
    this.atom.reportObserved();

    return this.$promptEditor;
  }

  get formValuesTouched() {
    return Object.values(this.formBody.touched).some(value => value);
  }

  onTagChange = async (tags: AutocompleteOption[]) => {
    const newTags = tags
      .filter(t => !this.opts.teamPromptsApiModel.tagsList.some(tag => tag.name === t.name))
      .map(t => t.name);
    let tagsCreated: TTeamPromptTag[] = [];

    if (!isEmpty(newTags)) {
      tagsCreated = await this.opts.teamPromptsApiModel.createTags(newTags);
    }

    this.formBody.form.tags.value = uniqBy([...tagsCreated, ...this.opts.teamPromptsApiModel.tagsList], 'name').filter(
      t => tags.find(tag => tag.name === t.name),
    );
  };

  private buildForm() {
    return FormModel.build(({ field }) => ({
      id: field({
        init: () => this.opts.teamPrompt?.()?.id || undefined,
      }),
      title: field({
        init: () => this.opts.teamPrompt?.()?.title || '',
        autotouched: true,
        validation: value => {
          if (value && value.length > MAX_TITLE_LENGTH) {
            return maxLengthMessage(MAX_TITLE_LENGTH);
          }

          return undefined;
        },
      }),
      icon: field({
        init: () => this.opts.teamPrompt?.()?.icon || TAppDecorationId.enum.longform,
        autotouched: true,
        validation: value => {
          if (!value) {
            return 'Icon is required';
          }

          return undefined;
        },
      }),
      prompt: field({
        init: () => this.opts.teamPrompt?.()?.prompt || '',
        autotouched: true,
        validation: value => {
          if (!value || isEmpty(cleanFromNewLines(value.trim()))) {
            return 'Required field';
          }

          const wordsCount = getRoughWordsCount(value);
          LOG.debug('Prompt words count:', wordsCount);

          if (value && wordsCount > MAX_PROMPT_WORDS_COUNT) {
            return maxWordsCountMessage(MAX_PROMPT_WORDS_COUNT);
          }

          return undefined;
        },
      }),
      description: field({
        init: () => this.opts.teamPrompt?.()?.description || '',
        autotouched: true,
        validation: value => {
          const wordsCount = getRoughWordsCount(value || '');
          LOG.debug('Description words count:', wordsCount);

          if (value && wordsCount > MAX_DESCRIPTION_WORDS_COUNT) {
            return maxWordsCountMessage(MAX_DESCRIPTION_WORDS_COUNT);
          }

          return undefined;
        },
      }),
      tags: field<TTeamPromptTag[]>({
        init: () => this.opts.teamPrompt?.()?.tags || [],
        autotouched: true,
      }),
    }));
  }
}
