import { LocalStorageKey, LocalStorageService } from '@writercolab/common-utils';

import { SeoBlogSection } from '@web/types';

interface CoWriteStorageRecord {
  mode: string;
  templateId?: string;
  blogState?: SeoBlogSection;
}

interface CoWriteNextStorageRecords {
  [key: string]: CoWriteStorageRecord;
}

export interface CoWriteNextCategoryStorageRecords {
  [key: string]: number | string;
}

const getCoWriteNextStateStorageKey = (orgId, teamId, docId) => `${orgId}-${teamId}-${docId}`;

export const saveCoWriteNextState = (orgId, teamId, docId, state: CoWriteStorageRecord): void => {
  const coWriteStorageKey = getCoWriteNextStateStorageKey(orgId, teamId, docId);

  const records = LocalStorageService.getItem<CoWriteNextStorageRecords[]>(LocalStorageKey.coWriteNextState) || [];
  const recordIndex = records.findIndex(r => r[coWriteStorageKey]);

  if (recordIndex === -1) {
    records.push({
      [coWriteStorageKey]: state,
    });
  } else {
    records[recordIndex] = {
      [coWriteStorageKey]: state,
    };
  }

  LocalStorageService.setItem(LocalStorageKey.coWriteNextState, records);
};

export const getCoWriteNextState = (orgId, teamId, docId): CoWriteStorageRecord | null => {
  const coWriteStorageKey = getCoWriteNextStateStorageKey(orgId, teamId, docId);
  const coWriteStorageRecords =
    LocalStorageService.getItem<CoWriteNextStorageRecords[]>(LocalStorageKey.coWriteNextState) || [];
  const targetRecords = coWriteStorageRecords.find(r => r[coWriteStorageKey]);

  return targetRecords ? targetRecords[coWriteStorageKey] : null;
};
