import React, { useRef } from 'react';

import { BaseComponentsProps } from '@writercolab/common-utils';
import { Button, ButtonTypes, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IXMLFileUploadProps extends BaseComponentsProps {
  handleUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemoveFile: () => void;
  xmlString: string;
}

export const XMLFileUpload = ({ xmlString, handleRemoveFile, handleUpload }: IXMLFileUploadProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => fileInputRef?.current?.click();

  return (
    <>
      {xmlString ? (
        <>
          <textarea className={styles.uploadTextarea} defaultValue={xmlString} />

          <span onClick={handleRemoveFile}>
            <Text variant={TextSize.L}>
              <Icon name={IconVariant.TRASH} /> Remove XML
            </Text>
          </span>
        </>
      ) : (
        <div className={styles.upload}>
          <input ref={fileInputRef} type="file" accept="application/xml" onChange={handleUpload} />

          <Text variant={TextSize.XXL}>Upload the metadata XML file</Text>

          <Button type={ButtonTypes.PRIMARY} onClick={handleClick}>
            Browse
          </Button>
        </div>
      )}
    </>
  );
};

export default XMLFileUpload;
