import type { BillingProduct, IDocument, ITeam, LogoutParams } from '@writercolab/common-utils';
import {
  CONTACT_SALES_LINK,
  DocsRequestTypes,
  LocalStorageKey,
  LocalStorageService,
  SUPPORT_EMAIL,
  SearchQueryParam,
  SharedQueryParam,
  getTeam,
  openNewTab,
} from '@writercolab/common-utils';
import type { FeatureFlags } from '@writercolab/feature-flags';
import { REDIRECT_DOC_ID } from '@writercolab/utils';

import type { TFeatureFlags } from '@web/types';
import head from 'lodash/head';
import trimEnd from 'lodash/trimEnd';
import type { NavigateFunction, Params } from 'react-router';

import { REACT_RELATIVE_ROUTE, ROUTE, SHORTCUT_PORTAL_ROUTES, SHORTCUT_ROUTES } from '../services/config/routes';
import { getDocumentsList } from '../services/request/documents';
import config from './dynamicConfig';

export const buildAbsoluteUrl = (path: string) => `${config.APP_ROOT}${path}`;

/**
 * Navigates to the billing page with the specified organization ID and optional trigger update.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {OrganizationId} orgId - The ID of the organization.
 * @param {BillingProduct} [triggerUpdateTo] - The optional billing product trigger update.
 * @returns {void}
 */
export const goToBilling = (navigate: NavigateFunction, orgId?: number, triggerUpdateTo?: BillingProduct) =>
  navigate(ROUTE.toBilling(orgId, triggerUpdateTo));

/**
 * Navigates to the Suggestions page.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {number} orgId - The ID of the organization.
 * @param {number} teamId - The ID of the team.
 */
export const goToSuggestions = (navigate: NavigateFunction, orgId: number, teamId: number) =>
  navigate(ROUTE.toSuggestions(orgId, teamId));

/**
 * Opens a new tab and navigates to the billing page for a given organization ID.
 * @param {number} orgId - The ID of the organization to navigate to the billing page for.
 * @param {BillingProduct} [triggerUpdateTo] - Optional parameter to trigger an update to a specific billing product.
 */
export const goToBillingNewTab = (orgId: number, triggerUpdateTo?: BillingProduct) =>
  openNewTab(`${config.APP_ROOT}${ROUTE.toBilling(orgId, triggerUpdateTo)}`);

/**
 * Redirects to a new URL and reloads the page.
 *
 * @param {string} locationHref - The URL to redirect to.
 * @returns {false} - Always returns false.
 */
export const goToHrefWithReload = (locationHref: string): false => {
  window.location.href = locationHref;

  return false;
};

/**
 * Function to navigate to the dashboard.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {number} orgId - The organization ID.
 * @param {boolean} [reloadPage=false] - Whether to reload the page.
 * @param {number} [teamId=0] - The team ID.
 * @param {boolean} [showOnboarding=false] - Whether to show onboarding.
 * @param {boolean} [showTutorial=false] - Whether to show the tutorial.
 * @returns {void}
 */
export const goToHome = (
  navigate: NavigateFunction,
  orgId: number,
  reloadPage = false,
  teamId: number = 0,
  showOnboarding = false,
  showTutorial = false,
): void => {
  if (reloadPage) {
    goToHrefWithReload(ROUTE.toHome(orgId, teamId, showOnboarding, showTutorial));
  } else {
    navigate(ROUTE.toHome(orgId, teamId, showOnboarding, showTutorial));
  }
};

/**
 * Navigates to the demo document.
 *
 * @param {NavigateFunction} navigate - The function used for navigation.
 * @param {number} orgId - The ID of the organization.
 * @param {boolean} [showBoarding=false] - Whether to show onboarding.
 * @returns {Promise<void>} - A promise representing the navigation action.
 */
export const goToDemoDocument = (navigate: NavigateFunction, orgId: number, showBoarding = false) =>
  getTeam(orgId).then(team => {
    const onboarding = showBoarding ? 'true' : 'false';

    return navigate({
      pathname: ROUTE.toEditorPage(orgId, team.id, REDIRECT_DOC_ID),
      search: `?${SearchQueryParam.hideTrialBanner}=true&onboarding=${onboarding}`,
    });
  });

/**
 * Function to redirect to the sign-in page with optional query parameters.
 *
 * @param {string} redirectTo - The redirect URL after sign-in.
 * @param {LogoutParams | null} queryParams - Additional query parameters for the sign-in page.
 */
export const goToSignIn = (redirectTo?: string, queryParams?: LogoutParams | null) => {
  const rootLocation = config.APP_ROOT || ROUTE.root;
  const isRootLocationEmpty = trimEnd(rootLocation, ROUTE.root).length > 0;
  const locationQueryParams: string[] = [];

  let signInExternalLocation = isRootLocationEmpty ? `${rootLocation}${ROUTE.loginExternal}` : rootLocation;

  if (queryParams) {
    Object.keys(queryParams).forEach(aParam => {
      const param = aParam as keyof typeof queryParams;

      if (param === SharedQueryParam.INVALID_SSO_TOKEN && queryParams[param]) {
        locationQueryParams.push(`${SharedQueryParam.INVALID_SSO_TOKEN}=true`);
      } else if (queryParams[param] && `${queryParams[param]}`.length > 0) {
        locationQueryParams.push(`${param}=${queryParams[param]}`);
      }
    });
  }

  if (redirectTo) {
    locationQueryParams.push(`${SharedQueryParam.REDIRECT_TO}=${redirectTo}`);
  }

  if (locationQueryParams.length > 0) {
    signInExternalLocation += `?${locationQueryParams.join('&')}`;
  }

  window.location.href = signInExternalLocation;

  return null;
};

export const goToAiStudioSignIn = () => {
  const rootLocation = config.APP_ROOT || ROUTE.root;
  const isRootLocationEmpty = trimEnd(rootLocation, ROUTE.root).length > 0;

  const signInExternalLocation = isRootLocationEmpty
    ? `${rootLocation}${REACT_RELATIVE_ROUTE.appStudioSignin}`
    : rootLocation;

  window.location.href = signInExternalLocation;

  return null;
};

/**
 * Function that updates a query parameter value and navigates to the updated URL.
 * @param {Function} navigate - Function to navigate to a new URL.
 * @returns {Function} - Function that takes a query parameter name and value (optional) and performs the navigation.
 */
export const navigateToQueryParam = (navigate: NavigateFunction) => (param: string, value?: string) => {
  // eslint-disable-next-line no-restricted-globals
  const query = new URLSearchParams(location.search);

  if (!value) {
    query.delete(param);
  } else {
    query.set(param, value!);
  }

  navigate({ search: query.toString() });

  return query;
};

/**
 * Removes a specified query parameter from the URL and performs navigation using the given navigate function.
 *
 * @param {NavigateFunction} navigate - The navigate function to use for navigation.
 * @param {URLSearchParams} queryParams - The query parameters of the current URL.
 * @param {string} params - The name of the query parameter to remove.
 */
export const removeQueryParam = (navigate: NavigateFunction, queryParams: URLSearchParams, params: string) => {
  if (queryParams.has(params)) {
    queryParams.delete(params);
    navigate(
      {
        search: queryParams.toString(),
      },
      { replace: true },
    );
  }
};

/**
 * Generates a query string from the given parameters.
 * @param {Record<string, string>} params - The parameters to construct the query string from.
 * @param {boolean} [skipQuestionMark] - Whether to skip the question mark in the query string. Default is false.
 * @returns {string} - The generated query string.
 */
export const generateQueryStringFromParams = (params: Record<string, string>, skipQuestionMark?: boolean) => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => searchParams.append(key, params[key]));

  return `${skipQuestionMark ? '' : '?'}${searchParams.toString()}`;
};

export const redirectToDefaultRoutes = (
  navigate: NavigateFunction,
  aPathname: string,
  teams: ITeam[],
  orgId: number,
  params: Readonly<Params<string>>,
  featureFlags: FeatureFlags<TFeatureFlags>,
) => {
  const DOCS_SHORTCUT_URL = '/docs';
  const EDITOR_NEW_DOC_QUERY = '/editor/new';
  const TERMS_SHORTCUT_URL = '/team/terms';
  const SNIPPETS_SHORTCUT_URL = '/team/snippets';
  const EDITOR_QUERY_REGEXP = /editor\/(.+)/;
  const lastAccessedTeam = LocalStorageService.getItem<Record<string, string> | null>(LocalStorageKey.usersLastTeam);
  const lastTeamId = (lastAccessedTeam && lastAccessedTeam[orgId]) || (head(teams) as ITeam)?.id;
  const pathname = aPathname as keyof typeof SHORTCUT_PORTAL_ROUTES;

  if (SHORTCUT_PORTAL_ROUTES[pathname]) {
    goToHrefWithReload(SHORTCUT_PORTAL_ROUTES[pathname](orgId));

    return;
  } else if ((pathname as string) === EDITOR_NEW_DOC_QUERY) {
    navigate(SHORTCUT_ROUTES[EDITOR_NEW_DOC_QUERY](orgId, lastTeamId));

    return;
  } else if (pathname.startsWith(EDITOR_NEW_DOC_QUERY)) {
    navigate(ROUTE.toNewDocumentWithTemplates(orgId, lastTeamId, params.categoryId, params.templateId));

    return;
  } else if (pathname.startsWith(TERMS_SHORTCUT_URL)) {
    if (featureFlags.get('waMyWorkPage', false)) {
      navigate(ROUTE.toNewTerms(orgId, lastTeamId));

      return;
    } else {
      navigate(ROUTE.toTerms(orgId, lastTeamId));

      return;
    }
  } else if (pathname.startsWith(SNIPPETS_SHORTCUT_URL)) {
    if (featureFlags.get('waMyWorkPage', false)) {
      navigate(ROUTE.toNewSnippets(orgId, lastTeamId));

      return;
    } else {
      navigate(ROUTE.toSnippets(orgId, lastTeamId));

      return;
    }
  } else {
    const queryRx = pathname.match(EDITOR_QUERY_REGEXP);

    if (queryRx) {
      const search = decodeURIComponent(queryRx[1]);
      getDocumentsList(orgId, lastTeamId as number, DocsRequestTypes.ALL, {
        search,
        offset: 0,
        limit: 1,
      })
        .then(({ result }) => {
          if (!result.length) {
            throw new Error('Not found');
          }

          navigate(ROUTE.toEditorPage(orgId, lastTeamId, (head(result) as IDocument).id));
        })
        .catch(() => navigate(ROUTE.toHome(orgId, lastTeamId)));
    }
  }

  if ((pathname as string) === DOCS_SHORTCUT_URL) {
    goToHome(navigate, orgId, false, lastTeamId as number, false);
  } else {
    const lastDocument: Record<string, number> = LocalStorageService.getItem(LocalStorageKey.usersLastDocument) || {};
    const docId = lastDocument[lastTeamId] || 0;
    const routeToRedirect = (SHORTCUT_ROUTES as any)[pathname]
      ? (SHORTCUT_ROUTES as any)[pathname](orgId, lastTeamId, docId)
      : pathname;

    goToHrefWithReload(routeToRedirect);
  }
};

/**
 * Extracts the encoded path from a URL.
 *
 * @param {string} href - The URL to extract the encoded path from.
 * @returns {string} - The encoded path with encoded parameters.
 */
export const extractEncodedPath = (href: string): string => {
  const parsedUrl = new URL(href);
  const params = new URLSearchParams(parsedUrl.search);
  const encodedParams = new URLSearchParams();

  params.forEach((value, key) => {
    encodedParams.append(encodeURIComponent(key), encodeURIComponent(value));
  });

  return encodeURIComponent(`${parsedUrl.pathname}?${encodedParams.toString()}`);
};

export const goToAppGuide = (navigate: NavigateFunction, organizationId: number, applicationId: string) => () =>
  navigate(ROUTE.toAiStudioGuide(`${organizationId}`, `${applicationId}`));

/**
 * Method to open the contact sales link in a new tab
 */
export const openContactSalesPage = () => openNewTab(CONTACT_SALES_LINK);

/**
 * Method to open the support email window in a new tab
 */
export const launchSupportEmail = () => openNewTab(supportLinkHref);

/**
 * Method to open the Voice Setup page shortcut in a new tab
 */
export const openVoiceSetupShortcut = () => openNewTab(`/setup/voice`);

/**
 * Using the supportLinkHref in an anchor tag to create an email link
 */
const supportLinkHref = `mailto:${SUPPORT_EMAIL}`;

/**
 * Navigates the window to the specified location.
 *
 * @param {string} locationHref - The URL of the location where to navigate the window.
 * @returns {boolean} False indicating that the navigation was not blocked.
 */
export const goToNative = (locationHref: string): false => {
  window.location.href = locationHref;

  return false;
};
