import React from 'react';

import cx from 'classnames';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import emptyPageIcon from '../../../assets/icons/paper.png';

import styles from './NoSearchResultsBanner.module.css';

interface INoSearchResultsBannerProps {
  className?: string;
}

export const NoSearchResultsBanner: React.FC<INoSearchResultsBannerProps> = ({ className }) => (
  <div className={cx(styles.container, className)}>
    <img className={styles.icon} src={emptyPageIcon} alt="empty-page" />
    <Text variant={TextSize.XXXXL} color={TextColor.GREY2} medium>
      No results
    </Text>
  </div>
);
