import { computed, makeObservable } from 'mobx';

import { TOAuthAppsResponse } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { OAuthAppsApiModel } from '../../../models/oauthApps.api';
import { TOAuthAppsModalManager } from '../../pages/OAuthAppsPage/OAuthAppsPageModel.ui';

interface OAuthAppsTableUIModelOptions {
  api: OAuthAppsApiModel;
  modalsManager: TOAuthAppsModalManager;
}

export class OAuthAppsTableUIModel {
  api: OAuthAppsApiModel;

  modalsManager: TOAuthAppsModalManager;

  constructor(private opts: OAuthAppsTableUIModelOptions) {
    this.api = this.opts.api;

    this.modalsManager = this.opts.modalsManager;
    makeObservable(this, {
      isLoading: computed,
      hasNext: computed,
      data: computed,
    });
  }

  get data(): TOAuthAppsResponse[] {
    return this.api.data ?? [];
  }

  get isLoading(): boolean {
    return this.opts.api.isLoading;
  }

  get hasNext(): boolean {
    return this.opts.api.pagination.hasNext ?? false;
  }

  loadMore = async () => {
    await this.opts.api.pagination.next();
  };

  get showLoader(): boolean {
    return this.isLoading && isEmpty(this.data);
  }
}
