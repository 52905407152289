import { type operations } from '@writercolab/network';
import { type DropdownOption } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

export type TGetOrganizationSessionsQueryParams = NonNullable<
  operations['getOrganizationSessions']['parameters']['query']
>;
export type TGetTeamSessionsQueryParams = NonNullable<operations['getTeamSessions']['parameters']['query']>;

export enum SessionsPageParams {
  SESSION_ID = 'sessionId',
}

export const TSessionsSort = new Enum('modificationTime', 'lastOpenedTime');

export const SessionSortOptions: DropdownOption[] = [
  { id: TSessionsSort.enum.modificationTime, name: 'Last updated' },
  { id: TSessionsSort.enum.lastOpenedTime, name: 'Last opened by me' },
];
