import type React from 'react';

import { useParams } from 'react-router';

import { QUICKSTART_VIDEOS } from '../../../constants/LearningCenter';
import OnboardingShowcaseModal from '../../molecules/OnboardingShowcaseModal';

interface ILearningCenterVideo {
  onNavigateToVideo: (route: string) => void;
  onNavigateToLearningCenter: () => void;
  onNavigateToAskWriter: () => void;
  onNavigateToBlogBuilder: () => void;
  onNavigateToRecaps: () => void;
}

const LearningCenterFullScreenVideoModal: React.FC<ILearningCenterVideo> = ({
  onNavigateToVideo,
  onNavigateToLearningCenter,
  onNavigateToAskWriter,
  onNavigateToBlogBuilder,
  onNavigateToRecaps,
}) => {
  const params = useParams();
  const videoIndex = QUICKSTART_VIDEOS.findIndex(payload => payload.route === params.videoId);

  const videoPayload = QUICKSTART_VIDEOS[videoIndex];

  const ctaActionMap = {
    0: onNavigateToAskWriter,
    1: onNavigateToBlogBuilder,
    2: onNavigateToRecaps,
  };

  return (
    <OnboardingShowcaseModal
      title={videoPayload.title}
      url={videoPayload.url}
      duration={videoPayload.duration}
      ctaButtonLabel={videoPayload.ctaButtonLabel}
      onClose={onNavigateToLearningCenter}
      onClickCtaButton={ctaActionMap[videoPayload.id]}
      onClickPreviousVideo={() => onNavigateToVideo(QUICKSTART_VIDEOS[videoIndex - 1]?.route)}
      onClickNextVideo={() => onNavigateToVideo(QUICKSTART_VIDEOS[videoIndex + 1]?.route)}
      isPreviousVideoEnabled={!!QUICKSTART_VIDEOS[videoIndex - 1]}
      isNextVideoEnabled={!!QUICKSTART_VIDEOS[videoIndex + 1]}
    />
  );
};

export default LearningCenterFullScreenVideoModal;
