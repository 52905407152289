import { Button, ButtonTypes, SizeTypes } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

export const CreatePasswordButton = ({ onCreatePassword, createPasswordLoading }) => (
  <div className={styles.createPasswordButtonContainer}>
    <Button
      type={ButtonTypes.SECONDARY}
      size={SizeTypes.SMALL}
      content="Create password"
      onClick={onCreatePassword}
      className={styles.createPasswordButton}
      isLoading={createPasswordLoading}
    />
  </div>
);
