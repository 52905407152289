import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import cx from 'classnames';

import {
  ApiErrorResponse,
  ILinkedTerm,
  ITerm,
  ITermCreateAndUpdate,
  MultiFieldError,
  TAddTermsState,
  TermErrorKeys,
  TermType,
  TermsPopupFields,
} from '@writercolab/common-utils';
import {
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  Switcher,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import {
  BacklinkedTermsList,
  CaseSensitiveDropdown,
  CommonMistakeInputGroup,
  ExampleInputGroup,
  InputGroup,
  InputTypes,
  PartOfSpeechDropdown,
  SuggestionsGroup,
  TagsGroup,
  TermTypeSwitcher,
  createTermMapper,
  initAddTermState,
  initMultiFieldError,
  suggestionsDefaultTooltips,
} from '@writercolab/ui-molecules';

import { CustomException, scrollToRef } from '@web/component-library';
import debounce from 'lodash/debounce';

import { useTermsContext } from '../../../context/termsContext';
import { getLogger } from '../../../utils/logger';

import styles from './styles.module.css';

const LOG = getLogger('AddTermsPanel');

interface IAddTermsPanel {}

type TTermsStateAction = {
  field?: keyof TAddTermsState;
  value?: any; // todo: check if we can pass a good value
  action?: TAddTermsAction;
};

enum TAddTermsAction {
  DEFAULT,
  RESET,
  RESET_ERRORS,
  OPEN_EXISTING,
  TAGS,
  CASE_SENSITIVE,
}

const reducer = (state: TAddTermsState, { field, value, action }: TTermsStateAction) => {
  switch (action) {
    case TAddTermsAction.RESET:
      return initAddTermState;
    case TAddTermsAction.RESET_ERRORS:
      return {
        ...state,
        termError: '',
        descriptionError: '',
        examplesError: initMultiFieldError,
        mistakesError: initMultiFieldError,
        tagsError: '',
      };
    case TAddTermsAction.OPEN_EXISTING:
      return value;
    case TAddTermsAction.CASE_SENSITIVE:
      return value
        ? { ...state, caseSensitive: true }
        : { ...state, caseSensitive: false, capitalize: false, fixCase: false };
    case TAddTermsAction.DEFAULT:
    default:
  }

  return {
    ...state,
    ...(field && { [field]: value }),
  };
};

const MIN_LOADING_DISPLAY_TIME = 2000;

const additionalOptions = [
  {
    name: 'Delete term',
    id: 'removeModal',
    icon: <Icon name={IconVariant.TRASH} />,
    active: false,
  },
];

type TBackLinkedTermsList = {
  [TermType.BANNED]: ILinkedTerm[];
  [TermType.USE_CAREFULLY]: ILinkedTerm[];
};

const InfoTooltipContent = ({ text }) => (
  <div className={styles.infoTooltipContainer}>
    <Text variant={TextSize.S} color={TextColor.WHITE}>
      {text}
    </Text>
  </div>
);

export const AddTermsPanel: React.FC<IAddTermsPanel> = () => {
  const {
    termsContext,
    handleTermSubmit,
    handleToggleTermIdModal,
    handleOpenEditTerm,
    handleSuggestedTermsSearch,
    handleSuggestedTermCreate,
  } = useTermsContext();
  const { termBankId, tags, openedTerm, suggestedTermsList, suggestedTermsSearchTerm } = termsContext;

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const termForSaveRef = useRef<TAddTermsState>();
  const [termForSave, setTermForSave] = useState<TAddTermsState>();
  const [filteredSuggestedTerms, setFilteredSuggestedTerms] = useState<ITerm[]>(suggestedTermsList || []);
  const [minimumTimeElapsed, setMinimumTimeElapsed] = useState(true);
  const isTermSavingRef = useRef(false);
  const [state, dispatch] = useReducer<React.Reducer<TAddTermsState, TTermsStateAction>>(reducer, initAddTermState);

  const isApprovedTerm = state.termType === TermType.APPROVED;
  const isBannedTerm = state.termType === TermType.BANNED;
  const isPendingTerm = state.termType === TermType.PENDING;
  const isUseCarefullyTerm = state.termType === TermType.USE_CAREFULLY;

  const backLinkedTerms = useMemo<TBackLinkedTermsList>(() => {
    if (!isApprovedTerm || !state.backlinkedTerms.length) {
      return {
        [TermType.BANNED]: [],
        [TermType.USE_CAREFULLY]: [],
      };
    }

    return state.backlinkedTerms.reduce(
      (backLinkedTermsList, term) => {
        if (term.type === TermType.BANNED) {
          backLinkedTermsList[TermType.BANNED].push(term);
        } else if (term.type === TermType.USE_CAREFULLY) {
          backLinkedTermsList[TermType.USE_CAREFULLY].push(term);
        }

        return backLinkedTermsList;
      },
      {
        [TermType.BANNED]: [],
        [TermType.USE_CAREFULLY]: [],
      } as TBackLinkedTermsList,
    );
  }, [isApprovedTerm, state.backlinkedTerms]);

  const bannedBackLinkedTermsVisible = isApprovedTerm && backLinkedTerms[TermType.BANNED].length > 0;
  const useCarefullyBackLinkedTermsVisible = isApprovedTerm && backLinkedTerms[TermType.USE_CAREFULLY].length > 0;

  const variant = {
    showCommonMistakes: isApprovedTerm || isPendingTerm,
    showVisibilitySuggestionsSwitch: isBannedTerm || isUseCarefullyTerm,
    shouldShowSuggestionsGroup: isBannedTerm || isUseCarefullyTerm,
  };

  const queueTermSave = () => setTermForSave(state);

  useEffect(() => {
    if (termForSave) {
      termForSaveRef.current = state;
      onSaveContentDebounced();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [termForSave]);

  const onChangeTerm = e => {
    dispatch({ field: 'term', value: e.target.value });
    queueTermSave();
  };

  const onChangeDescription = e => {
    dispatch({ field: 'description', value: e.target.value });

    // disable suggestions for approved terms if there is no description
    if (!e.target.value && isApprovedTerm) {
      dispatch({ field: 'enableSuggestions', value: false });
    }

    queueTermSave();
  };

  const onChangeType = val => {
    dispatch({ field: 'termType', value: val });
    queueTermSave();

    if ([TermType.BANNED, TermType.USE_CAREFULLY].includes(val as TermType)) {
      dispatch({ field: 'commonMistake', value: [] });
    }

    if (val !== TermType.APPROVED) {
      const updatedTerms = filteredSuggestedTerms.filter(term => term.id !== openedTerm?.id);
      setFilteredSuggestedTerms(updatedTerms);
    }

    queueTermSave();
  };

  const onChangeSuggestedTerms = (terms: ILinkedTerm[]) => {
    dispatch({ field: 'linkedTerms', value: terms });
    queueTermSave();
  };

  const onUpdate = (val, updateType?: TermsPopupFields) => {
    /* Todo: below code is redundant. remove if extra logic is not needed */
    switch (updateType) {
      case TermsPopupFields.POS:
        dispatch({ field: 'partOfSpeech', value: val });
        break;
      case TermsPopupFields.CASE_SENSITIVE:
        dispatch({ field: 'caseSensitive', value: val, action: TAddTermsAction.CASE_SENSITIVE });
        break;
      case TermsPopupFields.EXAMPLE:
        dispatch({ field: 'example', value: val });
        break;
      case TermsPopupFields.COMMON_MISTAKE:
        dispatch({ field: 'commonMistake', value: val });
        break;
      case TermsPopupFields.TAGS:
        dispatch({ field: 'tags', value: val.map(v => ({ tag: v.name || v })) });
        break;
      case TermsPopupFields.ENABLE_SUGGESTIONS:
        dispatch({ field: 'enableSuggestions', value: val });
        break;
      case TermsPopupFields.CAPITALIZE:
        dispatch({ field: 'capitalize', value: val });
        break;
      case TermsPopupFields.FIX_CASE:
        dispatch({ field: 'fixCase', value: val });
        break;
      case TermsPopupFields.FIX_COMMON_MISTAKES:
        dispatch({ field: 'fixCommonMistakes', value: val });
        break;
      default:
    }

    queueTermSave();
  };

  const validate = (model: ITermCreateAndUpdate) =>
    new Promise<void>((resolve, reject) => {
      if (model.term.length === 0) {
        reject(new CustomException('this is a required field', TermErrorKeys.EMPTY_TERM_NAME));
      }

      resolve();
    });

  const restartLoadingTimer = () => {
    dispatch({ field: 'isLoading', value: true });
    setMinimumTimeElapsed(false);
    setTimeout(() => setMinimumTimeElapsed(true), MIN_LOADING_DISPLAY_TIME);
  };

  const onSaveContent = async () => {
    if (isTermSavingRef.current || !termForSaveRef.current) {
      onSaveContentDebounced();

      return;
    }

    const model = createTermMapper(termForSaveRef.current);
    termForSaveRef.current = undefined;
    isTermSavingRef.current = true;

    dispatch({ action: TAddTermsAction.RESET_ERRORS });
    restartLoadingTimer();

    try {
      await validate(model);
      await handleTermSubmit(model);
    } catch (e: any) {
      const responseData: ApiErrorResponse | undefined = e.response?.data;
      const mistakesErrors: MultiFieldError[] = [];
      responseData?.errors?.forEach(({ key, extras }) => {
        const hasFixCase = /\.fixCase$/.test(key);
        const keyNormalize = key.replace(/\.fixCase$/, '');

        switch (keyNormalize) {
          case TermErrorKeys.DUPLICATE_AS_TERM:
            dispatch({
              field: 'termError',
              value: 'Term intersects with mistake',
            });
            break;

          case TermErrorKeys.DUPLICATE_TERM:
            dispatch({
              field: 'termError',
              value: 'Term already exists. Make sure part of speech & casing are unique',
            });
            break;
          case TermErrorKeys.XSS_INJECTION:
            dispatch({
              field: 'termError',
              value: `Symbols like '<' are not supported`,
            });
            break;
          case TermErrorKeys.DUPLICATE_COMMON_MISTAKE:
          case TermErrorKeys.DUPLICATE_COMMON_MISTAKE_AS_TERM: {
            const message = hasFixCase
              ? 'Casing errors are already caught by "AUTO-CORRECT CAPS"'
              : 'This mistake already exists';
            const term = (extras as any)?.item?.term;
            const fieldPosition = model.mistakes.findIndex(
              mistake => Object.prototype.hasOwnProperty.call(mistake, 'id') && mistake.mistake === term,
            );
            mistakesErrors.push({ message, fieldPosition, term });
            break;
          }
          default: {
            if (e.message) {
              dispatch({ field: 'termError', value: e.message });
            }

            LOG.error(e);
          }
        }
      });

      dispatch({
        field: 'mistakesErrors',
        value: mistakesErrors,
      });
    } finally {
      dispatch({ field: 'isLoading', value: false });
      isTermSavingRef.current = false;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSaveContentDebounced = useCallback(debounce(onSaveContent, 1000), [termBankId]);

  const handleDeleteButtonClick = useCallback(() => handleToggleTermIdModal(true), [handleToggleTermIdModal]);

  const tagsSuggestion = useMemo(() => tags.map(t => ({ name: t })), [tags]);
  const tagsOption = useMemo(() => state.tags.map(t => ({ name: t.tag })), [state.tags]);

  useEffect(() => {
    if (!openedTerm) {
      inputRef.current?.focus();
      dispatch({ action: TAddTermsAction.RESET_ERRORS });
      dispatch({ action: TAddTermsAction.OPEN_EXISTING, value: initAddTermState });
    }

    // TODO: investigate why plugin doesn't work without timeout [WA-1652]
    // setTimeout(() => {
    //   autosize.update(descriptionRef.current);
    //   autosize.update(inputRef.current);
    // }, 0);
  }, [openedTerm]);

  useEffect(() => {
    if (openedTerm?.id) {
      dispatch({ action: TAddTermsAction.RESET_ERRORS });
      dispatch({ action: TAddTermsAction.OPEN_EXISTING, value: openedTerm });
      scrollToRef(inputRef.current!);
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedTerm?.id]);

  useEffect(() => {
    setFilteredSuggestedTerms(suggestedTermsList || []);
  }, [suggestedTermsList]);

  return (
    <div className={styles.styledForm}>
      <Text className={styles.saveError} color={TextColor.ORANGE} variant={TextSize.M}>
        {state.termError}
      </Text>

      <div className={styles.termInputContainer}>
        {/* TERM */}
        <textarea
          id="term"
          rows={1}
          value={state.term}
          onChange={onChangeTerm}
          className={cx(styles.termInput, { [styles.disabled]: isTermSavingRef.current })}
          placeholder="add a term"
          spellCheck={false}
          autoComplete="false"
          ref={inputRef}
        />

        {(state.isLoading || !minimumTimeElapsed) && (
          <Text className={styles.saving} variant={TextSize.XXS} smallCaps color={TextColor.GREY2}>
            Saving
          </Text>
        )}

        {/* Remove */}
        {!!state.id && (
          <div className={styles.removeModal}>
            <Dropdown
              placement={DropdownPlacement.BOTTOM_LEFT}
              dropDownContainerClassName={styles.removeContainer}
              trigger={<Icon name={IconVariant.ELLIPSES} />}
              options={additionalOptions}
              onPrimaryOptionClickAction={handleDeleteButtonClick}
            />
          </div>
        )}
      </div>
      {/* TYPE */}
      <div className={styles.termTypeSwitcher}>
        <TermTypeSwitcher value={state.termType ?? TermType.APPROVED} onChange={onChangeType} />
      </div>
      <Text variant={TextSize.XL} bold>
        Details
      </Text>
      <div className={styles.termContent}>
        {/* PART OF SPEECH & CASE SENSITIVE */}
        <div className={styles.posAndCaseContainer}>
          <div className={styles.posAndCaseHolder}>
            <PartOfSpeechDropdown showLabel value={state.partOfSpeech} onUpdate={onUpdate} />
            <CaseSensitiveDropdown showLabel value={state.caseSensitive} onUpdate={onUpdate} />
          </div>

          {state.caseSensitive && isApprovedTerm && (
            <div className={cx(styles.caseSensitiveVariations, { [styles.disabledContent]: !state.id })}>
              <div className={styles.switcher}>
                <Switcher<TermsPopupFields>
                  id="capitalize"
                  checked={state.capitalize}
                  onChange={onUpdate}
                  size={20}
                  fieldType={TermsPopupFields.CAPITALIZE}
                />
                <div className={styles.switcherLabel}>
                  <Text variant={TextSize.M}>Capitalize at the start of a sentence</Text>
                  <Tooltip
                    title={<InfoTooltipContent text={suggestionsDefaultTooltips.capsAtStart} />}
                    placement="top"
                    tooltipWidth={220}
                  >
                    <span>
                      <Icon name={IconVariant.INFO_OUTLINED} />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.switcher}>
                <Switcher<TermsPopupFields>
                  id="fix-case"
                  checked={state.fixCase}
                  onChange={onUpdate}
                  size={20}
                  fieldType={TermsPopupFields.FIX_CASE}
                />
                <div className={styles.switcherLabel}>
                  <Text variant={TextSize.M}>Automatically suggest correct capitalizations</Text>
                  <Tooltip
                    title={<InfoTooltipContent text={suggestionsDefaultTooltips.correctCaps} />}
                    placement="top"
                    tooltipWidth={220}
                  >
                    <span>
                      <Icon name={IconVariant.INFO_OUTLINED} />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={cx(styles.details, { [styles.disabledContent]: !state.id })}>
          {/* DESCRIPTION */}
          <div className={styles.descriptionHolder}>
            <InputGroup
              name="description"
              id="description"
              inputType={InputTypes.TEXTAREA}
              label={
                <Text caps variant={TextSize.XS}>
                  Description
                </Text>
              }
              inputRef={descriptionRef}
              errorText={state.descriptionError}
              value={state.description}
              handleChangeInput={onChangeDescription}
            />
          </div>

          {/* EXAMPLE ROWS */}
          <div className={styles.examplesContainer}>
            <ExampleInputGroup examples={state.example} onUpdate={onUpdate} error={state.examplesError} />
          </div>

          {variant.shouldShowSuggestionsGroup && (
            <div className={styles.suggestionsContainer}>
              <SuggestionsGroup
                termId={state.id}
                onTermClick={handleOpenEditTerm}
                linkedTerms={state.linkedTerms}
                onChange={onChangeSuggestedTerms}
                terms={filteredSuggestedTerms}
                searchTerm={suggestedTermsSearchTerm || ''}
                handleSearch={handleSuggestedTermsSearch}
                onTermCreated={terms => handleSuggestedTermCreate({ ...terms, caseSensitive: state.caseSensitive })}
              />
            </div>
          )}

          {variant.showCommonMistakes && (
            <div className={styles.mistakesContainer}>
              {/* COMMON MISTAKE ROWS */}
              <CommonMistakeInputGroup items={state.commonMistake} onUpdate={onUpdate} errors={state.mistakesErrors} />
            </div>
          )}

          {bannedBackLinkedTermsVisible && (
            <BacklinkedTermsList
              terms={backLinkedTerms[TermType.BANNED]}
              termsType={TermType.BANNED}
              onClick={handleOpenEditTerm}
            />
          )}

          {useCarefullyBackLinkedTermsVisible && (
            <BacklinkedTermsList
              terms={backLinkedTerms[TermType.USE_CAREFULLY]}
              termsType={TermType.USE_CAREFULLY}
              onClick={handleOpenEditTerm}
            />
          )}

          <div className={styles.tagsContainer}>
            {/* TAGS */}
            <TagsGroup
              error={state.tagsError}
              initialTags={tagsSuggestion}
              tags={tagsOption}
              onUpdate={values => onUpdate(values, TermsPopupFields.TAGS)}
            />
          </div>

          {/* FOOTER */}
          {!isPendingTerm && (
            <div className={styles.visibility}>
              <Text variant={TextSize.XL} bold className={styles.visibilityHeader}>
                Visibility
              </Text>
              {isApprovedTerm && (
                <>
                  <div className={cx(styles.switcher, styles.switcherStretched)}>
                    <div className={styles.switcherLabel}>
                      <Text variant={TextSize.M}>Enable approved term highlighting</Text>
                      <Tooltip
                        title={<InfoTooltipContent text={suggestionsDefaultTooltips.enableApprovedTerm} />}
                        placement="top"
                        tooltipWidth={220}
                      >
                        <span>
                          <Icon name={IconVariant.INFO_OUTLINED} />
                        </span>
                      </Tooltip>
                    </div>
                    <Tooltip
                      title={<InfoTooltipContent text={suggestionsDefaultTooltips.disabledSuggestionsTooltip} />}
                      placement="top"
                      tooltipWidth={220}
                      disabled={!isApprovedTerm || !!state.description}
                    >
                      <span style={{ cursor: 'pointer' }}>
                        <Switcher<TermsPopupFields>
                          id="enable-suggestions"
                          checked={state.enableSuggestions}
                          disabled={isApprovedTerm && !state.description}
                          onChange={onUpdate}
                          size={20}
                          fieldType={TermsPopupFields.ENABLE_SUGGESTIONS}
                        />
                      </span>
                    </Tooltip>
                  </div>

                  <div className={cx(styles.switcher, styles.switcherStretched)}>
                    <div className={styles.switcherLabel}>
                      <Text variant={TextSize.M}>Enable common mistake suggestion</Text>
                      <Tooltip
                        title={<InfoTooltipContent text={suggestionsDefaultTooltips.enableCommonMistakeTerm} />}
                        placement="top"
                        tooltipWidth={220}
                      >
                        <span>
                          <Icon name={IconVariant.INFO_OUTLINED} />
                        </span>
                      </Tooltip>
                    </div>
                    <Switcher<TermsPopupFields>
                      id="enable-common-mistakes"
                      checked={state.fixCommonMistakes}
                      onChange={onUpdate}
                      size={20}
                      fieldType={TermsPopupFields.FIX_COMMON_MISTAKES}
                    />
                  </div>
                </>
              )}
              {variant.showVisibilitySuggestionsSwitch && (
                <div className={cx(styles.switcher, styles.switcherStretched)}>
                  <div className={styles.switcherLabel}>
                    <Text variant={TextSize.M}>Enable suggestions</Text>
                    <Tooltip
                      title={
                        <InfoTooltipContent
                          text={`When checking content, we'll underline ${
                            isBannedTerm ? "don't use" : 'use carefully'
                          } terms in ${isBannedTerm ? 'red' : 'yellow'}.`}
                        />
                      }
                      placement="top"
                      tooltipWidth={220}
                    >
                      <span>
                        <Icon name={IconVariant.INFO_OUTLINED} />
                      </span>
                    </Tooltip>
                  </div>
                  <Switcher<TermsPopupFields>
                    id="enable-suggestions"
                    checked={state.enableSuggestions}
                    onChange={onUpdate}
                    size={20}
                    fieldType={TermsPopupFields.ENABLE_SUGGESTIONS}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTermsPanel;
