import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { SortFieldTypes, SortOrders, TermsSortingTypes, openNewTab } from '@writercolab/common-utils';
import type { components } from '@writercolab/network';
import type { DropdownOption } from '@writercolab/ui-atoms';
import {
  AVATAR_COLOR,
  ColorDot,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  LinkText,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { DropdownHeader } from '@writercolab/ui-molecules';

import type { IOriginalUser } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import type { peopleFilterType } from '../../../context/peopleContext';
import { UserStatusFilterIdentifier } from '../../../context/peopleContext';
import { useAppState } from '../../../state';
import { getTeamColor } from '../../../utils/teamUtils';
import type { IModifiedUser } from './PeopleListRow';
import { PeopleListRow } from './PeopleListRow';

import styles from './styles.module.css';

interface IPeopleList extends BaseComponentsProps {
  list: IOriginalUser[];
  isLoading?: boolean;
  actions: Record<string, Function>;
  hideMoreOptions?: boolean;
  isTeamUsersPage?: boolean;
  isSelfServe?: boolean;
  hideInvitePendingDropdown?: boolean;
  hidePendingApprovalDropdown?: boolean;
  handleSortingChange?: (sortType: TermsSortingTypes) => void;
  handleFilterChange?: (filterType: TermsSortingTypes, value: string) => void;
  sortField?: SortFieldTypes;
  sortOrder?: SortOrders;
  infiniteRefSentry?: any;
  filterType?: peopleFilterType;
  filterValue?: UserStatusFilterIdentifier;
  handleUserStatusFilterChange?: (filterValue: UserStatusFilterIdentifier) => void;
  handleBillingGroupFilterChange?: (filterId: number) => void;
  handleClearFilters?: () => void;
  hideBillingGroups?: boolean;
  billingGroups: components['schemas']['com_qordoba_user_dto_BillingGroupResponse'][];
  userFilterVal: string[];
  billingGroupFilterVal: string[];
}

const modifyPeopleList = peopleList =>
  peopleList.map(({ user, role, teams, approvedByInviter, billingGroup }) => ({
    ...user,
    fullName: user.fullName.trim(),
    approvedByInviter,
    billingGroup,
    role,
    teams: teams?.map(team => ({
      ...team,
      color: AVATAR_COLOR[getTeamColor(team.id)],
    })),
  }));

const LastSeenSortingDropdown = ({ onPrimaryOptionClickAction, sortField, sortOrder }) => (
  <DropdownHeader
    triggerText={<Text>Last seen</Text>}
    options={[
      {
        id: TermsSortingTypes.LAST_SEEN_ASC,
        name: 'Sort Ascending',
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active: sortField === SortFieldTypes.LAST_SEEN && sortOrder === SortOrders.ASC,
      },
      {
        id: TermsSortingTypes.LAST_SEEN_DESC,
        name: 'Sort Descending',
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active: sortField === SortFieldTypes.LAST_SEEN && sortOrder === SortOrders.DESC,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === SortFieldTypes.LAST_SEEN}
    sortDirection={sortOrder}
    dropdownPlacement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

const UserStatusSortingDropdown = ({
  onPrimaryOptionClickAction,
  userFilterValue,
}: {
  onPrimaryOptionClickAction: (filterId: string) => void;
  userFilterValue: string[];
}) => (
  <div className={styles.filterContainer}>
    <Dropdown
      containerClassName={cx(styles.filterDropdown, {
        [styles.filterDropdownActive]: !isEmpty(userFilterValue),
      })}
      trigger={<Icon name={IconVariant.FILTER} width={18} height={18} />}
      itemsType={ItemsTypes.SELECT}
      options={[
        {
          id: UserStatusFilterIdentifier.APPROVAL_PENDING,
          name: 'Approval pending',
          icon: <ColorDot color="#A400CD" className={styles.filtersDotIcon} size={12} />,
          active: userFilterValue.includes(UserStatusFilterIdentifier.APPROVAL_PENDING),
        },
        {
          id: UserStatusFilterIdentifier.INVITE_PENDING,
          name: 'Invite pending',
          icon: <ColorDot color="#6985FF" className={styles.filtersDotIcon} size={12} />,
          active: userFilterValue.includes(UserStatusFilterIdentifier.INVITE_PENDING),
        },
        {
          id: UserStatusFilterIdentifier.ACTIVE,
          name: 'Active',
          icon: <ColorDot color="#5EEBC1" className={styles.filtersDotIcon} size={12} />,
          active: userFilterValue.includes(UserStatusFilterIdentifier.ACTIVE),
        },
      ]}
      useCheckbox
      hideOnCLick={false}
      onPrimaryOptionClickAction={onPrimaryOptionClickAction}
      placement={DropdownPlacement.BOTTOM_RIGHT}
      dropdownItemClassName={styles.dropdownItem}
    />
  </div>
);

const BillingGroupSortingDropdown = ({
  onPrimaryOptionClickAction,
  userFilterValue,
  options,
}: {
  onPrimaryOptionClickAction: (filterId: number) => void;
  userFilterValue: string[];
  options: DropdownOption<number>[];
}) => (
  <div className={styles.filterContainer}>
    <Dropdown
      containerClassName={cx(styles.filterDropdown, {
        [styles.filterDropdownActive]: !isEmpty(userFilterValue),
      })}
      trigger={<Icon name={IconVariant.FILTER} width={18} height={18} />}
      itemsType={ItemsTypes.SELECT}
      options={options.map(option => ({
        ...option,
        active: userFilterValue?.includes(option.id.toString()),
      }))}
      onPrimaryOptionClickAction={onPrimaryOptionClickAction}
      placement={DropdownPlacement.BOTTOM_RIGHT}
      dropdownItemClassName={styles.dropdownItem}
      hideOnCLick={false}
      useCheckbox
      testId="billing-group-filter"
    />
  </div>
);

const openBillingGroupSupportPage = () => openNewTab('https://support.writer.com/article/247-billing-groups');

export const PeopleList = observer(
  // eslint-disable-next-line react/display-name
  React.forwardRef<any, IPeopleList>(
    (
      {
        className,
        list,
        isLoading,
        actions,
        hideMoreOptions,
        isTeamUsersPage,
        isSelfServe,
        hideBillingGroups,
        hideInvitePendingDropdown,
        hidePendingApprovalDropdown,
        handleSortingChange,
        handleUserStatusFilterChange,
        handleBillingGroupFilterChange,
        sortField,
        sortOrder,
        infiniteRefSentry,
        handleClearFilters,
        billingGroups,
        userFilterVal,
        billingGroupFilterVal,
        ...props
      },
      ref,
    ) => {
      const {
        appModel: {
          user: userProfile,
          assistantSubscription: { isMultiTeam, isEnterprise, isTeam },
        },
      } = useAppState();
      const [modifiedList, setModifiedList] = useState<IModifiedUser[]>([]);
      const isPeoplePage = !isTeamUsersPage;

      const access = {
        deleteUsersActionVisible: isPeoplePage || (isEnterprise && !isMultiTeam) || (isTeam && !isMultiTeam),
        removeUsersActionVisible: !isPeoplePage && isEnterprise && isMultiTeam,
        updateBillingGroupActionVisible: isPeoplePage || isEnterprise || isMultiTeam,
      };

      const _handleSortingChange = useCallback(
        (sortType: TermsSortingTypes) => {
          handleSortingChange && handleSortingChange(sortType);
        },
        [handleSortingChange],
      );

      const _handleUserStatusFilterChange = (filterId: string) => {
        const filterValue = Object.values(UserStatusFilterIdentifier).find(filter => filter === filterId);

        if (filterValue) {
          handleUserStatusFilterChange?.(filterValue);
        }
      };

      const _handleBillingGroupFilterChange = (filterId: number) => {
        handleBillingGroupFilterChange?.(filterId);
      };

      useEffect(() => {
        setModifiedList(modifyPeopleList(list));
      }, [list]);

      const isListEmpty = useMemo(() => !isLoading && !modifiedList.length, [isLoading, modifiedList.length]);
      const billingGroupsOptions: DropdownOption<number>[] = billingGroups.map(({ id, name }) => ({ id, name }));

      return (
        <MuiTableContainer className={cx(styles.container, className)}>
          <MuiTable stickyHeader aria-label="people list" {...props} ref={ref} style={{ paddingBottom: 150 }}>
            <MuiTableHead className={styles.tableHead}>
              <MuiTableRow>
                <MuiTableCell align="left" key="tableCellName" sx={{ width: '245px' }}>
                  <Text variant={TextSize.L}>Name</Text>
                </MuiTableCell>
                <MuiTableCell align="left" key="tableCellEmail" sx={{ width: '175px' }}>
                  <Text variant={TextSize.L}>Email</Text>
                </MuiTableCell>
                {!hideBillingGroups && (
                  <MuiTableCell align="left" key="tableCellBillingGroup" sx={{ width: '150px' }}>
                    <div className={styles.tableCellBillingGroupWrapper}>
                      <Text variant={TextSize.L}>Billing group</Text>
                      {billingGroups.length > 0 && (
                        <>
                          <Tooltip
                            placement="top"
                            className={styles.infoTooltip}
                            title={
                              <Text variant={TextSize.S} color={TextColor.WHITE} medium>
                                Assign users to a billing group to attribute them to internal budgets.{' '}
                                <LinkText onClick={openBillingGroupSupportPage}>Learn more.</LinkText>
                              </Text>
                            }
                            tooltipWidth={180}
                          >
                            <span>
                              <Icon name={IconVariant.INFO_OUTLINED} />
                            </span>
                          </Tooltip>

                          <BillingGroupSortingDropdown
                            onPrimaryOptionClickAction={_handleBillingGroupFilterChange}
                            userFilterValue={billingGroupFilterVal}
                            options={billingGroupsOptions}
                          />
                        </>
                      )}
                    </div>
                  </MuiTableCell>
                )}
                {isPeoplePage && (
                  <MuiTableCell align="left" key="tableCellTeams" sx={{ width: '95px' }}>
                    <Text variant={TextSize.L}>Teams</Text>
                  </MuiTableCell>
                )}
                <MuiTableCell align="left" key="tableCellStatus" sx={{ width: '110px' }}>
                  <div className={styles.filtersContainer}>
                    <Text variant={TextSize.L}>Status</Text>
                    <UserStatusSortingDropdown
                      onPrimaryOptionClickAction={_handleUserStatusFilterChange}
                      userFilterValue={userFilterVal}
                    />
                  </div>
                </MuiTableCell>
                <MuiTableCell
                  align="left"
                  className={styles.tableCellLastSeen}
                  key="tableCellLastSeen"
                  sx={{ width: '110px' }}
                >
                  <LastSeenSortingDropdown
                    onPrimaryOptionClickAction={_handleSortingChange}
                    sortField={sortField}
                    sortOrder={sortOrder}
                  />
                </MuiTableCell>
                <MuiTableCell align="right" sx={{ width: '50px' }} />
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody className={styles.tableBody}>
              {modifiedList.map(user => (
                <PeopleListRow
                  key={user.id}
                  user={user}
                  actions={actions}
                  profileId={userProfile?.id}
                  hideBillingGroups={hideBillingGroups}
                  hideInvite={hideInvitePendingDropdown}
                  hidePendingApproval={hidePendingApprovalDropdown}
                  isPeoplePage={isPeoplePage}
                  isSelfServe={isSelfServe}
                  isTeamUsersPage={isTeamUsersPage}
                  hideMoreOptions={hideMoreOptions}
                  removeUsersActionVisible={access.removeUsersActionVisible}
                  deleteUsersActionVisible={access.deleteUsersActionVisible}
                  updateBillingGroupActionVisible={access.updateBillingGroupActionVisible}
                />
              ))}
              <div ref={infiniteRefSentry} />
              {isLoading && (
                <MuiTableRow>
                  <MuiTableCell align="center" colSpan={6}>
                    Loading...
                  </MuiTableCell>
                </MuiTableRow>
              )}
              {isListEmpty && (
                <MuiTableRow>
                  <MuiTableCell align="center" colSpan={6} className={styles.emptyCell}>
                    <div className={styles.emptyContainer}>
                      <div className={styles.emptyHeroTextContainer}>
                        <Text variant={TextSize.XXXL} color={TextColor.GREY2}>
                          No users found matching your filters
                        </Text>
                      </div>
                      <div className={cx(styles.emptyContainerButton, styles.clickable)} onClick={handleClearFilters}>
                        <div className={styles.emptyIconContainer}>
                          <Icon name={IconVariant.FILTER} width={32} height={32} className={styles.emptyIcon} />
                        </div>
                        <div className={styles.emptyTextContainer}>
                          <Text variant={TextSize.XXL} color={TextColor.GREY2}>
                            Clear filters
                          </Text>
                        </div>
                      </div>
                    </div>
                  </MuiTableCell>
                </MuiTableRow>
              )}
            </MuiTableBody>
          </MuiTable>
        </MuiTableContainer>
      );
    },
  ),
);

export default PeopleList;
