import type React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  HeadingVariant,
  Icon,
  IconVariant,
  SearchBar,
  SizeTypes,
  Slideout,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import type { TTeamPromptEditActionDto } from '@web/types';
import { TTeamPromptActionId } from '@web/types';
import { observer } from 'mobx-react-lite';

import emptyPagePath from '../../../assets/backgrounds/emptyPage.png';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { DeleteConfirmationModal } from '../../molecules/DeleteConfirmationModal';
import { TeamPromptsInitialState } from '../../molecules/TeamPromptsInitialState';
import SectionHeading from '../../organisms/SectionHeading';
import { TeamPromptPanel } from '../../organisms/TeamPromptPanel';
import { TeamPromptsTable } from '../../organisms/TeamPromptsTable';
import type { TeamPromptsPageUiModel } from './TeamPromptsPage.ui';

import styles from './TeamPromptsPage.module.css';

interface TeamPromptsPageProps {
  model: TeamPromptsPageUiModel;
}

const ViewOnlyReason: React.FC<{ reason: string }> = ({ reason }) => (
  <Text variant={TextSize.M} color={TextColor.WHITE} className={styles.tooltipViewOnly}>
    {reason}
  </Text>
);

export const TeamPromptsPage = observer<TeamPromptsPageProps>(({ model }) => {
  usePageTitle('Team Prompts');
  const {
    appState,
    appModel: { permissionsModel },
  } = useAppState();
  const { teamPromptsTableUiModel, searchPhrase, setSearchPhrase, resetSearch } = model;
  const { deleteMode, editMode } = teamPromptsTableUiModel;
  const isAdmin = appState.team ? permissionsModel?.isTeamAdminOf(appState.team.id) : false;
  const teamName = appState.team?.name || '';

  const handleDeletePrompt = (promptId: string) => {
    model.teamPromptsTableUiModel.setPromptId(promptId);
    model.teamPromptsTableUiModel.setDeleteMode(true);
  };

  const handleDuplicatePrompt = (promptId: string) => {
    model.duplicate(promptId);
    model.teamPromptsTableUiModel.setEditMode(false);
  };

  const handlePromptActionClick = (actionId: typeof TTeamPromptActionId.type, promptId?: string) => {
    if (actionId === TTeamPromptActionId.enum.favorite && promptId) {
      model.toggleFavorite(promptId);

      return;
    }

    if (actionId === TTeamPromptActionId.enum.delete && promptId) {
      handleDeletePrompt(promptId);

      return;
    }

    if (actionId === TTeamPromptActionId.enum.duplicate && promptId) {
      handleDuplicatePrompt(promptId);

      return;
    }

    model.teamPromptsTableUiModel.setEditMode(false);
    model.teamPromptsTableUiModel.setPromptId(promptId);
    model.teamPromptsTableUiModel.setEditMode(
      ([TTeamPromptActionId.enum.edit, TTeamPromptActionId.enum.add] as string[]).includes(actionId),
    );
  };

  const handleTeamPromptAction = async (teamPromptActionDto: TTeamPromptEditActionDto) => {
    if (teamPromptActionDto.id) {
      await model.editTeamPrompt(teamPromptActionDto);
    } else {
      await model.addTeamPrompt(teamPromptActionDto);
    }

    model.teamPromptsTableUiModel.setEditMode(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.preload}>
        <img src={emptyPagePath} alt="preload" />
      </div>
      <SectionHeading
        className={styles.heading}
        heading="Team Prompts"
        headingUppercase
        headingVariant={HeadingVariant.H2}
        subHeading={teamName}
        subHeadingUppercase
        descriptionContainerClassName={styles.description}
        description={
          !model.empty && <Text>Create and share frequently-used prompts with your team to use in Ask Writer</Text>
        }
        descriptionTextVariant={TextSize.XL}
        viewOnlyReason={
          isAdmin ? undefined : (
            <Text variant={TextSize.M} color={TextColor.WHITE} className={styles.tooltipViewOnly}>
              Team members have view only access to team prompts
            </Text>
          )
        }
      />
      <div className={styles.searchContainer}>
        {!model.empty && (
          <>
            <SearchBar
              id="search"
              value={searchPhrase.value}
              placeholder="Search"
              onChange={e => setSearchPhrase(e.target.value)}
              handleClearInput={() => resetSearch()}
              className={styles.searchBar}
            />
            <Tooltip
              tooltipWidth={200}
              disabled={isAdmin}
              title={<ViewOnlyReason reason="Only admins can add new team prompts" />}
            >
              <Button
                disabled={!isAdmin}
                type={ButtonTypes.PRIMARY}
                className={styles.newButton}
                size={SizeTypes.MEDIUM}
                icon={
                  <Icon
                    name={IconVariant.ADD}
                    className={cx({
                      [styles.newButtonDisabledIcon]: !isAdmin,
                    })}
                  />
                }
                onClick={() => handlePromptActionClick(TTeamPromptActionId.enum.add)}
                content="New"
              />
            </Tooltip>
          </>
        )}
      </div>
      <div className={styles.table}>
        {model.empty ? (
          <TeamPromptsInitialState
            showContactText={!isAdmin}
            onAddClick={isAdmin ? () => handlePromptActionClick(TTeamPromptActionId.enum.add) : undefined}
          />
        ) : (
          <TeamPromptsTable
            model={teamPromptsTableUiModel}
            onPromptActionClick={(actionId, promptId) => {
              handlePromptActionClick(actionId, promptId);
            }}
            showActionsDropdown={isAdmin}
            rowClickEnabled={isAdmin}
          />
        )}
      </div>
      <Slideout
        isOpen={editMode}
        onClose={() => model.teamPromptsTableUiModel.setEditMode(false)}
        className={styles.slideout}
      >
        <TeamPromptPanel
          teamPromptsApiModel={model.teamPromptsApiModel}
          promptId={teamPromptsTableUiModel.promptId}
          onPromptActionClick={handleTeamPromptAction}
          loading={model.teamPromptsApiModel.loading}
          onPromptRemoveClick={handleDeletePrompt}
          onPromptDuplicateClick={handleDuplicatePrompt}
        />
      </Slideout>
      <DeleteConfirmationModal
        modalClassName={styles.deletePromptModal}
        modalTitle="Delete prompt"
        bodyContent={<Text variant={TextSize.M}>Are you sure you want to delete this prompt?</Text>}
        isOpen={deleteMode}
        onClose={() => {
          model.teamPromptsTableUiModel.setDeleteMode(false);
        }}
        closeButton={
          <Button
            className={styles.submitButton}
            type={ButtonTypes.DEFAULT}
            content="Never mind"
            onClick={() => {
              model.teamPromptsTableUiModel.setDeleteMode(false);
            }}
          />
        }
        submitButton={
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Delete prompt"
            onClick={() => {
              model.teamPromptsTableUiModel.deletePrompt();
              model.teamPromptsTableUiModel.setEditMode(false);
            }}
          />
        }
      />
    </div>
  );
});
