import React from 'react';

import styles from './TextVariableEmbed.module.css';

interface ITextVariableEmbedProps {
  text?: string;
  onSuicide?: () => void;
}

export const TextVariableEmbed: React.FC<ITextVariableEmbedProps> = ({ text, onSuicide }) => {
  const handleSuicide = () => {
    if (onSuicide) {
      onSuicide();
    }
  };

  return (
    <span className={styles.input} onClick={handleSuicide}>
      {text}
    </span>
  );
};
