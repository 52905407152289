import { CHAMELEON_NLG_TOUR_ID } from '../services/config/constants';
import { getLogger } from './logger';

const LOG = getLogger('chmlnUtils');

/**
 * This function triggers the display of the Chameleon guided tour in your application.
 * It is designed to show the guided tour only once to the user, leveraging Chameleon's
 * show method with the "once" option set to true.
 *
 * Usage:
 *   Call `showChmln()` anywhere in your code where you want to initiate the Chameleon guided tour.
 *
 * Requirements:
 *   - The Chameleon script must be correctly integrated into your application.
 *   - `CHAMELEON_NLG_TOUR_ID` must be a valid Chameleon tour ID, and should be defined in your code.
 */
export const showChameleonNlgTour = () => {
  if (typeof window !== 'undefined' && window.chmln && typeof CHAMELEON_NLG_TOUR_ID !== 'undefined') {
    window.chmln.show(CHAMELEON_NLG_TOUR_ID, { once: true });
  } else {
    LOG.error('Chameleon script not loaded or CHAMELEON_NLG_TOUR_ID not defined.');
  }
};
