import React from 'react';

import cx from 'classnames';

import { Heading, HeadingVariant, Tag, TagColor, Text, TextColor, TextSize, Tooltip } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IPageTitleProps extends BaseComponentsProps {
  title: string;
  teamName?: string;
  description?: string;
  tagContent?: string | React.ReactNode;
  tagTooltipContent?: string | React.ReactNode;
  tagColor?: TagColor;
  tagClassName?: string;
}

export const PageTitle: React.FC<IPageTitleProps> = ({
  title,
  teamName,
  className,
  description,
  tagContent,
  tagTooltipContent,
  tagColor = TagColor.LIGHT_BLUE,
  tagClassName,
}) => (
  <div className={cx(styles.title, className)}>
    {teamName && (
      <Text variant={TextSize.XXS} smallCaps className={styles.titleTeam}>
        {teamName}
      </Text>
    )}

    <div className={styles.headingWrapper}>
      <Heading variant={HeadingVariant.H2} upperCase className={styles.heading}>
        {title}
      </Heading>
      {tagContent && (
        <Tooltip title={tagTooltipContent!} placement="top">
          <div>
            <Tag color={tagColor} upperCase className={cx(styles.labelTag, tagClassName)}>
              {tagContent}
            </Tag>
          </div>
        </Tooltip>
      )}
    </div>

    {description && (
      <Text variant={TextSize.XL} color={TextColor.GREY} className={styles.description}>
        {description}
      </Text>
    )}
  </div>
);

export default PageTitle;
