import type { ChangeEvent } from 'react';
import React, { useCallback, useEffect, useMemo } from 'react';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  RadioGroup,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';

import { WRITER_OAUTH_APP_TYPES } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { OAuthAppsApiModel } from 'models/oauthApps.api';
import { useSearchParams } from 'react-router';

import oauthAppsEmptyBg from '../../../assets/backgrounds/emptyOAuthApps.png';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { OAuthAppsEntry } from './OAuthAppsEntry';
import { OAuthAppsModalContainer } from './OAuthAppsModalContainer';
import { OAuthAppsPageUIModel } from './OAuthAppsPageModel.ui';

import styles from './OAuthAppsPage.module.css';

const radioGroupOptions = [
  {
    value: WRITER_OAUTH_APP_TYPES.MANAGED,
    className: styles.radioGroupOption,
    text: (
      <div className={styles.radioGroupTextWrapper}>
        <Text variant={TextSize.XL} bold>
          Use Writer-managed OAuth apps to sync data
        </Text>
        <Text variant={TextSize.XL}>
          Use an OAuth app configured by Writer for easy
          <br />
          setup to sync your data sources.
        </Text>
      </div>
    ),
  },
  {
    value: WRITER_OAUTH_APP_TYPES.OWN,
    className: styles.radioGroupOption,
    text: (
      <div className={styles.radioGroupTextWrapper}>
        <Text variant={TextSize.XL} bold>
          Use self-managed OAuth apps to sync data
        </Text>
        <Text variant={TextSize.XL}>
          Create your own OAuth apps to manage how
          <br />
          Writer accesses your data source.
        </Text>
      </div>
    ),
  },
];

export const OAuthAppsPage = observer(() => {
  const { appState, appModel } = useAppState();
  const { organizationId, teamId } = appState;
  const [searchParams] = useSearchParams();

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.oAuthAppsViewedPage, {});
  }, [appModel.analyticsService]);

  const model = useMemo(() => {
    if (!organizationId || !teamId) return null;

    return new OAuthAppsPageUIModel({
      api: new OAuthAppsApiModel({
        request: requestService.api,
        organizationId,
        teamId,
      }),
      analyticsService: appModel.analyticsService,
      isConfluenceConnectorEnabled: () => appModel.featureFlags.get('confluenceConnectorEnabled', false),
    });
  }, [appModel.analyticsService, appModel.featureFlags, organizationId, teamId]);

  useQueueWorkerNotifications(model?.notificationQueue);

  const handleOpenModal = useCallback(() => {
    model?.openViewerModal();
  }, [model]);

  const onSwitchAppsType = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value;

      model?.setAppsType(val);
    },
    [model],
  );

  if (!model) return null;

  return (
    <div className={styles.container}>
      <div className={styles.headWrapper}>
        <div className={styles.headTopWrapper}>
          <div className={styles.titleHeadWrapper}>
            <Heading variant={HeadingVariant.H2} upperCase>
              OAuth apps
            </Heading>
          </div>
          <div className={styles.descriptionOverviewWrapper}>
            <div className={styles.descriptionTextWrapper}>
              <Text variant={TextSize.XL} as="span" color={TextColor.BLACK}>
                To set up data connectors for Knowledge Graph, you must authorize Writer to
                <br />
                access your third-party data sources with OAuth apps.
              </Text>{' '}
              <a
                href="https://support.writer.com/article/251-setting-up-knowledge-graph-data-connectors"
                target="_blank"
                className={styles.link}
              >
                Learn more.
              </a>
            </div>
          </div>
        </div>
        <div className={styles.infoControlsWrapper}>
          <div className={styles.infoDescriptionWrapper}>
            <Text variant={TextSize.XL} as="span" color={TextColor.BLACK}>
              Select how you want to manage permissioning for your OAuth apps
            </Text>
          </div>
          <div className={styles.infoRadioGroupWrapper}>
            <RadioGroup
              name="appsType"
              className={styles.radioGroupWrapper}
              options={radioGroupOptions}
              onChange={onSwitchAppsType}
              currentValue={model.appsType}
            />
          </div>
        </div>
      </div>

      {model.appsType === WRITER_OAUTH_APP_TYPES.OWN && (
        <>
          {model.isStateEmpty && searchParams.size === 0 && (
            <div className={styles.emptyStateWrapper}>
              <div className={styles.descriptionButtonWrapper}>
                <Button type={ButtonTypes.PRIMARY} size={SizeTypes.LARGE} onClick={handleOpenModal}>
                  Set up a new app
                </Button>
              </div>
              <div className={styles.emptyBgWrapper}>
                <img src={oauthAppsEmptyBg} alt="OAuth Apps Empty" />
              </div>
            </div>
          )}

          {(!model.isStateEmpty || searchParams.size !== 0) && <OAuthAppsEntry model={model} />}
        </>
      )}

      <OAuthAppsModalContainer model={model} />
    </div>
  );
});
