import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './AddContentButton.module.css';

interface IAddContentButton extends BaseComponentsProps {
  className?: string;
  onClick?: () => void;
}

export const AddContentButton: React.FC<IAddContentButton> = ({ className, onClick }) => (
  <div className={cx(styles.iconRoundContainer, styles.plusIcon, className)} onClick={onClick}>
    <Icon name={IconVariant.PLUS} />
  </div>
);
