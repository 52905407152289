import { type ITemplateDraftFeedbackParams } from '@writercolab/common-utils';
import { type RequestServiceInitialize } from '@writercolab/network';
import type { TGetDraftsPaginatedResponse } from '@writercolab/types';

import type { TDraftWithInputsResponse, TOutputsQueryParams } from '@web/types';
import { getLogger } from 'utils/logger';

const LOG = getLogger('Outputs.api');

export interface IOutputsApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: () => number;
}

export class OutputsApiModel {
  constructor(private readonly opts: IOutputsApiModelOptions) {
    this.opts = opts;
  }

  getOutputs = async (payload: TOutputsQueryParams): Promise<TGetDraftsPaginatedResponse> => {
    try {
      const { data } = await this.opts.request.put(
        '/api/template/organization/{organizationId}/team/{teamId}/draft/get',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: this.opts.teamId(),
            },
          },
          body: payload,
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to get outputs', e);
      throw e;
    }
  };

  getOutput = async (outputId: number): Promise<TDraftWithInputsResponse> => {
    try {
      const { data } = await this.opts.request.get(
        '/api/template/organization/{organizationId}/team/{teamId}/draft/{draftId}',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: this.opts.teamId(),
              draftId: outputId,
            },
          },
        },
      );

      const { inputs, ...rest } = data;

      return {
        inputs: inputs as TDraftWithInputsResponse['inputs'],
        ...rest,
      };
    } catch (e) {
      LOG.error('Failed to get output', {
        outputId,
        error: e,
      });

      throw e;
    }
  };

  addOutputFeedback = async (outputId: number, documentId: number, payload: ITemplateDraftFeedbackParams) => {
    try {
      const { data } = await this.opts.request.post(
        '/api/template/organization/{organizationId}/team/{teamId}/document/{documentId}/draft/{draftId}/feedback',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: this.opts.teamId(),
              documentId,
              draftId: outputId,
            },
          },
          body: payload,
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to get outputs', e);
      throw e;
    }
  };
}
