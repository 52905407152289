import React from 'react';

import cx from 'classnames';

import { getDomElementOffset } from '@writercolab/common-utils';

import { AnalyticsActivity } from 'constants/analytics';
import analytics from 'services/analytics/analyticsService';

import { CssStyles, IStepConfig, calculateArrowStyle, toCssProperty } from '../common/common';

import styles from '../styles.module.css';

export const teamAdminSteps: IStepConfig[] = [
  {
    id: 1,
    title: 'Welcome to Writer, %userName%!',
    heading: (
      <div className={cx(styles.headingImgContainerCentered, styles.headingImgContainerOrange)}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerHello)} />
      </div>
    ),
    showDimmer: true,
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedWelcomeStep, {});
    },
    description:
      'Ready to get your whole team writing with one voice? Let’s take a 2-min tour through Writer so you know where to begin.',
  },
  {
    id: 2,
    title: 'Crisp and on-brand Suggestions',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerSuggestions)} />
      </div>
    ),
    showDimmer: true,
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedSuggestionsStep, {});
    },
    description:
      'Customize Writer to your team’s content guidelines in just a few clicks. You can control suggestions like style, clarity, and inclusivity, so it’s like you’re always by their side.',
    navigatorArrowStyle: (): CssStyles => calculateArrowStyle('top-nav-suggestions'),
  },
  {
    id: 3,
    title: 'Your Terms, however you like',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerTerms)} />
      </div>
    ),
    showDimmer: true,
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedTermsStep, {});
    },
    description:
      'Manage your terms and unique company language all in one place. Your team will get in-line suggestions to correct wrong usage as they write.',
    navigatorArrowStyle: (): CssStyles => calculateArrowStyle('top-nav-terms'),
  },
  {
    id: 4,
    title: 'Save time with snippets',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerSnippets)} />
      </div>
    ),
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedSnippetsStep, {});
    },
    showDimmer: true,
    description:
      'You know that text you’re constantly retyping or pasting across emails and chat? Add it as a snippet, then use a simple shortcut to insert it anywhere.',
    navigatorArrowStyle: (): CssStyles => calculateArrowStyle('top-nav-snippets'),
  },
  {
    id: 5,
    title: 'Get suggestions everywhere',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerApps)} />
      </div>
    ),
    showDimmer: true,
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedAppsStep, {});
    },
    description:
      'Writer works across the web. Install a Writer app to get writing suggestions in the websites and tools you’re already using.',
    navigatorArrowStyle: (): CssStyles => calculateArrowStyle('top-nav-apps'),
  },
  {
    id: 6,
    title: 'Share your style guide in a flash',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerStyleguide)} />
      </div>
    ),
    showDimmer: true,
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedStyleguideStep, {});
    },
    description:
      'In just a few clicks, you can take the guidelines you set up in Writer and publish a shareable style guide that your whole team can access.',
    navigatorArrowStyle: (): CssStyles => {
      const el = document.getElementById('styleguide-link-section') as HTMLElement;
      const offset = getDomElementOffset(el);

      return { minHeight: '59px', width: '203px', left: '17px', top: toCssProperty(offset.top + 13) };
    },
  },
  {
    id: 7,
    title: 'Ready to see Writer in action?',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerDocs)} />
      </div>
    ),
    description:
      'Let’s head over to your demo doc so you can see how Writer checks your content in real time as you type.',
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewsDemoDocStep, {});
    },
    navigatorArrowStyle: (): CssStyles => {
      const el = document.getElementById('dashboard-documents-table') as HTMLElement;
      const offset = getDomElementOffset(el);

      let style: Record<string, string> = { minHeight: '59px', width: '325px' };

      if (offset) {
        if (offset.left) {
          style = { ...style, left: toCssProperty(offset.left) };
        }

        if (offset.top) {
          style = { ...style, top: toCssProperty(offset.top + 51) };
        }
      }

      return style;
    },
  },
];
