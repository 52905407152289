import { useCallback, useEffect, useState } from 'react';

import qs from 'qs';

import { SearchBar } from '@writercolab/ui-atoms';
import { FilterIndicator } from '@writercolab/ui-molecules';

import { useFilterCount } from 'hooks/useFilterCount';
import { observer } from 'mobx-react-lite';
import { useLocation, useSearchParams } from 'react-router';
import { REACT_RELATIVE_ROUTE } from 'services/config/routes';

import { type MyWorkPageUiModel } from '../MyWorkPageModel.ui';
import { NewDocumentBtn, NewSessionBtn } from './Buttons';

import styles from './HeaderActions.module.css';

type THeaderActionsProps = {
  model: MyWorkPageUiModel;
  organizationId: number | undefined;
  teamId: number | undefined;
};

export const HeaderActions = observer(({ model, organizationId, teamId }: THeaderActionsProps) => {
  const { filterIndicatorKeys } = model;

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get('search') || '');
  const { filterCount, clearFilters } = useFilterCount([...filterIndicatorKeys]);

  const location = useLocation();
  const { pathname } = location;
  const isDocsTab = pathname.includes(`${REACT_RELATIVE_ROUTE.docs}`);
  const isSessionsTab = pathname.includes(`${REACT_RELATIVE_ROUTE.sessions}`);

  const updateUrlParams = useCallback(
    (params: Record<string, string>) => {
      const currentParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });
      const mergedParams = { ...currentParams, ...params };

      setSearchParams(qs.stringify(mergedParams, { arrayFormat: 'repeat' }));
    },
    [searchParams, setSearchParams],
  );

  const updateSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);

      updateUrlParams({
        search: value,
      });
    },
    [updateUrlParams],
  );

  const handleClearFilters = useCallback(() => {
    clearFilters();
    model.clearFilterIndicationKeys();
  }, [model, clearFilters]);

  const handleSearchInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      updateSearchValue(value);
      model.onSetInputValue(value);
    },
    [model, updateSearchValue],
  );

  const handleClearSearchInput = useCallback(() => {
    setSearchValue('');
    model.onClearSearchInput();
  }, [model, setSearchValue]);

  useEffect(() => {
    if (!searchParams.get('search')) {
      setSearchValue('');
    }
  }, [pathname, searchParams]);

  return (
    <div className={styles.container}>
      {filterCount > 0 && <FilterIndicator filtersAmount={filterCount} onClose={handleClearFilters} />}
      <div className={styles.searchBarContainer}>
        <SearchBar
          id="search"
          placeholder="Search"
          onChange={handleSearchInputChange}
          value={searchValue}
          handleClearInput={handleClearSearchInput}
          className={styles.searchBar}
        />
      </div>
      {isDocsTab && (
        <NewDocumentBtn organizationId={organizationId} teamId={teamId} notificationQueue={model.notificationQueue} />
      )}
      {isSessionsTab && (
        <NewSessionBtn organizationId={organizationId} teamId={teamId} notificationQueue={model.notificationQueue} />
      )}
    </div>
  );
});
