import React, { useState } from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, Input, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface CheckoutCouponProps {
  onApply: (couponCode: string) => void;
  onRemove: () => void;
  isLoading?: boolean;
  isValid?: boolean | null;
  isVisible?: boolean | null;
  couponCode?: string | null;
}

const CheckIcon = () => <Icon name={IconVariant.CHECK} width={14} height={10} />;
const ErrorMessage = () => (
  <div className={styles.errorContainer}>
    <Text variant={TextSize.XS} smallCaps>
      We didn't recognize that code. Double check and try again.
    </Text>
  </div>
);

export const CheckoutCoupon: React.FC<CheckoutCouponProps> = ({
  onApply,
  onRemove,
  isValid,
  couponCode,
  isLoading = false,
  isVisible = false,
}) => {
  const [visible, setVisible] = useState(isVisible);
  const [coupon, setCoupon] = useState(couponCode || '');

  const handleCodeChange = e => setCoupon(e.target.value);
  const handleSubmit = e => {
    e.preventDefault();

    onApply && onApply(coupon);
  };
  const handleRemove = e => {
    e.preventDefault();

    setCoupon('');
    setVisible(false);
    onRemove && onRemove();
  };

  const handleTriggerClick = e => {
    e.preventDefault();

    setVisible(!visible);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerCouponTrigger} onClick={handleTriggerClick}>
        <Text variant={TextSize.XS}>Add coupon code</Text>
      </div>
      <div className={styles.inputContainer}>
        {visible && (
          <form action="" onSubmit={handleSubmit} className={styles.inputContainerForm}>
            <Input
              className={cx(styles.input, {
                [styles.inputValid]: isValid === true,
                [styles.inputInvalid]: isValid === false,
              })}
              value={coupon}
              placeholder="Coupon code"
              onChange={handleCodeChange}
              disabled={isValid === true || isLoading}
              autoFocus
            />
            {!isValid && (
              <Button htmlType="submit" type={ButtonTypes.BLACK} className={styles.button} isLoading={isLoading}>
                {!isLoading && <Text variant={TextSize.M}>Apply</Text>}
              </Button>
            )}
            {isValid === true && (
              <>
                <div className={styles.checkContainer}>
                  <CheckIcon />
                </div>
                <div className={cx(styles.removeContainer)} onClick={handleRemove}>
                  <Text variant={TextSize.M}>Remove</Text>
                </div>
              </>
            )}
          </form>
        )}
        {visible && isValid === false && <ErrorMessage />}
      </div>
    </div>
  );
};

export default CheckoutCoupon;
