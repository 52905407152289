import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';

import type { BaseComponentsProps } from '@writercolab/common-utils';
import { Dropdown, DropdownPlacement, ItemsTypes, SearchBar, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { TermsLimitReached } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';

import { SnippetsFilterKey, useSnippetsContext } from '../../../../context/snippetsContext';
import { TermsAccessEnum } from '../../../../context/termsContext';
import { goToBilling } from '../../../../utils/navigationUtils';
import PageTitle from '../../../molecules/PageTitle';
import { ImportItemType } from '../../../organisms/ImporterPopup/types';
import { headerTooltipText } from '../../termsAndSnippetsShared/common';
import {
  CreateTermContainer,
  DeleteButton,
  DownloadButton,
  FreeSubscriptionTermsLimitReached,
  ImportButton,
  TagsFiltersContainer,
} from '../../termsAndSnippetsShared/commonComponents';
import { useTermsHeaderActions } from '../../termsAndSnippetsShared/useTermsHeaderActions';

import styles from '../../termsAndSnippetsShared/TermsHeader.module.css';

interface SnippetsHeaderProps extends BaseComponentsProps {
  isTeamNameShown: boolean;
  addNewItemsDisabled: boolean;
  orgId: number | undefined;
}

const SnippetsLimitReachedTooltipText = ({ navigate, orgId }) => (
  <FreeSubscriptionTermsLimitReached
    onCtaClick={() => goToBilling(navigate, orgId)}
    variant={TermsLimitReached.SNIPPET}
  />
);

export const SnippetsHeader = ({ isTeamNameShown = false, addNewItemsDisabled = true, orgId }: SnippetsHeaderProps) => {
  const {
    snippetsContext,
    handleSearch,
    handleDownloadSnippets,
    handleOpenNewTermPopup,
    handleToggleModal,
    handleOpenImportSnippetsPopup,
    handleFilterChange,
  } = useSnippetsContext();

  const navigate = useNavigate();
  const { onChangeSearchEvent, handleClearInput, handleDeleteButtonClick, downloadDropdownOptions } =
    useTermsHeaderActions(snippetsContext.clipMode, handleSearch, handleToggleModal);

  const access = {
    isTermsLoaded: snippetsContext.isTermsLoaded,
    showViewOnlyTag: snippetsContext.termsAccess !== TermsAccessEnum.ADMIN,
    viewOnlyTagTooltip:
      snippetsContext.termsAccess === TermsAccessEnum.TEAM_MEMBER
        ? headerTooltipText.viewOnlyTeamMember(ImportItemType.SNIPPETS)
        : headerTooltipText.viewOnlyAnotherTeam(snippetsContext.manageTeam?.name),
    showDownloadButton: !!snippetsContext.snippetsList.length,
  };

  const teamName = (isTeamNameShown && snippetsContext.currentTeam?.snippet?.teamName) || '';
  const isFilterTagsApplied = snippetsContext.filters[SnippetsFilterKey.TAGS].filter(filter => filter.isSelected);

  const handleLimitTooltipClick = useCallback(() => {
    orgId && goToBilling(navigate, orgId);
  }, [navigate, orgId]);

  const importButtonTooltipText = useMemo(() => {
    if (addNewItemsDisabled) {
      return <SnippetsLimitReachedTooltipText navigate={navigate} orgId={orgId} />;
    } else {
      return (
        <Text variant={TextSize.L} color={TextColor.WHITE}>
          {headerTooltipText.importBtn}
        </Text>
      );
    }
  }, [addNewItemsDisabled, navigate, orgId]);

  const handleTagsFilterClose = () => {
    const updatedTags = snippetsContext.filters[SnippetsFilterKey.TAGS].map(filter => ({
      ...filter,
      isSelected: false,
    }));
    handleFilterChange(SnippetsFilterKey.TAGS, updatedTags);
  };

  return (
    <div className={styles.header}>
      <div className={styles.headerInlineItem}>
        <PageTitle title="Snippets" teamName={teamName} className={styles.pageTitle} />
      </div>
      <div className={styles.headerInlineItem}>
        {!isEmpty(snippetsContext.selectedTerms) && (
          <DeleteButton
            className={styles.staticWidthButton}
            testId="ai-assistant-snippets-delete"
            numOfSelectedTerms={snippetsContext.selectedTerms.length}
            onClick={handleDeleteButtonClick}
          />
        )}

        {!isEmpty(isFilterTagsApplied) && (
          <TagsFiltersContainer filtersAmount={isFilterTagsApplied.length} onClose={handleTagsFilterClose} />
        )}

        {access.isTermsLoaded && !access.showViewOnlyTag && (
          <ImportButton
            className={cx(styles.importButton, styles.staticWidthButton, {
              [styles.importButtonDisabled]: addNewItemsDisabled,
            })}
            testId="ai-assistant-snippets-import"
            onClick={handleOpenImportSnippetsPopup}
            disabled={addNewItemsDisabled}
            buttonTooltipText={importButtonTooltipText}
          />
        )}
        <SearchBar
          id="search"
          value={snippetsContext.searchTerm}
          placeholder="Search"
          onChange={onChangeSearchEvent}
          className={styles.searchBar}
          handleClearInput={handleClearInput}
        />

        <div className={styles.newButtonContainer}>
          {access.isTermsLoaded && (
            <CreateTermContainer
              showViewOnlyTag={access.showViewOnlyTag}
              viewOnlyTagTooltip={access.viewOnlyTagTooltip}
              handleOpenNewTermPopup={handleOpenNewTermPopup}
              title="New snippet"
              disabled={addNewItemsDisabled}
              buttonTooltipEnabled={!addNewItemsDisabled}
              buttonTooltipText={
                <FreeSubscriptionTermsLimitReached
                  onCtaClick={handleLimitTooltipClick}
                  variant={TermsLimitReached.SNIPPET}
                />
              }
            />
          )}
        </div>
        {access.showDownloadButton && (
          <Dropdown
            trigger={
              <DownloadButton
                className={cx(styles.controlButton, styles.downloadBtn, styles.staticWidthButton)}
                testId="ai-assistant-snippets-download"
                isLoading={snippetsContext.downloadInProgress}
              />
            }
            options={downloadDropdownOptions}
            onPrimaryOptionClickAction={handleDownloadSnippets}
            placement={DropdownPlacement.BOTTOM_RIGHT}
            itemsType={ItemsTypes.ACTION}
          />
        )}
      </div>
    </div>
  );
};

export default SnippetsHeader;
