import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import {
  IIdentityProvider,
  IIdentityProviderName,
  IIdpMetaData,
  IScimConfig,
  deleteSsoScimSettings,
  downloadXML,
  getIdentityProviders,
  getSsoScimSettings,
  getSsoScimToken,
  getSsoSettings,
  initializeSsoScimSettings,
  removeSsoSettings,
  updateSsoScimSettings,
  updateSsoSettings,
  validateDomains,
  validateXML,
} from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { RemoveConfiguration } from '../components/pages/SsoPage/modals/removeProviderModal';

import { snackbarMessages } from '@web/component-library';
import { WithChildren } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import { useAppState } from '../state';

const defaultProvider: IIdentityProvider = {
  idpType: {
    id: 0,
    idp: '',
    name: '',
    imageUrl: '',
  },
  domains: [],
  idpMetaDataConfigBase64: '',
  idpMetaData: {
    ssoUrl: '',
    certificateFingerprintBase64: '',
    entityId: '',
  },
  remoteLogoutUrl: '',
  allowUserCreation: false,
  defaultTeamIds: [],
  sessionTimeout: 0,
  allowDirectPasswordLogin: false,
  creatorUserId: undefined,
};

const omittedProviderFields = [
  'lastModified',
  'addDate',
  'deleted',
  'creatorUserId',
  'updatedUserId',
  'sessionTimeout',
  'idpMetaData',
];

interface ISsoContext {
  currentProvider: IIdentityProvider | null;
  storedProvider: IIdentityProvider;
  identityProviders: IIdentityProviderName[];
  isLoading: boolean;
  isValidating: boolean;
  isFileDownloading: boolean;
  scimConfigEnabled: boolean;
  scimToken: string;
  scimConfig: IScimConfig | null;
  startEditing: (isEditing: boolean) => void;
  setIsModalOpen: (isEditing: boolean) => void;
  handleUpdateProvider: (provider: IIdentityProvider) => void;
  handleValidateDomains: (domains: string[]) => Promise<boolean>;
  handleDownloadSPXML: () => void;
  initScimConfig: () => Promise<void>;
  deleteScimConfig: () => Promise<void>;
  refreshScimToken: () => Promise<void>;
  handleXMLUpload: (base64File: string) => Promise<IIdpMetaData>;
  handleUpdateScimConfig: (scimConfig: IScimConfig) => Promise<void>;
}

const SsoContext = createContext<ISsoContext>({} as ISsoContext);

export const SsoContextProvider: React.FC<WithChildren> = ({ children }) => {
  const { appState } = useAppState();
  const [currentProvider, setCurrentProvider] = useState<IIdentityProvider | null>(null);
  const [scimConfigEnabled, setScimConfigEnabled] = useState(false);
  const [scimConfig, setScimConfig] = useState<IScimConfig | null>(null);
  const [scimToken, setScimToken] = useState('');
  const [storedProvider, setStoredProvider] = useState<IIdentityProvider>(defaultProvider);
  const [identityProviders, setIdentityProviders] = useState<IIdentityProviderName[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFileDownloading, setIsFileDownloading] = useState(false);
  const { enqueueSuccessSnackbar, enqueueDeleteSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const orgId = appState.organizationId;

  const getIdentityProvider = useCallback(async () => {
    setIsLoading(true);

    const provider = await getSsoSettings(Number(orgId));

    setCurrentProvider(provider);
    setStoredProvider(provider || defaultProvider);

    const scimConfig = await getSsoScimSettings(Number(orgId));
    setScimConfigEnabled(!!scimConfig);
    !isEmpty(scimConfig) && setScimConfig(scimConfig);

    setIsLoading(false);
  }, [orgId]);

  const getAllIdentityProviders = useCallback(async () => {
    const providers = await getIdentityProviders();
    setIdentityProviders(providers.idpTypes);
  }, []);

  useEffect(() => {
    getIdentityProvider();
    getAllIdentityProviders();
  }, [getAllIdentityProviders, getIdentityProvider]);

  const startEditing = useCallback(
    (isEditing: boolean) => {
      setCurrentProvider(isEditing ? null : storedProvider);
    },
    [storedProvider],
  );

  const decodeError = e => (e.message ? e.message : e.response.data.errors[0].description);
  const handleUpdateScimConfig = useCallback(
    async (scimConfig: IScimConfig) => {
      try {
        const _scimConfig = await updateSsoScimSettings(Number(orgId), scimConfig);
        setScimConfig(_scimConfig);
        enqueueSuccessSnackbar(snackbarMessages.sso.updateSuccess);
      } catch (e) {
        const error = decodeError(e);
        enqueueErrorSnackbar(snackbarMessages.sso.updateError(error));
      }
    },
    [enqueueErrorSnackbar, enqueueSuccessSnackbar, orgId],
  );

  const handleUpdateProvider = useCallback(
    async (provider: IIdentityProvider) => {
      setIsValidating(true);
      try {
        // Omit fields, because BE can't process them
        const omitedProvider = omit(provider, omittedProviderFields) as IIdentityProvider;
        const result: IIdentityProvider = await updateSsoSettings(Number(orgId), omitedProvider);
        // This will switch off Editing mode
        setCurrentProvider(result);
        setStoredProvider(result);
        enqueueSuccessSnackbar(snackbarMessages.sso.updateSuccess);
      } catch (e) {
        const error = decodeError(e);
        enqueueErrorSnackbar(snackbarMessages.sso.updateError(error));
      } finally {
        setIsValidating(false);
      }
    },
    [enqueueErrorSnackbar, enqueueSuccessSnackbar, orgId],
  );

  const handleDeleteProvider = useCallback(async () => {
    setIsLoading(true);
    try {
      await removeSsoSettings(Number(orgId));
      // This will switch to editing mode
      setCurrentProvider(null);
      setStoredProvider(defaultProvider);
      enqueueDeleteSnackbar(snackbarMessages.sso.removeProviderSuccess);
    } catch (e) {
      const error = decodeError(e);
      enqueueErrorSnackbar(snackbarMessages.sso.removeProviderError(error));
    } finally {
      setIsLoading(false);
    }
  }, [enqueueDeleteSnackbar, enqueueErrorSnackbar, orgId]);

  const handleValidateDomains = useCallback(
    async (domains: string[]): Promise<boolean> => {
      setIsValidating(true);
      try {
        return await validateDomains(Number(orgId), domains);
      } catch {
        enqueueErrorSnackbar(snackbarMessages.sso.addDomainError);

        return Promise.resolve(false);
      } finally {
        setIsValidating(false);
      }
    },
    [enqueueErrorSnackbar, orgId],
  );

  const handleXMLUpload = useCallback(
    async (base64File: string): Promise<IIdpMetaData> => {
      setIsValidating(true);
      try {
        const result = await validateXML(Number(orgId), base64File);
        enqueueSuccessSnackbar(snackbarMessages.sso.uploadXMLSuccess);

        if (!result) {
          throw new Error('Invalid XML');
        }

        return result;
      } catch (e) {
        const error = decodeError(e);
        enqueueErrorSnackbar(snackbarMessages.sso.updateError(error));

        return Promise.resolve(defaultProvider.idpMetaData!);
      } finally {
        setIsValidating(false);
      }
    },
    [enqueueErrorSnackbar, enqueueSuccessSnackbar, orgId],
  );

  const handleDownloadSPXML = useCallback(async () => {
    setIsFileDownloading(true);
    try {
      const blob = await downloadXML(Number(orgId));
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `WriterMetadataXML.xml`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (e) {
      const error = decodeError(e);
      enqueueErrorSnackbar(snackbarMessages.sso.addDownloadError(error));
    } finally {
      setIsFileDownloading(false);
    }
  }, [enqueueErrorSnackbar, orgId]);

  const refreshScimToken = useCallback(async () => {
    const token = await getSsoScimToken(Number(orgId));
    setScimToken(token);
  }, [orgId]);

  const initScimConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      await initializeSsoScimSettings(Number(orgId));
      setScimConfigEnabled(true);
      await refreshScimToken();
    } catch (e) {
      const error = decodeError(e);
      enqueueErrorSnackbar(snackbarMessages.sso.addDownloadError(error));
    } finally {
      setIsLoading(false);
    }
  }, [enqueueErrorSnackbar, orgId, refreshScimToken]);

  const deleteScimConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      await deleteSsoScimSettings(Number(orgId));
      setScimToken('');
      setScimConfigEnabled(false);
    } catch (e) {
      const error = decodeError(e);
      enqueueErrorSnackbar(snackbarMessages.sso.addDownloadError(error));
    } finally {
      setIsLoading(false);
    }
  }, [enqueueErrorSnackbar, orgId]);

  return (
    <SsoContext.Provider
      value={{
        currentProvider,
        storedProvider,
        identityProviders,
        isLoading,
        isValidating,
        isFileDownloading,
        scimToken,
        scimConfigEnabled,
        scimConfig,
        handleUpdateScimConfig,
        startEditing,
        setIsModalOpen,
        initScimConfig,
        deleteScimConfig,
        refreshScimToken,
        handleUpdateProvider,
        handleValidateDomains,
        handleDownloadSPXML,
        handleXMLUpload,
      }}
    >
      {children}

      {isModalOpen && (
        <RemoveConfiguration
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          onSubmit={handleDeleteProvider}
        />
      )}
    </SsoContext.Provider>
  );
};

export function useSsoContext() {
  const context = useContext(SsoContext);

  if (!context) {
    throw new Error('useSsoContext must be used within the SsoContextProvider');
  }

  return context;
}

export default SsoContextProvider;
