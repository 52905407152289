import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { autorun } from 'mobx';

import type { ITemplateDraft } from '@writercolab/common-utils';
import { LocalStorageKey, LocalStorageService, openNewTab } from '@writercolab/common-utils';
import { ModalsManager } from '@writercolab/models';
import { ApiError } from '@writercolab/network';
import { ContentGenerationJobStatus } from '@writercolab/types';
import { useCustomSnackbar, useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { RecapsFileUploaderModel } from 'components/organisms/RecapsFileUploader';

import type { ActionMap, IRecapJob, TContentGenerationJobStatus, TTemplateDraft } from '@web/types';
import {
  RECAPS_APP_ID,
  SUPPORTED_SUBTITLES_FILE_EXT,
  contentGenerationJobInputFields,
  recapsMessages,
  recapsRecordingType,
  recapsRecordingTypeTitle,
} from '@web/types';
import { useDocumentsContext } from 'context/documentsContext';
import delay from 'lodash/delay';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { useParams, useSearchParams } from 'react-router';
import { ROUTE } from 'services/config/routes';
import { generateRecaps } from 'services/request/recaps';
import { useAppState } from 'state';
import type { TAppState } from 'state/types';
import { extractBackendResponseErrorMessage } from 'utils/backendErrorUtils';
import { coWriteQuotaExceeded } from 'utils/coWriteUtils';
import { getFieldValue, isRecapsQueueJobType } from 'utils/recapsUtils';

import { appLocalStorage } from '../models/localStorage';
import requestService from '../services/request/requestService';
import { useDraftsContext } from './draftsContext';

export const RecapsModalType = new Enum('rateDraft');

interface IRecapsProvider {
  children?: ReactNode;
  organizationId: number | undefined | string;
  teamId: TAppState['teamId'] | string;
  onUseDraft?: (draft: TTemplateDraft) => void;
  onClose?: () => void;
  onShowTemplates: () => void;
}

interface IEventTakeawaysContext {
  context: IEventTakeawaysState;
  uploadModel: RecapsFileUploaderModel;
  modalsManager: ModalsManager<typeof RecapsModalType.type, ITemplateDraft>;
  onClose?: () => void;
  onShowTemplates: () => void;
  onGenerate: () => void;
  onChangeRecordingType: (type: recapsRecordingType) => void;
  onChangeIsGeneratingContent: (isGenerating: boolean) => void;
  drafts: {
    list: ITemplateDraft[];
    highlightedDraftId: number;
    isExpandedDraft: boolean;
    deleteDraft: (draftId: number) => void;
    rateDraft: (rate: number, feedback: string) => void;
    getDrafts: () => void;
    expandDraft: (draftId: number) => void;
    exportDraft: (draftId: number) => void;
    closeExpandedDraft: () => void;
    copyDraft: (draft: ITemplateDraft) => void;
    goToDraft: (draftId: number) => void;
  };
}

interface IEventTakeawaysState {
  recordingType: recapsRecordingType;
  isGeneratingContent: boolean;
  hasError: boolean;
  transcriptLimit: number;
  transcriptLimitValue: number;
  transcriptLimitResetDate: string;
  isTranscriptLimitReached: boolean;
  isCowriteLimitReached: boolean;
}

enum TRecapsActionType {
  SetRecordingType = 'recordingType',
  SetIsGeneratingContent = 'isGeneratingContent',
  SetHasError = 'hasError',
  SetTranscriptLimit = 'transcriptLimit',
  SetTranscriptLimitValue = 'transcriptLimitValue',
  SetTranscriptLimitResetDate = 'transcriptLimitResetDate',
  SetIsTranscriptLimitReached = 'isTranscriptLimitReached',
  SetIsCowriteLimitReached = 'isCowriteLimitReached',
}

type TRecapsPayload = {
  [TRecapsActionType.SetRecordingType]: recapsRecordingType;
  [TRecapsActionType.SetIsGeneratingContent]: boolean;
  [TRecapsActionType.SetHasError]: boolean;
  [TRecapsActionType.SetTranscriptLimit]: number;
  [TRecapsActionType.SetTranscriptLimitValue]: number;
  [TRecapsActionType.SetTranscriptLimitResetDate]: string;
  [TRecapsActionType.SetIsTranscriptLimitReached]: boolean;
  [TRecapsActionType.SetIsCowriteLimitReached]: boolean;
};

type TEventTakeawaysActions = ActionMap<TRecapsPayload>[keyof ActionMap<TRecapsPayload>];

const RecapsContext = createContext<IEventTakeawaysContext>({} as IEventTakeawaysContext);

const recapsContextReducer = (state: IEventTakeawaysState, action: TEventTakeawaysActions) => {
  let newState: IEventTakeawaysState;

  switch (action.type) {
    case TRecapsActionType.SetRecordingType:
      newState = { ...state, recordingType: action.payload };
      break;

    case TRecapsActionType.SetIsGeneratingContent:
      newState = { ...state, isGeneratingContent: action.payload };
      break;

    case TRecapsActionType.SetHasError:
      newState = { ...state, hasError: action.payload };
      break;

    case TRecapsActionType.SetTranscriptLimit:
      newState = { ...state, transcriptLimit: action.payload };
      break;

    case TRecapsActionType.SetTranscriptLimitValue:
      newState = { ...state, transcriptLimitValue: action.payload };
      break;

    case TRecapsActionType.SetTranscriptLimitResetDate:
      newState = { ...state, transcriptLimitResetDate: action.payload };
      break;

    case TRecapsActionType.SetIsTranscriptLimitReached:
      newState = { ...state, isTranscriptLimitReached: action.payload };
      break;

    case TRecapsActionType.SetIsCowriteLimitReached:
      newState = { ...state, isCowriteLimitReached: action.payload };
      break;

    default:
      newState = { ...state };
  }

  return newState;
};

const DRAFT_HIGHLIGHT_TIMEOUT = 4000;

const initialRecapsState: IEventTakeawaysState = {
  recordingType: recapsRecordingType.EVENT,
  isGeneratingContent: false,
  hasError: false,
  transcriptLimit: 1,
  transcriptLimitValue: 0,
  transcriptLimitResetDate: '',
  isTranscriptLimitReached: false,
  isCowriteLimitReached: false,
};

const RecapsContextProvider: React.FC<IRecapsProvider> = observer(
  ({ onClose, onShowTemplates, onUseDraft, children }) => {
    const [searchParams] = useSearchParams();
    const { orgId, teamId, docId } = useParams();
    const { currentDocData, isEditorReady } = useDocumentsContext();
    const { appModel } = useAppState();
    const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();
    const {
      state: { documentDrafts, isExpandedDraft },
      onDeleteDraft,
      onRateDraft,
      handleExpandDraft,
      updateDraftId,
      getDocumentTemplateDrafts,
      onDraftCopy,
      downloadMediaFileTranscript,
    } = useDraftsContext();
    const [context, dispatch] = useReducer(recapsContextReducer, initialRecapsState);
    const [previousJobStatus, setPreviousJobStatus] = useState<TContentGenerationJobStatus>(
      ContentGenerationJobStatus.enum.completed,
    );
    const [isEditorSet, setIsEditorSet] = useState(false);
    const [highlightedDraftId, setHighlightedDraftId] = useState(Number(searchParams.get('draftId')) || 0);

    const uploadModel = useMemo(
      () =>
        new RecapsFileUploaderModel({
          request: requestService.api,
          organizationId: +orgId!,
          teamId: +teamId!,
          extensionsOverride: context.isTranscriptLimitReached ? SUPPORTED_SUBTITLES_FILE_EXT : undefined,
        }),
      [orgId, teamId, context.isTranscriptLimitReached],
    );

    const modalsManager: ModalsManager<typeof RecapsModalType.type, ITemplateDraft> = useMemo(
      () => new ModalsManager<typeof RecapsModalType.type, ITemplateDraft>(),
      [],
    );

    useQueueWorkerNotifications(uploadModel.notificationQueue);

    const onChangeRecordingType = (recordingType: recapsRecordingType) =>
      dispatch({
        type: TRecapsActionType.SetRecordingType,
        payload: recordingType,
      });

    const onChangeIsGeneratingContent = (isGenerating: boolean) =>
      dispatch({
        type: TRecapsActionType.SetIsGeneratingContent,
        payload: isGenerating,
      });

    const onChangeTranscriptLimit = (value: number) =>
      dispatch({
        type: TRecapsActionType.SetTranscriptLimit,
        payload: value,
      });

    const onChangeTranscriptLimitValue = (value: number) =>
      dispatch({
        type: TRecapsActionType.SetTranscriptLimitValue,
        payload: value,
      });

    const onChangeTranscriptLimitResetDate = (date: string) =>
      dispatch({
        type: TRecapsActionType.SetTranscriptLimitResetDate,
        payload: date,
      });

    const onChangeIsTranscriptLimitReached = (isLimitReached: boolean) =>
      dispatch({
        type: TRecapsActionType.SetIsTranscriptLimitReached,
        payload: isLimitReached,
      });

    const onChangeIsCowriteLimitReached = (isLimitReached: boolean) =>
      dispatch({
        type: TRecapsActionType.SetIsCowriteLimitReached,
        payload: isLimitReached,
      });

    const onChangeHasError = (hasError: boolean) =>
      dispatch({
        type: TRecapsActionType.SetHasError,
        payload: hasError,
      });

    const onDraftRate = (rate: number, feedback: string) => {
      const draft = modalsManager.getModalState(RecapsModalType.enum.rateDraft);

      if (draft?.id) {
        onRateDraft(draft.id, docId || '', {
          rate,
          feedback,
        });
      }

      modalsManager.hideModal(RecapsModalType.enum.rateDraft);
    };

    const onDraftDelete = (draftId: number) => {
      onDeleteDraft(draftId, docId || '');
    };

    const exportDraft = async (draftId: number) => {
      const draft = documentDrafts.find(draft => draft.id === draftId);
      const fileId = getFieldValue(draft?.inputs, contentGenerationJobInputFields.FILE_ID);

      if (!fileId) {
        return;
      }

      await downloadMediaFileTranscript(fileId, 'transcript.docx');
    };

    const onDraftExpand = (draftId: number) => {
      updateDraftId(draftId);
      handleExpandDraft();
    };

    const onDraftGoTo = (draftId: number) => {
      const isMyWorkPage = appModel.featureFlags.get('waMyWorkPage', false);

      if (isMyWorkPage) {
        openNewTab(ROUTE.toTeamOutputsWithOutputId(orgId, teamId, `outputId=${draftId}`));
      } else {
        openNewTab(ROUTE.toTeamDraftsWithDraftId(orgId, teamId, `openId=${draftId}`));
      }
    };

    const onGenerate = async () => {
      const documentId = String(appModel.documentId);
      // Check if there is another transcript in progress
      const isAnotherJobInProgress = appModel.generationJobNotifications.some(
        job =>
          job.documentId !== documentId &&
          job.status === ContentGenerationJobStatus.enum.generating &&
          isRecapsQueueJobType(job as IRecapJob),
      );

      onChangeHasError(false);
      onChangeIsGeneratingContent(true);

      if (isAnotherJobInProgress) {
        enqueueErrorSnackbar(recapsMessages.GENERATION_ERROR_TRANSCRIPT_IN_PROGRESS, {
          className: 'narrowSnackbar',
        });
        onChangeIsGeneratingContent(false);

        return;
      }

      try {
        await uploadModel.prepareFileForUpload();

        if (!uploadModel.uploadedFile) {
          onChangeHasError(true);
          onChangeIsGeneratingContent(false);

          return;
        }

        const { name, fileId: id } = uploadModel.uploadedFile;

        if (!id) {
          onChangeIsGeneratingContent(false);

          return;
        }

        const recordingTypeKey = Object.keys(recapsRecordingType).find(
          key => recapsRecordingType[key] === context.recordingType,
        );

        const inputs = [
          { name: contentGenerationJobInputFields.RECORDING_TYPE, value: [context.recordingType] },
          {
            name: contentGenerationJobInputFields.NAME,
            value: [name ?? `Recaps for your ${recapsRecordingTypeTitle[recordingTypeKey!]} recording`],
          },
          { name: contentGenerationJobInputFields.FILE_ID, value: [id] },
        ];

        await generateRecaps(orgId!, teamId!, documentId!, RECAPS_APP_ID, inputs);
        setIsEditorSet(false);
        setPreviousJobStatus(ContentGenerationJobStatus.enum.generating);
        uploadModel.existingFiles.refresh();
      } catch (error) {
        if (coWriteQuotaExceeded(error)) {
          enqueueErrorSnackbar(recapsMessages.COWRITE_LIMIT_ERROR);
        } else if (error instanceof ApiError) {
          enqueueErrorSnackbar(extractBackendResponseErrorMessage(error, recapsMessages.GENERATION_ERROR));
        } else {
          enqueueErrorSnackbar(recapsMessages.GENERATION_ERROR);
        }

        onChangeHasError(true);
        onChangeIsGeneratingContent(false);
        setPreviousJobStatus(ContentGenerationJobStatus.enum.error);
      }
    };

    useEffect(() => {
      if (appModel.assistantSubscription.limits?.coWrite?.exceeded) {
        onChangeIsCowriteLimitReached(true);
      } else if (appModel.assistantSubscription.limits?.transcript?.exceeded) {
        const transcriptLimit = appModel.assistantSubscription.limits.transcript;

        onChangeTranscriptLimit(transcriptLimit.limit);
        onChangeTranscriptLimitValue(transcriptLimit.value);
        transcriptLimit.blockedUntil && onChangeTranscriptLimitResetDate(transcriptLimit.blockedUntil);
        onChangeIsTranscriptLimitReached(transcriptLimit.exceeded);
      }
    }, [appModel.assistantSubscription.limits?.transcript, appModel.assistantSubscription.limits?.coWrite]);

    useEffect(() => {
      const storedDrafts = appLocalStorage.lastDraftValues.get();
      const latestDraft = documentDrafts.find(draft => draft.templateId === RECAPS_APP_ID);

      if (storedDrafts && !isEmpty(storedDrafts)) {
        const recordingType = storedDrafts[contentGenerationJobInputFields.RECORDING_TYPE];
        const fileId = storedDrafts[contentGenerationJobInputFields.FILE_ID];

        if (recordingType?.[0]) {
          onChangeRecordingType(recordingType[0] as recapsRecordingType);
        }

        if (fileId?.[0]) {
          uploadModel.existingFiles.onChangeFile(fileId[0]);
        }

        LocalStorageService.setItem(LocalStorageKey.lastDraftValues, null);
      } else if (latestDraft && currentDocData && !isEditorSet) {
        const recordingType = getFieldValue(latestDraft.inputs, contentGenerationJobInputFields.RECORDING_TYPE);
        const fileId = getFieldValue(latestDraft.inputs, contentGenerationJobInputFields.FILE_ID);

        if (recordingType?.[0]) {
          onChangeRecordingType(recordingType[0]);
        }

        if (fileId?.[0]) {
          uploadModel.existingFiles.onChangeFile(fileId[0]);
        }

        if (isEditorReady) {
          onUseDraft?.(latestDraft as unknown as TTemplateDraft);
          setIsEditorSet(true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentDrafts, currentDocData?.title, isEditorReady]);

    useEffect(() => {
      if (highlightedDraftId) {
        delay(() => {
          setHighlightedDraftId(0);
        }, DRAFT_HIGHLIGHT_TIMEOUT);
      }
    }, [highlightedDraftId]);

    useEffect(
      () =>
        autorun(() => {
          const job = appModel.notificationsModel.currentDocumentNotification as IRecapJob;

          if (job && job.status !== previousJobStatus && isRecapsQueueJobType(job)) {
            switch (job.status) {
              case ContentGenerationJobStatus.enum.transcript:
              case ContentGenerationJobStatus.enum.generating:
                onChangeIsGeneratingContent(true);
                onChangeRecordingType(
                  getFieldValue(job.inputs.inputs, contentGenerationJobInputFields.RECORDING_TYPE)[0],
                );
                uploadModel.existingFiles.onChangeFile(
                  getFieldValue(job.inputs.inputs, contentGenerationJobInputFields.FILE_ID)[0],
                );
                break;

              case ContentGenerationJobStatus.enum.error:
                onChangeHasError(true);
                onChangeIsGeneratingContent(false);
                enqueueErrorSnackbar(job.error || recapsMessages.GENERATION_ERROR);
                break;

              case ContentGenerationJobStatus.enum.completed:
                getDocumentTemplateDrafts((drafts: ITemplateDraft[]) => {
                  if (!isEmpty(drafts)) {
                    setHighlightedDraftId(drafts[0].id);
                  }
                });
                onChangeIsGeneratingContent(false);
                enqueueSuccessSnackbar(recapsMessages.GENERATION_SUCCESS);
                break;

              default:
                break;
            }

            setPreviousJobStatus(job.status);
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [appModel.generationJobNotifications],
    );

    useEffect(() => {
      if (docId) {
        uploadModel.existingFiles.refresh();
        getDocumentTemplateDrafts();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [docId]);

    return (
      <RecapsContext.Provider
        value={{
          uploadModel,
          context,
          modalsManager,
          onClose,
          onShowTemplates,
          onGenerate,
          onChangeRecordingType,
          onChangeIsGeneratingContent,
          drafts: {
            list: documentDrafts,
            highlightedDraftId,
            isExpandedDraft,
            rateDraft: onDraftRate,
            deleteDraft: onDraftDelete,
            getDrafts: getDocumentTemplateDrafts,
            expandDraft: onDraftExpand,
            closeExpandedDraft: handleExpandDraft,
            copyDraft: onDraftCopy,
            goToDraft: onDraftGoTo,
            exportDraft,
          },
        }}
      >
        {children}
      </RecapsContext.Provider>
    );
  },
);

export function useRecapsContext() {
  const context = useContext(RecapsContext);

  if (!context) {
    throw new Error('useRecapsContext must be used within the RecapsContextProvider');
  }

  return context;
}

export default RecapsContextProvider;
