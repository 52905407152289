import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Heading, HeadingVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IWelcomeBannerProps {
  onCreateMyOrg: () => void;
  isCreatingOrg: boolean;
}

export const WelcomeBanner: React.FC<IWelcomeBannerProps> = ({ onCreateMyOrg, isCreatingOrg }) => (
  <div className={styles.bannerContainer}>
    <Heading className={cx(styles.heading, styles.centered)} variant={HeadingVariant.H2} medium>
      Get started with Writer
    </Heading>
    <div className={cx(styles.body, styles.centered)}>
      <Text variant={TextSize.XL} className={styles.bodyText}>
        Welcome to Writer! Start creating content and getting content consistency suggestions today by creating a Team
        trial.
      </Text>
      <div className={styles.buttonGroup}>
        <Button
          onClick={onCreateMyOrg}
          type={ButtonTypes.PRIMARY}
          content="Create my own org"
          isLoading={isCreatingOrg}
        ></Button>
      </div>
    </div>
  </div>
);
export default WelcomeBanner;
