import React from 'react';

import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import ExampleBlock from './ExampleBlock';

interface ExampleBlockHighlightProps {
  children: React.ReactNode;
  highlightColor: HighlightColor;
}

export const ExampleBlockHighlight: React.FC<ExampleBlockHighlightProps> = ({ children, highlightColor }) => (
  <ExampleBlock>
    <Text variant={TextSize.L} highlightColor={highlightColor} inline>
      {children}
    </Text>
  </ExampleBlock>
);

export default ExampleBlockHighlight;
