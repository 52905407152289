import { useMemo } from 'react';

import { AiAssistantSubscriptionModel } from '@writercolab/models';
import { IUserProfile } from '@writercolab/types';
import { type ISort } from '@writercolab/ui-molecules';

import type { TOrganizationsDocumentsScope, TOrganizationsDocumentsSort } from '@web/types';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useAppState } from 'state';

import DeleteDocument from '../../molecules/DocumentInfoPanel/modals/DeleteDocument';
import { BaseTable, type IRowProps } from '../BaseTable/BaseTable';
import { type DocumentsTableUiModel } from './DocumentsTableUiModel';
import { TableSkeleton } from './Skeleton';
import { generateDocumentsTableData, generateTableHeaders, toTableDocument } from './utils';

import styles from './DocumentsTable.module.css';

interface IDocumentsTableProps {
  model: DocumentsTableUiModel;
  access: {
    user: IUserProfile | undefined;
    assistantSubscription: AiAssistantSubscriptionModel | undefined;
  };
  sortField: typeof TOrganizationsDocumentsSort.type;
  handleSortingChange: (selectedSort: ISort) => void;
  handleOwnerFilterChange: (id: typeof TOrganizationsDocumentsScope.type) => void;
}

const GRID_TEMPLATE_COLUMNS = 'minmax(500px,1fr) minmax(200px,1fr) minmax(150px,1fr) 46px';
export const DOCUMENTS_TABLE_ID = 'container-documents-table';

export const DocumentsTable = observer<IDocumentsTableProps>(
  ({ model, access, sortField, handleSortingChange, handleOwnerFilterChange }) => {
    const {
      appModel: { analyticsService },
    } = useAppState();

    const {
      documents,
      loading,
      hasNext,
      loadMore,
      showLoader,
      scopeFilterOptions,
      sortingOptions,
      onDocumentAction,
      deleteConfirmationModalVisible,
      setDeleteConfirmationModalVisible,
      deleteDocument,
      scopeFilterDisabled,
      sortedByCreatedDate,
      openDocument,
      setSelectedDocument,
    } = model;

    const tableHeaders = useMemo(
      () =>
        generateTableHeaders({
          sortingOptions,
          scopeFilterOptions,
          handleOwnerFilterChange,
          scopeFilterDisabled,
          sortedByCreatedDate,
          sortField,
          handleSortingChange,
        }),
      [
        scopeFilterDisabled,
        scopeFilterOptions,
        handleOwnerFilterChange,
        sortingOptions,
        sortedByCreatedDate,
        sortField,
        handleSortingChange,
      ],
    );

    const tableData = generateDocumentsTableData(
      documents.map(document => toTableDocument(document)),
      onDocumentAction,
      scopeFilterDisabled,
      sortedByCreatedDate,
      setSelectedDocument,
      access,
      analyticsService,
    );

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading,
      hasNextPage: hasNext,
      onLoadMore: loadMore,
      rootMargin: '0px 0px 100px 0px',
    });

    return (
      <div className={styles.container}>
        <div className={styles.scrollableContainer} ref={rootRef} id={DOCUMENTS_TABLE_ID}>
          <BaseTable
            headers={tableHeaders}
            data={tableData}
            loading={showLoader}
            headerRowClassName={styles.headerRow}
            bodyRowClassName={styles.bodyRow}
            gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
            customLoading={
              <TableSkeleton rowNumber={15} columnNumber={4} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />
            }
            isEnableSticky={false}
            onRowClick={(row: IRowProps) => openDocument(row.data.id)}
          />
          {hasNext && (
            <div className={styles.loaderWrapper} ref={sentryRef}>
              <TableSkeleton rowNumber={3} columnNumber={3} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />
            </div>
          )}
        </div>
        <DeleteDocument
          isOpen={deleteConfirmationModalVisible}
          onClose={() => setDeleteConfirmationModalVisible(false)}
          onSubmit={() => deleteDocument()}
        />
      </div>
    );
  },
);
