import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IDeleteAccountModalInfoProps {
  isOpen: boolean;
  handleClose: () => void;
  userEmail?: string | null;
}

export const DeleteAccountModalInfo: React.FC<IDeleteAccountModalInfoProps> = ({
  isOpen,
  handleClose,
  userEmail,
  ...props
}) => (
  <Modal
    warning
    open={isOpen}
    handleClose={handleClose}
    title="Delete my organization"
    style={{ width: '426px' }}
    {...props}
  >
    <div className={styles.modalContainer}>
      <div className={styles.modalDescriptionContainer}>
        <Text variant={TextSize.XL}>
          We've sent an email to {userEmail || ''} to confirm that you want to delete your organization. Click the
          confirmation link to finish deleting your organization.
        </Text>
      </div>
      <div className={styles.modalSubmitContainer}>
        <Button className={styles.closeButton} type={ButtonTypes.BLACK} content="Close" onClick={handleClose} />
      </div>
    </div>
  </Modal>
);

export default DeleteAccountModalInfo;
