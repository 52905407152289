import React, { ReactNode } from 'react';

import { Heading, HeadingVariant, Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

export interface ISuggestionRuleHeaderProps {
  children?: ReactNode;
  icon?: IconVariant;
  heading?: string;
}

const SuggestionRuleHeader: React.FC<ISuggestionRuleHeaderProps> = ({ icon, heading }) => (
  <div>
    {icon && (
      <div className={styles.ruleIcon}>
        <Icon name={icon} />
      </div>
    )}
    {heading && (
      <Heading variant={HeadingVariant.H5} className={styles.rulesHeader}>
        {heading}
      </Heading>
    )}
  </div>
);

export default SuggestionRuleHeader;
