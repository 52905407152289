import React, { FC, useCallback, useMemo } from 'react';

import cx from 'classnames';

import { Dropdown, type DropdownOption, DropdownPlacement, Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './IconSelect.module.css';

interface IconSelectProps {
  options: DropdownOption[];
  className?: string;
  onSelect: (id: string) => void;
  activeId?: string;
  dropdownPlacement?: DropdownPlacement;
  error?: boolean;
  disabled?: boolean;
  id?: string;
}

const ActionTrigger = ({ children, id }: { children: React.ReactNode; id?: string }) => (
  <div className={styles.trigger} id={id}>
    <div className={styles.selectedIcon}>{children}</div>
    <div className={styles.arrowWrapper}>
      <Icon name={IconVariant.ARROW_DOWN} height={5} width={8} />
    </div>
  </div>
);

// TODO need rewrite Dropdown component
// Main problems is:
// - active state hard to control and set
// - need add ability to pass custom Option component
export const IconSelect: FC<IconSelectProps> = ({
  options,
  className,
  onSelect,
  activeId,
  disabled,
  error,
  dropdownPlacement = DropdownPlacement.BOTTOM_RIGHT,
  id,
}) => {
  const optionsWithClassName = useMemo(
    () =>
      options.map(option => ({
        ...option,
        className: cx(styles.option, {
          [styles.optionActive]: option.id === activeId,
        }),
      })),
    [options, activeId],
  );

  const activeTrigger = useMemo(
    () => optionsWithClassName.find(option => option.id === activeId),
    [activeId, optionsWithClassName],
  );

  const handleClick = useCallback((id: string) => onSelect(id), [onSelect]);

  return (
    <Dropdown
      dropDownContainerClassName={styles.dropdownContainer}
      containerClassName={cx(styles.container, className, { [styles.error]: error, [styles.disabled]: disabled })}
      options={optionsWithClassName}
      trigger={
        <ActionTrigger id={id}>
          {activeTrigger ? activeTrigger.icon : <div className={styles.emptyTrigger} />}
        </ActionTrigger>
      }
      highlightActiveOption
      onPrimaryOptionClickAction={handleClick}
      placement={dropdownPlacement}
    />
  );
};

IconSelect.displayName = 'IconSelect';
