import { computed, makeObservable } from 'mobx';

import { PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import type { ApplicationsApiModel } from '@writercolab/models';
import type { TBriefFrameworkApplicationData, TNotificationQueueItem } from '@writercolab/types';
import { EApplicationType, NotificationQueueItemType } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

interface IFrameworkApplicationPageUIModelProps {
  applicationApi: ApplicationsApiModel;
  teamId: number;
  applicationIdOrAlias: string;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

export const FrameworkApplicationPageModalType = new Enum('rateDraft', 'expandedDraft');

export class FrameworkApplicationPageUIModel {
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;

  $application = new PromisedModel({
    name: '$application',
    load: async () => {
      try {
        return this.opts.applicationApi.getApplicationForPreview(this.opts.teamId, this.opts.applicationIdOrAlias);
      } catch {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message:
            'App is no longer available. If you believe this is in error, please contact your team administrator.',
        });
      }

      return undefined;
    },
  });

  constructor(private opts: IFrameworkApplicationPageUIModelProps) {
    this.notificationQueue = opts.notificationQueue || new ReactiveQueue();

    makeObservable(this, {
      name: computed,
      deploymentUrl: computed,
    });
  }

  get name() {
    return this.$application.value?.name;
  }

  get deploymentUrl() {
    return this.$application.value?.type === EApplicationType.enum.framework
      ? `https://${(this.$application.value?.data as TBriefFrameworkApplicationData).deploymentUrl}`
      : undefined;
  }
}
