import { getFileExtension } from '@writercolab/common-utils';
import { isFileExceedingSizeLimit } from '@writercolab/utils';

import { KG_MAX_FILE_SIZE, KG_SUPPORTED_FILE_EXTENSIONS } from '@web/types';
import last from 'lodash/last';

const FORBIDDEN_FILENAME_CHARACTERS = [
  {
    char: '!',
    replacement: '%21',
  },
  {
    char: '~',
    replacement: '%7e',
  },
  {
    char: '*',
    replacement: '%2A',
  },
  {
    char: "'",
    replacement: '%39',
  },
  {
    char: '(',
    replacement: '%28',
  },
  {
    char: ')',
    replacement: '%29',
  },
];

export const getContentType = (file: File): string => {
  const fileExtension = last(file.name.split('.'));

  switch (fileExtension) {
    case 'txt':
      return 'text/plain';
    case 'srt':
      return 'application/x-subrip';
    default:
      return file.type;
  }
};

export const encodeFilename = (unsafeFilename: string, charsToReplace = FORBIDDEN_FILENAME_CHARACTERS): string => {
  let filename = encodeURIComponent(unsafeFilename);

  // encodeURIComponent does not encode A–Z a–z 0–9 - _ . ! ~ * ' ( )
  charsToReplace.forEach(({ char, replacement }) => {
    filename = filename.replaceAll(char, replacement);
  });

  return filename;
};

export const isFileExtensionAllowed = (file: File, allowedExtensions: string[]): boolean =>
  allowedExtensions.includes(getFileExtension(file.name));

export const getMsOfficeExtendedFileLabel = (format: string): string => `${format.slice(0, -1)}/${format}`;

export const validateFiles = ({
  files,
  onSuccess,
  onFileSizeError,
  onFileTypeError,
  fileSizeLimit = KG_MAX_FILE_SIZE,
  allowedFileExtensions = KG_SUPPORTED_FILE_EXTENSIONS,
}: {
  files: FileList;
  onSuccess: (files: FileList) => void;
  onFileSizeError: (fileName: string) => void;
  onFileTypeError: (fileName: string) => void;
  fileSizeLimit?: number;
  allowedFileExtensions?: string[];
}): void => {
  const fileList: File[] = Array.from(files);
  const oversizedFile = fileList.find(f => isFileExceedingSizeLimit(f, fileSizeLimit));
  const notAllowedFile = fileList.find(f => !isFileExtensionAllowed(f, allowedFileExtensions));

  if (oversizedFile) {
    onFileSizeError(oversizedFile.name);
  } else if (notAllowedFile) {
    onFileTypeError(notAllowedFile.name);
  } else {
    onSuccess(files);
  }
};

export const isVimeoUrl = (url: string): boolean => {
  // Vimeo has a wide variety of valid URLs
  // vimeo.com/123456789
  // vimeo.com/channels/mychannel/123456789
  // vimeo.com/groups/shortfilms/videos/123456789
  // player.vimeo.com/video/123456789
  // http://vimeo.com/123456789
  // https://vimeo.com/channels/mychannel/123456789
  // https://vimeo.com/groups/shortfilms/videos/123456789
  // https://www.player.vimeo.com/video/123456789
  const vimeoUrlRegex =
    // eslint-disable-next-line no-useless-escape
    /(?:http|https)?:?\/?\/?(?:www\.)?(?:player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

  return vimeoUrlRegex.test(url);
};
