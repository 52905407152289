import React from 'react';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

interface IClaimTooltipTextProps {
  content?: string | React.ReactNode;
}

export const ClaimTooltipText: React.FC<IClaimTooltipTextProps> = ({ content }) => (
  <Text variant={TextSize.M} bold color={TextColor.WHITE}>
    {content}
  </Text>
);

export default ClaimTooltipText;
