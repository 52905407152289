import { getWordsCount, wordPluralize } from '@writercolab/common-utils';

import {
  AUTO_GENERATE_CONTENT_USER_ID,
  CUSTOM_INPUT_VALUE_PREFIX,
  IQuestionnaireOption,
  QUESTIONNAIRE_TEXT_INPUT_WORDS_LIMIT,
} from '@web/types';
import trim from 'lodash/trim';

export const QUESTIONNAIRE_CUSTOM_INPUT_INVALID_ERROR = `max ${QUESTIONNAIRE_TEXT_INPUT_WORDS_LIMIT} ${wordPluralize(
  QUESTIONNAIRE_TEXT_INPUT_WORDS_LIMIT,
  'word',
)}`;

/**
 * This function collects all selected options from an array of options.
 * It formats selected options based on whether they are editable or not,
 * and then joins them into a comma-separated string.
 *
 * @param options - The array of options to process.
 * @returns A comma-separated string of selected options.
 */
export const collectSelectedOptions = (options: IQuestionnaireOption[]): string =>
  options
    .filter(option => option.selected)
    .map(option => (option.editable ? formatQuestionerCustomInputValue(`${option.value}`) : trim(option.title)))
    .join(', ');

/**
 * This function checks if the user has auto-generation content enabled.
 * It verifies this by looking if there's a selected option with a specific ID.
 *
 * @param options - The array of options to process.
 * @returns true if the user has auto-generation content enabled, false otherwise.
 */
export const isAutoGenerateContentUser = (options: IQuestionnaireOption[]): boolean =>
  options.some(option => option.id === AUTO_GENERATE_CONTENT_USER_ID && option.selected);

/**
 * This function checks if a text input in a questionnaire is valid.
 * Validity is determined by whether the number of words in the input does not exceed a limit.
 *
 * @param value - The string to be validated.
 * @returns true if the input is valid, false otherwise.
 */
export const isQuestionerTextInputValid = (value: string): boolean =>
  getWordsCount(value) <= QUESTIONNAIRE_TEXT_INPUT_WORDS_LIMIT;

/**
 * This function formats a questionnaire custom input value by appending a prefix to it.
 *
 * @param value - The string to be formatted.
 * @returns A string with the custom input value prefix appended.
 */
export const formatQuestionerCustomInputValue = (value: string): string =>
  trim(`${CUSTOM_INPUT_VALUE_PREFIX} ${value}`);

/**
 * This function checks if a provided value starts with a custom value prefix.
 *
 * @param value - The string to be checked.
 * @returns true if the value starts with the custom value prefix, false otherwise.
 */
export const isCustomValue = (value: string): boolean => value.startsWith(CUSTOM_INPUT_VALUE_PREFIX);

/**
 * This function removes the custom value prefix from a given string and trims the result.
 *
 * @param value - The string from which the prefix will be removed.
 * @returns A string with the custom value prefix removed and the result trimmed.
 */
export const removeCustomValuePrefix = (value: string): string => trim(value.replace(CUSTOM_INPUT_VALUE_PREFIX, ''));
