import type { ComponentProps, ComponentType, ReactNode } from 'react';
import { Suspense, lazy } from 'react';

import { ErrorBoundary, ErrorBoundaryFallback } from 'components/templates/ErrorBoundary';

import { LoadingPage } from '@web/component-library';

interface AsyncComponentProps<T extends ComponentType<any>> {
  component: () => Promise<{ default: T }>;
  fallback?: ReactNode;
  errorFallback?: ReactNode;
  onReset?: () => void;
  onError?: (error: Error, info: React.ErrorInfo) => void;
  props?: ComponentProps<T>;
}

export const AsyncComponent = <T extends ComponentType<any>>({
  component,
  fallback = <LoadingPage />,
  errorFallback = <ErrorBoundaryFallback error="Error while loading." resetErrorBoundary={() => {}} />,
  onReset,
  onError,
  props,
}: AsyncComponentProps<T>) => {
  const LazyComponent = lazy(component);

  return (
    <Suspense fallback={fallback}>
      <ErrorBoundary FallbackComponent={() => errorFallback} onReset={onReset} onError={onError}>
        <LazyComponent {...(props as ComponentProps<T>)} />
      </ErrorBoundary>
    </Suspense>
  );
};
