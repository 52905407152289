import type { Reducer } from 'react';
import type React from 'react';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import Delta from 'quill-delta';
import type Quill from 'quill';
import type { RangeStatic, Sources } from 'quill';
import cx from 'classnames';

import type { DefaultMagicModuleAction, MagicModuleState, SelectionArea } from '@writercolab/common-utils';
import {
  defaultArea,
  EDITOR_MARGIN,
  getAllSubstringsIndexes,
  getWordsCount,
  MagicModuleAction,
  MARGIN_BETWEEN_TEXT_AND_WIDGET,
  withRemovedNewLines,
} from '@writercolab/common-utils';

import { QA_MAGIC_FORMAT_NAME } from '@writercolab/quill-delta-utils';

import isEmpty from 'lodash/isEmpty';
import head from 'lodash/head';
import isNull from 'lodash/isNull';
import { MagicLinksIcon, useCustomSnackbar } from '@writercolab/ui-atoms';
import { useHandleOutsideMouseDown, snackbarMessages, useWindowDimensions } from '@web/component-library';
import styles from '../components/EditorPure/styles.module.css';
import { AnalyticsActivity, type IWebAppAnalyticsTrack } from '../analytics';

export const initialState: MagicModuleState = {
  selectedText: '',
  selectedArea: defaultArea,
  selectedRange: null,
  amountMagicLinksFound: [],
};

export const magicModuleReducer = (state: MagicModuleState, action: DefaultMagicModuleAction): MagicModuleState => {
  let newState = { ...state };

  switch (action.type) {
    case MagicModuleAction.RESET:
      newState = {
        ...initialState,
      };
      break;

    default:
      newState = { ...state, ...action.payload };
  }

  return newState;
};

export const useMagicLinksModule = (
  onMagicIconClick: (amount: number, text: string, doSearch?: boolean) => void,
  magicLinkUrl: string,
  magicLinksSearchText: string,
  isMagicLinksMode: boolean,
  amountOfDomains: number,
  analyticsService: IWebAppAnalyticsTrack,
  editor?: Quill,
): {
   
  onChangeMagicSelection: (range: RangeStatic, source: Sources, editor: any, magicLinksSearchText?: any) => void;
  onApplyLinkFormat: () => RangeStatic;
  MagicLinksUI: React.ReactNode;
} => {
  const { enqueueBasicSnackbar } = useCustomSnackbar();
  const windowDimensions = useWindowDimensions();
  const iconRef = useRef<HTMLDivElement>(null);

  const [iconBlocked, setIconBlocked] = useState(false);
  const [hideIcon, setHideIcon] = useState(false);
  const [hideIconIfUserSearched, setHideIconIfUserSearched] = useState(false);
  const [storedText, setStoredText] = useState('');
  const [elementTopPosition, setElementTopPosition] = useState(0);
  const [scrolledDistance, setScrolledDistance] = useState(0);
  const [domainsLength, setDomainsLength] = useState(0);

  const [magicModuleState, setMagicModuleState] = useReducer<Reducer<MagicModuleState, DefaultMagicModuleAction>>(
    magicModuleReducer,
    initialState,
  );

  const { selectedText, selectedArea, selectedRange, amountMagicLinksFound } = magicModuleState;

  // Calculate where to position icon
  const calculateSelectedArea = (rects: DOMRect[], range: RangeStatic, editor: Quill): SelectionArea | null => {
    const selection = editor.getBounds(range.index, range.length);

    if (!selection || rects.length === 0) {
      return null;
    }

    const last = rects[rects.length - 1] as DOMRect;
    const first = rects[0] as DOMRect;

    const rectBottom = last.bottom;
    const editorBottom = window.innerHeight - MARGIN_BETWEEN_TEXT_AND_WIDGET;
    const elementTop = first.top - MARGIN_BETWEEN_TEXT_AND_WIDGET / 1.75;
    const elementRight = last.right - MARGIN_BETWEEN_TEXT_AND_WIDGET / 4;

    setElementTopPosition(elementTop);

    const selectedArea = {
      ...selection,
      top: elementTop,
      right: elementRight,
      bottom: rectBottom > editorBottom ? editorBottom : rectBottom,
    };

    return selectedArea;
  };

  const onApplyLinkFormat = (): RangeStatic => {
    setHideIcon(true);
    setHideIconIfUserSearched(true);
    selectAllLinks(selectedText, QA_MAGIC_FORMAT_NAME, false);

    const magicRange = isNull(selectedRange) ? { index: 0, length: 0 } : selectedRange;

    return magicRange as RangeStatic;
  };

  // Find all separated words equal to selectedText and format them
  const selectAllLinks = useCallback(
    (selectedText: string, format: string, isSelected: boolean, magicText = '') => {
      const text = editor?.getText(0) || '';
      const textToCheck = selectedText || storedText;

      if (textToCheck) {
        const { indices, searchedText, trimmedLength } = getAllSubstringsIndexes(magicText || textToCheck, text);
        isSelected && onMagicIconClick(indices.length, searchedText);

        if (indices.length !== 0) {
          indices.map(index => editor?.formatText(index, trimmedLength, format, isSelected, 'api'));
          // Icon is blocked in case user click to searchField inside MagicLinksSuggestions
          setIconBlocked(true);

          setMagicModuleState({
            type: MagicModuleAction.DEFAULT,
            payload: {
              amountMagicLinksFound: indices,
            },
          });
        } else {
          // Hide icon if no elements found
          setHideIcon(indices.length === 0);
        }
      }
    },
    [editor, onMagicIconClick, storedText],
  );

  // Triggered on text selection (user or api)
  const onChangeMagicSelection = useCallback(
    (range: RangeStatic, source: Sources, editor?: Quill, magicLinksSearchText = '') => {
      if (domainsLength === 0) {
        return;
      }

      // Showing icon by default on each selection
      setHideIcon(false);

      if (range && !isEmpty(selectedText || storedText)) {
        selectAllLinks(selectedText, QA_MAGIC_FORMAT_NAME, false);
        setHideIcon(true);
      }

      // If no selection means erstore to default state
      if (range && range.length === 0) {
        setMagicModuleState({
          type: MagicModuleAction.DEFAULT,
          payload: {
            selectedText: '',
            selectedRange: { index: 0, length: 0 },
          },
        });

        return;
      }

      // If there is some text selected means find all words and format them
      if (range && source === 'user') {
        const editorText = editor?.getText() || '';
        const text = withRemovedNewLines(editorText);
        const selectedText = magicLinksSearchText || text.substring(range.index, range.index + range.length);
        const wordsSelected = getWordsCount(selectedText);

        if (wordsSelected > 10) {
          enqueueBasicSnackbar(snackbarMessages.magicLinksEditorError.appliedURL());

          return;
        }

        const { indices, searchedText } = getAllSubstringsIndexes(selectedText, text);

        if (indices.length > 0) {
          const selection = window.getSelection();

          if (searchedText && !isEmpty(searchedText) && selection) {
            const currentSelection = selection.getRangeAt(0);

            if (currentSelection && !currentSelection.collapsed && editor) {
              const rects = Array.from(currentSelection.getClientRects());
              const selectedArea = calculateSelectedArea(rects, range, editor);

              selectedArea &&
                setMagicModuleState({
                  type: MagicModuleAction.DEFAULT,
                  payload: {
                    selectedText: searchedText,
                    selectedArea,
                    selectedRange: { index: range.index, length: range.length },
                  },
                });

              setStoredText(searchedText);
              selectAllLinks(searchedText, QA_MAGIC_FORMAT_NAME, true, magicLinksSearchText);
              // Hide Icon always if user searched manually from MagicLinksSuggestions
              setHideIconIfUserSearched(!isEmpty(magicLinksSearchText));
              iconRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });

              const analyticsType = AnalyticsActivity.magicLinksHighlightedText;

              analyticsService.track(analyticsType, {
                clicked_from: 'magic-links',
              });
            }
          }
        } else {
          setMagicModuleState({
            type: MagicModuleAction.DEFAULT,
            payload: {
              selectedRange: { index: 0, length: 0 },
            },
          });
        }
      }
    },
    [analyticsService, domainsLength, enqueueBasicSnackbar, selectAllLinks, selectedText, storedText],
  );

  // Apply link to all text found
  const updateAllLinks = () => {
    amountMagicLinksFound.map(index => {
      const applyDelta = new Delta().retain(index).retain(selectedText.length, { link: magicLinkUrl });

      return editor?.updateContents(applyDelta, 'user');
    });
  };

  const onMagicIconClicked = () => {
    onMagicIconClick(amountMagicLinksFound.length, selectedText, true);
  };

  const onCloseCallback = () => {
    // Blocked if clicking the selected link, but can be closed if link is not applied yet
    if (iconBlocked) {
      return;
    }

    setMagicModuleState({
      type: MagicModuleAction.RESET,
    });
  };

  const onMagicTextUpdate = (magicText: string, isFromEditor: boolean) => {
    if (!isEmpty(magicText)) {
      const editorText = editor?.getText(0) || '';
      const text = withRemovedNewLines(editorText);
      const { indices, searchedText, trimmedLength } = getAllSubstringsIndexes(magicText, text);

      if (indices.length > 0) {
        editor?.setSelection(head(indices) || 0, trimmedLength);
        const range = {
          index: head(indices) || 0,
          length: trimmedLength,
        } as RangeStatic;

        onChangeMagicSelection(range, 'user', editor, isFromEditor ? '' : searchedText);
      } else {
        // Means no results found
        onMagicIconClick(0, searchedText);

        setMagicModuleState({
          type: MagicModuleAction.RESET,
        });
      }
    } else {
      // Means clear magic links to zero state
      onMagicIconClick(-1, magicText);

      setMagicModuleState({
        type: MagicModuleAction.RESET,
      });
    }
  };

  // Update all selected links and reset everything if user deselect text
  useEffect(() => {
    if (!isEmpty(magicLinkUrl)) {
      updateAllLinks();
      setIconBlocked(false);
      selectAllLinks(selectedText, QA_MAGIC_FORMAT_NAME, false);

      setMagicModuleState({
        type: MagicModuleAction.RESET,
      });

      enqueueBasicSnackbar(snackbarMessages.magicLinksEditor.appliedURL(amountMagicLinksFound.length));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicLinkUrl]);

  // Reset everything if user deselect text
  useEffect(() => {
    if (selectedRange?.length === 0) {
      selectAllLinks(selectedText, QA_MAGIC_FORMAT_NAME, false);
      setIconBlocked(false);
      setStoredText('');
      onMagicIconClick(-1, '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRange]);

  // Calculating the scrolled distance + onEditorScroll event
  useEffect(() => {
    let start: number | undefined;
    let end: number | undefined;
    let distance: number | undefined;

    let isScrolling: ReturnType<typeof setTimeout>;

    const onEditorScroll = (callback = (_: number) => {}, refresh = 100) => {
      setHideIcon(true);

      if (!start) {
        start = editor?.root?.scrollTop || 0;
      }

      window.clearTimeout(isScrolling);

      isScrolling = setTimeout(() => {
        end = editor?.root?.scrollTop ?? 0;
        distance = end - (start || 0);

        callback(distance);

        start = undefined;
        end = undefined;
        distance = undefined;
      }, refresh);
    };

    const onScroll = () =>
      onEditorScroll((distance: number) => {
        setScrolledDistance(distance);
      });

    editor?.root.addEventListener('scroll', onScroll, false);

    return () => {
      editor?.root.removeEventListener('scroll', onScroll, false);
    };
  }, [editor, selectedArea.top]);

  // Update element position onScroll and hide while scrolling
  useEffect(() => {
    const updatedTopPosition = elementTopPosition - scrolledDistance;
    const isIconHidden =
      updatedTopPosition - EDITOR_MARGIN <= 0 || updatedTopPosition + EDITOR_MARGIN >= windowDimensions.height;

    setElementTopPosition(updatedTopPosition);
    setHideIcon(isIconHidden);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledDistance]);

  // Hide all if it is not a magicLinksMode
  useEffect(() => {
    setDomainsLength(amountOfDomains);

    if (!isMagicLinksMode || amountOfDomains === 0) {
      setIconBlocked(false);
      setHideIcon(true);
      onCloseCallback();
      selectAllLinks(selectedText || storedText, QA_MAGIC_FORMAT_NAME, false);
    } else {
      const selection = editor?.getSelection();

      if (selection && selection.length > 0) {
        const editorText = editor?.getText(0) || '';
        const text = withRemovedNewLines(editorText);
        const updatedText = text.substring(selection.index, selection.index + selection.length);

        onMagicTextUpdate(updatedText, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMagicLinksMode, amountOfDomains]);

  // When received text search from MagicLinksSuggestions
  useEffect(() => {
    selectAllLinks(selectedText, QA_MAGIC_FORMAT_NAME, false);
    onMagicTextUpdate(magicLinksSearchText, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, magicLinksSearchText]);

  useHandleOutsideMouseDown(iconRef, onCloseCallback);

  const MagicLinksUI: React.ReactNode = editor ? (
    <>
      {selectedRange && selectedRange.length > 0 && !hideIcon && !hideIconIfUserSearched && (
        <MagicLinksIcon
          ref={iconRef}
          onIconClick={onMagicIconClicked}
          className={cx(styles.wandIcon, styles.magic)}
          style={{ top: elementTopPosition, left: selectedArea.right }}
        />
      )}
    </>
  ) : null;

  return { MagicLinksUI, onChangeMagicSelection, onApplyLinkFormat } as const;
};
