import React, { ReactElement } from 'react';

import { BaseComponentsProps } from '@writercolab/common-utils';
import { Icon, IconVariant, IdpButton, Text } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IIdentityProviderProps extends BaseComponentsProps {
  imageUrl: string;
  text?: string | ReactElement;
  handleEditProvider: () => void;
  handleRemoveProvider: () => void;
}

export const IdentityProvider: React.FC<IIdentityProviderProps> = ({
  imageUrl,
  text,
  handleEditProvider,
  handleRemoveProvider,
}) => (
  <div className={styles.identitySection}>
    <IdpButton imageUrl={imageUrl} text={text} hideHighlight />
    <div className={styles.identityColumn}>
      <div onClick={handleEditProvider}>
        <Text id="editConfigurations">
          <Icon name={IconVariant.SETTINGS} /> Edit configuration
        </Text>
      </div>
      <div onClick={handleRemoveProvider}>
        <Text id="removeConfigurations">
          <Icon name={IconVariant.TRASH} /> Remove configuration
        </Text>
      </div>
    </div>
  </div>
);

export default IdentityProvider;
