import React, { useCallback, useEffect } from 'react';

import cx from 'classnames';

import { Heading, HeadingVariant, SearchBar, Text, TextSize } from '@writercolab/ui-atoms';

import { PromptLibraryMenu } from 'components/molecules/PromptLibraryMenu';
import { PromptLibraryList } from 'components/organisms/PromptLibraryList';

import { TPrompt } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import { PromptLibraryEventSources } from '../../../constants/PromptLibrary';
import { PromptLibraryUserUIModel } from './PromptLibraryUserModel.ui';

import styles from './PromptLibraryUser.module.css';

interface IPromptLibraryUser {
  model: PromptLibraryUserUIModel;
  invocationContext: PromptLibraryEventSources;
  onUsePrompt?: (prompt: string) => void;
  onCopyToClipboard: (message: string) => void;
  onTeamPromptCreateClick?: (prompt: TPrompt) => void;
}

export const PromptLibraryUser: React.FC<IPromptLibraryUser> = observer(
  ({ model, invocationContext, onUsePrompt, onCopyToClipboard, onTeamPromptCreateClick }) => {
    const {
      activeCategory,
      activeContentTypeCategory,
      searchQuery,
      resetSearchPhrase,
      setActiveCategory,
      setActiveContentTypeCategory,
      prompts,
      categories,
      setSearchPhrase,
      searchInputField,
      loading,
    } = model;

    useEffect(() => {
      model.analyticsService.track(AnalyticsActivity.viewedPromptLibrary, {
        viewed_from: invocationContext,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSetActiveCategory = useCallback(
      (tagId, isSubcat) => {
        setActiveCategory(tagId);
        setActiveContentTypeCategory(tagId);

        const key = isSubcat ? 'subcategory_name' : 'category_name';
        model.analyticsService.track(AnalyticsActivity.viewedSubcategory, {
          [key]: tagId,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [setActiveCategory, setActiveContentTypeCategory],
    );

    const handleUsePrompt = onUsePrompt
      ? (prompt: string, promptName?: string, promptHeader?: string) => {
          if (onUsePrompt) {
            onUsePrompt(prompt);

            model.analyticsService.track(AnalyticsActivity.insertedPromptInAskWriter, {
              prompt_name: promptName,
              prompt_header: promptHeader,
            });
          }
        }
      : undefined;

    const handleCopyToClipboard = useCallback(
      (prompt: string, promptName?: string, promptHeader?: string) => {
        onCopyToClipboard(prompt);

        model.analyticsService.track(AnalyticsActivity.copiedPrompt, {
          prompt_name: promptName,
          prompt_header: promptHeader,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [onCopyToClipboard],
    );

    return (
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <Text
            className={cx(styles.menuHeading, {
              [styles.activeMenuHeading]: !activeCategory || !activeContentTypeCategory,
            })}
            variant={TextSize.M}
            onClick={() => {
              setActiveCategory('');
              setActiveContentTypeCategory('');
            }}
            bold={!activeCategory || !activeContentTypeCategory}
          >
            All
          </Text>
          <div className={styles.categoryContainer}>
            <PromptLibraryMenu
              mapCategoryToIcon={PromptLibraryUserUIModel.mapCategoryToIcon}
              activeCategory={activeCategory}
              items={categories}
              tagNameMap={model.tagNameMap}
              tagHierarchyMap={model.tagHierarchyMap}
              onSetActiveCategory={handleSetActiveCategory}
            />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.headerRowWrapper}>
            <div className={styles.headerRow}>
              <div>
                <Heading upperCase variant={HeadingVariant.H2}>
                  Writer prompts
                </Heading>
              </div>
              <SearchBar
                placeholder="Search"
                className={styles.searchBar}
                value={searchInputField.value}
                onChange={e => setSearchPhrase(e.target.value)}
                handleClearInput={() => resetSearchPhrase()}
              />
            </div>
          </div>
          <div className={styles.contentContainer}>
            <PromptLibraryList
              loading={loading}
              searchQuery={searchQuery}
              activeCategory={activeCategory}
              prompts={prompts}
              getContentTypeIcon={PromptLibraryUserUIModel.getContentTypeIcon}
              tagNameMap={model.tagNameMap}
              tagHierarchyMap={model.tagHierarchyMap}
              promptGroupByTagMap={model.promptGroupByTagMap}
              normalizedTagMap={model.normalizedTagMap}
              onUsePrompt={handleUsePrompt}
              onCopyToClipboard={handleCopyToClipboard}
              onTeamPromptCreateClick={onTeamPromptCreateClick}
            />
          </div>
        </div>
      </div>
    );
  },
);
