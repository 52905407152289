import { Button } from '@writercolab/fe.wds';
import { LinkText, Modal } from '@writercolab/ui-atoms';

import styles from './ExtensionsHomeWidget.module.css';

interface IHideExtensionModalProps {
  isOpen: boolean;
  extensionPageUrl: string;
  onClose: () => void;
  onSubmit: () => void;
}

export const HideExtensionModal: React.FC<IHideExtensionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  extensionPageUrl,
}) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal open={isOpen} handleClose={onClose} title="Hide extension" style={{ width: '450px' }}>
      <div>
        <p className="text-base">
          Are you sure you want to permanently hide this extension? You can still access all Writer extensions from the{' '}
          <LinkText className={styles.learnMoreLink} target="_blank" href={extensionPageUrl}>
            extension page
          </LinkText>
          .
        </p>
        <div className={styles.deleteControlButtons}>
          <Button className={styles.submitButton} onClick={onClose} variant="outline">
            Never mind
          </Button>
          <Button className={styles.submitButton} onClick={handleSubmit} variant="primary">
            Hide forever
          </Button>
        </div>
      </div>
    </Modal>
  );
};
