import { AnalyticsService } from '@writercolab/analytics';
import { E_CLIENT_ID, E_INTEGRATION_TYPE } from '@writercolab/types';

import { TWebAnalyticsParams } from 'constants/analytics';

import requestService from '../request/requestService';

export const analyticsServiceBase = new AnalyticsService<TWebAnalyticsParams>(requestService.api, {
  clientId: E_CLIENT_ID.enum.QordobaFE,
  integrationType: E_INTEGRATION_TYPE.enum.DEFAULT,
});

export default analyticsServiceBase;
