import { useState, useEffect } from 'react';

export function useOnlineStatus() {
  const [onlineStatus, setOnlineStatus] = useState(true);

  useEffect(() => {
    const onOffline = () => setOnlineStatus(false);
    const onOnline = () => setOnlineStatus(true);

    window.addEventListener('offline', onOffline);
    window.addEventListener('online', onOnline);

    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);
    };
  }, []);

  return onlineStatus;
}

export default useOnlineStatus;
