import type { IHomeWidgetConfig } from '@web/types';
import {
  ASK_WRITER_APP_ID,
  BLOG_BUILDER_APP_ID,
  IMAGE_ANALYZER_APP_ID,
  RECAPS_APP_ID,
  TWidgetType,
  TWriterExtensionId,
} from '@web/types';
import difference from 'lodash/difference';

export const getDefaultTeamWidgetConfigMap = () => {
  return TWidgetType.list.reduce(
    (acc, type) => {
      acc[type] = {
        id: '',
        type,
        visible: true,
        data: TWidgetType.match(
          type,
          {
            featuredChatApp: () => ({
              appId: ASK_WRITER_APP_ID,
            }),
            shortcuts: () => ({
              appIds: [RECAPS_APP_ID, BLOG_BUILDER_APP_ID, IMAGE_ANALYZER_APP_ID],
            }),
            recentWork: () => ({}),
            apps: () => ({}),
            aiStudio: () => ({}),
            writerAiAcademy: () => ({}),
            extensions: () => ({
              names: [
                TWriterExtensionId.enum.MAC,
                TWriterExtensionId.enum.CHROME,
                TWriterExtensionId.enum.FIGMA,
                TWriterExtensionId.enum.WORD,
              ],
            }),
          },
          null,
        ),
      } as IHomeWidgetConfig;

      return acc;
    },
    {} as Record<typeof TWidgetType.type, IHomeWidgetConfig>,
  );
};

export const getDefaultUserWidgetConfigMap = () => {
  return TWidgetType.list.reduce(
    (acc, type) => {
      acc[type] = {
        id: '',
        type,
        visible: true,
        data: TWidgetType.match(
          type,
          {
            featuredChatApp: () => ({
              appId: '',
            }),
            shortcuts: () => ({
              appIds: [],
            }),
            recentWork: () => ({}),
            apps: () => ({}),
            aiStudio: () => ({}),
            writerAiAcademy: () => ({}),
            extensions: () => ({
              names: [],
            }),
          },
          null,
        ),
      } as IHomeWidgetConfig;

      return acc;
    },
    {} as Record<typeof TWidgetType.type, IHomeWidgetConfig>,
  );
};

export const widgetConfigArrToMap = (configs: IHomeWidgetConfig[]) => {
  return configs.reduce(
    (acc, config) => {
      acc[config.type] = config;

      return acc;
    },
    {} as Record<typeof TWidgetType.type, IHomeWidgetConfig>,
  );
};

export const mergeWidgetConfigs = <T extends typeof TWidgetType.type>(
  teamConfigMap: Record<T, IHomeWidgetConfig>,
  userConfigMap: Record<T, IHomeWidgetConfig>,
): Record<T, IHomeWidgetConfig> => {
  return TWidgetType.list.reduce(
    (acc, type) => {
      acc[type] = TWidgetType.match(
        type,
        {
          featuredChatApp: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {
              appId: user.data.appId ? user.data.appId : team.data.appId,
            },
          }),
          shortcuts: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {
              appIds: [...new Set([...team.data.appIds, ...user.data.appIds])],
            },
          }),
          recentWork: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {},
          }),
          apps: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {},
          }),
          aiStudio: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {},
          }),
          writerAiAcademy: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {},
          }),
          extensions: ({ team, user }) => ({
            id: user.id,
            visible: team.visible,
            type: user.type,
            data: {
              names: difference(team.data.names, user.data.names),
            },
          }),
        },
        {
          team: teamConfigMap[type],
          user: userConfigMap[type],
        },
      );

      return acc;
    },
    {} as Record<T, IHomeWidgetConfig>,
  );
};
