import React from 'react';

import cx from 'classnames';

import { getDomElementOffset } from '@writercolab/common-utils';

import { AnalyticsActivity } from 'constants/analytics';
import analytics from 'services/analytics/analyticsService';

import { CssStyles, calculateArrowStyle, toCssProperty } from '../common/common';

import styles from '../styles.module.css';

export const teamMemberSteps = [
  {
    id: 1,
    title: 'Welcome to Writer, %userName%!',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerDocs)} />
      </div>
    ),
    showDimmer: true,
    navigatorArrowStyle: (): CssStyles => calculateArrowStyle('top-nav-dashboard'),
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedWelcomeStep, {});
    },
    description:
      'Ready to get your whole team writing with one voice? Let’s take a 2-min tour through Writer so you know where to begin.',
  },
  {
    id: 2,
    title: 'Get suggestions everywhere',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerApps)} />
      </div>
    ),
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewedAppsStep, {});
    },
    showDimmer: true,
    description:
      'Writer works across the web. Install a Writer app to get writing suggestions in the websites and tools you’re already using.',
    navigatorArrowStyle: (): CssStyles => calculateArrowStyle('top-nav-apps'),
  },
  {
    id: 3,
    title: 'Ready to see Writer in action?',
    heading: (
      <div className={styles.headingImgContainer}>
        <div className={cx(styles.imgContainer, styles.headingImgContainerDocs)} />
      </div>
    ),
    onActivate: () => {
      analytics.track(AnalyticsActivity.tutorialViewsDemoDocStep, {});
    },
    description:
      'Let’s head over to your demo doc so you can see how Writer checks your content in real time as you type.',
    navigatorArrowStyle: (): CssStyles => {
      const el = document.getElementById('dashboard-documents-table') as HTMLElement;
      const offset = getDomElementOffset(el);

      return {
        minHeight: '59px',
        width: '325px',
        left: toCssProperty(offset.left),
        top: toCssProperty(offset.top + 51),
      };
    },
  },
];
