import React from 'react';

import { ISuggestionExampleConfig } from '@writercolab/common-utils';
import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import { ExampleBlockHeader } from './generic/ExampleBlockHeader';
import ExampleBlockList from './generic/ExampleBlockList';

import commonStyles from '../styles.module.css';
import styles from './styles.module.css';

interface ConfidenceExampleProps {}

const exampleConfig: ISuggestionExampleConfig[] = [
  {
    id: '1',
    title: 'Examples',
    contents: [
      {
        id: '1',
        content: (
          <div className={styles.exampleBlockInlineList}>
            <div className={styles.exampleBlockInlineListItem}>
              <Text highlightColor={HighlightColor.RED} variant={TextSize.L} inline>
                I feel like we should
              </Text>
              <Text variant={TextSize.L}> meet again tomorrow to make a decision.</Text>
            </div>
            <Text variant={TextSize.L} bold inline className={styles.exampleBlockInlineListItemArrow}>
              &rarr;
            </Text>
            <div className={styles.exampleBlockInlineListItem}>
              <Text highlightColor={HighlightColor.GREEN} variant={TextSize.L} inline>
                We should
              </Text>
              <Text variant={TextSize.L} inline>
                {' '}
                meet again tomorrow to make a decision.
              </Text>
            </div>
          </div>
        ),
      },
      {
        id: '2',
        content: (
          <div className={styles.exampleBlockInlineList}>
            <div className={styles.exampleBlockInlineListItem}>
              <Text
                highlightColor={HighlightColor.RED}
                variant={TextSize.L}
                inline
                className={styles.exampleBlockInlineListItemDouble}
              >
                I think I should be able{' '}
              </Text>
              <Text
                highlightColor={HighlightColor.RED}
                variant={TextSize.L}
                inline
                className={styles.exampleBlockInlineListItemDouble}
              >
                to
              </Text>
              <Text variant={TextSize.L} inline>
                {' '}
                send you the report by Friday.
              </Text>
            </div>
            <Text variant={TextSize.L} bold inline className={styles.exampleBlockInlineListItemArrow}>
              &rarr;
            </Text>
            <div className={styles.exampleBlockInlineListItem}>
              <Text highlightColor={HighlightColor.GREEN} variant={TextSize.L} inline>
                I can
              </Text>
              <Text variant={TextSize.L} inline>
                {' '}
                send you the report by Friday.
              </Text>
            </div>
          </div>
        ),
      },
      {
        id: '3',
        content: (
          <div className={styles.exampleBlockInlineList}>
            <div className={styles.exampleBlockInlineListItem}>
              <Text
                highlightColor={HighlightColor.RED}
                variant={TextSize.L}
                inline
                className={styles.exampleBlockInlineListItemDouble}
              >
                The way I see it,
              </Text>
              <Text variant={TextSize.L} inline>
                {' '}
                the second option is better for our team.
              </Text>
            </div>
            <Text variant={TextSize.L} bold inline className={styles.exampleBlockInlineListItemArrow}>
              &rarr;
            </Text>
            <div className={styles.exampleBlockInlineListItem}>
              <Text variant={TextSize.L} inline>
                The second option is better for our team.
              </Text>
            </div>
          </div>
        ),
      },
    ],
  },
];

export const ConfidenceExample: React.FC<ConfidenceExampleProps> = () => (
  <div className={commonStyles.tooltipContent}>
    {exampleConfig.map(example => (
      <div className={styles.exampleBlockContainer} key={example.id}>
        <ExampleBlockHeader key={example.id}>{example.title}</ExampleBlockHeader>
        <ExampleBlockList blocks={example.contents} />
      </div>
    ))}
  </div>
);

export default ConfidenceExample;

// The second option is better for our team.
