import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export const MAX_FILE_SIZE = 2048;

export const MsOfficeExtendedFileFormats = new Enum('docx', 'pptx', 'xlsx');
export type TMsOfficeExtendedFileFormats = typeof MsOfficeExtendedFileFormats.type;

export const SUPPORTED_VIDEO_FILE_EXT = ['webm', 'mts', 'm2ts', 'ts', 'mov', 'mp4', 'm4p', 'm4v', 'mxf'];

export const SUPPORTED_AUDIO_FILE_EXT = [
  '3ga',
  'aac',
  'ac3',
  'aif',
  'aiff',
  'alac',
  'amr',
  'ape',
  'au',
  'dss',
  'flac',
  'm4a',
  'm4b',
  'm4p',
  'mp3',
  'mpga',
  'ogg',
  'oga',
  'mogg',
  'opus',
  'qcp',
  'tta',
  'voc',
  'wav',
  'wma',
  'wv',
];

export const SUPPORTED_SUBTITLES_FILE_EXT = ['srt', 'txt', 'vtt'];

export type TMediaFileDetails = components['schemas']['media_dto_MediaFileResponse'];

export const TMediaFileUploadType = new Enum('Youtube', 'Audio', 'Video', 'Text', 'SRT', 'VTT', 'Other');

export const TMediaFileUploadPlaintextTypes: (typeof TMediaFileUploadType.type)[] = [
  TMediaFileUploadType.enum.Text,
  TMediaFileUploadType.enum.SRT,
  TMediaFileUploadType.enum.VTT,
];
