import React from 'react';

import cx from 'classnames';

import {
  Heading,
  HeadingVariant,
  LabelledTags,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  WTimeAgo,
} from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface ISuggestionPageHeader {
  readOnly?: boolean;
  teamName: string;
  categoryName: string;
  lastUpdatedTime: string;
  lastUpdatedBy: string;
  isSaving?: boolean;
  isOrgAdmin?: boolean;
  tooltipTitle?: string | React.ReactNode;
  tooltipWrapperClassname?: string;
}

const SuggestionPageHeader: React.FC<ISuggestionPageHeader> = ({
  readOnly,
  teamName,
  categoryName,
  isSaving,
  lastUpdatedTime,
  lastUpdatedBy,
  tooltipWrapperClassname,
  tooltipTitle = 'Team members have view only access to style guide.',
}) => (
  <div className={styles.container}>
    <Text variant={TextSize.XXS} upperCase color={TextColor.GREY2} className={styles.teamName}>
      {teamName}
    </Text>
    <div className={styles.header}>
      <Heading variant={HeadingVariant.H2} upperCase className={styles.headerHeading}>
        {categoryName}
      </Heading>
      {readOnly && (
        <Tooltip
          title={tooltipTitle || false}
          className={styles.viewOnlyTag}
          tooltipWrapperClassname={cx(tooltipWrapperClassname, styles.tooltipWidth)}
        >
          <span>
            <LabelledTags bgColor="var(--classic-grey-1)">VIEW ONLY</LabelledTags>
          </span>
        </Tooltip>
      )}
    </div>
    <div className={styles.updatedAt}>
      {isSaving && (
        <Text variant={TextSize.XS} upperCase color={TextColor.GREY2} className={styles.changeDate}>
          Saving...
        </Text>
      )}
      {!isSaving && (
        <Text variant={TextSize.XS} upperCase color={TextColor.GREY2} className={styles.changeDate}>
          Last change by {lastUpdatedBy} | <WTimeAgo date={lastUpdatedTime} />
        </Text>
      )}
    </div>
  </div>
);

export default SuggestionPageHeader;
