import type { components, DateTime } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export const TUserActivityReportFileDownload = new Enum('csv', 'xlsx');
export const TUserActivitySortColumn = new Enum(
  'GroupName',
  'AcceptedSuggestions',
  'ViewedSuggestions',
  'ContentGenerated',
  'RecapGenerated',
  'BlogPostGenerated',
  'AskWriterSessionCreated',
  'AskWriterFileAnalyzed',
  'GroupTotal',
);

export const TUserActivitySortOrder = new Enum('asc', 'desc');
export const TUserOrEventSortBy = new Enum('Events', 'UniqueUsers');
export const TUserActivityModal = new Enum('activeUserDefinition');
export const TUserActivityGrouping = new Enum('TimePeriod', 'Team', 'BillingGroup', 'Feature', 'Surface', 'User');
export const TUserActivityTimeGrouping = new Enum('DayGrouping', 'WeekGrouping', 'MonthGrouping');
export const TUserActivityCountFilter = new Enum('billingGroupIds', 'teamIds');

export const TUserActivityChartSeries = new Enum(
  'AcceptedSuggestions',
  'ViewedSuggestions',
  'ContentGenerated',
  'RecapGenerated',
  'BlogPostGenerated',
  'AskWriterSessionCreated',
  'AskWriterFileAnalyzed',
  'SeatsInUse',
  'ActiveUsers',
  'TotalEvents',
  'SeatsActive',
);

export const TUserActivityActiveUserDefinition = new Enum(
  'AcceptedSuggestions',
  'ViewedSuggestions',
  'ContentGenerated',
  'RecapGenerated',
  'BlogPostGenerated',
  'AskWriterSessionCreated',
  'AskWriterFileAnalyzed',
);

export const TUserActivityTableColumn = new Enum(
  'TimePeriod',
  'AllUsersOrEvents',
  'Team',
  'BillingGroup',
  'Feature',
  'AppType',
  'Surface',
  'User',
  'UserActivity',
  'AcceptedSuggestions',
  'ViewedSuggestions',
  'ContentGenerated',
  'RecapGenerated',
  'BlogPostGenerated',
  'AskWriterSessionCreated',
  'AskWriterFileAnalyzed',
);

export const TUserActivityTableApp = new Enum(
  'PreBuiltApplication',
  'CustomApplication',
  'Feature',
  'VoiceRewrite',
  'WriterRewrite',
  'CustomRewrite',
);

export const TUserActivityTableUserStatus = new Enum('Active', 'Inactive', 'ApprovalPending', 'InvitePending');
// TODO need to update this enum after updating the API
export const TUserActivityFeature = new Enum(
  'VoiceRewrite',
  'WriterRewrite',
  'CustomRewrite',
  'PreBuiltApplication',
  'CustomApplication',
  'Feature',
);

// Chart
export type TUserActivityChartPayload = components['schemas']['reporting_dto_UserActivityDataReportRequest'];
export type TUserActivityChartUserDefinition =
  components['schemas']['reporting_dto_UserActivityDataReportRequest']['activeUserDefinition'];
export type TUserActivityChartResponse = components['schemas']['reporting_dto_UserActivityDataReportResponse'];

// CSV or XLSX report download
export type TUserActivityDownloadPayload = components['schemas']['reporting_dto_UserActivityStatsReportRequest'];
// URL params
export type TUserActivityUrlParams = {
  from: DateTime;
  to: DateTime;
  sortColumn: typeof TUserActivitySortColumn.type;
  sortOrder: typeof TUserActivitySortOrder.type;
  sortField: typeof TUserOrEventSortBy.type;
  grouping: typeof TUserActivityGrouping.type;
  timeGrouping: typeof TUserActivityTimeGrouping.type;
  activeUserDefinition?:
    | null
    | [typeof TUserActivityActiveUserDefinition.type, ...(typeof TUserActivityActiveUserDefinition.type)[]];

  billingGroupIds?: null | [number, ...number[]];
  teamIds?: null | [number, ...number[]];
  userIds?: null | [number, ...number[]];

  userStatus?: null | [typeof TUserActivityTableUserStatus.type, ...(typeof TUserActivityTableUserStatus.type)[]];
  features?: null | [typeof TUserActivityFeature.type, ...(typeof TUserActivityFeature.type)[]];
};

// Table
export type TUserActivityTablePayload = components['schemas']['reporting_dto_UserActivityStatsReportRequest'];
export type TUserActivityTableResponse = components['schemas']['reporting_dto_UserActivityStatsReportResponse'];
export type TUserActivityTableResponseGroupTotal =
  components['schemas']['reporting_model_UserActivityReportModel_GroupTotalPercentage'];
export type TUserActivityTableResponseSeriesTotal =
  components['schemas']['reporting_model_UserActivityReportModel_SeriesTotalPercentage'];
export type TUserActivityTableResponseDataItem =
  components['schemas']['reporting_model_UserActivityReportModel_GroupSeriesTotal'];

export type TUserActivityTableArgs = {
  limit?: number;
  offset?: number;
};

// Users filter for the table
export type TUserActivityTableFilterUsersResponse =
  components['schemas']['model_PaginatedResult_com_qordoba_user_dto_UserAndTeamsResponse'];
export type TUserActivityTableFilterUser = components['schemas']['com_qordoba_user_dto_UserAndTeamsResponse'];

export interface TUserActivityTableFilterUserSelected {
  id: string;
  name: string;
  description: string;
}

export interface TUserActivityTableFilterUsersArgs {
  offset: number;
  limit: number;
}

export interface TUserActivityTableFilterUsersExtraArgs {
  search?: string;
}
