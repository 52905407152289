import React from 'react';

import cx from 'classnames';

import { delay } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import ClaimDetectionButtonCounter from './ClaimDetectionButtonCounter';
import ClaimTooltipText from './ClaimTooltipText';

import styles from '../styles.module.css';

interface IClaimButtonExpandProps {
  count?: number;
  onToggleClaim?: () => void;
  isLoading?: boolean;
}

export const ClaimButtonExpand: React.FC<IClaimButtonExpandProps> = ({ onToggleClaim, count, isLoading }) => {
  const [isClosing, setIsClosing] = React.useState(false);

  const _onToggleClaim = async () => {
    setIsClosing(true);
    await delay(400);

    onToggleClaim && onToggleClaim();
  };

  return (
    <>
      <div className={cx(styles.container, styles.clickable)}>
        <Tooltip
          title={<ClaimTooltipText content="Exit claim detection mode" />}
          placement="bottom"
          tooltipWrapperClassname={styles.containerTooltipShort}
        >
          <div
            className={cx(styles.animateWrapper, styles.containerButtonOn, {
              [styles.containerButtonLoadingPink]: isLoading && !isClosing,
              [styles.animateWrapperBackwards]: isClosing,
            })}
            onClick={_onToggleClaim}
          >
            <div className={styles.backdropAnimate} />
            <Button
              className={styles.containerAnimateButtonClose}
              round
              size={SizeTypes.XXS}
              type={ButtonTypes.BLACK}
              icon={<Icon name={IconVariant.CLOSE_WHITE} width={13} height={13} />}
            />
            <div
              className={cx(styles.containerAnimateButtonText, {
                [styles.containerAnimateButtonTextBackward]: isClosing,
              })}
            >
              <Text variant={TextSize.M} color={TextColor.BLACK} className={styles.containerToolButtonExpandedText}>
                Claim detection mode is ON
              </Text>
            </div>
            <Button
              round
              size={SizeTypes.MEDIUM}
              type={ButtonTypes.PURPLE}
              icon={<Icon name={IconVariant.CLAIM_WHITE} />}
              className={styles.containerToolButtonExpandedButton}
            />
          </div>
        </Tooltip>
        <ClaimDetectionButtonCounter count={count} />
      </div>
    </>
  );
};

export default ClaimButtonExpand;
