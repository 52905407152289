import type React from 'react';
import { createContext, useContext, useReducer } from 'react';

import type { MagicLinksWebsite } from '@writercolab/common-utils';
import { addMagicLinksWebsite, deleteMagicLinksWebsite, getMagicLinksWebsites } from '@writercolab/common-utils';
import { TSubscriptionLimitType } from '@writercolab/models';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { snackbarMessages } from '@web/component-library';
import type { WithChildren } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import { BackendErrorCodeKey } from '../constants/BackendErrorCodeKey';
import { useAppState } from '../state';
import { getLogger } from '../utils/logger';
import { useSuggestionsContext } from './suggestionsContext';

export enum ISuggestionStateAsyncField {
  IS_LOADING_WEBSITES = 'isLoadingWebsites',
  IS_DELETING_WEBSITES = 'isDeletingWebsites',
  IS_SHOW_ADD_SLIDEOUT = 'showAddSlideout',
  IS_SHOW_MANAGE_SLIDEOUT = 'showManageSlideout',
}

export enum TSuggestionActionType {
  SetAsyncLoading = 'SetAsyncLoading',
  SetSelectedWebsite = 'SetSelectedWebsite',
  SetWebsites = 'SetWebsites',
}

type TSuggestionAction = {
  type: TSuggestionActionType;
  payload: any;
};

interface ISetStateProps {
  type: TSuggestionActionType;
  payload: any;
}

interface ISuggestionsState {
  isLoadingWebsites: boolean;
  isDeletingWebsite: boolean;
  showAddSlideout: boolean;
  selectedWebsite: MagicLinksWebsite | null;
  magicWebsites: MagicLinksWebsite[];
}

interface IMagicLinksSetupContext {
  suggestionsState: ISuggestionsState;
  teamId?: number;
  setSuggestionsState: ({ type, payload }: ISetStateProps) => void;
  handleFetchWebsites: () => void;
  handleAddWebsite: (websiteUrl: string) => Promise<void>;
  handleDeleteWebsite: (website: MagicLinksWebsite) => void;
}

const LOG = getLogger('MagicLinksSetupContext');

const initialSuggestionsReducer: ISuggestionsState = {
  isLoadingWebsites: false,
  isDeletingWebsite: false,
  showAddSlideout: false,
  selectedWebsite: null,
  magicWebsites: [],
};

const MagicLinksSetupContext = createContext<IMagicLinksSetupContext>({} as IMagicLinksSetupContext);

const getUserErrorMessage = (e: any) => {
  let errorMessage = '';

  if (
    e.response.data.errors &&
    e.response.data.errors[0] &&
    e.response.data.errors[0].key === BackendErrorCodeKey.MAGICLINKS_WEBSITE_URL_VALIDATION
  ) {
    errorMessage = 'This URL is invalid. Double check it and re-enter a valid website URL.';
  } else {
    errorMessage = e.response.data.errors ? e.response.data.errors[0].description : e.response.data || e?.message;
  }

  return errorMessage;
};

const MagicLinksSetupContextProvider: React.FC<WithChildren> = observer(({ children }) => {
  const {
    appState,
    loadApplicationStats,
    appModel: { analyticsService },
  } = useAppState();

  const { suggestionSettings, updateSettingsSection } = useSuggestionsContext();

  const [suggestionsState, setSuggestionsState] = useReducer(
    (state: ISuggestionsState, action: TSuggestionAction): ISuggestionsState => {
      switch (action.type) {
        case TSuggestionActionType.SetAsyncLoading:
          return { ...state, [action.payload.prop]: action.payload.isLoading };
        case TSuggestionActionType.SetSelectedWebsite:
          return { ...state, selectedWebsite: action.payload };
        case TSuggestionActionType.SetWebsites:
          return { ...state, magicWebsites: action.payload };
        default:
          return { ...state };
      }
    },
    initialSuggestionsReducer,
  );

  const { enqueueErrorSnackbar } = useCustomSnackbar();

  const _triggerSectionLastUpdate = () => {
    const defaultSectionToUpdate = suggestionSettings?.categories[0];
    defaultSectionToUpdate && updateSettingsSection(defaultSectionToUpdate);
  };

  const handleFetchWebsites = async () => {
    setSuggestionsState({
      type: TSuggestionActionType.SetAsyncLoading,
      payload: {
        prop: ISuggestionStateAsyncField.IS_LOADING_WEBSITES,
        isLoading: true,
      },
    });

    try {
      const res = await getMagicLinksWebsites({
        orgId: `${appState.organizationId}`,
        teamId: `${appState.teamId}`,
      });

      setSuggestionsState({
        type: TSuggestionActionType.SetWebsites,
        payload: res.websites,
      });
    } catch (e) {
      const errMessage = getUserErrorMessage(e);
      enqueueErrorSnackbar(snackbarMessages.magicLinks.getDomainsError(errMessage));
    } finally {
      setSuggestionsState({
        type: TSuggestionActionType.SetAsyncLoading,
        payload: {
          prop: ISuggestionStateAsyncField.IS_LOADING_WEBSITES,
          isLoading: false,
        },
      });
    }
  };

  const handleAddWebsite = async (websiteUrl: string) => {
    try {
      if (!appState.teamId) {
        LOG.error('Team id is not defined');

        return;
      }

      await addMagicLinksWebsite({ orgId: `${appState.organizationId}`, teamId: appState.teamId, websiteUrl });

      _triggerSectionLastUpdate();
      await handleFetchWebsites();

      setSuggestionsState({
        type: TSuggestionActionType.SetAsyncLoading,
        payload: {
          prop: ISuggestionStateAsyncField.IS_SHOW_ADD_SLIDEOUT,
          isLoading: false,
        },
      });

      await loadApplicationStats(TSubscriptionLimitType.enum.domain);

      analyticsService.track(AnalyticsActivity.magciLinksAddDomain, {
        clicked_from: 'magic-links',
      });
    } catch (e) {
      const errMessage = getUserErrorMessage(e);
      enqueueErrorSnackbar(snackbarMessages.magicLinks.addDomainError(errMessage));
    }
  };

  const handleDeleteWebsite = async (website: MagicLinksWebsite) => {
    setSuggestionsState({
      type: TSuggestionActionType.SetAsyncLoading,
      payload: {
        prop: ISuggestionStateAsyncField.IS_DELETING_WEBSITES,
        isLoading: true,
      },
    });

    try {
      await deleteMagicLinksWebsite({
        orgId: `${appState.organizationId}`,
        teamId: `${appState.teamId}`,
        websiteId: website.id,
      });

      _triggerSectionLastUpdate();
      await loadApplicationStats(TSubscriptionLimitType.enum.domain);
      await handleFetchWebsites();
    } catch (e) {
      const errMessage = getUserErrorMessage(e);
      enqueueErrorSnackbar(snackbarMessages.magicLinks.deleteDomainError(errMessage));
    } finally {
      setSuggestionsState({
        type: TSuggestionActionType.SetAsyncLoading,
        payload: {
          prop: ISuggestionStateAsyncField.IS_DELETING_WEBSITES,
          isLoading: false,
        },
      });
    }
  };

  return (
    <MagicLinksSetupContext.Provider
      value={{
        suggestionsState,
        teamId: appState.teamId,
        setSuggestionsState,
        handleAddWebsite,
        handleDeleteWebsite,
        handleFetchWebsites,
      }}
    >
      {children}
    </MagicLinksSetupContext.Provider>
  );
});

export function useMagicLinksSetupContext() {
  const context = useContext(MagicLinksSetupContext);

  if (!context) {
    throw new Error('useMagicLinksSetupContext must be used within the MagicLinksSetupContextProvider');
  }

  return context;
}

export default MagicLinksSetupContextProvider;
