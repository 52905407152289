import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import { preventEventDefault } from '@writercolab/common-utils';
import { Button, ButtonTypes, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';

import { getLogger } from '../../../../utils/logger';

import styles from '../styles.module.css';

const LOG = getLogger('CreateServiceAccountModal');
const SERVICE_ACCOUNT_NAME_MAX_LENGTH = 32;

interface ICreateServiceAccountModalProps {
  open: boolean;
  onCancel?: () => void;
  onCreateServiceAccount: (serviceAccountName: string) => Promise<void>;
}

const InputLabel = ({ text }) => (
  <Text variant={TextSize.XS} className={styles.label} smallCaps>
    {text}
  </Text>
);

export const CreateServiceAccountModal: React.FC<ICreateServiceAccountModalProps> = ({
  open,
  onCancel,
  onCreateServiceAccount,
}) => {
  const [loading, setLoading] = useState(false);
  const [serviceAccountName, setServiceAccountName] = useState('');
  const [serviceAccountNameValid, setServiceAccountNameValid] = useState<boolean | null>(null);
  const [serviceAccountNameErrorMessage, setServiceAccountNameErrorMessage] = useState<string | undefined>(undefined);

  const onAccountNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: accountName } = event.target;
    const valueValid = !isEmpty(accountName) && accountName.length <= SERVICE_ACCOUNT_NAME_MAX_LENGTH;
    setServiceAccountNameValid(valueValid);
    setServiceAccountName(accountName);

    if (valueValid) {
      setServiceAccountNameErrorMessage(undefined);
    } else if (isEmpty(accountName)) {
      setServiceAccountNameErrorMessage('this field is required');
    } else if (accountName.length > SERVICE_ACCOUNT_NAME_MAX_LENGTH) {
      setServiceAccountNameErrorMessage(`must be ${SERVICE_ACCOUNT_NAME_MAX_LENGTH} char or fewer`);
    }
  }, []);

  const onAccountNameSave = useCallback(
    async (e: React.FormEvent) => {
      try {
        preventEventDefault(e);

        if (serviceAccountNameValid) {
          setLoading(true);
          await onCreateServiceAccount(serviceAccountName);
        }
      } catch (e) {
        LOG.error(e);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceAccountName, serviceAccountNameValid],
  );

  useEffect(() => {
    if (open) {
      setLoading(false);
      setServiceAccountName('');
      setServiceAccountNameValid(null);
      setServiceAccountNameErrorMessage(undefined);
    }
  }, [open]);

  return (
    <Modal open={open} handleClose={onCancel} title="Create a service account" style={{ width: 520 }}>
      <form onSubmit={onAccountNameSave}>
        <div className={cx(styles.containerFormRow)}>
          <InputGroup
            id="serviceAccountName"
            label={<InputLabel text="Name" />}
            inputType={InputTypes.CUSTOM}
            disableHelperText
            errorText={serviceAccountNameErrorMessage}
          >
            <InputGroup
              id="serviceAccountName"
              name="serviceAccountName"
              autoComplete="new-password"
              label=""
              value={serviceAccountName}
              onChange={onAccountNameChange}
              disabled={loading}
            />
          </InputGroup>
        </div>
        <div className={cx(styles.containerRow, styles.containerRowModalActions)}>
          <Button
            className={styles.saveServiceAccountButton}
            type={ButtonTypes.PRIMARY}
            size={SizeTypes.SMALL}
            content="Create account"
            onClick={onAccountNameSave}
            disabled={!serviceAccountNameValid}
            isLoading={loading}
          />
        </div>
      </form>
    </Modal>
  );
};

export default CreateServiceAccountModal;
