import React from 'react';

import cx from 'classnames';

import { IUserProfile } from '@writercolab/common-utils';
import {
  AvatarSize,
  DropdownOption,
  Logo,
  LogoVariant,
  Text,
  TextColor,
  TextSize,
  UserAvatar,
} from '@writercolab/ui-atoms';
import { ProfilePopupDrawer } from '@writercolab/ui-molecules';

import sadCharacter from '../../../assets/backgrounds/sadCharacter.png';

import styles from './styles.module.css';

interface NoTeamsPageProps {
  orgList: DropdownOption[] | undefined;
  userProfile: IUserProfile;
  organizationName: string;
  onClickSupport: () => void;
  onOrganizationChange: (id: string) => void;
  onProfileOptionClick: () => void;
  onSignOutClick: () => void;
}

export const NoTeamsPage: React.FC<NoTeamsPageProps> = ({
  orgList,
  userProfile,
  organizationName,
  onSignOutClick,
  onClickSupport,
  onProfileOptionClick,
  onOrganizationChange,
}) => (
  <div className={styles.container}>
    <div className={styles.navigationHolder}>
      <Logo />
      <div className={cx(styles.signoutContainer, styles.clickable)}>
        <Text bold variant={TextSize.XL} color={TextColor.GREY2} onClick={onSignOutClick}>
          Sign out
        </Text>
      </div>
    </div>
    <div className={styles.contentHolder}>
      <div className={styles.bannerContainer}>
        <img className={styles.sadIcon} src={sadCharacter} alt="Sad Icon" width={90} />
        <div className={styles.heading}>
          <Text variant={TextSize.XXXXL} bold>
            Welcome to Writer!
          </Text>
        </div>
        <div className={styles.body}>
          <Text variant={TextSize.XXXXL} className={styles.bodyText}>
            It looks like you haven’t been added to a Team yet.
          </Text>
          <Text variant={TextSize.XXXXL} className={styles.bodyText}>
            Contact your org admin so they can get you set up.
          </Text>
        </div>
        <div className={styles.containerHeroLogo}>
          <Logo variant={LogoVariant.FULL_BLACK} />
        </div>
      </div>
    </div>
    <div className={styles.profileContainer}>
      {userProfile && (
        <ProfilePopupDrawer
          onClickProfile={onProfileOptionClick}
          userFullName={userProfile.fullName}
          userEmail={userProfile.email || ''}
          userAvatar={
            <UserAvatar fullName={userProfile.fullName} avatarPath={userProfile.avatar} size={AvatarSize.L} />
          }
          onClickSupport={onClickSupport}
          onSignOut={onSignOutClick}
          orgList={orgList}
          currentOrgName={organizationName}
          onSelectOrganization={onOrganizationChange}
        />
      )}
    </div>
  </div>
);

export default NoTeamsPage;
