import { type RequestServiceInitialize } from '@writercolab/network';
import type { TGetOrganizationSessionsPaginatedResponse } from '@writercolab/types';

import type { TGetOrganizationSessionsQueryParams, TGetTeamSessionsQueryParams } from '@web/types';
import { getLogger } from 'utils/logger';

const LOG = getLogger('sessions.api');

export interface ISessionsApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number;
}

export class SessionsApiModel {
  constructor(private readonly opts: ISessionsApiModelOptions) {
    this.opts = opts;
  }

  getOrganizationSessions = async (
    payload: TGetOrganizationSessionsQueryParams,
  ): Promise<TGetOrganizationSessionsPaginatedResponse> => {
    try {
      const { data } = await this.opts.request.get('/api/ask/organization/{organizationId}/session', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
          query: {
            ...payload,
          },
        },
      });

      return data;
    } catch (e) {
      LOG.error('Failed to get organization sessions', e);
      throw e;
    }
  };

  getTeamSessions = async (
    payload: TGetTeamSessionsQueryParams,
  ): Promise<TGetOrganizationSessionsPaginatedResponse> => {
    try {
      const { data } = await this.opts.request.get('/api/ask/organization/{organizationId}/team/{teamId}/session', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            teamId: this.opts.teamId,
          },
          query: {
            ...payload,
          },
        },
      });

      return data;
    } catch (e) {
      LOG.error('Failed to get team sessions', e);
      throw e;
    }
  };

  deleteSession = async (sessionId: string): Promise<void> => {
    try {
      await this.opts.request.delete('/api/ask/organization/{organizationId}/session/{sessionId}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            sessionId,
          },
        },
      });
    } catch (e) {
      LOG.error('Failed to delete session', e);
      throw e;
    }
  };

  updateSessionTitle = async (sessionId: string, title: string): Promise<void> => {
    try {
      await this.opts.request.put('/api/ask/organization/{organizationId}/session/{sessionId}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            sessionId,
          },
        },
        body: {
          title,
        },
      });
    } catch (e) {
      LOG.error('Failed to update session title', e);
      throw e;
    }
  };
}
