import type { Range } from 'quill';
import { Clipboard } from 'quill';
import Delta from 'quill-delta';
import { normalizeAndCleanDelta } from '@writercolab/quill-delta-utils';
import { StyleAttributor } from 'parchment';

const fontWeightAttributor = new StyleAttributor('font-weight', 'font-weight');

// we need a custom clipboard to override the behaviour on content paste
// the main difference is that all content update is now done using 'silent' source
// and there is a hooks options that can be passed to the clipboard module and run after
// content paste (we need to call a separate API on content paste)
export class CustomQuillClipboard extends Clipboard {
  override onPaste(range: Range, { text, html }: { text?: string; html?: string }) {
    const formats = this.quill.getFormat(range.index);
    const pastedDelta = normalizeAndCleanDelta(
      this.convert(
        {
          text,
          html: this.process(html ?? ''),
        },
        formats,
      ),
    );
    const delta = new Delta().retain(range.index).delete(range.length).concat(pastedDelta);
    this.quill.updateContents(delta, 'user'); // range.length contributes to delta.length()

    this.quill.setSelection(delta.length() - range.length, 'silent');
    this.quill.scrollIntoView();
  }

  // we need to do some manipulation with html that is being paste into the editor
  // we noticed a weird case when we get <b style="font-weight: normal">CONTENT</b> case
  // when we sometimes copy text from external editors. This leads to full text is bold after paste
  // I decided to go through the first level of elements from given html and replace such items with spans
  // use this method if you need to add more transformers into the paste html (MM-571)
  process(html: string) {
    const container = document.createElement('div');

    container.innerHTML = html;

    const newChildren = Array.from(container.childNodes).map(this.normalizeBoldElements);

    container.innerHTML = '';
    newChildren.forEach(child => container.appendChild(child));

    return container.innerHTML;
  }

  normalizeBoldElements(element: ChildNode) {
    const tags = ['B', 'STRONG'];

    if (
      element instanceof HTMLElement &&
      fontWeightAttributor.value(element) === 'normal' &&
      tags.includes(element.tagName)
    ) {
      const span = document.createElement('span');
      span.innerHTML = element.innerHTML;

      return span;
    }

    return element;
  }
}
