import React, { useEffect, useState } from 'react';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Logo,
  LogoVariant,
} from '@writercolab/ui-atoms';

import { useNavigate } from 'react-router';

import { capitalizedLabelByType } from '../consts';
import type { ImportItemType } from '../types';
import { ExitImporterModal } from './ExitImporterModal';

import styles from './styles.module.css';

interface IImporterPopupHeaderProps {
  step: number;
  onClose: () => void;
  type: ImportItemType;
}

export const ImporterPopupHeader = ({ step, onClose, type }: IImporterPopupHeaderProps) => {
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const popStateHandler = () => {
      setCloseModalOpen(true);
      navigate(1);
    };
    const unloadHandler = event => {
      // eslint-disable-next-line no-param-reassign
      event.returnValue = true;
    };

    if (step === 1) {
      window.addEventListener('popstate', popStateHandler);
      window.addEventListener('beforeunload', unloadHandler);
    }

    return () => {
      window.removeEventListener('popstate', popStateHandler);
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [navigate, step]);

  const onClickClose = () => {
    if (step === 1) {
      setCloseModalOpen(true);
    } else {
      onClose();
    }
  };

  const importerTypeLabel = capitalizedLabelByType[type];

  return (
    <div className={styles.popupHeader}>
      <Logo variant={LogoVariant.BLACK} />
      <Heading variant={HeadingVariant.H2} upperCase className={styles.headerTitle}>
        {importerTypeLabel} importer
      </Heading>
      <Button
        icon={<Icon name={IconVariant.CLOSE} width={32} height={32} />}
        type={ButtonTypes.GRAY}
        round
        onClick={onClickClose}
      />
      <ExitImporterModal
        isOpen={closeModalOpen}
        onCloseModal={() => setCloseModalOpen(false)}
        onCloseImporter={onClose}
        type={type}
      />
    </div>
  );
};
