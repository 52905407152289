export interface ListResponse<LIST> {
  pagination: {
    offset: number;
    limit: number;
  };
  totalCount: number;
  result: LIST[];
}

export const DEFAULT_PAGINATION_LIMIT = 50;
