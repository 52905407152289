import React from 'react';

import { Modal } from '@writercolab/ui-atoms';

import { BillingContactForm } from '../BillingContactForm';

import styles from './styles.module.css';

interface BillingContactPopupProps {
  open: boolean;
  contact?: string;
  isSubmitting?: boolean;
  onCancel?: () => void;
  onSubmit?: (contact) => void;
}

export const BillingContactPopup: React.FC<BillingContactPopupProps> = ({
  open,
  onCancel,
  contact,
  onSubmit,
  isSubmitting,
}) => (
  <Modal open={open} title="Update billing contact" className={styles.modalBody} handleClose={onCancel}>
    <BillingContactForm contact={contact} onSubmit={onSubmit} onCancel={onCancel} isSubmitting={isSubmitting} />
  </Modal>
);
export default BillingContactPopup;
