import React, { useEffect } from 'react';

import cn from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import { Button, ButtonTypes, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';

import { observer } from 'mobx-react-lite';

import iconPlanetPng from '../../../assets/icons/planet.png';
import { GraphStatusUIModel, STATUS_SYNC_TIMEOUT } from './GraphStatusModel.ui';

import styles from './GraphStatus.module.css';

interface IGraphStatusProps {
  model: GraphStatusUIModel;
  isConnected: boolean;
  viewOnly: boolean;
  manualUploadsInProgress?: number;
  onFailedFilesClick: () => void;
}

export const GraphStatus = observer(
  ({ model, isConnected, viewOnly, manualUploadsInProgress, onFailedFilesClick }: IGraphStatusProps) => {
    // If there are manual file uploads in progress, show them instead of parsing ones
    const uploadsInProgress = manualUploadsInProgress || model.parsingFilesCount;

    useEffect(() => {
      let interval;

      if (isConnected) {
        interval = setInterval(() => model.reloadFilesStats(), STATUS_SYNC_TIMEOUT);
      }

      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }, [model, isConnected]);

    return (
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={cn(styles.statusWrapper, styles.column)}>
            <div className={styles.statusIconWrapper}>
              <img src={iconPlanetPng} width={50} height={50} alt="Graph status" />
            </div>
            <div className={styles.statusTextWrapper}>
              <Text variant={TextSize.M} color={TextColor.GREY} upperCase>
                Graph status
              </Text>
              <Text variant={TextSize.XXXXL} color={TextColor.BLACK}>
                {model.successTotalFilesCount} {wordPluralize(model.successTotalFilesCount, 'file')}
              </Text>
            </div>
          </div>

          {(uploadsInProgress > 0 || model.indexingFilesCount > 0) && (
            <div className={cn(styles.progressWrapper, styles.column)}>
              {uploadsInProgress > 0 && (
                <div className={styles.progressTextWrapper}>
                  <ThreeDotsLoader className={styles.loader} />
                  <Text variant={TextSize.XL} color={TextColor.GREY}>
                    {uploadsInProgress} {wordPluralize(uploadsInProgress, 'file')} currently uploading
                  </Text>
                </div>
              )}

              {model.indexingFilesCount > 0 && (
                <div className={styles.progressTextWrapper}>
                  <ThreeDotsLoader className={styles.loader} />
                  <Text variant={TextSize.XL} color={TextColor.GREY}>
                    {model.indexingFilesCount} {wordPluralize(model.indexingFilesCount, 'file')} currently indexing
                  </Text>
                </div>
              )}
            </div>
          )}

          {model.errorTotalFilesCount > 0 && (
            <div className={cn(styles.errorWrapper, styles.column)}>
              {model.errorTotalFilesCount > 0 && (
                <div className={styles.errorTextWrapper}>
                  <div className={styles.redDot} />
                  <Text variant={TextSize.XL} color={TextColor.GREY}>
                    <span className={styles.textUnderline} onClick={onFailedFilesClick}>
                      {model.errorTotalFilesCount} {wordPluralize(model.errorTotalFilesCount, 'file')}
                    </span>{' '}
                    errored
                  </Text>

                  {!viewOnly && (
                    <Button
                      type={ButtonTypes.GRAY}
                      size={SizeTypes.XS}
                      className={styles.actionBtn}
                      onClick={() => model.onRetry()}
                    >
                      <Text variant={TextSize.L} color={TextColor.GREY} bold>
                        Retry
                      </Text>
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  },
);
