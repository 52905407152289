import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import qs from 'qs';

import { openNewTab, wordPluralize } from '@writercolab/common-utils';
import {
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LinkText,
  SearchBar,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import type { IFilterOptionsFilter, PerPageOption } from '@writercolab/ui-molecules';
import { FilterIndicator, ThreeDotsLoader } from '@writercolab/ui-molecules';

import { AddContentButton } from 'components/generic/AddContentButton';
import KnowledgeGraphIntroductionBanner from 'components/molecules/KnowledgeGraphIntroductionBanner';
import { KnowledgeGraphSelector } from 'components/molecules/KnowledgeGraphSelector';
import { AddKnowledgeGraphModal } from 'components/organisms/AddKnowledgeGraphModal';
import { DeleteKnowledgeGraphModal } from 'components/organisms/DeleteKnowledgeGraphModal';
import { KnowledgeGraphConnector } from 'components/organisms/KnowledgeGraphConnector';
import { RenameKnowledgeGraphModal } from 'components/organisms/RenameKnowledgeGraphModal';
import SectionHeading from 'components/organisms/SectionHeading';
import { KnowledgeGraphWebConnector } from 'components/templates/KnowledgeGraphWebConnector';

import type { TKnowledgeGraphFilesQuery, TKnowledgeGraphFilesSortOrder, TKnowledgeGraphFilesStatus } from '@web/types';
import {
  KG_MAX_FILE_SIZE,
  KG_SUPPORTED_FILE_EXTENSIONS,
  KnowledgeGraphFilesSortField,
  KnowledgeGraphFilesViewType,
  TKnowledgeGraphDropdownAction,
} from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { usePageTitle } from 'hooks/usePageTitle';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';
import { useAppState } from 'state';
import { openContactSalesPage } from 'utils/navigationUtils';

import { DEFAULT_PAGE_SIZE } from '../../../models/knowledgeGraphs.api';
import { validateFiles } from '../../../utils/mediaFilesUtils';
import { FileUploadButton } from '../../molecules/FileUploadButton';
import { GraphStatus } from '../../organisms/GraphStatus';
import { KnowledgeGraphFilesTable } from '../../organisms/KnowledgeGraphFilesTable';
import type { KnowledgeGraphPageModel } from './KnowledgeGraphPageModel';
import { KG_DESCRIPTION_CHARS_MAX, KG_TABLE_SYNC_TIMEOUT } from './KnowledgeGraphPageModel';

import styles from './KnowledgeGraphPage.module.css';

interface IAddGraphTooltipContentProps {
  isBlockedByBalance: boolean;
  isBlockedByUsageLimits: boolean;
  isAdmin: boolean;
  limit: number;
}

const AddGraphTooltipContent = ({
  isBlockedByBalance,
  isBlockedByUsageLimits,
  isAdmin,
  limit,
}: IAddGraphTooltipContentProps) => {
  if (isBlockedByBalance) {
    return (
      <Text variant={TextSize.M} color={TextColor.WHITE} bold className={styles.tooltipBlockedByBalance}>
        Disabled due to insufficient credits, contact your org admin to resume service
      </Text>
    );
  }

  if (isBlockedByUsageLimits) {
    return (
      <Text variant={TextSize.M} color={TextColor.WHITE} bold className={styles.tooltipBlockedByBalance}>
        Disabled due to exceeding usage limits, contact your org admin to resume service
      </Text>
    );
  }

  if (isAdmin) {
    return (
      <Text variant={TextSize.M} color={TextColor.WHITE} bold className={styles.tooltipLimitReached}>
        You've reached your limit of {limit} {wordPluralize(limit, 'Graph')}.{' '}
        <LinkText onClick={openContactSalesPage}>Contact sales</LinkText> to upgrade your plan.
      </Text>
    );
  }

  return (
    <Text variant={TextSize.M} color={TextColor.WHITE} bold className={styles.tooltipViewOnly}>
      Only org admins can create and manage Knowledge Graph
    </Text>
  );
};

const openDocumentation = () => openNewTab('https://support.writer.com/article/208-getting-started-with-knowledge');
const openWebKgDocumentation = () =>
  openNewTab('https://support.writer.com/article/272-setting-up-knowledge-graph-web-connectors');

interface IKnowledgeGraphPageProps {
  model: KnowledgeGraphPageModel;
  isOrgAdmin: boolean;
  isTeamAdmin: boolean;
  teamName?: string;
  isFeatureLocked?: boolean;
  isConnectorsEnabled: boolean;
  isWebConnectorsEnabled: boolean;
  hasAdminAccess?: (entityOwnerId?: number) => boolean;
  isBlockedByBalance: boolean;
  isBlockedByUsageLimits: boolean;
}

const KnowledgeGraphPage = observer<IKnowledgeGraphPageProps>(
  ({
    model,
    isOrgAdmin,
    isTeamAdmin,
    teamName,
    isFeatureLocked,
    isConnectorsEnabled,
    isWebConnectorsEnabled,
    hasAdminAccess,
    isBlockedByBalance,
    isBlockedByUsageLimits,
  }) => {
    usePageTitle('Knowledge Graph');
    useQueueWorkerNotifications(model.tableModel.notificationQueue);

    const { appState, appModel } = useAppState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchFileValue, setSearchFileValue] = useState(searchParams.get('search') || '');

    const updateUrlParams = useCallback(
      (params: TKnowledgeGraphFilesQuery, isArgs?: boolean) => {
        const currentParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });
        const mergedParams = { ...currentParams, ...params };

        setSearchParams(qs.stringify(mergedParams, { arrayFormat: 'repeat' }));

        if (isArgs) {
          model.updateArgs({
            offset: mergedParams.offset,
            limit: mergedParams.limit,
          });
        } else {
          model.updateExtraArgsDebounced(mergedParams);
        }
      },
      [model, searchParams, setSearchParams],
    );

    useEffect(() => {
      appModel.analyticsService.track(
        model.aiStudioMode
          ? AnalyticsActivity.aiStudioKnowledgeGraphViewedPage
          : AnalyticsActivity.knowledgeGraphViewedPage,
        {},
      );
    }, [appModel.analyticsService, model.aiStudioMode]);

    useEffect(() => {
      const initSelectedGraph = async () => {
        const graphId = searchParams.get('graphId');

        if (!model.selectedGraph) {
          if (graphId) {
            const graph = await model.api.getGraphById({ id: graphId });

            if (graph) {
              model.setSelectedGraph(graph);
            }
          } else if (model.graphs.length > 0) {
            const firstGraph = model.graphs[0];
            model.setSelectedGraph(firstGraph);
            updateUrlParams({ graphId: firstGraph.id });
          }
        }
      };

      initSelectedGraph();
    }, [model, model.graphs, searchParams, updateUrlParams]);

    useEffect(() => {
      const interval = setInterval(() => {
        const offset = Number(searchParams.get('offset'));

        if (offset === 0) {
          model.api.refreshPagination();
        }
      }, KG_TABLE_SYNC_TIMEOUT);

      return () => clearInterval(interval);
    }, [model.api, searchParams]);

    const updateSearchValue = useCallback(
      (value: string) => {
        setSearchFileValue(value);
        updateUrlParams({
          search: value,
        });
      },
      [updateUrlParams],
    );

    const handleChangeGraphId = useCallback(
      (selectedFilter: IFilterOptionsFilter) => {
        const graphId = selectedFilter.id;
        const selectedGraph = model.graphs.find(graph => graph.id === graphId);
        model.setSelectedGraph(selectedGraph);
        setSearchFileValue('');
        updateUrlParams({ graphId, search: '', status: [] });
      },
      [model, updateUrlParams],
    );

    const handleDropdownClick = useCallback(
      (action: string) => {
        switch (action) {
          case TKnowledgeGraphDropdownAction.enum.rename:
            model.openRenameGraphModal();
            break;
          case TKnowledgeGraphDropdownAction.enum.delete:
            model.openDeleteGraphModal();
            break;
          default:
            break;
        }
      },
      [model],
    );

    const handleStatusFilterChange = useCallback(
      (status: string) => {
        const statusValues = searchParams.getAll('status') || [];

        if (!statusValues.includes(status)) {
          statusValues.push(status);
        } else {
          statusValues.splice(statusValues.indexOf(status), 1);
        }

        updateUrlParams({
          status: statusValues as TKnowledgeGraphFilesStatus[],
        });
      },
      [searchParams, updateUrlParams],
    );

    const handleSortingChange = useCallback(
      (id: string | number) => {
        updateUrlParams({
          sortOrder: id as TKnowledgeGraphFilesSortOrder,
          sortField: KnowledgeGraphFilesSortField.enum.creationTime,
        });
      },
      [updateUrlParams],
    );

    const handlePaginationChange = useCallback(
      (_, pageNumber: number) => {
        const limit = Number(searchParams.get('limit')) || DEFAULT_PAGE_SIZE;
        const offset = (pageNumber - 1) * limit;
        updateUrlParams(
          {
            offset,
          },
          true,
        );
      },
      [searchParams, updateUrlParams],
    );

    const handleOffsetChange = useCallback(
      (options: PerPageOption) => {
        updateUrlParams(
          {
            limit: +options.value,
            offset: 0,
          },
          true,
        );
      },
      [updateUrlParams],
    );

    const onFileUpload = useCallback(
      (files: FileList) => {
        model.tableModel.handleUploadFiles({
          files,
          knowledgeGraphId: model.selectedGraph?.id || '',
        });
      },
      [model.selectedGraph?.id, model.tableModel],
    );

    const _handleFileSelect = useCallback(
      (files: FileList | null): void => {
        if (files) {
          validateFiles({
            files,
            onSuccess: onFileUpload,
            onFileSizeError: model.tableModel.onFileSizeError,
            onFileTypeError: model.tableModel.onFileTypeError,
            fileSizeLimit: KG_MAX_FILE_SIZE,
            allowedFileExtensions: KG_SUPPORTED_FILE_EXTENSIONS,
          });
        }
      },
      [model.tableModel.onFileSizeError, model.tableModel.onFileTypeError, onFileUpload],
    );

    const handleSearchFile = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        updateSearchValue(value);
      },
      [updateSearchValue],
    );

    const handleClearSearchFile = useCallback(() => {
      updateSearchValue('');
    }, [updateSearchValue]);

    const handleFailedFilesClick = useCallback(() => {
      updateUrlParams({ status: ['error'] });
    }, [updateUrlParams]);

    const renderKnowledgeMain = useMemo(() => {
      const getIsBlockedMessage = () => {
        if (isBlockedByBalance) {
          return 'Disabled due to insufficient credits, contact your org admin to resume service.';
        }

        if (isBlockedByUsageLimits) {
          return 'Disabled due to exceeding usage limits, contact your org admin to resume service.';
        }

        return 'Only team admins can add files to Knowledge Graph.';
      };

      if (model.selectedGraph) {
        const hasAdminAccessToGraph = isTeamAdmin && hasAdminAccess?.(model.selectedGraph.createdBy ?? undefined);

        // WebConnector type of KnowledgeGraph
        if (model.webConnectorModel) {
          return (
            <>
              <KnowledgeGraphSelector
                model={model}
                onChange={handleChangeGraphId}
                onDropdownClick={handleDropdownClick}
                viewOnly={!hasAdminAccessToGraph}
              />
              <KnowledgeGraphWebConnector model={model.webConnectorModel} />
            </>
          );
        }

        // Upload and Connector types of KnowledgeGraph
        return (
          <>
            <KnowledgeGraphSelector
              model={model}
              onChange={handleChangeGraphId}
              onDropdownClick={handleDropdownClick}
              viewOnly={!hasAdminAccessToGraph}
            />

            {model.selectedGraph.description && (
              <>
                <Text className={styles.graphDescriptionLabel} variant={TextSize.XXS} color={TextColor.GREY} upperCase>
                  Description
                </Text>
                <Text className={styles.graphDescription} variant={TextSize.XL}>
                  {model.selectedGraph.description}
                </Text>
              </>
            )}

            {model.connectorModel && (
              <KnowledgeGraphConnector
                model={model.connectorModel}
                viewOnly={!isTeamAdmin}
                isBlockedByBalance={isBlockedByBalance}
                isBlockedByUsageLimits={isBlockedByUsageLimits}
                isOrgAdmin={isOrgAdmin}
                isConnected={!!model.selectedGraph?.graphConnection}
                syncStatusModel={model.syncStatusModel}
                onDeleteConnectionClick={model.openDeleteGraphModal}
                onFailedFilesClick={handleFailedFilesClick}
              />
            )}

            {model.filesStatusModel && !isEmpty(model.tableModel.data) && (
              <div className={styles.graphStatusWrapper}>
                <div className={styles.graphStatusTitleWrapper}>
                  <Heading variant={HeadingVariant.H3} className={styles.graphStatusTitle}>
                    Knowledge Graph files
                  </Heading>
                </div>

                <GraphStatus
                  model={model.filesStatusModel}
                  isConnected={model.isUpload || !!model.connectorModel?.credentials}
                  viewOnly={!isTeamAdmin}
                  manualUploadsInProgress={model.tableModel.uploadsInProgress}
                  onFailedFilesClick={handleFailedFilesClick}
                />
              </div>
            )}

            {(model.isUpload || model.syncStatusModel.connectorSyncStats) && (
              <div
                className={cx({
                  [styles.containerKnowledgeWrapper]: !model.connectorModel?.connection,
                  [styles.connectorFiles]: !!model.connectorModel?.connection,
                })}
              >
                <div className={styles.filesContainerHeader}>
                  <div className={styles.fileSearchContainer}>
                    {searchParams.get('status') && (
                      <div className={styles.filterIndicatorContainer}>
                        <FilterIndicator
                          filtersAmount={1}
                          onClose={() => {
                            updateUrlParams({ status: [] });
                          }}
                        />
                      </div>
                    )}

                    {(!isEmpty(model.tableModel.data) || searchParams.get('search')) && (
                      <SearchBar
                        placeholder="Search"
                        className={styles.searchContainer}
                        onChange={handleSearchFile}
                        value={searchFileValue}
                        handleClearInput={handleClearSearchFile}
                      />
                    )}

                    {model.isUpload && (
                      <FileUploadButton
                        disabled={!isTeamAdmin || isBlockedByBalance || isBlockedByUsageLimits}
                        buttonType="primary"
                        buttonContent="Upload files"
                        buttonIcon={
                          <Icon
                            name={IconVariant.ADD}
                            width={24}
                            height={24}
                            className={cx(styles.uploadButtonIcon, {
                              [styles.uploadButtonIconDisabled]:
                                !isTeamAdmin || isBlockedByBalance || isBlockedByUsageLimits,
                            })}
                          />
                        }
                        className={styles.importButton}
                        onChange={_handleFileSelect}
                        allowedFileExtensions={KG_SUPPORTED_FILE_EXTENSIONS}
                        tooltipText={
                          (isBlockedByBalance || isBlockedByUsageLimits || !isTeamAdmin) && (
                            <Text
                              variant={TextSize.M}
                              bold
                              color={TextColor.WHITE}
                              className={styles.tooltipBlockedByBalance}
                            >
                              {getIsBlockedMessage()}
                            </Text>
                          )
                        }
                      />
                    )}
                  </div>
                </div>

                <KnowledgeGraphFilesTable
                  model={model.tableModel}
                  status={searchParams.getAll('status')}
                  sortField={searchParams.get('sortField') || ''}
                  sortOrder={searchParams.get('sortOrder') || ''}
                  limit={Number(searchParams.get('limit')) || DEFAULT_PAGE_SIZE}
                  offset={Number(searchParams.get('offset')) || 0}
                  graphId={model.selectedGraph.id}
                  connectorName={model.connectorModel?.connectorName}
                  viewType={
                    model.isConnector
                      ? KnowledgeGraphFilesViewType.enum.Connector
                      : KnowledgeGraphFilesViewType.enum.Upload
                  }
                  viewOnly={!isTeamAdmin || isBlockedByBalance || isBlockedByUsageLimits}
                  handleStatusFilterChange={handleStatusFilterChange}
                  handleSortingChange={handleSortingChange}
                  onFileSelect={_handleFileSelect}
                  onFileUpload={onFileUpload}
                  onFileSizeError={model.tableModel.onFileSizeError}
                  onFileTypeError={model.tableModel.onFileTypeError}
                  isSearchParamsEmpty={!searchParams.get('search') && !searchParams.get('status')}
                  handlePaginationChange={handlePaginationChange}
                  handleOffsetChange={handleOffsetChange}
                />
              </div>
            )}
          </>
        );
      }

      return <div className={styles.selectorPlaceholder}></div>;
    }, [
      model.selectedGraph,
      model.webConnectorModel,
      model.connectorModel,
      model.filesStatusModel,
      model.isUpload,
      model.tableModel.data,
      model.syncStatusModel.connectorSyncStats,
      isTeamAdmin,
      hasAdminAccess,
      handleChangeGraphId,
      handleDropdownClick,
      isBlockedByBalance,
      isBlockedByUsageLimits,
      isOrgAdmin,
      searchParams,
      handleSearchFile,
      searchFileValue,
      updateSearchValue,
      _handleFileSelect,
      handleStatusFilterChange,
      handleSortingChange,
      onFileUpload,
      handlePaginationChange,
      handleOffsetChange,
      updateUrlParams,
    ]);

    const renderContainer = useMemo(() => {
      if (model.api.isGraphByIdLoading) {
        return <ThreeDotsLoader className={styles.loader} />;
      } else if (isFeatureLocked) {
        return (
          <KnowledgeGraphIntroductionBanner
            className={styles.featureLockedBanner}
            waitingListInitialEmail={appState.userProfile?.email || ''}
            onLearnMoreClick={openDocumentation}
          />
        );
      } else if (!model.selectedGraph) {
        return (
          <div className={styles.bannerContainer}>
            <div className={styles.banner}></div>
          </div>
        );
      } else {
        return renderKnowledgeMain;
      }
    }, [
      model.api.isGraphByIdLoading,
      model.selectedGraph,
      isFeatureLocked,
      appState.userProfile?.email,
      renderKnowledgeMain,
    ]);

    return (
      <div className={styles.container}>
        {model.isAddGraphModalOpen && (
          <AddKnowledgeGraphModal
            name={model.graphName}
            description={model.graphDescription}
            isConnectorsEnabled={isConnectorsEnabled}
            isWebConnectorsEnabled={isWebConnectorsEnabled}
            isLoading={model.isAddGraphModalLoading}
            onClickSubmit={async type => {
              await model.createGraph(type);

              if (model.selectedGraph) {
                updateUrlParams({ graphId: model.selectedGraph.id });
              }
            }}
            onClickClose={model.closeAddGraphModal}
          />
        )}
        {model.isRenameGraphModalOpen && (
          <RenameKnowledgeGraphModal
            name={model.graphName}
            description={model.graphDescription}
            descriptionMaxLength={KG_DESCRIPTION_CHARS_MAX}
            onClickSubmit={model.updateGraph}
            onClickClose={model.closeRenameGraphModal}
          />
        )}
        {model.isDeleteGraphModalOpen && (
          <DeleteKnowledgeGraphModal
            connectorName={model.connectorModel?.connectorName}
            onClickSubmit={() => {
              updateUrlParams({ graphId: '' });
              model.deleteGraph();
            }}
            onClickClose={model.closeDeleteGraphModal}
          />
        )}
        <SectionHeading
          className={styles.heading}
          heading="Knowledge Graph"
          headingUppercase
          headingVariant={HeadingVariant.H2}
          subHeading={teamName}
          subHeadingUppercase
          descriptionContainerClassName={styles.description}
          description={
            !isFeatureLocked &&
            (model.webConnectorModel ? (
              <>
                Add specific webpages and entire domains to create a Knowledge Graph
                <br />
                that you can use with Ask Writer and custom chat apps.{' '}
                <LinkText onClick={openWebKgDocumentation}>Learn more</LinkText>.
              </>
            ) : (
              <>
                Connect your company’s data via Knowledge Graph to improve your app outputs.{' '}
                <LinkText onClick={openDocumentation}>Learn more</LinkText>.
              </>
            ))
          }
          descriptionTextVariant={TextSize.XL}
          cta={
            !isFeatureLocked && (
              <Tooltip
                placement="bottom"
                title={
                  <AddGraphTooltipContent
                    isBlockedByBalance={isBlockedByBalance}
                    isBlockedByUsageLimits={isBlockedByUsageLimits}
                    isAdmin={isTeamAdmin}
                    limit={model.api.graphsUsage.limit}
                  />
                }
                disabled={
                  !isBlockedByBalance &&
                  !isBlockedByUsageLimits &&
                  isTeamAdmin &&
                  model.api.graphsLimit < model.api.graphsUsage.limit
                }
              >
                <div
                  className={cx(styles.addGraphContainer, {
                    [styles.addGraphContainerDisabled]:
                      isBlockedByBalance ||
                      isBlockedByUsageLimits ||
                      !isTeamAdmin ||
                      model.api.graphsLimit >= model.api.graphsUsage.limit,
                  })}
                  onClick={() => {
                    if (
                      !isBlockedByBalance &&
                      !isBlockedByUsageLimits &&
                      isTeamAdmin &&
                      model.api.graphsLimit < model.api.graphsUsage.limit
                    ) {
                      model.openAddGraphModal();
                    }
                  }}
                >
                  New graph
                  <AddContentButton className={styles.addGraphButton} />
                </div>
              </Tooltip>
            )
          }
        />

        {renderContainer}
      </div>
    );
  },
);

export default KnowledgeGraphPage;
