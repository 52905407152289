import { useEffect, useState } from 'react';

import { SharedQueryParam } from '@writercolab/common-utils';

import useQuery from './useQuery';

export default function useQuestionnaire(): boolean {
  const query = useQuery();
  const [showOnboarding, setShowOnboarding] = useState(query.get(SharedQueryParam.ONBOARDING) === 'true');
  const isOnboarding = query.get(SharedQueryParam.ONBOARDING) === 'true';

  useEffect(() => setShowOnboarding(isOnboarding), [isOnboarding]);

  return showOnboarding;
}
