import { useEffect, useMemo } from 'react';

import { Button, ButtonTypes, SizeTypes, Text, TextColor, TextSize, Tooltip } from '@writercolab/ui-atoms';

import { BaseTable } from 'components/organisms/BaseTable/BaseTable';
import { TableSkeleton } from 'components/pages/UserActivityReportPage/components/Table/TableSkeleton';

import { TKnowledgeGraphUrlModal } from '@web/types';
import EmptyStateWebConnector from 'assets/backgrounds/knowledge/emptyStateWebConnector.svg?react';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphWebConnectorUIModel } from './KnowledgeGraphWebConnectorModel.ui';
import { AddUrlModal } from './modals/AddUrlModal';
import { DeleteUrlModal } from './modals/DeleteUrlModal';
import { EditExcludeUrlModal } from './modals/EditExcludeUrlModal';
import { EditUrlModal } from './modals/EditUrlModal';
import { ExcludeUrlModal } from './modals/ExcludeUrlModal';
import {
  KG_URLS_GRID_COLUMNS,
  KG_URLS_LIMIT,
  KG_URLS_REFRESH_TIMEOUT,
  generateTableBody,
  generateTableHeaders,
} from './utils';

import styles from './KnowledgeGraphWebConnector.module.css';

export const EmptyState = ({ onClick }: { onClick: () => void }) => (
  <div className={styles.emptyContainer}>
    <div className={styles.icon}>
      <EmptyStateWebConnector />
    </div>
    <Text className={styles.title} variant={TextSize.XXXL} color={TextColor.BLACK} bold upperCase>
      Index knowledge stored online
    </Text>
    <ul className={styles.list}>
      <li>Add public, indexable web pages or domains to your Knowledge Graph</li>
      <li>Exclude any subpages or directories you don’t want to index</li>
      <li>Support for up to {KG_URLS_LIMIT} included and excluded URLs</li>
    </ul>
    <Button size={SizeTypes.MEDIUM} type={ButtonTypes.GRAY} onClick={onClick}>
      Start adding URLs
    </Button>
  </div>
);

export interface IKnowledgeGraphWebConnectorProps {
  model: KnowledgeGraphWebConnectorUIModel;
}

export const KnowledgeGraphWebConnector = observer<IKnowledgeGraphWebConnectorProps>(({ model }) => {
  const {
    analyticsService,
    modalsManager,
    isAnyModalVisible,
    urls,
    isUrlsLoading,
    validUrlsCount,
    excludeUrlsCount,
    reloadUrls,
  } = model;

  const tableBody = useMemo(
    () =>
      generateTableBody({
        urls,
        isExcludeActionEnabled: validUrlsCount + excludeUrlsCount < KG_URLS_LIMIT,
        analyticsService,
        modalsManager,
      }),
    [urls, analyticsService, modalsManager, validUrlsCount, excludeUrlsCount],
  );

  useEffect(() => {
    let intervalId;

    if (!isAnyModalVisible && urls.length > 0) {
      intervalId = setInterval(() => reloadUrls(), KG_URLS_REFRESH_TIMEOUT);
    }

    return () => intervalId && clearInterval(intervalId);
  }, [isAnyModalVisible, reloadUrls, urls]);

  return (
    <div className={styles.container}>
      <Tooltip disabled={validUrlsCount + excludeUrlsCount < KG_URLS_LIMIT} placement="top" title="URL limit reached">
        <Button
          disabled={validUrlsCount + excludeUrlsCount >= KG_URLS_LIMIT}
          className={styles.addUrlButton}
          size={SizeTypes.MEDIUM}
          type={ButtonTypes.PRIMARY}
          onClick={() => {
            analyticsService.track(AnalyticsActivity.kgWebAddUrlModalOpened, {});
            modalsManager.showModal(TKnowledgeGraphUrlModal.enum.addUrl);
          }}
        >
          Add a URL
        </Button>
      </Tooltip>

      {isUrlsLoading || urls.length > 0 ? (
        <div data-testid="kg-url-table">
          <BaseTable
            containerClassName={styles.tableContainer}
            headers={generateTableHeaders()}
            data={tableBody}
            loading={isUrlsLoading}
            isEnableSticky={false}
            gridTemplateColumns={KG_URLS_GRID_COLUMNS}
            customLoading={<TableSkeleton rowNumber={4} columnNumber={4} gridTemplateColumns={KG_URLS_GRID_COLUMNS} />}
          />
          <Text className={styles.counter} variant={TextSize.S} color={TextColor.BLACK}>
            {validUrlsCount + excludeUrlsCount}/{KG_URLS_LIMIT} URLs ({validUrlsCount} included · {excludeUrlsCount}{' '}
            excluded)
          </Text>
        </div>
      ) : (
        <EmptyState
          onClick={() => {
            analyticsService.track(AnalyticsActivity.kgWebAddUrlModalOpened, {});
            modalsManager.showModal(TKnowledgeGraphUrlModal.enum.addUrl);
          }}
        />
      )}

      {modalsManager.isModalVisible(TKnowledgeGraphUrlModal.enum.addUrl) && <AddUrlModal model={model} />}
      {modalsManager.isModalVisible(TKnowledgeGraphUrlModal.enum.editUrl) && <EditUrlModal model={model} />}
      {modalsManager.isModalVisible(TKnowledgeGraphUrlModal.enum.deleteUrl) && <DeleteUrlModal model={model} />}
      {modalsManager.isModalVisible(TKnowledgeGraphUrlModal.enum.excludeUrl) && <ExcludeUrlModal model={model} />}
      {modalsManager.isModalVisible(TKnowledgeGraphUrlModal.enum.editExcludeUrl) && (
        <EditExcludeUrlModal model={model} />
      )}
    </div>
  );
});
