import { computed, makeObservable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import { components } from '@writercolab/network';

import { Locale, format, formatRelative } from 'date-fns';
import { enUS } from 'date-fns/locale';

import { KnowledgeGraphsApiModel } from '../../../models/knowledgeGraphs.api';

export const STATUS_SYNC_TIMEOUT = 5000;

const formatRelativeLocale = {
  lastWeek: "'lastWeek', h:mmaaa",
  yesterday: "'yesterday', h:mmaaa",
  today: "'today', h:mmaaa",
  tomorrow: "'tomorrow', h:mmaaa",
  nextWeek: "'nextWeek', h:mmaaa",
  other: 'P',
};

const locale: Locale = {
  ...enUS,
  formatRelative: token => formatRelativeLocale[token],
};

interface ISyncStatusUIModel {
  api: KnowledgeGraphsApiModel;
  graphId: () => string;
  teamId?: number;
}

export class SyncStatusUIModel {
  private api: KnowledgeGraphsApiModel;
  constructor(private opts: ISyncStatusUIModel) {
    this.api = opts.api;

    makeObservable(this, {
      connectorSyncStats: computed,
      inProgressSync: computed,
      nextSync: computed,
      previousSync: computed,
    });
  }

  $connectorSyncStats: PromisedModel<components['schemas']['media_dto_ConnectorSyncStatsResponse']> = new PromisedModel(
    {
      name: '$connectorSyncStats',
      load: async () => {
        return this.api.getConnectorSyncStats(this.opts.graphId());
      },
    },
  );

  reloadConnectorSyncStats = async () => {
    this.$connectorSyncStats.reload();

    return this.$connectorSyncStats.promise;
  };

  get connectorSyncStats(): components['schemas']['media_dto_ConnectorSyncStatsResponse'] | undefined {
    return this.$connectorSyncStats.value;
  }

  get inProgressSync() {
    if (this.connectorSyncStats?.inProgressSync) {
      return {
        ...this.connectorSyncStats?.inProgressSync,
        allFiles: this.connectorSyncStats?.inProgressSync.total,
        inProgressFiles: this.getAllInProgressFiles('inProgressSync'),
        formattedDate: this.connectorSyncStats?.inProgressSync.createdAt
          ? formatRelative(new Date(this.connectorSyncStats?.inProgressSync.createdAt), new Date(), {
              locale,
            })
          : '',
      };
    }

    return undefined;
  }

  get nextSync() {
    if (this.connectorSyncStats?.nextSync) {
      return {
        // formattedDate: format(new Date(this.connectorSyncStats?.nextSync), `MMM d, yyyy h:mmaaa`),
        formattedDate: format(new Date(this.connectorSyncStats?.nextSync), `MMM d, yyyy h:mmaaa`),
      };
    }

    return undefined;
  }

  get previousSync() {
    if (this.connectorSyncStats?.previousSync) {
      return {
        ...this.connectorSyncStats?.previousSync,
        allFiles: this.connectorSyncStats?.previousSync.total,
        inProgressFiles: this.getAllInProgressFiles('previousSync'),
        formattedDate: this.connectorSyncStats?.previousSync.finishedAt
          ? format(new Date(this.connectorSyncStats?.previousSync.finishedAt), `MMM d, yyyy h:mmaaa`)
          : '',
      };
    }

    return undefined;
  }

  getAllInProgressFiles = (type: 'inProgressSync' | 'previousSync') => {
    if (!type) {
      return 0;
    }

    const { connectorSyncStats } = this;

    if (!connectorSyncStats) {
      return 0;
    }

    const stats = connectorSyncStats[type];

    if (stats) {
      return stats.succeeded + stats.unsupported;
    }

    return 0;
  };
}
