import type React from 'react';
import { useEffect, useState } from 'react';

import type { ICreateTermsWithFails, ITermCreateAndUpdate } from '@writercolab/common-utils';
import { DotLoader, Slideout } from '@writercolab/ui-atoms';
import {
  DeleteTermByIdModal,
  DeleteTermsModal,
  PerPageSelector,
  Unreachable,
  UnreachableType,
} from '@writercolab/ui-molecules';

import { useKeypress } from '@web/component-library';
import { FREE_SUBSCRIPTION_TERMS_LIMIT } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import { useTermsContext } from '../../../context/termsContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import AddTermsPanel from '../../organisms/AddTermsPanel';
import { ImporterPopup } from '../../organisms/ImporterPopup';
import { ImportItemType } from '../../organisms/ImporterPopup/types';
import { TermsCounter } from '../termsAndSnippetsShared/commonComponents';
import TermsFooter from './TermsFooter';
import TermsHeader from './TermsHeader';
import TermsTable from './TermsTable';

import styles from '../termsAndSnippetsShared/TermsPage.module.css';

interface ITermsPageProps extends BaseComponentsProps {}

const Content = ({ terms, isTeamNameShown, addNewItemsAvailable, orgId }) => (
  <div className={styles.container}>
    <TermsHeader isTeamNameShown={isTeamNameShown} addNewItemsDisabled={addNewItemsAvailable} orgId={orgId} />

    <div className={styles.containerTable}>
      <TermsTable terms={terms} />
    </div>
  </div>
);

const Footer = ({ termsTotalCount, handleOffsetChange, limit }) => (
  <div className={styles.containerFooter}>
    <TermsFooter
      leftContainer={<TermsCounter count={termsTotalCount} />}
      rightContainer={<PerPageSelector onSelect={handleOffsetChange} activeValue={limit} />}
    />
  </div>
);

export const TermsPage: React.FC<ITermsPageProps> = observer(() => {
  usePageTitle('Terms');

  const {
    termsContext,
    handleOffsetChange,
    handleCloseTermPopup,
    handleDeleteTerms,
    handleDeleteTermById,
    handleToggleModal,
    handleToggleTermIdModal,
    handleBulkTermsSubmit,
    handleCloseImportTermsPopup,
  } = useTermsContext();
  const { appState, appModel } = useAppState();
  const [tableFooterVisible, setTableFooterVisible] = useState(false);
  const {
    termsList,
    isSingleTermLoading,
    termsTotalCount,
    limit,
    showError404,
    isLoading,
    isTermsLoaded,
    isImportTermsPopupOpen,
  } = termsContext;

  const addTermsLimitReached =
    appModel.assistantSubscription.isFree &&
    termsContext.termsTotalCount &&
    termsContext.termsTotalCount >= FREE_SUBSCRIPTION_TERMS_LIMIT;

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.termsViewed, {});
  }, [appModel.analyticsService]);

  useKeypress('Escape', () => handleCloseTermPopup());

  useEffect(() => {
    if (!appState.isEducationalTermsWindowVisible) {
      setTableFooterVisible(true);
    }
  }, [appState.isEducationalTermsWindowVisible]);

  return (
    <div className={styles.termPageContainer}>
      <>
        <div className={styles.contentWrapper}>
          {!isLoading && isTermsLoaded && showError404 ? (
            <Unreachable type={UnreachableType.enum.error404page} dashboardRouteHref={ROUTE.toHome(1)} />
          ) : (
            <Content
              terms={termsList}
              isTeamNameShown={appModel.assistantSubscription.isEnterprise}
              addNewItemsAvailable={addTermsLimitReached}
              orgId={appState.organizationId}
            />
          )}
        </div>
        {tableFooterVisible && (
          <Footer termsTotalCount={termsTotalCount} handleOffsetChange={handleOffsetChange} limit={limit} />
        )}

        <Slideout
          isOpen={termsContext.isAddTermPopupOpen}
          onClose={handleCloseTermPopup}
          className={styles.addTermsSlideout}
        >
          {isSingleTermLoading ? <DotLoader style={{ marginTop: 50 }} /> : <AddTermsPanel />}
        </Slideout>

        <DeleteTermsModal
          isOpen={termsContext.isDeletePopupOpen}
          numOfTerms={termsContext.selectedTerms.length}
          changeModalState={handleToggleModal}
          onSubmit={handleDeleteTerms}
        />
        {termsContext?.openedTerm?.id && (
          <DeleteTermByIdModal
            id={termsContext.openedTerm.id}
            isOpen={!!termsContext.isDeleteTermIdPopupOpen}
            changeModalState={handleToggleTermIdModal}
            onSubmit={handleDeleteTermById}
          />
        )}
        <ImporterPopup<ITermCreateAndUpdate, ICreateTermsWithFails>
          open={isImportTermsPopupOpen}
          onClose={handleCloseImportTermsPopup}
          onSubmit={handleBulkTermsSubmit}
          type={ImportItemType.TERMS}
        />
      </>
    </div>
  );
});
