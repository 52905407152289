import React, { useMemo } from 'react';

import cx from 'classnames';

import { IInvoice } from '@writercolab/common-utils';
import { Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';
import { BaseTable, ITableColumnItem, renderAsText, renderAsTextDate } from '@writercolab/ui-molecules';

import take from 'lodash/take';

import styles from './styles.module.css';

interface IHistoryTableProps {
  list: IInvoice[];
  limit: number;
  onViewAllClick?: () => void;
}

export const HistoryTable: React.FC<IHistoryTableProps> = ({ list, limit, onViewAllClick }) => {
  const tableData = useMemo(() => take(list, limit), [list, limit]);

  const columns: ITableColumnItem[] = useMemo(
    () => [
      {
        Header: (
          <Text variant={TextSize.S} smallCaps className={styles.historyColumn}>
            Date
          </Text>
        ),
        className: styles.historyHeader,
        accessor: 'date',
        minWidth: 200,
        Cell: ({ cell: { value } }) => renderAsTextDate(value, { className: cx(styles.historyCell), size: TextSize.M }),
      },
      {
        Header: (
          <Text variant={TextSize.S} smallCaps className={styles.historyColumn}>
            Description
          </Text>
        ),
        className: styles.historyHeader,
        accessor: 'activity',
        disableMatchFound: true,
        Cell: ({ cell: { value } }) => renderAsText(value, { className: cx(styles.historyCell), size: TextSize.M }),
      },
      {
        Header: (
          <Text variant={TextSize.S} smallCaps className={styles.historyColumn}>
            Amount
          </Text>
        ),
        className: styles.historyHeader,
        accessor: 'charge',
        Cell: ({ cell: { value } }) =>
          renderAsText(value >= 0 ? `$${value}` : `-$${Math.abs(value)}`, {
            className: cx(styles.historyCell),
            size: TextSize.M,
          }),
      },
      {
        Header: <div></div>,
        className: styles.historyHeader,
        id: 'download',
        accessor: 'link',
        Cell: ({ cell: { value } }) => (
          <a href={value} target="_blank">
            <div className={styles.downloadIcon}>
              <Icon name={IconVariant.DOWNLOAD} />
            </div>
          </a>
        ),
      },
    ],
    [],
  );

  const tableFooter = useMemo(
    () =>
      limit === list.length ? undefined : (
        <div onClick={onViewAllClick} className={styles.viewAll}>
          <Text variant={TextSize.M}>View all</Text>
        </div>
      ),
    [limit, list.length, onViewAllClick],
  );

  const emptyTableContent = useMemo(() => <div>No data</div>, []);

  return (
    <BaseTable
      className={styles.table}
      tableData={tableData}
      tableColumns={columns}
      tableFooter={tableFooter}
      isLoading={false}
      emptyTableContent={emptyTableContent}
    />
  );
};

export default HistoryTable;
