import type { IconVariant } from '@writercolab/ui-atoms';
import { Icon } from '@writercolab/ui-atoms';
import { renderToString } from 'react-dom/server';

const getIcon = (name: IconVariant, width?: number, height?: number) => (
  <Icon name={name} width={width} height={height}></Icon>
);

export const getIconHtml = (name: IconVariant, width?: number, height?: number) =>
  renderToString(getIcon(name, width, height));
