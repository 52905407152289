import { computed, makeObservable } from 'mobx';

import { PromisedModel, type ReactiveQueue, Subscriber } from '@writercolab/mobx';
import type { AiAssistantSubscriptionModel, AiStudioAccountModel } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import type { TNotificationQueueItem } from '@writercolab/types';
import {
  CacheManager,
  ChatMessagesApiModel,
  ChatModes,
  ChatSessionsApiModel,
  ChatTemplateUIModel,
  DEFAULT_TTL,
  type IChatTemplateUIModelOpts,
  LocalStorageCachingStrategy,
  type TConsoleChatApplicationBase,
  getDefaultMode,
} from '@writercolab/ui-chat-apps';

import { ASK_WRITER_APP_ID } from '@web/types';
import { IWebAppAnalyticsTrack } from 'constants/analytics';

export interface IChatApplicationModelOptions {
  organizationId: number;
  teamId: number;
  applicationId: () => string;
  analyticsService: IWebAppAnalyticsTrack;
  requestService: RequestServiceInitialize['api'];
  assistantSubscriptionModel: AiAssistantSubscriptionModel;
  aiStudioBalance: AiStudioAccountModel['balance'];
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
}

export class ChatApplicationModel {
  readonly sessionsApiModel: ChatSessionsApiModel;
  readonly messagesApiModel: ChatMessagesApiModel;

  constructor(private readonly opts: IChatApplicationModelOptions) {
    const { organizationId, teamId, requestService } = opts;

    this.sessionsApiModel = new ChatSessionsApiModel({
      request: requestService,
      organizationId,
      teamId,
      getApplicationId: () => this.application?.id,
      getActiveSessionId: () => undefined,
      getApplicationVersionId: () => undefined,
    });

    this.messagesApiModel = new ChatMessagesApiModel({
      request: requestService,
      organizationId,
      getActiveSessionId: () => undefined,
    });

    makeObservable(this, {
      allowViewPrompts: computed,
      application: computed,
      isApplicationLoading: computed,
      isApplicationRejected: computed,
      isAskWriterFeatureEnabled: computed,
      templateModel: computed,
    });
  }

  private readonly $application = new PromisedModel({
    name: '$application',
    load: async () => {
      const { organizationId, teamId } = this.opts;
      const applicationId = this.opts.applicationId();

      if (!organizationId || !teamId || !applicationId) {
        return undefined;
      }

      if (applicationId === ASK_WRITER_APP_ID && !this.isAskWriterFeatureEnabled) {
        return undefined;
      }

      const { data } = await this.opts.requestService.get(
        '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed/{applicationIdOrIdAlias}',
        {
          params: {
            path: {
              teamId,
              organizationId,
              applicationIdOrIdAlias: applicationId,
            },
          },
        },
      );

      return data as TConsoleChatApplicationBase;
    },
  });

  private readonly $templateModel = new Subscriber<IChatTemplateUIModelOpts, ChatTemplateUIModel>({
    getId: () => {
      const {
        organizationId,
        teamId,
        requestService,
        assistantSubscriptionModel,
        analyticsService,
        notificationQueue,
      } = this.opts;

      if (!assistantSubscriptionModel) {
        return undefined;
      }

      return {
        request: requestService,
        organizationId,
        teamId,
        notificationQueue,
        analyticsService,
        cacheManager: new CacheManager(new LocalStorageCachingStrategy(), DEFAULT_TTL),
        sessionsApiModel: this.sessionsApiModel,
        messagesApiModel: this.messagesApiModel,
        getApplication: () => this.application as TConsoleChatApplicationBase,
        getActiveSession: () => undefined,
        getIsDictationDisabled: () => false,
        getKnowledgeGraphAccess: () => assistantSubscriptionModel.access?.knowledgeGraph ?? false,
        getSubscriptionLimitState: () => ({
          exceeded: assistantSubscriptionModel.limits?.coWrite?.exceeded ?? false,
          limit: assistantSubscriptionModel.limits?.coWrite?.limit ?? 0,
        }),
        insufficientBalance: () => !!this.opts.aiStudioBalance?.insufficientBalance,
        usageLimitExceeded: () =>
          !!assistantSubscriptionModel.limits?.aiStudioMonthlyBudget?.exceeded ||
          !!assistantSubscriptionModel.limits?.aiStudioUsage?.exceeded,
        onSetActiveSession: () => {},
        getVoices: () => [],
        getRetentionPreferences: () => undefined,
      };
    },
    subscribe: (params, push) => {
      const model = new ChatTemplateUIModel(params);

      push(model);
    },
  });

  get templateModel() {
    return this.$templateModel.data;
  }

  get application() {
    return this.$application.value ?? undefined;
  }

  get isAskWriterFeatureEnabled() {
    return this.opts?.assistantSubscriptionModel?.access?.askWriter ?? false;
  }

  get defaultMode() {
    return getDefaultMode(this.application) ?? ChatModes.enum.chat;
  }

  get isApplicationLoading() {
    return this.$application.status === 'pending' || this.sessionsApiModel.$sessions.status === 'pending';
  }

  get isApplicationRejected() {
    const status = this.$application.status === 'rejected';

    return status;
  }

  get allowViewPrompts() {
    const chatModeSettings = this.defaultMode ? this?.application?.data?.[this.defaultMode] : {};

    return chatModeSettings?.showPrompt ?? false;
  }
}
