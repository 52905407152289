import React from 'react';

import cx from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import { Text, TextSize } from '@writercolab/ui-atoms';
import { Paginator, PerPageOption, PerPageSelector } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

interface IKnowledgeGraphListPaginatorProps {
  totalCount: number;
  limit: number;
  offset: number;
  handleOffsetChange: (option: PerPageOption) => void;
  handleFilesPaginationChange: (event: unknown, number: number) => void;
}

export const KnowledgeGraphListPaginator: React.FC<IKnowledgeGraphListPaginatorProps> = ({
  totalCount,
  limit,
  offset,
  handleOffsetChange,
  handleFilesPaginationChange,
}) => (
  <div className={cx(styles.paginationContainer)}>
    {totalCount > 0 && (
      <div>
        <Text smallCaps variant={TextSize.XS}>
          {totalCount} {wordPluralize(totalCount, 'file')}
        </Text>
      </div>
    )}
    <Paginator onChange={handleFilesPaginationChange} limit={limit} offset={offset} total={totalCount || 0} />
    <PerPageSelector onSelect={handleOffsetChange} activeValue={limit} />
  </div>
);

export default KnowledgeGraphListPaginator;
