import qs from 'qs';

import type { TUserStatusFilter } from '@writercolab/common-utils';
import { Request } from '@writercolab/common-utils';

type PeopleRequestParams = {
  billingGroupIds?: string[];
  userStatus?: (typeof TUserStatusFilter.type | undefined)[];
};

type TeammatesRequestParams = Pick<PeopleRequestParams, 'userStatus'>;

export const downloadPeoplesListAsCsv = async (organizationId: string, params: PeopleRequestParams): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get<Blob>(`/user/v2/organization/${organizationId}/csv`, {
    responseType: 'blob',
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const downloadPeoplesListAsXlsx = async (organizationId: string, params: PeopleRequestParams): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get<Blob>(`/user/v2/organization/${organizationId}/xlsx`, {
    responseType: 'blob',
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const downloadTeammatesListAsCsv = async (
  organizationId: string,
  teamId: string,
  params: TeammatesRequestParams,
): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get<Blob>(
    `/user/v2/organization/${organizationId}/team/${teamId}/csv`,
    {
      responseType: 'blob',
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );

  return data;
};

export const downloadTeammatesListAsXlsx = async (
  organizationId: string,
  teamId: string,
  params: TeammatesRequestParams,
): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get<Blob>(
    `/user/v2/organization/${organizationId}/team/${teamId}/xlsx`,
    {
      responseType: 'blob',
      params,
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
  );

  return data;
};
