import type React from 'react';
import { useMemo } from 'react';

import cx from 'classnames';

import { getFileExtension, getTimeOrDate } from '@writercolab/common-utils';
import { ContentGenerationJobStatus } from '@writercolab/types';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  MuiCircularProgress,
  MuiTable,
  MuiTableBody,
  MuiTableCell,
  MuiTableContainer,
  MuiTableHead,
  MuiTableRow,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import type { IRecapJob, TContentGenerationJobStatus } from '@web/types';
import { SUPPORTED_VIDEO_FILE_EXT, contentGenerationJobProcessMessage } from '@web/types';

import {
  getQueueJobContentItemName,
  isBlogBuilderQueueJobType,
  isRecapsQueueJobType,
} from '../../../utils/recapsUtils';

import styles from './styles.module.css';

interface IRecapsQueueManagerProps {
  jobs: IRecapJob[];
  onJobFileClick: (job: IRecapJob) => void;
}

interface IRecapJobProps {
  job: IRecapJob;
}

interface IRecapJobClickableProps {
  job: IRecapJob;
  onJobFileClick: (job: IRecapJob) => void;
}

interface IRecapJobStatusProps {
  status: TContentGenerationJobStatus;
}

const COMPLETED_OR_ERROR_STATUS: TContentGenerationJobStatus[] = [
  ContentGenerationJobStatus.enum.completed,
  ContentGenerationJobStatus.enum.error,
];

const MIN_LENGTH_FOR_FILENAME_TOOLTIP = 27;

const TooltipContent = ({ content }) => (
  <Text variant={TextSize.S} color={TextColor.WHITE}>
    {content}
  </Text>
);

const JobStatusIndicator: React.FC<IRecapJobStatusProps> = ({ status }) => {
  const progressValue = useMemo(() => {
    let progress = 0;

    if (status === ContentGenerationJobStatus.enum.generating) {
      progress = 25;
    } else if (status === ContentGenerationJobStatus.enum.transcript) {
      progress = 75;
    }

    return progress;
  }, [status]);

  return (
    <div className={styles.containerStatusIndicator}>
      <MuiCircularProgress
        variant="determinate"
        thickness={8}
        value={100}
        size={18}
        className={styles.containerFileStatusTrack}
        style={{ color: '#D4D6DB' }}
      />
      <MuiCircularProgress
        variant="determinate"
        thickness={8}
        value={progressValue}
        size={18}
        className={styles.containerFileStatusProgress}
      />
    </div>
  );
};

const JobStatusIcon: React.FC<IRecapJobStatusProps> = ({ status }) => {
  const iconVariant = useMemo(
    () => (status === ContentGenerationJobStatus.enum.completed ? IconVariant.CHECK : IconVariant.CLOSE),
    [status],
  );

  return (
    <div
      className={cx(styles.containerStatusIcon, {
        [styles.containerStatusIconGreen]: status === ContentGenerationJobStatus.enum.completed,
        [styles.containerStatusIconRed]: status === ContentGenerationJobStatus.enum.error,
      })}
    >
      <Icon name={iconVariant} width={10} height={10} />
    </div>
  );
};

const JobFileName: React.FC<IRecapJobProps> = ({ job }) => {
  const jobFileName = getQueueJobContentItemName(job);
  const jobFileIconVariant = useMemo(() => {
    let icon = IconVariant.MEDIA_FILE_DOCUMENT;

    if (isRecapsQueueJobType(job)) {
      const jobFileExtension = getFileExtension(jobFileName);

      icon = SUPPORTED_VIDEO_FILE_EXT.includes(jobFileExtension)
        ? IconVariant.MEDIA_FILE_VIDEO
        : IconVariant.MEDIA_FILE_AUDIO;
    }

    return icon;
  }, [job, jobFileName]);

  return (
    <Tooltip
      title={
        <Text variant={TextSize.XL} color={TextColor.WHITE} medium className={styles.fileNameTooltipContent}>
          {jobFileName}
        </Text>
      }
      tooltipWrapperClassname={cx(styles.tooltipWrapper, {
        [styles.tooltipWrapperLong]: jobFileName.length >= MIN_LENGTH_FOR_FILENAME_TOOLTIP,
      })}
      disabled={jobFileName.length <= MIN_LENGTH_FOR_FILENAME_TOOLTIP}
      placement="top"
    >
      <div className={styles.containerFileName}>
        <Icon name={jobFileIconVariant} width={20} height={18} />
        <div className={styles.containerFileNameText}>
          <Text variant={TextSize.XS} ellipsisOverflow>
            {jobFileName}
          </Text>
        </div>
      </div>
    </Tooltip>
  );
};

const JobFileStatus: React.FC<IRecapJobProps> = ({ job }) => {
  const _status = useMemo(() => {
    let status = job.status as string;

    if (status === ContentGenerationJobStatus.enum.transcript) {
      status = 'Transcribing file';
    } else if (status === ContentGenerationJobStatus.enum.completed) {
      status = 'Complete';
    } else if (status === ContentGenerationJobStatus.enum.error) {
      status = 'Error';
    } else if (ContentGenerationJobStatus.enum.generating === status) {
      if (isRecapsQueueJobType(job)) {
        status = 'Building Recaps';
      } else if (isBlogBuilderQueueJobType(job)) {
        status = 'Generating content';
      }
    }

    return status;
  }, [job]);

  const tooltipVisible = useMemo(() => job.status === ContentGenerationJobStatus.enum.error, [job.status]);
  const tooltipStatusText = useMemo(
    () => (job.status === ContentGenerationJobStatus.enum.error ? contentGenerationJobProcessMessage.ERROR : ''),
    [job.status],
  );

  return (
    <div className={styles.containerFileStatus}>
      <Tooltip
        disabled={!tooltipVisible}
        title={<TooltipContent content={tooltipStatusText} />}
        tooltipWrapperClassname={cx(styles.tooltipWrapper, styles.tooltipWrapperShort)}
      >
        <div className={styles.containerFileStatusWrapper}>
          {COMPLETED_OR_ERROR_STATUS.includes(job.status) ? (
            <div>
              <JobStatusIcon status={job.status} />
            </div>
          ) : (
            <JobStatusIndicator status={job.status} />
          )}
          <Text variant={TextSize.XS} color={TextColor.GREY2} className={styles.containerFileStatusText}>
            {_status}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};

const TableHeaderText = ({ text }) => (
  <Text variant={TextSize.M} medium>
    {text}
  </Text>
);

const FileLink: React.FC<IRecapJobProps> = ({ job }) => (
  <div>
    {COMPLETED_OR_ERROR_STATUS.includes(job.status) ? (
      <Button
        type={ButtonTypes.BLUE2}
        className={styles.containerFileLinkButton}
        icon={<Icon name={IconVariant.OPEN_LINK} />}
        content={<Text variant={TextSize.XS}>Open</Text>}
      />
    ) : (
      <div className={cx(styles.containerFileLinkLink, styles.clickable)}>
        <Icon name={IconVariant.OPEN_LINK} />
      </div>
    )}
  </div>
);

const JobTableRow: React.FC<IRecapJobClickableProps> = ({ job, onJobFileClick }) => (
  <MuiTableRow key={job.id} className={styles.tableRow} onClick={() => onJobFileClick(job)}>
    <MuiTableCell className={styles.tableCellBody}>
      <JobFileName job={job} />
    </MuiTableCell>
    <MuiTableCell className={styles.tableCellBody}>
      <Text variant={TextSize.XS} color={TextColor.GREY2}>
        {getTimeOrDate(job.creationTime)}
      </Text>
    </MuiTableCell>
    <MuiTableCell className={styles.tableCellBody}>
      <JobFileStatus job={job} />
    </MuiTableCell>
    <MuiTableCell className={styles.tableCellBody}>
      <FileLink job={job} />
    </MuiTableCell>
  </MuiTableRow>
);

export const RecapsQueueManager: React.FC<IRecapsQueueManagerProps> = ({ jobs, onJobFileClick }) => (
  <div className={styles.container}>
    <div className={styles.containerWrapper}>
      <div className={styles.headerContainer}>
        <Heading variant={HeadingVariant.H3} bold>
          Content generation
        </Heading>
        <Text color={TextColor.GREY2} variant={TextSize.XS}>
          Queue from the last 24 hours
        </Text>
      </div>
      <div className={styles.containerFiles}>
        <MuiTableContainer className={styles.containerFilesTable}>
          <MuiTable aria-label="files-list">
            <MuiTableHead>
              <MuiTableRow>
                <MuiTableCell
                  align="left"
                  key="tableCellTitle"
                  className={cx(styles.tableCell, styles.tableCellFilename, styles.tableCellSticky)}
                >
                  <TableHeaderText text="Title" />
                </MuiTableCell>
                <MuiTableCell
                  align="left"
                  key="tableCellCreateDate"
                  className={cx(styles.tableCell, styles.tableCellCreateDate, styles.tableCellSticky)}
                >
                  <TableHeaderText text="Started" />
                </MuiTableCell>
                <MuiTableCell
                  align="left"
                  key="tableCellFileStatus"
                  className={cx(styles.tableCell, styles.tableCellStatus, styles.tableCellSticky)}
                >
                  <TableHeaderText text="Status" />
                </MuiTableCell>
                <MuiTableCell
                  align="left"
                  key="tableCellAction"
                  className={cx(styles.tableCell, styles.tableCellSticky)}
                >
                  <Text variant={TextSize.M} />
                </MuiTableCell>
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody id="files-table">
              {jobs.map(job => (
                <JobTableRow key={job.id} job={job} onJobFileClick={onJobFileClick} />
              ))}
            </MuiTableBody>
          </MuiTable>
        </MuiTableContainer>
      </div>
    </div>
  </div>
);
