import type React from 'react';
import { useEffect, useMemo } from 'react';

import type { IGtmActivityParams, IUserProfile } from '@writercolab/common-utils';
import {
  CustomerType,
  GTMActionLabel,
  GTMActivity,
  GTMCategory,
  LocalStorageKey,
  LocalStorageService,
  SharedQueryParam,
  SignupType,
  delay,
  executeDeleteOrganizationRequest,
  isLocalEnv,
  isNewOrg,
  noop,
  unsetAuthCookie,
} from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { LoadingPage, snackbarMessages } from '@web/component-library';
import type { TOrganizationSubscription } from '@web/types';
import { TQuestionnaireVisibilityState } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import type { Location } from 'react-router';
import { useLocation, useNavigate, useParams } from 'react-router';

import { WRITER_ACADEMY_URI } from '../../../constants/LearningCenter';
import useQuery from '../../../hooks/useQuery';
import { LearningCenterModel } from '../../../models/learningCenter';
import { appLocalStorage } from '../../../models/localStorage';
import { navigateToDeleteAccountSuccessRoute } from '../../../services/config/routes';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import config from '../../../utils/dynamicConfig';
import { getLogger } from '../../../utils/logger';
import { goToHome, goToHrefWithReload, redirectToDefaultRoutes } from '../../../utils/navigationUtils';
import {
  getSignupType,
  isFirstSignIn,
  isInvitedSignup,
  isRemoveAccountParamExist,
  marketingConsentState,
  redirectLocation,
} from '../../../utils/queryParamUtils';
import { isInternalUrl, isWhitelistedDomainUrl, isWriterAcademyUrl } from '../../../utils/urlUtils';

const LOG = getLogger('AuthRedirectPage');

const trackInitialActivity = async (
  analyticsService: IWebAppAnalyticsTrack,
  location: Location,
  isSelfServe: boolean,
  userProfile: IUserProfile,
  subscription?: TOrganizationSubscription,
) => {
  const _signupType = getSignupType(location);
  const _isInvitedSignup = isInvitedSignup(location);
  const _isFirstSignIn = isFirstSignIn(location);
  const _marketingConsentState = marketingConsentState(location);

  const gmtFormSubmitEventProps: IGtmActivityParams = {
    event: GTMActivity.CUSTOM_FORM_SUBMIT,
    eventCategory: GTMCategory.FORM_SUBMISSION,
    userId: `${userProfile.id}`,
    email: userProfile.email,
    eventLabel: GTMActionLabel.EMAIL,
    eventAction: `${_isFirstSignIn ? 'Register' : 'Login'} ${subscription?.productName}`,
  };

  if (_signupType === SignupType.google) {
    gmtFormSubmitEventProps.eventLabel = GTMActionLabel.GOOGLE;
  } else if (_signupType === SignupType.saml) {
    gmtFormSubmitEventProps.eventLabel = GTMActionLabel.SAML_SSO;
  }

  appLocalStorage.pendingGtmEvents.set([gmtFormSubmitEventProps]);

  if (_isFirstSignIn && _signupType && subscription?.price?.name) {
    analyticsService.track(AnalyticsActivity.signedUp, {
      auth_method: _signupType,
      email_consent: _marketingConsentState,
      plan_name: subscription.price.name,
      subscription_status: subscription.status,
      ...appLocalStorage.utmData.get(),
    });
    appLocalStorage.utmData.remove();
  }

  if (
    isSelfServe &&
    _isFirstSignIn &&
    _signupType &&
    _isInvitedSignup !== null &&
    _isInvitedSignup &&
    subscription?.price?.name
  ) {
    await analyticsService.track(AnalyticsActivity.userJoined, {
      signup_method: _signupType,
      plan_name: subscription.price.name as string,
      subscription_status: subscription.status as string,
    });
  }
};

export const AuthRedirectPage: React.FC = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQuery();
  const params = useParams();
  const { enqueueErrorSnackbar } = useCustomSnackbar();
  const {
    appState,
    appModel: {
      teamsModel,
      isAuthenticated,
      assistantSubscription,
      aiStudioSubscription,
      analyticsService,
      featureFlags,
    },
  } = useAppState();
  const { userProfile, organization, team } = appState;
  const subscription = assistantSubscription.$subscription.value;
  const learningCenterModel = useMemo(() => {
    if (!organization) {
      return undefined;
    }

    return new LearningCenterModel({
      request: requestService.api,
      organizationId: Number(organization?.id),
      isEnrolledInWriterAcademy: false,
      refreshUserSettings: noop,
    });
  }, [organization]);

  useEffect(() => {
    LocalStorageService.setItem(LocalStorageKey.onboardingState, TQuestionnaireVisibilityState.enum.preload);

    // handle cases:
    // - subscription status is not ready (loading)
    // - analytics is not ready (analytics is not initialized yet)
    // - user profile is not ready (loading)
    // - organization is not ready (loading or user doesn't belong to any organization)
    // - teams is not ready (loading)
    // - appModel.aiStudioSubscription undefined when loading or organizationId is not set
    if (aiStudioSubscription === undefined || !subscription || !isAuthenticated || !userProfile || !organization) {
      return;
    }

    const selectedOrgId = organization.id;
    const isSelfServe = subscription?.customerType === CustomerType.SELF_SERVE;
    const redirectTo = redirectLocation(location);

    if (isRemoveAccountParamExist(location) && selectedOrgId) {
      const deleteAccountToken = query.get(SharedQueryParam.DELETE_TOKEN) as string;
      executeDeleteOrganizationRequest(selectedOrgId, deleteAccountToken)
        .then(() => {
          unsetAuthCookie();
          navigateToDeleteAccountSuccessRoute();
        })
        .catch(e => {
          LOG.error(e);
          enqueueErrorSnackbar(snackbarMessages.deleteOrgRequest.tokenExpired);
          delay(3000).then(() => goToHome(navigate, selectedOrgId));
        });

      return;
    }

    trackInitialActivity(analyticsService, location, isSelfServe, userProfile, subscription).then(async () => {
      const useNative = !isNewOrg(organization) && !isLocalEnv();

      if (redirectTo && teamsModel.teams && isInternalUrl(redirectTo)) {
        redirectToDefaultRoutes(navigate, redirectTo, teamsModel.teams, selectedOrgId, params, featureFlags);
      } else if (
        redirectTo &&
        (isInternalUrl(redirectTo) || isWhitelistedDomainUrl(redirectTo, config.REDIRECT_WHITELISTED_DOMAINS))
      ) {
        if (isWriterAcademyUrl(redirectTo) && learningCenterModel) {
          learningCenterModel
            .getWriterAcademyAuthToken()
            .then(token => goToHrefWithReload(`${WRITER_ACADEMY_URI}${token}`))
            .catch(e => {
              LOG.error(e);
              goToHome(navigate, selectedOrgId, useNative, team?.id, false, false);
            });
        } else {
          goToHrefWithReload(redirectTo);
        }
      } else {
        goToHome(navigate, selectedOrgId, useNative, team?.id, false, false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    organization,
    team?.id,
    teamsModel.teams,
    userProfile,
    aiStudioSubscription,
    subscription,
    learningCenterModel,
  ]);

  return <LoadingPage />;
});

export default AuthRedirectPage;
