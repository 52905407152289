import { GlobalNavSelection, INavAction } from '../components/organisms/GlobalNav';

/**
 * Filters out a specific navigation action from an array of navigation actions.
 * This function removes the navigation action with the given key from the array.
 *
 * @param actionKeyToRemove - The key of the navigation action to be removed.
 * @param navActions - An array of navigation actions to filter.
 * @returns A new array of navigation actions excluding the specified action.
 */
export const filterOutNavAction = (actionKeyToRemove: GlobalNavSelection, navActions: INavAction[]): INavAction[] =>
  navActions.filter(action => action.key !== actionKeyToRemove);
