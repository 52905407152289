import { computed, makeObservable, observable, runInAction } from 'mobx';

import { PaginatedModel, PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';
import type { TNotificationQueueItem, TOrgTeamUserActivityParams } from '@writercolab/types';
import { NotificationQueueItemType } from '@writercolab/types';

import type { TTeamPromptActionDto, TTeamPromptBrief, TTeamPromptTag, TTeamPrompts } from '@web/types';
import { DEFAULT_PAGE_SIZE, TTeamPromptSortDirection, TTeamPromptSortField } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';

import { sortByNameProperty } from '../utils/arrayUtils';
import { extractBackendResponseErrorMessage } from '../utils/backendErrorUtils';
import { getLogger } from '../utils/logger';
import { concatenateStrings } from '../utils/stringUtils';

const LOG = getLogger('TeamPromptsPageUiModel');

interface ITeamPromptsApiModelOpts {
  request: RequestServiceInitialize['api'];
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  organizationId: number;
  teamId: number;
}

type TDefaultQueryArgs = {
  search: string | undefined;
  tagIds: number[] | undefined;
  sortField: typeof TTeamPromptSortField.type | undefined;
  sortOrder: typeof TTeamPromptSortDirection.type | undefined;
};

type TDefaultQueryExtra = {
  limit: number;
  offset: number;
};

const QUERY_EXTRA_DEFAULTS: TDefaultQueryExtra = {
  limit: DEFAULT_PAGE_SIZE,
  offset: 0,
};

const QUERY_ARGS_DEFAULTS: TDefaultQueryArgs = {
  search: undefined,
  tagIds: undefined,
  sortField: TTeamPromptSortField.enum.createdAt,
  sortOrder: TTeamPromptSortDirection.enum.desc,
};

export class TeamPromptsApiModel {
  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  public readonly $teamPromptTags: PromisedModel<TTeamPromptTag[]>;

  loading: boolean = false;
  pagination: PaginatedModel<TTeamPrompts, TTeamPromptBrief, TDefaultQueryExtra, TDefaultQueryArgs> =
    new PaginatedModel<TTeamPrompts, TTeamPromptBrief, TDefaultQueryExtra, TDefaultQueryArgs>({
      argsExtra: QUERY_ARGS_DEFAULTS,
      argsDefault: QUERY_EXTRA_DEFAULTS,
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.pagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args: { limit, offset }, extra: { search, sortField, sortOrder, tagIds } }) => {
        let teamPrompts: TTeamPrompts = {
          pagination: {},
          totalCount: 0,
          result: [],
        };

        try {
          const { data } = await this.opts.request.get(
            '/api/generation/organization/{organizationId}/team/{teamId}/team/prompt',
            {
              params: {
                path: {
                  organizationId: this.opts.organizationId,
                  teamId: this.opts.teamId,
                },
                query: {
                  limit,
                  offset,
                  search,
                  tagIds,
                  sortField,
                  sortOrder,
                },
              },
            },
          );

          teamPrompts = data;
        } catch (e) {
          LOG.error('Failed to load team prompts', e);
          this.opts?.notificationQueue?.enqueue({
            type: NotificationQueueItemType.enum.error,
            message: extractBackendResponseErrorMessage(e),
          });
        }

        return teamPrompts;
      },
    });

  constructor(private readonly opts: ITeamPromptsApiModelOpts) {
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();

    this.$teamPromptTags = new PromisedModel({
      name: '$promptTags',
      load: () =>
        this.opts.request
          .get('/api/generation/organization/{organizationId}/team/{teamId}/team/prompt/tag', {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                teamId: this.opts.teamId,
              },
            },
          })
          .then(({ data }) => data.result ?? []),
    });

    makeObservable(this, {
      loading: observable,

      tagsList: computed.struct,
    });
  }

  get tagsList(): TTeamPromptTag[] {
    let tags: TTeamPromptTag[] = [];

    if (this.$teamPromptTags.value) {
      tags = this.$teamPromptTags.value.map(tag => ({
        id: tag.id,
        name: tag.name,
      }));
    }

    return tags.sort(sortByNameProperty);
  }

  delete = async (promptId: string) => {
    try {
      await this.opts.request.delete(
        '/api/generation/organization/{organizationId}/team/{teamId}/team/prompt/{promptId}',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: this.opts.teamId,
              promptId,
            },
          },
        },
      );

      this.opts?.notificationQueue?.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Team prompt deleted',
      });
    } catch (e) {
      LOG.error('Failed to delete team prompt', e);
      this.opts?.notificationQueue?.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    }
  };

  get = (promptId: string): PromisedModel<TTeamPromptBrief> =>
    new PromisedModel({
      name: '$teamPrompt',
      load: async () => {
        const { data } = await this.opts.request.get(
          '/api/generation/organization/{organizationId}/team/{teamId}/team/prompt/{promptId}',
          {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                teamId: this.opts.teamId,
                promptId,
              },
            },
          },
        );

        return data;
      },
    });

  // eslint-disable-next-line class-methods-use-this
  private preparePromptDto = (prompt: TTeamPromptActionDto): TTeamPromptActionDto => {
    const promptDto: TTeamPromptActionDto = {
      prompt: prompt.prompt,
      tagIds: prompt.tagIds,
      icon: prompt.icon,
    };

    if (!isEmpty(prompt.title)) {
      promptDto.title = prompt.title;
    }

    if (!isEmpty(prompt.description)) {
      promptDto.description = prompt.description;
    }

    return promptDto;
  };

  edit = async (promptId: string, prompt: TTeamPromptActionDto, withMessage = true) => {
    try {
      runInAction(() => {
        this.loading = true;
      });
      const promptDto = this.preparePromptDto(prompt);

      await this.opts.request.put(
        '/api/generation/organization/{organizationId}/team/{teamId}/team/prompt/{promptId}',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: this.opts.teamId,
              promptId,
            },
          },
          body: promptDto,
        },
      );

      if (withMessage) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.success,
          message: 'Team prompt updated',
        });
      }
    } catch (e) {
      LOG.error('Failed to edit team prompt', e);
      this.opts?.notificationQueue?.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  create = async (prompt: TTeamPromptActionDto, withMessage = true) => {
    try {
      const promptDto = this.preparePromptDto(prompt);

      runInAction(() => {
        this.loading = true;
      });
      await this.opts.request.post('/api/generation/organization/{organizationId}/team/{teamId}/team/prompt', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            teamId: this.opts.teamId,
          },
        },
        body: promptDto,
      });

      if (withMessage) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.success,
          message: 'Team prompt created',
        });
      }

      this.opts.analyticsService.track(AnalyticsActivity.createdNewTeamPrompt, {});
    } catch (e) {
      LOG.error('Failed to create team prompt', e);
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  duplicate = async (promptId: string) => {
    try {
      const prompt = await this.get(promptId).promise;

      if (!prompt) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Failed to duplicate team prompt',
        });

        return;
      }

      await this.create(
        {
          prompt: prompt.prompt,
          tagIds: prompt.tags.map(({ id }) => id),
          icon: prompt.icon,
          title: concatenateStrings(' ', '[Duplicated]', prompt.title),
          description: prompt.description,
        },
        false,
      );

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Team prompt duplicated',
      });
    } catch (e) {
      LOG.error('Failed to duplicate team prompt', e);
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    }
  };

  createTags = async (tags: string[]): Promise<TTeamPromptTag[]> => {
    try {
      await Promise.all(tags.map(tag => this.createTag(tag)));
      this.$teamPromptTags.reload();
      await this.$teamPromptTags.promise;

      return this.$teamPromptTags.value || [];
    } catch (e) {
      LOG.error('Failed to create tags', e);
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    }

    return [];
  };

  createTag = async (tag: string) => {
    try {
      await this.opts.request.post('/api/generation/organization/{organizationId}/team/{teamId}/team/prompt/tag', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            teamId: this.opts.teamId,
          },
        },
        body: {
          name: tag,
        },
      });
    } catch (e) {
      LOG.error('Failed to create tag', e);
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    }
  };

  toggleFavorite = async (promptId: string) => {
    try {
      const prompt = this.get(promptId);
      await prompt.promise;

      await this.opts.request.put(
        '/api/generation/organization/{organizationId}/team/{teamId}/team/prompt/favorite/{promptId}',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: this.opts.teamId,
              promptId,
            },
          },
          body: {
            favorite: !prompt.value?.favorite,
          },
        },
      );
    } catch (e) {
      LOG.error('Failed to toggle favorite', e);
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: extractBackendResponseErrorMessage(e),
      });
    }
  };
}
