import { ReactiveQueue } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';
import type { TNotificationQueueItem, TOrgTeamUserActivityParams } from '@writercolab/types';

import { PromptLibraryApiModel } from 'models/promptLibrary.api';
import { TeamPromptsApiModel } from 'models/teamPrompts.api';

import { PromptLibraryUIModel } from '../PromptLibrary';
import { PromptLibraryTeamUIModel } from '../PromptLibraryTeam';
import { PromptLibraryUserUIModel } from '../PromptLibraryUser';
import { IWebAppAnalyticsTrack } from 'constants/analytics';

export interface IPromptLibraryModalModelOpts {
  requestService: RequestServiceInitialize['api'];
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  organizationId: number;
  teamId: number;
}

export class PromptLibraryModalModel {
  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  apiModel: PromptLibraryApiModel;
  teamPromptsApiModel: TeamPromptsApiModel;
  userUiModel: PromptLibraryUserUIModel;
  teamUiModel: PromptLibraryTeamUIModel;
  uiModel: PromptLibraryUIModel;

  constructor(private opts: IPromptLibraryModalModelOpts) {
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();

    this.apiModel = new PromptLibraryApiModel({
      request: this.opts.requestService,
    });

    this.teamPromptsApiModel = new TeamPromptsApiModel({
      analyticsService: this.opts.analyticsService,
      request: this.opts.requestService,
      teamId: this.opts.teamId,
      organizationId: this.opts.organizationId,
    });

    this.userUiModel = new PromptLibraryUserUIModel({
      api: this.apiModel,
      analyticsService: this.opts.analyticsService,
    });

    this.teamUiModel = new PromptLibraryTeamUIModel({ api: this.teamPromptsApiModel });

    this.uiModel = new PromptLibraryUIModel({
      notificationQueue: this.notificationQueue,
      analyticsService: this.opts.analyticsService,
      promptLibraryUserUIModel: this.userUiModel,
      promptLibraryTeamUIModel: this.teamUiModel,
      teamPromptsApiModel: this.teamPromptsApiModel,
    });
  }
}
