import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import { IBillingGroupWithFails, TImportBillingGroupUsersRequest } from '@web/types';
import { observer } from 'mobx-react-lite';

import { DeleteConfirmationModal } from '../../molecules/DeleteConfirmationModal';
import { BillingGroupsEntityPanel } from '../../organisms/BillingGroupsEntityPanel';
import { BillingGroupsPanelTypes } from '../../organisms/BillingGroupsEntityPanel/BillingGroupsEntityPanel';
import { ImporterPopup } from '../../organisms/ImporterPopup';
import { ImportItemType } from '../../organisms/ImporterPopup/types';
import { BillingGroupPageUIModel } from './BillingGroupPageModel.ui';

import styles from './BillingGroup.module.css';

export const BillingGroupModalContainer = observer(({ model }: { model: BillingGroupPageUIModel }) => (
  <>
    <Modal className={styles.entityModal} open={model.isShowCreateModal} handleClose={model.closeModal}>
      <BillingGroupsEntityPanel
        panelType={BillingGroupsPanelTypes.enum.create}
        onCancel={model.closeModal}
        onCreate={model.onCreate}
        isLoading={model.isLoading}
        selectedGroup={model.selectedGroup}
      />
    </Modal>
    <Modal className={styles.entityModal} open={model.isShowEditNameModal} handleClose={model.closeModal}>
      <BillingGroupsEntityPanel
        panelType={BillingGroupsPanelTypes.enum.editName}
        onCancel={model.closeModal}
        selectedGroup={model.selectedGroup}
        onEdit={model.onEdit}
        isLoading={model.isLoading}
      />
    </Modal>
    <Modal className={styles.entityModal} open={model.isShowEditContactModal} handleClose={model.closeModal}>
      <BillingGroupsEntityPanel
        panelType={BillingGroupsPanelTypes.enum.editContact}
        onCancel={model.closeModal}
        selectedGroup={model.selectedGroup}
        onEdit={model.onEdit}
        isLoading={model.isLoading}
      />
    </Modal>

    <DeleteConfirmationModal
      modalClassName={styles.deletePromptModal}
      modalTitle="Delete billing group"
      bodyContent={
        <Text className={styles.deleteModalParagraph} variant={TextSize.M}>
          Are you sure you want to delete this billing group? Users in this group will no longer be assigned to any
          billing group.
        </Text>
      }
      isOpen={model.isShowDeleteModal}
      onClose={model.closeModal}
      closeButton={
        <Button
          className={styles.submitButton}
          type={ButtonTypes.DEFAULT}
          content="Never mind"
          onClick={model.closeModal}
        />
      }
      submitButton={
        <Button
          className={styles.submitButton}
          type={ButtonTypes.PRIMARY}
          danger
          content="Yes, delete"
          isLoading={model.isLoading}
          disabled={model.isLoading}
          onClick={model.handleDelete}
        />
      }
    />

    <ImporterPopup<TImportBillingGroupUsersRequest, IBillingGroupWithFails>
      open={model.billingImporterModel.isShow}
      onClose={model.billingImporterModel.onClose}
      onSubmit={model.billingImporterModel.onSubmit}
      type={ImportItemType.BILLING_GROUP}
    />
  </>
));
