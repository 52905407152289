/**
 * Shared onboarding storage keys.
 * DO NOT EDIT
 */
export enum OnboardingLocalStorageKey {
  /**
   * Used to keep track of the org id and name during
   * the onboarding process. We'll use this to join an org
   * if the user is not part of one.
   */
  OnboardingOrgId = 'onboarding:orgId',

  /**
   * Similar to org id. We'll use this to show the name
   * of the org in the onboarding process.
   */
  OnboardingOrgName = 'onboarding:orgName',

  /**
   * Persisted state similar to `onboardingState` from the legacy
   * flow. We need to keep some record if the user has actually completed
   * the questionnaire so we capture conversions in GTM and Segment.
   * Rather than using the old flag we are using a more explicit state to indicate
   * if the user completed the flow. If not, we'll take them back to their last
   * known step and force them to complete it.
   */
  OnboardingState = 'onboarding:state',

  /**
   * The step the user left off during onboarding. This entry will get purged
   * after the hard redirect to AIS of WA _after_ onboarding
   * is complete.
   */
  OnboardingStep = 'onboarding:step',

  /**
   * Tells the new onboarding experience to skip org creation. We use this in the case
   * of a legacy sign up **or** an invite.
   */
  OnboardingSkipOrgCreation = 'onboarding:skipOrgCreation',
}
