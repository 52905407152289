import React, { useEffect } from 'react';

import { SuggestionCategoryType } from '@writercolab/common-utils';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import analytics from 'services/analytics/analyticsService';

import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import SuggestionCategory from '../SuggestionCategory';
import SuggestionPageHeader from '../SuggestionPageHeader';
import ConfidenceExample from '../examples/ConfidenceCommunicationsExample';

import styles from '../styles.module.css';

const Description = ({ content }) => (
  <div className={styles.suggestionCategoryDescriptionSmall}>
    <Text variant={TextSize.L} inline>
      {content}
    </Text>
  </div>
);

export const DeliveryPage: React.FC = () => {
  usePageTitle('Delivery');
  const {
    suggestionSettings,
    isSaving,
    isReadOnly,
    isTeamNameShown,
    lastUpdated,
    lastUpdatedBy,
    teamName,
    setSectionEnabled,
  } = useSuggestionsContext();

  useEffect(() => {
    analytics.track(AnalyticsActivity.suggestionsDeliveryViewed, {});
  }, []);

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Delivery"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      <div className={styles.description}>
        <div className={styles.descriptionItemShort}>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            How content is delivered — the voice and tone in which it comes across — can make a big difference in
            achieving the goal of the message.
          </Text>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            When a switch is on, we’ll let you know if your content has issues in that category.
          </Text>
        </div>
      </div>

      <SuggestionCategory
        readOnly={isReadOnly}
        name="Confidence"
        type={SuggestionCategoryType.CONFIDENCE}
        description={
          <Description content="Confidence flags words and phrases that come across as hedging or uncertain, and suggests direct and assertive alternatives." />
        }
        halfLimitedWidth
        hideBottomBorder
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
        tooltipContent={<ConfidenceExample />}
        tooltipWrapperClassname={styles.deliveryRulesTooltip}
        className={styles.suggestionCategoryConfidence}
        smallBlockHeight
      />
    </div>
  );
};

export default DeliveryPage;
