import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Tag, TagColor, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { DEFAULT_TEXT_PLACEHOLDER } from '@web/types';

import styles from './styles.module.css';

export enum DropdownTriggerVariant {
  ROUNDED = 'rounded',
  TAG = 'tag',
  DEFAULT = 'default',
}

interface IDropdownTriggerProps extends BaseComponentsProps {
  placeholder: string;
  variant?: DropdownTriggerVariant;
  triggerColor?: TextColor;
  triggerSize?: TextSize;
  disabled?: boolean;
  iconHidden?: boolean;
  iconVisible?: boolean;
  placeholderVariant?: TextSize;
  placeholderMedium?: boolean;
  icon?: React.ReactNode;
  indicator?: React.ReactNode;
  tagColor?: TagColor;
}

export const DropdownTrigger: React.FC<IDropdownTriggerProps> = ({
  variant = DropdownTriggerVariant.DEFAULT,
  className,
  placeholder,
  disabled,
  iconVisible = true,
  placeholderMedium = false,
  icon,
  indicator,
  triggerColor = TextColor.BLACK,
  triggerSize = TextSize.L,
  tagColor = TagColor.LIGHT_GREEN,
}) => {
  if (variant === DropdownTriggerVariant.TAG) {
    return (
      <Tag color={tagColor} className={className}>
        <div className={styles.tagContainer}>
          <Text color={TextColor.BLACK} variant={TextSize.XXS} caps medium className={styles.tagTitle}>
            {placeholder}
          </Text>
          {iconVisible && <div>{icon || <Icon name={IconVariant.ARROW_DOWN} />}</div>}
        </div>
      </Tag>
    );
  }

  return (
    <div
      className={cx(className, styles.selectTrigger, {
        [styles.selectTriggerDisabled]: disabled,
        [styles.selectTriggerRounded]: variant === DropdownTriggerVariant.ROUNDED,
      })}
    >
      {!!indicator && <div className={styles.selectTriggerIndicator}>{indicator}</div>}
      <Text
        color={triggerColor}
        variant={triggerSize}
        ellipsisOverflow
        className={styles.selectTriggerIconText}
        medium={placeholderMedium}
      >
        {placeholder || DEFAULT_TEXT_PLACEHOLDER}
      </Text>
      {iconVisible && <div className={styles.selectTriggerIcon}>{icon || <Icon name={IconVariant.ARROW_DOWN} />}</div>}
    </div>
  );
};

export default DropdownTrigger;
