import React from 'react';

import { Tooltip, TooltipAlignment, TooltipBackgroundColor } from '@writercolab/ui-atoms';

import styles from '../../styles.module.css';

interface ExampleTooltipProps {
  example: NonNullable<React.ReactNode>;
  title: string;
}

const ExampleTooltip: React.FC<ExampleTooltipProps> = ({ example, title }) => (
  <Tooltip
    title={example}
    placement="right"
    tooltipBackgroundColor={TooltipBackgroundColor.WHITE}
    align={TooltipAlignment.LEFT}
    tooltipWidth={368}
  >
    <div className={styles.suggestionSimpleRuleCheckboxTitle}>
      <span>{title}</span>
    </div>
  </Tooltip>
);

export default ExampleTooltip;
