import type React from 'react';
import { useEffect, useState } from 'react';

import cx from 'classnames';

import { LocalStorageKey, LocalStorageService, openChromeExtensionStore } from '@writercolab/common-utils';
import { Fade, Heading, HeadingVariant, Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { AnalyticEvents, AnalyticsActivity } from 'constants/analytics';
import uniqBy from 'lodash/uniqBy';

import { useAppState } from '../../../state';
import { TActionType } from '../../../state/types';

import styles from './styles.module.css';

type ChromeExtWindowLocation = 'editor' | 'dashboard' | 'sidebar';

export interface UserChromeExtWindowState {
  id: number;
  state: boolean;
  footerState: boolean;
}

interface ChromeExtWindowProps extends BaseComponentsProps {
  /** Set the window location for Analytics */
  sourceLocation: ChromeExtWindowLocation;
}

export const ChromeExtWindow: React.FC<ChromeExtWindowProps> = ({ className, sourceLocation }) => {
  const { appState, dispatchAppState, appModel } = useAppState();
  const [show, setShow] = useState(false);

  const _trackButtonClick = (activity: AnalyticEvents) => {
    appModel.analyticsService.track(activity, { click_source: sourceLocation });
  };

  const setWindowStorageState = () => {
    const currentStorageData = LocalStorageService.getItem<UserChromeExtWindowState[]>(
      LocalStorageKey.chromeWindowVisible,
    );
    const state = {
      id: appState.userProfile?.id,
      state: false,
      footerState: false,
    };

    if (currentStorageData) {
      LocalStorageService.setItem(LocalStorageKey.chromeWindowVisible, uniqBy([...currentStorageData, state], 'id'));
    } else {
      LocalStorageService.setItem(LocalStorageKey.chromeWindowVisible, [state]);
    }
  };

  const closeWindow = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    _trackButtonClick(AnalyticsActivity.chromeWindowDismissed);
    setWindowStorageState();
    dispatchAppState({ type: TActionType.SetChromeWindowState, payload: false });
    dispatchAppState({ type: TActionType.SetChromeAnnouncementState, payload: true });
  };

  const _openChromeExtensionStore = () => {
    _trackButtonClick(AnalyticsActivity.installChromeExtClicked);
    openChromeExtensionStore();
  };

  useEffect(() => {
    // todo: this is temporary. we will revert in ~1 month. [https://writerai.atlassian.net/browse/WA-2957]
    if (appState.organizationId === 501065) {
      return;
    }

    if ((appState.isChromeExtensionWindowVisible && sourceLocation === 'dashboard') || sourceLocation === 'sidebar') {
      setTimeout(() => setShow(true), 5000);
    }
  }, [appState.isChromeExtensionWindowVisible, appState.organizationId, sourceLocation]);

  return (
    <div>
      {show && appState.userProfile ? (
        <Fade show time={1}>
          <div
            className={cx(styles.container, className, {
              [styles.containerDashboard]: sourceLocation === 'dashboard',
              [styles.containerSidebar]: sourceLocation === 'sidebar',
            })}
            onClick={_openChromeExtensionStore}
          >
            <div className={styles.containerCloseIcon} onClick={closeWindow} title="Close">
              <Icon name={IconVariant.CLOSE} width={27} height={27} />
            </div>
            <div className={styles.containerIcon}>
              <span className={styles.chromeIcon} />
            </div>
            <Heading variant={HeadingVariant.H4} className={styles.containerHeroText}>
              Add Writer to Chrome
            </Heading>
            <Text color={TextColor.WHITE} variant={TextSize.L} className={styles.containerHeroDesc}>
              Get Writer working almost anywhere you write.
            </Text>
          </div>
        </Fade>
      ) : null}
    </div>
  );
};

export default ChromeExtWindow;
