import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import {
  IconsShortcutsAskWriter,
  IconsShortcutsBlog,
  IconsShortcutsCoWrite,
  IconsShortcutsImageAnalyzer,
  IconsShortcutsRecaps,
} from '@writercolab/assets';
import {
  BillingProduct,
  ContentEditorPageMode,
  LocalStorageKey,
  LocalStorageService,
  SharedQueryParam,
  TAssetToDelete,
} from '@writercolab/common-utils';
import {
  DataRetentionPolicyBanner,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SearchBar,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { FilterIndicator } from '@writercolab/ui-molecules';
import { EMPTY_EDITOR_DOC_ID } from '@writercolab/utils';

import { AppShortcutButton } from 'components/molecules/AppShortcutButton';

import { TOrganizationTableAction } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { useDataRetentionBanner } from 'hooks/useDataRetentionBanner';
import head from 'lodash/head';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';

import { usePageTitle } from '../../../hooks/usePageTitle';
import useQuery from '../../../hooks/useQuery';
import { OrganizationDocumentsApi } from '../../../models/organizationDocuments.api';
import { ROUTE } from '../../../services/config/routes';
import { createDocument } from '../../../services/request/documents';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { TActionType } from '../../../state/types';
import { getLogger } from '../../../utils/logger';
import { goToBillingNewTab, removeQueryParam } from '../../../utils/navigationUtils';
import ChromeExtWindow from '../../molecules/ChromeExtWindow';
import DocumentsEmptyListPlaceholder, {
  DocumentsEmptyListViewType,
} from '../../molecules/DocumentsEmptyListPlaceholder';
import PageTitle from '../../molecules/PageTitle';
import { DocsTable, DocsTableUiModel } from '../../organisms/DocsTable';
import OnboardingTutorial from '../../organisms/OnboardingTutorial';

import styles from './styles.module.css';

const LOG = getLogger('DashboardPage');

export const DashboardPage: React.FC = () => {
  usePageTitle('Home');
  const { appState, dispatchAppState, appModel } = useAppState();
  const location = useLocation();
  const navigate = useNavigate();
  const { organizationId, teamId, userProfile, isChromeExtensionWindowVisible, isTutorialVisible } = appState;
  const queryParams = useQuery();
  const [showTutorial, setShowTutorial] = useState(isTutorialVisible);
  const [tutorialTargetDoc, setTutorialTargetDoc] = useState(-1);
  const { teamsModel, permissionsModel, featureFlags, assistantSubscription, analyticsService } = appModel;
  const { shouldShowPolicyBanner, ...bannerProps } = useDataRetentionBanner({ asset: TAssetToDelete.enum.Doc });

  const teamNameInHeader = useMemo(() => {
    if (!assistantSubscription.isMultiTeam || !teamsModel.teams) {
      return '';
    }

    const _activeTeam = teamsModel.teams?.find(team => team.id === teamId);

    return _activeTeam?.name || '';
  }, [teamsModel.teams, teamId, assistantSubscription.isMultiTeam]);

  const access = {
    showTeamsDropdown: assistantSubscription.isMultiTeam,
    showTeamInDocsList: assistantSubscription.isMultiTeam && teamId === 0,
    restrictedDeleteDocAccess: permissionsModel?.isTeamMember,
    showOwnedByColumn: assistantSubscription.isMultiTeam,
    showNewDocButton: !!teamId,
    showEmptyStateHideLearningCenterLink: !assistantSubscription.isFree,
    publicAppsAvailable: featureFlags.get('publicAppsAvailable', false),
    imageAnalysisAvailable: featureFlags.get('imageAnalysisAvailable', false),
  };

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.dashboardViewed, {});
  }, [analyticsService]);

  useEffect(() => {
    removeQueryParam(navigate, queryParams, SharedQueryParam.TUTORIAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowTutorial(isTutorialVisible);
  }, [isTutorialVisible]);

  const onCompleteTutorial = useCallback(() => {
    const tutorialVisibleStorageRecord = LocalStorageService.getItem(LocalStorageKey.tutorialVisible, []) as string[];
    const recordValue = `${organizationId}${userProfile?.id}`;

    if (!tutorialVisibleStorageRecord.includes(recordValue)) {
      LocalStorageService.setItem(LocalStorageKey.tutorialVisible, [...tutorialVisibleStorageRecord, recordValue]);
    }
  }, [organizationId, userProfile?.id]);

  const onNewDocumentClick = useCallback(async () => {
    if (!teamId) {
      return;
    }

    const newDoc = await createDocument(organizationId, teamId, '');
    analyticsService.track(AnalyticsActivity.createDocument, {
      created_from: 'dashboard',
    });
    navigate(ROUTE.toEditorPage(organizationId, teamId, newDoc.id, location.search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId]);

  const onStartWithCoWrite = () => {
    analyticsService.track(AnalyticsActivity.appLibraryAllAppsShortcutClicked, { opened_from: 'home' });
    navigate(ROUTE.toEditorWithCoWrite(organizationId, teamId));
  };

  const onStartWithBlogBuilder = () => {
    analyticsService.track(AnalyticsActivity.startWithCoWrite, {});
    navigate(
      ROUTE.toEditorWithModeAndTemplate(
        `${organizationId}`,
        `${teamId}`,
        EMPTY_EDITOR_DOC_ID,
        ContentEditorPageMode.BLOG,
      ),
    );
  };

  const onStartWithEventTakeaways = () => {
    analyticsService.track(AnalyticsActivity.startWithCoWrite, {});
    navigate(
      ROUTE.toEditorWithModeAndTemplate(
        `${organizationId}`,
        `${teamId}`,
        EMPTY_EDITOR_DOC_ID,
        ContentEditorPageMode.EVENT_TAKEAWAYS,
      ),
    );
  };

  const onStartWithAskWriter = () => {
    analyticsService.track(AnalyticsActivity.startWithCoWrite, {});
    navigate(ROUTE.toAskWriter(organizationId, teamId));
  };

  const onStartWithImageAnalyzer = () => {
    navigate(
      ROUTE.toEditorWithModeAndTemplate(
        `${organizationId}`,
        `${teamId}`,
        EMPTY_EDITOR_DOC_ID,
        ContentEditorPageMode.CO_WRITE,
        'image_analyzer_app',
      ),
    );
  };

  const onSkipTutorial = () => {
    dispatchAppState({
      type: TActionType.SetTutorialVisible,
      payload: false,
    });

    onCompleteTutorial();
  };

  const onFinishTutorial = useCallback(() => {
    onCompleteTutorial();
    dispatchAppState({
      type: TActionType.SetTutorialVisible,
      payload: false,
    });
    navigate(ROUTE.toEditorPage(organizationId, teamId, tutorialTargetDoc));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId, tutorialTargetDoc, userProfile]);

  const showLearningCenter = useCallback(() => {
    analyticsService.track(AnalyticsActivity.learningCenterOpened, {});

    navigate(ROUTE.toLearningCenter(organizationId, teamId), {
      state: { prevRoute: location.pathname },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId, location.pathname]);

  const organizationDocumentsApiModel = useMemo(
    () =>
      new OrganizationDocumentsApi({
        organizationId: Number(organizationId),
        teamId: Number(teamId),
        request: requestService.api,
      }),
    [organizationId, teamId],
  );
  const documentsTableUiModel = useMemo(
    () =>
      new DocsTableUiModel({
        organizationDocumentsApi: organizationDocumentsApiModel,
        organizationId: () => Number(organizationId),
        teamId: () => Number(teamId),
        scopeFilterDisabled: assistantSubscription.isFree,
      }),
    [organizationDocumentsApiModel, organizationId, teamId, assistantSubscription.isFree],
  );

  documentsTableUiModel.tableActionsQueue.read(event => {
    if (!event.params || !event.type) {
      LOG.error('DocumentsTableUiModel: tableActionsQueue: event is not valid', event);

      return;
    }

    if (event.type === TOrganizationTableAction.enum.openDocument) {
      navigate(ROUTE.toEditorPage(event.params.organizationId, event.params.teamId, event.params.documentId));
    }
  });

  useEffect(() => {
    if (documentsTableUiModel.empty) {
      return;
    }

    const document =
      documentsTableUiModel.documents.find(d => d.title.toLowerCase().includes('demo doc')) ||
      head(documentsTableUiModel.documents);

    if (document) {
      setTutorialTargetDoc(Number(document.id));
    }
  }, [documentsTableUiModel]);

  const featuredAppsLinks = useMemo(() => {
    if (!access.publicAppsAvailable) {
      return null;
    }

    return (
      <>
        {assistantSubscription.access?.coWrite && (
          <Tooltip
            disabled={assistantSubscription.access.askWriter}
            title={
              <Text className={styles.createDocTooltip} variant={TextSize.M} color={TextColor.WHITE} bold>
                {assistantSubscription.isFree ? (
                  <>
                    Ask Writer is available only on Team plans. Upgrade to{' '}
                    <span onClick={() => goToBillingNewTab(Number(organizationId), BillingProduct.TEAM)}>
                      Writer Team
                    </span>{' '}
                    to get access.
                  </>
                ) : (
                  'Ask Writer is locked for your organization. Contact your org admin for more details.'
                )}
              </Text>
            }
          >
            <div>
              <AppShortcutButton
                className={cx(styles.createAppButton, {
                  [styles.createDocButtonDisabled]: !assistantSubscription.access.askWriter,
                })}
                text="Ask Writer"
                onClick={onStartWithAskWriter}
                icon={<IconsShortcutsAskWriter width="40px" height="40px" />}
              />
            </div>
          </Tooltip>
        )}
        <Tooltip
          disabled={assistantSubscription.access?.seoBlogBuilder}
          title={
            <Text className={styles.createDocTooltip} variant={TextSize.M} color={TextColor.WHITE} bold>
              {assistantSubscription.isFree ? (
                <>
                  Blog builder is available only on Team plans. Upgrade to{' '}
                  <span onClick={() => goToBillingNewTab(Number(organizationId), BillingProduct.TEAM)}>
                    Writer Team
                  </span>{' '}
                  to get access.
                </>
              ) : (
                'Blog builder is locked for your organization. Contact your org admin for more details.'
              )}
            </Text>
          }
        >
          <div>
            <AppShortcutButton
              className={cx(styles.createAppButton, {
                [styles.createDocButtonDisabled]: !assistantSubscription.access?.seoBlogBuilder,
              })}
              text="Blog builder"
              onClick={onStartWithBlogBuilder}
              icon={<IconsShortcutsBlog width="40px" height="40px" />}
            />
          </div>
        </Tooltip>
        <Tooltip
          disabled={assistantSubscription.access?.eventTakeaways}
          title={
            <Text className={styles.createDocTooltip} variant={TextSize.M} color={TextColor.WHITE} bold>
              {assistantSubscription.isFree ? (
                <>
                  Recaps are available only on Team plans. Upgrade to{' '}
                  <span onClick={() => goToBillingNewTab(Number(organizationId), BillingProduct.TEAM)}>
                    Writer Team
                  </span>{' '}
                  to get access.
                </>
              ) : (
                'Recaps are locked for your organization. Contact your org admin for more details.'
              )}
            </Text>
          }
        >
          <div>
            <AppShortcutButton
              className={cx(styles.createAppButton, {
                [styles.createDocButtonDisabled]: !assistantSubscription.access?.eventTakeaways,
              })}
              text="Recaps builder"
              onClick={onStartWithEventTakeaways}
              icon={<IconsShortcutsRecaps width="40px" height="40px" />}
            />
          </div>
        </Tooltip>
        {access.imageAnalysisAvailable && (
          <AppShortcutButton
            className={styles.createAppButton}
            text="Image analyzer"
            onClick={onStartWithImageAnalyzer}
            icon={<IconsShortcutsImageAnalyzer width="40px" height="40px" />}
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    access.publicAppsAvailable,
    access.imageAnalysisAvailable,
    assistantSubscription.isFree,
    organizationId,
    assistantSubscription.access?.askWriter,
    assistantSubscription.access?.coWrite,
    assistantSubscription.access?.eventTakeaways,
    assistantSubscription.access?.seoBlogBuilder,
  ]);

  return (
    <div className={styles.pageWrapper}>
      {userProfile && (
        <OnboardingTutorial
          visible={showTutorial}
          userProfile={userProfile}
          onFinishAction={onFinishTutorial}
          onSkipAction={onSkipTutorial}
        />
      )}
      <div className={styles.contentBar}>
        <div className={styles.headLine}>
          <div className={styles.titleAndSearch}>
            <PageTitle title="Home" teamName={teamNameInHeader} />
            {documentsTableUiModel.filtersEnabled && (
              <div className={styles.filterIndicator}>
                <FilterIndicator
                  filtersAmount={documentsTableUiModel.filtersEnabledCount}
                  onClose={() => documentsTableUiModel.onClearFilters()}
                />
              </div>
            )}
            <SearchBar
              id="search"
              value={documentsTableUiModel.searchPhrase}
              placeholder="Search"
              onChange={e => documentsTableUiModel.onSearchChange(e.target.value)}
              handleClearInput={() => documentsTableUiModel.onClearSearch()}
              className={styles.searchBar}
            />
            {access.showNewDocButton && (
              <Tooltip
                title={
                  <Text className={styles.newDocTooltip} variant={TextSize.M} color={TextColor.WHITE} bold>
                    Start a doc from scratch
                  </Text>
                }
              >
                <div className={styles.newDocButton} onClick={onNewDocumentClick}>
                  <Icon name={IconVariant.NEW_DOC} width={24} height={24}></Icon>
                </div>
              </Tooltip>
            )}
          </div>
          <Heading variant={HeadingVariant.H3} bold>
            Shortcuts
          </Heading>
          <div className={styles.newButtons}>
            <AppShortcutButton
              className={styles.createAppButton}
              text="All apps"
              onClick={onStartWithCoWrite}
              icon={<IconsShortcutsCoWrite width="40px" height="40px" />}
            />
            {featuredAppsLinks}
          </div>
        </div>

        {shouldShowPolicyBanner && <DataRetentionPolicyBanner className={styles.banner} {...bannerProps} />}
        {documentsTableUiModel.empty ? (
          <DocumentsEmptyListPlaceholder
            className={styles.emptyPlaceholderContainer}
            onClickOpenTemplate={onStartWithCoWrite}
            onClickOpenLearningCenter={showLearningCenter}
            showLearningCenterLink={access.showEmptyStateHideLearningCenterLink}
            viewType={
              assistantSubscription.isFree ? DocumentsEmptyListViewType.COLLAPSED : DocumentsEmptyListViewType.EXPANDED
            }
            onClickNewDoc={onNewDocumentClick}
          />
        ) : (
          <DocsTable model={documentsTableUiModel} />
        )}
      </div>

      {isChromeExtensionWindowVisible && <ChromeExtWindow sourceLocation="dashboard" />}
    </div>
  );
};

export default observer(DashboardPage);
