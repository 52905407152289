import type React from 'react';
import { useCallback, useMemo } from 'react';

import type { IUserProfile, IUserTeam } from '@writercolab/common-utils';
import {
  MAX_LENGTH,
  UserAccountStatus,
  UserTeamRole,
  convertToEllipsis,
  getDateFormatMMMDDYYYY,
} from '@writercolab/common-utils';
import {
  AvatarSize,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  MuiTableCell,
  MuiTableRow,
  RoleLabel,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  UserAvatar,
} from '@writercolab/ui-atoms';
import { ListPreview } from '@writercolab/ui-molecules';

import type { TImportBillingGroupUsersResponse } from '@web/types';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router';
import { ROUTE } from 'services/config/routes';
import { useAppState } from 'state';

import styles from './styles.module.css';

const DEFAULT_PLACEHOLDER = '';

interface PeopleListRowProps {
  user: IModifiedUser;
  actions: Record<string, Function>;
  profileId?: number;
  isPeoplePage: boolean;
  hideBillingGroups?: boolean;
  hideInvite?: boolean;
  hidePendingApproval?: boolean;
  isSelfServe?: boolean;
  isTeamUsersPage?: boolean;
  hideMoreOptions?: boolean;
  removeUsersActionVisible: boolean;
  deleteUsersActionVisible: boolean;
  updateBillingGroupActionVisible: boolean;
}

type ListUserTeam = IUserTeam & { color?: string };

export interface IModifiedUser extends IUserProfile {
  role: UserTeamRole;
  teams?: ListUserTeam[];
  approvedByInviter: boolean;
  billingGroup?: null | TImportBillingGroupUsersResponse;
}

const inviteOptions = [
  {
    id: 'resend',
    name: 'Re-send invite link',
  },
];

const pendingUsersOptions = [
  {
    id: 'approve',
    name: 'Approve request to join',
  },
  {
    id: 'reject',
    name: 'Reject request to join',
  },
];

const UserActionDropdown = ({ options, onPrimaryOptionClickActionId, trigger }) => (
  <Dropdown
    trigger={trigger}
    itemsType={ItemsTypes.ACTION}
    options={options}
    onPrimaryOptionClickAction={onPrimaryOptionClickActionId}
    placement={DropdownPlacement.BOTTOM_LEFT}
    dropdownItemClassName={styles.dropdownItem}
  />
);

const UserLastSeenDisplay = ({ user }) => {
  let statusContent: React.ReactElement | null = null;

  if (user.lastOnlineTime) {
    statusContent = <Text>{getDateFormatMMMDDYYYY(user.lastOnlineTime)}</Text>;
  } else {
    statusContent = <Text>{DEFAULT_PLACEHOLDER}</Text>;
  }

  return statusContent;
};

const UserStatusDisplay = ({
  user,
  hideInvite,
  hidePendingApproval,
  onPrimaryOptionClickActionId,
  onPrimaryOptionClickAction,
}) => {
  const isUserInvited = user.invited;
  const isUserActive = user.accountStatus === UserAccountStatus.SIGNED_UP && user.approvedByInviter;
  const isUserApprovalPending = user.accountStatus === UserAccountStatus.SIGNED_UP && !user.approvedByInviter;

  let statusContent: React.ReactElement | null = <Text>{DEFAULT_PLACEHOLDER}</Text>;

  if (isUserActive) {
    statusContent = <Text>Active</Text>;
  } else if (isUserInvited && hideInvite) {
    statusContent = <InvitePendingLabel text="Invite pending" />;
  } else if (isUserInvited && !hideInvite) {
    statusContent = (
      <UserActionDropdown
        options={inviteOptions}
        onPrimaryOptionClickActionId={onPrimaryOptionClickActionId}
        trigger={<InvitePendingLabel text="Invite pending" />}
      />
    );
  } else if (isUserApprovalPending && !hidePendingApproval) {
    statusContent = (
      <UserActionDropdown
        options={pendingUsersOptions}
        onPrimaryOptionClickActionId={onPrimaryOptionClickAction}
        trigger={<InvitePendingLabel text="Approval pending" />}
      />
    );
  } else if (isUserApprovalPending && hidePendingApproval) {
    statusContent = (
      <Tooltip title="This request can be approved by an org admin" placement="top">
        <div>
          <InvitePendingLabel text="Approval pending" />
        </div>
      </Tooltip>
    );
  }

  return statusContent;
};

const InvitePendingLabel = ({ text }) => (
  <div className={styles.pendingTrigger}>
    <Text color={TextColor.GREY3}>{text}</Text>

    <Icon name={IconVariant.DROP_DOWN_ARROW} className={styles.pendingTriggerIcon} />
  </div>
);

export const PeopleListRow = observer(
  ({
    user,
    actions,
    profileId,
    hideInvite,
    isPeoplePage,
    isSelfServe,
    isTeamUsersPage,
    hideBillingGroups,
    hidePendingApproval,
    hideMoreOptions,
    removeUsersActionVisible,
    deleteUsersActionVisible,
    updateBillingGroupActionVisible,
  }: PeopleListRowProps) => {
    const { appState } = useAppState();
    const { organizationId } = appState;

    const getLabel = (s?: string | null) => s || '';
    const userName = `${user.firstName || ''} ${user.lastName || ''}`;
    const onPrimaryOptionClickAction = useCallback(i => actions[i](user), [actions, user]);
    const onPrimaryOptionClickActionId = useCallback(i => actions[i](user.id), [actions, user.id]);
    const teamsOptions = user.teams?.map(team => ({ ...team, labelColor: team.color })) || [];
    const computeOptionsMenu = useMemo(() => {
      const _adminType = isPeoplePage ? 'org' : 'team';

      let _makeOrRemoveAdminlabel = '';

      if (
        (isPeoplePage && user.role === UserTeamRole.ORG_ADMIN) ||
        (!isPeoplePage && user.role.includes(UserTeamRole.ADMIN))
      ) {
        _makeOrRemoveAdminlabel = `Unassign as ${_adminType} admin`;
      } else {
        _makeOrRemoveAdminlabel = `Make ${_adminType} admin`;
      }

      const options = [
        {
          id: 'make_admin',
          name: _makeOrRemoveAdminlabel,
          warning: false,
        },
      ];

      if (updateBillingGroupActionVisible) {
        options.push({
          id: 'update_billing_group',
          name: 'Edit billing group',
          warning: false,
        });
      }

      // remove user from the team
      if (removeUsersActionVisible) {
        options.push({
          id: 'remove',
          name: 'Remove user',
          warning: true,
        });
      }

      if (deleteUsersActionVisible) {
        options.push({
          id: 'delete',
          name: 'Delete user',
          warning: true,
        });
      }

      return options;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPeoplePage, isSelfServe, user.role, removeUsersActionVisible, deleteUsersActionVisible]);

    const roleLabelVisible = useMemo(
      () => [UserTeamRole.ORG_ADMIN, UserTeamRole.ADMIN].includes(user.role),
      [user.role],
    );

    return (
      <MuiTableRow className={styles.tableRow}>
        <MuiTableCell component="td" scope="coll" sx={{ width: '245px' }}>
          <div className={styles.nameContainer}>
            <UserAvatar
              fullName={userName}
              avatarPath={user.avatar}
              label={getLabel(user.email)}
              size={AvatarSize.XS}
            />
            <Tooltip title={userName} disabled={userName?.length <= MAX_LENGTH}>
              <span>
                <Text ellipsisOverflow className={styles.userName}>
                  {userName}
                </Text>
              </span>
            </Tooltip>
            {roleLabelVisible && <RoleLabel role={user.role} />}
          </div>
        </MuiTableCell>

        <MuiTableCell align="left" className={styles.tableRowEmail} sx={{ width: '175px' }}>
          <Tooltip title={getLabel(user.email)} disabled={getLabel(user.email).length <= 20}>
            <span>
              <Text ellipsisOverflow className={styles.userEmail}>
                {convertToEllipsis(getLabel(user.email), 20)}
              </Text>
            </span>
          </Tooltip>
        </MuiTableCell>

        {!hideBillingGroups && (
          <MuiTableCell align="left" sx={{ width: '150px' }}>
            {user.billingGroup ? (
              <Tooltip
                title={
                  <Text variant={TextSize.S} color={TextColor.WHITE}>
                    View billing groups
                  </Text>
                }
                placement="top"
              >
                <Link to={ROUTE.toAdminBillingGroups(organizationId)} className={styles.tag}>
                  <Text variant={TextSize.S} className={styles.tagText}>
                    {user.billingGroup?.name}
                  </Text>
                </Link>
              </Tooltip>
            ) : (
              <>{DEFAULT_PLACEHOLDER}</>
            )}
          </MuiTableCell>
        )}

        {isPeoplePage && (
          <MuiTableCell align="left" sx={{ width: '95px' }}>
            <ListPreview className={styles.listPreviewContainer} options={teamsOptions} />
          </MuiTableCell>
        )}
        <MuiTableCell align="left" sx={{ width: '110px' }}>
          <UserStatusDisplay
            user={user}
            hidePendingApproval={hidePendingApproval}
            hideInvite={hideInvite}
            onPrimaryOptionClickAction={onPrimaryOptionClickAction}
            onPrimaryOptionClickActionId={onPrimaryOptionClickActionId}
          />
        </MuiTableCell>
        <MuiTableCell align="left" sx={{ width: '110px' }}>
          <UserLastSeenDisplay user={user} />
        </MuiTableCell>
        <MuiTableCell align="right" sx={{ width: '50px' }}>
          {user.id &&
            user.id !== profileId &&
            !hideMoreOptions &&
            (isPeoplePage || isSelfServe || (isTeamUsersPage && user.role !== UserTeamRole.ORG_ADMIN)) && (
              <Dropdown
                trigger={<span className={styles.actionTrigger}>...</span>}
                itemsType={ItemsTypes.ACTION}
                options={computeOptionsMenu}
                onPrimaryOptionClickAction={onPrimaryOptionClickAction}
                placement={DropdownPlacement.BOTTOM_RIGHT}
              />
            )}
        </MuiTableCell>
      </MuiTableRow>
    );
  },
);
