import React from 'react';

import { Button, ButtonTypes, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import generalStyles from '../styles.module.css';
import styles from './styles.module.css';

interface IPlanCancelled {
  open: boolean;
  toBillingHref: string;
  isDowngrade?: boolean;
  onDeleteMyAccountCb?: () => void;
  handleClose?: () => void;
}

export const PlanCancelled: React.FC<IPlanCancelled> = ({
  open,
  isDowngrade,
  toBillingHref,
  onDeleteMyAccountCb,
  handleClose,
}) => (
  <Modal open={open} title="Plan cancelled" className={generalStyles.modalContainer} handleClose={handleClose}>
    <div>
      <div className={generalStyles.content}>
        <Text variant={TextSize.M} className={styles.text}>
          Your plan has been cancelled
          {isDowngrade ? ', and you’ve been downgraded to our Free plan.' : '.'}
        </Text>
        <Text variant={TextSize.M} className={styles.text}>
          Resubscribe anytime from the
          <a href={toBillingHref} className={styles.link}>
            Billing page
          </a>
          .
        </Text>
      </div>
      <div className={generalStyles.actionButtonsHolder}>
        <Button
          type={ButtonTypes.TRANSPARENT}
          size={SizeTypes.XS}
          className={generalStyles.secondaryAction}
          onClick={onDeleteMyAccountCb}
          content="I’d like to delete my account and all my data"
        ></Button>
        <Button
          type={ButtonTypes.PRIMARY}
          className={generalStyles.mainAction}
          content="Got it"
          navTo={toBillingHref}
        ></Button>
      </div>
    </div>
  </Modal>
);

export default PlanCancelled;
