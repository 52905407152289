import React, { useEffect } from 'react';

import { BaseComponentsProps, IIdentityProvider } from '@writercolab/common-utils';
import { Button, Icon, IconVariant, Input, Label, useCustomSnackbar } from '@writercolab/ui-atoms';
import { Collapsed, CopyToClipboardField } from '@writercolab/ui-molecules';

import { snackbarMessages } from '@web/component-library';
import { Buffer } from 'buffer';
import isEmpty from 'lodash/isEmpty';

import { useSsoContext } from '../../../../context/ssoContext';
import { setupIDPManagerTextConstants } from '../utils/constants';
import { spEntityId, spSsoUrl } from '../utils/ssoCommonUtils';
import StepDescription from './StepDescription';
import SubstepDescription from './SubstepDescription';
import XMLFileUpload from './XMLFileUpload';

import styles from '../styles.module.css';

interface StepThreeProps extends BaseComponentsProps {
  step: number;
  provider: IIdentityProvider;
  updateProvider: (updatedFields) => void;
  setIsNextStepAvailable: (isAvailable: boolean) => void;
  xmlString: string;
  setXmlString: (xmlString: string) => void;
}

const SP_SSO_URL = spSsoUrl();
const SP_ENTITY_ID = spEntityId();

export const StepTwo = ({
  step,
  provider,
  updateProvider,
  setIsNextStepAvailable,
  xmlString,
  setXmlString,
}: StepThreeProps) => {
  const reader = new FileReader();
  const { isFileDownloading, handleDownloadSPXML, handleXMLUpload } = useSsoContext();
  const { enqueueErrorSnackbar } = useCustomSnackbar();

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      reader.readAsText(file);
      reader.onloadend = async evt => {
        const readerData = evt?.target?.result as string;
        const encodedString = Buffer.from(readerData).toString('base64');

        if (isEmpty(readerData)) {
          enqueueErrorSnackbar(snackbarMessages.sso.updateError('XML file is empty.'));
        } else {
          setXmlString(readerData);

          handleXMLUpload(encodedString).then(idpMetaData => {
            updateProvider({ idpMetaDataConfigBase64: encodedString, idpMetaData });
            setIsNextStepAvailable(!!idpMetaData.ssoUrl);
          });
        }
      };
    }
  };

  const handleRemoveFile = () => {
    setXmlString('');
    updateProvider({ idpMetaDataConfigBase64: '', idpMetaData: null });
    setIsNextStepAvailable(false);
  };

  useEffect(() => {
    if (isEmpty(xmlString)) {
      setIsNextStepAvailable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xmlString]);

  return (
    <>
      <StepDescription step={step} description={setupIDPManagerTextConstants.description(provider.idpType.name)} />

      <div className={styles.connectionWrapper}>
        <div>
          <div className={styles.substepWrapper}>
            <SubstepDescription
              description={setupIDPManagerTextConstants.stepADescription(provider.idpType.name)}
              letter="A"
            />

            <CopyToClipboardField
              className={styles.copyToClipboard}
              value={SP_SSO_URL}
              label="sp sso url"
              infoText={setupIDPManagerTextConstants.spUrlInfoText}
            />

            <CopyToClipboardField
              className={styles.copyToClipboard}
              value={provider?.serviceProviderEntityId || SP_ENTITY_ID}
              label="sp entity id"
              infoText={setupIDPManagerTextConstants.spEntityInfoText}
            />

            <div>
              <Collapsed className={styles.dropdown} label="Advanced configuration">
                <Label infoText={setupIDPManagerTextConstants.samlMetadataXMLInfoText}>
                  {setupIDPManagerTextConstants.spMetadataDescription}
                </Label>

                <Button className={styles.xmlDownload} onClick={handleDownloadSPXML} isLoading={isFileDownloading}>
                  <Icon name={IconVariant.DOWNLOAD} />
                </Button>
              </Collapsed>
            </div>
          </div>
        </div>

        <div>
          <div className={styles.substepWrapper}>
            <SubstepDescription
              description={setupIDPManagerTextConstants.stepBDescription(provider.idpType.name)}
              letter="B"
            />

            <Label className={styles.stepBLabel} infoText={setupIDPManagerTextConstants.samlMetadataXMLInfoText}>
              {setupIDPManagerTextConstants.idpMetadataDescription}
            </Label>
            <XMLFileUpload xmlString={xmlString} handleUpload={handleUpload} handleRemoveFile={handleRemoveFile} />

            <Label className={styles.stepBLabel} infoText={setupIDPManagerTextConstants.idpIssuerInfoText}>
              {setupIDPManagerTextConstants.idpIssuerDescription}
            </Label>
            <Input
              value={provider.idpMetaData?.entityId || ''}
              placeholder={setupIDPManagerTextConstants.autoPopulatedText}
              readOnly
              disabled
            />

            <Label className={styles.stepBLabel} infoText={setupIDPManagerTextConstants.idpSsoUrlInfoText}>
              {setupIDPManagerTextConstants.idpSsoUrlDescription}
            </Label>
            <Input
              value={provider.idpMetaData?.ssoUrl || ''}
              placeholder={setupIDPManagerTextConstants.autoPopulatedText}
              readOnly
              disabled
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
