import React, { useCallback, useEffect, useMemo } from 'react';

import cx from 'classnames';

import { AnalyticsService } from '@writercolab/analytics';
import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Slideout, Text, TextSize } from '@writercolab/ui-atoms';
import { TabList } from '@writercolab/ui-molecules';

import type { TPrompt, TTeamPromptEditActionDto } from '@web/types';
import { TPromptsLibraryQueryParams, TPromptsLibraryTabsId } from '@web/types';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import PromptLibraryLogo from '../../../assets/backgrounds/promptLibrary.svg?react';
import { PromptLibraryEventSources } from '../../../constants/PromptLibrary';
import useQuery from '../../../hooks/useQuery';
import { GoBackArrow } from '../../generic/GoBackArrow';
import { PromptLibraryTeam } from '../PromptLibraryTeam';
import { PromptLibraryUser } from '../PromptLibraryUser';
import { TeamPromptPanel } from '../TeamPromptPanel';
import type { PromptLibraryUIModel } from './PromptLibraryModel.ui';

import styles from './PromptLibrary.module.css';
import { AnalyticsActivity } from 'constants/analytics';

interface IPromptLibraryProps {
  model: PromptLibraryUIModel;
  invocationContext: PromptLibraryEventSources;
  teamName?: string;
  emptyStateAvailable?: boolean;
  showContactText?: boolean;
  showAddTeamPrompts?: boolean;
  onUsePrompt?: (prompt: string) => void;
  onCopyToClipboard: (message: string) => void;
  onManageTeamPromptsClick?: () => void;
  onBackClick?: () => void;
}

export const PromptLibrary = observer<IPromptLibraryProps>(
  ({
    model,
    invocationContext,
    teamName,
    emptyStateAvailable,
    showContactText,
    showAddTeamPrompts = true,
    onCopyToClipboard,
    onUsePrompt,
    onManageTeamPromptsClick,
    onBackClick,
  }) => {
    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
      const tabQueryParam = query.get(TPromptsLibraryQueryParams.enum.plTab);

      TPromptsLibraryTabsId.matchPartial(
        tabQueryParam,
        {
          [TPromptsLibraryTabsId.enum.team]: () => model.setActiveTab(TPromptsLibraryTabsId.enum.team),
          [TPromptsLibraryTabsId.enum.user]: () => model.setActiveTab(TPromptsLibraryTabsId.enum.user),
        },
        null,
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model]);

    const onUsePromptFn =
      invocationContext === PromptLibraryEventSources.ASK_WRITER
        ? (prompt: string) => {
            model.analyticsService.track(AnalyticsActivity.insertedTeamPrompt, {});
            onUsePrompt?.(prompt);
          }
        : undefined;
    const onAddTeamPrompt = async (teamPromptActionDto: TTeamPromptEditActionDto) => {
      await model.addTeamPrompt(teamPromptActionDto);
      model.setCreateTeamPromptVisible(false);
    };
    const onTeamPromptCreateClick = showAddTeamPrompts
      ? (prompt: TPrompt) => model.setCreateTeamPromptVisible(true, prompt)
      : undefined;
    const handleClosePanel = useCallback(() => model.setCreateTeamPromptVisible(false), [model]);
    const showTeamPromptsManagement =
      !model.promptLibraryTeamUIModel.loading && !model.promptLibraryTeamUIModel.empty && onManageTeamPromptsClick;
    const handleSwitchTab = (tab: string) => {
      model.setActiveTab(TPromptsLibraryTabsId.get(tab, TPromptsLibraryTabsId.enum.team));
      query.set(TPromptsLibraryQueryParams.enum.plTab, tab);
      navigate({ search: query.toString() });
    };
    const handleTeamPromptCopy = (prompt: string) => {
      model.analyticsService.track(AnalyticsActivity.copiedTeamPrompt, {});
      onCopyToClipboard(prompt);
    };

    const renderUserPromptsLibrary = useMemo(() => {
      return (
        <PromptLibraryUser
          model={model.promptLibraryUserUIModel}
          onUsePrompt={onUsePromptFn}
          onCopyToClipboard={onCopyToClipboard}
          invocationContext={invocationContext}
          onTeamPromptCreateClick={onTeamPromptCreateClick}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model.promptLibraryUserUIModel]);

    const renderTeamPromptsLibrary = useMemo(() => {
      return (
        <PromptLibraryTeam
          emptyStateAvailable={emptyStateAvailable}
          onCreate={() => model.setCreateTeamPromptVisible(true)}
          model={model.promptLibraryTeamUIModel}
          teamName={teamName}
          onUsePrompt={onUsePromptFn}
          onCopyToClipboard={handleTeamPromptCopy}
          showContactText={showContactText}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emptyStateAvailable, model, showContactText, teamName]);

    return (
      <div className={styles.container}>
        {onBackClick && (
          <div className={styles.backButtonContainer}>
            <GoBackArrow onGoBackClick={onBackClick} />
          </div>
        )}
        <div className={styles.heading}>
          <PromptLibraryLogo />
        </div>
        {showTeamPromptsManagement && (
          <div className={styles.manageTeamLinkWrapper}>
            <div className={cx(styles.manageTeamLink, styles.clickable)} onClick={onManageTeamPromptsClick}>
              <Text variant={TextSize.M}>Manage prompts</Text>
              <Button
                type={ButtonTypes.GRAY}
                icon={<Icon name={IconVariant.VOICE_SETUP} width={18} height={18} />}
                round
              />
            </div>
            <div>
              <Button
                type={ButtonTypes.PRIMARY}
                className={styles.newButton}
                size={SizeTypes.MEDIUM}
                icon={<Icon name={IconVariant.ADD} />}
                onClick={() => model.setCreateTeamPromptVisible(true)}
                content="New"
              />
            </div>
          </div>
        )}
        <TabList
          border={false}
          className={styles.tabs}
          activeTab={model.activeTab}
          onChange={handleSwitchTab}
          tabs={[
            {
              id: TPromptsLibraryTabsId.enum.team,
              label: 'Team prompts',
              // @ts-expect-error TODO: fix this
              customLabel: (
                <Text variant={TextSize.XXXL} medium={model.activeTab === TPromptsLibraryTabsId.enum.team}>
                  Team prompts
                </Text>
              ),
              content: renderTeamPromptsLibrary,
            },
            {
              id: TPromptsLibraryTabsId.enum.user,
              label: 'Writer prompts',
              // @ts-expect-error TODO: fix this
              customLabel: (
                <Text variant={TextSize.XXXL} medium={model.activeTab === TPromptsLibraryTabsId.enum.user}>
                  Writer prompts
                </Text>
              ),
              content: renderUserPromptsLibrary,
            },
          ]}
        />
        <Slideout isOpen={model.createTeamPromptVisible} onClose={handleClosePanel} className={styles.slideout}>
          <TeamPromptPanel
            teamPromptsApiModel={model.teamPromptsApiModel}
            teamPromptFormUiModel={() => model.teamPromptFormUIModel}
            onPromptActionClick={onAddTeamPrompt}
            loading={model.addingTeamPrompt}
          />
        </Slideout>
      </div>
    );
  },
);
