import type { IUserProfile } from '@writercolab/common-utils';

export const DEFAULT_USER_ORG_NAME = 'Default Organization';

export interface IDiscoverOrganizationsResponse {
  joinRequiresApproval: boolean;
  organizationId: number;
  organizationName: string;
}

export interface ICreateOrganizationResponse {
  value: number;
}

export interface IOrganizationAdminsResponse {
  users: IUserProfile[];
}
