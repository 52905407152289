import React, { ChangeEvent, DragEvent, useCallback, useMemo, useRef, useState } from 'react';

import cn from 'classnames';

import { SortOrders, joinFileExtensions } from '@writercolab/common-utils';
import {
  ColorDot,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { DropdownHeader, PerPageOption } from '@writercolab/ui-molecules';

import { Spinner } from 'components/molecules/Spinner';

import {
  KG_SUPPORTED_FILE_EXTENSIONS,
  KG_VISIBLE_FILE_EXTENSIONS,
  KnowledgeGraphFilesSortField,
  KnowledgeGraphFilesSortOrder,
  KnowledgeGraphFilesStatus,
  KnowledgeGraphFilesViewType,
} from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { DndArea } from '../../molecules/DndArea';
import KnowledgeGraphListPaginator from '../../molecules/KnowledgeGraphListPaginator';
import { BaseTable } from '../BaseTable/BaseTable';
import { KnowledgeGraphFilesTableUIModel } from './KnowledgeGraphFilesTableModel.ui';
import { KnowledgeGraphFilesModalsContainer } from './modals/KnowledgeGraphFilesModalsContainer';
import { KnowledgeGraphFilesTableColumnsConfig, generateTableBody } from './utils';

import styles from './KnowledgeGraphFilesTable.module.css';

interface IKnowledgeGraphFilesTableProps {
  isSearchParamsEmpty: boolean;
  model: KnowledgeGraphFilesTableUIModel;
  sortOrder: string;
  sortField: string;
  status: string[];
  viewType: typeof KnowledgeGraphFilesViewType.type;
  viewOnly: boolean;
  handleStatusFilterChange: (status: string) => void;
  handleSortingChange: (id: string | number) => void;
  onFileSelect: (files: FileList) => void;
  onFileUpload: (files: FileList) => void;
  onFileSizeError: (fileName: string) => void;
  onFileTypeError: (fileName: string) => void;
  limit: number;
  offset: number;
  handlePaginationChange: (event: unknown, number: number) => void;
  handleOffsetChange: (option: PerPageOption) => void;
  graphId: string;
  connectorName?: string;
}

const AddedBySortingDropdown = ({
  onPrimaryOptionClickAction,
  sortField,
  sortOrder,
}: {
  onPrimaryOptionClickAction: (id: string | number) => void;
  sortField: string;
  sortOrder: string;
}) => (
  <DropdownHeader
    triggerText={
      <Text color={TextColor.GREY2} variant={TextSize.L}>
        Date added
      </Text>
    }
    options={[
      {
        id: KnowledgeGraphFilesSortOrder.enum.asc,
        name: 'Sort Ascending',
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active:
          sortField === KnowledgeGraphFilesSortField.enum.creationTime &&
          sortOrder === KnowledgeGraphFilesSortOrder.enum.asc,
      },
      {
        id: KnowledgeGraphFilesSortOrder.enum.desc,
        name: 'Sort Descending',
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active:
          sortField === KnowledgeGraphFilesSortField.enum.creationTime &&
          sortOrder === KnowledgeGraphFilesSortOrder.enum.desc,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === KnowledgeGraphFilesSortField.enum.creationTime}
    sortDirection={sortOrder as SortOrders}
    dropdownPlacement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

const UserStatusSortingDropdown = ({
  filterByStatus,
  userFilterValue,
}: {
  filterByStatus: (id: string) => void;
  userFilterValue?: string[];
}) => (
  <div className={styles.statusFilterWrapper}>
    <Text variant={TextSize.L} color={TextColor.GREY2}>
      Status
    </Text>
    <Dropdown
      containerClassName={cn(styles.filterDropdown, {
        [styles.filterDropdownActive]: !isEmpty(userFilterValue),
      })}
      trigger={<Icon name={IconVariant.FILTER} width={15} height={15} className={styles.filtersIcon} />}
      itemsType={ItemsTypes.SELECT}
      options={[
        {
          id: KnowledgeGraphFilesStatus.enum.parsing,
          name: 'Uploading',
          active: userFilterValue?.includes(KnowledgeGraphFilesStatus.enum.parsing),
          icon: <ColorDot color="orange" size={12} />,
        },
        {
          id: KnowledgeGraphFilesStatus.enum.indexing,
          name: 'Indexing',
          active: userFilterValue?.includes(KnowledgeGraphFilesStatus.enum.indexing),
          icon: (
            <Spinner
              progress={75}
              size={12}
              circleWidth={3}
              progressWidth={3}
              circleColor="#D4D6DB"
              progressColor="#000000"
            />
          ),
        },
        {
          id: KnowledgeGraphFilesStatus.enum.completed,
          name: 'Live in graph',
          active: userFilterValue?.includes(KnowledgeGraphFilesStatus.enum.completed),
          icon: <ColorDot color="#5EEBC1" size={12} />,
        },
        {
          id: KnowledgeGraphFilesStatus.enum.error,
          name: 'Errored',
          active: userFilterValue?.includes(KnowledgeGraphFilesStatus.enum.error),
          icon: <ColorDot color="#FF8866" size={12} />,
        },
      ]}
      hideOnCLick={false}
      useCheckbox
      onPrimaryOptionClickAction={filterByStatus}
      placement={DropdownPlacement.BOTTOM_RIGHT}
      dropdownItemClassName={styles.dropdownItem}
    />
  </div>
);

const fileExtensionsJoinedList = () => KG_SUPPORTED_FILE_EXTENSIONS.slice(0, KG_VISIBLE_FILE_EXTENSIONS).join(', ');

export const KnowledgeGraphFilesTable = observer(
  ({
    model,
    status,
    sortOrder,
    sortField,
    viewType,
    viewOnly,
    handleStatusFilterChange,
    handleSortingChange,
    onFileSizeError,
    onFileTypeError,
    onFileSelect,
    onFileUpload,
    isSearchParamsEmpty,
    limit,
    offset,
    handlePaginationChange,
    handleOffsetChange,
    graphId,
    connectorName,
  }: IKnowledgeGraphFilesTableProps) => {
    const _inputFileRef = useRef<HTMLInputElement>(null);
    const [isDropzoneActive, setIsDropzoneActive] = useState(false);
    const _preventDefaults = (e: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const _handleDrop = (e: DragEvent<HTMLDivElement>) => {
      _preventDefaults(e);
      setIsDropzoneActive(false);
    };
    const _handleDragOver = (e: DragEvent<HTMLDivElement>) => {
      _preventDefaults(e);
      setIsDropzoneActive(true);
    };
    const _onDragLeaveDropzone = (e: DragEvent<HTMLDivElement>) => {
      _preventDefaults(e);
      setIsDropzoneActive(false);
    };
    const _handleSelectFileClick = useCallback(() => _inputFileRef?.current?.click(), [_inputFileRef]);
    const _handleSelectFile = (e: ChangeEvent<HTMLInputElement>): void => {
      _preventDefaults(e);

      if (e.target?.files?.length) {
        onFileSelect(e.target.files);
      }
    };

    const actions = useMemo(
      () => ({
        downloadFile: model.handleDownloadFile,
        viewFileInConnector: model.handleViewFileInConnector,
        retry: model.onRetry,
      }),
      [model.handleDownloadFile, model.handleViewFileInConnector, model.onRetry],
    );

    const tableData = useMemo(
      () =>
        generateTableBody({
          data: model.data,
          columnsConfig: KnowledgeGraphFilesTableColumnsConfig,
          modalsManager: model.modalsManager,
          actions,
          viewType,
          viewOnly,
          graphId,
          connectorName,
        }),
      [actions, graphId, connectorName, model.data, model.modalsManager, viewOnly, viewType],
    );

    const tableHeaders = [
      {
        component: (
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            Name
          </Text>
        ),
      },
      {
        component: (
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            Type
          </Text>
        ),
      },
      {
        component: <UserStatusSortingDropdown filterByStatus={handleStatusFilterChange} userFilterValue={status} />,
      },
      {
        component: (
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            Added by
          </Text>
        ),
      },
      {
        component: (
          <AddedBySortingDropdown
            onPrimaryOptionClickAction={handleSortingChange}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        ),
      },
      {},
    ];

    return (
      <>
        <input
          type="file"
          id="knowledge-graph-table-input-upload"
          ref={_inputFileRef}
          className={styles.fileSelectInput}
          onChange={_handleSelectFile}
          accept={joinFileExtensions(KG_SUPPORTED_FILE_EXTENSIONS)}
          multiple
        />
        {model.data.length === 0 && isSearchParamsEmpty ? (
          <div className={styles.banner}>
            {!viewOnly && !connectorName && (
              <>
                Set up your graph by <span onClick={_handleSelectFileClick}>adding files</span>
              </>
            )}
          </div>
        ) : (
          <div
            className={styles.tableScrollableContainer}
            onDragOver={_handleDragOver}
            onDragLeave={_preventDefaults}
            onDrop={_handleDrop}
          >
            {isDropzoneActive && (
              <DndArea
                className={styles.dropzoneContainer}
                onFileUpload={onFileUpload}
                onFileSizeError={onFileSizeError}
                onFileTypeError={onFileTypeError}
                onDragLeaveDropzone={_onDragLeaveDropzone}
                title="Drag & drop your file"
                description={`e.g., ${fileExtensionsJoinedList()}, and more`}
              />
            )}
            <BaseTable
              headers={tableHeaders}
              data={tableData}
              loading={model.isLoading}
              gridTemplateColumns="minmax(320px,1fr) minmax(60px,1fr) minmax(180px,1fr) minmax(150px,1fr) minmax(170px,1fr) minmax(30px,1fr)"
              isEnableSticky={false}
            />
            <KnowledgeGraphListPaginator
              handleFilesPaginationChange={handlePaginationChange}
              handleOffsetChange={handleOffsetChange}
              limit={limit}
              offset={offset}
              totalCount={model.totalCount}
            />
          </div>
        )}

        {model.data.length !== 0 && <KnowledgeGraphFilesModalsContainer model={model} />}
      </>
    );
  },
);
