import { Request } from '@writercolab/common-utils';

import { IExtensionApplicationData, IInitExtensionApplicationData, IInitExtensionRequestParams } from '@web/types';
import { AxiosResponse } from 'axios';

import { getLogger } from '../../utils/logger';

const LOG = getLogger('extensions');

export const getApplicationData = async (clientId: string) => {
  let data: IExtensionApplicationData | null = null;
  try {
    const res = await Request.getAxiosInstance().get<IExtensionApplicationData>(`/auth/app`, {
      params: { client_id: clientId },
    });
    data = res.data;
  } catch (e: any) {
    LOG.error(e.message);
    throw e;
  }

  return data;
};

export const initApplicationData = async (
  params: IInitExtensionRequestParams,
): Promise<IInitExtensionApplicationData | null> => {
  let data: IInitExtensionApplicationData | null = null;
  try {
    const res = await Request.getAxiosInstance().post<
      IInitExtensionApplicationData,
      AxiosResponse<IInitExtensionApplicationData>
    >(`/auth/app/init`, {
      client_id: params.clientId,
      code_challenge: params.codeChallenge,
      code_challenge_method: params.codeChallengeMethod,
      write_key: params.writeKey,
      redirect_url: params.redirectUrl,
    });
    data = res.data;
  } catch (e: any) {
    LOG.error(e.message);
    throw e;
  }

  return data;
};

export const implicitlyRedirectApplication = async (
  redirectUrl: string,
): Promise<{ success: boolean; message: string }> => {
  try {
    const response = await fetch(redirectUrl);
    const json = await response.json();

    if (!response.ok) {
      throw new Error(json.message);
    }

    return json;
  } catch (e: any) {
    LOG.error(e.message);
    throw e;
  }
};
