import { loadScript } from '@writercolab/common-utils';
import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import { BlockEmbed } from 'quill';
import {
  HeightStyleAttributor,
  WidthStyleAttributor,
  MarginStyleAttributor,
  ClipMaxResolutionAttributor,
  PlaybackIdAttributor,
  ClipLabelAttributor,
  StreamTypeAttributor,
} from '../attributors';

const attributors = {
  [QUILL_FORMAT.WIDTH]: WidthStyleAttributor,
  [QUILL_FORMAT.HEIGHT]: HeightStyleAttributor,
  [QUILL_FORMAT.MARGIN]: MarginStyleAttributor,
  [QUILL_FORMAT.RESOLUTION]: ClipMaxResolutionAttributor,
  [QUILL_FORMAT.FILE_NAME]: ClipLabelAttributor,
};

export class VideoFormat extends BlockEmbed {
  static override blotName = 'video';
  static override className = 'ql-video';
  static override tagName = 'section';
  static muxPlayerLoaded = false;
  static muxPlayerCdnUrl = 'https://cdn.jsdelivr.net/npm/@mux/mux-player';

  static override create(value: string) {
    VideoFormat.loadMuxPlayer();

    const node = super.create(value) as HTMLElement;

    PlaybackIdAttributor.add(node, value);

    const player = document.createElement('mux-player');
    StreamTypeAttributor.add(player, 'on-demand');
    PlaybackIdAttributor.add(player, value);

    node.appendChild(player);

    return node;
  }

  static override formats(domNode: HTMLElement) {
    return Object.keys(attributors).reduce(
      (res, attr) => {
        const attribute = attr as keyof typeof attributors;

        if (attributors[attribute].value(domNode)) {
          res[attribute] = attributors[attribute].value(domNode);
        }

        return res;
      },
      {} as Record<string, string | null>,
    );
  }

  static override value(domNode: Element) {
    return PlaybackIdAttributor.value(domNode as HTMLElement);
  }

  static getResolution(domNode: HTMLElement) {
    const maxResolution = ClipMaxResolutionAttributor.value(domNode);

    if (!maxResolution || maxResolution === 'SD') {
      return 'low';
    }

    return 'high';
  }

  static getFileName(domNode: HTMLElement) {
    return ClipLabelAttributor.value(domNode) || 'clip';
  }

  static loadMuxPlayer() {
    if (!VideoFormat.muxPlayerLoaded) {
      loadScript(VideoFormat.muxPlayerCdnUrl);
      VideoFormat.muxPlayerLoaded = true;
    }
  }

  override format(aName: string, value: unknown) {
    const name = aName as keyof typeof attributors;

    if (attributors[name]) {
      if (value) {
        attributors[name].add(this.domNode, value as string);
      } else {
        attributors[name].remove(this.domNode);
      }
    } else {
      super.format(name, value);
    }
  }

  getVideoUrl(isDownload = false) {
    const { video } = this.value();
    const resolution = VideoFormat.getResolution(this.domNode);
    const fileName = VideoFormat.getFileName(this.domNode);

    return VideoFormat.generateMuxUrl({ playbackId: video, resolution, fileName, isDownload });
  }

  static generateMuxUrl({
    playbackId,
    resolution = 'low',
    fileName = 'video',
    isDownload = false,
  }: {
    playbackId: string;
    resolution?: 'low' | 'high';
    fileName?: string;
    isDownload?: boolean;
  }) {
    return `https://stream.mux.com/${playbackId}/${resolution}.mp4${isDownload ? `?download=${fileName}` : ''} `;
  }

  html() {
    return `<a href="${this.getVideoUrl()}">${VideoFormat.getFileName(this.domNode)}</a>`;
  }
}
