import React from 'react';

import { observer } from 'mobx-react-lite';

import { KnowledgeGraphRemoveModal } from '../../KnowledgeGraphRemoveModal';
import { KnowledgeGraphFilesAction, KnowledgeGraphFilesTableUIModel } from '../KnowledgeGraphFilesTableModel.ui';

interface IKnowledgeGraphFilesModalsContainerProps {
  model: KnowledgeGraphFilesTableUIModel;
}

export const KnowledgeGraphFilesModalsContainer = observer(({ model }: IKnowledgeGraphFilesModalsContainerProps) => (
  <>
    <KnowledgeGraphRemoveModal
      isOpen={model.modalsManager.isModalVisible(KnowledgeGraphFilesAction.enum.delete)}
      onClose={() => model.modalsManager.hideModal()}
      onSubmit={model.handleDelete}
    />
  </>
));
