import { Request } from '@writercolab/common-utils';

interface PortalSettings {
  result?: {
    createdAt: string;
    customCss: string;
    favicon: string;
    fontColor: string;
    fontName: string;
    hero: string;
    id: number;
    indexing: boolean;
    linkColor: string;
    logo: string;
    name: string;
    onboardingComplete: true;
    organizationId: number;
    previewCode: string;
    seoDescription: string;
    seoKeyword: string;
    status: 'live' | 'offline';
    subDomain: string;
    customDomain: string;
    updatedAt: string;
    updatedBy: number;
  };
}

export const getPortalSettings = async (orgId: number | undefined): Promise<PortalSettings> => {
  const { data } = await Request.getAxiosInstance().get<PortalSettings>(`/portal/organizations/${orgId}/settings`);

  return data;
};
