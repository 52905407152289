import React, { ReactNode } from 'react';

import cx from 'classnames';

import { MfaChallengeType } from '@writercolab/types';
import { IconVariant, Switcher, Text, TextSize } from '@writercolab/ui-atoms';
import { UtilIcon, UtilIconColor } from '@writercolab/ui-molecules';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import styles from './styles.module.css';

interface IMfaChallengeCardProps {
  children?: ReactNode;
  id: MfaChallengeType;
  label: string;
  onSwitch: (id: MfaChallengeType) => void;
  checked: boolean;
  disabled?: boolean;
}

export const MfaChallengeCard: React.FC<IMfaChallengeCardProps> = ({
  id,
  label,
  onSwitch,
  checked,
  disabled,
  children,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className={styles.mfaMethod} ref={setNodeRef} style={style}>
      <UtilIcon
        variant={IconVariant.DRAG}
        width={20}
        height={20}
        className={cx(styles.headlineSectionContainerAction, styles.clickable)}
        tooltipContent="Hold and drag to reorder"
        color={UtilIconColor.WHITE}
        {...attributes}
        {...listeners}
      />
      <Text className={styles.text} variant={TextSize.L}>
        {label}
      </Text>
      <div className={styles.body}>{children}</div>
      <Switcher
        onChange={() => onSwitch(id)}
        id={`mfa-switcher-${id}`}
        size={20}
        checked={checked}
        disabled={disabled}
      />
    </div>
  );
};
