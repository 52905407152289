import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Icon, IconVariant } from '@writercolab/ui-atoms';

import { TermsClipMode } from '../../../context/termsContext';
import { DOWNLOAD_TYPE, headerTooltipText } from './common';

export function useTermsHeaderActions(clipMode, handleSearch, handleToggleModal) {
  const [tooltipContent, setTooltipContent] = useState(headerTooltipText.clipModeBtn);
  const onChangeSearchEvent = useCallback(e => handleSearch(e.target.value), [handleSearch]);
  const handleClearInput = useCallback(() => handleSearch(''), [handleSearch]);
  const handleDeleteButtonClick = useCallback(() => handleToggleModal(true), [handleToggleModal]);
  const clipModeDropdownOptions = useMemo(
    () => [
      {
        name: 'Clip text',
        id: TermsClipMode.INLINE,
        icon: <Icon name={IconVariant.CLIP_MODE} />,
        active: clipMode === TermsClipMode.INLINE,
      },
      {
        name: 'Wrap text',
        id: TermsClipMode.WRAP_TEXT,
        icon: <Icon name={IconVariant.WRAP_MODE} />,
        active: clipMode === TermsClipMode.WRAP_TEXT,
      },
    ],
    [clipMode],
  );
  const downloadDropdownOptions = useMemo(
    () => [
      {
        name: 'Download as csv',
        id: DOWNLOAD_TYPE.CSV,
        icon: <Icon name={IconVariant.SPREADSHEET} width={18} height={18} />,
      },
      {
        name: 'Download as xlsx',
        id: DOWNLOAD_TYPE.XLSX,
        icon: <Icon name={IconVariant.SPREADSHEET} width={18} height={18} />,
      },
    ],
    [],
  );

  useEffect(() => {
    const content = clipMode === TermsClipMode.INLINE ? headerTooltipText.clipModeBtn : headerTooltipText.wrapModeBtn;
    setTooltipContent(content);
  }, [clipMode]);

  return {
    tooltipContent,
    onChangeSearchEvent,
    handleClearInput,
    handleDeleteButtonClick,
    clipModeDropdownOptions,
    downloadDropdownOptions,
  };
}
