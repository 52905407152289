import {
  ContentGenerationJobStatus,
  type TUserNotification,
  type TJobResponse,
} from '@writercolab/types';

// Check if the document ID is a webapp document ID, it should be an integer
// Desktop app document IDs are UUIDs
export function isWebAppDocumentId(documentId: string | number) {
  return Number.isInteger(Number(documentId));
}

// Filters notifications that are less than 24 hours old and are related to webapp document IDs
export function filterRecentWebappNotifications(
  n: Pick<TUserNotification['data'], 'creationTime' | 'documentId'>,
) {
  const startDateTime = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  return (
    n.creationTime > startDateTime.toISOString() &&
    isWebAppDocumentId(n.documentId)
  );
}

export function getRecentWebappNotificationsSummary(
  notifications: Pick<TJobResponse, 'status'>[],
) {
  const summary = {
    [ContentGenerationJobStatus.enum.completed]: 0,
    [ContentGenerationJobStatus.enum.error]: 0,
    [ContentGenerationJobStatus.enum.transcript]: 0,
    [ContentGenerationJobStatus.enum.generating]: 0,
  };

  notifications.forEach((job) => {
    if (summary[job.status]) {
      summary[job.status] += 1;
    } else {
      summary[job.status] = 1;
    }
  });

  return summary;
}
