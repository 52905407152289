import { action, computed, makeObservable, observable } from 'mobx';
import { downloadBlobAsFilename, openNewTab } from '@writercolab/common-utils';
import { ReactiveQueue } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import type { TNotificationQueueItem } from '@writercolab/types';
import { NotificationQueueItemType } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import type {  TKnowledgeGraphFilesResponse } from '@web/types';
import { KG_MAX_FILE_SIZE } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import type { KnowledgeGraphsApiModel } from 'models/knowledgeGraphs.api';

import { extractBackendResponseErrorMessage } from '../../../utils/backendErrorUtils';
import { getLogger } from '../../../utils/logger';
import { isBadRequest } from '../../../utils/requestUtils';

export const KnowledgeGraphFilesAction = new Enum('delete');

const KG_MESSAGES = {
  UPLOAD_FILE_SUCCESS: 'File(s) has been uploaded',
  UPLOAD_FILE_ERROR: 'There was an error with your file upload',
  DOWNLOAD_FILE_SUCCESS: 'Your download is in progress...',
  FILE_DOWNLOAD_ERROR: 'File download failed. Please try again later',
  STANDARD_ERROR: 'Something went wrong. Please try again later',
  FILE_SIZE_BIG: `We're unable to upload file because it's over the max file size of ${KG_MAX_FILE_SIZE}MB.`,
  FILE_SIZE_NOT_SUPPORTED: 'File type is not supported',
};
interface KnowledgeGraphFilesTableUIModelOptions {
  api: KnowledgeGraphsApiModel;
  teamId?: number;
  analyticsService: IWebAppAnalyticsTrack;
  aiStudioMode: boolean;
}

const LOG = getLogger('KnowledgeGraphFilesTableUIModel');

export type TKnowledgeGraphFilesModalsManager = ModalsManager<typeof KnowledgeGraphFilesAction.type, string>;

export class KnowledgeGraphFilesTableUIModel {
  api: KnowledgeGraphsApiModel;
  modalsManager: TKnowledgeGraphFilesModalsManager;
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  uploadsInProgress = 0;

  constructor(private opts: KnowledgeGraphFilesTableUIModelOptions) {
    this.api = this.opts.api;
    this.modalsManager = new ModalsManager<typeof KnowledgeGraphFilesAction.type, string>();
    this.notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

    makeObservable(this, {
      uploadsInProgress: observable,
      handleUploadFiles: action,
      currentActiveEntityId: computed,
      selectedFile: computed,
      isLoading: computed,
      hasNext: computed,
      data: computed,
      totalCount: computed,
    });
  }

  get currentActiveEntityId(): string | null {
    return this.modalsManager.getArrayModalState()[0] ?? null;
  }

  onDelete = async (fileId: string) => {
    try {
      await this.api.deleteGraphFile({
        fileId,
      });
      await this.api.refreshPagination();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.delete,
        message: `Your file was deleted`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Your file was not deleted`,
      });
    }
  };

  onRetry = async (fileIds: [string, ...string[]], graphId: string) => {
    try {
      await this.api.retryFiles({
        fileIds,
        graphId,
      });
      await this.api.refreshPagination();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.delete,
        message: `Your file was re-indexed`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Your file was not re-indexed`,
      });
    }
  };

  handleDelete = () => {
    const id = this.modalsManager.getModalState(KnowledgeGraphFilesAction.enum.delete);

    if (!id) {
      return;
    }

    this.onDelete(id);
  };

  handleUploadFiles = async ({ files, knowledgeGraphId }: { files: FileList; knowledgeGraphId: string }) => {
    try {
      this.uploadsInProgress = files.length;
      await Promise.all(
        Array.from(files).map(file =>
          this.api.uploadGraphFile({
            file,
            params: {
              knowledgeGraphId,
              teamId: this.opts.teamId,
            },
          }),
        ),
      );
      await this.api.refreshPagination();
      this.opts.analyticsService.track(
        this.opts.aiStudioMode
          ? AnalyticsActivity.aiStudioKnowledgeGraphUploadedFiles
          : AnalyticsActivity.knowledgeGraphUploadedFiles,
        {},
      );
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: KG_MESSAGES.UPLOAD_FILE_SUCCESS,
      });
    } catch (e) {
      if (isBadRequest(e)) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: extractBackendResponseErrorMessage(e),
        });
      } else {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: KG_MESSAGES.UPLOAD_FILE_ERROR,
        });
      }
    } finally {
      this.uploadsInProgress = 0;
    }
  };

  handleDownloadFile = async (fileId: string, fileName: string) => {
    try {
      const blob = await this.api.downloadGraphFile({
        fileId,
      });
      downloadBlobAsFilename(blob, fileName);
    } catch (e) {
      LOG.error(e);
    }
  };

  handleViewFileInConnector = async (fileId: string) => {
    try {
      const url = await this.api.getGraphFileSignedUrl({
        fileId,
      });
      openNewTab(url);
    } catch (e) {
      LOG.error(e);
    }
  };

  onFileSizeError = () => {
    this.notificationQueue.enqueue({
      type: NotificationQueueItemType.enum.error,
      message: KG_MESSAGES.FILE_SIZE_BIG,
    });
  };

  onFileTypeError = () => {
    this.notificationQueue.enqueue({
      type: NotificationQueueItemType.enum.error,
      message: KG_MESSAGES.FILE_SIZE_NOT_SUPPORTED,
    });
  };

  get selectedFile(): TKnowledgeGraphFilesResponse | undefined {
    return this.data.find(file => file.fileId === this.currentActiveEntityId);
  }

  get isLoading(): boolean {
    return this.api.isLoading;
  }

  get hasNext(): boolean {
    return this.api.pagination.hasNext ?? false;
  }

  get data(): TKnowledgeGraphFilesResponse[] {
    return this.api.filesData ?? [];
  }

  get totalCount() {
    return this.api.graphsFileCount;
  }
}
