import { computed, makeObservable, observable, runInAction } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import type { DateTime, RequestServiceInitialize, components } from '@writercolab/network';

import { getLogger } from 'utils/logger';
import { isResourceNotFound } from 'utils/requestUtils';

const LOG = getLogger('contentHistoryModel');

export type TContentHistoryPreview = components['schemas']['content_model_ContentDataHistoryRichPreviewEntry'];
export type TContentHistoryEntry = components['schemas']['content_dto_GetContentDataResponse'];

export interface ContentHistoryApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number;
  documentId: string;
}

export class ContentHistoryApiModel {
  error: null | Error = null;
  constructor(private opts: ContentHistoryApiModelOptions) {
    makeObservable(this, {
      historyPreview: computed.struct,
      isLoadingHistoryPreview: computed,
      error: observable,
    });
  }

  private $historyPreview = new PromisedModel({
    name: '$historyPreview',
    load: async () => {
      let historyPreview: TContentHistoryPreview[] | null = null;
      runInAction(() => {
        this.error = null;
      });

      try {
        const { data } = await this.opts.request.get(
          '/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/history',
          {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                teamId: this.opts.teamId,
                documentId: this.opts.documentId,
              },
            },
          },
        );

        historyPreview = data.value;
      } catch (e: unknown) {
        if (isResourceNotFound(e)) {
          LOG.info('Content history not found');
        } else {
          LOG.error('Failed to fetch content history data', e);
          runInAction(() => {
            this.error = e as Error;
          });
        }
      }

      return historyPreview;
    },
  });

  getHistoryEntry = async (modificationTime: DateTime): Promise<TContentHistoryEntry> => {
    const { data } = await this.opts.request.get(
      '/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/history/{modificationTime}',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            teamId: this.opts.teamId,
            documentId: this.opts.documentId,
            modificationTime,
          },
        },
      },
    );

    return data;
  };

  refetchHistoryPreview = async () => {
    this.$historyPreview.reload();
    await this.$historyPreview.promise;
  };

  get isLoadingHistoryPreview() {
    return this.$historyPreview.status === 'pending';
  }

  get historyPreview(): TContentHistoryPreview[] | null {
    return this.$historyPreview.value || null;
  }
}
