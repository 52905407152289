import React from 'react';

import cx from 'classnames';

import { IUserProfile, stopEventPropagation } from '@writercolab/common-utils';
import { AvatarSize, Icon, IconVariant, Text, TextSize, UserAvatar } from '@writercolab/ui-atoms';
import { CopyToClipboardField } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';

import styles from '../styles.module.css';

export enum ServiceAccountActionType {
  VIEW = 'view',
  COPY = 'copy',
  RELOAD = 'reload',
}

interface IListItem {
  id: number;
  profile: IUserProfile;
  open: boolean;
  loading: boolean;
  token: string;
}

interface IServiceAccountListItemProps {
  item: IListItem;
  handleClickOnOpenListItemButton: (e: React.MouseEvent<HTMLElement>, id: number) => void;
  handleClickOnAccountRemoveButton: (e: React.MouseEvent<HTMLElement>, profile: IUserProfile) => void;
  handleIssueServiceAccountToken: (profileId: number, action: ServiceAccountActionType) => void;
}

const UserName = ({ userProfile }) => (
  <Text variant={TextSize.L}>{!isEmpty(userProfile.fullName) ? userProfile.fullName : userProfile.firstName}</Text>
);

export const ServiceAccountListItem: React.FC<IServiceAccountListItemProps> = ({
  item,
  handleClickOnOpenListItemButton,
  handleIssueServiceAccountToken,
  handleClickOnAccountRemoveButton,
}) => (
  <div
    className={cx(styles.serviceAccountListItem, {
      [styles.serviceAccountListItemOpen]: item.open,
    })}
    key={item.profile.id}
    onClick={e => handleClickOnOpenListItemButton(e, item.id)}
  >
    <div className={cx(styles.serviceAccountListItemPrimary, styles.clickable)}>
      <UserAvatar
        fullName={item.profile.firstName}
        avatarPath={!isEmpty(item.profile.avatar) ? item.profile.avatar : undefined}
        size={AvatarSize.L}
      />
      <div className={styles.serviceAccountListDescription}>
        <UserName userProfile={item.profile} />
      </div>
      <div className={styles.removeAccountButtonContainer}>
        {item.open && (
          <div
            aria-label="Close"
            className={styles.accountListActionButton}
            onClick={e => handleClickOnAccountRemoveButton(e, item.profile)}
            title="Remove account"
          >
            <Icon name={IconVariant.TRASH} width={16} height={16} />
          </div>
        )}
        <div
          aria-label="Show more"
          className={cx(styles.accountListActionButton, {
            [styles.accountListActionButtonOpen]: item.open,
          })}
          onClick={e => handleClickOnOpenListItemButton(e, item.id)}
          title="Show API token"
        >
          <Icon name={IconVariant.DROP_DOWN_ARROW} width={8} height={8} />
        </div>
      </div>
    </div>
    <div
      className={cx(styles.serviceAccountListItemSecondary, {
        [styles.serviceAccountListItemVisible]: item.open,
      })}
      onClick={stopEventPropagation}
    >
      {item.open && (
        <div className={cx(styles.serviceAccountListItemSecondaryItems)}>
          <CopyToClipboardField
            infoText=""
            value={item.token}
            label="API Token"
            hideContent
            isLoading={item.loading}
            tooltipReloadText="Regenerate your API token. Your previous token will be invalidated"
            tooltipCopyText="Copy to clipboard"
            tooltipViewText="View"
            onClickReload={() => handleIssueServiceAccountToken(item.profile.id, ServiceAccountActionType.RELOAD)}
            onClickCopy={() => handleIssueServiceAccountToken(item.profile.id, ServiceAccountActionType.COPY)}
            onClickView={() => handleIssueServiceAccountToken(item.profile.id, ServiceAccountActionType.VIEW)}
          />
        </div>
      )}
    </div>
    <div className={styles.serviceAccountListItemSeparator} />
  </div>
);
