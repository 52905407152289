import { useEffect } from 'react';

const DISABLED_EXTENSIONS = [
  {
    id: 'jasper',
    querySelectors: ['#jasper-shadow-host'],
  },
  {
    id: 'grammarly',
    querySelectors: [
      'grammarly-button',
      'grammarly-desktop-integration',
      'grammarly-citation-builder',
      'grammarly-extension',
      'grammarly-extension-styles',
      'grammarly-extension-denali-styles',
      'grammarly-onboarding',
      'grammarly-popups',
      'grammarly-mirror',
      'grammarly-ghost',
      'grammarly-underline',
      'grammarly-highlights',
      'grammarly-dictionary-card',
    ],
  },
];

export const useDisableExtensions = (extensions = DISABLED_EXTENSIONS) => {
  useEffect(() => {
    const observer = new MutationObserver(() => {
      extensions.forEach(extension => {
        extension.querySelectors.forEach(selector => {
          document.querySelector(selector)?.remove();
        });
      });
    });

    observer.observe(document, { attributes: false, childList: true, characterData: false, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [extensions]);
};
