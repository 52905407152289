import React from 'react';

import cx from 'classnames';

import {
  BaseComponentsProps,
  convertToEllipsis,
  getTimeOrDateWithYear,
  sanitize,
  stopEventPropagation,
} from '@writercolab/common-utils';
import { Checkbox, Icon, IconVariant, TagColor, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { renderAsTag } from '@writercolab/ui-molecules';

import { IDraft, TDraftTemplateAccessType } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import styles from './styles.module.css';

const MAX_DRAFT_TITLE_LENGTH = 146;

interface IDraftProps extends BaseComponentsProps {
  openId: string;
  draft: IDraft;
  isFirst: boolean;
  selectedDrafts: number[];
  selectDrafts: (id: number) => void;
  onCardClicked: (id: number) => void;
}

export const DraftRowCard = ({
  openId = '',
  draft,
  isFirst,
  selectedDrafts,
  selectDrafts,
  onCardClicked,
}: IDraftProps) => {
  const isSelected = selectedDrafts.includes(draft.id);
  const creationDate = getTimeOrDateWithYear(draft.creationTime);
  const capitalizedFilters = ['Recaps', 'SEO Blog Post'];
  const isCustomTemplate = TDraftTemplateAccessType.enum.public !== draft?.template.access;

  return (
    <div
      className={cx(styles.draftWrapper, {
        [styles.isFirst]: isFirst,
        [styles.isSelected]: isSelected,
        [styles.isOpened]: +openId === draft.id,
      })}
      onClick={() => onCardClicked(draft.id)}
    >
      <div className={styles.name}>
        <div className={styles.icon} onClick={stopEventPropagation}>
          <Icon name={IconVariant.DRAFT} />
          <Checkbox className={styles.checkbox} checked={isSelected} onClick={() => selectDrafts(draft.id)} />
        </div>

        <div className={styles.body}>
          <Text
            variant={TextSize.XL}
            color={TextColor.GREY}
            dangerouslySetInnerHTML={{
              __html: sanitize(convertToEllipsis(draft.title || draft.body, MAX_DRAFT_TITLE_LENGTH)),
            }}
          />
        </div>
      </div>

      <div className={styles.template}>
        <Text variant={TextSize.L} color={TextColor.GREY2}>
          {capitalizedFilters.includes(draft.template.name) ? startCase(draft.template.name) : draft.template.name}
        </Text>
        {draft?.template?.tagIds && (
          <div className={styles.draftTags}>
            {isCustomTemplate &&
              renderAsTag('custom', TagColor.LIGHT_BLUE, `t-custom-${draft.template.name}`, styles.draftTagContainer)}
            {draft.template.tagIds.map((tag, i) => {
              if (isEmpty(tag)) {
                return null;
              }

              return (
                <div key={`tags-${i}`} className={styles.draftTag}>
                  {renderAsTag(tag, TagColor.LIGHT_BLUE, `t-${i}}`, styles.draftTagContainer)}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <div className={styles.created}>
        <Text variant={TextSize.M} color={TextColor.GREY}>
          {creationDate}
        </Text>
      </div>

      <div className={styles.line} />
    </div>
  );
};

export default DraftRowCard;
