import { SharedQueryParam } from '@writercolab/common-utils';

import type { TLearningCenterVideoId } from '@web/types';

import { ROUTE } from '../services/config/routes';

export type RouteParams = Record<string, unknown>;
export type QueryParams = Record<string, string>;

export const substituteRoute = (template: string, params: RouteParams, queryParams?: QueryParams): string => {
  const url = template.replace(/:([a-zA-Z]+)/g, (_, key) => {
    const value = params[key];

    if (value === undefined || value === null) {
      throw new Error(`Missing value for parameter '${key}'`);
    }

    return `${value}`;
  });

  if (queryParams && Object.keys(queryParams).length > 0) {
    const queryString = generateQueryStringFromParams(queryParams);

    return `${url}${queryString}`;
  }

  return url;
};

/**
 * Generates a query string from the specified parameters.
 *
 * @param {Record<string, string>} params - The parameters to include in the query string.
 * @param {boolean} [skipQuestionMark] - Indicates whether to skip the question mark at the beginning of the query string. Default is false.
 * @returns {string} - The generated query string.
 */
export const generateQueryStringFromParams = (params: QueryParams, skipQuestionMark: boolean = false): string => {
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => searchParams.append(key, params[key]));

  return `${skipQuestionMark ? '' : '?'}${searchParams.toString()}`;
};

/**
 * Builds the parameters for a dashboard URL.
 *
 * @param {string} url - The base URL for the dashboard.
 * @param {boolean} [showBoarding] - Whether to show onboarding.
 * @param {boolean} [showTutorial] - Whether to show tutorial.
 * @param {TLearningCenterVideoId.type} [lcVideoRoute] - The route for the LC video.
 * @returns {string} The URL with the built parameters.
 */
export const buildDashboardParams = (
  url: string,
  showBoarding?: boolean,
  showTutorial?: boolean,
  lcVideoRoute?: typeof TLearningCenterVideoId.type,
) => {
  let result = url;

  if (showBoarding) {
    result += `?${SharedQueryParam.ONBOARDING}=true`;
  }

  if (showTutorial) {
    result += `${showBoarding ? '&' : '?'}${SharedQueryParam.TUTORIAL}=true`;
  }

  if (lcVideoRoute) {
    result += `${showBoarding || showTutorial ? '&' : '?'}lcVideoRoute=${lcVideoRoute}`;
  }

  return result;
};

/**
 * Redirects to the learning video page.
 *
 * @param {string} orgId - The organization ID.
 * @param {string} teamId - The team ID.
 * @param {string} lcRoute - The learning center video route.
 * @return {string} - The URL of the learning video page.
 */
export function redirectToLearningVideo(
  orgId: string | number,
  teamId: string | number,
  lcRoute: typeof TLearningCenterVideoId.type,
) {
  if (teamId) {
    return ROUTE.toLearningCenterVideo(orgId, teamId, lcRoute);
  } else {
    return ROUTE.toHome(orgId, teamId, false, false, lcRoute);
  }
}
