import { addMiddleware, getLogger as getLoggerBase } from '@writercolab/utils';

import debug from 'debug';

import { datadogLogMiddleware } from './datadogUtils';

addMiddleware(debug);
addMiddleware(datadogLogMiddleware);

export function getLogger(name: string) {
  return getLoggerBase(name, 'writer-app');
}
