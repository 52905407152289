import type { components } from '@writercolab/network';
import { Inline } from 'quill';
import type { Root } from 'parchment/dist/typings/blot/abstract/blot';
import { QL_SNIPPET_CLASS_PREFIX, QL_SNIPPET_HIGHLIGHT_FORMAT_NAME } from '@writercolab/quill-delta-utils';

export class SnippetFormat extends Inline {
  static override tagName = 'span';
  static override blotName = QL_SNIPPET_HIGHLIGHT_FORMAT_NAME;
  static override className = QL_SNIPPET_CLASS_PREFIX;
  snippet: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'] | undefined;

  constructor(
    scroll: Root,
    domNode: Node,
    snippet: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'],
  ) {
    if (!snippet) {
      // @ts-expect-error invalid inheritance
      super(scroll, domNode, false);

      return;
    }

    super(scroll, domNode);

    this.snippet = snippet;
  }

  static override create(snippet: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2']) {
    if (!snippet) {
      return super.create(false);
    }

    const node = super.create(snippet) as Element;

    node.classList.add(QL_SNIPPET_CLASS_PREFIX);

    return node;
  }

  static override formats(domNode: HTMLElement) {
    return domNode.classList.contains(QL_SNIPPET_CLASS_PREFIX);
  }

  override formats() {
    const formats = super.formats();
    const value = SnippetFormat.formats(this.domNode);

    if (value) {
      formats[QL_SNIPPET_HIGHLIGHT_FORMAT_NAME] = SnippetFormat.formats(this.domNode);
    }

    // Return this blot's formats
    return formats;
  }
}
