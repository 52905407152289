import React from 'react';

import cx from 'classnames';

import { IUserProfile } from '@writercolab/common-utils';
import { Dropdown, DropdownOption } from '@writercolab/ui-atoms';
import { DropdownTrigger, DropdownTriggerVariant } from '@writercolab/ui-molecules';

import { getLogger } from '../../../utils/logger';

import styles from './styles.module.css';

const LOG = getLogger('AdminUsersDropdown');

interface IDeletePersonAdvancedProps {
  className?: string;
  handleChange: (id: number) => void;
  options: DropdownOption[];
  selectedAdmin: Partial<IUserProfile>;
  userProfile: IUserProfile;
}

export const AdminUsersDropdown: React.FC<IDeletePersonAdvancedProps> = ({
  className,
  handleChange,
  options,
  selectedAdmin,
  userProfile,
}) => {
  const adminUserSelection = options.find(({ id: optId }) => parseInt(optId, 10) === selectedAdmin.id);

  if (!adminUserSelection) {
    LOG.error('selected admin not found');

    return null;
  }

  const placeholder = parseInt(adminUserSelection.id, 10) === userProfile.id ? 'Assign to me' : adminUserSelection.name;

  return (
    <div className={cx(className, styles.adminsDropdownContainer)}>
      <Dropdown
        dropDownContainerClassName={styles.dropdownContainer}
        onPrimaryOptionClickAction={id => handleChange(parseInt(id, 10))}
        options={options}
        trigger={<DropdownTrigger placeholder={placeholder} variant={DropdownTriggerVariant.ROUNDED} />}
      />
    </div>
  );
};

export default AdminUsersDropdown;
