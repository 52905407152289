import { useMemo } from 'react';

import { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Modal, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { InputField } from 'components/organisms/InputField/InputField';

import { TKnowledgeGraphUrlModal } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphWebConnectorUIModel } from '../../KnowledgeGraphWebConnectorModel.ui';
import { KG_URLS_LIMIT, truncateModalUrl } from '../../utils';

import styles from './ExcludeUrlModal.module.css';

interface IExcludeUrlModalProps {
  model: KnowledgeGraphWebConnectorUIModel;
}

export const ExcludeUrlModal = observer<IExcludeUrlModalProps>(({ model }) => {
  const { analyticsService, modalsManager, isLoading, validUrlsCount, excludeUrlsCount, onAddExcludeUrl } = model;
  const modalState = modalsManager.getModalState(TKnowledgeGraphUrlModal.enum.excludeUrl);
  const urlField = useMemo(() => {
    const model = FieldModel.build({
      init: () => modalState?.url,
      autotouched: true,
      validation: value => {
        if (modalState && !value?.startsWith(modalState.url)) {
          return 'Excluded URL must contain the full parent URL';
        }

        return modalState?.url === value ? 'Enter a valid subpage' : undefined;
      },
    });
    model.touched = true;

    return model;
  }, [modalState]);

  return (
    <Modal
      open
      title="Exclude a specific subpage"
      className={styles.content}
      modalTitleClassName={styles.title}
      disableBackdropClick
      handleClose={() => {
        analyticsService.track(AnalyticsActivity.kgWebExcludeSpecificSubpagesModalClosed, {
          url: modalState?.url,
        });
        modalsManager.hideModal();
      }}
    >
      <>
        <Text className={styles.description} variant={TextSize.XL} color={TextColor.GREY}>
          Exclude a specific subpage from <b>{truncateModalUrl(modalState?.url ?? '')}</b> in your Knowledge Graph.
        </Text>
        <Text className={styles.label} variant={TextSize.XL} color={TextColor.BLACK}>
          Website URL
        </Text>
        <InputField field={urlField} placeholder="Enter a subpage" />
        <div className={styles.controls}>
          <Text variant={TextSize.M} color={TextColor.BLACK}>
            {validUrlsCount + excludeUrlsCount}/{KG_URLS_LIMIT} URLs ({validUrlsCount} included · {excludeUrlsCount}{' '}
            excluded)
          </Text>
          <Button
            isLoading={isLoading}
            disabled={!!urlField.error}
            size={SizeTypes.MEDIUM}
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              if (modalState && urlField.value) {
                onAddExcludeUrl(modalState.id, urlField.value, validUrlsCount + excludeUrlsCount + 1);
              }
            }}
          >
            Exclude
          </Button>
        </div>
      </>
    </Modal>
  );
});
