import React from 'react';

import cx from 'classnames';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IMenuItem {
  icon?: React.ReactElement;
  title: string;
  isActive?: boolean;
  onClick?: () => void;
}

export const MenuItem: React.FC<IMenuItem> = ({ title, icon, isActive, onClick }) => (
  <div className={cx(styles.container, { [styles.isActive]: isActive })} onClick={onClick}>
    {icon}
    <Text className={styles.itemText} variant={TextSize.M} color={TextColor.GREY}>
      {title}
    </Text>
  </div>
);
export default MenuItem;
