/**
 * Comparator function for sorting an array of objects by their 'name' property.
 * The 'name' is expected to be a string. The function can be used directly with
 * the array sort method.
 *
 * @param {T extends { name: string }} a - The first object for comparison.
 * @param {T extends { name: string }} b - The second object for comparison.
 * @returns {number} - The comparison result: -1 if 'a' should come before 'b',
 *                      1 if 'a' should come after 'b', or 0 if they are equal.
 * @template T - A type that extends an object with at least a 'name' property of type string.
 */
export const sortByNameProperty = <T extends { name: string }>(a: T, b: T): number => a.name.localeCompare(b.name);

/**
 * Sorts two objects in descending order based on the `createdAt` property, which should be a string representation
 * of a date. This comparator function is intended for use with the array's sort method. The comparison is based on
 * the timestamp value of the date strings, sorting the objects from the most recently created to the oldest.
 *
 * @param {T extends { createdAt: string }} a - The first object to compare.
 * @param {T extends { createdAt: string }} b - The second object to compare.
 * @returns {number} - A positive number if `a` was created after `b`, a negative number if `a` was created before `b`,
 *                     or zero if both objects were created at the same time.
 * @template T - A generic type constraint that ensures the object has a `createdAt` property of type string.
 */
export const sortByCreatedAtPropertyDesc = <T extends { createdAt: string }>(a: T, b: T): number => {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  return dateB.getTime() - dateA.getTime();
};

/*
 * Removes duplicate objects from an array by comparing a specific property name of the objects.
 *
 * This function checks each object in the provided array, maintaining the first unique instance of each value
 * associated with the specified property name and removing subsequent duplicates. The original array remains
 * unmodified, as the function returns a new array with duplicates removed. The order of elements in the original
 * array is preserved.
 *
 * @template T - The object type contained within the array.
 * @template K - The type representing the keys of object T.
 * @param {T[]} array - The array of objects from which to remove duplicates.
 * @param {K} propertyName - The name of the property based on which duplicates will be determined.
 * @returns {T[]} A new array with duplicates removed, based on the specified property name.
 *
 * @example
 * interface User {
 *   id: number;
 *   name: string;
 * }
 *
 * const users: User[] = [
 *   { id: 1, name: 'Alice' },
 *   { id: 2, name: 'Bob' },
 *   { id: 3, name: 'Alice' },
 * ];
 *
 * const uniqueUsers = removeDuplicatesByPropertyName(users, 'name');
 * console.log(uniqueUsers); // Output: [{ id: 1, name: 'Alice' }, { id: 2, name: 'Bob' }]
 */
export function removeDuplicatesByPropertyName<T, K extends keyof T>(array: T[], propertyName: K): T[] {
  const propertyValues = new Set<T[K]>();

  return array.filter(item => {
    const value = item[propertyName];
    const isDuplicate = propertyValues.has(value);

    if (!isDuplicate) {
      propertyValues.add(value);
    }

    return !isDuplicate;
  });
}
