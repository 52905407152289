import { DropdownOptionUserSettingDepartment, UserSettingDepartment } from '@web/types';

import { stringToId } from '../utils/stringUtils';

export const UserSettingDepartments: DropdownOptionUserSettingDepartment[] = (() =>
  Object.values(UserSettingDepartment).map(d => {
    const isEditable = d === UserSettingDepartment.OTHER;

    return {
      id: stringToId(d),
      name: d,
      value: isEditable ? '' : true,
      editable: isEditable,
      valid: true,
    };
  }))();

export const isCustomDepartment = (department: string): boolean =>
  stringToId(department) === stringToId(UserSettingDepartment.OTHER);
