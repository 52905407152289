import React from 'react';

import cx from 'classnames';

import { Heading, HeadingColor, HeadingVariant, TextSize } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import InfoIconText from '../InfoIconText/InfoIconText';

import styles from './PageDescription.module.css';

const TPageDescriptionVariant = new Enum('primary', 'secondary');

interface IPageDescriptionProps extends BaseComponentsProps {
  title?: string | React.ReactNode;
  variant?: typeof TPageDescriptionVariant.type;
  description?: string | React.ReactNode;
  tooltipTitle?: string | React.ReactNode;
  headingColor?: HeadingColor;
  tooltipPlacement?: 'top' | 'bottom';
  descriptionBold?: boolean;
  descriptionMedium?: boolean;
  descriptionLight?: boolean;
  descriptionSize?: TextSize;
  headingVariant?: HeadingVariant;
  icon?: React.ReactNode;
}

export const PageDescription: React.FC<IPageDescriptionProps> = ({
  className,
  variant = TPageDescriptionVariant.enum.primary,
  title,
  icon,
  tooltipTitle,
  description,
  descriptionLight = false,
  descriptionMedium = false,
  descriptionBold = false,
  descriptionSize = TextSize.XXXL,
  tooltipPlacement = 'top',
  headingColor = HeadingColor.GREY,
  headingVariant = HeadingVariant.H2,
  children,
}) => (
  <div className={cx(styles.container, className)}>
    {title && (
      <div
        className={cx({
          [styles.containerWithIcon]: !!icon,
          [styles.containerHeaderWrapper]: variant === TPageDescriptionVariant.enum.primary,
        })}
      >
        {icon && <div className={styles.icon}>{icon}</div>}
        <Heading className={styles.containerHeader} color={headingColor} variant={headingVariant}>
          {title}
        </Heading>
      </div>
    )}
    {description && (
      <InfoIconText
        className={cx(styles.containerDescription, {
          [styles.containerDescriptionWithIcon]: !!icon,
        })}
        titleSize={descriptionSize}
        titleBold={descriptionBold}
        description={tooltipTitle}
        title={description}
        tooltipPlacement={tooltipPlacement}
        titleMedium={descriptionMedium}
        titleLight={descriptionLight}
      />
    )}
    {children}
  </div>
);
