import type React from 'react';

import {
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SearchBar,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { Unreachable, UnreachableType } from '@writercolab/ui-molecules';

import { TTeamPromptActionId, TTeamPromptSortField } from '@web/types';
import { observer } from 'mobx-react-lite';

import emptyPagePath from '../../../assets/backgrounds/emptyPage.png';
import { TeamPromptsInitialState } from '../../molecules/TeamPromptsInitialState';
import { TeamPromptsTable } from '../TeamPromptsTable';
import { TTeamPromptsTableVariant } from '../TeamPromptsTable/TeamPromptsTable';
import type { PromptLibraryTeamUIModel } from './PromptLibraryTeam.ui';

import styles from './PromptLibraryTeam.module.css';

interface IPromptLibraryTeamProps {
  model: PromptLibraryTeamUIModel;
  teamName?: string;
  onUsePrompt?: (prompt: string) => void;
  onCopyToClipboard: (message: string) => void;
  onCreate: () => void;
  emptyStateAvailable?: boolean;
  showContactText?: boolean;
}

export const PromptLibraryTeam: React.FC<IPromptLibraryTeamProps> = observer(
  ({ teamName, model, onUsePrompt, onCopyToClipboard, onCreate, emptyStateAvailable, showContactText }) => {
    const onPromptActionClick = async (actionId: typeof TTeamPromptActionId.type, promptId?: string) => {
      if (!promptId) {
        return;
      }

      const prompt = model.find(promptId);

      if (!prompt) {
        return;
      }

      TTeamPromptActionId.matchPartial(
        actionId,
        {
          [TTeamPromptActionId.enum.copy]: () => onCopyToClipboard(prompt.prompt),
          [TTeamPromptActionId.enum.use]: () => onUsePrompt?.(prompt.prompt),
        },
        null,
      );
    };

    if (model.empty) {
      return (
        <div className={styles.container}>
          <TeamPromptsInitialState
            teamName={teamName}
            showContactText={showContactText}
            onAddClick={emptyStateAvailable ? onCreate : undefined}
          />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.preload}>
          <img src={emptyPagePath} alt="preload" />
        </div>
        <div className={styles.heading}>
          <div>
            {teamName && (
              <div className={styles.headingTeam}>
                <Text variant={TextSize.XXS} color={TextColor.GREY2} extraSmallCaps>
                  {teamName}
                </Text>
              </div>
            )}
            <Heading variant={HeadingVariant.H2} className={styles.headingText}>
              Team prompts
            </Heading>
          </div>
          <div className={styles.utils}>
            <SearchBar
              placeholder="Search"
              className={styles.searchBar}
              value={model.searchInputField.value}
              onChange={e => model.setSearchPhrase(e.target.value)}
              handleClearInput={() => model.resetSearchPhrase()}
            />
            <div>
              <Dropdown
                options={model.sortingOptions}
                trigger={
                  <div className={styles.sortActionTriggerContainer}>
                    <Icon name={IconVariant.SORT} width={18} height={18} className={styles.sortActionIcon} />
                  </div>
                }
                onPrimaryOptionClickAction={id => {
                  model.setSortingOption(TTeamPromptSortField.get(id, TTeamPromptSortField.enum.createdAt));
                }}
                placement={DropdownPlacement.BOTTOM_RIGHT}
              />
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <TeamPromptsTable
            variant={TTeamPromptsTableVariant.enum.extended}
            className={styles.tableContainer}
            model={model.teamPromptsTableUiModel}
            onPromptActionClick={onPromptActionClick}
            headersVisible={false}
            showActionsDropdown={false}
            rowClickEnabled={false}
            showCopyAction
            showUseAction={!!onUsePrompt}
            emptyState={
              <div className={styles.emptyState}>
                <Unreachable type={UnreachableType.enum.noResultsEmpty} />
              </div>
            }
          />
        </div>
      </div>
    );
  },
);
