import type React from 'react';

import { LoadingPage } from '@web/component-library';
import { observer } from 'mobx-react-lite';
import { useAppState } from 'state';

import { useAppRedirects } from '../../../hooks/useAppRedirects';

export const NewUserActionRedirectPage: React.FC = observer(() => {
  const {
    appModel: {
      permissionsModel,
      assistantSubscription,
      isUnverifiedEmail,
      teamsModel: { teams },
    },
  } = useAppState();

  useAppRedirects({
    isOrganizationAdmin: !!permissionsModel?.isOrganizationAdmin,
    isModelReady: assistantSubscription.isModelReady,
    isMultiTeam: assistantSubscription.isMultiTeam,
    teams,
    isUnverifiedEmail,
  });

  return <LoadingPage />;
});
