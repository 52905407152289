import React, { useMemo, useState } from 'react';

import { capitalizeWord, wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

import { capitalizedPreviewTitleByType, submitButtonTextByType } from '../../consts';
import { ImportItemType, TableColumnDataType } from '../../types';
import { PreviewImportedDataTable } from './PreviewImportedDataTable';
import { ACCEPTABLE_COLUMNS } from './consts';
import { getColumnValues, getStringWithoutDelimiterAndDigit } from './utils';

import styles from './styles.module.css';

interface IPreviewImportedDataStepProps {
  tableData: string[][];
  onChangeTable: (newTableData: string[][]) => void;
  onBack: () => void;
  onImport: (columnsData: TableColumnDataType[], rowsAmount: number) => void;
  uploadingAmount: number;
  type: ImportItemType;
  onClickImport: () => void;
}

export const PreviewImportedDataStep = ({
  tableData = [],
  onChangeTable,
  onBack,
  onImport,
  uploadingAmount,
  type,
  onClickImport,
}: IPreviewImportedDataStepProps) => {
  const [hiddenColumnsIndexes, setHiddenColumnsIndexes] = useState<number[]>([]);

  const onChangeColumnName = (columnIndex: number, newName: string) => {
    if (hiddenColumnsIndexes.includes(columnIndex)) {
      setHiddenColumnsIndexes(hiddenColumnsIndexes.filter(hiddenColumnIndex => hiddenColumnIndex !== columnIndex));
    }

    const newTableData = [...tableData];
    newTableData[0][columnIndex] = newName;
    onChangeTable(newTableData);
  };

  const onHideColumn = (columnIndex: number) => {
    setHiddenColumnsIndexes([...hiddenColumnsIndexes, columnIndex]);
  };

  const rowsAmount = tableData.length - 1;
  const visibleRowsAmount = rowsAmount < 10 ? rowsAmount : 10;

  const requiredAcceptableColumns = ACCEPTABLE_COLUMNS[type].filter(column => column.required);

  const tableCalculatedData = useMemo(() => {
    const headerRow = tableData[0].filter(columnName => columnName);
    const headerRowFormatted = headerRow.map(headCell =>
      headCell.replaceAll('’', '').replaceAll("'", '').replaceAll('-', '_').replaceAll(' ', '_').toLowerCase(),
    );

    const matchedAcceptableColumns: string[] = [];

    const columns: TableColumnDataType[] = headerRowFormatted.map((headerCell, columnIndex) => {
      const isContainsDelimiterAndNumber = !isEmpty(headerCell) && headerCell.match(/_[0-9]+/g)?.length === 1;
      const foundedAcceptableColumn = ACCEPTABLE_COLUMNS[type].find(column => {
        if (isContainsDelimiterAndNumber) {
          return (
            column.supportedMultiColumn &&
            column.names.some(
              name => name.toLowerCase() === getStringWithoutDelimiterAndDigit(headerCell).toLowerCase(),
            )
          );
        } else {
          return column.names.some(name => name.toLowerCase() === headerCell.toLowerCase());
        }
      });

      const isHiddenColumn = hiddenColumnsIndexes.includes(columnIndex);

      const isDuplicatedColumn =
        foundedAcceptableColumn && matchedAcceptableColumns.includes(foundedAcceptableColumn.label);

      if (
        !isHiddenColumn &&
        foundedAcceptableColumn &&
        !foundedAcceptableColumn.supportedMultiColumn &&
        !isDuplicatedColumn
      ) {
        matchedAcceptableColumns.push(foundedAcceptableColumn.label);
      }

      return {
        acceptableColumn: isDuplicatedColumn ? undefined : foundedAcceptableColumn,
        initialName: headerRow[columnIndex],
        columnIndex,
        values: getColumnValues(tableData, columnIndex, foundedAcceptableColumn?.htmlContentSupport),
        hidden: isHiddenColumn,
      };
    });

    const nonRequiredColumns = columns.filter(column => !column.acceptableColumn?.required);

    const matchedRequiredColumns: TableColumnDataType[] = columns.filter(column => column.acceptableColumn?.required);
    const missedRequiredColumns: TableColumnDataType[] = requiredAcceptableColumns
      .filter(acceptableColumn => !acceptableColumn.names.some(name => headerRowFormatted.includes(name.toLowerCase())))
      .map(acceptableColumn => ({
        acceptableColumn,
        initialName: acceptableColumn.label,
        values: Array(visibleRowsAmount).fill('-'),
        missed: true,
      }));
    const requiredColumns = [...matchedRequiredColumns, ...missedRequiredColumns].sort((firstColumn, secondColumn) => {
      if (firstColumn.acceptableColumn && secondColumn.acceptableColumn) {
        if (firstColumn.acceptableColumn?.label > secondColumn.acceptableColumn?.label) {
          return 1;
        } else if (firstColumn.acceptableColumn?.label < secondColumn.acceptableColumn?.label) {
          return -1;
        } else return 0;
      } else return 0;
    });

    const missedColumns = ACCEPTABLE_COLUMNS[type].filter(
      column =>
        column.supportedMultiColumn || !column.names.some(name => headerRowFormatted.includes(name.toLowerCase())),
    );

    const matchedColumns = headerRowFormatted.filter(headerCell =>
      ACCEPTABLE_COLUMNS[type].some(column =>
        column.names.some(name => name.toLowerCase() === headerCell.toLowerCase()),
      ),
    );

    const emptyRequiredColumns = matchedRequiredColumns.filter(
      column => column.acceptableColumn && !column.values.some(val => !!val),
    );

    return {
      columns,
      requiredColumns,
      nonRequiredColumns,
      missedColumns,
      missedRequiredColumns,
      emptyRequiredColumns,
      warningMismatchAmount: headerRowFormatted.length - matchedColumns.length,
    };
  }, [tableData, requiredAcceptableColumns, type, hiddenColumnsIndexes, visibleRowsAmount]);

  const _onClickImport = () => {
    onImport(tableCalculatedData.columns, rowsAmount);
    onClickImport && onClickImport();
  };

  const previewTypeTitle = capitalizedPreviewTitleByType[type];
  const submitBtnTextByType = submitButtonTextByType[type];

  return (
    <div className={styles.previewStep}>
      <div className={styles.previewHeader}>
        {uploadingAmount ? (
          <Heading variant={HeadingVariant.H2} bold className={styles.previewHeaderTitle}>
            Importing {uploadingAmount} {type}...
          </Heading>
        ) : (
          <>
            <Heading variant={HeadingVariant.H2} bold className={styles.previewHeaderTitle}>
              {capitalizeWord(previewTypeTitle)} preview
            </Heading>
            <Text variant={TextSize.XS} upperCase>
              ({visibleRowsAmount}/{rowsAmount} rows)
            </Text>
          </>
        )}
      </div>
      <PreviewImportedDataTable
        requiredColumns={tableCalculatedData.requiredColumns}
        nonRequiredColumns={tableCalculatedData.nonRequiredColumns}
        missedColumns={tableCalculatedData.missedColumns}
        importingItemsAmount={uploadingAmount}
        onChangeColumnName={onChangeColumnName}
        onHideColumn={onHideColumn}
      />
      {!uploadingAmount ? (
        <div className={styles.errorList}>
          {tableCalculatedData.missedRequiredColumns.map(column => (
            <div className={styles.errorListItem} key={column.initialName}>
              <Icon className={styles.errorIcon} name={IconVariant.CLOSE} width={12} height={12} />
              <Text variant={TextSize.L}>
                <Text variant={TextSize.L} inline bold>
                  We couldn’t find your {column.acceptableColumn?.label} column.
                </Text>{' '}
                Match a column from your sheet to {column.acceptableColumn?.label} to continue.
              </Text>
            </div>
          ))}
          {tableCalculatedData.emptyRequiredColumns.map(column => (
            <div className={styles.errorListItem} key={column.initialName}>
              <Icon className={styles.errorIcon} name={IconVariant.CLOSE} width={12} height={12} />
              <Text variant={TextSize.L}>
                <Text variant={TextSize.L} inline bold>
                  Your {column.acceptableColumn?.label} column is completely empty. This field is required.
                </Text>{' '}
                Doublecheck your import file and try again.
              </Text>
            </div>
          ))}
          {!!tableCalculatedData.warningMismatchAmount && (
            <div className={styles.errorListItem}>
              <Icon className={styles.warningIcon} name={IconVariant.EXCLAMATION} width={8} height={8} />
              <Text variant={TextSize.L}>
                We also couldn’t match {tableCalculatedData.warningMismatchAmount} other{' '}
                {wordPluralize(tableCalculatedData.warningMismatchAmount, 'column')}. We’ll skip these unless you match
                them.
              </Text>
            </div>
          )}
        </div>
      ) : null}
      <div className={styles.bottomControls}>
        <Button
          type={ButtonTypes.BORDERED}
          size={SizeTypes.MEDIUM}
          onClick={onBack}
          disabled={!!uploadingAmount}
          content="Back"
        />
        <Button
          type={ButtonTypes.PRIMARY}
          size={SizeTypes.MEDIUM}
          className={styles.importBtn}
          onClick={_onClickImport}
          disabled={
            !!tableCalculatedData.missedRequiredColumns.length ||
            !!tableCalculatedData.emptyRequiredColumns.length ||
            !!uploadingAmount
          }
          content={submitBtnTextByType}
        />
      </div>
    </div>
  );
};
