export enum PromptLibraryEventSources {
  LEARNING_CENTER = 'learning_center',
  ASK_WRITER = 'ask_writer',
}

export const DEFAULT_MENU_ITEM_COLOR = '#E4E9FF';
export const contentTypeTags = ['ads', 'blogs', 'emails', 'social', 'translations'];
export const contentTypeTagMap = {
  ads: 'Ads',
  blogs: 'Blogs',
  emails: 'Emails',
  social: 'Social',
  translations: 'Translations',
};
export const categoryOrder = ['team', 'tasks', 'contentType', 'content-length', 'verticals'];
