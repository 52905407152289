import { useCallback } from 'react';

import qs from 'qs';

import { useSearchParams } from 'react-router';

/**
 * Type definition for URL parameters.
 * @typedef {Record<string, unknown>} TParams
 */
type TParams = Record<string, unknown>;

/**
 * Interface for the useUpdateUrlParams hook.
 * @template T - The type of the URL parameters.
 * @interface
 */
interface IUseUpdateUrlParams<T extends TParams> {
  /**
   * The current URL search parameters.
   * @type {URLSearchParams}
   */
  searchParams: URLSearchParams;

  /**
   * Function to update the URL parameters.
   * @param {T} params - The parameters to update.
   */
  updateUrlParams: (params: T) => void;
}

/**
 * Custom hook to manage and update URL parameters.
 * @template T - The type of the URL parameters.
 * @returns {IUseUpdateUrlParams<T>} - The current search parameters and the function to update them.
 */
const useUpdateUrlParams = <T extends TParams = TParams>(): IUseUpdateUrlParams<T> => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateUrlParams = useCallback(
    (params: T) => {
      setSearchParams(prevSearchParams => {
        const currentParams = qs.parse(prevSearchParams.toString(), { ignoreQueryPrefix: true, parseArrays: true });
        const mergedParams = { ...currentParams, ...params };

        // Remove null or undefined values
        Object.entries(mergedParams).forEach(([key, value]) => {
          if (value === null || value === undefined || value === '') {
            delete mergedParams[key];
          }
        });

        return qs.stringify(mergedParams, { arrayFormat: 'repeat' });
      });
    },
    [setSearchParams],
  );

  return { updateUrlParams, searchParams };
};

export default useUpdateUrlParams;
