import type React from 'react';
import { Suspense, useCallback, useEffect, useMemo } from 'react';

import cx from 'classnames';

import {
  IconsCommonAiStudio,
  IconsCommonAiStudioFilled,
  IconsCommonArrowTopRight,
  IconsCommonBulb,
  IconsCommonBulbFilled,
  IconsCommonChrome,
  IconsCommonChromeFilled,
  IconsCommonDocFilled,
  IconsCommonEmptyDoc,
  IconsCommonHome,
  IconsCommonHomeFilled,
  IconsCommonSettings,
  IconsCommonSettingsFilled,
  IconsCommonSetup,
  IconsCommonSetupFilled,
  IconsCommonTeam,
  IconsCommonTeamFilled,
} from '@writercolab/assets';
import {
  BillingProduct,
  ContentEditorPageMode,
  GTMActivity,
  GTMCategory,
  REACT_APP_SUPPORT_PAGE_URL,
  SearchQueryParam,
  isChromeBrowser,
  loadScript,
  openNewTab,
} from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import { AvatarSize, Fade, IconVariant, Text, TextColor, UserAvatar, useCustomSnackbar } from '@writercolab/ui-atoms';
import { TopTrialBanner } from '@writercolab/ui-molecules';
import { CREATE_NEW_DOC_ID, SETUP_ROUTES } from '@writercolab/utils';

import { CookieConsent } from './components/molecules/CookieConsent';
import { LearningCenter } from './components/organisms/LearningCenter';
import NotificationBar from './components/organisms/NotificationBar';
import { UIOnboardingQuestionnaireModel } from './components/organisms/OnboardingQuestionnaire/UIOnboardingQuestionnaireModel.ui';
import { PromptLibraryModal, PromptLibraryModalModel } from './components/organisms/PromptLibraryModal';
import AdminPanel from './components/pages/AdminPanel';
import AutoJoin from './components/pages/AutoJoin/AutoJoin';
import { ChatAskPage } from './components/pages/ChatAskPage/ChatAskPage';
import { ChatPage } from './components/pages/ChatPage/ChatPage';
import ContentEditorPageWrapper from './components/pages/ContentEditorPage/ContentEditorPageWrapper';
import { DashboardPageWrapper } from './components/pages/DashboardPage/DashboardPageWrapper';
import DraftsPage from './components/pages/DraftsPage';
import EmailUnconfirmedPage from './components/pages/EmailUnconfirmedPage';
import Error404Page from './components/pages/Error404Page';
import { ExtensionsPage } from './components/pages/ExtensionsPage';
import { MyWorkPage } from './components/pages/MyWorkPage/MyWorkPage';
import NoTeamsPage from './components/pages/NoTeamsPage';
import OnboardingPage from './components/pages/OnboardingPage';
import PeoplePage from './components/pages/PeoplePage';
import ProfilePage from './components/pages/ProfilePage';
import StyleguideSection from './components/pages/StyleguideSection';
import { ErrorRouteComponent } from './components/templates/ErrorBoundary';
import { LearningCenterNavSection } from 'components/molecules/LearningCenterNavSection';
import { AiStudioAccessPendingModal } from 'components/organisms/AiStudioAppModal/AiStudioAccessPendingModal';
import { JoinAiStudioAppModal } from 'components/organisms/AiStudioAppModal/JoinAiStudioAppModal';
import GlobalNav, { GlobalNavSelection, INavAction } from 'components/organisms/GlobalNav';
import { FrameworkApplicationPage } from 'components/pages/FrameworkApplicationPage';
import { HomePage } from 'components/pages/HomePage';
import { ResearchAssistantPage } from 'components/pages/ResearchAssistantPage/ResearchAssistantPage';
import LearningCenterFullScreenVideoModal from 'components/templates/LearningCenterVideoModal/LearningCenterVideoModal';

import { LoadingPage, useDisableExtensions, useOnlineStatus } from '@web/component-library';
import type { TLearningCenterVideoId } from '@web/types';
import { TAppQueryParams, TLearningCenterCategoryId } from '@web/types';
import { QUICKSTART_VIDEOS } from 'constants/LearningCenter';
import { AnalyticsActivity, ILearningCenterNavigationCategory, IWebAppAnalyticsTrack } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react-lite';
import { TAppModals } from 'models/app';
import { LearningCenterModel } from 'models/learningCenter';
import { Route, Routes, matchPath, useLocation, useNavigate } from 'react-router';
import config from 'utils/dynamicConfig';
import {
  getAiAssistantCategoryIdFromPath,
  getLearningCenterCategories,
  mapVideosAsViewed,
} from 'utils/learningCenterUtils';

import { PromptLibraryEventSources } from './constants/PromptLibrary';
import DraftsContextProvider from './context/draftsContext';
import PeopleContextProvider from './context/peopleContext';
import ProfileContextProvider from './context/profileContext';
import { useTermsContext } from './context/termsContext';
import { useAppRedirects } from './hooks/useAppRedirects';
import { useComputeRoutes } from './hooks/useComputeRoutes';
import useCookieConsent from './hooks/useCookieConsent';
import { useEmailConfirmation } from './hooks/useEmailConfirmation';
import useExtensionChrome from './hooks/useExtensionChrome';
import { useFavicons } from './hooks/useFavicons';
import { useGtmAnalytics } from './hooks/useGtmAnalytics';
import useHelpscout from './hooks/useHelpscout';
import useLearningCenterVideos from './hooks/useLearningCenterVideos';
import { useLegacy } from './hooks/useLegacy';
import usePortal from './hooks/usePortal';
import useQuery from './hooks/useQuery';
import useUserSalesProfile from './hooks/useUserSalesProfile';
import { appLocalStorage, clearReportLocalStorage } from './models/localStorage';
import { HIDE_TRIAL_BANNER_FROM_PAGES, REACT_RELATIVE_ROUTE, ROUTE } from './services/config/routes';
import requestService from './services/request/requestService';
import { useAppState } from './state';
import { useAppSessionContext } from './state/sessionContext';
import { TActionType } from './state/types';
import { sortByNameProperty } from './utils/arrayUtils';
import { showChameleonNlgTour } from './utils/chmlnUtils';
import { filterOutNavAction } from './utils/navActionUtils';
import { goToBilling, goToHome } from './utils/navigationUtils';
import { teamDropdownOptionsMapper } from './utils/teamUtils';
import { SharedOnboardingRoutes } from '@web/utils';

import styles from './styles.module.css';

const { VITE_CANDU_TOKEN } = import.meta.env;

const _trackButtonClick = (
  analytics: IWebAppAnalyticsTrack,
  activity: (typeof AnalyticsActivity)[keyof typeof AnalyticsActivity],
  activityProperties: Record<string, string>,
) => analytics.track(activity, activityProperties);

const TutorialTipTooltipContent = () => (
  <div>
    <Text color={TextColor.WHITE} bold style={{ display: 'inline', lineHeight: '24px' }}>
      Psst —
    </Text>
    <Text color={TextColor.WHITE} style={{ display: 'inline', lineHeight: '24px' }}>
      {' '}
      You can take the Welcome Tour any <br />
    </Text>
    <Text color={TextColor.WHITE} style={{ display: 'inline', lineHeight: '24px' }}>
      time. You’ll find it right here in this menu.
    </Text>
  </div>
);

const App: React.FC = () => {
  useDisableExtensions();

  const { appState, dispatchAppState, appModel } = useAppState();

  const { signOutUser, onOrganizationChange } = useAppSessionContext();
  const { activeRoute, isGlobalNavHidden } = useComputeRoutes();
  const { termsContext } = useTermsContext();
  const isOnline = useOnlineStatus();
  const query = useQuery();
  const chromeBrowser = isChromeBrowser();

  const { closeSnackbar, enqueueAlertSnackbar } = useCustomSnackbar();

  const navigate = useNavigate();
  const location = useLocation();

  const { cookieConsented, acceptCookieConsent } = useCookieConsent();

  const {
    organization,
    userProfile,
    organizationId,
    organizations,
    isTutorialVisible,
    teamId,
    isInvalidOrg,
    isInvalidTeam,
    isAppLoading,
    isTutorialTipVisible,
  } = appState;

  const {
    analyticsService,
    isAuthenticated,
    aiStudioSubscription,
    featureFlags,
    refreshUserSettings,
    userSettings,
    isUnverifiedEmail,
    teamsModel,
    permissionsModel,
    assistantSubscription,
    modalsManager,
  } = appModel;

  const { isEnterprise, isFree, isTeam, isMultiTeam, isTrial, isModelReady, isActive } = assistantSubscription;

  const isTeamAdmin = permissionsModel?.isTeamAdminOf?.(Number(teamId));
  const userSalesProfile = useUserSalesProfile();
  const { learningCenterViewedVideosList, setLearningCenterViewedVideosList, showcaseVideosLeftToView } =
    useLearningCenterVideos();

  useHelpscout(
    isModelReady,
    !!isActive,
    isEnterprise,
    isTeam,
    isTrial,
    assistantSubscription.$subscription.value?.productName || '',
    userProfile,
    userSalesProfile,
  );
  useGtmAnalytics({
    location,
    userProfile: appState.userProfile,
    isTrialSubscription: assistantSubscription.isTrial,
    aiAssistantSubscriptionProductName: assistantSubscription.productName,
    isAiStudioSubscriptionReady: false,
  });
  useFavicons(isAuthenticated);
  useAppRedirects({
    isOrganizationAdmin: !!permissionsModel?.isOrganizationAdmin,
    isModelReady,
    isMultiTeam,
    teams: teamsModel.teams,
    isUnverifiedEmail,
  });

  const { isLegacy } = useLegacy(organizations || [], organizationId, location.pathname);

  const { onResendEmailClick } = useEmailConfirmation(isUnverifiedEmail === true);
  const hideTrialBannerOnSomeRoutes = !!HIDE_TRIAL_BANNER_FROM_PAGES.find(r =>
    matchPath({ path: r, end: true }, location.pathname),
  );
  const hideTrialBannerParam = query.get(SearchQueryParam.hideTrialBanner);
  const autoJoinHideJoinOrg = !isEmpty(query.get(SearchQueryParam.createOrganization));
  const isAiStudioPermissionsError = query.get(TAppQueryParams.enum.aiStudioPermissionsError);

  const isChromeExtensionInstalled = useExtensionChrome();

  const promptLibraryModalModel = useMemo(() => {
    if (!organizationId || !organization || !teamId) {
      return undefined;
    }

    return new PromptLibraryModalModel({
      analyticsService,
      requestService: requestService.api,
      organizationId,
      teamId,
    });
  }, [analyticsService, organization, organizationId, teamId]);

  const onBuyNowClick = useCallback(() => {
    if (organizationId) {
      const action =
        assistantSubscription.isTeam && (assistantSubscription?.isTrialExpired || assistantSubscription.isTrial)
          ? BillingProduct.TEAM
          : BillingProduct.PRO;
      const analyticsAction =
        action === BillingProduct.PRO ? AnalyticsActivity.clickPurchaseProNow : AnalyticsActivity.clickPurchaseTeamNow;

      _trackButtonClick(analyticsService, analyticsAction, { clicked_from: 'trial_banner' });
      goToBilling(navigate, organizationId, action);
    }
  }, [
    organizationId,
    assistantSubscription.isTeam,
    assistantSubscription?.isTrialExpired,
    assistantSubscription.isTrial,
    analyticsService,
    navigate,
  ]);

  const hideTrialBanner =
    isUnverifiedEmail ||
    hideTrialBannerOnSomeRoutes ||
    !!hideTrialBannerParam ||
    location.pathname.includes('/document/'); // hide trial banner on Editor page

  const access = {
    adminPanelButton: permissionsModel?.isOrganizationAdmin || permissionsModel?.isAdminOfAnyTeam,
    hideTeamDropdown: !assistantSubscription.isEnterprise,
    showAiStudioButton: featureFlags.get('aiStudioAllowed', false) || permissionsModel?.hasAccessToConsole,
    trialBannerVisible:
      !hideTrialBanner &&
      assistantSubscription.isSelfServeCustomer &&
      (assistantSubscription.isTrial || !!assistantSubscription?.isTrialExpired) &&
      !isTutorialVisible,
    restrictedTopNavAccess: assistantSubscription?.isTrialExpired || assistantSubscription.isCancelled,
    showTeammatesNav:
      assistantSubscription.isMultiTeam || assistantSubscription.isEnterprise || assistantSubscription.isTeam,
    learningCenterGlobalNavVisible: !assistantSubscription.isFree,
    extensionsPageAvailable: featureFlags.get('extensionsPageAvailable', true),
  };

  const { portal, isPortalDisabled } = usePortal(organizationId, isModelReady, isFree);

  const onProfileOptionClick = useCallback(() => navigate(ROUTE.toProfile(organizationId)), [navigate, organizationId]);
  const onProfilePopupDrawerDropDownClick = () =>
    dispatchAppState({ type: TActionType.SetTutorialTipVisible, payload: false });

  const onClickSupport = useCallback(() => openNewTab(REACT_APP_SUPPORT_PAGE_URL), []);
  const onClickWelcomeTour = assistantSubscription.isTeam
    ? () => {
        navigate(ROUTE.toHome(organizationId, teamId));

        _trackButtonClick(analyticsService, AnalyticsActivity.tutorialRestarted, {});
        dispatchAppState({
          type: TActionType.SetTutorialVisible,
          payload: true,
        });
      }
    : undefined;

  const onSingOutOptionClick = () => signOutUser();

  const onTopNavItemClick = useCallback(
    (item: GlobalNavSelection) => () => {
      const _orgId = `${organizationId}`;
      const _teamId = `${teamId}`;

      switch (item) {
        case GlobalNavSelection.EDITOR:
          navigate(ROUTE.toNewEditorPage(organizationId, teamId));
          break;
        case GlobalNavSelection.TEAM:
          navigate(ROUTE.toTeamUsers(_orgId, _teamId));
          break;
        case GlobalNavSelection.DASHBOARD:
          navigate(ROUTE.toHome(_orgId, _teamId));
          break;
        case GlobalNavSelection.MY_WORK:
          navigate(ROUTE.toMyWork(_orgId, _teamId));
          break;
        case GlobalNavSelection.SETTINGS:
          navigate(ROUTE.toAllTeams(_orgId));
          break;
        case GlobalNavSelection.TERMS:
          navigate(ROUTE.toTerms(_orgId, _teamId));
          break;
        case GlobalNavSelection.SNIPPETS:
          navigate(ROUTE.toSnippets(_orgId, _teamId));
          break;
        case GlobalNavSelection.EXTENSIONS:
          navigate(ROUTE.toExtensions(_orgId));
          break;
        case GlobalNavSelection.SETUP:
        case GlobalNavSelection.SUGGESTIONS:
          navigate(ROUTE.toSuggestions(_orgId, _teamId));
          break;
        case GlobalNavSelection.DRAFTS:
          navigate(ROUTE.toTeamDrafts(_orgId, _teamId));
          break;
        case GlobalNavSelection.AI_STUDIO:
          openNewTab(ROUTE.toAiStudioHome(_orgId));
          break;
        default:
          navigate(ROUTE.root);
      }
    },
    [navigate, organizationId, teamId],
  );

  const onSettingsItemClick = useCallback(() => {
    const isTeamTrial = assistantSubscription.isTeam && assistantSubscription.isTrial;
    const isTeamExpired = assistantSubscription.isTeam && assistantSubscription.isExpired;
    const isCancelledTeamOrFree =
      access.restrictedTopNavAccess && (assistantSubscription.isTeam || assistantSubscription.isFree);
    _trackButtonClick(analyticsService, AnalyticsActivity.clickAdminPanel, {});

    if (assistantSubscription.isFree || isTeamTrial || isTeamExpired || isCancelledTeamOrFree) {
      organizationId && goToBilling(navigate, organizationId);
    } else {
      onTopNavItemClick(GlobalNavSelection.SETTINGS)();
    }
  }, [
    assistantSubscription.isTeam,
    assistantSubscription.isTrial,
    assistantSubscription.isExpired,
    assistantSubscription.isFree,
    access.restrictedTopNavAccess,
    analyticsService,
    organizationId,
    navigate,
    onTopNavItemClick,
  ]);

  const onAiStudioClick = useCallback(() => {
    if (aiStudioSubscription?.isSubscriptionActive && permissionsModel?.hasAccessToConsole) {
      onTopNavItemClick(GlobalNavSelection.AI_STUDIO)();
    } else if (permissionsModel?.isOrganizationAdmin) {
      modalsManager.showModal(TAppModals.enum.aiStudioOptIn);
    } else {
      modalsManager.showModal(TAppModals.enum.aiStudioContactAdmin);
    }
  }, [
    aiStudioSubscription?.isSubscriptionActive,
    modalsManager,
    permissionsModel?.hasAccessToConsole,
    permissionsModel?.isOrganizationAdmin,
    onTopNavItemClick,
  ]);

  const onTeamSelect = (teamId: string) => {
    const _orgId = `${organizationId}`;
    let targetLocation: string;

    switch (activeRoute) {
      case GlobalNavSelection.HOME:
        targetLocation = ROUTE.toHome(_orgId, teamId);
        break;
      case GlobalNavSelection.TEAM:
        targetLocation = ROUTE.toTeamUsers(_orgId, teamId);
        break;
      case GlobalNavSelection.TERMS:
        targetLocation = ROUTE.toTerms(_orgId, `${teamId}`);
        break;
      case GlobalNavSelection.SNIPPETS:
        targetLocation = ROUTE.toSnippets(_orgId, teamId);
        break;
      case GlobalNavSelection.EDITOR:
        targetLocation = ROUTE.toEditorPage(_orgId, teamId, -1);
        break;
      case GlobalNavSelection.DRAFTS:
        targetLocation = ROUTE.toTeamDrafts(_orgId, teamId);
        break;
      case GlobalNavSelection.MY_WORK:
        targetLocation = ROUTE.toMyWork(_orgId, teamId);
        break;
      default:
        targetLocation = ROUTE.toHome(_orgId, teamId);
        break;
    }

    clearReportLocalStorage();
    navigate(targetLocation);
  };

  const teamOptions = useMemo(() => {
    const _activeTeamId = teamId || 0;

    return teamDropdownOptionsMapper(teamsModel.teams || [], _activeTeamId);
  }, [teamsModel.teams, teamId]);

  const handleOpenLearningCenter = useCallback(() => {
    if (promptLibraryModalModel) {
      promptLibraryModalModel.teamUiModel.resetSearchPhrase();
      promptLibraryModalModel.userUiModel.resetSearchPhrase();
    }

    analyticsService.track(AnalyticsActivity.learningCenterOpened, {});

    navigate(ROUTE.toLearningCenter(organizationId, teamId), {
      state: { prevRoute: location.pathname + location.search },
    });
  }, [analyticsService, location.pathname, location.search, navigate, organizationId, promptLibraryModalModel, teamId]);

  const { primaryActions, subPrimaryActions, secondaryActions, selectActions, selectCurrentAction } = useMemo(() => {
    const _orgId = `${organizationId}`;
    const _teamId = `${teamId}`;

    let primaryActions: INavAction[] = [
      {
        key: GlobalNavSelection.DASHBOARD,
        navTo: ROUTE.toHome(_orgId, _teamId),
        label: 'Home',
        icon: <IconsCommonHome />,
        iconActive: <IconsCommonHomeFilled />,
        onClick: onTopNavItemClick(GlobalNavSelection.DASHBOARD),
      },
    ];

    const subPrimaryActions: INavAction[] = [];

    if (teamId !== 0) {
      primaryActions = [
        ...primaryActions,
        ...(featureFlags.get('waMyWorkPage', false)
          ? [
              {
                key: GlobalNavSelection.MY_WORK,
                navTo: ROUTE.toMyWork(_orgId, _teamId),
                label: 'My work',
                icon: <IconsCommonEmptyDoc />,
                iconActive: <IconsCommonDocFilled />,
                onClick: onTopNavItemClick(GlobalNavSelection.MY_WORK),
              },
            ]
          : []),
        ...(featureFlags.get('waMyWorkPage', false)
          ? []
          : [
              {
                key: GlobalNavSelection.DRAFTS,
                navTo: ROUTE.toTeamDrafts(_orgId, _teamId),
                label: 'Drafts',
                iconVariant: IconVariant.DRAFT,
                iconVariantActive: IconVariant.DRAFT_ACTIVE,
                onClick: onTopNavItemClick(GlobalNavSelection.DRAFTS),
              },
              {
                key: GlobalNavSelection.TERMS,
                label: 'Terms',
                navTo: ROUTE.toTerms(_orgId, _teamId),
                iconVariant: IconVariant.TERMINOLOGY_UNFILLED_GREY,
                iconVariantActive: IconVariant.TERMINOLOGY_FILLED,
                onClick: onTopNavItemClick(GlobalNavSelection.TERMS),
              },
              {
                key: GlobalNavSelection.SNIPPETS,
                label: 'Snippets',
                navTo: ROUTE.toSnippets(_orgId, _teamId),
                iconVariant: IconVariant.SNIPPETS_UNFILLED_GREY,
                iconVariantActive: IconVariant.SNIPPETS_FILLED,
                onClick: onTopNavItemClick(GlobalNavSelection.SNIPPETS),
              },
            ]),
        {
          key: GlobalNavSelection.SETUP,
          label: 'Setup',
          navTo: ROUTE.toSuggestions(_orgId, _teamId),
          icon: <IconsCommonSetup />,
          iconActive: <IconsCommonSetupFilled />,
          onClick: onTopNavItemClick(GlobalNavSelection.SETUP),
        },
      ];

      if (access.showTeammatesNav) {
        primaryActions.push({
          key: GlobalNavSelection.TEAM,
          navTo: ROUTE.toTeamUsers(_orgId, _teamId),
          label: 'Teammates',
          icon: <IconsCommonTeam />,
          iconActive: <IconsCommonTeamFilled />,
          onClick: onTopNavItemClick(GlobalNavSelection.TEAM),
        });
      }
    }

    if (featureFlags.get('waMyWorkPage', false) && access.showAiStudioButton) {
      subPrimaryActions.push({
        key: GlobalNavSelection.AI_STUDIO,
        label: 'AI Studio',
        icon: <IconsCommonAiStudio />,
        iconActive: <IconsCommonAiStudioFilled />,
        trailingIcon: (
          <div className={styles.trailingIcon}>
            <IconsCommonArrowTopRight />
          </div>
        ),
        onClick: onAiStudioClick,
        isSubPrimary: true,
      });
    }

    let secondaryActions: INavAction[] = [
      ...(!isFree && featureFlags.get('waMyWorkPage', false)
        ? [
            {
              key: GlobalNavSelection.LEARNING,
              label: 'Learning',
              icon: <IconsCommonBulb />,
              iconActive: <IconsCommonBulbFilled />,
              onClick: handleOpenLearningCenter,
            },
          ]
        : []),
      {
        key: GlobalNavSelection.EXTENSIONS,
        label: 'Extensions',
        navTo: ROUTE.toExtensions(_orgId),
        icon: <IconsCommonChrome />,
        iconActive: <IconsCommonChromeFilled />,
        indicated: chromeBrowser && !isChromeExtensionInstalled,
        onClick: onTopNavItemClick(GlobalNavSelection.EXTENSIONS),
      },
      ...(featureFlags.get('waMyWorkPage', false)
        ? []
        : [
            {
              key: GlobalNavSelection.PROFILE,
              navTo: ROUTE.toProfile(_orgId),
              label: 'Profile',
              iconVariant: IconVariant.PROFILE_UNFILLED_GREY,
              iconVariantActive: IconVariant.PROFILE_FILLED,
              onClick: onProfileOptionClick,
            },
          ]),
    ];

    if (!featureFlags.get('waMyWorkPage', false) && access.showAiStudioButton) {
      secondaryActions.push({
        key: GlobalNavSelection.AI_STUDIO,
        label: 'AI Studio',
        iconVariant: IconVariant.OPEN_LINK,
        iconVariantActive: IconVariant.OPEN_LINK,
        onClick: onAiStudioClick,
      });
    }

    if (!access.extensionsPageAvailable) {
      secondaryActions = filterOutNavAction(GlobalNavSelection.EXTENSIONS, secondaryActions);
    }

    if (access.adminPanelButton) {
      const settingsOpts = {
        key: GlobalNavSelection.SETTINGS,
        navTo: ROUTE.toBilling(_orgId),
        label: assistantSubscription.isFree ? 'Billing' : 'Admin',
        icon: <IconsCommonSettings />,
        iconActive: <IconsCommonSettingsFilled />,
        onClick: onSettingsItemClick,
      };

      if (featureFlags.get('waMyWorkPage', false)) {
        secondaryActions.push(settingsOpts);
      } else {
        secondaryActions.unshift(settingsOpts);
      }
    }

    let selectActions: DropdownOption[] = [];
    let selectCurrentAction = '';

    if (organizations) {
      selectActions = organizations
        .map(
          org =>
            ({
              id: `${org.id}`,
              name: org.name,
              active: org.id === organizationId,
            }) as DropdownOption,
        )
        .sort(sortByNameProperty);

      selectCurrentAction = organizations.find(org => org.id === organizationId)?.name || '';
    }

    return { primaryActions, subPrimaryActions, secondaryActions, selectActions, selectCurrentAction };
  }, [
    organizationId,
    teamId,
    onTopNavItemClick,
    featureFlags,
    access.showAiStudioButton,
    access.extensionsPageAvailable,
    access.adminPanelButton,
    access.showTeammatesNav,
    isFree,
    handleOpenLearningCenter,
    chromeBrowser,
    isChromeExtensionInstalled,
    onProfileOptionClick,
    organizations,
    onAiStudioClick,
    assistantSubscription.isFree,
    onSettingsItemClick,
  ]);

  /** ------ Utils related to TopNav */

  useEffect(() => {
    if (organizationId && assistantSubscription.$subscription.status === 'rejected') {
      navigate(ROUTE.toAiStudioHome(organizationId));
    }
  }, [assistantSubscription.$subscription.status, navigate, organizationId, onSettingsItemClick]);

  useEffect(() => {
    if (!isAiStudioPermissionsError || !access.showAiStudioButton) {
      return;
    }

    const modalType = permissionsModel?.isOrganizationAdmin
      ? TAppModals.enum.aiStudioOptIn
      : TAppModals.enum.aiStudioContactAdmin;

    modalsManager.showModal(modalType);
  }, [modalsManager, isAiStudioPermissionsError, access.showAiStudioButton, permissionsModel?.isOrganizationAdmin]);

  useEffect(() => {
    if (userProfile && teamId && userSettings.status === 'fulfilled' && !config.SINGLE_TENANCY_CONFIG?.disableCandu) {
      loadScript(`https://cdn.candu.ai/sdk/latest/candu.umd.js?token=${VITE_CANDU_TOKEN}`).then(() => {
        (window as any).Candu.init({
          userId: `segment-prefix-${userProfile.id}`,
          clientToken: VITE_CANDU_TOKEN!,
          traits: {
            teamId: teamId ? `${teamId}` : undefined,
            department: userSettings.value?.department,
          },
        });
      });
    }
  }, [userSettings.value, userSettings.status, teamId, userProfile, featureFlags]);

  useEffect(() => {
    const showLoaderBeforeExitingTheApp = () => {
      if (termsContext && !termsContext.isAddTermPopupOpen) {
        dispatchAppState({ type: TActionType.SetAppLoading, payload: true });
      }
    };

    window.addEventListener('beforeunload', showLoaderBeforeExitingTheApp);

    return () => {
      window.removeEventListener('beforeunload', showLoaderBeforeExitingTheApp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId, userProfile?.id]);

  useEffect(() => {
    const pendingGtmEvents = appLocalStorage.pendingGtmEvents.get();

    if (pendingGtmEvents?.length) {
      pendingGtmEvents.forEach(event => analyticsService.trackGtmActivity(event));
      appLocalStorage.pendingGtmEvents.remove();
    }
  }, [analyticsService]);

  const onAfterSubmitQuestionnaire = useCallback(
    (autoGenerateContentUser: boolean) => {
      if (!appState.organization?.id || !appState.userProfile) {
        return;
      }

      if (assistantSubscription.isFree) {
        analyticsService.trackGtmActivity({
          event: GTMActivity.CUSTOM_FORM_SUBMIT,
          eventCategory: GTMCategory.FORM_SUBMISSION,
          userId: `${appState.userProfile.id}`,
          email: `${appState.userProfile.email}`,
          eventAction: `Onboarding free`,
        });
      }

      goToHome(navigate, appState.organization.id, false, undefined, false, !autoGenerateContentUser);

      if (!autoGenerateContentUser) {
        showChameleonNlgTour();
      }
    },
    [appState.organization?.id, appState.userProfile, assistantSubscription.isFree, navigate, analyticsService],
  );

  const uiOnboardingQuestionnaireModel = useMemo(
    () =>
      new UIOnboardingQuestionnaireModel({
        request: requestService.api,
        analytics: analyticsService,
        organizationId: String(appState.organization?.id),
        organizationName: appState.organization?.name ?? '',
        featureFlags,
        onAfterSubmit: (opts: { autoGenerateContentUser: boolean; nlgUserQuestionnaireOptionChosen: boolean }) =>
          onAfterSubmitQuestionnaire(opts.autoGenerateContentUser),
      }),
    [
      analyticsService,
      appState.organization?.id,
      appState.organization?.name,
      featureFlags,
      onAfterSubmitQuestionnaire,
    ],
  );

  const learningCenterModel = useMemo(() => {
    if (!organizationId) {
      return undefined;
    }

    return new LearningCenterModel({
      request: requestService.api,
      organizationId,
      isEnrolledInWriterAcademy: !!userSettings.value?.academyEnrolledAt,
      refreshUserSettings: () => refreshUserSettings(),
    });
  }, [organizationId, userSettings.value?.academyEnrolledAt, refreshUserSettings]);

  useEffect(() => {
    if (!appState.organization?.id) {
      return;
    }

    uiOnboardingQuestionnaireModel.checkVisibility();
  }, [appState.organization?.id, location, uiOnboardingQuestionnaireModel]);

  useEffect(() => {
    if (isOnline) {
      closeSnackbar();
    } else {
      enqueueAlertSnackbar(
        'You’ve gone offline',
        'Any changes you make won’t be saved. Reconnect to the Internet to keep writing.',
      );
    }
  }, [closeSnackbar, enqueueAlertSnackbar, isOnline]);

  useEffect(() => {
    if (
      !config.SINGLE_TENANCY_CONFIG?.disableQuestionnaire &&
      !uiOnboardingQuestionnaireModel.loadingVisibility &&
      uiOnboardingQuestionnaireModel.visible
    ) {
      uiOnboardingQuestionnaireModel.redirectToOnboarding();
    }
  }, [uiOnboardingQuestionnaireModel]);

  //If the user has not completed the onboarding questionnaire, redirect them to finish it
  useEffect(() => {
    const isOnboardingV2 = featureFlags.get('onboardingV2', false);

    if (isOnboardingV2) {
      requestService.api
        .get('/api/user/v3/questionnaire', {})
        .then(res => {
          const response = res.data;

          if (!response.completedAt) {
            window.location.replace(SharedOnboardingRoutes.OnboardingQuestionIndustry);
          }

          return;
        })
        .catch(err => {
          console.error('Error fetching onboarding questionnaire', err);
        });
    }

    return;
  }, [featureFlags]);

  if (isUnverifiedEmail) {
    return <EmailUnconfirmedPage />; // TODO: move this to separate route
  }

  if (userProfile && teamsModel.teams?.length === 0) {
    return (
      <div className={styles.noTeamsContainer}>
        <NoTeamsPage // TODO: move this to separate route
          orgList={selectActions}
          userProfile={userProfile}
          organizationName={organization?.name || ''}
          onClickSupport={onClickSupport}
          onOrganizationChange={onOrganizationChange}
          onProfileOptionClick={onProfileOptionClick}
          onSignOutClick={signOutUser}
        />
      </div>
    );
  }

  if (userProfile && organizations?.length === 0) {
    // TODO: move this to separate route
    return (
      <div className={styles.noOrgsContainer}>
        <AutoJoin hideJoinOrg={autoJoinHideJoinOrg} analytics={analyticsService} />
      </div>
    );
  }

  if (
    !(userProfile && permissionsModel?.orgPermissions && teamsModel.teams) ||
    isAppLoading ||
    isLegacy ||
    isInvalidOrg ||
    isInvalidTeam
  ) {
    return <LoadingPage />;
  }

  const handleManageTeamPromptsClick = isTeamAdmin
    ? () => openNewTab(SETUP_ROUTES.toTeamPrompts(Number(organizationId), Number(teamId)))
    : undefined;

  const handleLearningCenterNavigationClick = (category: ILearningCenterNavigationCategory) => {
    if (category.analyticsEvent) {
      analyticsService.track(category.analyticsEvent, {});
    }

    switch (category.id) {
      case TLearningCenterCategoryId.enum.videos:
        navigate(ROUTE.toLearningCenterVideos(organizationId, teamId));
        break;
      case TLearningCenterCategoryId.enum.webinars:
        navigate(ROUTE.toLearningCenterWebinars(organizationId, teamId));
        break;
      case TLearningCenterCategoryId.enum.prompts:
        navigate(ROUTE.toLearningCenterPrompts(organizationId, teamId));
        break;
      case TLearningCenterCategoryId.enum.writeracademy:
        navigate(ROUTE.toLearningCenterWriterAcademy(organizationId, teamId));
        break;
      default:
        category.link && openNewTab(category.link);
        break;
    }
  };

  const handleLearningCloseClick = () => {
    navigate(location?.state?.prevRoute ?? ROUTE.toHome(organizationId, teamId));
  };

  const handleLearningCenterVideoClick = (id: number, route: typeof TLearningCenterVideoId.type) => {
    setLearningCenterViewedVideosList(uniq([...learningCenterViewedVideosList, id]));
    navigate(ROUTE.toLearningCenterVideo(organizationId, teamId, route));
  };

  const handleLearningCenterWebinarClick = (id: string, url: string) => {
    analyticsService.track(AnalyticsActivity.learningCenterClickedWebinarCTA, { webinarId: id });
    openNewTab(url);
  };

  const AppContent = (
    <div
      className={cx(styles.content, {
        [styles.resetOverflow]: hideTrialBannerOnSomeRoutes,
      })}
    >
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          {!assistantSubscription.isFree && (
            <>
              <Route
                path={REACT_RELATIVE_ROUTE.learningCenter}
                element={
                  learningCenterModel && (
                    <LearningCenter
                      model={learningCenterModel}
                      selectedCategoryId={getAiAssistantCategoryIdFromPath(location.pathname)}
                      navigationCategories={getLearningCenterCategories()}
                      videos={mapVideosAsViewed(QUICKSTART_VIDEOS, learningCenterViewedVideosList)}
                      onClose={handleLearningCloseClick}
                      onLoad={() => analyticsService.track(AnalyticsActivity.learningCenterOpened, {})}
                      onWebinarsLoad={() => analyticsService.track(AnalyticsActivity.learningCenterViewedWebinars, {})}
                      onNavigationClick={handleLearningCenterNavigationClick}
                      onVideoClick={handleLearningCenterVideoClick}
                      onWebinarClick={handleLearningCenterWebinarClick}
                      onEnrollInWriterAcademy={(url: string) => openNewTab(url)}
                    />
                  )
                }
              />
              <Route
                path={REACT_RELATIVE_ROUTE.learningCenterPromptLibrary}
                element={
                  promptLibraryModalModel ? (
                    <PromptLibraryModal
                      model={promptLibraryModalModel}
                      onNavigateToLearningCenter={handleOpenLearningCenter}
                      onBackClick={handleOpenLearningCenter}
                      showContactText={!permissionsModel?.isAdminOfAnyTeam}
                      emptyStateAvailable={permissionsModel?.isOrganizationAdmin || permissionsModel?.isAdminOfAnyTeam}
                      onClose={() => navigate(ROUTE.toHome(organizationId, teamId))}
                      invocationContext={PromptLibraryEventSources.LEARNING_CENTER}
                      teamName={teamsModel.teams?.find(team => team.id === teamId)?.name || ''}
                      showAddTeamPrompts={isTeamAdmin}
                      onManageTeamPromptsClick={handleManageTeamPromptsClick}
                    />
                  ) : null
                }
              />
              <Route
                path={REACT_RELATIVE_ROUTE.learningCenterVideo}
                element={
                  <LearningCenterFullScreenVideoModal
                    onNavigateToVideo={videoRoute => {
                      navigate(ROUTE.toLearningCenterVideo(organizationId, teamId, videoRoute));
                    }}
                    onNavigateToLearningCenter={() => navigate(ROUTE.toLearningCenter(organizationId, teamId))}
                    onNavigateToAskWriter={() => {
                      analyticsService.track(AnalyticsActivity.learningCenterOpenedAskWriterAnything, {});
                      openNewTab(ROUTE.toAskWriter(organizationId, teamId));
                    }}
                    onNavigateToBlogBuilder={() => {
                      analyticsService.track(AnalyticsActivity.learningCenterOpenedBlogBuilder, {});
                      openNewTab(ROUTE.toNewDocumentWithMode(organizationId, teamId, ContentEditorPageMode.BLOG));
                    }}
                    onNavigateToRecaps={() => {
                      analyticsService.track(AnalyticsActivity.learningCenterOpenedRecaps, {});
                      openNewTab(
                        ROUTE.toNewDocumentWithMode(
                          organizationId,
                          appState.teamId!,
                          ContentEditorPageMode.EVENT_TAKEAWAYS,
                        ),
                      );
                    }}
                  />
                }
              />
            </>
          )}
          <Route
            path={REACT_RELATIVE_ROUTE.homeBase}
            element={featureFlags.get('waMyWorkPage', false) ? <HomePage /> : <DashboardPageWrapper />}
          />
          <Route
            path={REACT_RELATIVE_ROUTE.home}
            element={featureFlags.get('waMyWorkPage', false) ? <HomePage /> : <DashboardPageWrapper />}
          />
          <Route path={`${REACT_RELATIVE_ROUTE.myWork}/*`} element={<MyWorkPage />} />
          <Route
            path={REACT_RELATIVE_ROUTE.drafts}
            element={
              <DraftsContextProvider>
                <DraftsPage />
              </DraftsContextProvider>
            }
          />
          <Route
            path={REACT_RELATIVE_ROUTE.editorPage}
            element={<ContentEditorPageWrapper openLearningCenter={handleOpenLearningCenter} />}
            errorElement={<ErrorRouteComponent />}
          />
          <Route path={REACT_RELATIVE_ROUTE.frameworkAppPage} element={<FrameworkApplicationPage />} />
          <Route
            path={REACT_RELATIVE_ROUTE.teamUsers}
            element={
              <PeopleContextProvider
                isSelfServe={assistantSubscription.isSelfServeCustomer}
                isEnterprise={assistantSubscription.isEnterpriseCustomer}
                isOrganizationAdmin={permissionsModel?.isOrganizationAdmin}
              >
                <PeoplePage />
              </PeopleContextProvider>
            }
          />
          <Route path={REACT_RELATIVE_ROUTE.admin} element={<AdminPanel onAiStudioClick={onAiStudioClick} />} />
          {/* Legacy extensions route, still in use in CS assets, demo docs, etc */}
          <Route path={REACT_RELATIVE_ROUTE.apps} element={<ExtensionsPage />} />
          <Route path={REACT_RELATIVE_ROUTE.extensions} element={<ExtensionsPage />} />
          <Route
            path={REACT_RELATIVE_ROUTE.profile}
            element={
              <ProfileContextProvider>
                <ProfilePage />
              </ProfileContextProvider>
            }
          />
          <Route path={`${REACT_RELATIVE_ROUTE.setup}/*`} element={<StyleguideSection />} />
          <Route path={REACT_RELATIVE_ROUTE.onboarding} element={<OnboardingPage />} />
          {/* a temporary solution to overcome the patheticness of React Router */}
          <Route path={REACT_RELATIVE_ROUTE.askBase} element={<ChatAskPage />} />
          <Route path={REACT_RELATIVE_ROUTE.askWithParam} element={<ChatAskPage />} />
          <Route
            path={REACT_RELATIVE_ROUTE.chatApps}
            element={<ChatPage onManageTeamPromptsClick={handleManageTeamPromptsClick} />}
          />
          <Route
            path={REACT_RELATIVE_ROUTE.chatAppsWithParam}
            element={<ChatPage onManageTeamPromptsClick={handleManageTeamPromptsClick} />}
          />
          <Route path={REACT_RELATIVE_ROUTE.researchAssistant} element={<ResearchAssistantPage />} />
          <Route path="*" element={<Error404Page />} />
        </Routes>
      </Suspense>
    </div>
  );

  return (
    <Fade show time={1}>
      <div className={cx('App', { bannerVisible: access.trialBannerVisible })}>
        <Fade show={access.trialBannerVisible} time={2}>
          <TopTrialBanner
            trialDaysLeft={assistantSubscription?.trialEndDays || 0}
            resendConfirmationEmail={isUnverifiedEmail}
            onResendEmailClick={onResendEmailClick}
            onBuyNowClick={permissionsModel?.isOrganizationAdmin ? onBuyNowClick : undefined}
          />
        </Fade>
        <NotificationBar />
        <div className="AppContent">
          {!isGlobalNavHidden && userProfile && teamsModel.teams && (
            <GlobalNav
              className={styles.globalNav}
              userFullName={userProfile.fullName}
              userEmail={userProfile.email || ''}
              userAvatar={
                <UserAvatar fullName={userProfile.fullName} avatarPath={userProfile.avatar} size={AvatarSize.L} />
              }
              onEditClick={onTopNavItemClick(GlobalNavSelection.EDITOR)}
              onLogoClick={onTopNavItemClick(GlobalNavSelection.DASHBOARD)}
              onSingOutClick={onSingOutOptionClick}
              onClickSupport={onClickSupport}
              currentSelection={activeRoute}
              primaryActions={primaryActions}
              subPrimaryActions={subPrimaryActions}
              secondaryActions={secondaryActions}
              onClickWelcomeTour={onClickWelcomeTour}
              teamOptions={(!access.hideTeamDropdown && teamOptions) || []}
              onChangeTeam={onTeamSelect}
              isMultiTeam={assistantSubscription.isMultiTeam}
              isLoading={!assistantSubscription.isModelReady || teamsModel.teams.length === 0}
              selectActions={selectActions}
              selectCurrent={selectCurrentAction}
              onSelectActions={onOrganizationChange}
              styleguide={portal}
              onProfileClick={onProfileOptionClick}
              isAdminMode={permissionsModel?.isOrganizationAdmin || permissionsModel?.isAdminOfAnyTeam}
              navToNewDocument={ROUTE.toEditorPage(organizationId, teamId, CREATE_NEW_DOC_ID)}
              logoNavTo={ROUTE.toHome(organizationId, teamId)}
              toolTipContent={<TutorialTipTooltipContent />}
              toolTipOpen={isTutorialTipVisible}
              onProfilePopupDrawerDropDownClick={onProfilePopupDrawerDropDownClick}
              isEnterprise={assistantSubscription.isEnterpriseCustomer}
              hideStyleguide={isPortalDisabled}
              featureFlags={{
                waMyWorkPage: featureFlags.get('waMyWorkPage', false),
              }}
              learningCenter={
                !featureFlags.get('waMyWorkPage', false) &&
                access.learningCenterGlobalNavVisible && (
                  <LearningCenterNavSection
                    onClick={handleOpenLearningCenter}
                    videosLeftToWatch={showcaseVideosLeftToView}
                  />
                )
              }
            />
          )}
          {AppContent}
        </div>
        {!cookieConsented && (
          <CookieConsent className={styles.cookieConsentNotification} onClose={acceptCookieConsent} />
        )}

        <JoinAiStudioAppModal organizationId={organizationId} />
        <AiStudioAccessPendingModal />
      </div>
    </Fade>
  );
};

export default observer(App);
