import type React from 'react';
import { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { openNewTab } from '@writercolab/dom';
import { Slideout, useCustomSnackbar, useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import {
  ChatEvents,
  ChatMessagesEvents,
  ChatModes,
  ChatTemplate,
  getDefaultMode,
  getFirstFiveWords,
} from '@writercolab/ui-chat-apps';
import { CREATE_NEW_DOC_ID } from '@writercolab/utils';

import { PromptLibraryModal } from 'components/organisms/PromptLibraryModal';
import { ChatPageUIModel } from 'components/pages/ChatPage/ChatPageModel.ui';

import { LoadingPage } from '@web/component-library';
import {
  ASK_WRITER_APP_ID,
  TPromptsLibraryQueryParams,
  TPromptsLibraryTabsId,
  TTeamPromptEditActionDto,
} from '@web/types';
import { useQueueWorkerEvents } from 'hooks/useQueueWorkerEvents';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { appLocalStorage } from 'models/localStorage';
import { Route, Routes, useLocation, useNavigate, useParams, useSearchParams } from 'react-router';
import requestService from 'services/request/requestService';

import { PromptLibraryEventSources } from '../../../constants/PromptLibrary';
import { AnalyticsActivity } from '../../../constants/analytics';
import { REACT_RELATIVE_ROUTE, ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import { TeamPromptPanel } from '../../organisms/TeamPromptPanel';

import styles from './Chat.module.css';

interface IChatProps {
  onManageTeamPromptsClick?: () => void;
}

export const ChatPage: React.FC<IChatProps> = observer(({ onManageTeamPromptsClick }) => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const { enqueueSuccessSnackbar } = useCustomSnackbar();
  const sessionBeingCreated = useRef(false);
  const [isShowInitialLoading, setIsShowInitialLoading] = useState(true);
  const goBackTo = useRef(location.state?.backTo);

  const {
    appState: { teamId, organization, organizationId, userProfile },
    appModel: {
      permissionsModel,
      analyticsService,
      assistantSubscription,
      teamsModel: { teams },
      voiceModel,
      dataRetentionModel,
      aiStudioBalance,
    },
  } = useAppState();

  const isTeamAdmin = permissionsModel?.isTeamAdmin;

  const model = useMemo(() => {
    if (!organizationId || !teamId || !userProfile || !params.appId) {
      return undefined;
    }

    return new ChatPageUIModel({
      analyticsService,
      requestService: requestService.api,
      applicationIdOrAlias: params.appId,
      sessionId: params.sessionId,
      subscriptionModel: assistantSubscription,
      aiStudioBalance,
      organizationId,
      teamId,
      voices: voiceModel?.voiceList || [],
      retentionPreferences: dataRetentionModel?.retentionPreferences,
      organizationName: organization?.name,
    });
  }, [
    organizationId,
    teamId,
    userProfile,
    params.appId,
    params.sessionId,
    analyticsService,
    assistantSubscription,
    voiceModel?.voiceList,
    dataRetentionModel?.retentionPreferences,
    organization?.name,
    aiStudioBalance,
  ]);

  if (!model) {
    return null;
  }

  const navigateToDashboard = () => {
    if (goBackTo.current) {
      return navigate(goBackTo.current);
    }

    analyticsService.track(AnalyticsActivity.appExited, {
      app_id: model.application?.id ?? '',
      app_name: model.application?.name ?? '',
      app_type: model.application?.type ?? '',
    });
    return navigate(ROUTE.toHome(organizationId, teamId));
  };

  const navigateToPromptLibrary = () => {
    navigate(
      ROUTE.toChatApp(organizationId, teamId, params.appId, model?.activeSessionId) + REACT_RELATIVE_ROUTE.prompts,
    );
  };

  // Check if ask writer feature flag is enabled
  // We should lock only Ask Writer, custom chat apps should be available
  useEffect(() => {
    if (
      assistantSubscription.access &&
      !assistantSubscription.access?.askWriter &&
      params.appId === ASK_WRITER_APP_ID
    ) {
      navigate(ROUTE.toHome(organizationId, teamId));
    }
  }, [assistantSubscription.access, navigate, organizationId, teamId, params.appId]);

  useEffect(() => {
    if (model.$application.status !== 'fulfilled' || sessionBeingCreated.current) return;

    if (params.sessionId && location.pathname.endsWith(REACT_RELATIVE_ROUTE.prompts)) return;

    const hasOpenPL = queryParams.get('openPL');

    const isPromptsPage = location.pathname.includes('prompts') || hasOpenPL;

    const handleNewSession = async () => {
      let activeSessionId = model.activeSession?.id;

      if (queryParams.get('new')) {
        sessionBeingCreated.current = true;
        const newSession = await createAndSetSession();
        sessionBeingCreated.current = false;

        if (newSession) activeSessionId = newSession.id;
      }

      // make sure url is up to date with session
      if (activeSessionId) {
        const pathSuffix = isPromptsPage ? REACT_RELATIVE_ROUTE.prompts : '';
        const baseUrl = ROUTE.toChatApp(organizationId, teamId, params.appId, activeSessionId) + pathSuffix;

        if (hasOpenPL) {
          const activeTab = queryParams.get('plTab') || TPromptsLibraryTabsId.enum.user;
          navigate(`${baseUrl}?${TPromptsLibraryQueryParams.enum.plTab}=${activeTab}`, { replace: true });
        } else {
          navigate(baseUrl, { replace: true });
        }
      }
    };

    handleNewSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId, params.appId, model.activeSession]);

  // redirect to dashboard if chat config retrieval failed
  useEffect(() => {
    if (model.$application.status === 'rejected') {
      navigate(ROUTE.toHome(organizationId, teamId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId, model.$application.status]);

  const createAndSetSession = useCallback(async () => {
    const defaultMode = getDefaultMode(model.application);

    const session = await model.sessionsApiModel.createSession(ChatModes.get(defaultMode, ChatModes.enum.chat));
    await model.sessionsApiModel.reloadSessions();

    if (session) {
      model.onSetActiveSession(session);
    }

    return session;
  }, [model]);

  useEffect(() => {
    const { sessions } = model.sessionsApiModel;

    if (Array.isArray(sessions) && isEmpty(sessions) && !model.sessionsApiModel.searchQuery) {
      setTimeout(() => {
        createAndSetSession();
      }, 100);
    }
  }, [createAndSetSession, model.sessionsApiModel, model.sessionsApiModel.sessions]);

  const chatReady =
    model.application &&
    model.activeSession &&
    model.templateUIModel.applicationUIModel.chatMode &&
    !model.isApplicationLoading &&
    !sessionBeingCreated.current;

  useEffect(() => {
    if (chatReady) {
      const payload = appLocalStorage.chatPagePromptAutoSubmit.get();

      if (payload) {
        const { submitPrompt, insertPrompt } = payload;

        appLocalStorage.chatPagePromptAutoSubmit.remove();

        if (submitPrompt) {
          window.requestAnimationFrame(() => {
            model.templateUIModel.applicationUIModel.postMessage(submitPrompt.prompt, submitPrompt.variables);
            setIsShowInitialLoading(false);
          });

          return;
        }

        if (insertPrompt) {
          model.templateUIModel.applicationUIModel.chatInputNeueUiModel?.updateContent?.(insertPrompt.prompt, true);
        }

        setIsShowInitialLoading(false);
      } else {
        setIsShowInitialLoading(false);
      }
    }
  }, [chatReady, model.templateUIModel.applicationUIModel]);

  const generalEventQueueReader = async item => {
    switch (item.type) {
      case ChatMessagesEvents.enum.sendToDocument:
        appLocalStorage.chatMessageDocumentBootstrapContent.set(item.params);
        openNewTab(ROUTE.toEditorPage(organizationId, teamId, CREATE_NEW_DOC_ID));
        break;
      case ChatEvents.enum.promptPosted:
        if (model.activeSessionId && !model.activeSession?.title) {
          const firstFiveWords = getFirstFiveWords(item.params);
          await model.templateUIModel.sessionsUIModel.onRenameSession(model.activeSessionId, firstFiveWords || '');
        }

        break;
      case ChatEvents.enum.setupKnowledgeGraph:
        navigate(ROUTE.toKnowledgeGraph(`${organizationId}`, `${teamId}`));
        break;
      default:
    }
  };

  const onOpenUrl = (url: string) => openNewTab(url);

  useQueueWorkerEvents<string>(model.eventQueues, generalEventQueueReader, false);
  useQueueWorkerNotifications(model.notificationQueue);
  useQueueWorkerNotifications(model.templateUIModel.applicationUIModel.notificationQueue);

  const navigateBack = () =>
    navigate(ROUTE.toChatApp(organizationId, teamId, params.appId, params.sessionId), { replace: true });

  const onUsePrompt = (prompt: string) => {
    const { chatInputNeueUiModel } = model.templateUIModel.applicationUIModel;
    chatInputNeueUiModel.updateContent(prompt, true);

    navigateBack();
    enqueueSuccessSnackbar('Prompt loaded into Ask Writer');
  };

  // overcoming promised model bug
  useEffect(() => {
    if (model.$application.status === 'fulfilled' && !model.application) {
      model.$application.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.$application.status]);

  const onAddTeamPrompt = isTeamAdmin
    ? (prompt: string) => {
        model.setCreateTeamPromptVisible(true, { prompt });
      }
    : undefined;

  const handleClosePanel = () => {
    model.setCreateTeamPromptVisible(false);
  };

  const onCreateTeamPrompt = async (teamPromptActionDto: TTeamPromptEditActionDto) => {
    await model.addTeamPrompt(teamPromptActionDto);
    handleClosePanel();
  };

  return (
    <div className={styles.wrapper}>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route
            path="prompts"
            element={
              <PromptLibraryModal
                model={model.promptLibraryModalModel}
                onClose={navigateBack}
                onUsePrompt={onUsePrompt}
                invocationContext={PromptLibraryEventSources.ASK_WRITER}
                teamName={teams?.find(t => t.id === Number(teamId))?.name || ''}
                showAddTeamPrompts={isTeamAdmin}
                showContactText={!isTeamAdmin}
                emptyStateAvailable={isTeamAdmin}
                onManageTeamPromptsClick={onManageTeamPromptsClick}
              />
            }
          />
        </Routes>
      </Suspense>

      <ChatTemplate
        model={model.templateUIModel}
        userFirstName={userProfile?.firstName}
        userLastName={userProfile?.lastName}
        userAvatar={userProfile?.avatar}
        isTeamAdmin={isTeamAdmin}
        onNavigateToPromptLibrary={navigateToPromptLibrary}
        onOpenUrl={onOpenUrl}
        onNavBackButtonClick={navigateToDashboard}
        onNavLogoClick={navigateToDashboard}
        onAddTeamPrompt={onAddTeamPrompt}
        isIsolated={model.isIsolated}
        isShowInitialLoading={!chatReady || isShowInitialLoading}
      />

      <Slideout isOpen={model.createTeamPromptVisible} onClose={handleClosePanel} className={styles.slideout}>
        <TeamPromptPanel
          teamPromptsApiModel={model.teamPromptsApiModel}
          teamPromptFormUiModel={() => model.teamPromptFormUIModel}
          onPromptActionClick={onCreateTeamPrompt}
          loading={model.addingTeamPrompt}
        />
      </Slideout>
    </div>
  );
});

ChatPage.displayName = 'ChatPage';
