import { useEffect, useState } from 'react';

import type { IUserProfile, IUserSalesProfile } from '@writercolab/common-utils';
import { ContentEditorPageMode, ContentEditorQueryParams } from '@writercolab/common-utils';

import { matchPath, useLocation } from 'react-router';

import { ROUTE } from '../services/config/routes';
import { useAppState } from '../state';
import { getLogger } from '../utils/logger';
import useQuery from './useQuery';

const LOG = getLogger('useHelpscout');

const { VITE_HELPSCOUT_TEAM_TRIAL_ID, VITE_HELPSCOUT_TEAM_ACTIVE_ID, VITE_HELPSCOUT_ENTERPRISE_ACTIVE_ID } = import.meta
  .env;

const enum HelpscoutEvent {
  INIT = 'init',
  IDENTIFY = 'identify',
  EVENT = 'event',
  DESTROY = 'destroy',
  SUGGEST = 'suggest',
}

export default function useHelpscout(
  isSubscriptionModelReady: boolean,
  isSubscriptionActive: boolean,
  isEnterprise: boolean,
  isTeam: boolean,
  isTrial: boolean,
  productName: string,
  userProfile?: IUserProfile,
  userSalesProfile?: IUserSalesProfile,
) {
  const helpscoutInstance = (window as any).Beacon;
  const [active, setActive] = useState(false);
  const query = useQuery();
  const location = useLocation();
  const {
    appState,
    appModel: { permissionsModel },
  } = useAppState();

  useEffect(() => {
    if (isSubscriptionModelReady) {
      let helpscoutId = '';

      if (isTeam && isTrial) {
        helpscoutId = VITE_HELPSCOUT_TEAM_TRIAL_ID!;
      } else if (isTeam && !isTrial) {
        helpscoutId = VITE_HELPSCOUT_TEAM_ACTIVE_ID!;
      } else if (isEnterprise) {
        helpscoutId = VITE_HELPSCOUT_ENTERPRISE_ACTIVE_ID!;
      }

      const isDocumentPage = location.pathname.includes('document');

      const isAIStudioPage = ['aistudio', 'console', 'app-studio'].some(path => location.pathname.includes(path));

      const isAskWriterV2Page = matchPath(
        { path: ROUTE.toAskWriter(appState.organizationId, appState.teamId), end: false },
        location.pathname,
      );

      const isChatAppPage = matchPath(
        { path: ROUTE.toChatAppBase(appState.organizationId, appState.teamId), end: false },
        location.pathname,
      );
      const pageToIgnore = isAskWriterV2Page || isChatAppPage || isDocumentPage || isAIStudioPage;
      const isCoWriterTemplateLibraryPage = [
        ContentEditorPageMode.LIBRARY,
        ContentEditorPageMode.START_WITH_LIBRARY,
      ].includes(query.get(ContentEditorQueryParams.mode) as ContentEditorPageMode);

      if (active && pageToIgnore && !isCoWriterTemplateLibraryPage) {
        helpscoutInstance(HelpscoutEvent.DESTROY);
        setActive(false);

        return;
      }

      if (
        !active &&
        (!pageToIgnore || isCoWriterTemplateLibraryPage) &&
        userProfile &&
        userSalesProfile &&
        appState.teamId
      ) {
        helpscoutInstance(HelpscoutEvent.INIT, helpscoutId);
        helpscoutInstance(HelpscoutEvent.EVENT, {
          type: 'page-viewed',
          url: document.location.href,
          title: document.title,
        });

        if (userSalesProfile && userProfile && userProfile.email) {
          const teamAdmin = permissionsModel?.isTeamAdminOf(appState.teamId);
          const { fullName, email, id } = userProfile;
          const { employees, role, leadStatus, title, companyType, industry } = userSalesProfile;
          const identifyData = {
            name: fullName,
            email,
            planName: productName,
            subscriptionStatus: isSubscriptionActive ? 'Active' : 'Trailing',
            orgName: appState.organization?.name,
            orgID: appState.organization?.id,
            userID: `${id}`,
            userRole: teamAdmin ? 'admin' : 'team member',
            companyMetricsEmployees: employees,
            companyType,
            companyCategoryIndustry: industry,
            personEmploymentTitle: title,
            personEmploymentRole: role,
            leadStatus,
          };

          helpscoutInstance(HelpscoutEvent.IDENTIFY, identifyData);
          LOG.info('beacon identify', identifyData);
        }

        helpscoutInstance(HelpscoutEvent.SUGGEST);
        LOG.info(`beacon ${HelpscoutEvent.INIT}: ${helpscoutId}`);

        setActive(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    active,
    query,
    isEnterprise,
    isTeam,
    isTrial,
    userProfile,
    isSubscriptionActive,
    isSubscriptionModelReady,
    location.pathname,
    helpscoutInstance,
    userSalesProfile,
    appState.organization,
    productName,
    appState.teamId,
  ]);

  // We should destroy the beacon when the component unmounts and hook is no longer used
  // For example, when the user switches from AI Assistant to AI Studio
  useEffect(() => {
    return () => {
      (window as any).Beacon(HelpscoutEvent.DESTROY);
    };
  }, []);
}
