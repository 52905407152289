import { useCallback } from 'react';

import { matchPath, useLocation } from 'react-router';

export const useRouteMatch = () => {
  const location = useLocation();

  const isRouteMatch = useCallback(
    (route: string, exact = false) => matchPath({ path: route, end: exact }, location.pathname),
    [location.pathname],
  );

  return isRouteMatch;
};
