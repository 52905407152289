import type React from 'react';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import type { ITimelineEvent } from '@writercolab/ui-molecules';
import { Timeline } from '@writercolab/ui-molecules';

import { observer } from 'mobx-react-lite';

import type { VersionHistoryUIModel } from './VersionHistoryModel.ui';

import styles from './VersionHistory.module.css';

export interface IVersionHistoryProps {
  onClose: () => void;
  onSelectVersion: (version: ITimelineEvent) => void;
  model: VersionHistoryUIModel;
}

export const VersionHistory: React.FC<IVersionHistoryProps> = observer(({ onClose, model, onSelectVersion }) => {
  const getContent = () => {
    if (model.isLoadingHistoryPreview) {
      return (
        <>
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} height={12} width={100} />
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} height={12} width="90%" />
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} height={12} width="90%" />
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} height={12} width={100} />
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} height={12} width="90%" />
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} height={12} width="90%" />
        </>
      );
    }

    if (model.historyPreviewFetchError) {
      return (
        <div className={styles.errorMessage}>
          <Text variant={TextSize.XXXL}>There was an error fetching version history. Please try again.</Text>
          <Button type={ButtonTypes.SECONDARY} onClick={model.refetchHistoryPreview}>
            Try again
          </Button>
        </div>
      );
    }

    return (
      <Timeline
        events={model.historyEntries}
        selectedId={model.selectedVersionId}
        onEventClick={version => onSelectVersion(version)}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.titleWrapper}>
          <Text smallCaps color={TextColor.GREY2} className={styles.subtitle}>
            Version History
          </Text>
          <Heading className={styles.contentTitle} variant={HeadingVariant.H2} bold>
            Last 30 days
          </Heading>
        </div>
        <Button
          className={styles.closeButton}
          type={ButtonTypes.GRAY_NU}
          icon={<Icon name={IconVariant.CLOSE} />}
          size={SizeTypes.XS}
          onClick={onClose}
          round
        />
      </div>
      <div className={styles.content}>{getContent()}</div>
    </div>
  );
});
