import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownOption,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputGroup } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';

import { extractPlaceholder } from '../../../utils/dropdownUtils';
import DropdownTrigger, { DropdownTriggerVariant } from '../DropdownTrigger';
import InfoIconText from '../InfoIconText/InfoIconText';

import styles from './WildcardDomainsTable.module.css';

interface IWildcardDomainsTableProps extends BaseComponentsProps {
  options: IWildcardOption[];
  restrictionOptions: DropdownOption[];
  addNewOptionDisabled: boolean;
  checkDescriptionTooltipContent?: React.ReactNode;
  onOptionChange: (opt: IWildcardOption) => void;
  onRemoveOption: (opt: IWildcardOption) => void;
  onAddNewOption: () => void;
}

export interface IWildcardOption {
  id: string;
  name: string;
  restriction: string;
  valid?: boolean | null;
  duplicate?: boolean | null;
  restrictionOptions: DropdownOption[];
}

interface IWildcardDomainsTableRowProps {
  option: IWildcardOption;
  onOptionChange: (opt: IWildcardOption) => void;
  onRemoveOption: (opt: IWildcardOption) => void;
}

const WildcardDomainsTableRow: React.FC<IWildcardDomainsTableRowProps> = ({
  option,
  onOptionChange,
  onRemoveOption,
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onOptionChange({ ...option, name: e.target.value });
    },
    [onOptionChange, option],
  );

  const handleRemove = useCallback(() => {
    onRemoveOption(option);
  }, [onRemoveOption, option]);

  const errorText = useMemo<string | undefined>(() => {
    let text: string | undefined;

    if (option.valid === false && option.duplicate === true) {
      text = 'The URL is invalid and duplicated';
    } else if (option.valid === false) {
      text = 'Please enter a valid URL';
    } else if (option.duplicate === true) {
      text = 'Please enter a unique URL';
    }

    return text;
  }, [option]);

  return (
    <>
      <div className={styles.column}>
        <InputGroup
          id={`url-${option.id}`}
          name={`url-${option.id}`}
          label=""
          value={option.name}
          onChange={handleChange}
          className={styles.input}
          disableHelperText
          style={{ borderRadius: 8 }}
          errorText={errorText}
        />
        {errorText && (
          <div className={styles.errMessage}>
            <Text variant={TextSize.XL} color={TextColor.ORANGE}>
              {errorText}
            </Text>
          </div>
        )}
      </div>
      <div className={styles.column}>
        <Dropdown
          containerClassName={styles.dropdownContainer}
          options={option.restrictionOptions}
          trigger={
            <DropdownTrigger
              placeholder={extractPlaceholder(option.restrictionOptions)}
              variant={DropdownTriggerVariant.ROUNDED}
            />
          }
          onPrimaryOptionClickAction={restriction => onOptionChange({ ...option, restriction })}
        />
      </div>
      <div className={cx(styles.column, styles.columnRowAction)}>
        <Button
          className={styles.removeButton}
          round
          icon={<Icon name={IconVariant.CLOSE} height={16} width={16} />}
          onClick={handleRemove}
        />
      </div>
    </>
  );
};

export const WildcardDomainsTable: React.FC<IWildcardDomainsTableProps> = ({
  className,
  options,
  checkDescriptionTooltipContent,
  onOptionChange,
  onRemoveOption,
  onAddNewOption,
  addNewOptionDisabled = false,
}) => (
  <div className={cx(className, styles.container)}>
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.rowHeader}>
          <div className={styles.column}>
            <Text color={TextColor.GREY2} medium>
              URL
            </Text>
          </div>
          <div className={styles.column}>
            <InfoIconText
              titleSize={TextSize.M}
              titleColor={TextColor.GREY2}
              titleMedium
              title="When to check content"
              description={checkDescriptionTooltipContent}
              tooltipWidth={250}
            />
          </div>
          <div className={styles.column} />
        </div>
      </div>
      <div className={styles.row}>
        {options.map(option => (
          <div key={`row-${option.id}`}>
            <WildcardDomainsTableRow option={option} onRemoveOption={onRemoveOption} onOptionChange={onOptionChange} />
            <div className={styles.rowBorder} />
          </div>
        ))}
      </div>
      <div
        className={cx(styles.row, {
          [styles.rowActionEmpty]: isEmpty(options),
        })}
      >
        <div className={cx(styles.column, styles.columnAction)}>
          <Button
            className={cx(styles.inputButton, {
              [styles.inputButtonDisabled]: addNewOptionDisabled,
            })}
            type={ButtonTypes.TRANSPARENT_LINK}
            icon={<Icon name={IconVariant.ADD} />}
            content={<Text variant={TextSize.L}>Add new URL</Text>}
            onClick={onAddNewOption}
            disabled={addNewOptionDisabled}
          />
        </div>
      </div>
    </div>
  </div>
);
