import { IconVariant } from '@writercolab/ui-atoms';
import type Formatter from './formatter';
import styles from './formatter.module.css';
import { MarginStyleAttributor } from '../../attributors';
import { getIconHtml } from '../../utils/shared';

interface AlignProps {
  icon: IconVariant;
  value: string;
}

export const VideoAlign = {
  LEFT: {
    icon: IconVariant.IMAGE_ALIGN_LEFT,
    value: '0px auto 0px 0px',
  } as AlignProps,
  MIDDLE: {
    icon: IconVariant.IMAGE_ALIGN_MIDDLE,
    value: '0px auto',
  } as AlignProps,
  RIGHT: {
    icon: IconVariant.IMAGE_ALIGN_RIGHT,
    value: '0px 0px 0px auto',
  } as AlignProps,
} as const;

export class Aligner {
  container: HTMLElement;
  currentAlign = VideoAlign.LEFT;
  leftAligner: HTMLElement | null = null;
  middleAligner: HTMLElement | null = null;
  rightAligner: HTMLElement | null = null;

  constructor(private formatter: Formatter) {
    this.container = this.createAlignContainer();
    formatter.overlay.appendChild(this.container);
  }

  createAlignContainer() {
    const container = document.createElement('div');
    container.classList.add(styles.alignerContainer);

    this.leftAligner = this.createAligner(VideoAlign.LEFT);
    this.middleAligner = this.createAligner(VideoAlign.MIDDLE);
    this.rightAligner = this.createAligner(VideoAlign.RIGHT);

    container.appendChild(this.leftAligner);
    container.appendChild(this.middleAligner);
    container.appendChild(this.rightAligner);

    return container;
  }

  createAligner(align: AlignProps) {
    const aligner = document.createElement('div');
    aligner.classList.add(styles.aligner);

    aligner.innerHTML = getIconHtml(align.icon);

    aligner.addEventListener('click', () => this.onAlignClick(align));

    return aligner;
  }

  onAlignClick = (align: AlignProps) => {
    if (this.formatter.currentElement) {
      MarginStyleAttributor.add(this.formatter.currentElement, align.value);
      this.refresh();
      this.formatter.refreshBreakpointHighlights();
      this.formatter.repositionOverlay();
    }
  };

  getCurrentAlign(): AlignProps {
    if (!this.formatter.currentElement) {
      return VideoAlign.LEFT;
    }

    const margin = MarginStyleAttributor.value(this.formatter.currentElement);
    const align = Object.keys(VideoAlign).find(align => VideoAlign[align as keyof typeof VideoAlign].value === margin);

    if (align) {
      return VideoAlign[align as keyof typeof VideoAlign];
    }

    return VideoAlign.LEFT;
  }

  refresh() {
    this.currentAlign = this.getCurrentAlign();
    [this.leftAligner, this.middleAligner, this.rightAligner].forEach(aligner =>
      aligner?.classList.remove(styles.alignerSelected),
    );

    switch (this.currentAlign) {
      case VideoAlign.LEFT:
        this.leftAligner?.classList.add(styles.alignerSelected);
        break;
      case VideoAlign.MIDDLE:
        this.middleAligner?.classList.add(styles.alignerSelected);
        break;
      case VideoAlign.RIGHT:
        this.rightAligner?.classList.add(styles.alignerSelected);
        break;

      default:
        break;
    }
  }

  show() {
    this.container.classList.add(styles.visibleFlex);
  }

  hide() {
    this.container.classList.remove(styles.visibleFlex);
  }
}
