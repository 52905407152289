import { isBefore } from 'date-fns';

const OLD_DISCOUNT = '$50';
const DEFAULT_DISCOUNT = '$10';
const CUTOFF_DATE = new Date('2024-08-27T11:00:00.0000Z');

export const DISCOUNT_PARAM_KEY = 'd';
export const DISCOUNT_SESSION_KEY = 'ais:discount';

export function getDiscountFromURL(
  params: URLSearchParams,
  createdAt?: string,
) {
  const discount = params.get(DISCOUNT_PARAM_KEY);

  if (discount) {
    const decoded = window.atob(discount);

    if (decoded) {
      return `$${decoded}`;
    }
  }

  if (createdAt && isBefore(new Date(createdAt), CUTOFF_DATE)) {
    return OLD_DISCOUNT;
  }

  return DEFAULT_DISCOUNT;
}

export function getDiscountFromSessionStorage() {
  const discount = window.sessionStorage.getItem(DISCOUNT_SESSION_KEY);

  return getDiscountFromURL(
    new URLSearchParams({ [DISCOUNT_PARAM_KEY]: discount ?? '' }),
  );
}
