import React from 'react';

import { Button, ButtonTypes, Heading, HeadingVariant, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IDocumentHighlightsHeaderProps {
  onHideClick: () => void;
}

export const DocumentHighlightsHeader: React.FC<IDocumentHighlightsHeaderProps> = ({ onHideClick }) => (
  <div className={styles.headerContainer}>
    <div className={styles.headerContainerTitle}>
      <Heading variant={HeadingVariant.H2}>Highlights</Heading>
    </div>
    <div>
      <Button onClick={onHideClick} type={ButtonTypes.GRAY} className={styles.headerButton}>
        <Icon name={IconVariant.CLOSE} width={14} height={14} className={styles.headerContainerButtonIcon} />
        <Text variant={TextSize.XS} bold extraSmallCaps>
          Hide
        </Text>
      </Button>
    </div>
  </div>
);

export default DocumentHighlightsHeader;
