import type { ReactNode } from 'react';
import type React from 'react';

import cx from 'classnames';

import { convertToEllipsis } from '@writercolab/common-utils';
import { DotLoader, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { Unreachable, UnreachableType } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';

import { useSticky } from './useSticky';

import styles from './BaseTable.module.css';

export interface IHeadCellProps {
  title?: string;
  className?: string;
  component?: ReactNode;
}

export interface IBodyCellProps {
  id: string | number;
  cellType?: string;
  component: ReactNode;
  className?: string;
}

export interface IRowProps<T = any> {
  id: string | number;
  cells: IBodyCellProps[];
  className?: string;
  status?: string;
  // TODO this is not work, need to update logic correctly, use onRowClick
  action?: (arg?: T) => void;
  colorStatus?: string;
  disabled?: boolean;
  active?: boolean;
  data?: T; // Additional generic field for extra row data
}

interface ICustomDocsTableProps {
  headers: IHeadCellProps[];
  data: IRowProps[];
  gridTemplateColumns: CSSStyleDeclaration['gridTemplateColumns'];
  stickyHeaderQuery?: string;
  stickyContainerQuery?: string;
  isEnableSticky?: boolean;
  onRowClick?: (row: IRowProps) => void;
  loading?: boolean;
  headerRowHeight?: string;
  customEmpty?: ReactNode;
  customLoading?: ReactNode;
  containerClassName?: string;
  headerRowClassName?: string;
  loadingContainerClassName?: string;
  headerClassName?: string;
  emptyContainerClassName?: string;
  bodyClassName?: string;
  bodyRowClassName?: string;
  isEnableStickyHeader?: boolean;
  bodyRowHeight?: string;
}

interface IBaseComponentProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
}

const Row = (props: IBaseComponentProps) => {
  const { className, style, children, onClick } = props;

  return (
    <div className={cx(styles.row, className)} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

const Cell = (props: IBaseComponentProps) => {
  const { className, style, children } = props;

  return (
    <div className={cx(styles.cell, className)} style={style}>
      {children}
    </div>
  );
};

const Head = (props: IBaseComponentProps) => {
  const { className, style, children } = props;

  return (
    <div className={cx(styles.head, className)} style={style}>
      {children}
    </div>
  );
};

const Body = (props: IBaseComponentProps) => {
  const { className, style, children } = props;

  return (
    <div className={cx(styles.body, className)} style={style}>
      {children}
    </div>
  );
};

export const BaseTable = (props: ICustomDocsTableProps) => {
  const {
    headers,
    data,
    gridTemplateColumns,
    stickyHeaderQuery = '.stickyHeader',
    stickyContainerQuery = '.scrollableContainer',
    isEnableSticky = true,
    headerRowHeight = '60px',
    onRowClick,
    loading,
    containerClassName,
    headerClassName,
    headerRowClassName,
    emptyContainerClassName,
    loadingContainerClassName,
    bodyClassName,
    bodyRowClassName,
    customEmpty,
    customLoading,
    isEnableStickyHeader = true,
    bodyRowHeight = '45px',
  } = props;

  useSticky(stickyHeaderQuery, stickyContainerQuery, isEnableSticky);

  const hasData = !isEmpty(data);
  const showLoader = loading && !hasData;
  const showEmpty = !loading && !hasData;

  return (
    <div className={cx(containerClassName, styles.container)}>
      <Head
        className={cx(headerClassName, {
          [styles.stickyHeader]: isEnableStickyHeader,
        })}
      >
        <Row
          style={{
            gridTemplateColumns,
            gridAutoRows: headerRowHeight,
          }}
          className={headerRowClassName}
        >
          {isEnableSticky && <Cell></Cell>}
          {headers.map((header, i) => (
            <Cell key={`header-${header.title}-${i}`} className={header.className}>
              {header.title && (
                <Text color={TextColor.GREY2} variant={TextSize.L}>
                  {convertToEllipsis(header.title, 20)}
                </Text>
              )}
              {header.component && header.component}
            </Cell>
          ))}
        </Row>
      </Head>
      <Body className={bodyClassName}>
        {showLoader && (
          <>
            {customLoading || (
              <div className={cx(styles.loadingContainerWrapper, loadingContainerClassName)}>
                <DotLoader />
              </div>
            )}
          </>
        )}
        {showEmpty ? (
          <div className={cx(styles.emptyContainerWrapper, emptyContainerClassName)}>
            {customEmpty || <Unreachable type={UnreachableType.enum.noResultsEmpty} />}
          </div>
        ) : (
          data?.map(row => (
            <Row
              key={row.id}
              className={cx(row.className || '', bodyRowClassName, {
                [styles.disabled]: row.disabled,
                [styles.active]: row.active,
              })}
              style={{
                gridTemplateColumns,
                gridAutoRows: bodyRowHeight,
              }}
              onClick={() => onRowClick?.(row)}
            >
              {row.cells?.map((cell, i) => (
                <Cell key={`${cell.id}-${i}`} className={cell.className || ''}>
                  {cell.component}
                </Cell>
              ))}
            </Row>
          ))
        )}
      </Body>
    </div>
  );
};
