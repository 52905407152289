import Quill, { Module } from 'quill';
import Delta from 'quill-delta';
import type { Registry as RegistryType } from 'parchment';
import type { IIssue } from '@writercolab/common-utils';
import { issueSorter } from '@writercolab/common-utils';
import {
  QA_TEXTHIGHLIGHT_FORMAT_NAME,
  QL_TEXTHIGHLIGHT_CLASS_PREFIX,
  findIssuesThatAreIntersect,
} from '@writercolab/quill-delta-utils';
import uniqBy from 'lodash/uniqBy';
import { createAnimator } from '../utils/animator';
import { TextHighlightFormat } from '../formats';
import { getLogger } from '../utils/logger';

interface ISuggestionsModuleOptions {}

const LOG = getLogger('suggestionsModule');

export const isElementInViewport = (el: HTMLElement, container: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  const containerRect = container.getBoundingClientRect();

  return (
    rect.top >= containerRect.top &&
    rect.left >= containerRect.left &&
    rect.bottom <= containerRect.bottom &&
    rect.right <= containerRect.right
  );
};

export class SuggestionsModule extends Module<ISuggestionsModuleOptions> {
  currentIssuesList = {} as Record<string, IIssue>;
  registry: RegistryType;

  constructor(quill: Quill, options: ISuggestionsModuleOptions) {
    super(quill, options);

    const { Registry } = Quill.import('parchment');

    this.registry = Registry as unknown as RegistryType;
  }

  highlightSuggestion = (issue: IIssue) => {
    const { from, length } = issue;

    if ((!from && from !== 0) || !length) {
      return;
    }

    this.quill.formatText(from, length, QA_TEXTHIGHLIGHT_FORMAT_NAME, issue, 'api');
  };

  /**
   * Removes suggestion highlights for given issues from editor
   * @param issues Issue[]
   * @returns void
   */
  removeSuggestionHighlight = (issues: IIssue[]) => {
    if (!issues.length) {
      return;
    }

    const ids = issues.map(issue => TextHighlightFormat.getIssueClass(issue)).join(',');

    const elements = this.quill.root.querySelectorAll(ids);
    elements.forEach(element => {
      const blot = this.registry.find(element);

      if (blot) {
        const index = blot.offset(this.quill.scroll);
        this.quill.formatText(index, blot.length(), QA_TEXTHIGHLIGHT_FORMAT_NAME, false, 'api');
      }
    });
  };

  /**
   * Removes all suggestion focus from editor
   * @returns
   */
  onUnfocusSuggestionHighlight = () => {
    const elements = this.quill.root.querySelectorAll(`.${QL_TEXTHIGHLIGHT_CLASS_PREFIX}`);

    if (!elements.length) {
      return;
    }

    elements.forEach(element => element.removeAttribute('data-state'));
  };

  private readonly animator = createAnimator();

  /**
   * Focuses suggestion in editor
   * @param issue Issue
   * @param skipScroll Flag if scrolling to the element is required after focus
   */

  onFocusSuggestionHighlight = (issue: IIssue, skipScroll: boolean = false) => {
    // Assuming that getIssueClass() and getIssueCategoryId() are working correctly
    const elements = this.quill.root.querySelectorAll(TextHighlightFormat.getIssueClass(issue));
    const container = document.querySelector('.ql-editor') as HTMLElement;

    if (elements.length === 0) return;

    this.onUnfocusSuggestionHighlight();

    elements.forEach(element => {
      element.setAttribute('data-state', TextHighlightFormat.getIssueCategoryId(issue));
    });

    const targetElement = elements[0] as HTMLElement;

    if (!isElementInViewport(targetElement, container) && !skipScroll) {
      const startValue = container.scrollTop;
      const endValue = targetElement.offsetTop - container.getBoundingClientRect().height / 2;

      this.animator.run(300, persent => {
        container.scrollTop = startValue + (endValue - startValue) * persent;
      });
    }
  };

  /**
   * Replaces issue highlight in given position with given replacement
   * @param issue Issue
   * @param replacement Text replacement
   * @returns void
   */
  replaceSuggestion = (issue: IIssue, replacement: string) => {
    const elements = this.quill.root.querySelectorAll(TextHighlightFormat.getIssueClass(issue));
    let fromReplace = Number.MAX_VALUE;
    let untilReplace = 0;

    if (!elements.length) {
      return;
    }

    elements.forEach(element => {
      const blot = this.registry.find(element);

      if (blot) {
        const from = blot.offset(this.quill.scroll);
        const until = from + blot.length();

        if (from < fromReplace) {
          fromReplace = from;
        }

        if (until > untilReplace) {
          untilReplace = until;
        }
      }
    });

    // if this is a delete highlight case removes extra space
    // at the end of the suggestion to avoid double space and other weird result
    if (
      replacement === '' &&
      this.quill.getText({ index: untilReplace, length: 1 }) === ' ' &&
      this.quill.getText({ index: fromReplace - 1, length: 1 }) === ' '
    ) {
      untilReplace++;
    }

    const length = untilReplace - fromReplace;

    const { texthighlight: _, ...formats } = this.quill.getFormat(fromReplace, length);
    let replacementText = replacement;

    const lineBreaksRegex = /\n+$/gi;

    // WA-1924 if both highlight and replacement ends with one or multiple line breaks
    // trim replacement to avoid situation when quill considers \n as new <p>
    if (issue.highlight && issue.highlight.match(lineBreaksRegex) && replacement.match(lineBreaksRegex)) {
      replacementText = replacement.replace(lineBreaksRegex, '');
    }

    const applyDelta = new Delta().retain(fromReplace).insert(replacementText, formats).delete(length);
    this.quill.updateContents(applyDelta, 'user');
    this.quill.focus();
    this.quill.setSelection(fromReplace + replacementText.length, 0, 'user');
  };

  private getIssuesDiff = (issues: IIssue[]) => {
    const added: IIssue[] = [];
    const cache = { ...this.currentIssuesList };

    issues.forEach(issue => {
      if (!cache[issue.issueId]) {
        added.push(issue);
      } else {
        delete cache[issue.issueId];
      }
    });

    const deleted = Object.values(cache);

    return { added, deleted };
  };

  private onHighlightSuggestion = (issues: IIssue[]) => {
    issues.forEach(issue => {
      this.highlightSuggestion(issue);
      this.currentIssuesList[issue.issueId] = issue;
    });
  };

  private onRemoveSuggestionHighlight = (issues: IIssue[]) => {
    this.removeSuggestionHighlight(issues);
    issues.forEach(issue => delete this.currentIssuesList[issue.issueId]);
  };

  private issuesHasChanged = (issues: IIssue[]) => {
    const prevIssuesHash = Object.keys(this.currentIssuesList).sort().join('_');
    const issuesHash = issues
      .map(issue => `${issue.issueId}`)
      .sort()
      .join('_');

    return prevIssuesHash !== issuesHash;
  };

  setCurrentIssuesList = (issues: IIssue[], currentIssueId?: string) => {
    if (!this.issuesHasChanged(issues)) {
      return;
    }

    const { added, deleted } = this.getIssuesDiff(issues);

    const selection = this.quill.getSelection();

    this.onRemoveSuggestionHighlight(deleted);

    // this preserves cursor position. Don't ask why (however I read the quill source and know the answer)
    this.quill.getSelection();

    // now let's find all issues that has intersection between added\deleted to avoid highlighting problems
    const issuesIntersectWithModified = findIssuesThatAreIntersect(issues, [...deleted, ...added]);

    // unhighlight related issues first
    this.onRemoveSuggestionHighlight(issuesIntersectWithModified);

    const uniqAndSortedForAdding = uniqBy([...issuesIntersectWithModified, ...added], 'issueId')
      .sort(issueSorter)
      .map(issue => {
        if (this.doesReferenceTextMatchHighlight(issue)) {
          return issue;
        }

        return this.searchForShiftedIssue(issue);
      })
      .filter(Boolean) as IIssue[];

    LOG.info('List of issues to be highlighted now:', uniqAndSortedForAdding);

    // highlight added + related suggestions (invalidation)
    this.onHighlightSuggestion(uniqAndSortedForAdding);

    const highlightedSuggestion = issues.find(issue => issue.issueId === currentIssueId);

    if (highlightedSuggestion) {
      this.onFocusSuggestionHighlight(highlightedSuggestion, true);
    }

    if (selection) {
      this.quill.setSelection(selection.index, selection.length, 'silent');
    }
  };

  /**
   * Checks if the text in editor matches issue highlight
   * @param issue Issue
   * @returns boolean
   */
  private doesReferenceTextMatchHighlight = ({ highlight, from, length }: IIssue) => {
    const referenceText = this.quill.getText({ index: from, length });

    if (referenceText !== highlight) {
      LOG.error('Actual text for issue does not match.', `actual: "${referenceText}" - expected: "${highlight}"`);
    }

    return referenceText === highlight;
  };

  /**
   * When the text of issue highlight doesn't match text in given position within editor
   * it tries to find the reference text near the issue positions (+-10 symbols) and returns updated issue
   * @param issue Issue
   * @returns New issue with updated positions
   */
  private searchForShiftedIssue = (issue: IIssue) => {
    const CONTEXT_LENGTH = 10;
    const { from, length, highlight } = issue;
    // taking {CONTEXT_LENGTH} symbols before\after expected position
    const textPlusContext = this.quill.getText({ index: from - CONTEXT_LENGTH, length: length + CONTEXT_LENGTH * 2 });

    if (highlight) {
      LOG.log('Trying to find actual position for issue.', issue);
      LOG.log('Context: ', textPlusContext);
      let searchFrom = 0;
      // sometimes the from is smaller than {CONTEXT_LENGTH}, we need to consider this when calculate new from
      const beginningShift = from < CONTEXT_LENGTH ? CONTEXT_LENGTH - from : 0;
      let match = textPlusContext.indexOf(highlight, searchFrom);
      let bestFrom = -1;

      while (match !== -1) {
        const newFrom = match - CONTEXT_LENGTH + from + beginningShift;

        if (Math.abs(newFrom - from) < Math.abs(bestFrom - from)) {
          bestFrom = newFrom;
        }

        searchFrom = match + 1;
        match = textPlusContext.indexOf(highlight, searchFrom);
      }

      if (bestFrom !== -1) {
        LOG.info('Found reference text within the context. Updating positions.');
        LOG.log('Old from:', from);
        LOG.log('New From', bestFrom);

        return {
          ...issue,
          from: bestFrom,
          until: bestFrom + length,
        };
      }

      LOG.error('Could not find reference text within the context. Skip issue.');
    }

    return undefined;
  };
}
