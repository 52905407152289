import { action, computed, makeObservable, observable } from 'mobx';

import { FieldModel } from '@writercolab/mobx';

import { TTeamPromptBrief, TTeamPromptSortDirection, TTeamPromptSortField, TeamPromptSortOptions } from '@web/types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY_MS } from '../../../services/config/constants';
import { getLogger } from '../../../utils/logger';
import { TeamPromptsTableUiModel } from '../TeamPromptsTable';

interface IPromptLibraryTeamModelOptions {
  api: TeamPromptsApiModel;
}

const LOG = getLogger('PromptLibraryTeamUIModel');

export class PromptLibraryTeamUIModel {
  searchInputField: FieldModel<string>;
  teamPromptsTableUiModel: TeamPromptsTableUiModel;

  constructor(private readonly opts: IPromptLibraryTeamModelOptions) {
    this.teamPromptsTableUiModel = new TeamPromptsTableUiModel({
      teamPromptsApiModel: this.opts.api,
    });
    this.searchInputField = FieldModel.build({
      init: () => '',
    });

    makeObservable(this, {
      searchInputField: observable,

      empty: computed.struct,
      loading: computed.struct,
      sortingOptions: computed.struct,

      setSearchPhrase: action,
      resetSearchPhrase: action,
    });
  }

  setSearchPhrase = (phrase: string) => {
    if (isEmpty(phrase)) {
      this.resetSearchPhrase();

      return;
    }

    this.searchInputField.value = phrase;
    this.debouncedSearch();
  };

  resetSearchPhrase = () => {
    LOG.debug('resetting search phrase');
    this.searchInputField.value = '';
    this.opts.api.pagination.setExtra({
      search: this.searchInputField.value,
    });
  };

  debouncedSearch = debounce(
    action(() => {
      LOG.debug('debounced search:', this.searchInputField.value);
      this.opts.api.pagination.setExtra({
        search: this.searchInputField.value,
      });
    }),
    DEFAULT_SEARCH_DEBOUNCE_DELAY_MS,
  );

  toggleFavorite = async (promptId: string) => {
    LOG.debug('toggling favorite for prompt:', promptId);
    await this.opts.api.toggleFavorite(promptId);
    this.opts.api.pagination.reload();
  };

  find = (promptId: string): TTeamPromptBrief | undefined => {
    LOG.debug('finding prompt:', promptId);

    return this.opts.api.pagination.value?.find(prompt => prompt.id === promptId);
  };

  setSortingOption(sortField: typeof TTeamPromptSortField.type) {
    let direction: typeof TTeamPromptSortDirection.type;

    if (sortField === TTeamPromptSortField.enum.createdAt) {
      direction = TTeamPromptSortDirection.enum.desc;
    } else if (sortField === TTeamPromptSortField.enum.title) {
      direction = TTeamPromptSortDirection.enum.asc;
    } else {
      direction = TTeamPromptSortDirection.enum.asc;
    }

    this.opts.api.pagination.setExtra({
      sortField,
      sortOrder: direction,
    });
  }

  get sortingOptions() {
    return TeamPromptSortOptions.map(option => ({
      ...option,
      active: option.id === this.opts.api.pagination.extra.sortField,
    }));
  }

  get loading() {
    return this.teamPromptsTableUiModel.loading;
  }

  get empty() {
    return (
      !this.teamPromptsTableUiModel.loading &&
      !this.teamPromptsTableUiModel.hasPrompts &&
      this.searchInputField.value === ''
    );
  }
}
