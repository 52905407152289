import Quill from 'quill';
import { createRoot } from 'react-dom/client';
import { Dropdown, Icon, IconVariant, Tooltip, Text, TextSize, TextColor } from '@writercolab/ui-atoms';
import { copyTextToClipboard, openNewTab } from '@writercolab/common-utils';
import type Formatter from './formatter';
import styles from './formatter.module.css';
import type { VideoFormat } from '../../formats/video';

enum VideoAction {
  DOWNLOAD = 'download',
  SHARE = 'share',
  REMOVE = 'remove',
}

const additionalOptions = [
  {
    name: 'Share video',
    id: VideoAction.SHARE,
    icon: <Icon name={IconVariant.OPEN_LINK} />,
  },
  {
    name: 'Remove video',
    id: VideoAction.REMOVE,
    icon: <Icon name={IconVariant.TRASH} />,
  },
];

export class VideoActions {
  container: HTMLElement;

  constructor(private formatter: Formatter) {
    this.container = this.createDropdownContainer();
    formatter.overlay.appendChild(this.container);
  }

  createDropdownContainer() {
    const container = document.createElement('div');
    container.classList.add(styles.dropdownContainer);

    const root = createRoot(container);

    root.render(
      <div>
        <Dropdown
          trigger={
            <div className={styles.actionButton}>
              <Icon name={IconVariant.ELLIPSES} />
            </div>
          }
          options={additionalOptions}
          onPrimaryOptionClickAction={this.onVideoActionClick}
        />
        <Tooltip
          title={
            <Text variant={TextSize.M} color={TextColor.WHITE} bold>
              Download
            </Text>
          }
          placement="right"
        >
          <div className={styles.actionButton} onClick={this.onDownloadVideoClip}>
            <Icon name={IconVariant.DOWNLOAD} />
          </div>
        </Tooltip>
      </div>,
    );

    return container;
  }

  onDownloadVideoClip = () => {
    this.onVideoActionClick(VideoAction.DOWNLOAD);
  };

  onVideoActionClick = (id: string) => {
    const { Registry } = Quill.import('parchment');
    const blot = Registry.find(this.formatter.currentElement) as VideoFormat;

    if (!blot) {
      return;
    }

    switch (id) {
      case VideoAction.DOWNLOAD:
        openNewTab(blot.getVideoUrl(true));
        break;
      case VideoAction.SHARE:
        copyTextToClipboard(blot.getVideoUrl());
        this.formatter.options.onAfterShareVideoClick?.();
        break;
      case VideoAction.REMOVE:
        blot.remove();
        this.formatter.hideOverlay();
        break;

      default:
        break;
    }
  };

  show() {
    this.container.classList.add(styles.visibleFlex);
  }

  hide() {
    this.container.classList.remove(styles.visibleFlex);
  }
}
