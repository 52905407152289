import { action, computed, makeObservable, observable } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import { ApiError } from '@writercolab/network';
import { NotificationQueueItemType, STATIC_VOICE_OPTIONS, TNotificationQueueItem } from '@writercolab/types';

import { TVoiceType } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { VoiceApiModel } from 'models/voice.api';

import { VoiceAnalyzerModalUIModel } from '../../organisms/VoiceAnalyzerModal';
import { VoiceConstructorModalUIModel } from '../../organisms/VoiceConstructorModal';

export interface IVoicePageModel {
  api: VoiceApiModel;
  analyticsService: IWebAppAnalyticsTrack;
  aiStudioMode: boolean;
}

export class VoicePageModel {
  api: VoiceApiModel;
  voiceAnalyzer: VoiceAnalyzerModalUIModel;
  voiceConstructor: VoiceConstructorModalUIModel;
  isAddVoiceModalOpen = false;
  isAnalyzerOpen = false;
  isConstructorOpen = false;
  isViewerOpen = false;
  notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

  constructor(private opts: IVoicePageModel) {
    makeObservable(this, {
      aiStudioMode: computed,
      voiceList: computed,
      voiceCount: computed,
      isLoading: computed,
      isAddVoiceModalOpen: observable,
      isAnalyzerOpen: observable,
      isConstructorOpen: observable,
      isViewerOpen: observable,
      openAddVoiceModal: action,
      closeAddVoiceModal: action,
      openAnalyzer: action,
      closeAnalyzer: action,
      openConstructor: action,
      closeConstructor: action,
      openViewer: action,
      closeViewer: action,
      refreshVoices: action,
      editVoice: action,
      calibrateVoice: action,
      viewVoice: action,
      showVoiceInRewrite: action,
      setVoiceAsDefault: action,
      deleteVoice: action,
    });

    this.api = this.opts.api;

    this.voiceAnalyzer = new VoiceAnalyzerModalUIModel({
      api: this.opts.api,
      analyticsService: this.opts.analyticsService,
      notificationQueue: this.notificationQueue,
      aiStudioMode: this.opts.aiStudioMode,
      onClose: () => {
        this.closeAnalyzer();
        this.refreshVoices();
      },
    });

    this.voiceConstructor = new VoiceConstructorModalUIModel({
      api: this.opts.api,
      analyticsService: this.opts.analyticsService,
      notificationQueue: this.notificationQueue,
      aiStudioMode: this.opts.aiStudioMode,
      onClose: () => {
        this.closeConstructor();
        this.refreshVoices();
      },
    });
  }

  get aiStudioMode() {
    return this.opts.aiStudioMode;
  }

  get voiceList() {
    return this.aiStudioMode ? this.api.appStudioVoiceList : this.api.voiceList;
  }

  get voiceCount() {
    return this.aiStudioMode ? this.api.appStudioVoiceCount : this.api.voiceCount;
  }

  get isLoading() {
    return this.aiStudioMode ? this.api.isAppStudioVoiceListLoading : this.api.isVoiceListLoading;
  }

  openAddVoiceModal = () => {
    this.isAddVoiceModalOpen = true;
  };

  closeAddVoiceModal = () => {
    this.isAddVoiceModalOpen = false;
  };

  openAnalyzer = () => {
    this.isAnalyzerOpen = true;
  };

  closeAnalyzer = () => {
    this.isAnalyzerOpen = false;
    this.voiceAnalyzer.reset();
  };

  openConstructor = () => {
    this.isConstructorOpen = true;
  };

  closeConstructor = () => {
    this.isConstructorOpen = false;
    this.voiceConstructor.reset();
  };

  openViewer = () => {
    this.isViewerOpen = true;
  };

  closeViewer = () => {
    this.isViewerOpen = false;
  };

  refreshVoices = async () => {
    try {
      if (this.opts.aiStudioMode) {
        await this.api.reloadAppStudioVoiceList();
      } else {
        await this.api.reloadVoiceList();
      }
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  editVoice = async (id: string) => {
    try {
      const voice = await this.api.getVoiceDetails(id);

      if (voice.type === TVoiceType.enum.Analyze) {
        this.isAnalyzerOpen = true;
        this.voiceAnalyzer.setIsEditingEnabled(true);
        this.voiceAnalyzer.setSelectedVoice(voice);
        this.voiceAnalyzer.setName(voice.name);
        this.voiceAnalyzer.setDescription(voice.description || '');
        this.voiceAnalyzer.setInput(voice?.sampleText || '');
        this.voiceAnalyzer.setOutput({
          profile: voice.profile || '',
          overview: voice.overview || '',
        });
      } else if (voice.type === TVoiceType.enum.Manual) {
        this.isConstructorOpen = true;
        this.voiceConstructor.setIsEditingEnabled(true);
        this.voiceConstructor.setSelectedVoice(voice);
        this.voiceConstructor.setName(voice.name);
        this.voiceConstructor.setDescription(voice.description || '');

        if (voice.settings) {
          this.voiceConstructor.setTraits(voice.settings.traits?.map(t => t.name) || []);
          this.voiceConstructor.setPreferences([
            voice.settings.pronouns,
            voice.settings.secondPerson,
            voice.settings.firstPerson,
          ]);
        }
      }
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  calibrateVoice = async (id: string) => {
    try {
      const voice = await this.api.getVoiceDetails(id);

      if (voice.type === TVoiceType.enum.Analyze) {
        this.isAnalyzerOpen = true;
        this.voiceAnalyzer.setIsCalibrationEnabled(true);
        this.voiceAnalyzer.setSelectedVoice(voice);
        this.voiceAnalyzer.setName(voice.name);
        this.voiceAnalyzer.setDescription(voice.description || '');
        this.voiceAnalyzer.setInput(voice?.sampleText || '');
        this.voiceAnalyzer.setOutput({
          profile: voice.profile || '',
          overview: voice.overview || '',
        });
      } else if (voice.type === TVoiceType.enum.Manual) {
        this.isConstructorOpen = true;
        this.voiceConstructor.setSelectedVoice(voice);
        this.voiceConstructor.setName(voice.name);
        this.voiceConstructor.setDescription(voice.description || '');

        if (voice.settings) {
          this.voiceConstructor.setTraits(voice.settings.traits?.map(t => t.name) || []);
          this.voiceConstructor.setPreferences([
            voice.settings.pronouns,
            voice.settings.secondPerson,
            voice.settings.firstPerson,
          ]);
        }
      }
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  showVoiceInRewrite = async (id: string, visible: boolean) => {
    try {
      const voice = await this.api.getVoiceDetails(id);
      await this.api.updateVoice(id, {
        name: voice.name,
        description: voice.description || '',
        type: voice.type,
        sampleText: voice.sampleText || (voice.type === TVoiceType.enum.Analyze ? '' : undefined),
        profile: voice.profile || undefined,
        overview: voice.overview || undefined,
        settings: voice.settings || undefined,
        rewrite: visible,
      });
      this.refreshVoices();
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  viewVoice = async (id: string) => {
    try {
      const voice = await this.api.getVoiceDetails(id);
      this.isViewerOpen = true;
      this.voiceAnalyzer.setSelectedVoice(voice);
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  setVoiceAsDefault = async (id: string) => {
    try {
      if (id === STATIC_VOICE_OPTIONS.DEFAULT) {
        // Make fake "Writer voice" visibly default
        await this.api.removeTeamDefaultVoice();
      } else {
        const voice = this.api.voiceList.find(voice => voice.id === id);
        await this.api.setVoiceAsDefault(id);
        this.opts.analyticsService.track(
          this.opts.aiStudioMode ? AnalyticsActivity.aiStudioVoiceSetAsDefault : AnalyticsActivity.voiceSetAsDefault,
          {
            voice_name: voice?.name,
            method: voice?.type.toLowerCase(),
          },
        );
      }

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'We’ve updated your default voice',
      });
      this.refreshVoices();
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  deleteVoice = async (id: string) => {
    try {
      const voice = this.voiceList.find(voice => voice.id === id);
      await this.api.deleteVoice(id);
      this.opts.analyticsService.track(
        this.opts.aiStudioMode ? AnalyticsActivity.aiStudioVoiceDeleted : AnalyticsActivity.voiceDeleted,
        {
          voice_name: voice?.name,
          method: voice?.type.toLowerCase(),
        },
      );
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.delete,
        message: `${voice?.name} voice was deleted`,
      });
      this.refreshVoices();
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };
}
