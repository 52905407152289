import { ReactNode } from 'react';

import cx from 'classnames';

import { FieldModel } from '@writercolab/mobx';
import { Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { Editor, EditorContent } from '@tiptap/react';
import { observer } from 'mobx-react-lite';

import styles from './TextEditorField.module.css';

interface ITextEditorFieldProps {
  children?: ReactNode;
  field: FieldModel<string>;
  editor: Editor;
}

export const TextEditorField = observer<ITextEditorFieldProps>(({ field, editor, children }) => {
  const hasError = !!field.error && field.touched;

  return (
    <div className={styles.container}>
      {hasError && (
        <div
          className={cx(styles.errorContainer, {
            [styles.noLabel]: !children,
          })}
        >
          <Text variant={TextSize.XS} caps color={TextColor.ORANGE}>
            {field.error}
          </Text>
        </div>
      )}
      {children && <div>{children}</div>}
      <div
        className={cx(styles.editorWrapper, {
          [styles.invalid]: hasError,
        })}
      >
        <EditorContent editor={editor} />
        <Icon name={IconVariant.RESIZE_TEXTAREA} className={styles.resizeIcon} />
      </div>
    </div>
  );
});
