import React, { useEffect } from 'react';

import { openNewTab } from '@writercolab/dom';
import { Heading, HeadingVariant, Modal } from '@writercolab/ui-atoms';

import { LearningCenterNavigation } from 'components/molecules/LearningCenterNavigation';

import { TLearningCenterCategoryId, TLearningCenterVideoId } from '@web/types';
import { WRITER_ACADEMY_URI } from 'constants/LearningCenter';
import { ILearningCenterNavigationCategory, ILearningCenterVideo } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { LearningCenterModel } from 'models/learningCenter';

import { LearningCenterDocs } from '../LearningCenterDocs';
import { LearningCenterVideos } from '../LearningCenterVideos';
import { LearningCenterWebinars } from '../LearningCenterWebinars';
import { LearningCenterWriterAcademy } from '../LearningCenterWriterAcademy';

import styles from './LearningCenter.module.css';

interface ILearningCenterProps {
  model: LearningCenterModel;
  selectedCategoryId: typeof TLearningCenterCategoryId.type;
  navigationCategories: ILearningCenterNavigationCategory[];
  videos?: ILearningCenterVideo[];
  onClose: () => void;
  onLoad?: () => void;
  onEnrollInWriterAcademy: (url: string) => void;
  onWebinarsLoad?: () => void;
  onNavigationClick: (category: ILearningCenterNavigationCategory) => void;
  onVideoClick?: (id: number, route: typeof TLearningCenterVideoId.type) => void;
  onWebinarClick?: (id: string, url: string) => void;
}

export const LearningCenter: React.FC<ILearningCenterProps> = observer(
  ({
    model,
    selectedCategoryId,
    navigationCategories,
    videos,
    onClose,
    onLoad,
    onEnrollInWriterAcademy,
    onWebinarsLoad,
    onNavigationClick,
    onVideoClick,
    onWebinarClick,
  }) => {
    useEffect(() => {
      onLoad?.();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleEnrollInWriterAcademy = async () => {
      const token = await model.getWriterAcademyAuthToken();
      onEnrollInWriterAcademy(`${WRITER_ACADEMY_URI}${token}`);

      if (!model.isEnrolledInWriterAcademy) {
        model.refreshUserSettings();
      }
    };

    return (
      <Modal
        open
        multiModalContainerClassName={styles.modalContainer}
        className={styles.modalContainer}
        handleClose={onClose}
      >
        <div className={styles.container}>
          <LearningCenterNavigation
            categories={navigationCategories}
            selectedCategoryId={selectedCategoryId}
            onCategoryClick={onNavigationClick}
          />

          <div className={styles.content}>
            <div className={styles.header}>
              <Heading variant={HeadingVariant.H2} upperCase>
                {navigationCategories.find(category => category.id === selectedCategoryId)?.name}
              </Heading>
            </div>

            {TLearningCenterCategoryId.match(
              selectedCategoryId,
              {
                videos: () => <LearningCenterVideos videos={videos} onVideoClick={onVideoClick} />,
                webinars: () => (
                  <LearningCenterWebinars
                    webinars={model.webinars}
                    onWebinarClick={onWebinarClick}
                    onLoad={onWebinarsLoad}
                  />
                ),
                writeracademy: () => (
                  <LearningCenterWriterAcademy
                    isLoading={model.isWriterAcademyAuthTokenLoading}
                    isEnrolled={model.isEnrolledInWriterAcademy}
                    onEnroll={handleEnrollInWriterAcademy}
                  />
                ),
                support: () => null,
                new: () => null,
                guides: () => null,
                prompts: () => null,
                docs: () => <LearningCenterDocs onClick={() => openNewTab('https://dev.writer.com/')} />,
              },
              null,
            )}
          </div>
        </div>
      </Modal>
    );
  },
);
