import type React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Heading, HeadingVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import TeamPromptEmpty from '../../../assets/backgrounds/teamPromptEmpty.svg?react';

import styles from './TeamPromptsInitialState.module.css';

interface ITeamPromptsInitialStateProps {
  className?: string;
  teamName?: string;
  onAddClick?: () => void;
  showContactText?: boolean;
}

export const TeamPromptsInitialState: React.FC<ITeamPromptsInitialStateProps> = ({
  className,
  teamName,
  onAddClick,
  showContactText,
}) => (
  <div className={cx(className, styles.container)}>
    <div className={styles.heading}>
      {teamName && (
        <div className={styles.headingText}>
          <div className={styles.headingTeamTitle}>
            <Text variant={TextSize.XXS} color={TextColor.GREY2} extraSmallCaps>
              {teamName}
            </Text>
          </div>
          <Heading variant={HeadingVariant.H2} upperCase className={styles.headingTitleText}>
            Team prompts
          </Heading>
        </div>
      )}
      <div className={styles.subheading}>
        <Text variant={TextSize.XXXXL} color={TextColor.GREY2}>
          Create and share frequently-used prompts with your team to use in Ask Writer
        </Text>
      </div>
      {showContactText && (
        <div className={styles.contact}>
          <Text variant={TextSize.XL} color={TextColor.GREY2} italic>
            Contact a team admin to get set up
          </Text>
        </div>
      )}
      {onAddClick && (
        <div className={styles.add}>
          <Button onClick={onAddClick} type={ButtonTypes.BLACK} content="Start adding" />
        </div>
      )}
      <div className={styles.bannerFrame}>
        <TeamPromptEmpty width={773} />
      </div>
    </div>
  </div>
);
