import { appLocalStorage } from '../../../../models/localStorage';

const generateStorageKey = (orgId: string, teamId: string, docId: string) => `${orgId}-${teamId}-${docId}`;

export const saveSeoBlogDraftUsed = (orgId: string, teamId: string, docId: string, draftId: string): void => {
  const seoBlogStorageKey = generateStorageKey(orgId, teamId, docId);
  const records = appLocalStorage.seoBlogDraftUsed.get() || [];
  const recordsMap = new Map(records.map(record => [Object.keys(record)[0], record]));

  recordsMap.set(seoBlogStorageKey, { [seoBlogStorageKey]: draftId });

  appLocalStorage.seoBlogDraftUsed.set(Array.from(recordsMap.values()));
};

export const getSeoBlogDraftUsed = (orgId: string, teamId: string, docId: string): string | null => {
  const seoBlogStorageKey = generateStorageKey(orgId, teamId, docId);
  const coWriteStorageRecords = appLocalStorage.seoBlogDraftUsed.get() || [];
  const targetRecords = coWriteStorageRecords.find(r => r[seoBlogStorageKey]);

  return targetRecords ? `${targetRecords[seoBlogStorageKey]}` : null;
};
