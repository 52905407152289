import React, { useEffect } from 'react';

import { BaseComponentsProps, IIdentityProviderName } from '@writercolab/common-utils';
import { IdpButton } from '@writercolab/ui-atoms';

import otherIdpImage from '../../../../assets/icons/otherIcons/otherIdps.png';
import { TUpdateProviderValue } from '../index';
import { setupIDPManagerTextConstants } from '../utils/constants';
import StepDescription from './StepDescription';

import styles from '../styles.module.css';

interface StepOneProps extends BaseComponentsProps {
  step: number;
  idp: string;
  updateProvider: (field: string, value: TUpdateProviderValue) => void;
  identityProviders: IIdentityProviderName[];
  setIsNextStepAvailable: (isNextStepAvailable: boolean) => void;
}

export const StepOne = ({ step, idp, updateProvider, identityProviders, setIsNextStepAvailable }: StepOneProps) => {
  const handleProviderClick = (prov: IIdentityProviderName) => {
    updateProvider('idpType', prov);
    setIsNextStepAvailable(true);
  };

  useEffect(() => {
    setIsNextStepAvailable(!!idp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StepDescription step={step} description={setupIDPManagerTextConstants.descriptionStepOne} />
      <div className={styles.identityProviders}>
        {identityProviders.map(prov => (
          <IdpButton
            key={prov.idp}
            imageUrl={prov.imageUrl || otherIdpImage}
            isSelected={idp === prov.name}
            onClick={() => handleProviderClick(prov)}
          />
        ))}
      </div>
    </>
  );
};

export default StepOne;
