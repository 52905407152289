import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import type {
  ICreateTermsWithFails,
  ITableFilter,
  ITerm,
  ITermCreateAndUpdate,
  Pagination,
  TAddTermsState,
} from '@writercolab/common-utils';
import {
  BatchSubmitFailHandlingTypes,
  SearchQueryParam,
  SortFieldTypes,
  SortOrders,
  TermType,
  TermsSortingTypes,
  delay,
  downloadBlobAsFilename,
  getTeamDetailsExtended,
  getTeams,
  termToAddTermsStateMapper,
  toTagFilters,
} from '@writercolab/common-utils';
import { transformNbsp } from '@writercolab/quill-delta-utils';
import { AVATAR_COLOR, useCustomSnackbar, useDebounce } from '@writercolab/ui-atoms';
import type { PerPageOption } from '@writercolab/ui-molecules';

import { DOWNLOAD_TYPE } from '../components/pages/termsAndSnippetsShared/common';

import { TERMS_PAGE_CONFIG, snackbarMessages, useLocalStorage } from '@web/component-library';
import type { ActionMap, IMappedTeamDetails } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import trim from 'lodash/trim';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useAppState } from 'state';

import useQuery from '../hooks/useQuery';
import { DEFAULT_TERMS_FETCH_LIMIT } from '../services/config/constants';
import {
  createTermsRequest,
  deleteTerms,
  downloadTermsListAsCsv,
  downloadTermsListAsXlsx,
  getTermById,
  getTermsListRequest,
  getTermsTagsRequest,
  updateTermsRequest,
} from '../services/request/terms';
import type { TAppState } from '../state/types';
import { extractBackendResponseErrorMessage, isTermDuplicateError } from '../utils/backendErrorUtils';
import { getLogger } from '../utils/logger';
import { transformFiltersToQuery } from '../utils/queryFiltersUtils';
import { getTeamColor, teamDetailsMapper } from '../utils/teamUtils';

const LOG = getLogger('termsContext');

interface ITermsContext {
  termsContext: TTermsState;
  handleSearch: (searchTerm: string) => void;
  handleClipMode: (clipMode: TermsClipMode) => void;
  handleDownloadTerms: (filetype: string) => void;
  handleOffsetChange: (value: PerPageOption) => void;
  handlePaginationChange: (object: any, value: number) => void;
  handleSortingChange: (value) => void;
  handleFilterChange: (key: TermsFilterKey, filters: ITableFilter[]) => void;
  handleOpenNewTermPopup: () => void;
  handleCloseTermPopup: (refreshList?: boolean) => void;
  handleTermSubmit: (models: ITermCreateAndUpdate) => Promise<ITerm>; // MOVE
  handleBulkTermsSubmit: (models: ITermCreateAndUpdate[]) => Promise<ICreateTermsWithFails>;
  handleOpenImportTermsPopup: () => void;
  handleCloseImportTermsPopup: () => void;
  handleOpenEditTerm: (termId: ITerm['id']) => void;
  handleMultiSelect: (data: ITerm[]) => void;
  handleToggleModal: (flag: boolean) => void;
  handleToggleTermIdModal: (flag: boolean) => void;
  handleDeleteTerms: () => void;
  handleDeleteTermById: (id: number) => void;
  handleSuggestedTermsSearch: (searchTerm: string) => void; // MOVE
  handleSuggestedTermCreate: (models: ITermCreateAndUpdate) => Promise<ITerm>; // MOVE
}

const TermsContext = createContext<ITermsContext>({} as ITermsContext);

export enum TermsAccessEnum {
  ADMIN = 'has full access as he is the admin',
  TEAM_MEMBER = 'current team`s terms where user is team member',
  TEAM_MEMBER_ANOTHER_TEAM = 'terms is of another team where user is team member',
}

export enum TermsClipMode {
  INLINE = 'clip_text',
  WRAP_TEXT = 'wrap_text',
}

export interface TeamDetails {
  team: IMappedTeamDetails;
  manageTeam?: IMappedTeamDetails;
  termBank?: {
    id: number;
  };
  snippet?: {
    teamId?: number;
    teamName: string;
  };
}

export enum TermsFilterKey {
  TAGS = 'tags',
}

const cleanUpTermsModels = (models: ITermCreateAndUpdate[]): ITermCreateAndUpdate[] =>
  models.map(model => ({
    ...model,
    term: transformNbsp(model.term),
    description: model.description && transformNbsp(model.description),
  }));

interface TTermsState {
  isLoading: boolean;
  isTermsLoaded: boolean;
  isTermsTagsListLoading: boolean;
  isSingleTermLoading: boolean;
  showError404: boolean;
  downloadInProgress: boolean;
  termBankId: number;
  currentTeam?: IMappedTeamDetails;
  manageTeam?: IMappedTeamDetails;
  termsList: ITerm[];
  termsTotalCount: number | null;
  clipMode: TermsClipMode;
  searchTerm: string;
  termId: number;
  debouncedSearchTerm: string;
  offset: number;
  limit: number;
  sortField: SortFieldTypes | null;
  sortOrder: SortOrders | null;
  isAddTermPopupOpen: boolean;
  isImportTermsPopupOpen: boolean;
  openedTerm: TAddTermsState | undefined;
  selectedTerms: ITerm[];
  isDeletePopupOpen: boolean;
  tags: string[]; // MOVE
  termsAccess: TermsAccessEnum;
  isDeleteTermIdPopupOpen?: boolean;
  suggestedTermsList?: ITerm[]; // MOVE ?
  suggestedTermsSearchTerm?: string; // MOVE ?
  debouncedSuggestedTermsSearchTerm?: string; // MOVE ?
  filters: Record<string, ITableFilter[]>;
}

enum TTermActionType {
  SetIsLoading = 'isLoading',
  SetIsTermsLoaded = 'isTermsLoaded',
  SetIsSingleTermLoading = 'isSingleTermLoading',
  SetShowError404 = 'showError404',
  SetDownloadInProgress = 'downloadInProgress',
  SetCurrentTeam = 'SetCurrentTeam',
  SetTermsList = 'termsList',
  SetSuggestedTermsList = 'suggestedTermsList',
  SetTermsTagsList = 'termsTagsList',
  SetTermsTagsListLoading = 'termsTagsListLoading',
  SetTermsTotalCount = 'termsTotalCount',
  SetClipMode = 'clipMode',
  SetSearchTerm = 'SetSearchTerm',
  SetDebouncedSearchTerm = 'SetDebouncedSearchTerm',
  SetSuggestedTermsSearchTerm = 'SetSuggestedTermsSearchTerm',
  SetDebouncedSuggestedTermsSearchTerm = 'SetDebouncedSuggestedTermsSearchTerm',
  SetSortField = 'sortField',
  SetSortOrder = 'sortOrder',
  SetTermId = 'termId',
  SetPaginationLimits = 'SetPaginationLimits',
  SetIsAddTermPopupOpen = 'isAddTermPopupOpen',
  SetIsImportTermsPopupOpen = 'isImportTermsPopupOpen',
  SetOpenedTerm = 'openedTerm',
  SetSelectedTerms = 'selectedTerms',
  SetOpenDeletePopup = 'isDeletePopupOpen',
  SetOpenDeleteTermIdPopup = 'isDeleteTermIdPopupOpen',
  Reset = 'reset',
  SetFilters = 'SetFilters',
}

export interface PaginationExtended {
  limit?: number;
  offset?: number;
  sortField?: SortFieldTypes;
  sortOrder?: SortOrders;
  clipMode: TermsClipMode;
  termId?: number;
  searchTerm?: string;
  debouncedSearchTerm?: string;
}

type TTermPayload = {
  [TTermActionType.SetIsLoading]: boolean;
  [TTermActionType.SetIsTermsLoaded]: boolean;
  [TTermActionType.SetTermsTagsListLoading]: boolean;
  [TTermActionType.SetIsSingleTermLoading]: boolean;
  [TTermActionType.SetShowError404]: boolean;
  [TTermActionType.SetDownloadInProgress]: boolean;
  [TTermActionType.SetCurrentTeam]: TeamDetails & { access: TermsAccessEnum };
  [TTermActionType.SetTermsList]: ITerm[];
  [TTermActionType.SetSuggestedTermsList]: ITerm[];
  [TTermActionType.SetTermsTagsList]: string[];
  [TTermActionType.SetTermsTotalCount]: number;
  [TTermActionType.SetClipMode]: TermsClipMode;
  [TTermActionType.SetSearchTerm]: string;
  [TTermActionType.SetDebouncedSearchTerm]: string;
  [TTermActionType.SetSuggestedTermsSearchTerm]: string;
  [TTermActionType.SetDebouncedSuggestedTermsSearchTerm]: string;
  [TTermActionType.SetSortField]: SortFieldTypes;
  [TTermActionType.SetSortOrder]: SortOrders;
  [TTermActionType.SetTermId]: number;
  [TTermActionType.SetPaginationLimits]: Pagination;
  [TTermActionType.SetIsAddTermPopupOpen]: boolean;
  [TTermActionType.SetIsImportTermsPopupOpen]: boolean;
  [TTermActionType.SetOpenedTerm]: TAddTermsState | null;
  [TTermActionType.SetSelectedTerms]: ITerm[];
  [TTermActionType.SetOpenDeletePopup]: boolean;
  [TTermActionType.SetOpenDeleteTermIdPopup]: boolean;
  [TTermActionType.Reset]: PaginationExtended;
  [TTermActionType.SetFilters]: {
    key: TermsFilterKey;
    filters: ITableFilter[];
  };
};

type TermActions = ActionMap<TTermPayload>[keyof ActionMap<TTermPayload>];

const initialTermsState: TTermsState = {
  isLoading: true,
  isTermsLoaded: false,
  isTermsTagsListLoading: true,
  isSingleTermLoading: false,
  showError404: false,
  downloadInProgress: false,
  currentTeam: undefined,
  manageTeam: undefined,
  termBankId: 0,
  termsList: [],
  termsTotalCount: null,
  clipMode: TermsClipMode.INLINE,
  searchTerm: '',
  debouncedSearchTerm: '',
  offset: 0,
  limit: DEFAULT_TERMS_FETCH_LIMIT,
  sortField: SortFieldTypes.MODIFICATION_TIME,
  sortOrder: SortOrders.DESC,
  termId: 0,
  isAddTermPopupOpen: false,
  isImportTermsPopupOpen: false,
  openedTerm: undefined,
  selectedTerms: [],
  isDeletePopupOpen: false,
  termsAccess: TermsAccessEnum.TEAM_MEMBER,
  tags: [],
  suggestedTermsList: [],
  suggestedTermsSearchTerm: '',
  debouncedSuggestedTermsSearchTerm: '',
  filters: { tags: [] },
};

const termsContextReducer = (state: TTermsState, action: TermActions) => {
  let newState: TTermsState;

  switch (action.type) {
    case TTermActionType.SetCurrentTeam:
      newState = {
        ...state,
        currentTeam: action.payload.team,
        manageTeam: action.payload.manageTeam,
        termBankId: action.payload?.termBank?.id || 0,
        termsAccess: action.payload.access,
      };
      break;
    case TTermActionType.SetSearchTerm:
      newState = { ...state, offset: 0, searchTerm: action.payload };

      if (state.searchTerm.length === 0 && action.payload.length > 0) {
        newState = { ...newState, sortField: null, sortOrder: null };
      }

      break;
    case TTermActionType.SetSuggestedTermsSearchTerm:
      newState = { ...state, offset: 0, suggestedTermsSearchTerm: action.payload };
      break;
    case TTermActionType.SetTermId:
      newState = { ...state, termId: action.payload };
      break;
    case TTermActionType.SetTermsTagsList:
      newState = {
        ...state,
        tags: map(action.payload, trim),
      };
      break;
    case TTermActionType.SetPaginationLimits:
      newState = { ...state, limit: action.payload?.limit, offset: action.payload?.offset };
      break;
    case TTermActionType.SetDebouncedSearchTerm:
      newState = { ...state, offset: 0, debouncedSearchTerm: action.payload };
      break;
    case TTermActionType.SetDebouncedSuggestedTermsSearchTerm:
      newState = { ...state, offset: 0, debouncedSuggestedTermsSearchTerm: action.payload };
      break;
    case TTermActionType.SetShowError404:
      newState = { ...state, isLoading: false, showError404: action.payload };
      break;
    case TTermActionType.Reset:
      newState = {
        ...initialTermsState,
        sortField: action.payload?.sortField || SortFieldTypes.MODIFICATION_TIME,
        offset: action.payload?.offset || 0,
        sortOrder: action.payload?.sortOrder || SortOrders.DESC,
        limit: action.payload?.limit || DEFAULT_TERMS_FETCH_LIMIT,
        clipMode: action.payload?.clipMode,
        termId: action.payload?.termId || 0,
        searchTerm: action.payload?.searchTerm || '',
        debouncedSearchTerm: action.payload?.debouncedSearchTerm || '',
      };
      break;
    case TTermActionType.SetFilters:
      newState = { ...state, filters: { ...state.filters, [action.payload.key]: action.payload.filters } };
      break;
    case TTermActionType.SetIsLoading:
    case TTermActionType.SetIsTermsLoaded:
    case TTermActionType.SetIsSingleTermLoading:
    case TTermActionType.SetDownloadInProgress:
    case TTermActionType.SetTermsList:
    case TTermActionType.SetTermsTotalCount:
    case TTermActionType.SetClipMode:
    case TTermActionType.SetSortField:
    case TTermActionType.SetSortOrder:
    case TTermActionType.SetIsAddTermPopupOpen:
    case TTermActionType.SetIsImportTermsPopupOpen:
    case TTermActionType.SetOpenedTerm:
    case TTermActionType.SetSelectedTerms:
    case TTermActionType.SetOpenDeletePopup:
    case TTermActionType.SetOpenDeleteTermIdPopup:
    case TTermActionType.SetSuggestedTermsList:
      newState = { ...state, [action.type]: action.payload };
      break;
    case TTermActionType.SetTermsTagsListLoading:
      newState = { ...state, isTermsTagsListLoading: action.payload };
      break;
    default:
      newState = { ...state };
  }

  return newState;
};

interface ITermsContextProvider {
  organizationId: number | undefined;
  teamId: TAppState['teamId'];
  rootTeamId: TAppState['teamId'];
  children?: ReactNode;
}

const TermsContextProvider: React.FC<ITermsContextProvider> = observer(
  ({ organizationId, teamId, rootTeamId, children }) => {
    const query = useQuery();
    const {
      appModel: { permissionsModel, analyticsService },
    } = useAppState();
    const navigate = useNavigate();
    const [termsConfig, setTermsConfig] = useLocalStorage(TERMS_PAGE_CONFIG, {
      clipMode: TermsClipMode.INLINE,
    });

    const { enqueueBasicSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();
    const [termsContext, dispatchTermsContext] = useReducer(termsContextReducer, initialTermsState);

    const {
      currentTeam,
      termBankId,
      offset,
      searchTerm,
      debouncedSearchTerm,
      sortField,
      sortOrder,
      limit,
      termId,
      suggestedTermsSearchTerm,
      debouncedSuggestedTermsSearchTerm,
      tags,
      filters,
      isTermsTagsListLoading,
    } = termsContext;

    const fetchTermsTagsList = useCallback(async () => {
      try {
        dispatchTermsContext({ type: TTermActionType.SetTermsTagsListLoading, payload: true });
        const { tags } = await getTermsTagsRequest(organizationId, termBankId);

        dispatchTermsContext({ type: TTermActionType.SetTermsTagsList, payload: tags.sort() });
      } catch (e) {
        LOG.error(e);
      } finally {
        dispatchTermsContext({ type: TTermActionType.SetTermsTagsListLoading, payload: false });
      }
    }, [organizationId, termBankId]);

    const fetchTermsList = useCallback(async () => {
      dispatchTermsContext({ type: TTermActionType.SetIsLoading, payload: true });
      const tags = filters.tags.filter(tag => tag.isSelected).map(tag => tag.name) || [];

      const params = {
        offset,
        limit,
        tags,
        search: debouncedSearchTerm,
        sortField: sortField || SortFieldTypes.MODIFICATION_TIME,
        sortOrder: sortOrder || SortOrders.DESC,
      };

      try {
        const { result, totalCount } = await getTermsListRequest(organizationId, termBankId, params);

        dispatchTermsContext({ type: TTermActionType.SetTermsList, payload: result });
        dispatchTermsContext({ type: TTermActionType.SetTermsTotalCount, payload: totalCount });
        dispatchTermsContext({
          type: TTermActionType.SetSuggestedTermsList,
          payload: result.filter(term => term.type === TermType.APPROVED),
        });
        dispatchTermsContext({ type: TTermActionType.SetIsTermsLoaded, payload: true });
      } catch (e) {
        LOG.error(e);
      } finally {
        dispatchTermsContext({ type: TTermActionType.SetIsLoading, payload: false });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      debouncedSearchTerm,
      filters.tags,
      isTermsTagsListLoading,
      limit,
      offset,
      organizationId,
      sortField,
      sortOrder,
      termBankId,
    ]);

    const fetchSuggestedTermsList = useCallback(async () => {
      const params = {
        search: debouncedSuggestedTermsSearchTerm,
        searchFields: 'term',
      };

      try {
        const { result } = await getTermsListRequest(organizationId, termBankId, params, { type: TermType.APPROVED });

        dispatchTermsContext({
          type: TTermActionType.SetSuggestedTermsList,
          payload: result,
        });

        return result;
      } catch (e) {
        LOG.error(e);

        return Promise.resolve([]);
      }
    }, [debouncedSuggestedTermsSearchTerm, organizationId, termBankId]);

    /**
     * Code for Download Button functionality
     */
    const handleDownloadTerms = async (filetype: string) => {
      dispatchTermsContext({ type: TTermActionType.SetDownloadInProgress, payload: true });
      const tags = filters.tags.filter(tag => tag.isSelected).map(tag => tag.name) || [];

      const params = {
        offset,
        search: debouncedSearchTerm,
        tags,
        sortField: sortField || SortFieldTypes.MODIFICATION_TIME,
        sortOrder: sortOrder || SortOrders.DESC,
      };

      try {
        if (filetype === DOWNLOAD_TYPE.CSV) {
          const blob = await downloadTermsListAsCsv(organizationId, termBankId, params);
          downloadBlobAsFilename(blob, 'terms.csv');
        } else {
          const blob = await downloadTermsListAsXlsx(organizationId, termBankId, params);
          downloadBlobAsFilename(blob, 'terms.xlsx');
        }
      } catch (e) {
        LOG.error(e);
      } finally {
        await delay(1500);
        dispatchTermsContext({ type: TTermActionType.SetDownloadInProgress, payload: false });
      }
    };

    /**
     * Code for ClipMode functionality
     * */
    const handleClipMode = (value: TermsClipMode) => {
      dispatchTermsContext({ type: TTermActionType.SetClipMode, payload: value });
      /* Todo: update this to append to current config */
      setTermsConfig({
        clipMode: value,
      });
    };

    /**
     * Code for change offset functionality
     * */
    const handleOffsetChange = (value: PerPageOption) => {
      query.set(SearchQueryParam.limit, value.name);
      navigate({ search: query.toString() });

      dispatchTermsContext({ type: TTermActionType.SetPaginationLimits, payload: { limit: value.value, offset: 0 } });
    };

    const processSortingChange = ({ sortField, sortDirection }) => {
      query.set(SearchQueryParam.sortField, sortField);
      query.set(SearchQueryParam.sortOrder, sortDirection);
      navigate({ search: query.toString() });

      dispatchTermsContext({ type: TTermActionType.SetSortField, payload: sortField });
      dispatchTermsContext({ type: TTermActionType.SetSortOrder, payload: sortDirection });
    };

    const handleFilterChange = (key: TermsFilterKey, tableFilters: ITableFilter[]) => {
      const filtersQuery = transformFiltersToQuery(tableFilters);

      dispatchTermsContext({
        type: TTermActionType.SetFilters,
        payload: {
          key,
          filters: tableFilters,
        },
      });

      query.set(key, filtersQuery);
      navigate({ search: query.toString() });
    };

    /**
     * Code for change sorting functionality
     * */
    const handleSortingChange = (sortFieldType: TermsSortingTypes) => {
      switch (sortFieldType) {
        case TermsSortingTypes.TITLE_ASC:
        case TermsSortingTypes.TITLE_DESC:
          processSortingChange({
            sortField: SortFieldTypes.TERM,
            sortDirection: TermsSortingTypes.TITLE_ASC === sortFieldType ? SortOrders.ASC : SortOrders.DESC,
          });
          break;
        case TermsSortingTypes.TYPE_ASC:
        case TermsSortingTypes.TYPE_DESC:
          processSortingChange({
            sortField: SortFieldTypes.TYPE,
            sortDirection: TermsSortingTypes.TYPE_ASC === sortFieldType ? SortOrders.ASC : SortOrders.DESC,
          });
          break;
        case TermsSortingTypes.MODIFICATION_DATE_ASC:
        case TermsSortingTypes.MODIFICATION_DATE_DESC:
          processSortingChange({
            sortField: SortFieldTypes.MODIFICATION_TIME,
            sortDirection: TermsSortingTypes.MODIFICATION_DATE_ASC === sortFieldType ? SortOrders.DESC : SortOrders.ASC,
          });
          break;
      }
    };

    /**
     * Code for change pagination functionality
     * */
    const handlePaginationChange = useCallback(
      (mouseEvent: React.MouseEvent<HTMLElement>, pageNumber: number) => {
        const offset = (pageNumber - 1) * limit;

        query.set(SearchQueryParam.offset, `${offset}`);
        navigate({ search: query.toString() });

        dispatchTermsContext({ type: TTermActionType.SetPaginationLimits, payload: { limit, offset } });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [limit, termBankId],
    );

    /**
     * Code for Search functionality
     * */
    const handleSearch = (value: string) => {
      dispatchTermsContext({ type: TTermActionType.SetSearchTerm, payload: value });
    };
    const debounceSearchCallback = (value: string) => {
      value ? query.set(SearchQueryParam.search, value) : query.delete(SearchQueryParam.search);
      dispatchTermsContext({ type: TTermActionType.SetDebouncedSearchTerm, payload: value });
      navigate({ search: query.toString() });
    };
    useDebounce(searchTerm, 500, debounceSearchCallback);
    /**
     * Code for Suggested Terms Search functionality
     * */
    const handleSuggestedTermsSearch = (value: string) => {
      dispatchTermsContext({ type: TTermActionType.SetSuggestedTermsSearchTerm, payload: value });
    };
    const debounceSearchSuggestedTermsCallback = (value: string) => {
      dispatchTermsContext({ type: TTermActionType.SetDebouncedSuggestedTermsSearchTerm, payload: value });
    };
    useDebounce(suggestedTermsSearchTerm ?? '', 500, debounceSearchSuggestedTermsCallback);

    /**
     * Code for AddTermPopup
     * */
    const handleOpenNewTermPopup = () => {
      dispatchTermsContext({ type: TTermActionType.SetOpenedTerm, payload: null });
      dispatchTermsContext({ type: TTermActionType.SetIsAddTermPopupOpen, payload: true });
    };

    const handleCloseTermPopup = (refreshList?: boolean) => {
      dispatchTermsContext({ type: TTermActionType.SetIsAddTermPopupOpen, payload: false });
      dispatchTermsContext({ type: TTermActionType.SetTermId, payload: 0 });
      dispatchTermsContext({ type: TTermActionType.SetOpenedTerm, payload: null });

      query.delete(SearchQueryParam.entityId);
      navigate({ search: query.toString() });

      refreshList && Promise.all([fetchTermsTagsList(), fetchTermsList()]);
    };

    const handleTermSubmit = async (model: ITermCreateAndUpdate) => {
      if (model.id) {
        const updatedTerm = await updateTerm(model);
        await fetchTermsTagsList();

        return updatedTerm;
      }

      const { models: createTermsModels } = await createTerm([model]);
      const termSubmitted = first(createTermsModels) as ITerm;

      handleOpenEditTerm(termSubmitted.id);
      enqueueBasicSnackbar(snackbarMessages.terms.createSuccess());

      await fetchTermsList();

      return termSubmitted;
    };

    const handleBulkTermsSubmit = async (models: ITermCreateAndUpdate[]) => {
      const createdTermsData = await createTermsRequest(
        organizationId,
        termBankId,
        models,
        BatchSubmitFailHandlingTypes.ACCUMULATE,
      );
      await fetchTermsList();

      return createdTermsData;
    };

    const handleOpenImportTermsPopup = () => {
      analyticsService.track(AnalyticsActivity.termsClickedImportCSV, {});

      dispatchTermsContext({ type: TTermActionType.SetIsImportTermsPopupOpen, payload: true });
    };

    const handleCloseImportTermsPopup = () => {
      dispatchTermsContext({ type: TTermActionType.SetIsImportTermsPopupOpen, payload: false });
    };

    const getTermTypeString = (type: TermType | string) => (type === TermType.BANNED ? 'don’t use' : type);

    const updateTerm = async (model: ITermCreateAndUpdate): Promise<ITerm> => {
      if (teamId) {
        analyticsService.track(AnalyticsActivity.termEdited, {
          term_type: getTermTypeString(model.type),
        });
      }

      const _models = cleanUpTermsModels([model]);
      const { models: updateTermsModels } = await updateTermsRequest(organizationId, termBankId, _models);
      const updatedTerm = first(updateTermsModels) as ITerm;

      const existingTerm = termsContext.termsList.find(term => term.id === updatedTerm.id);

      if (existingTerm) {
        const updatedList = termsContext.termsList.map(term => {
          if (term.id !== updatedTerm.id) {
            return term;
          }

          return updatedTerm;
        });

        dispatchTermsContext({ type: TTermActionType.SetTermsList, payload: updatedList });
      }

      return updatedTerm;
    };

    const createTerm = async (
      models: ITermCreateAndUpdate[],
      failHandling?: BatchSubmitFailHandlingTypes,
    ): Promise<ICreateTermsWithFails> => {
      if (teamId) {
        analyticsService.track(AnalyticsActivity.termAdded, {
          term_type: getTermTypeString(first<ITermCreateAndUpdate>(models)?.type as TermType),
        });
      }

      const _models = cleanUpTermsModels(models);
      let createdTerm;

      try {
        createdTerm = await createTermsRequest(organizationId, termBankId, _models, failHandling);

        return createdTerm;
      } catch (err) {
        LOG.error(err);

        if (isTermDuplicateError(err)) {
          enqueueErrorSnackbar(snackbarMessages.terms.createDuplicateError);
        } else {
          enqueueErrorSnackbar(extractBackendResponseErrorMessage(err));
        }

        throw err;
      }
    };

    const handleSuggestedTermCreate = async (model: ITermCreateAndUpdate): Promise<ITerm> => {
      const { models } = await createTerm([model]);
      await fetchTermsList();

      return first(models) as ITerm;
    };

    const handleOpenEditTerm = useCallback(
      (termId: ITerm['id']) => {
        query.set(SearchQueryParam.entityId, `${termId}`);
        navigate({ search: query.toString() });
        dispatchTermsContext({ type: TTermActionType.SetTermId, payload: termId });
      },
      [navigate, query],
    );

    /**
     * Code for multi-select and delete
     * */
    const handleMultiSelect = useCallback((data: ITerm[]) => {
      dispatchTermsContext({ type: TTermActionType.SetSelectedTerms, payload: data });
    }, []);

    const handleToggleModal = (flag: boolean) => {
      dispatchTermsContext({ type: TTermActionType.SetOpenDeletePopup, payload: flag });
    };

    const handleToggleTermIdModal = (flag: boolean) => {
      dispatchTermsContext({ type: TTermActionType.SetOpenDeleteTermIdPopup, payload: flag });
    };

    const handleDeleteTerms = async () => {
      const termIds = termsContext.selectedTerms.map(d => d.id);

      await deleteTerms(organizationId, termBankId, termIds);
      handleCloseTermPopup();

      return fetchTermsList();
    };

    const handleDeleteTermById = async (termId: ITerm['id']) => {
      await deleteTerms(organizationId, termBankId, [termId]);
      handleCloseTermPopup();

      return fetchTermsList();
    };

    const selectTermById = useCallback(
      async (termId: ITerm['id']) => {
        if (!termId) {
          return;
        }

        dispatchTermsContext({ type: TTermActionType.SetIsSingleTermLoading, payload: true });

        const data = await getTermById(organizationId, Number(teamId), termId);
        const _mappedTerm = termToAddTermsStateMapper(data);

        dispatchTermsContext({ type: TTermActionType.SetIsSingleTermLoading, payload: false });
        dispatchTermsContext({ type: TTermActionType.SetOpenedTerm, payload: _mappedTerm });
        dispatchTermsContext({ type: TTermActionType.SetIsAddTermPopupOpen, payload: true });
      },
      [organizationId, teamId],
    );

    /**
     * Code for fetching termsList at various points during the terms lifecycle
     * */
    useEffect(() => {
      currentTeam && fetchTermsList();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTeam, debouncedSearchTerm, offset, limit, sortField, sortOrder]);

    useEffect(() => {
      currentTeam && fetchSuggestedTermsList();
    }, [currentTeam, debouncedSuggestedTermsSearchTerm, fetchSuggestedTermsList]);

    useEffect(() => {
      currentTeam && fetchTermsTagsList();
    }, [currentTeam, fetchTermsTagsList]);

    useEffect(() => {
      if (teamId) {
        selectTermById(+termId);
      }
    }, [termId, teamId, selectTermById]);

    useEffect(() => {
      if (!isEmpty(filters)) {
        fetchTermsList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    // handle tags filter change
    useEffect(() => {
      if (isEmpty(tags)) {
        return;
      }

      const tagsFromQuery = query.get(TermsFilterKey.TAGS) || '';
      const tagsToFilters = toTagFilters(tags);
      const selectedTagFilter = tagsToFilters.map(filter =>
        tagsFromQuery.includes(filter.id) ? { ...filter, isSelected: true } : filter,
      );

      if (!isEmpty(selectedTagFilter)) {
        handleFilterChange(TermsFilterKey.TAGS, selectedTagFilter);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags]);

    /* Runs only when the page loads or org/team is updated */
    useEffect(() => {
      const _sortField = query.get(SearchQueryParam.sortField) as SortFieldTypes;
      const _sortOrder = query.get(SearchQueryParam.sortOrder) as SortOrders;
      const _offset = query.get(SearchQueryParam.offset) as string;
      const _limit = query.get(SearchQueryParam.limit) as string;
      const _termId = query.get(SearchQueryParam.entityId) || 0;
      const _search = query.get(SearchQueryParam.search) as string;

      dispatchTermsContext({
        type: TTermActionType.Reset,
        payload: {
          clipMode: termsConfig.clipMode,
          ...(_limit && { limit: +_limit }),
          ...(_offset && { offset: +_offset }),
          ...(_sortField && { sortField: _sortField }),
          ...(_sortOrder && { sortOrder: _sortOrder }),
          ...(_termId && { termId: +_termId }),
          ...(_search && { searchTerm: _search, debouncedSearchTerm: _search }),
        },
      });

      getTeams(Number(organizationId)).then(teams => {
        if (teams && teamId) {
          const currentTeam = teams.find(t => t.id === +teamId);

          if (currentTeam) {
            const _mappedTeam = teamDetailsMapper(currentTeam);
            let manageTeam;
            let _access;

            if (_mappedTeam.termsManagedBy && _mappedTeam.termsManagedBy.id) {
              const manageTeamId = parseInt(_mappedTeam.termsManagedBy.id, 10);
              manageTeam = teams.find(team => team.id === manageTeamId);
              _access = TermsAccessEnum.TEAM_MEMBER_ANOTHER_TEAM;
            } else {
              manageTeam = currentTeam;
              _access = TermsAccessEnum.TEAM_MEMBER;
            }

            if (permissionsModel?.isTeamAdminOf(manageTeam.id)) {
              _access = TermsAccessEnum.ADMIN;
            }

            manageTeam.color = AVATAR_COLOR[getTeamColor(manageTeam.id)];

            getTeamDetailsExtended(Number(organizationId), Number(rootTeamId)).then(res => {
              // todo: this can be cleaned up and make better type safe
              dispatchTermsContext({
                type: TTermActionType.SetCurrentTeam,
                payload: {
                  team: _mappedTeam,
                  termBank: res.termBank,
                  manageTeam,
                  access: _access,
                },
              });
              dispatchTermsContext({ type: TTermActionType.SetShowError404, payload: false });
            });
          } else {
            dispatchTermsContext({ type: TTermActionType.SetShowError404, payload: true });
          }
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, teamId, rootTeamId]);

    return (
      <TermsContext.Provider
        value={{
          termsContext,
          handleSearch,
          handleClipMode,
          handleDownloadTerms,
          handleOffsetChange,
          handlePaginationChange,
          handleSortingChange,
          handleOpenNewTermPopup,
          handleCloseTermPopup,
          handleTermSubmit,
          handleBulkTermsSubmit,
          handleOpenImportTermsPopup,
          handleCloseImportTermsPopup,
          handleOpenEditTerm,
          handleMultiSelect,
          handleDeleteTerms,
          handleDeleteTermById,
          handleToggleModal,
          handleToggleTermIdModal,
          handleSuggestedTermsSearch,
          handleSuggestedTermCreate,
          handleFilterChange,
        }}
      >
        {children}
      </TermsContext.Provider>
    );
  },
);

export function useTermsContext() {
  const context = useContext(TermsContext);

  if (!context) {
    throw new Error('useTermsContext must be used within the TermsContextProvider');
  }

  return context;
}

export default TermsContextProvider;
