import { action, computed, makeObservable, observable } from 'mobx';

import { TOrgTeamUserActivityParams } from '@writercolab/types';

import { THomeWidgetsState } from '@web/types';
import type {
  TAiStudioWidgetConfigData,
  TAppsWidgetConfigData,
  TExtensionsWidgetConfigData,
  TFeaturedChatAppWidgetConfigData,
  TRecentWorkWidgetConfigData,
  TShortcutsWidgetConfigData,
  TWidgetConfig,
  TWidgetEditAppearance,
  TWidgetType,
  TWriterAiAcademyWidgetConfigData,
} from '@web/types';
import { type IWebAppAnalyticsTrack, AnalyticsActivity } from 'constants/analytics';

export type IHomeWidgetData =
  | TAiStudioWidgetConfigData
  | TAppsWidgetConfigData
  | TExtensionsWidgetConfigData
  | TFeaturedChatAppWidgetConfigData
  | TRecentWorkWidgetConfigData
  | TShortcutsWidgetConfigData
  | TWriterAiAcademyWidgetConfigData;

export abstract class AbstractHomeWidgetModel<TId extends typeof TWidgetType.type = typeof TWidgetType.type> {
  type: TId;
  protected $editableConfig = observable.box<TWidgetConfig<TId> | null>(null);
  protected $currentMode = observable.box<typeof THomeWidgetsState.type>(THomeWidgetsState.enum.view);

  abstract get editAppearance(): typeof TWidgetEditAppearance.type;

  constructor(
    type: TId,
    private initialConfig: () => TWidgetConfig<TId>,
    public readonly analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>,
  ) {
    this.type = type;

    makeObservable(this, {
      isVisible: computed,
      currentConfig: computed,
      configForSave: computed,
      isAdminEdit: computed,

      onStartEditing: action,
      onStopEditing: action,
      editData: action,
      editVisibility: action,
    });
  }

  get currentConfig() {
    const initial = this.initialConfig();
    const editable = this.$editableConfig.get();

    if (!editable) {
      return initial;
    }

    return editable;
  }

  // allows to override the save payload in child models
  get configForSave() {
    return this.currentConfig;
  }

  get isVisible() {
    return this.currentConfig.visible;
  }

  get isValid() {
    return true;
  }

  get isAdminEdit() {
    return this.$currentMode.get() === THomeWidgetsState.enum.adminEdit;
  }

  editVisibility = (visible: boolean) => {
    const config = this.$editableConfig.get();

    if (config) {
      this.$editableConfig.set({ ...config, visible });

      this.analyticsService.track(AnalyticsActivity.displayWidgetToggled, {
        option: visible ? 'On' : 'Off',
        widget: config.type,
      });
    }
  };

  editData = (data: IHomeWidgetData) => {
    // try to narrow this down to TWidgetConfig<TId>
    const config = this.$editableConfig.get();

    if (config) {
      this.$editableConfig.set({ ...config, data });
    }
  };

  onStartEditing = (mode: typeof THomeWidgetsState.type) => {
    const config = this.initialConfig();

    if (config) {
      this.$editableConfig.set({ ...config });
      this.$currentMode.set(mode);
    }
  };

  onStopEditing = () => {
    this.$editableConfig.set(null);
    this.$currentMode.set(THomeWidgetsState.enum.view);
  };
}
