import { Inline } from 'quill';
import type { Root } from 'parchment/dist/typings/blot/abstract/blot';
import type { IIssue } from '@writercolab/common-utils';
import { IssueType, IssueCategory } from '@writercolab/common-utils';
import { QL_TEXTHIGHLIGHT_CLASS_PREFIX, QA_TEXTHIGHLIGHT_FORMAT_NAME } from '@writercolab/quill-delta-utils';

export class TextHighlightFormat extends Inline {
  static override blotName = QA_TEXTHIGHLIGHT_FORMAT_NAME;
  static override className = QL_TEXTHIGHLIGHT_CLASS_PREFIX;
  static override tagName = 'span';

  /**
   * Issue ID generator
   * @param issue Issue
   * @returns Issue ID
   */
  static getIssueClass = (issue: IIssue, skipLeadingDot = false) =>
    `${skipLeadingDot ? '' : '.'}issue-${issue.issueId}`;

  static override create(issue: IIssue) {
    if (!this.isIssueUseful(TextHighlightFormat.getIssueCategoryId(issue))) {
      return super.create(false);
    }

    const node = super.create(issue) as Element;
    this.setNodeConfigurations(node, issue);

    return node;
  }

  static override formats(domNode: HTMLElement, scroll: Root) {
    const data = domNode.getAttribute('data-value');

    // Data is not useful
    if (!this.isIssueUseful(data)) {
      return super.formats(domNode, scroll);
    }
    // Useful
    else {
      return data;
    }
  }

  /** See: https://github.com/quilljs/parchment#example */
  override formats() {
    const formats = super.formats();
    const value = TextHighlightFormat.formats(this.domNode, this.scroll);

    // Only defined if the value is correct/acceptable.
    if (TextHighlightFormat.isIssueUseful(value)) {
      formats[QA_TEXTHIGHLIGHT_FORMAT_NAME] = TextHighlightFormat.formats(this.domNode, this.scroll);
    }

    // Return this blot's formats
    return formats;
  }

  override format(format: string, issue?: IIssue) {
    const oldValue = TextHighlightFormat.formats(this.domNode, this.scroll);

    if (!issue) {
      super.format(format, false);

      return;
    }

    if (
      !format ||
      format !== TextHighlightFormat.blotName ||
      !TextHighlightFormat.isIssueUseful(TextHighlightFormat.getIssueCategoryId(issue))
    ) {
      super.format(format, issue);
    }
    // Add
    else {
      const node = this.domNode;
      TextHighlightFormat.setNodeConfigurations(node, issue, oldValue);
    }
  }

  /* To prevent the node from accidentally being built in different ways. */
  static setNodeConfigurations(node: Element, issue: IIssue, oldValue?: string) {
    // Remove previous highlight.
    if (oldValue && this.isIssueUseful(oldValue)) {
      node.classList.remove(TextHighlightFormat.getIssueClass(issue, true));
      node.classList.remove('single-letter');
    }

    node.classList.add(TextHighlightFormat.getIssueClass(issue, true));

    if (issue.length === 1) {
      node.classList.add('single-letter');
    }

    node.setAttribute('data-id', issue.issueId);
    node.setAttribute('data-category', TextHighlightFormat.getIssueCategoryId(issue));

    const dataIds = node.getAttribute('data-value') ? node.getAttribute('data-value')!.split(',') : [];
    dataIds.push(`${issue.issueId}`);
    node.setAttribute('data-value', dataIds.join(','));
  }

  static getIssueCategoryId(issue: IIssue): string {
    if (!issue) {
      return '';
    }

    if (
      IssueType.DICTIONARY !== issue.issueType &&
      ![IssueCategory.Claim, IssueCategory.Quote].includes(issue.category)
    ) {
      return issue.sidebarCategory ? issue.sidebarCategory?.id.toString() : '';
    }

    return issue.category;
  }

  static isIssueUseful(value: string | null) {
    return !!value;
  }

  html() {
    return this.domNode.innerText;
  }
}
