import { Request } from '@writercolab/common-utils';

import { IAccount } from '@web/types';

interface IUpdateAccountProps {
  name: string;
}

export const updateAccount = async (orgId: number | undefined, props: IUpdateAccountProps) => {
  const { data } = await Request.getAxiosInstance().put<IAccount>(`/organization/v2/${orgId}`, props);

  return data;
};
