import Quill, { Module } from 'quill';
import first from 'lodash/first';
import last from 'lodash/last';
import remove from 'lodash/remove';
import type QuillCursors from 'quill-cursors';

import type { IBaseSidebarConfig } from '@writercolab/common-utils';
import { getSentences } from '@writercolab/common-utils';
import type { RequestServiceInitialize } from '@writercolab/network';
import { ApiStreamError } from '@writercolab/errors';
import { requiredObject } from '@writercolab/utils';

import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import { getLogger } from '../utils/logger';

const LOG = getLogger('Quill:autowrite');

interface IAutoWriteModuleOptions {
  config: IBaseSidebarConfig;
  requestService: RequestServiceInitialize['api'];
}

export class AutoWriteModule extends Module<IAutoWriteModuleOptions> {
  override options: IAutoWriteModuleOptions;
  streamCtrl: AbortController | null = null;

  constructor(quill: Quill, options: IAutoWriteModuleOptions) {
    super(quill, options);
    this.options = options;

    const isOptionsValid = requiredObject<IAutoWriteModuleOptions>(options);

    if (!isOptionsValid) {
      throw new Error('Autowrite module requires correct options.');
    }

    const cursorModule = this.quill.getModule('cursors') as QuillCursors;

    if (!cursorModule) {
      throw new Error('Autowrite module requires "quill-cursors" to be registered.');
    }
  }

  keepWriting(selectGeneratedText?: boolean): Promise<{ hasContent: boolean }> {
    return new Promise((resolve, reject) => {
      const handleEscape = (): boolean => {
        if (this.streamCtrl) {
          this.streamCtrl.abort();
          this.streamCtrl = null;
        }

        onClose();

        return true;
      };

      const editor = this.quill;
      // Add temporary Esc key binding
      editor.keyboard.addBinding(
        {
          key: 27,
        },
        handleEscape,
      );

      let hasContent = false;
      let initialPosition = editor.getLength();

      const selection = editor.getSelection();
      const cursors = editor.getModule('cursors') as QuillCursors;
      const cursorId = 'autowrite-cursor';
      const cursorName = 'AutoWrite';
      const takeSentencesForSuffix = 3;
      const takeSentencesForPrompt = 6;

      if (selection) {
        initialPosition = selection.index + selection.length;
      }

      let currentPosition = initialPosition;
      let currentMessageIndex = 0;
      const originalFormats = {
        [QUILL_FORMAT.COLOR]: false,
        ...editor.getFormat(initialPosition - 1, 1),
         
      } as Record<QUILL_FORMAT, any>;

      const text = editor.getText(0);

      let prompt: string | null = null;
      let suffix: string | null = null;

      // Use suffix when the cursor at the document beginning and take max of 3 sentences (text after cursor)
      // Use prompt when the cursor is NOT at the document beginning, take max of 6 sentences (text before cursor)
      // Always trim whitespace chars from prompt and suffix
      if (initialPosition === 0) {
        const sentences = getSentences(text);
        const sentencesForSuffix = sentences.slice(0, takeSentencesForSuffix);

        suffix = sentencesForSuffix
          .map(s => s.text)
          .join('')
          .trim();
      } else {
        const textBeforeCursor = text.slice(0, initialPosition);
        const sentences = getSentences(textBeforeCursor);

        if (sentences.length < takeSentencesForPrompt) {
          prompt = textBeforeCursor;
        } else {
          const sentencesBefore = sentences.slice(sentences.length - takeSentencesForPrompt);
          prompt = sentencesBefore
            .map(s => s.text)
            .join('')
            .trim();
        }
      }

      const whitespaceRegex = /\s/;
      const insertSpaceAtStart =
        initialPosition !== 0 && !whitespaceRegex.test(editor.getText({ index: initialPosition - 1, length: 1 }));
      const insertSpaceAtEnd = !whitespaceRegex.test(editor.getText({ index: initialPosition, length: 1 }));
      let lastChunk: string | undefined;

      const cleanUp = () => {
        // Remove Esc key binding
        remove(editor.keyboard.bindings[27]!, binding => binding.handler === handleEscape);
        cursors?.clearCursors();
      };

      const onMessage = (suggestion: string) => {
        currentMessageIndex++;
        let chunk = suggestion.replace(/\n\n/g, '\n');

        if (chunk.length) {
          // if this is the first message, and there is not space already in stream
          if (currentMessageIndex === 1 && insertSpaceAtStart && chunk[0] !== ' ') {
            chunk = ` ${chunk}`; // add space at the beginning
          }

          if (last(lastChunk) === '\n' && first(chunk) === '\n') {
            chunk = chunk.trimStart();
          }

          const newFrom = currentPosition + chunk.length;
          cursors?.createCursor(cursorId, cursorName, '#9B51E0');
          editor.insertText(currentPosition, chunk, Quill.sources.USER);
          editor.formatText({ index: currentPosition, length: chunk.length }, QUILL_FORMAT.COLOR, '#828282');
          editor.setSelection(newFrom, 0, Quill.sources.SILENT);
          editor.scrollIntoView();
          currentPosition = newFrom;
          cursors?.moveCursor(cursorId, { index: newFrom, length: 0 });
          hasContent = true;
          lastChunk = chunk;
        }
      };

      const onClose = () => {
        if (hasContent) {
          if (insertSpaceAtEnd) {
            editor.insertText(currentPosition, ' ', Quill.sources.USER); // add space at the end
            editor.setSelection(++currentPosition, 0, Quill.sources.SILENT); // and shift cursor
          }

          if (selectGeneratedText) {
            editor.setSelection(initialPosition, currentPosition - initialPosition, Quill.sources.SILENT);
          }

          Object.keys(originalFormats).forEach(aFormat => {
            const format = aFormat as keyof typeof originalFormats;

            editor.formatText(
              initialPosition,
              currentPosition - initialPosition,
              format,
              originalFormats[format],
              Quill.sources.USER,
            );
          });
        }

        resolve({ hasContent });
        cleanUp();
      };

      const onError = (error: unknown) => {
        reject(error);
        LOG.warn('Error while autowrite: ', error);
        cleanUp();
      };

      this.requestContentAsStream(
        {
          prompt,
          suffix,
        },
        onMessage,
        onClose,
        onError,
      );
    });
  }

  private requestContentAsStream = async (
    {
      prompt,
      suffix,
    }: {
      prompt?: string | null;
      suffix?: string | null;
    },
    onMessage: (suggestion: string) => void,
    onClose: () => void,
    onError: (error: unknown) => void,
  ) => {
    this.streamCtrl = new AbortController();
    const streamIterator = this.options.requestService.streamIterPost(
      '/api/generation/organization/{organizationId}/team/{teamId}/autowrite/stream',
      {
        body: {
          documentId: this.options.config.documentId,
          prompt,
          suffix,
        },
        params: {
          path: {
            organizationId: Number(this.options.config.organizationId),
            teamId: Number(this.options.config.workspaceId),
          },
        },
        signal: this.streamCtrl.signal,
      },
    );

    try {
      for await (const ev of streamIterator) {
        const { suggestion } = JSON.parse(ev.data);
        onMessage(suggestion);
      }
      onClose();
    } catch (error: unknown) {
      if (error instanceof ApiStreamError) {
        onError(error);
      }

      LOG.warn('Autowrite: requestContentAsStream error', error);
    }
  };
}
