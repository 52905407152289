import { useEffect, useState } from 'react';

const FAVICONS_FOLDER_PATH = '/favicons';
/** the value of enum refers to subfolder name under /favicons */
enum FaviconType {
  DEFAULT = '',
  LOGIN = '/login',
}

const getFaviconElements = () => document.querySelectorAll<HTMLLinkElement>('link[rel$="icon"]');
const getFaviconFolderBasedOnType = (subFolderName: FaviconType) => `${FAVICONS_FOLDER_PATH}${subFolderName}/`;

const handleFavicons = (typeOfIcon: FaviconType) => {
  const favicons = getFaviconElements();

  favicons.forEach(item => {
    const _path = getFaviconFolderBasedOnType(typeOfIcon);
    const _iconName = item.href.split('/').pop();

    const _pathOfNewIcon = `${_path}${_iconName}`;

    if (item) {
      // eslint-disable-next-line no-param-reassign
      item.href = _pathOfNewIcon;
    }
  });
};

export const useFavicons = (isAuthenticated: boolean | undefined) => {
  const [faviconType, setFaviconType] = useState<FaviconType>(FaviconType.DEFAULT);

  useEffect(() => {
    if (!isAuthenticated) {
      setFaviconType(FaviconType.LOGIN);
    } else {
      setFaviconType(FaviconType.DEFAULT);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    handleFavicons(faviconType);
  }, [faviconType]);
};
