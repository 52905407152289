import type React from 'react';
import type { ReactNode } from 'react';
import type { components } from '@writercolab/network';

import type {
  ITableFilter,
  ITeam,
  ITemplateDraft,
  ITemplateDraftFeedbackParams,
  ITemplateInput,
  SortFieldTypes,
  SortOrders,
} from '@writercolab/common-utils';
import { Enum } from '@writercolab/utils';

import type { TApplicationTag } from './Tag';

export type TTemplateDraft = components['schemas']['template_model_Draft'];

export type TDraftResponse = components['schemas']['template_dto_DraftResponse'];

export type TDraftWithInputsResponse = Omit<components['schemas']['template_dto_DraftResponse'], 'inputs' & 'media'> & {
  inputs: IDraftInput[];
  media?: IDraftMedia;
};

export type TDraftInput = components['schemas']['template_model_Input'];

export type TOutputsQueryParams = Omit<components['schemas']['template_dto_FindDraftsRequest'], 'offset' | 'limit'>;

export const TDraftTemplateAccessType = new Enum('public', 'private-organization', 'private-team');

export enum DraftsFilterKey {
  TEMPLATE = 'app',
}

export interface IGetDraftQueryParams {
  search: string;
  documentId: string;
  templateIds: string[];
  sortField: SortFieldTypes;
  sortOrder: SortOrders;
  offset: number;
  limit: number;
  draftIds?: number[];
}

export type IGetOutputsPayload = {
  templateIds: string[];
  offset: number;
  limit: number;
};

export interface IGetDraftsProps {
  orgId: string;
  teamId: string;
  params: IGetDraftQueryParams;
}

export interface IGetDraftProps {
  orgId: string;
  teamId: string;
  draftId: string;
}

export interface IDeleteDraftProps {
  orgId: string;
  teamId: string;
  draftIds: number[];
}

interface IDraftTemplate {
  id: string;
  name: string;
  tagIds?: null | string[];
  access?: typeof TDraftTemplateAccessType.type;
}

interface IDraftCreatedBy {
  id: 0;
  email: string;
  firstName: string;
  lastName: string;
  avatar: string;
}

interface IDraftDocument {
  id: number;
  title: string;
  creationTime: string;
  createdUserId: number;
}

export interface IDraftMedia {
  fileId: string;
  filename: string;
  url?: string;
}

export interface IDraftInput {
  name: string;
  value: string[];
}

export interface IDraft {
  id: number;
  organizationId: number;
  teamId: number;
  documentId: string;
  body: string;
  title: string;
  template: IDraftTemplate;
  createdBy: IDraftCreatedBy;
  creationTime: string;
}

export interface IDraftWithInputs {
  id: number;
  organizationId: number;
  teamId: number;
  body: string;
  title: string;
  template: IDraftTemplate;
  createdBy: IDraftCreatedBy;
  document: IDraftDocument;
  inputs: IDraftInput[];
  creationTime: string;
  media?: IDraftMedia;
  voiceId: string | null;
  reWritePromptId: string | null;
}

export enum IDraftDropdownOptionKey {
  CSV = 'csv',
  XLSX = 'xlsx',
}

export enum DraftPageParams {
  OPEN_ID = 'openId',
}

export enum OutputPageParams {
  OUTPUT_ID = 'outputId',
}

export interface IDraftsContext {
  state: IDraftsState;
  currentTeam: ITeam | undefined;

  infiniteRefSentry?: React.RefObject<HTMLDivElement>;
  applicationTags: TApplicationTag[];
  handleSearch: (searchValue: string) => void;
  selectDrafts: (id: number) => void;
  updateDraftId: (id: number | null) => void;
  handleFilterChange: (key: DraftsFilterKey, filters: ITableFilter[]) => void;
  handleTagsFilterClose: () => void;
  handleFileDownload: (id: string) => void;
  handleDeleteDrafts: () => void;
  getDraftDescription: (draftId: number) => void;
  onRateDraft: (draftId: number, documentId: string, params: ITemplateDraftFeedbackParams) => void;
  onDraftCopy: (draft?: ITemplateDraft) => void;
  onDeleteDraft: (draftId?: number, documentId?: string) => void;
  handleExpandDraft: () => void;
  handleDeleteDraftIdFromQuery: () => void;
  getDocumentTemplateDrafts: (onFetchFinish?: (drafts: ITemplateDraft[]) => void) => void;
  addDocumentDraft: (draft: ITemplateDraft) => void;
  openDraftInNewDocument: () => void;
  goToDocumentDrafts: () => void;
  downloadMediaFile: (fileId: string, filename: string) => Promise<void>;
  downloadMediaFileSummary: (fileId: string, filename: string) => Promise<void>;
  downloadMediaFileTranscript: (fileId: string, filename: string) => Promise<void>;
}

export interface IDraftsContextProvider {
  children?: ReactNode;
}

export interface IDraftsState {
  isLoading: boolean;
  isLoadingDocumentDrafts: boolean;
  documentId: string;
  templateId: string;
  search: string;
  sortField: SortFieldTypes;
  sortOrder: SortOrders;
  offset: number;
  limit: number;
  totalCount: number;
  drafts: IDraft[];
  documentDrafts: ITemplateDraft[];
  openedDraftId: number | null;
  openedDraft: IDraftWithInputs | null;
  isLoadingOpenedDraft: boolean;
  isDeletingDraft: boolean;
  isExpandedDraft: boolean;
  selectedDrafts: number[];
  applicationTags: TApplicationTag[];
  allTemplates: IDraftTemplate[];
  filters: Record<string, ITableFilter[]>;
  templateInputs: ITemplateInput[];
  voiceName: string | null;
}

export enum TDraftsActionType {
  SetIsLoading = 'SetIsLoading',
  SetDrafts = 'SetDrafts',
  SetFilteredDrafts = 'SetFilteredDrafts',
  SetSearchValue = 'SetSearchValue',
  SetSelectedDrafts = 'SetSelectedDrafts',
  SetOffset = 'SetOffset',
  SetApplicationTags = 'SetApplicationTags',
  SetTemplates = 'SetTemplates',
  SetFilters = 'SetFilters',
  SetOpenedDraftId = 'SetOpenedDraftId',
  SetOpenedDraft = 'SetOpenedDraft',
  SetVoiceName = 'SetVoiceName',
  SetIsLoadingOpenedDraft = 'SetIsLoadingOpenedDraft',
  SetDraftsAfterDelete = 'SetDraftsAfterDelete',
  SetIsDeletingDraft = 'SetIsDeletingDraft',
  SetIsExpandedDraft = 'SetIsExpandedDraft',
  SetIsLoadingDocumentDrafts = 'SetIsLoadingDocumentDrafts',
  SetDocumentDrafts = 'SetDocumentDrafts',
  SetDraftsAfterBulkDelete = 'SetDraftsAfterBulkDelete',
  SetTemplateInputs = 'SetTemplateInputs',
  SetReset = 'SetReset',
}

export type TCoWritePayload = {
  [TDraftsActionType.SetIsLoading]: boolean;
  [TDraftsActionType.SetIsLoadingOpenedDraft]: boolean;
  [TDraftsActionType.SetIsDeletingDraft]: boolean;
  [TDraftsActionType.SetIsExpandedDraft]: boolean;
  [TDraftsActionType.SetIsLoadingDocumentDrafts]: boolean;
  [TDraftsActionType.SetOffset]: number;
  [TDraftsActionType.SetSearchValue]: string;
  [TDraftsActionType.SetOpenedDraftId]: number | null;
  [TDraftsActionType.SetSelectedDrafts]: number[];
  [TDraftsActionType.SetOpenedDraft]: IDraftWithInputs | null;
  [TDraftsActionType.SetDocumentDrafts]: ITemplateDraft[];
  [TDraftsActionType.SetTemplateInputs]: ITemplateInput[];
  [TDraftsActionType.SetApplicationTags]: TApplicationTag[];
  [TDraftsActionType.SetTemplates]: IDraftTemplate[];
  [TDraftsActionType.SetReset]: IDraftsState;
  [TDraftsActionType.SetVoiceName]: string | null;
  [TDraftsActionType.SetFilters]: {
    key: DraftsFilterKey;
    filters: ITableFilter[];
  };
  [TDraftsActionType.SetDrafts]: {
    drafts: IDraft[];
    isLoading: boolean;
    totalCount: number;
    allTemplates: IDraftTemplate[];
    offset: number;
  };
  [TDraftsActionType.SetFilteredDrafts]: {
    drafts: IDraft[];
    isLoading: boolean;
    totalCount: number;
    offset: number;
  };
  [TDraftsActionType.SetDraftsAfterDelete]: {
    drafts: IDraft[];
    isDeletingDraft: boolean;
    isExpandedDraft: boolean;
    openedDraftId: number | null;
    selectedDrafts: number[];
  };
  [TDraftsActionType.SetDraftsAfterBulkDelete]: {
    drafts: IDraft[];
    selectedDrafts: number[];
  };
};

export const MEDIA_DROPDOWN_ACTION_ID = {
  DOWNLOAD_FILE: 'DOWNLOAD_FILE',
  OPEN_YOUTUBE: 'OPEN_YOUTUBE',
};

export interface IStoredDraft {
  body: string;
  title: string;
}

export interface ISeoBlogDraftUsed {
  [key: string]: string;
}

export type IStoredDraftFormValues = Record<string, string[]>;

export interface TDraftsTableFilterApps {
  id: string;
  name: string;
}

export interface TDraftsTableFilterAppsExtraArgs {
  search?: string;
  appType?: components['schemas']['template_model_ApplicationType'];
  sortField?: 'name' | 'createdAt' | 'deployedAt' | 'lastEditedAt' | 'recentlyUsed';
  sortOrder?: 'asc' | 'desc';
}
