import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import { type RequestServiceInitialize } from '@writercolab/network';
import {
  NotificationQueueItemType,
  type TEventQueueItem,
  type TNotificationQueueItem,
  TOrgTeamUserActivityParams,
} from '@writercolab/types';
import { type DropdownOption } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import {
  OrganizationDocumentAction,
  OrganizationDocumentFilterOptions,
  OrganizationDocumentSortOptions,
  TApiRequestSortDirection,
  TOrganizationDocumentsExtraArgs,
  TOrganizationTableAction,
  type TOrganizationsDocument,
  TOrganizationsDocumentsScope,
  TOrganizationsDocumentsSort,
} from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import Delta from 'quill-delta';
import { generateWord } from 'services/docx';

import { OrganizationDocumentsApi } from '../../../models/organizationDocuments.api';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY_MS } from '../../../services/config/constants';

interface IDocumentsTableUiModelOpts {
  request: RequestServiceInitialize['api'];
  organizationId: () => number;
  teamId: () => number;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  scopeFilterDisabled: boolean;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
}

type TOrganizationTableActionParams = {
  organizationId: number;
  teamId: number;
  documentId: number;
};

export const TSharingModals = new Enum('sharing');

export class DocumentsTableUiModel {
  readonly organizationDocumentsApi: OrganizationDocumentsApi;
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
  searchPhrase = '';
  deleteConfirmationModalVisible = false;
  scopeFilterDisabled = false;
  tableActionsQueue = new ReactiveQueue<
    TEventQueueItem<typeof TOrganizationTableAction.type, TOrganizationTableActionParams>
  >();

  modalsManager = new ModalsManager<typeof TSharingModals.type, null>();

  private documentId: string | null = null;
  private $selectedDocument = observable.box<TOrganizationsDocument | null>(null, { deep: false });

  constructor(private readonly opts: IDocumentsTableUiModelOpts) {
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();
    this.scopeFilterDisabled = opts.scopeFilterDisabled;

    this.organizationDocumentsApi = new OrganizationDocumentsApi({
      request: opts.request,
      organizationId: opts.organizationId(),
      teamId: opts.teamId(),
    });

    makeAutoObservable(this, {
      searchPhrase: observable,
      scopeFilterDisabled: observable,
      deleteConfirmationModalVisible: observable,

      hasNext: computed.struct,
      loading: computed.struct,
      documents: computed.struct,
      showLoader: computed.struct,
      organizationId: computed.struct,
      teamId: computed.struct,
      scopeFilterOptions: computed.struct,
      sortingOptions: computed.struct,
      empty: computed.struct,
      sortedByCreatedDate: computed.struct,
      filtersEnabled: computed.struct,
      filtersEnabledCount: computed.struct,

      onSearchChange: action.bound,
      onClearSearch: action.bound,
      onDocumentAction: action.bound,
      setDeleteConfirmationModalVisible: action.bound,
    });
  }

  loadMore = async () => {
    await this.organizationDocumentsApi.pagination.next();
  };

  get documents(): TOrganizationsDocument[] {
    return this.organizationDocumentsApi.pagination.value ?? [];
  }

  get selectedDocument(): TOrganizationsDocument | null {
    return this.$selectedDocument.get();
  }

  get empty(): boolean {
    return isEmpty(this.documents) && isEmpty(this.searchPhrase) && !this.loading;
  }

  get hasNext(): boolean {
    return this.organizationDocumentsApi.pagination.hasNext;
  }

  get loading(): boolean {
    return this.organizationDocumentsApi.pagination.status === 'pending';
  }

  get showLoader(): boolean {
    return this.loading && isEmpty(this.documents);
  }

  get organizationId(): number {
    return this.opts.organizationId();
  }

  get sortedByCreatedDate(): boolean {
    return this.sortingOptions.find(s => s.active)?.id === TOrganizationsDocumentsSort.enum.modificationTime;
  }

  get teamId(): number {
    return this.opts.teamId();
  }

  get filters(): {
    search?: string;
    sortField?: typeof TOrganizationsDocumentsSort.type;
    sortOrder?: typeof TApiRequestSortDirection.type;
    scope?: typeof TOrganizationsDocumentsScope.type;
    offset?: number;
    limit?: number;
  } {
    return this.organizationDocumentsApi.pagination.extra;
  }

  get filtersEnabled(): boolean {
    return (
      this.organizationDocumentsApi.pagination.extra.scope === TOrganizationsDocumentsScope.enum.mine ||
      !isEmpty(this.organizationDocumentsApi.pagination.extra.search)
    );
  }

  get filtersEnabledCount(): number {
    let count = 0;

    if (this.organizationDocumentsApi.pagination.extra.scope === TOrganizationsDocumentsScope.enum.mine) {
      count += 1;
    }

    if (!isEmpty(this.organizationDocumentsApi.pagination.extra.search)) {
      count += 1;
    }

    return count;
  }

  get sortField(): typeof TOrganizationsDocumentsSort.type {
    return this.filters.sortField as typeof TOrganizationsDocumentsSort.type;
  }

  get scopeFilterOptions(): DropdownOption[] {
    return OrganizationDocumentFilterOptions.map(option => ({
      ...option,
      active: this.filters.scope === option.id,
    }));
  }

  get sortingOptions(): DropdownOption[] {
    return OrganizationDocumentSortOptions.map(option => ({
      ...option,
      active: this.sortField === option.id,
    }));
  }

  setSelectedDocument = (document: TOrganizationsDocument | null) => {
    this.$selectedDocument.set(document);
  };

  onSearchChange(search: string) {
    this.searchPhrase = search;
    this.debouncedOnSearchChange();
  }

  openDocument = (documentId: string | number) => {
    this.tableActionsQueue.enqueue({
      type: TOrganizationTableAction.enum.openDocument,
      params: {
        organizationId: this.organizationId,
        teamId: this.teamId,
        documentId: documentId as number,
      },
    });
  };

  debouncedOnSearchChange = debounce(() => {
    this.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      search: this.searchPhrase,
    });
  }, DEFAULT_SEARCH_DEBOUNCE_DELAY_MS);

  onClearSearch() {
    this.searchPhrase = '';
    this.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      search: undefined,
    });
  }

  onClearFilters = () => {
    this.searchPhrase = '';
    this.organizationDocumentsApi.pagination.setExtra({
      scope: TOrganizationsDocumentsScope.enum.all,
      search: undefined,
      sortField: TOrganizationsDocumentsSort.enum.openedByMeTime,
    });
  };

  async onDocumentAction(id: string, action: typeof OrganizationDocumentAction.type) {
    return OrganizationDocumentAction.match(
      action,
      {
        [OrganizationDocumentAction.enum.share]: () => {
          this.opts.analyticsService.track(AnalyticsActivity.docShared, {
            origin: 'my_work',
            doc_id: id,
          });

          this.modalsManager.showModal(TSharingModals.enum.sharing);
        },
        [OrganizationDocumentAction.enum.download]: () => {
          this.opts.analyticsService.track(AnalyticsActivity.docDownloaded, {
            origin: 'my_work',
            doc_id: id,
          });

          this.downloadDocument(id);
        },
        [OrganizationDocumentAction.enum.delete]: () => {
          this.documentId = id;
          this.setDeleteConfirmationModalVisible(true);
        },
      },
      OrganizationDocumentAction.enum.share,
    );
  }

  downloadDocument = async (documentId: string) => {
    try {
      const { data } = await this.organizationDocumentsApi.getDocumentContent(documentId);

      const docContent = data as Delta;
      const processedDelta = new Delta(docContent.ops.flatMap(op => op));

      generateWord(processedDelta, this.selectedDocument?.title || 'Untitled');
    } catch {
      const emptyDelta = new Delta();

      generateWord(emptyDelta, 'Untitled');
    }
  };

  deleteDocument = async () => {
    if (!this.documentId) {
      return;
    }

    try {
      this.opts.analyticsService.track(AnalyticsActivity.docDeleted, {
        origin: 'my_work',
        doc_id: this.documentId,
      });

      await this.organizationDocumentsApi.deleteDocument(parseInt(this.documentId, 10));
      this.organizationDocumentsApi.pagination.reload();

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Document was successfully deleted',
      });
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Document could not be deleted',
      });
    }

    runInAction(() => {
      this.setDeleteConfirmationModalVisible(false);
    });
  };

  setDeleteConfirmationModalVisible(visible: boolean) {
    this.deleteConfirmationModalVisible = visible;

    if (!visible) {
      this.documentId = null;
    }
  }

  updateExtraArgs = (extraArgs: TOrganizationDocumentsExtraArgs) => {
    const scope = extraArgs.scope || this.filters.scope || TOrganizationsDocumentsScope.enum.all;
    const search = extraArgs.search || this.filters.search;
    const sortField = extraArgs.sortField as typeof TOrganizationsDocumentsSort.type;
    let sortOrder: typeof TApiRequestSortDirection.type = TApiRequestSortDirection.enum.desc;

    if (sortField === TOrganizationsDocumentsSort.enum.title) {
      sortOrder = TApiRequestSortDirection.enum.asc;
    }

    this.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      search,
      scope,
      sortField,
      sortOrder,
    });
  };
}
