import type { DropdownOption } from '@writercolab/ui-atoms';

export enum UserSettingDepartment {
  MARKETING = 'Marketing',
  CONTENT_STRATEGY = 'Content strategy',
  COMMUNICATIONS = 'Communications',
  EDITORIAL = 'Editorial',
  CUSTOMER_SUCCESS = 'Customer success',
  CUSTOMER_SUPPORT = 'Customer support',
  SALES = 'Sales',
  PRODUCT = 'Product',
  DESIGN = 'Design',
  IT = 'IT',
  HR = 'HR',
  LD = 'L&D',
  FINANCE = 'Finance',
  OPERATIONS = 'Operations',
  LEGAL = 'Legal',
  RESEARCH = 'Research',
  ENGINEERING = 'Engineering',
  OTHER = 'Other',
}

export interface DropdownOptionUserSettingDepartment extends DropdownOption {
  editable: boolean;
  valid: boolean;
  value: string | boolean;
}
