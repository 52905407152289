import type React from 'react';
import { useEffect, useMemo } from 'react';

import cx from 'classnames';
import type { MagicLinksWebsite } from '@writercolab/common-utils';
import { getDateFormatMMMDDYYYY, openNewTab, wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Label,
  SizeTypes,
  Slideout,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import {
  ISuggestionStateAsyncField,
  TSuggestionActionType,
  useMagicLinksSetupContext,
} from '../../../../context/magicLinksSetupContext';
import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import analyticsServiceBase from '../../../../services/analytics/analyticsService';
import { useAppState } from '../../../../state';
import { openContactSalesPage } from '../../../../utils/navigationUtils';
import AddDomainsContent from '../../../organisms/AddDomainsContent';
import SuggestionPageHeader from '../SuggestionPageHeader';

import styles from '../styles.module.css';
import { AnalyticsActivity } from 'constants/analytics';

enum ActionType {
  DELETE = 'delete',
}

const dropdownOptions = [
  {
    id: ActionType.DELETE,
    name: 'Delete',
    icon: <Icon name={IconVariant.TRASH} />,
  },
];

const TooltipTitleNotAdminTitle = (): React.ReactElement => (
  <Text variant={TextSize.S} color={TextColor.WHITE}>
    Only team admins for this team can add and manage websites for magic links
  </Text>
);
const TooltipTitleTooMuchDomains = (limit: number, onContactSalesClick: () => void): React.ReactNode => (
  <div className={styles.tooltipTextContainer}>
    <Label className={styles.limitLabelContainer}>limit reached</Label>
    <Text color={TextColor.WHITE} variant={TextSize.S}>
      You've reached your magic links limit of {limit} {wordPluralize(limit, 'website')}.<br />
      <span onClick={onContactSalesClick} className={cx(styles.tooltipLink, styles.clickable)}>
        Contact sales
      </span>{' '}
      to upgrade your plan.
    </Text>
  </div>
);
const openSupportLink = () => openNewTab('https://support.writer.com/article/196-get-started-with-magic-links');

export const MagicDomainsPage: React.FC = observer(() => {
  usePageTitle('Magic links');
  const {
    appModel: { permissionsModel, assistantSubscription },
  } = useAppState();
  const { enqueueSuccessSnackbar } = useCustomSnackbar();
  const { lastUpdated, lastUpdatedBy, teamName, isTeamNameShown, isSaving } = useSuggestionsContext();
  const { suggestionsState, teamId, handleFetchWebsites, setSuggestionsState, handleDeleteWebsite, handleAddWebsite } =
    useMagicLinksSetupContext();
  const isReadOnly = !permissionsModel?.isTeamAdminOf(Number(teamId));
  const { isLoadingWebsites, magicWebsites, showAddSlideout } = suggestionsState;
  const isMagicLinksFeatureEnabled = !!assistantSubscription.access?.magicLinks;

  const TooltipTitle = useMemo<React.ReactNode>(() => {
    let title: React.ReactNode | null;

    if (!permissionsModel?.isTeamAdmin && !permissionsModel?.isOrganizationAdmin) {
      title = TooltipTitleNotAdminTitle();
    } else if (assistantSubscription.limits?.domain?.exceeded) {
      title = TooltipTitleTooMuchDomains(assistantSubscription.limits.domain.limit, openContactSalesPage);
    } else {
      title = null;
    }

    return title;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    permissionsModel?.isTeamAdmin,
    assistantSubscription.limits?.domain,
    permissionsModel?.isOrganizationAdmin,
    magicWebsites.length,
  ]);

  const access = useMemo(
    () => ({
      areTooMuchDomains: assistantSubscription.limits?.domain?.exceeded,
      areNoDomains: isEmpty(magicWebsites) && !isLoadingWebsites,
      isLockedFeature: magicWebsites?.length === 0 || !isMagicLinksFeatureEnabled || isReadOnly,
    }),
    [
      magicWebsites,
      assistantSubscription.limits?.domain?.exceeded,
      isLoadingWebsites,
      isMagicLinksFeatureEnabled,
      isReadOnly,
    ],
  );

  const _handleAction = async (website: MagicLinksWebsite, event: string) => {
    setSuggestionsState({
      type: TSuggestionActionType.SetSelectedWebsite,
      payload: website,
    });

    if (event === ActionType.DELETE) {
      await handleDeleteWebsite(website);

      setSuggestionsState({
        type: TSuggestionActionType.SetAsyncLoading,
        payload: {
          prop: ISuggestionStateAsyncField.IS_SHOW_MANAGE_SLIDEOUT,
          isLoading: false,
        },
      });
      enqueueSuccessSnackbar('Website successfully removed');
    }
  };

  const _handleCloseAddSlideout = () => {
    setSuggestionsState({
      type: TSuggestionActionType.SetAsyncLoading,
      payload: {
        prop: ISuggestionStateAsyncField.IS_SHOW_ADD_SLIDEOUT,
        isLoading: false,
      },
    });
  };

  const _handleAddDomainClicked = () => {
    setSuggestionsState({
      type: TSuggestionActionType.SetAsyncLoading,
      payload: {
        prop: ISuggestionStateAsyncField.IS_SHOW_ADD_SLIDEOUT,
        isLoading: true,
      },
    });
  };

  useEffect(() => {
    let timer;

    if (isMagicLinksFeatureEnabled) {
      handleFetchWebsites();

      analyticsServiceBase.track(AnalyticsActivity.magciLinksViewPage, {
        clicked_from: 'magic-links',
      });
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMagicLinksFeatureEnabled]);

  return (
    <div className={cx(styles.suggestionSection, styles.magicSection)}>
      <div className={styles.marginedPart}>
        <SuggestionPageHeader
          readOnly={isReadOnly || !isMagicLinksFeatureEnabled}
          teamName={isTeamNameShown ? teamName : ''}
          categoryName="Magic links"
          lastUpdatedTime={lastUpdated}
          lastUpdatedBy={lastUpdatedBy}
          isSaving={isSaving}
          tooltipTitle={<TooltipTitleNotAdminTitle />}
          tooltipWrapperClassname={cx({
            [styles.tooltipWrapperViewOnly]: isReadOnly || !isMagicLinksFeatureEnabled,
          })}
        />
      </div>
      <div className={cx(styles.description, styles.magicDomains, styles.marginedPart)}>
        <div>
          <Text color={TextColor.GREY} variant={TextSize.XL}>
            Add websites you'd like to source links from.{' '}
            <Button
              type={ButtonTypes.LINK}
              size={SizeTypes.MEDIUM}
              className={styles.supportButton}
              onClick={() => openSupportLink()}
            >
              Learn more
            </Button>
            .
          </Text>
        </div>
      </div>
      <div className={cx(styles.magicDomainsWrapper)}>
        <div className={cx(styles.title, styles.marginedPart)}>
          <div>
            <Heading className={styles.header} variant={HeadingVariant.H3}>
              Websites
            </Heading>
          </div>
          <div>
            <Tooltip
              title={TooltipTitle}
              disabled={!TooltipTitle}
              tooltipWrapperClassname={cx(styles.tooltipWidth)}
              placement="bottom"
            >
              <Button
                type={ButtonTypes.PRIMARY}
                className={cx(styles.addDomainButton, {
                  [styles.disabled]: !!TooltipTitle,
                })}
                size={SizeTypes.MEDIUM}
                icon={<Icon name={IconVariant.ADD} />}
                onClick={() => _handleAddDomainClicked()}
                content="New website"
                disabled={!!TooltipTitle || isReadOnly || !isMagicLinksFeatureEnabled}
              />
            </Tooltip>
          </div>
        </div>
        <div className={cx(styles.headers, styles.marginedPart)}>
          <Text variant={TextSize.M} className={styles.id}>
            Website
          </Text>
          <Text variant={TextSize.M} className={styles.date}>
            Added
          </Text>
        </div>
        <div className={styles.rows}>
          {isLoadingWebsites ? (
            <ThreeDotsLoader className={styles.loadingDomains} />
          ) : (
            magicWebsites.map(website => (
              <div key={website.id} className={styles.row}>
                <Text variant={TextSize.XL} className={styles.id}>
                  {website.url}
                </Text>
                <Text variant={TextSize.XL} className={styles.date}>
                  {getDateFormatMMMDDYYYY(new Date(website.creationTime))}
                </Text>
                {!access.isLockedFeature && (
                  <div className={styles.options}>
                    <Dropdown
                      options={dropdownOptions}
                      onPrimaryOptionClickAction={(event: string) => _handleAction(website, event)}
                      trigger={<Icon name={IconVariant.ELLIPSES} />}
                      placement={DropdownPlacement.BOTTOM_RIGHT}
                    />
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        {access.areNoDomains && (
          <div className={cx(styles.marginedPart, styles.emptyDomains)}>
            <Heading className={styles.emptyHeader} variant={HeadingVariant.H2}>
              Add a website to get started
            </Heading>
            <div className={styles.previewDomains}>
              <div className={styles.domainsText}>
                <Text variant={TextSize.XXL} className={styles.descriptionEmpty} bold>
                  Effortlessly hyperlink your content
                </Text>
                <Text variant={TextSize.XXL} className={styles.descriptionEmpty}>
                  You’ll be able to select keywords that need hyperlinks.
                </Text>
                <Text variant={TextSize.XXL} className={styles.descriptionEmpty}>
                  Writer will suggested pages to link to based on your URLs.
                </Text>
              </div>
              <div className={styles.domainsBackground} />
            </div>
          </div>
        )}
      </div>
      <Slideout className={styles.slideoutContentContainer} isOpen={showAddSlideout} onClose={_handleCloseAddSlideout}>
        <AddDomainsContent className={styles.magicDomainsSlideout} handleAddWebsite={handleAddWebsite} />
      </Slideout>
    </div>
  );
});

export default MagicDomainsPage;
