export const getCSSColorHex = (name?: string): string | undefined => {
  if (!name) {
    return undefined;
  }

  const fakeDiv = document.createElement('div');
  fakeDiv.style.color = name;
  document.body.appendChild(fakeDiv);

  const cs = window.getComputedStyle(fakeDiv);
  let hex: string | undefined;

  if (cs.color) {
    const rgb = cs.color.match(/\d+/g);

    if (rgb) {
      hex = `#${rgb.map((bit: string) => `0${parseInt(bit, 10).toString(16)}`.slice(-2)).join('')}`;
    }
  }

  if (fakeDiv.parentNode === document.body) {
    document.body.removeChild(fakeDiv);
  }

  return hex;
};
