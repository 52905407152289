import { useEffect } from 'react';

import { IReactionDisposer, autorun } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import { TEventQueueItem } from '@writercolab/types';

export function useQueueWorkerEvents<T>(
  queues: (ReactiveQueue<TEventQueueItem<string, T>> | undefined)[],
  handler: (item: TEventQueueItem<string, T>) => void,
  isDisposable: boolean = true,
) {
  if (!isDisposable) {
    queues.forEach(queue => {
      queue?.read(handler);
    });
  }

  useEffect(() => {
    if (isDisposable) {
      const subscriptions: IReactionDisposer[] = []; // store disposers for autorun

      queues.forEach(queue => {
        if (queue) {
          const disposer = autorun(() => {
            queue.read(handler);
          });

          subscriptions.push(disposer);
        }
      });

      return () => {
        subscriptions.forEach(unsubscribe => unsubscribe());
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
