import { ReactNode } from 'react';

// eslint-disable-next-line no-restricted-syntax
import type { PropertyPath } from 'lodash';

import { TermType } from '@writercolab/common-utils';

export enum ImportItemType {
  TERMS = 'terms',
  SNIPPETS = 'snippets',
  TEAMMATES = 'teammates',
  BILLING_GROUP = 'billingGroup',
}

type ConvertForApiReturnType = Record<string, string | boolean> | { tag: string }[] | string | boolean | undefined;

export type AcceptableColumnType = {
  names: string[];
  label: string;
  apiFieldKey?: string;
  required?: boolean;
  htmlContentSupport?: boolean;
  wide?: boolean;
  variants?: {
    label: string;
    acceptedValues: string[];
    keyForApi?: PropertyPath;
    booleanValue?: boolean;
  }[];
  defaultValue?: string | boolean;
  convertForApi?: (value: string, reference: string, rawData: RawDataForConversionType) => ConvertForApiReturnType;
  supportedMultiColumn?: boolean;
};

export type TableColumnDataType = {
  acceptableColumn?: AcceptableColumnType;
  initialName: string;
  values: string[];
  columnIndex?: number;
  missed?: boolean;
  hidden?: boolean;
};

export type ColumnWithIndexType = { index: number; columnInfo: TableColumnDataType };

export type RawDataForConversionType = {
  currentColumn: ColumnWithIndexType;
  columnsWithData: ColumnWithIndexType[];
  currentRow: string[];
};

export type IWrongTermsModel<T> = T & {
  reference?: string;
  missedRequiredFields?: string[];
  highlight?: boolean;
  type?: TermType;
};

export type AutoMatchedColumnsInfoType = {
  matched: number;
  total: number;
};

export type StepsContentType = {
  title: string;
  description?: (type: ImportItemType, autoMatchedColumnsInfo: AutoMatchedColumnsInfoType) => ReactNode;
};

export type FailsAndModelsType = {
  fails: any[];
  models: any[];
};

export type MistakeForApiType = {
  mistake: string;
  pos?: string;
  caseSensitive: boolean;
  reference: string;
};

export type TagForApiType = {
  tag: string;
};
