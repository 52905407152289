import { useRef } from 'react';

import cx from 'classnames';

import { AvatarSize, Button, Icon, IconVariant, SizeTypes, UserAvatar } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

export const Avatar = ({ onUploadUserAvatar, userAvatar, userFullName, isLoading }) => {
  const _fileInputRef = useRef<HTMLInputElement>(null);
  const _handleClick = () => _fileInputRef?.current?.click();

  return (
    <div className={styles.inputItem}>
      <input ref={_fileInputRef} className={styles.fileInput} type="file" onChange={onUploadUserAvatar} />
      <UserAvatar
        avatarPath={userAvatar || ''}
        size={AvatarSize.XXXL}
        className={cx(styles.abbreviationContainer, {
          [styles.abbreviationContainerLoading]: isLoading,
        })}
        fullName={userFullName}
        onClick={_handleClick}
      />
      <Button size={SizeTypes.XS} className={styles.avatarChangeBtn} onClick={_handleClick} isLoading={isLoading} round>
        {!isLoading && <Icon name={IconVariant.PLUS} className={styles.avatarChangeIcon} />}
      </Button>
    </div>
  );
};
