import React, { useState } from 'react';

import cx from 'classnames';

import { noop } from '@writercolab/common-utils';
import { Button, ButtonTypes, Heading, HeadingVariant, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import writers from '../../../../assets/backgrounds/billingTrialPopupWriters.png';

import styles from './styles.module.css';

interface IStart30DayTeamTrialPopup {
  open: boolean;
  onStartTrial?: () => void;
  onCancel?: () => void;
}

export const Start30DayTeamTrialPopup: React.FC<IStart30DayTeamTrialPopup> = ({ open, onStartTrial, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onStartMyTrialClick = () => {
    setIsSubmitting(true);
    onStartTrial && onStartTrial();
  };

  return (
    <Modal open={open} title="" className={styles.modalContainer} handleClose={!isSubmitting ? onCancel : noop}>
      <div className={cx(styles.modalContent, 'flexVerticalCenter')}>
        <div className={styles.writersLogo}>
          <img src={writers} alt="writers logo" width={147} />
        </div>

        <Heading variant={HeadingVariant.H2} className={styles.title}>
          Start your free, 14-day Team trial
        </Heading>

        <Text variant={TextSize.L}>
          Invite teammates, set up a style guide, manage terminology and snippets, and more.
        </Text>
        <Button
          className={styles.action}
          type={ButtonTypes.PRIMARY}
          onClick={onStartMyTrialClick}
          isLoading={isSubmitting}
          content="Start my trial"
        />
      </div>
    </Modal>
  );
};

export default Start30DayTeamTrialPopup;
