import { useEffect, useState } from 'react';

import { OrganizationType, removeLegacyOrgCookie, setLegacyOrgCookie } from '@writercolab/common-utils';

import { matchPath } from 'react-router';

import { ROUTE } from '../services/config/routes';
import type { TAppState } from '../state/types';
import { useNavigateToAngular } from './useNavigateToAngular';

const getOrgIdFromUrl = (pathname: string): number => {
  const routeParams: any = matchPath({ path: ROUTE.orgBasePath }, pathname)?.params;

  if (routeParams?.orgId) {
    return Number(routeParams.orgId);
  }

  return 0;
};

export const useLegacy = (organizations: TAppState['organizations'], orgId: number | undefined, pathname: string) => {
  const [isLegacy, setIsLegacy] = useState(false);
  const { routeToLegacyApp } = useNavigateToAngular(orgId);

  useEffect(() => {
    const _organizationId = orgId || getOrgIdFromUrl(pathname);

    if (organizations && _organizationId) {
      const _orgType = organizations.find(org => org.id === _organizationId)?.type;

      if (_orgType === OrganizationType.OLD) {
        setIsLegacy(true);
        setLegacyOrgCookie();
        routeToLegacyApp();
      } else {
        removeLegacyOrgCookie();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, orgId]);

  return { isLegacy };
};
