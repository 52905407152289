import React from 'react';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IHighlightEmptyBodyProps {}

export const HighlightEmptyBody: React.FC<IHighlightEmptyBodyProps> = () => (
  <div className={styles.containerHighlightEmptyContent}>
    <Text variant={TextSize.L} color={TextColor.GREY2}>
      Looks like we came up with nothing this time.
      <br /> Keep writing and you can try again.
    </Text>
  </div>
);
