import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import { SimpleTooltip } from '@writercolab/fe.wds';

import { cn } from '@web/utils';

import styles from './EllipsisTooltip.module.css';

interface EllipsisTooltipProps {
  text: string;
  textClassName?: string;
  delay?: number;
  disabled?: boolean;
  tooltipClassName?: string;
}

const EllipsisTooltip: React.FC<EllipsisTooltipProps> = ({
  text,
  textClassName,
  delay,
  disabled,
  tooltipClassName,
}) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const pRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const checkOverflow = () => {
      if (pRef.current) {
        setIsOverflowed(pRef.current.scrollWidth > pRef.current.offsetWidth);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => window.removeEventListener('resize', checkOverflow);
  }, [text]);

  const content = (
    <p ref={pRef} className={cn('text-base', textClassName, styles.container)}>
      {text}
    </p>
  );

  return (
    <SimpleTooltip
      delay={delay}
      disabled={!isOverflowed || disabled}
      trigger={content}
      className={cn('max-w-[360px] min-w-none z-20 text-center text-wrap', tooltipClassName)}
    >
      <span className="text-sm">{text}</span>
    </SimpleTooltip>
  );
};

export default EllipsisTooltip;
