import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { FormModel, ReactiveQueue } from '@writercolab/mobx';
import { DataRetentionPreferencesApiModel, IDataRetentionConfigParams } from '@writercolab/models';
import { components } from '@writercolab/network';
import {
  NotificationQueueItemType,
  TAssetToDelete,
  TAssetToDeleteType,
  TDeletionOption,
  TNotificationQueueItem,
} from '@writercolab/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

export const readableAssetOptions = TAssetToDelete.hash({
  Doc: 'Docs',
  Draft: 'Drafts',
  UploadedFile: 'Uploaded files',
  Session: 'Chat sessions',
});

export interface IDataRetentionPreferencesUIModelOptions {
  api: DataRetentionPreferencesApiModel;
  analyticsService: IWebAppAnalyticsTrack;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

export class DataRetentionPreferencesUIModel {
  dataForm: ReturnType<typeof this.buildForm>;
  api: DataRetentionPreferencesApiModel;
  isSaving = false;
  confirmationModalOpen = false;
  deletionCounts: {
    assetType: components['schemas']['organization_model_AssetType'];
    count: number;
  }[] = [];

  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;

  constructor(private opts: IDataRetentionPreferencesUIModelOptions) {
    this.api = this.opts.api;
    this.dataForm = this.buildForm(this.api.retentionPreferences);
    this.notificationQueue = opts.notificationQueue || new ReactiveQueue();

    makeObservable(this, {
      dataForm: observable,
      isSaving: observable,
      confirmationModalOpen: observable,
      deletionCounts: observable,

      formTouched: computed,
      hasValidationError: computed,

      onUpdateDataRetentionPreferences: action,
      resetForm: action,
      toggleValue: action,
      setConfirmationModalOpen: action,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private buildForm(input: IDataRetentionConfigParams | null) {
    const enabled = input?.enabled || false;

    return FormModel.build(({ field }) => ({
      retentionDays: field({
        init: String(input?.retentionDays) || TDeletionOption.enum[0],
        autotouched: true,
      }),
      assets: field({
        init: enabled ? input?.assets || [] : TAssetToDelete.list,
        autotouched: true,
      }),
    }));
  }

  resetForm = () => {
    const data = this.api.retentionPreferences;
    const enabled = !!data?.retentionDays;
    this.dataForm.value = {
      retentionDays: String(data?.retentionDays),
      assets: enabled ? data?.assets || [] : TAssetToDelete.list,
    };
    this.dataForm.form.retentionDays.touched = false;
    this.dataForm.form.assets.touched = false;
  };

  onPreviewDeletedAssetCounts = async () => {
    try {
      if (this.hasValidationError) {
        return;
      }

      runInAction(() => {
        this.isSaving = true;
      });

      const enabled = this.dataForm.value.retentionDays !== TDeletionOption.enum[0];

      const res = await this.api.previewDeletionCounts({
        retentionDays: Number(this.dataForm.value.retentionDays),
        assets: enabled ? this.dataForm.value.assets : [],
        enabled,
      });
      runInAction(() => {
        this.deletionCounts = res;
      });

      if (
        Object.values(res)
          .map(({ count }) => count)
          .some(c => c !== 0)
      ) {
        this.setConfirmationModalOpen(true);
      } else {
        this.onUpdateDataRetentionPreferences();
      }
    } catch (e) {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'There was an error fetching a preview. Please try again.',
      });
    } finally {
      runInAction(() => {
        this.isSaving = false;
      });
    }
  };

  onUpdateDataRetentionPreferences = async () => {
    try {
      if (this.hasValidationError) {
        return;
      }

      runInAction(() => {
        this.isSaving = true;
      });

      const enabled = this.dataForm.value.retentionDays !== TDeletionOption.enum[0];
      const hasPreviousPreferences = !!this.api.retentionPreferences?.createdAt;

      await this.api.updatePreferences({
        retentionDays: Number(this.dataForm.value.retentionDays),
        assets: enabled ? this.dataForm.value.assets : [],
        enabled,
      });

      if (!enabled) {
        this.opts.analyticsService.track(AnalyticsActivity.disabledDataRetention, {});
      } else if (
        hasPreviousPreferences &&
        this.api.retentionPreferences?.retentionDays &&
        this.api.retentionPreferences?.assets
      ) {
        this.opts.analyticsService.track(AnalyticsActivity.modifiedDataRetention, {
          selected_schedule_new: TDeletionOption.get(this.dataForm.value.retentionDays, TDeletionOption.enum[0]),
          assets_to_delete_new: this.dataForm.value.assets,
          selected_schedule_old: TDeletionOption.get(
            String(this.api.retentionPreferences.retentionDays),
            TDeletionOption.enum[0],
          ),
          assets_to_delete_old: this.api.retentionPreferences.assets,
        });
      } else {
        this.opts.analyticsService.track(AnalyticsActivity.enabledDataRetention, {
          selected_schedule: TDeletionOption.get(this.dataForm.value.retentionDays, TDeletionOption.enum[0]),
          assets_to_delete: this.dataForm.value.assets,
        });
      }

      runInAction(() => {
        this.api.refetchRetentionPreferences();
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.success,
          message: 'Your data retention policy has been updated',
        });
      });
    } catch (e) {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'There was an error while saving your changes. Please try again.',
      });
    } finally {
      runInAction(() => {
        this.isSaving = false;
        this.setConfirmationModalOpen(false);
      });
    }
  };

  isSelected = (asset: TAssetToDeleteType) => this.dataForm.value.assets.includes(asset);

  toggleValue = (asset: TAssetToDeleteType) => {
    if (this.isSelected(asset)) {
      this.dataForm.form.assets.set(this.dataForm.value.assets.filter(a => a !== asset));
    } else {
      this.dataForm.form.assets.set([...(this.dataForm.value.assets || []), asset]);
    }
  };

  setConfirmationModalOpen = (open: boolean) => {
    this.confirmationModalOpen = open;
  };

  cancelChanges = () => {
    this.setConfirmationModalOpen(false);
    this.opts.analyticsService.track(AnalyticsActivity.canceledDataRetention, {
      deletion_summary: this.deletionCounts,
    });
  };

  get formTouched() {
    return this.dataForm.touchedForm;
  }

  get hasValidationError() {
    return (
      this.dataForm.form.retentionDays.value !== TDeletionOption.enum[0] &&
      this.dataForm.form.assets.value?.length === 0
    );
  }
}
