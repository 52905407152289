import { action, computed, makeObservable, observable } from 'mobx';

import { getWordsCount } from '@writercolab/common-utils';
import { ReactiveQueue } from '@writercolab/mobx';
import { ApiError } from '@writercolab/network';
import type { components } from '@writercolab/network';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';

import { TVoiceType } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { VoiceApiModel } from 'models/voice.api';

export const VOICE_ANALYZER_INPUT_LIMIT = {
  MIN: 300,
  MAX: 1000,
};
export const VOICE_ANALYZER_NAME_LIMIT = {
  MAX: 40,
};
export const VOICE_ANALYZER_DESCRIPTION_LIMIT = {
  MAX: 90,
};

export interface IVoiceAnalyzerModalUIModel {
  api: VoiceApiModel;
  analyticsService: IWebAppAnalyticsTrack;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  aiStudioMode: boolean;
  onClose: (id: string) => void;
}

export class VoiceAnalyzerModalUIModel {
  api: VoiceApiModel;
  isLoading = false;
  input = '';
  isInputTouched = false;
  output: components['schemas']['content_generation_dto_AnalyzeVoiceResponse'] | null = null;
  name = '';
  description = '';
  selectedVoice: components['schemas']['content_generation_dto_VoiceResponse'] | null = null;
  isEditingEnabled = false;
  isCalibrationEnabled = false;
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;

  constructor(private opts: IVoiceAnalyzerModalUIModel) {
    this.notificationQueue = opts.notificationQueue || new ReactiveQueue();

    makeObservable(this, {
      isLoading: observable,
      input: observable,
      isInputTouched: observable,
      output: observable.struct,
      name: observable,
      description: observable,
      selectedVoice: observable.struct,
      isEditingEnabled: observable,
      isCalibrationEnabled: observable,
      inputWordsCount: computed,
      isDefaultVoice: computed,
      reset: action,
      setInput: action,
      setOutput: action,
      setName: action,
      setDescription: action,
      setSelectedVoice: action,
      setIsEditingEnabled: action,
      analyzeVoice: action,
    });

    this.api = opts.api;
  }

  get inputWordsCount() {
    return getWordsCount(this.input);
  }

  get isDefaultVoice() {
    return this.selectedVoice?.default || !this.api.voiceCount;
  }

  reset = () => {
    this.isLoading = false;
    this.input = '';
    this.isInputTouched = false;
    this.output = null;
    this.name = '';
    this.description = '';
    this.isEditingEnabled = false;
    this.selectedVoice = null;
  };

  setInput = (text: string) => {
    this.isInputTouched = true;
    this.input = text;
  };

  setOutput = (output: components['schemas']['content_generation_dto_AnalyzeVoiceResponse'] | null) => {
    this.output = output;
  };

  setName = (name: string) => {
    this.name = name;
  };

  setDescription = (description: string) => {
    this.description = description;
  };

  setSelectedVoice = (voice: components['schemas']['content_generation_dto_VoiceResponse'] | null) => {
    this.selectedVoice = voice;
  };

  setIsEditingEnabled = (isEnabled: boolean) => {
    this.isEditingEnabled = isEnabled;
  };

  setIsCalibrationEnabled = (isEnabled: boolean) => {
    this.isCalibrationEnabled = isEnabled;
  };

  analyzeVoice = async () => {
    this.isLoading = true;
    this.output = null;
    this.opts.analyticsService.track(
      this.opts.aiStudioMode
        ? AnalyticsActivity.aiStudioVoiceAddedTextForAnalysis
        : AnalyticsActivity.voiceAddedTextForAnalysis,
      {},
    );

    try {
      this.output = await this.api.analyzeVoice(this.input);
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }

    this.isLoading = false;
  };

  addVoice = async () => {
    try {
      this.opts.analyticsService.track(
        this.opts.aiStudioMode ? AnalyticsActivity.aiStudioVoiceCreated : AnalyticsActivity.voiceCreated,
        {
          method: 'analyze',
          voice_name: this.name,
        },
      );
      const voice = await this.api.addVoice({
        name: this.name,
        description: this.description,
        type: TVoiceType.enum.Analyze,
        sampleText: this.input,
        profile: this.output?.profile,
        overview: this.output?.overview,
        settings: undefined,
        rewrite: false,
      });
      this.opts.onClose(voice.id);
    } catch (error) {
      if (error instanceof ApiError) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.error,
          message: 'Something went wrong',
        });
      }
    }
  };

  updateVoice = async () => {
    if (this.selectedVoice) {
      try {
        this.opts.analyticsService.track(
          this.opts.aiStudioMode ? AnalyticsActivity.aiStudioVoiceEdited : AnalyticsActivity.voiceEdited,
          {
            voice_name: this.name,
          },
        );
        const voice = await this.api.updateVoice(this.selectedVoice.id, {
          name: this.name,
          description: this.description,
          type: TVoiceType.enum.Analyze,
          sampleText: this.input ?? this.selectedVoice.sampleText,
          profile: this.output?.profile ?? this.selectedVoice.profile ?? undefined,
          overview: this.output?.overview ?? this.selectedVoice.overview ?? undefined,
          settings: undefined,
          rewrite: this.selectedVoice.rewrite,
        });
        this.opts.onClose(voice.id);
      } catch (error) {
        if (error instanceof ApiError) {
          this.notificationQueue.enqueue({
            type: NotificationQueueItemType.enum.error,
            message: 'Something went wrong',
          });
        }
      }
    }
  };
}
