import React, { ReactElement, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { CurrentVariable, parseVariables } from '@writercolab/ui-chat-apps';

import { TextVariableEmbed } from '../../generic/TextVariableEmbed';

import styles from './PromptItem.module.css';

interface IPromptItem {
  heading?: string;
  prompt?: string;
  icon?: React.ReactElement;
  textHighlight: string;
  isShifted?: boolean;
  heightThreshold?: number;
  onUsePrompt?: (prompt: string) => void;
  onCopy?: (prompt: string) => void;
  onAdd?: () => void;
  addTeamPromptsVisible?: boolean;
}

const wrapTags = (text: string, searchQuery: string, className?: string) => {
  const regex = new RegExp(`(${searchQuery})`, 'i');
  const textArray = text.split(regex);

  return textArray.map(str => {
    if (regex.test(str)) {
      return <span className={className}>{str}</span>;
    }

    return str;
  });
};

const renderVariables = (varArr: CurrentVariable[], str: string) => {
  let prevIterationLastIndex: undefined | number;
  const result: (string | ReactElement)[] = [];

  varArr.forEach(varData => {
    if (varData.end) {
      if (prevIterationLastIndex) {
        result.push(str.slice(varData.end + 1, prevIterationLastIndex));
      } else {
        result.push(str.slice(varData.end + 1));
      }
    }

    result.push(<TextVariableEmbed text={varData.text} />);
    prevIterationLastIndex = varData.start;
  });

  result.push(str.substring(0, prevIterationLastIndex));

  return result.reverse();
};

const buildHighlight = (text: string, searchQuery: string, className?: string) => {
  const variables = parseVariables(text);
  const elements = renderVariables(variables.reverse(), text);

  if (!searchQuery) {
    return elements;
  } else {
    return elements.map(el => {
      if (typeof el === 'string') {
        return wrapTags(el, searchQuery, className);
      }

      return el;
    });
  }
};

export const PromptItem: React.FC<IPromptItem> = ({
  heading,
  prompt,
  icon,
  textHighlight,
  heightThreshold = 400,
  isShifted,
  onUsePrompt,
  onCopy,
  onAdd,
  addTeamPromptsVisible,
}) => {
  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const showUseButton = onUsePrompt && prompt;
  const showCopyButton = onCopy && prompt;
  const showAddButton = addTeamPromptsVisible && onAdd && prompt;

  useEffect(() => {
    if (ref.current) {
      setHeight(ref?.current?.clientHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={cx(styles.container, { [styles.containerShifted]: isShifted })} ref={ref}>
      {icon && (
        <div className={cx(styles.iconContainer, { [styles.iconContainerFixed]: height > heightThreshold })}>
          {icon}
        </div>
      )}
      <div className={styles.textContainer}>
        {heading && (
          <Heading variant={HeadingVariant.H5}>{buildHighlight(heading, textHighlight, styles.textHighlight)}</Heading>
        )}
        {prompt && (
          <Text className={styles.promptWrapper} variant={TextSize.L} color={TextColor.GREY}>
            {buildHighlight(prompt, textHighlight, styles.textHighlight)}
          </Text>
        )}
      </div>
      <div className={cx(styles.buttonContainer, { [styles.buttonContainerFixed]: height > heightThreshold })}>
        {showAddButton && (
          <Tooltip title="Save to team prompts" placement="top">
            <Button
              className={styles.transparentButton}
              onClick={() => prompt && onAdd?.()}
              type={ButtonTypes.TRANSPARENT_LINK}
              icon={<Icon name={IconVariant.ADD} width={16} height={16} />}
            />
          </Tooltip>
        )}
        {showUseButton && (
          <Tooltip title="Load prompt in Ask Writer" placement="top">
            <Button
              className={styles.useButton}
              type={ButtonTypes.BLACK}
              onClick={() => onUsePrompt(prompt)}
              content="Use"
            />
          </Tooltip>
        )}
        {showCopyButton && (
          <Tooltip title="Copy prompt" placement="top">
            <Button
              className={styles.transparentButton}
              onClick={() => prompt && onCopy?.(prompt)}
              type={ButtonTypes.TRANSPARENT_LINK}
              icon={<Icon name={IconVariant.COPY_PASTE} width={16} height={16} />}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};
