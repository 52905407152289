import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import {
  IRephraseGrades,
  MAX_SHOWN_GRADES_BY_DEFAULT,
  fetchRewritePrompts,
  noop,
  updateRephrasePrompts,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Logo,
  LogoVariant,
  SizeTypes,
  Slideout,
  Text,
  TextColor,
  TextSize,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';
import { RewriteSortablePrompts } from '@writercolab/ui-molecules';

import { snackbarMessages } from '@web/component-library';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import analytics from 'services/analytics/analyticsService';

import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { useAppState } from '../../../../state';
import { openContactSalesPage } from '../../../../utils/navigationUtils';
import CustomizationForm from '../../../organisms/CustomizationForm';
import SuggestionPageHeader from '../SuggestionPageHeader';

import styles from '../styles.module.css';

export const ReWritePage: React.FC = observer(() => {
  usePageTitle('Rewrite');
  const {
    appModel: { assistantSubscription, permissionsModel },
    appState: { organizationId, teamId },
    loadSubscriptionLimits,
  } = useAppState();
  const reWriteLimit = assistantSubscription.limits?.reWrite?.limit || 0;
  const reWriteLimitExceeded = !!assistantSubscription.limits?.reWrite?.exceeded;

  const {
    suggestionSettings,
    isSaving,
    isTeamNameShown,
    lastUpdated,
    lastUpdatedBy,
    teamName,
    isEnterpriseFour,
    userEmail,
    updateSettingsSection,
  } = useSuggestionsContext();

  const isReadOnly = !permissionsModel?.isTeamAdminOf(Number(teamId)) || reWriteLimitExceeded;
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);
  const [rewrites, setRewrites] = useState<IRephraseGrades[]>([]);
  const [persistantRewrites, setPersistantRewrites] = useState<IRephraseGrades[]>([]);
  const [isAllPromptsShown, setIsAllPromptsShown] = useState(false);
  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [showCustomizationForm, setShowCustomizationForm] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [firstRewrites, setFirstRewrites] = useState<IRephraseGrades[]>([]);
  const [lastRewrites, setLastRewrites] = useState<IRephraseGrades[]>([]);
  const { enqueueErrorSnackbar } = useCustomSnackbar();
  const rewritePromoTypes = isEnterpriseFour
    ? {
        header: 'Rewrite customization',
        buttonText: `Request customization`,
        textHeader: '',
        textBold: `Looking for something that sounds more like you?`,
        text: `Contact us or fill out a request form, and we'll work with you on customization!`,
      }
    : {
        header: 'Enterprise feature',
        buttonText: `Contact sales`,
        textHeader: 'Rewrite customization',
        textBold: `Looking for something that sounds more like you?`,
        text: `Select Enterprise plans come with custom rewrite. Contact us to learn more.`,
      };

  const handleSelectGrade = (id: string) => {
    setSelectedGrade(id);
    setIsAllPromptsShown(false);
  };

  const openSupportLink = () => {
    const supportLink = 'https://support.writer.com/article/186-introducing-rewrite';
    window.open(supportLink, '_blank');
  };

  const handleRequestClick = () => {
    if (isEnterpriseFour) {
      setShowCustomizationForm(true);
    } else {
      openContactSalesPage();
    }
  };

  const getRewritePrompts = async () => {
    setIsLoadingPrompts(true);
    loadSubscriptionLimits();

    try {
      const rewrites = await fetchRewritePrompts(`${organizationId || ''}`, `${teamId || ''}`);
      const { prompts } = rewrites;

      setRewrites(prompts);
      setPersistantRewrites(prompts);
    } catch (e) {
      // @ts-expect-error TODO: fix this
      const err = e.response.data.errors ? e.response.data.errors[0].description : e.response.data || e?.message;

      enqueueErrorSnackbar(snackbarMessages.rewrites.getRewritePromptsError(err));
    } finally {
      setIsLoadingPrompts(false);
    }
  };

  const updateRewritePrompts = async (rewrites: IRephraseGrades[]) => {
    const defaultSectionToUpdate = suggestionSettings?.categories[0];
    setRewrites(rewrites);

    try {
      const formattedRewrites = rewrites.map(rewrite => ({ promptId: rewrite.id, enabled: !!rewrite.enabled }));

      await updateRephrasePrompts(`${organizationId || ''}`, `${teamId || ''}`, formattedRewrites);
      setPersistantRewrites(rewrites);

      // This will trigger section "updated by"
      defaultSectionToUpdate && updateSettingsSection(defaultSectionToUpdate);
    } catch (e) {
      // @ts-expect-error TODO: fix this
      const err = e.response.data.errors ? e.response.data.errors[0].description : e.response.data || e?.message;

      setRewrites(persistantRewrites);
      enqueueErrorSnackbar(snackbarMessages.rewrites.updateRewritePromptsError(err));
    }
  };

  useEffect(() => {
    const filterDisabled = rewrites.filter(rewrite => rewrite.enabled);
    const updatedFirst = filterDisabled.slice(0, MAX_SHOWN_GRADES_BY_DEFAULT);
    const updatedLast = filterDisabled.slice(MAX_SHOWN_GRADES_BY_DEFAULT);
    const isAllDisabled = filterDisabled.length === 0;

    setFirstRewrites(updatedFirst);
    setLastRewrites(updatedLast);
    setIsAllDisabled(isAllDisabled);
  }, [rewrites]);

  useEffect(() => {
    analytics.track(AnalyticsActivity.suggestionsReWriteUpdated, {});
    getRewritePrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Rewrite"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
        tooltipTitle="Only team admins for this team can manage rewrite settings"
      />
      <div className={styles.description}>
        <div>
          <Text color={TextColor.GREY} variant={TextSize.XL}>
            With rewrite, highlight any text in the Editor and get instant rephrasing suggestions.
            <Button type={ButtonTypes.LINK} onClick={openSupportLink}>
              Learn more.
            </Button>
          </Text>
        </div>
      </div>

      {reWriteLimitExceeded && (
        <div className={cx(styles.disabledRewrites, styles.hasError)}>
          <Heading
            variant={HeadingVariant.H3}
          >{`You’ve reached your limit of ${reWriteLimit} words generated this month.`}</Heading>
          <Text variant={TextSize.XL}>
            To get more words,{' '}
            <Button type={ButtonTypes.LINK} onClick={openContactSalesPage}>
              contact sales
            </Button>{' '}
            about upgrading to a larger Enterprise plan.
          </Text>
        </div>
      )}

      {!isLoadingPrompts && (
        <>
          {isAllDisabled ? (
            <div className={styles.disabledRewrites}>
              <Heading variant={HeadingVariant.H3}>Rewrite is disabled for this team</Heading>
              <Text variant={TextSize.XL}>Toggle on one of the options below to enable rewrite for your team</Text>
            </div>
          ) : (
            <div className={styles.preview}>
              <Heading variant={HeadingVariant.H3}>What your team sees</Heading>

              <div className={styles.widget}>
                <>
                  <div className={styles.toolbar} id="toolbar">
                    <Logo variant={LogoVariant.BLACK} className={styles.logo} />

                    <ul>
                      {firstRewrites.map(({ id, name }) => (
                        <li key={id} className={cx({ [styles.active]: id === selectedGrade })}>
                          <Text onClick={() => handleSelectGrade(id)} variant={TextSize.XL} medium>
                            {name}
                          </Text>
                        </li>
                      ))}

                      {lastRewrites.length > 0 && (
                        <li className={styles.showMoreRelative}>
                          <div className={styles.iconMore} onClick={() => setIsAllPromptsShown(!isAllPromptsShown)}>
                            <Icon name={IconVariant.ELLIPSES} />
                          </div>

                          <div className={cx(styles.lastGradesWrapper, { [styles.isHidden]: !isAllPromptsShown })}>
                            {lastRewrites.map(({ id, name }) => (
                              <div key={id}>
                                <span key={id} className={styles.lastGrade}>
                                  <span>
                                    <Icon name={IconVariant.CUSTOM_CATEGORIES} width={18} height={18} />

                                    <Text onClick={() => handleSelectGrade(id)} variant={TextSize.XL} medium>
                                      {name}
                                    </Text>
                                  </span>

                                  {id === selectedGrade && (
                                    <Icon
                                      className={styles.checkMark}
                                      name={IconVariant.CHECK}
                                      width={13}
                                      height={13}
                                    />
                                  )}
                                </span>
                              </div>
                            ))}
                          </div>
                        </li>
                      )}
                    </ul>

                    <div>
                      <span className={styles.icons} onClick={noop}>
                        <Icon name={IconVariant.CLOSE} width={20} height={20} />
                      </span>
                    </div>
                  </div>

                  <div className={styles.content}>
                    <div className={styles.grayLine}>
                      <div className={cx(styles.greenBlock, styles.firstRow)} />
                    </div>

                    <div className={styles.grayLine}>
                      <div className={cx(styles.greenBlock, styles.secondRowOne)} />
                      <div className={cx(styles.greenBlock, styles.secondRowTwo)} />
                    </div>

                    <div className={cx(styles.grayLine, styles.bigger)}>
                      <div className={cx(styles.greenBlock, styles.thirdRow)} />
                    </div>

                    <div className={styles.grayLineSeparator}></div>

                    <div className={styles.grayLine}>
                      <div className={cx(styles.greenBlock, styles.fourthRow)} />
                    </div>

                    <div className={cx(styles.grayLine, styles.bigger)}>
                      <div className={cx(styles.greenBlock, styles.fifthRow)} />
                    </div>
                  </div>

                  <div className={styles.footer}>
                    <div className={styles.marketing}></div>
                    <div className={styles.showMore}>
                      <Text onClick={noop} variant={TextSize.XS} smallCaps>
                        Show more
                      </Text>
                    </div>
                  </div>

                  <div className={styles.resizer}>
                    <Icon name={IconVariant.RESIZE} />
                  </div>
                </>
              </div>
            </div>
          )}
        </>
      )}

      <RewriteSortablePrompts
        isLoadingPrompts={isLoadingPrompts}
        rewrites={rewrites}
        isDisabled={isReadOnly || reWriteLimitExceeded}
        updateRewritePrompts={updateRewritePrompts}
      />

      {!isLoadingPrompts && (
        <div className={styles.rewriteFeature}>
          <div className={styles.rewriteSection}>
            {isEnterpriseFour ? (
              <Heading className={styles.header} variant={HeadingVariant.H2}>
                {rewritePromoTypes.header}
              </Heading>
            ) : (
              <Heading className={styles.header} variant={HeadingVariant.H3} medium>
                <div className={styles.lockIcon}>
                  <Icon name={IconVariant.LOCK_LINE} />
                </div>

                {rewritePromoTypes.header}
              </Heading>
            )}

            <Button
              className={cx(styles.actionButton, { [styles.bigger]: isEnterpriseFour })}
              type={ButtonTypes.PRIMARY}
              size={SizeTypes.LARGE}
              icon={<Icon name={IconVariant.PREMIUM}></Icon>}
              content={rewritePromoTypes.buttonText}
              onClick={handleRequestClick}
            />
          </div>

          <div className={styles.rewriteSection}>
            <div className={styles.textBlock}>
              {rewritePromoTypes.textHeader && (
                <Heading style={{ marginBottom: 10 }} variant={HeadingVariant.H2}>
                  {rewritePromoTypes.textHeader}
                </Heading>
              )}

              <div className={styles.rewriteText}>
                <Text variant={TextSize.XL} bold>
                  {rewritePromoTypes.textBold}
                </Text>
                <Text variant={TextSize.XL}>{rewritePromoTypes.text}</Text>
              </div>
            </div>

            <div className={styles.widgetPreview} />
          </div>
        </div>
      )}

      {showCustomizationForm && (
        <Slideout
          className={styles.slideoutContentContainer}
          isOpen={showCustomizationForm}
          onClose={() => setShowCustomizationForm(false)}
        >
          <CustomizationForm email={userEmail} />
        </Slideout>
      )}
    </div>
  );
});

export default ReWritePage;
