import { useCallback, useEffect, useState } from 'react';

import type { ITeam } from '@writercolab/common-utils';
import { delay, isLocalEnv, isNewOrg } from '@writercolab/common-utils';

import { OrgJoinQueryParams } from '@web/types';
import first from 'lodash/first';
import { useLocation, useNavigate } from 'react-router';

import { EXPIRED_USERS_ALLOWED_ROUTES, REACT_RELATIVE_ROUTE, ROUTE } from '../services/config/routes';
import { useAppState } from '../state';
import { getLogger } from '../utils/logger';
import { goToHome } from '../utils/navigationUtils';
import { isUserActionQueryParamValid } from '../utils/queryParamUtils';
import useQuery from './useQuery';
import { useRouteMatch } from './useRouteMatch';

const LOG = getLogger('useAppRedirects');

export const useAppRedirects = ({
  isOrganizationAdmin,
  isModelReady,
  isMultiTeam,
  teams,
  isUnverifiedEmail,
}: {
  isOrganizationAdmin: boolean;
  isModelReady: boolean;
  isMultiTeam: boolean;
  teams?: ITeam[];
  isUnverifiedEmail?: boolean;
}) => {
  const { appState } = useAppState();
  const [isCurrentRouteAccessBlocked, setIsCurrentRouteAccessBlocked] = useState(false);
  const { organization, organizations, team, isInvalidOrg, isInvalidTeam, isInvalidSubscription } = appState;
  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const isRouteMatch = useRouteMatch();
  const userId = query.get(OrgJoinQueryParams.USER_ID) || '';
  const userAction = query.get(OrgJoinQueryParams.ACTION) || '';

  const navigateToTeamOrganization = useCallback(
    (fallbackToFirst = false) => {
      const organizationToSelect = organization || (fallbackToFirst ? first(organizations) : undefined);

      if (!organizationToSelect) {
        return;
      }

      if (isUnverifiedEmail) {
        goToHome(navigate, organizationToSelect.id);

        return;
      }

      goToHome(navigate, organizationToSelect.id, !isNewOrg(organizationToSelect) && !isLocalEnv(), team?.id);
    },
    [organization, isUnverifiedEmail, team?.id, organizations, navigate],
  );

  useEffect(() => {
    if (organization && isRouteMatch(ROUTE.root, true)) {
      navigateToTeamOrganization();
    }
  }, [organization, isRouteMatch, navigateToTeamOrganization]);

  useEffect(() => {
    if (isInvalidOrg) {
      const organizationToSelect = organization || first(organizations);

      if (!organizationToSelect) {
        return;
      }

      goToHome(navigate, organizationToSelect.id, !isNewOrg(organizationToSelect) && !isLocalEnv());
    }
  }, [isInvalidOrg, navigate, navigateToTeamOrganization, organization, organizations]);

  useEffect(() => {
    if (isInvalidTeam || (isRouteMatch(ROUTE.orgBasePath, true) && team)) {
      const teamToSelect = team || first(teams);

      if (!teamToSelect || !organization) {
        return;
      }

      goToHome(navigate, organization.id, !isNewOrg(organization) && !isLocalEnv(), teamToSelect.id);
    }
  }, [isInvalidTeam, isRouteMatch, navigate, navigateToTeamOrganization, organization, team, teams]);

  useEffect(() => {
    if (isInvalidSubscription) {
      const isOnAllowedRoute = EXPIRED_USERS_ALLOWED_ROUTES.find(r => isRouteMatch(r));

      setIsCurrentRouteAccessBlocked(!isOnAllowedRoute);
    }
  }, [isRouteMatch, isInvalidSubscription]);

  useEffect(() => {
    /* Route to Billing or Teams page for trialexpired users */
    if (organization?.id && team?.id && isCurrentRouteAccessBlocked) {
      if (!isOrganizationAdmin) {
        navigate(ROUTE.toTeamUsers(organization.id, team.id));
      } else {
        navigate(ROUTE.toBilling(organization.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization?.id, team?.id, isOrganizationAdmin, isCurrentRouteAccessBlocked]);

  useEffect(() => {
    /* Route to Approve/Deny new users */
    if (
      !isModelReady ||
      !organization ||
      !teams ||
      !isRouteMatch(REACT_RELATIVE_ROUTE.newUserActionRedirect, true) ||
      !isUserActionQueryParamValid(userAction) ||
      !userId.length
    ) {
      return;
    }

    const team = first(teams);

    const additionalRouteParams = {
      [OrgJoinQueryParams.ACTION]: userAction,
      [OrgJoinQueryParams.USER_ID]: userId,
    };

    const targetRoute = isMultiTeam
      ? ROUTE.toPeople(organization.id, additionalRouteParams)
      : ROUTE.toTeamUsers(organization.id, team?.id, additionalRouteParams);

    delay(1).then(() => navigate(targetRoute));
  }, [isModelReady, organization, teams, isMultiTeam, userId, userAction, navigate, isRouteMatch]);

  useEffect(() => {
    LOG.info('Going to route: ', location.pathname);
  }, [location.pathname]);
};
