import React from 'react';

import { SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

export const TableLoadingRow: React.FC = () => (
  <div className={styles.tableGridRow}>
    {[...Array(4)].map((_, i) => (
      <div className={styles.containerTableRowItem} key={`table-loading-row-${i}`}>
        <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.containerTableRowLoader} />
      </div>
    ))}
  </div>
);

export default TableLoadingRow;
