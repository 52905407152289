import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import type {
  ICreateSnippetWithFails,
  ISnippetCreate,
  ITableFilter,
  Pagination,
  TSnippet,
} from '@writercolab/common-utils';
import {
  BatchSubmitFailHandlingTypes,
  SearchQueryParam,
  SortFieldTypes,
  SortOrders,
  TermsSortingTypes,
  createMultipleSnippetsRequest,
  createSnippetsRequest,
  delay,
  deleteSnippets,
  downloadBlobAsFilename,
  downloadSnippetsListAsCsv,
  downloadSnippetsListAsXlsx,
  getQueryParamDirect,
  getSnippetsListRequest,
  getSnippetsTagsRequest,
  getTeams,
  toTagFilters,
  updateSnippetsRequest,
} from '@writercolab/common-utils';
import { transformBr, transformNbsp } from '@writercolab/quill-delta-utils';
import { AVATAR_COLOR, useDebounce } from '@writercolab/ui-atoms';
import type { PerPageOption } from '@writercolab/ui-molecules';

import { DOWNLOAD_TYPE } from '../components/pages/termsAndSnippetsShared/common';

import { TERMS_PAGE_CONFIG, useLocalStorage } from '@web/component-library';
import type { ActionMap, IMappedTeamDetails } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import trim from 'lodash/trim';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';
import { useAppState } from 'state';

import useQuery from '../hooks/useQuery';
import { DEFAULT_TERMS_FETCH_LIMIT } from '../services/config/constants';
import { getLogger } from '../utils/logger';
import { removeQueryParam } from '../utils/navigationUtils';
import { transformFiltersToQuery } from '../utils/queryFiltersUtils';
import { getTeamColor, teamDetailsMapper } from '../utils/teamUtils';
import type { PaginationExtended, TeamDetails } from './termsContext';
import { TermsAccessEnum, TermsClipMode } from './termsContext';

const LOG = getLogger('peopleContext');
const cleanUpTermsModels = (models: ISnippetCreate[]): ISnippetCreate[] =>
  models.map(model => ({
    ...model,
    snippet: transformBr(model.snippet),
    term: transformBr(transformNbsp(model.snippet)),
    description: model.description && transformNbsp(model.description),
  }));

interface ISnippetsContext {
  snippetsContext: TSnippetsState;
  handleSearch: (searchTerm: string) => void;
  handleClipMode: (clipMode: TermsClipMode) => void;
  handleDownloadSnippets: (filetype: string) => void;
  handleOffsetChange: (value: PerPageOption) => void;
  handlePaginationChange: (object: any, value: number) => void;
  handleSortingChange: (value) => void;
  handleOpenNewTermPopup: () => void;
  handleCloseTermPopup: (refreshList?: boolean) => void;
  handleSnippetSubmit: (models: ISnippetCreate[]) => Promise<TSnippet[]>;
  handleBulkSnippetsSubmit: (models: ISnippetCreate[]) => Promise<ICreateSnippetWithFails>;
  handleOpenImportSnippetsPopup: () => void;
  handleCloseImportSnippetsPopup: () => void;
  handleOpenEditTerm: (data: TSnippet) => void;
  handleMultiSelect: (data: TSnippet[]) => void;
  handleToggleModal: (flag: boolean) => void;
  handleDeleteSnippets: () => void;
  handleFilterChange: (key: SnippetsFilterKey, filters: ITableFilter[]) => void;
  handleResetSelected: () => void;
}

const SnippetsContext = createContext<ISnippetsContext>({} as ISnippetsContext);

export enum SnippetsFilterKey {
  TAGS = 'tags',
}

interface TSnippetsState {
  isLoading: boolean;
  isTermsLoaded: boolean;
  showError404: boolean;
  downloadInProgress: boolean;
  currentTeam?: IMappedTeamDetails;
  manageTeam?: IMappedTeamDetails;
  snippetsList: TSnippet[];
  termsTotalCount: number | null;
  clipMode: TermsClipMode;
  searchTerm: string;
  debouncedSearchTerm: string;
  offset: number;
  limit: number;
  sortField: SortFieldTypes | null;
  sortOrder: SortOrders | null;
  isAddTermPopupOpen: boolean;
  isImportSnippetsPopupOpen: boolean;
  openedTerm: ISnippetCreate | undefined;
  selectedTerms: TSnippet[];
  isDeletePopupOpen: boolean;
  tags: string[];
  termsAccess: TermsAccessEnum;
  filters: Record<string, ITableFilter[]>;
  snippet?: {
    teamId?: number;
    teamName: string;
  };
}

enum TSnippetActionType {
  SetIsLoading = 'isLoading',
  SetIsTermsLoaded = 'isTermsLoaded',
  SetShowError404 = 'showError404',
  SetDownloadInProgress = 'downloadInProgress',
  SetCurrentTeam = 'SetCurrentTeam',
  SetSnippetsList = 'snippetsList',
  SetSnippetsTagsList = 'termsTagsList',
  SetTermsTotalCount = 'termsTotalCount',
  SetClipMode = 'clipMode',
  SetSearchTerm = 'SetSearchTerm',
  SetDebouncedSearchTerm = 'SetDebouncedSearchTerm',
  SetSortField = 'sortField',
  SetSortOrder = 'sortOrder',
  SetPaginationLimits = 'SetPaginationLimits',
  SetIsAddTermPopupOpen = 'isAddTermPopupOpen',
  SetIsImportSnippetsPopupOpen = 'isImportSnippetsPopupOpen',
  SetOpenedTerm = 'openedTerm',
  SetSelectedTerms = 'selectedTerms',
  SetOpenDeletePopup = 'isDeletePopupOpen',
  Reset = 'reset',
  SetFilters = 'SetFilters',
}

type TTermPayload = {
  [TSnippetActionType.SetIsLoading]: boolean;
  [TSnippetActionType.SetIsTermsLoaded]: boolean;
  [TSnippetActionType.SetShowError404]: boolean;
  [TSnippetActionType.SetDownloadInProgress]: boolean;
  [TSnippetActionType.SetCurrentTeam]: TeamDetails & { access: TermsAccessEnum };
  [TSnippetActionType.SetSnippetsList]: TSnippet[];
  [TSnippetActionType.SetSnippetsTagsList]: string[];
  [TSnippetActionType.SetTermsTotalCount]: number;
  [TSnippetActionType.SetClipMode]: TermsClipMode;
  [TSnippetActionType.SetSearchTerm]: string;
  [TSnippetActionType.SetDebouncedSearchTerm]: string;
  [TSnippetActionType.SetSortField]: SortFieldTypes | null;
  [TSnippetActionType.SetSortOrder]: SortOrders | null;
  [TSnippetActionType.SetPaginationLimits]: Pagination;
  [TSnippetActionType.SetIsAddTermPopupOpen]: boolean;
  [TSnippetActionType.SetIsImportSnippetsPopupOpen]: boolean;
  [TSnippetActionType.SetOpenedTerm]: ISnippetCreate | null;
  [TSnippetActionType.SetSelectedTerms]: TSnippet[];
  [TSnippetActionType.SetOpenDeletePopup]: boolean;
  [TSnippetActionType.Reset]: PaginationExtended;
  [TSnippetActionType.SetFilters]: {
    key: SnippetsFilterKey;
    filters: ITableFilter[];
  };
};

type TermActions = ActionMap<TTermPayload>[keyof ActionMap<TTermPayload>];

const initialTermsState: TSnippetsState = {
  isLoading: true,
  isTermsLoaded: false,
  showError404: false,
  downloadInProgress: false,
  currentTeam: undefined,
  manageTeam: undefined,
  snippetsList: [],
  termsTotalCount: null,
  clipMode: TermsClipMode.WRAP_TEXT,
  searchTerm: '',
  debouncedSearchTerm: '',
  offset: 0,
  limit: DEFAULT_TERMS_FETCH_LIMIT,
  sortField: SortFieldTypes.CREATION_TIME,
  sortOrder: SortOrders.DESC,
  isAddTermPopupOpen: false,
  isImportSnippetsPopupOpen: false,
  openedTerm: undefined,
  selectedTerms: [],
  isDeletePopupOpen: false,
  termsAccess: TermsAccessEnum.TEAM_MEMBER,
  tags: [],
  filters: { tags: [] },
  snippet: undefined,
};

const snippetsContextReducer = (state: TSnippetsState, action: TermActions) => {
  let newState = {} as TSnippetsState;

  switch (action.type) {
    case TSnippetActionType.SetCurrentTeam:
      newState = {
        ...state,
        currentTeam: action.payload.team,
        manageTeam: action.payload.manageTeam,
        termsAccess: action.payload.access,
      };
      break;
    case TSnippetActionType.SetSearchTerm:
      // covers case for https://writerai.atlassian.net/browse/WA-1914
      if (isEmpty(state.searchTerm)) {
        newState = { ...state, offset: 0, sortField: null, sortOrder: null, searchTerm: action.payload };
      } else {
        newState = { ...state, offset: 0, searchTerm: action.payload };
      }

      break;
    case TSnippetActionType.SetSnippetsTagsList:
      newState = { ...state, tags: map(action.payload, trim) };
      break;
    case TSnippetActionType.SetPaginationLimits:
      newState = { ...state, limit: action.payload?.limit, offset: action.payload?.offset };
      break;
    case TSnippetActionType.SetDebouncedSearchTerm:
      newState = { ...state, offset: 0, debouncedSearchTerm: action.payload };
      break;
    case TSnippetActionType.SetShowError404:
      newState = { ...state, showError404: action.payload };
      break;
    case TSnippetActionType.SetFilters:
      newState = { ...state, filters: { ...state.filters, [action.payload.key]: action.payload.filters } };
      break;

    case TSnippetActionType.Reset:
      newState = {
        ...initialTermsState,
        sortField: action.payload?.sortField || SortFieldTypes.CREATION_TIME,
        offset: action.payload?.offset || 0,
        sortOrder: action.payload?.sortOrder || SortOrders.DESC,
        searchTerm: action.payload?.searchTerm || '',
        limit: action.payload?.limit || DEFAULT_TERMS_FETCH_LIMIT,
      };

      break;
    default:
      newState = { ...state, [action.type]: action.payload };
  }

  return newState;
};

interface ISnippetsContextProvider {
  children?: ReactNode;
  organizationId?: number;
  teamId?: number;
}

const SnippetsContextProvider: React.FC<ISnippetsContextProvider> = observer(({ organizationId, teamId, children }) => {
  const query = useQuery();
  const {
    appModel: { permissionsModel, analyticsService },
  } = useAppState();
  const location = useLocation();
  const navigate = useNavigate();
  const [termsConfig, setTermsConfig] = useLocalStorage(TERMS_PAGE_CONFIG, {
    clipMode: TermsClipMode.INLINE,
  });

  const [snippetsContext, dispatchSnippetsContext] = useReducer(snippetsContextReducer, initialTermsState);

  const {
    currentTeam,
    offset,
    searchTerm,
    debouncedSearchTerm,
    sortField,
    sortOrder,
    limit,
    isLoading,
    tags,
    filters,
  } = snippetsContext;

  const fetchSnippetsTagsList = async () => {
    try {
      const { tags } = await getSnippetsTagsRequest(`${organizationId}`, `${teamId}`);
      const sortedTags = tags.sort();
      dispatchSnippetsContext({ type: TSnippetActionType.SetSnippetsTagsList, payload: sortedTags });
    } catch (e) {
      LOG.error(e);
    }
  };

  const fetchSnippetsList = async () => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetIsLoading, payload: true });
    const tags = filters.tags.filter(tag => tag.isSelected).map(tag => tag.name) || [];

    const params = {
      offset,
      limit,
      tags,
      search: debouncedSearchTerm.replace('w.', ''),
      sortField: sortField || SortFieldTypes.MODIFICATION_TIME,
      sortOrder: sortOrder || SortOrders.DESC,
    };

    try {
      const { result, totalCount } = await getSnippetsListRequest(`${organizationId}`, `${teamId}`, params);

      dispatchSnippetsContext({ type: TSnippetActionType.SetSnippetsList, payload: result });
      dispatchSnippetsContext({ type: TSnippetActionType.SetTermsTotalCount, payload: totalCount });
      dispatchSnippetsContext({ type: TSnippetActionType.SetIsTermsLoaded, payload: true });
    } catch (e) {
      LOG.error(e);
    } finally {
      dispatchSnippetsContext({ type: TSnippetActionType.SetIsLoading, payload: false });
    }
  };

  /**
   * Code for Download Button functionality
   */
  const handleDownloadSnippets = async (filetype: string) => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetDownloadInProgress, payload: true });
    const tags = filters.tags.filter(tag => tag.isSelected).map(tag => tag.name) || [];

    const params = {
      offset,
      search: debouncedSearchTerm,
      tags,
      sortField: sortField || SortFieldTypes.MODIFICATION_TIME,
      sortOrder: sortOrder || SortOrders.DESC,
    };

    try {
      if (filetype === DOWNLOAD_TYPE.CSV) {
        const blob = await downloadSnippetsListAsCsv(`${organizationId}`, `${teamId}`, params);
        downloadBlobAsFilename(blob, 'snippets.csv');
      } else {
        const blob = await downloadSnippetsListAsXlsx(`${organizationId}`, `${teamId}`, params);
        downloadBlobAsFilename(blob, 'snippets.xlsx');
      }
    } catch (e) {
      LOG.error(e);
    } finally {
      await delay(1500);
      dispatchSnippetsContext({ type: TSnippetActionType.SetDownloadInProgress, payload: false });
    }
  };

  /**
   * Code for ClipMode functionality
   * */
  const handleClipMode = (value: TermsClipMode) => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetClipMode, payload: value });
    /* Todo: update this to append to current config */
    setTermsConfig({
      clipMode: value,
    });
  };

  /**
   * Code for change offset functionality
   * */
  const handleOffsetChange = (value: PerPageOption) => {
    query.set(SearchQueryParam.limit, value.name);
    navigate({ search: query.toString() });

    dispatchSnippetsContext({
      type: TSnippetActionType.SetPaginationLimits,
      payload: { limit: value.value, offset: 0 },
    });
  };

  const handleFilterChange = (key: SnippetsFilterKey, tableFilters: ITableFilter[]) => {
    query.set(key, transformFiltersToQuery(tableFilters));

    navigate({ search: query.toString() });

    dispatchSnippetsContext({
      type: TSnippetActionType.SetFilters,
      payload: {
        key,
        filters: tableFilters,
      },
    });
  };

  const processSortingChange = ({ sortField, sortDirection }) => {
    const _searchQueryParam = getQueryParamDirect(SearchQueryParam.search);

    if (_searchQueryParam && _searchQueryParam.length) {
      query.set(SearchQueryParam.search, _searchQueryParam);
    } else {
      query.delete(SearchQueryParam.search);
    }

    query.set(SearchQueryParam.sortField, sortField);
    query.set(SearchQueryParam.sortOrder, sortDirection);
    navigate({ search: query.toString() });

    dispatchSnippetsContext({ type: TSnippetActionType.SetSortField, payload: sortField });
    dispatchSnippetsContext({ type: TSnippetActionType.SetSortOrder, payload: sortDirection });
  };

  /**
   * Code for change sorting functionality
   * */
  const handleSortingChange = (sortFieldType: TermsSortingTypes) => {
    switch (sortFieldType) {
      case TermsSortingTypes.TITLE_ASC:
      case TermsSortingTypes.TITLE_DESC:
        processSortingChange({
          sortField: SortFieldTypes.TERM,
          sortDirection: TermsSortingTypes.TITLE_ASC === sortFieldType ? SortOrders.ASC : SortOrders.DESC,
        });
        break;
      case TermsSortingTypes.TYPE_ASC:
      case TermsSortingTypes.TYPE_DESC:
        processSortingChange({
          sortField: SortFieldTypes.TYPE,
          sortDirection: TermsSortingTypes.TYPE_ASC === sortFieldType ? SortOrders.ASC : SortOrders.DESC,
        });
        break;
      case TermsSortingTypes.ADDED_DATE_DESC:
      case TermsSortingTypes.ADDED_DATE_ASC:
        processSortingChange({
          sortField: SortFieldTypes.CREATION_TIME,
          sortDirection: TermsSortingTypes.ADDED_DATE_ASC === sortFieldType ? SortOrders.DESC : SortOrders.ASC,
        });
        break;
    }
  };

  /**
   * Code for change pagination functionality
   * */
  const handlePaginationChange = useCallback(
    (mouseEvent: React.MouseEvent<HTMLElement>, pageNumber: number) => {
      const offset = (pageNumber - 1) * limit;

      query.set(SearchQueryParam.offset, `${offset}`);
      navigate({ search: query.toString() });

      dispatchSnippetsContext({ type: TSnippetActionType.SetPaginationLimits, payload: { limit, offset } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [limit, teamId],
  );

  const unsetSortingConditions = () => {
    query.delete(SearchQueryParam.sortOrder);
    query.delete(SearchQueryParam.sortField);
    navigate({ search: query.toString() });
  };

  /**
   * Code for Search functionality
   * */
  const handleSearch = (value: string) => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetSearchTerm, payload: value });
  };
  const debounceSearchCallback = (value: string) => {
    const _existingSearchTerm = query.get(SearchQueryParam.search);

    // covers case for https://writerai.atlassian.net/browse/WA-1914
    if (value.length && _existingSearchTerm !== value) {
      unsetSortingConditions();
    }

    value ? query.set(SearchQueryParam.search, value) : query.delete(SearchQueryParam.search);
    dispatchSnippetsContext({ type: TSnippetActionType.SetDebouncedSearchTerm, payload: value });
    navigate({ search: query.toString() });
  };
  useDebounce(searchTerm, 500, debounceSearchCallback);

  /**
   * Code for AddSnippetPopup
   * */
  const handleOpenNewTermPopup = () => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetOpenedTerm, payload: null });
    dispatchSnippetsContext({ type: TSnippetActionType.SetIsAddTermPopupOpen, payload: true });
  };
  const handleCloseTermPopup = (refreshList?: boolean) => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetIsAddTermPopupOpen, payload: false });
    dispatchSnippetsContext({ type: TSnippetActionType.SetOpenedTerm, payload: null });
    refreshList && Promise.all([fetchSnippetsList(), fetchSnippetsTagsList()]);
  };
  const handleSnippetSubmit = (models: ISnippetCreate[]) => {
    const _models = cleanUpTermsModels(models);

    if (models[0].id?.length) {
      analyticsService.track(AnalyticsActivity.snippetEdited, {});

      return updateSnippetsRequest(`${organizationId}`, `${teamId}`!, _models);
    }

    analyticsService.track(AnalyticsActivity.snippetAdded, {});

    return createSnippetsRequest(
      `${organizationId}`,
      `${teamId}`!,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _models.map(({ id, ...rest }) => rest),
    );
  };
  const handleBulkSnippetsSubmit = async (models: ISnippetCreate[]) => {
    const createdSnippetsData = await createMultipleSnippetsRequest(
      `${organizationId}`,
      `${teamId}`,
      models,
      BatchSubmitFailHandlingTypes.ACCUMULATE,
    );
    await fetchSnippetsList();

    return createdSnippetsData;
  };
  const handleOpenImportSnippetsPopup = () => {
    analyticsService.track(AnalyticsActivity.snippetsClickedImportCSV, {});
    dispatchSnippetsContext({ type: TSnippetActionType.SetIsImportSnippetsPopupOpen, payload: true });
  };
  const handleCloseImportSnippetsPopup = () => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetIsImportSnippetsPopupOpen, payload: false });
  };
  const handleOpenEditTerm = useCallback(
    ({ id }: TSnippet) => {
      const _termToBeUpdated = snippetsContext.snippetsList.find(d => d.id === id);

      if (_termToBeUpdated) {
        const { shortcut, description, snippet, tags } = _termToBeUpdated;

        const _mappedTerm: ISnippetCreate = {
          id: String(id),
          shortcut: shortcut || undefined,
          description: description || undefined,
          snippet,
          tags: tags || [],
        };

        dispatchSnippetsContext({ type: TSnippetActionType.SetOpenedTerm, payload: _mappedTerm });
        dispatchSnippetsContext({ type: TSnippetActionType.SetIsAddTermPopupOpen, payload: true });
      }
    },
    [snippetsContext.snippetsList],
  );

  /**
   * Code for multi-select and delete
   * */
  const handleMultiSelect = useCallback((data: TSnippet[]) => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetSelectedTerms, payload: data });
  }, []);
  const handleToggleModal = (flag: boolean) => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetOpenDeletePopup, payload: flag });
  };
  const handleResetSelected = () => {
    dispatchSnippetsContext({ type: TSnippetActionType.SetSelectedTerms, payload: [] });
  };
  const handleDeleteSnippets = () => {
    const termIds = snippetsContext.selectedTerms.map(d => d.id);

    return deleteSnippets(`${organizationId}`, `${teamId}`, termIds).then(() => {
      handleCloseTermPopup();
      fetchSnippetsList();
    });
  };

  /**
   * Code for open add term modal using query param
   * */
  useEffect(() => {
    const createQueryParam = query.get(SearchQueryParam.create) as string;

    if (!isLoading && createQueryParam === 'true') {
      handleOpenNewTermPopup();
      const params = new URLSearchParams(location.search.slice(1));
      removeQueryParam(navigate, params, SearchQueryParam.create);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  /**
   * Code for fetching snippetsList at various points during the terms lifecycle
   * */
  useEffect(() => {
    currentTeam && fetchSnippetsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam, debouncedSearchTerm, offset, limit, sortField, sortOrder, filters]);

  useEffect(() => {
    currentTeam && fetchSnippetsTagsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeam]);

  /* Runs only when the page loads or org/team is updated */
  useEffect(() => {
    const _sortField = query.get(SearchQueryParam.sortField) as SortFieldTypes;
    const _sortOrder = query.get(SearchQueryParam.sortOrder) as SortOrders;
    const _offset = query.get(SearchQueryParam.offset) as string;
    const _limit = query.get(SearchQueryParam.limit) as string;
    const _search = query.get(SearchQueryParam.search) as string;

    dispatchSnippetsContext({
      type: TSnippetActionType.Reset,
      payload: {
        clipMode: termsConfig.clipMode,
        ...(_limit && { limit: +_limit }),
        ...(_offset && { offset: +_offset }),
        ...(_sortField && { sortField: _sortField }),
        ...(_sortOrder && { sortOrder: _sortOrder }),
        ...(_search && { searchTerm: _search, debouncedSearchTerm: _search }),
      },
    });

    if (_search) {
      navigate({ search: query.toString() });
      dispatchSnippetsContext({ type: TSnippetActionType.SetDebouncedSearchTerm, payload: _search });
      dispatchSnippetsContext({ type: TSnippetActionType.SetSearchTerm, payload: _search });
    }

    getTeams(organizationId as any).then(teams => {
      if (teams && teamId) {
        const currentTeam = teams.find(t => t.id === +teamId);
        if (currentTeam) {
          const _mappedTeam = teamDetailsMapper(currentTeam);
          let manageTeam;
          let _access;
          if (_mappedTeam.snippetsManagedBy && _mappedTeam.snippetsManagedBy.id) {
            const manageTeamId = parseInt(_mappedTeam.snippetsManagedBy.id, 10);
            manageTeam = teams.find(team => team.id === manageTeamId);
            _access = TermsAccessEnum.TEAM_MEMBER_ANOTHER_TEAM;
          } else {
            manageTeam = currentTeam;
            _access = TermsAccessEnum.TEAM_MEMBER;
          }

          if (permissionsModel?.isTeamAdminOf(manageTeam.id)) {
            _access = TermsAccessEnum.ADMIN;
          }

          manageTeam.color = AVATAR_COLOR[getTeamColor(manageTeam.id)];

          dispatchSnippetsContext({
            type: TSnippetActionType.SetCurrentTeam,
            payload: {
              team: _mappedTeam,
              snippet: _mappedTeam.snippet,
              manageTeam,
              access: _access,
            },
          });
          dispatchSnippetsContext({ type: TSnippetActionType.SetShowError404, payload: false });
        } else {
          dispatchSnippetsContext({ type: TSnippetActionType.SetShowError404, payload: true });
          dispatchSnippetsContext({ type: TSnippetActionType.SetIsLoading, payload: false });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId]);

  useEffect(() => {
    const tagsFromQuery = query.get(SnippetsFilterKey.TAGS) || '';
    const tagsToFilters = toTagFilters(tags);
    const selectedFilters = tagsToFilters.map(filter =>
      tagsFromQuery.includes(filter.id) ? { ...filter, isSelected: true } : filter,
    );

    if (!isEmpty(selectedFilters)) {
      handleFilterChange(SnippetsFilterKey.TAGS, selectedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  return (
    <SnippetsContext.Provider
      value={{
        snippetsContext,
        handleSearch,
        handleClipMode,
        handleDownloadSnippets,
        handleOffsetChange,
        handlePaginationChange,
        handleSortingChange,
        handleOpenNewTermPopup,
        handleCloseTermPopup,
        handleSnippetSubmit,
        handleBulkSnippetsSubmit,
        handleOpenImportSnippetsPopup,
        handleCloseImportSnippetsPopup,
        handleOpenEditTerm,
        handleMultiSelect,
        handleDeleteSnippets,
        handleToggleModal,
        handleFilterChange,
        handleResetSelected,
      }}
    >
      {children}
    </SnippetsContext.Provider>
  );
});

export function useSnippetsContext() {
  const context = useContext(SnippetsContext);

  if (!context) {
    throw new Error('useSnippetsContext must be used within the SnippetsContextProvider');
  }

  return context;
}

export default SnippetsContextProvider;
