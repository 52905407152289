import type { RangeStatic } from 'quill';

import { Module } from 'quill';

import type Quill from 'quill';
import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';

import { ALLOWED_DELTA_FORMATS, QUILL_FORMAT } from '@writercolab/quill-delta-utils';

import { QUILL_SELECTION_CHANGE_EVENT } from '@writercolab/react-quill';
import { EditorToolbar } from '../components/EditorToolbar/EditorToolbar';
import { EditorToolbarModel } from '../components/EditorToolbar/UIEditorToolbarModel';
import EditorToolbarPickers from '../components/EditorToolbar/EditorToolbarPickers';

interface IToolbarModuleOptions {
  container: string | HTMLElement;
  formats: QUILL_FORMAT[];
  onApplyLinkFormatCb?: () => RangeStatic;
}

export class ToolbarModule extends Module<IToolbarModuleOptions> {
  container: HTMLElement;
  modalContainer: HTMLElement;
  override options: IToolbarModuleOptions;
  root: Root;
  modalsRoot: Root;

  model: EditorToolbarModel;

  constructor(quill: Quill, options: IToolbarModuleOptions) {
    if (!options.container) {
      throw new Error('container should be provided.');
    }

    super(quill, options);

    if (typeof options.container === 'string') {
      const element = document.getElementById(options.container);

      if (!element) {
        throw new Error(`Container ID ${options.container} provided doesnt exists in DOM.`);
      }

      this.container = element;
    } else {
      this.container = options.container;
    }

    this.options = options;

    this.modalContainer = document.createElement('div');
    document.body.appendChild(this.modalContainer);

    this.model = new EditorToolbarModel(quill, options.formats);

    this.quill.on(QUILL_SELECTION_CHANGE_EVENT, this.model.onChangeSelection);

    this.root = createRoot(this.container);
    this.modalsRoot = createRoot(this.modalContainer);

    this.registerShortcuts();

    this.render();
  }

  render() {
    this.renderToolbar();
    this.renderModals();
  }

  renderToolbar() {
    this.root.render(<EditorToolbar model={this.model} onFormatClick={this.onFormatClick}></EditorToolbar>);
  }

  renderModals() {
    this.modalsRoot.render(<EditorToolbarPickers model={this.model} element={this.quill.root} />);
  }

  // refer to https://writerai.atlassian.net/browse/WA-2822
  registerShortcuts = () => {
    // Cmd + Shift + 7
    // numbered lists
    this.quill.keyboard.addBinding({
      key: 55,
      shortKey: true,
      shiftKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.LIST, 'ordered');
      },
    });

    // Cmd + Shift + 8
    // bulleted lists
    this.quill.keyboard.addBinding({
      key: 56,
      shortKey: true,
      shiftKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.LIST, 'bullet');
      },
    });

    // Cmd + K
    // hyperlinks
    this.quill.keyboard.addBinding({
      key: 75,
      shortKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.LINK, true);
      },
    });

    // Ctrl + Shift + 9
    // quote block
    this.quill.keyboard.addBinding({
      key: 57,
      ctrlKey: true,
      shiftKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.BLOCKQUOTE);
      },
    });

    // Cmd + Shift + C
    // code block
    this.quill.keyboard.addBinding({
      key: 67,
      shortKey: true,
      shiftKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.CODE, true);
      },
    });

    // Cmd + [
    // decrease indent
    this.quill.keyboard.addBinding({
      key: 219,
      shortKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.INDENT, '-1');
      },
    });

    // Cmd + ]
    // increase indent
    this.quill.keyboard.addBinding({
      key: 221,
      shortKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.INDENT, '+1');
      },
    });

    // Cmd + \
    // clear formatting
    this.quill.keyboard.addBinding({
      key: 220,
      shortKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.CLEAN);
      },
    });

    // Ctrl + L
    // left align
    this.quill.keyboard.addBinding({
      key: 76,
      ctrlKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.ALIGN, 'left');
      },
    });

    // Ctrl + E
    // middle align
    this.quill.keyboard.addBinding({
      key: 69,
      ctrlKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.ALIGN, 'center');
      },
    });

    // Ctrl + R
    // right align
    this.quill.keyboard.addBinding({
      key: 82,
      ctrlKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.ALIGN, 'right');
      },
    });

    // Cmd + Option + 0
    // normal text
    this.quill.keyboard.addBinding({
      key: 48,
      shortKey: true,
      altKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.HEADER, '0');
      },
    });

    // Cmd + Option + 1
    // h1
    this.quill.keyboard.addBinding({
      key: 49,
      shortKey: true,
      altKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.HEADER, '1');
      },
    });

    // Cmd + Option + 2
    // h2
    this.quill.keyboard.addBinding({
      key: 50,
      shortKey: true,
      altKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.HEADER, '2');
      },
    });

    // Cmd + Option + 3
    // h3
    this.quill.keyboard.addBinding({
      key: 51,
      shortKey: true,
      altKey: true,
      handler: () => {
        this.onFormatClick(QUILL_FORMAT.HEADER, '3');
      },
    });
  };

  onFormatClick = (format: QUILL_FORMAT, value: boolean | string = true) => {
    const range = this.quill.getSelection(true);

    switch (format) {
      case QUILL_FORMAT.CLEAN:
        ALLOWED_DELTA_FORMATS.forEach(format => {
          this.quill.format(format, false, 'user');
          this.quill.setSelection(range, 'user');
        });

        break;

      case QUILL_FORMAT.INDENT:
        this.quill.format(QUILL_FORMAT.INDENT, value, 'user');
        break;

      case QUILL_FORMAT.LINK:
        if (value === true) {
          this.options.onApplyLinkFormatCb?.();
          this.model.onShowLinkModal();
        } else {
          this.quill.format(QUILL_FORMAT.LINK, false, 'user');
        }

        break;

      case QUILL_FORMAT.LIST:
        if (value === 'check') {
          if (
            this.model.currentTextFormats[QUILL_FORMAT.LIST] === 'checked' ||
            this.model.currentTextFormats[QUILL_FORMAT.LIST] === 'unchecked'
          ) {
            this.quill.format(QUILL_FORMAT.LIST, false, 'user');
          } else {
            this.quill.format(QUILL_FORMAT.LIST, 'unchecked', 'user');
          }
        } else {
          const valueToBeSet = this.model.currentTextFormats[format] === value ? false : value;
          this.quill.format(QUILL_FORMAT.LIST, valueToBeSet, 'user');
        }

        break;

      case QUILL_FORMAT.ALIGN:
        if (this.model.currentTextFormats[format] === value || value === 'left') {
          this.quill.format(format, false, 'user');
        } else {
          this.quill.format(format, value, 'user');
        }

        break;

      case QUILL_FORMAT.HEADER:
        {
          const valueToBeSet = (value === '0' || this.model.currentTextFormats[format]) === true ? false : value;
          this.quill.format(format, valueToBeSet, 'user');
        }

        break;

      case QUILL_FORMAT.FONT:
        this.quill.format(format, value, 'user');

        break;

      case QUILL_FORMAT.BACKGROUND:
      case QUILL_FORMAT.COLOR:
        {
          const valueToBeSet = value || false;
          this.quill.format(format, valueToBeSet, 'user');
        }

        break;

      default:
        {
          const valueToBeSet = this.model.currentTextFormats[format] === true ? false : value;
          this.quill.format(format, valueToBeSet, 'user');
        }
        break;
    }

    if (range) {
      this.quill.setSelection(range, 'user');
      this.model.onChangeSelection(range);
    }
  };
}
