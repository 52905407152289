import React, { useCallback } from 'react';

import {
  Dropdown,
  DropdownOption,
  DropdownPlacement,
  FontFace,
  Icon,
  IconVariant,
  Text,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { AcceptableColumnType, TableColumnDataType } from '../../../types';

import styles from './styles.module.css';

interface IPreviewImportedDataTableProps {
  nonRequiredColumns: TableColumnDataType[];
  requiredColumns: TableColumnDataType[];
  missedColumns: AcceptableColumnType[];
  importingItemsAmount: number;
  onChangeColumnName: (columnIndex: number, newName: string) => void;
  onHideColumn: (columnIndex: number) => void;
}

export const PreviewImportedDataTable = ({
  nonRequiredColumns,
  requiredColumns,
  missedColumns,
  importingItemsAmount,
  onChangeColumnName,
  onHideColumn,
}: IPreviewImportedDataTableProps) => {
  const modalsRootElement = document.getElementById('modals-root') || undefined;

  const getColumnOptions: (columnIndex?: number, disableHideAbility?: boolean) => DropdownOption[] = useCallback(
    (columnIndex, disableHideAbility) => {
      const columnDropdownOptions: DropdownOption[] = [
        ...missedColumns.map(column => ({
          id: column.names[0],
          name: `Match to ${column.label}`,
          customOptionName: (
            <Text variant={TextSize.S} className={styles.dropdownItemLabel}>
              Match to{' '}
              <Text bold inline variant={TextSize.S} className={styles.dropdownItemLabelKey}>
                {column.label}
                {column.required ? '*' : ''}
              </Text>
            </Text>
          ),
          icon: <Icon name={IconVariant.TABLE_COLUMNS} />,
        })),
      ];

      if (!disableHideAbility && columnIndex !== undefined) {
        columnDropdownOptions.push({
          id: 'remove',
          name: 'Hide this column',
          icon: <Icon name={IconVariant.TRASH} />,
          action: () => onHideColumn(columnIndex),
        });
      }

      return columnDropdownOptions;
    },
    [missedColumns, onHideColumn],
  );

  const renderColumn = (column: TableColumnDataType) => {
    const isValid = column.acceptableColumn && !column.missed;
    const name = column.acceptableColumn?.label || column.initialName || '-';
    const label = (
      <Text medium variant={TextSize.M} fontFace={FontFace.monospace} ellipsisOverflow className={styles.columnName}>
        <Text className={isValid ? '' : styles.columnNameWrong} inline fontFace={FontFace.monospace}>
          {name}
        </Text>
        {column.acceptableColumn?.required ? ' *' : ''}
      </Text>
    );

    const columnOptions = getColumnOptions(column.columnIndex, column.acceptableColumn?.required);

    return (
      <div
        className={`${styles.tableColumn}${column.acceptableColumn?.wide ? ` ${styles.columnWide}` : ''}${
          column.hidden ? ` ${styles.columnHidden}` : ''
        }`}
        key={`${column.acceptableColumn?.label} ${column.columnIndex}`}
      >
        <div className={styles.tableHeadCell}>
          <Icon
            className={isValid ? styles.checkmark : styles.close}
            name={isValid ? IconVariant.CHECK : IconVariant.CLOSE}
            width={12}
            height={12}
          />
          <div className={styles.tableHeadCellLabel}>
            {!column.missed && columnOptions.length ? (
              <Dropdown
                options={columnOptions}
                onPrimaryOptionClickAction={newName => {
                  if (column.columnIndex !== undefined) {
                    onChangeColumnName(column.columnIndex, newName);
                  }
                }}
                trigger={
                  <>
                    {label}
                    <Icon name={IconVariant.DROP_DOWN_ARROW} className={styles.dropdownTriggerIcon} />
                  </>
                }
                triggerClassName={styles.dropdownTrigger}
                dropDownContainerClassName={styles.dropdownContainer}
                placement={DropdownPlacement.BOTTOM_LEFT}
                domContainer={modalsRootElement}
                tooltip={name}
              />
            ) : (
              <Tooltip title={name}>{label}</Tooltip>
            )}
          </div>
        </div>
        <div className={styles.columnValues}>
          {column.values.map(cellValue => (
            <Text key={cellValue} variant={TextSize.XS} fontFace={FontFace.monospace} className={styles.cell}>
              {cellValue?.toString() || '-'}
            </Text>
          ))}
        </div>
      </div>
    );
  };

  if (importingItemsAmount)
    return (
      <div className={styles.tableProcessing}>
        <div className={styles.loaderContainer}>
          <div />
          <div />
          <div />
        </div>
      </div>
    );

  const sortedNonRequiredColumns = [...nonRequiredColumns].sort((columnA, columnB) => {
    if (!!columnA.hidden > !!columnB.hidden) return 1;
    else if (!!columnA.hidden < !!columnB.hidden) return -1;

    return 0;
  });

  return (
    <div className={styles.table}>
      {!!requiredColumns.length && (
        <div className={styles.tableRequiredPartContainer}>
          <div className={styles.tableRequiredPart}>{requiredColumns.map(renderColumn)}</div>
        </div>
      )}
      <div className={styles.divider}>
        <div />
      </div>
      <div className={styles.tableMainPartContainer}>
        <div className={styles.tableMainPart}>{[...sortedNonRequiredColumns].map(renderColumn)}</div>
      </div>
    </div>
  );
};
