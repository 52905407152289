import type { ReactNode } from 'react';

import type { IconVariant } from '@writercolab/ui-atoms';
import { Icon, Tag, TagColor, TagTextAlign, Text, TextColor, TextSize, Tooltip } from '@writercolab/ui-atoms';

import { cn as cx } from '@web/utils/cn';

import styles from './AppTypeTile.module.css';

interface IAppTypeTileProps {
  icon: IconVariant | React.ReactNode;
  id?: string;
  name: string;
  selected?: boolean;
  description?: string;
  preview?: boolean;
  onClick?: (id: string) => void;
  isDisabled?: boolean;
  tooltipText?: string;
  /**
   * Pass a custom tag component to the app card. Use `Tag` from WDS.
   * Override styles using tailwind via the `className` prop.
   */
  tag?: ReactNode;
  titleVariant?: TextSize;
  descriptionVariant?: TextSize;
}

export const AppTypeTile: React.FC<IAppTypeTileProps> = ({
  icon,
  id,
  name,
  description,
  selected,
  preview = false,
  onClick,
  isDisabled,
  tooltipText,
  tag,
  titleVariant = TextSize.XXXXXL,
  descriptionVariant = TextSize.XL,
}) => (
  <Tooltip placement="top" title={tooltipText} disabled={preview || !isDisabled || !tooltipText} tooltipWidth="250px">
    <div className="relative">
      {tag}
      <div
        className={cx(styles.tile, {
          [styles.selected]: selected,
          [styles.disabled]: isDisabled || preview,
        })}
        onClick={() => onClick?.(id || name)}
      >
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            {typeof icon === 'string' ? <Icon width={40} height={40} name={icon as IconVariant} /> : icon}
          </div>
          <div className="relative">
            {name && (
              <Text medium variant={titleVariant}>
                {name}
              </Text>
            )}
            <div className="relative mt-1">
              {!preview && description && (
                <Text variant={descriptionVariant} color={TextColor.GREY2}>
                  {description}
                </Text>
              )}
              {preview && (
                <div className="w-[114px]">
                  <Tag color={TagColor.GREY2} className={styles.tag} textAlign={TagTextAlign.CENTER}>
                    <Text variant={TextSize.M} color={TextColor.WHITE} smallCaps>
                      coming soon
                    </Text>
                  </Tag>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Tooltip>
);
