import type React from 'react';
import { useCallback, useMemo, useState } from 'react';

import { BillingProduct, SUPPORT_EMAIL, openNewTab } from '@writercolab/common-utils';
import { LinkText, TextColor, TextSize } from '@writercolab/ui-atoms';

import SectionHeading from 'components/organisms/SectionHeading';

import type { OrgDomain, OrgDomainId } from '@web/types';
import { useNavigate } from 'react-router';

import {
  domainDiscoverabilityAccess,
  useDomainDiscoverabilityPreferencesContext,
} from '../../../context/domainDiscoverabilityPreferencesContext';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import { launchSupportEmail, openContactSalesPage } from '../../../utils/navigationUtils';
import PageSectionPlaceholder from '../../generic/PageSectionPlaceholder/PageSectionPlaceholder';
import DeleteDomainDiscoverabilityModal from '../../molecules/DeleteDomainDiscoverabilityModal/DeleteDomainDiscoverabilityModal';
import DomainDiscoverabilityTable from '../DomainDiscoverabilityTable/DomainDiscoverabilityTable';

import styles from './styles.module.css';

interface IDomainDiscoverabilityPreferencesProps {
  showSamlCopy?: boolean;
}

export const DomainDiscoverabilityPreferences: React.FC<IDomainDiscoverabilityPreferencesProps> = ({
  showSamlCopy,
}) => {
  const {
    context,
    onDomainTeamChange,
    onDomainJoinRequiresApprovalChange,
    onDomainAccessTypeChange,
    enableProfileEmailDomain,
    onDeleteDomain,
  } = useDomainDiscoverabilityPreferencesContext();
  const { appState } = useAppState();
  const navigate = useNavigate();

  const [deleteDomain, setDeleteDomain] = useState<OrgDomain | null>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const _handleGoToSsoPageClick = useCallback(
    () => navigate(ROUTE.toAdminSso(appState.organizationId)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appState.organizationId, navigate],
  );
  const _handleSupportPageClick = useCallback(() => {
    const docPage = showSamlCopy
      ? 'https://support.writer.com/article/43-setting-up-saml-sso'
      : 'https://support.writer.com/article/224-domain-discoverability';

    return openNewTab(docPage);
  }, [showSamlCopy]);
  const _handleBillingPageClick = () => navigate(ROUTE.toBilling(appState.organizationId, BillingProduct.TEAM));
  const _handleDeleteDomainClick = (domainId: OrgDomainId) => {
    const affectedDomain = context.orgDomains.find(d => d.id === domainId);

    if (!affectedDomain) {
      return;
    }

    setDeleteDomain(affectedDomain);
    setDeleteModalOpen(true);
  };

  const handleDeleteDomainConfirm = () => {
    if (!deleteDomain) {
      return;
    }

    onDeleteDomain(deleteDomain.id);
    setDeleteModalOpen(false);
  };

  const content = useMemo(() => {
    // show banner in case of org has Trial subscription
    if (context.access[domainDiscoverabilityAccess.SHOW_LOCK_TRIAL_BANNER] && !context.isListLoading) {
      return (
        <PageSectionPlaceholder
          message={
            <>
              Domain discoverability is available for orgs subscribed to Team and Enterprise plans.
              <br /> <span onClick={_handleBillingPageClick}>Upgrade to Team</span> or{' '}
              <LinkText onClick={openContactSalesPage}>contact sales</LinkText> about Enterprise to activate this
              feature.
            </>
          }
        />
      );
    }

    // show banner in case of org has sso enabled
    if (context.access[domainDiscoverabilityAccess.SHOW_SSO_PAGE_BANNER] && !context.isListLoading) {
      return (
        <PageSectionPlaceholder
          message={
            <>
              Your organization has single sign-on set up. Go to the{' '}
              <span onClick={_handleGoToSsoPageClick}>Single Sign-on page</span> to make changes related to managing new
              users on your domain.
            </>
          }
        />
      );
    }

    return (
      <>
        {context.access[domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION] && (
          <PageSectionPlaceholder
            message={
              <>
                It looks like your email domain ({context.profileEmailDomain}) is already taken by another organization.
                If this is incorrect, contact <LinkText onClick={launchSupportEmail}>{SUPPORT_EMAIL}</LinkText> to
                resolve.
              </>
            }
          />
        )}
        {context.access[domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST] && (
          <DomainDiscoverabilityTable
            onDeleteDomain={_handleDeleteDomainClick}
            showOrgAccess={context.access[domainDiscoverabilityAccess.SHOW_ORG_ACCESS]}
            showAccessForPasswordUsers={context.access[domainDiscoverabilityAccess.SHOW_ACCESS_FOR_PASSWORD_USERS]}
            canEnableProfileDomain={context.access[domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]}
            profileEmailDomainValid={context.profileEmailDomainValid}
            orgsListLoading={context.isListLoading}
            orgDomains={context.orgDomains}
            accessTypeOptions={context.accessTypeOptions}
            teamsOptions={context.teamsOptions}
            profileEmailDomain={context.profileEmailDomain}
            onTeamOptionChanged={onDomainTeamChange}
            onJoinRequiresApprovalChange={onDomainJoinRequiresApprovalChange}
            onAccessTypeOptionChanged={onDomainAccessTypeChange}
            onEnableProfileDomainClick={enableProfileEmailDomain}
            showSamlCopy={showSamlCopy}
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context.accessTypeOptions,
    context.isListLoading,
    context.orgDomains,
    context.profileEmailDomain,
    context.profileEmailDomainValid,
    context.showSsoPageBanner,
    context.teamsOptions,
    context.access,
    showSamlCopy,
  ]);

  const description = showSamlCopy
    ? 'Manage your SAML-enabled domains below.'
    : 'Allow your colleagues to join Writer when they sign up with an email address from your organization’s domain.';

  return (
    <div className={styles.container}>
      <SectionHeading
        heading={showSamlCopy ? 'SAML domain management' : 'Domain discoverability'}
        description={
          <>
            {description}{' '}
            <LinkText className={styles.containerTextLink} onClick={_handleSupportPageClick}>
              Learn more
            </LinkText>
            .
          </>
        }
        descriptionTextColor={TextColor.GREY}
        descriptionTextVariant={TextSize.XL}
        descriptionContainerClassName={styles.description}
      />
      <div className={styles.containerTableWrapper}>{content}</div>
      {deleteDomain && (
        <DeleteDomainDiscoverabilityModal
          isOpen={deleteModalOpen}
          domainName={deleteDomain.domain}
          onClose={() => setDeleteModalOpen(false)}
          onSubmit={() => handleDeleteDomainConfirm()}
        />
      )}
    </div>
  );
};

export default DomainDiscoverabilityPreferences;
