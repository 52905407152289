import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import styles from '../styles.module.css';

interface ICustomProps {
  customUrl: string;
  updateCustomUrl: (customUrl: string) => void;
  onLinkApply: () => void;
}

export const CustomLinkUrl = ({ customUrl, updateCustomUrl, onLinkApply }: ICustomProps) => (
  <div className={cx(styles.linkWrapper, styles.customLink)}>
    <Icon name={IconVariant.ARROW_RIGHT_ALT} width={20} height={20} />
    <div className={styles.link}>
      <Text variant={TextSize.XL} className={styles.title}>
        Add a custom URL
      </Text>

      <InputGroup
        name="customUrl"
        id="customUrl"
        className={styles.customUrlInput}
        inputType={InputTypes.INPUT}
        value={customUrl}
        label=""
        placeholder="Add a URL"
        handleChangeInput={e => updateCustomUrl(e.target.value)}
        disableHelperText
      />

      <Button
        className={styles.applyButton}
        size={SizeTypes.SMALL}
        type={ButtonTypes.BLACK}
        content="Apply link"
        onClick={() => onLinkApply()}
      />
    </div>
  </div>
);

export default CustomLinkUrl;
