import { useCallback, useEffect, useMemo } from 'react';

import { TAssetToDelete, type TGetOrganizationSessionResponse, openNewTab } from '@writercolab/common-utils';
import { DataRetentionPolicyBanner, useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import { ISort } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import { type IRowProps } from 'components/organisms/BaseTable/BaseTable';
import {
  SESSIONS_TABLE_ID,
  SessionsTable,
  SessionsTableModals,
  SessionsTableUiModel,
} from 'components/organisms/SessionsTable';
import { getSessionsUrlParams } from 'components/organisms/SessionsTable/utils';

import { LoadingPage } from '@web/component-library';
import { SessionsPageParams, TGetTeamSessionsQueryParams, TSessionsSort } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { useDataRetentionBanner } from 'hooks/useDataRetentionBanner';
import useUpdateUrlParams from 'hooks/useUpdateUrlParams';
import { observer } from 'mobx-react-lite';
import { SessionsApiModel } from 'models/sessions.api';
import { useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';

import { MyWorkPageEvents, type MyWorkPageUiModel } from '../../MyWorkPageModel.ui';

import styles from './SessionsTab.module.css';

type TSessionsTabProps = {
  model: MyWorkPageUiModel;
};

export const OPEN_FROM = new Enum('table', 'action');

export const SessionsTab = observer(({ model }: TSessionsTabProps) => {
  const {
    appModel: { organizationId, teamId, analyticsService },
  } = useAppState();
  const { shouldShowPolicyBanner, ...bannerProps } = useDataRetentionBanner({ asset: TAssetToDelete.enum.Session });
  const { searchParams, updateUrlParams } = useUpdateUrlParams<
    Partial<TGetTeamSessionsQueryParams> & { [SessionsPageParams.SESSION_ID]?: string }
  >();
  const navigate = useNavigate();
  const urlParams = getSessionsUrlParams();
  const sortFieldParam = searchParams.get('sortField');
  const { applicationIds: selectedApplicationIds = [] } = urlParams;

  const tableModel = useMemo(() => {
    if (!organizationId || !teamId) {
      return undefined;
    }

    return new SessionsTableUiModel({
      organizationId,
      teamId,
      request: requestService.api,
      sessionsApi: new SessionsApiModel({
        request: requestService.api,
        organizationId,
        teamId,
      }),
      analyticsService,
    });
  }, [organizationId, teamId, analyticsService]);

  useQueueWorkerNotifications(tableModel?.notificationQueue);

  const handleUpdateUrlParams = useCallback(
    (params: Partial<TGetTeamSessionsQueryParams>) => {
      updateUrlParams(params);

      if (tableModel) {
        tableModel?.updateExtraArgs(params);
      }
    },
    [tableModel, updateUrlParams],
  );

  const handleClearFilterIndicator = useCallback(() => {
    if (tableModel) {
      tableModel.updateExtraArgs({ applicationIds: [], search: undefined });
    }
  }, [tableModel]);

  const handleClearSearchInput = useCallback(() => {
    if (tableModel) {
      handleUpdateUrlParams({ search: undefined });
      tableModel.updateExtraArgs({ search: undefined });
    }
  }, [tableModel, handleUpdateUrlParams]);

  const handleSortingChange = useCallback(
    (selectedSort: ISort) => {
      const { id } = selectedSort;

      const tableContainerRef = document.getElementById(SESSIONS_TABLE_ID);
      const analyticOpts = {
        modificationTime: 'last_updated',
        lastOpenedTime: 'last_opened',
      };

      if (tableModel) {
        tableModel?.updateExtraArgs({ sortField: id as TGetTeamSessionsQueryParams['sortField'] });

        analyticsService.track(AnalyticsActivity.myWorkTabSorted, {
          origin: 'sessions',
          option: analyticOpts[id],
        });
      }

      tableContainerRef?.scrollTo({ top: 0 });
    },
    [tableModel, analyticsService],
  );

  model.eventsQueue.read(event => {
    return MyWorkPageEvents.matchPartial(
      event.type,
      {
        clearFilterIndicator: () => handleClearFilterIndicator(),
        searchValue: params => {
          analyticsService.track(AnalyticsActivity.myWorkTabSearched, {
            origin: 'sessions',
            search_term: params?.searchValue || '',
          });

          handleUpdateUrlParams({
            search: params?.searchValue || undefined,
          });
        },
        clearSearchInput: () => handleClearSearchInput(),
      },
      {
        searchValue: event.params?.searchValue,
      },
    );
  });

  const handleOpenSession = useCallback(
    (sessionData: TGetOrganizationSessionResponse, { openedFrom }: { openedFrom: typeof OPEN_FROM.type }) => {
      if (sessionData) {
        const { organizationId, teamId, applicationId, id } = sessionData?.session || {};

        if (!organizationId || !teamId || !applicationId || !id) {
          return;
        }

        if (openedFrom === OPEN_FROM.enum.action) {
          openNewTab(ROUTE.toChatApp(organizationId, teamId, applicationId, id));
        } else {
          navigate(ROUTE.toChatApp(organizationId, teamId, applicationId, id), {
            state: {
              backTo: ROUTE.toSessions(organizationId, teamId),
            },
          });
        }
      }
    },
    [navigate],
  );

  const handleDeleteSession = useCallback(
    (sessionData: TGetOrganizationSessionResponse) => {
      if (sessionData) {
        tableModel?.setSelectedSessionData(sessionData);
        tableModel?.setIsOpenDeleteConfirmationModal(true);
      }
    },
    [tableModel],
  );

  const handleOpenEditNameModal = useCallback(
    (sessionData: TGetOrganizationSessionResponse) => {
      if (tableModel) {
        sessionData && tableModel.setSelectedSessionData(sessionData);
        tableModel.modalsManager.showModal(SessionsTableModals.enum.editTitle);
      }
    },
    [tableModel],
  );

  const handleRowClick = useCallback(
    (sessionData: IRowProps<TGetOrganizationSessionResponse>) => {
      if (sessionData.data) {
        analyticsService.track(AnalyticsActivity.sessionOpened, {
          origin: 'my_work',
          session_id: sessionData.data.session.id,
        });

        handleOpenSession(sessionData.data, { openedFrom: OPEN_FROM.enum.table });
      }
    },
    [handleOpenSession, analyticsService],
  );

  useEffect(() => {
    if (model) {
      const urlParamKeys = Array.from(searchParams.keys()).filter(key => key !== 'sortField');
      model.setFilterIndicatorKeys(urlParamKeys);
    }
  }, [model, searchParams, tableModel]);

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.myWorkTabSelected, {
      option: 'sessions',
    });
  }, [analyticsService]);

  if (!tableModel) {
    return <LoadingPage />;
  }

  return (
    <>
      {shouldShowPolicyBanner && (
        <DataRetentionPolicyBanner className={styles.retentionPolicyBanner} {...bannerProps} />
      )}

      <SessionsTable
        model={tableModel}
        onRowClick={handleRowClick}
        selectedApplicationIds={selectedApplicationIds}
        actions={{
          onRenameSession: sessionData => {
            analyticsService.track(AnalyticsActivity.sessionRenamed, {
              origin: 'my_work',
              session_id: sessionData.session.id,
            });

            handleOpenEditNameModal(sessionData);
          },
          onOpenApp: sessionData => {
            analyticsService.track(AnalyticsActivity.sessionOpened, {
              origin: 'my_work',
              session_id: sessionData.session.id,
            });

            handleOpenSession(sessionData, { openedFrom: OPEN_FROM.enum.action });
          },
          onDelete: sessionData => {
            handleDeleteSession(sessionData);
          },
        }}
        sortField={TSessionsSort.get(sortFieldParam, TSessionsSort.enum.modificationTime)}
        handleSortingChange={handleSortingChange}
        onFilterChange={handleUpdateUrlParams}
      />
    </>
  );
});
