import React, { ChangeEvent, type ReactElement, useRef } from 'react';

import { joinFileExtensions } from '@writercolab/common-utils';
import { Button, ButtonProps, IconButton, SimpleTooltip } from '@writercolab/fe.wds';
import { Icon, IconVariant } from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

import { ALL_ALLOWED_FILE_EXTENSIONS } from '../../../constants/Chat';

import styles from './FileUploadButton.module.css';

interface IFileUploadButtonProps {
  iconVariant?: IconVariant;
  allowedFileExtensions: string[];
  className?: string;
  tooltipText?: React.ReactNode;
  onChange?: (files: FileList | null) => void;
  disabled?: boolean;
  loading?: boolean;
  buttonType?: Exclude<ButtonProps['variant'], 'link'>;
  buttonSize?: ButtonProps['size'];
  buttonIcon?: React.ReactNode;
  buttonContent?: string | ReactElement;
}

export const FileUploadButton: React.FC<IFileUploadButtonProps> = ({
  className,
  iconVariant = IconVariant.UPLOAD,
  tooltipText,
  loading,
  onChange,
  disabled,
  allowedFileExtensions = ALL_ALLOWED_FILE_EXTENSIONS,
  buttonType = 'secondary',
  buttonSize,
  buttonIcon = <Icon name={iconVariant} />,
  buttonContent,
}) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const hasFiles = (files: FileList | null) => files && !isEmpty(files);

  const handleChooseFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      const { files } = e.target;

      if (hasFiles(files)) {
        onChange(files);
      }
    }
  };

  return (
    <div className={styles.container}>
      <input
        type="file"
        multiple
        className="hidden"
        ref={hiddenFileInput}
        accept={joinFileExtensions(allowedFileExtensions)}
        onChange={handleChooseFile}
      />
      <SimpleTooltip
        trigger={
          buttonContent ? (
            <Button
              className={className}
              variant={buttonType}
              size={buttonSize}
              leftIcon={buttonIcon}
              onClick={handleClick}
              disabled={disabled}
              loading={loading}
            >
              {buttonContent}
            </Button>
          ) : (
            <IconButton
              className={className}
              variant="gray"
              aria-label="Upload file"
              size={buttonSize}
              onClick={handleClick}
              disabled={disabled}
            >
              {buttonIcon}
            </IconButton>
          )
        }
        className="z-10"
        disabled={!tooltipText}
        placement="bottom"
      >
        {tooltipText}
      </SimpleTooltip>
    </div>
  );
};
