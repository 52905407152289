import { type TOrgTeamUserActivityParams } from '@writercolab/types';
import type { TWidgetConfig } from '@web/types';
import { TWidgetEditAppearance, TWidgetType } from '@web/types';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';
import { IWebAppAnalyticsTrack } from 'constants/analytics';

interface IAcademyHomeWidgetModelParams {
  config: () => TWidgetConfig<typeof TWidgetType.enum.writerAiAcademy>;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
}

export class AcademyHomeWidgetModelUi extends AbstractHomeWidgetModel<typeof TWidgetType.enum.writerAiAcademy> {
  editAppearance = TWidgetEditAppearance.enum.edit;

  constructor(private params: IAcademyHomeWidgetModelParams) {
    super(TWidgetType.enum.writerAiAcademy, () => params.config(), params.analyticsService);
  }
}
