import React from 'react';

import cx from 'classnames';

import { ITemplateDraft } from '@writercolab/common-utils';
import { sanitize } from '@writercolab/text-utils';
import {
  Button,
  ButtonTypes,
  FontFace,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  WTimeAgo,
} from '@writercolab/ui-atoms';

import { TTemplateDraft } from '@web/types';

import styles from './styles.module.css';

interface IDraftCardProps {
  draft: TTemplateDraft | ITemplateDraft;
  isHighlighted?: boolean;
  highlightTooltipText?: string;
  onDraftRate: (draftId: number) => void;
  onDraftDelete: (draftId: number) => void;
  onDraftExpand: (draftId: number) => void;
  onDraftCopy: (draftId: number) => void;
  onGoToDraft?: (draftId: number) => void;
  onDraftExport?: (draftId: number) => void;
}

export function DraftCard({
  draft,
  isHighlighted = false,
  highlightTooltipText = '',
  onDraftDelete,
  onDraftRate,
  onDraftExpand,
  onGoToDraft,
  onDraftCopy,
  onDraftExport,
}: IDraftCardProps) {
  const { id, title, body, creationTime } = draft;

  if (!id) {
    return null;
  }

  return (
    <>
      <Tooltip
        placement="right"
        title={highlightTooltipText}
        disabled={!isHighlighted || !highlightTooltipText}
        open={isHighlighted && !!highlightTooltipText}
      >
        <div
          className={cx(styles.draftCard, {
            [styles.draftCardHighlighted]: isHighlighted,
          })}
        >
          <header className={styles.header}>
            <div className={styles.controls}>
              <Tooltip title="Rate this output" placement="top">
                <Button
                  className={styles.controlButton}
                  testId="ai-assistant-draft-rate"
                  onClick={() => onDraftRate(id)}
                  icon={<Icon name={IconVariant.FEEDBACK} />}
                  type={ButtonTypes.GRAY}
                  round
                />
              </Tooltip>

              <Tooltip title="Delete output" placement="top">
                <Button
                  className={styles.controlButton}
                  testId="ai-assistant-draft-delete"
                  icon={<Icon name={IconVariant.TRASH} />}
                  type={ButtonTypes.GRAY}
                  round
                  onClick={() => onDraftDelete(id)}
                />
              </Tooltip>

              <Tooltip title="Copy text" placement="top">
                <Button
                  className={styles.controlButton}
                  testId="ai-assistant-draft-copy"
                  icon={<Icon name={IconVariant.COPY_PASTE} />}
                  type={ButtonTypes.GRAY}
                  round
                  onClick={() => onDraftCopy(id)}
                />
              </Tooltip>

              <Tooltip title="Preview output" placement="top">
                <Button
                  className={styles.controlButton}
                  testId="ai-assistant-draft-preview"
                  icon={<Icon name={IconVariant.EXPAND_ALL_SCREEN} />}
                  type={ButtonTypes.GRAY}
                  round
                  onClick={() => onDraftExpand(id)}
                />
              </Tooltip>

              {onGoToDraft && (
                <Tooltip title="Go to output" placement="top">
                  <Button
                    className={styles.controlButton}
                    testId="ai-assistant-draft-go-to"
                    icon={<Icon name={IconVariant.GO_TO_DOC} />}
                    type={ButtonTypes.GRAY}
                    round
                    onClick={() => onGoToDraft?.(id)}
                  />
                </Tooltip>
              )}
            </div>
          </header>

          <main className={styles.main}>
            {title && (
              <section className={styles.titleSection}>
                <Text className={styles.title} variant={TextSize.XXXL} color={TextColor.GREY} bold>
                  {title}
                </Text>
                <Text variant={TextSize.L} color={TextColor.GREY} className={styles.dateContainer}>
                  <WTimeAgo date={creationTime} />
                </Text>
              </section>
            )}

            {onDraftExport && (
              <div className={cx(styles.downloadContainer, styles.clickable)} onClick={() => onDraftExport(id)}>
                <div className={styles.downloadContainerSection}>
                  <Icon name={IconVariant.TRANSCRIPT} className={styles.downloadTranscriptIcon} />
                  <Text variant={TextSize.L} color={TextColor.GREY}>
                    Recording transcript
                  </Text>
                </div>
                <div className={styles.downloadContainerSection}>
                  <Text variant={TextSize.L} color={TextColor.GREY} className={styles.downloadContainerText}>
                    Download
                  </Text>
                  <Tooltip title="Download transcript" placement="top" tooltipWidth={180}>
                    <div>
                      <Button
                        type={ButtonTypes.GRAY}
                        size={SizeTypes.MEDIUM}
                        round
                        className={styles.downloadTranscriptButton}
                      >
                        <Icon name={IconVariant.DOWNLOAD} />
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}

            <section className={styles.content}>
              <Text
                className="writer-html-editor"
                variant={TextSize.L}
                fontFace={FontFace.monospace}
                dangerouslySetInnerHTML={{ __html: sanitize(body) || '' }}
              />
            </section>
          </main>
        </div>
      </Tooltip>
    </>
  );
}
