import { Inline } from 'quill';
import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';

// @ts-expect-error TODO: Inline.tagName should be string[] | string
export class StrikeFormat extends Inline {
  static override tagName = ['S', 'DEL'];
  static override blotName = QUILL_FORMAT.STRIKE;

  static create() {
    // @ts-expect-error TODO: Inline.tagName should be string[] | string
    return super.create();
  }

  optimize(context: { [key: string]: any }) {
    super.optimize(context);

    if (this.domNode.tagName !== this.statics.tagName[0]) {
      this.replaceWith(this.statics.blotName);
    }
  }
}
