import React from 'react';

import cx from 'classnames';

import { DocumentHighlightType } from '@writercolab/common-utils';
import { SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';

import HighlightHeader from './HighlightHeader';

import styles from '../styles.module.css';

interface IHighlightLoadingProps {
  type: DocumentHighlightType;
}

const getLoaderRepeatCount = (type: DocumentHighlightType): number => {
  switch (type) {
    case DocumentHighlightType.TWITTER:
    case DocumentHighlightType.LINKEDIN:
    case DocumentHighlightType.POINTS:
      return 3;
    default:
      return 1;
  }
};
export const HighlightLoading: React.FC<IHighlightLoadingProps> = ({ type }) => {
  const _repeatCount = getLoaderRepeatCount(type);
  let counter = 0;

  return (
    <div className={styles.containerHighlight}>
      <HighlightHeader type={type} />
      <div className={styles.containerHighlightLoadingContent}>
        {Array(_repeatCount)
          .fill(0)
          .map(() => (
            <div
              key={`loader-${++counter}`}
              className={cx(styles.outlineSectionContainer, styles.outlineSectionContainerLoading)}
            >
              <SkeletonLoader type={SkeletonLoaderType.DEFAULT} repeat={_repeatCount} />
            </div>
          ))}
        <SkeletonLoader
          key={`loader-${++counter}`}
          type={SkeletonLoaderType.DEFAULT}
          className={styles.skeletonLoaderShort}
        />
      </div>
    </div>
  );
};

export default HighlightLoading;
