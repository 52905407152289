import React from 'react';

import { TDeletionOptionType, wordPluralize } from '@writercolab/common-utils';
import { components } from '@writercolab/network';
import { Button, ButtonTypes, Input, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import { readableAssetOptions } from 'components/organisms/DataRetentionPreferences/DataRetentionPreferencesModel.ui';

import styles from './DataRetentionPreferences.module.css';

const SIMPLE_DAYS_PER_MONTH = 30;
const CONFIRMATION_PHRASE = 'I understand that deleted assets are not recoverable';

interface IDataRetentionConfirmationModalProps {
  onClose: () => void;
  onCancel: () => void;
  onContinue: () => void;
  retentionDays: TDeletionOptionType;
  deletionCounts: {
    assetType: components['schemas']['organization_model_AssetType'];
    count: number;
  }[];
  isLoading: boolean;
}

export const DataRetentionConfirmationModal: React.FC<IDataRetentionConfirmationModalProps> = ({
  onClose,
  onCancel,
  onContinue,
  retentionDays,
  deletionCounts,
  isLoading,
}) => {
  const [confirmationInput, setConfirmationInput] = React.useState('');
  const monthCount = Number(retentionDays) / SIMPLE_DAYS_PER_MONTH;
  const monthText = `${monthCount} ${wordPluralize(monthCount, 'month')}`;

  return (
    <Modal open handleClose={onClose} style={{ width: 500 }} title={`Delete assets older than ${monthText}`}>
      <div className={styles.modalContainer}>
        <div className={styles.modalContent}>
          <Text variant={TextSize.XL}>
            Warning, completing this action will delete selected asset types older than your data retention policy.
          </Text>
          <Text variant={TextSize.XL}>By saving this configuration, you will immediately and permanently delete:</Text>
          <div className={styles.assetSummary}>
            <table>
              <thead>
                <tr>
                  <th>Number of assets</th>
                  <th>Asset type</th>
                </tr>
              </thead>
              <tbody>
                {deletionCounts.map(({ count, assetType }) => (
                  <tr key={assetType}>
                    <td>{count}</td>
                    <td>{readableAssetOptions[assetType]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Text variant={TextSize.XL}>
            To confirm, type the following phrase:
            <span className={styles.typePhrase}>{CONFIRMATION_PHRASE}</span>
          </Text>
          <Input
            value={confirmationInput}
            placeholder="Type the phrase"
            onChange={e => setConfirmationInput(e.target.value)}
          />
        </div>
        <div className={styles.modalActions}>
          <Button
            type={ButtonTypes.DEFAULT}
            className={styles.button}
            size={SizeTypes.MEDIUM}
            content="Nevermind"
            onClick={onCancel}
          />
          <Button
            type={ButtonTypes.PRIMARY}
            className={styles.button}
            size={SizeTypes.MEDIUM}
            content="Accept and delete"
            onClick={onContinue}
            disabled={confirmationInput.trim().toLowerCase() !== CONFIRMATION_PHRASE.toLowerCase()}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  );
};
