import { action, computed, makeObservable, observable } from 'mobx';

import { AnalyticsService } from '@writercolab/analytics';
import { openNewTab } from '@writercolab/common-utils';
import { PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import type { AiAssistantSubscriptionModel, AiStudioAccountModel, TRetentionConfig } from '@writercolab/models';
import { ApplicationsApiModel, KnowledgeFilesApiModel } from '@writercolab/models';
import type { RequestServiceInitialize, components } from '@writercolab/network';
import type { TEventQueueItem, TNotificationQueueItem, TOrgTeamUserActivityParams } from '@writercolab/types';
import { NotificationQueueItemType } from '@writercolab/types';
import {
  AppOrigin,
  CacheManager,
  ChatFilesManager,
  ChatMessagesApiModel,
  ChatSessionsApiModel,
  ChatTemplateUIModel,
  DEFAULT_TTL,
  ERROR_MESSAGES,
  LocalStorageCachingStrategy,
} from '@writercolab/ui-chat-apps';

import { PromptLibraryModalModel } from 'components/organisms/PromptLibraryModal';

import type { TApplicationExpand, TPrompt, TTeamPromptEditActionDto, TVoice } from '@web/types';
import { ChatModes, EApplicationType } from '@web/types';
import { AnalyticsActivity, TWebAnalyticsParams } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';

import { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { getLogger } from '../../../utils/logger';
import { TeamPromptPanelUiModel } from '../../organisms/TeamPromptPanel';

const LOG = getLogger('ChatPageUIModel');

const APPS_WITH_ISOLATED_INTEFRACE = ['askcc'];

interface ChatUIModelOptions {
  subscriptionModel: Pick<AiAssistantSubscriptionModel, 'limits' | 'access'>;
  requestService: RequestServiceInitialize['api'];
  analyticsService: AnalyticsService<TWebAnalyticsParams, TOrgTeamUserActivityParams>;
  aiStudioBalance: AiStudioAccountModel['balance'];
  applicationIdOrAlias: string;
  sessionId?: string;
  organizationId: number;
  teamId: number;
  voices: TVoice[];
  retentionPreferences: TRetentionConfig | undefined | null;
  organizationName?: string;
}

export class ChatPageUIModel {
  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  readonly eventQueue = new ReactiveQueue<TEventQueueItem<string, string>>();
  readonly templateUIModel: ChatTemplateUIModel;
  readonly knowledgeFilesApiModel: KnowledgeFilesApiModel;
  readonly chatFilesManager: ChatFilesManager;
  readonly messagesApiModel: ChatMessagesApiModel;
  readonly sessionsApiModel: ChatSessionsApiModel;
  readonly teamPromptsApiModel: TeamPromptsApiModel;
  readonly applicationApi: ApplicationsApiModel;
  teamPromptFormUiModel: TeamPromptPanelUiModel;

  applicationIdOrAlias?: string;
  sessionId?: string;

  createTeamPromptVisible = false;

  promptLibraryModalModel: PromptLibraryModalModel;

  readonly $application = new PromisedModel({
    name: 'application',
    load: async () => {
      if (!this.applicationIdOrAlias) {
        return undefined;
      }

      return this.applicationApi.getApplicationForPreview(this.opts.teamId, this.applicationIdOrAlias);
    },
  });

  analyticsService: AnalyticsService<TWebAnalyticsParams, TOrgTeamUserActivityParams>;

  constructor(private opts: ChatUIModelOptions) {
    this.notificationQueue = new ReactiveQueue();
    this.analyticsService = this.opts.analyticsService.withContext({
      chat_app_id: () => this.applicationId,
      chat_mode: () => this.activeSession?.mode as string,
      session_id: () => this.activeSession?.id,
    });

    this.applicationIdOrAlias = opts.applicationIdOrAlias;
    this.sessionId = opts.sessionId;

    this.applicationApi = new ApplicationsApiModel({
      request: opts.requestService,
      organizationId: opts.organizationId,
    });

    this.messagesApiModel = new ChatMessagesApiModel({
      request: this.opts.requestService,
      organizationId: this.opts.organizationId,
      getActiveSessionId: () => this.activeSessionId ?? '',
    });

    this.teamPromptsApiModel = new TeamPromptsApiModel({
      notificationQueue: this.notificationQueue,
      request: this.opts.requestService,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      analyticsService: this.analyticsService,
    });

    this.sessionsApiModel = new ChatSessionsApiModel({
      request: this.opts.requestService,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      appOrigin: AppOrigin.enum.web,
      getActiveSessionId: () => this.activeSessionId ?? '',
      getApplicationId: () => this.applicationId,
      getApplicationVersionId: () => undefined,
    });

    this.knowledgeFilesApiModel = new KnowledgeFilesApiModel({
      request: this.opts.requestService,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      getApplicationId: () => this.applicationId,
    });

    this.chatFilesManager = new ChatFilesManager({
      sessionsApiModel: this.sessionsApiModel,
      knowledgeFilesApi: this.knowledgeFilesApiModel,
      notificationQueue: this.notificationQueue,
      getActiveSessionId: () => this.activeSession?.id,
      cacheManager: new CacheManager(new LocalStorageCachingStrategy(), DEFAULT_TTL),
      getFileLimit: () => this.application?.data?.documentAnalysis?.maxFiles || DEFAULT_TTL,
      getAllowedFileExtensions: () => this.application?.data?.documentAnalysis?.fileTypes || [],
    });

    this.templateUIModel = new ChatTemplateUIModel({
      sessionsApiModel: this.sessionsApiModel,
      cacheManager: new CacheManager(new LocalStorageCachingStrategy(), DEFAULT_TTL),
      request: this.opts.requestService,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      messagesApiModel: this.messagesApiModel,
      analyticsService: this.analyticsService,
      getApplication: () => this.application,
      getActiveSession: () => this.activeSession,
      onSetActiveSession: this.onSetActiveSession,
      getKnowledgeGraphAccess: () => {
        return this.opts.subscriptionModel.access?.knowledgeGraph ?? false;
      },
      getSubscriptionLimitState: () => ({
        exceeded: this.opts.subscriptionModel.limits?.coWrite?.exceeded ?? false,
        limit: this.opts.subscriptionModel.limits?.coWrite?.limit ?? 0,
      }),
      usageLimitExceeded: () =>
        !!this.opts.subscriptionModel.limits?.aiStudioMonthlyBudget?.exceeded ||
        !!this.opts.subscriptionModel.limits?.aiStudioUsage?.exceeded,
      insufficientBalance: () => !!this.opts.aiStudioBalance?.insufficientBalance,
      getVoices: () => this.opts.voices,
      getRetentionPreferences: () => this.opts.retentionPreferences,
      organizationName: this.opts.organizationName,
      onDataRetentionLearnMore: () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL),
    });

    this.teamPromptFormUiModel = new TeamPromptPanelUiModel({
      teamPromptsApiModel: this.teamPromptsApiModel,
    });

    this.promptLibraryModalModel = new PromptLibraryModalModel({
      requestService: this.opts.requestService,
      analyticsService: this.analyticsService,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
    });

    makeObservable(this, {
      applicationIdOrAlias: observable,
      createTeamPromptVisible: observable,
      sessionId: observable,

      applicationId: computed,
      application: computed,
      activeSessionId: computed,
      activeSession: computed,
      eventQueues: computed,

      onSetActiveSession: action,
      toggleTeamPromptForm: action,
      setCreateTeamPromptVisible: action,

      teamPromptsModel: computed,
      teamPromptFormUIModel: computed,

      isSourcesLoading: computed,
      isApplicationLoading: computed,
    });
  }

  get teamPromptsModel() {
    return this.teamPromptsApiModel;
  }

  get teamPromptFormUIModel() {
    return this.teamPromptFormUiModel;
  }

  get addingTeamPrompt() {
    return this.teamPromptsApiModel.loading;
  }

  get applicationId() {
    return this.application?.id;
  }

  private $activeSessionId = observable.box<string | null>(null);

  onSetActiveSession = (session: components['schemas']['ask_model_Session'] | null) => {
    if (this.templateUIModel.applicationUIModel.chatFilesManager.$uploadQueue.length !== 0) {
      return this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.basic,
        message: <div style={{ maxWidth: '320px' }}>{ERROR_MESSAGES.sessionChangeGuardFileUpload}</div>,
      });
    }

    if (
      !this.templateUIModel.applicationUIModel.isLoadingChatAnswer &&
      this.templateUIModel.applicationUIModel.chatFilesManager.$uploadQueue.length === 0
    ) {
      this.$activeSessionId.set(session?.id ?? null);

      const { activeSession } = this;

      if (activeSession && this.applicationId) {
        this.analyticsService.track(AnalyticsActivity.chatAppOpenedSession, {});
      }
    } else {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.basic,
        message: <div style={{ maxWidth: '320px' }}>{ERROR_MESSAGES.sessionChangeGuard}</div>,
      });
    }

    return null;
  };

  get activeSessionId(): string | null {
    const activeSessionId = this.$activeSessionId.get();
    const sessionExists = this.sessionsApiModel.sessions?.find(({ session }) => session.id === activeSessionId);

    if (activeSessionId && sessionExists) {
      return activeSessionId;
    }

    const { sessions } = this.sessionsApiModel;

    if (!sessions || isEmpty(sessions)) {
      return null;
    }

    if (this.sessionId === undefined) {
      return sessions[0].session?.id;
      // if session id in url
    } else if (typeof this.sessionId === 'string') {
      const session = sessions?.find(({ session }) => session.id === this.sessionId);

      if (session) {
        return session.session?.id;
      } else {
        // if session id in url is invalid
        return sessions[0].session?.id;
      }
    }

    return null;
  }

  get activeSession(): components['schemas']['ask_model_Session'] | undefined {
    const session =
      this.sessionsApiModel.sessions?.find(({ session }) => session.id === this.activeSessionId)?.session ?? null;

    if (!session) {
      return undefined;
    } else {
      return {
        ...session,
        mode: ChatModes.get(
          this.templateUIModel.applicationUIModel.$localSessionModes.value?.get(session.id),
          ChatModes.enum.chat,
        ),
        title: this.templateUIModel.sessionsUIModel.$localSessionNames.value?.get(session.id),
      };
    }
  }

  get application() {
    if (this.isChatApplicationData(this.$application.value)) {
      return this.$application.value;
    }

    return undefined;
  }

  get eventQueues(): ReactiveQueue<TEventQueueItem<string, string>>[] {
    return [this.eventQueue, this.templateUIModel.applicationUIModel.eventQueue];
  }

  get isIsolated() {
    if (this.applicationIdOrAlias) {
      return APPS_WITH_ISOLATED_INTEFRACE.includes(this.applicationIdOrAlias);
    }

    return false;
  }

  get isSourcesLoading() {
    return (
      this.templateUIModel?.chatFilesManager?.isLoading ||
      this.templateUIModel?.chatFilesManager?.isLoadingKnowledgeFiles
    );
  }

  get isApplicationLoading() {
    return (
      this.$application.status === 'pending' ||
      this.sessionsApiModel.$sessions.status === 'pending' ||
      this.messagesApiModel.isInitialLoadingMessages
    );
  }

  setCreateTeamPromptVisible(value: boolean, prompt?: Partial<TPrompt>) {
    this.teamPromptFormUiModel.formBody.form.prompt.set(prompt?.prompt || '');
    this.teamPromptFormUiModel.formBody.form.title.set('');
    this.teamPromptFormUiModel.formBody.form.description.set('');
    this.teamPromptFormUiModel.formBody.form.tags.set([]);
    this.analyticsService.track(AnalyticsActivity.saveTeamPromptsAw, {});
    this.toggleTeamPromptForm(value);
  }

  toggleTeamPromptForm(value: boolean) {
    this.createTeamPromptVisible = value;
  }

  addTeamPrompt = async (teamPromptActionDto: TTeamPromptEditActionDto) => {
    await this.teamPromptsApiModel.create(teamPromptActionDto, false);
    this.notificationQueue.enqueue({
      type: NotificationQueueItemType.enum.success,
      message: 'Added to team prompts',
    });
    LOG.debug('Team prompt created', teamPromptActionDto);
  };

  private isChatApplicationData = (
    application?: Pick<TApplicationExpand, 'type' | 'data'>,
  ): application is {
    type: typeof EApplicationType.enum.chat;
    data: components['schemas']['template_model_BriefApplicationData_BriefChatApplicationData'];
  } => {
    return application?.type === EApplicationType.enum.chat;
  };
}
