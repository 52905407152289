import React from 'react';

import { BaseComponentsProps } from '@writercolab/common-utils';
import { Icon, IconVariant, Text, TextSize, Tooltip } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IStepDescriptionProps extends BaseComponentsProps {
  step: number;
  description: string;
  infoText?: string;
}

export const StepDescription = ({ step, description, infoText }: IStepDescriptionProps) => (
  <div className={styles.stepDescription}>
    <span>{step}</span>

    <Text variant={TextSize.XXXL}>{description}</Text>
    {infoText && (
      <Tooltip title={infoText} placement="top">
        <span>
          <Icon name={IconVariant.INFO_OUTLINED} />
        </span>
      </Tooltip>
    )}
  </div>
);

export default StepDescription;
