import React, { useMemo } from 'react';

import cx from 'classnames';

import { openNewTab } from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  LinkText,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import type { AutocompleteOption } from '@writercolab/ui-molecules';
import { TagsGroup } from '@writercolab/ui-molecules';

import type { TTeamPromptActionDto, TTeamPromptEditActionDto, TTeamPromptTag } from '@web/types';
import { TAppDecorationId, TTeamPromptActionId } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { TeamPromptPanelUiModel } from '.';
import type { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { sortByNameProperty } from '../../../utils/arrayUtils';
import { getLogger } from '../../../utils/logger';
import { IconSelect } from '../../molecules/IconSelect/IconSelect';
import { InputFieldLabel } from '../../molecules/InputFieldLabel';
import { InputField } from '../InputField/InputField';
import { TextEditorField } from '../TextEditorField/TextEditorField';
import { TextareaField } from '../TextareaField/TextareaField';

import styles from './TeamPromptPanel.module.css';

const LOG = getLogger('TeamPromptPanel');

interface ITeamPromptPanelProps {
  promptId?: string;
  loading?: boolean;
  onPromptActionClick?: (teamPromptActionDto: TTeamPromptActionDto | TTeamPromptEditActionDto) => void;
  teamPromptsApiModel: TeamPromptsApiModel;
  teamPromptFormUiModel?: () => TeamPromptPanelUiModel | undefined;
  onPromptRemoveClick?: (id: string) => void;
  onPromptDuplicateClick?: (id: string) => void;
}

const SUPPORT_PAGE = 'https://support.writer.com/article/252-working-with-team-prompts';

const actionOptions: DropdownOption<Partial<typeof TTeamPromptActionId.type>>[] = [
  { id: TTeamPromptActionId.enum.duplicate, name: 'Duplicate', icon: <Icon name={IconVariant.COPY_AND_ADD} /> },
  {
    id: TTeamPromptActionId.enum.delete,
    name: 'Delete',
    warning: true,
    icon: <Icon name={IconVariant.TRASH} />,
  },
];

const appIconsList = TAppDecorationId.partialHash({
  ads: {
    name: 'Ads',
    icon: <Icon name={IconVariant.CONSOLE_APP_ADS} height={24} width={24} />,
  },
  social: {
    name: 'Social',
    icon: <Icon name={IconVariant.CONSOLE_APP_SOCIAL} height={24} width={24} />,
  },
  messaging: {
    name: 'Messaging',
    icon: <Icon name={IconVariant.CONSOLE_APP_MESSAGING} height={24} width={24} />,
  },
  blogs: {
    name: 'Blogs',
    icon: <Icon name={IconVariant.CONSOLE_APP_BLOGS} height={24} width={24} />,
  },
  caseStudies: {
    name: 'Case studies',
    icon: <Icon name={IconVariant.CONSOLE_APP_CASE_STUDIES} height={24} width={24} />,
  },
  lists: {
    name: 'Lists',
    icon: <Icon name={IconVariant.CONSOLE_APP_LISTS} height={24} width={24} />,
  },
  errors: {
    name: 'Errors',
    icon: <Icon name={IconVariant.CONSOLE_APP_ERRORS} height={24} width={24} />,
  },
  faqs: {
    name: 'FAQs',
    icon: <Icon name={IconVariant.CONSOLE_APP_FAQ} height={24} width={24} />,
  },
  summaries: {
    name: 'Summaries',
    icon: <Icon name={IconVariant.CONSOLE_APP_SUMMARIES} height={24} width={24} />,
  },
  supportArticles: {
    name: 'Support articles',
    icon: <Icon name={IconVariant.CONSOLE_APP_SUPPORT_ARTICLES} height={24} width={24} />,
  },
  emails: {
    name: 'Emails',
    icon: <Icon name={IconVariant.CONSOLE_APP_EMAILS} height={24} width={24} />,
  },
  shortform: {
    name: 'Shortform',
    icon: <Icon name={IconVariant.CONSOLE_APP_SHORT_FORM} height={24} width={24} />,
  },
  longform: {
    name: 'Longform',
    icon: <Icon name={IconVariant.CONSOLE_APP_LONG_FORM} height={24} width={24} />,
  },
  image: {
    name: 'Image',
    icon: <Icon name={IconVariant.CONSOLE_APP_IMAGE} height={24} width={24} />,
  },
});

const toAutocompleteOption = (tag: TTeamPromptTag): AutocompleteOption => ({
  name: tag.name,
});

export const TeamPromptPanel = observer<ITeamPromptPanelProps>(
  ({
    teamPromptsApiModel,
    loading,
    promptId,
    teamPromptFormUiModel,
    onPromptActionClick,
    onPromptRemoveClick,
    onPromptDuplicateClick,
  }) => {
    const model = useMemo(() => {
      if (!promptId) {
        return (
          teamPromptFormUiModel?.() ||
          new TeamPromptPanelUiModel({
            teamPromptsApiModel,
          })
        );
      }

      const prompt = teamPromptsApiModel.get(promptId);

      return new TeamPromptPanelUiModel({
        teamPromptsApiModel,
        teamPrompt: () => prompt?.value || undefined,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promptId, teamPromptFormUiModel]);

    const handleSubmit = () => {
      if (!isEmpty(model.formBody.errors)) {
        return;
      }

      onPromptActionClick?.({
        id: promptId,
        title: model.formBody.form.title.value || '',
        icon: model.formBody.form.icon.value,
        prompt: model.formBody.form.prompt.value || '',
        description: model.formBody.form.description.value,
        tagIds: model.formBody.form.tags.value?.map(t => Number(t.id)) || [],
      });
    };

    const iconOptions = Object.entries(appIconsList)
      .map(([id, opt]) => ({
        id,
        name: opt.name,
        icon: opt.icon,
        active: id === model.formBody.form.icon.value,
      }))
      .sort(sortByNameProperty);

    const onActionClick = (actionId: typeof TTeamPromptActionId.type) => {
      if (!promptId) {
        LOG.error('Prompt ID is missing');

        return;
      }

      switch (actionId) {
        case TTeamPromptActionId.enum.delete:
          onPromptRemoveClick?.(promptId);
          break;
        case TTeamPromptActionId.enum.duplicate:
          onPromptDuplicateClick?.(promptId);
          break;
        default:
          LOG.warn(`Unhandled action ID: ${actionId}`);
      }
    };

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <div>
            <Heading variant={HeadingVariant.H2}>{promptId ? 'Edit' : 'New'} team prompt</Heading>
          </div>
          {promptId && (
            <div>
              <Dropdown<Partial<typeof TTeamPromptActionId.type>>
                trigger={
                  <Button round icon={<Icon name={IconVariant.MORE_HORIZ} />} className={styles.dropdownButton} />
                }
                options={actionOptions}
                onPrimaryOptionClickAction={onActionClick}
                placement={DropdownPlacement.BOTTOM_RIGHT}
                dropDownContainerClassName={styles.dropdownActionContainer}
              />
            </div>
          )}
        </div>
        <div className={styles.inputs}>
          <div className={cx(styles.inputsRow, styles.inputsRowFlex)}>
            <div className={styles.inputGroup}>
              <InputField
                field={model.formBody.form.title}
                className={cx(styles.inputTextarea, styles.inputTitle)}
                placeholder="Type something"
              >
                <InputFieldLabel label="Title" />
              </InputField>
            </div>
            <div>
              <InputFieldLabel label="Icon" required className={styles.iconDropdownLabel} />
              <IconSelect
                className={styles.iconsDropdown}
                dropdownPlacement={DropdownPlacement.BOTTOM_LEFT}
                options={iconOptions}
                error={!!model.formBody.form.icon.error && model.formBody.form.icon.touched}
                onSelect={iconId => {
                  model.formBody.form.icon.set(iconId);
                }}
                activeId={model.formBody.form.icon.value}
              />
            </div>
          </div>
          <div className={styles.inputsRow}>
            <TextEditorField field={model.formBody.form.prompt} editor={model.promptEditor}>
              <InputFieldLabel label="Prompt" required />
            </TextEditorField>
            <div className={styles.inputDescription}>
              <Text color={TextColor.GREY2} variant={TextSize.M}>
                Add a variable by wrapping it in curly brackets.{' '}
                <LinkText onClick={() => openNewTab(SUPPORT_PAGE)}>Learn more</LinkText>.
              </Text>
            </div>
          </div>
          <div className={styles.inputsRow}>
            <TextareaField
              field={model.formBody.form.description}
              className={cx(styles.inputTextarea, styles.inputTextareaDescription)}
              placeholder="Type something"
            >
              <InputFieldLabel label="Description" />
            </TextareaField>
          </div>
          <div className={styles.inputsRow}>
            <TagsGroup
              initialTags={teamPromptsApiModel.tagsList.map(toAutocompleteOption)}
              tags={model.formBody.form.tags.value?.map(toAutocompleteOption) || []}
              onUpdate={(tags: AutocompleteOption[]) => model.onTagChange(tags)}
            />
          </div>
        </div>
        <div className={styles.action}>
          <Button
            className={styles.actionButton}
            type={ButtonTypes.BLACK}
            disabled={!isEmpty(model.formBody.errors) || !model.formValuesTouched}
            onClick={handleSubmit}
            isLoading={loading}
          >
            <Text variant={TextSize.XXL} color={TextColor.WHITE} bold>
              {promptId ? 'Update' : 'Add prompt'}
            </Text>
          </Button>
        </div>
      </div>
    );
  },
);
