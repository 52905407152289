import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { validateEmail } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Input,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface BillingContactFormProps {
  contact?: string;
  onSubmit?: (contact: string) => void;
  onCancel?: () => void;
  isSubmitting?: boolean;
}

export const BillingContactForm: React.FC<BillingContactFormProps> = ({
  contact,
  onSubmit,
  onCancel,
  isSubmitting,
}) => {
  const [contactValue, setContactValue] = useState('');
  const [valid, setValid] = useState<boolean | null>(null);

  const handleChange = e => {
    setContactValue(e.target.value);
    setValid(validateEmail(e.target.value));
  };

  useEffect(() => {
    if (contact) {
      setContactValue(contact);
    }
  }, [contact]);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit && onSubmit(contactValue);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  return (
    <div className={styles.container}>
      <form action="" onSubmit={e => handleSubmit(e)}>
        <div className={styles.containerRow}>
          <Input
            onChange={handleChange}
            className={cx(styles.contactInput, {
              [styles.contactInputValid]: valid === true,
            })}
            value={contactValue}
          />
          {valid && (
            <div className={cx('flexContainerCentered', styles.inputCheck)}>
              <Icon name={IconVariant.CHECK} width={10} height={10} />
            </div>
          )}
        </div>
        <div className={cx(styles.containerRow, styles.containerRowActions)}>
          <Button
            className={cx(styles.containerButton, styles.buttonCancel)}
            size={SizeTypes.SMALL}
            type={ButtonTypes.DEFAULT}
            content="Cancel"
            onClick={handleCancel}
          >
            <Text variant={TextSize.L} color={TextColor.BLACK}>
              Cancel
            </Text>
          </Button>
          <Button
            htmlType="submit"
            size={SizeTypes.XS}
            type={ButtonTypes.BLACK}
            className={cx(styles.containerButton)}
            disabled={valid === false}
            isLoading={isSubmitting}
          >
            {!isSubmitting && (
              <Text
                variant={TextSize.L}
                color={TextColor.WHITE}
                className={cx({
                  [styles.containerButtonTextDisabled]: valid === false,
                })}
              >
                Update
              </Text>
            )}
          </Button>
        </div>
      </form>
    </div>
  );
};
