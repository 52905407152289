import React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import { IOnboardingTutorialFlow, IStepConfig } from '../common/common';

import styles from '../styles.module.css';

interface IStepWindowProps {
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
  stepsConfig: IStepConfig[];
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
  flow: IOnboardingTutorialFlow | null;
  currentStepsConfig: IStepConfig;
}

export const StepWindow: React.FC<IStepWindowProps> = ({
  onPrevButtonClick,
  onNextButtonClick,
  stepsConfig,
  currentStepsConfig,
  flow,
  onClose,
}) => {
  const firstStep = currentStepsConfig.id === 1;
  const lastStep = currentStepsConfig.id === stepsConfig.length;
  let nextButtonContent = firstStep ? 'Start tour' : 'Next';

  if (lastStep) {
    nextButtonContent = 'Head to demo doc';
  }

  return (
    <>
      {currentStepsConfig.showDimmer && <div className={styles.containerDimmer} />}
      <div className={styles.containerWindow}>
        <div className={cx(styles.containerWindowCloseButton, styles.clickable)} onClick={onClose}>
          <Icon name={IconVariant.CLOSE} />
        </div>
        <div
          className={cx(styles.containerWindowHeading, {
            [styles.containerWindowHeadingTeam]: flow === IOnboardingTutorialFlow.TEAM,
            [styles.containerWindowHeadingPro]: flow === IOnboardingTutorialFlow.PRO,
            [styles.containerWindowHeadingFirstStep]: firstStep,
          })}
        >
          {currentStepsConfig.heading}
        </div>
        <div className={styles.containerWindowContent}>
          <div className={styles.containerCounters}>
            <Text extraSmallCaps color={TextColor.GREY3} variant={TextSize.XS} bold>
              Step {currentStepsConfig.id} of {stepsConfig.length}
            </Text>
          </div>
          <div className={styles.containerTitle}>
            <Heading variant={HeadingVariant.H4}>{currentStepsConfig.title}</Heading>
          </div>
          <div className={styles.containerText}>
            <Text variant={TextSize.L}>{currentStepsConfig.description}</Text>
          </div>
        </div>
        <div className={styles.containerActions}>
          <Button
            className={styles.inputsSkipButton}
            size={SizeTypes.SMALL}
            type={ButtonTypes.BORDERED}
            content={firstStep ? 'Skip for now' : 'Previous'}
            onClick={onPrevButtonClick}
          />
          <Button
            className={styles.inputsSubmitButton}
            size={SizeTypes.SMALL}
            type={ButtonTypes.SECONDARY}
            content={nextButtonContent}
            onClick={onNextButtonClick}
          />
        </div>
      </div>
    </>
  );
};
