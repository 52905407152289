import React, { useState } from 'react';

import cx from 'classnames';

import { invalidateAllTokens } from '@writercolab/common-utils';
import { Button, ButtonTypes, Heading, HeadingVariant, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import { useAppSessionContext } from '../../../../state/sessionContext';
import { getLogger } from '../../../../utils/logger';

import styles from '../styles.module.css';

const LOG = getLogger('DeviceManagementSection');

interface IDeviceManagementSectionProps {}

export const DeviceManagementSection: React.FC<IDeviceManagementSectionProps> = () => {
  const { signOutUser } = useAppSessionContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [processingSignout, setProcessingSignout] = useState(false);

  const _onButtonClick = () => {
    setModalOpen(true);
  };

  const _onCancel = () => {
    setModalOpen(false);
  };

  const _onConfirmSignout = async () => {
    try {
      setProcessingSignout(true);
      await invalidateAllTokens();
      await signOutUser();
    } catch (error) {
      LOG.error('Error signing out user', error);
    } finally {
      setProcessingSignout(false);
      setModalOpen(false);
    }
  };

  return (
    <>
      <div className={cx(styles.containerRowColumn, styles.containerRowColumnSection)}>
        <Heading variant={HeadingVariant.H3} className={cx(styles.containerRowTitle)}>
          Device management
        </Heading>

        <div className={styles.containerRowColumn}>
          <Text variant={TextSize.L} bold>
            Sign out on all devices
          </Text>
          <Text variant={TextSize.M} className={styles.containerRowTitleDescription}>
            You’ll be signed out from Writer on any device where you’re currently signed in.
          </Text>
        </div>

        <div className={styles.containerRowColumnAction}>
          <Button
            type={ButtonTypes.SECONDARY}
            size={SizeTypes.SMALL}
            isLoading={processingSignout}
            className={styles.signoutPasswordButton}
            onClick={_onButtonClick}
          >
            <Text variant={TextSize.L} bold>
              Sign out everywhere
            </Text>
          </Button>
        </div>
      </div>
      <Modal open={modalOpen} handleClose={_onCancel} title="Sign out everywhere" style={{ width: 328 }}>
        <div>
          <Text variant={TextSize.XL}>Are you sure you want to sign out of your Writer account on all devices?</Text>
          <div>
            <div className={styles.modalControlButtons}>
              <Button type={ButtonTypes.DEFAULT} content="Never mind" onClick={_onCancel} />
              <Button
                isLoading={processingSignout}
                className={styles.signoutButton}
                type={ButtonTypes.PRIMARY}
                content="Sign out"
                onClick={_onConfirmSignout}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
