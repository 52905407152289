import React from 'react';

import { Button, ButtonTypes, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import { SeoBlogUseDraftAction } from '@web/types';

import { ITemplateDraftExtended } from './BlogDrafts';

import styles from '../styles.module.css';

interface IUseDraftConfirmationModalProps {
  draft: ITemplateDraftExtended;
  onCloseModal: () => void;
  onActionClick: (draft: ITemplateDraftExtended, actionType: SeoBlogUseDraftAction) => void;
}

export const UseDraftConfirmationModal: React.FC<IUseDraftConfirmationModalProps> = ({
  draft,
  onCloseModal,
  onActionClick,
}) => (
  <Modal open handleClose={onCloseModal} style={{ width: 444 }} title="Replace or add to your current doc?">
    <div className={styles.useDraftConfirmationModalContainer}>
      <div className={styles.useDraftConfirmationModalContent}>
        <Text variant={TextSize.XL} className={styles.useDraftConfirmationModalContentItem}>
          Your doc already has some text.
        </Text>
        <Text variant={TextSize.XL} className={styles.useDraftConfirmationModalContentItem}>
          Do you want to replace your current text with this draft? Or, add it below your current text?
        </Text>
        <Text variant={TextSize.XL} className={styles.useDraftConfirmationModalContentItem}>
          Alternatively, close this popup and copy this draft to your clipboard.
        </Text>
      </div>
      <div className={styles.useDraftConfirmationModalAction}>
        <Button
          type={ButtonTypes.DEFAULT}
          className={styles.button}
          size={SizeTypes.MEDIUM}
          content="Add below current text"
          onClick={() => onActionClick(draft, SeoBlogUseDraftAction.ATTACH)}
        />
        <Button
          type={ButtonTypes.PRIMARY}
          className={styles.button}
          size={SizeTypes.MEDIUM}
          content="Replace text"
          onClick={() => onActionClick(draft, SeoBlogUseDraftAction.REPLACE)}
        />
      </div>
    </div>
  </Modal>
);
