import React from 'react';

import cx from 'classnames';

import { BaseComponentsProps, wordPluralize } from '@writercolab/common-utils';
import { Heading, HeadingVariant, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';
import { abbreviateNumber } from '@writercolab/utils';

import styles from './TemplateQuotaBanner.module.css';

export enum QUOTA_BANNER_TYPE {
  TEMPLATE,
  BLOG,
  HIGHLIGHTS,
}

interface ITemplateQuotaBannerProps extends BaseComponentsProps {
  wordsLimit: number;
  type: QUOTA_BANNER_TYPE;
  onContactSales: () => void;
}

export const TemplateQuotaBanner: React.FC<ITemplateQuotaBannerProps> = ({
  className,
  type,
  wordsLimit,
  onContactSales,
}) => {
  const _wordsLimit = abbreviateNumber(wordsLimit, true);

  return (
    <div className={className}>
      <div
        className={cx(styles.banner, {
          [styles.bannerWide]: type === QUOTA_BANNER_TYPE.BLOG,
        })}
      >
        {type === QUOTA_BANNER_TYPE.HIGHLIGHTS && (
          <Heading variant={HeadingVariant.H4} className={styles.bannerHeading}>
            Looks like you’ve reached your <br /> monthly limit of {_wordsLimit} {wordPluralize(wordsLimit, 'word')}{' '}
            generated. <br /> To upgrade,{' '}
            <span className={styles.bannerContactSales} onClick={onContactSales}>
              contact sales
            </span>
            .
          </Heading>
        )}

        {type === QUOTA_BANNER_TYPE.TEMPLATE && (
          <Heading bold variant={HeadingVariant.H4} className={styles.bannerHeading}>
            Looks like you’ve reached your monthly limit of <br /> {_wordsLimit} {wordPluralize(wordsLimit, 'word')}{' '}
            generated. To upgrade,{' '}
            <span className={styles.bannerContactSales} onClick={onContactSales}>
              contact sales
            </span>
            .
          </Heading>
        )}

        {type === QUOTA_BANNER_TYPE.BLOG && (
          <>
            <div>
              <Heading bold variant={HeadingVariant.H4} className={styles.bannerHeading}>
                You’ve reached your limit of {_wordsLimit} {wordPluralize(wordsLimit, 'word')} generated this month.
              </Heading>
              <Text variant={TextSize.XL} className={styles.bannerDescription}>
                To get more words,{' '}
                <span className={styles.bannerContactSales} onClick={onContactSales}>
                  contact sales
                </span>{' '}
                about upgrading to a larger plan.
              </Text>
            </div>
            <Icon className={styles.bannerIcon} name={IconVariant.AWESOME_RED} />
          </>
        )}
      </div>
    </div>
  );
};

export default TemplateQuotaBanner;
