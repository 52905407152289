import { useEffect, useState } from 'react';

import { SharedQueryParam } from '@writercolab/common-utils';

import useQuery from './useQuery';

export default function useTutorial(): boolean {
  const queryParams = useQuery();
  const state = queryParams.has(SharedQueryParam.TUTORIAL) && queryParams.get(SharedQueryParam.TUTORIAL) === 'true';
  const [showTutorial, setShowTutorial] = useState(state);

  useEffect(() => {
    setShowTutorial(state);
  }, [state]);

  return showTutorial;
}
