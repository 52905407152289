import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type TPaginatedApiApp = components['schemas']['model_PaginatedResult_template_dto_GetApiResponse'];
export type TCreateApiAppResponse = components['schemas']['template_dto_ApiResponse'];
export type TApiAppResponse = components['schemas']['template_dto_GetApiResponse'];
export const ApiAppSortField = new Enum('name', 'createdAt');
export const ApiAppSortOrder = new Enum('asc', 'desc');

export type TApiAppSortField = typeof ApiAppSortField.type;
export type TApiAppSortOrder = typeof ApiAppSortOrder.type;

export interface TApiAppQuery extends TPaginatedApiAppArgs, TPaginatedApiAppExtraArgs {}

export type TPaginatedApiAppArgs = {
  limit?: number;
  offset?: number;
};

export type TPaginatedApiAppExtraArgs = {
  search?: string;
  sortField?: TApiAppSortField;
  sortOrder?: TApiAppSortOrder;
};

export type TApiAppUpdateRequestBody = components['schemas']['template_dto_UpdateApiRequest'];
