import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import { IUserProfile, RemovePersonActionType } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  DropdownOption,
  IRadioGroupOption,
  Modal,
  RadioGroup,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

import AdminUsersDropdown from '../../molecules/AdminUsersDropdown/AdminUsersDropdown';
import { IModifiedUser } from '../../molecules/PeopleList/PeopleListRow';

import styles from './DeletePersonAdvanced.module.css';

type PeopleRadioGroupOption = IRadioGroupOption<string>;

interface IDeletePersonAdvancedProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (user: IModifiedUser, actionType: RemovePersonActionType, admin: IUserProfile) => void;
  teammate: IModifiedUser;
  organizationAdmins: IUserProfile[];
  userProfile?: IUserProfile;
}

const defaultActionOptions: PeopleRadioGroupOption[] = [
  {
    value: RemovePersonActionType.ASSIGN_ALL_DOCS,
    text: <Text variant={TextSize.M}>Assign all of their docs to an org admin</Text>,
  },
  {
    value: RemovePersonActionType.ASSIGN_SHARED_DOCS,
    text: <Text variant={TextSize.M}>Assign only their shared docs to an org admin</Text>,
  },
  {
    value: RemovePersonActionType.DELETE_ALL_DOCS,
    text: <Text variant={TextSize.M}>Delete their docs</Text>,
  },
];

const optionsWithAdditionsControls = [
  RemovePersonActionType.ASSIGN_ALL_DOCS,
  RemovePersonActionType.ASSIGN_SHARED_DOCS,
];

export const DeletePersonAdvanced: React.FC<IDeletePersonAdvancedProps> = ({
  isOpen,
  teammate,
  onSubmit,
  onClose,
  organizationAdmins,
  userProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const [actionOptions, setActionOptions] = useState<PeopleRadioGroupOption[]>(defaultActionOptions);
  const [selectedActionOption, setSelectedActionOption] = useState<RemovePersonActionType>(
    RemovePersonActionType.ASSIGN_ALL_DOCS,
  );
  const [selectedAdmin, setSelectedAdmin] = useState<IUserProfile>();

  const onSubmitClick = () => {
    setLoading(true);
    selectedAdmin && onSubmit(teammate, selectedActionOption, selectedAdmin);
    onClose();
  };

  const onTargetPersonChange = useCallback(
    (id: number) => {
      const adminUser = organizationAdmins.find(({ id: adminId }) => adminId === id);

      if (!adminUser) {
        return;
      }

      setSelectedAdmin(adminUser);
    },
    [organizationAdmins],
  );

  useEffect(() => {
    userProfile && setSelectedAdmin(userProfile);
  }, [userProfile]);

  useEffect(() => {
    if (!selectedAdmin || isEmpty(organizationAdmins)) {
      return;
    }

    const options = organizationAdmins.map(
      (userDetails: IUserProfile) =>
        ({
          id: `${userDetails.id}`,
          name: isEmpty(userDetails.fullName) ? userDetails.email : userDetails.fullName,
        }) as DropdownOption,
    );

    setActionOptions(
      defaultActionOptions.map(o => {
        const _option = { ...o };

        if (
          optionsWithAdditionsControls.includes(_option.value as RemovePersonActionType) &&
          userProfile &&
          _option.value === selectedActionOption
        ) {
          _option.additions = (
            <div className={styles.dropdownContainerWrapper}>
              <AdminUsersDropdown
                handleChange={onTargetPersonChange}
                options={options}
                selectedAdmin={selectedAdmin}
                userProfile={userProfile}
              />
            </div>
          );
        } else {
          _option.additions = undefined;
        }

        return _option as PeopleRadioGroupOption;
      }),
    );
  }, [onTargetPersonChange, organizationAdmins, selectedActionOption, selectedAdmin, userProfile]);

  const onOptionChange = e => {
    setSelectedActionOption(e.target.value);

    if (optionsWithAdditionsControls.includes(e.target.value)) {
      setSelectedAdmin(userProfile);
    }
  };

  useEffect(() => setLoading(false), [isOpen]);

  return (
    <Modal warning open={isOpen} title="Delete user" style={{ width: 444, marginBottom: 140 }} handleClose={onClose}>
      <div className={styles.deleteUserContainer}>
        <div className={styles.deleteUserContainerDesc}>
          <Text variant={TextSize.XL}>
            Hold on! Deleting a user is a serious action. This means that we’ll completely delete this user’s personal
            data, including name and email. As a result, you’ll lose any history of edits or reporting data related to
            this user.
          </Text>
          <div className={cx(styles.deleteUserContainerSeparator, styles.deleteUserContainerSeparatorSmall)}></div>
          <div className={cx(styles.deleteUserContainerSeparator, styles.deleteUserContainerSeparatorLarge)}></div>
          <Text variant={TextSize.XL}>To proceed, you’ll need to decide what to do with their docs.</Text>
        </div>
        <div className={styles.submitDeleteUserOptionsContainer}>
          <RadioGroup
            options={actionOptions}
            onChange={onOptionChange}
            currentValue={selectedActionOption}
            column
            className={styles.radioGroupContainer}
          />
        </div>
        <div className={styles.deleteUserContainerAction}>
          <Button
            className={styles.submitDeleteUserButton}
            type={ButtonTypes.PRIMARY}
            content="Delete user"
            onClick={onSubmitClick}
            isLoading={loading}
            danger
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeletePersonAdvanced;
