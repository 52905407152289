import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import {
  ISuggestionMetaAcronym,
  ISuggestionMetaEmoji,
  ISuggestionSection,
  SuggestionCategoryType,
  findSuggestionSection,
} from '@writercolab/common-utils';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { EmojiSelection } from '@writercolab/ui-molecules';

import { AnalyticsActivity } from 'constants/analytics';
import { lensProp, pipe, set, uniq } from 'ramda';
import analytics from 'services/analytics/analyticsService';

import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { CommonAcronymsAllowlistModal } from '../CommonAcronymsAllowlistModal';
import SuggestionCategory from '../SuggestionCategory';
import SuggestionCategoryWithRules from '../SuggestionCategoryWithRules';
import SuggestionPageHeader from '../SuggestionPageHeader';
import SuggestionRule from '../SuggestionRule';
import { SuggestionSimpleRule } from '../SuggestionRule/SuggestionSimpleRule';
import SuggestionRuleGroup from '../SuggestionRuleGroup';
import { defaultAcronyms } from '../structure/defaultAcronyms';
import structure from '../structure/writingStyle';

import styles from '../styles.module.css';

const commonAcronymCheckLens = lensProp<ISuggestionMetaAcronym, 'commonAcronymCheck'>('commonAcronymCheck');
const ignoreLens = lensProp<ISuggestionMetaAcronym, 'ignore'>('ignore');
const defaultIgnoreLens = lensProp<ISuggestionMetaAcronym, 'defaultIgnore'>('defaultIgnore');
const myIgnoreLens = lensProp<ISuggestionMetaAcronym, 'myIgnore'>('myIgnore');
const format = lensProp<ISuggestionMetaAcronym, 'format'>('format');
const writingStyleBannedEmojis = lensProp<ISuggestionMetaEmoji, 'bannedEmojis'>('bannedEmojis');

const ACRONYMS_META_FORMAT_DEFAULT_VALUE = 'not_exists';

export const WritingStylePage: React.FC = () => {
  usePageTitle('Writing style');
  const [commonAcronymsAllowlistVisible, setCommonAcronymsAllowlistVisible] = useState(false);

  const {
    suggestionSettings,
    isSaving,
    isReadOnly,
    isTeamNameShown,
    lastUpdated,
    lastUpdatedBy,
    updateSectionMeta,
    setSectionEnabled,
    teamName,
  } = useSuggestionsContext();

  useEffect(() => {
    analytics.track(AnalyticsActivity.suggestionsWritingStyleViewed, {});
  }, []);

  const acronyms: ISuggestionSection<ISuggestionMetaAcronym> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.ACRONYM, suggestionSettings)!,
    [suggestionSettings],
  );

  const emojis: ISuggestionSection<ISuggestionMetaEmoji> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.EMOJIS, suggestionSettings)!,
    [suggestionSettings],
  );

  const onUpdateAcronymRule = (checked: boolean) => {
    const newAcronymMeta = pipe(set(defaultIgnoreLens, true), set(commonAcronymCheckLens, checked))(acronyms.meta);
    updateSectionMeta(SuggestionCategoryType.ACRONYM, newAcronymMeta);
  };

  const onUpdateAcronymFormat = (value?: string) => {
    if (!value) {
      setSectionEnabled(SuggestionCategoryType.ACRONYM, false);
    } else {
      const formatValue = value === ACRONYMS_META_FORMAT_DEFAULT_VALUE ? null : value;
      const newAcronymMeta = pipe(
        set(format, formatValue),
        set(commonAcronymCheckLens, value ? acronyms.meta.commonAcronymCheck : false),
      )(acronyms.meta);
      updateSectionMeta(SuggestionCategoryType.ACRONYM, newAcronymMeta);
    }
  };

  const onChangeBannedEmojis = (newBannedEmojis: string[]) => {
    const newSectionMeta = set(writingStyleBannedEmojis, newBannedEmojis, emojis.meta);
    updateSectionMeta(SuggestionCategoryType.EMOJIS, newSectionMeta);
  };

  const access = {
    emojiDisabled: !emojis.enabled || isReadOnly,
    acronymsDisabled: !acronyms.enabled || isReadOnly,
    editCommonAcronymsDisabled: !acronyms.meta.commonAcronymCheck || !acronyms.enabled || isReadOnly,
  };

  const writingStylePage = useMemo(
    () =>
      structure.map(section => (
        <SuggestionCategoryWithRules
          key={section.name}
          readOnly={isReadOnly}
          type={section.type}
          name={section.name}
          tag={section.tag}
          description={section.description}
          structure={section.structure}
          suggestionSettings={suggestionSettings}
          setSectionEnabled={setSectionEnabled}
          updateSectionMeta={updateSectionMeta}
        />
      )),
    [setSectionEnabled, updateSectionMeta, suggestionSettings, isReadOnly],
  );

  const closeCommonAcronymsList = () => setCommonAcronymsAllowlistVisible(false);

  const onCommonAcronymsListSave = (list: string[], defaultListEnabled: boolean, customListEnabled: boolean) => {
    const filteredList = uniq(list.filter(Boolean).map(item => item.trim()));
    const commonAcronymsChecked = defaultListEnabled || customListEnabled;

    const newAcronymMeta = pipe(
      set(ignoreLens, filteredList),
      set(defaultIgnoreLens, defaultListEnabled),
      set(myIgnoreLens, customListEnabled),
      set(commonAcronymCheckLens, commonAcronymsChecked),
    )(acronyms.meta);

    updateSectionMeta(SuggestionCategoryType.ACRONYM, newAcronymMeta);
    closeCommonAcronymsList();
  };

  const onEditCommonAcronymsClick = () => {
    if (!access.editCommonAcronymsDisabled) {
      setCommonAcronymsAllowlistVisible(true);
    }
  };

  const commonAcronymsAllowlist = useMemo(() => {
    if (!acronyms.meta.ignore.length) {
      return new Array(5).fill('');
    }

    return acronyms.meta.ignore;
  }, [acronyms.meta.ignore]);

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Writing style"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      <div className={styles.description}>
        <div className={styles.descriptionItemShortest}>
          <Text color={TextColor.GREY} variant={TextSize.XL}>
            When a switch is on, we’ll let you know if your content has issues in that category.
          </Text>
        </div>
      </div>

      <SuggestionCategory
        readOnly={isReadOnly}
        type={SuggestionCategoryType.ACRONYM}
        name="Acronyms"
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
      >
        <SuggestionRuleGroup borderedRules>
          <SuggestionRule
            readOnly={access.acronymsDisabled}
            className={cx(styles.inputRuleContainer, styles.simpleRule, styles.smallPadded)}
            onUpdateRuleValue={(newValue?: string) => onUpdateAcronymFormat(newValue)}
            currentValue={acronyms.meta.format || ACRONYMS_META_FORMAT_DEFAULT_VALUE}
            defaultValue={ACRONYMS_META_FORMAT_DEFAULT_VALUE}
            dropdownOptions={[
              ['using either convention', 'with the term in parentheses', 'with the acronym in parentheses'],
            ]}
            template="When using an acronym, introduce it with {0} on first mention."
            valuesConfig={{
              [ACRONYMS_META_FORMAT_DEFAULT_VALUE]: {
                selected: ['using either convention'],
                example: [
                  'Our conference will be held at the Acme Convention Center (ACC).',
                  'Our conference will be held at the ACC (Acme Convention Center).',
                ],
              },
              definition_in_parentheses: {
                selected: ['with the term in parentheses'],
                example: ['Our conference will be held at the ACC (Acme Convention Center).'],
              },
              acronym_in_parentheses: {
                selected: ['with the acronym in parentheses'],
                example: ['Our conference will be held at the Acme Convention Center (ACC).'],
              },
            }}
          />
          <div className={styles.groupWithMargin}>
            <SuggestionRuleGroup>
              <SuggestionSimpleRule
                className={cx(styles.inputRuleContainer, styles.simpleRule, styles.smallPadded)}
                readOnly={access.acronymsDisabled}
                isChecked={acronyms.meta.commonAcronymCheck}
                onUpdateRuleValue={onUpdateAcronymRule}
              >
                Don’t introduce common acronyms (e.g. CEO).
              </SuggestionSimpleRule>
            </SuggestionRuleGroup>

            <div className={styles.editAcronymsModalTrigger} onClick={onEditCommonAcronymsClick} role="presentation">
              <Text
                variant={TextSize.L}
                bold
                color={access.editCommonAcronymsDisabled ? TextColor.GREY2 : TextColor.BLACK}
              >
                Edit common acronyms
              </Text>
            </div>
          </div>
        </SuggestionRuleGroup>
      </SuggestionCategory>

      {writingStylePage}

      <SuggestionCategory
        readOnly={isReadOnly}
        type={SuggestionCategoryType.EMOJIS}
        name="Emoji"
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
      >
        <SuggestionRuleGroup borderedRules>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.simpleRule, styles.smallPadded)}
            readOnly={access.emojiDisabled}
            isChecked={emojis.enabled}
            onUpdateRuleValue={(checked: boolean) => setSectionEnabled(SuggestionCategoryType.EMOJIS, checked)}
          >
            Don’t use negative emoji.
          </SuggestionSimpleRule>

          <div className={styles.bannedEmojis}>
            {!access.emojiDisabled && (
              <Text variant={TextSize.M} medium>
                Add banned emojis:
              </Text>
            )}
            <EmojiSelection
              readOnly={access.emojiDisabled}
              value={emojis.meta.bannedEmojis}
              onChange={onChangeBannedEmojis}
            />
          </div>
        </SuggestionRuleGroup>
      </SuggestionCategory>
      <CommonAcronymsAllowlistModal
        isOpen={commonAcronymsAllowlistVisible}
        onCancel={closeCommonAcronymsList}
        onSave={onCommonAcronymsListSave}
        defaultListEnabled={acronyms.meta.defaultIgnore}
        customListEnabled={acronyms.meta.myIgnore}
        list={commonAcronymsAllowlist}
        commonList={defaultAcronyms}
      />
    </div>
  );
};

export default WritingStylePage;
