import { useCallback, useEffect, useMemo } from 'react';

import {
  AnalyticsDraftPlace,
  ContentEditorPageMode,
  TAssetToDelete,
  type TGetDraftsResponse,
  openNewTab,
} from '@writercolab/common-utils';
import { DataRetentionPolicyBanner, Modal, Slideout, useQueueWorkerNotifications } from '@writercolab/ui-atoms';

import { type IRowProps } from 'components/organisms/BaseTable/BaseTable';
import { EditOutputPanel } from 'components/organisms/EditOutputPanel';
import { EditOutputPanelEvents, TOutputsModals } from 'components/organisms/EditOutputPanel/EditOutputPanelModel.ui';
import { OutputsTable } from 'components/organisms/OutputsTable';

import { LoadingPage } from '@web/component-library';
import { OutputPageParams } from '@web/types';
import { type TDraftWithInputsResponse, type TOutputsQueryParams } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { useDataRetentionBanner } from 'hooks/useDataRetentionBanner';
import useUpdateUrlParams from 'hooks/useUpdateUrlParams';
import { observer } from 'mobx-react-lite';
import { ROUTE } from 'services/config/routes';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';

import { MyWorkPageEvents, type MyWorkPageUiModel } from '../../MyWorkPageModel.ui';
import { OutputsTabUiModel } from './OutputsTabModel.ui';

import styles from './OutputsTab.module.css';

type TOutputsTabProps = {
  model: MyWorkPageUiModel;
};

export const OutputsTab = observer(({ model }: TOutputsTabProps) => {
  const {
    appModel: { organizationId, teamId, analyticsService, dataRetentionModel, assistantSubscription },
  } = useAppState();
  const { shouldShowPolicyBanner, ...bannerProps } = useDataRetentionBanner({
    asset: TAssetToDelete.enum.Draft,
  });
  const { searchParams, updateUrlParams } = useUpdateUrlParams<
    Partial<TOutputsQueryParams> & { [OutputPageParams.OUTPUT_ID]?: string }
  >();
  const outputId = searchParams.get(OutputPageParams.OUTPUT_ID);

  const outputTabModel = useMemo(() => {
    return new OutputsTabUiModel({
      request: requestService.api,
      organizationId: Number(organizationId),
      teamId: () => Number(teamId),
      isVoiceEnabled: () => !!assistantSubscription.access?.voice,
      analyticsService,
    });
  }, [organizationId, teamId, analyticsService, assistantSubscription.access?.voice]);

  const editPanelModel = outputTabModel?.editOutputPanelModel;
  const tableModel = outputTabModel?.outputsTableModel;

  const handleUpdateUrlParams = useCallback(
    (params: Partial<TOutputsQueryParams>) => {
      updateUrlParams(params);

      if (tableModel) {
        tableModel?.updateExtraArgs(params);
      }
    },
    [tableModel, updateUrlParams],
  );

  const handleClearFilterIndicator = useCallback(() => {
    if (tableModel) {
      tableModel.updateExtraArgs({ templateIds: [], search: '' });
    }
  }, [tableModel]);

  const handleClearSearchInput = useCallback(() => {
    if (tableModel) {
      handleUpdateUrlParams({ search: '' });
      tableModel.updateExtraArgs({ search: '' });
    }
  }, [tableModel, handleUpdateUrlParams]);

  const handleRowClick = useCallback(
    (output: IRowProps<TGetDraftsResponse>) => {
      if (output.data) {
        analyticsService.track(AnalyticsActivity.outputSelected, {
          origin: 'my_work',
          output_id: String(output.data.id),
        });

        editPanelModel.modalsManager.showModal(TOutputsModals.enum.outputPanel);
        editPanelModel.setSelectedId(output.data.id);
      }

      updateUrlParams({
        [OutputPageParams.OUTPUT_ID]: String(output.data?.id),
      });
    },
    [updateUrlParams, editPanelModel, analyticsService],
  );

  const deleteOutputIdParam = useCallback(() => {
    updateUrlParams({
      [OutputPageParams.OUTPUT_ID]: '',
    });
  }, [updateUrlParams]);

  const handleClosePanel = useCallback(() => {
    editPanelModel.modalsManager.hideModal(TOutputsModals.enum.outputPanel);
    deleteOutputIdParam();
  }, [deleteOutputIdParam, editPanelModel.modalsManager]);

  const handleCloseModal = useCallback(() => {
    editPanelModel.modalsManager.hideModal(TOutputsModals.enum.expandedOutputPanel);
  }, [editPanelModel.modalsManager]);

  const handleOutputDeleted = useCallback(() => {
    editPanelModel.modalsManager.hideModal(TOutputsModals.enum.outputPanel);
    editPanelModel.modalsManager.hideModal(TOutputsModals.enum.expandedOutputPanel);
    deleteOutputIdParam();
    tableModel?.reload();
  }, [deleteOutputIdParam, tableModel, editPanelModel.modalsManager]);

  const handleOpenInNewDoc = useCallback(
    (output: TDraftWithInputsResponse) => {
      editPanelModel.openInNewDoc(output);
    },
    [editPanelModel],
  );

  const handleCopyOutput = useCallback(
    (output: TDraftWithInputsResponse) => {
      editPanelModel.copyOutput(output);
    },
    [editPanelModel],
  );

  const handleDeleteOutput = useCallback(
    (output: TDraftWithInputsResponse) => {
      editPanelModel.deleteOutput(output.id);
    },
    [editPanelModel],
  );

  useQueueWorkerNotifications(editPanelModel?.notificationQueue);

  model.eventsQueue.read(event => {
    return MyWorkPageEvents.matchPartial(
      event.type,
      {
        clearFilterIndicator: () => handleClearFilterIndicator(),
        searchValue: params => {
          analyticsService.track(AnalyticsActivity.myWorkTabSearched, {
            origin: 'outputs',
            search_term: params?.searchValue || '',
          });

          handleUpdateUrlParams({
            search: params?.searchValue,
          });
        },
        clearSearchInput: () => handleClearSearchInput(),
      },
      {
        searchValue: event.params?.searchValue,
      },
    );
  });

  editPanelModel.eventsQueue.read(event => {
    return EditOutputPanelEvents.match(
      event.type,
      {
        outputDeleted: () => handleOutputDeleted(),
        toEventTakeawaysMode: params =>
          openNewTab(
            ROUTE.toEditorWithModeAndTemplate(
              String(organizationId),
              String(teamId),
              String(params?.documentId),
              ContentEditorPageMode.EVENT_TAKEAWAYS,
              params?.templateId,
            ),
          ),
        toBlogMode: params =>
          openNewTab(
            ROUTE.toEditorWithModeAndTemplate(
              String(organizationId),
              String(teamId),
              String(params?.documentId),
              ContentEditorPageMode.BLOG,
              params?.templateId,
            ),
          ),
        toCoWriteMode: params =>
          openNewTab(
            ROUTE.toEditorWithModeAndTemplate(
              String(organizationId),
              String(teamId),
              String(params?.documentId),
              ContentEditorPageMode.CO_WRITE,
              params?.templateId,
            ),
          ),
      },
      {
        documentId: event.params?.documentId,
        templateId: event.params?.templateId,
      },
    );
  });

  useEffect(() => {
    if (outputId) {
      editPanelModel.modalsManager.showModal(TOutputsModals.enum.outputPanel);
      editPanelModel.setSelectedId(Number(outputId));
    }
  }, [outputId, editPanelModel.modalsManager, editPanelModel]);

  useEffect(() => {
    if (model) {
      const urlParamKeys = Array.from(searchParams.keys()).filter(key => key !== 'sortField' && key !== 'outputId');
      model.setFilterIndicatorKeys(urlParamKeys);
    }
  }, [model, searchParams, tableModel]);

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.myWorkTabSelected, {
      option: 'outputs',
    });
  }, [analyticsService]);

  if (!tableModel) {
    return <LoadingPage />;
  }

  const bannerPropsWithOutput = { ...bannerProps, asset: 'Outputs' as typeof TAssetToDelete.type };

  return (
    <>
      {shouldShowPolicyBanner && (
        <DataRetentionPolicyBanner className={styles.retentionPolicyBanner} {...bannerPropsWithOutput} />
      )}

      <OutputsTable
        model={tableModel}
        onRowClick={handleRowClick}
        actions={{
          onUseInNewDoc: row => {
            analyticsService.track(AnalyticsActivity.outputUseInNewDocButtonClicked, {
              origin: 'my_work',
              output_id: String(row.id),
              mode: 'menu',
            });

            handleOpenInNewDoc(row);
          },
          onCopy: row => {
            analyticsService.track(AnalyticsActivity.outputCopied, {
              origin: 'my_work',
              output_id: String(row.id),
              mode: 'menu',
            });

            handleCopyOutput(row);
          },
          onDelete: row => {
            analyticsService.track(AnalyticsActivity.outputDeleted, {
              origin: 'my_work',
              output_id: String(row.id),
              mode: 'menu',
            });

            handleDeleteOutput(row);
          },
        }}
        onFilterChange={handleUpdateUrlParams}
      />

      <>
        <Slideout
          isOpen={editPanelModel.modalsManager.isModalVisible(TOutputsModals.enum.outputPanel)}
          onClose={handleClosePanel}
          className={styles.slideout}
        >
          <EditOutputPanel model={editPanelModel} dataRetentionPreferences={dataRetentionModel?.retentionPreferences} />
        </Slideout>

        <Modal
          open={editPanelModel.modalsManager.isModalVisible(TOutputsModals.enum.expandedOutputPanel)}
          handleClose={handleCloseModal}
          className={styles.isOutputPreview}
        >
          <EditOutputPanel
            model={editPanelModel}
            dataRetentionPreferences={dataRetentionModel?.retentionPreferences}
            isPreviewMode
          />
        </Modal>
      </>
    </>
  );
});
