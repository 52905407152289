import type { ReactElement } from 'react';
import type React from 'react';
import { useMemo } from 'react';

import cx from 'classnames';

import { convertToEllipsis } from '@writercolab/common-utils';
import { getTimeOrDateWithYear } from '@writercolab/date-utils';
import type { DateTime } from '@writercolab/network';
import type { DropdownOption } from '@writercolab/ui-atoms';
import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  TagColor,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import type { CurrentVariable } from '@writercolab/ui-chat-apps';
import { parseVariables } from '@writercolab/ui-chat-apps';
import { renderAsTag } from '@writercolab/ui-molecules';

import type { TBaseTableSection, TTeamPromptBrief, TTeamPromptTag } from '@web/types';
import { DEFAULT_TEXT_PLACEHOLDER, TTeamPromptActionId } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { sortByNameProperty } from '../../../utils/arrayUtils';
import { concatenateStrings } from '../../../utils/stringUtils';
import { ApplicationLibraryIcon } from '../../generic/ApplicationLibraryIcon';
import { TApplicationLibraryIconVariant } from '../../generic/ApplicationLibraryIcon/ApplicationLibraryIcon';
import { TextVariableEmbed } from '../../generic/TextVariableEmbed';
import type { IBodyCellProps, IRowProps } from '../BaseTable/BaseTable';
import type { TeamPromptsTableUiModel } from './TeamPromptsTable.ui';

import styles from './TeamPromptsTable.module.css';

const PROMPT_TRUNCATE_LENGTH = 125;
const PROMPT_TITLE_TRUNCATE_LENGTH = 115;

type TTableAttrs = {
  showActionsDropdown: boolean;
  showCopyAction: boolean;
  showUseAction: boolean;
  showCreator: boolean;
  showLargeIcon: boolean;
  showInlineTags: boolean;
  rowClickEnabled: boolean;
  showCreateDateColumn: boolean;
  truncatePrompt: boolean;
  titleVariant: TextSize;
};

export const TeamPromptActionOptions: DropdownOption[] = [
  { id: TTeamPromptActionId.enum.edit, name: 'Edit', icon: <Icon name={IconVariant.PENCIL} /> },
  { id: TTeamPromptActionId.enum.duplicate, name: 'Duplicate', icon: <Icon name={IconVariant.COPY_AND_ADD} /> },
  {
    id: TTeamPromptActionId.enum.delete,
    name: 'Delete',
    warning: true,
    icon: <Icon name={IconVariant.TRASH} />,
  },
];

const generateTableCell = (id: string, component: React.ReactNode): IBodyCellProps => ({
  id: `cell-${id}`,
  component,
});

const TagsCell: React.FC<{ tags: TTeamPromptTag[] }> = ({ tags }) => (
  <div className={cx(styles.cellTags, styles.cellTagsInline)}>
    {tags
      .sort(sortByNameProperty)
      .map(tag => renderAsTag(tag.name, TagColor.LIGHT_BLUE, `${tag.id}`, styles.cellTagContainer))}
  </div>
);

const DateCell: React.FC<{ date: null | DateTime }> = ({ date }) => (
  <div>
    <Text color={TextColor.GREY} variant={TextSize.XS}>
      {date ? getTimeOrDateWithYear(date) : DEFAULT_TEXT_PLACEHOLDER}
    </Text>
  </div>
);

const renderVariables = (varArr: CurrentVariable[], str: string) => {
  let prevIterationLastIndex: undefined | number;
  const result: (string | ReactElement)[] = [];

  varArr.forEach((varData, i) => {
    if (varData.end) {
      if (prevIterationLastIndex) {
        result.push(str.slice(varData.end + 1, prevIterationLastIndex));
      } else {
        result.push(str.slice(varData.end + 1));
      }
    }

    result.push(<TextVariableEmbed text={varData.text} key={`item-${i}`} />);
    prevIterationLastIndex = varData.start;
  });

  result.push(str.substring(0, prevIterationLastIndex));

  return result.reverse();
};

const TableTitleCell: React.FC<{
  prompt: string;
  title?: string | null;
  icon?: string | null;
  description?: string | null;
  createdBy?: string;
  promptTextSize?: TextSize;
  truncatePrompt?: boolean;
  iconVariant?: typeof TApplicationLibraryIconVariant.type;
}> = ({ title, prompt, icon, description, truncatePrompt, promptTextSize = TextSize.XL, createdBy, iconVariant }) => {
  const promptTitle = title || '';
  const promptTextTitle = truncatePrompt
    ? convertToEllipsis(promptTitle || '', PROMPT_TITLE_TRUNCATE_LENGTH)
    : promptTitle;
  const promptText = truncatePrompt ? convertToEllipsis(prompt, PROMPT_TRUNCATE_LENGTH) : prompt;
  const variables = useMemo(() => parseVariables(promptText), [promptText]);
  const elements = useMemo(() => renderVariables(variables.reverse(), promptText), [variables, promptText]);

  return (
    <div className={styles.titleCell}>
      <div className={styles.promptIcon}>{icon && <ApplicationLibraryIcon icon={icon} variant={iconVariant} />}</div>
      <div className={styles.promptTitleContainer}>
        <div className={styles.promptTitleText}>
          <Text variant={TextSize.XXL} medium className={styles.promptTitleTextContent}>
            {promptTextTitle}
          </Text>
        </div>
        <div className={styles.promptTitle}>
          <Text variant={promptTextSize}>{elements}</Text>
        </div>
        {!isEmpty(description) && (
          <div className={styles.promptDescription}>
            <Text variant={TextSize.L} color={TextColor.GREY2}>
              {description}
            </Text>
          </div>
        )}
        {createdBy && (
          <div className={styles.promptCreator}>
            <Text variant={TextSize.XS} color={TextColor.GREY}>
              Added by
            </Text>
            <Text variant={TextSize.XS} bold>
              {createdBy}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

type Section = TBaseTableSection<TTeamPromptBrief>;

const sectionsGenerator = (prompts: TTeamPromptBrief[]): Record<string, Section> => ({
  default: { title: '', data: prompts, condition: () => true },
});

export const generateTeamPromptRow = (
  prompt: TTeamPromptBrief,
  onPromptAction: (id: typeof TTeamPromptActionId.type, promptId: string) => void,
  attrs: TTableAttrs = {
    showActionsDropdown: true,
    showCopyAction: false,
    showUseAction: false,
    showCreator: true,
    showLargeIcon: false,
    showInlineTags: false,
    rowClickEnabled: true,
    showCreateDateColumn: true,
    truncatePrompt: false,
    titleVariant: TextSize.XL,
  },
): IRowProps => ({
  id: String(prompt.id),
  className: cx(styles.tableRow, {
    [styles.tableRowNoHover]: !attrs.rowClickEnabled,
  }),
  cells: [
    generateTableCell(
      prompt.id,
      <TableTitleCell
        key={prompt.id}
        title={prompt.title}
        description={attrs.showCreator ? prompt.description : undefined}
        prompt={prompt.prompt}
        icon={prompt.icon}
        truncatePrompt={attrs.truncatePrompt}
        promptTextSize={attrs.titleVariant}
        iconVariant={
          attrs.showLargeIcon ? TApplicationLibraryIconVariant.enum.medium : TApplicationLibraryIconVariant.enum.small
        }
        createdBy={
          attrs.showCreator
            ? concatenateStrings('', prompt?.createdBy?.firstName || '', prompt?.createdBy?.lastName || '')
            : undefined
        }
      />,
    ),
    generateTableCell(prompt.id, <TagsCell tags={prompt.tags} />),
    generateTableCell(prompt.id, attrs.showCreateDateColumn ? <DateCell date={prompt.updatedAt} /> : null),
    generateTableCell(
      'actions',
      <div className={styles.cellActionList}>
        {attrs.showActionsDropdown && (
          <Dropdown
            trigger={
              <div className={styles.actionsTrigger}>
                <Icon name={IconVariant.MORE_HORIZ} />
              </div>
            }
            options={TeamPromptActionOptions}
            onPrimaryOptionClickAction={actionId => {
              if (TTeamPromptActionId.is(actionId)) {
                onPromptAction(actionId as typeof TTeamPromptActionId.type, prompt.id);
              }
            }}
            placement={DropdownPlacement.BOTTOM_RIGHT}
            dropDownContainerClassName={styles.dropdownActionContainer}
          />
        )}
        {attrs.showUseAction && (
          <Tooltip title="Load prompt in Ask Writer" placement="top">
            <Button
              className={styles.useButton}
              type={ButtonTypes.BLACK}
              onClick={() => onPromptAction(TTeamPromptActionId.enum.use, prompt.id)}
              content="Use"
            />
          </Tooltip>
        )}
        {attrs.showCopyAction && (
          <Tooltip title="Copy prompt" placement="top">
            <Button
              className={styles.transparentButton}
              onClick={() => onPromptAction(TTeamPromptActionId.enum.copy, prompt.id)}
              type={ButtonTypes.TRANSPARENT_LINK}
              icon={<Icon name={IconVariant.COPY_PASTE} width={16} height={16} />}
            />
          </Tooltip>
        )}
      </div>,
    ),
  ],
});

export const generateTableBody = (
  model: TeamPromptsTableUiModel,
  onAction: (actionId: typeof TTeamPromptActionId.type, promptId: string) => void,
  attrs: TTableAttrs = {
    showActionsDropdown: true,
    showCopyAction: false,
    showUseAction: false,
    showCreator: true,
    showLargeIcon: false,
    showInlineTags: false,
    rowClickEnabled: true,
    showCreateDateColumn: true,
    truncatePrompt: true,
    titleVariant: TextSize.XL,
  },
): IRowProps[] => {
  const sections = sectionsGenerator(model.prompts);

  return Object.keys(sections).flatMap(sectionKey => {
    const section = sections[sectionKey];

    if (isEmpty(section.data)) {
      return [];
    }

    return section.data.map(prompt => generateTeamPromptRow(prompt, onAction, attrs));
  });
};
