import { useEffect, useRef } from 'react';

import { loadScript } from '@writercolab/common-utils';

import { getLogger } from '../utils/logger';

const LOG = getLogger('useScriptLoad');

/**
 * A custom hook that loads a script from a given URL and tracks if the script has been loaded.
 *
 * @param {string} scriptUrl - The URL of the script to load.
 * @returns {Object} An object containing a boolean flag indicating whether the script has been loaded.
 * @returns {boolean} scriptLoaded - Flag indicating whether the script has been loaded.
 *
 * @example
 * const { scriptLoaded } = useScriptLoad("https://example.com/some-script.js");
 */
export function useScriptLoad(scriptUrl: string): { scriptLoaded: boolean } {
  const scriptLoaded = useRef(false);

  useEffect(() => {
    if (!scriptLoaded.current) {
      loadScript(scriptUrl, false)
        .then(() => {
          scriptLoaded.current = true;
        })
        .catch(err => {
          LOG.error(`Failed to load script: ${scriptUrl}`, err);
        });
    }
  }, [scriptUrl]);

  return { scriptLoaded: scriptLoaded.current };
}
