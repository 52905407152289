import React from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import Papa from 'papaparse';

import { FailsAndModelsType, IWrongTermsModel, ImportItemType } from '../../types';
import { findRowByReference } from '../../utils';

import styles from './styles.module.css';

const importedItemNameByType = {
  [ImportItemType.TERMS]: 'term',
  [ImportItemType.SNIPPETS]: 'snippet',
  [ImportItemType.BILLING_GROUP]: 'billing group',
};

interface IImportSummaryStepProps<N, R> {
  tableData: string[][];
  lastNonUploadedItems?: IWrongTermsModel<N>[];
  importFailData?: R;
  onBack: () => void;
  onClose: () => void;
  onRetry: () => void;
  type: ImportItemType;
}

const textDependingOnState = {
  partiallyFailed: {
    title: (importedAmount: number, type: ImportItemType) =>
      importedAmount > 0
        ? `We’ve successfully imported ${importedAmount} ${wordPluralize(
            importedAmount,
            importedItemNameByType[type],
          )}!`
        : 'Shoot, this didn’t work',
    descriptionTitle: (failedAmount: number, importedAmount: number) =>
      `${importedAmount > 0 ? 'But we' : 'We'} found ${failedAmount} ${wordPluralize(failedAmount, 'error')}.`,
    descriptionContent:
      'Download your error report to review the errors we found. After fixing the errors, you can retry your import.',
    buttonLabel: 'Download error report',
    buttonIcon: IconVariant.DOWNLOAD,
  },
  failed: {
    title: () => 'Shoot, this didn’t work',
    descriptionTitle: () => 'The import has failed.',
    descriptionContent: 'There may have been a connection issue. Try the import again.',
    buttonLabel: 'Try again',
    buttonIcon: undefined,
  },
};

export const ImportSummaryStep = <S, R extends FailsAndModelsType>({
  tableData = [],
  lastNonUploadedItems,
  importFailData,
  onBack,
  onClose,
  onRetry,
  type,
}: IImportSummaryStepProps<S, R>) => {
  const textState = importFailData ? textDependingOnState.partiallyFailed : textDependingOnState.failed;

  const downloadErrorReport = () => {
    const errorReportData: string[][] = [];

    errorReportData.push(['Import status', 'Error description', ...tableData[0]]);
    lastNonUploadedItems?.forEach(item => {
      const nonImportedRowData = item.reference ? findRowByReference(item.reference, tableData) : [];
      errorReportData.push([
        'Not imported',
        `Missing required columns: ${item.missedRequiredFields?.map(field => field).join(';')}`,
        ...(nonImportedRowData || []),
      ]);
    });
    importFailData?.fails.forEach(fail => {
      const isPartialImported = fail.key === `fail.${importedItemNameByType[type]}.mistake.duplicate`;
      const importStatus = isPartialImported ? 'Imported but there’s a field error' : 'Not imported';
      const failDescription = isPartialImported
        ? `Common mistake '${fail?.extras?.item?.[importedItemNameByType[type]]}' already exists`
        : fail.description;
      const failItemReference = fail?.extras?.item?.reference?.split('.')?.[0];
      const failedItemRow = failItemReference && findRowByReference(failItemReference, tableData);
      const failConflictReference = fail?.extras?.conflict?.reference?.split('.')?.[0];
      const conflictItemRow = failConflictReference && findRowByReference(failConflictReference, tableData);
      const failTableRow = failedItemRow || conflictItemRow;

      if (failTableRow) {
        errorReportData.push([importStatus, failDescription, ...failTableRow]);
      }
    });
    const csv = Papa.unparse(errorReportData);
    const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'import-error-report.csv');
    tempLink.click();
  };

  const importedAmount = importFailData?.models.length || 0;
  const failedAmount = (importFailData?.fails.length || 0) + (lastNonUploadedItems?.length || 0);

  return (
    <div className={styles.summaryStep}>
      <Heading variant={HeadingVariant.H2} bold>
        {textState.title(importedAmount, type)}
      </Heading>
      <div className={styles.errorWrapper}>
        <Icon name={IconVariant.ALERT} />
        <div className={styles.errorInfo}>
          <Heading variant={HeadingVariant.H6} medium>
            {textState.descriptionTitle(failedAmount, importedAmount)}
          </Heading>
          <Text variant={TextSize.XL}>{textState.descriptionContent}</Text>
        </div>
        <div className={styles.errorButtonWrapper}>
          <Button
            type={ButtonTypes.BLACK}
            icon={textState.buttonIcon && <Icon name={textState.buttonIcon} className={styles.errorBtnIcon} />}
            content={textState.buttonLabel}
            onClick={importFailData ? downloadErrorReport : onRetry}
          />
        </div>
      </div>
      <div className={styles.bottomControls}>
        <Button type={ButtonTypes.BORDERED} size={SizeTypes.MEDIUM} onClick={onBack} content="Back" />
        <Button
          type={ButtonTypes.PRIMARY}
          size={SizeTypes.MEDIUM}
          className={styles.doneBtn}
          onClick={onClose}
          content="Done"
        />
      </div>
    </div>
  );
};
