import { IconVariant } from '@writercolab/ui-atoms';

import { TLearningCenterCategoryId, TLearningCenterVideoId, TVideoTag } from '@web/types';

import AskWriterCover from '../assets/backgrounds/onboarding/askWriter.png';
import buildBlogCover from '../assets/backgrounds/onboarding/buildBlog.png';
import fiveThingsWriterCanDo from '../assets/backgrounds/onboarding/fiveThingsWriterCanDo.png';
import promptCrafting101 from '../assets/backgrounds/onboarding/promptCrafting101.png';
import recapRecordingsCover from '../assets/backgrounds/onboarding/recapRecordings.png';
import whatIsCowrite from '../assets/backgrounds/onboarding/whatIsCowrite.png';
import { AnalyticsActivity } from './analytics';

export const AI_STUDIO_LEARNING_CENTER_NAVIGATION = [
  {
    id: TLearningCenterCategoryId.enum.docs,
    name: 'Docs',
    icon: IconVariant.GUIDE,
  },
  {
    id: TLearningCenterCategoryId.enum.writeracademy,
    name: 'Writer Academy',
    icon: IconVariant.ACADEMY,
  },
];

export const AI_ASSISTANT_LEARNING_CENTER_NAVIGATION = [
  {
    id: TLearningCenterCategoryId.enum.videos,
    name: 'Quickstart videos',
    icon: IconVariant.MEDIA_FILE_VIDEO,
  },
  {
    id: TLearningCenterCategoryId.enum.prompts,
    name: 'Prompt library',
    icon: IconVariant.MESSAGE_BUBBLE,
  },
  {
    id: TLearningCenterCategoryId.enum.support,
    name: 'Support',
    icon: IconVariant.HEADPHONES_WITH_MIC,
    link: 'https://support.writer.com/',
    analyticsEvent: AnalyticsActivity.learningCenterClickedSupportCenter,
  },
  {
    id: TLearningCenterCategoryId.enum.new,
    name: "What's new",
    icon: IconVariant.WHATS_NEW,
    link: 'https://support.writer.com/article/40-whats-new-at-writer',
    analyticsEvent: AnalyticsActivity.learningCenterClickedWhatsNew,
  },
  {
    id: TLearningCenterCategoryId.enum.webinars,
    name: 'Webinars',
    icon: IconVariant.MICROPHONE,
    analyticsEvent: AnalyticsActivity.learningCenterClickedWebinars,
  },
  {
    id: TLearningCenterCategoryId.enum.guides,
    name: 'Guides',
    icon: IconVariant.GUIDE,
    link: 'https://writer.com/guides/',
    analyticsEvent: AnalyticsActivity.learningCenterClickedGuides,
  },
  {
    id: TLearningCenterCategoryId.enum.writeracademy,
    name: 'Writer Academy',
    icon: IconVariant.ACADEMY,
    inLowerSection: true,
  },
];

export const WRITER_ACADEMY_DOMAIN_NAME = 'academy.writer.com';
export const WRITER_ACADEMY_URI = `https://${WRITER_ACADEMY_DOMAIN_NAME}/access/jwt?jwt=`;
export const LEARNING_CENTER_WEBINAR_LIMIT = 500;
export const QUICKSTART_VIDEO_QUERY_PARAMS = '?autoplay=1&modestbranding=1&rel=0';
export const QUICKSTART_VIDEOS = [
  {
    id: 0,
    route: TLearningCenterVideoId.enum['ask-writer'],
    title: 'Ask Writer',
    duration: '2:50',
    description: 'Learn how to freestyle and request anything from Writer’s enterprise-first AI',
    url: `https://www.youtube.com/embed/gGxXJIxozXU${QUICKSTART_VIDEO_QUERY_PARAMS}`,
    cover: AskWriterCover,
    ctaButtonLabel: 'Open Ask Writer',
    icon: 'ASKWRITER_ONBOARDING',
    analyticsEvent: AnalyticsActivity.learningCenterWatchedVideo1,
    articleTitle: 'Read: Getting started',
    articleLink: 'https://support.writer.com/article/200-cowrite-general-request-template-guide',
    tags: [TVideoTag.enum.onboarding, TVideoTag.enum.cowrite],
  },
  {
    id: 1,
    route: TLearningCenterVideoId.enum['blog-builder'],
    title: 'Build a blog from scratch',
    duration: '3:20',
    description: 'Learn how to take a headline and turn it into a complete blog post',
    url: `https://www.youtube.com/embed/B_4LYgIfCTk${QUICKSTART_VIDEO_QUERY_PARAMS}`,
    cover: buildBlogCover,
    ctaButtonLabel: 'Open blog builder',
    icon: 'BLOG_ONBOARDING',
    analyticsEvent: AnalyticsActivity.learningCenterWatchedVideo2,
    tags: [TVideoTag.enum.onboarding, TVideoTag.enum.cowrite],
  },
  {
    id: 2,
    route: TLearningCenterVideoId.enum.recaps,
    title: 'Recap your recordings',
    duration: '1:13',
    description: 'Learn how to take any recording and summarize into takeaways and quotes',
    url: `https://www.youtube.com/embed/M1TRzbysPxw${QUICKSTART_VIDEO_QUERY_PARAMS}`,
    cover: recapRecordingsCover,
    ctaButtonLabel: 'Open recaps builder',
    icon: 'RECAPS_ONBOARDING',
    articleTitle: 'Read: Getting started',
    articleLink: 'https://support.writer.com/article/203-cowrite-template-guide-recaps',
    analyticsEvent: AnalyticsActivity.learningCenterWatchedVideo3,
    tags: [TVideoTag.enum.onboarding, TVideoTag.enum.cowrite],
  },
  {
    id: 3,
    route: TLearningCenterVideoId.enum['writer-ai'],
    title: 'How Writer AI works',
    duration: '1:15',
    description: 'Learn about the AI that powers Writer’s generative AI features',
    url: `https://www.youtube.com/embed/9jLPUZ9mSR0${QUICKSTART_VIDEO_QUERY_PARAMS}`,
    cover: whatIsCowrite,
    icon: 'RECAPS_ONBOARDING',
    analyticsEvent: AnalyticsActivity.learningCenterWatchedVideo4,
    tags: [TVideoTag.enum.cowrite],
  },
  {
    id: 4,
    route: TLearningCenterVideoId.enum['5-things'],
    title: '5 things Writer can do',
    duration: '0:47',
    description: 'Check out 5 things Writer can do: generate, research, repurpose, analyze, and transform',
    url: `https://www.youtube.com/embed/xwDk40gPld4${QUICKSTART_VIDEO_QUERY_PARAMS}`,
    cover: fiveThingsWriterCanDo,
    icon: 'RECAPS_ONBOARDING',
    analyticsEvent: AnalyticsActivity.learningCenterWatchedVideo5,
    tags: [TVideoTag.enum.cowrite],
  },
  {
    id: 5,
    route: TLearningCenterVideoId.enum['prompt-crafting'],
    title: 'Prompt crafting 101',
    duration: '2:22',
    description: 'Check out Writer’s framework for writing great prompts',
    url: `https://www.youtube.com/embed/mVmU-FARev8${QUICKSTART_VIDEO_QUERY_PARAMS}`,
    cover: promptCrafting101,
    ctaButtonLabel: 'Open Ask Writer',
    icon: 'RECAPS_ONBOARDING',
    analyticsEvent: AnalyticsActivity.learningCenterWatchedVideo6,
    tags: [TVideoTag.enum.cowrite],
  },
];
