import React, { useEffect, useState } from 'react';

import { CancellationPopupType, ICancellationSurveyQuestion } from '@writercolab/common-utils';

import { ROUTE } from '../../../../services/config/routes';
import BillingInfoPopup, { BillingInfoPopupType } from '../BillingInfoPopup';
import CancellationConfirm from './CancellationConfirm';
import CancellationSurvey from './CancellationSurvey';
import DeleteMyAccount from './DeleteMyAccount';
import OneTimeOffer, { OneTimeOfferType } from './OneTimeOffer';
import PlanCancelled from './PlanCancelled';
import TeamTrialExtension from './TeamTrialExtension';

interface ICancellation {
  popupType: CancellationPopupType | null;
  orgId: string;
  isTeam: boolean;
  isTrialCancelledTeam: boolean;
  comments: string;
  isLoading: boolean;
  questions: ICancellationSurveyQuestion[];
  onCommentsChangeCb: (comments: string) => void;
  onChangeQuestionStateCb: (question: ICancellationSurveyQuestion) => void;
  onApplyForMonthFreeCb: () => void;
  onApplyForWeeksFreeCb: () => void;
  onStopCancellationCb: () => void;
  onCancellationNextStepCb: () => void;
}

export const Cancellation: React.FC<ICancellation> = ({
  popupType,
  orgId,
  isTeam,
  isTrialCancelledTeam,
  comments,
  questions,
  isLoading,
  onCommentsChangeCb,
  onChangeQuestionStateCb,
  onApplyForMonthFreeCb,
  onApplyForWeeksFreeCb,
  onStopCancellationCb,
  onCancellationNextStepCb,
}) => {
  const [openTeamCancel, setOpenTeamCancel] = useState(false);
  useEffect(() => setOpenTeamCancel(isTrialCancelledTeam), [isTrialCancelledTeam, isLoading]);

  return (
    <>
      <BillingInfoPopup
        open={popupType === CancellationPopupType.CANCEL_PLAN}
        type={isTeam ? BillingInfoPopupType.ENDING_A_TEAM_PLAN : BillingInfoPopupType.DOWNGRADE_TO_FREE}
        handleClose={onStopCancellationCb}
        onContinueDowngradeCb={onCancellationNextStepCb}
        isLoading={isLoading}
      />
      <OneTimeOffer
        open={popupType === CancellationPopupType.ONE_TIME_OFFER}
        type={isTeam ? OneTimeOfferType.ONE_MONTH_OFFER_TEAM : OneTimeOfferType.ONE_MONTH_OFFER_PRO}
        onContinueDowngradeCb={onCancellationNextStepCb}
        onMonthFreeCb={onApplyForMonthFreeCb}
        handleClose={onStopCancellationCb}
        isLoading={isLoading}
      />
      <OneTimeOffer
        open={popupType === CancellationPopupType.ONE_TIME_OFFER_ACCEPTED}
        type={isTeam ? OneTimeOfferType.ONE_MONTH_OFFER_TEAM_ACCEPTED : OneTimeOfferType.ONE_MONTH_OFFER_PRO_ACCEPTED}
        handleClose={onStopCancellationCb}
      />
      <TeamTrialExtension
        isLoading={isLoading}
        handleClose={() => setOpenTeamCancel(false)}
        onMonthFreeCb={onApplyForWeeksFreeCb}
        open={openTeamCancel}
      />
      <CancellationSurvey
        open={popupType === CancellationPopupType.CANCELLATION_SURVEY}
        isLoading={isLoading}
        comments={comments}
        questions={questions}
        onContinueDowngradeCb={onCancellationNextStepCb}
        onCommentsChange={onCommentsChangeCb}
        onChangeQuestionState={onChangeQuestionStateCb}
        handleClose={onStopCancellationCb}
      />
      <CancellationConfirm
        open={popupType === CancellationPopupType.CANCELLATION_CONFIRM}
        onConfirm={onCancellationNextStepCb}
        handleClose={onStopCancellationCb}
        isLoading={isLoading}
      />
      <PlanCancelled
        open={popupType === CancellationPopupType.PLAN_CANCELLED}
        toBillingHref={ROUTE.toBilling(orgId)}
        isDowngrade={!isTeam}
        onDeleteMyAccountCb={onCancellationNextStepCb}
        handleClose={onStopCancellationCb}
      />
      <DeleteMyAccount
        open={popupType === CancellationPopupType.DELETE_ACCOUNT}
        toBillingHref={ROUTE.toBilling(orgId)}
        extendedWarning={isTeam}
        handleClose={onStopCancellationCb}
      />
    </>
  );
};

export default Cancellation;
