import { Embed } from 'quill';

export class CustomEmojiFormat extends Embed {
  static override tagName = 'span';
  static override blotName = 'emoji';
  static override className = 'ql-emoji';

  static override create(value: string) {
    const node = super.create(value) as HTMLElement;

    node.dataset['emoji'] = value;
    node.innerHTML = value;
    node.setAttribute('contentEditable', 'false');

    return node;
  }

  static override value(node: HTMLElement) {
    return node.dataset['emoji'];
  }
}
