import React from 'react';

import cx from 'classnames';

import { Text, TextSize } from '@writercolab/ui-atoms';

import MenuItem from 'components/generic/MenuItem/MenuItem';

import { PromptCategories } from '@web/types';

import styles from './PromptLibraryMenu.module.css';

interface IPromptLibraryMenu {
  activeCategory?: string;
  items: string[];
  tagNameMap: Record<string, string>;
  tagHierarchyMap: Record<string, string[]> | undefined;
  mapCategoryToIcon: (category: typeof PromptCategories.type) => React.ReactElement;
  onSetActiveCategory: (category: string, isSubCat?: boolean) => void;
}

export const PromptLibraryMenu: React.FC<IPromptLibraryMenu> = ({
  activeCategory,
  items,
  tagNameMap,
  tagHierarchyMap,
  mapCategoryToIcon,
  onSetActiveCategory,
}) => (
  <div>
    {items.map(topLevelTagId => (
      <>
        <Text
          key={topLevelTagId}
          className={cx(styles.menuHeading, {
            [styles.activeMenuHeading]: activeCategory === topLevelTagId,
          })}
          variant={TextSize.XXS}
          onClick={() => onSetActiveCategory(topLevelTagId)}
          upperCase
          medium
        >
          {tagNameMap[topLevelTagId]}
        </Text>
        {tagHierarchyMap?.[topLevelTagId].map(tagId => (
          <MenuItem
            key={tagId}
            title={tagNameMap[tagId]}
            icon={mapCategoryToIcon(tagId as typeof PromptCategories.type)}
            isActive={activeCategory === tagId}
            onClick={() => onSetActiveCategory(tagId)}
          />
        ))}
      </>
    ))}
  </div>
);
