export const defaultAcronyms = [
  'AA',
  'AAA',
  'ABA',
  'ABM',
  'AC',
  'ACH',
  'ACL',
  'AD',
  'ADD',
  'ADHD',
  'ADP',
  'AE',
  'AF',
  'AI',
  'AIDS',
  'AK',
  'AKA',
  'AL',
  'AMEX',
  'API',
  'APR',
  'AR',
  'ARPU',
  'ASAP',
  'ASP',
  'AT&T',
  'ATM',
  'ATTN',
  'AWS',
  'AWOL',
  'AZ',
  'BCC',
  'BF',
  'BPM',
  'BRB',
  'BTW',
  'BYOB',
  'CA',
  'CAD',
  'CAGR',
  'CC',
  'CCTV',
  'CD',
  'CD-ROM',
  'CDC',
  'CDMA',
  'CDN',
  'CDT',
  'CEO',
  'CFA',
  'CFO',
  'CGI',
  'CHF',
  'CIA',
  'CIO',
  'CLSI',
  'CMS',
  'CNC',
  'CNY',
  'CO',
  'COBOL',
  'COBRA',
  'COVID',
  'CPA',
  'CPC',
  'CPL',
  'CPM',
  'CPR',
  'CPS',
  'CPU',
  'CRM',
  'CS',
  'CSA',
  'CSI',
  'CSR',
  'CSS',
  'CST',
  'CSV',
  'CT',
  'CTA',
  'CTO',
  'CV',
  'CVS',
  'DARPA',
  'DB',
  'DBA',
  'DC',
  'DD',
  'DDOS',
  'DE',
  'DIY',
  'DLP',
  'DMV',
  'DMZ',
  'DNA',
  'DNC',
  'DOA',
  'DOB',
  'DOM',
  'DR',
  'DSL',
  'DTP',
  'DTR',
  'DUI',
  'DVD',
  'DVR',
  'EDT',
  'EEG',
  'EIN',
  'EMS',
  'EOB',
  'EOD',
  'ESL',
  'ESPN',
  'EST',
  'ET',
  'ETA',
  'ETF',
  'EU',
  'F&A',
  'FAQ',
  'FBI',
  'FCC',
  'FDA',
  'FDR',
  'FIFA',
  'FL',
  'FLOTUS',
  'FMCG',
  'FPGA',
  'FT',
  'FTE',
  'FTP',
  'FTTP',
  'FYA',
  'FYI',
  'GA',
  'GAAP',
  'GB',
  'GDP',
  'GDPR',
  'GF',
  'GIF',
  'GMO',
  'GMV',
  'GPS',
  'GPU',
  'GST',
  'GU',
  'GUI',
  'HBO',
  'HD',
  'HDR',
  'HEVC',
  'HI',
  'HIV',
  'HIPAA',
  'HQ',
  'HKD',
  'HMP',
  'HR',
  'HTML',
  'HTTP',
  'HTTPS',
  'HVAC',
  'IA',
  'IBAN',
  'IBM',
  'ICANN',
  'ID',
  'IL',
  'IMEI',
  'IMF',
  'IMHO',
  'IMO',
  'IN',
  'IoT',
  'IP',
  'IPO',
  'IQ',
  'IR',
  'IRL',
  'IRS',
  'ISO',
  'IT',
  'JFK',
  'JPEG',
  'JPY',
  'JS',
  'JSON',
  'KB',
  'KM',
  'KPI',
  'KS',
  'KY',
  'L&D',
  'LA',
  'LAN',
  'LASIK',
  'LATAM',
  'LED',
  'LGBT',
  'LGBTQ',
  'LLC',
  'LOL',
  'LTE',
  'LTV',
  'MA',
  'MB',
  'MBA',
  'MD',
  'MDMA',
  'MDT',
  'ME',
  'MERS',
  'MI',
  'MIA',
  'ML',
  'MLB',
  'MM',
  'MN',
  'MO',
  'MOU',
  'MP',
  'MQ',
  'MRI',
  'MS',
  'MST',
  'MT',
  'MVP',
  'N/A',
  'NA',
  'NAFTA',
  'NASA',
  'NASDAQ',
  'NATO',
  'NBA',
  'NC',
  'ND',
  'NDA',
  'NE',
  'NFL',
  'NH',
  'NJ',
  'NM',
  'NP',
  'NPS',
  'NV',
  'NY',
  'NYSE',
  'NZD',
  'OBGYN',
  'OCD',
  'OECD',
  'OH',
  'OK',
  'OKR',
  'OMG',
  'OOO',
  'OR',
  'OS',
  'OSHA',
  'OT',
  'PA',
  'PAP',
  'PC',
  'PCI',
  'PDA',
  'PDF',
  'PDT',
  'PE',
  'PFA',
  'PGA',
  'PHP',
  'PIP',
  'PM',
  'PNG',
  'PO',
  'POC',
  'POTUS',
  'POV',
  'PPC',
  'PPO',
  'PPP',
  'PPS',
  'PPT',
  'PPV',
  'PR',
  'PS',
  'PSA',
  'PST',
  'PT',
  'Q&A',
  'QA',
  'QFP',
  'QR',
  'R&D',
  'RAM',
  'REST',
  'RFP',
  'RGB',
  'RI',
  'RMB',
  'ROFL',
  'ROI',
  'ROM',
  'RPG',
  'RPM',
  'RSS',
  'RSVP',
  'S&P',
  'SAAS',
  'SAP',
  'SARS',
  'SC',
  'SCOTUS',
  'SD',
  'SDK',
  'SDR',
  'SEC',
  'SEO',
  'SIM',
  'SKU',
  'SLA',
  'SMB',
  'SMH',
  'SMP',
  'SMS',
  'SMTP',
  'SOW',
  'SPF',
  'SSD',
  'SSL',
  'SSN',
  'STEM',
  'SUV',
  'SVC',
  'SWAT',
  'SWIFT',
  'SWOT',
  'TBA',
  'TBC',
  'TBD',
  'TGIF',
  'TLC',
  'TM',
  'TN',
  'TNDP',
  'TO',
  'TTO',
  'TTY',
  'TTYL',
  'TV',
  'TX',
  'UAE',
  'UCLA',
  'UFO',
  'UI',
  'UK',
  'UN',
  'UNESCO',
  'UNICEF',
  'UPS',
  'URL',
  'US',
  'USA',
  'USAID',
  'USB',
  'USB-C',
  'USCIS',
  'USV',
  'UT',
  'UTC',
  'UVA',
  'UVB',
  'UX',
  'UX/UI',
  'VA',
  'VAT',
  'VP',
  'VPN',
  'VT',
  'WA',
  'WACC',
  'WAN',
  'WFH',
  'WI',
  'WLAN',
  'WV',
  'WWW',
  'WY',
  'WYSIWYG',
  'XL',
  'XML',
  'XSS',
  'XXL',
  'YMCA',
  'YOLO',
  'YTD',
  'YY',
  'ZIP',
];
