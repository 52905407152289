import { IComputedValue, action, computed, makeObservable, observable } from 'mobx';

import type { RequestQueryParams, TOrgTeamUserActivityParams } from '@writercolab/common-utils';
import { TApplicationSortOrder } from '@writercolab/common-utils';
import { FeatureFlags } from '@writercolab/feature-flags';
import { PaginatedModel } from '@writercolab/mobx';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import { ISort } from '@writercolab/ui-molecules';

import type {
  TAdminAuditLogSortOrder,
  TBriefApplication,
  TBriefApplicationsPaginated,
  TFeatureFlags,
  TPaginatedConsoleArgs,
  TWidgetConfig,
} from '@web/types';
import {
  AppsWidgetSortFields,
  AppsWidgetTabId,
  DEFAULT_PAGE_SIZE,
  TWidgetEditAppearance,
  TWidgetType,
} from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';

interface IAppsHomeWidgetModelParams {
  hiddenApps: () => IComputedValue<Set<string>>;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  requestService: RequestServiceInitialize['api'];
  featureFlags: () => FeatureFlags<TFeatureFlags>;
  assistantSubscription: () => AiAssistantSubscriptionModel;
  organizationId: () => number;
  teamId: () => number;
  isTeamAdmin: () => boolean;
  config: () => TWidgetConfig<typeof TWidgetType.enum.apps>;
}

export class AppsHomeWidgetModelUi extends AbstractHomeWidgetModel<typeof TWidgetType.enum.apps> {
  $activeTab = observable.box<typeof AppsWidgetTabId.type>(AppsWidgetTabId.enum.recent);
  $sortOrder = observable.box<typeof TApplicationSortOrder.type>(TApplicationSortOrder.enum.asc);
  $sortField = observable.box<typeof AppsWidgetSortFields.type>(AppsWidgetSortFields.enum.name);
  editAppearance = TWidgetEditAppearance.enum.readonly;

  readonly customAppsPagination: PaginatedModel<
    TBriefApplicationsPaginated,
    TBriefApplication,
    TPaginatedConsoleArgs,
    RequestQueryParams
  >;

  readonly favouriteAppsPagination: PaginatedModel<
    TBriefApplicationsPaginated,
    TBriefApplication,
    TPaginatedConsoleArgs,
    RequestQueryParams
  >;

  readonly recentAppsPagination: PaginatedModel<
    TBriefApplicationsPaginated,
    TBriefApplication,
    TPaginatedConsoleArgs,
    RequestQueryParams
  >;

  constructor(private params: IAppsHomeWidgetModelParams) {
    super(TWidgetType.enum.apps, () => params.config(), params.analyticsService);

    this.customAppsPagination = new PaginatedModel<
      TBriefApplicationsPaginated | undefined,
      TBriefApplication,
      TPaginatedConsoleArgs,
      RequestQueryParams
    >({
      argsExtra: {},
      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: o => {
        if (!o) {
          return this.customAppsPagination.args;
        }

        const offset = (o.pagination.offset ?? 0) + (o.result?.length ?? 0);

        if (offset >= o.totalCount) {
          return this.customAppsPagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: o => o?.result ?? [],
      load: async ({ args }) => {
        const organizationId = this.params.organizationId();
        const teamId = this.params.teamId();

        if (!organizationId || !teamId) {
          return undefined;
        }

        const { data } = await this.params.requestService.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed/my',
          {
            params: {
              path: {
                organizationId,
                teamId,
              },
              query: {
                ...args,
                sortField: this.$sortField.get(),
                sortOrder: this.$sortOrder.get(),
                allowBeta: false,
                access: ['private-organization', 'private-team'],
              },
            },
          },
        );

        return data;
      },
    });

    this.favouriteAppsPagination = new PaginatedModel<
      TBriefApplicationsPaginated | undefined,
      TBriefApplication,
      TPaginatedConsoleArgs,
      RequestQueryParams
    >({
      argsExtra: {},
      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: o => {
        if (!o) {
          return this.favouriteAppsPagination.args;
        }

        const offset = (o.pagination.offset ?? 0) + (o.result?.length ?? 0);

        if (offset >= o.totalCount) {
          return this.favouriteAppsPagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: o => o?.result ?? [],
      load: async ({ args }) => {
        const organizationId = this.params.organizationId();
        const teamId = this.params.teamId();

        if (!organizationId || !teamId) {
          return undefined;
        }

        const { data } = await this.params.requestService.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed/favorite',
          {
            params: {
              path: {
                organizationId,
                teamId,
              },
              query: {
                ...args,
                sortField: this.$sortField.get(),
                sortOrder: this.$sortOrder.get(),
                allowBeta: false,
              },
            },
          },
        );

        return data;
      },
    });

    this.recentAppsPagination = new PaginatedModel<
      TBriefApplicationsPaginated | undefined,
      TBriefApplication,
      TPaginatedConsoleArgs,
      RequestQueryParams
    >({
      argsExtra: {},
      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: o => {
        if (!o) {
          return this.recentAppsPagination.args;
        }

        const offset = (o.pagination.offset ?? 0) + (o.result?.length ?? 0);

        if (offset >= o.totalCount) {
          return this.recentAppsPagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: o => o?.result ?? [],
      load: async ({ args }) => {
        const organizationId = this.params.organizationId();
        const teamId = this.params.teamId();

        if (!organizationId || !teamId) {
          return undefined;
        }

        const { data } = await this.params.requestService.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed/my',
          {
            params: {
              path: {
                organizationId,
                teamId,
              },
              query: {
                ...args,
                limit: 5,
                allowBeta: false,
                sortField: AppsWidgetSortFields.enum.recentlyUsed,
                sortOrder: 'desc',
              },
            },
          },
        );

        return {
          ...data,
          totalCount: 5, // CORE-848
        };
      },
    });

    makeObservable(this, {
      setActiveTab: action,
      setSorting: action,
      isLoading: computed,
      hasCustomApps: computed,
      hasFavouriteApps: computed,
      hasInvisibleFooter: computed,
      activeTab: computed,
      sorting: computed,
      recentAppsFiltered: computed,
      favouriteAppsFiltered: computed,
    });
  }

  get isLoading() {
    return !this.customAppsPagination.value || !this.favouriteAppsPagination.value || !this.recentAppsPagination.value;
  }

  get hasCustomApps() {
    if (this.customAppsPagination.value) {
      return this.customAppsPagination.value.length > 0;
    }

    return false;
  }

  get recentAppsFiltered() {
    const data = this.recentAppsPagination.value || [];
    const hiddenAppIds = this.params.hiddenApps().get();

    const filtered = data.filter(app => !hiddenAppIds.has(app.id));

    return filtered;
  }

  get favouriteAppsFiltered() {
    const data = this.favouriteAppsPagination.value || [];
    const hiddenAppIds = this.params.hiddenApps().get();

    const filtered = data.filter(app => !hiddenAppIds.has(app.id));

    return filtered;
  }

  get hasFavouriteApps() {
    if (this.favouriteAppsFiltered) {
      return this.favouriteAppsFiltered.length > 0;
    }

    return false;
  }

  get hasRecentApps() {
    if (this.recentAppsFiltered) {
      return this.recentAppsFiltered.length > 0;
    }

    return false;
  }

  get hasInvisibleFooter() {
    return (
      (this.activeTab === AppsWidgetTabId.enum.favorite && !this.hasFavouriteApps) ||
      (this.activeTab === AppsWidgetTabId.enum.recent && !this.hasRecentApps)
    );
  }

  get activeTab() {
    return this.$activeTab.get();
  }

  setActiveTab = (tab: typeof AppsWidgetTabId.type) => {
    this.$activeTab.set(tab);

    this.analyticsService.track(AnalyticsActivity.appsWidgetTabSelected, { option: tab });
  };

  get sorting() {
    return `${this.$sortField.get()}_${this.$sortOrder.get()}`;
  }

  setSorting = (selectedSort: ISort) => {
    const { id } = selectedSort;

    if (id.includes('_')) {
      const [sortField, sortOrder] = id.split('_') as [typeof AppsWidgetSortFields.type, TAdminAuditLogSortOrder];

      if (sortField && sortOrder) {
        this.$sortField.set(sortField);
        this.$sortOrder.set(sortOrder);

        this.analyticsService.track(AnalyticsActivity.appsWidgetSorted, {
          origin: this.activeTab,
          option: selectedSort.name,
        });
      }
    }
  };
}
