import type React from 'react';
import { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import {
  LocalStorageKey,
  LocalStorageService,
  SearchQueryParam,
  openNewTab,
  stopEventPropagation,
} from '@writercolab/common-utils';
import { ContentGenerationJobStatus } from '@writercolab/types';
import { ClickAwayListener, DotLoader, Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { UtilIcon } from '@writercolab/ui-molecules';

import type { IRecapJob, TContentGenerationJobStatus } from '@web/types';
import { contentGenerationJobInputFields } from '@web/types';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { useRouteMatch } from '../../../hooks/useRouteMatch';
import { ROUTE, WRITER_CONSOLE_ROUTES } from '../../../services/config/routes';
import { getDocumentById } from '../../../services/request/documents';
import { useAppState } from '../../../state';
import { TActionType } from '../../../state/types';
import { getLogger } from '../../../utils/logger';
import {
  JOBS_IN_PROGRESS_STATUSES,
  getQueueJobFieldValue,
  isBlogBuilderQueueJobType,
  isRecapsQueueJobType,
} from '../../../utils/recapsUtils';
import { RecapsQueueManager } from '../RecapsQueueManager';

import styles from './styles.module.css';

const LOG = getLogger('NotificationBar');

const summarizeJobsStatus = (jobsSummary: Record<TContentGenerationJobStatus, number>): string => {
  const summary: string[] = [];

  Object.entries(jobsSummary).forEach((_, i) => {
    const [status, value] = Object.entries(jobsSummary)[i];

    if (value > 0) {
      if (ContentGenerationJobStatus.enum.generating === status) {
        summary.push(`${value} in progress`);
      } else if (status === ContentGenerationJobStatus.enum.completed) {
        summary.push(`${value} complete`);
      } else if (status === ContentGenerationJobStatus.enum.transcript) {
        summary.push(`${value} transcribing file`);
      } else if (status === ContentGenerationJobStatus.enum.error) {
        summary.push(`${value} error`);
      }
    }
  });

  return summary.join(', ');
};

const JobQueueSummary = ({
  jobs,
  isRecapsQueueManagerOpen,
  jobsSummary,
  handleJobQueueManagerVisibility,
  handleJobFileClick,
  arrowClassName,
}) => (
  <div className={styles.containerQueueManager}>
    {jobsSummary && (
      <Text extraSmallCaps color={TextColor.GREY2} className={styles.containerQueueManagerSummary}>
        Status: {summarizeJobsStatus(jobsSummary)}
      </Text>
    )}
    <div
      className={cx(styles.containerQueueManagerTrigger, styles.clickable, arrowClassName)}
      onClick={handleJobQueueManagerVisibility}
    >
      <Icon name={IconVariant.ARROW_DOWN} />
    </div>
    {isRecapsQueueManagerOpen && (
      <ClickAwayListener onClickAway={handleJobQueueManagerVisibility}>
        <div className={styles.containerQueueManagerWrapper}>
          <RecapsQueueManager jobs={jobs} onJobFileClick={handleJobFileClick} />
        </div>
      </ClickAwayListener>
    )}
  </div>
);

const NotificationBar = observer(() => {
  const isRouteMatch = useRouteMatch();
  const { appState, dispatchAppState, appModel } = useAppState();

  const jobs = appModel.generationJobNotifications;
  const jobsSummary = appModel.generationJobNotificationsSummary;

  const [jobsStatusMode, setJobsStatusMode] = useState<TContentGenerationJobStatus | null>(null);
  const [jobId, setJobId] = useState<string | null>(null);
  const [isRecapsQueueManagerOpen, setIsRecapsQueueManagerOpen] = useState(false);

  useEffect(() => {
    // Hack to overcome problem with non-resetting appState.documentId
    const appStateDocumentId = appState.site.title === 'Content Editor' && String(appState.documentId);
    const jobsInProgress = jobs.some(job => JOBS_IN_PROGRESS_STATUSES.includes(job.status));
    const lastActiveStorageJob = LocalStorageService.getItem(LocalStorageKey.jobsQueueLastActiveJob);
    const lastActiveJob = head(jobs);
    let visible = true;

    if (lastActiveStorageJob && lastActiveJob) {
      visible = lastActiveJob.id !== lastActiveStorageJob;
    }

    if (!isEmpty(jobs) && visible) {
      if (jobsInProgress) {
        setJobsStatusMode(ContentGenerationJobStatus.enum.generating);
      } else {
        const allJobsCompleted = jobs.every(job => job.status === ContentGenerationJobStatus.enum.completed);
        const allJobsFailed = jobs.every(job => job.status === ContentGenerationJobStatus.enum.error);

        if (allJobsFailed) {
          setJobsStatusMode(ContentGenerationJobStatus.enum.error);
        } else if (allJobsCompleted) {
          setJobsStatusMode(ContentGenerationJobStatus.enum.completed);
        } else {
          const ifAnyJobCompleted = jobs.some(job => job.status === ContentGenerationJobStatus.enum.completed);

          if (ifAnyJobCompleted) {
            setJobsStatusMode(ContentGenerationJobStatus.enum.completed);
          } else {
            setJobsStatusMode(ContentGenerationJobStatus.enum.error);
          }
        }
      }
    }

    if (jobId) {
      const job = jobs.find(job => job.id === jobId);

      if (
        job &&
        job.documentId === appStateDocumentId &&
        (job.status === ContentGenerationJobStatus.enum.completed ||
          job.status === ContentGenerationJobStatus.enum.error)
      ) {
        _handleReset();
      }
    } else {
      const jobInProgress = jobs.find(job => JOBS_IN_PROGRESS_STATUSES.includes(job.status));

      if (jobInProgress) {
        setJobId(jobInProgress.id);
        dispatchAppState({ type: TActionType.SetNotificationBarState, payload: true });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.documentId, appState.site.title, jobs]);

  const _handleJobQueueManagerVisibility = () => setIsRecapsQueueManagerOpen(!isRecapsQueueManagerOpen);

  const _handleJobFileClick = useCallback(
    async (job: IRecapJob) => {
      try {
        const docId = parseInt(job.documentId, 10);

        if (docId) {
          await getDocumentById(appState.organizationId, appState.teamId, docId);

          if (isBlogBuilderQueueJobType(job)) {
            const hasError = job.status === ContentGenerationJobStatus.enum.error;
            openNewTab(
              ROUTE.toEditorWithBlogBuilder(appState.organizationId, appState.teamId, docId, {
                [SearchQueryParam.error]: hasError ? encodeURI(job.error || '') : null,
                [SearchQueryParam.draftsPreview]: hasError ? null : 'true',
              }),
            );
          } else if (isRecapsQueueJobType(job)) {
            const draftId = getQueueJobFieldValue(job, contentGenerationJobInputFields.DRAFT_ID);
            openNewTab(
              ROUTE.toEditorWithEventTakeawaysAndDraftHighlight(
                appState.organizationId,
                appState.teamId,
                docId,
                draftId || 0,
              ),
            );
          }
        }
      } catch (e) {
        LOG.error(e);

        const draftsId = getQueueJobFieldValue(job, contentGenerationJobInputFields.DRAFT_ID);
        const toTeamDraftsWithDraftId = appModel.featureFlags.get('waMyWorkPage', false)
          ? ROUTE.toTeamOutputsWithOutputId
          : ROUTE.toTeamDraftsWithDraftId;
        const toTeamDrafts = appModel.featureFlags.get('waMyWorkPage', false) ? ROUTE.toTeamDrafts : ROUTE.toOutputs;

        if (draftsId && draftsId.length) {
          const draftId = head(draftsId);
          openNewTab(toTeamDraftsWithDraftId(appState.organizationId, appState.teamId, `openId=${draftId}`));
        } else {
          openNewTab(toTeamDrafts(appState.organizationId!, appState.teamId!));
        }
      }
    },
    [appState.organizationId, appState.teamId, appModel.featureFlags],
  );

  const _handleReset = () => {
    setJobId(null);
    dispatchAppState({ type: TActionType.SetNotificationBarState, payload: false });
  };

  const _saveLastActiveJob = useCallback(() => {
    if (jobs && jobs.length > 0) {
      const lastJob = head(jobs);

      if (lastJob && lastJob.id) {
        LocalStorageService.setItem(LocalStorageKey.jobsQueueLastActiveJob, lastJob.id);
      }
    }
  }, [jobs]);

  const _handleClose = (e: React.MouseEvent) => {
    stopEventPropagation(e);
    _saveLastActiveJob();
    _handleReset();
  };

  const isAIStudioRoutes = () => Object.values(WRITER_CONSOLE_ROUTES).some(r => isRouteMatch(r, true));

  if (!appState.isNotificationBarVisible || isAIStudioRoutes()) {
    return null;
  }

  if (jobsStatusMode === ContentGenerationJobStatus.enum.error) {
    return (
      <div className={cx(styles.notificationBar, styles.notificationBarError)} data-notification-bar>
        <span className={styles.status} role="presentation">
          <Text variant={TextSize.XXL} medium caps className={styles.title}>
            error generating content
          </Text>
          <UtilIcon
            className={styles.closeError}
            onClick={_handleClose}
            variant={IconVariant.CLOSE}
            tooltipContent="Close"
            circleSmall
            width={10}
            height={10}
          />
        </span>
        <JobQueueSummary
          jobs={jobs}
          jobsSummary={jobsSummary}
          handleJobQueueManagerVisibility={_handleJobQueueManagerVisibility}
          isRecapsQueueManagerOpen={isRecapsQueueManagerOpen}
          handleJobFileClick={_handleJobFileClick}
          arrowClassName={styles.containerQueueManagerTriggerRed}
        />
      </div>
    );
  }

  if (jobsStatusMode === ContentGenerationJobStatus.enum.completed) {
    return (
      <div className={cx(styles.notificationBar, styles.notificationBarCompleted)} data-notification-bar>
        <span className={styles.status} role="presentation">
          <Text variant={TextSize.XXL} medium caps className={styles.title}>
            done! all content complete
          </Text>
          <UtilIcon
            className={styles.closeCompleted}
            onClick={_handleClose}
            variant={IconVariant.CLOSE}
            tooltipContent="Close"
            circleSmall
            width={10}
            height={10}
          />
        </span>
        <JobQueueSummary
          jobs={jobs}
          jobsSummary={jobsSummary}
          handleJobQueueManagerVisibility={_handleJobQueueManagerVisibility}
          isRecapsQueueManagerOpen={isRecapsQueueManagerOpen}
          handleJobFileClick={_handleJobFileClick}
          arrowClassName={styles.containerQueueManagerTriggerGreen}
        />
      </div>
    );
  }

  if (jobsStatusMode === ContentGenerationJobStatus.enum.generating) {
    return (
      <div className={styles.notificationBar} data-notification-bar>
        <span className={styles.status} role="presentation">
          <DotLoader className={styles.dots} />
          <Text variant={TextSize.XXL} medium caps className={styles.title}>
            generating content
          </Text>
        </span>
        <JobQueueSummary
          jobs={jobs}
          jobsSummary={jobsSummary}
          handleJobQueueManagerVisibility={_handleJobQueueManagerVisibility}
          isRecapsQueueManagerOpen={isRecapsQueueManagerOpen}
          handleJobFileClick={_handleJobFileClick}
          arrowClassName={styles.containerQueueManagerTriggerBlue}
        />
      </div>
    );
  }

  return null;
});

export default NotificationBar;
