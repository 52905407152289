import React from 'react';

import cx from 'classnames';

import {
  Heading,
  HeadingVariant,
  LabelledTags,
  LinkText,
  TagColor,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { BetaFeatureLabel } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';

import styles from './styles.module.css';

interface ISectionHeadingProps extends BaseComponentsProps {
  heading: string;
  subHeading?: string;
  headingUppercase?: boolean;
  subHeadingUppercase?: boolean;
  description?: React.ReactNode;
  subHeadingColor?: TextColor;
  descriptionTextColor?: TextColor;
  descriptionTextVariant?: TextSize;
  linkTextColor?: TextColor;
  headingVariant?: HeadingVariant;
  descriptionContainerClassName?: string;
  learnMoreContainerClassName?: string;
  cta?: React.ReactNode;
  linkTextVariant?: TextSize;
  betaFeature?: boolean;
  viewOnlyTooltipTitle?: React.ReactNode;
  onLearnMoreClick?: () => void;
  linkTextBold?: boolean;
  viewOnlyReason?: React.ReactNode;
}

export const SectionHeading: React.FC<ISectionHeadingProps> = ({
  className,
  descriptionContainerClassName,
  learnMoreContainerClassName,
  heading,
  headingUppercase,
  description,
  onLearnMoreClick,
  subHeading,
  subHeadingUppercase,
  cta,
  betaFeature,
  viewOnlyTooltipTitle,
  subHeadingColor = TextColor.GREY2,
  headingVariant = HeadingVariant.H3,
  descriptionTextColor = TextColor.GREY2,
  linkTextColor = TextColor.GREY2,
  linkTextVariant = TextSize.M,
  descriptionTextVariant = TextSize.M,
  linkTextBold,
  viewOnlyReason,
}) => (
  <div className={cx(className, styles.container)}>
    {subHeading && (
      <div className={styles.containerSubHeading}>
        <Text
          variant={TextSize.XXS}
          color={subHeadingColor}
          upperCase={subHeadingUppercase}
          className={styles.containerSubHeadingText}
        >
          {subHeading}
        </Text>
      </div>
    )}
    <div className={styles.containerHeadingWrapper}>
      <Heading className={styles.containerHeading} variant={headingVariant} upperCase={headingUppercase} bold>
        {heading}
      </Heading>
      {betaFeature && (
        <div className={styles.betaFeatureLabelWrapper}>
          <BetaFeatureLabel tagColor={TagColor.LIGHT_GREEN} />
        </div>
      )}
      {!isEmpty(viewOnlyReason) && (
        <Tooltip title={viewOnlyReason} disabled={!viewOnlyReason} tooltipWidth={200}>
          <div>
            <LabelledTags bgColor="var(--classic-grey-1)">{viewOnlyTooltipTitle || 'view only'}</LabelledTags>
          </div>
        </Tooltip>
      )}
    </div>
    <div className={styles.containerDescriptionWrapper}>
      <div className={descriptionContainerClassName}>
        {description && (
          <Text variant={descriptionTextVariant} color={descriptionTextColor} className={styles.containerText}>
            {description}
          </Text>
        )}
        {onLearnMoreClick && (
          <LinkText onClick={onLearnMoreClick} className={learnMoreContainerClassName}>
            <Text variant={linkTextVariant} color={linkTextColor} bold={linkTextBold}>
              Learn more
            </Text>
          </LinkText>
        )}
      </div>
      {cta && <div className={styles.containerDescriptionCta}>{cta}</div>}
    </div>
  </div>
);

export default SectionHeading;
