import React, { useEffect } from 'react';

import { Heading, HeadingVariant } from '@writercolab/ui-atoms';
import { ContentPreviewCard } from '@writercolab/ui-molecules';

import { ILearningCenterWebinar, TLearningCenterCanduId } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { NoSearchResultsBanner } from '../../generic/NoSearchResultsBanner';

import styles from './LearningCenterWebinars.module.css';

interface ILearningCenterWebinarsProps {
  webinars: ILearningCenterWebinar[];
  onWebinarClick?: (id: string, url: string) => void;
  onLoad?: () => void;
}

export const LearningCenterWebinars: React.FC<ILearningCenterWebinarsProps> = ({
  webinars,
  onWebinarClick,
  onLoad,
}) => {
  const upcomingWebinars = webinars.filter(webinar => webinar.webinarType === 'upcoming');
  const previousWebinars = webinars.filter(webinar => webinar.webinarType === 'previous');

  useEffect(() => {
    onLoad?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      {isEmpty(upcomingWebinars) && isEmpty(previousWebinars) && (
        <div>
          <NoSearchResultsBanner className={styles.noResultsWrapper} />
        </div>
      )}

      {!isEmpty(upcomingWebinars) && (
        <>
          <Heading variant={HeadingVariant.H3} className={styles.subtitle}>
            Upcoming webinars
          </Heading>
          <div
            className={styles.contentPreviewContainer}
            id={TLearningCenterCanduId.enum.CANDU_LEARNING_CENTER_WEBINARS_UPCOMING}
          >
            {upcomingWebinars.map(webinar => (
              <ContentPreviewCard
                key={webinar.id}
                title={webinar.title}
                description={webinar.description}
                cover={webinar.imagePath}
                articleTitle="Register now"
                articleLink={webinar.registrationUrl}
                onClick={() => onWebinarClick?.(webinar.id, webinar.registrationUrl)}
              />
            ))}
          </div>
        </>
      )}

      {!isEmpty(previousWebinars) && (
        <>
          <Heading variant={HeadingVariant.H3} className={styles.subtitle}>
            Previous webinars
          </Heading>
          <div
            className={styles.contentPreviewContainer}
            id={TLearningCenterCanduId.enum.CANDU_LEARNING_CENTER_WEBINARS_PREVIOUS}
          >
            {previousWebinars.map(webinar => (
              <ContentPreviewCard
                key={webinar.id}
                title={webinar.title}
                description={webinar.description}
                cover={webinar.imagePath}
                articleTitle="Watch now"
                articleLink={webinar.watchUrl}
                onClick={() => onWebinarClick?.(webinar.id, webinar.watchUrl)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};
