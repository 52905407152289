import type { ContentGenerationJobStatus, TJobResponse } from '@writercolab/types';

export type TContentGenerationJobStatus = typeof ContentGenerationJobStatus.type;

export type IRecapJob = Omit<TJobResponse, 'inputs'> & {
   
  inputs: Record<string, any>;
};

export enum contentGenerationJobInputFields {
  RECORDING_TYPE = 'recordingType',
  NAME = 'eventName',
  FILE_ID = 'fileId',
  FILE_NAME = 'fileName',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  EVENT_NAME = 'eventName',
  EVENT_TITLE = 'title',
  DRAFT_ID = 'draftId',
}

export enum recapsRecordingType {
  EVENT = 'Event',
  SALES_CALL = 'SalesCall',
  CUSTOMER_SUCCESS_CALL = 'CustomerSuccessCall',
  RESEARCH_AND_FEEDBACK_CALL = 'ResearchAndFeedbackCall',
  TRAINING_SESSION = 'TrainingSession',
  LARGE_SCALE_MEETING = 'LargeScaleMeeting',
  SMALL_SCALE_SYNC = 'SmallScaleSync',
  JOB_INTERVIEW = 'JobInterview',
}

export enum recapsRecordingTypeTitle {
  EVENT = 'Event',
  SALES_CALL = 'Sales',
  CUSTOMER_SUCCESS_CALL = 'Customer success',
  RESEARCH_AND_FEEDBACK_CALL = 'Product and UX research',
  TRAINING_SESSION = 'Training',
  LARGE_SCALE_MEETING = 'Large-scale meeting',
  SMALL_SCALE_SYNC = 'Small-scale meeting',
  JOB_INTERVIEW = 'Job interview',
}

export enum recapsRecordingTypeDescription {
  EVENT = 'webinar, panel, fireside chat, announcement, etc.',
  SALES_CALL = 'discovery, product demo, follow-up, pricing, etc.',
  CUSTOMER_SUCCESS_CALL = 'account management, relationship building, onboarding, kickoff, etc.',
  RESEARCH_AND_FEEDBACK_CALL = 'UX research, product feedback, prototype review, etc.',
  TRAINING_SESSION = 'technical training, process training, soft skills training, etc.',
  LARGE_SCALE_MEETING = 'All Hands, company or team announcement, quarterly review, roadmap review, etc.',
  SMALL_SCALE_SYNC = 'team sync, project sync, scrum, etc.',
  JOB_INTERVIEW = 'screen, panel interview, 1-1 interview, etc.',
}

export enum recapsMessages {
  GENERATION_SUCCESS = 'Your Recaps are ready!',
  GENERATION_ERROR = 'Something went wrong. Try again or use another recording.',
  GENERATION_ERROR_TRANSCRIPT_IN_PROGRESS = 'Another recap is still in progress. Wait until it’s done, then try this one again.',
  UPLOAD_SUCCESS = 'Your upload is complete',
  UPLOAD_ERROR = 'There was an error with your file upload',
  UPLOAD_ERROR_YOUTUBE = 'We couldn’t retrieve a video from this link. Double-check it or try another URL.',
  FILE_DETAILS_FETCH_ERROR = 'We couldn’t load your recording. Try refreshing the page',
  DRAFTS_FETCH_ERROR = 'We couldn’t load your drafts. Try refreshing the page',
  COWRITE_LIMIT_ERROR = 'We couldn’t generate takeaways because you’ve reached your words generated limit. Contact sales to add more words to your plan.',
  TRANSCRIPT_LIMIT_ERROR = 'You’re out of transcriptions for this month. You can still generate recaps for media files you previously uploaded. Or, you can upload a YouTube URL, paste in text, or upload a text file (txt or srt).',
}

export enum contentGenerationJobProcessMessage {
  ERROR = 'There was an error with generating Recaps. Head back to your doc for more details.',
}
