import React from 'react';

import cx from 'classnames';

import { IconVariant } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes, UtilIcon } from '@writercolab/ui-molecules';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import throttle from 'lodash/throttle';

import styles from '../styles.module.css';

interface IKeyPoint {
  id: string;
  name: string;
}

interface KeyPointLoaderProps {
  sectionId: string;
  keyPoint: IKeyPoint;
  onChangeKeyPoint: (sectionId: string, keyPointId: string, title: string) => Promise<void>;
  onRemoveKeyPoint: (sectionId: string, keyPointId: string) => Promise<void>;
}

export const KeyPointsSectionInput: React.FC<KeyPointLoaderProps> = ({
  sectionId,
  keyPoint,
  onChangeKeyPoint,
  onRemoveKeyPoint,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: keyPoint.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [keyPointName, setKeyPointName] = React.useState(keyPoint.name);

  const throttleCb = (title: string) => throttle(title => onChangeKeyPoint(sectionId, keyPoint.id, title), 500)(title);
  const _onChangeKeyPoint = async (title: string) => {
    setKeyPointName(title);
    throttleCb(title);
  };

  return (
    <div className={styles.headlineSectionInputContainer} ref={setNodeRef} style={style}>
      <div className={styles.headlineSectionInput}>
        <InputGroup
          label=""
          id={keyPoint.id}
          name={keyPoint.id}
          value={keyPointName}
          inputType={InputTypes.TEXTAREA}
          placeholder="Add a key point"
          onChange={e => _onChangeKeyPoint(e.target.value)}
          rows={1}
        />
      </div>
      <div className={styles.headlineSectionInputActions}>
        <UtilIcon
          onClick={() => onRemoveKeyPoint(sectionId, keyPoint.id)}
          variant={IconVariant.CLOSE}
          width={16}
          height={16}
          className={cx(styles.outlineSectionContainerAction, styles.clickable)}
          circleSmall
          tooltipContent="Remove key point"
        />
        <UtilIcon
          variant={IconVariant.DRAG}
          width={16}
          height={16}
          className={cx(styles.outlineSectionContainerAction, styles.clickable)}
          circleSmall
          noBackground
          tooltipContent="Hold and drag to reorder"
          {...attributes}
          {...listeners}
        />
      </div>
    </div>
  );
};

export default KeyPointsSectionInput;
