import type { FC, ReactNode } from 'react';

import cx from 'classnames';

import type { BaseComponentsProps } from '@writercolab/common-utils';
import { Button } from '@writercolab/fe.wds';
import { Enum, cn } from '@writercolab/utils';

import styles from './HomeExtensionTile.module.css';

export const HomeExtensionTileType = new Enum('install', 'installed', 'hide', 'show');

interface IHomeExtensionTile extends BaseComponentsProps {
  icon: ReactNode;
  title: ReactNode;
  onClickHide?: () => void;
  onClickInstall: () => void;
  onToggleVisibility: () => void;
  type: typeof HomeExtensionTileType.type;
}

export const HomeExtensionTile: FC<IHomeExtensionTile> = ({
  className,
  icon,
  title,
  type,
  onClickHide,
  onClickInstall,
  onToggleVisibility,
}) => (
  <div className={cx(styles.container, className)}>
    {onClickHide && (
      <Button className={styles.hideButton} variant="link" onClick={onClickHide}>
        Hide
      </Button>
    )}
    <div
      className={cx(styles.icon, {
        [styles.hidden]: type === HomeExtensionTileType.enum.show,
      })}
    >
      {icon}
    </div>
    <p
      className={cn(
        styles.title,
        {
          [styles.hidden]: type === HomeExtensionTileType.enum.show,
        },
        'font-medium text-lg',
      )}
    >
      {title}
    </p>
    {HomeExtensionTileType.match(
      type,
      {
        install: () => (
          <Button variant="secondary" size="sm" onClick={onClickInstall}>
            Install
          </Button>
        ),
        installed: () => (
          <Button variant="tertiary" size="sm" disabled>
            Installed
          </Button>
        ),
        hide: () => (
          <Button variant="tertiary" size="sm" onClick={onToggleVisibility}>
            Hide
          </Button>
        ),
        show: () => (
          <Button variant="tertiary" size="sm" onClick={onToggleVisibility}>
            Show
          </Button>
        ),
      },
      null,
    )}
  </div>
);
