import type { components } from '@writercolab/network';
import type { DropdownOption } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

export type TTeamPrompts =
  components['schemas']['model_PaginatedResult_content_generation_dto_ExpandTeamPromptResponse'];
export type TTeamPromptBrief = components['schemas']['content_generation_dto_ExpandTeamPromptResponse'];
export type TTeamPromptTag = components['schemas']['content_generation_dto_BriefTeamPromptTag'];
export type TTeamPromptActionDto = components['schemas']['content_generation_dto_CreateTeamPromptRequest'];

export type TTeamPromptEditActionDto = { id?: string } & TTeamPromptActionDto;

export const TTeamPromptSortField = new Enum('createdAt', 'title');
export const TTeamPromptSortDirection = new Enum('asc', 'desc');

export const TeamPromptSortOptions: DropdownOption[] = [
  { id: TTeamPromptSortField.enum.title, name: 'Prompt A-Z' },
  { id: TTeamPromptSortField.enum.createdAt, name: 'Date added' },
];

export const TTeamPromptActionId = new Enum(
  'add',
  'edit',
  'delete',
  'favorite',
  'unfavorite',
  'use',
  'copy',
  'duplicate',
);
export const TTeamPromptFilter = new Enum('tag');
