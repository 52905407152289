import type React from 'react';
import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';

import type {
  ErrorMessage,
  ITableFilter,
  ITemplateCategory,
  ITemplateDraft,
  ITemplateDraftFeedbackParams,
} from '@writercolab/common-utils';
import {
  AnalyticsDraftPlace,
  BUILDER_TEMPLATE_ID,
  ContentEditorPageMode,
  SortFieldTypes,
  SortOrders,
  copyToClipboard,
  createDraftFeedback,
  delay,
  deleteTemplateDraft,
  downloadBlobAsFilename,
  getTemplate,
  getTemplateDrafts,
  openNewTab,
  toTagFiltersFromObject,
} from '@writercolab/common-utils';
import { IconVariant, useCustomSnackbar } from '@writercolab/ui-atoms';
import { isDocumentIsValid } from '@writercolab/utils';

import { snackbarMessages } from '@web/component-library';
import type {
  ActionMap,
  IDraftWithInputs,
  IDraftsContext,
  IDraftsContextProvider,
  IDraftsState,
  TCoWritePayload,
  TMediaFileUploadType,
} from '@web/types';
import {
  DraftPageParams,
  DraftsFilterKey,
  IDraftDropdownOptionKey,
  TDraftsActionType,
  TMediaFileUploadPlaintextTypes,
} from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useNavigate, useParams } from 'react-router';

import useQuery from '../hooks/useQuery';
import { appLocalStorage } from '../models/localStorage';
import { VoiceApiModel } from '../models/voice.api';
import { DEFAULT_DRAFTS_FETCH_LIMIT } from '../services/config/constants';
import { ROUTE } from '../services/config/routes';
import { createDocument } from '../services/request/documents';
import {
  deleteDraftsBulk,
  downloadCSVTeamDrafts,
  downloadDOCXTeamDrafts,
  getDraftById,
  getTeamDrafts,
} from '../services/request/drafts';
import { getMediaFile, getMediaFileDetails, getMediaFileSummary } from '../services/request/mediaFiles';
import requestService from '../services/request/requestService';
import { useAppState } from '../state';
import { extractBackendResponseErrorMessage } from '../utils/backendErrorUtils';
import {
  downloadAs,
  getMediaFileIdFromInputs,
  mapMediaFileDetailsToInputs,
  transformSeoBlogInputs,
} from '../utils/draftsUtils';
import { getLogger } from '../utils/logger';
import { HTTP_STATUS_INTERNAL_SERVER_ERROR } from '../utils/responseStatusCodeUtils';
import { processLlmOutputBeforeInsert } from '../utils/textUtils';

const LOG = getLogger('DraftsContext');

export type TDraftsActions = ActionMap<TCoWritePayload>[keyof ActionMap<TCoWritePayload>];

const DraftsContext = createContext<IDraftsContext>({} as IDraftsContext);

const draftsReducer = (state: IDraftsState, action: TDraftsActions) => {
  let newState: IDraftsState;

  switch (action.type) {
    case TDraftsActionType.SetIsLoading:
      newState = { ...state, isLoading: action.payload };
      break;
    case TDraftsActionType.SetSearchValue:
      newState = { ...state, search: action.payload };
      break;
    case TDraftsActionType.SetOffset:
      newState = { ...state, offset: action.payload };
      break;
    case TDraftsActionType.SetSelectedDrafts:
      newState = { ...state, selectedDrafts: action.payload };
      break;
    case TDraftsActionType.SetApplicationTags:
      newState = { ...state, applicationTags: action.payload };
      break;
    case TDraftsActionType.SetTemplates:
      newState = { ...state, allTemplates: action.payload };
      break;
    case TDraftsActionType.SetFilters:
      newState = { ...state, filters: { ...state.filters, [action.payload.key]: action.payload.filters } };
      break;
    case TDraftsActionType.SetOpenedDraftId:
      newState = { ...state, openedDraftId: action.payload };
      break;
    case TDraftsActionType.SetOpenedDraft:
      newState = { ...state, openedDraft: action.payload };
      break;
    case TDraftsActionType.SetIsLoadingOpenedDraft:
      newState = { ...state, isLoadingOpenedDraft: action.payload };
      break;
    case TDraftsActionType.SetIsDeletingDraft:
      newState = { ...state, isLoadingOpenedDraft: action.payload };
      break;
    case TDraftsActionType.SetIsExpandedDraft:
      newState = { ...state, isExpandedDraft: action.payload };
      break;
    case TDraftsActionType.SetIsLoadingDocumentDrafts:
      newState = { ...state, isLoadingDocumentDrafts: action.payload };
      break;
    case TDraftsActionType.SetDocumentDrafts:
      newState = { ...state, documentDrafts: action.payload };
      break;
    case TDraftsActionType.SetTemplateInputs:
      newState = { ...state, templateInputs: action.payload };
      break;
    case TDraftsActionType.SetVoiceName:
      newState = { ...state, voiceName: action.payload };
      break;
    case TDraftsActionType.SetReset:
      newState = { ...action.payload };
      break;
    case TDraftsActionType.SetDrafts:
    case TDraftsActionType.SetFilteredDrafts:
    case TDraftsActionType.SetDraftsAfterDelete:
    case TDraftsActionType.SetDraftsAfterBulkDelete:
      newState = { ...state, ...action.payload };
      break;
    default:
      newState = { ...state };
  }

  return newState;
};

const initialDraftsState: IDraftsState = {
  isLoading: true,
  isLoadingOpenedDraft: false,
  isDeletingDraft: false,
  isLoadingDocumentDrafts: false,
  documentId: '',
  templateId: '',
  search: '',
  sortField: SortFieldTypes.CREATION_TIME,
  sortOrder: SortOrders.DESC,
  offset: 0,
  limit: DEFAULT_DRAFTS_FETCH_LIMIT,
  drafts: [],
  openedDraftId: null,
  openedDraft: null,
  selectedDrafts: [],
  totalCount: 0,
  applicationTags: [],
  allTemplates: [],
  documentDrafts: [],
  filters: { [DraftsFilterKey.TEMPLATE]: [] },
  isExpandedDraft: false,
  templateInputs: [],
  voiceName: null,
};

enum DRAFTS_MESSAGES {
  DOWNLOAD_FILE_IN_PROGRESS = 'Your download is in progress...',
  DELETE_SUCCESS = 'Your draft was deleted',
  DELETE_ERROR = 'Unable to delete draft',
  TEXT_COPY_SUCCESS = 'Text copied to clipboard',
  FEEDBACK_SUCCESS = 'Your feedback was submitted. Thanks for reviewing!',
  FEEDBACK_ERROR = 'Unable to rate draft',
}

const DELAY_UNTIL_SHOW_FILE_DOWNLOAD_IN_PROGRESS = 2000;

const DraftsContextProvider: React.FC<IDraftsContextProvider> = observer(({ children }) => {
  const { orgId, teamId, docId } = useParams<Record<string, string>>();
  const {
    appState,
    appModel: { teamsModel, appLibraryModel, analyticsService, assistantSubscription },
  } = useAppState();
  const query = useQuery();
  const navigate = useNavigate();
  const mode = query.get('mode' as ContentEditorPageMode);
  const openDraftId = query.get(DraftPageParams.OPEN_ID) as string;
  const isContentEditor = !isEmpty(mode);

  const [state, dispatchDraftsState] = useReducer(draftsReducer, initialDraftsState);
  const { enqueueSuccessSnackbar, enqueueCustomSnackbar, enqueueErrorSnackbar, closeSnackbar } = useCustomSnackbar();

  const voiceApiModel = useMemo(
    () =>
      new VoiceApiModel({
        request: requestService.api,
        organizationId: () => Number(orgId),
        teamId: () => Number(teamId),
        isVoiceEnabled: () => assistantSubscription.isModelReady && !!assistantSubscription.access?.voice,
      }),
    [assistantSubscription.access?.voice, assistantSubscription.isModelReady, orgId, teamId],
  );

  const {
    isLoading,
    documentId,
    search,
    sortField,
    sortOrder,
    offset,
    limit,
    totalCount,
    drafts,
    selectedDrafts,
    applicationTags,
    allTemplates,
    filters,
    openedDraftId,
    openedDraft,
    isExpandedDraft,
    documentDrafts,
  } = state;

  const deployedApps = appLibraryModel?.applicationsList ?? [];

  const access = useMemo(
    () => ({
      isSeoBlogBuilder: openedDraft?.template.id === BUILDER_TEMPLATE_ID.SEO_BLOG || false,
      isEventTakeaways: openedDraft?.template.id === BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS || false,
    }),
    [openedDraft?.template.id],
  );

  const currentTeam = teamsModel.teams?.find(team => `${team.id}` === teamId);

  const onLoadMore = () => {
    if (!isLoading && drafts.length && offset < totalCount) {
      getDrafts(false);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: totalCount > drafts.length,
    onLoadMore,
    rootMargin: '0px 0px 50px 0px',
  });

  const setLoading = (isLoading: boolean) => {
    dispatchDraftsState({
      type: TDraftsActionType.SetIsLoading,
      payload: isLoading,
    });
  };

  const handleSearch = (searchValue: string) => {
    dispatchDraftsState({
      type: TDraftsActionType.SetSearchValue,
      payload: searchValue,
    });
  };

  const selectDrafts = (id: number) => {
    const isIdIncluded = selectedDrafts.includes(id);
    const updatedDrafts = isIdIncluded
      ? selectedDrafts.filter((draftId: number) => draftId !== id)
      : [...selectedDrafts, id];

    dispatchDraftsState({
      type: TDraftsActionType.SetSelectedDrafts,
      payload: updatedDrafts,
    });
  };

  const updateDraftId = (id: number | null) => {
    dispatchDraftsState({
      type: TDraftsActionType.SetOpenedDraftId,
      payload: id || null,
    });
  };

  const handleFilterChange = (key: DraftsFilterKey, tableFilters: ITableFilter[]) => {
    dispatchDraftsState({
      type: TDraftsActionType.SetFilters,
      payload: {
        key,
        filters: tableFilters,
      },
    });
  };

  const handleTagsFilterClose = () => {
    const updatedTags = filters[DraftsFilterKey.TEMPLATE].map(filter => ({ ...filter, isSelected: false }));

    analyticsService.track(AnalyticsActivity.filteredDrafts, {
      filter: 'tags',
      templateIds: updatedTags.map(tag => tag.id),
    });

    handleFilterChange(DraftsFilterKey.TEMPLATE, updatedTags);
  };

  const getDrafts = async (isReset: boolean) => {
    const _offset = isReset ? 0 : offset;
    const templateIds = filters[DraftsFilterKey.TEMPLATE].filter(tag => tag.isSelected).map(tag => tag.id) || [];

    setLoading(true);

    const params = {
      documentId,
      templateIds,
      search,
      sortField,
      sortOrder,
      offset: _offset,
      limit,
    };

    try {
      if (orgId && teamId) {
        const data = await getTeamDrafts({ orgId, teamId, params });

        dispatchDraftsState({
          type: TDraftsActionType.SetDrafts,
          payload: {
            drafts: isReset ? data.result : [...drafts, ...data.result],
            isLoading: false,
            totalCount: data.totalCount,
            allTemplates: [],
            offset: _offset + DEFAULT_DRAFTS_FETCH_LIMIT,
          },
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueErrorSnackbar(snackbarMessages.templates.getTemplatesError(error as string));
    }
  };

  const deriveErrorMessage = (err: ErrorMessage, errorPrefix: string): string => {
    if (typeof err.response.data === 'string') {
      return err.response.data;
    }

    return `${errorPrefix} ${extractBackendResponseErrorMessage(err)}`;
  };

  const handleBeError = (err: ErrorMessage, errorPrefix: string = '') => {
    if (err?.response?.data) {
      const message = deriveErrorMessage(err, errorPrefix) || 'Something went wrong.';
      enqueueErrorSnackbar(message);
    }
  };

  const fetchTemplateCategories = async (): Promise<ITemplateCategory[]> => {
    const templateCategoriesFiltered: ITemplateCategory[] = [];
    try {
      const applicationTags = await requestService.api
        .get('/api/template/application/tag', {})
        .then(({ data }) => data.value || []);

      dispatchDraftsState({
        type: TDraftsActionType.SetApplicationTags,
        payload: applicationTags,
      });
    } catch (error) {
      handleBeError(error as ErrorMessage);
    }

    return templateCategoriesFiltered;
  };

  const fetchTemplates = async (): Promise<void> => {
    try {
      const customBuildersTemplate = [
        {
          id: BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS as string,
          name: 'Recaps',
          isSelected: false,
        },
        {
          id: BUILDER_TEMPLATE_ID.SEO_BLOG as string,
          name: 'Blog post builder',
          isSelected: false,
        },
      ];

      let allTemplates = [...customBuildersTemplate];

      if (orgId && teamId) {
        if (deployedApps) {
          allTemplates = [
            ...allTemplates,
            ...deployedApps
              .filter(({ id }) => id !== BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS && id !== BUILDER_TEMPLATE_ID.SEO_BLOG)
              .map(({ id, name }) => ({
                id,
                name,
                isSelected: false,
              })),
          ];
        }
      }

      dispatchDraftsState({
        type: TDraftsActionType.SetTemplates,
        payload: allTemplates,
      });
    } catch (error) {
      handleBeError(error as ErrorMessage);
    }
  };

  const handleFileDownload = async (fileType: string) => {
    const templateIds = filters[DraftsFilterKey.TEMPLATE].filter(tag => tag.isSelected).map(tag => tag.id) || [];

    setLoading(true);

    const params = {
      documentId,
      templateIds,
      search,
      sortField,
      sortOrder,
      offset,
      limit,
      draftIds: selectedDrafts,
    };

    try {
      if (orgId && teamId) {
        const blobFn = fileType === IDraftDropdownOptionKey.CSV ? downloadCSVTeamDrafts : downloadDOCXTeamDrafts;
        const blob: Blob = await blobFn({ orgId, teamId, params });
        downloadBlobAsFilename(blob, `drafts.${fileType}`);
      }
    } catch (err) {
      const errorMessage = extractBackendResponseErrorMessage(err);
      enqueueErrorSnackbar(`Download drafts failed: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDrafts = async () => {
    try {
      if (orgId && teamId) {
        await deleteDraftsBulk({ orgId, teamId, draftIds: selectedDrafts });
        const updatedDrafts = drafts ? drafts.filter(({ id }) => !selectedDrafts.includes(id)) : [];
        enqueueSuccessSnackbar('Your draft was deleted');

        dispatchDraftsState({
          type: TDraftsActionType.SetDraftsAfterBulkDelete,
          payload: {
            drafts: updatedDrafts,
            selectedDrafts: [],
          },
        });
      }
    } catch (error) {
      handleBeError(error as ErrorMessage, 'Deleting drafts failed:');
    }
  };

  const handleExpandDraft = () => {
    dispatchDraftsState({
      type: TDraftsActionType.SetIsExpandedDraft,
      payload: !isExpandedDraft,
    });
  };

  const getDraftDescription = async (draftId: number) => {
    dispatchDraftsState({
      type: TDraftsActionType.SetOpenedDraft,
      payload: null,
    });

    dispatchDraftsState({
      type: TDraftsActionType.SetIsLoadingOpenedDraft,
      payload: true,
    });

    try {
      if (orgId && teamId) {
        const data = await getDraftById({ orgId, teamId, draftId: `${draftId}` });

        try {
          if (data.voiceId) {
            const voice = await voiceApiModel.getVoiceDetails(data.voiceId);

            dispatchDraftsState({
              type: TDraftsActionType.SetVoiceName,
              payload: voice.name,
            });
          }
        } catch {
          dispatchDraftsState({
            type: TDraftsActionType.SetVoiceName,
            payload: 'Deleted voice',
          });
        }

        if (data.template.id === BUILDER_TEMPLATE_ID.SEO_BLOG) {
          data.inputs = transformSeoBlogInputs(data.inputs);
        } else if (data.template.id === BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS) {
          const mediaFileId = getMediaFileIdFromInputs(data.inputs);

          if (mediaFileId) {
            try {
              const mediaFileDetails = await getMediaFileDetails(orgId, teamId, mediaFileId.value[0]);

              data.media = mapMediaFileDetailsToInputs(
                mediaFileId.value[0],
                mediaFileDetails.name,
                mediaFileDetails.url,
              );
            } catch (error) {
              LOG.error(error);
            }
          }
        }

        dispatchDraftsState({
          type: TDraftsActionType.SetOpenedDraft,
          payload: data,
        });
      }
    } catch (error) {
      const err = error as ErrorMessage;
      const messageFromErrror =
        typeof err?.response?.data === 'string' ? err.response.data : err?.response?.data?.errors?.[0]?.description;
      const message =
        err.response.status === HTTP_STATUS_INTERNAL_SERVER_ERROR
          ? `This draft can't be opened because it was deleted.`
          : `Fetching draft failed: ${messageFromErrror}`;

      enqueueErrorSnackbar(message || 'Something went wrong.');
    } finally {
      dispatchDraftsState({
        type: TDraftsActionType.SetIsLoadingOpenedDraft,
        payload: false,
      });
    }
  };

  const onRateDraft = async (draftId: number, documentId: string, params: ITemplateDraftFeedbackParams) => {
    if (isContentEditor) {
      analyticsService.track(AnalyticsActivity.rateDraft, {
        draft_view: AnalyticsDraftPlace.TILE,
        template_name: openedDraft?.template?.name || '',
      });
    }

    try {
      const result = await createDraftFeedback(orgId, teamId, documentId, `${draftId}`, params);

      if (result) {
        enqueueSuccessSnackbar(DRAFTS_MESSAGES.FEEDBACK_SUCCESS);
      }
    } catch {
      enqueueErrorSnackbar(DRAFTS_MESSAGES.FEEDBACK_ERROR);
    }
  };

  const onDraftCopy = async (draft?: ITemplateDraft) => {
    if (isContentEditor) {
      analyticsService.track(AnalyticsActivity.copyDraft, {
        draft_view: AnalyticsDraftPlace.TILE,
        template_name: openedDraft?.template?.name || '',
      });
    }

    const tempDraft = draft || openedDraft;

    if (tempDraft) {
      const text = tempDraft.title ? `<h2>${tempDraft.title}</h2>\n${tempDraft.body}` : tempDraft.body;
      const html = processLlmOutputBeforeInsert(text);
      const isCopied = copyToClipboard({ text, html });

      if (isCopied) {
        enqueueCustomSnackbar(DRAFTS_MESSAGES.TEXT_COPY_SUCCESS, { icon: IconVariant.COPY });
      }
    }
  };

  const onDeleteDraft = async (draftId = 0, documentId = '') => {
    if (isContentEditor) {
      analyticsService.track(AnalyticsActivity.deleteDraft, {
        draft_view: AnalyticsDraftPlace.TILE,
        template_name: openedDraft?.template?.name || '',
      });
    }

    try {
      const docId = `${documentId || openedDraft?.document.id}`;
      const dId = draftId || openedDraft?.id;
      await deleteTemplateDraft(orgId, teamId, docId, `${dId}`);

      if (draftId) {
        const updatedDrafts = documentDrafts.filter(({ id }) => id !== dId);

        dispatchDraftsState({
          type: TDraftsActionType.SetDocumentDrafts,
          payload: updatedDrafts,
        });
      } else {
        const updatedDrafts = drafts.filter(({ id }) => id !== dId);
        const updatedSelectedDrafts = selectedDrafts.filter(id => id !== dId);
        handleDeleteDraftIdFromQuery();
        enqueueSuccessSnackbar(DRAFTS_MESSAGES.DELETE_SUCCESS);

        dispatchDraftsState({
          type: TDraftsActionType.SetDraftsAfterDelete,
          payload: {
            drafts: updatedDrafts,
            isDeletingDraft: false,
            isExpandedDraft: false,
            openedDraftId: null,
            selectedDrafts: updatedSelectedDrafts,
          },
        });
      }
    } catch {
      enqueueErrorSnackbar(DRAFTS_MESSAGES.DELETE_ERROR);
    }
  };

  const getDocumentTemplateDrafts = async (onFetchFinish?: (drafts: ITemplateDraft[]) => void) => {
    try {
      if (!isDocumentIsValid(docId)) {
        return;
      }

      dispatchDraftsState({
        type: TDraftsActionType.SetIsLoadingDocumentDrafts,
        payload: true,
      });

      const fetchedDrafts = await getTemplateDrafts(orgId, teamId, docId);
      onFetchFinish?.(fetchedDrafts);

      dispatchDraftsState({
        type: TDraftsActionType.SetDocumentDrafts,
        payload: fetchedDrafts,
      });
    } catch (error: any) {
      const err = error.message ? error.message : error.response.data.errors[0].description;
      snackbarMessages.templates.getTemplatesError(err);
    } finally {
      dispatchDraftsState({
        type: TDraftsActionType.SetIsLoadingDocumentDrafts,
        payload: false,
      });
    }
  };

  const addDocumentDraft = (draft: ITemplateDraft) => {
    const updatedDrafts = documentDrafts ? [draft, ...documentDrafts] : [draft];

    dispatchDraftsState({
      type: TDraftsActionType.SetDocumentDrafts,
      payload: updatedDrafts,
    });
  };

  const handleDeleteDraftIdFromQuery = () => {
    dispatchDraftsState({
      type: TDraftsActionType.SetOpenedDraft,
      payload: null,
    });

    query.delete(DraftPageParams.OPEN_ID);

    navigate(ROUTE.toTeamDraftsWithDraftId(appState.organizationId, appState.teamId, query.toString()));
  };

  const getTemplateData = async (draft: IDraftWithInputs) => {
    try {
      const template = await getTemplate(`${orgId}`, `${teamId}`, draft.template.id);

      dispatchDraftsState({
        type: TDraftsActionType.SetTemplateInputs,
        payload: template.inputs,
      });
    } catch (err) {
      LOG.error(err);
    }
  };

  const downloadMediaFile = async (fileId: string, filename: string) => {
    try {
      if (!orgId || !teamId) {
        LOG.error('Organization or team id is empty');

        return;
      }

      enqueueSuccessSnackbar(DRAFTS_MESSAGES.DOWNLOAD_FILE_IN_PROGRESS, { key: fileId });
      const data = await getMediaFile(orgId, teamId, fileId);
      downloadAs(data, filename);
    } catch (err) {
      LOG.error(extractBackendResponseErrorMessage(err));
    } finally {
      delay(DELAY_UNTIL_SHOW_FILE_DOWNLOAD_IN_PROGRESS).then(() => closeSnackbar(fileId));
    }
  };

  const downloadMediaFileTranscript = async (fileId: string, filename: string) => {
    try {
      if (isEmpty(orgId) || isEmpty(teamId)) {
        LOG.error('organization or team id is empty');

        return;
      }

      const mediaFileUploadInformation = await requestService.api
        .get('/api/media/organization/{organizationId}/team/{teamId}/file/{fileId}', {
          params: {
            path: {
              organizationId: Number(orgId),
              teamId: Number(teamId),
              fileId,
            },
          },
        })
        .then(({ data }) => data)
        .catch(err => LOG.error(err));

      if (!mediaFileUploadInformation) {
        LOG.error('File info is empty');

        return;
      }

      if (
        TMediaFileUploadPlaintextTypes.includes(
          mediaFileUploadInformation.mediaType as typeof TMediaFileUploadType.type,
        )
      ) {
        requestService.api
          .get('/api/media/organization/{organizationId}/team/{teamId}/file/{fileId}/download', {
            params: {
              path: {
                organizationId: Number(orgId),
                teamId: Number(teamId),
                fileId,
              },
            },
            parseAs: 'blob',
          })
          .then(({ data }) => {
            enqueueSuccessSnackbar(DRAFTS_MESSAGES.DOWNLOAD_FILE_IN_PROGRESS, { key: fileId });
            downloadAs(new Blob([data]), mediaFileUploadInformation.name);
          })
          .catch(err => LOG.error(err));
      } else {
        requestService.api
          .get('/api/media/organization/{organizationId}/team/{teamId}/file/{fileId}/download/diarized', {
            params: {
              path: {
                organizationId: Number(orgId),
                teamId: Number(teamId),
                fileId,
              },
            },
            parseAs: 'blob',
          })
          .then(({ data }) => {
            enqueueSuccessSnackbar(DRAFTS_MESSAGES.DOWNLOAD_FILE_IN_PROGRESS, { key: fileId });
            downloadAs(new Blob([data]), filename);
          })
          .catch(err => LOG.error(err));
      }
    } catch (err) {
      LOG.error(err);
    } finally {
      delay(DELAY_UNTIL_SHOW_FILE_DOWNLOAD_IN_PROGRESS).then(() => closeSnackbar(fileId));
    }
  };

  const downloadMediaFileSummary = async (fileId: string, filename: string) => {
    try {
      if (!orgId || !teamId) {
        LOG.error('Organization or team id is empty');

        return;
      }

      enqueueSuccessSnackbar(DRAFTS_MESSAGES.DOWNLOAD_FILE_IN_PROGRESS, { key: fileId });
      const data = await getMediaFileSummary(orgId, teamId, fileId);
      downloadAs(data, filename);
    } catch (err) {
      LOG.error(extractBackendResponseErrorMessage(err));
    } finally {
      delay(DELAY_UNTIL_SHOW_FILE_DOWNLOAD_IN_PROGRESS).then(() => closeSnackbar(fileId));
    }
  };

  const openDraftInNewDocument = async () => {
    const templateId = openedDraft?.template?.id || '';

    if (!openedDraft || !orgId || !teamId || !templateId) {
      return;
    }

    const newDocument = await createDocument(+orgId, +teamId, '');

    const formValues = openedDraft.inputs.reduce((acc, input) => ({ ...acc, [input.name]: input.value }), {});
    const draftValues = { title: openedDraft?.title, body: openedDraft?.body };
    const values = openedDraft.inputs.map(input => ({
      name: input.name,
      value: input.value,
    }));
    const cachedKey = `${newDocument.id}-${openedDraft.template.id}`;

    appLocalStorage.lastDraft.set(draftValues);
    appLocalStorage.lastDraftValues.set(formValues);
    appLocalStorage.editorApplicationPreviewValues.update({ [cachedKey]: _ => values });

    if (access.isEventTakeaways) {
      openNewTab(
        ROUTE.toEditorWithModeAndTemplate(
          orgId,
          teamId,
          newDocument.id,
          ContentEditorPageMode.EVENT_TAKEAWAYS,
          templateId,
        ),
      );
    } else if (access.isSeoBlogBuilder) {
      openNewTab(
        ROUTE.toEditorWithModeAndTemplate(orgId, teamId, newDocument.id, ContentEditorPageMode.BLOG, templateId),
      );
    } else {
      openNewTab(
        ROUTE.toEditorWithModeAndTemplate(orgId, teamId, newDocument.id, ContentEditorPageMode.CO_WRITE, templateId),
      );
    }
  };

  const goToDocumentDrafts = () => {
    if (access.isEventTakeaways) {
      openNewTab(ROUTE.toEditorWithEventTakeaways(orgId, teamId, openedDraft?.document?.id));
    } else if (access.isSeoBlogBuilder) {
      openNewTab(ROUTE.toEditorWithBlogBuilder(orgId, teamId, openedDraft?.document?.id));
    } else {
      openNewTab(ROUTE.toDocumentDrafts(orgId, teamId, openedDraft?.document?.id, openedDraft?.template?.id));
    }
  };

  useEffect(() => {
    const isGetDraftsAvailable = !isEmpty(filters[DraftsFilterKey.TEMPLATE]) && !isContentEditor;

    if (!isEmpty(search)) {
      analyticsService.track(AnalyticsActivity.searchedDrafts, {});
    }

    if (isGetDraftsAvailable) {
      getDrafts(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filters[DraftsFilterKey.TEMPLATE]]);

  useEffect(() => {
    fetchTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationTags]);

  useEffect(() => {
    const tagsFromQuery = query.get(DraftsFilterKey.TEMPLATE) || '';
    const tagsToFilters = toTagFiltersFromObject(allTemplates);
    const selectedFilters = tagsToFilters.map(filter =>
      tagsFromQuery.includes(filter.id) ? { ...filter, isSelected: true } : filter,
    );

    if (!isEmpty(selectedFilters)) {
      handleFilterChange(DraftsFilterKey.TEMPLATE, selectedFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTemplates]);

  useEffect(() => {
    if (openedDraftId) {
      getDraftDescription(openedDraftId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedDraftId]);

  useEffect(() => {
    if (openedDraft && !access.isEventTakeaways && !access.isSeoBlogBuilder) {
      getTemplateData(openedDraft);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedDraft]);

  useEffect(() => {
    if (isContentEditor) {
      getDocumentTemplateDrafts();
    } else {
      dispatchDraftsState({
        type: TDraftsActionType.SetReset,
        payload: initialDraftsState,
      });

      fetchTemplateCategories();
      analyticsService.track(AnalyticsActivity.viewedDraftsPage, {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    updateDraftId(openDraftId ? +openDraftId : null);
  }, [openDraftId]);

  const contextValue = {
    state,
    applicationTags,
    currentTeam,
    infiniteRefSentry: sentryRef as unknown as React.RefObject<HTMLDivElement>,
    selectedDrafts,
    handleSearch,
    selectDrafts,
    updateDraftId,
    onRateDraft,
    onDraftCopy,
    onDeleteDraft,
    getDraftDescription,
    handleFilterChange,
    handleTagsFilterClose,
    handleFileDownload,
    handleDeleteDrafts,
    handleExpandDraft,
    getDocumentTemplateDrafts,
    addDocumentDraft,
    handleDeleteDraftIdFromQuery,
    openDraftInNewDocument,
    goToDocumentDrafts,
    downloadMediaFile,
    downloadMediaFileSummary,
    downloadMediaFileTranscript,
  };

  return <DraftsContext.Provider value={contextValue}>{children}</DraftsContext.Provider>;
});

export function useDraftsContext() {
  const context = useContext(DraftsContext);

  if (!context) {
    throw new Error('useDraftsContext must be used within the DraftsContextProvider');
  }

  return context;
}

export default DraftsContextProvider;
