import type React from 'react';
import { useMemo } from 'react';

import { Heading, HeadingVariant } from '@writercolab/ui-atoms';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { observer } from 'mobx-react-lite';

import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { openContactSalesPage } from '../../../utils/navigationUtils';
import type { IOrgTeamIdsListItem } from './parts/OrgTeamIdsSection';
import OrgTeamIdsSection from './parts/OrgTeamIdsSection';
import ServiceAccountSection from './parts/ServiceAccountSection';

import styles from './styles.module.css';

interface IApiPageProps {
  isDisabled: boolean;
  onAiStudioClick: VoidFunction;
}

export const ApiPage: React.FC<IApiPageProps> = observer(({ isDisabled, onAiStudioClick }) => {
  usePageTitle('API');

  const {
    appState: { organization },
    appModel: {
      assistantSubscription,
      isForcedAIStudioAPIKey,
      teamsModel: { teams },
    },
  } = useAppState();

  const access = {
    showServiceAccounts: assistantSubscription.isEnterprise || assistantSubscription.isTeam,
  };

  const org = useMemo<IOrgTeamIdsListItem>(
    () => ({
      id: `${organization?.id}`,
      name: organization?.name || '',
    }),
    [organization],
  );

  const orgTeams = useMemo<IOrgTeamIdsListItem[]>(
    () =>
      (teams || [])
        .map(team => ({
          id: `${team.id}`,
          name: team.name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [teams],
  );

  if (isDisabled) {
    return (
      <div className={styles.container}>
        <BillingLock type={BillingLockType.ADMIN_API} onActionCallback={openContactSalesPage} />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Heading variant={HeadingVariant.H2} className={styles.containerHeader} bold>
        API
      </Heading>
      {organization?.id && access.showServiceAccounts && (
        <ServiceAccountSection
          organizationId={organization?.id}
          forceAIStudioAPIKey={isForcedAIStudioAPIKey}
          onGoToAiStudio={onAiStudioClick}
        />
      )}
      <div className={styles.containerFormSeparator} />
      <OrgTeamIdsSection org={org} teams={orgTeams} />
    </div>
  );
});
