import type React from 'react';

import { getDateTime } from '@writercolab/common-utils';
import {
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import type { TBaseTableSection, TOrganizationsDocument, TOrganizationsTableDocument } from '@web/types';
import { OrganizationDocumentAction } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { EMPTY_DOCUMENT_PLACEHOLDER, EMPTY_PLACEHOLDER } from '../../../services/config/constants';
import { generateDocumentsTableSections } from '../../../utils/tableUtils';
import type { IBodyCellProps, IRowProps } from '../BaseTable/BaseTable';

import styles from './DocsTable.module.css';

const sectionsGenerator = (
  documents: TOrganizationsTableDocument[],
): Record<string, TBaseTableSection<TOrganizationsTableDocument>> => {
  const sections = generateDocumentsTableSections<TOrganizationsTableDocument>();
  documents.forEach(document => {
    const date = new Date(String(document.modificationTime));

    Object.keys(sections).forEach(section => {
      const { data, condition } = sections[section];

      if (condition(date)) {
        data.push(document);
      }
    });
  });

  return sections;
};

export const toTableDocument = (document: TOrganizationsDocument): TOrganizationsTableDocument =>
  ({
    ...document,
    id: String(document.id),
  }) as TOrganizationsTableDocument;

const generateTableCell = (id: string, component: React.ReactNode): IBodyCellProps => ({
  id: `cell-${id}`,
  component,
});

const TableTitleCell: React.FC<{ title: string }> = ({ title }) => {
  const hasTitle = !isEmpty(title);

  return (
    <div className={styles.titleCell}>
      <Icon name={IconVariant.DOCUMENT} />
      <Text variant={TextSize.XL} ellipsisOverflow italic={!hasTitle}>
        {hasTitle ? title : EMPTY_DOCUMENT_PLACEHOLDER}
      </Text>
    </div>
  );
};

const TableTextCell: React.FC<{ text: string }> = ({ text }) => <Text variant={TextSize.M}>{text}</Text>;

const generateDocumentRow = (
  document: TOrganizationsTableDocument,
  onDocumentAction: (docId: string, action: typeof OrganizationDocumentAction.type) => void,
  scopeFilterDisabled: boolean,
  showCreateDocumentDate: boolean,
): IRowProps => {
  const actionDate = showCreateDocumentDate ? document.creationTime : document.openedByMeTime;

  return {
    id: document.id,
    cells: [
      generateTableCell(document.id, <TableTitleCell title={document.title} />),
      generateTableCell(
        document.id,
        scopeFilterDisabled ? null : (
          <TableTextCell text={document.createdUser?.fullName || document.createdUser?.email || EMPTY_PLACEHOLDER} />
        ),
      ),
      generateTableCell(document.id, <TableTextCell text={actionDate ? getDateTime(actionDate) : EMPTY_PLACEHOLDER} />),
      generateTableCell(
        document.id,
        <Dropdown
          triggerClassName={styles.rowActions}
          dropDownContainerClassName={styles.rowActionBottom}
          trigger={<Icon name={IconVariant.MORE_HORIZ} />}
          itemsType={ItemsTypes.ACTION}
          placement={DropdownPlacement.BOTTOM_RIGHT}
          options={[
            {
              id: OrganizationDocumentAction.enum.delete,
              warning: true,
              name: 'Delete',
            },
          ]}
          onPrimaryOptionClickAction={(actionId: string) =>
            onDocumentAction(document.id, actionId as typeof OrganizationDocumentAction.type)
          }
        />,
      ),
    ],
  };
};

const generateStickyHeader = (title: string): IRowProps => ({
  id: `row-header-${title}`,
  className: styles.stickyHeader,
  cells: [
    {
      id: `cell-header-${title}`,
      component: (
        <Heading variant={HeadingVariant.H3} bold>
          {title}
        </Heading>
      ),
    },
  ],
});

export const generateDocumentsTableData = (
  documents: TOrganizationsTableDocument[],
  onDocumentAction: (id: string, action: typeof OrganizationDocumentAction.type) => void,
  scopeFilterDisabled: boolean,
  showCreateDocumentDate: boolean,
): IRowProps[] => {
  const sections = sectionsGenerator(documents);

  return Object.keys(sections).flatMap(sectionKey => {
    const section = sections[sectionKey];

    if (isEmpty(section.data)) {
      return [];
    }

    return [
      generateStickyHeader(section.title),
      ...section.data.map(document =>
        generateDocumentRow(document, onDocumentAction, scopeFilterDisabled, showCreateDocumentDate),
      ),
    ] as IRowProps[];
  });
};
