import { useMemo } from 'react';

import { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Modal, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { InputField } from 'components/organisms/InputField/InputField';

import { TKnowledgeGraphUrlModal } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphWebConnectorUIModel } from '../../KnowledgeGraphWebConnectorModel.ui';
import { KG_URLS_LIMIT } from '../../utils';

import styles from './EditExcludeUrlModal.module.css';

interface IEditExcludeUrlModalProps {
  model: KnowledgeGraphWebConnectorUIModel;
}

export const EditExcludeUrlModal = observer<IEditExcludeUrlModalProps>(({ model }) => {
  const { analyticsService, modalsManager, isLoading, onEditExcludeUrl, validUrlsCount, excludeUrlsCount } = model;
  const modalState = modalsManager.getModalState(TKnowledgeGraphUrlModal.enum.editExcludeUrl);
  const urlField = useMemo(
    () =>
      FieldModel.build({
        init: () => modalState?.excludeUrl,
        autotouched: true,
        validation: value => {
          if (modalState && !value?.startsWith(modalState.url)) {
            return 'Excluded URL must contain the full parent URL';
          }

          return modalState?.url === value ? 'Enter a valid subpage' : undefined;
        },
      }),
    [modalState],
  );

  return (
    <Modal
      open
      title="Edit excluded subpage"
      className={styles.content}
      modalTitleClassName={styles.title}
      disableBackdropClick
      handleClose={() => {
        analyticsService.track(AnalyticsActivity.kgWebEditUrlModalClosed, {
          url: modalState?.url,
        });
        modalsManager.hideModal();
      }}
    >
      <>
        <Text className={styles.label} variant={TextSize.XL} color={TextColor.BLACK}>
          Website URL
        </Text>
        <InputField field={urlField} placeholder="Enter a subpage" />
        <div className={styles.controls}>
          <Text variant={TextSize.M} color={TextColor.BLACK}>
            {validUrlsCount + excludeUrlsCount}/{KG_URLS_LIMIT} URLs ({validUrlsCount} included · {excludeUrlsCount}{' '}
            excluded)
          </Text>
          <Button
            isLoading={isLoading}
            disabled={!!urlField.error}
            size={SizeTypes.MEDIUM}
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              if (modalState?.excludeUrl && urlField.value) {
                onEditExcludeUrl(modalState.id, modalState.excludeUrl, urlField.value);
              }
            }}
          >
            Save
          </Button>
        </div>
      </>
    </Modal>
  );
});
