import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type TPaginatedTemplatesApp = components['schemas']['model_PaginatedResult_template_dto_TemplateResponse'];
export type TTemplatesAppResponse = components['schemas']['template_dto_TemplateResponse'];
export const TemplatesAppSortField = new Enum('name', 'createdAt');
export const TemplatesAppSortOrder = new Enum('asc', 'desc');

export type TTemplatesAppSortField = typeof TemplatesAppSortField.type;
export type TTemplatesAppSortOrder = typeof TemplatesAppSortOrder.type;

export interface TTemplatesAppQuery extends TPaginatedTemplatesAppArgs, TPaginatedTemplatesAppExtraArgs {}

export type TPaginatedTemplatesAppArgs = {
  limit?: number;
  offset?: number;
};

export type TPaginatedTemplatesAppExtraArgs = {
  search?: string;
  sortField?: TTemplatesAppSortField;
  sortOrder?: TTemplatesAppSortOrder;
};
