import type React from 'react';

import { useParams } from 'react-router';

import DocumentsContextProvider from '../../../context/documentsContext';
import DashboardPage from './index';

export const DashboardPageWrapper: React.FC = () => {
  const { orgId, teamId } = useParams<Record<string, string>>();

  return (
    <DocumentsContextProvider activeOrgId={+orgId!} activeTeamId={+teamId!}>
      <DashboardPage />
    </DocumentsContextProvider>
  );
};
