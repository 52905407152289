import type React from 'react';
import { Suspense } from 'react';

import { LoadingPage } from '@web/component-library';
import { Route, Routes } from 'react-router';

import SnippetsContextProvider from '../../../context/snippetsContext';
import SuggestionsContextProvider from '../../../context/suggestionsContext';
import TermsContextProvider from '../../../context/termsContext';
import { REACT_RELATIVE_ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import Error404Page from '../Error404Page';
import { SnippetsPage } from '../SnippetsPage';
import { SuggestionPage } from '../SuggestionsPage';
import { TermsPage } from '../TermsPage';

export const StyleguideSection: React.FC = () => {
  const { appState } = useAppState();

  if (!appState.team) {
    return null;
  }

  if (!appState.team?.termBank?.teamId) {
    return <Error404Page />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route
          path={REACT_RELATIVE_ROUTE.terms}
          element={
            <TermsContextProvider
              organizationId={appState.organization?.id}
              rootTeamId={appState.team.id}
              teamId={appState.team.termBank.teamId}
            >
              <TermsPage />
            </TermsContextProvider>
          }
        />
        <Route
          path={REACT_RELATIVE_ROUTE.snippets}
          element={
            <SnippetsContextProvider organizationId={appState.organization?.id} teamId={appState.team.snippet.teamId}>
              <SnippetsPage />
            </SnippetsContextProvider>
          }
        />
        <Route
          path={`${REACT_RELATIVE_ROUTE.suggestions}/*`}
          element={
            <SuggestionsContextProvider>
              <SuggestionPage />
            </SuggestionsContextProvider>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default StyleguideSection;
