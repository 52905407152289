import { SuggestionCategoryType } from '@writercolab/common-utils';
import { IconVariant } from '@writercolab/ui-atoms';
import { StructureSection, StructureSectionType } from '@writercolab/ui-molecules';

const structure = [
  {
    name: 'Punctuation checker',
    type: SuggestionCategoryType.PUNCTUATION_CHECKER,
    description:
      'Our punctuation checker looks for misplaced or missing commas, hyphens, semicolons, periods, and more.',
    structure: [],
  },
  {
    name: 'Punctuation style',
    type: SuggestionCategoryType.PUNCTUATION,
    description:
      'Some punctuation marks are a matter of preference. Use these settings to customize how you’d like these punctuation marks to appear in your writing. They’ll show up as Style suggestions to your team.',
    structure: [
      {
        type: StructureSectionType.GROUP,
        heading: 'Ampersands',
        icon: IconVariant.AMPERSAND,
        items: [
          {
            type: StructureSectionType.RULE,
            description: "Don't use an ampersand (&) unless it's used in a brand name.",
            example: ['The top sponsor was PG&E.'],
            ruleId: 'BAN_AMPERSANDS',
          },
          {
            type: StructureSectionType.RULE,
            description: "Don't use '+' to mean 'and'.",
            ruleId: 'PLUS_SIGN_MATH_ONLY',
            example: ['The conference will include a panel + group sessions.'],
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Apostrophes',
        icon: IconVariant.APOSTROPHE,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'S_POSS_NO_S',
            template: 'When making a possessive from a noun ending in -s, use {0}.',
            dropdownSources: [['only an apostrophe', 'an apostrophe -s']],
            valuesConfig: {
              S_POSS_NO_S: {
                selected: ['only an apostrophe'],
                example: ["This is Charles' project."],
              },
              S_POSS_WITH_S: {
                selected: ['an apostrophe -s'],
                example: ['This is Charles’s project.'],
              },
            },
          },

          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'ACRONYM_PL_S',
            template: 'Pluralize an acronym by adding {0}.',
            dropdownSources: [['-s', 'apostrophe -s']],
            valuesConfig: {
              ACRONYM_PL_S: {
                selected: ['-s'],
                example: ['These VIPs have early access.'],
              },
              ACRONYM_PL_APOSTROPHE_S: {
                selected: ['apostrophe -s'],
                example: ["These VIP's have early access."],
              },
            },
          },
        ],
      },

      {
        type: StructureSectionType.GROUP,
        heading: 'Colons and semicolons',
        icon: IconVariant.COLONS_AND_SEMI,
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Use semicolons sparingly. Consider using an em-dash or starting a new sentence.',
            ruleId: 'SEMICOLONS',
          },
        ],
      },

      {
        type: StructureSectionType.GROUP,
        heading: 'Contractions',
        icon: IconVariant.CONTRACTIONS,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'ENFORCE_CONTRACTIONS',
            template: '{0} contractions for common phrases.',
            dropdownSources: [['Use', "Don't use"]],
            valuesConfig: {
              ENFORCE_CONTRACTIONS: {
                selected: ['Use'],
                example: ["Registration doesn't open until tomorrow."],
              },
              BAN_CONTRACTIONS: {
                selected: ["Don't use"],
                example: ['Registration does not open until tomorrow.'],
              },
            },
          },
        ],
      },

      {
        type: StructureSectionType.GROUP,
        heading: 'Commas',
        icon: IconVariant.COMMA,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'ENFORCE_OXFORD_COMMA',
            template: '{0} the Oxford or serial comma when writing lists.',
            dropdownSources: [['Always use', "Don't use"]],
            valuesConfig: {
              ENFORCE_OXFORD_COMMA: {
                selected: ['Always use'],
                example: ["We're hiring in sales, product, and finance."],
              },
              BAN_OXFORD_COMMA: {
                selected: ["Don't use"],
                example: ["We're hiring in sales, product and finance."],
              },
            },
          },
        ],
      },

      {
        type: StructureSectionType.GROUP,
        heading: 'Dashes and hyphens',
        icon: IconVariant.DASH,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'EM_DASH_SPACES',
            template: 'Use an em-dash (—) to offset an aside. {0} spaces before and after the em-dash.',
            dropdownSources: [['Use', "Don't use"]],
            valuesConfig: {
              EM_DASH_SPACES: {
                selected: ['Use'],
                example: ['They aren’t adjusting the entire schedule — only this section.'],
              },
              EM_DASH_NO_SPACES: {
                selected: ["Don't use"],
                example: ['They aren’t adjusting the entire schedule—only this section.'],
              },
            },
          },
          {
            type: StructureSectionType.RULE,
            ruleId: 'EN_DASH_NUMBERS_DATES',
            description: 'Use an en-dash (–) to connect a range of numbers or dates.',
            example: ['We’re open from Mon-Tues.'],
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        icon: IconVariant.THREEDOTS,
        heading: 'Ellipses',
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'ELLIPSES_SPACES',
            dropdownSources: [['Use', "Don't use"]],
            template: '{0} spaces before and after an ellipses.',
            valuesConfig: {
              ELLIPSES_SPACES: {
                selected: ['Use'],
                example: ['Hmmm … something went wrong.'],
              },
              ELLIPSES_NO_SPACES: {
                selected: ["Don't use"],
                example: ['Hmmm…something went wrong.'],
              },
            },
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Exclamation points',
        icon: IconVariant.EXCLAMATION,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            template: "Don't use {0}.",
            defaultValue: 'EXCLAMATION_FLAG',
            dropdownSources: [['exclamation points', 'multiple exclamation points']],
            valuesConfig: {
              EXCLAMATION_FLAG: {
                selected: ['exclamation points'],
              },
              MULTIPLE_EXCLAMATIONS: {
                selected: ['multiple exclamation points'],
              },
            },
          },
        ],
      },

      {
        type: StructureSectionType.GROUP,
        heading: 'Percentages',
        icon: IconVariant.PERCENTAGES,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'PERCENT_WORD',
            dropdownSources: [
              ["spell out 'percent' instead of using '%'", "use '%' instead of spelling out 'percent'"],
            ],
            template: 'With numerals, {0}.',
            valuesConfig: {
              PERCENT_WORD: {
                selected: ["spell out 'percent' instead of using '%'"],
                example: ['Let’s do this 100 percent of the time.'],
              },
              PERCENT_SIGN: {
                selected: ["use '%' instead of spelling out 'percent'"],
                example: ['Let’s do this 100% of the time.'],
              },
            },
          },
        ],
      },

      {
        type: StructureSectionType.GROUP,
        heading: 'Periods',
        icon: IconVariant.DOT,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'COUNTRY_ACRONYMS_PERIODS',
            template: '{0} periods in common country acronyms.',
            dropdownSources: [['Use', "Don't use"]],
            valuesConfig: {
              COUNTRY_ACRONYMS_PERIODS: {
                selected: ['Use'],
                example: ['Our director is based in the U.K.'],
              },
              COUNTRY_ACRONYMS_NO_PERIODS: {
                selected: ["Don't use"],
                example: ['Our director is based in the UK.'],
              },
            },
          },
          {
            type: StructureSectionType.RULE,
            ruleId: 'DOUBLE_SPACE',
            description: "Don't use double spaces within sentences.",
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Quotation marks',
        icon: IconVariant.QUOTATIONMARK,
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'SMART_QUOTES',
            dropdownSources: [['smart quotes and apostrophes', 'straight quotes and apostrophes']],
            template: 'Use {0} instead of {unSelectedOption}.',
            valuesConfig: {
              SMART_QUOTES: {
                selected: ['smart quotes and apostrophes'],
              },
              PRIME_MARKS: {
                selected: ['straight quotes and apostrophes'],
              },
            },
          },
        ],
      },
    ],
  },
];

export default structure as StructureSection[];
