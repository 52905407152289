import React from 'react';

import cx from 'classnames';

import { Tooltip, TooltipAlignment } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IBeaconIconProps {
  tooltipContent?: NonNullable<React.ReactNode>;
  tooltipOpen?: boolean;
  tooltipWidth?: string | number;
  beaconClassName?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
}

export const BeaconIcon: React.FC<IBeaconIconProps> = ({
  tooltipContent,
  tooltipOpen = false,
  tooltipWidth,
  tooltipPlacement = 'right',
  beaconClassName,
}) => (
  <div className={styles.container}>
    <Tooltip
      title={tooltipContent || ''}
      align={TooltipAlignment.LEFT}
      open={tooltipOpen}
      tooltipWidth={tooltipWidth}
      placement={tooltipPlacement}
    >
      <div className={cx(styles.beacon, beaconClassName)} />
    </Tooltip>
  </div>
);

export default BeaconIcon;
