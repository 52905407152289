import React, { ReactNode } from 'react';

import cx from 'classnames';

import { Checkbox, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface ISuggestionSimpleRuleProps {
  children?: ReactNode;
  className?: string;
  value?: string;
  isChecked: boolean;
  highlighted?: boolean;
  readOnly?: boolean;
  example?: string[];
  exampleLabel?: string;
  onUpdateRuleValue: (checked: boolean, value?: string) => void;
}

export const SuggestionSimpleRule: React.FC<ISuggestionSimpleRuleProps> = ({
  children,
  className,
  value,
  isChecked,
  highlighted,
  readOnly,
  example,
  exampleLabel = 'Example:',
  onUpdateRuleValue,
}) => {
  const elClass = cx(className, { [styles.highlighted]: highlighted });
  const onCheckboxClick = () => !readOnly && onUpdateRuleValue(!isChecked, value);
  const showExamplesAsList = example && example.length > 1;

  return (
    <div className={elClass}>
      <Checkbox checked={isChecked} disabled={readOnly} className={styles.checkbox} onClick={onCheckboxClick} />
      <div
        className={styles.ruleLabelContainer}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Text variant={TextSize.L} bold={highlighted} color={TextColor.GREY} className={styles.ruleLabel}>
          {children}
        </Text>
        {example && (
          <div
            className={cx(styles.ruleExample, {
              [styles.ruleExamplesWrapperList]: showExamplesAsList,
            })}
          >
            <div>
              <Text variant={TextSize.L} color={TextColor.GREY2} bold className={styles.ruleExamplePrefix}>
                {exampleLabel}
              </Text>
            </div>
            <div>
              {example.map(exampleItem => (
                <Text
                  key={exampleItem}
                  variant={TextSize.L}
                  color={TextColor.GREY2}
                  className={cx({
                    [styles.ruleExamplesWrapperListItem]: showExamplesAsList,
                  })}
                >
                  {exampleItem}
                </Text>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SuggestionSimpleRule;
