import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type TOAuthAppsConnectorResponse = components['schemas']['media_dto_ConnectorResponse'];
export type TOAuthAppsResponse = components['schemas']['media_dto_BriefConnectorResponse'];
export type TPaginatedOAuthApps = components['schemas']['model_PaginatedResult_media_dto_BriefConnectorResponse'];
export const OAuthAppsTypes = new Enum('gdrive', 'sharepoint', 'notion', 'confluence');
export const OAuthAppsSortField = new Enum('id', 'name', 'type', 'organizationId', 'createdAt');
export const OAuthAppsSortOrder = new Enum('asc', 'desc');

export type TOAuthAppsTypes = typeof OAuthAppsTypes.type;
export type TOAuthAppsSortField = typeof OAuthAppsSortField.type;
export type TOAuthAppsSortOrder = typeof OAuthAppsSortOrder.type;

export interface TOAuthAppsQuery extends TPaginatedOAuthAppsArgs, TPaginatedOAuthAppsExtraArgs {}

export type TPaginatedOAuthAppsArgs = {
  limit?: number;
  offset?: number;
};

export type TPaginatedOAuthAppsExtraArgs = {
  search?: string;
  types?: TOAuthAppsTypes[];
  sortField?: TOAuthAppsSortField;
  sortOrder?: TOAuthAppsSortOrder;
};

export type TOAuthAppsCreateConnectorRequest = components['schemas']['media_dto_ConnectorRequest'];
export type TOAuthAppsUpdateConnectorRequest = components['schemas']['media_dto_UpdateConnectorRequest'];
export type TOrganizationSettingsRequestBody = components['schemas']['organization_dto_UpdateOrganizationSettings'];
export const WRITER_OAUTH_APP_TYPES = {
  OWN: 'false',
  MANAGED: 'true',
};
