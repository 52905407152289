import { openNewTab } from '@writercolab/common-utils';
import { preferenceDeletesGivenAsset } from '@writercolab/data-retention-utils';
import { TAssetToDeleteType, TDeletionOption } from '@writercolab/types';

import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';

import { useAppState } from '../state';

export const useDataRetentionBanner = ({ asset }: { asset: TAssetToDeleteType }) => {
  const { appModel, appState } = useAppState();
  const dataRetentionDays = appModel.dataRetentionModel?.retentionPreferences?.retentionDays;
  const deletesAsset = preferenceDeletesGivenAsset(asset, appModel.dataRetentionModel?.retentionPreferences);
  const shouldShowPolicyBanner = deletesAsset && !!dataRetentionDays;
  const onLearnMoreClick = () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL);

  return {
    shouldShowPolicyBanner,
    retentionDays: TDeletionOption.get(String(dataRetentionDays), '0'),
    asset,
    onLearnMoreClick,
    organizationName: appState.organization?.name,
  };
};
