export enum SharedOnboardingRoutes {
  Login = '/login',
  ResetPassword = '/reset-password',
  Saml = '/saml',
  Register = '/register',
  Verify = '/verify',
  JoinOrg = '/register/join-org',
  JoinCreateOrg = '/register/join-create-org',
  NewOrg = '/register/new-org',
  PendingApproval = '/register/pending',
  AppAccess = '/app-access',
  Onboarding = '/onboarding',
  OnboardingQuestion = '/onboarding/question',
  OnboardingWelcome = '/onboarding/welcome',
  OnboardingWriterApp = '/onboarding/writer-app',
  OnboardingAIStudio = '/onboarding/ai-studio',
  OnboardingQuestionRole = '/onboarding/question/role',
  OnboardingQuestionIndustry = '/onboarding/question/industry',
  OnboardingQuestionPosition = '/onboarding/question/position',
  OnboardingAIStudioAbout = '/onboarding/ai-studio/about',
  OnboardingAIStudioAboutExperience = '/onboarding/ai-studio/about/experience',
  OnboardingAIStudioAboutPurpose = '/onboarding/ai-studio/about/purpose',
  OnboardingAIStudioAboutIdea = '/onboarding/ai-studio/about/idea',
  OnboardingAIStudioAboutUseCase = '/onboarding/ai-studio/about/use-case',
  OnboardingAIStudioAboutUseCompanyDetails = '/onboarding/ai-studio/about/company-details',
}
