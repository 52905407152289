import type { RefObject } from 'react';
import { createRoot } from 'react-dom/client';
import { autorun } from 'mobx';
import isEmpty from 'lodash/isEmpty';
import type { Sources, RangeStatic } from 'quill';
import Quill, { Module } from 'quill';
import type Delta from 'quill-delta';
import type QuillCursors from 'quill-cursors';

import type { RequestServiceInitialize } from '@writercolab/network';
import { ApiStreamError } from '@writercolab/errors';

import { QA_COMMANDS_FORMAT_NAME, QA_COMMANDS_LOADING_FORMAT_NAME, QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import { QUILL_SELECTION_CHANGE_EVENT, QUILL_TEXT_CHANGE_EVENT } from '@writercolab/react-quill';
import type { IBaseSidebarConfig } from '@writercolab/common-utils';
import { getWords, NUMBER_OF_WORDS_FOR_AUTO_WRITE } from '@writercolab/common-utils';
import {
  Commands,
  type UICommandsModel,
  type CommandsModel,
  Rewriter,
  UIRewriterModel,
  COMMAND_TYPE,
  MAX_SELECTED_WORDS,
  MIN_SELECTED_WORDS,
  REWRITE_MODIFY_TONE_MENU_ITEM_SEPARATOR,
} from '@writercolab/ui-commands';
import { Logo, LogoVariant } from '@writercolab/ui-atoms';

import { AnalyticsService } from '@writercolab/analytics';
import { E_INTEGRATION_TYPE } from '@writercolab/types';
import { type IWebAppAnalyticsTrack, AnalyticsActivity } from '../analytics';
import {
  COMMANDS_SCENARIOS,
  generateKnowledgeGraphSourceUrl,
  getCommandsScenario,
  getErrorMessageFromResponse,
  removeModifyTonePrefix,
  trackCommandsActivity,
} from '../utils/commands';
import { getLogger } from '../utils/logger';

import styles from '../components/EditorPure/styles.module.css';

const LOG = getLogger('commandsModule');

enum DIMENSIONS {
  QUILL_PADDING = 40,
  QUILL_LINE_HEIGHT = 34,
  FOLLOWUP_MENU_WIDTH = 272,
  LOGO_HEIGHT = 30,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  LOGO_WIDTH = 30,
  LOGO_MARGIN_TOP = 3,
  LOGO_MARGIN_LEFT = 4,
}

interface IEnteredCommand {
  prompt?: string;
  content?: string;
  commandId?: string;
}

interface ICommandModuleOptions {
  config: IBaseSidebarConfig;
  editorWrapperRef: RefObject<HTMLDivElement>;
  requestService: RequestServiceInitialize['api'];
  trigger: string;
  model: CommandsModel;
  onError: (error?: string) => void;
  onSuccess: (message: string) => void;
  onContactSales: () => void;
  onLimitReached: () => void;
  onClickAutoWrite: (callback?: () => void) => void;
  limit: string;
  isLockedByLimit: boolean;
  isLocked: boolean;
  onUpgradeToTeam: () => void;
  isAskKnowledgeGraphEnabled: boolean;
  isKeepWritingEnabled: boolean;
  isRewriteEnabled: boolean;
  isFree: boolean;
  isVoiceEnabled: boolean;
}

export class CommandsModule extends Module {
  container: HTMLElement = document.createElement('div');
  root = createRoot(this.container);
  isOpen = true;
  cursorPosition = 0;
  originalColor: string | false = false;
  isStreaming = false;
  streamStartPosition = 0;
  streamAbortController: AbortController | null = null;
  isLogoVisible = false;
  isModelLoaded = false;
  logoTimeoutId: ReturnType<typeof setTimeout> | null = null;
  triggerBlotPosition: number | null = null;
  loadingBlotPosition: number | null = null;
  emptyLinesAdded = 0;
  isRewriting = false;
  lastEnteredCommand: IEnteredCommand = {};
  cursorModule: QuillCursors;
  analytics: IWebAppAnalyticsTrack;
  isMouseDown = false;

  constructor(
    quill: Quill,
    private opts: ICommandModuleOptions,
  ) {
    super(quill, opts);
    this.analytics = new AnalyticsService(opts.requestService, E_INTEGRATION_TYPE.enum.DEFAULT);
    this.cursorModule = this.quill.getModule('cursors') as QuillCursors;
    this.container.style.position = 'absolute';
    this.container.style.userSelect = 'none';
    this.quill.container.appendChild(this.container);

    this.container.addEventListener('mousedown', (e: MouseEvent) => {
      if (this.quill.getSelection()?.length) {
        e.preventDefault();
        e.stopPropagation();
      }
    });

    this.container.addEventListener('click', (event: MouseEvent) => {
      event.stopPropagation();

      if (event.target === this.container) {
        this.hideCommands();
      }
    });

    // Definitely a hack, need it until EventEmitter will be available
    window.addEventListener('mouseup', event => {
      this.isMouseDown = false;

      if (!this.opts.editorWrapperRef.current?.contains(event.target as HTMLElement)) {
        if (this.isLogoVisible) {
          this.hideLogo();
        } else {
          this.hideCommands();
        }
      }
    });
    window.addEventListener('mousedown', _ => {
      this.isMouseDown = true;
    });

    this.hideCommands();

    autorun(() => {
      this.isModelLoaded = this.opts.model.isLoaded;
    });

    quill.on(QUILL_TEXT_CHANGE_EVENT, (_delta: Delta, _oldContents: Delta, source: Sources) =>
      this.onChangeText(source),
    );
    quill.on(QUILL_SELECTION_CHANGE_EVENT, (range: RangeStatic, _oldRange: RangeStatic, source: Sources) => {
      if (quill.isEnabled()) {
        this.onChangeSelection(range, source);
      }
    });
    quill.keyboard.addBinding(
      {
        key: 27, // Esc
      },
      () => {
        if (this.streamAbortController) {
          this.streamAbortController.abort();
          this.streamAbortController = null;
          this.removeLoadingBlot();
          this.cleanupAfterStreaming();
        }

        this.hideCommands();

        return true;
      },
    );

    new ResizeObserver(entries => {
      window.requestAnimationFrame(() => {
        const first = Array.isArray(entries) && entries.length ? entries[0] : null;

        if (!first) {
          return;
        }

        this.removeEmptyLines();
        const containerHeight = (first.target as HTMLElement).offsetHeight;

        // Add few empty lines if CommandsMenu or Rewriter can't fit in the editor
        if (containerHeight > 0) {
          const isLogoContainer = containerHeight === DIMENSIONS.LOGO_HEIGHT;
          const selection = this.quill.getSelection();
          const bounds = this.quill.getBounds({
            index: selection?.length ? selection.index + selection.length : this.cursorPosition,
            length: 0,
          });

          if (!bounds) {
            return;
          }

          const heightOverflow =
            this.quill.root.parentElement!.offsetHeight -
            bounds.top -
            bounds.height -
            containerHeight -
            DIMENSIONS.QUILL_PADDING;

          if (heightOverflow < 0) {
            this.emptyLinesAdded = Math.ceil(containerHeight / DIMENSIONS.QUILL_LINE_HEIGHT);
            this.quill.insertText(this.quill.getLength(), '\n'.repeat(this.emptyLinesAdded), Quill.sources.SILENT);
            this.quill.root.scrollBy(0, Math.abs(heightOverflow));
          }

          const updatedBounds = this.quill.getBounds({
            index: selection?.length ? selection.index + selection.length - 1 : this.cursorPosition,
            length: 0,
          });

          if (!updatedBounds) {
            return;
          }

          this.container.style.top = `${
            isLogoContainer
              ? updatedBounds.bottom - DIMENSIONS.LOGO_HEIGHT + DIMENSIONS.LOGO_MARGIN_TOP
              : updatedBounds.bottom
          }px`;
        }
      });
    }).observe(this.container);
  }

  private renderRewriter(optionId: string, selectedText: string): void {
    this.container.style.width = '100%';

    const uiModel = new UIRewriterModel({
      request: this.opts.model.request,
      organizationId: this.opts.config.organizationId,
      teamId: this.opts.config.workspaceId,
      documentId: this.opts.config.documentId,
      prompts: removeModifyTonePrefix(this.opts.model.rewritePrompts, REWRITE_MODIFY_TONE_MENU_ITEM_SEPARATOR),
      voices: this.opts.isFree ? [] : this.opts.model.voices,
      selectedOptionId: optionId,
      originalText: selectedText,
    });

    this.root = createRoot(this.container);
    this.root.render(
      <Rewriter
        model={uiModel}
        onClose={() => this.hideCommands()}
        onClickReplace={(text: string) => {
          this.replaceSelectedText(text);
          this.hideCommands();
          trackCommandsActivity({
            analytics: this.analytics,
            teamId: this.opts.config.workspaceId,
            eventName: AnalyticsActivity.commandsReplacedTextWithRewriteSuggestion,
            scenario: COMMANDS_SCENARIOS.FOLLOW_UP_MENU,
            commandName: this.opts.model.rewritePrompts.find(p => p.id === uiModel.selectedOptionId)?.name,
          });
        }}
        onClickCopy={(text: string) => {
          navigator.clipboard.writeText(text);
          this.opts.onSuccess('Rewritten text copied to clipboard');
          trackCommandsActivity({
            analytics: this.analytics,
            teamId: this.opts.config.workspaceId,
            eventName: AnalyticsActivity.commandsCopiedRewriteSuggestion,
            scenario: COMMANDS_SCENARIOS.FOLLOW_UP_MENU,
            commandName: this.opts.model.rewritePrompts.find(p => p.id === uiModel.selectedOptionId)?.name,
          });
        }}
        onClickShowMore={() => {
          trackCommandsActivity({
            analytics: this.analytics,
            teamId: this.opts.config.workspaceId,
            eventName: AnalyticsActivity.commandsClickedShowMoreRewriteSuggestions,
            scenario: COMMANDS_SCENARIOS.FOLLOW_UP_MENU,
            commandName: this.opts.model.rewritePrompts.find(p => p.id === uiModel.selectedOptionId)?.name,
          });
        }}
      />,
    );
  }

  private renderCommands(isAfterStreaming: boolean): void {
    this.root = createRoot(this.container);
    this.root.render(
      <Commands
        model={this.opts.model}
        isInputVisible={!this.quill.getSelection()?.length}
        isKeepWritingEnabled={
          this.opts.isKeepWritingEnabled && getWords(this.quill.getText(0)).length >= NUMBER_OF_WORDS_FOR_AUTO_WRITE
        }
        isRewriteEnabled={this.opts.isRewriteEnabled}
        rewriteSelectionLimit={this.getSelectedWordsCount() >= MAX_SELECTED_WORDS ? MAX_SELECTED_WORDS : null}
        isAskKnowledgeGraphEnabled={this.opts.isAskKnowledgeGraphEnabled && !isEmpty(this.opts.model.knowledgeFiles)}
        isStreamingCommandsEnabled={isAfterStreaming}
        onMenuItemClick={(id: string, uiModel: UICommandsModel) => this.onMenuItemClick(id, uiModel)}
        limit={this.opts.limit}
        isLockedByLimit={this.opts.isLockedByLimit}
        isLocked={this.opts.isLocked}
        isFree={this.opts.isFree}
        onUpgradeToTeam={() => this.opts.onUpgradeToTeam()}
        onExecuteCommand={(command: string) => this.onExecuteCommand(command)}
        onEscape={() => this.hideCommands()}
        onContactSales={() => this.opts.onContactSales()}
        onSwitchHistory={(replacement: string) => {
          this.replaceSelectedText(replacement);
        }}
        onClickSwitch={() => {
          trackCommandsActivity({
            analytics: this.analytics,
            teamId: this.opts.config.workspaceId,
            eventName: AnalyticsActivity.commandsNavigatedTheArrows,
            scenario: COMMANDS_SCENARIOS.FOLLOW_UP_MENU,
          });
        }}
      />,
    );
  }

  private renderButtonOverSelectedText(): void {
    this.root = createRoot(this.container);
    this.root.render(
      <div
        onMouseDown={e => {
          e.preventDefault();
          e.stopPropagation();
          this.isLogoVisible = false;
          this.showCommandsAfterSelection(false);
        }}
      >
        <Logo className={styles.rewriteLogo} variant={LogoVariant.BLACK} />
      </div>,
    );
  }

  private onMenuItemClick(id: string, uiModel: UICommandsModel): void {
    const selection = this.quill.getSelection();

    /* eslint-disable no-case-declarations */
    switch (id) {
      case COMMAND_TYPE.KEEP_WRITING:
        trackCommandsActivity({
          analytics: this.analytics,
          teamId: this.opts.config.workspaceId,
          eventName: AnalyticsActivity.commandsClickedKeepWriting,
          scenario: getCommandsScenario(0, true),
        });
        this.hideCommands();

        if (selection) {
          this.quill.setSelection(
            {
              index: selection.index + selection.length,
              length: 0,
            },
            Quill.sources.SILENT,
          );
        }

        this.opts.onClickAutoWrite(() => {
          this.showCommandsAfterSelection(false);
        });
        break;

      case COMMAND_TYPE.REGENERATE:
        if (selection) {
          trackCommandsActivity({
            analytics: this.analytics,
            teamId: this.opts.config.workspaceId,
            eventName: AnalyticsActivity.commandsClickedRegenerate,
            scenario: COMMANDS_SCENARIOS.FOLLOW_UP_MENU,
          });
          this.isRewriting = true;
          this.container.style.display = 'none';

          if (isEmpty(uiModel.rewriteHistory)) {
            uiModel.rewriteHistoryPush(
              this.quill.getText({
                index: selection.index,
                length: selection.length,
              }),
            );
          }

          this.quill.deleteText(selection.index, selection.length, Quill.sources.API);
          this.cursorPosition = selection.index;
          this.streamStartPosition = this.cursorPosition;
          this.addLoadingBlot(this.cursorPosition);
          this.requestContentAsStream(this.lastEnteredCommand, uiModel);
        }

        break;

      case COMMAND_TYPE.DONE:
        this.isRewriting = false;
        this.hideCommands();
        break;

      case COMMAND_TYPE.ASK_KNOWLEDGE_GRAPH:
        this.opts.model.setWidgetValue('Q: ');
        this.container.getElementsByTagName('textarea')[0]?.focus();
        break;

      default:
        const command = this.opts.model.filteredCommands.find(command => command.id === id);
        const rewritePrompt = this.opts.model.rewritePrompts.find(prompt => prompt.id === id);
        const voice = this.opts.model.voices.find(voice => voice.id === id);
        const userPrompt = this.opts.model.filteredUserPrompts.find(prompt => id.includes(String(prompt.id)));

        if (command) {
          if (command.type === COMMAND_TYPE.GENERATE_FROM_CONTENT && selection?.length) {
            trackCommandsActivity({
              analytics: this.analytics,
              teamId: this.opts.config.workspaceId,
              eventName: AnalyticsActivity.commandsGeneratedFromText,
              scenario: getCommandsScenario(this.quill.getText(0).length, true),
              commandName: command.name,
            });
            this.generateFromContent(command.id);
          } else {
            this.opts.model.setWidgetValue(`${command.prefix} `);
            this.container.getElementsByTagName('textarea')[0]?.focus();
          }
        } else if (rewritePrompt?.id && selection) {
          const selectedText = this.quill.getText({
            index: selection.index,
            length: selection.length,
          });
          this.renderRewriter(rewritePrompt.id, selectedText);
          trackCommandsActivity({
            analytics: this.analytics,
            teamId: this.opts.config.workspaceId,
            eventName: AnalyticsActivity.commandsClickedRewrite,
            scenario: getCommandsScenario(this.quill.getText(0).length, true),
            commandName: this.opts.model.rewritePrompts.find(p => p.id === rewritePrompt.id)?.name,
          });
        } else if (voice?.id && selection) {
          const selectedText = this.quill.getText({
            index: selection.index,
            length: selection.length,
          });
          this.renderRewriter(voice.id, selectedText);
        } else if (userPrompt) {
          this.opts.model.setWidgetValue(`${userPrompt.prompt} `);
          this.container.getElementsByTagName('textarea')[0]?.focus();
        }

        break;
    }
    /* eslint-enable no-case-declarations */
  }

  private generateFromContent(commandId: string): void {
    const selection = this.quill.getSelection();

    if (selection) {
      const content = this.quill.getText({
        index: selection.index,
        length: selection.length,
      });
      this.lastEnteredCommand = {
        content,
        commandId,
      };
      this.cursorPosition = selection.index + selection.length;
      this.quill.insertText(this.cursorPosition, '\n\n', Quill.sources.USER);
      this.cursorPosition++;
      this.streamStartPosition = this.cursorPosition;
      this.addLoadingBlot(this.cursorPosition);
      this.requestContentAsStream({ content, commandId });
      this.hideCommands();
    }
  }

  private insertText(text: string): void {
    const cursorId = 'commands-cursor';
    this.cursorModule.createCursor(cursorId, 'Commands', '#9B51E0');
    this.quill.insertText(this.cursorPosition, text, Quill.sources.USER);
    this.quill.formatText({ index: this.cursorPosition, length: text.length }, QUILL_FORMAT.COLOR, '#828282');
    this.cursorPosition += text.length;
    this.cursorModule.moveCursor(cursorId, {
      index: this.cursorPosition,
      length: 0,
    });
    this.quill.setSelection({ index: this.cursorPosition, length: 0 }, Quill.sources.SILENT);
  }

  private replaceSelectedText(replacement: string): void {
    const selection = this.quill.getSelection();

    if (selection) {
      this.isRewriting = true;
      this.cursorPosition = selection.index;
      this.quill.insertText(this.cursorPosition, replacement, Quill.sources.USER);
      this.quill.deleteText(this.cursorPosition + replacement.length, selection.length, Quill.sources.USER);
      this.quill.setSelection(this.cursorPosition, replacement.length, Quill.sources.USER);
      this.isRewriting = false;
    }
  }

  private alignWithSelection(): void {
    const selection = this.quill.getSelection();

    if (selection?.length) {
      const bounds = this.quill.getBounds({
        index: selection!.index + selection!.length,
        length: 0,
      });

      if (!bounds) {
        return;
      }

      if (this.container.offsetHeight === DIMENSIONS.LOGO_HEIGHT) {
        this.container.style.top = `${bounds.bottom - DIMENSIONS.LOGO_HEIGHT + DIMENSIONS.LOGO_MARGIN_TOP}px`;
      } else {
        this.container.style.top = `${bounds.bottom}px`;
      }
    }
  }

  private onExecuteCommand(command: string): void {
    this.hideCommands(true);

    const knowledgeGraphPrefix = 'Q:';
    const trimmedCommand = command.trim();

    if (!trimmedCommand.replace(knowledgeGraphPrefix, '').length) {
      return;
    }

    this.streamStartPosition = this.cursorPosition;
    this.addLoadingBlot(this.cursorPosition);
    this.lastEnteredCommand = {
      prompt: trimmedCommand,
    };

    if (
      this.opts.isAskKnowledgeGraphEnabled &&
      trimmedCommand.startsWith(knowledgeGraphPrefix) &&
      !isEmpty(this.opts.model.knowledgeFiles)
    ) {
      trackCommandsActivity({
        analytics: this.analytics,
        teamId: this.opts.config.workspaceId,
        eventName: AnalyticsActivity.commandsEnteredAskKnowledgeGraph,
        scenario: getCommandsScenario(this.quill.getText(0).length),
      });
      this.requestAskKnowledgeGraph(command, knowledgeGraphPrefix);
    } else {
      const commandEntered = this.opts.model.filteredCommands.find(c => c.prefix === trimmedCommand);

      if (commandEntered) {
        trackCommandsActivity({
          analytics: this.analytics,
          teamId: this.opts.config.workspaceId,
          eventName: AnalyticsActivity.commandsGeneratedNew,
          scenario: getCommandsScenario(this.quill.getText(0).length),
          commandName: commandEntered.name,
        });
      } else {
        trackCommandsActivity({
          analytics: this.analytics,
          teamId: this.opts.config.workspaceId,
          eventName: AnalyticsActivity.commandsEntered,
          scenario: getCommandsScenario(this.quill.getText(0).length),
        });
      }

      this.requestContentAsStream({ prompt: trimmedCommand });
    }
  }

  private insertAskKnowledgeGraphAnwser(
    question: string,
    answer: string,
    references: { fileId: string; fileName: string }[],
  ): void {
    const title = `${question}\n\nAnswer: ${answer}`;
    this.quill.insertText(this.cursorPosition, title, Quill.sources.USER);
    this.cursorPosition += title.length;

    if (!isEmpty(references)) {
      const caption = '\n\nContributing sources:\n';
      this.quill.insertText(this.cursorPosition, caption, Quill.sources.USER);
      this.cursorPosition += caption.length;

      references.forEach(ref => {
        this.quill.insertText(
          this.cursorPosition,
          `${ref.fileName}\n`,
          QUILL_FORMAT.LINK,
          generateKnowledgeGraphSourceUrl(
            this.opts.config.appRoot,
            this.opts.config.organizationId,
            this.opts.config.workspaceId,
            ref.fileId,
          ),
          Quill.sources.USER,
        );
      });
    }
  }

  private cleanupAfterStreaming(): void {
    this.opts.model.$userPrompts.reload();
    this.isStreaming = false;
    this.quill.formatText(
      this.streamStartPosition,
      this.cursorPosition - this.streamStartPosition,
      QUILL_FORMAT.COLOR,
      this.originalColor,
      Quill.sources.USER,
    );
    this.quill.setSelection(this.streamStartPosition, this.cursorPosition - this.streamStartPosition);
    this.showCommandsAfterSelection(true);
    this.streamStartPosition = 0;
    this.cursorModule.clearCursors();
  }

  private cleanupAfterRewriting(uiModel: UICommandsModel): void {
    this.isStreaming = false;
    this.quill.formatText(
      this.streamStartPosition,
      this.cursorPosition - this.streamStartPosition,
      QUILL_FORMAT.COLOR,
      this.originalColor,
      Quill.sources.USER,
    );
    this.quill.setSelection(this.streamStartPosition, this.cursorPosition - this.streamStartPosition);
    uiModel.rewriteHistoryPush(
      this.quill.getText({
        index: this.streamStartPosition,
        length: this.cursorPosition - this.streamStartPosition,
      }),
    );
    this.streamStartPosition = 0;
    this.cursorModule.clearCursors();
    this.container.style.display = 'block';
  }

  private showCommands(): void {
    if (!this.isOpen) {
      this.cursorPosition = Math.max(0, this.cursorPosition - this.opts.trigger.length);
      this.quill.deleteText(this.cursorPosition, this.opts.trigger.length, Quill.sources.USER);
      this.addTriggerBlot(this.cursorPosition);
      this.cursorPosition++;

      if (this.quill.getLeaf(this.cursorPosition)[0]?.domNode as HTMLElement) {
        this.renderCommands(false);
        this.container.style.left = '0';
        this.container.style.width = '100%';
        this.container.style.opacity = '1';
        this.quill.root.style.overflowY = 'hidden';
        this.isOpen = true;
        trackCommandsActivity({
          analytics: this.analytics,
          teamId: this.opts.config.workspaceId,
          eventName: AnalyticsActivity.commandsOpened,
          scenario: getCommandsScenario(this.quill.getText(0).length),
        });

        if (!this.opts.isLockedByLimit) {
          this.quill.enable(false);
          this.container.getElementsByTagName('textarea')[0]?.focus();
        }
      }
    }
  }

  private showCommandsAfterSelection(isAfterStreaming: boolean): void {
    if (!this.isOpen) {
      this.renderCommands(isAfterStreaming);
      this.container.style.left = '0';
      this.container.style.width = `${DIMENSIONS.FOLLOWUP_MENU_WIDTH}px`;
      this.container.style.opacity = '1';
      this.isOpen = true;
      this.quill.root.addEventListener('scroll', () => this.alignWithSelection());
      trackCommandsActivity({
        analytics: this.analytics,
        teamId: this.opts.config.workspaceId,
        eventName: AnalyticsActivity.commandsOpened,
        scenario: getCommandsScenario(0, !isAfterStreaming, isAfterStreaming),
      });
    }
  }

  private hideCommands(isGenerating?: boolean): void {
    if (this.isOpen && !this.isRewriting) {
      const { scrollTop } = this.quill.root;
      this.root.unmount();
      this.quill.root.removeEventListener('scroll', () => this.alignWithSelection());
      this.quill.root.style.overflowY = '';
      this.container.style.top = '-9999px';
      this.container.style.left = '0';
      this.container.style.width = '100%';
      this.container.style.opacity = '0';
      this.quill.enable(true);
      this.removeTriggerBlot();
      this.opts.model.setWidgetValue('');
      this.isOpen = false;

      if (!isGenerating) {
        this.quill.setSelection({ index: this.cursorPosition, length: 0 }, Quill.sources.SILENT);
      }

      this.quill.root.scrollTo(0, scrollTop);
    }
  }

  private showLogo(selectionRange: RangeStatic): void {
    const { index, length } = selectionRange;
    const selectedWords = getWords(this.quill.getText({ index, length }));

    if (selectedWords.length >= MIN_SELECTED_WORDS && selectedWords.length <= 1000) {
      const selectionEndBounds = this.quill.getBounds({
        index: index + length - 1,
        length: 0,
      });

      if (!selectionEndBounds) {
        return;
      }

      this.container.style.left = `${
        selectionEndBounds.left - DIMENSIONS.LOGO_WIDTH > 0 ? selectionEndBounds.left - DIMENSIONS.LOGO_MARGIN_LEFT : 5
      }px`;
      this.container.style.width = `${DIMENSIONS.LOGO_WIDTH}px`;
      this.container.style.opacity = '1';
      this.isLogoVisible = true;
      this.renderButtonOverSelectedText();
      this.quill.root.addEventListener('scroll', () => this.alignWithSelection());
    }
  }

  private hideLogo(): void {
    this.root.unmount();
    this.quill.root.removeEventListener('scroll', () => this.alignWithSelection());
    this.isLogoVisible = false;
    this.container.style.top = '-9999px';
    this.container.style.left = '0';
    this.container.style.width = '100%';
    this.container.style.opacity = '0';
  }

  private addLoadingBlot(position: number): void {
    this.loadingBlotPosition = position;
    this.quill.insertEmbed(position, QA_COMMANDS_LOADING_FORMAT_NAME, this.opts.trigger, Quill.sources.SILENT);
  }

  private removeLoadingBlot(): void {
    if (this.loadingBlotPosition !== null) {
      this.quill.deleteText(this.loadingBlotPosition, 1, Quill.sources.SILENT);
      this.loadingBlotPosition = null;
    }
  }

  private addTriggerBlot(position: number): void {
    this.triggerBlotPosition = position;
    this.quill.insertEmbed(position, QA_COMMANDS_FORMAT_NAME, this.opts.trigger, Quill.sources.SILENT);
  }

  private removeTriggerBlot(): void {
    if (this.triggerBlotPosition !== null) {
      this.quill.deleteText(this.triggerBlotPosition, 1, Quill.sources.SILENT);
      this.triggerBlotPosition = null;
      this.cursorPosition--;
    }
  }

  private removeEmptyLines(): void {
    if (this.emptyLinesAdded) {
      this.quill.deleteText(this.quill.getLength() - this.emptyLinesAdded, this.emptyLinesAdded, Quill.sources.SILENT);
      this.emptyLinesAdded = 0;
    }
  }

  private getSelectedWordsCount(): number {
    let selectedWordsCount = 0;
    const selection = this.quill.getSelection();

    if (selection && selection.length > 0) {
      const selectedText = this.quill.getText({
        index: selection.index,
        length: selection.length,
      });
      selectedWordsCount = getWords(selectedText).length;
    }

    return selectedWordsCount;
  }

  private onChangeText(source: Sources): void {
    if (source === Quill.sources.USER) {
      if (this.isLogoVisible) {
        this.hideLogo();
      } else if (this.isOpen) {
        this.hideCommands();
      }

      const rangeIndex = this.quill.getSelection()?.index;

      if (!this.isStreaming && rangeIndex) {
        this.cursorPosition = rangeIndex;
        const textBeforeCursor = this.quill.getText({
          index: Math.max(0, this.cursorPosition - this.opts.trigger.length),
          length: this.opts.trigger.length,
        });

        if (this.opts.trigger === textBeforeCursor) {
          this.showCommands();
        }
      }
    }
  }

  private onChangeSelection(range: RangeStatic, source: Sources): void {
    if (source === Quill.sources.USER && range !== null && !this.isStreaming) {
      if (this.isLogoVisible) {
        this.hideLogo();
      }

      const { index, length } = range;
      this.cursorPosition = length ? length + index : index;
      const isWithinTrigger =
        index === this.cursorPosition || this.cursorPosition - this.opts.trigger.length + 1 === index;

      if (
        (!isWithinTrigger && !length) ||
        (this.quill.getLeaf(this.cursorPosition)[0]?.domNode as HTMLElement).innerText !== this.opts.trigger ||
        (index === 1 && this.quill.getLength() === 2)
      ) {
        this.hideCommands();
      }

      // Show clickable <Logo /> when user selects enough text
      if (length && !this.isLogoVisible && !this.isRewriting) {
        if (this.logoTimeoutId) {
          clearTimeout(this.logoTimeoutId);
        }

        this.logoTimeoutId = setTimeout(() => {
          if (this.quill.getSelection()?.length && !this.isMouseDown) {
            this.showLogo(range);
          }
        }, 200);
      }
    }
  }

  private requestAskKnowledgeGraph = (question: string, prefix: string) => {
    this.opts.requestService
      .post('/api/ask/organization/{organizationId}/team/{teamId}/knowledge', {
        params: {
          path: {
            organizationId: Number(this.opts.config.organizationId),
            teamId: Number(this.opts.config.workspaceId),
          },
        },
        body: {
          question: question.slice(prefix.length).trim(),
        },
      })
      .then(response => {
        this.removeLoadingBlot();
        this.insertAskKnowledgeGraphAnwser(question.trim(), response.data.answer, response.data.references!);
      })
      .catch(error => {
        this.removeLoadingBlot();

        if (error.response.status === 500) {
          this.opts.onError('Something went wrong. Please try again.');
        } else {
          this.opts.onError(getErrorMessageFromResponse(error.error));
        }
      });
  };

  private requestContentAsStream = async (
    { prompt, content, commandId }: IEnteredCommand,
    uiModel?: UICommandsModel,
  ) => {
    this.streamAbortController = new AbortController();
    const streamIterator = this.opts.model.request.streamIterPost(
      '/api/generation/organization/{organizationId}/team/{teamId}/command/generate/stream',
      {
        body: {
          prompt,
          content,
          commandId,
          suffix: null,
        },
        params: {
          path: {
            organizationId: Number(this.opts.config.organizationId),
            teamId: Number(this.opts.config.workspaceId),
          },
        },
        credentials: 'include',
        openWhenHidden: true,
        signal: this.streamAbortController.signal,
      },
    );

    try {
      this.isStreaming = true;
      this.originalColor = (this.quill.getFormat(this.cursorPosition + 1, 1)[QUILL_FORMAT.COLOR] as string) || false;

      for await (const ev of streamIterator) {
        this.removeLoadingBlot();
        const { suggestion } = JSON.parse(ev.data);
        this.insertText(suggestion);
      }
      this.removeLoadingBlot();

      if (uiModel) {
        this.cleanupAfterRewriting(uiModel);
      } else {
        this.cleanupAfterStreaming();
      }
    } catch (error: unknown) {
      this.removeLoadingBlot();
      this.isStreaming = false;

      // Handling manual AbortController.abort() call

       
      if (!(error as any).response) {
        return;
      } else if (error instanceof ApiStreamError) {
        const { status } = error.response;

        if (status === 403) {
          this.opts.onLimitReached();
        } else if (status >= 400 && status < 500) {
          this.opts.onError(getErrorMessageFromResponse(error.json));
        } else {
          this.opts.onError();
        }
      } else {
        this.opts.onError();
      }

      LOG.warn('Commands: requestContentAsStream error', error);
    }
  };
}
