import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export const TLearningCenterCategoryId = new Enum(
  'videos',
  'support',
  'new',
  'webinars',
  'guides',
  'prompts',
  'writeracademy',
  'docs',
);

export const TLearningCenterVideoId = new Enum(
  'ask-writer',
  'blog-builder',
  'recaps',
  'writer-ai',
  '5-things',
  'prompt-crafting',
);

export const TLearningCenterCanduId = new Enum(
  'CANDU_LEARNING_CENTER_VIDEOS',
  'CANDU_LEARNING_CENTER_WEBINARS_UPCOMING',
  'CANDU_LEARNING_CENTER_WEBINARS_PREVIOUS',
);

export const TVideoTag = new Enum('onboarding', 'cowrite');

export type ILearningCenterWebinar = components['schemas']['organization_model_Webinar'];
