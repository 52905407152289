import type React from 'react';

import { Icon, IconVariant } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { AppTypeBadge } from 'components/molecules/AppTypeBadge/AppTypeBadge';
import EllipsisTooltip from 'components/molecules/EllipsisTooltip/EllipsisTooltip';

import { type TApplicationBriefResponse } from '@web/types';
import { formatDistanceStrict } from 'date-fns';
import { getIconByAppType } from 'utils/applications';

import type { IBodyCellProps, IRowProps } from '../BaseTable/BaseTable';

import styles from './AiStudioHomeWidget.module.css';

export type TRecentAppsTableConfig = {
  id: typeof TRecentAppsColumnId.type;
};

export const TRecentAppsColumnId = new Enum('name', 'createdAt', 'type');

export const RecentAppsTableColumnsConfig: TRecentAppsTableConfig[] = [
  {
    id: TRecentAppsColumnId.enum.name,
  },
  {
    id: TRecentAppsColumnId.enum.createdAt,
  },
  {
    id: TRecentAppsColumnId.enum.type,
  },
];

const TitleCell: React.FC<{
  title: string;
  appType: TApplicationBriefResponse['type'];
  icon?: string | null;
}> = ({ title, icon, appType }) => (
  <div className={styles.titleContainer}>
    <Icon name={getIconByAppType(appType, icon) || IconVariant.CONSOLE_APP_LONG_FORM} width={30} height={30} />
    <EllipsisTooltip textClassName={styles.text} text={title} tooltipClassName="z-100" />
  </div>
);

export const generateRecentAppsTableBody = ({
  data,
}: {
  data?: TApplicationBriefResponse[];
}): IRowProps<TApplicationBriefResponse>[] => {
  if (!data) return [];

  return data.map(item => {
    const { id, name, type, icon, createdAt } = item;
    const generateTableCell = (cellType: string, component: React.ReactNode): IBodyCellProps => ({
      id: `cell-${cellType}-${id}`,
      component,
    });

    const tableCells = RecentAppsTableColumnsConfig.map(column =>
      TRecentAppsColumnId.match(
        column.id,
        {
          name: () =>
            generateTableCell(TRecentAppsColumnId.enum.name, <TitleCell title={name} icon={icon} appType={type} />),
          createdAt: () =>
            createdAt ? (
              generateTableCell(
                TRecentAppsColumnId.enum.createdAt,
                <p className="text-sm">{formatDistanceStrict(new Date(createdAt), new Date())} ago</p>,
              )
            ) : (
              <></>
            ),
          type: () => generateTableCell(TRecentAppsColumnId.enum.type, <AppTypeBadge type={type} />),
        },
        null,
      ),
    ).filter(Boolean) as IBodyCellProps[];

    const row: IRowProps<any> = {
      id: `row-${id}`,
      data: item,
      cells: tableCells,
      className: styles.row,
    };

    return row;
  });
};
