import type { TFeatureFlags } from '@web/types';

declare global {
  interface Window {
    APP_ROOT: string;
    API_URL: string;
    ANGULAR_REDIRECT: string;
    FIREBASE_DB_ID: string;
    FIREBASE_PROJECT_ID: string;
    FIREBASE_API_KEY: string;
    FIREBASE_MESSAGING_SENDER_ID: string;
    FIREBASE_APP_ID: string;
    RECAPTCHA_ENTEPRISE_KEY: string;
    APP_PLAYGROUND_URL: string;
    APP_EMBED_URL: string;
    SINGLE_TENANCY_CONFIG?: {
      disableCandu: boolean;
      disableDatadog: boolean;
      disableFirebase: boolean;
      disableChameleon: boolean;
      disableWriterAcademy: boolean;
      disableQuestionnaire: boolean;
      disablePortal: boolean;
      disableMagicLinks: boolean;
      disableDeploymentEmbed: boolean;
      disableDeploymentPlayground: boolean;
      offlineFeatureFlags?: TFeatureFlags;
      writerFrameworkDeployment: boolean;
    };
    REDIRECT_WHITELISTED_DOMAINS: string[];
  }
}

export const config = {
  APP_ROOT: window.APP_ROOT,
  API_URL: window.API_URL,
  ANGULAR_REDIRECT: window.ANGULAR_REDIRECT,
  FIREBASE_DB_ID: window.FIREBASE_DB_ID,
  FIREBASE_PROJECT_ID: window.FIREBASE_PROJECT_ID,
  FIREBASE_API_KEY: window.FIREBASE_API_KEY,
  FIREBASE_MESSAGING_SENDER_ID: window.FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: window.FIREBASE_APP_ID,
  RECAPTCHA_ENTEPRISE_KEY: window.RECAPTCHA_ENTEPRISE_KEY,
  APP_PLAYGROUND_URL: window.APP_PLAYGROUND_URL,
  APP_EMBED_URL: window.APP_EMBED_URL,
  SINGLE_TENANCY_CONFIG: window.SINGLE_TENANCY_CONFIG,
  REDIRECT_WHITELISTED_DOMAINS: window.REDIRECT_WHITELISTED_DOMAINS || [],
};

export default config;
