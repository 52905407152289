import React from 'react';

import cx from 'classnames';

import { DocumentHighlightType } from '@writercolab/common-utils';
import { Text, TextSize } from '@writercolab/ui-atoms';

import HighlightHeader from './HighlightHeader';

import styles from '../styles.module.css';

interface IHighlightErrorBodyProps {
  onReloadClick?: (type: DocumentHighlightType) => void;
  type?: DocumentHighlightType;
  onForceReload?: () => void;
}

export const HighlightErrorBody: React.FC<IHighlightErrorBodyProps> = ({ type, onReloadClick, onForceReload }) => {
  const _onReloadClick = () => {
    onReloadClick && type && onReloadClick(type);
    onForceReload && onForceReload();
  };

  return (
    <div className={styles.containerHighlight}>
      {type && <HighlightHeader type={type} />}
      <div className={styles.containerHighlightError}>
        <Text variant={TextSize.XXXL} className={styles.containerHighlightErrorText} medium>
          Oops, something went wrong
        </Text>
        <Text variant={TextSize.XXXL} className={styles.containerHighlightErrorText} medium>
          but let’s try that again.
        </Text>
        <Text
          variant={TextSize.XXXL}
          className={cx(styles.containerHighlightErrorText, styles.clickable)}
          medium
          underline
          onClick={_onReloadClick}
        >
          Click to regenerate
        </Text>
      </div>
    </div>
  );
};

export default HighlightErrorBody;
