import React from 'react';

import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import ExampleBlock from './generic/ExampleBlock';
import ExampleBlockHeader from './generic/ExampleBlockHeader';

import commonStyles from '../styles.module.css';

export const VocabularySimplificationExample: React.FC = () => (
  <div className={commonStyles.tooltipContent}>
    <ExampleBlockHeader>Example</ExampleBlockHeader>
    <ExampleBlock>
      <Text variant={TextSize.L} inline>
        We
      </Text>{' '}
      <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
        commenced
      </Text>{' '}
      <Text variant={TextSize.L} inline>
        the day by
      </Text>{' '}
      <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
        convening
      </Text>{' '}
      <Text variant={TextSize.L} inline>
        in the library.
      </Text>
    </ExampleBlock>
    <ExampleBlock>
      <Text variant={TextSize.L} inline>
        We
      </Text>{' '}
      <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
        started
      </Text>{' '}
      <Text variant={TextSize.L} inline>
        the day by
      </Text>{' '}
      <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
        meeting
      </Text>{' '}
      <Text variant={TextSize.L} inline>
        in the library.
      </Text>
    </ExampleBlock>
  </div>
);
