import React, { useMemo } from 'react';

import cx from 'classnames';

import {
  ISuggestionMetaRules,
  ISuggestionSection,
  SuggestionCategoryType,
  findSuggestionSection,
} from '@writercolab/common-utils';
import { IconVariant, Tooltip, TooltipAlignment, TooltipBackgroundColor } from '@writercolab/ui-atoms';
import {
  StructureSectionItem,
  StructureSectionType,
  StructureSubsectionCustom,
  StructureSubsectionDropdown,
  StructureSubsectionGroup,
  StructureSubsectionRule,
} from '@writercolab/ui-molecules';

import first from 'lodash/first';
import intersection from 'lodash/intersection';

import SuggestionCategory, { ISuggestionCategoryProps } from '../SuggestionCategory';
import SuggestionRule from '../SuggestionRule';
import SuggestionSimpleRule from '../SuggestionRule/SuggestionSimpleRule';
import SuggestionRuleGroup from '../SuggestionRuleGroup';

import commonStyles from '../styles.module.css';
import styles from './styles.module.css';

interface ISuggestionCategoryWithRulesProps extends ISuggestionCategoryProps {
  structure: StructureSectionItem[];
  updateSectionMeta: (sectionType: SuggestionCategoryType, meta: ISuggestionMetaRules) => void;
  borderedRules?: boolean;
  customRules?: Record<string, React.ReactNode>;
}

const SuggestionCategoryWithRules: React.FC<ISuggestionCategoryWithRulesProps> = ({
  readOnly,
  type,
  name,
  tag,
  description,
  structure,
  suggestionSettings,
  setSectionEnabled,
  updateSectionMeta,
  tooltipContent,
  halfLimitedWidth,
  borderedRules = true,
  hideBottomBorder,
  customRules,
}) => {
  const sectionData: ISuggestionSection<ISuggestionMetaRules> = useMemo(
    () => findSuggestionSection(type, suggestionSettings)!,
    [suggestionSettings, type],
  );
  const onUpdateSimpleRuleValue = (checked: boolean, value?: string) => {
    let rules = sectionData.meta.rules?.filter(rule => rule !== value) || [];

    if (checked && value) {
      rules = [...rules, value];
    }

    updateSectionMeta(type, { rules });
  };

  const onUpdateDropdownRuleValue = (newValue?: string, oldValue?: string) => {
    const {
      meta: { rules = [] },
    } = sectionData;
    const newRules = [...rules.filter(rule => rule !== oldValue), ...(newValue ? [newValue] : [])];
    updateSectionMeta(type, { rules: newRules });
  };

  const _renderSimpleRule = (item: StructureSubsectionRule) => {
    const isChecked = sectionData.meta.rules?.includes(item.ruleId);

    return (
      <Tooltip
        disabled={!item.tooltip}
        title={item.tooltip || ''}
        placement="right"
        tooltipBackgroundColor={TooltipBackgroundColor.WHITE}
        align={TooltipAlignment.LEFT}
      >
        <div className={cx({ [styles.highlightedTooltip]: item.highlighted })}>
          <SuggestionSimpleRule
            className={cx(commonStyles.inputRuleContainer, commonStyles.simpleRule, commonStyles.smallPadded)}
            value={item.ruleId}
            example={item.example}
            exampleLabel={item.exampleLabel}
            highlighted={item.highlighted}
            isChecked={isChecked}
            readOnly={!sectionData.enabled || readOnly}
            onUpdateRuleValue={onUpdateSimpleRuleValue}
          >
            {item.description}
          </SuggestionSimpleRule>
        </div>
      </Tooltip>
    );
  };

  const _renderDropdownRule = (item: StructureSubsectionDropdown) => {
    const possibleValues = Object.keys(item.valuesConfig);
    const currentValue = first(intersection(sectionData.meta.rules, possibleValues));

    return (
      <SuggestionRule
        className={cx(commonStyles.inputRuleContainer, commonStyles.simpleRule, commonStyles.smallPadded)}
        valuesConfig={item.valuesConfig}
        template={item.template}
        defaultValue={item.defaultValue}
        dropdownOptions={item.dropdownSources}
        readOnly={!sectionData.enabled || readOnly}
        currentValue={currentValue}
        onUpdateRuleValue={onUpdateDropdownRuleValue}
      />
    );
  };

  const _renderRules = (items: StructureSectionItem[]) =>
    items.map((item: StructureSectionItem) => {
      switch (item.type) {
        case StructureSectionType.GROUP:
          return _renderRulesGroup(item as StructureSubsectionGroup);
        case StructureSectionType.RULE:
          return _renderSimpleRule(item as StructureSubsectionRule);
        case StructureSectionType.DROPDOWNS:
          return _renderDropdownRule(item as StructureSubsectionDropdown);
        case StructureSectionType.CUSTOM:
          return customRules?.[(item as StructureSubsectionCustom).ruleId];

        default:
          return null;
      }
    });

  const _renderRulesGroup = (item: StructureSubsectionGroup) => (
    <>
      <SuggestionRuleGroup icon={item.icon as IconVariant} heading={item.heading} borderedRules={borderedRules}>
        {_renderRules(item.items)}
      </SuggestionRuleGroup>
    </>
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const children = useMemo(() => _renderRules(structure), [sectionData, type, customRules]);

  return (
    <SuggestionCategory
      readOnly={readOnly}
      type={type}
      name={name}
      tag={tag}
      description={description}
      tooltipContent={tooltipContent}
      halfLimitedWidth={halfLimitedWidth}
      hideBottomBorder={hideBottomBorder}
      setSectionEnabled={setSectionEnabled}
      suggestionSettings={suggestionSettings}
    >
      {children}
    </SuggestionCategory>
  );
};

export default SuggestionCategoryWithRules;
