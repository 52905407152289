export const KG_CONNECTOR_LABEL = [
  {
    id: 'notion',
    name: 'Notion',
  },
  {
    id: 'gdrive',
    name: 'Google Drive',
  },
  {
    id: 'sharepoint',
    name: 'SharePoint',
  },
];

export const getFolderName = (name: string, driveType: string, url: string): string => {
  if (name === 'root' && driveType === 'documentLibrary') {
    return url.split('/').pop() ?? name;
  } else {
    return name;
  }
};

export const getConnectorsSectionLabel = (id: string): string => {
  return KG_CONNECTOR_LABEL.find(connector => connector.id === id)?.name ?? 'Other';
};
