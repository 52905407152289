import React from 'react';

import cx from 'classnames';

import { Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface ICreateDeleteRequestProps {
  orgName: string;
  handleDeleteOrgModalOpen: () => void;
}

export const CreateDeleteRequest: React.FC<ICreateDeleteRequestProps> = ({ orgName, handleDeleteOrgModalOpen }) => (
  <>
    <div className={styles.deleteOrgContainerTitle}>
      <Text variant={TextSize.L} bold>
        Delete {orgName}
      </Text>
    </div>
    <div className={cx(styles.deleteOrgContainerDescription)}>
      <Text variant={TextSize.S} onClick={handleDeleteOrgModalOpen} className={styles.clickable}>
        I want to delete my organization
      </Text>
    </div>
  </>
);

export default CreateDeleteRequest;
