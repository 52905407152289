import { computed, makeObservable, observable, runInAction } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';

import { ILearningCenterWebinar } from '@web/types';
import { LEARNING_CENTER_WEBINAR_LIMIT } from 'constants/LearningCenter';

interface ILearningCenterModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  isEnrolledInWriterAcademy: boolean;
  refreshUserSettings: () => void;
}

export class LearningCenterModel {
  private $webinars: PromisedModel<ILearningCenterWebinar[]>;
  private $isWriterAcademyAuthTokenLoading = observable.box(false);

  constructor(private opts: ILearningCenterModelOptions) {
    makeObservable(this, {
      webinars: computed.struct,
    });

    this.$webinars = new PromisedModel({
      name: '$webinars',
      load: async () => {
        const { data } = await this.opts.request.get('/api/organization/v2/webinar', {
          params: {
            query: {
              limit: LEARNING_CENTER_WEBINAR_LIMIT,
            },
          },
        });

        return data.result;
      },
    });
  }

  get webinars(): ILearningCenterWebinar[] {
    return this.$webinars.value ?? [];
  }

  getWriterAcademyAuthToken = async () => {
    runInAction(() => {
      this.$isWriterAcademyAuthTokenLoading.set(true);
    });

    const { data } = await this.opts.request.put('/api/auth/organization/{organizationId}/academy/login', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
    });

    runInAction(() => {
      this.$isWriterAcademyAuthTokenLoading.set(false);
    });

    return data.token;
  };

  refreshUserSettings = () => {
    this.opts.refreshUserSettings();
  };

  get isWriterAcademyAuthTokenLoading() {
    return this.$isWriterAcademyAuthTokenLoading.get();
  }

  get isEnrolledInWriterAcademy() {
    return this.opts.isEnrolledInWriterAcademy;
  }
}
