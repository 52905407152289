import React, { useMemo } from 'react';

import cx from 'classnames';

import { Paginator } from '@writercolab/ui-molecules';

import { useTermsContext } from '../../../../context/termsContext';

import styles from '../../termsAndSnippetsShared/TermsFooter.module.css';

interface ITermsFooterProps extends BaseComponentsProps {
  leftContainer?: JSX.Element;
  rightContainer?: JSX.Element;
}

export const TermsFooter: React.FC<ITermsFooterProps> = ({ className, leftContainer, rightContainer }) => {
  const { termsContext, handlePaginationChange } = useTermsContext();
  const { offset, limit, termsTotalCount } = termsContext;

  const Pagination = useMemo(
    () => (
      <div className={cx(styles.paginationContainer)}>
        <Paginator onChange={handlePaginationChange} limit={limit} offset={offset} total={termsTotalCount || 0} />
      </div>
    ),
    [offset, limit, termsTotalCount, handlePaginationChange],
  );

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.containerWrapper}>
        <div className={styles.leftContainer}>{leftContainer}</div>
        <div className={styles.teamInfo}>
          <div className={styles.teamInfoWrapper}>{Pagination}</div>
        </div>
        <div className={styles.rightContainer}>{rightContainer}</div>
      </div>
    </div>
  );
};

export default TermsFooter;
