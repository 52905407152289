import { Enum } from '@writercolab/utils';

export const nameof = <T>(name: keyof T) => name;

export interface NormalizedState<T> {
  byId: Record<string, T>;
  allIds: string[];
}

export type WithChildren = {
  children?: React.ReactNode;
};

export const RECAPS_APP_ID = 'd20d5fd7-2b36-4c4a-924a-26b2f1794338';
export const BLOG_BUILDER_APP_ID = '7f74a63d-99ad-45ab-8078-e97297cb01bc';
export const IMAGE_ANALYZER_APP_ID = 'ef18435e-5e76-451f-bc90-012a265834c5';
export const ASK_WRITER_APP_ID = 'b40099de-6615-4d22-9e4d-e4179bdafbad';

export const DEFAULT_TEXT_PLACEHOLDER = '-';
export const DEFAULT_PAGE_SIZE = 50;

export const TApiRequestSortDirection = new Enum('asc', 'desc');
export const TAppQueryParams = new Enum('aiStudioPermissionsError');
