import { useState, useEffect } from 'react';

// Used for the dropdown elements that need to also handle state
export const useDetectOutsideClick = (el: React.MutableRefObject<HTMLElement | null>, initialState: boolean) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const pageClickEvent = (e: MouseEvent) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target as HTMLElement)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      document.addEventListener('click', pageClickEvent);
    }

    return () => {
      document.removeEventListener('click', pageClickEvent);
    };
  }, [isActive, el]);

  return [isActive, setIsActive] as const;
};
