import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import type { ISuggestionMetaSpellChecker, ISuggestionSection } from '@writercolab/common-utils';
import { SuggestionCategoryType, findSuggestionSection } from '@writercolab/common-utils';
import { Dropdown, Heading, HeadingVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import type { Lens } from 'ramda';
import { lensProp, set } from 'ramda';
// eslint-disable-next-line no-restricted-imports
import analytics from 'services/analytics/analyticsService';

import ArrowIcon from '../../../../assets/icons/otherIcons/dropDownArrow.svg?react';
import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import SuggestionCategory from '../SuggestionCategory';
import SuggestionPageHeader from '../SuggestionPageHeader';

import styles from '../styles.module.css';

const languageSelectOptions = [
  {
    id: 'en-us',
    name: 'English - United States',
    className: cx(styles.flag, styles.unitedStatesFlag),
  },
  {
    id: 'en-au',
    name: 'English - Australia',
    className: cx(styles.flag, styles.australiaFlag),
  },
  {
    id: 'en-ca',
    name: 'English - Canada',
    className: cx(styles.flag, styles.canadaFlag),
  },
  {
    id: 'en-gb',
    name: 'English - United Kingdom',
    className: cx(styles.flag, styles.unitedKingdomFlag),
  },
];

const LanguageDropdownTrigger = ({ languageName, languageClassName }) => (
  <div className={cx(styles.languageSelectTrigger, styles.triggerFlag, languageClassName)}>
    <div>
      <Text color={TextColor.BLACK} variant={TextSize.L}>
        {languageName}
      </Text>
    </div>
    <div className={styles.selectTriggerIcon}>
      <ArrowIcon className={styles.arrowIcon} />
    </div>
  </div>
);

const spellCheckerLens = lensProp<ISuggestionMetaSpellChecker, 'language'>('language');

export const GeneralPage: React.FC = () => {
  usePageTitle('General');
  const {
    suggestionSettings,
    isSaving,
    isReadOnly,
    isTeamNameShown,
    lastUpdated,
    lastUpdatedBy,
    setSectionEnabled,
    teamName,
    updateSectionMeta,
  } = useSuggestionsContext();

  useEffect(() => {
    analytics.track(AnalyticsActivity.suggestionsGeneralViewed, {});
  }, []);

  const [languageCode, setCurrentLanguageCode] = useState('');

  const spellChecker: ISuggestionSection<ISuggestionMetaSpellChecker> = useMemo(() => {
    const spellCheckerSection = findSuggestionSection(SuggestionCategoryType.SPELL_CHECKER, suggestionSettings)!;

    if (spellCheckerSection) {
      setCurrentLanguageCode(spellCheckerSection.meta.language);
    }

    return spellCheckerSection;
  }, [suggestionSettings]);

  const onUpdateLanguage = (lens: Lens<ISuggestionMetaSpellChecker, string>, language: string) => {
    const newSpellCheckerMeta = set(lens, language, spellChecker.meta);

    updateSectionMeta(SuggestionCategoryType.SPELL_CHECKER, newSpellCheckerMeta);
  };

  const languagesList = useMemo(
    () => languageSelectOptions.map(option => ({ ...option, active: option.id === languageCode })),
    [languageCode],
  );

  const { selectedLanguageName, selectedLanguageClassName } = useMemo(() => {
    const language = languageSelectOptions.find(option => option.id === languageCode);
    const selectedLanguageName = language?.name;
    const selectedLanguageClassName = language?.className;

    return { selectedLanguageName, selectedLanguageClassName };
  }, [languageCode]);

  const onLanguageChange = useCallback(
    async (id: string) => {
      setCurrentLanguageCode(id);
      onUpdateLanguage(spellCheckerLens, id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [suggestionSettings],
  );

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="General"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />

      <div className={styles.description}>
        <div className={styles.descriptionItemShortest}>
          <Text color={TextColor.GREY} variant={TextSize.XL}>
            When a switch is on, Writer will let you know if your content has issues in that category.
          </Text>
        </div>
      </div>

      <SuggestionCategory
        readOnly={isReadOnly}
        name="Grammar checker"
        type={SuggestionCategoryType.GRAMMAR_CHECKER}
        description="Our grammar checker catches grammar errors such as subject-verb agreement, pronoun casing, wrong prepositions, and much more."
        halfLimitedWidth
        hideBottomBorder
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
      />

      <SuggestionCategory
        readOnly={isReadOnly}
        name="Spellchecker"
        type={SuggestionCategoryType.SPELL_CHECKER}
        description="Our spellchecker helps keep your writing free of typos and misspellings."
        halfLimitedWidth
        hideBottomBorder
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
      />

      <div className={styles.languageSwitcher}>
        <Heading variant={HeadingVariant.H3} className={styles.labelText}>
          Language
        </Heading>

        <Text
          className={cx(styles.description, styles.descriptionNoBorder, styles.descriptionBlack)}
          variant={TextSize.L}
          color={TextColor.BLACK}
        >
          Select a language to receive spelling suggestions customized to your locale.
        </Text>

        <div
          className={cx(styles.languageSelectContainer, {
            [styles.eventsDisabled]: isReadOnly,
          })}
        >
          <Dropdown
            className={styles.languageDropdown}
            containerClassName={styles.languageDropdown}
            triggerClassName={styles.dropdownTrigger}
            trigger={
              <LanguageDropdownTrigger
                languageName={selectedLanguageName}
                languageClassName={selectedLanguageClassName}
              />
            }
            options={languagesList}
            onPrimaryOptionClickAction={onLanguageChange}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralPage;
