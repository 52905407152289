import { RequestServiceInitialize } from '@writercolab/network';

import { CreateTeamRequestParams, TTeam } from '@web/types';

interface ICreateTeamOpts {
  request: RequestServiceInitialize['api'];
  organizationId: number;
}

export class CreateTeamApiModel {
  private readonly options: ICreateTeamOpts;

  constructor(opts: ICreateTeamOpts) {
    this.options = opts;
  }

  async create(params: CreateTeamRequestParams): Promise<TTeam> {
    const { data } = await this.options.request.post(`/api/organization/v2/{organizationId}/team`, {
      params: {
        path: {
          organizationId: this.options.organizationId,
        },
      },
      body: params,
    });

    return data;
  }
}
