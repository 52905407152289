import { computed, makeObservable } from 'mobx';

import { downloadBlobAsFilename } from '@writercolab/common-utils';
import { ReactiveQueue } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import { NotificationQueueItemType, TEventQueueItem, TNotificationQueueItem } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import { TBillingGroupWithCount, TPaginatedBillingGroupExtraArgs } from '@web/types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { BillingGroupApiModel } from 'models/billingGroup.api';

import { getLogger } from '../../../utils/logger';
import { DOWNLOAD_TYPE } from '../termsAndSnippetsShared/common';
import { BillingGroupImporterUIModel } from './BillingGroupImporter/BillingGroupImporterModel.ui';

export const BillingGroupAction = new Enum('view', 'create', 'editName', 'editContact', 'delete', 'importer');

interface BillingGroupPageUIModelOptions {
  api: BillingGroupApiModel;
}

const LOG = getLogger('BillingGroupPageUIModel');

type TBillingGroupRoute = typeof BillingGroupAction.type;

type TBillingGroupParams = {
  billingGroupId: string;
};

export type TBillingModalsManager = ModalsManager<typeof BillingGroupAction.type, number>;

export type TTableActionsQueue = TEventQueueItem<TBillingGroupRoute, TBillingGroupParams>;
export class BillingGroupPageUIModel {
  modalsManager: TBillingModalsManager;

  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  tableActionsQueue: ReactiveQueue<TTableActionsQueue>;

  constructor(private opts: BillingGroupPageUIModelOptions) {
    this.modalsManager = new ModalsManager<typeof BillingGroupAction.type, number>();
    this.notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
    this.tableActionsQueue = new ReactiveQueue<TTableActionsQueue>();
    makeObservable(this, {
      currentActiveEntityId: computed,
      billingImporterModel: computed,
      selectedGroup: computed,
      isLoading: computed,
      hasNext: computed,
      data: computed,

      isShowCreateModal: computed,
      isShowEditNameModal: computed,
      isShowEditContactModal: computed,
      isShowDeleteModal: computed,
    });
  }

  get billingImporterModel() {
    return new BillingGroupImporterUIModel({ modalsManager: this.modalsManager, api: this.opts.api });
  }

  get currentActiveEntityId(): number | null {
    return this.modalsManager.getArrayModalState()[0] ?? null;
  }

  onCreate = async ({ name, contact }: { name: string; contact: string }) => {
    try {
      await this.opts.api.create({
        name,
        contact,
      });
      await this.opts.api.refresh();
      this.modalsManager.hideModal();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: `Billing group ${name} was successfully created`,
      });
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Billing group ${name} was not created`,
      });
    }
  };

  onEdit = async ({ groupId, name, contact }: { name: string; groupId?: number; contact?: string }) => {
    const isSameName = this.selectedGroup?.name === name;
    try {
      if (!groupId) {
        throw new Error('BillingGroup id is not defined');
      }

      await this.opts.api.edit({
        groupId,
        name,
        contact,
      });
      await this.opts.api.refresh();

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: isSameName
          ? `Billing group ${name} contact was successfully updated`
          : `Successfully renamed to ${name}`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Billing group ${name} was not updated`,
      });
    }
  };

  onDelete = async (groupId: number) => {
    try {
      await this.opts.api.deleteBillingGroup({
        groupId,
      });
      await this.opts.api.refresh();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.delete,
        message: `Your billing group was deleted`,
      });
      this.modalsManager.hideModal();
    } catch {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: `Your billing group was not deleted`,
      });
    }
  };

  handleDelete = () => {
    const id = this.modalsManager.getModalState(BillingGroupAction.enum.delete);

    if (!id) {
      return;
    }

    this.onDelete(id);
  };

  get selectedGroup(): TBillingGroupWithCount | undefined {
    return this.data.find(group => group.id === this.currentActiveEntityId);
  }

  get isLoading(): boolean {
    return this.opts.api.isLoading;
  }

  get hasNext(): boolean {
    return this.opts.api.pagination.hasNext ?? false;
  }

  loadMore = async () => {
    await this.opts.api.pagination.next();
  };

  get showLoader(): boolean {
    return this.isLoading && isEmpty(this.data);
  }

  get isShowCreateModal() {
    return this.modalsManager.isModalVisible(BillingGroupAction.enum.create);
  }

  get isShowEditNameModal() {
    return this.modalsManager.isModalVisible(BillingGroupAction.enum.editName);
  }

  get isShowEditContactModal() {
    return this.modalsManager.isModalVisible(BillingGroupAction.enum.editContact);
  }

  get isShowDeleteModal() {
    return this.modalsManager.isModalVisible(BillingGroupAction.enum.delete);
  }

  closeModal = () => {
    this.modalsManager.hideModal();
  };

  updateExtraArgsDebounced = debounce((extraArgs: TPaginatedBillingGroupExtraArgs) => {
    this.opts.api.pagination.setExtra(extraArgs);
  }, 500);

  handleDownloadBillingGroup = async (filetype: string) => {
    try {
      if (filetype === DOWNLOAD_TYPE.CSV) {
        const blob = await this.opts.api.downloadCSVBillingGroup({
          hasBillingGroup: true,
        });
        downloadBlobAsFilename(blob, 'billing-group.csv');
      } else {
        const blob = await this.opts.api.downloadXLSXBillingGroup({
          hasBillingGroup: true,
        });
        downloadBlobAsFilename(blob, 'billing-group.xlsx');
      }
    } catch (e) {
      LOG.error(e);
    }
  };

  get data(): TBillingGroupWithCount[] {
    return this.opts.api.data ?? [];
  }
}
