import { computed, makeObservable } from 'mobx';

import { IBillingGroupWithFails, TImportBillingGroupUsersRequest } from '@web/types';

import { BillingGroupApiModel } from '../../../../models/billingGroup.api';
import { BillingGroupAction, TBillingModalsManager } from '../BillingGroupPageModel.ui';

interface IBillingGroupImporterUIModelOpts {
  modalsManager: TBillingModalsManager;
  api: BillingGroupApiModel;
}
export class BillingGroupImporterUIModel {
  modalsManager: TBillingModalsManager;
  api: BillingGroupApiModel;
  constructor(private opts: IBillingGroupImporterUIModelOpts) {
    this.modalsManager = this.opts.modalsManager;
    this.api = this.opts.api;
    makeObservable(this, {
      isShow: computed,
    });
  }

  get isShow() {
    return this.modalsManager.isModalVisible(BillingGroupAction.enum.importer);
  }

  onOpen = () => {
    this.modalsManager.showModal(BillingGroupAction.enum.importer);
  };

  onSubmit = async (models: TImportBillingGroupUsersRequest[]): Promise<IBillingGroupWithFails> => {
    const res = await this.api.importBillingGroup(models);
    this.opts.api.refresh();

    return res as unknown as IBillingGroupWithFails;
  };

  onClose = () => {
    this.modalsManager.hideModal(BillingGroupAction.enum.importer);
  };
}
