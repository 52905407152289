import React, { useMemo, useState } from 'react';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingColor,
  HeadingVariant,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import { IOption, TagSelect } from '../TagSelect';

import styles from './UpdateBillingGroup.module.css';

interface IUpdateBillingGroupProps {
  billingGroupOptions: IOption[];
  onSubmit: (id: number) => void;
  defaultBillingGroupId?: number;
}

export const UpdateBillingGroup = ({
  billingGroupOptions,
  onSubmit,
  defaultBillingGroupId,
}: IUpdateBillingGroupProps) => {
  const defaultValue = useMemo(() => {
    if (!defaultBillingGroupId) return null;

    return billingGroupOptions.find(option => option.value === defaultBillingGroupId) || null;
  }, [defaultBillingGroupId, billingGroupOptions]);

  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  const handleChange = (newValue: IOption | null) => {
    setSelectedOption(newValue);
  };

  const handleSubmit = () => {
    onSubmit(selectedOption?.value as number);
  };

  return (
    <div>
      <div className={styles.headWrapper}>
        <div className={styles.titleWrapper}>
          <Heading variant={HeadingVariant.H4} color={HeadingColor.BLACK} bold>
            Edit billing group
          </Heading>
        </div>
        <div className={styles.descriptionWrapper}>
          <Text variant={TextSize.XL} color={TextColor.BLACK}>
            Edit the group this user is assigned to. Users can only be assigned to one billing group at a time.{' '}
            <a target="_blank" href="https://support.writer.com/article/247-billing-groups" className={styles.link}>
              Learn more.
            </a>
          </Text>
        </div>
      </div>

      <div className={styles.tagSelectWrapper}>
        <div className={styles.tagTitleSelect}>
          <Text variant={TextSize.S} color={TextColor.BLACK} upperCase>
            Billing Group
          </Text>
        </div>
        <TagSelect
          placeholder="Add billing group"
          noOptionsMessage="No billing groups found"
          options={billingGroupOptions}
          onChange={handleChange}
          defaultValue={defaultValue}
          containerStyles={{
            width: '200px',
          }}
        />
      </div>

      <div className={styles.actionWrapper}>
        <Button type={ButtonTypes.BLACK} onClick={() => handleSubmit()} content="Update" />
      </div>
    </div>
  );
};
