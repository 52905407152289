import { computed, makeObservable } from 'mobx';

import { SearchQueryParam } from '@writercolab/common-utils';
import { PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, components } from '@writercolab/network';

import isEmpty from 'lodash/isEmpty';

interface IPromptLibraryModelOptions {
  request: RequestServiceInitialize['api'];
}

export class PromptLibraryApiModel {
  public readonly request: RequestServiceInitialize['api'];
  public readonly $prompts: PromisedModel<
    components['schemas']['model_PaginatedResult_content_generation_model_Prompt']
  >;

  public readonly $promptTags: PromisedModel<components['schemas']['content_generation_dto_PromptTagResponse']>;

  constructor(private opts: IPromptLibraryModelOptions) {
    const { request } = opts;

    this.request = request;

    const queryParams = new URLSearchParams();
    queryParams.append(SearchQueryParam.limit, '500');

    this.$prompts = new PromisedModel({
      name: '$prompts',
      load: () =>
        request
          .get('/api/generation/prompt', {
            params: {
              query: {
                limit: 500,
              },
            },
          })
          .then(({ data }) => data),
    });

    this.$promptTags = new PromisedModel({
      name: '$promptTags',
      load: () => request.get('/api/generation/prompt/tag', {}).then(({ data }) => data),
    });

    makeObservable(this, {
      isLoaded: computed,
      prompts: computed,
      promptTags: computed,
    });
  }

  get isLoaded() {
    return [this.$prompts.status, this.$promptTags.status].every(status => status !== 'pending');
  }

  get prompts(): components['schemas']['content_generation_model_Prompt'][] {
    return this.$prompts.value?.result || [];
  }

  get promptTags(): components['schemas']['content_generation_model_PromptTag'][] | null {
    const promptTags = this.$promptTags.value?.tags;

    if (Array.isArray(promptTags) && !isEmpty(promptTags)) {
      return promptTags;
    } else {
      return null;
    }
  }
}
