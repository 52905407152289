import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { openNewTab } from '@writercolab/common-utils';
import { MfaChallengeType } from '@writercolab/types';
import {
  Heading,
  HeadingVariant,
  LabelledTags,
  LinkText,
  Switcher,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import { DndContext, DragEndEvent, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';

import PageSectionPlaceholder from '../../generic/PageSectionPlaceholder/PageSectionPlaceholder';
import { MfaChallengeCard } from '../../molecules/MfaChallengeCard';

import styles from './styles.module.css';

interface MfaChallengesListProps {
  className?: string;
  phone?: string;
  enabledMfas: MfaChallengeType[];
  noPassword: boolean;
  switchMfaSettings: (type?: MfaChallengeType) => void;
  onReorderChallenges: (challenges: MfaChallengeType[]) => void;
  onAddPhoneNumber: () => void;
  onCreatePassword: () => void;
  mfaSetupPossible: boolean;
}

export const MfaChallengesList: React.FC<MfaChallengesListProps> = ({
  className,
  phone,
  noPassword,
  enabledMfas,
  switchMfaSettings,
  onReorderChallenges,
  onAddPhoneNumber,
  onCreatePassword,
  mfaSetupPossible,
}) => {
  const [challenges, setChallenges] = useState([] as MfaChallengeType[]);
  const sensors = useSensors(useSensor(PointerSensor));

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const activeIndex = challenges.findIndex(item => item === active.id);
        const overIndex = challenges.findIndex(item => item === over?.id);

        const newChallenges = arrayMove(challenges, activeIndex, overIndex);

        setChallenges(newChallenges);
        onReorderChallenges(newChallenges);
      }
    },
    [challenges, onReorderChallenges],
  );

  useEffect(() => {
    setChallenges(() => {
      if (!enabledMfas.length) {
        return [MfaChallengeType.EMAIL, MfaChallengeType.PHONE];
      }

      if (enabledMfas.length === 1) {
        return [
          enabledMfas[0],
          enabledMfas[0] === MfaChallengeType.EMAIL ? MfaChallengeType.PHONE : MfaChallengeType.EMAIL,
        ];
      }

      return enabledMfas;
    });
  }, [enabledMfas]);

  const content = useMemo(() => {
    if (mfaSetupPossible) {
      return (
        <div
          className={cx(styles.rowContainer, styles.mfaChallenges, {
            [styles.disabledMfa]: noPassword,
          })}
        >
          <div className={styles.mfaListHeader}>
            <Text variant={TextSize.XXL} medium>
              Verification methods
            </Text>
          </div>
          <div className={styles.mfaMethods}>
            <div className={styles.challengeTypeSeparator} />
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd}>
              <SortableContext items={challenges} strategy={verticalListSortingStrategy}>
                {challenges.map((item, index) => (
                  <MfaChallengeCard
                    id={item}
                    key={`mfa-challenge-${item}`}
                    label={item === MfaChallengeType.PHONE ? 'SMS' : 'Email'}
                    onSwitch={switchMfaSettings}
                    checked={enabledMfas.includes(item)}
                    disabled={item === MfaChallengeType.PHONE && !phone}
                  >
                    <div className={styles.challengeBody}>
                      <div>
                        {index === 0 && (
                          <LabelledTags bgColor="var(--writer-green-3)" className={styles.mfaMethodsPrimaryTag}>
                            primary
                          </LabelledTags>
                        )}
                      </div>
                      {item === MfaChallengeType.PHONE && !phone && (
                        <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.addPhoneLink}>
                          <LinkText onClick={onAddPhoneNumber}>Add a phone number</LinkText> to enable SMS
                        </Text>
                      )}
                    </div>
                  </MfaChallengeCard>
                ))}
              </SortableContext>
            </DndContext>
          </div>
        </div>
      );
    } else {
      return (
        <PageSectionPlaceholder
          className={styles.mfaPlaceholderContainer}
          message={
            <>
              Your organization requires SAML SSO (single sign-on) for signing into Writer. Because of this, MFA
              settings for your account are controlled by your SAML identity provider (e.g., Okta, Ping, etc.) and not
              by Writer.
            </>
          }
        />
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenges, enabledMfas, mfaSetupPossible, noPassword, onDragEnd, phone, sensors]);

  const openMfaDocumentationPage = () =>
    openNewTab('https://support.writer.com/article/223-setting-up-multi-factor-authentication-mfa');

  return (
    <>
      <div className={className}>
        <Heading variant={HeadingVariant.H3} className={cx(styles.header)}>
          Multi-factor authentication (MFA)
        </Heading>
        <div className={styles.rowContainer}>
          <Text variant={TextSize.L} className={styles.description}>
            Multi-factor authentication (MFA) adds an additional
            <br /> layer of security to your account by requiring more
            <br /> than just a password to log in.{' '}
            <LinkText onClick={openMfaDocumentationPage} className={styles.learnMoreLink}>
              Learn more
            </LinkText>
            .
          </Text>
          <div className={styles.enableMfaSwitcher}>
            <Text variant={TextSize.L} bold className={styles.enableMfaSwitcherLabel}>
              Enable MFA
            </Text>
            <Switcher
              onChange={() => switchMfaSettings()}
              id="enable-mfa"
              size={20}
              checked={!!enabledMfas.length}
              disabled={!mfaSetupPossible}
            />
          </div>
          {noPassword && (
            <div className={styles.noPasswordMfa}>
              <Text variant={TextSize.L}>
                <LinkText onClick={onCreatePassword}>Create a password</LinkText> to enable MFA
              </Text>
            </div>
          )}
        </div>
      </div>
      <>{content}</>
    </>
  );
};
