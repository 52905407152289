import { useCallback, useRef } from 'react';

import { MfaRecaptchaAction, VerdictStatusCode } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import config from '../utils/dynamicConfig';
import { getLogger } from '../utils/logger';
import { isMfaCodeExpiredError } from '../utils/mfaUtils';
import { useScriptLoad } from './useScriptLoad';

const LOG = getLogger('useGoogleMfa');

const { RECAPTCHA_ENTEPRISE_KEY } = config;

interface ReCaptcha {
  ready(callback: () => void): void;

  enterprise: ReCaptchaEnterprise;
}

interface ReCaptchaEnterpriseOptions {
  action?: string;
}

interface ReCaptchaEnterprise {
  execute(sitekey: string, options?: ReCaptchaEnterpriseOptions): Promise<string>;

  eap: {
    initTwoFactorVerificationHandle: (sitekey: string, requestToken: string) => ReCaptchaVerificationHandle;
  };
}

interface MfaChallengeVerdict {
  isSuccess: () => boolean;
  getVerdictToken: () => string;
  getStatusCode: () => VerdictStatusCode;
}

interface ReCaptchaVerificationHandle {
  verifyAccount: (code: string) => Promise<MfaChallengeVerdict>;
  challengeAccount: () => Promise<MfaChallengeVerdict>;
}

const RECAPTCHA_SCRIPT_URL = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_ENTEPRISE_KEY}`;

const GOOGLE_MFA_MESSAGE = {
  NO_RECAPTCHA_KEY: 'No recaptcha key',
  FAILED_TO_CHALLENGE_ACCOUNT: 'Failed to challenge account',
  INCORRECT_CODE: 'Incorrect code',
  INCORRECT_CODE_EXPIRED: "You've reached the limit of incorrect attempts. Try resending the code.",
  FAIL_TO_RESEND_CODE: 'Failed to resend code',
  NO_VERIFICATION_HANDLE: 'No verification handle',
};

export const useGoogleMfa = () => {
  if (isEmpty(RECAPTCHA_ENTEPRISE_KEY)) {
    throw new Error(GOOGLE_MFA_MESSAGE.NO_RECAPTCHA_KEY);
  }

  const { scriptLoaded } = useScriptLoad(RECAPTCHA_SCRIPT_URL);

  const verificationHandle = useRef<ReCaptchaVerificationHandle | null>(null);

  const executeRecaptcha = useCallback(
    async (action: MfaRecaptchaAction) => {
      LOG.info('Executing reCAPTCHA with action:', action);
      const reCaptcha = (window as any).grecaptcha as ReCaptcha;

      return (
        reCaptcha &&
        reCaptcha.enterprise.execute(RECAPTCHA_ENTEPRISE_KEY, {
          action,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scriptLoaded],
  );

  const initTwoFactor = useCallback(
    async (requestToken: string) => {
      LOG.info('Initializing two-factor verification');
      const reCaptcha = (window as any).grecaptcha as ReCaptcha;
      const handle = reCaptcha.enterprise.eap.initTwoFactorVerificationHandle(RECAPTCHA_ENTEPRISE_KEY, requestToken);

      const { isSuccess, getStatusCode, getVerdictToken } = await handle.challengeAccount();
      const isChallengeSuccess = isSuccess();

      if (isChallengeSuccess) {
        LOG.info('Two-factor verification initialized successfully');
        verificationHandle.current = handle;
      } else {
        LOG.error('init two factor error', getStatusCode(), isChallengeSuccess, getVerdictToken());
        throw new Error(GOOGLE_MFA_MESSAGE.FAILED_TO_CHALLENGE_ACCOUNT);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scriptLoaded],
  );

  const verifyAccount = useCallback(async (code: string) => {
    LOG.info('Verifying account with MFA code');
    if (!verificationHandle.current) {
      LOG.error('No verification handle available');
      throw new Error(GOOGLE_MFA_MESSAGE.NO_VERIFICATION_HANDLE);
    }

    const { isSuccess, getVerdictToken, getStatusCode } = await verificationHandle.current.verifyAccount(code);

    if (!isSuccess()) {
      let errorMessage = GOOGLE_MFA_MESSAGE.INCORRECT_CODE;
      const verdictStatusCode = getStatusCode();

      if (isMfaCodeExpiredError(verdictStatusCode)) {
        errorMessage = GOOGLE_MFA_MESSAGE.INCORRECT_CODE_EXPIRED;
      }

      LOG.error('Account verification failed:', { statusCode: getStatusCode(), errorMessage });
      throw new Error(errorMessage);
    }

    LOG.info('Account verified successfully');
    return { getVerdictToken };
  }, []);

  const resendCode = useCallback(async () => {
    LOG.info('Attempting to resend MFA code');
    if (!verificationHandle.current) {
      LOG.error('No verification handle available for resending code');
      throw new Error(GOOGLE_MFA_MESSAGE.FAIL_TO_RESEND_CODE);
    }

    const { isSuccess, getStatusCode } = await verificationHandle.current.challengeAccount();
    LOG.info('Code resend attempt completed', { isSuccess: isSuccess(), statusCode: getStatusCode() });

    return {
      isSuccess,
      getStatusCode,
    };
  }, []);

  return {
    executeRecaptcha,
    initTwoFactor,
    verifyAccount,
    resendCode,
  };
};
