import { UserSettingDepartment } from '@web/types';
import trim from 'lodash/trim';

/**
 * This function maps the response of a department to the user department.
 * It compares the input department with the UserCompanyDepartment enum values.
 * If it finds a match, it returns the matching enum value; otherwise, it returns null.
 *
 * @param department - A string representing a department name.
 * @returns The matching UserCompanyDepartment enum value or null if no match is found.
 */
export const mapDepartmentResponseToUserDepartmentSetting = (department: string): UserSettingDepartment | null => {
  const trimmedDepartment = trim(department);
  const foundDepartment = Object.values(UserSettingDepartment).find(d => d === trimmedDepartment);

  return foundDepartment || null;
};
