import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { InputType, getFileExtension, isTextOverflowEllipsisActive, openNewTab } from '@writercolab/common-utils';
import {
  Button,
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import type { IDraftInput, IDraftMedia, TDraftInput } from '@web/types';
import { MEDIA_DROPDOWN_ACTION_ID, SUPPORTED_VIDEO_FILE_EXT } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import { filterMediaDropdownOptions, getInputType } from '../../../utils/draftsUtils';

import styles from './styles.module.css';

const LONG_INPUT_VALUE_LENGTH = 45;

const INPUTS_GROUP_LABELS = {
  eventName: 'Event name',
  participants: 'Participants',
  fileId: 'Media file',
};

const MEDIA_DROPDOWN_OPTIONS = [
  {
    name: 'Download file',
    id: MEDIA_DROPDOWN_ACTION_ID.DOWNLOAD_FILE,
    icon: <Icon name={IconVariant.DOWNLOAD} />,
  },
  {
    name: 'Open YouTube video',
    id: MEDIA_DROPDOWN_ACTION_ID.OPEN_YOUTUBE,
    icon: <Icon name={IconVariant.VIDEO} />,
  },
];

interface IDraftInputsProps {
  isInputsOpen: boolean;
  inputs: IDraftInput[];
  media?: IDraftMedia;
  templateInputs: TDraftInput[];
  voiceName: string | null;
  rewriteOptionName: string | null;
  onToggle: (isOpen: boolean) => void;
  downloadMediaFile: (fileId: string, filename: string) => Promise<void>;
}

const VoiceInput: React.FC<{ voiceName: string }> = ({ voiceName }) => {
  return (
    <div className={styles.voiceContainer}>
      <Heading variant={HeadingVariant.H5} className={styles.inputHeader} bold>
        Voice
      </Heading>
      <div className={styles.voiceDropdown}>
        <div className={styles.voiceDropdownTitle}>
          <Icon name={IconVariant.VOICE_TUNE} width={18} height={18} className={styles.voiceDropdownIconTune} />
          <Text variant={TextSize.L} ellipsisOverflow className={styles.voiceDropdownTitleText}>
            {voiceName}
          </Text>
        </div>
        <div>
          <Icon name={IconVariant.ARROW_DOWN} className={styles.voiceDropdownArrowDown} />
        </div>
      </div>
    </div>
  );
};

const DraftInputs = ({
  isInputsOpen,
  inputs,
  media,
  templateInputs,
  onToggle,
  downloadMediaFile,
  voiceName,
  rewriteOptionName,
}: IDraftInputsProps) => {
  const [isMediaFileTooltipVisible, setIsMediaFileTooltipVisible] = useState(true);
  const mediaFileNameRef = useRef<HTMLElement>(null);
  const hiddenInputNames = ['diarized_transcript', 'eventClips', 'recordingType'];
  const hiddenInputTypes = ['file'];

  useEffect(() => {
    if (media?.filename) {
      setIsMediaFileTooltipVisible(isTextOverflowEllipsisActive(mediaFileNameRef?.current));
    }
  }, [media?.filename, isInputsOpen]);

  const toggleInputs = () => {
    onToggle(!isInputsOpen);
  };

  const handleMediaDropdownClick = (id: string) => {
    if (media) {
      switch (id) {
        case MEDIA_DROPDOWN_ACTION_ID.DOWNLOAD_FILE:
          downloadMediaFile(media.fileId, media.filename);
          break;
        case MEDIA_DROPDOWN_ACTION_ID.OPEN_YOUTUBE:
          openNewTab(media.url!);
          break;
        default:
          break;
      }
    }
  };

  if (!isEmpty(templateInputs) && templateInputs.every(i => hiddenInputTypes.includes(i.type))) {
    return null;
  }

  return (
    <div className={styles.inputGroup}>
      <Button
        className={styles.inputButton}
        icon={<Icon name={IconVariant.QUARTER_BURGER} />}
        size={SizeTypes.SMALL}
        onClick={toggleInputs}
      >
        <Text variant={TextSize.L} color={TextColor.GREY} medium>
          {isInputsOpen ? 'Hide' : 'Show'} inputs
        </Text>

        <Icon className={cx(styles.arrow, { [styles.isOpen]: isInputsOpen })} name={IconVariant.BREADCRUMB_ARROW} />
      </Button>

      {isInputsOpen && (
        <div className={styles.inputsWrapper}>
          {inputs.map((input, i) => {
            const hasSingleValue = input.value.length === 1;
            const inputName = INPUTS_GROUP_LABELS[input.name] ?? input.name;
            const templateInputType = templateInputs.find(i => i.name === input.name)?.type;

            if (
              isEmpty(input.value) ||
              (hasSingleValue && isEmpty(input.value[0])) ||
              hiddenInputNames.includes(input.name) ||
              (templateInputType && hiddenInputTypes.includes(templateInputType))
            ) {
              return null;
            }

            if (inputName === 'voiceId' && voiceName) {
              return (
                <div key={`${input.name}_${i}`} className={styles.inputRow}>
                  <VoiceInput voiceName={voiceName} />
                </div>
              );
            }

            if (media && inputName === INPUTS_GROUP_LABELS.fileId) {
              const mediaFileExtension = getFileExtension(media.filename);
              const isVideoFile = SUPPORTED_VIDEO_FILE_EXT.includes(mediaFileExtension);

              return (
                <div key={`${input.name}_${i}`} className={styles.mediaFileRow}>
                  <Heading variant={HeadingVariant.H5} className={styles.inputHeader}>
                    {inputName}
                  </Heading>
                  <div className={styles.mediaFile}>
                    <Icon
                      className={styles.mediaFileIcon}
                      name={isVideoFile ? IconVariant.MEDIA_FILE_VIDEO : IconVariant.MEDIA_FILE_AUDIO}
                      width={44}
                      height={36}
                    />
                    <Tooltip
                      placement="top"
                      disabled={!isMediaFileTooltipVisible}
                      title={
                        <Text className={styles.mediaFileTooltip} medium color={TextColor.WHITE} variant={TextSize.XL}>
                          {media.filename}
                        </Text>
                      }
                    >
                      <span ref={mediaFileNameRef} className={styles.mediaFileDescription}>
                        {media.filename}
                      </span>
                    </Tooltip>
                    <Dropdown
                      options={filterMediaDropdownOptions(media, MEDIA_DROPDOWN_OPTIONS)}
                      onPrimaryOptionClickAction={handleMediaDropdownClick}
                      placement={DropdownPlacement.TOP_RIGHT}
                      trigger={<Icon className={styles.mediaFileIconEllipsis} name={IconVariant.ELLIPSES} />}
                      highlightActiveOption
                    />
                  </div>
                </div>
              );
            }

            const inputType = !isEmpty(templateInputs) ? getInputType(templateInputs, input.name) : 'text';

            return (
              <div key={`${input.name}_${i}`} className={styles.inputRow}>
                <Heading variant={HeadingVariant.H5} className={styles.inputHeader} bold>
                  {startCase(inputName)}
                </Heading>

                <div
                  className={cx(styles.inputBody, {
                    [styles.inputBodyWide]: inputName === INPUTS_GROUP_LABELS.eventName,
                  })}
                >
                  {input.value.map((val, i) => {
                    return isEmpty(val) ? null : (
                      <div
                        className={cx(styles.bodyRow, {
                          [styles.bodyRowHasNumber]: !hasSingleValue && inputName !== INPUTS_GROUP_LABELS.participants,
                        })}
                        key={`${val}_${i}`}
                      >
                        {(!hasSingleValue || inputName === INPUTS_GROUP_LABELS.participants) && (
                          <div className={styles.inputNumber}>
                            <Text variant={TextSize.XL} bold>
                              {i + 1}
                            </Text>
                          </div>
                        )}
                        {inputType === 'text' && (
                          <Text
                            variant={TextSize.M}
                            className={cx(styles.inputValue, {
                              [styles.short]: val.length < LONG_INPUT_VALUE_LENGTH,
                            })}
                          >
                            {val}
                          </Text>
                        )}
                        {inputType === InputType.DROPDOWN && (
                          <div className={styles.dropdown}>
                            <Text>{val}</Text>
                            <Icon name={IconVariant.ARROW_DOWN} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}

          {rewriteOptionName && (
            <div className={styles.inputRow}>
              <Heading variant={HeadingVariant.H5} className={styles.inputHeader} bold>
                Rewrite option
              </Heading>

              <div className={styles.inputBody}>
                <Text>{rewriteOptionName}</Text>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DraftInputs;
