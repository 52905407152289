import { action, computed, makeObservable, observable } from 'mobx';

import type { AnalyticsService, TOrgTeamUserActivityParams } from '@writercolab/common-utils';
import { wordPluralize } from '@writercolab/common-utils';
import { FieldModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, components } from '@writercolab/network';

import { KnowledgeGraphConnectorUIModel, TKnowledgeGraphConnector } from 'components/organisms/KnowledgeGraphConnector';
import { KnowledgeGraphWebConnectorUIModel } from 'components/templates/KnowledgeGraphWebConnector';

// TODO: Not sure why it was updated but we have a shared model in .core cc @leo
import type {
  TKnowledgeGraphPaginatedExtraArgs,
  TPaginatedKnowledgeGraphFilesArgs,
  TPaginatedKnowledgeGraphFilesExtraArgs,
} from '@web/types';
import { KnowledgeGraphFilesViewAnalyticsType, KnowledgeGraphFilesViewType } from '@web/types';
import { AnalyticsActivity, TWebAnalyticsParams } from 'constants/analytics';
import debounce from 'lodash/debounce';
import { KnowledgeGraphsApiModel } from 'models/knowledgeGraphs.api';

import { GraphStatusUIModel } from '../../organisms/GraphStatus/GraphStatusModel.ui';
import { KnowledgeGraphFilesTableUIModel } from '../../organisms/KnowledgeGraphFilesTable/KnowledgeGraphFilesTableModel.ui';
import { SyncStatusUIModel } from '../../organisms/SyncStatus/SyncStatusModel.ui';

export const KG_NAME_CHARS_MAX = 50;
export const KG_DESCRIPTION_CHARS_MAX = 150;
export const KG_TABLE_SYNC_TIMEOUT = 5000;

interface IKnowledgeGraphPageModel {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId?: number;
  analyticsService: AnalyticsService<TWebAnalyticsParams, TOrgTeamUserActivityParams>;
  aiStudioMode: boolean;
  onRenameGraphSuccess: () => void;
  onDeleteGraphSuccess: (name: string) => void;
  onError: () => void;
  isAssistantSubscriptionActive: () => boolean | undefined;
  isConfluenceConnectorEnabled: () => boolean;
}

export class KnowledgeGraphPageModel {
  selectedGraph: components['schemas']['media_dto_KnowledgeGraphResponse'] | undefined;
  graphName: FieldModel<string>;
  graphDescription: FieldModel<string>;
  isAddGraphModalOpen = false;
  isAddGraphModalLoading = false;
  isDeleteGraphModalOpen = false;
  isRenameGraphModalOpen = false;
  api: KnowledgeGraphsApiModel;
  $connectorModel: KnowledgeGraphConnectorUIModel;
  $webConnectorModel: KnowledgeGraphWebConnectorUIModel;
  tableModel: KnowledgeGraphFilesTableUIModel;
  filesStatusModel: GraphStatusUIModel;
  syncStatusModel: SyncStatusUIModel;

  constructor(private opts: IKnowledgeGraphPageModel) {
    makeObservable(this, {
      selectedGraph: observable,
      isAddGraphModalOpen: observable,
      isAddGraphModalLoading: observable,
      isDeleteGraphModalOpen: observable,
      isRenameGraphModalOpen: observable,

      isUpload: computed,
      isConnector: computed,
      isWebConnector: computed,
      connectorModel: computed,
      webConnectorModel: computed,
      graphs: computed,
      isGraphsLoading: computed,
      filesCount: computed,
      isAssistantSubscriptionActive: computed,

      setSelectedGraph: action,
      openAddGraphModal: action,
      closeAddGraphModal: action,
      openRenameGraphModal: action,
      closeRenameGraphModal: action,
      openDeleteGraphModal: action,
      closeDeleteGraphModal: action,
      createGraph: action,
      updateGraph: action,
      deleteGraph: action,
    });

    this.graphName = FieldModel.build({
      init: () => '',
      autotouched: false,
      validation: value => {
        if (!value) {
          return 'Name is required';
        } else if (value && value.length > KG_NAME_CHARS_MAX) {
          return `Max ${KG_NAME_CHARS_MAX} ${wordPluralize(KG_NAME_CHARS_MAX, 'char')}`;
        }

        return undefined;
      },
    });

    this.graphDescription = FieldModel.build({
      init: () => '',
      autotouched: true,
      validation: value => {
        if (value && value.length > KG_DESCRIPTION_CHARS_MAX) {
          return `Max ${KG_DESCRIPTION_CHARS_MAX} ${wordPluralize(KG_DESCRIPTION_CHARS_MAX, 'char')}`;
        }

        return undefined;
      },
    });

    this.api = new KnowledgeGraphsApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
    });

    this.$connectorModel = new KnowledgeGraphConnectorUIModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      graphId: () => this.selectedGraph?.id || '',
      connectors: () => this.api.connectors,
      writerConnectors: () =>
        this.api.writerConnectors.filter(connector => {
          if (connector.type === TKnowledgeGraphConnector.enum.confluence) {
            return this.opts.isConfluenceConnectorEnabled();
          }

          return true;
        }),
      isConnector: () => this.selectedGraph?.source === KnowledgeGraphFilesViewType.enum.Connector,
      isAssistantSubscriptionActive: () => this.isAssistantSubscriptionActive,
      aiStudioMode: this.opts.aiStudioMode,
    });

    this.$webConnectorModel = new KnowledgeGraphWebConnectorUIModel({
      request: this.opts.request,
      analyticsService: this.opts.analyticsService.withContext({
        graph_id: () => this.selectedGraph?.id,
      }),
      organizationId: this.opts.organizationId,
      graphId: () => this.selectedGraph?.id || '',
    });

    this.tableModel = new KnowledgeGraphFilesTableUIModel({
      api: this.api,
      teamId: this.opts.teamId,
      analyticsService: this.opts.analyticsService,
      aiStudioMode: this.opts.aiStudioMode,
    });

    this.filesStatusModel = new GraphStatusUIModel({
      api: this.api,
      teamId: this.opts.teamId,
      graphId: () => this.selectedGraph?.id || '',
    });

    this.syncStatusModel = new SyncStatusUIModel({
      api: this.api,
      teamId: this.opts.teamId,
      graphId: () => this.selectedGraph?.id || '',
    });
  }

  get isUpload() {
    return this.selectedGraph?.source === 'Upload';
  }

  get isConnector() {
    return this.selectedGraph?.source === 'Connector';
  }

  get isWebConnector() {
    return this.selectedGraph?.source === 'WebConnector';
  }

  get connectorModel() {
    return this.isConnector ? this.$connectorModel : undefined;
  }

  get webConnectorModel() {
    return this.isWebConnector ? this.$webConnectorModel : undefined;
  }

  get graphs() {
    const graphs = this.opts.teamId ? this.api.graphs : this.api.appStudioGraphs;

    // Selected graph should go first
    if (this.selectedGraph) {
      const filteredGraphs = graphs.filter(graph => graph.id !== this.selectedGraph?.id);

      return [this.selectedGraph, ...filteredGraphs];
    }

    return graphs;
  }

  get isGraphsLoading() {
    return this.opts.teamId ? this.api.isGraphsLoading : this.api.isAppStudioGraphsLoading;
  }

  get aiStudioMode() {
    return this.opts.aiStudioMode;
  }

  get filesCount() {
    return this.api.filesData?.length || 0;
  }

  get isAssistantSubscriptionActive() {
    return this.opts.isAssistantSubscriptionActive();
  }

  reloadGraphs = async () => {
    if (this.opts.teamId) {
      await this.api.reloadGraphs();
    } else {
      await this.api.reloadAppStudioGraphs();
    }
  };

  updateGraphsFilterExtraArgsDebounced = debounce((extraArgs: TKnowledgeGraphPaginatedExtraArgs) => {
    if (this.opts.teamId) {
      this.api.updateGraphsExtraArgs(extraArgs);
    } else {
      this.api.updateAppStudioGraphsExtraArgs(extraArgs);
    }
  }, 500);

  get hasGraphsFilterNextPage() {
    return this.opts.teamId ? this.api.hasNextGraphs : this.api.hasAppStudioGraphs;
  }

  loadMoresGraphsFilter = async () => {
    return this.opts.teamId ? this.api.loadMoreGraphs() : this.api.loadMoreAppStudioGraphs();
  };

  setSelectedGraph = (graph: components['schemas']['media_dto_KnowledgeGraphResponse'] | undefined) => {
    this.selectedGraph = graph;
  };

  onResetGraphsFilterSearch = () => {
    this.updateGraphsFilterExtraArgsDebounced({ search: undefined });
  };

  openAddGraphModal = () => {
    this.opts.analyticsService.track(AnalyticsActivity.kgAddKnowledgeGraphModalOpened, {});
    this.isAddGraphModalOpen = true;
  };

  closeAddGraphModal = () => {
    if (!this.isAddGraphModalLoading) {
      this.opts.analyticsService.track(AnalyticsActivity.kgAddKnowledgeGraphModalClosed, {
        name: this.graphName.value,
        description: this.graphDescription.value,
      });
      this.isAddGraphModalOpen = false;
      this.graphName.value = '';
      this.graphName.touched = false;
      this.graphDescription.value = '';
      this.graphDescription.touched = false;
    }
  };

  openRenameGraphModal = () => {
    if (this.selectedGraph) {
      this.graphName.value = this.selectedGraph.name;
      this.graphDescription.value = this.selectedGraph.description || '';
      this.isRenameGraphModalOpen = true;
    }
  };

  closeRenameGraphModal = () => {
    this.isRenameGraphModalOpen = false;
    this.graphName.value = '';
    this.graphName.touched = false;
    this.graphDescription.value = '';
    this.graphDescription.touched = false;
  };

  openDeleteGraphModal = () => {
    this.isDeleteGraphModalOpen = true;
  };

  closeDeleteGraphModal = () => {
    this.isDeleteGraphModalOpen = false;
  };

  createGraph = async (type: components['schemas']['media_model_SourceType']) => {
    this.graphName.touched = true;

    if (this.graphName.value && !this.graphName.error && !this.graphDescription.error) {
      this.isAddGraphModalLoading = true;
      const newGraph = await this.api.createGraph(type, this.graphName.value, this.graphDescription.value);
      this.opts.analyticsService.track(AnalyticsActivity.kgAddedGraph, {
        name: this.graphName.value,
        description: this.graphDescription.value,
        type:
          type === KnowledgeGraphFilesViewType.enum.Connector
            ? KnowledgeGraphFilesViewAnalyticsType.enum.DataConnector
            : type,
      });
      this.isAddGraphModalLoading = false;
      this.setSelectedGraph(newGraph);
      this.api.pagination.setExtra({ graphId: newGraph.id });
      this.closeAddGraphModal();
      this.reloadGraphs();
    }
  };

  updateExtraArgsDebounced = debounce((extraArgs: TPaginatedKnowledgeGraphFilesExtraArgs) => {
    this.api.pagination.setExtra(extraArgs);
  }, 500);

  updateArgs = (args: TPaginatedKnowledgeGraphFilesArgs) => {
    this.api.pagination.setArgs(args);
  };

  updateGraph = async () => {
    if (this.selectedGraph && this.graphName.value && !this.graphName.error && !this.graphDescription.error) {
      const updatedGraph = await this.api.updateGraph(
        this.selectedGraph.id,
        this.graphName.value,
        this.graphDescription.value,
      );
      this.closeRenameGraphModal();
      this.reloadGraphs();
      this.setSelectedGraph(updatedGraph);
      this.api.pagination.setExtra({ graphId: updatedGraph.id });
      this.opts.onRenameGraphSuccess();
    }
  };

  deleteGraph = async () => {
    if (this.selectedGraph) {
      const { name } = this.selectedGraph;
      this.closeDeleteGraphModal();
      await this.api.deleteGraph(this.selectedGraph.id);
      await this.reloadGraphs();
      this.opts.onDeleteGraphSuccess(name);
      this.setSelectedGraph(undefined);
      this.api.pagination.setExtra({ graphId: undefined });
    }
  };
}
