import type React from 'react';

import cx from 'classnames';

import {
  TAssetToDelete,
  getDateFormatMMMYYYY,
  getFullDateFormatWithTime,
  noop,
  openNewTab,
} from '@writercolab/common-utils';
import { getDeletionBannerInfo } from '@writercolab/data-retention-utils';
import { type TRetentionConfig } from '@writercolab/models';
import { ALLOWED_QUILL_FORMATS } from '@writercolab/quill-delta-utils';
import { ReactQuill } from '@writercolab/react-quill';
import {
  Button,
  ButtonTypes,
  DataRetentionDeletionBanner,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import TitleWithDescriptionRow from 'components/generic/TitleWithDescriptionRow';
import DraftInputs from 'components/molecules/DraftInputs';
import DraftOutputs from 'components/molecules/DraftOutputs';

import { DraftFeedback, type IDraftFeedbackValues } from '@web/component-library';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { ROUTE } from 'services/config/routes';
import { useAppState } from 'state';
import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';
import { getEventClipsFromInputs } from 'utils/draftsUtils';

import { type EditOutputPanelUiModel, TOutputsModals } from './EditOutputPanelModel.ui';
import { getAccess } from './utils';

import styles from './EditOutputPanel.module.css';

interface IEditOutputPanel {
  model: EditOutputPanelUiModel;
  dataRetentionPreferences?: TRetentionConfig | null;
  isPreviewMode?: boolean;
}

export const EditOutputPanel: React.FC<IEditOutputPanel> = observer(
  ({ model, dataRetentionPreferences, isPreviewMode = false }) => {
    const {
      appModel: { organizationId, teamId, analyticsService, commandsModel },
    } = useAppState();
    const modalType = isPreviewMode ? TOutputsModals.enum.expandedOutputPanel : TOutputsModals.enum.outputPanel;

    const { shouldShowDataDeletionBanner, readableDeletionDate } = getDeletionBannerInfo(
      model.selectedOutput?.creationTime,
      TAssetToDelete.enum.Draft,
      dataRetentionPreferences,
    );

    const outputAccess = () => {
      if (model.selectedOutput) {
        return getAccess(model.selectedOutput);
      }

      return null;
    };

    const handleDeleteOutput = () => {
      analyticsService.track(AnalyticsActivity.outputDeleted, {
        origin: 'my_work',
        output_id: String(model.selectedOutput?.id),
        mode: 'sidebar',
      });

      model.deleteOutput(Number(model.selectedOutput?.id));
    };

    const handleToggleFeedback = () => {
      model.toggleFeedbackOpen(modalType);
    };

    const handleToggleInputs = () => {
      model.toggleInputsOpen(modalType);
    };

    const handleCopyOutput = () => {
      if (model.selectedOutput) {
        analyticsService.track(AnalyticsActivity.outputCopied, {
          origin: 'my_work',
          output_id: String(model.selectedOutput?.id),
          mode: 'sidebar',
        });

        model.copyOutput(model.selectedOutput);
      }
    };

    const handleExpandOutput = () => {
      model.modalsManager.showModal(TOutputsModals.enum.expandedOutputPanel);
    };

    const handleOpenInNewDoc = () => {
      if (model.selectedOutput) {
        analyticsService.track(AnalyticsActivity.outputUseInNewDocButtonClicked, {
          origin: 'my_work',
          output_id: String(model.selectedOutput?.id),
          mode: 'sidebar',
        });

        model.openInNewDoc(model.selectedOutput);
      }
    };

    const handleGoToDocument = () => {
      const access = outputAccess();

      if (access?.isEventTakeaways) {
        openNewTab(ROUTE.toEditorWithEventTakeaways(organizationId, teamId, model.selectedOutput?.document?.id));
      } else if (access?.isSeoBlogBuilder) {
        openNewTab(ROUTE.toEditorWithBlogBuilder(organizationId, teamId, model.selectedOutput?.document?.id));
      } else {
        openNewTab(
          ROUTE.toDocumentDrafts(
            organizationId,
            teamId,
            model.selectedOutput?.document?.id,
            model.selectedOutput?.template?.id,
          ),
        );
      }
    };

    const handleDownloadMediaFile = (fileId: string, filename: string): Promise<void> => {
      return model.downloadMediaFile({ fileId, filename });
    };

    const handleDownloadMediaTranscript = (fileId: string, filename: string): Promise<void> => {
      return model.downloadMediaFileTranscript({ fileId, filename });
    };

    const handleSubmitOutputFeedback = (feedbackPayload: IDraftFeedbackValues) => {
      if (model.selectedOutput?.document) {
        model.rateOutput(model.selectedOutput.id, model.selectedOutput.document?.id, feedbackPayload);
        model.toggleFeedbackOpen(modalType);
      }
    };

    const draftDocumentDate = `${model.selectedOutput?.document?.title} ${getDateFormatMMMYYYY(
      model.selectedOutput?.document?.creationTime,
    )}`;

    const documentDateIcon = (
      <span className={styles.goToDoc} onClick={handleGoToDocument}>
        <Icon name={IconVariant.OPEN_LINK} />
      </span>
    );

    const openedDraftTemplateName = getFullDateFormatWithTime(model.selectedOutput?.creationTime || '');
    const draftRewriteOption = commandsModel.rewritePrompts.find(
      prompt => prompt.id === model.selectedOutput?.reWritePromptId,
    );

    const handleDataRetentionLearnMoreClick = () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL);

    if (!model.selectedOutput) {
      return null;
    }

    return (
      <div
        className={cx(styles.outputContainer, {
          [styles.isOutputPreview]: isPreviewMode,
        })}
      >
        <>
          <div className={styles.buttonGroup}>
            <Tooltip
              title="Delete output"
              placement={isPreviewMode ? 'top' : 'bottom'}
              tooltipTitleClassName={styles.semiBold}
            >
              <Button
                onClick={handleDeleteOutput}
                icon={<Icon name={IconVariant.TRASH} />}
                type={ButtonTypes.GRAY}
                round
              />
            </Tooltip>

            <Tooltip
              tooltipTitleClassName={styles.semiBold}
              title="Rate this output"
              placement={isPreviewMode ? 'top' : 'bottom'}
            >
              <Button
                onClick={handleToggleFeedback}
                icon={<Icon name={IconVariant.FEEDBACK} />}
                type={ButtonTypes.GRAY}
                round
              />
            </Tooltip>

            <Tooltip
              tooltipTitleClassName={styles.semiBold}
              title="Copy text"
              placement={isPreviewMode ? 'top' : 'bottom'}
            >
              <Button
                icon={<Icon name={IconVariant.COPY_PASTE} />}
                type={ButtonTypes.GRAY}
                onClick={handleCopyOutput}
                round
              />
            </Tooltip>

            {!isPreviewMode && (
              <div className={styles.expandWrapper}>
                <Tooltip tooltipTitleClassName={styles.semiBold} title="Preview" placement="bottom">
                  <Button
                    className={styles.expandButton}
                    icon={<Icon name={IconVariant.EXPAND_ALL_SCREEN} width={13} height={13} />}
                    type={ButtonTypes.GRAY}
                    onClick={handleExpandOutput}
                    round
                  />
                </Tooltip>
              </div>
            )}

            {!outputAccess()?.isSeoBlogBuilder && (
              <Tooltip
                tooltipTitleClassName={styles.semiBold}
                title="Send output and input to new doc"
                placement={isPreviewMode ? 'top' : 'bottom'}
              >
                <Button
                  className={styles.useDocButton}
                  icon={<Icon name={IconVariant.USE_IN_NEW_DOC} />}
                  type={ButtonTypes.PRIMARY}
                  onClick={handleOpenInNewDoc}
                >
                  <Text variant={TextSize.L} color={TextColor.WHITE} bold>
                    Use in new doc
                  </Text>
                </Button>
              </Tooltip>
            )}
          </div>

          <div
            className={cx(styles.titleGroup, {
              [styles.isOutputPreview]: isPreviewMode,
            })}
          >
            <TitleWithDescriptionRow title="App" description={model.selectedOutput?.template.name} />
            {model.selectedOutput?.document && (
              <TitleWithDescriptionRow title="Document" description={draftDocumentDate} icon={documentDateIcon} />
            )}
            <TitleWithDescriptionRow title="Generated" description={openedDraftTemplateName} />
            {shouldShowDataDeletionBanner && readableDeletionDate && (
              <DataRetentionDeletionBanner
                asset={'Output' as typeof TAssetToDelete.type}
                readableDate={readableDeletionDate}
                className={styles.banner}
                onLearnMoreClick={handleDataRetentionLearnMoreClick}
              />
            )}
            <DraftInputs
              isInputsOpen={model.isInputsOpen[modalType]}
              inputs={model.inputs ?? []}
              media={model.mediaFiles}
              templateInputs={model.templateInputs}
              onToggle={handleToggleInputs}
              downloadMediaFile={handleDownloadMediaFile}
              voiceName={model.voice ?? null}
              rewriteOptionName={draftRewriteOption ? draftRewriteOption.name : null}
            />
          </div>

          <div className={styles.bodyGroup}>
            {model.selectedOutput?.title && (
              <Heading className={styles.heading} variant={HeadingVariant.H3}>
                {model.selectedOutput.title}
              </Heading>
            )}
            <div className={cx(styles.containerEditor, 'writer-editor')}>
              <ReactQuill
                key={model.selectedOutput?.id}
                className={styles.body}
                placeholder=""
                theme="snow"
                defaultValue={model.selectedOutput?.body}
                readOnly
                onChange={noop}
                formats={ALLOWED_QUILL_FORMATS}
                modules={{
                  toolbar: false,
                }}
              />
            </div>
          </div>

          {model?.mediaFiles && (
            <DraftOutputs
              inputMedia={model.mediaFiles}
              downloadMediaFileTranscript={handleDownloadMediaTranscript}
              eventClips={getEventClipsFromInputs(model.inputs ?? [])}
            />
          )}
          <div className={cx(styles.fadeOut, { [styles.isOutputPreview]: isPreviewMode })} />
          {model.isFeedbackOpen[modalType] && (
            <DraftFeedback
              className={cx(styles.outputFeedback, { [styles.isOutputPreview]: isPreviewMode })}
              onClose={handleToggleFeedback}
              onSubmit={handleSubmitOutputFeedback}
            />
          )}
        </>
      </div>
    );
  },
);
