import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import type { DocumentHighlightType, DocumentHighlights, DocumentHighlightsExtended } from '@writercolab/common-utils';
import {
  copyToClipboard,
  fetchDocumentHighlights,
  generateDocumentHighlights,
  generateDocumentHighlightsByType,
} from '@writercolab/common-utils';
import { IconVariant, useCustomSnackbar } from '@writercolab/ui-atoms';

import { extendDocumentHighlights } from '../components/organisms/DocumentHighlights/utils/documentHighlightsUtils';

import { snackbarMessages } from '@web/component-library';
import type { ActionMap } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import analyticsServiceBase from '../services/analytics/analyticsService';
import type { TAppState } from '../state/types';
import { HighlightsCopyContentActivityEventsMap, HighlightsGenerateActivityEventsMap } from '../utils/analyticsUtils';
import { coWriteQuotaExceeded } from '../utils/coWriteUtils';
import { getLogger } from '../utils/logger';

const LOG = getLogger('coWriterContext');

interface IDocumentHighlightsContext {
  documentHighlightsContext: IDocumentHighlightsState;
  onCloseClick: () => void;
  onRegenerateClick: (type: DocumentHighlightType) => void;
  onCopyClick: (text: string, type: DocumentHighlightType) => void;
  onDocumentVersionChange: (documentHash: string) => void;
  onDocumentWordsCountChange: (wordsCount: number) => void;
  genDocumentHighlights: () => Promise<DocumentHighlightsExtended[]>;
}

const DocumentHighlightContext = createContext<IDocumentHighlightsContext>({} as IDocumentHighlightsContext);

interface IDocumentHighlightsState {
  isLoading: boolean;
  isEmpty: boolean;
  isError: boolean;
  docHash: string;
  locked: boolean;
  documentWordsCount: number;
  quotaExceeded: boolean;
  highlightsSource: DocumentHighlights[];
  highlights: DocumentHighlightsExtended[];
}

interface IDocumentHighlightsProvider {
  children?: ReactNode;
  activeDocId?: TAppState['documentId'];
  organizationId: number | undefined;
  teamId: TAppState['teamId'];
  locked: boolean;
  onHighlightsClosed: () => void;
  onLoading: (isLoading: boolean) => void;
}

enum TDocumentHighlightsActionType {
  SetIsLoading = 'isLoading',
  SetIsEmpty = 'isEmpty',
  SetIsError = 'isError',
  SetHighlights = 'highlights',
  SetHighlightsSource = 'highlightsSource',
  SetDocumentHash = 'documentHash',
  SetDocumentWordsCount = 'documentWordsCount',
  SetLocked = 'locked',
  SetQuotaExceeded = 'quotaExceeded',
}

type TDocumentHighlightsPayload = {
  [TDocumentHighlightsActionType.SetIsLoading]: boolean;
  [TDocumentHighlightsActionType.SetIsEmpty]: boolean;
  [TDocumentHighlightsActionType.SetIsError]: boolean;
  [TDocumentHighlightsActionType.SetQuotaExceeded]: boolean;
  [TDocumentHighlightsActionType.SetLocked]: boolean;
  [TDocumentHighlightsActionType.SetDocumentHash]: string;
  [TDocumentHighlightsActionType.SetDocumentWordsCount]: number;
  [TDocumentHighlightsActionType.SetHighlights]: DocumentHighlightsExtended[];
  [TDocumentHighlightsActionType.SetHighlightsSource]: DocumentHighlights[];
};

type TDocumentHighlightsActions = ActionMap<TDocumentHighlightsPayload>[keyof ActionMap<TDocumentHighlightsPayload>];

const initialState: IDocumentHighlightsState = {
  isLoading: true,
  isEmpty: false,
  isError: false,
  locked: true,
  quotaExceeded: false,
  documentWordsCount: 0,
  docHash: '',
  highlights: [],
  highlightsSource: [],
};

const documentHighlightsReducer = (state: IDocumentHighlightsState, action: TDocumentHighlightsActions) => {
  let newState: IDocumentHighlightsState;

  switch (action.type) {
    case TDocumentHighlightsActionType.SetIsLoading:
      newState = { ...state, isLoading: action.payload };
      break;
    case TDocumentHighlightsActionType.SetIsEmpty:
      newState = { ...state, isEmpty: action.payload };
      break;
    case TDocumentHighlightsActionType.SetHighlights:
      newState = { ...state, highlights: action.payload };
      break;
    case TDocumentHighlightsActionType.SetIsError:
      newState = { ...state, isError: action.payload };
      break;
    case TDocumentHighlightsActionType.SetDocumentHash:
      newState = { ...state, docHash: action.payload };
      break;
    case TDocumentHighlightsActionType.SetLocked:
      newState = { ...state, locked: action.payload };
      break;
    case TDocumentHighlightsActionType.SetQuotaExceeded:
      newState = { ...state, quotaExceeded: action.payload };
      break;
    case TDocumentHighlightsActionType.SetHighlightsSource:
      newState = { ...state, highlightsSource: action.payload };
      break;
    case TDocumentHighlightsActionType.SetDocumentWordsCount:
      newState = { ...state, documentWordsCount: action.payload };
      break;
    default:
      newState = { ...state };
      break;
  }

  return newState;
};

const DocumentHighlightsContextProvider: React.FC<IDocumentHighlightsProvider> = ({
  organizationId,
  teamId,
  activeDocId,
  onHighlightsClosed,
  locked,
  onLoading,
  children,
}) => {
  const { enqueueCustomSnackbar } = useCustomSnackbar();
  const [documentHighlightsContext, dispatchDocumentHighlightContext] = useReducer(
    documentHighlightsReducer,
    initialState,
  );

  const onCloseClick = () => onHighlightsClosed();

  const onCopyClick = (text: string, type: DocumentHighlightType) => {
    enqueueCustomSnackbar(snackbarMessages.copy.text, { icon: IconVariant.COPY });
    copyToClipboard({ html: text });
    analyticsServiceBase.track(HighlightsCopyContentActivityEventsMap[type], {
      team_id: teamId,
    });
  };

  useEffect(() => {
    dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsEmpty, payload: true });
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetHighlights,
      payload: [],
    });
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetHighlightsSource,
      payload: [],
    });
  }, []);

  useEffect(() => {
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetLocked,
      payload: locked,
    });
  }, [locked]);

  useEffect(() => {
    const isLoadingItem = !!documentHighlightsContext.highlights.find(item => item.loading);

    onLoading(documentHighlightsContext.isLoading || isLoadingItem);
  }, [documentHighlightsContext.isLoading, onLoading, documentHighlightsContext.highlights]);

  useEffect(() => {
    if (!locked && organizationId && teamId && activeDocId) {
      getDocumentHighlights(organizationId, teamId, activeDocId).then(highlights =>
        dispatchDocumentHighlightContext({
          type: TDocumentHighlightsActionType.SetHighlightsSource,
          payload: highlights,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, teamId, activeDocId, locked]);

  const genDocumentHighlights = async (): Promise<DocumentHighlightsExtended[]> => {
    let highlightsExtended: DocumentHighlightsExtended[] = [];

    try {
      dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsError, payload: false });
      dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsLoading, payload: true });
      const highlights = await generateDocumentHighlights(`${organizationId}`, `${teamId}`, activeDocId!);
      dispatchDocumentHighlightContext({
        type: TDocumentHighlightsActionType.SetIsEmpty,
        payload: isEmpty(highlights),
      });

      highlightsExtended = extendDocumentHighlights(highlights, documentHighlightsContext.docHash);
    } catch (error: any) {
      LOG.error(error);

      if (coWriteQuotaExceeded(error)) {
        onQuotaExceededError();
      } else {
        onAPIGenerateHighlightsError();
      }
    } finally {
      dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsLoading, payload: false });
    }

    return highlightsExtended;
  };

  const getDocumentHighlights = async (organizationId, teamId, activeDocId): Promise<DocumentHighlights[]> => {
    let _highlights: DocumentHighlights[] = [];
    try {
      dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsError, payload: false });
      dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsLoading, payload: true });
      const highlights = await fetchDocumentHighlights(organizationId, teamId, activeDocId);

      if (isEmpty(highlights)) {
        dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsEmpty, payload: true });
        _highlights = await genDocumentHighlights();
      } else {
        _highlights = highlights;
      }
    } catch (error: any) {
      LOG.error(error);

      if (coWriteQuotaExceeded(error)) {
        onQuotaExceededError();
      } else {
        onAPIGenerateHighlightsError();
      }
    } finally {
      dispatchDocumentHighlightContext({ type: TDocumentHighlightsActionType.SetIsLoading, payload: false });
    }

    return _highlights;
  };

  const regenerateDocumentHighlights = async (type: DocumentHighlightType): Promise<DocumentHighlights[]> => {
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetHighlights,
      payload: documentHighlightsContext.highlights.map(highlight => ({
        ...highlight,
        loading: highlight.type === type,
      })),
    });

    return generateDocumentHighlightsByType(`${organizationId}`, `${teamId}`, activeDocId!, type);
  };

  const onQuotaExceededError = () =>
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetQuotaExceeded,
      payload: true,
    });

  const onAPIGenerateHighlightsError = () =>
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetIsError,
      payload: true,
    });

  const onAPIGenerateHighlightError = (type: DocumentHighlightType) =>
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetHighlights,
      payload: documentHighlightsContext.highlights.map(highlight =>
        highlight.type === type
          ? {
              ...highlight,
              error: true,
              loading: false,
            }
          : highlight,
      ),
    });

  const trackGenerateActivity = useCallback(
    async (type: DocumentHighlightType) => {
      if (!teamId) {
        return;
      }

      analyticsServiceBase.track(HighlightsGenerateActivityEventsMap[type], {
        team_id: teamId,
      });
    },
    [teamId],
  );

  const onRegenerateClick = async (type: DocumentHighlightType) => {
    try {
      trackGenerateActivity(type);
      dispatchDocumentHighlightContext({
        type: TDocumentHighlightsActionType.SetHighlights,
        payload: documentHighlightsContext.highlights.map(highlight =>
          highlight.type === type ? { ...highlight, loading: true, error: false } : highlight,
        ),
      });

      const highlights = await regenerateDocumentHighlights(type);

      dispatchDocumentHighlightContext({
        type: TDocumentHighlightsActionType.SetHighlightsSource,
        payload: highlights,
      });
    } catch (error: any) {
      LOG.error(error);

      if (coWriteQuotaExceeded(error)) {
        onQuotaExceededError();
      } else {
        onAPIGenerateHighlightError(type);
      }
    }
  };

  const onDocumentVersionChange = (documentHash: string) =>
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetDocumentHash,
      payload: documentHash,
    });

  const onDocumentWordsCountChange = (wordsCount: number) =>
    dispatchDocumentHighlightContext({
      type: TDocumentHighlightsActionType.SetDocumentWordsCount,
      payload: wordsCount,
    });

  useEffect(() => {
    if (!isEmpty(documentHighlightsContext.docHash)) {
      dispatchDocumentHighlightContext({
        type: TDocumentHighlightsActionType.SetHighlights,
        payload: extendDocumentHighlights(
          documentHighlightsContext.highlightsSource,
          documentHighlightsContext.docHash,
        ),
      });
    }
  }, [documentHighlightsContext.docHash, documentHighlightsContext.highlightsSource]);

  const contextValue = {
    documentHighlightsContext,
    onDocumentVersionChange,
    onDocumentWordsCountChange,
    onRegenerateClick,
    genDocumentHighlights,
    onCloseClick,
    onCopyClick,
  };

  return <DocumentHighlightContext.Provider value={contextValue}>{children}</DocumentHighlightContext.Provider>;
};

export function useDocumentHighlightsContextProvider() {
  const context = useContext(DocumentHighlightContext);

  if (!context) {
    throw new Error('DocumentHighlightContext must be used within the DocumentHighlightsContextProvider');
  }

  return context;
}

export default DocumentHighlightsContextProvider;
