import { INTERNAL_REFERENCE_PREFIX, REFERENCE_COLUMN_NAME } from './consts';
import { AcceptableColumnType } from './types';

export const getFormattedHeaderCells = (headerRow: (string | undefined)[]) =>
  headerRow.map(headCell => headCell?.replaceAll('’', '').replaceAll("'", '').replaceAll(' ', '_').toLowerCase() || '');

export const getValidAnswerVariant = (rawCellValue?: string, columnParams?: AcceptableColumnType) => {
  const formattedValue = String(rawCellValue ?? '')
    ?.replaceAll('’', '')
    .replaceAll("'", '')
    .replaceAll(' ', '_')
    .toLowerCase();
  const columnAnswerVariants = columnParams?.variants;
  let cellValue;

  if (!columnAnswerVariants) {
    cellValue = rawCellValue?.toString();
  } else {
    const foundedAnswerVariant = columnAnswerVariants.find(variant => variant.acceptedValues?.includes(formattedValue));

    if (foundedAnswerVariant) {
      if (foundedAnswerVariant.booleanValue !== undefined) {
        cellValue = foundedAnswerVariant.booleanValue;
      } else if (foundedAnswerVariant.keyForApi !== undefined) {
        cellValue = foundedAnswerVariant.keyForApi;
      } else {
        cellValue = formattedValue;
      }
    } else {
      cellValue = undefined;
    }
  }

  return cellValue;
};

export const findRowByReference = (reference: string, tableData: string[][]): string[] | undefined => {
  if (reference.startsWith(INTERNAL_REFERENCE_PREFIX)) {
    return tableData[+reference.replace(INTERNAL_REFERENCE_PREFIX, '')];
  } else {
    const tableHeaderRow = tableData[0];
    const tableRowsWithValues = tableData.slice(1);
    const referenceColumnIndex = tableHeaderRow.findIndex(headerCell => headerCell === REFERENCE_COLUMN_NAME);

    return tableRowsWithValues.find(row => row[referenceColumnIndex] === reference);
  }
};
