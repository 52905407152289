import { createFirebaseClient } from '@writercolab/firebase';
import type { IFirebaseConfig, ISidebarDataParams } from '@writercolab/types';

import requestService from 'services/request/requestService';

import config from '../../utils/dynamicConfig';

const {
  FIREBASE_API_KEY,
  FIREBASE_DB_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  RECAPTCHA_ENTEPRISE_KEY,
} = config;

export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY || 'no apiKey',
  authDomain: `${FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseId: FIREBASE_DB_ID,
  databaseURL: `https://${FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: FIREBASE_PROJECT_ID || 'no projectId',
  storageBucket: `${FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: FIREBASE_MESSAGING_SENDER_ID || 'no messagingSenderId',
  appId: FIREBASE_APP_ID || 'no appId',
} as IFirebaseConfig;

export const recaptchaEnterpriseKey = RECAPTCHA_ENTEPRISE_KEY;

export const initFirebaseClient = (params: ISidebarDataParams) =>
  createFirebaseClient({
    config: firebaseConfig,
    settings: {
      recaptchaEnterpriseKey: RECAPTCHA_ENTEPRISE_KEY,
      useAppCheck: false,
      firestoreSettings: {
        experimentalAutoDetectLongPolling: true,
      },
    },
    request: requestService.api,
    params,
  });
