import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import { IUserProfile, openNewTab, validateEmail } from '@writercolab/common-utils';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Modal,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import { observer } from 'mobx-react-lite';

import { PRIVACY_PAGE_URL } from '../../../../services/config/constants';

import styles from './styles.module.css';

interface IDeleteAccountModalProps {
  isOpen: boolean;
  userProfile: IUserProfile | undefined;
  handleClose: () => void;
  onSubmit: () => void;
  subscriptionModel: AiAssistantSubscriptionModel;
}

enum InputHtmlElementType {
  EMAIL = 'email',
  PLACEHOLDER = 'PLACEHOLDER',
}

const PLACEHOLDER = 'I’d like to delete my organization';

const freeDescriptionItems = ['Access to Writer’s web app and extensions', 'All of your docs and data'];

const teamDescriptionItems = [
  'Access to Writer’s web app and extensions',
  'All of your docs',
  'All terms and snippets',
  'Style guide, including your drafts and live pages',
  'Suggestion settings',
  'Any other docs in your org',
];

const validPlaceholder = (val: string): boolean => {
  const prepareInput = (val: string): string =>
    val
      .replaceAll(/’|`|"|'/g, '_')
      .trim()
      .toLowerCase();

  return !!(val.length && prepareInput(PLACEHOLDER) === prepareInput(val));
};

export const DeleteAccountModal: React.FC<IDeleteAccountModalProps> = observer(
  ({ isOpen, handleClose, subscriptionModel, userProfile, onSubmit, ...props }) => {
    const [loading, isLoading] = useState(false);
    const [descriptionItems] = useState<string[]>(
      subscriptionModel.isTeam ? teamDescriptionItems : freeDescriptionItems,
    );
    const [canSubmit, setCanSubmit] = useState(false);
    const [email, setEmail] = useState('');
    const [placeholder, setPlaceholder] = useState('');
    const [emailValid, setEmailValid] = useState<null | boolean>(false);
    const [emailValidationError, setEmailValidationError] = useState<null | string>(null);
    const [placeholderValidationError, setPlaceholderValidationError] = useState<null | string>(null);
    const [placeholderValid, setPlaceholderValid] = useState<null | boolean>(false);

    useEffect(() => {
      if (isOpen) {
        setEmail('');
        setPlaceholder('');
        isLoading(false);
        setEmailValidationError(null);
        setPlaceholderValidationError(null);
      }
    }, [isOpen]);

    const onChangeInput = useCallback(
      (e, type: InputHtmlElementType) => {
        const { value } = e.target;

        if (userProfile && type === InputHtmlElementType.EMAIL) {
          setEmailValidationError(null);
          setEmail(value);
          setEmailValid(userProfile.email === value);
        } else if (type === InputHtmlElementType.PLACEHOLDER) {
          setPlaceholderValidationError(null);
          setPlaceholder(value);
          setPlaceholderValid(validPlaceholder(value));
        }
      },
      [userProfile],
    );
    const onSubmitClick = () => {
      isLoading(true);
      onSubmit();
    };

    useEffect(() => {
      setCanSubmit(emailValid === true && placeholderValid === true);
    }, [emailValid, placeholderValid]);

    const _validateEmail = useCallback(
      e => {
        const { value } = e.target;

        if (!value.length || !validateEmail(value) || (userProfile && userProfile.email !== value)) {
          setEmailValidationError('enter a valid email address');
          setEmailValid(false);
        } else {
          setEmailValidationError(null);
          setEmailValid(true);
        }
      },
      [userProfile],
    );

    const _validatePlaceholder = e => {
      const { value } = e.target;

      if (!value.length || !validPlaceholder(value)) {
        setPlaceholderValidationError('invalid phrase');
        setPlaceholderValid(false);
      } else {
        setPlaceholderValidationError(null);
        setPlaceholderValid(true);
      }
    };

    return (
      <Modal
        warning
        open={isOpen}
        handleClose={handleClose}
        title="Delete my organization"
        style={{ width: '426px' }}
        {...props}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalDescriptionContainer}>
            <Text variant={TextSize.XL}>
              To delete your org, and all of your data, confirm your email address and type “I’d like to delete my
              organization” below.
            </Text>
          </div>
          <div className={styles.modalFormContainer}>
            <form>
              <div className={styles.modalFormInput}>
                <InputGroup
                  name="email"
                  value={email}
                  inputType={InputTypes.INPUT}
                  handleChangeInput={e => onChangeInput(e, InputHtmlElementType.EMAIL)}
                  id="email"
                  label="Email"
                  onBlur={_validateEmail}
                  errorText={emailValidationError || undefined}
                />
              </div>
              <div className={styles.modalFormInput}>
                <InputGroup
                  name="placeholder"
                  value={placeholder}
                  inputType={InputTypes.INPUT}
                  handleChangeInput={e => onChangeInput(e, InputHtmlElementType.PLACEHOLDER)}
                  id="placeholder"
                  label="type “I’d like to delete my organization”"
                  onBlur={_validatePlaceholder}
                  errorText={placeholderValidationError || undefined}
                />
              </div>
            </form>
          </div>
          <div className={styles.modalMessageContainer}>
            <div className={styles.modalMessageTitle}>
              <Heading variant={HeadingVariant.H4} className={styles.warningText}>
                But are you sure you want to do this?
              </Heading>
            </div>
            <div className={styles.modalMessageBody}>
              <Text variant={TextSize.XL} bold>
                You’ll permanently lose:
              </Text>
              <ul className={styles.modalMessageList}>
                {descriptionItems.map(i => (
                  <li key={i} className={styles.modalMessageListItem}>
                    <Text variant={TextSize.M}>{i}</Text>
                  </li>
                ))}
              </ul>
              <div className={styles.modalMessageBottom}>
                <Icon name={IconVariant.LOCK_LINE} className={styles.modalMessageBottomIcon} />
                <Text variant={TextSize.M}>
                  Learn more about our data privacy policy{' '}
                  <button
                    type="button"
                    onClick={() => openNewTab(PRIVACY_PAGE_URL)}
                    className={cx(styles.linkButton, styles.clickable)}
                  >
                    here
                  </button>
                  .
                </Text>
              </div>
            </div>
          </div>
          <div className={styles.modalSubmitContainer}>
            <Button
              disabled={!canSubmit}
              className={styles.submitButton}
              type={canSubmit ? ButtonTypes.PRIMARY : ButtonTypes.GRAY}
              content="Delete my organization"
              onClick={onSubmitClick}
              isLoading={loading}
              danger={canSubmit}
            />
          </div>
        </div>
      </Modal>
    );
  },
);

export default DeleteAccountModal;
