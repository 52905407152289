import { useMemo } from 'react';

import type { ITeam } from '@writercolab/common-utils';

import { ANGULAR_ROUTE } from '../services/config/routes';
import type { TAppState } from '../state/types';

export const useNavigateToAngular = (
  currentOrgId: number | undefined,
  currentTeamId?: TAppState['teamId'],
  teams?: ITeam[],
) => {
  const currentTeam = useMemo(
    () => (teams ? teams.find(team => team.id === currentTeamId) : null),
    [currentTeamId, teams],
  );

  const routeToTermBank = () => {
    if (currentTeam) {
      window.location.href = ANGULAR_ROUTE.toTermBank(currentOrgId!, currentTeamId!);
    }
  };
  const routeToLegacyApp = () => {
    window.location.href = ANGULAR_ROUTE.toLegacyApp(currentOrgId!);
  };

  const linkToStyleguide = ANGULAR_ROUTE.toPortal(currentOrgId!);
  const linkToSuggestionReport = ANGULAR_ROUTE.toSuggestionReport(currentOrgId!);
  const linkToActivityReport = ANGULAR_ROUTE.toActivityReport(currentOrgId!);
  const linkToSamlSSO = ANGULAR_ROUTE.toSamlSSO(currentOrgId!);
  const linkToApps = ANGULAR_ROUTE.toApps(currentOrgId!);

  return {
    routeToTermBank,
    routeToLegacyApp,
    linkToStyleguide,
    linkToSuggestionReport,
    linkToActivityReport,
    linkToSamlSSO,
    linkToApps,
  };
};
