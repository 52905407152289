import React from 'react';

import cx from 'classnames';

import { DropdownOption, LinkText, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { OrgDomain, OrgDomainId } from '@web/types';

import { TAccessTypeOptionId } from '../../../context/domainDiscoverabilityPreferencesContext';
import { DomainDiscoverabilityTableRow } from '../../molecules/DomainDiscoverabilityTableRow/DomainDiscoverabilityTableRow';
import InfoIconText from '../../molecules/InfoIconText/InfoIconText';
import { TableLoadingRow } from '../../molecules/TableLoadingRow/TableLoadingRow';

import styles from './styles.module.css';

interface IDomainDiscoverabilityTableProps {
  orgDomains: OrgDomain[];
  onDeleteDomain: (domainId: OrgDomainId) => void;
  orgsListLoading: boolean;
  accessTypeOptions: DropdownOption[];
  teamsOptions: DropdownOption[];
  profileEmailDomain: string;
  onAccessTypeOptionChanged: (domainId: OrgDomainId, accessType: typeof TAccessTypeOptionId.type) => void;
  onTeamOptionChanged: (domainId: OrgDomainId, teamSelectedIds: string[]) => void;
  onJoinRequiresApprovalChange: (domainId: OrgDomainId, joinRequiresApproval: boolean) => void;
  onEnableProfileDomainClick: () => void;
  profileEmailDomainValid: boolean;
  canEnableProfileDomain: boolean;
  showAccessForPasswordUsers: boolean;
  showOrgAccess: boolean;
  showSamlCopy?: boolean;
}

const LOADER_ROWS_COUNT = 3;

enum TableHeader {
  DOMAINS = 'Domains',
  SAML_DOMAINS = 'SAML domains',
  DISCOVERABILITY = 'Discoverability',
  ACCESS = 'Access',
  DEFAULT_TEAM = 'Default team',
  ACCESS_FOR_PASSWORD_USERS = 'Access for password users',
}

const TableHeaderDescription: React.FC<{ header: TableHeader }> = ({ header }) => {
  switch (header) {
    case TableHeader.SAML_DOMAINS:
      return (
        <>
          <Text variant={TextSize.S} color={TextColor.WHITE}>
            These email domains have already been configured from the Writer side for SAML sign-in.
          </Text>
          <br />
          <Text variant={TextSize.S} color={TextColor.WHITE}>
            To add a different email domain not listed here, contact support@writer.com.
          </Text>
        </>
      );
    case TableHeader.DISCOVERABILITY:
      return (
        <Text variant={TextSize.S} color={TextColor.WHITE}>
          When turned on, new users signing up with this email domain can choose to join this organization instead of
          creating their own.
        </Text>
      );
    case TableHeader.ACCESS:
      return (
        <Text variant={TextSize.S} color={TextColor.WHITE}>
          Use this option to decide whether new users added via domain discoverability can immediately join this org or
          should be approved by an org admin first before joining.
        </Text>
      );
    case TableHeader.ACCESS_FOR_PASSWORD_USERS:
      return (
        <>
          <Text variant={TextSize.S} color={TextColor.WHITE}>
            <span className={styles.textBold}>For users signing up with a password:</span> Use this dropdown to decide
            whether these users can immediately join this org or should be approved by an org admin first before
            joining.
          </Text>
          <br />
          <Text variant={TextSize.S} color={TextColor.WHITE}>
            <span className={styles.textBold}>For users signing up via SAML:</span> These users’ access to Writer is
            controlled by your org’s settings with your IdP (Identity Provider).
          </Text>
        </>
      );
    case TableHeader.DEFAULT_TEAM:
      return (
        <Text variant={TextSize.S} color={TextColor.WHITE}>
          Select which default team a new user added to this org via domain discoverability should join.
        </Text>
      );
    default:
      return null;
  }
};

export const DomainDiscoverabilityTable: React.FC<IDomainDiscoverabilityTableProps> = ({
  accessTypeOptions,
  teamsOptions,
  onAccessTypeOptionChanged,
  onTeamOptionChanged,
  onJoinRequiresApprovalChange,
  orgDomains,
  onDeleteDomain,
  orgsListLoading,
  onEnableProfileDomainClick,
  profileEmailDomain,
  canEnableProfileDomain,
  showAccessForPasswordUsers,
  showOrgAccess,
  showSamlCopy,
}) => (
  <>
    <div className={styles.containerTable}>
      <>
        <div className={cx(styles.containerTableHead, styles.tableGridRow)}>
          <InfoIconText
            title={showSamlCopy ? TableHeader.SAML_DOMAINS : TableHeader.DOMAINS}
            description={showSamlCopy ? <TableHeaderDescription header={TableHeader.SAML_DOMAINS} /> : null}
          />
          <InfoIconText
            title={TableHeader.DISCOVERABILITY}
            description={<TableHeaderDescription header={TableHeader.DISCOVERABILITY} />}
          />
          {showOrgAccess && (
            <InfoIconText
              title={showAccessForPasswordUsers ? TableHeader.ACCESS_FOR_PASSWORD_USERS : TableHeader.ACCESS}
              description={
                <TableHeaderDescription
                  header={showAccessForPasswordUsers ? TableHeader.ACCESS_FOR_PASSWORD_USERS : TableHeader.ACCESS}
                />
              }
            />
          )}
          <InfoIconText
            title={TableHeader.DEFAULT_TEAM}
            description={<TableHeaderDescription header={TableHeader.DEFAULT_TEAM} />}
          />
        </div>
        <div className={styles.containerTableBody}>
          {orgsListLoading
            ? [...Array(LOADER_ROWS_COUNT)].map((_, i) => <TableLoadingRow key={`table-data-loading-row-${i}`} />)
            : orgDomains.map(d => (
                <DomainDiscoverabilityTableRow
                  onDeleteDomain={onDeleteDomain}
                  key={`table-data-row-${d.id}-${d.domain}`}
                  domain={d}
                  onAccessTypeOptionChanged={onAccessTypeOptionChanged}
                  accessTypeOptions={accessTypeOptions}
                  teamsOptions={teamsOptions}
                  onTeamOptionChanged={onTeamOptionChanged}
                  showOrgAccess={showOrgAccess}
                  onJoinRequiresApprovalChange={onJoinRequiresApprovalChange}
                />
              ))}
          {canEnableProfileDomain && (
            <div className={cx(styles.tableGridRow, styles.tableGridRow2columns)}>
              <div className={styles.containerTableRowItem}>
                <Text variant={TextSize.L}>{profileEmailDomain}</Text>
              </div>
              <div className={styles.containerTableRowItem}>
                <div className={styles.domainStatusSwitcherText}>
                  <Text variant={TextSize.S} color={TextColor.GREY2}>
                    You’re signed in with this domain. Would you like to enable it as a{' '}
                    {showSamlCopy ? 'SAML' : 'discoverable'} domain?
                  </Text>
                  <LinkText className={styles.domainStatusSwitcher} onClick={onEnableProfileDomainClick}>
                    <Text variant={TextSize.S} medium>
                      Enable domain
                    </Text>
                  </LinkText>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    </div>
    {showSamlCopy && (
      <div className={styles.additionalDomainText}>
        <Text variant={TextSize.S} color={TextColor.GREY2}>
          To add a different domain to your SAML configuration, contact support@writer.com.
        </Text>
      </div>
    )}
  </>
);

export default DomainDiscoverabilityTable;
