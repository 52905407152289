import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type AIStudioReportUsageResponse = components['schemas']['billing_dto_AIStudioReportUsage'];
export type AIStudioReportUsageProduct = components['schemas']['billing_dto_ProductUsage'];
export type AIStudioReportUsageProductNonCombined = components['schemas']['billing_dto_ProductUsage_ModelProductUsage'];
export type AIStudioReportUsageProductCombined =
  components['schemas']['billing_dto_ProductUsage_ModelProductCombinedUsage'];

export type AIStudioReportUsageProductMap = {
  pricing: number;
  usage: number;
  cost: number;
  priceType?: string;
};

export type AIStudioReportUsageUiMap = {
  modelId: string;
  input?: AIStudioReportUsageProductMap;
  output?: AIStudioReportUsageProductMap;
  image?: AIStudioReportUsageProductMap;
  video?: AIStudioReportUsageProductMap;
  knowledgeGraph?: AIStudioReportUsageProductMap;
  pdfParser?: AIStudioReportUsageProductMap;
  apiRequest?: AIStudioReportUsageProductMap;
};

export const TNonCombinedProductType = new Enum('KnowledgeGraphHosting', 'OCR', 'ApiRequest');
export const TAIStudioReportUsagePrice = new Enum('MegaWords', 'MegaTokens');
