import { makeAutoObservable } from 'mobx';

import type { AiAssistantSubscriptionModel } from '@writercolab/models';
import type { components } from '@writercolab/network';
import type { IOrgPermission } from '@writercolab/types';
import { UserRole } from '@writercolab/types';

import type { TeamsModel } from './teams';

interface IPermissionModelParams {
  permissions: Pick<components['schemas']['auth_dto_UserPermissionsResponse'], 'organizations'>;
  organizationId?: number;
  teamId?: number;
  userId?: number;
  teamsModel: TeamsModel;
  assistantSubscriptionModel: AiAssistantSubscriptionModel;
}

export class PermissionModel {
  constructor(private opts: IPermissionModelParams) {
    makeAutoObservable(this);
  }

  get orgPermissions(): IOrgPermission | undefined {
    return this.opts.permissions.organizations[this.opts.organizationId ?? 0];
  }

  get isTeamAdmin() {
    return this.orgPermissions?.teams[this.opts.teamId ?? 0] === 'admin';
  }

  get isTeamMember() {
    return this.orgPermissions?.teams[this.opts.teamId ?? 0] === 'member';
  }

  get isAdminOfAnyTeam() {
    return PermissionModel.checkIfAdminOfAnyTeam(this.orgPermissions);
  }

  get isOrganizationAdmin() {
    return this.orgPermissions ? this.orgPermissions.role === UserRole.ADMIN : undefined;
  }

  get hasAccessToConsole() {
    return this.orgPermissions ? !!this.orgPermissions.consoleRole : undefined;
  }

  get isAiStudioViewOnlyUser() {
    return this.orgPermissions ? this.orgPermissions.consoleRole === 'View' : undefined;
  }

  get hasAccessToBuilderRole() {
    return !this.opts.assistantSubscriptionModel.isFree && this.hasAccessToConsole && !this.isAiStudioViewOnlyUser;
  }

  /**
   * This function checks if the user has draft access to AI studio.
   * The impact of this role is the user cannot access dev applications.
   * @see https://dev.writer.com/home/account_management#account-management
   */
  get isAiStudioDraftUser() {
    return this.orgPermissions ? this.orgPermissions.consoleRole === 'Draft' : undefined;
  }

  get isInternalWriterUser() {
    return this.orgPermissions ? this.orgPermissions.internalAtWriter : undefined;
  }

  isTeamAdminOf = (teamId: number): boolean => this.orgPermissions?.teams[teamId] === 'admin';

  hasAccessToTeam = (teamId: number) => !!this.orgPermissions?.teams[teamId];

  hasAccessToOrg = (orgId: number) => !!this.opts.permissions.organizations[orgId];

  /*
   * This function checks if the user has admin access to the entity across AI studio
   * If user has FullAccess role, he has access to all entities
   * If user has Draft role, he has access only to his own entities
   * */
  hasStudioAdminAccess = (entityOwnerId?: number) => {
    if (!this.opts.permissions || !this.opts.organizationId || !this.opts.userId) {
      return false;
    }

    const { consoleRole } = this.opts.permissions.organizations[this.opts.organizationId];

    const isFullAccessUser = consoleRole === 'FullAccess';
    const isDraftUser = consoleRole === 'Draft';

    if (isFullAccessUser) {
      return true;
    }

    if (isDraftUser && !entityOwnerId) {
      return true;
    }

    return this.opts.userId === entityOwnerId;
  };

  get isEnterpriseAdmin() {
    return this.opts.assistantSubscriptionModel.isEnterprise && (this.isAdminOfAnyTeam || this.isOrganizationAdmin);
  }

  get isEnterpriseOrgAdmin() {
    return this.opts.assistantSubscriptionModel.isEnterprise && this.isOrganizationAdmin;
  }

  get isEnterpriseTeamAdmin() {
    return this.opts.assistantSubscriptionModel.isEnterprise && this.isAdminOfAnyTeam;
  }

  static checkIfAdminOfAnyTeam = (orgPermissions?: IOrgPermission) =>
    orgPermissions && Object.keys(orgPermissions?.teams).some(id => orgPermissions.teams[id] === 'admin');
}
