import type { ChangeEvent } from 'react';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BillingProduct, RemovePersonActionType, UserTeamRole, isUserInvited } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  LabelledTags,
  Modal,
  SearchBar,
  SizeTypes,
  Tooltip,
} from '@writercolab/ui-atoms';
import { FilterIndicator } from '@writercolab/ui-molecules';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { UpdateBillingGroup } from 'components/molecules/UpdateBillingGroup';

import type { IAddPeopleOption } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { DROPDOWN_DOWNLOAD_OPTIONS, usePeopleContext } from '../../../context/peopleContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { ROUTE } from '../../../services/config/routes';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { goToBilling } from '../../../utils/navigationUtils';
import { calcMaxTeamSize } from '../../../utils/teamUtils';
import { InviteTeammates } from '../../molecules/InviteTeammates/InviteTeammates';
import UIModelInviteTeammates from '../../molecules/InviteTeammates/UIModelInviteTeammates';
import PageTitle from '../../molecules/PageTitle';
import PeopleList from '../../molecules/PeopleList';
import type { IModifiedUser } from '../../molecules/PeopleList/PeopleListRow';
import TeamStatsSection from '../../molecules/TeamStatsSection';
import DeletePersonAdvanced from '../../organisms/DeletePersonAdvanced/DeletePersonAdvanced';
import { ImportItemType } from '../../organisms/ImporterPopup/types';
import Error404Page from '../Error404Page';
import { headerTooltipText } from '../termsAndSnippetsShared/common';
import { SendInviteModal } from './modals';
import DeletePersonModal from './modals/DeletePersonModal';
import RemovePersonModal from './modals/RemovePersonModal';

import styles from './styles.module.css';

interface IPeoplePageProps {
  isDisabled?: boolean;
}

const TooltipText = {
  disabledAddButton: 'To invite a user, ask an org admin',
  disabledSSAddButton: 'Only org admins can invite people',
};

export const PeoplePage: React.FC<IPeoplePageProps> = observer(({ isDisabled }) => {
  const navigate = useNavigate();
  const {
    appState,
    appModel: { permissionsModel, assistantSubscription, analyticsService },
  } = useAppState();
  const { organization, userProfile } = appState;
  const { isTeam, isEnterprise, isMultiTeam, limits } = assistantSubscription;

  const {
    people,
    orgId,
    teamId,
    isLoading,
    infiniteRefSentry,
    currentTeam,
    isTeamUsersPage,
    organizationAdmins,
    filtersCount,
    handleSearch,
    searchValue,
    handleMakeAdmin,
    handleRemovePerson,
    handleResendInvite,
    handleSendInvite,
    handleAddUserToTeam,
    handlePeopleSortingChange,
    handleRemovePersonFromTeam,
    handleSeatsNumberChange,
    handleDownloadPeoplesList,
    handleRejectPendingUser,
    handleApprovePendingUser,
    handleUserFilterChange,
    handleBillingGroupFilterChange,
    handleResetFilters,
    fetchPeopleList,
    billingGroups,
    userFilterVal,
    billingGroupFilterVal,
    editBillingGroup,
  } = usePeopleContext();
  usePageTitle(isTeamUsersPage ? 'Teammates' : 'People');

  const [isSendInviteOpen, setIsSendInviteOpen] = useState(false);
  const [isDeletePersonOpen, setIsDeletePersonOpen] = useState(false);
  const [isRemoveTeammateOpen, setIsRemoveTeammateOpen] = useState(false);
  const [isUpdateBillingGroupOpen, setIsUpdateBillingGroupOpen] = useState(false);
  const [person, setActivePerson] = useState<IModifiedUser | null>(null);
  const teamSizeLimit = calcMaxTeamSize(limits?.user?.limit);
  const teamSizeLimitReached = !isEnterprise && (limits?.user?.value || 0) >= teamSizeLimit;
  const billingGroupOptions = billingGroups.map(group => ({ label: group.name, value: group.id }));

  const handleEditBillingGroup = useCallback(
    (billingGroupId: number) => {
      if (!person) {
        return;
      }

      editBillingGroup({
        billingGroupId,
        userIds: [person.id],
      });

      setIsUpdateBillingGroupOpen(false);
    },
    [editBillingGroup, person],
  );

  const inviteTeammates = async (invitees: IAddPeopleOption[]) => {
    handleAddUserToTeam({
      orgId,
      teamId,
      role: UserTeamRole.MEMBER,
      invitees,
    });
    fetchPeopleList();
    setIsSendInviteOpen(false);
  };

  const uiModelInviteTeammatesModel = useMemo(
    () =>
      new UIModelInviteTeammates({
        request: requestService.api,
        onSubmit: inviteTeammates,
        teamId,
        orgId,
        teamSizeLimitReached,
        teamSizeLimit,
      }),
    [orgId, teamId, teamSizeLimit, teamSizeLimitReached, people],
  );

  const access = {
    isTeamPage: isTeamUsersPage,
    isPeoplePage: !isTeamUsersPage,
    showViewOnlyTag: permissionsModel?.isTeamMember,
    addPeopleDisabled: permissionsModel?.isTeamMember || teamSizeLimitReached,
    hideMoreOptions: permissionsModel?.isTeamMember,
    hideInvitePendingDropdown: permissionsModel?.isTeamMember,
    hidePendingApprovalDropdown: !permissionsModel?.isOrganizationAdmin,
    teamSizeLimitReached,
  };

  const handleClearInput = () => {
    handleSearch('');
  };

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    handleSearch(e.target.value);
  };

  const actions = {
    make_admin: handleMakeAdmin,
    // delete user from the org
    delete: (person: IModifiedUser) => {
      setIsDeletePersonOpen(!isDeletePersonOpen);
      setActivePerson(person);
    },
    // remove user from the team
    remove: (person: IModifiedUser) => {
      setIsRemoveTeammateOpen(!isRemoveTeammateOpen);
      setActivePerson(person);
    },
    resend: handleResendInvite,
    approve: handleApprovePendingUser,
    reject: handleRejectPendingUser,
    update_billing_group: (person: IModifiedUser) => {
      setIsUpdateBillingGroupOpen(!isUpdateBillingGroupOpen);
      setActivePerson(person);
    },
  };

  const onBillingLockAction = () => {
    if (teamId) {
      analyticsService.track(AnalyticsActivity.clickPurchaseTeamNow, {
        clicked_from: 'team_people',
      });
    }

    organization?.id && goToBilling(navigate, organization?.id, BillingProduct.TEAM);
  };

  useEffect(() => {
    analyticsService.track(isTeamUsersPage ? AnalyticsActivity.teammatesViewed : AnalyticsActivity.peopleViewed, {});
  }, [analyticsService, isTeamUsersPage]);

  const handleSingleTeamPerson = useCallback(
    (user: IModifiedUser) => {
      if (userProfile) {
        handleRemovePerson(user, RemovePersonActionType.ASSIGN_ALL_DOCS, userProfile);
      }
    },
    [handleRemovePerson, userProfile],
  );

  const handleCloseBillingGroupModel = () => {
    setIsUpdateBillingGroupOpen(false);
  };

  const navigateToPeoplePage = () => {
    navigate(ROUTE.toPeople(orgId));
  };

  const inviteButtonTooltipText = useMemo(() => {
    let inviteButtonTooltipText = !isMultiTeam ? TooltipText.disabledSSAddButton : TooltipText.disabledAddButton;

    if (teamSizeLimitReached) {
      inviteButtonTooltipText = `You’ve reached your max of ${teamSizeLimit} users`;
    }

    return inviteButtonTooltipText;
  }, [isMultiTeam, teamSizeLimitReached, teamSizeLimit]);

  if (isDisabled) {
    return <Error404Page />;
  }

  if (!userProfile) {
    return null;
  }

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.peopleList}>
        <div className={styles.peopleHeadline}>
          <div className={styles.title}>
            <PageTitle title={isTeamUsersPage ? 'Teammates' : 'People'} teamName={currentTeam?.name || ''} />
            {access.showViewOnlyTag && (
              <Tooltip
                className={styles.indicatorContainer}
                title={headerTooltipText.viewOnlyTeamMember(ImportItemType.TEAMMATES)}
              >
                <div>
                  <LabelledTags bgColor="var(--classic-grey-1)">VIEW ONLY</LabelledTags>
                </div>
              </Tooltip>
            )}
          </div>
          {permissionsModel?.isOrganizationAdmin && (
            <Dropdown
              options={DROPDOWN_DOWNLOAD_OPTIONS}
              onPrimaryOptionClickAction={handleDownloadPeoplesList}
              placement={DropdownPlacement.BOTTOM_RIGHT}
              itemsType={ItemsTypes.ACTION}
              testId="people-download-dropdown"
              trigger={
                <Tooltip title="Download">
                  <Button
                    className={styles.downloadButton}
                    icon={<Icon name={IconVariant.DOWNLOAD} />}
                    type={ButtonTypes.GRAY}
                    size={SizeTypes.MEDIUM}
                    round
                  />
                </Tooltip>
              }
            />
          )}
          {!!filtersCount && (
            <div className={styles.indicatorContainer}>
              <FilterIndicator filtersAmount={filtersCount} onClose={handleResetFilters} testId="people-filter-count" />
            </div>
          )}
          <SearchBar
            id="search"
            value={searchValue}
            placeholder="Search"
            onChange={handleSearchInput}
            handleClearInput={handleClearInput}
            className={styles.searchBar}
          />
          <Tooltip title={inviteButtonTooltipText} disabled={!access.addPeopleDisabled}>
            <Button
              type={ButtonTypes.PRIMARY}
              className={styles.invitePeopleButton}
              size={SizeTypes.MEDIUM}
              icon={<Icon name={IconVariant.ADD} />}
              onClick={() => setIsSendInviteOpen(!isSendInviteOpen)}
              content={isEnterprise && isMultiTeam ? 'Add people' : 'Invite people'}
              disabled={access.addPeopleDisabled}
            />
          </Tooltip>
        </div>
        {currentTeam && <TeamStatsSection team={currentTeam} adminTitle="Team admins" compact />}
        <div className={styles.listWrapper}>
          <PeopleList
            list={people}
            actions={actions}
            isLoading={isLoading}
            handleSortingChange={handlePeopleSortingChange}
            hideMoreOptions={access.hideMoreOptions}
            isTeamUsersPage={isTeamUsersPage}
            isSelfServe={!isMultiTeam}
            hideInvitePendingDropdown={access.hideInvitePendingDropdown}
            hidePendingApprovalDropdown={access.hidePendingApprovalDropdown}
            handleUserStatusFilterChange={handleUserFilterChange}
            handleBillingGroupFilterChange={handleBillingGroupFilterChange}
            infiniteRefSentry={infiniteRefSentry}
            handleClearFilters={handleResetFilters}
            hideBillingGroups={!isEnterprise || (isTeamUsersPage && isMultiTeam)}
            billingGroups={billingGroups}
            userFilterVal={userFilterVal}
            billingGroupFilterVal={billingGroupFilterVal}
          />
        </div>
      </div>
      {isSendInviteOpen &&
        (isTeamUsersPage && isMultiTeam && isEnterprise && currentTeam ? (
          <Modal
            open={isSendInviteOpen}
            modalTitleClassName={styles.modalTitle}
            handleClose={() => setIsSendInviteOpen(false)}
            title={`Add people to ${currentTeam?.name} team`}
            style={{ width: '460px' }}
          >
            <InviteTeammates
              model={uiModelInviteTeammatesModel}
              onNavigateToPeoplePageClick={navigateToPeoplePage}
              showWarningsForTeamUser={!permissionsModel?.isOrganizationAdmin}
              showPreventAddUserWarning={isTeamUsersPage}
            />
          </Modal>
        ) : (
          <SendInviteModal
            isOpen={isSendInviteOpen}
            changeModalState={() => setIsSendInviteOpen(!isSendInviteOpen)}
            onSubmit={handleSendInvite}
            orgId={orgId}
            onSeatsChange={handleSeatsNumberChange}
            teamSizeLimit={teamSizeLimit}
            billingGroupOptions={billingGroupOptions}
          />
        ))}

      <RemovePersonModal
        person={person}
        orgName={organization?.name || ''}
        isOpen={isRemoveTeammateOpen}
        onClose={() => setIsRemoveTeammateOpen(false)}
        isEnterpriseAccount={isEnterprise}
        isMultiTeamAccount={isMultiTeam}
        modalText={{
          title: `${isEnterprise && !isMultiTeam ? 'Delete' : 'Remove'} user from team`,
          description: personName => `Are you sure you want to remove ${personName} from ${currentTeam?.name}?`,
        }}
        submitButtonText={`Yes, ${isEnterprise && !isMultiTeam ? 'delete' : 'remove'}`}
        onSubmit={handleRemovePersonFromTeam}
      />

      {((access.isTeamPage && !isEnterprise && !isMultiTeam) ||
        (access.isPeoplePage && isMultiTeam) ||
        (access.isTeamPage && isMultiTeam) ||
        (!isMultiTeam && access.isTeamPage)) &&
      person &&
      !isUserInvited(person) ? (
        <DeletePersonAdvanced
          isOpen={isDeletePersonOpen}
          onSubmit={handleRemovePerson}
          teammate={person}
          userProfile={userProfile}
          organizationAdmins={organizationAdmins}
          onClose={() => setIsDeletePersonOpen(false)}
        />
      ) : (
        <DeletePersonModal
          userProfile={userProfile}
          isOpen={isDeletePersonOpen}
          onClose={() => setIsDeletePersonOpen(false)}
          onSubmit={handleSingleTeamPerson}
          person={person}
          orgName={organization?.name || ''}
          modalText={{
            title: 'Delete user from organization',
            description: (personName, orgName) => `Are you sure you want to delete ${personName} from ${orgName}?`,
          }}
        />
      )}
      {!isTeam && !isEnterprise && <BillingLock type={BillingLockType.TEAMS} onActionCallback={onBillingLockAction} />}

      <Modal open={isUpdateBillingGroupOpen} handleClose={handleCloseBillingGroupModel} style={{ width: '445px' }}>
        <UpdateBillingGroup
          billingGroupOptions={billingGroupOptions}
          onSubmit={handleEditBillingGroup}
          defaultBillingGroupId={person?.billingGroup?.id}
        />
      </Modal>
    </div>
  );
});

export default PeoplePage;

PeoplePage.displayName = 'PeoplePage';
