import type React from 'react';

import { copyToClipboard } from '@writercolab/common-utils';
import { Icon, IconVariant, Modal, useCustomSnackbar, useQueueWorkerNotifications } from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import type { PromptLibraryModalModel } from '.';
import { PromptLibraryEventSources } from '../../../constants/PromptLibrary';
import { getLogger } from '../../../utils/logger';
import { PromptLibrary } from '../PromptLibrary';

import styles from './styles.module.css';

const LOG = getLogger('PromptLibraryModal');

interface IPromptLibraryModal {
  model: PromptLibraryModalModel;
  teamName: string;
  invocationContext: PromptLibraryEventSources;
  onNavigateToLearningCenter?: () => void;
  onUsePrompt?: (prompt: string) => void;
  onClose?: () => void;
  emptyStateAvailable?: boolean;
  showAddTeamPrompts?: boolean;
  showContactText?: boolean;
  onManageTeamPromptsClick?: () => void;
  onBackClick?: () => void;
}

export const PromptLibraryModal: React.FC<IPromptLibraryModal> = observer(
  ({
    model,
    teamName,
    invocationContext,
    onUsePrompt,
    onClose,
    onManageTeamPromptsClick,
    emptyStateAvailable,
    showContactText,
    showAddTeamPrompts,
    onBackClick,
  }) => {
    useQueueWorkerNotifications(model.notificationQueue);
    const { enqueueBasicSnackbar } = useCustomSnackbar();

    const onCopySuccess = () => {
      enqueueBasicSnackbar(
        <span>
          <Icon className={styles.copyPasteIcon} name={IconVariant.COPY_PASTE} />
          Copied to clipboard
        </span>,
      );
    };

    const onCopyToClipboard = (message: string) => {
      if (isEmpty(message)) {
        return;
      }

      const copyResult = copyToClipboard({ text: message });

      if (!copyResult) {
        navigator.clipboard.writeText(message).then(onCopySuccess, err => {
          LOG.error('Failed to copy to clipboard', err);
        });
      } else {
        onCopySuccess();
      }
    };

    return (
      <Modal
        multiModalContainerClassName={styles.modalContainer}
        className={styles.modalContainer}
        handleClose={onClose}
        open
      >
        <PromptLibrary
          onBackClick={onBackClick}
          onManageTeamPromptsClick={onManageTeamPromptsClick}
          emptyStateAvailable={emptyStateAvailable}
          model={model.uiModel}
          teamName={teamName}
          onUsePrompt={invocationContext === PromptLibraryEventSources.ASK_WRITER ? onUsePrompt : undefined}
          onCopyToClipboard={onCopyToClipboard}
          invocationContext={invocationContext}
          showAddTeamPrompts={showAddTeamPrompts}
          showContactText={showContactText}
        />
      </Modal>
    );
  },
);
