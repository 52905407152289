import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import { RequestServiceInitialize, components } from '@writercolab/network';
import { getLogger } from '@writercolab/utils';

interface IExtensionConfigModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: () => number | undefined;
}

const LOG = getLogger('ExtensionConfigModel');

export class ExtensionConfigModel {
  private readonly $extensionConfig: PromisedModel<
    components['schemas']['organization_model_OrganizationExtensionConfig'] | null
  >;

  public loading = false;

  constructor(private opts: IExtensionConfigModelOptions) {
    this.$extensionConfig = new PromisedModel({
      name: '$extensionConfig',
      load: async () => {
        const organizationId = this.opts.organizationId();
        let config: components['schemas']['organization_model_OrganizationExtensionConfig'] | null = null;

        if (!organizationId) {
          return config;
        }

        this.loading = true;

        try {
          const { data } = await this.opts.request.get('/api/organization/v2/{organizationId}/extension/config/v2', {
            params: {
              path: {
                organizationId,
              },
            },
          });

          config = data;
        } catch (e) {
          LOG.error('Failed to load extension config', e);
        } finally {
          runInAction(() => {
            this.loading = false;
          });
        }

        return config;
      },
    });

    makeObservable(this, {
      loading: observable,

      config: computed,

      updateConfig: action.bound,
    });
  }

  async updateConfig(
    config: Omit<components['schemas']['organization_model_OrganizationExtensionConfig'], 'organizationId'>,
  ) {
    try {
      const organizationId = this.opts.organizationId();

      if (!organizationId) {
        return;
      }

      this.loading = true;

      await this.opts.request.put('/api/organization/v2/{organizationId}/extension/config/v2', {
        params: {
          path: {
            organizationId,
          },
        },
        body: config,
      });
    } catch (e) {
      LOG.error('Failed to update extension config', e);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  get config(): components['schemas']['organization_model_OrganizationExtensionConfig'] | null {
    return this.$extensionConfig.value || null;
  }
}
