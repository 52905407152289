import type React from 'react';
import { useEffect, useState } from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';
import type { AppVoiceDropdownModel } from '@writercolab/ui-organisms';
import { AppVoiceDropdown } from '@writercolab/ui-organisms';

import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import styles from '../styles.module.css';

interface IBlogTitleFormProps {
  title: string;
  keywords: string;
  cta: string;
  titleValid: boolean | null;
  titlesGenerated?: string[];
  titleGenerating: boolean;
  generatingTitles?: boolean;
  allowSetupNewVoices: boolean;
  voiceDropdownModel: AppVoiceDropdownModel;
  onSetupNewVoice: () => void;
  onGenerateTitlesClick: () => void;
  onChangeBlogTitle: (blogTitle: string) => void;
  onChangeSeoKeywords: (seoKeywords: string) => void;
  onChangeBlogCta: (blogCta: string) => void;
  isVoiceEnabled: boolean;
}

interface IInputLabelProps {
  label: string;
  description?: string;
}

const InputLabel: React.FC<IInputLabelProps> = ({ label, description }) => (
  <>
    <Text variant={TextSize.XL} bold className={styles.containerFormItemLabel}>
      {label}
    </Text>
    {description && (
      <Text variant={TextSize.XL} color={TextColor.GREY2} className={styles.containerFormItemLabelDescription}>
        {description}
      </Text>
    )}
  </>
);

const GenerateTitlesButton = ({ onClick, isGenerating }) => (
  <div className={styles.generateTitlesContainer}>
    <Button
      size={SizeTypes.XS}
      type={ButtonTypes.BLACK}
      className={styles.generateTitlesButton}
      isLoading={isGenerating}
      onClick={onClick}
    >
      <Icon name={IconVariant.WAND_WHITE} className={styles.generateTitlesButtonIcon} />
      <Text color={TextColor.WHITE} bold>
        Generate similar titles
      </Text>
    </Button>
  </div>
);

const TitlesList = ({ titles, onClick }) => (
  <div className={styles.titlesListContainer}>
    {titles.map(title => (
      <>
        <div className={cx(styles.titlesListItem, styles.clickable)} key={title} onClick={() => onClick(title)}>
          <Text variant={TextSize.XL} bold>
            {title}
          </Text>
        </div>
        <div className={styles.titlesListItemSeparator} />
      </>
    ))}
  </div>
);

const TitleListLoading = ({ count }) => (
  <div className={styles.titlesListContainer}>
    {Array(count)
      .fill(0)
      .map(i => (
        <div key={i} className={styles.titlesListLoader}>
          <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.titlesListContainerLoader} />
        </div>
      ))}
  </div>
);

export const BlogTitleForm: React.FC<IBlogTitleFormProps> = observer(
  ({
    title,
    keywords,
    cta,
    titlesGenerated,
    onGenerateTitlesClick,
    titleGenerating,
    voiceDropdownModel,
    allowSetupNewVoices,
    onSetupNewVoice,
    onChangeBlogTitle,
    onChangeSeoKeywords,
    onChangeBlogCta,
    titleValid,
    isVoiceEnabled,
  }) => {
    const [blogTitleValid, setBlogTitleValid] = useState<boolean | null>(true);
    useEffect(() => setBlogTitleValid(titleValid), [titleValid]);
    const _onChangeTitle = e => {
      setBlogTitleValid(!isEmpty(e.target.value));
      onChangeBlogTitle(e.target.value);
    };
    const _onChangeKeywords = e => onChangeSeoKeywords(e.target.value);
    const _onChangeCta = e => onChangeBlogCta(e.target.value);
    const _onGenerateTitlesClick = () => {
      const titleValid = !isEmpty(title);
      setBlogTitleValid(titleValid);

      if (titleValid) {
        onGenerateTitlesClick();
      }
    };

    const onUseTitleClick = (title: string) => onChangeBlogTitle(title);

    const isVoiceInputVisible = voiceDropdownModel.isVisible && isVoiceEnabled;

    return (
      <div className={styles.containerForm}>
        <div className={styles.containerFormItems}>
          <div className={styles.containerFormItem}>
            <InputGroup
              className={styles.containerFormItemInput}
              id="blogTitle"
              name="blogTitle"
              label={<InputLabel label="Blog title" />}
              inputType={InputTypes.TEXTAREA}
              multiline
              rows={1}
              handleChangeInput={_onChangeTitle}
              value={title}
              errorText={blogTitleValid === false ? 'required' : undefined}
              placeholder="e.g., How to improve your clickthrough rates"
              rounded
              autoFocus
            />
            <GenerateTitlesButton onClick={_onGenerateTitlesClick} isGenerating={titleGenerating} />
            {!isEmpty(titlesGenerated) && !titleGenerating && (
              <TitlesList titles={titlesGenerated} onClick={onUseTitleClick} />
            )}
            {titleGenerating && <TitleListLoading count={5} />}
          </div>

          {isVoiceInputVisible && (
            <div className={styles.containerFormItemVoice}>
              <Heading className={styles.headingVoice} variant={HeadingVariant.H6} bold>
                Voice
              </Heading>
              <AppVoiceDropdown
                model={voiceDropdownModel}
                allowSetupNewVoices={allowSetupNewVoices}
                onSetupNewVoice={onSetupNewVoice}
              />
            </div>
          )}

          <div className={cx(styles.containerFormItem, styles.containerFormItemTol)}>
            <InputGroup
              id="keywords"
              name="keywords"
              label={
                <InputLabel
                  label="SEO keywords (optional)"
                  description="Are there keywords you'd like to optimize for?"
                />
              }
              handleChangeInput={_onChangeKeywords}
              inputType={InputTypes.TEXTAREA}
              multiline
              minRows={4}
              value={keywords}
              rounded
              placeholder="e.g., widgets, widget shops"
            />
          </div>

          <div className={styles.containerFormItem}>
            <InputGroup
              id="cta"
              name="cta"
              handleChangeInput={_onChangeCta}
              rounded
              label={
                <InputLabel
                  label="CTA (optional)"
                  description="What do you want the reader to do after reading your post?"
                />
              }
              inputType={InputTypes.TEXTAREA}
              multiline
              minRows={4}
              value={cta}
              placeholder="e.g., Sign up for our newsletter"
            />
          </div>
        </div>
      </div>
    );
  },
);

export default BlogTitleForm;
