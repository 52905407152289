import React, { useMemo } from 'react';

import cx from 'classnames';

import { copyToClipboard } from '@writercolab/common-utils';
import {
  Button,
  FontFace,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

export interface IOrgTeamIdsListItem {
  id: string;
  name: string;
}

interface IOrgTeamIdsSectionProps {
  org: IOrgTeamIdsListItem;
  teams: IOrgTeamIdsListItem[];
}

enum OrgTeamListType {
  ORG = 'org',
  TEAM = 'team',
}

interface IListItemsProps {
  type: OrgTeamListType;
  items: IOrgTeamIdsListItem[];
  onCopyClick: (type: OrgTeamListType, value: string) => void;
}

interface IListItemProps {
  type: OrgTeamListType;
  item: IOrgTeamIdsListItem;
  onClickCopy: (type: OrgTeamListType, value: string) => void;
}

const ListItems: React.FC<IListItemsProps> = ({ type, items, onCopyClick }) => {
  const listItemName = useMemo(() => (type === OrgTeamListType.ORG ? 'Org' : 'Team'), [type]);

  return (
    <div className={styles.listItems}>
      <div className={styles.listItemsRow}>
        <div className={styles.listItemsHeader}>
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            {listItemName}
          </Text>
        </div>
        <div className={styles.listItemsHeader}>
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            ID
          </Text>
        </div>
      </div>
      {items.map(item => (
        <ListItem type={type} key={`${type}-${item.id}`} item={item} onClickCopy={onCopyClick} />
      ))}
    </div>
  );
};

const ListItem: React.FC<IListItemProps> = ({ type, item, onClickCopy }) => {
  const listItemIcon = useMemo(
    () => (type === OrgTeamListType.ORG ? <Icon name={IconVariant.BUILDING} /> : <Icon name={IconVariant.TEAM} />),
    [type],
  );

  return (
    <div className={styles.listItemsRow}>
      <div className={styles.listItemsValue}>
        <div className={styles.listItemsImage}>{listItemIcon}</div>
        <Text variant={TextSize.L}>{item.name}</Text>
      </div>
      <div className={styles.listItemsValue}>
        <div className={styles.listItemsAction}>
          <Text
            fontFace={FontFace.monospace}
            color={TextColor.GREY2}
            variant={TextSize.L}
            className={styles.listItemsActionText}
          >
            {item.id}
          </Text>
          <Tooltip title="Copy ID" placement="top">
            <Button
              className={styles.listItemsActionButton}
              round
              icon={<Icon name={IconVariant.COPY_PASTE} />}
              onClick={() => onClickCopy(type, item.id)}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export const OrgTeamIdsSection: React.FC<IOrgTeamIdsSectionProps> = ({ org, teams }) => {
  const { enqueueSuccessSnackbar } = useCustomSnackbar();
  const _onCopyClick = (type: OrgTeamListType, value: string) => {
    copyToClipboard({ text: value });
    enqueueSuccessSnackbar(`${type === OrgTeamListType.ORG ? 'Organization' : 'Team'} ID copied to clipboard`);
  };

  return (
    <>
      <Heading variant={HeadingVariant.H3} className={styles.containerFormHeader} bold>
        Org and Team IDs
      </Heading>
      <Text color={TextColor.GREY2} variant={TextSize.M}>
        Use these org and team IDs in your requests.
      </Text>
      <div className={cx(styles.containerList, styles.containerFormWide)}>
        <ListItems type={OrgTeamListType.ORG} items={[org]} onCopyClick={_onCopyClick} />
      </div>
      <div className={cx(styles.containerList, styles.containerFormWide)}>
        <ListItems type={OrgTeamListType.TEAM} items={teams} onCopyClick={_onCopyClick} />
      </div>
    </>
  );
};

export default OrgTeamIdsSection;
