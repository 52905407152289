import { action, computed, makeObservable, observable } from 'mobx';

import { PaginatedModel, ReactiveQueue } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import {
  EApplicationType,
  NotificationQueueItemType,
  type TApplicationBase,
  type TApplicationBriefPaginatedTemplateResponse,
  type TGetOrganizationSessionResponse,
  type TGetOrganizationSessionsPaginatedResponse,
  type TNotificationQueueItem,
  TOrgTeamUserActivityParams,
} from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import { DEFAULT_PAGE_SIZE, type TDraftsTableFilterAppsExtraArgs, type TGetTeamSessionsQueryParams } from '@web/types';
import debounce from 'lodash/debounce';
import { ApplicationApiModel } from 'models/application.api';
import { SessionsApiModel } from 'models/sessions.api';
import { getLogger } from 'utils/logger';

import { getSessionsUrlParams } from './utils';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

export const SessionsTableModals = new Enum('editTitle');

export type TSessionsTableModalsManager = ModalsManager<'editTitle', number>;

interface ISessionsTableOptions {
  organizationId: number;
  teamId: number;
  request: RequestServiceInitialize['api'];
  sessionsApi: SessionsApiModel;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

const LOG = getLogger('SessionsTableModel');

export class SessionsTableUiModel {
  readonly sessionsApi: SessionsApiModel;
  readonly applicationApi: ApplicationApiModel;
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

  isOpenDeleteConfirmationModal = false;
  private $selectedSessionData = observable.box<TGetOrganizationSessionResponse | null>(null);

  modalsManager: TSessionsTableModalsManager;

  constructor(private readonly opts: ISessionsTableOptions) {
    this.modalsManager = new ModalsManager<typeof SessionsTableModals.type, number>();
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();

    this.sessionsApi = new SessionsApiModel({
      request: opts.request,
      organizationId: opts.organizationId,
      teamId: opts.teamId,
    });

    this.applicationApi = new ApplicationApiModel({
      request: opts.request,
      organizationId: opts.organizationId,
      teamId: () => opts.teamId,
    });

    makeObservable(this, {
      sessions: computed.struct,
      applications: computed.struct,
      hasMore: computed,
      isLoading: computed,
      setSelectedSessionData: action,
      setIsOpenDeleteConfirmationModal: action.bound,

      isOpenEditNameModal: computed,
      isOpenDeleteConfirmationModal: observable,
    });
  }

  private readonly $paginatedSessions: PaginatedModel<
    TGetOrganizationSessionsPaginatedResponse,
    TGetOrganizationSessionResponse,
    {
      offset: number;
      limit: number;
    },
    TGetTeamSessionsQueryParams
  > = new PaginatedModel({
    name: '$paginatedSessions',
    argsExtra: { origin: 'web', sortOrder: 'desc', ...getSessionsUrlParams() },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

      if (offset >= obj.totalCount) {
        return this.$paginatedSessions.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj.result ?? [],
    load: async ({ args, extra }) => {
      const data = await this.sessionsApi.getTeamSessions({
        ...args,
        ...extra,
      });

      return data;
    },
  });

  private readonly $applications: PaginatedModel<
    TApplicationBriefPaginatedTemplateResponse,
    TApplicationBase,
    {
      offset: number;
      limit: number;
    },
    TDraftsTableFilterAppsExtraArgs
  > = new PaginatedModel({
    name: '$applications',
    argsExtra: { sortField: 'name', sortOrder: 'asc' },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

      if (offset >= obj.totalCount) {
        return this.$applications.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj.result ?? [],
    load: async ({ args, extra }) => {
      const data = await this.applicationApi.getDeployedApps({
        ...args,
        ...extra,
        appType: EApplicationType.enum.chat,
      });

      return data;
    },
  });

  get isLoading(): boolean {
    return this.$paginatedSessions.status === 'pending';
  }

  get isApplicationsFilterLoading(): boolean {
    return this.$applications.status === 'pending';
  }

  get sessions(): TGetOrganizationSessionResponse[] {
    return this.$paginatedSessions.value ?? [];
  }

  get applications() {
    return this.$applications.value ?? [];
  }

  get hasMore(): boolean {
    return this.$paginatedSessions.hasNext;
  }

  get hasMoreApplications(): boolean {
    return this.$applications.hasNext;
  }

  get isOpenEditNameModal(): boolean {
    return this.modalsManager.isModalVisible(SessionsTableModals.enum.editTitle);
  }

  closeModal = () => {
    this.setSelectedSessionData(null);
    this.modalsManager.hideModal();
  };

  loadMore = async () => {
    await this.$paginatedSessions.next();
  };

  loadMoreApplications = async () => {
    await this.$applications.next();
  };

  reload = async () => {
    this.$paginatedSessions.reload();

    return this.$paginatedSessions.promise;
  };

  get selectedSessionData(): TGetOrganizationSessionResponse | null {
    return this.$selectedSessionData.get();
  }

  setSelectedSessionData = (sessionData: TGetOrganizationSessionResponse | null) => {
    this.$selectedSessionData.set(sessionData);
  };

  updateExtraArgs = (extraArgs: TGetTeamSessionsQueryParams) => {
    this.$paginatedSessions.setExtra(extraArgs);
  };

  updateTableFilterAppsExtraArgs = debounce((extraArgs: TDraftsTableFilterAppsExtraArgs) => {
    this.$applications.setExtra(extraArgs);
  }, 300);

  deleteSession = async () => {
    const sessionId = this.selectedSessionData?.session.id;

    if (!sessionId) {
      return;
    }

    try {
      this.opts.analyticsService.track(AnalyticsActivity.sessionDeleted, {
        origin: 'my_work',
        session_id: sessionId,
      });

      await this.sessionsApi.deleteSession(sessionId);
      await this.reload();

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Session was successfully deleted',
      });
    } catch (e) {
      LOG.error('Failed to delete session', {
        sessionId,
        error: e,
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Session could not be deleted',
      });
    }
  };

  setIsOpenDeleteConfirmationModal(visible: boolean) {
    this.isOpenDeleteConfirmationModal = visible;

    if (!visible) {
      this.setSelectedSessionData(null);
    }
  }

  updateSessionTitle = async (sessionId: string, title: string) => {
    try {
      await this.sessionsApi.updateSessionTitle(sessionId, title);
      await this.reload();
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Session title was successfully updated',
      });
      this.closeModal();
    } catch (e) {
      LOG.error('Failed to update session title', {
        sessionId,
        title,
        error: e,
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Session title could not be updated',
      });
    }
  };
}
