import React from 'react';
import { TextSize, Text, Icon, IconVariant } from '@writercolab/ui-atoms';
import MuiAccordion, { type AccordionProps as MuiAccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

const accordionStyles = {
  border: 'none',
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 'auto',
  },
};

const summaryStyles = {
  borderBottom: 'none',
  padding: 0,
  minHeight: 'auto !important',
  justifyContent: 'flex-start',
  marginBottom: -1,
  '& .MuiAccordionSummary-content': {
    margin: '10px 5px 10px 0 !important',
    flexGrow: 0,
    '& p': {
      fontWeight: 500,
    },
  },
  '& .MuiAccordionSummary-expandIcon': {
    padding: 0,
  },
};

const detailsStyles = {
  padding: 0,
};

export interface IAccordionProps extends MuiAccordionProps {
  title?: string;
  hideExpandIcon?: boolean;
  expanded?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const CustomizedAccordion: React.FC<IAccordionProps> = ({
  title,
  hideExpandIcon,
  expanded,
  children,
  className,
  onClick,
}) => (
  <div className={className}>
    <MuiAccordion sx={accordionStyles} onClick={onClick} expanded={expanded}>
      <MuiAccordionSummary
        sx={summaryStyles}
        expandIcon={!hideExpandIcon && <Icon name={IconVariant.DROP_DOWN_ARROW} />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {title && (
          <Text variant={TextSize.S} caps>
            {title}
          </Text>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails sx={detailsStyles}>{children}</MuiAccordionDetails>
    </MuiAccordion>
  </div>
);
