import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type {
  BaseComponentsProps,
  ICreateDocument,
  IDocument,
  IDocumentShareStatus,
  IDocumentShareStatusPayload,
} from '@writercolab/common-utils';
import { SharingOptions, noop } from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';
import { CREATE_NEW_DOC_ID, EMPTY_EDITOR_DOC_ID, REDIRECT_DOC_ID } from '@writercolab/utils';

import DeleteDocument from '../components/molecules/DocumentInfoPanel/modals/DeleteDocument';

import { snackbarMessages } from '@web/component-library';
import { AnalyticsActivity } from 'constants/analytics';
import debounce from 'lodash/debounce';
import Delta from 'quill-delta';
import { useLocation, useNavigate } from 'react-router';

import { ROUTE } from '../services/config/routes';
import { generateWord } from '../services/docx';
import {
  createDocument,
  deleteDocument,
  getDocumentById,
  getDocumentShareStatus,
  updateDocument,
  updateDocumentShareStatus,
} from '../services/request/documents';
import { useAppState } from '../state';
import type { TAppState } from '../state/types';
import { getLogger } from '../utils/logger';

const LOG = getLogger('documentsContext');

interface IDocumentsContext {
  currentDocData: IDocument | null;
  updateDocumentTitle: (title: string) => void;
  changeShareStatus: (shareStatus: IDocumentShareStatusPayload) => Promise<any>;
  invokeDeleteDoc: (teamId: number, docId: number, docTitle: string, stayOnCurrentPage?: boolean) => void;
  handleChangeIsEditorReady: (isReady: boolean) => void;
  documentShareStatus: IDocumentShareStatus;
  currentDocLoading: boolean;
  isEditorReady: boolean;
  downloadAsDocx: (delta: Delta) => void;
  addNewDoc: (teamId?: number, locationFrom?: string) => Promise<ICreateDocument | null>;
}

export const DocumentsContext = createContext<IDocumentsContext>({} as IDocumentsContext);

interface IDocumentsContextProviderProps extends BaseComponentsProps {
  activeOrgId: number | undefined;
  activeTeamId: TAppState['teamId'];
  activeDocId?: TAppState['documentId'];
}

const initialDeleteDocObjState = {
  showPopup: false,
  callback: noop,
};

const DocumentsContextProvider: React.FC<IDocumentsContextProviderProps> = ({
  activeOrgId,
  activeTeamId,
  activeDocId,
  children,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    appState: { userProfile },
    appModel,
  } = useAppState();
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [currentDocLoading, setCurrentDocLoading] = useState(true);
  const [currentDocData, setCurrentDocData] = useState<IDocument | null>(null);
  const [documentShareStatus, setDocumentShareStatus] = useState<IDocumentShareStatus>({
    access: SharingOptions.PRIVATE,
  });
  const [deleteDocObj, setDeleteDocObj] = useState(initialDeleteDocObjState);
  const { enqueueDeleteSnackbar, enqueueErrorSnackbar, enqueueBasicSnackbar } = useCustomSnackbar();

  const handleChangeIsEditorReady = (isReady: boolean) => {
    setIsEditorReady(isReady);
  };

  const invokeDeleteDoc = (teamId: number, docId: number) => {
    setDeleteDocObj({
      showPopup: true,
      callback: () => deleteDoc(teamId, docId),
    });
  };

  const createNewDocument = async (orgId?: number, teamId?: number): Promise<ICreateDocument | null> =>
    createDocument(orgId, teamId!, '');

  const addNewDoc = async (teamId?: number, locationFrom?: string) => {
    const _teamId = activeTeamId || teamId;

    if (!_teamId) {
      LOG.error('Invalid teamId. Please refresh and try again');

      return null;
    }

    const newDocument = await createNewDocument(activeOrgId, _teamId);

    if (newDocument) {
      appModel.analyticsService.track(AnalyticsActivity.createDocument, {
        created_from: locationFrom || 'dashboard',
      });
    }

    return newDocument;
  };

  const deleteDoc = async (teamId: number, docId: number) => {
    try {
      if (!docId) throw new Error('Invalid Document data');

      await deleteDocument(activeOrgId, teamId, docId);

      navigate(ROUTE.toHome(activeOrgId, teamId));
      enqueueDeleteSnackbar(snackbarMessages.docs.deleteSuccess());
    } catch (e: any) {
      LOG.error(e);
      enqueueErrorSnackbar(snackbarMessages.docs.deleteError(e.message));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUpdateDoc = useCallback(
    debounce((documentId, title, isUpdateCurrentDoc = false) => {
      updateDocument(activeOrgId, activeTeamId, documentId, { title })
        .then(updatedDocument => {
          if (isUpdateCurrentDoc) {
            setCurrentDocData(updatedDocument);
          }
        })
        .catch(err => LOG.error(err));
    }, 500),
    [activeOrgId, activeTeamId],
  );

  const updateDocumentTitle = useCallback(
    (title: string) => {
      if (currentDocData) {
        setCurrentDocData({
          ...currentDocData,
          title,
        });

        handleUpdateDoc(currentDocData.id, title, false);
      }
    },
    [currentDocData, handleUpdateDoc],
  );

  const downloadAsDocx = useCallback(
    async (delta: Delta) => {
      const processedDelta = new Delta(
        // we need to process some delta elements (e.g. Video) to correctly transform them into DOCX entities. The list of transformation below:
        // video insert -> replace with link following by new line (\n)
        delta.ops.flatMap(op => {
          // if (typeof op.insert !== 'string' && typeof op.insert?.video === 'string') {
          //   return [
          //     {
          //       attributes: {
          //         ...op.attributes,
          //         heading: 4,
          //         link: VideoFormat.generateMuxUrl({ playbackId: op.insert.video }),
          //       },
          //       insert: (op.attributes?.[QUILL_FORMAT.FILE_NAME] as unknown as Record<string, string>) || 'Video',
          //     } as Op,
          //     { insert: '\n' },
          //   ];
          // }

          return op;
        }),
      );

      generateWord(processedDelta, currentDocData?.title || 'Untitled');
    },
    [currentDocData],
  );

  const changeShareStatus = async (shareStatus: IDocumentShareStatusPayload) => {
    setCurrentDocLoading(true);

    if (activeDocId) {
      try {
        const newShareStatus = await updateDocumentShareStatus(activeOrgId, activeTeamId, activeDocId, shareStatus);
        setDocumentShareStatus(newShareStatus);
        enqueueBasicSnackbar(snackbarMessages.docs.shareChange());
      } catch {
        LOG.warn('Error while shanging the sharing status.');
      }
    }

    setCurrentDocLoading(false);
  };

  useEffect(() => {
    const initializeDocument = async () => {
      try {
        setCurrentDocLoading(true);

        let currentDoc: IDocument | null = null;

        if (activeDocId === CREATE_NEW_DOC_ID) {
          currentDoc = await addNewDoc();
          navigate(ROUTE.toEditorPage(activeOrgId, activeTeamId, currentDoc?.id, location.search), { replace: true });
        } else if (activeDocId && activeDocId !== REDIRECT_DOC_ID && activeDocId !== EMPTY_EDITOR_DOC_ID) {
          currentDoc = await getDocumentById(activeOrgId, activeTeamId, activeDocId);
        }

        setCurrentDocData(currentDoc);

        if (currentDoc && currentDoc.createdUserId === userProfile?.id) {
          const shareStatus = await getDocumentShareStatus(activeOrgId, activeTeamId, currentDoc.id);
          setDocumentShareStatus(shareStatus);
        }
      } catch (e) {
        LOG.error(e);
      } finally {
        setCurrentDocLoading(false);
      }
    };

    initializeDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDocId, userProfile?.id]);

  return (
    <DocumentsContext.Provider
      value={{
        currentDocData,
        updateDocumentTitle,
        invokeDeleteDoc,
        changeShareStatus,
        documentShareStatus,
        currentDocLoading,
        handleChangeIsEditorReady,
        downloadAsDocx,
        isEditorReady,
        addNewDoc,
      }}
    >
      {children}
      {deleteDocObj.showPopup && (
        <DeleteDocument
          onClose={() => setDeleteDocObj(initialDeleteDocObjState)}
          isOpen={deleteDocObj.showPopup}
          onSubmit={deleteDocObj.callback}
        />
      )}
    </DocumentsContext.Provider>
  );
};

export function useDocumentsContext() {
  const context = useContext(DocumentsContext);

  if (!context) {
    throw new Error('useDocumentsContext must be used within the DocumentsContextProvider');
  }

  return context;
}

export default DocumentsContextProvider;
