import type { components, DateTime } from '@writercolab/network';
import { Enum } from '@writercolab/utils';
import { ReactNode } from 'react';

export const TimeGroupings = new Enum('DayGrouping', 'WeekGrouping', 'MonthGrouping');
export const GroupingCategories = new Enum('TimePeriod', 'Team', 'BillingGroup', 'Feature', 'Surface', 'User');
export const ReportGroupingTypes = new Enum('timeGrouping', 'groupingCategory');

export const AiUsageTableFeatureTab = new Enum('WordsGenerated', 'WordsRewritten');
export const AiUsageTableWordsRewrittenFilterTypes = new Enum('VoiceRewrite', 'WriterRewrite', 'CustomRewrite');
export const AiUsageTableWordsGeneratedFilterTypes = new Enum('PreBuiltApplication', 'CustomApplication', 'Feature');

export type TAiUsageReportRequest = components['schemas']['reporting_dto_AIUsageReportRequest'];
export type TAiUsageReportResponse = components['schemas']['reporting_dto_AIUsageReportResponse'];
export type TPaginatedReportTableData = components['schemas']['reporting_dto_AIUsageTotalsResponse'];
export type TAiUsageUserStatus = components['schemas']['reporting_model_UserStatus'];
export type TPaginatedReportTableDataGroupArgs = {
  limit: number;
  offset: number;
};

export type TPaginatedReportTableDataExtraArgs = components['schemas']['reporting_dto_AIUsageTotalsRequest'] & {
  tableTab?: typeof AiUsageTableFeatureTab.type;
};
export type TPaginatedReportTableDataItem =
  components['schemas']['reporting_model_AIUsageReportModel_GroupSeriesTotal'];

export type TAiUsageReportResponseData = components['schemas']['reporting_model_AIUsageReportModel_TimePoint'];
export const TReportTableSortField = new Enum('WordsGenerated', 'WordsRewritten', 'RecapsTranscribed');
export type TAiUsageSimpleFeatureType = components['schemas']['reporting_model_AIUsageReportModel_SimpleFeatureType'];
export const TReportTableSortOrder = new Enum('asc', 'desc');

export interface TPaginatedReportTableExtraArgs {
  sortField?: typeof TReportTableSortField.type;
  sortOrder?: typeof TReportTableSortOrder.type;
  features?: [TAiUsageSimpleFeatureType, ...TAiUsageSimpleFeatureType[]];
  timeGrouping?: typeof TimeGroupings.type;
  groupingCategory?: typeof GroupingCategories.type;
  startDate?: string;
  endDate?: string;
  type?: string[];
  from?: DateTime;
  to?: DateTime;
  billingGroupIds?: [number, ...number[]];
  teamIds?: [number, ...number[]];
  tableTab?: typeof AiUsageTableFeatureTab.type;
}

export type TTypeOfDownloadFile = 'csv' | 'xlsx';

interface GroupItem {
  id: string;
  name: string;
  groupType?: string | null;
}

export interface InputDataItem {
  seriesName: string;
  group?: GroupItem | null;
  time: string;
  value: number;
  percentage: number;
}

export interface ILabel {
  key: string;
  color: string;
  title: ReactNode;
  inactive?: boolean;
}