import type { ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';
import head from 'lodash/head';
import cx from 'classnames';

import type { BaseComponentsProps } from '@writercolab/common-utils';
import type { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import {
  type DropdownOption,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import styles from './EditorToolbarFormat.module.css';

interface IEditorToolbarFormatDropdown extends BaseComponentsProps {
  options: DropdownOption[];
  format: QUILL_FORMAT;
  onClick: (format: QUILL_FORMAT, value?: string) => void;
}

const ActionTrigger: React.FC<{
  iconClassName?: string;
  children?: ReactNode;
}> = ({ children, iconClassName }) => (
  <div className={styles.actionTrigger}>
    {children}
    <Icon name={IconVariant.BREADCRUMB_ARROW} className={iconClassName} />
  </div>
);

export const EditorToolbarFormatDropdown: React.FC<IEditorToolbarFormatDropdown> = ({
  format,
  onClick,
  options,
  className,
}) => {
  const activeOpt = useMemo(
    () => options.find(option => option.active) || (head(options) as DropdownOption),
    [options],
  );

  const handleClick = useCallback((value: string) => onClick(format, value), [onClick, format]);

  return (
    <div className={styles.actionContainer}>
      <Dropdown
        dropDownContainerClassName={styles.actionMainDropdownContainer}
        containerClassName={cx(styles.actionDropdownContainer, className)}
        placement={DropdownPlacement.TOP_LEFT}
        options={options}
        trigger={
          <ActionTrigger iconClassName={styles.actionDropdownTrigger}>
            <Text variant={TextSize.L} className={styles.actionDropdownTriggerText}>
              {activeOpt.name}
            </Text>
          </ActionTrigger>
        }
        highlightActiveOption
        onPrimaryOptionClickAction={handleClick}
      />
    </div>
  );
};
