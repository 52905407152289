import React from 'react';

import { IGetSuggestion, SUGGESTION_DESCRIPTION_MAX_LENGTH } from '@writercolab/common-utils';
import { convertToEllipsis, sanitize } from '@writercolab/text-utils';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface ILinkSuggestionContentProps {
  linkSuggestion: IGetSuggestion;
}

export const LinkSuggestionContent: React.FC<ILinkSuggestionContentProps> = ({ linkSuggestion }) => {
  const content = convertToEllipsis(linkSuggestion.snippet, SUGGESTION_DESCRIPTION_MAX_LENGTH);

  return (
    <Text variant={TextSize.S} color={TextColor.GREY2} className={styles.suggestionContent}>
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(content),
        }}
      />
    </Text>
  );
};
