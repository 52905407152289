import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './SessionsTable.module.css';

interface IDeleteSessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}

export const DeleteSessionModal: React.FC<IDeleteSessionModalProps> = ({ isOpen, onClose, onSubmit, ...props }) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal open={isOpen} handleClose={onClose} title="Delete session" style={{ width: '352px' }} {...props}>
      <div className={styles.styledDeleteContent}>
        <Text variant={TextSize.M}>Are you sure you want to delete this session?</Text>
        <div className={styles.deleteControlButtons}>
          <Button className={styles.submitButton} type={ButtonTypes.DEFAULT} content="Never mind" onClick={onClose} />
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Delete forever"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};
