import { getLogger } from './logger';

const LOG = getLogger('stringUtils');

export const capitalizeFirstLetter = (string?: string | null): string => {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const stringToId = (input: string): string => Array.from(input, char => char.charCodeAt(0)).join('');

export const concatenateStrings = (separator: string = ' ', ...strings: Array<string | null | undefined>): string =>
  strings.filter(str => str !== undefined && str !== null && str !== '').join(separator);

export const getTokensCount = async (text: string): Promise<number> => {
  try {
    const { encode } = await import('gpt-3-encoder-browser');

    const tokens = encode(text);

    return tokens.length;
  } catch (error) {
    LOG.error('Error occurred while encoding text:', error);

    return 0;
  }
};

export const formatFileName = (extension: string, ...args: string[]): string => {
  const name = args.join('_').replace(/\s/g, '_').trim().toLowerCase();

  return concatenateStrings('.', name, extension);
};
