import { Request } from '@writercolab/common-utils';

import { OrgDomain, OrgDomainId, OrgDomainUpdateProps } from '@web/types';
import { AxiosResponse } from 'axios';

export const addOrgDomainFromEmail = async (orgId: number | undefined): Promise<OrgDomain> => {
  const { data } = await Request.getAxiosInstance().post<any, AxiosResponse<OrgDomain>>(
    `/organization/v2/${orgId}/domain`,
  );

  return data;
};

export const updateOrgDomain = async (
  orgId: number | undefined,
  domain: OrgDomainId,
  props: OrgDomainUpdateProps,
): Promise<OrgDomain> => {
  const { data } = await Request.getAxiosInstance().put<OrgDomainUpdateProps, AxiosResponse<OrgDomain>>(
    `/organization/v2/${orgId}/domain/${domain}`,
    props,
  );

  return data;
};

export const validateOrgDomainFromEmail = async (orgId: number | undefined): Promise<void> => {
  await Request.getAxiosInstance().put(`/organization/v2/${orgId}/domain/validate`);
};

export const getOrgDomains = async (orgId: number | undefined): Promise<OrgDomain[]> => {
  const { data } = await Request.getAxiosInstance().get<any, AxiosResponse<{ value: OrgDomain[] }>>(
    `/organization/v2/${orgId}/domain`,
  );

  return data.value;
};
