import React from 'react';

import { TVoice } from '@web/types';

import { BaseTable, IRowProps } from '../BaseTable/BaseTable';
import { TVoiceTableActions, generateVoiceTableBody, generateVoiceTableHeaders } from './utils';

import styles from './VoiceTable.module.css';

export interface IVoiceTableProps {
  voices: TVoice[];
  viewOnly: boolean;
  aiStudioMode: boolean;
  onClickShowInRewrite: (id: string, visible: boolean) => void;
  onClickView: (id: string) => void;
  onClickEdit: (id: string) => void;
  onClickCalibrate: (id: string) => void;
  onClickSetAsDefault: (id: string) => void;
  onClickDelete: (id: string) => void;
  checkIsVoiceEditable?: (id?: number) => boolean;
}

export const VoiceTable: React.FC<IVoiceTableProps> = ({
  voices,
  viewOnly,
  aiStudioMode,
  onClickShowInRewrite,
  onClickView,
  onClickEdit,
  onClickCalibrate,
  onClickSetAsDefault,
  onClickDelete,
  checkIsVoiceEditable,
}) => {
  const handleDropdownClick = (actionId: typeof TVoiceTableActions.type, id: string) => {
    switch (actionId) {
      case TVoiceTableActions.enum.view:
        onClickView(id);
        break;
      case TVoiceTableActions.enum.editDetails:
        onClickEdit(id);
        break;
      case TVoiceTableActions.enum.editCalibration:
        onClickCalibrate(id);
        break;
      case TVoiceTableActions.enum.setAsDefault:
        onClickSetAsDefault(id);
        break;
      case TVoiceTableActions.enum.delete:
        onClickDelete(id);
        break;
      default:
        break;
    }
  };

  const handleCellClick = (id: string) => {
    if (viewOnly) {
      onClickView(id);
    } else {
      onClickEdit(id);
    }
  };

  return (
    <div className={styles.container}>
      <BaseTable
        containerClassName={styles.tableContainer}
        headerClassName={styles.tableHeader}
        headers={generateVoiceTableHeaders(aiStudioMode)}
        data={generateVoiceTableBody({
          voices,
          viewOnly,
          aiStudioMode,
          onClickShowInRewrite,
          handleDropdownClick,
          handleCellClick,
          checkIsVoiceEditable,
        })}
        gridTemplateColumns="320px 50px 110px 150px 140px 20px"
        bodyRowHeight="65px"
        onRowClick={(row: IRowProps) => row.action?.()}
        loading={false}
        isEnableSticky={false}
      />
    </div>
  );
};
