import type { MfaChallengeType } from '@writercolab/types';

export enum VerdictStatusCode {
  CODE_EXPIRED = 'attempts-exhausted',
  CODE_CHALLENGE_EXPIRED = 'challenge-expired',
  ABORTED = 'aborted',
}

export interface IMfaChallenge {
  token: string;
  method: MfaChallengeType;
  hint: string;
}

export interface IMfaSettings {
  methods: MfaChallengeType[];
}
