import React from 'react';

import { Icon, IconVariant, Modal } from '@writercolab/ui-atoms';

import styles from './FullScreenModal.module.css';

export const FullScreenModal = ({
  children,
  open,
  onClose,
}: {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      {open && (
        <div className={styles.closeButton} onClick={onClose}>
          <Icon name={IconVariant.CLOSE_WHITE} height={18} width={18} />
        </div>
      )}

      <Modal
        open={open}
        className={styles.container}
        modalContainerClassName={styles.modalContainer}
        backdropStyles={{ cursor: 'pointer' }}
        handleClose={onClose}
        hideCloseButton
      >
        <>{children}</>
      </Modal>
    </>
  );
};
