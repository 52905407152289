import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import type { ErrorMessage, IUserProfile } from '@writercolab/common-utils';
import { MfaChallengeType } from '@writercolab/types';
import { useCustomSnackbar } from '@writercolab/ui-atoms';
import type { IFilterOptionsFilter } from '@writercolab/ui-molecules';

import { snackbarMessages, useCountdown } from '@web/component-library';
import type {
  DropdownOptionUserSettingDepartment,
  IMfaChallenge,
  UserSettingDepartment,
  WithChildren,
} from '@web/types';
import { RecaptchaAction } from '@web/types';
import type { AxiosError } from 'axios';
import { UserSettingDepartments } from 'constants/Profile';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { useGoogleMfa } from '../hooks/useGoogleMfa';
import { clearReportLocalStorage } from '../models/localStorage';
import requestService from '../services/request/requestService';
import {
  createUserPassword,
  cropPicture,
  deleteMfaSettings,
  getMfaSettings,
  resetPassword,
  saveEmail,
  saveMfaSettings,
  savePassword,
  savePhone,
  saveProfile,
  uploadProfilePicture,
} from '../services/request/user';
import { useAppState } from '../state';
import { getLogger } from '../utils/logger';
import { extractMfaChallenges, isMfaAttemptRetryable, isMfaRequired, resendCountDownMessage } from '../utils/mfaUtils';
import { isCustomValue, removeCustomValuePrefix } from '../utils/questionnaireUtils';
import { stringToId } from '../utils/stringUtils';
import { mapDepartmentResponseToUserDepartmentSetting } from '../utils/userSettingsUtils';

const LOG = getLogger('profileContext');

export enum ChallengeStep {
  PASSWORD = 'password',
  MFA = 'mfa',
  NONE = 'none',
}

export enum PasswordChallengeType {
  STANDARD = 'password',
  RESET_PASSWORD = 'resetPassword',
}

// Maximum number of attempts allowed for resending MFA codes
const MFA_RESEND_ATTEMPT_LIMIT = 3;
// Timeout duration in seconds before allowing another MFA code resend
const MFA_RESEND_CHALLENGE_TIMEOUT = 60;

interface IProfileContext {
  profileSaving: boolean;
  passwordSaving: boolean;
  emailSaving: boolean;
  phoneSaving: boolean;

  resetPasswordLoading: boolean;
  createPasswordLoading: boolean;
  avatarLoading: boolean;
  mfaLoading: boolean;

  initialUser: IUserProfile | undefined;
  userDepartment: UserSettingDepartment | string | null;
  userDepartmentList: DropdownOptionUserSettingDepartment[];
  initialUserDepartment: UserSettingDepartment | string | null;

  timezones: IFilterOptionsFilter[] | null;

  // MFA
  mfaResendSuccess: boolean;
  mfaResendCodeBlockReason: string | null;
  mfaResendLimitReached: boolean;
  challengeStep: ChallengeStep;
  passwordChallengeType: PasswordChallengeType;
  challengeError: string | undefined;
  passwordChallengeCallback: ((password: string) => Promise<void>) | undefined;
  mfaSettings: MfaChallengeType[];
  mfaChallengeCallback: ((token: string) => Promise<void>) | undefined;
  availableMfaChallenges: IMfaChallenge[];
  onChangeMfaChallenge: () => void;
  onResendMfaCode: (challengeType: MfaChallengeType) => Promise<void>;
  onVerifyMfaCode: (code: string) => Promise<void>;

  uploadAvatar: (file: File) => Promise<string | undefined>;
  requestPasswordCreate: () => Promise<void>;
  requestPasswordReset: () => Promise<void>;
  updateProfile: () => Promise<void>;
  cancelProfileEditing: () => void;
  updateEmail: () => Promise<void>;
  cancelEmailEditing: () => void;
  updatePhone: () => Promise<void>;
  cancelPhoneEditing: () => void;
  updatePassword: (newPassword: string, confirmPassword: string) => void;
  onCloseMfaModal: () => void;
  closePasswordModal: () => void;

  onReorderChallenges: (challenges: MfaChallengeType[]) => void;
  switchMfaSettings: (challenge?: MfaChallengeType) => void;

  onChangeDepartment: (departmentName: UserSettingDepartment | string) => void;
}

const ProfileContext = createContext<IProfileContext>({} as IProfileContext);

export const ProfileContextProvider: React.FC<WithChildren> = observer(({ children }) => {
  const { appModel } = useAppState();
  const { enqueueBasicSnackbar, enqueueErrorSnackbar, enqueueSuccessSnackbar } = useCustomSnackbar();
  const { executeRecaptcha, initTwoFactor, verifyAccount, resendCode } = useGoogleMfa();

  const [initialUser, setInitialUser] = useState<IUserProfile | undefined>(appModel.user);
  const [initialUserDepartment, setInitialUserDepartment] = useState<UserSettingDepartment | string | null>(null);
  const [timezones, setTimezones] = useState<IFilterOptionsFilter[] | null>(null);
  const [userDepartmentList, setUserDepartmentList] =
    useState<DropdownOptionUserSettingDepartment[]>(UserSettingDepartments);

  // Tracks the number of attempts to resend MFA codes for each challenge type
  const [resendAttemptsCount, setResendAttemptsCount] = useState({
    [MfaChallengeType.EMAIL]: 0,
    [MfaChallengeType.PHONE]: 0,
  });
  const [challengeStep, setChallengeStep] = useState<ChallengeStep>(ChallengeStep.NONE);
  const [challengeError, setChallengeError] = useState<string | undefined>(undefined);
  const [passwordChallengeCallback, setPasswordChallengeCallback] = useState<
    ((password: string, token?: string) => Promise<void>) | undefined
  >(undefined);

  const [profileSaving, setProfileSaving] = useState(false);
  const [passwordSaving, setPasswordSaving] = useState(false);
  const [emailSaving, setEmailSaving] = useState(false);
  const [phoneSaving, setPhoneSaving] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [createPasswordLoading, setCreatePasswordLoading] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);
  const [mfaLoading, setMfaLoading] = useState(false);
  const [mfaResendSuccess, setMfaResendSuccess] = useState(false);
  const [mfaResendCodeBlockReason, setMfaResendCodeBlockReason] = useState<string | null>(null);
  const [mfaResendLimitReached, setMfaResendLimitReached] = useState(false);
  const [passwordChallengeType, setPasswordChallengeType] = useState(PasswordChallengeType.STANDARD);
  const [remainingSeconds, startCountdown, restartCountdown] = useCountdown(MFA_RESEND_CHALLENGE_TIMEOUT);
  const [userDepartment, setUserDepartment] = useState<UserSettingDepartment | string | null>(null);

  const increaseResendAttemptsCount = (type: MfaChallengeType) => {
    setResendAttemptsCount({
      ...resendAttemptsCount,
      [type]: resendAttemptsCount[type] + 1,
    });
  };
  const [mfaSettings, setMfaSettings] = useState<MfaChallengeType[]>([]);
  const [mfaChallengeCallback, setMfaChallengeCallback] = useState<((token: string) => Promise<void>) | undefined>(
    undefined,
  );
  const [availableMfaChallenges, setAvailableMfaChallenges] = useState<IMfaChallenge[]>([]);

  const cancelEmailEditing = useCallback(() => {
    appModel.setUser(initialUser);
    setEmailSaving(false);
  }, [appModel, initialUser]);

  const cancelProfileEditing = useCallback(() => {
    setUserDepartment(initialUserDepartment);
    setUserDepartmentList(
      userDepartmentList.map(department => ({ ...department, active: initialUserDepartment === department.name })),
    );
    appModel.setUser(initialUser);
    setProfileSaving(false);
  }, [appModel, initialUser, initialUserDepartment, userDepartmentList]);

  const cancelPhoneEditing = useCallback(() => {
    appModel.setUser(initialUser);
    setPhoneSaving(false);
  }, [appModel, initialUser]);

  const completeOperation = useCallback(() => {
    setChallengeError(undefined);
    setPasswordChallengeCallback(undefined);
    setMfaChallengeCallback(undefined);
    setAvailableMfaChallenges([]);
    setChallengeStep(ChallengeStep.NONE);
    setPasswordSaving(false);
    setProfileSaving(false);
    setEmailSaving(false);
    setPhoneSaving(false);
  }, []);

  const closePasswordModal = useCallback(() => {
    setChallengeError(undefined);
    setPasswordChallengeCallback(undefined);
    setChallengeStep(ChallengeStep.NONE);
    setPasswordSaving(false);
    setPhoneSaving(false);
    setMfaChallengeCallback(undefined);
    setAvailableMfaChallenges([]);
  }, []);

  const onCloseMfaModal = useCallback(() => {
    setChallengeStep(ChallengeStep.NONE);
    setAvailableMfaChallenges([]);
    setMfaChallengeCallback(undefined);
  }, []);

  const requestPasswordCreate = useCallback(async () => {
    try {
      setCreatePasswordLoading(true);
      await createUserPassword();
      enqueueBasicSnackbar(snackbarMessages.user.checkInboxCreatePassword);
    } catch (e) {
      LOG.error(e);
    } finally {
      setCreatePasswordLoading(false);
    }
  }, [enqueueBasicSnackbar]);

  const requestPasswordReset = useCallback(async () => {
    if (initialUser?.email) {
      try {
        setResetPasswordLoading(true);
        await resetPassword(initialUser.email);
        enqueueBasicSnackbar(snackbarMessages.user.resetPasswordSendSuccess);
      } catch (e) {
        LOG.error(e);
      } finally {
        setResetPasswordLoading(false);
      }
    }
  }, [enqueueBasicSnackbar, initialUser?.email]);

  // Handles resending of MFA codes with rate limiting and error handling
  const onResendMfaCode = useCallback(
    async (challengeType: MfaChallengeType) => {
      // Check if we've exceeded the maximum number of resend attempts
      if (isMfaAttemptRetryable(resendAttemptsCount[challengeType], MFA_RESEND_ATTEMPT_LIMIT)) {
        setMfaResendLimitReached(true);
        setMfaResendCodeBlockReason(null);
        return;
      }

      increaseResendAttemptsCount(challengeType);
      setMfaLoading(true);
      setChallengeError(undefined);

      try {
        const { isSuccess, getStatusCode } = await resendCode();

        if (isSuccess()) {
          setMfaResendSuccess(true);
        } else {
          LOG.error(`Failed to resend ${challengeType} code`, getStatusCode());
        }
      } catch (e) {
        let message = 'Failed to resend code';

        if (e instanceof Error) {
          message = e.message;
        }

        LOG.error(e);
        setChallengeError(message);
        setMfaResendSuccess(false);
      } finally {
        setMfaLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resendCode, resendAttemptsCount, mfaSettings, mfaLoading],
  );

  useEffect(() => {
    if (mfaResendSuccess) {
      startCountdown();

      if (remainingSeconds) {
        setMfaResendCodeBlockReason(resendCountDownMessage(remainingSeconds) || null);
      } else {
        setMfaResendCodeBlockReason(null);
        setMfaResendSuccess(false);
        restartCountdown();
      }
    } else {
      restartCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mfaResendSuccess, remainingSeconds]);

  const onChangeMfaChallenge = useCallback(() => {}, []);

  const onVerifyMfaCode = useCallback(
    async (code: string) => {
      try {
        setMfaLoading(true);

        const { getVerdictToken } = await verifyAccount(code);
        const verdictToken = getVerdictToken();

        await mfaChallengeCallback?.(verdictToken);
        completeOperation();
      } catch (e) {
        let message = 'Failed to verify code';

        if (e instanceof Error) {
          message = e.message;
        }

        setChallengeError(message);
      } finally {
        setMfaLoading(false);
      }
    },
    [mfaChallengeCallback, completeOperation, verifyAccount],
  );

  const checkMfaRequiredErrors = useCallback(e => (isMfaRequired(e) ? extractMfaChallenges(e) : []), []);

  const setupMfaChallenge = useCallback(
    (
      availableMfaChallenges: IMfaChallenge[],
      password: string,
      onAfterPasswordClick: (password: string, mfaToken?: string) => Promise<void>,
      errorMessage?: string,
    ) => {
      setAvailableMfaChallenges(availableMfaChallenges);
      setMfaChallengeCallback(() => async (token: string) => onAfterPasswordClick(password, token));
      setChallengeError(errorMessage);
      setChallengeStep(ChallengeStep.MFA);
      setPasswordChallengeCallback(undefined);
      setPasswordSaving(false);
      setPhoneSaving(false);
    },
    [],
  );

  const startPasswordChallenge = useCallback(
    (onAfterPasswordClick: (password: string, mfaToken?: string) => Promise<void>) => {
      const passwordChallengeCallback = async (password: string) => {
        try {
          await onAfterPasswordClick(password);
          completeOperation();
        } catch (e) {
          const error = e as AxiosError;
          const availableMfaChallenges = checkMfaRequiredErrors(e);

          if (isEmpty(availableMfaChallenges)) {
            setChallengeError(error?.message ?? 'Failed to complete the operation');
            return;
          }

          try {
            const firstChallenge = first<IMfaChallenge>(availableMfaChallenges);
            if (!firstChallenge?.token) {
              setChallengeError('Invalid MFA challenge token');
              return;
            }

            await initTwoFactor(firstChallenge.token);
            const mfaChallengeCallback = async (token: string) => onAfterPasswordClick(password, token);

            setupMfaChallenge(
              availableMfaChallenges,
              password,
              mfaChallengeCallback,
              error?.message ?? 'Failed to complete the operation',
            );
          } catch (mfaError) {
            const error = mfaError as AxiosError;

            if (error?.message?.includes('aborted')) {
              setChallengeError('Operation was cancelled');
              setChallengeStep(ChallengeStep.NONE);
              setPasswordChallengeCallback(undefined);
              setMfaChallengeCallback(undefined);
              setAvailableMfaChallenges([]);
              setPasswordSaving(false);
              setPhoneSaving(false);
              return;
            }

            setupMfaChallenge(
              availableMfaChallenges,
              password,
              async (password, mfaToken) => onAfterPasswordClick(password, mfaToken),
              error?.message ?? 'Failed to complete the operation',
            );
          }
        }
      };

      setChallengeError(undefined);
      setPasswordChallengeCallback(() => passwordChallengeCallback);
      setChallengeStep(ChallengeStep.PASSWORD);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      checkMfaRequiredErrors,
      initTwoFactor,
      setChallengeError,
      setChallengeStep,
      setPasswordChallengeCallback,
      setAvailableMfaChallenges,
      setMfaChallengeCallback,
      closePasswordModal,
      completeOperation,
    ],
  );

  const uploadAvatar = useCallback(
    async (file: File): Promise<string> => {
      const formData = new FormData();
      formData.append('file', file);

      setAvatarLoading(true);

      try {
        const res = await uploadProfilePicture(formData);

        if (!res) {
          throw new Error('Failed to upload avatar');
        }

        await cropPicture(res);

        return res;
      } catch (e) {
        const error = e as AxiosError;
        LOG.error(error);
        const defaultMessage = 'Failed to upload avatar';
        enqueueErrorSnackbar(error?.message ?? defaultMessage);

        throw e;
      } finally {
        setAvatarLoading(false);
      }
    },
    [enqueueErrorSnackbar],
  );

  const updateEmail = useCallback(async () => {
    if (appModel.user?.email !== initialUser?.email) {
      setEmailSaving(true);

      startPasswordChallenge(async (password, mfaToken) => {
        const token = mfaToken || (await executeRecaptcha(RecaptchaAction.UPDATE_EMAIL));
        const res = await saveEmail(appModel.user?.email || '', password, token);
        setEmailSaving(false);
        setInitialUser(appModel.user);

        enqueueBasicSnackbar(snackbarMessages.user.emailSuccess);

        return res;
      });
    }
  }, [enqueueBasicSnackbar, executeRecaptcha, initialUser?.email, startPasswordChallenge, appModel]);

  const updatePhone = useCallback(async () => {
    if (appModel.user?.phone !== initialUser?.phone) {
      setPhoneSaving(true);

      startPasswordChallenge(async (password, mfaToken) => {
        const token = mfaToken || (await executeRecaptcha(RecaptchaAction.UPDATE_PHONE));
        const phone = appModel.user?.phone?.trim();
        const requestPhoneNumber = phone?.length ? phone : null; // BE accepts null as empty phone

        const res = await savePhone(requestPhoneNumber, password, token);
        setPhoneSaving(false);
        setInitialUser(appModel.user);

        enqueueBasicSnackbar(snackbarMessages.user.phoneSuccess);

        return res;
      });
    }
  }, [enqueueBasicSnackbar, executeRecaptcha, initialUser?.phone, startPasswordChallenge, appModel]);

  const updateProfile = useCallback(async () => {
    try {
      if (appModel.user) {
        const { firstName, lastName, timezone } = appModel.user;
        setProfileSaving(true);

        await saveProfile(firstName, lastName, timezone);

        setInitialUser(appModel.user);

        if (userDepartment && userDepartment !== initialUserDepartment) {
          await requestService.api.put('/api/user/v2/department', {
            body: {
              department: userDepartment,
            },
          });

          if (isCustomValue(userDepartment)) {
            const userSettingDepartmentValue = removeCustomValuePrefix(userDepartment);
            setUserDepartment(userSettingDepartmentValue);
            setInitialUserDepartment(userSettingDepartmentValue);
            setUserDepartmentList([
              ...UserSettingDepartments,
              {
                id: stringToId(userSettingDepartmentValue),
                name: userSettingDepartmentValue,
                value: userSettingDepartmentValue,
                active: true,
                editable: false,
                valid: true,
              },
            ]);
          } else {
            setUserDepartment(userDepartment);
            setInitialUserDepartment(userDepartment);
          }
        }
      }

      enqueueBasicSnackbar(snackbarMessages.user.profileUpdatedSuccess);
    } catch (e) {
      const err = e as ErrorMessage;

      if (typeof err.response.data === 'string') {
        enqueueErrorSnackbar(err.response.data);
        LOG.error(err.response.data);
      } else {
        LOG.error(err.message);
        err.response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
      }
    } finally {
      setProfileSaving(false);
      clearReportLocalStorage();
    }
  }, [enqueueBasicSnackbar, enqueueErrorSnackbar, appModel, userDepartment, initialUserDepartment]);

  const updatePassword = useCallback(
    async (newPassword: string, confirmPassword: string) => {
      if (newPassword) {
        setPasswordChallengeType(PasswordChallengeType.RESET_PASSWORD);
        setPasswordSaving(true);
        startPasswordChallenge(async password => {
          const res = await savePassword(password, newPassword, confirmPassword);
          setPasswordSaving(false);

          enqueueBasicSnackbar(snackbarMessages.user.passwordSuccess);

          return res;
        });
      }
    },
    [enqueueBasicSnackbar, startPasswordChallenge],
  );

  const onReorderChallenges = useCallback(
    (newSettings: MfaChallengeType[]) => {
      const currentSettings = mfaSettings;

      startPasswordChallenge(async password => {
        try {
          await saveMfaSettings(password, newSettings);

          setMfaSettings(newSettings);
        } catch (e) {
          setMfaSettings(currentSettings);

          throw e;
        }
      });
    },
    [startPasswordChallenge, mfaSettings],
  );

  const onChangeDepartment = useCallback((departmentName: UserSettingDepartment | string) => {
    setUserDepartment(departmentName);
  }, []);

  const switchMfaSettings = useCallback(
    async (challenge?: MfaChallengeType) => {
      const currentSettings = mfaSettings;

      startPasswordChallenge(async password => {
        let newSettings = [] as MfaChallengeType[];

        if (!challenge) {
          if (!currentSettings.length) {
            newSettings = [MfaChallengeType.EMAIL];
          }
        } else if (currentSettings.includes(challenge)) {
          newSettings = currentSettings.filter(item => item !== challenge);
        } else {
          newSettings = [...currentSettings, challenge];
        }

        try {
          if (newSettings.length) {
            await saveMfaSettings(password, newSettings);

            const isChallengeEnable = challenge && newSettings.includes(challenge);

            enqueueSuccessSnackbar(
              snackbarMessages.mfa.multiFactorAuthenticationStatus(challenge as string, isChallengeEnable!),
            );
          } else {
            await deleteMfaSettings(password);

            enqueueSuccessSnackbar(snackbarMessages.mfa.disabled);
          }

          setMfaSettings(newSettings);
        } catch (e) {
          setMfaSettings(currentSettings);

          throw e;
        }
      });
    },
    [startPasswordChallenge, mfaSettings, enqueueSuccessSnackbar],
  );

  useEffect(() => {
    requestService.api
      .get('/api/user/v2/timezone', {})
      .then(res => {
        setTimezones(
          res.data.map(tz => ({
            id: tz.id,
            name: tz.label,
            isSelected: false,
          })),
        );
      })
      .catch(err => LOG.error(err));

    requestService.api
      .get('/api/user/v2/settings', {})
      .then(res => {
        const department = mapDepartmentResponseToUserDepartmentSetting(res.data.department as string);
        const userSettingDepartment = department ?? (res.data.department as string);
        setInitialUserDepartment(userSettingDepartment);
        setUserDepartment(userSettingDepartment);

        if (isCustomValue(userSettingDepartment)) {
          const userSettingDepartmentValue = removeCustomValuePrefix(userSettingDepartment);
          setUserDepartment(userSettingDepartmentValue);
          setInitialUserDepartment(userSettingDepartmentValue);
          setUserDepartmentList([
            ...UserSettingDepartments,
            {
              id: stringToId(userSettingDepartmentValue),
              name: userSettingDepartmentValue,
              value: userSettingDepartmentValue,
              active: true,
              editable: false,
              valid: true,
            },
          ]);
        } else {
          setUserDepartment(userSettingDepartment);
          setInitialUserDepartment(userSettingDepartment);
          setUserDepartmentList(
            UserSettingDepartments.map(department => ({
              ...department,
              active: department.name === userSettingDepartment,
            })),
          );
        }
      })
      .catch(err => LOG.error(err));
  }, []);

  useEffect(() => {
    getMfaSettings()
      .then(setMfaSettings)
      .catch(() => setMfaSettings([]));
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        profileSaving,
        passwordSaving,
        emailSaving,
        phoneSaving,
        mfaLoading,
        resetPasswordLoading,
        createPasswordLoading,
        avatarLoading,
        initialUser,
        timezones,
        passwordChallengeCallback,
        challengeError,
        challengeStep,
        mfaChallengeCallback,
        availableMfaChallenges,
        mfaSettings,
        passwordChallengeType,
        uploadAvatar,
        requestPasswordCreate,
        requestPasswordReset,
        updateProfile,
        cancelProfileEditing,
        updateEmail,
        cancelEmailEditing,
        updatePhone,
        cancelPhoneEditing,
        updatePassword,
        onCloseMfaModal,
        closePasswordModal,
        onResendMfaCode,
        onChangeMfaChallenge,
        onVerifyMfaCode,
        onReorderChallenges,
        switchMfaSettings,
        mfaResendSuccess,
        mfaResendCodeBlockReason,
        mfaResendLimitReached,
        userDepartment,
        initialUserDepartment,
        onChangeDepartment,
        userDepartmentList,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
});

export function useProfileContext() {
  const context = useContext(ProfileContext);

  if (isEmpty(context)) {
    throw new Error('useProfileContext must be used within the ProfileContextProvider');
  }

  return context;
}

export default ProfileContextProvider;
