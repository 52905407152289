import React from 'react';

import { Dropdown, DropdownPlacement, Icon, IconVariant, Text, TextColor } from '@writercolab/ui-atoms';

import type { TOrganizationsDocumentsScope, TOrganizationsDocumentsSort } from '@web/types';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import DeleteDocument from '../../molecules/DocumentInfoPanel/modals/DeleteDocument';
import type { IRowProps } from '../BaseTable/BaseTable';
import { BaseTable } from '../BaseTable/BaseTable';
import type { DocsTableUiModel } from './DocsTableUiModel';
import { generateDocumentsTableData, toTableDocument } from './utils';

import styles from './DocsTable.module.css';

interface IDocumentsTableProps {
  model: DocsTableUiModel;
}
export const DOCUMENTS_TABLE_ID = 'container-documents-table';
export const DocsTable = observer<IDocumentsTableProps>(({ model }) => {
  const {
    documents,
    loading,
    hasNext,
    loadMore,
    showLoader,
    scopeFilterOptions,
    sortingOptions,
    onSortChange,
    onScopeFilterChange,
    onDocumentAction,
    deleteConfirmationModalVisible,
    setDeleteConfirmationModalVisible,
    deleteDocument,
    scopeFilterDisabled,
    sortedByCreatedDate,
    openDocument,
  } = model;

  const handleSort = (id: string) => {
    const tableContainerRef = document.getElementById(DOCUMENTS_TABLE_ID);
    tableContainerRef?.scrollTo({ top: 0 });
    onSortChange(id as typeof TOrganizationsDocumentsSort.type);
  };

  const tableHeaders = [
    {
      component: scopeFilterDisabled ? null : (
        <Dropdown
          options={scopeFilterOptions}
          trigger={
            <div className={styles.filterTrigger}>
              <Text>{scopeFilterOptions.find(s => s.active)?.name}</Text>
              <Icon name={IconVariant.DROP_DOWN_ARROW} />
            </div>
          }
          onPrimaryOptionClickAction={id => onScopeFilterChange(id as typeof TOrganizationsDocumentsScope.type)}
          placement={DropdownPlacement.BOTTOM_RIGHT}
        />
      ),
    },
    {
      component: <Text color={TextColor.GREY2}>{sortedByCreatedDate ? 'Last modified' : 'Last opened by me'}</Text>,
    },
    {
      component: (
        <Dropdown
          options={sortingOptions}
          trigger={
            <div className={styles.sortActionTriggerContainer}>
              <Icon name={IconVariant.SORT} width={18} height={18} className={styles.sortActionIcon} />
            </div>
          }
          onPrimaryOptionClickAction={handleSort}
          placement={DropdownPlacement.BOTTOM_RIGHT}
        />
      ),
    },
  ];
  const tableData = generateDocumentsTableData(
    documents.map(document => toTableDocument(document)),
    onDocumentAction,
    scopeFilterDisabled,
    sortedByCreatedDate,
  );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: hasNext,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div className={styles.container}>
      <div className={styles.scrollableContainer} ref={rootRef} id={DOCUMENTS_TABLE_ID}>
        <BaseTable
          headers={tableHeaders}
          data={tableData}
          loading={showLoader}
          gridTemplateColumns="minmax(470px,1fr) minmax(280px,1fr) minmax(250px,1fr) minmax(50px,1fr)"
          stickyHeaderQuery={`.${styles.stickyHeader}`}
          stickyContainerQuery={`.${styles.scrollableContainer}`}
          onRowClick={(row: IRowProps) => openDocument(row.id)}
        />
        <div ref={sentryRef} />
      </div>
      <DeleteDocument
        isOpen={deleteConfirmationModalVisible}
        onClose={() => setDeleteConfirmationModalVisible(false)}
        onSubmit={() => deleteDocument()}
      />
    </div>
  );
});
