import React from 'react';

import cx from 'classnames';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IPageSectionPlaceholderProps extends BaseComponentsProps {
  message: React.ReactNode;
}

export const PageSectionPlaceholder: React.FC<IPageSectionPlaceholderProps> = ({ className, message }) => (
  <div className={cx(className, styles.containerPlaceholder)}>
    <Text variant={TextSize.S} color={TextColor.GREY2}>
      {message}
    </Text>
  </div>
);

export default PageSectionPlaceholder;
