import type { ChangeEvent } from 'react';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import type {
  ISuggestionMetaClarity,
  ISuggestionMetaMetaReadability,
  ISuggestionSection,
} from '@writercolab/common-utils';
import {
  ReadabilityIndex,
  ReadabilityWriterType,
  SuggestionCategoryType,
  findSuggestionSection,
} from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import { Dropdown, Icon, IconVariant, RadioGroup, Text, TextColor, TextSize, Tooltip } from '@writercolab/ui-atoms';
import type { ISelectableBlock } from '@writercolab/ui-molecules';
import { SelectableBlocks } from '@writercolab/ui-molecules';

import { AnalyticsActivity } from 'constants/analytics';
import type { Lens } from 'ramda';
import { lensProp, pipe, set } from 'ramda';
import { useAppState } from 'state';

import ArrowIcon from '../../../../assets/icons/otherIcons/dropDownArrow.svg?react';
import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import FleschKincaldAllowlistModal from '../FleschKincaldAllowlistModal';
import SuggestionCategory from '../SuggestionCategory';
import SuggestionPageHeader from '../SuggestionPageHeader';
import SuggestionSimpleRule from '../SuggestionRule/SuggestionSimpleRule';
import PassiveVoiceExample from '../examples/PassiveVoiceExample';
import SplitInfinitivesExample from '../examples/SplitInfinitivesExample';
import { VocabularySimplificationExample } from '../examples/VocabularySimplificationExample';
import WordinessExample from '../examples/WordinessExample';
import ExampleTooltip from '../examples/generic/ExampleTooltip';

import styles from '../styles.module.css';

interface IDescriptionItem {
  header: string;
  body: React.ReactNode;
}

interface ISimpleBlockDescriptionProps {
  items: IDescriptionItem[];
  isBlockActive?: boolean;
  isLocked?: boolean;
}

const WRITER_READABILITY_DEFAULTS_CONFIG = {
  [ReadabilityWriterType.SIMPLE]: {
    complexityValue: 8,
    vocabularyValue: 8,
  },
  [ReadabilityWriterType.GENERAL]: {
    complexityValue: 12,
    vocabularyValue: 12,
  },
  [ReadabilityWriterType.ADVANCED]: {
    complexityValue: 13,
    vocabularyValue: 13,
  },
  [ReadabilityWriterType.CUSTOM]: {
    complexityValue: 13,
    vocabularyValue: 13,
  },
};

const clarityWordinessLens = lensProp<ISuggestionMetaClarity, 'wordiness'>('wordiness');
const clarityInfinitiveLens = lensProp<ISuggestionMetaClarity, 'infinitive'>('infinitive');
const clarityPassiveVoiceLens = lensProp<ISuggestionMetaClarity, 'passiveVoice'>('passiveVoice');
const vocabularySimplificationLens = lensProp<ISuggestionMetaClarity, 'vocabularySimplification'>(
  'vocabularySimplification',
);

const readabilityIndexLens = lensProp<ISuggestionMetaMetaReadability, 'index'>('index');
const readabilitySelectedLens = lensProp<ISuggestionMetaMetaReadability, 'selected'>('selected');
const readabilitySentenceComplexityLens = lensProp<ISuggestionMetaMetaReadability, 'sentenceComplexity'>(
  'sentenceComplexity',
);
const readabilityVocabularyLens = lensProp<ISuggestionMetaMetaReadability, 'vocabulary'>('vocabulary');
const readabilityGradeLens = lensProp<ISuggestionMetaMetaReadability, 'grade'>('grade');
const readabilityIgnoreLens = lensProp<ISuggestionMetaMetaReadability, 'ignore'>('ignore');

const getActiveColor = (isActive?: boolean): TextColor => (isActive ? TextColor.BLACK : TextColor.GREY);

const BlockDescription = ({ items, isBlockActive, isLocked }: ISimpleBlockDescriptionProps) => {
  const color = getActiveColor(isBlockActive);

  return (
    <div
      className={cx(styles.selectBlockDescription, {
        [styles.eventsDisabled]: isLocked,
      })}
    >
      {items.map(item => (
        <div className={styles.selectBlockDescriptionItem} key={`${item.header}${item.body}`}>
          <Text variant={TextSize.M} bold color={color} className={styles.selectBlockDescriptionHead}>
            {item.header}
          </Text>
          {item.body}
        </div>
      ))}
    </div>
  );
};

const SimpleBlockDescription = ({ isBlockActive, isLocked }) => {
  const items = [
    {
      header: 'Target grade level',
      body: (
        <div>
          <Text variant={TextSize.M} className={styles.descriptionTitle} color={TextColor.GREY}>
            Up to Grade 6
          </Text>
          <Text variant={TextSize.M} color={TextColor.GREY} className={styles.descriptionBody}>
            Best for support articles, consumer guides, emails, landing pages, etc.
          </Text>
        </div>
      ),
    },
  ];

  return <BlockDescription items={items} isBlockActive={isBlockActive} isLocked={isLocked} />;
};

const GeneralBlockDescription = ({ isBlockActive, isLocked }) => {
  const items = [
    {
      header: 'Target grade level',
      body: (
        <div>
          <Text variant={TextSize.M} className={styles.descriptionTitle} color={TextColor.GREY}>
            Up to Grade 12
          </Text>
          <Text variant={TextSize.M} color={TextColor.GREY} className={styles.descriptionBody}>
            Best for expert blogs, whitepapers, press releases, quarterly reports, etc.
          </Text>
        </div>
      ),
    },
  ];

  return <BlockDescription items={items} isBlockActive={isBlockActive} isLocked={isLocked} />;
};

const AdvancedBlockDescription = ({ isBlockActive, isLocked }) => {
  const items = [
    {
      header: 'Target grade level',
      body: (
        <div>
          <Text variant={TextSize.M} className={styles.descriptionTitle} color={TextColor.GREY}>
            College+
          </Text>
          <Text variant={TextSize.M} color={TextColor.GREY} className={styles.descriptionBody}>
            Best for academic papers, textbooks, technical documentation, etc.
          </Text>
        </div>
      ),
    },
  ];

  return <BlockDescription items={items} isBlockActive={isBlockActive} isLocked={isLocked} />;
};

const FleshKincaidGradeLevelAddition = ({ fleshKincaidGradeLevelChange, gradeValue, isActive }) => {
  const gradesValueList = ['4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16'];
  const dropdownOptions: DropdownOption[] = gradesValueList.map(g => ({
    id: g,
    name: `Grade ${g}`,
    active: +gradeValue === +g,
  }));
  const Trigger = () => (
    <div className={styles.selectBlockTriggerContainer}>
      <Text color={TextColor.GREY} variant={TextSize.M}>
        {`Grade ${gradeValue}`}
      </Text>
      <div className={styles.selectTriggerIcon}>
        <ArrowIcon className={styles.arrowIcon} />
      </div>
    </div>
  );

  return (
    <div
      className={cx(styles.selectBlockDescriptionItem, styles.selectBlockDescriptionItemShort, {
        [styles.eventsDisabled]: !isActive,
      })}
    >
      <Dropdown
        trigger={<Trigger />}
        dropDownContainerClassName={styles.selectDropdownContainer}
        containerClassName={styles.selectDropdown}
        triggerClassName={styles.selectDropdownTrigger}
        options={dropdownOptions}
        onPrimaryOptionClickAction={fleshKincaidGradeLevelChange}
      />
    </div>
  );
};

const FleschKincaidTooltip = () => {
  const TooltipContent = () => (
    <Text color={TextColor.WHITE}>
      Flesch-Kincaid Grade Level is a standardized readability formula that approximates the grade level of a text by
      factoring in sentence length and word length. When this suggestion is enabled, we'll flag each sentence that
      measures above your selected grade level.
    </Text>
  );

  return (
    <Tooltip title={<TooltipContent />} placement="top">
      <div className={styles.fleschKincaidInfo}>
        <Icon name={IconVariant.INFO_OUTLINED} />
      </div>
    </Tooltip>
  );
};

export const ClarityPage: React.FC = () => {
  usePageTitle('Clarity');
  const {
    appModel: { analyticsService },
  } = useAppState();
  const {
    isSaving,
    isReadOnly,
    isTeamNameShown,
    suggestionSettings,
    lastUpdated,
    lastUpdatedBy,
    teamName,
    setSectionEnabled,
    updateSectionMeta,
  } = useSuggestionsContext();

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.suggestionsClarityViewed, {});
  }, [analyticsService]);

  const [fleshKincaldAllowListVisible, setFleshKincaldAllowListVisible] = useState(false);

  const clarity: ISuggestionSection<ISuggestionMetaClarity> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.CLARITY, suggestionSettings)!,
    [suggestionSettings],
  );

  const readability: ISuggestionSection<ISuggestionMetaMetaReadability> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.READABILITY, suggestionSettings)!,
    [suggestionSettings],
  );

  const onUpdateClarityRule = (lens: Lens<ISuggestionMetaClarity, boolean>, checked: boolean) => {
    const newClarityMeta = set(lens, checked, clarity.meta);
    updateSectionMeta(SuggestionCategoryType.CLARITY, newClarityMeta);
  };

  const onUpdateReadabilityRule = (
    lens: Lens<ISuggestionMetaMetaReadability, any>,
    value: string | number | string[],
  ) => {
    const newReadabilityMeta = set(lens, value, readability.meta);
    updateSectionMeta(SuggestionCategoryType.READABILITY, newReadabilityMeta);
  };

  const onReadabilityIndexChange = (e: ChangeEvent<HTMLInputElement>) =>
    onUpdateReadabilityRule(readabilityIndexLens, e.target.value as ReadabilityIndex);

  const onReadabilityWriterBlockChange = (type: string) => {
    const complexityValue = WRITER_READABILITY_DEFAULTS_CONFIG[type]?.complexityValue || 0;
    const vocabularyValue = WRITER_READABILITY_DEFAULTS_CONFIG[type]?.vocabularyValue || 0;

    const newReadabilityMeta = pipe(
      set(readabilitySelectedLens, type as ReadabilityWriterType),
      set(readabilitySentenceComplexityLens, complexityValue),
      set(readabilityVocabularyLens, vocabularyValue),
    )(readability.meta);

    updateSectionMeta(SuggestionCategoryType.READABILITY, newReadabilityMeta);
  };

  const fleshKincaidGradeLevelChange = (value: string) =>
    onUpdateReadabilityRule(readabilityGradeLens, parseInt(value, 10));

  const closeFleshKincaldAllowlist = () => setFleshKincaldAllowListVisible(false);
  const openFleshKincaldAllowList = () => {
    if (isReadabilityIndexFleshKincaid && !access.readabilityDisabled) {
      setFleshKincaldAllowListVisible(true);
    }
  };

  const onFleshKincaidAllowListSave = (list: string[]) => {
    const filteredList = list.filter(Boolean).map(item => item.trim());
    onUpdateReadabilityRule(readabilityIgnoreLens, filteredList);
    closeFleshKincaldAllowlist();
  };

  const fleshKincaldAllowList = useMemo(() => {
    if (!readability.meta.ignore.length) {
      return new Array(5).fill('');
    }

    return readability.meta.ignore;
  }, [readability.meta.ignore]);

  const access = {
    clarityDisabled: !clarity.enabled || isReadOnly,
    readabilityDisabled: !readability.enabled || isReadOnly,
  };

  const isSimpleBlockSelected = readability.meta.selected === ReadabilityWriterType.SIMPLE;
  const isGeneralBlockSelected = readability.meta.selected === ReadabilityWriterType.GENERAL;
  const isAdvancedBlockSelected = readability.meta.selected === ReadabilityWriterType.ADVANCED;
  const isReadabilityIndexWriter = readability.meta.index === ReadabilityIndex.WRITER;
  const isReadabilityIndexFleshKincaid = readability.meta.index === ReadabilityIndex.FLESCH_KINCAID;

  const selectableBlocks: ISelectableBlock[] = [
    {
      id: ReadabilityWriterType.SIMPLE,
      title: 'Simple',
      selected: isSimpleBlockSelected,
      description: (
        <SimpleBlockDescription isBlockActive={isSimpleBlockSelected} isLocked={!isReadabilityIndexWriter} />
      ),
      locked: !isReadabilityIndexWriter || access.readabilityDisabled,
    },
    {
      id: ReadabilityWriterType.GENERAL,
      title: 'General',
      selected: isGeneralBlockSelected,
      description: (
        <GeneralBlockDescription isBlockActive={isGeneralBlockSelected} isLocked={!isReadabilityIndexWriter} />
      ),
      locked: !isReadabilityIndexWriter || access.readabilityDisabled,
    },
    {
      id: ReadabilityWriterType.ADVANCED,
      title: 'Advanced',
      selected: isAdvancedBlockSelected,
      description: (
        <AdvancedBlockDescription isBlockActive={isAdvancedBlockSelected} isLocked={!isReadabilityIndexWriter} />
      ),
      locked: !isReadabilityIndexWriter || access.readabilityDisabled,
    },
  ];

  const radioGroupOptions = [
    {
      value: ReadabilityIndex.WRITER,
      text: (
        <Text bold className={styles.radioGroupLabel}>
          Writer Readability Level
        </Text>
      ),
      description: (
        <Text variant={TextSize.L}>
          Using Writer’s readability model, we’ll highlight any complex sentences above your selected
          <br /> grade level and suggest a simpler replacement.
        </Text>
      ),
      additions: (
        <div className={cx(styles.selectBlockContainer, styles.selectBlockContainerSelectBlocks)}>
          <SelectableBlocks blocks={selectableBlocks} onSelect={onReadabilityWriterBlockChange} />
        </div>
      ),
    },
    {
      value: ReadabilityIndex.FLESCH_KINCAID,
      description: (
        <Text variant={TextSize.L} className={styles.fleschKincaidDescription}>
          Using the Flesch-Kincaid grade level calculation, we’ll highlight any complex sentences above
          <br /> your selected grade level and suggest a simpler replacement.
        </Text>
      ),
      text: (
        <Text variant={TextSize.XXL} bold className={styles.radioGroupLabel}>
          Flesch-Kincaid Grade Level
        </Text>
      ),
      additions: (
        <div className={styles.selectBlockContainer}>
          <FleschKincaidTooltip />

          <div className={styles.clarityRadioGradeLevel}>
            <FleshKincaidGradeLevelAddition
              fleshKincaidGradeLevelChange={fleshKincaidGradeLevelChange}
              gradeValue={readability.meta.grade}
              isActive={isReadabilityIndexFleshKincaid}
            />
            <div onClick={openFleshKincaldAllowList} className={styles.customizeAllowlistLink}>
              <Text className={styles.customLink} color={TextColor.GREY} variant={TextSize.M}>
                Customize allowlist
              </Text>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Clarity"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      <Text variant={TextSize.XL} className={styles.description}>
        Clear writing ensures that a wide range of audiences can quickly understand your content.
        <br />
        When a switch is on, we’ll let you know if your content has issues in that category.
      </Text>
      <SuggestionCategory
        readOnly={isReadOnly}
        name="Readability"
        type={SuggestionCategoryType.READABILITY}
        description=""
        halfLimitedWidth
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
        className={styles.categoryContainerShort}
      >
        <div className={cx(styles.radioGroupContainer, styles.clarityRadioGroupContainer)}>
          <RadioGroup
            name="readability"
            className={cx(styles.radioGroup, styles.radioGroupClarity)}
            options={radioGroupOptions}
            onChange={onReadabilityIndexChange}
            currentValue={readability.meta.index}
            disabled={access.readabilityDisabled}
          />
        </div>
        <div className={cx(styles.radioGroupItemDescription, styles.alternativeCalculatorsTitle)}>
          <Text extraSmallCaps color={TextColor.GREY} variant={TextSize.XS}>
            Alternative readability calculators
          </Text>
        </div>
      </SuggestionCategory>
      <SuggestionCategory
        readOnly={isReadOnly}
        name="Additional Clarity suggestions"
        type={SuggestionCategoryType.CLARITY}
        description="Recommendation: leave these suggestions enabled to keep your content clear and easy to understand."
        halfLimitedWidth
        hideBottomBorder
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
      >
        <div className={styles.suggestionSimpleRuleList}>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.simpleRule)}
            readOnly={access.clarityDisabled}
            isChecked={clarity.meta.passiveVoice}
            onUpdateRuleValue={(checked: boolean) => onUpdateClarityRule(clarityPassiveVoiceLens, checked)}
          >
            <ExampleTooltip example={<PassiveVoiceExample />} title="Passive voice" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.simpleRule)}
            readOnly={access.clarityDisabled}
            isChecked={clarity.meta.infinitive}
            onUpdateRuleValue={(checked: boolean) => onUpdateClarityRule(clarityInfinitiveLens, checked)}
          >
            <ExampleTooltip example={<SplitInfinitivesExample />} title="Split infinitive" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.simpleRule)}
            readOnly={access.clarityDisabled}
            isChecked={clarity.meta.vocabularySimplification}
            onUpdateRuleValue={(checked: boolean) => onUpdateClarityRule(vocabularySimplificationLens, checked)}
          >
            <ExampleTooltip example={<VocabularySimplificationExample />} title="Vocabulary simplification" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.simpleRule)}
            readOnly={access.clarityDisabled}
            isChecked={clarity.meta.wordiness}
            onUpdateRuleValue={(checked: boolean) => onUpdateClarityRule(clarityWordinessLens, checked)}
          >
            <ExampleTooltip example={<WordinessExample />} title="Wordiness" />
          </SuggestionSimpleRule>
        </div>
      </SuggestionCategory>

      <FleschKincaldAllowlistModal
        list={fleshKincaldAllowList}
        isOpen={fleshKincaldAllowListVisible}
        onSave={onFleshKincaidAllowListSave}
        onCancel={closeFleshKincaldAllowlist}
      />
    </div>
  );
};

export default ClarityPage;
