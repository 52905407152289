/**
 * Validates a phone number.
 * @param phoneNumber - The phone number to validate.
 * @returns True if the phone number is valid, false otherwise.
 */
export const validatePhoneNumber = (phoneNumber: string): boolean => {
  const phoneNumberRegex = /^\+[1-9]\d{1,14}$/;

  return phoneNumberRegex.test(phoneNumber);
};

/**
 * Validates if a given string is a well-formed URL with support for wildcard characters.
 *
 * The method checks the URL structure, allowing for the "*" wildcard character to be used
 * in place of various URL components like protocol, domain, path, etc. This is useful in
 * scenarios where URLs with flexible subdomains or paths are needed, for example, in
 * matching callback or redirect URLs in web applications.
 *
 * The method uses a regular expression to validate the URL format, ensuring adherence to
 * general URL standards while allowing for the specified wildcard flexibility.
 *
 * @param url - The URL string to be validated. It can contain "*" as a wildcard character.
 * @returns A boolean indicating whether the URL is valid with wildcard support.
 *          Returns `true` for a valid URL, `false` otherwise.
 *
 * @example
 * isValidWildcardURL("https://*.example.com"); // returns true
 * isValidWildcardURL("*.example.com"); // returns true
 * isValidWildcardURL("https://example.*"); // returns true
 * isValidWildcardURL("https://example.com/*"); // returns true
 * isValidWildcardURL("*://example.com"); // returns true
 * isValidWildcardURL("example.com"); // returns true, since protocol is optional
 * isValidWildcardURL("https://example.com"); // returns true
 * isValidWildcardURL("just a string"); // returns false
 * isValidWildcardURL("https://"); // returns false
 */
export const isValidWildcardURL = (url: string): boolean => {
  const wildcardURLRegex = new RegExp(
    '^' +
      // protocol identifier (optional)
      '(?:(?:https?|ftp)://)?' +
      // user:pass authentication (optional)
      '(?:\\S+(?::\\S*)?@)?' +
      // domain name with wildcard "*"
      '(?:' +
      '(?:\\*\\.)?' + // allow a "*" only at the beginning followed by a dot "."
      '(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+' +
      '[a-zA-Z]{2,}' +
      '|' +
      // OR a wildcard "*" (for the cases like just "*")
      '\\*' +
      ')' +
      // port number (optional)
      '(?::\\d{2,5})?' +
      // resource path (optional)
      '(?:/[^\\s]*)?' +
      '$',
    'i',
  );

  return wildcardURLRegex.test(url);
};

/**
 * This function checks if a given string is a valid JSON string or not.
 *
 * @export
 * @param {string} str - The string to be tested for JSON validity.
 * @returns {boolean} Returns true if the string is a valid JSON, otherwise returns false.
 *
 * @example
 * // returns true
 * isJsonString('{"name":"John", "age":30, "city":"New York"}');
 *
 * @example
 * // returns false
 * isJsonString('This is not a JSON string');
 */
export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const isValidNumber = (value: unknown): boolean => {
  if (typeof value === 'number') {
    return !Number.isNaN(value);
  }

  if (typeof value === 'string') {
    const parsedNumber = parseFloat(value);

    return !Number.isNaN(parsedNumber);
  }

  return false;
};
