import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AnalyticsService } from '@writercolab/analytics';
// TODO: move IOrganization to @writercolab/types
import type { IOrganization } from '@writercolab/common-utils';
import { isUserEmailUnconfirmed } from '@writercolab/common-utils';
import { FeatureFlags, LaunchdarklyClient, createContext, createDefaultLocalStorage } from '@writercolab/feature-flags';
import { FieldModel, PromisedError, PromisedModel } from '@writercolab/mobx';
import {
  AiAssistantSubscriptionModel,
  AiStudioAccountModel,
  DataRetentionPreferencesApiModel,
  ModalsManager,
  NotificationsModel,
} from '@writercolab/models';
import { ApiError } from '@writercolab/network';
import type { ISidebarDataParams, ITeam, IUserProfile, TOrgTeamUserActivityParams } from '@writercolab/types';
import { E_CLIENT_ID } from '@writercolab/types';
import { AppLibraryModel } from '@writercolab/ui-app-library';
import { CommandsModel } from '@writercolab/ui-commands';
import { createLongPoolingClient } from '@writercolab/ui-sidebar-models';
import { Enum } from '@writercolab/utils';

import type { TFeatureFlags } from '@web/types';
import { filterRecentWebappNotifications, getRecentWebappNotificationsSummary } from '@web/utils';
import { TWebAnalyticsParams } from 'constants/analytics';
import merge from 'lodash/merge';
import { OfflineFeatureFlagsClient } from 'services/OfflineFeatureFlagsClient';
// eslint-disable-next-line no-restricted-imports
import { analyticsServiceBase } from 'services/analytics/analyticsService';
import requestService from 'services/request/requestService';
import { initFirebaseClient } from 'services/sidebarDataClients/firebase';
import config from 'utils/dynamicConfig';

import { getUserProfile } from '../services/request/user';
import { ConsoleApplicationTagsApiModel } from './ConsoleApplicationTags.api';
import { PermissionModel } from './permission';
import { TeamsModel } from './teams';
import { VoiceApiModel } from './voice.api';

export const TAppModals = new Enum('aiStudioOptIn', 'aiStudioContactAdmin');

const { VITE_VERSION, VITE_LAUNCH_DARKLY } = import.meta.env;

export class AppModel {
  documentId: string | undefined = undefined;

  team: ITeam | undefined = undefined;
  organization: IOrganization | undefined = undefined;

  isOptingIntoAiStudio = FieldModel.build({ init: false });
  modalsManager = new ModalsManager<typeof TAppModals.type, string>();

  analyticsService: AnalyticsService<TWebAnalyticsParams, TOrgTeamUserActivityParams> =
    analyticsServiceBase.withContext<TOrgTeamUserActivityParams>({
      organization_id: () => this.organizationId ?? -1,
      user_email: () => this.user?.email ?? '',
      user_id: () => this.user?.id ?? -1,
      team_id: () => this.teamId ?? -1,
    });

  featureFlags = new FeatureFlags<TFeatureFlags>({
    getClient: () =>
      config.SINGLE_TENANCY_CONFIG?.offlineFeatureFlags ? this.offlineFeatureFlagClient : this.launchDarklyClient,
    getStorage: () => createDefaultLocalStorage('featureFlagsCache'),
  });

  commandsModel = new CommandsModel({
    request: requestService.api,
    organizationId: () => Number(this.organizationId),
    teamId: () => Number(this.teamId),
    isVoiceEnabled: () => this.assistantSubscription.isModelReady && !!this.assistantSubscription.access?.voice,
  });

  // observable object to store updated user profile
  private $user = observable.box<Partial<IUserProfile> | undefined>(undefined);

  constructor() {
    makeObservable(this, {
      documentId: observable,
      team: observable,
      organization: observable,

      aiStudioSubscription: computed,
      aiStudioBalance: computed,
      permissionsModel: computed,
      voiceModel: computed.struct,
      dataRetentionModel: computed.struct,
      appLibraryModel: computed.struct,
      isForcedAIStudioAPIKey: computed,

      setUser: action,
      setTeam: action,
      setOrganization: action,
      setDocumentId: action,

      organizationId: computed,
      userId: computed,
      teamId: computed,
      isAuthenticated: computed,
      authenticationError: computed,
      isUnverifiedEmail: computed,
      launchDarklyClient: computed,
      user: computed,
      userTimeZone: computed,
      cmudictLib: computed,

      generationJobNotifications: computed,
      generationJobNotificationsSummary: computed,
    });
  }

  get organizationId() {
    if (typeof this.isUnverifiedEmail === 'undefined' || this.isUnverifiedEmail) {
      return undefined;
    }

    return this.organization?.id;
  }

  // combine user profile from the server and updated user profile
  get user(): IUserProfile | undefined {
    const initialUser = this.$userProfile.value;
    const updatedUser = this.$user.get();

    return initialUser ? merge({}, initialUser, updatedUser) : undefined;
  }

  get userId() {
    return this.user?.id;
  }

  get teamId() {
    return this.team?.id;
  }

  get isUnverifiedEmail() {
    return isUserEmailUnconfirmed(this.user);
  }

  get isForcedAIStudioAPIKey() {
    return this.featureFlags.get('forceAIStudioAPIKey', true);
  }

  get dataClient() {
    const params: ISidebarDataParams = {
      userId: () => this.userId,
      organizationId: () => this.organizationId,
      teamId: () => this.teamId,
      documentId: () => this.documentId,
      personaId: '94',
    };

    if (config.SINGLE_TENANCY_CONFIG?.disableFirebase) {
      return createLongPoolingClient({
        params,
        request: requestService.api,
      });
    }

    return initFirebaseClient(params);
  }

  userSettings = new PromisedModel({
    name: '$userSettings',
    load: async () => {
      const data = await requestService.api.get('/api/user/v2/settings', {}).then(res => res.data);

      return data;
    },
  });

  orgAdmins = new PromisedModel({
    name: '$orgAdmins',
    load: async () => {
      if (!this.organizationId) {
        return undefined;
      }

      const data = await requestService.api
        .get('/api/user/v2/organization/{organizationId}/admins', {
          params: {
            path: {
              organizationId: this.organizationId,
            },
          },
        })
        .then(res => res.data.users);

      return data;
    },
  });

  $permissions = new PromisedModel({
    name: '$permissions',
    load: async () => {
      const data = await requestService.api.get('/api/auth/permission', {}).then(res => res.data);

      return data;
    },
  });

  // initial user profile
  private $userProfile = new PromisedModel({
    name: '$userProfile',
    load: async () => {
      const data = await getUserProfile();

      return data;
    },
  });

  get launchDarklyClient() {
    if (!this.user || !this.permissionsModel || !this.organizationId || !this.assistantSubscription) {
      return undefined;
    }

    const userContext = createContext({
      email: this.user?.email ?? undefined,
      userId: this.user?.id,
      teamId: this.team?.id,
      isFree: this.assistantSubscription.isFree,
      isEnterprise: this.assistantSubscription.isEnterprise,
      isTeamAdmin: !!this.permissionsModel?.isTeamAdmin,
      organizationId: this.organizationId,
      isOrganizationAdmin: !!this.permissionsModel?.isOrganizationAdmin,
      consoleRole: this.permissionsModel?.orgPermissions?.consoleRole,
      internalAtWriter: this.permissionsModel?.orgPermissions?.internalAtWriter,
    });

    return new LaunchdarklyClient({
      envKey: VITE_LAUNCH_DARKLY,
      context: userContext,
      options: {
        application: {
          version: VITE_VERSION,
          id: E_CLIENT_ID.enum.QordobaFE,
        },
      },
    });
  }

  get offlineFeatureFlagClient() {
    return new OfflineFeatureFlagsClient<TFeatureFlags>(
      config.SINGLE_TENANCY_CONFIG?.offlineFeatureFlags ?? {
        canUploadMp3AndMp4: true,
        aiStudioAllowed: true,
        extensionsPageAvailable: false,
        recentlyUsedAvailable: false,
        publicAppsAvailable: true,
        imageAnalysisAvailable: true,
        styleguideAvailable: true,
        writerFrameworkDeployment: false,
        suggestionsFoundReportAvailable: false,
        aiStudioObservability: {
          general: false,
          noCode: false,
          API: false,
          appPerformance: false,
          sessions: false,
          apiPerformanceTTFT: false,
        },
        availableExtensionsList: [],
        slackDeployment: true,
        confluenceConnectorEnabled: true,
        reportingPreGAHistory: true,
        agentEditor: false,
        forceAIStudioAPIKey: true,
        aiUsageReportAvailable: true,
        documentHistoryAvailable: true,
        adminAuditLogPageAvailable: true,
        userActivityReportAvailable: true,
        showPalmyraV5Model: false,
        showFireflyModel: false,
        aisUsageLimits: false,
        onboardingRefresh: {
          disableAISLogin: false,
          useV3Questionnaire: false,
          disableLandingPagesLogin: false,
          disableLegacyDiscovery: false,
          disableLegacyQuestionnaire: false,
        },
        waMyWorkPage: false,
        kgWebConnectorEnabled: false,
        onboardingV2: false,
        onboardingStarterPlan: false,
        aiStudioChatBuilderPreconnectedSources: false,
      },
    );
  }

  get isAuthenticated() {
    if (this.permissionsModel === undefined) {
      return this.permissionsModel;
    }

    return this.permissionsModel !== null;
  }

  get authenticationError() {
    if (
      this.$permissions.valueWithError instanceof PromisedError &&
      this.$permissions.valueWithError.error instanceof ApiError
    ) {
      return this.$permissions.valueWithError.error;
    }

    return undefined;
  }

  get permissionsModel() {
    if (this.$permissions.status === 'pending') {
      return undefined;
    }

    if (!this.$permissions.value) {
      return null;
    }

    return new PermissionModel({
      permissions: this.$permissions.value,
      teamId: this.teamId,
      userId: this.userId,
      organizationId: this.organizationId,
      teamsModel: this.teamsModel,
      assistantSubscriptionModel: this.assistantSubscription,
    });
  }

  assistantSubscription = new AiAssistantSubscriptionModel({
    api: requestService.api,
    organizationId: () => this.organizationId,
    teamId: () => this.teamId,
  });

  aiStudioAccount = new AiStudioAccountModel({
    api: requestService.api,
    organizationId: () => (this.organizationId ? Number(this.organizationId) : undefined),
  });

  get appLibraryModel() {
    const { teamId, organizationId } = this;

    if (!teamId || !organizationId) {
      return undefined;
    }

    return new AppLibraryModel({
      request: requestService.api,
      subscriptionModel: this.assistantSubscription,
      analyticsService: this.analyticsService,
      teamId: () => teamId,
      organizationId: () => organizationId,
      isBlockedByBalance: () => this.aiStudioBalance?.insufficientBalance ?? false, // Re-enabled in CORE-84
      isImageAnalysisAvailable: () => this.featureFlags.get('imageAnalysisAvailable', false),
      isPublicApplicationsAvailable: () => this.featureFlags.get('publicAppsAvailable', true),
      isBetaApplicationsAvailable: () => false,
      getPinnedApplicationsIds: () => [],
      getRecentApplicationsIds: () => [],
      isBlockedByUsageLimits: () =>
        !!this.aiStudioBalance?.monthlyBudgetReached || !!this.aiStudioBalance?.usageLimitReached,
    });
  }

  get aiStudioSubscription(): (typeof this.aiStudioAccount)['subscription'] {
    return this.aiStudioAccount.subscription;
  }

  get aiStudioBalance() {
    return this.aiStudioAccount.balance;
  }

  teamsModel: TeamsModel = new TeamsModel({
    request: requestService.api,
    teamId: () => (this.teamId ? Number(this.teamId) : undefined),
    organizationId: () => this.organizationId,
    permissionsModel: () => this.permissionsModel,
  });

  notificationsModel = new NotificationsModel({
    client: this.dataClient,
  });

  get generationJobNotifications() {
    return this.notificationsModel.deriveNotifications(filterRecentWebappNotifications);
  }

  get generationJobNotificationsSummary() {
    return getRecentWebappNotificationsSummary(this.generationJobNotifications);
  }

  get voiceModel() {
    if (this.assistantSubscription.isFree && !this.aiStudioSubscription?.isSubscriptionActive) {
      return undefined;
    }

    return new VoiceApiModel({
      request: requestService.api,
      organizationId: () => (this.organizationId ? Number(this.organizationId) : undefined),
      teamId: () => (this.teamId ? Number(this.teamId) : undefined),
      isVoiceEnabled: () => this.assistantSubscription.isModelReady && !!this.assistantSubscription.access?.voice,
    });
  }

  applicationTags = new ConsoleApplicationTagsApiModel({
    request: requestService.api,
  });

  get dataRetentionModel() {
    if (!this.assistantSubscription.access?.retention) {
      return undefined;
    }

    return new DataRetentionPreferencesApiModel({
      request: requestService.api,
      organizationId: this.organizationId ? Number(this.organizationId) : 0,
    });
  }

  optInIntoAiStudio = async () => {
    if (!this.organizationId) {
      return;
    }

    this.isOptingIntoAiStudio.set(true);

    try {
      await requestService.api
        .post('/api/billing/organizations/{organizationId}/subscription/aistudio/payg', {
          params: {
            path: {
              organizationId: this.organizationId,
            },
          },
        })
        .then(r => r.data);

      await this.refreshAiStudioSubscription();
    } finally {
      runInAction(() => {
        this.isOptingIntoAiStudio.set(false);
      });
    }
  };

  setDocumentId = (id: string | undefined) => {
    this.documentId = id;
  };

  setUser = (user: Partial<IUserProfile> | undefined) => {
    this.$user.set(user);
  };

  setTeam = (team?: ITeam) => {
    this.team = team;
  };

  setOrganization = (organization?: IOrganization) => {
    this.organization = organization;
  };

  refreshAiStudioSubscription = () => this.aiStudioAccount.reloadSubscription();

  refreshPermissions = () => {
    this.$permissions.reload();

    return this.$permissions.promise;
  };

  refreshUserSettings = () => {
    this.userSettings.reload();

    return this.userSettings.promise;
  };

  get userTimeZone() {
    return this.user?.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  readonly $cmudictLib = new PromisedModel({
    name: 'cmudictLib',
    load: async () => {
      const cmuDictAsyncModule = await import('@writercolab/cmudict');

      return cmuDictAsyncModule?.cmudict;
    },
  });

  get cmudictLib(): Partial<Record<string, [string[]]>> | undefined {
    return this.$cmudictLib.value;
  }
}
