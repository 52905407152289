import { useEffect, useState } from 'react';

export function useBrowserReload() {
  const reloadParamName = 'applyActionAfterRefresh';

  const [wasReloaded, setWasReloaded] = useState(localStorage.getItem(reloadParamName) === 'true');
  const cleanUpReloadMarker = () => {
    setWasReloaded(false);
    localStorage.removeItem(reloadParamName);
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      localStorage.setItem(reloadParamName, 'true');
    };
  }, []);

  return {
    wasReloaded,
    cleanUpReloadMarker,
  };
}
