import cx from 'classnames';
import { Highlight, Snippet } from 'react-instantsearch-dom';

import { Text, TextSize } from '@writercolab/ui-atoms';
import { renderTermType } from '@writercolab/ui-molecules';
import type { TermType } from '@writercolab/common-utils';
import type { HitComponentProps, IHit, IPageContentHit, ISnippetHit, ITermHit } from '@web/types';
import { HitType } from '@web/types';
import styles from './styles.module.css';

function PageContentHit({ hit }: HitComponentProps<IPageContentHit>) {
  return (
    <div className={styles.hitContainer}>
      <div className={styles.hitContent}>
        <div className={cx(styles.hitCol, styles.descriptionCol)}>
          <Text variant={TextSize.L} ellipsisOverflow>
            <Snippet attribute="content.html" hit={hit} tagName="mark" />
          </Text>
        </div>
      </div>

      <div className={styles.hitDetails}>
        <Text variant={TextSize.L} bold>
          <Snippet attribute="title" hit={hit} tagName="mark" />
        </Text>

        <Text variant={TextSize.XXS} smallCaps ellipsisOverflow>
          {hit.section}
        </Text>
      </div>
    </div>
  );
}

function TermHit({ hit }: HitComponentProps<ITermHit>) {
  return (
    <div className={styles.hitContainer}>
      <div className={styles.hitContent}>
        <div className={cx(styles.hitCol, styles.nameCol)}>
          <Text variant={TextSize.XL} bold>
            <Highlight attribute="content.term" hit={hit} tagName="mark" />
          </Text>
        </div>

        <div className={styles.hitCol}>{renderTermType(hit.content.type as TermType)}</div>

        <div className={cx(styles.hitCol, styles.descriptionCol)}>
          <Text variant={TextSize.L}>
            <Snippet attribute="content.description" hit={hit} tagName="mark" />
          </Text>
        </div>
      </div>

      <div className={styles.hitDetails}>
        <Text variant={TextSize.L} bold>
          <Snippet attribute="title" hit={hit} tagName="mark" />
        </Text>
      </div>
    </div>
  );
}

function SnippetHit({ hit }: HitComponentProps<ISnippetHit>) {
  return (
    <div className={styles.hitContainer}>
      <div className={styles.hitContent}>
        <div className={styles.hitCol}>
          <Text variant={TextSize.L}>
            <Snippet attribute="content.snippet" hit={hit} tagName="mark" />
          </Text>
        </div>
      </div>

      <div className={styles.hitDetails}>
        <Text variant={TextSize.XL} bold>
          <Highlight attribute="content.shortcut" hit={hit} tagName="mark" />
        </Text>
      </div>
    </div>
  );
}

/*
 * SearchHit fabric component
 * */
export function SearchHit({ hit }: HitComponentProps<IHit>) {
  switch (hit.type) {
    case HitType.HTML:
      return (
        <a className={styles.hitLink} href={hit.url}>
          <PageContentHit hit={hit as IPageContentHit} />
        </a>
      );

    case HitType.TERM:
      return (
        <a className={styles.hitLink} href={hit.url}>
          <TermHit hit={hit as ITermHit} />
        </a>
      );

    case HitType.SNIPPET:
      return (
        <a className={styles.hitLink} href={hit.url}>
          <SnippetHit hit={hit as ISnippetHit} />
        </a>
      );

    default:
      return null;
  }
}

export default SearchHit;
