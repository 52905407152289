import { useMemo } from 'react';

import { useQueueWorkerNotifications } from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';
import { BillingGroupApiModel } from 'models/billingGroup.api';
import { useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';

import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { BillingGroupEntry } from './BillingGroupEntry';
import { BillingGroupModalContainer } from './BillingGroupModalContainer';
import { BillingGroupAction, BillingGroupPageUIModel } from './BillingGroupPageModel.ui';

export const BillingGroupPage = observer(() => {
  const { appState, appModel } = useAppState();
  const { organizationId, teamId } = appState;
  const navigate = useNavigate();

  const { isMultiTeam } = appModel.assistantSubscription;

  const model = useMemo(() => {
    if (!organizationId) return null;

    return new BillingGroupPageUIModel({
      api: new BillingGroupApiModel({
        request: requestService.api,
        organizationId,
      }),
    });
  }, [organizationId]);

  useQueueWorkerNotifications(model?.notificationQueue);

  model?.tableActionsQueue.read(app => {
    switch (app.type) {
      case BillingGroupAction.enum.view: {
        const params = { billingGroupIds: app.params?.billingGroupId || '' };
        const targetRoute = isMultiTeam
          ? ROUTE.toPeople(organizationId, params)
          : ROUTE.toTeamUsers(organizationId, teamId, params);

        navigate(targetRoute);
        break;
      }
      default:
        break;
    }
  });

  if (!model) return null;

  return (
    <>
      <BillingGroupEntry model={model} />
      <BillingGroupModalContainer model={model} />
    </>
  );
});
