import { useEffect } from 'react';

// Used for the elements that need to check if click happened outside
export const useHandleOutsideMouseDown = (
  ref: React.MutableRefObject<HTMLElement | null>,
  onClickOutside?: () => void,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickOutside]);
};
