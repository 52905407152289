import React from 'react';

import { ISuggestionExampleConfig } from '@writercolab/common-utils';
import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import { ExampleBlockHeader } from './generic/ExampleBlockHeader';
import ExampleBlockList from './generic/ExampleBlockList';

import commonStyles from '../styles.module.css';
import styles from './styles.module.css';

interface AgeExampleProps {}

const exampleConfig: ISuggestionExampleConfig[] = [
  {
    id: '1',
    title: 'Example',
    contents: [
      {
        id: '1',
        content: (
          <div className={styles.exampleBlockInline}>
            <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
              elderly
            </Text>
            <Text variant={TextSize.L} bold inline className={styles.exampleBlockArrow}>
              &rarr;
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
              senior
            </Text>
          </div>
        ),
      },
    ],
  },
];

export const AgeExample: React.FC<AgeExampleProps> = () => (
  <div className={commonStyles.tooltipContent}>
    {exampleConfig.map(example => (
      <div className={styles.exampleBlockContainer} key={example.id}>
        <ExampleBlockHeader key={example.id}>{example.title}</ExampleBlockHeader>
        <ExampleBlockList blocks={example.contents} />
      </div>
    ))}
  </div>
);

export default AgeExample;
