import React, { useEffect, useState } from 'react';

import { AnalyticsService } from '@writercolab/analytics';
import { E_INTEGRATION_TYPE } from '@writercolab/types';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { snackbarMessages } from '@web/component-library';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';

import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { getLogger } from '../../../utils/logger';
import { ImporterPopupHeader } from './ImporterPopupHeader';
import { StepInfo } from './StepInfo';
import { ImportSummaryStep } from './Steps/ImportSummaryStep';
import { PreviewImportedDataStep } from './Steps/PreviewImportedDataStep';
import { ACCEPTABLE_COLUMNS } from './Steps/PreviewImportedDataStep/consts';
import { UploadFileStep } from './Steps/UploadFileStep';
import {
  INTERNAL_REFERENCE_PREFIX,
  REFERENCE_COLUMN_NAME,
  baseUploadModelByType,
  importerAnalyticsEventsByType,
} from './consts';
import {
  AutoMatchedColumnsInfoType,
  FailsAndModelsType,
  IWrongTermsModel,
  ImportItemType,
  TableColumnDataType,
} from './types';
import { getFormattedHeaderCells, getValidAnswerVariant } from './utils';

import styles from './styles.module.css';

const LOG = getLogger('ImporterPopup');

interface IImporterPopupProps<S, R> {
  open: boolean;
  onClose: () => void;
  onSubmit: (models: S[]) => Promise<R>;
  type: ImportItemType;
}

export const ImporterPopup = <S, R extends FailsAndModelsType>({
  open,
  onClose,
  onSubmit,
  type,
}: IImporterPopupProps<S, R>) => {
  const { appState } = useAppState();
  const { enqueueSuccessSnackbar } = useCustomSnackbar();
  const importerAnalyticsEvents = importerAnalyticsEventsByType[type];
  const analytics = new AnalyticsService(requestService.api, E_INTEGRATION_TYPE.enum.DEFAULT);

  const [step, setStep] = useState(0);
  const [tableData, setTableData] = useState<string[][]>([]);
  const [lastUploadData, setLastUploadData] = useState<S[]>([]);
  const [lastNonUploadedItems, setLastNonUploadedItems] = useState<IWrongTermsModel<S>[]>([]);
  const [uploadingAmount, setUploadingAmount] = useState(0);
  const [autoMatchedColumnsInfo, setAutoMatchedColumnsInfo] = useState<AutoMatchedColumnsInfoType>({
    matched: 0,
    total: 0,
  });
  const [importFailData, setImportFailData] = useState<R>();

  useEffect(() => {
    if (!open) {
      setStep(0);
    }
  }, [open]);

  if (!open) return null;

  const uploadItems = async (models: S[], wrongRowsModels: IWrongTermsModel<S>[]) => {
    setImportFailData(undefined);
    try {
      const res = await onSubmit(models);

      if (!isEmpty(res.fails) || !isEmpty(wrongRowsModels)) {
        setImportFailData(res);
        setStep(2);
      } else {
        const snackbarMessagesForCategory = snackbarMessages[type];
        enqueueSuccessSnackbar(snackbarMessagesForCategory.bulkImportSuccess(res.models.length));
        analytics.track(importerAnalyticsEvents.importSucceeded, {
          team_id: appState.teamId,
        });
        onClose();
      }
    } catch (e: any) {
      LOG.error('Upload error:', e.message);
      setStep(2);
    } finally {
      setUploadingAmount(0);
    }
  };

  const processAndUpload = async (columnsData: TableColumnDataType[], rowsAmount: number) => {
    setStep(1);
    setUploadingAmount(rowsAmount);

    const columnsWithIndexes = columnsData
      .map((columnInfo, columnIndex) => ({
        index: columnIndex,
        columnInfo,
      }))
      .filter(column => column.columnInfo.acceptableColumn && !column.columnInfo.hidden);

    const tableRowsWithValues = tableData.slice(1);
    const rowsModels = tableRowsWithValues.map((row, index) => {
      const internalReference = `${INTERNAL_REFERENCE_PREFIX}${index + 1}`;
      const missedRequiredFields: string[] = [];
      const rowModel: IWrongTermsModel<S> = {
        ...structuredClone<S>(baseUploadModelByType[type]),
        [REFERENCE_COLUMN_NAME]: internalReference,
      };
      columnsWithIndexes.forEach(column => {
        const matchedColumnParams = column.columnInfo.acceptableColumn;

        if (!row[column.index] && matchedColumnParams?.required) {
          const { apiFieldKey } = matchedColumnParams;
          apiFieldKey && missedRequiredFields.push(apiFieldKey);

          return;
        }

        const fieldPath = matchedColumnParams?.apiFieldKey;

        if (fieldPath) {
          const validCellValue = getValidAnswerVariant(row[column.index], matchedColumnParams);
          let cellValue = validCellValue;

          if (typeof validCellValue === 'undefined') {
            if (matchedColumnParams?.required) {
              const { apiFieldKey } = matchedColumnParams;
              apiFieldKey && missedRequiredFields.push(apiFieldKey);

              return;
            } else {
              cellValue = matchedColumnParams.defaultValue;
            }
          }

          if (cellValue || cellValue === false) {
            if (matchedColumnParams?.convertForApi) {
              const convertedValue = matchedColumnParams.convertForApi(
                cellValue,
                `${internalReference}.${column.index}`,
                {
                  currentColumn: column,
                  columnsWithData: columnsWithIndexes,
                  currentRow: row,
                },
              );

              if (Array.isArray(rowModel[fieldPath]) && !['tags'].includes(fieldPath)) {
                set(rowModel, fieldPath, [...(get(rowModel, fieldPath) || []), convertedValue]);
              } else {
                set(rowModel, fieldPath, convertedValue);
              }
            } else {
              set(rowModel, fieldPath, cellValue);
            }
          }
        }
      });

      if (missedRequiredFields.length) {
        rowModel.missedRequiredFields = missedRequiredFields;
        rowModel.reference = internalReference;
      }

      return rowModel;
    });
    const correctRowsModels = rowsModels.filter(rowModel => !rowModel.missedRequiredFields);
    const wrongRowsModels: IWrongTermsModel<S>[] = rowsModels.filter(rowModel => rowModel.missedRequiredFields);

    setUploadingAmount(correctRowsModels.length);
    setLastUploadData(correctRowsModels);
    setLastNonUploadedItems(wrongRowsModels);
    await uploadItems(correctRowsModels, wrongRowsModels);
  };

  const onRetryUpload = async () => {
    setStep(1);
    setUploadingAmount(lastUploadData.length);
    await uploadItems(lastUploadData as S[], lastNonUploadedItems);
  };

  const onReadComplete = (data: string[][]) => {
    const validData = data.filter(row => row.length > 0);

    setTableData(validData);
    const headerRow = validData[0].filter(columnName => columnName);

    const headerRowFormatted = getFormattedHeaderCells(headerRow);

    const matchedColumns = headerRowFormatted.filter(headerCell =>
      ACCEPTABLE_COLUMNS[type].find(column => column.names.includes(headerCell)),
    );

    setAutoMatchedColumnsInfo({
      matched: matchedColumns.length,
      total: headerRowFormatted.length,
    });
    setStep(1);
    analytics.track(importerAnalyticsEvents.uploadedFile, {
      team_id: appState.teamId,
    });
  };

  const onClickLearnMore = (step: number) =>
    analytics.track(importerAnalyticsEvents.clickedLearnMore, {
      clicked_from: `step ${step}`,
      team_id: appState.teamId,
    });

  const onClickImport = () => analytics.track(importerAnalyticsEvents.clickedImport, { team_id: appState.teamId });

  const renderStepContent = () => {
    switch (step) {
      case 0:
        return <UploadFileStep onReadComplete={onReadComplete} type={type} />;
      case 1:
        return (
          <PreviewImportedDataStep
            tableData={tableData}
            onChangeTable={setTableData}
            onBack={() => setStep(0)}
            onImport={processAndUpload}
            uploadingAmount={uploadingAmount}
            type={type}
            onClickImport={onClickImport}
          />
        );
      case 2:
        return (
          <ImportSummaryStep<S, R>
            tableData={tableData}
            lastNonUploadedItems={lastNonUploadedItems}
            importFailData={importFailData}
            onBack={() => setStep(1)}
            onClose={onClose}
            onRetry={onRetryUpload}
            type={type}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.importPopup}>
      <ImporterPopupHeader step={step} onClose={onClose} type={type} />
      <div className={styles.popupContent}>
        <StepInfo
          currentStep={step}
          greyed={!!uploadingAmount}
          autoMatchedColumnsInfo={autoMatchedColumnsInfo}
          type={type}
          onClickLearnMore={onClickLearnMore}
        />
        {renderStepContent()}
      </div>
    </div>
  );
};
