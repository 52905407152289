import type { RequestServiceInitialize } from '@writercolab/network';
import { createRequestService } from '@writercolab/network';
import { E_CLIENT_ID } from '@writercolab/types';

import config from '../../utils/dynamicConfig';

const { VITE_VERSION } = import.meta.env;
const clientId = E_CLIENT_ID.enum.QordobaFE;

const requestService: RequestServiceInitialize = createRequestService({
  baseUrl: config.API_URL.endsWith('/api') ? config.API_URL.slice(0, -4) : config.API_URL,
  clientId,
  clientVersion: VITE_VERSION!,
  middleware: [
    (url, init, next) =>
      next(url, {
        ...init,
        credentials: 'include',
        headers: new Headers({
          ...(init.headers instanceof Headers ? Object.fromEntries(init.headers) : init.headers),
          'X-Client': clientId,
          'X-Client-Ver': VITE_VERSION!,
        }),
      }),
  ],
});

export default requestService;
