import type { components } from '@writercolab/network';
import type { DropdownOption } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';
import type { TApiRequestSortDirection } from './Common';

export type TOrganizationsDocuments =
  components['schemas']['model_PaginatedResult_organization_dto_DocumentResponseWithUser'];
export type TOrganizationsDocument = components['schemas']['organization_dto_DocumentResponseWithUser'];

export type TOrganizationsTableDocument = TOrganizationsDocument & { id: string };

export type TOrganizationDocumentsExtraArgs = {
  search?: string;
  sortField?: typeof TOrganizationsDocumentsSort.type;
  sortOrder?: typeof TApiRequestSortDirection.type;
  scope?: typeof TOrganizationsDocumentsScope.type;
  offset?: number;
  limit?: number;
};

export const TOrganizationTableAction = new Enum('openDocument');

export const TOrganizationsDocumentsScope = new Enum('favorite', 'mine', 'notOwnedByMe', 'modified', 'all');
export const TOrganizationsDocumentsSort = new Enum(
  'id',
  'title',
  'creationTime',
  'modificationTime',
  'modifiedByMeTime',
  'openedByMeTime',
);

export const TOrganizationsDocumentsFilter = new Enum('scope');

export const OrganizationDocumentAction = new Enum('share', 'download', 'delete');

export const OrganizationDocumentSortOptions: DropdownOption[] = [
  { id: TOrganizationsDocumentsSort.enum.openedByMeTime, name: 'Last opened by me' },
  { id: TOrganizationsDocumentsSort.enum.modificationTime, name: 'Last updated' },
  { id: TOrganizationsDocumentsSort.enum.title, name: 'Doc name' },
];

export const OrganizationDocumentFilterOptions: DropdownOption[] = [
  {
    id: TOrganizationsDocumentsScope.enum.all,
    name: 'Owned by anyone',
  },
  {
    id: TOrganizationsDocumentsScope.enum.mine,
    name: 'Owned by me',
  },
];
