import type React from 'react';

import cx from 'classnames';

import { observer } from 'mobx-react-lite';

import styles from './OutputsTable.module.css';

interface ITableSkeletonProps extends BaseComponentsProps {
  rowNumber: number;
  columnNumber: number;
  gridTemplateColumns?: string;
}

export const TableSkeleton: React.FC<ITableSkeletonProps> = observer(
  ({ className, columnNumber, rowNumber, gridTemplateColumns }) => (
    <div className={cx(styles.placeholderContainer, className)}>
      {Array.from({ length: rowNumber }).map((_, index) => (
        <div
          key={index}
          className={styles.placeholderRow}
          style={{
            gridTemplateColumns,
          }}
        >
          {Array.from({ length: columnNumber }).map((_, index) => (
            <div key={index} className={styles.placeholderCell} />
          ))}
        </div>
      ))}
    </div>
  ),
);
