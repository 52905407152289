import type React from 'react';

import cn from 'classnames';

import { convertToEllipsis, noop } from '@writercolab/common-utils';
import type { ReactiveQueue } from '@writercolab/mobx';
import {
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import type { TBillingGroupWithCount } from '@web/types';

import type { IBodyCellProps, IRowProps } from '../../../organisms/BaseTable/BaseTable';
import type { TBillingModalsManager, TTableActionsQueue } from '../BillingGroupPageModel.ui';
import { BillingGroupAction } from '../BillingGroupPageModel.ui';

import styles from './BillingGroupTable.module.css';

const TABLE_TITLE_MAX_LENGTH = 20;
const SHOW_TITLE_TOOLTIP_DELAY = 1000;

export type TBillingTablesConfig = {
  id: typeof TBillingTablesColumnId.type;
};

export const TBillingTablesColumnId = new Enum('groupName', 'users', 'groupContact', 'actions');

export const BillingTableColumnsConfig: TBillingTablesConfig[] = [
  {
    id: TBillingTablesColumnId.enum.groupName,
  },
  {
    id: TBillingTablesColumnId.enum.users,
  },
  {
    id: TBillingTablesColumnId.enum.groupContact,
  },
  {
    id: TBillingTablesColumnId.enum.actions,
  },
];

const BillingGroupOptions = new Enum('rename', 'editContact', 'delete');
interface IRowGenerator {
  data: TBillingGroupWithCount[];
  modalsManager: TBillingModalsManager;
  tableActionsQueue: ReactiveQueue<TTableActionsQueue>;
}

const TextCell = ({ text }: { text?: string | null }) =>
  text ? (
    <div className={styles.cellWrapperText}>
      <Tooltip
        title={text}
        disabled={!!(text && text.length <= TABLE_TITLE_MAX_LENGTH)}
        placement="top"
        enterDelay={SHOW_TITLE_TOOLTIP_DELAY}
        enterNextDelay={SHOW_TITLE_TOOLTIP_DELAY}
      >
        <span>
          <Text variant={TextSize.M} ellipsisOverflow color={TextColor.BLACK}>
            {convertToEllipsis(text || '', TABLE_TITLE_MAX_LENGTH)}
          </Text>
        </span>
      </Tooltip>
    </div>
  ) : (
    <>-</>
  );

const UserTextCell = ({
  text,
  groupId,
  tableActionsQueue,
}: {
  text?: string | null;
  groupId?: number | null;
  tableActionsQueue: ReactiveQueue<TTableActionsQueue>;
}) =>
  text ? (
    <div
      className={cn(styles.cellWrapperText, styles.cellWrapperTextClickable)}
      onClick={() => {
        tableActionsQueue.enqueue({
          type: BillingGroupAction.enum.view,
          params: { billingGroupId: groupId?.toString() || '' },
        });
      }}
    >
      <Tooltip
        title={text}
        disabled={!!(text && text.length <= TABLE_TITLE_MAX_LENGTH)}
        placement="top"
        enterDelay={SHOW_TITLE_TOOLTIP_DELAY}
        enterNextDelay={SHOW_TITLE_TOOLTIP_DELAY}
      >
        <span>
          <Text variant={TextSize.M} ellipsisOverflow color={TextColor.BLACK}>
            {convertToEllipsis(text || '', TABLE_TITLE_MAX_LENGTH)}
          </Text>
        </span>
      </Tooltip>
    </div>
  ) : (
    <>-</>
  );

const ActionsCell = ({
  modalsManager,
  groupId,
  tableActionsQueue,
}: {
  modalsManager: TBillingModalsManager;
  groupId: number;
  tableActionsQueue: ReactiveQueue<TTableActionsQueue>;
}) => (
  <Dropdown
    triggerClassName={styles.actionTrigger}
    trigger={<Icon name={IconVariant.MORE_HORIZ} />}
    itemsType={ItemsTypes.ACTION}
    testId="billing-groups-menu-cell"
    options={[
      {
        id: BillingGroupOptions.enum.rename,
        name: 'View users',
        icon: <Icon name={IconVariant.TEAM} width={18} height={18} />,
        action: () =>
          tableActionsQueue.enqueue({
            type: BillingGroupAction.enum.view,
            params: { billingGroupId: groupId.toString() },
          }),
      },
      {
        id: BillingGroupOptions.enum.rename,
        name: 'Rename group',
        icon: <Icon name={IconVariant.PENCIL} width={18} height={18} />,
        action: () => modalsManager.showModal(BillingGroupAction.enum.editName, groupId),
      },
      {
        id: BillingGroupOptions.enum.editContact,
        name: 'Edit contact',
        icon: <Icon name={IconVariant.SETTINGS} width={18} height={18} />,
        action: () => modalsManager.showModal(BillingGroupAction.enum.editContact, groupId),
      },
      {
        id: BillingGroupOptions.enum.delete,
        name: 'Delete group',
        icon: <Icon name={IconVariant.TRASH} width={18} height={18} />,
        action: () => modalsManager.showModal(BillingGroupAction.enum.delete, groupId),
      },
    ]}
    onPrimaryOptionClickAction={() => {}}
    placement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

export const generateTableBody = ({ data, modalsManager, tableActionsQueue }: IRowGenerator): IRowProps[] =>
  data?.map(item => {
    const { id, name, userCount, contact } = item;
    const generateTableCell = (cellType: string, component: React.ReactNode): IBodyCellProps => ({
      id: `cell-${cellType}-${id}`,
      component,
    });

    const tableCells = BillingTableColumnsConfig.map(column =>
      TBillingTablesColumnId.match(
        column.id,
        {
          groupName: () => generateTableCell(TBillingTablesColumnId.enum.groupName, <TextCell text={name} />),
          users: () =>
            generateTableCell(
              TBillingTablesColumnId.enum.users,
              <UserTextCell text={userCount.toString()} tableActionsQueue={tableActionsQueue} groupId={id} />,
            ),
          groupContact: () => generateTableCell(TBillingTablesColumnId.enum.groupContact, <TextCell text={contact} />),
          actions: () =>
            id
              ? generateTableCell(
                  TBillingTablesColumnId.enum.actions,
                  <ActionsCell modalsManager={modalsManager} tableActionsQueue={tableActionsQueue} groupId={id} />,
                )
              : null,
        },
        null,
      ),
    ).filter(Boolean) as IBodyCellProps[];

    const row: IRowProps<any> = {
      id: `row-${id}`,
      data: item,
      action: () => noop,
      cells: tableCells,
    };

    return row;
  });
