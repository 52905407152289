import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import { IIdentityProvider, ITeam } from '@writercolab/common-utils';
import { DropdownOption, SkeletonLoader, SkeletonLoaderType, useCustomSnackbar } from '@writercolab/ui-atoms';

import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { useSsoContext } from '../../../context/ssoContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import config from '../../../utils/dynamicConfig';
import IdentityProviderSettings, { ISwitcherTypes } from '../../molecules/IdentityProviderSettings';
import PageTitle from '../../molecules/PageTitle';
import ScimSettings from '../../molecules/ScimSettings';
import SsoSetupManager from '../../organisms/SsoSetupManager';
import Error404Page from '../Error404Page';

import styles from './styles.module.css';

const scimSettingsId = 'email';
const scimSettingsEndpoint = `${config.APP_ROOT}/api/scim/v2`;

export const SsoPage: React.FC = observer(() => {
  usePageTitle('Single sign-on');

  const {
    appState,
    appModel: { permissionsModel, assistantSubscription, teamsModel },
  } = useAppState();
  const {
    currentProvider,
    storedProvider,
    identityProviders,
    isLoading,
    isValidating,
    startEditing,
    setIsModalOpen,
    handleUpdateProvider,
    handleUpdateScimConfig,
    scimConfig,
    initScimConfig,
    deleteScimConfig,
    refreshScimToken,
    scimToken,
    scimConfigEnabled,
  } = useSsoContext();
  const access = {
    isMultiTeam: assistantSubscription.isTeam || assistantSubscription.isEnterprise,
    scimSectionVisible: assistantSubscription.access?.scim && assistantSubscription.isEnterprise,
  };
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [teams, setTeams] = useState<DropdownOption[]>([]);
  const [scimTeams, setScimTeams] = useState<DropdownOption[]>([]);
  const [showScimSettings, setShowScimSettings] = useState<boolean>();
  const isPageUnavailable =
    !isLoading && (!permissionsModel?.isOrganizationAdmin || !assistantSubscription.isSsoAvailable);
  const isNotAllowedSubscription = assistantSubscription.isFree;

  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useCustomSnackbar();

  const handleSwitchers = (value: boolean, type: ISwitcherTypes) => {
    const updatedProvider = { ...currentProvider, [type]: value } as IIdentityProvider;

    if (isEmpty(updatedProvider.defaultTeamIds) && !isEmpty(teams)) {
      const defaultTeamId = parseInt(`${head(teams)?.id}`, 10);

      if (defaultTeamId) {
        updatedProvider.defaultTeamIds.push(defaultTeamId);
      }
    }

    handleUpdateProvider(updatedProvider);
  };

  const filterSelectedTeams = useCallback(
    (sourceTeamIds: number[], teamId: number) => {
      let teamIds: number[] = sourceTeamIds.filter(id => teamsModel.teams?.find(team => team.id === id));

      if (teamIds.includes(teamId)) {
        teamIds = teamIds.filter(id => id !== teamId);
      } else {
        teamIds.push(teamId);
      }

      return teamIds;
    },
    [teamsModel.teams],
  );

  const handleTeamScimTeamChange = useCallback(
    async (selectedTeamId: number) => {
      if (scimConfig) {
        const teamIds = filterSelectedTeams(scimConfig.defaultTeamIds || [], selectedTeamId);
        await handleUpdateScimConfig({ ...scimConfig, defaultTeamIds: teamIds });
      }
    },
    [filterSelectedTeams, handleUpdateScimConfig, scimConfig],
  );

  const handleScimConfigDelete = async () => {
    await deleteScimConfig();

    setShowScimSettings(false);
  };

  const handleScimInit = async () => {
    await initScimConfig();

    setShowScimSettings(true);
  };

  const handleEditScim = async () => {
    await refreshScimToken();

    setShowScimSettings(true);
  };

  const handleRefreshToken = async () => {
    setIsRefreshing(true);
    try {
      await refreshScimToken();
      enqueueSuccessSnackbar('A new bearer token has been generated.');
    } catch (e: any) {
      enqueueErrorSnackbar('Something is wrong.');
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    if (!teamsModel.teams) {
      return;
    }

    if (teamsModel.teams.length > 0 && currentProvider) {
      const teamsOptions: DropdownOption[] = teamsModel.teams.map((team: ITeam) => ({
        name: team.name,
        id: `${team.id}`,
        active: currentProvider?.defaultTeamIds.includes(team.id),
      }));

      setTeams(teamsOptions);
    }

    if (scimConfig) {
      const teamsOptions: DropdownOption[] = teamsModel.teams.map((team: ITeam) => ({
        name: team.name,
        id: `${team.id}`,
        active: !!scimConfig?.defaultTeamIds?.includes(team.id),
      }));

      setScimTeams(teamsOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsModel.teams, scimConfig, currentProvider]);

  if (isPageUnavailable || isNotAllowedSubscription) {
    return <Error404Page />;
  }

  const renderPageContent = () => {
    if (isLoading) {
      return <SkeletonLoader type={SkeletonLoaderType.SINGLE_SIGN_ON} repeat={1} style={{ marginTop: 25 }} />;
    }

    if (showScimSettings) {
      return (
        <ScimSettings
          id={scimSettingsId}
          endpoint={scimSettingsEndpoint}
          token={scimToken}
          refreshToken={handleRefreshToken}
          isLoading={isRefreshing}
          onSubmit={() => setShowScimSettings(false)}
        />
      );
    }

    if (currentProvider) {
      return (
        <IdentityProviderSettings
          orgId={appState.organizationId}
          userProfile={appState.userProfile}
          provider={currentProvider}
          teams={teams}
          showScimSection={access.scimSectionVisible}
          scimTeams={scimTeams}
          isValidating={isValidating}
          showScimSetupButton={!scimConfigEnabled}
          handleScimInit={handleScimInit}
          handleEditScim={handleEditScim}
          handleRemoveScim={handleScimConfigDelete}
          handleEditProvider={() => startEditing(true)}
          handleRemoveProvider={() => setIsModalOpen(true)}
          handleSwitchers={handleSwitchers}
          scimConfig={scimConfig}
          handleTeamScimTeamChange={handleTeamScimTeamChange}
        />
      );
    }

    return (
      <SsoSetupManager
        handleSubmit={(provider: IIdentityProvider) => handleUpdateProvider(provider)}
        provider={storedProvider}
        identityProviders={identityProviders}
        // Only existing configuration will have user data
        isEditingMode={!!storedProvider.creatorUserId}
        isValidating={isValidating}
        handleExit={() => startEditing(false)}
      />
    );
  };

  return (
    <div className={cx(styles.contentWrapper, { [styles.overflow]: isLoading })}>
      <div className={styles.identityProviderWrapper}>
        <PageTitle title="Single sign-on" className={styles.title} />

        <div className={styles.identityProviderSection}>{renderPageContent()}</div>
      </div>
    </div>
  );
});

export default SsoPage;
