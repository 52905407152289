import React from 'react';

import { BUILDER_TEMPLATE_ID, isDateTodays, noop } from '@writercolab/common-utils';
import { sanitize } from '@writercolab/text-utils';
import type { TGetDraftQueryParams, TGetDraftsResponse, TOrgTeamUserActivityParams } from '@writercolab/types';
import {
  Dropdown,
  type DropdownOption,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { FilterOptions, type IFilterOptionsFilter } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import { DEFAULT_TEXT_PLACEHOLDER, type TDraftWithInputsResponse, type TOutputsQueryParams } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { formatDate } from 'date-fns';
import { Copy, FileInput, Trash2 } from 'lucide-react';

import { type IBodyCellProps, type IRowProps } from '../BaseTable/BaseTable';
import { TableFilterHeader } from './FilterHeader';
import { type IOutputsTableProps, OutputsTableActionType } from './OutputsTable';
import { type OutputsTableUiModel } from './OutputsTableModel.ui';

import styles from './OutputsTable.module.css';

export type TOutputsTableConfig = {
  id: typeof TOutputsColumnId.type;
};

export const TOutputsColumnId = new Enum('outputTitle', 'appName', 'createdAt', 'actions');

export const OutputColumnsConfig: TOutputsTableConfig[] = [
  {
    id: TOutputsColumnId.enum.outputTitle,
  },
  {
    id: TOutputsColumnId.enum.appName,
  },
  {
    id: TOutputsColumnId.enum.createdAt,
  },
  {
    id: TOutputsColumnId.enum.actions,
  },
];

export const ColumnHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text variant={TextSize.L} className={styles.columnHeader} color={TextColor.GREY2} medium upperCase>
      {children}
    </Text>
  );
};

export const getOutputsUrlParams = (): TOutputsQueryParams & { outputId?: string } => {
  const searchParams = new URLSearchParams(document.location.search);
  const searchParam = searchParams.get('search');
  const documentIdParam = searchParams.get('documentId');
  const templateIdsParamArr = searchParams.getAll('templateIds');
  const sortFieldParam = searchParams.get('sortField') as TGetDraftQueryParams['sortField'];
  const sortOrderParam = searchParams.get('sortOrder') as TGetDraftQueryParams['sortOrder'];
  const outputIdParam = searchParams.get('outputId');

  const search = searchParam ?? '';
  const documentId = documentIdParam ?? '';
  const templateIds = templateIdsParamArr?.length ? templateIdsParamArr : [];
  const sortField = sortFieldParam ?? undefined;
  const sortOrder = sortOrderParam ?? undefined;
  const outputId = outputIdParam ?? undefined;

  return {
    search,
    documentId,
    templateIds,
    sortField,
    sortOrder,
    outputId,
  };
};

const TitleText = ({ title, body }: { title?: string | null | undefined; body?: string }) => (
  <div className={styles.titleText}>
    <Text
      variant={TextSize.L}
      color={TextColor.GREY}
      dangerouslySetInnerHTML={{
        __html: sanitize(title || body || '', { ALLOWED_TAGS: [] }),
      }}
    />
  </div>
);

const CellText: React.FC<{ children?: string | null }> = ({ children }) => (
  <Text variant={TextSize.L} color={TextColor.GREY}>
    {children}
  </Text>
);

const DateCell: React.FC<{
  date?: string | null;
}> = React.memo(({ date }) => {
  const newDate = date ? new Date(date) : '';
  const formattedDate = isDateTodays(newDate as string)
    ? formatDate(newDate, 'h:mmaaa')
    : formatDate(newDate, 'MMMM d, yyyy, h:mmaaa');

  return (
    <>
      {date ? (
        <Text variant={TextSize.L} color={TextColor.BLACK}>
          {formattedDate}
        </Text>
      ) : (
        <Text color={TextColor.BLACK}>{DEFAULT_TEXT_PLACEHOLDER}</Text>
      )}
    </>
  );
});

DateCell.displayName = 'DateCell';

const renderAppOption = (app: IFilterOptionsFilter) => (
  <Text variant={TextSize.L} className={styles.appOption} title={app.name}>
    {app.name}
  </Text>
);

export const generateTableHeaders = ({
  model,
  filteredApps,
  handleAppsFilterChange,
}: {
  model: OutputsTableUiModel;
  filteredApps: {
    id: string;
    name: string;
    isSelected: boolean;
  }[];
  handleAppsFilterChange: (apps: IFilterOptionsFilter[]) => void;
}) => {
  const { updateTableFilterAppsExtraArgs, loadMoreApplications, isApplicationsFilterLoading, hasMoreApplications } =
    model;

  const handleAppsSearch = (search: string) => {
    updateTableFilterAppsExtraArgs({ search });
  };

  const handleAppsClearSearch = () => {
    updateTableFilterAppsExtraArgs({ search: '' });
  };

  return [
    {
      component: <ColumnHeader>Output</ColumnHeader>,
    },
    {
      component: (
        <FilterOptions
          menuWidth="330px"
          renderTrigger={({ onClick }) => <TableFilterHeader title="App" onClick={onClick} />}
          options={filteredApps}
          onChange={handleAppsFilterChange}
          onLoadMore={loadMoreApplications}
          isLoading={isApplicationsFilterLoading}
          hasNextPage={hasMoreApplications}
          renderOptionNode={renderAppOption}
          onSearch={handleAppsSearch}
          onResetSearch={handleAppsClearSearch}
          isSearchable
          isEnableInfiniteScroll
        />
      ),
    },
    {
      component: <ColumnHeader>Created</ColumnHeader>,
    },
    {
      component: <></>,
    },
  ];
};

const generateMenuOptions = ({
  selectedOutput,
  onUseInNewDoc,
  onCopy,
  onDelete,
  isSeoBlogBuilder,
}: {
  selectedOutput: TGetDraftsResponse | TDraftWithInputsResponse | null;
  onUseInNewDoc: (output: TDraftWithInputsResponse) => void;
  onCopy: (output: TDraftWithInputsResponse) => void;
  onDelete: (output: TDraftWithInputsResponse) => void;
  isSeoBlogBuilder: boolean;
}): DropdownOption[] => {
  return [
    ...(!isSeoBlogBuilder
      ? [
          {
            id: OutputsTableActionType.enum.onUseInNewDoc,
            icon: <FileInput size={16} strokeWidth={1} />,
            name: 'Use in new doc',
            action: () => onUseInNewDoc(selectedOutput as TDraftWithInputsResponse),
          },
        ]
      : []),
    {
      id: OutputsTableActionType.enum.onCopy,
      icon: <Copy size={16} strokeWidth={1} />,
      name: 'Copy to clipboard',
      action: () => onCopy(selectedOutput as TDraftWithInputsResponse),
    },
    {
      id: OutputsTableActionType.enum.onDelete,
      name: 'Delete',
      warning: true,
      icon: <Trash2 size={16} strokeWidth={1} />,
      action: () => onDelete(selectedOutput as TDraftWithInputsResponse),
    },
  ];
};

export const generateTableBody = (
  model: OutputsTableUiModel,
  actions: IOutputsTableProps['actions'],
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>,
  outputId?: string,
) => {
  const { outputs, selectedOutput, setSelected } = model;
  const { onUseInNewDoc, onCopy, onDelete } = actions;

  const recordsCount = outputs.length;
  const recordIndexFn = (id: number) => outputs.findIndex(output => output.id === id);

  return outputs.map(result => {
    const { id, title, body, template, creationTime } = result;
    const isSeoBlogBuilder = template.id === BUILDER_TEMPLATE_ID.SEO_BLOG;

    const posInArray = recordIndexFn(id);
    const isLastRow = recordsCount > 20 && posInArray >= recordsCount - 5;
    const options = generateMenuOptions({ selectedOutput, onUseInNewDoc, onCopy, onDelete, isSeoBlogBuilder });

    const generateTableCell = (
      columnId: TOutputsTableConfig['id'],
      rowId: number,
      component: React.ReactNode,
    ): IBodyCellProps => ({
      id: `cell-${columnId}-${rowId}`,
      component,
    });

    const tableCells = OutputColumnsConfig.map(column =>
      TOutputsColumnId.match(
        column.id,
        {
          outputTitle: () =>
            generateTableCell(TOutputsColumnId.enum.outputTitle, id, <TitleText title={title} body={body} />),
          appName: () => generateTableCell(TOutputsColumnId.enum.appName, id, <CellText>{template.name}</CellText>),
          createdAt: () => generateTableCell(TOutputsColumnId.enum.createdAt, id, <DateCell date={creationTime} />),
          actions: () =>
            options.length > 0
              ? generateTableCell(
                  TOutputsColumnId.enum.actions,
                  id,
                  <Dropdown
                    triggerClassName={styles.rowActions}
                    dropDownContainerClassName={styles.rowActionBottom}
                    dropdownItemClassName={styles.dropdownItem}
                    trigger={<Icon name={IconVariant.MORE_HORIZ} />}
                    itemsType={ItemsTypes.ACTION}
                    options={options}
                    onTriggerClickCallback={() => {
                      analyticsService.track(AnalyticsActivity.myWorkItemMenuClicked, {
                        option: 'outputs',
                      });

                      setSelected(result);
                    }}
                    onPrimaryOptionClickAction={noop}
                    shouldUpdatePlacementOnChange
                    placement={isLastRow ? DropdownPlacement.TOP_LEFT : DropdownPlacement.BOTTOM_LEFT}
                  />,
                )
              : generateTableCell(TOutputsColumnId.enum.actions, id, null),
        },
        null,
      ),
    ).filter(Boolean);

    const row: IRowProps<TGetDraftsResponse> = {
      id,
      data: result,
      cells: tableCells,
      active: Number(outputId) === id,
    };

    return row;
  });
};
