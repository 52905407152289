import { action, makeObservable, observable } from 'mobx';

import type { RequestServiceInitialize, components } from '@writercolab/network';

import { IAddPeopleOption } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { PeopleModel } from '../../../models/people';
import { TAppState } from '../../../state/types';

interface IUIModelInviteTeammatesOptions {
  request: RequestServiceInitialize['api'];
  orgId: number | undefined;
  teamId: TAppState['teamId'];
  teamSizeLimitReached: boolean;
  teamSizeLimit: number;
  onSubmit: (teammatesList: IAddPeopleOption[]) => Promise<void>;
}

export default class UIModelInviteTeammates {
  private readonly $people: PeopleModel;

  public teamSizeLimitReached = false;
  public loading = false;
  public teamSizeLimit = 0;
  public teammatesList: IAddPeopleOption[] = [];

  constructor(private opt: IUIModelInviteTeammatesOptions) {
    this.teamSizeLimit = opt.teamSizeLimit;
    this.teamSizeLimitReached = true;
    this.$people = new PeopleModel({
      request: opt.request,
      orgId: opt.orgId,
    });

    makeObservable(this, {
      loading: observable,
      teamSizeLimit: observable,
      teamSizeLimitReached: observable,
      teammatesList: observable,

      search: action.bound,
      submit: action.bound,
    });
  }

  search(query: string) {
    if (isEmpty(query)) {
      return;
    }

    this.$people.search(query);
  }

  async submit() {
    if (isEmpty(this.teammatesList)) {
      return;
    }

    this.loading = true;
    await this.opt.onSubmit(this.teammatesList);
  }

  updateTeammatesList(teammatesList: IAddPeopleOption[]) {
    this.teammatesList = teammatesList;
  }

  get isTeamSizeLimitReached(): boolean {
    return this.opt.teamSizeLimitReached;
  }

  get isLoading(): boolean {
    return this.$people.loading;
  }

  get isSubmitAvailable(): boolean {
    return (
      !isEmpty(this.peopleList) &&
      !isEmpty(this.teammatesList) &&
      !this.$people.loading &&
      !this.opt.teamSizeLimitReached
    );
  }

  get peopleList(): components['schemas']['com_qordoba_user_dto_UserAndTeamsResponse'][] {
    return this.$people.people;
  }
}
