import { BillingProduct } from '@writercolab/common-utils';
import { BillingLock, BillingLockType } from '@writercolab/ui-organisms';

import { AnalyticsActivity } from 'constants/analytics';
import { useNavigate } from 'react-router';

import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { goToBilling } from '../../../utils/navigationUtils';
import Error404Page from '../Error404Page';

import styles from './styles.module.css';

interface IStyleguideEditorPageProps extends BaseComponentsProps {
  isDisabled?: boolean;
}

export const StyleguideEditorPage = ({ isDisabled }: IStyleguideEditorPageProps) => {
  usePageTitle('Style guide');

  const { appState, appModel } = useAppState();
  const navigate = useNavigate();
  const handleLockActionClick = () => {
    appModel.analyticsService.track(AnalyticsActivity.clickPurchaseProNow, {
      clicked_from: 'styleguide',
    });
    goToBilling(navigate, appState.organizationId!, BillingProduct.PRO);
  };

  if (isDisabled) {
    return <Error404Page />;
  }

  return (
    <div className={styles.container}>
      <BillingLock type={BillingLockType.STYLEGUIDE} onActionCallback={handleLockActionClick} />
    </div>
  );
};

export default StyleguideEditorPage;
