import { computed, makeObservable } from 'mobx';

import {
  type IDocumentShareStatusPayload,
  IUserProfile,
  NotificationQueueItemType,
  type TNotificationQueueItem,
} from '@writercolab/common-utils';
import { PaginatedModel, PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import { type RequestServiceInitialize } from '@writercolab/network';

import { DocumentsTableUiModel } from 'components/organisms/DocumentsTable';

import {
  DEFAULT_PAGE_SIZE,
  type ITeamQueryParams,
  type TDocumentSharingResponse,
  type TPaginatedTeamUsers,
  type TTeamUser,
} from '@web/types';
import { OrganizationDocumentsApi } from 'models/organizationDocuments.api';
import { TeamApiModel } from 'models/team.api';
import { getLogger } from 'utils/logger';
import { IWebAppAnalyticsTrack } from 'constants/analytics';

const LOG = getLogger('DocumentTab');

export interface IDocumentsTabModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number;
  scopeFilterDisabled: boolean;
  access: () => {
    user: IUserProfile | undefined;
    assistantSubscription: AiAssistantSubscriptionModel;
  };
  analyticsService: IWebAppAnalyticsTrack;
}

export class DocumentsTabUiModel {
  readonly documentApi: OrganizationDocumentsApi;
  readonly teamApi: TeamApiModel;
  readonly documentsTableUiModel: DocumentsTableUiModel;
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

  constructor(private readonly opts: IDocumentsTabModelOptions) {
    this.notificationQueue = new ReactiveQueue();

    this.documentApi = new OrganizationDocumentsApi({
      request: opts.request,
      organizationId: opts.organizationId,
      teamId: opts.teamId,
    });

    this.teamApi = new TeamApiModel({
      request: opts.request,
      organizationId: opts.organizationId,
      teamId: opts.teamId,
    });

    this.documentsTableUiModel = new DocumentsTableUiModel({
      request: opts.request,
      organizationId: () => opts.organizationId,
      teamId: () => opts.teamId,
      notificationQueue: this.notificationQueue,
      scopeFilterDisabled: opts.scopeFilterDisabled,
      analyticsService: opts.analyticsService,
    });

    makeObservable(this, {
      documentShareStatus: computed.struct,
      teamMembers: computed.struct,
    });
  }

  private readonly $documentShareStatus = new PromisedModel({
    name: '$documentShareStatus',
    load: async () => {
      const documentId = this.documentsTableUiModel?.selectedDocument?.id;
      const documentOwnerId = this.documentsTableUiModel?.selectedDocument?.createdUser?.id;
      const access = this.opts.access();
      const canShare = access.user?.id === documentOwnerId || access.assistantSubscription?.isFree;

      if (!documentId || !canShare) {
        return null;
      }

      try {
        const data = await this.documentApi.getDocumentShareStatus(documentId);

        return data;
      } catch {
        return null;
      }
    },
  });

  private readonly $teamMembers: PaginatedModel<
    TPaginatedTeamUsers,
    TTeamUser,
    {
      offset: number;
      limit: number;
    },
    ITeamQueryParams
  > = new PaginatedModel({
    name: '$paginatedOutputs',
    argsExtra: { search: '' },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

      if (offset >= obj.totalCount) {
        return this.$teamMembers.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj.result ?? [],
    load: async ({ args, extra }) => {
      const data = await this.teamApi.findTeamMembers({
        ...args,
        ...extra,
      });

      return data;
    },
  });

  get documentShareStatus(): TDocumentSharingResponse | null | undefined {
    return this.$documentShareStatus.value;
  }

  get teamMembers() {
    return this.$teamMembers.value;
  }

  createDocument = async (title: string) => {
    try {
      const data = await this.documentApi.createDocument(this.opts.organizationId, this.opts.teamId, title);

      return data;
    } catch (error) {
      LOG.error(error);

      return undefined;
    }
  };

  getDocumentShareStatus = async (documentId: number): Promise<TDocumentSharingResponse | undefined> => {
    if (!documentId) {
      return undefined;
    }

    try {
      const data = await this.documentApi.getDocumentShareStatus(documentId);

      return data;
    } catch (error) {
      LOG.error(error);

      return undefined;
    }
  };

  changeShareStatus = async (documentId: number, shareStatus: IDocumentShareStatusPayload) => {
    if (!documentId || !shareStatus) {
      return undefined;
    }

    try {
      const data = await this.documentApi.updateDocumentShareStatus(documentId, shareStatus);

      if (!data) {
        return null;
      }

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Sharing settings have been updated',
      });

      return data;
    } catch (error) {
      LOG.error(error);

      return undefined;
    }
  };

  searchTeamMembers = async (args: ITeamQueryParams) => {
    const data = await this.teamApi.findTeamMembers({
      ...args,
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    });

    return data;
  };
}
