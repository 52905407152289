import type { IAnalyticsTrack } from '@writercolab/analytics';
import type { TAnalyticsActivityTypeBuilder } from '@writercolab/types';
import type { TUISidebarModelsAnalyticsParams } from '@writercolab/ui-sidebar-models';
import { UISidebarModelsAnalyticsActivity } from '@writercolab/ui-sidebar-models';
export const AnalyticsActivity = {
  ...UISidebarModelsAnalyticsActivity,
  commandsClickedShowMoreRewriteSuggestions: '[Commands] Clicked Show More Rewrite Suggestions',
  commandsReplacedTextWithRewriteSuggestion: '[Commands] Replaced Text with Rewrite Suggestion',
  commandsCopiedRewriteSuggestion: '[Commands] Copied Rewrite Suggestion',
  commandsNavigatedTheArrows: '[Commands] Navigated the Arrows',
  commandsClickedKeepWriting: '[Commands] Clicked Keep Writing',
  commandsClickedRegenerate: '[Commands] Clicked Regenerate',
  commandsGeneratedFromText: '[Commands] Generated From Text',
  commandsClickedRewrite: '[Commands] Clicked Rewrite',
  commandsEnteredAskKnowledgeGraph: '[Commands] Entered Ask Knowledge Graph',
  commandsGeneratedNew: '[Commands] Generated New',
  commandsEntered: '[Commands] Entered Freeform Command',
  commandsOpened: '[Commands] Opened Commands',

  magicLinksHighlightedText: '[Magic Links] Highlighted Editor Text',
} as const;

export type TActivityParams = TUISidebarModelsAnalyticsParams & {
  [AnalyticsActivity.commandsClickedShowMoreRewriteSuggestions]: {
    integration_type: string;
    scenario: string;
    team_id: number;
    command_name: string;
  };
  [AnalyticsActivity.commandsClickedKeepWriting]: {
    integration_type: string;
    scenario: string;
    command_name: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsNavigatedTheArrows]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsOpened]: {
    team_id: number;
    integration_type: string;
    scenario: string;
    command_name: string;
  };
  [AnalyticsActivity.commandsEntered]: {
    integration_type: string;
    scenario: string;
    command_name: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsGeneratedFromText]: {
    integration_type: string;
    scenario: string;
    command_name: string;
    team_id: number;
  };

  [AnalyticsActivity.commandsEnteredAskKnowledgeGraph]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsClickedRegenerate]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };

  [AnalyticsActivity.commandsClickedRewrite]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsCopiedRewriteSuggestion]: {
    integration_type: string;
    scenario: string;
    team_id: number;
  };
  [AnalyticsActivity.commandsReplacedTextWithRewriteSuggestion]: {
    integration_type: string;
    scenario: string;
    team_id: number;
    command_name: string;
  };
};
export type TWebAnalyticsParams = TAnalyticsActivityTypeBuilder<typeof AnalyticsActivity, TActivityParams>;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type IWebAppAnalyticsTrack<TContext = {}> = IAnalyticsTrack<TWebAnalyticsParams, TContext>;

export type AnalyticEvents = (typeof AnalyticsActivity)[keyof typeof AnalyticsActivity];
