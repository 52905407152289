import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IDeleteRequestExistProps {
  orgName: string;
  loading: boolean;
  handleDeleteRequest: () => void;
  handleResendRequest: () => void;
}

export const DeleteRequestExist: React.FC<IDeleteRequestExistProps> = ({
  orgName,
  loading,
  handleDeleteRequest,
  handleResendRequest,
}) => (
  <div className={styles.deleteOrgContainer}>
    <div className={styles.deleteOrgContainerTitle}>
      <Text variant={TextSize.L} bold>
        Delete {orgName}
      </Text>
    </div>
    <div className={cx(styles.deleteOrgContainerCancelDescription)}>
      <Text variant={TextSize.S}>
        You’ve requested an organization deletion. Check your email inbox for a confirmation email and click to finalize
        deletion.
      </Text>
    </div>
    <div className={cx(styles.deleteOrgContainerCancelButtonContainer)}>
      <Button
        className={styles.resendButton}
        type={ButtonTypes.SECONDARY}
        content="Resend confirmation"
        onClick={handleResendRequest}
        isLoading={loading}
      />
    </div>
    <div className={cx(styles.deleteOrgContainerCancelAction)}>
      <Text variant={TextSize.S} onClick={handleDeleteRequest} className={styles.clickable}>
        Cancel delete request
      </Text>
    </div>
  </div>
);
