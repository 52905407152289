import React from 'react';

import { isDateTodays, noop } from '@writercolab/common-utils';
import type { TGetOrganizationSessionResponse, TOrgTeamUserActivityParams } from '@writercolab/types';
import {
  Dropdown,
  type DropdownOption,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import type { IFilterOptionsFilter, ISort } from '@writercolab/ui-molecules';
import { FilterOptions, SortingHeader } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import {
  DEFAULT_TEXT_PLACEHOLDER,
  SessionSortOptions,
  type TGetTeamSessionsQueryParams,
  TSessionsSort,
} from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { formatDate } from 'date-fns';
import differenceBy from 'lodash/differenceBy';
import { SquareArrowOutUpRight, SquarePen, Trash2 } from 'lucide-react';
import { sortByNameProperty } from 'utils/arrayUtils';

import type { IBodyCellProps, IRowProps } from '../BaseTable/BaseTable';
import { TableFilterHeader } from './FilterHeader';
import { type ISessionsTableProps, SessionsTableActionType } from './SessionsTable';
import { type SessionsTableUiModel } from './SessionsTableModel.ui';

import styles from './SessionsTable.module.css';

export type TSessionsTableConfig = {
  id: typeof TSessionsColumnId.type;
};

export const TSessionsColumnId = new Enum('sessionTitle', 'appName', 'totalMessages', 'updatedAt', 'actions');

export const SessionsColumnsConfig: TSessionsTableConfig[] = [
  {
    id: TSessionsColumnId.enum.sessionTitle,
  },
  {
    id: TSessionsColumnId.enum.appName,
  },
  {
    id: TSessionsColumnId.enum.totalMessages,
  },
  {
    id: TSessionsColumnId.enum.updatedAt,
  },
  {
    id: TSessionsColumnId.enum.actions,
  },
];

export const ColumnHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text variant={TextSize.L} className={styles.columnHeader} color={TextColor.GREY2} medium upperCase>
      {children}
    </Text>
  );
};

export const getSessionsUrlParams = (): TGetTeamSessionsQueryParams => {
  const searchParams = new URLSearchParams(document.location.search);
  const searchParam = searchParams.get('search');
  const applicationIdsParamArr = searchParams.getAll('applicationIds');
  const sortFieldParam = searchParams.get('sortField') as TGetTeamSessionsQueryParams['sortField'];

  const search = searchParam ?? undefined;
  const applicationIds = applicationIdsParamArr?.length ? applicationIdsParamArr : [];
  const sortField = sortFieldParam ?? TSessionsSort.enum.modificationTime;

  return {
    search,
    applicationIds,
    sortField,
  };
};

const TitleText: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Text variant={TextSize.L} color={TextColor.GREY}>
    {children ? children : <i>Untitled session</i>}
  </Text>
);

const CellText: React.FC<{ children?: string | null }> = ({ children }) => (
  <Text variant={TextSize.L} color={TextColor.GREY}>
    {children && children.length > 0 ? children : DEFAULT_TEXT_PLACEHOLDER}
  </Text>
);

const DateCell: React.FC<{
  date?: string | null;
}> = React.memo(({ date }) => {
  const newDate = date ? new Date(date) : '';
  const formattedDate = isDateTodays(newDate as string)
    ? formatDate(newDate, 'h:mmaaa')
    : formatDate(newDate, 'MMMM d, yyyy, h:mmaaa');

  return (
    <>
      {date ? (
        <Text variant={TextSize.L} color={TextColor.BLACK}>
          {formattedDate}
        </Text>
      ) : (
        <Text color={TextColor.BLACK}>{DEFAULT_TEXT_PLACEHOLDER}</Text>
      )}
    </>
  );
});

DateCell.displayName = 'DateCell';

const renderAppOption = (app: IFilterOptionsFilter) => (
  <Text variant={TextSize.L} className={styles.appOption} title={app.name}>
    {app.name}
  </Text>
);

export const getTableHeaders = (
  model: SessionsTableUiModel,
  selectedApplicationIds: string[],
  onFilterChange: (filter: TGetTeamSessionsQueryParams) => void,
  handleSortingChange: (selectedSort: ISort) => void,
  sortField: typeof TSessionsSort.type,
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>,
  prevSelectedApps: React.MutableRefObject<IFilterOptionsFilter[]>,
) => {
  const {
    applications,
    isApplicationsFilterLoading,
    hasMoreApplications,
    loadMoreApplications,
    updateTableFilterAppsExtraArgs,
  } = model;

  const filteredApps = [
    ...applications
      .map(app => ({
        id: app.id,
        name: app.name,
        isSelected: selectedApplicationIds.includes(app.id),
      }))
      .sort((a, b) => {
        if (a.isSelected && !b.isSelected) {
          return -1;
        }

        if (!a.isSelected && b.isSelected) {
          return 1;
        }

        return sortByNameProperty(a, b);
      }),
  ];

  // Used only to provide a selected app to analytics
  const getSelectedAppFromFilter = (selectedApps: IFilterOptionsFilter[]) => {
    const previousApps = prevSelectedApps.current;

    const [addedApp] = differenceBy(selectedApps, previousApps, app => `${app.id}-${app.isSelected}`);
    const [removedApp] = differenceBy(previousApps, selectedApps, app => `${app.id}-${app.isSelected}`);

    return addedApp || removedApp;
  };

  const handleAppsFilterChange = (apps: IFilterOptionsFilter[]) => {
    const selectedApps = apps.filter(({ isSelected }) => isSelected);
    const selectedAppsIds = selectedApps.map(({ id }) => id);
    const selectedApp = getSelectedAppFromFilter(selectedApps);

    prevSelectedApps.current = selectedApps;

    analyticsService.track(AnalyticsActivity.myWorkTabFiltered, {
      origin: 'sessions',
      option: 'app',
      app_id: selectedApp?.id,
      app_name: selectedApp?.name,
    });

    onFilterChange?.({
      applicationIds: selectedApps.length ? selectedAppsIds : [],
    });
  };

  const handleAppsSearch = (search: string) => {
    updateTableFilterAppsExtraArgs({ search });
  };

  const handleAppsClearSearch = () => {
    updateTableFilterAppsExtraArgs({ search: '' });
  };

  return [
    {
      component: <ColumnHeader>Session Name</ColumnHeader>,
    },
    {
      component: (
        <FilterOptions
          menuWidth="330px"
          renderTrigger={({ onClick }) => <TableFilterHeader title="App" onClick={onClick} />}
          options={filteredApps}
          onChange={handleAppsFilterChange}
          onLoadMore={loadMoreApplications}
          isLoading={isApplicationsFilterLoading}
          hasNextPage={hasMoreApplications}
          renderOptionNode={renderAppOption}
          isEnableInfiniteScroll
          onSearch={handleAppsSearch}
          onResetSearch={handleAppsClearSearch}
          isSearchable
        />
      ),
    },
    {
      component: <ColumnHeader>Size</ColumnHeader>,
    },
    {
      component: <ColumnHeader>Last Updated</ColumnHeader>,
    },
    {
      component: (
        <SortingHeader
          isFillOut
          menuClassName={styles.sortingHeaderMenu}
          header={<></>}
          title={
            <Text variant={TextSize.XXS} medium className={styles.sortBy}>
              SORT BY
            </Text>
          }
          headerIcon={<Icon name={IconVariant.SORT} width={18} height={18} />}
          defaultActiveSortId={sortField}
          placement="right"
          options={SessionSortOptions.map(option => ({
            id: option.id,
            name: option.name,
          }))}
          onSelect={handleSortingChange}
        />
      ),
    },
  ];
};

const generateMenuOptions = ({
  sessionData,
  onRenameSession,
  onOpenApp,
  onDelete,
}: {
  sessionData: TGetOrganizationSessionResponse | null;
  onRenameSession: (sessionData: TGetOrganizationSessionResponse) => void;
  onOpenApp: (sessionData: TGetOrganizationSessionResponse) => void;
  onDelete: (sessionData: TGetOrganizationSessionResponse) => void;
}): DropdownOption[] => {
  return [
    {
      id: SessionsTableActionType.enum.onRenameSession,
      icon: <SquarePen size={16} strokeWidth={1} />,
      name: 'Rename session',
      action: () => onRenameSession(sessionData as TGetOrganizationSessionResponse),
    },
    {
      id: SessionsTableActionType.enum.onOpenApp,
      icon: <SquareArrowOutUpRight size={16} strokeWidth={1} />,
      name: 'Open app',
      action: () => onOpenApp(sessionData as TGetOrganizationSessionResponse),
    },
    {
      id: SessionsTableActionType.enum.onDelete,
      name: 'Delete',
      warning: true,
      icon: <Trash2 size={16} strokeWidth={1} />,
      action: () => onDelete(sessionData as TGetOrganizationSessionResponse),
    },
  ];
};

export const generateTableBody = (
  model: SessionsTableUiModel,
  actions: ISessionsTableProps['actions'],
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>,
) => {
  const { sessions } = model;
  const { onRenameSession, onOpenApp, onDelete } = actions;

  const recordsCount = sessions.length;
  const recordIndexFn = (id: string) => sessions.findIndex(sessionData => sessionData.session.id === id);

  return sessions.map(sessionData => {
    const { session, totalMessages } = sessionData;
    const { id, title, updatedAt } = session;

    const posInArray = recordIndexFn(id);
    const isLastRow = recordsCount > 20 && posInArray >= recordsCount - 5;
    const options = generateMenuOptions({ sessionData, onRenameSession, onOpenApp, onDelete });

    const generateTableCell = (
      columnId: TSessionsTableConfig['id'],
      rowId: string,
      component: React.ReactNode,
    ): IBodyCellProps => ({
      id: `cell-${columnId}-${rowId}`,
      component,
    });

    const totalMessagesText = totalMessages ? `${totalMessages} messages` : '0 messages';

    const tableCells = SessionsColumnsConfig.map(column =>
      TSessionsColumnId.match(
        column.id,
        {
          sessionTitle: () =>
            generateTableCell(TSessionsColumnId.enum.sessionTitle, id, <TitleText>{title}</TitleText>),
          appName: () =>
            generateTableCell(TSessionsColumnId.enum.appName, id, <CellText>{session.applicationName}</CellText>),
          totalMessages: () =>
            generateTableCell(TSessionsColumnId.enum.totalMessages, id, <CellText>{totalMessagesText}</CellText>),
          updatedAt: () => generateTableCell(TSessionsColumnId.enum.updatedAt, id, <DateCell date={updatedAt} />),
          actions: () =>
            options.length > 0
              ? generateTableCell(
                  TSessionsColumnId.enum.actions,
                  id,
                  <Dropdown
                    triggerClassName={styles.rowActions}
                    dropDownContainerClassName={styles.rowActionBottom}
                    dropdownItemClassName={styles.dropdownItem}
                    trigger={<Icon name={IconVariant.MORE_HORIZ} />}
                    onTriggerClickCallback={() =>
                      analyticsService.track(AnalyticsActivity.myWorkItemMenuClicked, {
                        option: 'sessions',
                      })
                    }
                    itemsType={ItemsTypes.ACTION}
                    options={options}
                    onPrimaryOptionClickAction={noop}
                    shouldUpdatePlacementOnChange
                    placement={isLastRow ? DropdownPlacement.TOP_LEFT : DropdownPlacement.BOTTOM_LEFT}
                  />,
                )
              : generateTableCell(TSessionsColumnId.enum.actions, id, null),
        },
        null,
      ),
    ).filter(Boolean);

    const row: IRowProps<TGetOrganizationSessionResponse> = {
      id,
      data: sessionData,
      cells: tableCells,
    };

    return row;
  });
};
