import React, { useEffect, useMemo, useState } from 'react';

import { wordPluralize } from '@writercolab/text-utils';
import {
  Button,
  ButtonTypes,
  Checkbox,
  DotLoader,
  Icon,
  IconVariant,
  Modal,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { TabList } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import { TKnowledgeGraphFilterSection } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { IConfluenceSpace } from 'models/confluence.api';

import styles from './SyncKnowledgeGraphModal.module.css';

export const SyncKnowledgeGraphItemType = new Enum('document', 'folder', 'space');

interface ISyncKnowledgeGraphTab {
  id: string;
  label: string;
  content: React.ReactNode;
}

interface ISyncKnowledgeGraphModalProps {
  isLoading: boolean;
  title: string;
  description: React.ReactNode;
  documents: TKnowledgeGraphFilterSection[];
  folders: TKnowledgeGraphFilterSection[];
  spaces: IConfluenceSpace[];
  onAddItems: () => void;
  onClickClose: () => void;
  onClickSubmit: (sections: TKnowledgeGraphFilterSection[]) => void;
}

const getActiveTab = (folders: TKnowledgeGraphFilterSection[], documents: TKnowledgeGraphFilterSection[]) => {
  if (!isEmpty(folders)) {
    return SyncKnowledgeGraphItemType.enum.folder;
  }

  if (!isEmpty(documents)) {
    return SyncKnowledgeGraphItemType.enum.document;
  }

  return SyncKnowledgeGraphItemType.enum.space;
};

export const SyncKnowledgeGraphModal: React.FC<ISyncKnowledgeGraphModalProps> = ({
  isLoading,
  title,
  description,
  documents,
  folders,
  spaces,
  onAddItems,
  onClickClose,
  onClickSubmit,
}) => {
  const [activeTab, setActiveTab] = useState<typeof SyncKnowledgeGraphItemType.type>(getActiveTab(folders, documents));
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<number[]>([]);
  const [selectedSpaces, setSelectedSpaces] = useState<number[]>([]);

  useEffect(() => {
    setSelectedDocuments(prevState => [...prevState, ...documents.map((_, index) => index)]);
    setSelectedFolders(prevState => [...prevState, ...folders.map((_, index) => index)]);
    setSelectedSpaces(prevState => [...prevState, ...spaces.filter(space => space.selected).map(space => space.id)]);
  }, [documents, folders, spaces]);

  const updatedItems = [
    ...documents.filter((_, index) => selectedDocuments.includes(index)),
    ...folders.filter((_, index) => selectedFolders.includes(index)),
    ...spaces
      .filter(space => selectedSpaces.includes(space.id))
      .map(space => ({
        id: String(space.id),
        name: space.name,
        type: SyncKnowledgeGraphItemType.enum.folder,
      })),
  ];

  const handleDocumentClick = (index: number) => {
    if (selectedDocuments.includes(index)) {
      setSelectedDocuments([...selectedDocuments.filter(item => item !== index)]);
    } else {
      setSelectedDocuments([...selectedDocuments, index]);
    }
  };

  const handleFolderClick = (index: number) => {
    if (selectedFolders.includes(index)) {
      setSelectedFolders([...selectedFolders.filter(item => item !== index)]);
    } else {
      setSelectedFolders([...selectedFolders, index]);
    }
  };

  const handleSpaceClick = (id: number) => {
    if (selectedSpaces.includes(id)) {
      setSelectedSpaces([...selectedSpaces.filter(item => item !== id)]);
    } else {
      setSelectedSpaces([...selectedSpaces, id]);
    }
  };

  const tabs = useMemo(() => {
    const tabs: ISyncKnowledgeGraphTab[] = [];

    if (!isEmpty(documents)) {
      tabs.push({
        label: SyncKnowledgeGraphItemType.enum.document,
        id: SyncKnowledgeGraphItemType.enum.document,
        content: (
          <ul className={styles.list}>
            {documents.map((file, index) => (
              <li key={`${file.id}-${index}`}>
                <Checkbox checked={selectedDocuments.includes(index)} onClick={() => handleDocumentClick(index)}>
                  <Icon name={IconVariant.DOCUMENT} width={18} height={18} />
                  <span>{file.name}</span>
                </Checkbox>
              </li>
            ))}
          </ul>
        ),
      });
    }

    if (!isEmpty(folders)) {
      tabs.push({
        label: SyncKnowledgeGraphItemType.enum.folder,
        id: SyncKnowledgeGraphItemType.enum.folder,
        content: (
          <ul className={styles.list}>
            {folders.map((folder, index) => (
              <li key={`${folder.id}-${index}`}>
                <Checkbox checked={selectedFolders.includes(index)} onClick={() => handleFolderClick(index)}>
                  <Icon name={IconVariant.FOLDER} width={18} height={18} />
                  <span>{folder.name}</span>
                </Checkbox>
              </li>
            ))}
          </ul>
        ),
      });
    }

    if (!isEmpty(spaces)) {
      tabs.push({
        label: 'Spaces',
        id: SyncKnowledgeGraphItemType.enum.space,
        content: (
          <ul className={styles.list}>
            {spaces.map(space => (
              <li key={space.id}>
                <Checkbox checked={selectedSpaces.includes(space.id)} onClick={() => handleSpaceClick(space.id)}>
                  <Icon name={IconVariant.FOLDER} width={18} height={18} />
                  <span className={styles.name}>{space.name}</span>
                  {space.totalSize !== undefined && (
                    <span>
                      {space.totalSize} {wordPluralize(space.totalSize, 'page')}
                    </span>
                  )}
                </Checkbox>
              </li>
            ))}
          </ul>
        ),
      });
    }

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, folders, spaces, selectedDocuments, selectedFolders, selectedSpaces]);

  return (
    <Modal className={styles.modal} open title={title} modalTitleClassName={styles.title} handleClose={onClickClose}>
      <div className={styles.content}>
        {(!isEmpty(documents) || !isEmpty(folders)) && (
          <Button
            content="Add items"
            className={styles.addItemsButton}
            type={ButtonTypes.BORDERED}
            icon={<Icon name={IconVariant.PLUS} width={18} height={18} />}
            onClick={onAddItems}
          />
        )}

        <Text className={styles.description} variant={TextSize.M} color={TextColor.GREY}>
          {description}
        </Text>

        {isLoading ? (
          <DotLoader className={styles.loader} />
        ) : (
          <TabList
            className={styles.tabs}
            activeTab={activeTab}
            onChange={tab => setActiveTab(tab as typeof SyncKnowledgeGraphItemType.type)}
            tabs={tabs}
          />
        )}

        <div className={styles.controls}>
          <Button className={styles.submitButton} type={ButtonTypes.GRAY_NU} content="Cancel" onClick={onClickClose} />
          <Button
            isLoading={isLoading}
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            content="Update sync preferences"
            onClick={() => onClickSubmit(updatedItems)}
          />
        </div>
      </div>
    </Modal>
  );
};
