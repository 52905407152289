import React, { useState } from 'react';

import { Button, ButtonTypes, HeadingVariant, Modal } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import { getLogger } from '../../../utils/logger';

import styles from './styles.module.css';

const LOG = getLogger('VerifyPasswordModal');

interface IVerifyPasswordModalProps {
  isOpen: boolean;
  errorMessage?: string;
  onClose: () => void;
  modalStyles?: React.CSSProperties;
  modalBackdropStyles?: React.CSSProperties;
  onVerifyPassword?: (password: string) => Promise<any>;
  description?: React.ReactNode;
}

const initialModalStyles: React.CSSProperties = {
  width: '372px',
  padding: '33px 39px',
};

export const VerifyPasswordModal: React.FC<IVerifyPasswordModalProps> = ({
  isOpen,
  errorMessage,
  onClose,
  onVerifyPassword,
  modalStyles,
  modalBackdropStyles,
  description,
  ...props
}) => {
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onVerifyButtonClick = async () => {
    try {
      setIsLoading(true);
      await onVerifyPassword?.(password);
    } catch (e) {
      LOG.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onVerifyButtonClick();
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title="Verify your password"
      style={{ ...initialModalStyles, ...modalStyles }}
      backdropStyles={modalBackdropStyles}
      headingVariant={HeadingVariant.H3}
      modalTitleClassName={styles.verificationModalTitle}
      {...props}
    >
      <form onSubmit={onSubmitForm}>
        {description && <div className={styles.body}>{description}</div>}
        <InputGroup
          autoFocus={isOpen}
          name="mfa-password"
          value={password}
          inputType={InputTypes.INPUT}
          handleChangeInput={onPasswordChange}
          id="mfa-password"
          label="Password"
          type="password"
          errorText={errorMessage}
        />
        <Button
          className={styles.verifyButton}
          type={ButtonTypes.BLACK}
          onClick={onVerifyButtonClick}
          disabled={isLoading}
          isLoading={isLoading}
          content="Verify"
        />
      </form>
    </Modal>
  );
};
