import { useCallback, useEffect, useMemo } from 'react';

import {
  type IDocumentShareStatus,
  type IDocumentShareStatusPayload,
  type ITeam,
  NotificationQueueItemType,
  TAssetToDelete,
  TUserStatusFilter,
} from '@writercolab/common-utils';
import { DataRetentionPolicyBanner, useQueueWorkerNotifications } from '@writercolab/ui-atoms';
import { ISort } from '@writercolab/ui-molecules';

import { ShareDocument } from 'components/molecules/ShareDocument';
import { DOCUMENTS_TABLE_ID, DocumentsTable, TSharingModals } from 'components/organisms/DocumentsTable';
import { getDocumentsUrlParams } from 'components/organisms/DocumentsTable/utils';

import { LoadingPage, snackbarMessages } from '@web/component-library';
import {
  type IAdminsOptionsList,
  TOrganizationDocumentsExtraArgs,
  TOrganizationTableAction,
  TOrganizationsDocumentsScope,
  TOrganizationsDocumentsSort,
} from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { useDataRetentionBanner } from 'hooks/useDataRetentionBanner';
import useUpdateUrlParams from 'hooks/useUpdateUrlParams';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';
import { mapUserNameOption } from 'utils/teamUtils';

import { MyWorkPageEvents, type MyWorkPageUiModel } from '../../MyWorkPageModel.ui';
import { DocumentsTabUiModel } from './DocumentsTabModel.ui';

import styles from './DocumentsTab.module.css';

type TDocumentsTabProps = {
  model: MyWorkPageUiModel;
};

export const DocumentsTab = observer(({ model }: TDocumentsTabProps) => {
  const { appState, appModel } = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const { shouldShowPolicyBanner, ...bannerProps } = useDataRetentionBanner({ asset: TAssetToDelete.enum.Doc });
  const { organizationId, teamId } = appState;
  const { assistantSubscription } = appModel;
  const { searchParams, updateUrlParams } = useUpdateUrlParams<TOrganizationDocumentsExtraArgs>();
  const sortFieldParam = searchParams.get('sortField');

  const access = useMemo(
    () => ({
      user: appModel.user,
      assistantSubscription: appModel.assistantSubscription,
    }),
    [appModel.user, appModel.assistantSubscription],
  );

  const documentsTabModel = useMemo(() => {
    if (!organizationId || !teamId) {
      return null;
    }

    return new DocumentsTabUiModel({
      request: requestService.api,
      organizationId: Number(organizationId),
      teamId: Number(teamId),
      scopeFilterDisabled: assistantSubscription.isFree,
      access: () => access,
      analyticsService: appModel.analyticsService,
    });
  }, [organizationId, teamId, assistantSubscription.isFree, appModel.analyticsService, access]);

  const tableModel = documentsTabModel?.documentsTableUiModel;

  const handleUpdateUrlParams = useCallback(
    (params: Partial<TOrganizationDocumentsExtraArgs>) => {
      updateUrlParams(params);

      if (tableModel) {
        tableModel?.updateExtraArgs(params);
      }
    },
    [updateUrlParams, tableModel],
  );

  const handleSortingChange = useCallback(
    (selectedSort: ISort) => {
      const { id } = selectedSort;
      const tableContainerRef = document.getElementById(DOCUMENTS_TABLE_ID);
      const analyticOpts = {
        modificationTime: 'last_updated',
        title: 'name',
        openedByMeTime: 'last_opened',
      };

      if (tableModel) {
        tableModel?.updateExtraArgs({
          sortField: id as TOrganizationDocumentsExtraArgs['sortField'],
        });

        appModel.analyticsService.track(AnalyticsActivity.myWorkTabSorted, {
          origin: 'docs',
          option: analyticOpts[id],
        });
      }

      tableContainerRef?.scrollTo({ top: 0 });
    },
    [tableModel, appModel.analyticsService],
  );

  const handleClearFilterIndicator = useCallback(() => {
    if (tableModel) {
      tableModel.onClearFilters();

      handleUpdateUrlParams({
        sortField: undefined,
        scope: undefined,
        search: '',
      });
    }
  }, [tableModel, handleUpdateUrlParams]);

  const handleClearSearchInput = useCallback(() => {
    if (tableModel) {
      handleUpdateUrlParams({ search: '' });
      tableModel.onClearSearch();
    }
  }, [tableModel, handleUpdateUrlParams]);

  const handleNewDocument = useCallback(async () => {
    const newDoc = await documentsTabModel?.createDocument('');

    if (!newDoc) {
      return;
    }

    navigate(ROUTE.toEditorPage(organizationId, teamId, newDoc.id, location.search), {
      state: {
        backTo: ROUTE.toDocs(`${organizationId}`, `${teamId}`),
      },
    });
  }, [navigate, organizationId, teamId, documentsTabModel, location.search]);

  const handleUpdateShareStatus = useCallback(
    (shareStatus: IDocumentShareStatusPayload) => {
      if (tableModel?.selectedDocument) {
        documentsTabModel?.changeShareStatus(tableModel.selectedDocument.id!, shareStatus);
      }
    },
    [documentsTabModel, tableModel?.selectedDocument],
  );

  const handleChangeMembers = async (inputValue?: string) => {
    const data = await documentsTabModel?.searchTeamMembers({
      search: inputValue ?? '',
      userStatus: [TUserStatusFilter.enum.active],
    });

    if (!data) {
      return [];
    }

    return data?.result
      .filter(user => user.user.id !== tableModel?.selectedDocument?.createdUserId)
      .map(({ user }) => {
        const { name, subText } = mapUserNameOption(user.firstName, user.lastName, user.email);

        return {
          id: user.id,
          name,
          subText,
        } as IAdminsOptionsList;
      });
  };

  const handleOwnerFilterChange = useCallback(
    (id: typeof TOrganizationsDocumentsScope.type) => {
      const scopeId = id === TOrganizationsDocumentsScope.enum.all ? undefined : id;

      appModel.analyticsService.track(AnalyticsActivity.myWorkTabFiltered, {
        origin: 'docs',
        option: id === TOrganizationsDocumentsScope.enum.all ? 'owned_by_anyone' : 'owned_by_me',
      });

      updateUrlParams({ scope: scopeId });
      tableModel?.updateExtraArgs({ scope: id });
    },
    [tableModel, updateUrlParams, appModel.analyticsService],
  );

  const handleCopyDocumentLink = useCallback(() => {
    documentsTabModel?.notificationQueue.enqueue({
      type: NotificationQueueItemType.enum.success,
      message: snackbarMessages.docs.copyDocumentLink,
    });
  }, [documentsTabModel?.notificationQueue]);

  useQueueWorkerNotifications(documentsTabModel?.notificationQueue);

  tableModel?.tableActionsQueue.read(event => {
    if (!event.params) return false;

    return TOrganizationTableAction.match(
      event.type,
      {
        [TOrganizationTableAction.enum.openDocument]: (params: {
          organizationId: number;
          teamId: number;
          documentId: number;
        }) => {
          navigate(ROUTE.toEditorPage(params.organizationId, params.teamId, params.documentId), {
            state: {
              backTo: ROUTE.toDocs(params.organizationId, params.teamId),
            },
          });
        },
      },
      {
        organizationId: event.params?.organizationId,
        teamId: event.params?.teamId,
        documentId: event.params?.documentId,
      },
    );
  });

  model.eventsQueue.read(event => {
    return MyWorkPageEvents.match(
      event.type,
      {
        [MyWorkPageEvents.enum.clearFilterIndicator]: () => {
          handleClearFilterIndicator();
        },
        [MyWorkPageEvents.enum.searchValue]: (params: { searchValue: string }) => {
          appModel.analyticsService.track(AnalyticsActivity.myWorkTabSearched, {
            origin: 'docs',
            search_term: params?.searchValue || '',
          });

          handleUpdateUrlParams({
            search: params?.searchValue,
          });
        },
        [MyWorkPageEvents.enum.clearSearchInput]: () => {
          handleClearSearchInput();
        },
        [MyWorkPageEvents.enum.buttonClick]: () => {
          handleNewDocument();
        },
      },
      {
        searchValue: event.params?.searchValue || '',
      },
    );
  });

  const showShareModal = tableModel?.selectedDocument && documentsTabModel?.documentShareStatus;

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.myWorkTabSelected, {
      option: 'docs',
    });
  }, [appModel.analyticsService]);

  useEffect(() => {
    if (tableModel) {
      tableModel?.updateExtraArgs({ ...getDocumentsUrlParams() });
    }
  }, [tableModel, searchParams]);

  useEffect(() => {
    if (model) {
      const urlParamKeys = Array.from(searchParams.keys()).filter(key => key !== 'sortField');
      model.setFilterIndicatorKeys(urlParamKeys);
    }
  }, [model, searchParams, tableModel]);

  if (!tableModel) {
    return <LoadingPage />;
  }

  return (
    <>
      {shouldShowPolicyBanner && (
        <DataRetentionPolicyBanner className={styles.retentionPolicyBanner} {...bannerProps} />
      )}

      <DocumentsTable
        model={tableModel}
        access={access}
        sortField={TOrganizationsDocumentsSort.get(sortFieldParam, TOrganizationsDocumentsSort.enum.openedByMeTime)}
        handleSortingChange={handleSortingChange}
        handleOwnerFilterChange={id => handleOwnerFilterChange(id)}
      />

      {showShareModal && (
        <ShareDocument
          organizationId={organizationId}
          documentId={tableModel.selectedDocument?.id ?? undefined}
          team={tableModel.selectedDocument?.team as unknown as ITeam}
          documentShareStatus={documentsTabModel.documentShareStatus as IDocumentShareStatus}
          onInputChangeMembers={handleChangeMembers}
          onClose={() => tableModel.modalsManager.hideModal(TSharingModals.enum.sharing)}
          isOpen={tableModel.modalsManager.isModalVisible(TSharingModals.enum.sharing)}
          onCopyLink={handleCopyDocumentLink}
          onSubmit={handleUpdateShareStatus}
        />
      )}
    </>
  );
});
