import { action, makeObservable, observable, runInAction } from 'mobx';

import { LocalStorageKey, LocalStorageService } from '@writercolab/common-utils';
import type { RequestServiceInitialize } from '@writercolab/network';

import type { TQuestionnaireOptionBlocksConfig } from '@web/types';
import { QuestionnaireBlock, TQuestionnaireVisibilityState } from '@web/types';
import { SharedOnboardingRoutes } from '@web/utils';
import { QUESTIONNAIRE_OPTION_BLOCKS } from 'constants/Questionnaire';
import { IWebAppAnalyticsTrack } from 'constants/analytics';
import type { AppModel } from 'models/app';
import { appLocalStorage } from 'models/localStorage';
import config from 'utils/dynamicConfig';
import { getLogger } from 'utils/logger';
import { getOnboardingFF } from 'utils/onboardingUtils';

import { isResourceNotFound } from '../../../utils/requestUtils';

const LOG = getLogger('UIOnboardingQuestionnaireModel');

type IUIOnboardingQuestionnaireModel = Pick<AppModel, 'featureFlags'> & {
  request: RequestServiceInitialize['api'];
  analytics: IWebAppAnalyticsTrack;
  organizationId: string;
  organizationName: string;
  onAfterSubmit(opts: { autoGenerateContentUser: boolean; nlgUserQuestionnaireOptionChosen: boolean }): void;
};

/**
 * @deprecated we need to move this up a different model that handles
 * redirects to the onboarding app on the client. The logic here
 * that does this is deprecated.
 */
export class UIOnboardingQuestionnaireModel {
  private readonly request: RequestServiceInitialize['api'];
  private readonly analytics: IWebAppAnalyticsTrack;
  private readonly opts: IUIOnboardingQuestionnaireModel;
  private readonly organizationName: string;
  private organizationId: string;
  optionsConfig = observable.object<TQuestionnaireOptionBlocksConfig>(QUESTIONNAIRE_OPTION_BLOCKS);
  visibilityState: typeof TQuestionnaireVisibilityState.type = TQuestionnaireVisibilityState.enum.hidden;
  loading = false;
  loadingVisibility = true;
  hideSkipButton = false;
  hideGeneralUseCase = false;
  hideWelcomeMessage = false;
  isVisible = false;
  isValid: boolean | null = null;

  constructor(opts: IUIOnboardingQuestionnaireModel) {
    this.request = opts.request;
    this.analytics = opts.analytics;
    this.organizationId = opts.organizationId;
    this.organizationName = opts.organizationName;
    this.opts = opts;

    makeObservable(this, {
      loading: observable,
      isValid: observable,
      loadingVisibility: observable,
      hideSkipButton: observable,
      hideGeneralUseCase: observable,
      hideWelcomeMessage: observable,
      optionsConfig: observable,
      isVisible: observable,
      visibilityState: observable,

      checkVisibility: action.bound,
    });
  }

  hide() {
    runInAction(() => {
      this.visibilityState = TQuestionnaireVisibilityState.enum.hidden;
      this.isVisible = false;
      this.loading = false;
      this.isVisible = false;
      this.loadingVisibility = false;
      LocalStorageService.setItem(LocalStorageKey.onboardingState, TQuestionnaireVisibilityState.enum.hidden);
    });
  }

  private async getQuestionnaire(orgId: string, v2?: boolean): Promise<boolean> {
    if (v2) {
      return this.getLegacyQuestionnaire(orgId);
    }

    const { data: questionnaire } = await this.request.get('/api/user/v3/organization/{organizationId}/questionnaire', {
      params: { path: { organizationId: Number(orgId) } },
    });

    return questionnaire?.some(q => q.product === 'WriterApp');
  }

  private async getLegacyQuestionnaire(orgId: string): Promise<boolean> {
    const { data: questionnaire } = await this.request.get('/api/user/v2/organization/{organizationId}/questionnaire', {
      params: { path: { organizationId: Number(orgId) } },
    });

    return Boolean(questionnaire);
  }

  /**
   * Get the new onboarding step if the user has not completed the onboarding
   * and if the legacy questionnaire has been disabled. This should NOT
   * be overwritten by a child class.
   */
  protected getNewOnboardingStep(): string | null {
    if (config.SINGLE_TENANCY_CONFIG?.disableQuestionnaire) {
      return null;
    }

    const flag = getOnboardingFF(this.opts.featureFlags);

    if (
      flag.disableLegacyQuestionnaire &&
      appLocalStorage.onboardingStep.get() &&
      appLocalStorage.onboardingStep.get() !== ''
    ) {
      return appLocalStorage.onboardingStep.get();
    }

    return null;
  }

  protected redirectToStep(step: string) {
    window.location.replace(step);
  }

  public redirectToOnboarding() {
    const flag = getOnboardingFF(this.opts.featureFlags);

    if (flag.disableLegacyQuestionnaire) {
      try {
        window.location.replace(SharedOnboardingRoutes.OnboardingWelcome);
      } catch (err) {
        console.error('Failed to redirect to onboarding', err);
      }
    }
  }

  protected checkOnboardingStatus() {
    // ST config has the highest priority. Always disable the questionnaire if
    // the config is set to do so
    if (config.SINGLE_TENANCY_CONFIG?.disableQuestionnaire) {
      return;
    }

    const flag = getOnboardingFF(this.opts.featureFlags);
    const step = this.getNewOnboardingStep();

    // if the model is not loading, and the state is not hidden, AND the flag is set tp disable the questionnaire
    // then go to the new onboarding
    if (!this.loadingVisibility && this.visibilityState !== 'hidden' && flag.disableLegacyQuestionnaire) {
      // TODO: remove once onboarding is done
      appLocalStorage.onboardingStep.set(SharedOnboardingRoutes.OnboardingWelcome);
      appLocalStorage.onboardingOrgId.set(String(this.organizationId));
      appLocalStorage.onboardingOgName.set(this.organizationName);

      if (this.organizationId) {
        appLocalStorage.skipOnboardingOrgCreation.set(true);
      }

      window.location.replace(SharedOnboardingRoutes.OnboardingWelcome);
      // the questionnaire is disable but the user has not completed the onboarding within the same browser
      // window so we'll redirect the user back to the correct spot.
    } else if (step) {
      this.redirectToStep(step);
    }
  }

  async checkVisibility() {
    this.checkOnboardingStatus();

    const orgId = this.organizationId;
    let generalVisibilityState: typeof TQuestionnaireVisibilityState.type;
    const flag = getOnboardingFF(this.opts.featureFlags);

    if (flag.disableLegacyQuestionnaire) {
      generalVisibilityState = TQuestionnaireVisibilityState.enum.hidden;
    } else {
      const storedVisibilityState = LocalStorageService.getItem<typeof TQuestionnaireVisibilityState.type>(
        LocalStorageKey.onboardingState,
        TQuestionnaireVisibilityState.enum.preload,
      );

      if (storedVisibilityState === TQuestionnaireVisibilityState.enum.hidden) {
        this.visibilityState = TQuestionnaireVisibilityState.enum.hidden;
        this.loadingVisibility = false;
        this.isVisible = false;
        this.hideSkipButton = false;
        this.hideGeneralUseCase = false;
        this.hideWelcomeMessage = false;

        return this.visibilityState;
      }

      generalVisibilityState = TQuestionnaireVisibilityState.enum.preload;

      if (
        storedVisibilityState === TQuestionnaireVisibilityState.enum.preload ||
        storedVisibilityState === TQuestionnaireVisibilityState.enum.full
      ) {
        try {
          if (await this.getQuestionnaire(orgId, flag.useV3Questionnaire === false)) {
            try {
              const userSettings = await this.request.get('/api/user/v2/settings', {});

              generalVisibilityState = userSettings?.data?.department
                ? TQuestionnaireVisibilityState.enum.hidden
                : TQuestionnaireVisibilityState.enum.partial;
            } catch (err) {
              if (!isResourceNotFound(err)) {
                LOG.error('Failed to get user department', err);
              }

              generalVisibilityState = TQuestionnaireVisibilityState.enum.full;
            }
          } else {
            generalVisibilityState = TQuestionnaireVisibilityState.enum.full;
          }
        } catch (err) {
          if (!isResourceNotFound(err)) {
            LOG.error('Failed to get user department', err);
          }

          generalVisibilityState = TQuestionnaireVisibilityState.enum.full;
        }
      }
    }

    LocalStorageService.setItem(LocalStorageKey.onboardingState, generalVisibilityState);

    runInAction(() => {
      this.visibilityState = generalVisibilityState;

      this.isVisible =
        this.visibilityState === TQuestionnaireVisibilityState.enum.full ||
        this.visibilityState === TQuestionnaireVisibilityState.enum.partial;

      this.hideGeneralUseCase = this.visibilityState === TQuestionnaireVisibilityState.enum.partial;
      this.hideSkipButton = this.visibilityState === TQuestionnaireVisibilityState.enum.full;
      this.hideWelcomeMessage = this.visibilityState === TQuestionnaireVisibilityState.enum.partial;
      this.loadingVisibility = false;

      if (this.hideGeneralUseCase) {
        this.optionsConfig = {
          [QuestionnaireBlock.DEPARTMENT]: QUESTIONNAIRE_OPTION_BLOCKS[QuestionnaireBlock.DEPARTMENT],
        };
      }
    });

    return this.visibilityState;
  }

  get canSkip() {
    return !this.hideSkipButton;
  }

  get canSetGeneralUseCase() {
    return !this.hideGeneralUseCase;
  }

  get showWelcomeMessage() {
    return !this.hideWelcomeMessage;
  }

  get options() {
    return this.optionsConfig;
  }

  get valid() {
    return this.isValid;
  }

  get visible() {
    return (
      this.visibilityState === TQuestionnaireVisibilityState.enum.full ||
      this.visibilityState === TQuestionnaireVisibilityState.enum.partial
    );
  }
}
