import { type TNotificationQueueItem, TOrgTeamUserActivityParams } from '@writercolab/common-utils';
import { ReactiveQueue } from '@writercolab/mobx';
import { type RequestServiceInitialize } from '@writercolab/network';

import { EditOutputPanelUiModel } from 'components/organisms/EditOutputPanel/EditOutputPanelModel.ui';
import { OutputsTableUiModel } from 'components/organisms/OutputsTable';

import { IWebAppAnalyticsTrack } from 'constants/analytics';
import { appLocalStorage } from 'models/localStorage';

export interface IOutputsTabModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: () => number;
  isVoiceEnabled: () => boolean;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
}

export class OutputsTabUiModel {
  readonly outputsTableModel: OutputsTableUiModel;
  readonly editOutputPanelModel: EditOutputPanelUiModel;
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

  constructor(private readonly opts: IOutputsTabModelOptions) {
    this.outputsTableModel = new OutputsTableUiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: () => this.opts.teamId(),
      isVoiceEnabled: () => this.opts.isVoiceEnabled(),
      analyticsService: this.opts.analyticsService,
      notificationQueue: this.notificationQueue,
    });

    this.editOutputPanelModel = new EditOutputPanelUiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: () => this.opts.teamId(),
      isVoiceEnabled: () => this.opts.isVoiceEnabled(),
      storage: appLocalStorage,
      notificationQueue: this.notificationQueue,
    });
  }
}
