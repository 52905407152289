import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextSize, Tooltip, TooltipAlignment } from '@writercolab/ui-atoms';

import styles from './InputFieldLabel.module.css';

export const InputFieldLabel: React.FC<{
  className?: string;
  label: string;
  description?: string;
  required?: boolean;
  textSize?: TextSize;
}> = ({ className, label, description, textSize = TextSize.M, required = false }) => (
  <div className={cx(className, styles.container)}>
    <Text variant={textSize} medium className={styles.label}>
      {label} {required && <span>*</span>}
    </Text>
    {description && (
      <Tooltip title={description} placement="top" tooltipWidth={150} align={TooltipAlignment.LEFT}>
        <div>
          <Icon name={IconVariant.INFO_OUTLINED} width={18} height={18} />
        </div>
      </Tooltip>
    )}
  </div>
);
