import { computed, makeObservable } from 'mobx';

import type { RequestQueryParams } from '@writercolab/common-utils';
import { FeatureFlags } from '@writercolab/feature-flags';
import { PaginatedModel, PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import type { AiAssistantSubscriptionModel, AiStudioAccountModel } from '@writercolab/models';
import { EApplicationType } from '@writercolab/types';
import type { TNotificationQueueItem, TOrgTeamUserActivityParams, TPaginatedConsoleArgs } from '@writercolab/types';
import { ChatModes, FeaturedChatAppModel } from '@writercolab/ui-chat-apps';

import type { TBriefApplication, TBriefApplicationsPaginated, TFeatureFlags, TWidgetConfig } from '@web/types';
import { ASK_WRITER_APP_ID, TWidgetEditAppearance, TWidgetType } from '@web/types';
import { INSUFFICIENT_BALANCE_MESSAGE, USAGE_LIMITS_EXCEEDED_MESSAGE } from 'constants/Console';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import debounce from 'lodash/debounce';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';
import requestService from 'services/request/requestService';

import { ChatApplicationModel } from './ChatApplicationModel';

export const APPS_PAGE_SIZE = 100;

interface IChatAppHomeWidgetModelParams {
  featureFlags: () => FeatureFlags<TFeatureFlags>;
  assistantSubscription: () => AiAssistantSubscriptionModel;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  aiStudioBalance: () => AiStudioAccountModel['balance'];
  organizationId: () => number;
  teamId: () => number;
  config: () => TWidgetConfig<typeof TWidgetType.enum.featuredChatApp>;
}

export class ChatAppHomeWidgetModelUi extends AbstractHomeWidgetModel<typeof TWidgetType.enum.featuredChatApp> {
  editAppearance = TWidgetEditAppearance.enum.edit;

  private readonly $apps: PromisedModel<TBriefApplicationsPaginated | undefined>;
  private readonly $appsPaginated: PaginatedModel<
    TBriefApplicationsPaginated | undefined,
    TBriefApplication,
    TPaginatedConsoleArgs,
    RequestQueryParams
  >;

  constructor(private params: IChatAppHomeWidgetModelParams) {
    super(TWidgetType.enum.featuredChatApp, () => params.config(), params.analyticsService);

    this.$apps = new PromisedModel({
      name: '$appsTotalCount',
      load: async () => {
        const { organizationId, teamId } = this;

        if (!organizationId || !teamId) {
          return undefined;
        }

        const { data } = await requestService.api.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed',
          {
            params: {
              path: {
                organizationId,
                teamId,
              },
              query: {
                appType: EApplicationType.enum.chat,
              },
            },
          },
        );

        return data;
      },
    });

    this.$appsPaginated = new PaginatedModel<
      TBriefApplicationsPaginated | undefined,
      TBriefApplication,
      TPaginatedConsoleArgs,
      RequestQueryParams
    >({
      argsExtra: {
        search: undefined,
      },
      argsDefault: {
        offset: 0,
        limit: APPS_PAGE_SIZE,
      },
      extractMeta: o => {
        if (!o) {
          return this.$appsPaginated.args;
        }

        const offset = (o.pagination.offset ?? 0) + (o.result?.length ?? 0);

        if (offset >= o.totalCount) {
          return this.$appsPaginated.args;
        }

        return {
          offset,
          limit: APPS_PAGE_SIZE,
        };
      },
      extract: o => o?.result ?? [],
      load: async ({ args, extra }) => {
        const { organizationId, teamId } = this;

        if (!organizationId || !teamId) {
          return undefined;
        }

        const { data } = await requestService.api.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed',
          {
            params: {
              path: {
                organizationId,
                teamId,
              },
              query: {
                ...args,
                search: extra.search,
                sortField: 'name',
                sortOrder: 'asc',
                appType: EApplicationType.enum.chat,
              },
            },
          },
        );

        return data;
      },
    });

    makeObservable(this, {
      title: computed,

      organizationId: computed,
      teamId: computed,

      selectedAppId: computed,
      showPromptLibraryButton: computed,
      showKnowledgeGraphModeButton: computed,
      selectedApplication: computed,
      selectedApplicationName: computed,

      chatApplicationModel: computed,
      featuredChatAppModel: computed,

      isSubmitDisabled: computed,
      disabledSubmitTooltipText: computed,

      isAppsPaginatedLoading: computed,
      isAppsPaginatedHasNext: computed,
      isOnlyOneApp: computed,
      isNoAppsAvailable: computed,
      isWidgetHiddenForUser: computed,
      appsPaginated: computed.struct,
      appsPaginatedSearch: computed,
      availableAppIds: computed,
    });
  }

  get title() {
    return 'Featured Chat App';
  }

  get teamId() {
    return this.params.teamId();
  }

  get organizationId() {
    return this.params.organizationId();
  }

  get selectedAppId() {
    return this.currentConfig.data.appId;

    // TODO: implement logic to fallback to the first available app if selected app is missing
    // CORE-820, if selected app is missing, get 1st available
    // return this.availableAppIds.some(appId => appId === selectedId) ? selectedId : this.availableAppIds[0];
  }

  get selectedApplication() {
    return this.chatApplicationModel?.application;
  }

  get selectedApplicationName() {
    if (this.chatApplicationModel?.isApplicationLoading) {
      return '';
    }

    return this.selectedApplication?.name ?? '';
  }

  get showPromptLibraryButton() {
    return this.chatApplicationModel?.allowViewPrompts;
  }

  get showKnowledgeGraphModeButton() {
    return this.params.assistantSubscription().access?.knowledgeGraph;
  }

  get isAppsPaginatedLoading() {
    return this.$appsPaginated.status === 'pending';
  }

  get isAppsPaginatedHasNext() {
    return this.$appsPaginated.hasNext;
  }

  get appsPaginatedSearch() {
    return this.$appsPaginated.extra.search;
  }

  get configForSave() {
    if (this.isAdminEdit) {
      return this.currentConfig;
    }

    return {
      ...this.currentConfig,
      data: {
        appId: this.selectedAppId,
      },
    };
  }

  get isOnlyOneApp() {
    return this.availableAppIds.length === 1 && this.selectedApplication;
  }

  get isNoAppsAvailable() {
    return this.availableAppIds.length === 0;
  }

  get isWidgetHiddenForUser() {
    // Implement widget locked state in admin edit mode, once design is ready
    // https://writerai.atlassian.net/browse/CORE-567
    return this.params.assistantSubscription().isFree || (!this.isAdminEdit && this.isNoAppsAvailable);
  }

  get isSubmitDisabled() {
    if (!this.chatApplicationTemplateUIModel) {
      return true;
    }

    if (this.selectedAppId !== ASK_WRITER_APP_ID && (this.usageLimitedExceeded || this.insufficientBalance)) {
      return true;
    }

    return this.chatApplicationTemplateUIModel.chatFilesManager.$uploadQueue.length > 0;
  }

  get usageLimitedExceeded() {
    return this.params.aiStudioBalance()?.monthlyBudgetReached || this.params.aiStudioBalance()?.usageLimitReached;
  }

  get insufficientBalance() {
    return this.params.aiStudioBalance()?.insufficientBalance;
  }

  get disabledSubmitTooltipText() {
    const usageLimitExceeded = this.usageLimitedExceeded;
    const insufficientBalance = this.insufficientBalance;

    if (this.selectedAppId !== ASK_WRITER_APP_ID) {
      if (usageLimitExceeded) {
        return USAGE_LIMITS_EXCEEDED_MESSAGE;
      } else if (insufficientBalance) {
        return INSUFFICIENT_BALANCE_MESSAGE;
      } else {
        return '';
      }
    }

    return undefined;
  }

  get availableAppIds() {
    const publicAppsAvailable = this.params.featureFlags().get('publicAppsAvailable', false);
    const apps = this.$apps.value?.result ?? [];
    const assistantSubscription = this.params.assistantSubscription();

    const appIds = apps.map(app => app.id).filter(id => id !== ASK_WRITER_APP_ID);

    if (publicAppsAvailable && assistantSubscription.access?.askWriter) {
      return [ASK_WRITER_APP_ID, ...appIds];
    }

    return appIds;
  }

  get chatApplicationModel() {
    const { organizationId, teamId, selectedAppId } = this;
    const assistantSubscription = this.params.assistantSubscription();

    if (!organizationId || !teamId || !selectedAppId) {
      return undefined;
    }

    const notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

    return new ChatApplicationModel({
      applicationId: () => selectedAppId,
      requestService: requestService.api,
      analyticsService: this.analyticsService,
      aiStudioBalance: this.params.aiStudioBalance(),
      assistantSubscriptionModel: assistantSubscription,
      notificationQueue,
      teamId,
      organizationId,
    });
  }

  get featuredChatAppModel() {
    if (!this.chatApplicationModel?.templateModel) {
      return undefined;
    }

    return new FeaturedChatAppModel({
      chatTemplateUIModel: this.chatApplicationModel.templateModel,
    });
  }

  get chatApplicationTemplateUIModel() {
    return this.chatApplicationModel?.templateModel?.applicationUIModel;
  }

  get chatWidgetInputModel() {
    return this.chatApplicationTemplateUIModel?.chatInputNeueUiModel;
  }

  createKnowledgeGraphSession = async () => {
    const session = await this.chatApplicationModel?.sessionsApiModel.createSession(ChatModes.enum['knowledge-graph']);

    if (!session) {
      return;
    }

    const inputText = this.chatWidgetInputModel?.editor?.getText();
    const inputState = this.chatWidgetInputModel?.parseValue(inputText ?? '');

    return {
      session,
      inputState,
    };
  };

  insertPrompt = (prompt: string) => {
    // Insert prompt into the chat input and focus on it
    this.chatWidgetInputModel?.updateContent(prompt, true);
  };

  loadMoreAppsPaginated = async () => {
    await this.$appsPaginated.next();
  };

  searchAppsPaginated = debounce((search: string, skipAnalytic?: boolean) => {
    this.$appsPaginated.setExtra({
      search,
    });

    !skipAnalytic &&
      this.analyticsService.track(AnalyticsActivity.featuredChatAppSearched, {
        search,
        edit_flow: this.isAdminEdit ? 'team admin' : 'user',
      });
  }, 500);

  get appsPaginated() {
    if (!this.$appsPaginated.value) {
      return [];
    }

    const assistantSubscription = this.params.assistantSubscription();
    const apps = this.$appsPaginated.value
      // Filter out Ask Writer app from the list
      // we will add it at the beginning of the list if the user has access to it
      .filter(app => app.id !== ASK_WRITER_APP_ID)
      .map(app => ({
        id: app.id,
        name: app.name,
        isSelected: app.id === this.selectedAppId,
      }));

    const publicAppsAvailable = this.params.featureFlags().get('publicAppsAvailable', false);

    const showAskWriter =
      publicAppsAvailable &&
      assistantSubscription.access?.askWriter &&
      this.$appsPaginated.value.some(app => app.id === ASK_WRITER_APP_ID);

    if (showAskWriter) {
      const askWriterApp = {
        id: ASK_WRITER_APP_ID,
        name: 'Ask Writer',
        isSelected: ASK_WRITER_APP_ID === this.selectedAppId,
      };

      // Ask Writer app should be the first in the list if the user has access to it,
      // and app is present in the original response
      return [askWriterApp, ...apps];
    }

    return apps;
  }
}
