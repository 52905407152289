import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Heading, HeadingVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { IExistingOrg } from 'components/pages/AutoJoin/AutoJoin';

import styles from './styles.module.css';

interface IOrganizationJoinRequestedProps {
  existingOrg: IExistingOrg;
  onRequestJoinOrg: () => void;
  isJoining: boolean;
  hideJoinOrg?: boolean;
  hideCreateOrg?: boolean;
  isCreatingOrg?: boolean;
  showDirectJoinButton?: boolean;
  onCreateMyOrg: () => void;
}

export const OrganizationJoinRequested: React.FC<IOrganizationJoinRequestedProps> = ({
  existingOrg,
  onRequestJoinOrg,
  isJoining,
  onCreateMyOrg,
  showDirectJoinButton,
  hideJoinOrg = false,
  hideCreateOrg = false,
  isCreatingOrg = false,
}) => (
  <div className={styles.bannerContainer}>
    <Heading className={cx(styles.heading, styles.centered)} variant={HeadingVariant.H2} medium>
      {existingOrg.organizationName} <br /> is already on Writer!
    </Heading>
    <div className={cx(styles.body, styles.centered)}>
      <Text variant={TextSize.XL} className={styles.bodyText}>
        Join now to maximize creativity and productivity with Writer, the generative AI platform for enterprises
      </Text>
      <div className={styles.buttonGroup}>
        {!hideJoinOrg && (
          <Button
            type={ButtonTypes.BLACK}
            content={
              <Text variant={TextSize.L} color={TextColor.WHITE} bold>
                {showDirectJoinButton ? 'Join your teammates' : 'Request to join your teammates'}
              </Text>
            }
            onClick={onRequestJoinOrg}
            isLoading={isJoining}
          />
        )}
        {!hideCreateOrg && (
          <Button
            onClick={onCreateMyOrg}
            type={ButtonTypes.PRIMARY}
            content={
              <Text variant={TextSize.L} color={TextColor.WHITE} bold>
                Create my own org
              </Text>
            }
            isLoading={isCreatingOrg}
          />
        )}
      </div>
    </div>
  </div>
);

export default OrganizationJoinRequested;
