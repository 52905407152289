import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import type { IUserSalesProfile, TSubscription, TSubscriptionLimit } from '@writercolab/common-utils';
import { LocalStorageKey, LocalStorageService, isChromeBrowser } from '@writercolab/common-utils';
import type { TSubscriptionLimitType } from '@writercolab/models';

import type { UserChromeExtWindowState } from '../components/molecules/ChromeExtWindow';

import { observer } from 'mobx-react-lite';
import { initDatadog, setDatadogUser } from 'utils/datadogUtils';

import { useAnalytics } from '../hooks/useAnalytics';
import useEducationalModule from '../hooks/useEducationalModule';
import useExtensionChrome from '../hooks/useExtensionChrome';
import useQuestionnaire from '../hooks/useQuestionnaire';
import useTutorial from '../hooks/useTutorial';
import { AppModel } from '../models/app';
import { APP_ENV, PAGE_TITLE_PREFIX } from '../services/config/constants';
import type { Actions, TAppState } from './types';
import { TActionType } from './types';

const { VITE_ENV } = import.meta.env;

const initialAppState: TAppState = {
  isTutorialDocBeaconVisible: false,
  isTutorialTipVisible: false,
  isChromeExtensionWindowVisible: false,
  isChromeAnnouncementVisible: true,
  organizationId: 0, // TODO: remove
  organization: null,
  teamId: 0, // TODO: remove
  team: null,
  isInvalidTeam: false,
  documentId: 0,
  isAppLoading: true,
  isTutorialVisible: false,
  isInvalidOrg: false,
  isInvalidSubscription: false,
  pendingAuthentication: false,
  userProfile: undefined,
  userSalesProfile: {} as IUserSalesProfile,
  organizations: undefined,
  isEducationalTermsWindowVisible: true,
  isEducationalSnippetsWindowVisible: true,
  currentRoute: '',
  site: {
    title: '',
  },
  isChromeExtInstalled: false,
  isNotificationBarVisible: false,
};

const reducer = (state: TAppState = initialAppState, action: Actions): TAppState => {
  let newState: TAppState;

  switch (action.type) {
    case TActionType.SetPageTitle:
      document.title = PAGE_TITLE_PREFIX + action.payload;
      newState = { ...state, site: { title: action.payload } };
      break;

    case TActionType.SetAppLoading:
      newState = { ...state, isAppLoading: action.payload };
      break;

    case TActionType.SetChromeWindowState:
      newState = {
        ...state,
        isChromeExtensionWindowVisible: action.payload,
      };
      break;

    case TActionType.SetIsChromeExtensionInstalled:
      newState = { ...state, isChromeExtInstalled: action.payload };
      break;

    case TActionType.SetChromeAnnouncementState:
      newState = {
        ...state,
        isChromeAnnouncementVisible: action.payload,
      };
      break;

    case TActionType.OrganizationId: {
      newState = { ...state, organizationId: action.payload };
      break;
    }

    case TActionType.SetIsInvalidOrg: {
      newState = { ...state, isInvalidOrg: action.payload };
      break;
    }

    case TActionType.Organization:
      newState = { ...state, organization: action.payload };
      break;

    case TActionType.SetUnconfirmedOrganizations:
      newState = { ...state, unconfirmedOrganizations: action.payload };
      break;

    case TActionType.SetOrganizations:
      newState = { ...state, organizations: action.payload };
      break;

    case TActionType.SetTutorialDocBeaconVisible:
      newState = { ...state, isTutorialDocBeaconVisible: action.payload };
      break;

    case TActionType.SetTutorialTipVisible:
      newState = { ...state, isTutorialTipVisible: action.payload };
      break;

    case TActionType.SetInvalidTeam: {
      newState = { ...state, isInvalidTeam: action.payload };
      break;
    }

    case TActionType.TeamId:
      newState = { ...state, teamId: action.payload };
      break;

    case TActionType.SetCurrentTeam:
      newState = { ...state, team: action.payload };
      break;

    case TActionType.DocumentId:
      newState = { ...state, documentId: action.payload };
      break;

    case TActionType.SetIsInvalidSubscription:
      newState = { ...state, isInvalidSubscription: action.payload };
      break;

    case TActionType.SetUserSalesProfile:
      newState = { ...state, userSalesProfile: action.payload };
      break;

    case TActionType.SetEducationalTermsState:
      newState = { ...state, isEducationalTermsWindowVisible: action.payload };
      break;

    case TActionType.SetEducationalSnippetsState:
      newState = { ...state, isEducationalSnippetsWindowVisible: action.payload };
      break;

    case TActionType.SetTutorialVisible:
      newState = { ...state, isTutorialVisible: action.payload };
      break;

    case TActionType.SetNotificationBarState:
      newState = {
        ...state,
        isNotificationBarVisible: action.payload,
      };
      break;

    default:
      return state;
  }

  return { ...newState };
};

const appModel = new AppModel();
const Context = createContext<{
  appState: TAppState;
  appModel: AppModel;
  loadApplicationStats: (
    limitType: typeof TSubscriptionLimitType.type,
  ) => Promise<{ limit: number; value: number } | undefined>;
  loadSubscription: () => Promise<TSubscription | undefined>;
  loadSubscriptionLimits: () => Promise<TSubscriptionLimit[] | undefined>;
  refreshTeamsList: () => void;
  refreshPermissions: () => Promise<any>;
  dispatchAppState: React.Dispatch<Actions>;
}>({
  appState: initialAppState,
  appModel,
  dispatchAppState: () => {},
  loadApplicationStats: () => Promise.resolve(undefined),
  loadSubscription: () => Promise.resolve(undefined),
  loadSubscriptionLimits: () => Promise.resolve(undefined),
  refreshTeamsList: () => {},
  refreshPermissions: () => Promise.resolve(undefined),
});

export const AppStateProvider: React.FC<{ children: ReactNode }> = observer(({ children }) => {
  const [appState, dispatch] = useReducer(reducer, initialAppState);
  const { organizationId, documentId, organization, team } = appState;

  const { user } = appModel;

  const isChromeExtensionInstalled = useExtensionChrome();
  const isQuestionnaireVisible = useQuestionnaire();
  const isTutorialVisible = useTutorial();

  const { assistantSubscription } = appModel;

  const { termsEducational, snippetsEducational } = useEducationalModule(organizationId, user);
  const storageChromeWindowVisible = LocalStorageService.getItem<UserChromeExtWindowState[] | null>(
    LocalStorageKey.chromeWindowVisible,
  );
  const tutorialVisibleRecord = LocalStorageService.getItem<string[]>(LocalStorageKey.tutorialVisible);
  const educationalTermsModuleVisible = LocalStorageService.getItem<string[]>(
    LocalStorageKey.educationalTermsModuleVisible,
  );
  const educationalSnippetsModuleVisible = LocalStorageService.getItem<string[]>(
    LocalStorageKey.educationalSnippetsModuleVisible,
  );

  useEffect(() => {
    initDatadog();
  }, []);

  useEffect(() => {
    if (!user?.id || !organizationId || VITE_ENV !== APP_ENV.PROD) {
      return;
    }

    setDatadogUser({
      userId: `${user.id}`,
      orgId: `${organizationId}`,
      teamId: team?.id,
    });
  }, [organizationId, team?.id, user]);

  useEffect(() => {
    appModel.setDocumentId(documentId ? String(documentId) : undefined);

    if (organization) {
      appModel.setOrganization(organization);
    }

    if (team) {
      appModel.setTeam(team);
    }
  }, [team, organization, documentId]);

  const loadApplicationStats = useCallback(
    (limitType: typeof TSubscriptionLimitType.type) => {
      if (assistantSubscription.limits) {
        return assistantSubscription.limits.reloadLimit(limitType);
      }

      return Promise.resolve(undefined);
    },
    [assistantSubscription.limits],
  );

  const loadSubscriptionLimits = useCallback(() => {
    if (assistantSubscription.limits) {
      return assistantSubscription.limits.reloadSubscriptionLimits();
    }

    return Promise.resolve(undefined);
  }, [assistantSubscription.limits]);

  const loadSubscription = useCallback(() => {
    assistantSubscription.$subscription.reload();

    return assistantSubscription.$subscription.promise;
  }, [assistantSubscription.$subscription]);

  useAnalytics({
    appState,
    userProfile: user,
    isAuthenticated: appModel.isAuthenticated,
  });

  useEffect(() => {
    if (user) {
      dispatch({ type: TActionType.SetIsChromeExtensionInstalled, payload: isChromeExtensionInstalled });

      let isChromeWindowVisible: null | boolean = !isQuestionnaireVisible;

      const userOrgStorageKey = `${organizationId}${user.id}`;

      if (tutorialVisibleRecord?.includes(userOrgStorageKey)) {
        dispatch({ type: TActionType.SetTutorialVisible, payload: false });
      }

      dispatch({
        type: TActionType.SetEducationalTermsState,
        payload: !educationalTermsModuleVisible?.includes(userOrgStorageKey),
      });

      dispatch({
        type: TActionType.SetEducationalSnippetsState,
        payload: !educationalSnippetsModuleVisible?.includes(userOrgStorageKey),
      });

      if (storageChromeWindowVisible) {
        const userChromeWindowState = storageChromeWindowVisible.find(item => item.id === user.id);

        if (userChromeWindowState) {
          if (userChromeWindowState.state !== null) {
            isChromeWindowVisible = userChromeWindowState.state;
          }

          if (userChromeWindowState.footerState !== null && !userChromeWindowState.footerState) {
            LocalStorageService.setItem(
              LocalStorageKey.chromeWindowVisible,
              storageChromeWindowVisible.map(item => {
                const storageItem = item;

                if (item.id === user.id) {
                  storageItem.footerState = true;
                }

                return storageItem;
              }),
            );
          }
        }
      }

      if (!isTutorialVisible) {
        if (isChromeBrowser() && isChromeExtensionInstalled) {
          dispatch({ type: TActionType.SetChromeWindowState, payload: false });
          dispatch({ type: TActionType.SetChromeAnnouncementState, payload: false });
        } else if (isChromeWindowVisible !== null) {
          const chromeWindowState = isChromeBrowser() ? isChromeWindowVisible : false;
          dispatch({ type: TActionType.SetChromeWindowState, payload: chromeWindowState });
          dispatch({ type: TActionType.SetChromeAnnouncementState, payload: !chromeWindowState });
        } else {
          dispatch({ type: TActionType.SetChromeWindowState, payload: false });
          dispatch({ type: TActionType.SetChromeAnnouncementState, payload: false });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChromeExtensionInstalled, isQuestionnaireVisible, user, organizationId, isTutorialVisible]);

  useEffect(() => {
    if (termsEducational) {
      dispatch({ type: TActionType.SetEducationalTermsState, payload: termsEducational });
    }

    if (snippetsEducational) {
      dispatch({ type: TActionType.SetEducationalSnippetsState, payload: snippetsEducational });
    }
  }, [termsEducational, snippetsEducational]);

  useEffect(() => {
    if (isTutorialVisible) {
      dispatch({ type: TActionType.SetTutorialVisible, payload: true });
    }
  }, [isTutorialVisible]);

  return (
    <Context.Provider
      value={{
        appState: {
          ...appState,
          userProfile: user,
        },
        appModel,
        loadApplicationStats,
        loadSubscription,
        loadSubscriptionLimits,
        refreshTeamsList: appModel.teamsModel.$teams.reload,
        refreshPermissions: appModel.refreshPermissions,
        dispatchAppState: dispatch,
      }}
    >
      {children}
    </Context.Provider>
  );
});

export function useAppState() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }

  return context;
}
