import type { ReactNode } from 'react';
import type React from 'react';
import type { IUserProfile } from '@writercolab/common-utils';
import { wordPluralize } from '@writercolab/common-utils';

import { Icon, IconVariant, Text, TextColor, UserAvatar } from '@writercolab/ui-atoms';
import styles from './styles.module.css';

export interface WithComponentSnackProps {
  trigger: React.ReactNode;
}

const WithAvatar: React.FC<{
  avatar?: ReactNode;
  name?: string;
  email?: string;
  abbr?: string;
  children: ReactNode;
}> = ({ avatar = null, name = '', email = '', abbr = '', children }) => (
  <span style={{ display: 'flex', alignItems: 'center' }}>
    {avatar || <UserAvatar fullName={name} label={email} style={{ marginRight: 5 }} abbreviation={abbr} />}{' '}
    <span>{children}</span>
  </span>
);

const WithComponent = ({ trigger }: WithComponentSnackProps) => <div>{trigger}</div>;

export const snackbarMessages = {
  error: {
    standard: 'Something went wrong. Please try again.',
    lackOfPermissions:
      "Your approval was unsuccessful because you don't have the right permissions. Only org admins can approve requests to join your organization.",
  },
  billing: {
    loadingBillingDetails: 'Loading billing details…',
    paymentMethodUpdated: 'Your payment method has been updated',
    paymentConfirmed: 'Payment confirmed successfully!',
    paymentConfirmationError: 'Payment has not been confirmed!',
    paymentExtensionSuccess: 'Your team trial has been restarted',
    paymentFailed: (errMsg: string) => `There was an error inviting the person ${errMsg}`,
    withComponent: ({ trigger }: WithComponentSnackProps) => <WithComponent trigger={trigger} />,
  },
  deleteOrgRequest: {
    reCreated: "We've resent the email to confirm your delete request. Please check your inbox.",
    deleted: 'Your request to delete this organization was canceled.',
    tokenExpired:
      'This delete organization link has expired or been canceled. Check your account page to resend the confirmation email or submit a new deletion request.',
  },
  pendingUserAction: {
    rejectedAlready:
      "This user's request to join was already rejected by another org admin. If this is an error, you can invite the user to the org.",
    approvedAlready: "This user's request to join was already approved by another org admin.",
  },
  loader: {
    invitingTeamMember: 'Inviting team member(s)…',
    searching: 'Loading search results',
    roleUpdating: 'Updating role',
    removePersonUpdating: 'Deleting user...',
  },
  copy: {
    inviteLink: 'Invite URL copied to clipboard',
    text: 'Copied to clipboard',
  },
  user: {
    emailConfirmed: 'Your email address has been confirmed.',
    extensionsConfigurationSaved: 'App settings have been saved.',
    resendConfirmationEmail: 'Confirmation email sent. Please check your inbox.',
    resendConfirmationEmailError: 'Error sending confirmation email. Please try again',
    passwordSuccess: 'Your password has been updated',
    resetPasswordSendSuccess: 'We’ve emailed you instructions on how to reset your password.',
    profileUpdatedSuccess: 'Your profile updated successfully!',
    phoneSuccess: 'Your phone number was successfully updated',
    emailSuccess: 'Your email updated successfully!',
    organizationNameUpdatedSuccess: 'Organization name updated successfully!',
    checkInbox: 'Please check your inbox to confirm your new email.',
    checkInboxCreatePassword: "We've sent an email to your inbox with instructions for creating your password.",
    removeTeammate: 'This user has been deleted',
    requestJoinOrganizationError: 'Error requesting to join organization. Please try later.',
    createOrganizationError: 'Error creating organization. Please try later.',
    roleUpdateSuccess: (
      userProfile: IUserProfile,
      isTeamUsersPage: boolean,
      role: React.ReactNode,
      isMultiTeam: boolean,
    ) => (
      <WithAvatar name={userProfile.fullName} email={userProfile.email || ''}>
        <b>{userProfile.fullName?.trim().length ? userProfile.fullName : userProfile.email}</b> is now{' '}
        {isTeamUsersPage && isMultiTeam ? ' a team ' : ' an org '} {role}
      </WithAvatar>
    ),
    roleUpdateError: (err: Error) => `Error updating user role. ${err.message}`,
    removePersonSuccess: ({ fullName, email }: IUserProfile, orgOrTeamName: string) => (
      <span>
        <b>{fullName?.trim().length ? fullName : email}</b> has been deleted from {orgOrTeamName}
      </span>
    ),
    removePersonError: (err: Error) => `Error removing user. ${err.message}`,
    resendInviteSuccess: (email: string) => (
      <WithAvatar email={email}>
        An invite email has been resent to <b>{email}</b>
      </WithAvatar>
    ),
    inviteDuplicates: (duplicatesCount: number) => (
      <span>
        <b>
          {duplicatesCount} {wordPluralize(duplicatesCount, 'teammate')}
        </b>{' '}
        already exist
      </span>
    ),
    inviteSuccess: (invitees: string[]) => (
      <span>
        {invitees.length > 1 ? (
          <span>
            <b>
              {invitees.length} {wordPluralize(invitees.length, 'teammate')}
            </b>{' '}
            were successfully invited
          </span>
        ) : (
          <WithAvatar email={invitees[0] ?? ''}>
            <span>
              <b>{invitees[0]}</b> has been invited
            </span>
          </WithAvatar>
        )}
      </span>
    ),
    inviteError: (errMsg: string) => `${errMsg || 'Error sending invitation'}`,
    userSignupApproved: (email: string) => `${email} was approved to join your org`,
    userSignupRejected: (email: string) => `${email} was not approved to join this org and has been removed`,
  },
  team: {
    deleteSuccess: (teamName: string) => (
      <span>
        <b>{teamName}</b> team has been deleted
      </span>
    ),
    createSuccess: (teamName: string) => (
      <WithAvatar abbr={teamName}>
        <b>{teamName}</b> team has been created
      </WithAvatar>
    ),
    createError: (errMsg: string) => `There was an error creating new team: ${errMsg}`,
    updateSuccess: (avatar: React.ReactNode, name: string) => (
      <WithAvatar name={name} avatar={avatar}>
        {name} team details have been updated
      </WithAvatar>
    ),
    updateError: (errMsg: string) => `There was an error updating the team ${errMsg}`,
  },
  terms: {
    createSuccess: () => (
      <span className={styles.successOneLine}>
        <Icon name={IconVariant.GREEN1_CHECKMARK}></Icon>
        <Text color={TextColor.WHITE}>Your new term was added</Text>
      </span>
    ),
    createDuplicateError: "Your new term wasn't created because it already exists.",
    bulkImportSuccess: (amount: number) => `We’ve successfully imported ${amount} ${wordPluralize(amount, 'term')}!`,
  },
  billingGroup: {
    createSuccess: () => (
      <span className={styles.successOneLine}>
        <Icon name={IconVariant.GREEN1_CHECKMARK}></Icon>
        <Text color={TextColor.WHITE}>Your new billing groups was added</Text>
      </span>
    ),
    createDuplicateError: "Your new billing group wasn't created because it already exists.",
    bulkImportSuccess: (amount: number) =>
      `${wordPluralize(amount, 'Billing group')} were successfully updated for ${amount} users!`,
  },
  snippets: {
    bulkImportSuccess: (amount: number) => `We’ve successfully imported ${amount} ${wordPluralize(amount, 'snippet')}!`,
  },
  docs: {
    deleteSuccess: () => `Your document was deleted`,
    deleteError: (errMsg: string) => `Error deleting document ${errMsg}`,
    shareChange: () => 'Sharing settings have been updated',
    copyDocumentLink: 'Document link copied',
  },
  sso: {
    addDomainError: `One or more of the domains you added are already registered by another organization. Add a different domain or contact support@writer.com for help.`,
    uploadXMLSuccess: `Your SAML metadata XML was successfully uploaded`,
    connectionTestSuccess: `Your SAML SSO connection looks good!`,
    connectionTestFail: `Your SAML SSO connection was unsuccessful. Check to make sure you've entered the right information.`,
    entityIDRegistered: `This entity ID is already registered. Update your IdP configuration with a unique entity ID and re-upload the xml metadata to finish SAML SSO setup.`,
    updateSuccess: `Your IdP configuration was successfully updated!`,
    updateError: (errMsg: string) => `Your IdP configuration update failed: ${errMsg}!`,
    removeProviderSuccess: `Your IdP configuration successfully deleted!`,
    removeProviderError: (errMsg: string) => `Your IdP configuration deletion failed: ${errMsg}!`,
    minTeamSize: 'To save your changes, select at least 1 default team for uninvited users to join',
    addDownloadError: (errMsg: string) => `Your IdP configuration XML Metadata file downloaded failed: ${errMsg}!`,
  },
  templates: {
    getTemplatesSuccess: `Language templates were fetched successfully!`,
    getTemplateSuccess: `Language template were fetched successfully!`,
    getTemplateFieldSuccess: `Field suggestions were fetched successfully!`,
    getCategoriesError: (errMsg: string) => `Template categories fetching failed: ${errMsg}!`,
    getTemplatesError: (errMsg: string) => `Language templates fetching failed: ${errMsg}!`,
    getTemplateError: (errMsg: string) => `Language template fetching failed: ${errMsg}!`,
    getTemplateFieldError: (errMsg: string) => `Field fetching failed: ${errMsg}!`,
  },
  serviceAccount: {
    createSuccess: 'Your service account was successfully created',
    deleteSuccess: 'Your service account was successfully deleted',
  },
  appSecurity: {
    settingsUpdated: 'Your settings have been updated',
  },
  rewrites: {
    getRewritePromptsError: (errMsg: string) => `Prompts fetching failed: ${errMsg}!`,
    updateRewritePromptsError: (errMsg: string) => `Update prompts failed: ${errMsg}!`,
  },
  magicLinks: {
    deleteEntryPointSuccess: 'Successfully deleted entry point.',
    deleteSitemapSuccess: 'Successfully deleted entry point.',
    getDomainsError: (errMsg: string) => `Domain fetching failed: ${errMsg}!`,
    addDomainError: (errMsg: string) => `${errMsg}`,
    updateDomainError: (errMsg: string) => `Domain updating failed: ${errMsg}!`,
    deleteDomainError: (errMsg: string) => `Deletion failed: ${errMsg}!`,
    crawlDomainError: (errMsg: string) => `Domain crawl failed: ${errMsg}!`,
  },
  magicLinksEditor: {
    appliedURL: (times: number) => (
      <span className={styles.successOneLine}>
        <Icon name={IconVariant.MAGIC_LINK_SNACKBAR}></Icon>
        <Text color={TextColor.WHITE}>{`Link has been applied in ${times} ${wordPluralize(
          times,
          'location',
        )} in your doc`}</Text>
      </span>
    ),
  },
  magicLinksEditorError: {
    appliedURL: () => (
      <span className={styles.successOneLine}>
        <Icon name={IconVariant.MAGIC_LINK_SNACKBAR}></Icon>
        <Text color={TextColor.WHITE}>Magic links can be applied to 10 words max. Try selecting fewer words.</Text>
      </span>
    ),
  },
  mfa: {
    disabled: 'Multifactor authentication is disabled',
    multiFactorAuthenticationStatus: (challenge: string, isEnable: boolean) =>
      `Multifactor authentication for ${challenge === 'Email' ? 'Email' : 'SMS'} is ${
        isEnable ? 'enabled' : 'disabled'
      }`,
  },
  domainDiscoverability: {
    deleteSuccess: (domainName: string) => `${domainName} successfully deleted`,
    deleteError: `Something went wrong while deleting domain`,
  },
  feedback: {
    success: 'Your feedback was submitted. Thanks for reviewing!',
    error: 'Unable to submit feedback',
  },
  knowledgeGraph: {
    genericError: 'Something went wrong',
    updateSuccess: 'Your graph details have been updated',
    deleteSuccess: (name: string) => `${name} was deleted`,
    fileSizeError: (fileName: string, maxFileSize: number) =>
      `We're unable to upload ${fileName} because it's over the max file size of ${maxFileSize}MB.`,
    fileTypeError: (fileName: string, fileExtension: string) =>
      `${fileName} wasn't added because ${fileExtension} files are not supported.`,
  },
};
