import React from 'react';

import { IDomElementOffset, getDomElementOffset } from '@writercolab/common-utils';

export const TUTORIAL_TIP_SHOW_DELAY = 4000;
export const TUTORIAL_DOC_TIP_SHOW_DELAY = 2500;
export const TUTORIAL_DOC_TIP_HIDE_DELAY = 4500;
export const FIRST_STEP_ID = 1;
export const FIRST_EMPTY_NAME_TITLE = 'Welcome to Writer!';

export type CssStyles = Record<string, string>;

export interface IStepConfig {
  id: number;
  title: string;
  description: string;
  heading?: React.ReactNode;
  showDimmer?: boolean;
  onActivate?: () => void;
  navigatorArrowStyle?: () => CssStyles;
}

export enum IOnboardingTutorialFlow {
  TEAM = 'team',
  PRO = 'pro',
}

export const toCssProperty = (val: number): string => `${val}px`;

export const calculateArrowStyle = (
  domElementId: string,
  initialStyleValues: CssStyles = { width: '203px' },
): CssStyles => {
  const offset = getArrowStyleOffset(domElementId);
  let style = { ...initialStyleValues };

  if (offset) {
    if (offset.left) {
      style = { ...style, left: toCssProperty(offset.left) };
    }

    if (offset.top) {
      style = { ...style, top: toCssProperty(offset.top) };
    }
  }

  return style;
};

const getArrowStyleOffset = (domElementId: string): IDomElementOffset | null => {
  const el = document.getElementById(domElementId);

  return el ? getDomElementOffset(el) : null;
};
