import { useState } from 'react';
import useWindowEvent from './useWindowEvent';
import { getLogger } from '../utils/logger';

const LOG = getLogger('useLocalStorage');

const LOCAL_STORAGE_EVENT = 'storage';

export const TRIGGER_LOGOUT_IN_TABS = 'trigger logout in all tabs'; // using the same key as angular so that it logs out angular pages if any as well
export const TERMS_PAGE_CONFIG = 'terms page configuration';
export const FIRST_SIGN_IN = 'firstSignIn';

export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      LOG.error(error);

      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);

      if (value === undefined) {
        // Remove item from local storage
        window.localStorage.removeItem(key);
      } else {
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      LOG.error(error);
    }
  };

  return [storedValue, setValue] as const;
}

export const useLocalStorageListener = (localStorageKey: string, callback: (fn: StorageEvent) => void) => {
  useWindowEvent(LOCAL_STORAGE_EVENT, storageEvent => {
    if (storageEvent.key === localStorageKey) {
      callback(storageEvent);
    }
  });
};
