import React from 'react';

import { noop } from '@writercolab/common-utils';
import { DotLoader, Text } from '@writercolab/ui-atoms';

import { loadingHighlightsMock } from '../constants';
import DocumentHighlight from './DocumentHighlight';
import DocumentHighlightsHeader from './DocumentHighlightsHeader';

import styles from '../styles.module.css';

interface IDocumentHighlightLoaderProps {
  onCloseClick: () => void;
}

export const DocumentHighlightLoader: React.FC<IDocumentHighlightLoaderProps> = ({ onCloseClick }) => (
  <div className={styles.container}>
    <DocumentHighlightsHeader onHideClick={onCloseClick} />
    <div className={styles.containerList}>
      {loadingHighlightsMock.map(highlight => (
        <DocumentHighlight
          documentWordsCount={500}
          key={`${highlight.id}-${highlight.type}`}
          highlight={highlight}
          onReloadClick={noop}
          onCopyClick={noop}
        />
      ))}
    </div>
    <div className={styles.containerLoading}>
      <DotLoader className={styles.dotLoader} />
      <Text className={styles.containerLoadingText}>Analyzing your content</Text>
    </div>
  </div>
);

export default DocumentHighlightLoader;
