import React from 'react';

import { Button, ButtonTypes, Modal, Text } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface ITeamTrialExtension {
  open: boolean;
  isLoading: boolean;
  onMonthFreeCb: () => void;
  handleClose: () => void;
}

export const TeamTrialExtension = ({ open, isLoading, onMonthFreeCb, handleClose }: ITeamTrialExtension) => (
  <Modal open={open} title="Restart your Team trial" className={styles.modalContainer} handleClose={handleClose}>
    <div className={styles.wrapper}>
      <div className={styles.restartButton}>
        <Text>
          Welcome back to Writer! We’re so glad you’re back for another look. We’re giving you another 14 days to try
          out Writer, on us. Enjoy!
        </Text>

        <div className={styles.trialBackground} />

        <div className={styles.btnGroup}>
          <Button
            type={ButtonTypes.BLACK}
            className={styles.mainAction}
            onClick={() => onMonthFreeCb()}
            isLoading={isLoading}
            content="Restart my trial"
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default TeamTrialExtension;
