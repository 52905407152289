import type { FC } from 'react';
import { useMemo } from 'react';

import { ContentEditorPageMode, ContentEditorQueryParams } from '@writercolab/common-utils';

import { LoadingPage } from '@web/component-library';
import DocumentsContextProvider from 'context/documentsContext';
import useQuery from 'hooks/useQuery';
import { appLocalStorage } from 'models/localStorage';
import type { Params } from 'react-router';
import { useParams } from 'react-router';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';

import ContentEditorPage from './ContentEditorPage';
import { ContentEditorPageModel } from './ContentEditorPageModel';

interface ContentEditorPageWrapperProps {
  openLearningCenter: () => void;
}

interface QueryParamTypes extends Params {
  orgId: string;
  teamId: string;
  docId: string;
}

const ContentEditorPageWrapper: FC<ContentEditorPageWrapperProps> = ({ openLearningCenter }) => {
  const query = useQuery();
  const { orgId, teamId, docId } = useParams() as QueryParamTypes;
  const { appModel } = useAppState();
  const currentMode =
    (query.get(ContentEditorQueryParams.mode) as ContentEditorPageMode) || ContentEditorPageMode.PURE_EDITOR;

  const model = useMemo(() => {
    if (!appModel || !currentMode) {
      return null;
    }

    return new ContentEditorPageModel({
      appModel,
      request: requestService.api,
      storage: appLocalStorage,
      currentMode,
    });
  }, [appModel, currentMode]);

  if (!model) {
    return <LoadingPage />;
  }

  return (
    <DocumentsContextProvider activeOrgId={+orgId} activeTeamId={+teamId} activeDocId={+docId}>
      <ContentEditorPage model={model} openLearningCenter={openLearningCenter} />
    </DocumentsContextProvider>
  );
};

export default ContentEditorPageWrapper;
