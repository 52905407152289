import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import type { ISuggestionSection, ISuggestionSettings } from '@writercolab/common-utils';
import {
  SuggestionCategoryType,
  getSuggestionSettings,
  updateSuggestionSettings,
  updateSuggestionsLanguage,
} from '@writercolab/common-utils';

import type { WithChildren } from '@web/types';
import { AnalyticEvents, AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { lensProp, set } from 'ramda';

import { useAppState } from '../state';

interface ISuggestionsContext {
  suggestionSettings: ISuggestionSettings;
  isSaving: boolean;
  isReadOnly: boolean;
  isLoading: boolean;
  isTeamNameShown: boolean;
  isTeamMember: boolean;
  teamName: string;
  teamId?: number;
  lastUpdated: string;
  lastUpdatedBy: string;
  isEnterpriseFour: boolean;
  userEmail: string;
  setSectionEnabled: (name: string, enabled: boolean) => void;
  updateSectionMeta: (name: string, meta: any) => void;
  updateLanguageCode: (languageCode: string) => Promise<void>;
  updateSettingsSection: (section: ISuggestionSection<any>) => void;
}

const suggestionSettingsCategories = lensProp<ISuggestionSettings, 'categories'>('categories');
const suggestionSectionMeta = lensProp<ISuggestionSection<any>, 'meta'>('meta');
const suggestionSectionEnabled = lensProp<ISuggestionSection<any>, 'enabled'>('enabled');

const SuggestionsContext = createContext<ISuggestionsContext>({} as ISuggestionsContext);

const SuggestionsContextProvider: React.FC<WithChildren> = observer(({ children }) => {
  const { appState, appModel } = useAppState();
  const [isLoading, setIsLoading] = useState(true);
  const [suggestionSettings, setSuggestionSettings] = useState<ISuggestionSettings>();
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const userEmail = appState.userProfile?.email || '';

  const { teamName, styleguideId } = useMemo(() => {
    let teamName = 'UNKNOWN';

    if (appState.team?.styleguide.teamName) {
      teamName = appState.team?.styleguide.teamName;
    } else if (appState.team) {
      teamName = appState.team.name;
    }

    if (appState.team && teamName) {
      return {
        teamName,
        styleguideId: appState.team.styleguide.styleguideId,
      };
    }

    return {
      teamName,
      styleguideId: '',
    };
  }, [appState.team]);

  useEffect(() => {
    if (!styleguideId) {
      return;
    }

    getSuggestionSettings(Number(appState.organizationId), styleguideId).then(settings => {
      setSuggestionSettings(settings);
      setIsReadOnly(!appModel.permissionsModel?.isTeamAdminOf(settings.teamId));
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.organizationId, styleguideId]);

  const onSaveSuggestions = async (suggestionSettings: ISuggestionSettings) => {
    setIsSaving(true);
    const newSettings = await updateSuggestionSettings(
      Number(appState.organizationId),
      styleguideId,
      suggestionSettings.categories,
    );
    setSuggestionSettings(newSettings);
    setIsSaving(false);
  };

  const getSectionByName = useCallback(
    (name: string) => suggestionSettings?.categories.find(section => section.category === name),
    [suggestionSettings],
  );

  const setSectionEnabled = (name: string, enabled: boolean) => {
    const section = getSectionByName(name);

    if (!section) {
      return;
    }

    const newSection = set(suggestionSectionEnabled, enabled, section);
    updateSettingsSection(newSection);
    _trackUpdateSectionMeta(name);
  };

  const _trackUpdateSectionMeta = useCallback(
    (sectionName: string) => {
      let eventName: AnalyticEvents | undefined;
      const suggestionSection = sectionName as SuggestionCategoryType;

      if (
        [SuggestionCategoryType.PUNCTUATION, SuggestionCategoryType.PUNCTUATION_CHECKER].includes(suggestionSection)
      ) {
        eventName = AnalyticsActivity.suggestionsPunctuationUpdated;
      } else if (
        [
          SuggestionCategoryType.ACRONYM,
          SuggestionCategoryType.CAPITALIZATION,
          SuggestionCategoryType.DATE_TIME,
          SuggestionCategoryType.SOCIAL,
          SuggestionCategoryType.EMOJIS,
        ].includes(suggestionSection)
      ) {
        eventName = AnalyticsActivity.suggestionsWritingStyleUpdated;
      } else if ([SuggestionCategoryType.READABILITY, SuggestionCategoryType.CLARITY].includes(suggestionSection)) {
        eventName = AnalyticsActivity.suggestionsClarityUpdated;
      } else if (
        [SuggestionCategoryType.GENDER_INCLUSIVITY, SuggestionCategoryType.SENSITIVITY].includes(suggestionSection)
      ) {
        eventName = AnalyticsActivity.suggestionsInclusivityUpdated;
      } else if (
        [SuggestionCategoryType.HEALTHY_COMMUNICATION, SuggestionCategoryType.CONFIDENCE].includes(suggestionSection)
      ) {
        eventName = AnalyticsActivity.suggestionsDeliveryUpdated;
      } else if (
        [SuggestionCategoryType.DATA_LOSS_PREVENTION, SuggestionCategoryType.GLOBAL_COMPLIANCE].includes(
          suggestionSection,
        )
      ) {
        eventName = AnalyticsActivity.suggestionsComplianceUpdated;
      } else if (
        [SuggestionCategoryType.GRAMMAR_CHECKER, SuggestionCategoryType.SPELL_CHECKER].includes(suggestionSection)
      ) {
        eventName = AnalyticsActivity.suggestionsGeneralUpdated;
      } else if ([SuggestionCategoryType.PLAGIARISM].includes(suggestionSection)) {
        eventName = AnalyticsActivity.suggestionsPlagiarismUpdated;
      }

      if (eventName) {
        appModel.analyticsService.track(eventName, {});
      }
    },
    [appModel.analyticsService],
  );

  const updateSectionMeta = (name: string, meta: any) => {
    const section = getSectionByName(name);

    if (!section) {
      return;
    }

    const newSection = set(suggestionSectionMeta, meta, section);
    updateSettingsSection(newSection);
    _trackUpdateSectionMeta(name);
  };

  const updateLanguageCode = async (languageCode: string) => {
    setIsSaving(true);
    await updateSuggestionsLanguage(Number(appState.organizationId), Number(appState.teamId), languageCode);
    setIsSaving(false);
  };

  const updateSettingsSection = (section: ISuggestionSection<any>) => {
    if (!suggestionSettings) {
      return;
    }

    const newSections = suggestionSettings.categories.map(item => {
      if (item.category !== section.category) {
        return item;
      }

      return { ...section };
    });

    const newSettings = set(suggestionSettingsCategories, newSections, suggestionSettings);
    setSuggestionSettings(newSettings);
    onSaveSuggestions(newSettings);
  };

  return (
    <SuggestionsContext.Provider
      value={{
        suggestionSettings: suggestionSettings!,
        lastUpdated: suggestionSettings?.updateTime || Date.now().toString(),
        lastUpdatedBy: `${suggestionSettings?.modifiedUser?.firstName} ${suggestionSettings?.modifiedUser?.lastName}`,
        isLoading,
        isTeamMember: !!appModel.permissionsModel?.isTeamMember,
        setSectionEnabled,
        updateSectionMeta,
        updateLanguageCode,
        isSaving,
        isReadOnly,
        teamName,
        isTeamNameShown: appModel.assistantSubscription.isEnterprise,
        isEnterpriseFour: !!appModel.assistantSubscription.enterpriseMeta?.tierFour,
        userEmail,
        updateSettingsSection,
        teamId: appState.teamId,
      }}
    >
      {children}
    </SuggestionsContext.Provider>
  );
});

export function useSuggestionsContext() {
  const context = useContext(SuggestionsContext);

  if (!context) {
    throw new Error('useSuggestionsContext must be used within the SuggestionsContextProvider');
  }

  return context;
}

export default SuggestionsContextProvider;
