import { useCallback, useMemo, useState } from 'react';

import {
  type IDocumentShareStatus,
  type IDocumentShareStatusPayload,
  type ITeam,
  type IUserProfile,
  SharingOptions,
  copyToClipboard,
} from '@writercolab/common-utils';
import { Button, ButtonTypes, Modal, RadioGroup, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import type { IAdminsOptionsList } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { ROUTE } from 'services/config/routes';
import { useAppState } from 'state';

import TeamAvatar from '../TeamAvatar';

import styles from './ShareDocument.module.css';

interface IShareDocProps {
  organizationId: number | undefined;
  documentId: number | undefined;
  isOpen: boolean;
  documentShareStatus: IDocumentShareStatus;
  team: ITeam;
  onInputChangeMembers: (value?: string) => Promise<IAdminsOptionsList[]>;
  onClose: () => void;
  onCopyLink: () => void;
  onSubmit: (shareStatus: IDocumentShareStatusPayload) => void;
}

const analyticOpts = {
  [SharingOptions.PRIVATE]: 'visible_only_to_me',
  [SharingOptions.TEAM]: 'share_with_team',
  [SharingOptions.MEMBERS]: 'share_with_specific_teammates',
};

const generateEditorUrl = (orgId: number, teamId: number, docId: number) => {
  const { host } = document.location;
  const editorPage = ROUTE.toEditorPage(orgId, teamId, docId);

  return `${host}${editorPage}`;
};

const usersToOptions = (users: IUserProfile[]) =>
  users.map(
    user =>
      ({
        name: user.fullName,
        subText: user.email || '',
        id: user.id,
      }) as IAdminsOptionsList,
  );

export const ShareDocument: React.FC<IShareDocProps> = ({
  organizationId,
  documentId,
  documentShareStatus,
  onInputChangeMembers,
  isOpen,
  team,
  onClose,
  onCopyLink,
  onSubmit,
  ...props
}) => {
  const {
    appModel: { analyticsService },
  } = useAppState();
  const [memberOptions, setMemberOptions] = useState<IAdminsOptionsList[]>([]);
  const [members, setMembers] = useState<IAdminsOptionsList[]>(usersToOptions(documentShareStatus.users || []));
  const [shareSelection, setShareSelection] = useState<SharingOptions>(documentShareStatus.access);

  const _onClose = useCallback(() => {
    analyticsService.track(AnalyticsActivity.docSharedModalClosed, {
      origin: 'my_work',
      doc_id: String(documentId),
      sharing_option: analyticOpts[shareSelection] as any,
    });

    onClose();
  }, [onClose, analyticsService, documentId, shareSelection]);

  const _onCopyLink = useCallback(() => {
    analyticsService.track(AnalyticsActivity.docSharedCopyLinkButtonClicked, {
      origin: 'my_work',
      doc_id: String(documentId),
      sharing_option: analyticOpts[shareSelection] as any,
    });

    onCopyLink();
  }, [onCopyLink, analyticsService, documentId, shareSelection]);

  const saveButtonEnabled = useMemo(() => {
    const oldMembers = memberOptions
      .map(member => member.id)
      .sort()
      .join('_');
    const newMembers = documentShareStatus.users
      ?.map(member => member.id)
      .sort()
      .join('_');

    if (shareSelection === SharingOptions.MEMBERS && oldMembers !== newMembers) {
      return true;
    }

    return shareSelection !== documentShareStatus.access;
  }, [documentShareStatus, memberOptions, shareSelection]);

  const onChangeMembers = useCallback(values => {
    setMembers(values);
  }, []);

  const _onInputChangeMembers = useCallback(
    async (value?: string) => {
      const membersList = await onInputChangeMembers(value);
      setMemberOptions(membersList);
    },
    [onInputChangeMembers],
  );

  const onChangeValue = event => {
    setShareSelection(event.target.value as SharingOptions);
  };

  const handleSubmit = () => {
    analyticsService.track(AnalyticsActivity.docSharedUpdateButtonClicked, {
      origin: 'my_work',
      doc_id: String(documentId),
      sharing_option: analyticOpts[shareSelection] as any,
    });

    onSubmit({
      access: shareSelection,
      userIds: members.map(user => user.id),
    });

    _onClose();
  };

  const handleCopyLink = useCallback(() => {
    if (!organizationId || !team.id || !documentId) {
      return;
    }

    const url = generateEditorUrl(organizationId, team.id, documentId);

    copyToClipboard({
      text: url,
    });

    _onCopyLink();
  }, [organizationId, team.id, documentId, _onCopyLink]);

  const radioGroupOptions = useMemo(
    () => [
      {
        value: SharingOptions.PRIVATE,
        text: (
          <Text
            variant={TextSize.M}
            bold={shareSelection === SharingOptions.PRIVATE}
            className={styles.sharingOptionTextSimple}
          >
            Visible only to me
          </Text>
        ),
      },
      {
        value: SharingOptions.TEAM,
        text: (
          <Text
            variant={TextSize.M}
            bold={shareSelection === SharingOptions.TEAM}
            className={styles.sharingOptionTextTeamAvatar}
          >
            Share with
            <span className={styles.sharingText}>
              <TeamAvatar className={styles.teamAvatar} team={team} />
            </span>
            {team.name}
          </Text>
        ),
      },
      {
        value: SharingOptions.MEMBERS,
        text: (
          <Text
            variant={TextSize.M}
            bold={shareSelection === SharingOptions.MEMBERS}
            className={styles.sharingOptionTextSimple}
          >
            Share with specific Teammates
          </Text>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shareSelection],
  );

  return (
    <Modal open={isOpen} handleClose={_onClose} title="Share doc" style={{ width: '408px' }} {...props}>
      <div className={styles.styledShareContent}>
        <Text variant={TextSize.M}>Give team members access to view and </Text>
        <Text variant={TextSize.M}>edit your doc.</Text>
        <RadioGroup
          name="shareOption"
          className={styles.sharingOptions}
          options={radioGroupOptions}
          onChange={onChangeValue}
          currentValue={shareSelection}
        />
        {shareSelection === SharingOptions.MEMBERS && (
          <InputGroup
            className={styles.styledInputGroup}
            name="members"
            inputType={InputTypes.AUTOCOMPLETE}
            handleChangeInput={onChangeMembers}
            onInputChange={_onInputChangeMembers}
            autocompleteOptions={memberOptions}
            defaultValue={members}
            placeholder="Name or email"
            id="members"
            label="Teammates"
            style={{ minHeight: '70px' }}
          />
        )}
        <div className={styles.controlButtons}>
          <Button type={ButtonTypes.DEFAULT} size={SizeTypes.SMALL} content="Copy link" onClick={handleCopyLink} />
          <Button
            type={ButtonTypes.PRIMARY}
            size={SizeTypes.SMALL}
            content="Update"
            onClick={handleSubmit}
            disabled={!saveButtonEnabled}
          />
        </div>
      </div>
    </Modal>
  );
};
