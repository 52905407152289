import { observable, runInAction } from 'mobx';
import qs from 'qs';

import { PaginatedModel } from '@writercolab/mobx';
import { RequestServiceInitialize } from '@writercolab/network';

import {
  TBillingGroupSortField,
  TBillingGroupSortOrder,
  TBillingGroupWithCount,
  TImportBillingGroupUsersRequest,
  TPaginatedBillingGroup,
  TPaginatedBillingGroupArgs,
  TPaginatedBillingGroupExtraArgs,
} from '@web/types';

interface BillingGroupApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  withDefaultSortField?: boolean;
}

const DEFAULT_PAGE_SIZE = 50;
export class BillingGroupApiModel {
  private $isLoading = observable.box(false);

  public pagination: PaginatedModel<
    TPaginatedBillingGroup,
    TBillingGroupWithCount,
    TPaginatedBillingGroupArgs,
    TPaginatedBillingGroupExtraArgs
  >;

  constructor(private opts: BillingGroupApiModelOptions) {
    const urlParams = new URLSearchParams(document.location.search);
    this.pagination = new PaginatedModel<
      TPaginatedBillingGroup,
      TBillingGroupWithCount,
      TPaginatedBillingGroupArgs,
      TPaginatedBillingGroupExtraArgs
    >({
      argsExtra: {
        search: urlParams?.get('search') ?? undefined,
        sortField: !this.opts.withDefaultSortField
          ? (urlParams?.get('sortField') as TBillingGroupSortField) ?? 'name'
          : 'name',
        sortOrder: (urlParams?.get('sortOrder') as TBillingGroupSortOrder) ?? 'asc',
      },
      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.pagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args, extra }) => {
        if (!this.opts.organizationId) {
          throw new Error('OrganizationId is not defined');
        }

        const query = {
          ...args,
          ...extra,
        };

        runInAction(() => {
          this.$isLoading.set(true);
        });

        const { data } = await this.opts.request.get('/api/user/v2/organization/{organizationId}/billing-group', {
          params: {
            path: {
              organizationId: this.opts.organizationId,
            },
            query,
          },
          querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
        });

        runInAction(() => {
          this.$isLoading.set(false);
        });

        return data;
      },
    });
  }

  getAllBillingGroups = async ({
    sortField = 'name',
    sortOrder = 'asc',
    limit = 1000,
  }: {
    sortField?: TBillingGroupSortField;
    sortOrder?: TBillingGroupSortOrder;
    limit?: number;
  }) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.get('/api/user/v2/organization/{organizationId}/billing-group', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
        query: {
          sortField,
          sortOrder,
          limit,
        },
      },
    });

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data;
  };

  create = async (params: { name: string; contact: string | null }) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });
    await this.opts.request.post('/api/user/v2/organization/{organizationId}/billing-group', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
      body: params,
    });
    runInAction(() => {
      this.$isLoading.set(false);
    });
  };

  edit = async (params: { name: string; contact?: string; groupId: number }) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    if (!params.groupId) {
      throw new Error('BillingGroupId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });
    await this.opts.request.put('/api/user/v2/organization/{organizationId}/billing-group/{billingGroupId}', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          billingGroupId: params.groupId,
        },
      },
      body: params,
    });
    runInAction(() => {
      this.$isLoading.set(false);
    });
  };

  deleteBillingGroup = async (params: { groupId: number }) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    if (!params.groupId) {
      throw new Error('BillingGroupId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });
    await this.opts.request.delete('/api/user/v2/organization/{organizationId}/billing-group/{billingGroupId}', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          billingGroupId: params.groupId,
        },
      },
    });
    runInAction(() => {
      this.$isLoading.set(false);
    });
  };

  importBillingGroup = async (params: TImportBillingGroupUsersRequest[]) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });
    const { data } = await this.opts.request.post('/api/user/v2/organization/{organizationId}/billing-group/import', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
      },
      body: params,
    });
    runInAction(() => {
      this.$isLoading.set(false);
    });

    return data;
  };

  downloadCSVBillingGroup = async (params: { hasBillingGroup?: boolean; billingGroupIds?: number[] }) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });

    const { data } = await this.opts.request.get('/api/user/v2/organization/{organizationId}/csv', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
        query: params,
      },
      parseAs: 'blob',
    });

    runInAction(() => {
      this.$isLoading.set(false);
    });

    return new Blob([data]);
  };

  downloadXLSXBillingGroup = async (params: { hasBillingGroup?: boolean; billingGroupIds?: number[] }) => {
    if (!this.opts.organizationId) {
      throw new Error('OrganizationId is not defined');
    }

    runInAction(() => {
      this.$isLoading.set(true);
    });
    const { data } = await this.opts.request.get('/api/user/v2/organization/{organizationId}/xlsx', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
        },
        query: params,
      },
      parseAs: 'blob',
    });
    runInAction(() => {
      this.$isLoading.set(false);
    });

    return new Blob([data]);
  };

  get data(): TBillingGroupWithCount[] | undefined {
    return this.pagination.value;
  }

  get isLoading() {
    return this.$isLoading.get();
  }

  refresh = async () => {
    await this.pagination.reload();
    await this.pagination.promise;
  };
}
