import type { ReactNode } from 'react';
import type React from 'react';
import { useCallback, useEffect, useReducer, useState } from 'react';

import { DropdownOption, Icon, IconVariant } from '@writercolab/ui-atoms';
import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import { CustomizedAccordion as Accordion } from '@web/component-library';
import type { IAdminsOptionsList, IMappedTeamDetails } from '@web/types';

import SuggestionsIcon from '../../../../assets/icons/navIcons/suggestions.svg?react';
import TermsIcon from '../../../../assets/icons/navIcons/terms.svg?react';
import { DEFAULT_PAGINATION_LIMIT } from '../../../../services/request/_types';
import { findUserInOrganization } from '../../../../services/request/user';
import { concatenateStrings } from '../../../../utils/stringUtils';
import { teamDropdownOptionsMapper } from '../../../../utils/teamUtils';

import styles from './styles.module.css';

interface IEditModalProps {
  isOpen: boolean;
  changeModalState: () => void;
  onSubmit: (teamId, state) => void;
  values?: IEditTeamEditForm;
  teamId: number;
  orgId?: number;
  teamsList: IMappedTeamDetails[];
}

export interface IEditTeamEditForm {
  name: IMappedTeamDetails['name'];
  admins: any[];
  anotherTeamsSuggestions?: DropdownOption | null;
  anotherTeamsTerms?: DropdownOption | null;
  anotherTeamsSnippets?: DropdownOption | null;
  avatar: ReactNode;
}

const initState: IEditTeamEditForm = {
  name: '',
  admins: [],
  anotherTeamsSuggestions: null,
  anotherTeamsTerms: null,
  anotherTeamsSnippets: null,
  avatar: '',
};

const reducer = (state: IEditTeamEditForm, { field, value }) => ({
  ...state,
  [field]: value,
});

const handleClickDropdown = (options: DropdownOption[], setOptions: (i: DropdownOption[]) => void, id: string) =>
  setOptions(options.map(option => (option.id === id ? { ...option, active: true } : { ...option, active: false })));

export const EditTeamModal: React.FC<IEditModalProps> = ({
  isOpen,
  changeModalState,
  values = initState,
  teamsList,
  orgId,
  onSubmit,
  teamId,
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const [adminOptions, setAdminOptions] = useState<IAdminsOptionsList[]>([]);
  const [anotherTermsTeams, setAnotherTermsTeams] = useState<DropdownOption[]>([]);
  const [anotherSuggestionsTeams, setAnotherSuggestionsTeams] = useState<DropdownOption[]>([]);
  const [anotherSnippetsTeams, setAnotherSnippetsTeams] = useState<DropdownOption[]>([]);

  const onChangeInput = useCallback(e => {
    dispatch({ field: e.target.name, value: e.target.value });
  }, []);

  const onChangeAdmins = useCallback(values => {
    dispatch({ field: 'admins', value: values });
  }, []);

  const onInputChangeAdmins = useCallback(
    (search: string) => {
      findUserInOrganization(orgId, { search, offset: 0, limit: DEFAULT_PAGINATION_LIMIT }).then(({ result }) => {
        const adminList: IAdminsOptionsList[] = result.map(({ user }) => ({
          name: concatenateStrings(' ', user.firstName, user.lastName),
          subText: user.email || '',
          id: user.id,
        }));

        setAdminOptions(adminList);
      });
    },
    [orgId],
  );

  const onChangeAnotherTerms = useCallback(
    (id: string) => {
      handleClickDropdown(anotherTermsTeams, setAnotherTermsTeams, id);
      dispatch({ field: 'anotherTeamsTerms', value: anotherTermsTeams.find(team => team.id === id) });
    },
    [anotherTermsTeams],
  );

  const onChangeAnotherSuggestions = useCallback(
    (id: string) => {
      handleClickDropdown(anotherSuggestionsTeams, setAnotherSuggestionsTeams, id);
      dispatch({ field: 'anotherTeamsSuggestions', value: anotherSuggestionsTeams.find(team => team.id === id) });
    },
    [anotherSuggestionsTeams],
  );

  const onChangeAnotherSnippets = useCallback(
    (id: string) => {
      handleClickDropdown(anotherSnippetsTeams, setAnotherSnippetsTeams, id);
      dispatch({ field: 'anotherTeamsSnippets', value: anotherSnippetsTeams.find(team => team.id === id) });
    },
    [anotherSnippetsTeams],
  );

  const { name, admins, anotherTeamsSuggestions, anotherTeamsTerms, anotherTeamsSnippets } = state;

  const teamsListWithoutCurrent = teamsList.filter(t => t.id !== teamId);

  let teamNameError;
  const teamAdminsError = admins.length === 0 ? 'required field' : undefined;

  if (name.length === 0) {
    teamNameError = 'required field';
  }

  if (name.length > 35) {
    teamNameError = '35 character limit';
  }

  if (teamsListWithoutCurrent.some(team => team.name === name)) {
    teamNameError = 'team name already in use';
  }

  const onUpdateButtonClick = () => {
    onSubmit(teamId, state);
    changeModalState();
  };

  useEffect(() => {
    Object.keys(initState).map(i => dispatch({ field: i, value: values[i] }));

    const _anotherTeamSuggestionId = values.anotherTeamsSuggestions?.id || teamId;
    const _anotherTeamTermsId = String(values.anotherTeamsTerms?.id || teamId);
    const _anotherTeamSnippetsId = String(values.anotherTeamsSnippets?.id || teamId);

    const _defaultOption = {
      id: `${teamId}`,
      name: 'Use my own',
      active: false,
    };

    const _suggestionsTeamsList: DropdownOption[] = [
      _defaultOption,
      ...teamDropdownOptionsMapper(
        [
          ...teamsListWithoutCurrent.filter(t =>
            t.suggestionsManagedBy ? t.suggestionsManagedBy.id === `${t.id}` : true,
          ),
        ],
        +_anotherTeamSuggestionId,
      ),
    ];

    const _termsTeamsList: DropdownOption[] = [
      _defaultOption,
      ...teamDropdownOptionsMapper(
        [...teamsListWithoutCurrent.filter(t => (t.termsManagedBy ? t.termsManagedBy.id === `${t.id}` : true))],
        +_anotherTeamTermsId,
      ),
    ];

    const _snippetsTeamsList: DropdownOption[] = [
      _defaultOption,
      ...teamDropdownOptionsMapper(
        [...teamsListWithoutCurrent.filter(t => (t.snippetsManagedBy ? t.snippetsManagedBy.id === `${t.id}` : true))],
        +_anotherTeamSnippetsId,
      ),
    ];

    if (!values?.anotherTeamsSuggestions) {
      dispatch({ field: 'anotherTeamsSuggestions', value: _defaultOption });
    }

    if (!values?.anotherTeamsTerms) {
      dispatch({ field: 'anotherTeamsTerms', value: _defaultOption });
    }

    if (!values?.anotherTeamsSnippets) {
      dispatch({ field: 'anotherTeamsSnippets', value: _defaultOption });
    }

    setAnotherSuggestionsTeams(_suggestionsTeamsList);
    setAnotherTermsTeams(_termsTeamsList);
    setAnotherSnippetsTeams(_snippetsTeamsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsList, values]);

  return (
    <Modal open={isOpen} handleClose={changeModalState} title="Edit team details" style={{ width: '410px' }} {...props}>
      <form className={styles.styledForm}>
        <InputGroup
          value={name}
          name="name"
          inputType={InputTypes.INPUT}
          handleChangeInput={onChangeInput}
          errorText={teamNameError}
          id="name"
          label="Team name"
        />
        <InputGroup
          className={styles.styledInputGroup}
          name="admins"
          inputType={InputTypes.AUTOCOMPLETE}
          handleChangeInput={onChangeAdmins}
          onInputChange={onInputChangeAdmins}
          autocompleteOptions={adminOptions}
          defaultValue={admins}
          prefilledTagsTooltip="Org admins cannot be removed from teams"
          errorText={teamAdminsError}
          id="admins"
          label="Team admins"
          style={{ minHeight: '70px' }}
        />
        {teamsList.length > 1 && (
          <Accordion title="Advanced">
            <div className={styles.advancedContent}>
              <Text variant={TextSize.S} style={{ width: '280px', marginBottom: '15px' }}>
                You can use another team’s Suggestions or Terms instead of your own. If you do, you’ll need to be a team
                admin on that team to make changes.
              </Text>
              <InputGroup
                className={styles.styledInputGroup}
                value={anotherTeamsSuggestions?.name || 'Select'}
                style={{ marginLeft: '18px', width: '230px' }}
                name="anotherTeamsSuggestions"
                inputType={InputTypes.SELECT}
                dropdownOptions={anotherSuggestionsTeams}
                id="anotherTeamsSuggestions"
                label={
                  <div className={styles.labelWithIcon}>
                    <SuggestionsIcon />
                    USE ANOTHER TEAM’S SUGGESTIONS
                  </div>
                }
                handleChangeInput={onChangeAnotherSuggestions}
              />
              <InputGroup
                className={styles.styledInputGroup}
                value={anotherTeamsTerms?.name || 'Select'}
                style={{ marginLeft: '18px', width: '230px' }}
                name="anotherTeamsTerms"
                inputType={InputTypes.SELECT}
                dropdownOptions={anotherTermsTeams}
                id="anotherTeamsTerms"
                label={
                  <div className={styles.labelWithIcon}>
                    <TermsIcon />
                    USE ANOTHER TEAM’S TERMS
                  </div>
                }
                handleChangeInput={onChangeAnotherTerms}
              />
              <InputGroup
                className={styles.styledInputGroup}
                value={anotherTeamsSnippets?.name || 'Select'}
                style={{ marginLeft: '18px', width: '230px' }}
                name="anotherTeamsSnippets"
                inputType={InputTypes.SELECT}
                dropdownOptions={anotherSnippetsTeams}
                id="anotherTeamsSnippets"
                label={
                  <div className={styles.labelWithIcon}>
                    <Icon name={IconVariant.SNIPPETS_UNFILLED} />
                    USE ANOTHER TEAM’S SNIPPETS
                  </div>
                }
                handleChangeInput={onChangeAnotherSnippets}
              />
            </div>
          </Accordion>
        )}
        <Button
          className={styles.submitButton}
          type={ButtonTypes.PRIMARY}
          onClick={onUpdateButtonClick}
          disabled={!!teamAdminsError || !!teamNameError}
          content="Update"
        />
      </form>
    </Modal>
  );
};

export default EditTeamModal;
