import { wordPluralize } from '@writercolab/common-utils';

import { IMfaChallenge, VerdictStatusCode } from '@web/types';
import first from 'lodash/first';

import { BackendErrorCodeKey } from '../constants/BackendErrorCodeKey';

/**
 * Partial interface for MFA Backend Extras.
 *
 * @interface
 * @property {Object} extras - The extras object.
 * @property {IMfaChallenge[]} extras.challenges - An array of MFA challenges.
 */
interface IMfaBackendExtras {
  extras: {
    challenges: IMfaChallenge[];
  };
}

/**
 * Partial interface for MFA Backend Error.
 *
 * @interface
 * @property {BackendErrorCodeKey} key - The error code key.
 */
interface IMfaBackendError {
  key: BackendErrorCodeKey;
}

/**
 * Checks if the MFA code has expired.
 *
 * @param {string} statusCode - The status code to check.
 * @returns {boolean} True if the code has expired, false otherwise.
 */
const isMfaCodeExpired = (statusCode: VerdictStatusCode): boolean => statusCode === VerdictStatusCode.CODE_EXPIRED;

/**
 * Checks if the MFA code challenge has expired.
 *
 * @param {string} statusCode - The status code to check.
 * @returns {boolean} True if the code has expired, false otherwise.
 */
const isMfaCodeChallengeExpired = (statusCode: VerdictStatusCode): boolean =>
  statusCode === VerdictStatusCode.CODE_CHALLENGE_EXPIRED;

/**
 * Checks if the MFA challenge is aborted.
 *
 * @param {string} statusCode - The status code to check.
 * @returns {boolean} True if the code is incorrect, false otherwise.
 */
const isMfaAborted = (statusCode: VerdictStatusCode): boolean => statusCode === VerdictStatusCode.ABORTED;

/**
 * Checks if MFA (Multi-Factor Authentication) is required based on the given API error.
 *
 * @param apiError - The API error object.
 * @returns True if MFA is required, false otherwise.
 */
export const isMfaRequired = (apiError: any): boolean =>
  apiError?.response?.data?.errors &&
  first<IMfaBackendError>(apiError?.response?.data?.errors)?.key === BackendErrorCodeKey.MFA_REQUIRED;

/**
 * Extracts MFA challenges from the given API error.
 *
 * @param apiError - The API error object.
 * @returns An array of MFA challenges if MFA is required, an empty array otherwise.
 */
export const extractMfaChallenges = (apiError: any): IMfaChallenge[] =>
  isMfaRequired(apiError) ? first<IMfaBackendExtras>(apiError?.response?.data?.errors)?.extras?.challenges ?? [] : [];

/**
 * This function checks if a multi-factor authentication attempt can be retried based on the number of retries that have occurred.
 *
 * @param retriesCount - The number of retries that have occurred.
 * @param limit - The number of retries allowed.
 * @returns A boolean indicating if the MFA attempt can be retried.
 */
export const isMfaAttemptRetryable = (retriesCount: number, limit: number): boolean => retriesCount >= limit;

/**
 * This function generates a countdown message indicating the remaining time to request a new code.
 *
 * @param remainingSeconds - The number of seconds remaining before a new code can be requested.
 * @returns A string message indicating the time remaining to request a new code.
 */
export const resendCountDownMessage = (remainingSeconds: number): string | undefined =>
  remainingSeconds > 0
    ? `You can request a new code in ${remainingSeconds} ${wordPluralize(remainingSeconds, 'second')}`
    : undefined;

/**
 * Checks if the given verdict status code indicates an expired MFA code error.
 *
 * @param {VerdictStatusCode} verdictStatusCode - The verdict status code to check.
 * @returns {boolean} True if the status code indicates an expired MFA code, false otherwise.
 */
export const isMfaCodeExpiredError = (verdictStatusCode: VerdictStatusCode): boolean =>
  isMfaCodeExpired(verdictStatusCode) ||
  isMfaCodeChallengeExpired(verdictStatusCode) ||
  isMfaAborted(verdictStatusCode);
