import type { DateTime, components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type TPaginatedAdminAuditLog =
  components['schemas']['model_PaginatedResult_reporting_dto_AdminAuditLogResponse'];
export type TAdminAuditLogResponse = components['schemas']['reporting_dto_AdminAuditLogResponse'];
export type TAdminAuditLogProductAreaResponse = components['schemas']['reporting_dto_ProductAreaResponse'];

export const AdminAuditLogSortField = new Enum('createdAt');
export const AdminAuditLogArea = new Enum(
  'SignIn',
  'Saml',
  'ServiceAccount',
  'OAuthApp',
  'Security',
  'BillingGroup',
  'Team',
  'StyleGuide',
  'PeopleTeammate',
  'Suggestion',
  'Term',
  'Snippet',
  'Voice',
  'TeamPrompt',
  'KnowledgeGraph',
  'MagicLink',
  'TeamMate',
);
export const AdminAuditLogResult = new Enum('Success', 'Failure');
export const AdminAuditLogSortOrder = new Enum('asc', 'desc');

export type TAdminAuditLogSortField = typeof AdminAuditLogSortField.type;
export type TAdminAuditLogSortOrder = typeof AdminAuditLogSortOrder.type;
export type TAdminAuditLogArea = typeof AdminAuditLogArea.type;
export type TAdminAuditLogResult = typeof AdminAuditLogResult.type;

export interface TAdminAuditLogQuery extends TPaginatedAdminAuditLogArgs, TPaginatedAdminAuditLogExtraArgs {}

export type TPaginatedAdminAuditLogArgs = {
  limit?: number;
  offset?: number;
};

export type TPaginatedAdminAuditLogExtraArgs = {
  from: DateTime;
  to: DateTime;
  productArea?:
    | null
    | [
        components['schemas']['reporting_model_AdminAuditEvent_ProductArea'],
        ...components['schemas']['reporting_model_AdminAuditEvent_ProductArea'][],
      ];
  result?:
    | null
    | [
        components['schemas']['reporting_model_AdminAuditEvent_Result'],
        ...components['schemas']['reporting_model_AdminAuditEvent_Result'][],
      ];
  userIds?: null | [number, ...number[]];
  search?: null | string;
  sortField?: null | 'createdAt';
  sortOrder?: null | 'asc' | 'desc';
};
