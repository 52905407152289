import React from 'react';
import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { IssueCategory } from '@writercolab/common-utils';
import styles from './styles.module.css';

interface IClaimCardProps {
  type: IssueCategory;
  onResolveClick: () => void;
  onDeclineClick: () => void;
}

export const ClaimCard: React.FC<IClaimCardProps> = ({ type, onResolveClick, onDeclineClick }) => (
  <div className={styles.container}>
    <div className={styles.containerHeader}>
      <div>
        <Text variant={TextSize.XL} bold>
          {type === IssueCategory.Quote ? 'Verify quote' : 'Claim detected'}
        </Text>
      </div>
      <div className={styles.containerAction}>
        <Tooltip
          title={
            <div className={styles.tooltipTitleContent}>
              <Text variant={TextSize.S} bold color={TextColor.WHITE}>
                Help Writer improve
              </Text>
              <Text variant={TextSize.S} color={TextColor.WHITE}>
                Flag if we highlighted this
                <br /> as a {type === IssueCategory.Quote ? 'quote' : 'stat'} but it isn’t.
              </Text>
            </div>
          }
          placement="top"
        >
          <Button
            round
            onClick={onDeclineClick}
            type={ButtonTypes.GRAY}
            size={SizeTypes.XXS}
            icon={<Icon name={IconVariant.OUTLINED_FLAG} width={15} height={15} />}
            className={styles.actionButton}
          />
        </Tooltip>
        <Tooltip
          title={
            <Text bold color={TextColor.WHITE} variant={TextSize.S}>
              Mark if you've <br /> verified this claim
            </Text>
          }
          placement="top"
        >
          <Button
            content={
              <Text variant={TextSize.S} medium>
                Verified
              </Text>
            }
            onClick={onResolveClick}
            type={ButtonTypes.GRAY}
            size={SizeTypes.XXS}
            icon={<Icon name={IconVariant.CHECK} width={15} height={15} />}
            className={styles.resolvedButton}
          />
        </Tooltip>
      </div>
    </div>
    <div className={styles.containerBody}>
      <Text variant={TextSize.L} medium>
        {type === IssueCategory.Quote ? 'This looks like a quote.' : 'This looks like a fact or statistic.'}
      </Text>
      <Text variant={TextSize.L}>Verify it before you publish this content.</Text>
      <Text variant={TextSize.L}>Make sure to include citations if applicable.</Text>
    </div>
  </div>
);
