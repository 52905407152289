import React, { useMemo } from 'react';

import cx from 'classnames';

import { BillingCreditCardBrand } from '@writercolab/common-utils';
import { Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import { formatLast4 } from '../../../../utils/billingUtils';

import styles from './styles.module.css';

interface CreditCardHolderProps extends BaseComponentsProps {
  onEditClick?: () => void;
  lastDigits: string;
  withTitle?: boolean;
  brandName?: BillingCreditCardBrand;
}

export const CreditCardHolder: React.FC<CreditCardHolderProps> = ({
  className,
  lastDigits,
  onEditClick,
  brandName,
  withTitle = true,
}) => {
  const brandIconName = useMemo<IconVariant>(() => {
    let icon;

    switch (brandName) {
      case BillingCreditCardBrand.VISA:
        icon = IconVariant.CREDIT_CARD_VISA;
        break;
      case BillingCreditCardBrand.AMERICAN_EXPRESS:
        icon = IconVariant.CREDIT_CARD_AMERICAN_EXPRESS;
        break;
      case BillingCreditCardBrand.DINERS_CLUB:
        icon = IconVariant.CREDIT_CARD_DINERS_CLUB;
        break;
      case BillingCreditCardBrand.DISCOVER:
        icon = IconVariant.CREDIT_CARD_DISCOVER;
        break;
      case BillingCreditCardBrand.JCB:
        icon = IconVariant.CREDIT_CARD_JCB;
        break;
      case BillingCreditCardBrand.MASTERCARD:
        icon = IconVariant.CREDIT_CARD_MASTERCARD;
        break;
      case BillingCreditCardBrand.UNION_PAY:
        icon = IconVariant.CREDIT_CARD_UNIONPAY;
        break;
      default:
        return IconVariant.CREDIT_CARD_UNKNOWN;
    }

    return icon;
  }, [brandName]);

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.preload}>
        <Icon name={IconVariant.CREDIT_CARD_UNKNOWN} />
        <Icon name={IconVariant.CREDIT_CARD_UNIONPAY} />
        <Icon name={IconVariant.CREDIT_CARD_AMERICAN_EXPRESS} />
        <Icon name={IconVariant.CREDIT_CARD_VISA} />
        <Icon name={IconVariant.CREDIT_CARD_DINERS_CLUB} />
        <Icon name={IconVariant.CREDIT_CARD_DISCOVER} />
        <Icon name={IconVariant.CREDIT_CARD_JCB} />
        <Icon name={IconVariant.CREDIT_CARD_MASTERCARD} />
        <Icon name={IconVariant.CREDIT_CARD_UNKNOWN} />
      </div>
      <div className={styles.containerCreditCard}>
        {withTitle && (
          <div className={styles.containerCreditCardDescription}>
            <Text smallCaps variant={TextSize.XS}>
              Credit or debit card
            </Text>
          </div>
        )}
      </div>
      <div className={styles.containerPaymentMethodInput}>
        <div className={styles.containerCreditCardNumber}>
          <Text variant={TextSize.M}>{formatLast4(lastDigits)}</Text>
          {brandIconName && <Icon name={brandIconName} />}
        </div>
        <div className={styles.containerEditCreditCard}>
          {onEditClick && (
            <div className={cx(styles.editButtonContainer)} onClick={onEditClick}>
              <Text variant={TextSize.M}>Edit</Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditCardHolder;
