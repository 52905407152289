import type React from 'react';
import cx from 'classnames';

import { Logo, LogoVariant, DotLoader } from '@writercolab/ui-atoms';
import styles from './styles.module.css';

interface ILoadingPageProps {
  disableFullScreen?: boolean;
}

export const LoadingPage: React.FC<ILoadingPageProps> = ({ disableFullScreen }) => {
  const elClass = cx(styles.container, { [styles.disableFullScreen]: disableFullScreen });

  return (
    <div className={elClass}>
      <div className={styles.pleaseWaitContainer}>
        <Logo variant={LogoVariant.BLACK} className={styles.logo} />
        <DotLoader className={styles.dotLoader} />
      </div>
    </div>
  );
};

export default LoadingPage;
