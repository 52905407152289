import type { ReactNode } from 'react';
import type React from 'react';

import cx from 'classnames';

import { SimpleTooltip } from '@writercolab/fe.wds';

import { X } from 'lucide-react';

import EllipsisTooltip from '../EllipsisTooltip/EllipsisTooltip';

import styles from './AppShortcutButton.module.css';

export interface IAppShortcutButtonProps {
  className?: string;
  contentClassName?: string;
  disabled?: boolean;
  iconClassName?: string;
  closeIconClassName?: string;
  icon: ReactNode;
  text: string;
  tooltipText?: ReactNode;
  canClose?: boolean;
  disableInteraction?: boolean;
  onClick: () => void;
  onClose?: () => void;
}

export const AppShortcutButton: React.FC<IAppShortcutButtonProps> = ({
  className,
  contentClassName,
  iconClassName,
  closeIconClassName,
  disabled,
  disableInteraction,
  icon,
  text,
  tooltipText,
  canClose,
  onClick,
  onClose,
}) => {
  const content = (
    <div
      className={cx(styles.content, { [styles.disabled]: disabled }, contentClassName)}
      onClick={disabled ? undefined : onClick}
    >
      <div className={cx(styles.icon, iconClassName)}>{icon}</div>
      <EllipsisTooltip text={text} delay={500} disabled={disableInteraction} />
    </div>
  );

  const withTooltip = tooltipText && disabled;

  return (
    <div className={cx(styles.container, { [styles.disabled]: disabled }, className)}>
      {withTooltip ? (
        <SimpleTooltip trigger={content} placement="bottom" delay={500} disabled={disableInteraction}>
          <div className={cx(styles.tooltip, 'text-base')}>{tooltipText}</div>
        </SimpleTooltip>
      ) : (
        <div className="text-base">{content}</div>
      )}
      {canClose && (
        <div className={cx(styles.closeIcon, closeIconClassName)} onClick={onClose}>
          <X size={10} />
        </div>
      )}
    </div>
  );
};
