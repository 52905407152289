import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextColor } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IDocumentsEmptyListPlaceholderProps extends BaseComponentsProps {
  onClickOpenLearningCenter: () => void;
  onClickOpenTemplate: () => void;
  onClickNewDoc?: () => void;
  showLearningCenterLink?: boolean;
  viewType?: DocumentsEmptyListViewType;
}

export enum DocumentsEmptyListViewType {
  COLLAPSED = 'collapsed',
  EXPANDED = 'expanded',
}

const MiniIcon: React.FC<{ className: string; iconName: IconVariant; width?: number; height?: number }> = ({
  className,
  iconName,
  width = 18,
  height = 18,
}) => (
  <Icon name={iconName} className={cx(className, styles.noDashboardDocsTextItemIcon)} width={width} height={height} />
);

const DocumentsEmptyListPlaceholder: React.FC<IDocumentsEmptyListPlaceholderProps> = ({
  className,
  onClickOpenTemplate,
  onClickOpenLearningCenter,
  showLearningCenterLink,
  onClickNewDoc,
  viewType = DocumentsEmptyListViewType.EXPANDED,
}) => {
  const content = () => {
    if (viewType === DocumentsEmptyListViewType.COLLAPSED) {
      return (
        <div className={cx(styles.noDashboardDocsTextWrapper, styles.noDashboardDocsContainerShort)}>
          <div className={styles.noDashboardDocsText}>
            <Text color={TextColor.GREY} className={styles.noDashboardDocsTextItem} medium>
              You haven’t started any docs yet!
            </Text>
          </div>
          <div className={styles.noDashboardDocsText}>
            <MiniIcon
              className={styles.noDashboardDocsTextItemIconBlue}
              iconName={IconVariant.NEW_DOC}
              width={12}
              height={12}
            />
            <Text color={TextColor.GREY} className={styles.noDashboardDocsTextItem} medium>
              <span onClick={onClickNewDoc} className={styles.clickable}>
                Start a new doc
              </span>{' '}
              to get going.
            </Text>
          </div>
        </div>
      );
    }

    return (
      <>
        <>
          <div className={cx(styles.noDashboardDocsContainerArrow, styles.noDashboardDocsContainerTopArrow)}>
            <Icon name={IconVariant.LONG_ARROW_UP} />
          </div>
          <div className={styles.noDashboardDocsTextWrapper}>
            <div className={styles.noDashboardDocsText}>
              <Text color={TextColor.GREY} className={styles.noDashboardDocsTextItem} medium>
                Start with
              </Text>
              <MiniIcon className={styles.noDashboardDocsTextItemIconBlue} iconName={IconVariant.ALL_SETTINGS} />
              <Text color={TextColor.GREY} className={styles.noDashboardDocsTextItem} medium>
                <span onClick={onClickOpenTemplate} className={styles.clickable}>
                  an app
                </span>{' '}
                above
              </Text>
            </div>
            {showLearningCenterLink && (
              <div className={styles.noDashboardDocsText}>
                <Text color={TextColor.GREY} className={styles.noDashboardDocsTextItem} medium>
                  or check out the
                </Text>
                <MiniIcon className={styles.noDashboardDocsTextItemIconBlue} iconName={IconVariant.LIGHT_BULB} />
                <Text color={TextColor.GREY} className={styles.noDashboardDocsTextItem} medium>
                  <span onClick={onClickOpenLearningCenter} className={styles.clickable}>
                    learning center
                  </span>
                </Text>
              </div>
            )}
          </div>
        </>
        {showLearningCenterLink && (
          <div className={cx(styles.noDashboardDocsContainerArrow, styles.noDashboardDocsContainerBottomArrow)}>
            <Icon name={IconVariant.LONG_ARROW_DOWN_LEFT} />
          </div>
        )}
      </>
    );
  };

  return <div className={cx(className, styles.container, styles.noDashboardDocsContainer)}>{content()}</div>;
};

export default DocumentsEmptyListPlaceholder;
