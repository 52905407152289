import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import qs from 'qs';

import type { ITerm, ITermMistake, TermType } from '@writercolab/common-utils';
import {
  LocalStorageKey,
  LocalStorageService,
  SortFieldTypes,
  SortOrders,
  TermsSortingTypes,
} from '@writercolab/common-utils';
import { DropdownPlacement, Icon, IconVariant, TagColor, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import type { ITableColumnItem, RenderAsTextProps } from '@writercolab/ui-molecules';
import {
  BaseTable,
  DropdownHeader,
  EducationalBanner,
  EducationalBannerLocation,
  FilterHeader,
  TextHeader,
  renderAsTag,
  renderAsText,
  renderTermType,
} from '@writercolab/ui-molecules';

import { AnalyticsActivity } from 'constants/analytics';
import { useLocation } from 'react-router';

import { TermsAccessEnum, TermsClipMode, TermsFilterKey, useTermsContext } from '../../../../context/termsContext';
import { useAppState } from '../../../../state';
import { TActionType } from '../../../../state/types';
import { generateUserTimeStamp } from '../../termsAndSnippetsShared/common';
import { ZeroState } from '../../termsAndSnippetsShared/commonComponents';

import styles from '../../termsAndSnippetsShared/TermsTable.module.css';

const MAX_TERMS_AMOUNT_FOR_EDUCATIONAL_MODULE = 20;

interface ITermsTable extends BaseComponentsProps {
  terms: ITerm[];
}

const commaSeparatedCommonMistakes = (commonMistakes: ITermMistake[], key: string) =>
  commonMistakes.map((t, i, arr) => {
    const _appendOptionalComma = i !== arr.length - 1 ? ',' : '';

    return renderAsText(`${t.mistake}${_appendOptionalComma}`, {
      className: cx(styles.commonMistakesCell),
      size: TextSize.L,
      key: `${key}-${t.id}`,
    });
  });

const TermHeader = ({ onPrimaryOptionClickAction, sortField, sortOrder }) => (
  <DropdownHeader
    triggerText={<TextHeader headerText="Term" />}
    options={[
      {
        name: 'Sort A > Z',
        id: TermsSortingTypes.TITLE_ASC,
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active: sortField === SortFieldTypes.TERM && sortOrder === SortOrders.ASC,
      },
      {
        name: 'Sort Z > A',
        id: TermsSortingTypes.TITLE_DESC,
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active: sortField === SortFieldTypes.TERM && sortOrder === SortOrders.DESC,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === SortFieldTypes.TERM}
    sortDirection={sortOrder}
  />
);

const TypeHeader = ({ onPrimaryOptionClickAction, sortField, sortOrder }) => (
  <DropdownHeader
    triggerText={<TextHeader headerText="Type" />}
    options={[
      {
        name: 'Sort A > Z',
        id: TermsSortingTypes.TYPE_ASC,
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active: sortField === SortFieldTypes.TYPE && sortOrder === SortOrders.ASC,
      },
      {
        name: 'Sort Z > A',
        id: TermsSortingTypes.TYPE_DESC,
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active: sortField === SortFieldTypes.TYPE && sortOrder === SortOrders.DESC,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === SortFieldTypes.TYPE}
    sortDirection={sortOrder}
  />
);

const ModificationTimeHeader = ({ onPrimaryOptionClickAction, sortField, sortOrder }) => (
  <DropdownHeader
    triggerText={<TextHeader headerText="Edited" />}
    options={[
      {
        name: 'Sort Newest > Oldest',
        id: TermsSortingTypes.MODIFICATION_DATE_ASC,
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active: sortField === SortFieldTypes.MODIFICATION_TIME && sortOrder === SortOrders.DESC,
      },
      {
        name: 'Sort Oldest > Newest',
        id: TermsSortingTypes.MODIFICATION_DATE_DESC,
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active: sortField === SortFieldTypes.MODIFICATION_TIME && sortOrder === SortOrders.ASC,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === SortFieldTypes.MODIFICATION_TIME}
    sortDirection={sortOrder}
    dropdownPlacement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

export const TermsTable: React.FC<ITermsTable> = ({ terms }) => {
  const { appState, appModel, dispatchAppState } = useAppState();
  const location = useLocation();
  const { entityId } = qs.parse(location.search.slice(1));
  const [educationalBannerVisible, setEducationalBannerVisible] = useState(false);
  const {
    termsContext,
    handleSortingChange,
    handleFilterChange,
    handleOpenEditTerm,
    handleMultiSelect,
    handleOpenNewTermPopup,
    handleOpenImportTermsPopup,
  } = useTermsContext();
  const { isLoading, isTermsLoaded, debouncedSearchTerm, sortField, sortOrder, isAddTermPopupOpen, filters } =
    termsContext;
  const access = {
    disableOperations: termsContext.termsAccess !== TermsAccessEnum.ADMIN,
  };

  const { analyticsService } = appModel;

  const tagsFilters = filters[TermsFilterKey.TAGS];

  const columns: ITableColumnItem[] = useMemo(
    () => [
      {
        Header: (
          <TermHeader onPrimaryOptionClickAction={handleSortingChange} sortField={sortField} sortOrder={sortOrder} />
        ),
        className: cx(styles.termHeader),
        accessor: 'term',
        minWidth: 200,
        Cell: ({ cell: { value } }) =>
          renderAsText(value, { className: cx(styles.termCell, styles.clipModeCell), size: TextSize.L }),
      },
      {
        Header: (
          <TypeHeader onPrimaryOptionClickAction={handleSortingChange} sortField={sortField} sortOrder={sortOrder} />
        ),
        className: cx(styles.termHeaderType, styles.termHeaderNowrap),
        accessor: 'type',
        disableMatchFound: true,
        Cell: ({ cell: { value } }) => renderTermType(value as TermType),
      },
      {
        Header: <TextHeader headerText="Part of speech" />,
        className: cx(styles.termHeaderPartOfSpeech, styles.termHeaderNowrap),
        accessor: 'pos',
        Cell: ({ cell: { value } }) => (value ? renderAsText(value, { size: TextSize.L }) : null),
      },
      {
        Header: <TextHeader headerText="Description" />,
        accessor: 'description',
        className: cx(styles.termHeaderDescription, styles.termHeaderNowrap),
        Cell: ({ cell: { value } }) =>
          value
            ? renderAsText(value, { className: cx(styles.descriptionCell, styles.clipModeCell), size: TextSize.L })
            : null,
      },
      {
        Header: <TextHeader headerText="Common mistakes" />,
        accessor: 'mistakes',
        className: cx(styles.termHeaderMistakes, styles.termHeaderNowrap),
        cellClassName: cx(styles.clipModeCell),
        Cell({ row, cell: { value } }) {
          return value && value.length > 0 ? commaSeparatedCommonMistakes(value, `${row.original.id}-${row.id}`) : null;
        },
      },
      {
        Header: tagsFilters.length > 0 && (
          <FilterHeader
            header={
              <Text variant={TextSize.M} color={TextColor.GREY2}>
                Tags
              </Text>
            }
            title={
              <Text variant={TextSize.XXS} smallCaps>
                Filter by {TermsFilterKey.TAGS}
              </Text>
            }
            options={tagsFilters}
            onChange={updatedFilters => handleFilterChange(TermsFilterKey.TAGS, updatedFilters)}
          />
        ),
        accessor: 'tags',
        className: cx(styles.termHeaderTags),
        cellClassName: cx(styles.clipModeCell),
        Cell({ row, cell: { value } }) {
          return value && value.length > 0 ? (
            <div className={styles.tagsCellContainer}>
              {value.map(t => renderAsTag(t.tag, TagColor.LIGHT_BLUE, `${row.original.id}-${row.id}-tag-${t.id}`))}
            </div>
          ) : null;
        },
      },
      {
        Header: (
          <ModificationTimeHeader
            onPrimaryOptionClickAction={handleSortingChange}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        ),
        colSpan: 2,
        accessor: 'modificationTime',
        className: cx(styles.termModificationHeader, styles.termHeaderNowrap),
        disableMatchFound: true,
        Cell(e) {
          const cellProps: RenderAsTextProps = {
            className: cx(styles.termModificationInfo),
          };

          if (e.row?.original?.modifiedUser?.fullName) {
            cellProps.tooltipText = e.row.original.modifiedUser.fullName;
          }

          return renderAsText(e.value, cellProps);
        },
      },
    ],
    [handleSortingChange, sortField, sortOrder, tagsFilters, handleFilterChange],
  );

  const emptyTableContent = useMemo(
    () =>
      debouncedSearchTerm.length ? (
        <span>No search results</span>
      ) : (
        <ZeroState onClick={handleOpenNewTermPopup} disableOperations={access.disableOperations} />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm, access.disableOperations],
  );

  const tableData = React.useMemo(
    () =>
      terms.map(term => {
        const _updatedTimeStamp = generateUserTimeStamp(term.modifiedUser, term.createdUser, term.modificationTime);

        return { ...term, modificationTime: _updatedTimeStamp };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [terms],
  );

  const onRowClick = useCallback((term: ITerm) => handleOpenEditTerm(term.id), [handleOpenEditTerm]);

  const onEducationalBannerClose = () => {
    const educationalTermsModuleVisible = LocalStorageService.getItem<string[] | null>(
      LocalStorageKey.educationalTermsModuleVisible,
      [],
    );

    if (!educationalTermsModuleVisible?.includes(`${appState.organizationId}${appState.userProfile?.id}`)) {
      educationalTermsModuleVisible?.push(`${appState.organizationId}${appState.userProfile?.id}`);
      LocalStorageService.setItem(LocalStorageKey.educationalTermsModuleVisible, educationalTermsModuleVisible);
    }

    setEducationalBannerVisible(false);
    dispatchAppState({ type: TActionType.SetEducationalTermsState, payload: false });
  };

  useEffect(() => {
    if (isLoading || !isTermsLoaded) {
      setEducationalBannerVisible(false);
    } else if (tableData.length >= 0) {
      if (tableData.length >= MAX_TERMS_AMOUNT_FOR_EDUCATIONAL_MODULE) {
        setEducationalBannerVisible(false);
        dispatchAppState({ type: TActionType.SetEducationalTermsState, payload: false });
      } else {
        setEducationalBannerVisible(appState.isEducationalTermsWindowVisible);
      }
    }
  }, [tableData, appState.isEducationalTermsWindowVisible, isLoading, isTermsLoaded, dispatchAppState]);

  const _handleOpenNewTermPopup = () => {
    analyticsService.track(AnalyticsActivity.educationalModulesTermsClickedAddTerm, {});
    handleOpenNewTermPopup();
  };

  const _handleOpenTermImportPopup = () => {
    analyticsService.track(AnalyticsActivity.educationalModulesTermsClickedImportTerms, {});
    handleOpenImportTermsPopup();
  };

  const _handleOpenPopup = () =>
    analyticsService.track(AnalyticsActivity.educationalModulesTermsClickedQuickStartVideo, {});

  return (
    <div className={cx(styles.container)}>
      <BaseTable
        tableData={tableData}
        tableColumns={columns}
        className={cx(styles.termTable, {
          [styles.wrapTextEnabled]: termsContext.clipMode === TermsClipMode.WRAP_TEXT,
        })}
        containerClassName={styles.tableMainContainer}
        emptyTableContent={emptyTableContent}
        isLoading={isLoading || !isTermsLoaded}
        searchTerm={debouncedSearchTerm}
        tableRowClassName={cx(styles.termTableRow)}
        onRowClick={onRowClick}
        onMultiSelect={handleMultiSelect}
        disableOperations={access.disableOperations}
        multiSelectAllMonitor={isAddTermPopupOpen}
        selectedOriginalId={Number(entityId)}
      >
        {educationalBannerVisible && (
          <div className={styles.bannerContainer}>
            <EducationalBanner
              location={EducationalBannerLocation.TERMS}
              onPrimaryActionClick={_handleOpenNewTermPopup}
              onSecondaryActionClick={_handleOpenPopup}
              onTertiaryActionClick={_handleOpenTermImportPopup}
              onCloseClick={onEducationalBannerClose}
              closeButtonVisible={tableData.length > 0}
              bannerClassName={styles.termsEducationalBanner}
            />
          </div>
        )}
      </BaseTable>
    </div>
  );
};

export default React.memo(TermsTable);
