import React, { useMemo } from 'react';

import { SortOrders } from '@writercolab/common-utils';
import type { ITableFilter } from '@writercolab/types';
import {
  DropdownPlacement,
  Icon,
  IconVariant,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { DropdownHeader, FilterHeader } from '@writercolab/ui-molecules';

import { OAuthAppsModalType } from 'components/pages/OAuthAppsPage/OAuthAppsPageModel.ui';

import { OAuthAppsSortField, OAuthAppsSortOrder, OAuthAppsTypes, TOAuthAppsTypes } from '@web/types';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { BaseTable } from '../BaseTable/BaseTable';
import { OAuthAppsTableUIModel } from './OAuthAppsTableModel.ui';
import { OAuthAppsTableColumnsConfig, generateTableBody } from './utils';

import styles from './OAuthAppsTable.module.css';

const AddedBySortingDropdown = ({
  onPrimaryOptionClickAction,
  sortField,
  sortOrder,
}: {
  onPrimaryOptionClickAction: (id: string | number) => void;
  sortField: string;
  sortOrder: string;
}) => (
  <DropdownHeader
    triggerText={
      <Text color={TextColor.GREY2} variant={TextSize.L}>
        Date added
      </Text>
    }
    options={[
      {
        id: OAuthAppsSortOrder.enum.asc,
        name: 'Sort Ascending',
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active: sortField === OAuthAppsSortField.enum.createdAt && sortOrder === OAuthAppsSortOrder.enum.asc,
      },
      {
        id: OAuthAppsSortOrder.enum.desc,
        name: 'Sort Descending',
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active: sortField === OAuthAppsSortField.enum.createdAt && sortOrder === OAuthAppsSortOrder.enum.desc,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === OAuthAppsSortField.enum.createdAt}
    sortDirection={sortOrder as SortOrders}
    dropdownPlacement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

const ConnectorTypeSortingDropdown = ({
  filterByStatus,
  filterValue,
}: {
  filterByStatus: (filters: ITableFilter[]) => void;
  filterValue?: TOAuthAppsTypes[];
}) => (
  <div className={styles.connectorFilterWrapper}>
    <FilterHeader
      header={
        <Text variant={TextSize.M} color={TextColor.GREY2}>
          Connector
        </Text>
      }
      className={styles.filterDropdown}
      title={
        <Text variant={TextSize.XXS} smallCaps>
          Filter by connector
        </Text>
      }
      options={[
        {
          id: OAuthAppsTypes.enum.gdrive,
          name: 'Google Drive',
          isSelected: !!filterValue?.includes(OAuthAppsTypes.enum.gdrive),
        },
        {
          id: OAuthAppsTypes.enum.sharepoint,
          name: 'SharePoint',
          isSelected: !!filterValue?.includes(OAuthAppsTypes.enum.sharepoint),
        },
        {
          id: OAuthAppsTypes.enum.notion,
          name: 'Notion',
          isSelected: !!filterValue?.includes(OAuthAppsTypes.enum.notion),
        },
      ]}
      onChange={filterByStatus}
    />
  </div>
);

interface IOAuthAppsTableProps {
  isSearchParamsEmpty: boolean;
  model: OAuthAppsTableUIModel;
  sortOrder: string;
  sortField: string;
  types: TOAuthAppsTypes[];
  handleTypeFilterChange: (filter: ITableFilter[]) => void;
  handleSortingChange: (id: string | number) => void;
}
export const OAuthAppsTable = observer(
  ({
    model,
    types,
    sortOrder,
    sortField,

    handleTypeFilterChange,
    handleSortingChange,
  }: IOAuthAppsTableProps) => {
    const tableData = useMemo(
      () =>
        generateTableBody({
          data: model.data,
          columnsConfig: OAuthAppsTableColumnsConfig,
          modalsManager: model.modalsManager,
        }),
      [model.data, model.modalsManager],
    );

    const tableHeaders = [
      {
        component: <ConnectorTypeSortingDropdown filterByStatus={handleTypeFilterChange} filterValue={types} />,
      },
      {
        component: (
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            Application name
          </Text>
        ),
      },
      {
        component: (
          <Text color={TextColor.GREY2} variant={TextSize.L}>
            Created by
          </Text>
        ),
      },
      {
        component: (
          <AddedBySortingDropdown
            onPrimaryOptionClickAction={handleSortingChange}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        ),
      },

      {},
    ];

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading: model.isLoading,
      hasNextPage: model.hasNext,
      onLoadMore: model.loadMore,
      rootMargin: '0px 0px 400px 0px',
    });

    return (
      <div className={styles.tableContainer}>
        <div className={styles.tableScrollableContainer} ref={rootRef}>
          <BaseTable
            headers={tableHeaders}
            data={tableData}
            loading={model.showLoader}
            gridTemplateColumns="300px 250px 155px 125px 50px"
            isEnableSticky={false}
            onRowClick={row => {
              model.modalsManager.showModal(OAuthAppsModalType.enum.viewer, {
                connectorId: row.data.id,
              });
            }}
          />
          {model.hasNext && (
            <div className={styles.loaderWrapper} ref={sentryRef}>
              <SkeletonLoader type={SkeletonLoaderType.ISSUE_CARD} className={styles.loader} />
            </div>
          )}
        </div>
      </div>
    );
  },
);
