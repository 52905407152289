import type React from 'react';

import { noop } from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import {
  Dropdown,
  DropdownPlacement,
  Icon,
  IconButton,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import type { TKnowledgeGraphFilesResponse, TKnowledgeGraphFilesStatus } from '@web/types';
import { KnowledgeGraphFilesError, KnowledgeGraphFilesStatus, KnowledgeGraphFilesViewType } from '@web/types';
import { format } from 'date-fns';

import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import { Spinner } from '../../molecules/Spinner';
import type { IBodyCellProps, IRowProps } from '../BaseTable/BaseTable';
import type { TKnowledgeGraphFilesModalsManager } from './KnowledgeGraphFilesTableModel.ui';
import { KnowledgeGraphFilesAction } from './KnowledgeGraphFilesTableModel.ui';

import styles from './KnowledgeGraphFilesTable.module.css';

export type TKnowledgeGraphFilesTablesConfig = {
  id: typeof TKnowledgeGraphFilesTableColumnId.type;
};

export const TKnowledgeGraphFilesTableColumnId = new Enum('name', 'type', 'status', 'addedBy', 'dateAdded', 'actions');
export const TKnowledgeGraphFilesTableActionId = new Enum('download', 'delete');

export const KnowledgeGraphFilesTableColumnsConfig: TKnowledgeGraphFilesTablesConfig[] = [
  {
    id: TKnowledgeGraphFilesTableColumnId.enum.name,
  },
  {
    id: TKnowledgeGraphFilesTableColumnId.enum.type,
  },
  {
    id: TKnowledgeGraphFilesTableColumnId.enum.status,
  },
  {
    id: TKnowledgeGraphFilesTableColumnId.enum.addedBy,
  },
  {
    id: TKnowledgeGraphFilesTableColumnId.enum.dateAdded,
  },
  {
    id: TKnowledgeGraphFilesTableColumnId.enum.actions,
  },
];

interface IActions {
  downloadFile: (fileId: string, fileName: string) => void;
  viewFileInConnector: (fileId: string) => void;
  retry: (fileIds: [string, ...string[]], graphId: string) => void;
}

interface IRowGenerator {
  data: TKnowledgeGraphFilesResponse[];
  columnsConfig: TKnowledgeGraphFilesTablesConfig[];
  modalsManager: TKnowledgeGraphFilesModalsManager;
  actions: IActions;
  viewType: typeof KnowledgeGraphFilesViewType.type;
  viewOnly: boolean;
  graphId: string;
  connectorName?: string;
}

const CellActions = ({
  error,
  actions,
  fileId,
  modalsManager,
  graphId,
}: {
  error?: string | null;
  actions: IActions;
  fileId: string;
  modalsManager: TKnowledgeGraphFilesModalsManager;
  graphId: string;
}) => (
  <>
    {error !== KnowledgeGraphFilesError.enum.parsing && error !== KnowledgeGraphFilesError.enum.indexing && (
      <IconButton
        onClick={() => modalsManager.showModal(KnowledgeGraphFilesAction.enum.delete, fileId)}
        icon={IconVariant.TRASH}
        iconHeight={18}
        iconWidth={18}
        height={24}
        width={24}
      />
    )}
    {(error === KnowledgeGraphFilesError.enum.parsing || error === KnowledgeGraphFilesError.enum.indexing) && (
      <IconButton
        onClick={() => actions.retry([fileId], graphId)}
        icon={IconVariant.REFRESH}
        iconHeight={18}
        iconWidth={18}
        height={24}
        width={24}
      />
    )}
  </>
);

const FileCell = ({ fileName }: { fileName: string }) => (
  <Tooltip title={fileName} tooltipWidth={280} placement="top" disabled={fileName.length < 40}>
    <div className={styles.fileWrapperCell}>
      <Icon name={IconVariant.DOCUMENT} />
      <Text variant={TextSize.M} ellipsisOverflow color={TextColor.BLACK}>
        {fileName}
      </Text>
    </div>
  </Tooltip>
);

const TextCell = ({ text }: { text?: string | null }) => (
  <div className={styles.textWrapperCell}>
    <Text variant={TextSize.M} ellipsisOverflow color={TextColor.GREY}>
      {text}
    </Text>
  </div>
);

const StatusCell = ({
  status,
  viewType,
  viewOnly,
  error,
  actions,
  fileId,
  modalsManager,
  graphId,
}: {
  status: TKnowledgeGraphFilesStatus;
  viewType: typeof KnowledgeGraphFilesViewType.type;
  viewOnly: boolean;
  error?: string | null;
  actions: IActions;
  fileId: string;
  modalsManager: TKnowledgeGraphFilesModalsManager;
  graphId: string;
}) => {
  const render = KnowledgeGraphFilesViewType.match(
    viewType,
    {
      Upload: () =>
        KnowledgeGraphFilesStatus.match(
          status,
          {
            error: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <div className={styles.redDot} />
                </div>

                <Text variant={TextSize.M} color={TextColor.GREY}>
                  {capitalizeFirstLetter(error)} error
                </Text>

                {!viewOnly && (
                  <CellActions
                    error={error}
                    actions={actions}
                    fileId={fileId}
                    modalsManager={modalsManager}
                    graphId={graphId}
                  />
                )}
              </div>
            ),
            completed: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <Icon name={IconVariant.GREEN1_CHECKMARK} />
                </div>

                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Live in graph
                </Text>
              </div>
            ),
            indexing: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <Spinner
                    progress={75}
                    size={16}
                    circleWidth={3}
                    progressWidth={3}
                    circleColor="#D4D6DB"
                    progressColor="#000000"
                  />
                </div>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Indexing
                </Text>
              </div>
            ),
            parsing: () => (
              <div className={styles.statusWrapperCell}>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Uploading
                </Text>
              </div>
            ),
            queued: () => (
              <div className={styles.statusWrapperCell}>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Queued
                </Text>
              </div>
            ),
            processing: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <Spinner
                    progress={75}
                    size={16}
                    circleWidth={3}
                    progressWidth={3}
                    circleColor="#D4D6DB"
                    progressColor="#000000"
                  />
                </div>

                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Uploading
                </Text>
              </div>
            ),
          },
          null,
        ),
      Connector: () =>
        KnowledgeGraphFilesStatus.match(
          status,
          {
            error: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <div className={styles.redDot} />
                </div>

                <Text variant={TextSize.M} color={TextColor.GREY}>
                  {capitalizeFirstLetter(error)} error
                </Text>

                {!viewOnly && (
                  <CellActions
                    error={error}
                    actions={actions}
                    fileId={fileId}
                    modalsManager={modalsManager}
                    graphId={graphId}
                  />
                )}
              </div>
            ),
            completed: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <Icon name={IconVariant.GREEN1_CHECKMARK} />
                </div>

                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Live in graph
                </Text>
              </div>
            ),
            indexing: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <Spinner
                    progress={75}
                    size={16}
                    circleWidth={3}
                    progressWidth={3}
                    circleColor="#D4D6DB"
                    progressColor="#000000"
                  />
                </div>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Indexing
                </Text>
              </div>
            ),
            parsing: () => (
              <div className={styles.statusWrapperCell}>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Uploading
                </Text>
              </div>
            ),
            queued: () => (
              <div className={styles.statusWrapperCell}>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Queued
                </Text>
              </div>
            ),
            processing: () => (
              <div className={styles.statusWrapperCell}>
                <div className={styles.iconWrapper}>
                  <Spinner
                    progress={75}
                    size={16}
                    circleWidth={3}
                    progressWidth={3}
                    circleColor="#D4D6DB"
                    progressColor="#000000"
                  />
                </div>
                <Text variant={TextSize.M} color={TextColor.GREY}>
                  Syncing
                </Text>
              </div>
            ),
          },
          null,
        ),
    },
    null,
  );

  return <div className={styles.statusWrapperCell}>{render}</div>;
};

const ActionsCell = ({
  modalsManager,
  viewType,
  fileId,
  fileName,
  connectorName,
  actions,
  status,
}: {
  modalsManager: TKnowledgeGraphFilesModalsManager;
  viewType: typeof KnowledgeGraphFilesViewType.type;
  fileId: string;
  fileName: string;
  connectorName?: string;
  actions: IActions;
  status: TKnowledgeGraphFilesStatus;
}) => {
  const options: DropdownOption[] = [];

  KnowledgeGraphFilesViewType.match(
    viewType,
    {
      Upload: () => {
        if (status === KnowledgeGraphFilesStatus.enum.completed) {
          options.push({
            id: TKnowledgeGraphFilesTableActionId.enum.download,
            name: 'Download',
            action: () => actions.downloadFile(fileId, fileName),
          });
        }
      },

      Connector: () => {
        options.push({
          id: TKnowledgeGraphFilesTableActionId.enum.download,
          name: `View file in ${connectorName || 'connector'}`,
          action: () => actions.viewFileInConnector(fileId),
        });
      },
    },
    null,
  );

  options.push({
    id: TKnowledgeGraphFilesTableActionId.enum.delete,
    name: 'Delete',
    action: () => modalsManager.showModal(KnowledgeGraphFilesAction.enum.delete, fileId),
  });

  return (
    <Dropdown
      triggerClassName={styles.actionTrigger}
      trigger={<Icon name={IconVariant.MORE_HORIZ} />}
      itemsType={ItemsTypes.ACTION}
      options={options}
      onPrimaryOptionClickAction={() => {}}
      placement={DropdownPlacement.BOTTOM_RIGHT}
    />
  );
};

export const generateTableBody = ({
  data,
  columnsConfig,
  modalsManager,
  actions,
  viewType,
  viewOnly,
  graphId,
  connectorName,
}: IRowGenerator): IRowProps[] =>
  data?.map(item => {
    const { error, name, status, fileType, createdBy, createdAt, fileId } = item;

    const generateTableCell = (cellType: string, component: React.ReactNode): IBodyCellProps => ({
      id: `cell-${cellType}-${fileId}`,
      component,
    });

    const addedByDisplay = createdBy
      ? `${createdBy?.firstName ?? ''} ${createdBy?.lastName ?? ''}`.trim() || createdBy.email
      : 'API';

    const tableCells = columnsConfig
      .map(column =>
        TKnowledgeGraphFilesTableColumnId.match(
          column.id,
          {
            name: () => generateTableCell(TKnowledgeGraphFilesTableColumnId.enum.name, <FileCell fileName={name} />),
            type: () => generateTableCell(TKnowledgeGraphFilesTableColumnId.enum.type, <TextCell text={fileType} />),
            status: () =>
              generateTableCell(
                TKnowledgeGraphFilesTableColumnId.enum.status,
                <StatusCell
                  status={status}
                  viewType={viewType}
                  viewOnly={viewOnly}
                  error={error}
                  fileId={fileId}
                  actions={actions}
                  modalsManager={modalsManager}
                  graphId={graphId}
                />,
              ),
            addedBy: () =>
              generateTableCell(TKnowledgeGraphFilesTableColumnId.enum.addedBy, <TextCell text={addedByDisplay} />),
            dateAdded: () =>
              generateTableCell(
                TKnowledgeGraphFilesTableColumnId.enum.dateAdded,
                <TextCell text={format(new Date(createdAt), 'MMMM d, yyyy h:mmaaa')} />,
              ),

            actions: () =>
              !viewOnly ? (
                generateTableCell(
                  TKnowledgeGraphFilesTableColumnId.enum.actions,
                  <ActionsCell
                    modalsManager={modalsManager}
                    viewType={viewType}
                    fileId={fileId}
                    fileName={name}
                    connectorName={connectorName}
                    actions={actions}
                    status={status}
                  />,
                )
              ) : (
                <></>
              ),
          },
          null,
        ),
      )
      .filter(Boolean) as IBodyCellProps[];

    const row: IRowProps<any> = {
      id: `row-${fileId}`,
      data: item,
      action: () => noop,
      cells: tableCells,
    };

    return row;
  });
