import cx from 'classnames';

import { ChatVariables, PLUGIN_NAME } from '@writercolab/tiptap-extensions';

import type { Content, EditorOptions } from '@tiptap/core';
import Document from '@tiptap/extension-document';
import History from '@tiptap/extension-history';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import Text from '@tiptap/extension-text';
import type { Slice } from '@tiptap/pm/model';

import styles from './TeamPromptPanel.module.css';

type TBuildEditorStartConfig = {
  initialValue: Content;
  onChange?: (text: string) => void;
  clipboardTextSerializer?: (slice: Slice) => string;
  transformPastedHTML?: (html: string) => string;
};

export const formatClipboardHtmlToVariables = (html: string) =>
  html.replace(
    /<span>(.*?)<\/span>/g,
    (_, text: string) => `<span data-value="${text}" data-type="${PLUGIN_NAME}">${text}</span>`,
  );

export function parseSchemaToString(schema) {
  function parseParagraph(p) {
    let result = '';

    if (p.content) {
      p.content.forEach(el => {
        if (el.type === 'text') {
          result += el.text;
        } else if (el.type === PLUGIN_NAME) {
          result += `{${el.attrs.value}}`;
        }
      });
    }

    return `${result}\n`;
  }

  const result = schema.content.map(parseParagraph).join('');

  return result;
}

export const editorStartConfig = ({
  initialValue,
  onChange,
  clipboardTextSerializer,
  transformPastedHTML,
}: TBuildEditorStartConfig) => {
  return {
    editorProps: {
      attributes: {
        class: cx(styles.editorBase, styles.editor),
      },
      clipboardTextSerializer,
      transformPastedHTML,
    },
    onUpdate: ({ editor }) => {
      onChange?.(parseSchemaToString(editor.getJSON()));
    },
    extensions: [
      Document,
      Text,
      Paragraph,
      History,
      Placeholder.configure({
        placeholder: `Type something`,
      }),
      ChatVariables,
    ],
    content: initialValue,
    injectCSS: true,
  } as Partial<EditorOptions>;
};
