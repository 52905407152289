import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import cx from 'classnames';
import type { IIssue } from '@writercolab/common-utils';
import { IssueFlag, IssueType } from '@writercolab/common-utils';
import { SnippetInlineCard } from '@writercolab/ui-molecules';
import type { components } from '@writercolab/network';
import { useWindowDimensions } from '@web/component-library';

import styles from './styles.module.css';
import InlineSuggestionCard from '../InlineSuggestionCard';

export enum FloatingCardType {
  INLINE_CARD = 'INLINE_CARD',
  SNIPPET = 'SNIPPET',
  NONE = 'NONE',
}

interface IFloatingPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface IFloatingCard {
  issue?: IIssue;
  snippet?: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'];
  element?: HTMLElement;
  cardType: FloatingCardType;
  canWriteToTerms: boolean;
  onSnippetClick: () => void;
  onClaimResolveClick: (issue: IIssue) => void;
  onClaimDeclineClick: (issue: IIssue) => void;

   
  onApplySuggestionCallback: (replacement: string, issue: IIssue) => Promise<any> | undefined;

   
  onFlagSuggestionCallback: (issue: IIssue, flagType: IssueFlag, comment?: string | null) => Promise<any>;
}

const RE_CALCULATE_ISSUE_CONTAINER_POSITION_DELAY = 100;
const APPROXIMATE_ISSUE_CONTAINER_HEIGHT = 200;

const FloatingCard: React.FC<IFloatingCard> = ({
  issue,
  snippet,
  element,
  cardType,
  canWriteToTerms,
  onSnippetClick,
  onClaimResolveClick,
  onClaimDeclineClick,
  onApplySuggestionCallback,
  onFlagSuggestionCallback,
}) => {
  const [issueContainerPosition, setIssueContainerPosition] = useState<IFloatingPosition | undefined>(undefined);
  const [issueContainerHeight, setIssueContainerHeight] = useState(0);
  const windowDimensions = useWindowDimensions();
  const isMultiLineIssue = issue?.issueType === IssueType.READABILITY || issue?.issueType === IssueType.PASSIVE_VOICE;

  const calculatePosition = useCallback(() => {
    if (!element) {
      return;
    }

    const clientRects = element.getClientRects();

    if (!clientRects.length) {
      return;
    }

    const { top, left, width, height } = clientRects[isMultiLineIssue ? clientRects.length - 1 : 0];

    if (!element.parentElement || (left === 0 && top === 0)) {
      setIssueContainerPosition(undefined);

      return;
    }

    const newPosition = {
      left,
      top,
      width,
      height,
    };

    setIssueContainerPosition(newPosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType, element, isMultiLineIssue]);

  const translateX = useMemo<string>(() => `${issueContainerPosition?.left}px`, [issueContainerPosition]);

  const isCardOutOfWindow = useMemo<boolean | null>(() => {
    let outOfWindow: boolean | null = null;

    if (
      issueContainerPosition?.top &&
      issueContainerPosition.top > 0 &&
      issueContainerPosition.height &&
      issueContainerPosition.height > 0
    ) {
      outOfWindow = issueContainerPosition.top + APPROXIMATE_ISSUE_CONTAINER_HEIGHT > windowDimensions.height;
    }

    return outOfWindow;
  }, [issueContainerPosition, windowDimensions]);

  const translateY = useMemo<string>(() => {
    let translateValue = 0;

    if (issueContainerPosition?.height && issueContainerPosition?.top) {
      translateValue = issueContainerPosition.height + issueContainerPosition.top;
    }

    if (
      isCardOutOfWindow !== null &&
      issueContainerHeight &&
      issueContainerHeight > 0 &&
      isCardOutOfWindow &&
      translateValue &&
      issueContainerPosition?.height
    ) {
      translateValue -= issueContainerHeight + issueContainerPosition.height;
    }

    return `${translateValue}px`;
  }, [issueContainerPosition, isCardOutOfWindow, issueContainerHeight]);

  useEffect(() => {
    calculatePosition();
    const interval = setInterval(() => calculatePosition(), RE_CALCULATE_ISSUE_CONTAINER_POSITION_DELAY);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType, element]);

  const onClickInsideCard = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).classList.contains('dropdown-trigger')) {
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }
  };

  const card = useMemo(() => {
    switch (cardType) {
      case FloatingCardType.INLINE_CARD:
        return (
          issue && (
            <InlineSuggestionCard
              issue={issue}
              isInverted={!!isCardOutOfWindow}
              canAddTerms={canWriteToTerms}
              onApplySuggestion={onApplySuggestionCallback}
              onClaimResolveClick={onClaimResolveClick}
              onClaimDeclineClick={onClaimDeclineClick}
              onDeleteIssueClick={() => onFlagSuggestionCallback(issue, IssueFlag.IGNORE)}
              onMarkIssueAsWrong={(_, _cardType, comment) => onFlagSuggestionCallback(issue, IssueFlag.WRONG, comment)}
            />
          )
        );

      case FloatingCardType.SNIPPET:
        return <SnippetInlineCard text={snippet?.snippet as string} onClick={onSnippetClick}></SnippetInlineCard>;

      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType, issue, element, isCardOutOfWindow]);

  if (!issueContainerPosition) {
    return null;
  }

  if (cardType === FloatingCardType.NONE) {
    return null;
  }

  const getBoundingClientRect = (el: HTMLDivElement) => {
    if (!el) {
      return;
    }

    const { height: containerHeight } = el.getBoundingClientRect();
    setIssueContainerHeight(containerHeight);
  };

  const issueContainer = (
    <div
      ref={getBoundingClientRect}
      className={cx(styles.floatingCard, {
        [styles.floatingInlineCard]: cardType === FloatingCardType.INLINE_CARD,
        [styles.floatingSnippet]: cardType === FloatingCardType.SNIPPET,
      })}
      onClick={onClickInsideCard}
      style={{
        transform: `translate(${translateX}, ${translateY})`,
      }}
    >
      {card}
    </div>
  );

  return ReactDOM.createPortal(issueContainer, document.body);
};

export default FloatingCard;
