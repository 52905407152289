import type React from 'react';
import { useEffect } from 'react';

import { LoadingPage } from '@web/component-library';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router';

import { useAppState } from '../../../state';
import { redirectToDefaultRoutes } from '../../../utils/navigationUtils';

interface IRedirectPageProps {}

export const RedirectPage: React.FC<IRedirectPageProps> = observer(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const {
    appState,
    appModel: { teamsModel, featureFlags },
  } = useAppState();

  useEffect(() => {
    if (appState.organization && teamsModel.teams) {
      redirectToDefaultRoutes(
        navigate,
        location.pathname,
        teamsModel.teams,
        appState.organization?.id,
        params,
        featureFlags,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.organization, teamsModel.teams]);

  return <LoadingPage />;
});

export default RedirectPage;
