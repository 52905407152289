import type React from 'react';
import { useEffect, useState } from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { UtilIcon, UtilIconColor } from '@writercolab/ui-molecules';
import { generateRandomString } from '@writercolab/utils';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { IKeyPointsSection, IOutlineSection } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

import { getLogger } from '../../../../utils/logger';
import DynamicItemEmptyState, { DynamicItemEmptyStatePosition } from './DynamicItemEmptyState';
import KeyPointLoader, { KeyPointLoaderType } from './KeyPointLoader';
import KeyPointsSectionInput from './KeyPointsInputs';

import styles from '../styles.module.css';

const LOG = getLogger('BlogKeyPointsForm');

const MAX_KEY_POINTS_PER_SECTION = 10;

interface IBlogHeadlineFormProps {
  keyPointsGenerating: boolean;
  headline: string;
  keyPointsGenerated: IKeyPointsSection[];
  onKeyPointsGenerate: (outline: IOutlineSection[], index?: number) => Promise<IKeyPointsSection[]>;
  outline: IOutlineSection[];
  setKeyPoints: (sections: IKeyPointsSection[]) => void;
}

const HeadLine = ({ headline }) => (
  <div className={styles.containerHeadlineContainer}>
    <Text variant={TextSize.XL} bold className={styles.containerHeadlineTop}>
      Headline
    </Text>
    <Text variant={TextSize.XXXXL} bold>
      {headline}
    </Text>
  </div>
);

const HeadLineDescription = () => (
  <div className={styles.containerHeadlineContainer}>
    <Text variant={TextSize.XL} bold className={styles.containerHeadlineTop}>
      Outline
    </Text>
    <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.containerHeadlineBottom}>
      Add optional key points to your outline.
    </Text>
    <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.containerHeadlineBottom}>
      You can add things like stats, quotes, or specific talking points.
    </Text>
  </div>
);

const HeadlineSection = ({
  id,
  key,
  section,
  onChangeKeyPoint,
  onAddKeyPoint,
  onRemoveKeyPoint,
  onRemoveSection,
  onReGenerateKeyPoint,
  handleKeypointDragEnd,
  addKeyPointAvailable,
  emptyStateDisabled,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const _handleKeypointDragEnd = event => {
    const { active, over } = event;
    const activeIndex = section.keyPoints.findIndex(section => section.id === active.id);
    const overIndex = section.keyPoints.findIndex(section => section.id === over.id);
    handleKeypointDragEnd(event, {
      ...section,
      keyPoints: arrayMove(section.keyPoints, activeIndex, overIndex),
    });
  };

  return (
    <div key={key} className={styles.headlineSectionContainer} ref={setNodeRef} style={style}>
      <div className={cx(styles.headlineSectionTitle, styles.clickable)}>
        <UtilIcon
          variant={IconVariant.DRAG}
          width={20}
          height={20}
          className={cx(styles.headlineSectionContainerAction, styles.clickable)}
          tooltipContent="Hold and drag to reorder"
          color={UtilIconColor.WHITE}
          {...attributes}
          {...listeners}
        />
        <Text variant={TextSize.XL} bold className={styles.headlineSectionContainerTitle}>
          {section.name}
        </Text>
      </div>
      <div className={styles.headlineSectionKeyPoints}>
        {isEmpty(section.keyPoints) && !emptyStateDisabled.includes(section.id) ? (
          <DynamicItemEmptyState
            loading={section.loading}
            position={DynamicItemEmptyStatePosition.KEYPOINTS}
            onDeleteClick={() => onRemoveSection(section.id)}
            onReloadClick={() => onReGenerateKeyPoint(section.id)}
          />
        ) : (
          <>
            <Text className={styles.headlineSectionKeyPointsTitle} caps>
              Key points
            </Text>
            <div className={styles.headlineSectionKeyPointsGenerateButton}>
              <Button
                size={SizeTypes.XS}
                type={ButtonTypes.BLACK}
                className={styles.generateTitlesButton}
                onClick={() => onReGenerateKeyPoint(section.id)}
                isLoading={section.loading}
              >
                <Icon name={IconVariant.WAND_WHITE} className={styles.generateTitlesButtonIcon} />
                <Text color={TextColor.WHITE} bold>
                  Regenerate points
                </Text>
              </Button>
            </div>
            {section.loading ? (
              <KeyPointLoader type={KeyPointLoaderType.SINGLE} count={section.keyPoints.length} />
            ) : (
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={_handleKeypointDragEnd}>
                <SortableContext items={section.keyPoints} strategy={verticalListSortingStrategy}>
                  <div className={styles.headlineSectionKeyPointsContainer}>
                    {section.keyPoints.map(keyPoint => (
                      <KeyPointsSectionInput
                        key={`keyPointsSectionInput-${keyPoint.id}`}
                        sectionId={section.id}
                        keyPoint={keyPoint}
                        onChangeKeyPoint={onChangeKeyPoint}
                        onRemoveKeyPoint={onRemoveKeyPoint}
                      />
                    ))}
                  </div>
                </SortableContext>
              </DndContext>
            )}
            <div className={styles.headlineSectionKeyPointsAddButton}>
              {addKeyPointAvailable ? (
                <div
                  className={cx(styles.containerNewItemButton, styles.clickable, {
                    [styles.eventsDisabled]: section.loading,
                  })}
                  onClick={() => onAddKeyPoint(section.id)}
                >
                  <Icon name={IconVariant.ADD} className={styles.containerNewItemButtonIcon} />
                  <Text>Add a point</Text>
                </div>
              ) : (
                <div className={cx(styles.containerNewItemButton, styles.eventsDisabled)}>
                  <Icon
                    name={IconVariant.ADD}
                    className={cx(styles.containerNewItemButtonIcon, styles.containerNewItemButtonIconTransparent)}
                  />
                  <Text>You’ve reached the limit of {MAX_KEY_POINTS_PER_SECTION} points</Text>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const BlogKeyPointsForm: React.FC<IBlogHeadlineFormProps> = ({
  headline,
  keyPointsGenerating,
  keyPointsGenerated,
  onKeyPointsGenerate,
  outline,
  setKeyPoints,
}) => {
  const [sections, setSections] = useState<IKeyPointsSection[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  useEffect(() => {
    if (isEmpty(keyPointsGenerated)) {
      onKeyPointsGenerate(outline)
        .then(keyPoints => setSections(keyPoints))
        .catch(e => LOG.error(e));
    } else {
      setSections(keyPointsGenerated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyPointsGenerated]);

  const [emptyStateDisabled, setEmptyStateDisabled] = useState<string[]>([]);

  const _throttleCb = sections => throttle(sections => setKeyPoints(sections), 500)(sections);
  const _onChangeKeyPointTitle = async (sectionId: string, keyPointId: string, title: string) => {
    const keyPointsSections = sections.map(section => {
      const _section = section;

      if (section.id === sectionId) {
        _section.keyPoints = section.keyPoints.map(keyPoint => {
          const _keyPoint = keyPoint;

          if (keyPoint.id === keyPointId) {
            _keyPoint.name = title;
          }

          return _keyPoint;
        });
      }

      return _section;
    });

    _throttleCb(keyPointsSections);
  };
  const _onRemoveKeyPoint = async (sectionId: string, keyPointId: string) => {
    const keyPointsSections = sections.map(section => {
      const _section = section;
      emptyStateDisabled.push(sectionId);
      setEmptyStateDisabled(emptyStateDisabled);

      if (section.id === sectionId) {
        _section.keyPoints = section.keyPoints.filter(keyPoint => keyPoint.id !== keyPointId);
      }

      return _section;
    });
    setSections(keyPointsSections);
    setKeyPoints(keyPointsSections);
  };
  const _onAddKeyPoint = async (sectionId: string) => {
    const keyPointsSections = sections.map(section => {
      const _section = section;

      if (section.id === sectionId) {
        section.keyPoints.push({ id: generateRandomString(), name: '' });
        _section.keyPoints = section.keyPoints;
      }

      return _section;
    });
    setSections(keyPointsSections);
    setKeyPoints(keyPointsSections);
  };
  const _onRemoveSection = async (sectionId: string) =>
    setSections(sections.filter(section => section.id !== sectionId));
  const _onReGenerateKeyPoint = async (sectionId: string) => {
    const section = sections.find(s => s.id === sectionId);
    const sectionIndex = sections.findIndex(s => s.id === sectionId);
    const _sections = sections.map((section, index) =>
      sectionIndex === index ? { ...section, loading: true } : section,
    );
    setSections(_sections);

    if (section) {
      await onKeyPointsGenerate(outline, sectionIndex);
      setSections(
        sections.map((section, index) =>
          sectionIndex === index
            ? {
                ...section,
                loading: false,
              }
            : section,
        ),
      );
    }
  };
  const _handleHeadlineDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over) {
      const activeIndex = sections.findIndex(section => section.id === active.id);
      const overIndex = sections.findIndex(section => section.id === over.id);
      const sectionsSorted = arrayMove(sections, activeIndex, overIndex);
      setSections(sectionsSorted);
      setKeyPoints(sectionsSorted);
    }
  };
  const _handleHeadlineKeypointDragEnd = (event, section) => {
    const _sections = sections.map(s => (s.id === section.id ? section : s));
    setSections(_sections);
    setKeyPoints(_sections);
  };

  return (
    <div className={styles.containerForm}>
      <div className={styles.containerFormItems}>
        <div className={styles.containerFormItem}>
          <HeadLine headline={headline} />
        </div>
        <div className={styles.containerFormItem}>
          <HeadLineDescription />
        </div>
        <div className={cx(styles.containerFormItem, styles.containerFormItemScrollable)}>
          {keyPointsGenerating ? (
            <KeyPointLoader count={2} />
          ) : (
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={_handleHeadlineDragEnd}>
              <SortableContext items={sections} strategy={verticalListSortingStrategy}>
                {sections.map((section, index) => (
                  <HeadlineSection
                    addKeyPointAvailable={section.keyPoints.length < MAX_KEY_POINTS_PER_SECTION}
                    id={section.id}
                    key={`headlineSection-${index}`}
                    section={section}
                    handleKeypointDragEnd={_handleHeadlineKeypointDragEnd}
                    onAddKeyPoint={_onAddKeyPoint}
                    onRemoveKeyPoint={_onRemoveKeyPoint}
                    onChangeKeyPoint={_onChangeKeyPointTitle}
                    onReGenerateKeyPoint={_onReGenerateKeyPoint}
                    onRemoveSection={_onRemoveSection}
                    emptyStateDisabled={emptyStateDisabled}
                  />
                ))}
              </SortableContext>
            </DndContext>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogKeyPointsForm;
