import { useMemo } from 'react';
import { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Heading, HeadingVariant, Modal, TextSize } from '@writercolab/ui-atoms';

import { InputLabel } from 'components/molecules/InputLabel/InputLabel';

import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { observer } from 'mobx-react-lite';
import { useAppState } from 'state';

import { InputField } from '../InputField/InputField';
import { type SessionsTableUiModel } from './SessionsTableModel.ui';

import styles from './SessionsTable.module.css';

export const SessionsTableModal = observer(({ model }: { model: SessionsTableUiModel }) => {
  const {
    appModel: { analyticsService },
  } = useAppState();
  const { selectedSessionData } = model;

  const sessionTitle = useMemo(
    () =>
      FieldModel.build({
        init: selectedSessionData?.session?.title || '',
        autotouched: true,
        validation: value => {
          if (!value || isEmpty(trim(value))) {
            return 'Please enter a session name';
          }

          return undefined;
        },
      }),
    [selectedSessionData?.session?.title],
  );

  const handleSubmit = () => {
    if (selectedSessionData?.session.id && sessionTitle.value) {
      analyticsService.track(AnalyticsActivity.sessionRenameSavedButtonClicked, {
        origin: 'my_work',
        session_id: selectedSessionData.session.id,
      });

      model.updateSessionTitle(selectedSessionData?.session.id, sessionTitle.value);
    }
  };
  const handleCancel = () => {
    if (selectedSessionData?.session.id) {
      analyticsService.track(AnalyticsActivity.sessionRenamedCancelButtonClicked, {
        origin: 'my_work',
        session_id: selectedSessionData.session.id,
      });
    }

    model.closeModal();
  };

  const isValid = !!sessionTitle.error || !sessionTitle.value;

  return (
    <Modal
      className={styles.modalContainer}
      open={model.isOpenEditNameModal}
      handleClose={model.closeModal}
      hideCloseButton
    >
      <form onSubmit={e => e.preventDefault()}>
        <Heading className={styles.heading} variant={HeadingVariant.H2}>
          Rename session
        </Heading>
        <p className={styles.inputDescription}>Choose a new name for your session</p>
        <InputField
          containerClassName={styles.inputGroup}
          field={sessionTitle}
          placeholder="Session name"
          errorPosition="bottom"
        >
          <InputLabel textSize={TextSize.XL} label="Name" />
        </InputField>

        <div className={styles.controlButtons}>
          <Button type={ButtonTypes.DEFAULT} content="Cancel" onClick={handleCancel} />
          <Button type={ButtonTypes.PRIMARY} disabled={isValid} content="Save" onClick={handleSubmit} />
        </div>
      </form>
    </Modal>
  );
});
