export class CustomException extends Error {
  public readonly messageKey?: string;

  constructor(message: string, messageKey?: string) {
    super(message);
    this.messageKey = messageKey;

    Object.setPrototypeOf(this, CustomException.prototype);
  }
}
