import { Enum } from '@writercolab/utils';

export type OrgDomainId = number | string;

export enum OrgJoinNewUserStatus {
  APPROVE = 'approve',
  DENY = 'deny',
}

export enum OrgJoinQueryParams {
  USER_ID = 'user_id',
  ACTION = 'action',
}

export interface OrgDomain {
  id: OrgDomainId;
  organizationId: number;
  domain: string;
  allowJoin: boolean;
  joinRequiresApproval: boolean;
  joinTeamIds: number[];
}

export const TOrganizationJoinType = new Enum('request_to_join', 'immediate');

export type OrgDomainUpdateProps = Pick<OrgDomain, 'allowJoin' | 'joinRequiresApproval' | 'joinTeamIds'>;
