import { computed, makeObservable } from 'mobx';

import { PaginatedModel } from '@writercolab/mobx';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import {
  EApplicationType,
  type TApplicationBase,
  type TApplicationBriefPaginatedTemplateResponse,
} from '@writercolab/types';
import { ChatSessionsApiModel } from '@writercolab/ui-chat-apps';

import { DEFAULT_PAGE_SIZE, type TDraftsTableFilterAppsExtraArgs, type TGetDeployedAppsParams } from '@web/types';
import debounce from 'lodash/debounce';
import { ApplicationApiModel } from 'models/application.api';
import { OrganizationDocumentsApi } from 'models/organizationDocuments.api';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY_MS } from 'services/config/constants';

interface IHeaderActionsOptions {
  organizationId: number;
  teamId: number;
  request: RequestServiceInitialize['api'];
}

export class HeaderActionsUiModel {
  readonly applicationApi: ApplicationApiModel;
  readonly chatSessionsApi: ChatSessionsApiModel;
  readonly documentApi: OrganizationDocumentsApi;
  readonly assistantSubscription: AiAssistantSubscriptionModel;

  constructor(private readonly opts: IHeaderActionsOptions) {
    this.applicationApi = new ApplicationApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: () => this.opts.teamId,
    });

    this.chatSessionsApi = new ChatSessionsApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      getApplicationId: () => undefined,
      getActiveSessionId: () => undefined,
      getApplicationVersionId: () => undefined,
    });

    this.documentApi = new OrganizationDocumentsApi({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
    });

    this.assistantSubscription = new AiAssistantSubscriptionModel({
      api: this.opts.request,
      organizationId: () => this.opts.organizationId,
      teamId: () => this.opts.teamId,
    });

    makeObservable(this, {
      applications: computed.struct,
    });
  }

  private readonly $applications: PaginatedModel<
    TApplicationBriefPaginatedTemplateResponse,
    TApplicationBase,
    {
      offset: number;
      limit: number;
    },
    TDraftsTableFilterAppsExtraArgs
  > = new PaginatedModel({
    name: '$applications',
    argsExtra: { search: '', sortOrder: 'asc', sortField: 'name' },
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    extractMeta: obj => {
      const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

      if (offset >= obj.totalCount) {
        return this.$applications.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj.result ?? [],
    load: async ({ args, extra }) => {
      const data = await this.applicationApi.getDeployedApps({
        ...args,
        ...extra,
        appType: EApplicationType.enum.chat,
      });

      return data;
    },
  });

  get isLoading() {
    return this.$applications.status === 'pending';
  }

  get applications() {
    return this.$applications.value ?? [];
  }

  get hasMoreApplications(): boolean {
    return this.$applications.hasNext;
  }

  get assistantSubscriptionAccess() {
    return this.assistantSubscription.access;
  }

  loadMoreApplications = async () => {
    await this.$applications.next();
  };

  updateAppsExtraArgs = debounce((extraArgs: Partial<TGetDeployedAppsParams>) => {
    this.$applications.setExtra(extraArgs);
  }, DEFAULT_SEARCH_DEBOUNCE_DELAY_MS);
}
