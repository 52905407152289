import { useEffect } from 'react';

import type { IUserProfile } from '@writercolab/common-utils';
import { GTMActivity } from '@writercolab/common-utils';

import type { Location } from 'react-router';

import analyticsServiceBase from '../services/analytics/analyticsService';
import { TQueryParamBooleanStd } from '../utils/queryParamUtils';

interface IGtmAnalyticsParams {
  location: Location;
  isTrialSubscription: boolean;
  aiAssistantSubscriptionProductName?: string;
  isAiStudioSubscriptionReady?: boolean;
  aiStudioSubscriptionProductName?: string;
  userProfile?: IUserProfile;
}
export const useGtmAnalytics = ({
  location,
  isTrialSubscription,
  aiAssistantSubscriptionProductName,
  isAiStudioSubscriptionReady,
  aiStudioSubscriptionProductName,
  userProfile,
}: IGtmAnalyticsParams): void => {
  useEffect(() => {
    if (isAiStudioSubscriptionReady === undefined || !userProfile) {
      return;
    }

    const planType = isAiStudioSubscriptionReady ? aiStudioSubscriptionProductName : aiAssistantSubscriptionProductName;
    const { id, email } = userProfile;
    const isAiAssistantSubscriptionTrial = isTrialSubscription
      ? TQueryParamBooleanStd.enum.true
      : TQueryParamBooleanStd.enum.false;

    analyticsServiceBase.trackGtmActivity({
      event: GTMActivity.CUSTOM_PAGE_DETAILS,
      userId: String(id),
      email,
      planType,
      isTrial: isAiStudioSubscriptionReady ? TQueryParamBooleanStd.enum.false : isAiAssistantSubscriptionTrial,
      loggedIn: TQueryParamBooleanStd.enum.true,
    });
  }, [
    location.pathname,
    userProfile,
    isAiStudioSubscriptionReady,
    aiStudioSubscriptionProductName,
    aiAssistantSubscriptionProductName,
    isTrialSubscription,
  ]);
};
