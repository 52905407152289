import { useCallback, useEffect, useRef, useState } from 'react';

export function useCountdown(initialSeconds: number): [number, () => void, () => void] {
  const [counter, setCounter] = useState(initialSeconds);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const clearCountdown = useCallback(() => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const startCountdown = useCallback(() => {
    if (intervalRef.current !== null) {
      return;
    }

    intervalRef.current = setInterval(() => {
      setCounter(counter => {
        const newCounter = counter - 1;

        if (newCounter <= 0) {
          clearCountdown();
        }

        return newCounter;
      });
    }, 1000);
  }, [clearCountdown]);

  const restartCountdown = useCallback(() => {
    clearCountdown();
    setCounter(initialSeconds);
  }, [clearCountdown, initialSeconds]);

  useEffect(
    () => clearCountdown, // clear on component unmount
    [clearCountdown],
  );

  return [counter, startCountdown, restartCountdown];
}

export default useCountdown;
