import React from 'react';

import { Icon, IconVariant, Text, TextColor, TextSize, Tooltip, TooltipAlignment } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IInfoIconTextProps extends BaseComponentsProps {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  titleSize?: TextSize;
  titleColor?: TextColor;
  titleBold?: boolean;
  titleMedium?: boolean;
  titleLight?: boolean;
  tooltipWidth?: number;
  tooltipAlignment?: TooltipAlignment;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
}

export const InfoIconText: React.FC<IInfoIconTextProps> = ({
  className,
  title,
  titleMedium = false,
  titleLight = false,
  titleBold = false,
  description,
  titleColor = TextColor.BLACK,
  titleSize = TextSize.L,
  tooltipAlignment = TooltipAlignment.LEFT,
  tooltipPlacement = 'top',
  tooltipWidth,
}) => (
  <div className={className}>
    <div className={styles.containerItemsWrapper}>
      {title && (
        <Text variant={titleSize} color={titleColor} bold={titleBold} medium={titleMedium} light={titleLight}>
          {title}
          {description && (
            <Tooltip
              disabled={!description}
              placement={tooltipPlacement}
              align={tooltipAlignment}
              tooltipWidth={tooltipWidth}
              title={<div className={styles.tooltipText}>{description}</div>}
            >
              <div className={styles.itemIconWrapper}>
                <Icon name={IconVariant.INFO_OUTLINED} className={styles.itemIcon} />
              </div>
            </Tooltip>
          )}
        </Text>
      )}
    </div>
  </div>
);

export default InfoIconText;
