import { action, makeObservable, observable } from 'mobx';

import { BUILDER_TEMPLATE_ID, type ITemplateDraftFeedbackParams, copyToClipboard } from '@writercolab/common-utils';
import { PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import { NotificationQueueItemType, type TEventQueueItem, type TNotificationQueueItem } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import {
  type TDraftInput,
  type TDraftWithInputsResponse,
  TMediaFileUploadPlaintextTypes,
  type TMediaFileUploadType,
} from '@web/types';
import { type TAppLocalStorage } from 'models/localStorage';
import { MediaApiModel } from 'models/media.api';
import { OrganizationDocumentsApi } from 'models/organizationDocuments.api';
import { OutputsApiModel } from 'models/outputs.api';
import { TemplatesAppApiModel } from 'models/templatesApp.api';
import { VoiceApiModel } from 'models/voice.api';
import { getMediaFileDetails } from 'services/request/mediaFiles';
import { extractBackendResponseErrorMessage } from 'utils/backendErrorUtils';
import {
  downloadAs,
  getMediaFileIdFromInputs,
  mapMediaFileDetailsToInputs,
  transformSeoBlogInputs,
} from 'utils/draftsUtils';
import { getLogger } from 'utils/logger';

import { getAccess } from './utils';

export const EditOutputPanelEvents = new Enum('outputDeleted', 'toEventTakeawaysMode', 'toBlogMode', 'toCoWriteMode');

interface IEditOutputPanelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: () => number;
  isVoiceEnabled: () => boolean;
  storage: TAppLocalStorage;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  modalsManager?: ModalsManager<typeof TOutputsModals.type>;
}

export const TOutputsModals = new Enum('outputPanel', 'expandedOutputPanel');

export type Togglables =
  | {
      [K in typeof TOutputsModals.type]: boolean;
    }
  | object;

const LOG = getLogger('OutputsTableModel.ui');

export class EditOutputPanelUiModel {
  modalsManager = new ModalsManager<typeof TOutputsModals.type>();
  readonly organizationDocumentsApi: OrganizationDocumentsApi;
  readonly outputsApi: OutputsApiModel;
  readonly voiceApi: VoiceApiModel;
  readonly templatesAppApi: TemplatesAppApiModel;
  readonly mediaApi: MediaApiModel;
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
  readonly eventsQueue = new ReactiveQueue<
    TEventQueueItem<
      typeof EditOutputPanelEvents.type,
      {
        documentId: number;
        templateId: string;
      }
    >
  >();

  private $isFeedbackOpen = observable.box<Togglables>({});
  private $isInputsOpen = observable.box<Togglables>({});
  private $isLoading = observable.box(false);
  private $selectedOutputId = observable.box<number | null>(null);
  private $templateInputs = observable.array<TDraftInput>([]);

  constructor(private readonly opts: IEditOutputPanelOptions) {
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();

    this.organizationDocumentsApi = new OrganizationDocumentsApi({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId(),
    });

    this.outputsApi = new OutputsApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: () => this.opts.teamId(),
    });

    this.voiceApi = new VoiceApiModel({
      request: this.opts.request,
      organizationId: () => this.opts.organizationId,
      teamId: () => this.opts.teamId(),
      isVoiceEnabled: this.opts.isVoiceEnabled,
    });

    this.templatesAppApi = new TemplatesAppApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
    });

    this.mediaApi = new MediaApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
    });

    makeObservable(this, {
      setSelectedId: action,
      toggleFeedbackOpen: action,
      toggleInputsOpen: action,
      setTemplateInputs: action,
    });
  }

  private readonly $selectedOutput = new PromisedModel({
    name: '$selectedOutput',
    load: async () => {
      const selectedOutputId = this.$selectedOutputId.get();

      if (!selectedOutputId) {
        return null;
      }

      return this.outputsApi.getOutput(selectedOutputId);
    },
  });

  private readonly $voice = new PromisedModel({
    name: '$voice',
    load: async () => {
      const selectedOutput = this.$selectedOutput.value;
      const voiceId = selectedOutput?.voiceId;

      if (!voiceId) {
        return null;
      }

      try {
        const voice = await this.voiceApi.getVoiceDetails(voiceId);

        if (voice) {
          return voice.name;
        }
      } catch {
        LOG.error('Failed to get voice details', {
          voiceId: selectedOutput?.voiceId,
        });

        return 'Deleted voice';
      }

      return null;
    },
  });

  private readonly $inputs = new PromisedModel({
    name: '$inputs',
    load: async () => {
      const output = this.$selectedOutput.value;

      if (!output) {
        return null;
      }

      if (output.template.id === BUILDER_TEMPLATE_ID.SEO_BLOG) {
        return transformSeoBlogInputs(output.inputs);
      }

      return output.inputs;
    },
  });

  private readonly $mediaFiles = new PromisedModel({
    name: '$mediaFiles',
    load: async () => {
      const output = this.$selectedOutput.value;

      if (!output) {
        return undefined;
      }

      if (output.template.id === BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS) {
        const mediaFileId = getMediaFileIdFromInputs(output.inputs);

        if (mediaFileId) {
          try {
            const teamId = this.opts.teamId();
            const mediaFileDetails = await getMediaFileDetails(
              String(this.opts.organizationId),
              String(teamId),
              mediaFileId.value[0],
            );

            return mapMediaFileDetailsToInputs(mediaFileId.value[0], mediaFileDetails.name, mediaFileDetails.url);
          } catch (error) {
            LOG.error(error);
          }
        }
      }

      return output.media;
    },
  });

  get isLoading() {
    return this.$isLoading.get();
  }

  get isFeedbackOpen() {
    return this.$isFeedbackOpen.get();
  }

  get isInputsOpen() {
    return this.$isInputsOpen.get();
  }

  get selectedOutput() {
    this.$inputs.reload();

    return this.$selectedOutput.value;
  }

  get voice() {
    return this.$voice.value;
  }

  get inputs() {
    return this.$inputs.value;
  }

  get mediaFiles() {
    return this.$mediaFiles.value;
  }

  get templateInputs(): TDraftInput[] {
    return this.$templateInputs;
  }

  toggleFeedbackOpen = (type: typeof TOutputsModals.type) => {
    const feedback = this.$isFeedbackOpen.get();

    this.$isFeedbackOpen.set({
      ...feedback,
      [type]: !feedback[type],
    });
  };

  toggleInputsOpen = (type: typeof TOutputsModals.type) => {
    const inputs = this.$isInputsOpen.get();

    this.$isInputsOpen.set({
      ...inputs,
      [type]: !inputs[type],
    });
  };

  setSelectedId = (outputId: number) => {
    this.$selectedOutputId.set(outputId);
  };

  setTemplateInputs = (inputs: TDraftInput[]) => {
    this.$templateInputs.replace(inputs);
  };

  openInNewDoc = async (output: TDraftWithInputsResponse) => {
    const { organizationId, storage } = this.opts;
    const teamId = this.opts.teamId();

    if (!output) {
      return null;
    }

    const createdDocument = await this.organizationDocumentsApi.createDocument(organizationId, teamId, '');

    if (!createdDocument) {
      LOG.error('Failed to create new document', {
        outputId: output.id,
        organizationId,
        teamId,
      });

      return null;
    }

    const formValues = output.inputs.reduce((acc, input) => {
      acc[input.name] = input.value;

      return acc;
    }, {});
    const draftValues = { title: output?.title || '', body: output.body };
    const values = output.inputs.map(input => ({
      name: input.name,
      value: input.value,
    }));
    const cachedKey = `${createdDocument.id}-${output.template.id}`;

    storage.lastDraft.set(draftValues);
    storage.lastDraftValues.set(formValues);
    storage.editorApplicationPreviewValues.update({ [cachedKey]: () => values });

    const access = getAccess(output);

    const eventParams = {
      documentId: createdDocument.id as number,
      templateId: output.template.id,
    };

    if (access.isEventTakeaways) {
      this.eventsQueue.enqueue({
        type: EditOutputPanelEvents.enum.toEventTakeawaysMode,
        params: eventParams,
      });
    } else if (access.isSeoBlogBuilder) {
      this.eventsQueue.enqueue({
        type: EditOutputPanelEvents.enum.toBlogMode,
        params: eventParams,
      });
    } else {
      this.eventsQueue.enqueue({
        type: EditOutputPanelEvents.enum.toCoWriteMode,
        params: eventParams,
      });
    }

    return undefined;
  };

  copyOutput = async (output: TDraftWithInputsResponse) => {
    const text = output.title ? `<h2>${output.title}</h2>\n${output.body}` : output.body;
    const html = text.replace(/\n/g, '<br/>');
    const isCopied = copyToClipboard({ text, html });

    if (isCopied) {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Output text was successfully copied',
      });
    } else {
      LOG.error('Failed to copy output text', {
        outputId: output.id,
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Output text could not be copied',
      });
    }
  };

  deleteOutput = async (outputId?: number) => {
    if (!outputId) {
      return undefined;
    }

    try {
      const teamId = this.opts.teamId();

      await this.opts.request.delete('/api/template/organization/{organizationId}/team/{teamId}/draft', {
        params: {
          path: {
            organizationId: Number(this.opts.organizationId),
            teamId,
          },
          query: {
            draftIds: [outputId],
          },
        },
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Output was successfully deleted',
      });

      this.eventsQueue.enqueue({
        type: EditOutputPanelEvents.enum.outputDeleted,
      });
    } catch (e) {
      LOG.error('Failed to delete output', {
        outputId,
        error: e,
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Output could not be deleted',
      });
    }

    return undefined;
  };

  getTemplate = async (output: TDraftWithInputsResponse) => {
    try {
      const template = await this.templatesAppApi.getPublicTemplateById(output.template.id);
      const access = getAccess(output);

      if (!access.isEventTakeaways && !access.isSeoBlogBuilder) {
        this.setTemplateInputs(template.inputs);
      }

      return null;
    } catch (error) {
      LOG.error(error);

      return undefined;
    }
  };

  downloadMediaFile = async ({ fileId, filename }: { fileId: string; filename: string }) => {
    try {
      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.success,
        message: 'Your download is in progress...',
      });

      const data = await this.mediaApi.dowloadByFileId(fileId);
      downloadAs(new Blob([data]), filename);
    } catch (e) {
      LOG.error(extractBackendResponseErrorMessage(e));
    }
  };

  downloadMediaFileTranscript = async ({ fileId, filename }: { fileId: string; filename: string }) => {
    try {
      const mediaFile = await this.mediaApi.getByFileId(fileId);

      if (!mediaFile) {
        LOG.error('Media file is empty');

        return undefined;
      }

      if (TMediaFileUploadPlaintextTypes.includes(mediaFile.mediaType as typeof TMediaFileUploadType.type)) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.success,
          message: 'Your download is in progress...',
        });

        const data = await this.mediaApi.dowloadByFileId(fileId);
        downloadAs(new Blob([data]), mediaFile.name);

        return undefined;
      } else {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.success,
          message: 'Your download is in progress...',
        });

        const data = await this.mediaApi.dowloadDiarizedByFileId(fileId);
        downloadAs(new Blob([data]), filename);

        return undefined;
      }
    } catch (e) {
      LOG.error(extractBackendResponseErrorMessage(e));

      return undefined;
    }
  };

  rateOutput = async (outputId: number, documentId: number, payload: ITemplateDraftFeedbackParams) => {
    try {
      const data = await this.outputsApi.addOutputFeedback(outputId, documentId, {
        rate: payload.rate,
        feedback: payload.feedback,
      });

      if (data) {
        this.notificationQueue.enqueue({
          type: NotificationQueueItemType.enum.success,
          message: 'Your feedback was submitted. Thanks for reviewing!',
        });
      }
    } catch (e) {
      LOG.error(extractBackendResponseErrorMessage(e));

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Unable to rate feedback',
      });
    }
  };
}
