import type { Reducer } from 'react';
import type React from 'react';
import { useCallback, useEffect, useMemo, useReducer } from 'react';

import cx from 'classnames';

import { AnalyticsService } from '@writercolab/analytics';
import type { IUserProfile } from '@writercolab/common-utils';
import { getSsoSettings, validateName } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  type DropdownOption,
  Heading,
  HeadingVariant,
  SizeTypes,
  Text,
  TextSize,
  useCustomSnackbar,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import { InputGroup, InputTypes, ThreeDotsLoader } from '@writercolab/ui-molecules';

import { DataRetentionPreferences } from 'components/organisms/DataRetentionPreferences';
import { DataRetentionPreferencesUIModel } from 'components/organisms/DataRetentionPreferences/DataRetentionPreferencesModel.ui';

import { snackbarMessages } from '@web/component-library';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import DomainDiscoverabilityPreferencesContextProvider from '../../../context/domainDiscoverabilityPreferencesContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { updateAccount } from '../../../services/request/account';
import { useAppState } from '../../../state';
import { useAppSessionContext } from '../../../state/sessionContext';
import { getLogger } from '../../../utils/logger';
import DeleteOrganization from '../../organisms/DeleteOrganization';
import DomainDiscoverabilityPreferences from '../../organisms/DomainDiscoverabilityPreferences/DomainDiscoverabilityPreferences';

import styles from './styles.module.css';
import { AnalyticsActivity } from 'constants/analytics';

const LOG = getLogger('AccountPage');

interface IAccountPageProps {}

enum AccountActionType {
  setOrgName = 'setOrgName',
  setOrgDeleteAvailable = 'setOrgDeleteAvailable',
  setLoading = 'setLoading',
  setOrgHasSaml = 'setOrgHasSaml',
  setOrgHasSamlConfigLoading = 'setOrgHasSamlConfigLoading',
}

type TAccountState = {
  orgName: string;
  orgNameInitial: string;
  orgNameErrorMessage: string | null;
  orgNameLoading: boolean;
  orgHasSaml: boolean;
  orgHasSamlLoading: boolean;
  orgRequestLoading: boolean;
  saveOrgNameAvailable: boolean;
  orgNameValid: boolean | null;
  cancelOrgNameAvailable: boolean;
  createServiceAccountModalOpen: boolean;
  confirmDeleteServiceAccountModalOpen: boolean;
  serviceAccounts: IUserProfile[];
  deleteServiceAccountInstance: IUserProfile | undefined;
};

const accountInitialState: TAccountState = {
  orgName: '',
  orgNameInitial: '',
  orgRequestLoading: false,
  orgNameErrorMessage: null,
  orgNameLoading: false,
  orgNameValid: null,
  saveOrgNameAvailable: false,
  cancelOrgNameAvailable: false,
  createServiceAccountModalOpen: false,
  orgHasSaml: false,
  orgHasSamlLoading: true,
  confirmDeleteServiceAccountModalOpen: false,
  serviceAccounts: [],
  deleteServiceAccountInstance: undefined,
};

type TAccountAction = {
  type: AccountActionType;
  payload?: { [key: string]: any };
};

const reducer = (state: TAccountState, action: TAccountAction) => ({ ...state, ...action.payload });

export const AccountPage: React.FC<IAccountPageProps> = observer(() => {
  usePageTitle('Account');

  const {
    appModel: { assistantSubscription, dataRetentionModel, teamsModel, analyticsService },
    appState: { organization, organizationId, userProfile },
  } = useAppState();
  const { fetchOrganizations } = useAppSessionContext();
  const [state, dispatch] = useReducer<Reducer<TAccountState, TAccountAction>>(reducer, accountInitialState);
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const { retentionPreferences } = dataRetentionModel || {};

  const dataRetentionUiModel = useMemo(() => {
    if (!dataRetentionModel || !retentionPreferences) {
      return undefined;
    }

    return new DataRetentionPreferencesUIModel({
      api: dataRetentionModel,
      analyticsService,
    });
  }, [analyticsService, dataRetentionModel, retentionPreferences]);

  useQueueWorkerNotifications(dataRetentionUiModel?.notificationQueue);

  const access = useMemo(
    () => ({
      // only Enterprise plans not allowed to delete organizations
      canDeleteOrg: userProfile && !assistantSubscription.isEnterprise,
      showServiceAccounts: assistantSubscription.isEnterprise || assistantSubscription.isTeam,
    }),
    [assistantSubscription.isEnterprise, assistantSubscription.isTeam, userProfile],
  );

  const teamsDropdownOptions: DropdownOption[] = useMemo(
    () =>
      teamsModel.teams?.map(t => ({
        id: `${t.id}`,
        name: t.name,
      })) || [],
    [teamsModel.teams],
  );

  useEffect(() => {
    if (!userProfile || !organizationId || !assistantSubscription.isActive) {
      return;
    }

    getSsoSettings(organizationId)
      .then(ssoSettings => {
        dispatch({
          type: AccountActionType.setOrgHasSaml,
          payload: {
            orgHasSaml: !isEmpty(ssoSettings),
          },
        });
      })
      .finally(() => {
        dispatch({
          type: AccountActionType.setOrgHasSamlConfigLoading,
          payload: {
            orgHasSamlLoading: false,
          },
        });
      });
  }, [organizationId, userProfile, assistantSubscription.isActive]);

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.accountViewed, {});
  }, [analyticsService]);

  useEffect(() => {
    if (organization) {
      dispatch({
        type: AccountActionType.setOrgName,
        payload: {
          orgName: organization.name,
          orgNameInitial: organization.name,
        },
      });
    }
  }, [organization]);

  useEffect(() => {
    dispatch({
      type: AccountActionType.setOrgDeleteAvailable,
      payload: {
        deleteOrgAvailable: !assistantSubscription.isEnterprise,
      },
    });
  }, [assistantSubscription.isEnterprise]);

  const _handleOrgNameChange = useCallback(e => {
    const isValid = validateName(e.target.value);

    dispatch({
      type: AccountActionType.setOrgName,
      payload: {
        orgName: e.target.value,
        orgNameValid: isValid,
        saveOrgNameAvailable: isValid,
        cancelOrgNameAvailable: true,
        orgNameErrorMessage: 'Name is invalid',
      },
    });
  }, []);

  const _handleOrgNameChangeCancel = useCallback(() => {
    dispatch({
      type: AccountActionType.setOrgName,
      payload: {
        orgName: state.orgNameInitial,
        orgNameValid: true,
        orgNameErrorMessage: null,
        saveOrgNameAvailable: false,
        cancelOrgNameAvailable: false,
      },
    });
  }, [state.orgNameInitial]);

  const _handleOrgNameChangeSave = useCallback(async () => {
    dispatch({
      type: AccountActionType.setLoading,
      payload: {
        orgNameLoading: true,
      },
    });

    try {
      await updateAccount(organizationId, { name: state.orgName });
      await fetchOrganizations();
      enqueueSuccessSnackbar(snackbarMessages.user.organizationNameUpdatedSuccess);
      dispatch({
        type: AccountActionType.setLoading,
        payload: {
          orgNameValid: true,
          orgNameInitial: state.orgName,
          orgNameErrorMessage: null,
          saveOrgNameAvailable: false,
          cancelOrgNameAvailable: false,
        },
      });
    } catch (e) {
      if (e instanceof Error) {
        LOG.error(e.message);
        (e as any).response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
      }
    } finally {
      dispatch({
        type: AccountActionType.setLoading,
        payload: {
          orgNameLoading: false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.orgName, organization]);

  return (
    <div className={styles.container}>
      <Heading variant={HeadingVariant.H2} className={styles.containerHeader}>
        Account
      </Heading>
      <div className={styles.containerForm}>
        <Heading variant={HeadingVariant.H3} className={styles.containerFormHeader}>
          Organization
        </Heading>
        <div className={cx(styles.containerFormRow)}>
          <InputGroup
            id="organizationNameContainer"
            label={
              <Text variant={TextSize.XS} className={styles.label} smallCaps>
                Organization name
              </Text>
            }
            inputType={InputTypes.CUSTOM}
            disableHelperText
          >
            <InputGroup
              id="organizationName"
              name="organizationName"
              autoComplete="new-password"
              label=""
              value={state.orgName}
              onChange={_handleOrgNameChange}
              className={cx({
                [styles.inputInvalid]: !state.orgNameValid && state.orgNameErrorMessage,
              })}
              errorText={!state.orgNameValid && state.orgNameErrorMessage ? state.orgNameErrorMessage : undefined}
            />
          </InputGroup>
        </div>
        <div className={cx(styles.containerRow, styles.containerRowActions)}>
          <Button
            className={styles.saveButton}
            type={ButtonTypes.PRIMARY}
            size={SizeTypes.SMALL}
            content="Save"
            onClick={_handleOrgNameChangeSave}
            disabled={!state.saveOrgNameAvailable}
            isLoading={state.orgNameLoading}
          />
          <Button
            type={ButtonTypes.DEFAULT}
            size={SizeTypes.SMALL}
            content="Cancel"
            onClick={_handleOrgNameChangeCancel}
            disabled={!state.cancelOrgNameAvailable}
            isLoading={state.orgNameLoading}
          />
        </div>
      </div>
      {assistantSubscription.isModelReady && (
        <div className={styles.rowContainer}>
          <DomainDiscoverabilityPreferencesContextProvider
            orgId={organizationId}
            teams={teamsDropdownOptions}
            userProfile={userProfile}
            showSsoPageBanner={state.orgHasSaml}
            showTrialBanner={assistantSubscription.isTrial}
          >
            <DomainDiscoverabilityPreferences showSamlCopy={state.orgHasSaml} />
          </DomainDiscoverabilityPreferencesContextProvider>
        </div>
      )}
      {dataRetentionUiModel && (
        <div className={styles.rowContainer}>
          {!dataRetentionModel ? <ThreeDotsLoader /> : <DataRetentionPreferences model={dataRetentionUiModel} />}
        </div>
      )}
      <div className={styles.containerForm}>
        {access.canDeleteOrg && (
          <DeleteOrganization
            className={styles.deleteOrgWrapper}
            orgName={state.orgName}
            profile={userProfile}
            subscriptionModel={assistantSubscription}
            orgId={organizationId}
          />
        )}
      </div>
    </div>
  );
});
