import React, { useEffect } from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';

import { Spinner } from '../../molecules/Spinner';
import { STATUS_SYNC_TIMEOUT, SyncStatusUIModel } from './SyncStatusModel.ui';

import styles from './SyncStatus.module.css';

interface ISyncStatusProps {
  model: SyncStatusUIModel;
  isConnected: boolean;
  connectorName?: string;
  onFailedFilesClick: () => void;
}

export const SyncStatus = observer(({ model, isConnected, connectorName, onFailedFilesClick }: ISyncStatusProps) => {
  useEffect(() => {
    let interval;

    if (isConnected) {
      interval = setInterval(() => model.reloadConnectorSyncStats(), STATUS_SYNC_TIMEOUT);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [model, isConnected]);

  return (
    <div className={styles.container}>
      {model.nextSync && (
        <div className={styles.block}>
          <div className={styles.headWrapper}>
            <Text upperCase variant={TextSize.M} color={TextColor.BLACK}>
              Next Sync
            </Text>
          </div>
          <div className={styles.dateWrapper}>
            <Text variant={TextSize.XXXXL} color={TextColor.BLACK}>
              {model.nextSync.formattedDate}
            </Text>
          </div>
        </div>
      )}

      {model.inProgressSync && (
        <div className={styles.block}>
          <div className={styles.headWrapper}>
            <Text upperCase variant={TextSize.M} color={TextColor.BLACK}>
              Sync in progress
            </Text>
          </div>
          <div className={styles.dateWrapper}>
            <Text variant={TextSize.XXXXL} color={TextColor.BLACK}>
              Started {model.inProgressSync.formattedDate}
            </Text>
          </div>
          <div className={styles.syncWrapper}>
            <Spinner
              progress={75}
              size={16}
              circleWidth={3}
              progressWidth={3}
              circleColor="#D4D6DB"
              progressColor="#000000"
            />
            <Text variant={TextSize.XL} color={TextColor.GREY}>
              {model.inProgressSync.inProgressFiles}/{model.inProgressSync.allFiles} files synced from {connectorName}
            </Text>
          </div>
        </div>
      )}

      {model.previousSync && (
        <div className={styles.block}>
          <div className={styles.headWrapper}>
            <Text upperCase variant={TextSize.M} color={TextColor.BLACK}>
              Previous sync
            </Text>
          </div>
          <div className={styles.dateWrapper}>
            <Text variant={TextSize.XXXXL} color={TextColor.BLACK}>
              {model.previousSync.formattedDate}
            </Text>
          </div>
          <div className={styles.syncWrapper}>
            <Text variant={TextSize.XL} color={TextColor.GREY}>
              {model.previousSync.inProgressFiles}/{model.previousSync.allFiles} files synced from {connectorName}
            </Text>
          </div>

          {(model.previousSync.failed > 0 || model.previousSync.unsupported > 0) && (
            <div className={styles.actionsWrapper}>
              {model.previousSync.failed > 0 && (
                <div className={styles.rowWrapper}>
                  <div className={styles.redDot} />
                  <Text variant={TextSize.XL} color={TextColor.GREY}>
                    <span className={styles.textUnderline} onClick={onFailedFilesClick}>
                      {model.previousSync.failed} {wordPluralize(model.previousSync.failed, 'file')}
                    </span>{' '}
                    didn’t sync
                  </Text>
                </div>
              )}

              {model.previousSync.unsupported > 0 && (
                <div className={styles.rowWrapper}>
                  <div className={styles.grayDot} />
                  <Text variant={TextSize.XL} color={TextColor.GREY}>
                    {model.previousSync.unsupported} other {wordPluralize(model.previousSync.unsupported, 'file')}{' '}
                    aren’t supported.{' '}
                    <a
                      href="https://support.writer.com/article/251-setting-up-knowledge-graph-data-connectors#Managing-data-connectors-MPWUC"
                      target="_blank"
                      className={styles.link}
                    >
                      Learn more.
                    </a>
                  </Text>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
