import React, { useEffect } from 'react';

interface IFormProps {
  email: string;
}

export const CustomizationForm = ({ email }: IFormProps) => {
  useEffect(() => {
    const embeddedParams = `isIframeEmbed=1&email=${email}`;
    const ifr = document.getElementById('JotFormIFrame-222286833358059') as HTMLIFrameElement | null;

    if (ifr) {
      let { src } = ifr;
      let iframeParams: string[] = [];

      if (window.location.href && window.location.href.indexOf('?') > -1) {
        iframeParams = iframeParams.concat(
          window.location.href.substr(window.location.href.indexOf('?') + 1).split('&'),
        );
      }

      if (src && src.indexOf('?') > -1) {
        iframeParams = iframeParams.concat(src.substr(src.indexOf('?') + 1).split('&'));
        src = src.substr(0, src.indexOf('?'));
      }

      iframeParams.push(embeddedParams);

      ifr.src = `${src}?${iframeParams.join('&')}`;
    }
  }, [email]);

  return (
    <div>
      <iframe
        id="JotFormIFrame-222286833358059"
        title="Rewrite customization request"
        allow="geolocation; microphone; camera"
        src="https://form.jotform.com/222286833358059"
        frameBorder="0"
        style={{
          width: '90%',
          height: 1050,
          border: 'none',
          margin: '-110px 5% 0 5%',
          overflow: 'hidden',
        }}
        scrolling="no"
      />
    </div>
  );
};

export default CustomizationForm;
