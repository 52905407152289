import type React from 'react';

import cx from 'classnames';

import styles from './Card.module.css';

interface ICardProps {
  className?: string;
  children?: React.ReactNode;
  withBorder?: boolean;
  withShadow?: boolean;
  highlight?: boolean;
}

export const Card: React.FC<ICardProps> = ({
  className,
  highlight = false,
  withBorder = true,
  withShadow = true,
  children,
}) => {
  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.withBorder]: withBorder,
          [styles.withShadow]: withShadow,
          [styles.highlight]: highlight,
        },
        className,
      )}
    >
      {children}
    </div>
  );
};
