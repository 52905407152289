import type React from 'react';
import { useEffect, useMemo } from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Logo,
  SizeTypes,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import { ResearchAssistantTemplateResponsive } from '@writercolab/ui-research-assistant';

import { LoadingPage } from '@web/component-library';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';

import { ROUTE } from '../../../services/config/routes';
import requestService from '../../../services/request/requestService';
import { useAppState } from '../../../state';
import { ResearchAssistantPageUIModel } from './ResearchAssistantPageModel.ui';

import styles from './ResearchAssistant.module.css';

const AppLogo: React.FC<{
  onLogoClick?: () => void;
  onBackButtonClick?: () => void;
}> = ({ onBackButtonClick, onLogoClick }) => (
  <div className={styles.logoContainer}>
    {onBackButtonClick && (
      <Button
        className={styles.backButton}
        type={ButtonTypes.TRANSPARENT}
        icon={<Icon name={IconVariant.BACK_ARROW} />}
        size={SizeTypes.SMALL}
        onClick={onBackButtonClick}
        round
      />
    )}
    <Logo
      onClick={onLogoClick}
      className={cx(styles.logo, {
        [styles.clickable]: !!onLogoClick,
      })}
    />
  </div>
);

export const ResearchAssistantPage: React.FC = observer(() => {
  const navigate = useNavigate();
  const params = useParams();

  const {
    appState: { teamId, organizationId },
    appModel: { analyticsService },
  } = useAppState();

  const model = useMemo(() => {
    if (!organizationId || !teamId || !params.appId) {
      return undefined;
    }

    return new ResearchAssistantPageUIModel({
      analyticsService,
      request: requestService.api,
      appId: params.appId,
      organizationId,
      teamId,
    });
  }, [organizationId, teamId, analyticsService, params.appId]);

  if (!model) {
    return null;
  }

  const navigateToDashboard = () => {
    analyticsService.track(AnalyticsActivity.appExited, {
      app_id: model.application?.id ?? '',
      app_name: model.application?.name ?? '',
      app_type: model.application?.type ?? '',
    });
    navigate(ROUTE.toHome(organizationId, teamId));
  };

  useEffect(() => {
    if (model.$application.status === 'rejected') {
      navigateToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model.$application.status]);

  useQueueWorkerNotifications(model.notificationQueue);

  const handleSubmit = () => {
    analyticsService.track(AnalyticsActivity.appGenerated, {
      app_id: model.application?.id ?? '',
      app_name: model.application?.name ?? '',
      app_type: model.application?.type ?? '',
      built_by_writer: model.application?.writer?.access === 'public',
    });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <AppLogo onLogoClick={navigateToDashboard} onBackButtonClick={navigateToDashboard} />
      </div>
      <div className={styles.applicationContainer}>
        {model.application ? (
          <ResearchAssistantTemplateResponsive
            model={model.researchAssistantUIModel}
            onSubmit={handleSubmit}
            isFullScreen
          />
        ) : (
          <LoadingPage />
        )}
      </div>
    </div>
  );
});
