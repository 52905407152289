import { useEffect, useState } from 'react';

import { DEFAULT_PORTAL_DOMAIN, stripHttpProtocol } from '@writercolab/common-utils';

import { IPortalGlobalNavState } from '../components/organisms/GlobalNav';

import { APP_ENV, DEV_ENV_PREFIX, TEST_ENV_PREFIX } from '../services/config/constants';
import { ANGULAR_ROUTE } from '../services/config/routes';
import { getPortalSettings } from '../services/request/portal';
import config from '../utils/dynamicConfig';

const { VITE_ENV } = import.meta.env;

const initState: IPortalGlobalNavState = {
  show: false, // false for accounts with no Portal feature (pro & free accounts)
  isPublished: false,
  name: '',
  url: '',
  adminPanelUrl: '',
};

export default function usePortal(orgId: number | undefined, isModelReady: boolean, isFree: boolean) {
  const [portal, setPortal] = useState<IPortalGlobalNavState>(initState);
  const isPortalDisabled = config.SINGLE_TENANCY_CONFIG?.disablePortal === true;

  useEffect(() => {
    if (orgId && isModelReady && !isPortalDisabled) {
      const access = {
        show: !isFree,
      };

      getPortalSettings(orgId)
        .then(({ result }) => {
          if (!result) {
            throw new Error();
          }

          const _portalUrl = result.customDomain
            ? result.customDomain
            : computedDomainNameAccordingToEnv(result.subDomain);
          const _portalName = stripHttpProtocol(_portalUrl);
          setPortal({
            show: access.show,
            name: _portalName,
            url: _portalUrl,
            isPublished: result.status === 'live',
            adminPanelUrl: ANGULAR_ROUTE.toPortal(orgId),
          } as IPortalGlobalNavState);
        })
        .catch(() => {
          setPortal({ ...initState, adminPanelUrl: ANGULAR_ROUTE.toPortal(orgId), show: access.show });
        });
    }
  }, [orgId, isModelReady, isFree, isPortalDisabled]);

  return { portal, isPortalDisabled };
}

const computedDomainNameAccordingToEnv = (subDomain: string, mainDomain: string = DEFAULT_PORTAL_DOMAIN) => {
  if (VITE_ENV === APP_ENV.PROD) {
    return `${subDomain}${mainDomain}`;
  }

  let envPrefix = DEV_ENV_PREFIX;

  switch (VITE_ENV) {
    case APP_ENV.TEST:
      envPrefix = TEST_ENV_PREFIX;
      break;
    default:
      if (/tst/.test(config.APP_ROOT)) {
        envPrefix = TEST_ENV_PREFIX;
      }
  }

  return `${subDomain}.portal.qordoba${envPrefix}.com`;
};
