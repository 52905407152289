import React from 'react';

import cx from 'classnames';

import { formatAmount, formatPrice, wordPluralize } from '@writercolab/common-utils';
import { Text, TextSize } from '@writercolab/ui-atoms';

import styles from './BillingSeatsNotifier.module.css';

interface BillingSeatsNotifierProps {
  seatsCount: number;
  usersCount: number;
  priceForAdditionalUser: number;
  futureUserCount: number;
  teamLimitReached: boolean;
  onBillingClick: () => void;
  monthlyBills?: boolean;
}

export const BillingSeatsNotifier: React.FC<BillingSeatsNotifierProps> = ({
  seatsCount,
  usersCount,
  priceForAdditionalUser,
  futureUserCount,
  onBillingClick,
  teamLimitReached,
  monthlyBills = true,
}) => {
  const futurePrice = (usersCount - seatsCount) * formatAmount(priceForAdditionalUser);
  const futurePriceFormat = formatPrice(futurePrice);
  const showMessage = !teamLimitReached && futureUserCount > 0 && futurePrice > 0 && usersCount > seatsCount;

  return (
    <div className={styles.seatsHolder}>
      <div className={styles.seatsContainer}>
        <div>
          <Text
            bold
            variant={TextSize.L}
            className={cx(styles.textGreen, {
              [styles.seatsLimitNumberDanger]: teamLimitReached,
            })}
          >
            {usersCount} {wordPluralize(usersCount, 'User')}
          </Text>
        </div>
        <div className={styles.seatsSeparator} />
        <div className={cx(styles.seatsLimitNumber)}>
          <Text variant={TextSize.XXXS} extraSmallCaps>
            {seatsCount} {wordPluralize(seatsCount, 'Seat')}
          </Text>
        </div>
      </div>
      {showMessage && (
        <div className={styles.seatsContainerDescription}>
          <Text variant={TextSize.XS}>
            You'll be billed {futurePriceFormat} per {monthlyBills ? 'month' : 'year'} for {futureUserCount} new{' '}
            {wordPluralize(futureUserCount, 'seat')}. See{' '}
            <button type="button" onClick={onBillingClick} className={styles.seatsContainerButton}>
              billing
            </button>{' '}
            for more info.
          </Text>
        </div>
      )}
    </div>
  );
};

export default BillingSeatsNotifier;
