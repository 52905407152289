import React from 'react';

import { ITeam } from '@writercolab/types';
import { AVATAR_COLOR, AvatarSize, UserAvatar } from '@writercolab/ui-atoms';

import { getTeamColor } from '../../../utils/teamUtils';

interface ITeamAvatarProps extends BaseComponentsProps {
  team: ITeam;
}

const TeamAvatar: React.FC<ITeamAvatarProps> = ({ className, team }) => {
  const color = AVATAR_COLOR[getTeamColor(team.id)];

  return <UserAvatar className={className} abbreviation={team.name} customColor={color} size={AvatarSize.XS} />;
};

export default TeamAvatar;
