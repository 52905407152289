import React from 'react';

import { Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

export const InfoIcon = ({ onClick }) => (
  <span className={styles.infoIconHolder} onClick={onClick}>
    <Icon name={IconVariant.INFO_OUTLINED} />
  </span>
);

export default InfoIcon;
