import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IRemovePersonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (state) => void;
  person: any;
  submitButtonText: string;
  orgName: string;
  isEnterpriseAccount: boolean;
  isMultiTeamAccount: boolean;
  modalText: { title: string; description: (name, orgName) => string };
  orgId?: number | undefined;
}

export const RemovePersonModal: React.FC<IRemovePersonModalProps> = ({
  isOpen,
  onClose,
  person,
  orgName,
  onSubmit,
  modalText,
  submitButtonText,
  ...props
}) => {
  const onSubmitClick = () => {
    onSubmit(person);
    onClose();
  };

  return (
    <Modal open={isOpen} handleClose={onClose} title={modalText.title} style={{ width: '335px' }} {...props}>
      <div className={styles.styledDeleteContent}>
        <Text variant={TextSize.M}>{modalText.description(person?.fullName || person?.email, orgName)}</Text>
        <div className={styles.controlButtons}>
          <Button className={styles.submitButton} type={ButtonTypes.DEFAULT} content="Never mind" onClick={onClose} />
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content={submitButtonText}
            onClick={onSubmitClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default RemovePersonModal;
