import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import cx from 'classnames';

import { Dropdown, DropdownOption, Icon, IconVariant, Text } from '@writercolab/ui-atoms';

import { useDetectOutsideClick } from '@web/component-library';

import styles from './styles.module.css';

interface CheckoutDropdownProps extends BaseComponentsProps {
  onOptionClickAction: (id: string) => void;
  options: DropdownOption[];
}

const Trigger: FC<{ activeCountryName: React.ReactNode }> = ({ activeCountryName }) => (
  <div className={styles.containerTrigger}>
    <Text>{activeCountryName}</Text>
    <Icon name={IconVariant.DROP_DOWN_ARROW} />
  </div>
);

export const CheckoutDropdown: React.FC<CheckoutDropdownProps> = ({ onOptionClickAction, options, className }) => {
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [activeCountryCode, setActiveCountryCode] = useState<string | null>(null);
  const [activeCountryName, setActiveCountryName] = useState<string | null>(null);
  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [isInputFocused, setIsInputFocused] = useDetectOutsideClick(dropdownRef, false);

  const handleContainerClick = useCallback(() => setIsInputFocused(true), [setIsInputFocused]);

  useEffect(() => {
    if (options) {
      setDropdownOptions(options);
      const actionOption = options.find(option => option.active);

      if (actionOption) {
        onOptionClickAction && onOptionClickAction(actionOption.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleSelectCounty = useCallback(
    (id: string) => {
      const options = dropdownOptions.map(option => {
        const optionItem = option;
        optionItem.active = option.id === id;

        return optionItem;
      });
      setDropdownOptions(options);
      const actionOption = options.find(option => option.active);

      if (actionOption) {
        setActiveCountryName(actionOption.name);
      }

      onOptionClickAction && onOptionClickAction(id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dropdownOptions],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setIsInputFocused(false), [activeCountryCode]);
  useEffect(() => {
    const active = options.find(item => item.active);

    if (active) {
      setActiveCountryName(active.name);
      setActiveCountryCode(active.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <div
      onClick={handleContainerClick}
      className={cx(styles.container, className, {
        [styles.containerFocus]: isInputFocused,
      })}
      ref={dropdownRef}
    >
      <Dropdown
        trigger={<Trigger activeCountryName={activeCountryName} />}
        options={dropdownOptions}
        onPrimaryOptionClickAction={handleSelectCounty}
        className={styles.containerDropdown}
        highlightActiveOption={false}
      />
    </div>
  );
};

export default CheckoutDropdown;
