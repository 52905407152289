import { useLocation } from 'react-router';

/**
 * Uses URLSearchParams to access the URL
 * - to get a QueryParam => useQuery().get('sortField')
 * - to set a QueryParam => useQuery().set('sortOrder', 'asc')
 * - to get entire => useQuery().toString() => "sortField=creationTime&sortOrder=asc"
 *
 * @return {URLSearchParams}
 */
export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}
