import { StyleAttributor, Attributor } from 'parchment';

export const WidthStyleAttributor = new StyleAttributor('width', 'width');
export const HeightStyleAttributor = new StyleAttributor('height', 'height');
export const MarginStyleAttributor = new StyleAttributor('margin', 'margin');
export const AltAttributor = new Attributor('alt', 'alt');
export const StreamTypeAttributor = new Attributor('stream-type', 'stream-type');
export const PlaybackIdAttributor = new Attributor('playback-id', 'playback-id');
export const ClipMaxResolutionAttributor = new Attributor('clip-max-resolution', 'clip-max-resolution');
export const ClipLabelAttributor = new Attributor('clip-label', 'clip-label');
