import type React from 'react';
import { useMemo } from 'react';

import { IconsConsoleResearchAssistant } from '@writercolab/assets';
import type { IconVariant } from '@writercolab/ui-atoms';
import { LinkText, TextSize } from '@writercolab/ui-atoms';

import { AppTypeTile } from 'components/molecules/AppTypeTile';

import type { TApplicationBriefResponse, TBaseApplicationType } from '@web/types';
import { EApplicationType, noCodeApps } from '@web/types';
import { cn } from '@web/utils';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';

import { BaseTable, IRowProps } from '../BaseTable/BaseTable';
import type { AiStudioHomeWidgetModelUi } from './AiStudioHomeWidgetModel.ui';
import { generateRecentAppsTableBody } from './utils';

import styles from './AiStudioHomeWidget.module.css';

interface IAiStudioHomeWidgetProps {
  model: AiStudioHomeWidgetModelUi;
  onAppClick: (app: TApplicationBriefResponse) => void;
  onAppCreate: (appType: TBaseApplicationType) => void;
}

const EmptyState: React.FC<{
  isResearchAppAvailable?: boolean;
  noCodeAppsDisabled?: boolean;
  onSelect: (appType: TBaseApplicationType) => void;
}> = ({ isResearchAppAvailable, noCodeAppsDisabled, onSelect }) => (
  <div className={styles.emptyContainer}>
    {Object.entries(noCodeApps).map(([id, appType]) => {
      let icon: IconVariant | React.ReactNode;

      if (id === 'research' && isResearchAppAvailable) {
        icon = <IconsConsoleResearchAssistant />;
      } else {
        icon = appType.icon;
      }

      return (
        <AppTypeTile
          id={id}
          key={id}
          isDisabled={noCodeAppsDisabled}
          preview={id === 'research' ? !isResearchAppAvailable : appType.preview}
          icon={icon}
          name={appType.name}
          description={appType.description}
          onClick={id => onSelect(EApplicationType.get(id, EApplicationType.enum.generation))}
          titleVariant={TextSize.XL}
          descriptionVariant={TextSize.M}
        />
      );
    })}
  </div>
);

const tableHeaders = [
  {
    component: <p className={cn(styles.columnTitle, 'text-sm')}>YOUR DRAFTS</p>,
  },
  {
    component: <p className={cn(styles.columnTitle, 'text-sm')}>CREATED</p>,
  },
  {
    component: <p className={cn(styles.columnTitle, 'text-sm')}>APP TYPE</p>,
  },
];

export const AiStudioHomeWidget: React.FC<IAiStudioHomeWidgetProps> = observer(({ model, onAppClick, onAppCreate }) => {
  const { recentApps, isLoading } = model;
  const hasRecentApps = recentApps && recentApps.length > 0;
  const widgetCardTitle = hasRecentApps ? 'Keep building in AI Studio' : 'Start building in AI Studio';
  const showDescription = !hasRecentApps && !isLoading;

  const tableData = useMemo(() => generateRecentAppsTableBody({ data: recentApps }), [recentApps]);
  const showEmpty = !isLoading && isEmpty(tableData);

  return (
    <div>
      <p className={cn(styles.headingTitle, 'text-[16px] font-medium')}>{widgetCardTitle}</p>
      {showDescription && (
        <p className="text-base">
          Use our no-code builder to create your own custom app.
          <LinkText className={styles.learnMoreLink} target="_blank" href="https://dev.writer.com/home/introduction">
            Learn more
          </LinkText>
        </p>
      )}
      {showEmpty ? (
        <EmptyState isResearchAppAvailable onSelect={onAppCreate} />
      ) : (
        <BaseTable
          containerClassName={styles.tableContainer}
          headers={tableHeaders}
          data={tableData}
          loading={isLoading}
          customLoading={<Skeleton count={3} height={20} style={{ marginTop: 20 }} />}
          headerRowClassName={styles.flexStart}
          bodyRowClassName={styles.flexStart}
          isEnableSticky={false}
          gridTemplateColumns="2fr 1fr 1fr"
          headerRowHeight="36px"
          bodyClassName={styles.resetPadding}
          headerClassName={styles.resetPadding}
          onRowClick={(row: IRowProps<TApplicationBriefResponse>) => row.data && onAppClick(row.data)}
        />
      )}
    </div>
  );
});
