import { useEffect, useState } from 'react';
import type { IOrganization } from '@writercolab/common-utils';
import { getOrganizations } from '@writercolab/common-utils';
import { getLogger } from '../utils/logger';

const LOG = getLogger('useOrganizations');

type IOrganizationsList = IOrganization[];

interface IUseOrganizations {
  confirmed: IOrganizationsList;
  unconfirmed: IOrganizationsList;
}

export function useOrganizations() {
  const [organizations, setOrganizations] = useState<IOrganizationsList>();
  const [unconfirmedOrganizations, setUnconfirmedOrganizations] = useState<IOrganizationsList>();
  const [fetchingOrganizations, setFetchingOrganizations] = useState(false);

  const fetchOrganizations = async (): Promise<IUseOrganizations> => {
    const initialOrganizationsList: IUseOrganizations = { confirmed: [], unconfirmed: [] };
    setFetchingOrganizations(true);
    try {
      const organizationsRes = await getOrganizations();
      const organizations: IUseOrganizations = organizationsRes.reduce((acc: IUseOrganizations, org: IOrganization) => {
        if (org.approvedByInviter) {
          acc.confirmed.push(org);
        } else {
          acc.unconfirmed.push(org);
        }

        return acc;
      }, initialOrganizationsList);

      setOrganizations(organizations.confirmed);
      setUnconfirmedOrganizations(organizations.unconfirmed);

      return organizations;
    } catch (e) {
      LOG.error(e);

      return initialOrganizationsList;
    } finally {
      setFetchingOrganizations(false);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  return { organizations, unconfirmedOrganizations, fetchOrganizations, fetchingOrganizations };
}

export default useOrganizations;
