import { ICoWriteKeyPointSection } from '@writercolab/common-utils';
import { generateRandomString } from '@writercolab/utils';

import { IKeyPointsSection, IOutlineSection } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

export const generateOutlineSection = (): IOutlineSection => ({
  id: generateRandomString(),
  name: '',
});

export const extractOutlineSectionNames = (outlines: IOutlineSection[]): string[] =>
  outlines.map(outline => trim(outline.name));

const generateKeyPointSection = (outline: string, points: string[], loading?: boolean): IKeyPointsSection => ({
  loading,
  id: generateRandomString(),
  name: outline,
  keyPoints: points.map(point => ({
    id: generateRandomString(),
    name: trim(point),
  })),
});

export const formatKeyPoints = (keyPointsSections: IKeyPointsSection[]): ICoWriteKeyPointSection[] =>
  keyPointsSections.map(section => ({
    outline: trim(section.name),
    points: section.keyPoints.map(keyPoint => trim(keyPoint.name)).filter(item => !isEmpty(item)),
  }));

export const mapKeyPointSection = (sections: ICoWriteKeyPointSection[]): IKeyPointsSection[] =>
  sections.map(item => generateKeyPointSection(item.outline, item.points));
