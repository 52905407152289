import React from 'react';

import cx from 'classnames';

import { Modal } from '@writercolab/ui-atoms';

import styles from './DeleteConfirmationModal.module.css';

interface DeleteConfirmationModalProps {
  isOpen: boolean;
  bodyContent?: React.ReactNode;
  modalTitle?: string;
  modalTitleClassName?: string;
  closeButton?: React.ReactNode;
  submitButton?: React.ReactNode;
  modalClassName?: string;
  onClose?: () => void;
  wrapperClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  isOpen,
  modalTitle,
  modalTitleClassName,
  bodyContent,
  closeButton,
  submitButton,
  modalClassName,
  wrapperClassName,
  bodyClassName,
  footerClassName,

  onClose,
  ...props
}) =>
  isOpen ? (
    <Modal
      hideCloseButton
      open={isOpen}
      handleClose={onClose}
      title={modalTitle}
      titleClassName={cx(styles.modalTitle, modalTitleClassName)}
      className={cx(styles.modalWrapper, modalClassName)}
      {...props}
    >
      <div className={cx(styles.wrapper, wrapperClassName)}>
        <div className={cx(styles.body, bodyClassName)}>{bodyContent}</div>
        <div className={cx(styles.footer, footerClassName)}>
          {closeButton}
          {submitButton}
        </div>
      </div>
    </Modal>
  ) : null;

DeleteConfirmationModal.displayName = 'DeleteConfirmationModal';
