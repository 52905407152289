import { NormalizedState } from '@web/types';

export function normalizeByProperty<T>(payload: T[], prop: string) {
  const result: NormalizedState<T> = {
    byId: {},
    allIds: [],
  };

  if (prop && Array.isArray(payload))
    payload.forEach((item: T) => {
      result.byId[item[prop]] = item;
      result.allIds.push(item[prop]);
    });

  return result;
}
