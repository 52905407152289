import React from 'react';

import { Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './GoBackArrow.module.css';

interface IGoBackArrowProps {
  onGoBackClick: () => void;
}

export const GoBackArrow: React.FC<IGoBackArrowProps> = ({ onGoBackClick }) => (
  <div className={styles.backButtonWrapper} onClick={onGoBackClick}>
    <div className={styles.roundButtonContainer}>
      <Icon name={IconVariant.ARROW_LEFT} />
    </div>
    <Text className={styles.backButtonText} variant={TextSize.XS} medium upperCase>
      Back
    </Text>
  </div>
);
