import { Request, UserTeamRole } from '@writercolab/common-utils';

import { AxiosResponse } from 'axios';

import { getLogger } from '../../utils/logger';

const LOG = getLogger('serviceAccount');

interface ICreateServiceAccount {
  name: string;
  organizationRole: UserTeamRole;
  teamRoles: any;
}

interface IIssueServiceAccount {
  token: string;
}

export const createServiceAccount = async (orgId: number | undefined, accountName: string): Promise<void> => {
  await Request.getAxiosInstance().post<ICreateServiceAccount>(`/user/v2/organization/${orgId}/service-account`, {
    name: accountName,
    teamRoles: {},
    organizationRole: UserTeamRole.ADMIN,
  });
};

export const deleteServiceAccount = async (orgId: number | undefined, accountId: number): Promise<void> => {
  await Request.getAxiosInstance().delete<ICreateServiceAccount>(
    `/user/v2/organization/${orgId}/service-account/${accountId}`,
  );
};

export const issueServiceAccountToken = async (orgId: number | undefined, accountId: number): Promise<string> => {
  const { data } = await Request.getAxiosInstance().put<void, AxiosResponse<IIssueServiceAccount>>(
    `/auth/organization/${orgId}/service-account/${accountId}/token`,
  );

  return data.token;
};

export const getServiceAccountTokens = async (orgId: number | undefined, accountId: number): Promise<string> => {
  let token: string;

  try {
    const { data } = await Request.getAxiosInstance().get(
      `/auth/organization/${orgId}/service-account/${accountId}/token`,
    );
    token = data.token;
  } catch (err) {
    LOG.error(err);
    token = await issueServiceAccountToken(orgId, accountId);
  }

  return token;
};
