import React from 'react';

import styles from '../styles.module.css';

interface ExampleBlockProps {
  children: React.ReactNode;
}

export const ExampleBlock: React.FC<ExampleBlockProps> = ({ children }) => (
  <div className={styles.exampleBlock}>{children}</div>
);

export default ExampleBlock;
