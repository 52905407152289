import Quill, { Module } from 'quill';

interface IFontsModuleOptions {}

export class FontsModule extends Module<IFontsModuleOptions> {
  static fontsList = [
    {
      title: 'Allerta Stencil',
      url: 'https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap&wght=400;600&italic=1',
      name: 'allerta-stencil',
    },
    { title: 'Arial', name: 'arial' },
    { title: 'Arial Black', name: 'arial-black' },
    {
      title: 'Arvo',
      url: 'https://fonts.googleapis.com/css2?family=Arvo&display=swap&wght=400;600&italic=1',
      name: 'arvo',
    },
    {
      title: 'Barlow',
      url: 'https://fonts.googleapis.com/css2?family=Barlow&display=swap&wght=400;600&italic=1',
      name: 'barlow',
    },
    {
      title: 'Bree',
      url: 'https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap&wght=400;600&italic=1',
      name: 'bree',
    },
    {
      title: 'Cabin',
      url: 'https://fonts.googleapis.com/css2?family=Cabin&display=swap&wght=400;600&italic=1',
      name: 'cabin',
    },
    {
      title: 'Caudex',
      url: 'https://fonts.googleapis.com/css2?family=Caudex&display=swap&wght=400;600&italic=1',
      name: 'caudex',
    },
    {
      title: 'Chelsea Market',
      url: 'https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap&wght=400;600&italic=1',
      name: 'chelsea-market',
    },
    {
      title: 'Chivo',
      url: 'https://fonts.googleapis.com/css2?family=Chivo&display=swap&wght=400;600&italic=1',
      name: 'chivo',
    },
    {
      title: 'Cinzel',
      url: 'https://fonts.googleapis.com/css2?family=Cinzel&display=swap&wght=400;600&italic=1',
      name: 'cinzel',
    },
    {
      title: 'Cookie',
      url: 'https://fonts.googleapis.com/css2?family=Cookie&display=swap&wght=400;600&italic=1',
      name: 'cookie',
    },
    {
      title: 'Corben',
      url: 'https://fonts.googleapis.com/css2?family=Corben&display=swap&wght=400;600&italic=1',
      name: 'corben',
    },
    {
      title: 'Cormorant Garamond',
      url: 'https://fonts.googleapis.com/css2?family=Cormorant+Garamond&display=swap&wght=400;600&italic=1',
      name: 'cormorant-garamond',
    },
    { title: 'Courier', name: 'courier' },
    { title: 'Courier New', name: 'courier-new' },
    {
      title: 'Damion',
      url: 'https://fonts.googleapis.com/css2?family=Damion&display=swap&wght=400;600&italic=1',
      name: 'damion',
    },
    {
      title: 'Dancing Script',
      url: 'https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap&wght=400;600&italic=1',
      name: 'dancing-script',
    },
    {
      title: 'Domine',
      url: 'https://fonts.googleapis.com/css2?family=Domine&display=swap&wght=400;600&italic=1',
      name: 'domine',
    },
    {
      title: 'DM Serif Display',
      url: 'https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap&wght=400;600&italic=1',
      name: 'dm-serif-display',
    },
    {
      title: 'Droid Serif',
      url: 'https://fonts.googleapis.com/css2?family=Droid+Serif&display=swap&wght=400;600&italic=1',
      name: 'droid-serif',
    },
    {
      title: 'EB Garamond',
      url: 'https://fonts.googleapis.com/css2?family=EB+Garamond&display=swap&wght=400;600&italic=1',
      name: 'eb-garamond',
    },
    {
      title: 'Enriqueta',
      url: 'https://fonts.googleapis.com/css2?family=Enriqueta&display=swap&wght=400;600&italic=1',
      name: 'enriqueta',
    },
    {
      title: 'Fira Sans',
      url: 'https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap&wght=400;600&italic=1',
      name: 'fira-sans',
    },
    {
      title: 'Forum',
      url: 'https://fonts.googleapis.com/css2?family=Forum&display=swap&wght=400;600&italic=1',
      name: 'forum',
    },
    { title: 'Georgia', name: 'georgia' },
    { title: 'Helvetica', name: 'helvetica' },
    {
      title: 'Impact',
      url: 'https://fonts.googleapis.com/css2?family=Impact&display=swap&wght=400;600&italic=1',
      name: 'impact',
    },
    {
      title: 'Jockey One',
      url: 'https://fonts.googleapis.com/css2?family=Jockey+One&display=swap&wght=400;600&italic=1',
      name: 'jockey-one',
    },
    {
      title: 'Josefin Slab',
      url: 'https://fonts.googleapis.com/css2?family=Josefin+Slab&display=swap&wght=400;600&italic=1',
      name: 'josefin-slab',
    },
    {
      title: 'Jura',
      url: 'https://fonts.googleapis.com/css2?family=Jura&display=swap&wght=400;600&italic=1',
      name: 'jura',
    },
    {
      title: 'Kelly Slab',
      url: 'https://fonts.googleapis.com/css2?family=Kelly+Slab&display=swap&wght=400;600&italic=1',
      name: 'kelly-slab',
    },
    {
      title: 'Lato',
      url: 'https://fonts.googleapis.com/css2?family=Lato&display=swap&wght=400;600&italic=1',
      name: 'lato',
    },
    {
      title: 'Libre Baskerville',
      url: 'https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap&wght=400;600&italic=1',
      name: 'libre-baskerville',
    },
    {
      title: 'Libre Franklin',
      url: 'https://fonts.googleapis.com/css2?family=Libre+Franklin&display=swap&wght=400;600&italic=1',
      name: 'libre-franklin',
    },
    {
      title: 'Lobster',
      url: 'https://fonts.googleapis.com/css2?family=Lobster&display=swap&wght=400;600&italic=1',
      name: 'lobster',
    },
    {
      title: 'Lora',
      url: 'https://fonts.googleapis.com/css2?family=Lora&display=swap&wght=400;600&italic=1',
      name: 'lora',
    },
    {
      title: 'Marck Script',
      url: 'https://fonts.googleapis.com/css2?family=Marck+Script&display=swap&wght=400;600&italic=1',
      name: 'marck-script',
    },
    {
      title: 'Merriweather',
      url: 'https://fonts.googleapis.com/css2?family=Merriweather&display=swap&wght=400;600&italic=1',
      name: 'merriweather',
    },
    {
      title: 'Monoton',
      url: 'https://fonts.googleapis.com/css2?family=Monoton&display=swap&wght=400;600&italic=1',
      name: 'monoton',
    },
    {
      title: 'Montserrat',
      url: 'https://fonts.googleapis.com/css2?family=Montserrat&display=swap&wght=400;600&italic=1',
      name: 'montserrat',
    },
    {
      title: 'Niconne',
      url: 'https://fonts.googleapis.com/css2?family=Niconne&display=swap&wght=400;600&italic=1',
      name: 'niconne',
    },
    {
      title: 'Noticia Text',
      url: 'https://fonts.googleapis.com/css2?family=Noticia+Text&display=swap&wght=400;600&italic=1',
      name: 'noticia-text',
    },
    {
      title: 'Open Sans',
      url: 'https://fonts.googleapis.com/css2?family=Open+Sans&display=swap&wght=400;600&italic=1',
      name: 'open-sans',
    },
    {
      title: 'Oswald',
      url: 'https://fonts.googleapis.com/css2?family=Oswald&display=swap&wght=400;600&italic=1',
      name: 'oswald',
    },
    {
      title: 'Overlock',
      url: 'https://fonts.googleapis.com/css2?family=Overlock&display=swap&wght=400;600&italic=1',
      name: 'overlock',
    },
    {
      title: 'Palatino Linotype',
      url: 'https://fonts.googleapis.com/css2?family=Palatino+Linotype&display=swap&wght=400;600&italic=1',
      name: 'palatino-linotype',
    },
    {
      title: 'Patrick Hand',
      url: 'https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap&wght=400;600&italic=1',
      name: 'patrick-hand',
    },
    {
      title: 'Play',
      url: 'https://fonts.googleapis.com/css2?family=Play&display=swap&wght=400;600&italic=1',
      name: 'play',
    },
    {
      title: 'Playfair Display',
      url: 'https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap&wght=400;600&italic=1',
      name: 'playfair-display',
    },
    { title: 'Poppins', name: 'poppins' },
    {
      title: 'Proxima Nova',
      url: 'https://fonts.googleapis.com/css2?family=Proxima+Nova&display=swap&wght=400;600&italic=1',
      name: 'proxima-nova',
    },
    {
      title: 'Questrial',
      url: 'https://fonts.googleapis.com/css2?family=Questrial&display=swap&wght=400;600&italic=1',
      name: 'questrial',
    },
    {
      title: 'Raleway',
      url: 'https://fonts.googleapis.com/css2?family=Raleway&display=swap&wght=400;600&italic=1',
      name: 'raleway',
    },
    {
      title: 'Roboto',
      url: 'https://fonts.googleapis.com/css2?family=Roboto&display=swap&wght=400;600&italic=1',
      name: 'roboto',
    },
    {
      title: 'Rozha One',
      url: 'https://fonts.googleapis.com/css2?family=Rozha+One&display=swap&wght=400;600&italic=1',
      name: 'rozha-one',
    },
    {
      title: 'Rubik',
      url: 'https://fonts.googleapis.com/css2?family=Rubik&display=swap&wght=400;600&italic=1',
      name: 'rubik',
    },
    {
      title: 'Sacramento',
      url: 'https://fonts.googleapis.com/css2?family=Sacramento&display=swap&wght=400;600&italic=1',
      name: 'sacramento',
    },
    {
      title: 'Signika',
      url: 'https://fonts.googleapis.com/css2?family=Signika&display=swap&wght=400;600&italic=1',
      name: 'signika',
    },
    {
      title: 'Source Sans Pro',
      url: 'https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap&wght=400;600&italic=1',
      name: 'source-sans-pro',
    },
    {
      title: 'Source Serif Pro',
      url: 'https://fonts.googleapis.com/css2?family=Source+Serif+Pro&display=swap&wght=400;600&italic=1',
      name: 'source-serif-pro',
    },
    {
      title: 'Space Mono',
      url: 'https://fonts.googleapis.com/css2?family=Space+Mono&display=swap&wght=400;600&italic=1',
      name: 'space-mono',
    },
    {
      title: 'Spinnaker',
      url: 'https://fonts.googleapis.com/css2?family=Spinnaker&display=swap&wght=400;600&italic=1',
      name: 'spinnaker',
    },
    {
      title: 'Suez One',
      url: 'https://fonts.googleapis.com/css2?family=Suez+One&display=swap&wght=400;600&italic=1',
      name: 'suez-one',
    },
    { title: 'Tahoma', name: 'tahoma' },
    { title: 'Times', name: 'times' },
    { title: 'Times New Roman', name: 'times-new-roman' },
    { title: 'Verdana', name: 'verdana' },
    {
      title: 'Work Sans',
      url: 'https://fonts.googleapis.com/css2?family=Work+Sans&display=swap&wght=400;600&italic=1',
      name: 'work-sans',
    },
  ];

  constructor(quill: Quill, options?: Partial<IFontsModuleOptions> | undefined) {
    super(quill, options);

    FontsModule.registerFonts();
  }

  static registerFonts() {
    FontsModule.fontsList.forEach(font => {
      if (font.url) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = font.url;
        document.head.appendChild(link);
      }
    });
  }
}

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Font as any).whitelist = FontsModule.fontsList.map(font => font.name);
