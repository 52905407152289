import React from 'react';

import cx from 'classnames';

import { SUPPORT_EMAIL, getDateFormatMMDDYYYY, openNewTab } from '@writercolab/common-utils';
import { Heading, HeadingVariant, LinkText, Text, TextSize } from '@writercolab/ui-atoms';

import { launchSupportEmail } from '../../../utils/navigationUtils';

import styles from './styles.module.css';

interface ITranscriptLimit extends BaseComponentsProps {
  isVisible: boolean;
  isEnterprise: boolean;
  resetDate: string;
  url: string;
}

const TranscriptLimit: React.FC<ITranscriptLimit> = ({ className, isVisible, isEnterprise, resetDate, url }) => {
  const date = getDateFormatMMDDYYYY(resetDate);
  const handleOpenNewTab = () => {
    openNewTab(url);
  };

  if (isVisible) {
    return (
      <div className={cx(className, styles.container)}>
        <Heading variant={HeadingVariant.H4} medium className={styles.heading}>
          You're out of media file transcriptions for this month!
        </Heading>
        <Text variant={TextSize.XL} className={styles.description}>
          Your usage resets on {date}.<br />
          {isEnterprise ? (
            <>
              Contact <LinkText onClick={launchSupportEmail}>{SUPPORT_EMAIL}</LinkText> for a higher limit.
              <br />
            </>
          ) : (
            <>
              <span className={styles.upgrade} onClick={handleOpenNewTab}>
                Upgrade
              </span>{' '}
              for a higher limit.{' '}
            </>
          )}
          In the meantime, you can still generate recaps from any existing media files, YouTube URLs, pasted
          transcripts, or by uploading a txt or srt file.
        </Text>
      </div>
    );
  } else {
    return null;
  }
};

export default TranscriptLimit;
