import { SuggestionCategoryType } from '@writercolab/common-utils';
import { StructureSection, StructureSectionType, StructureSubsectionDropdown } from '@writercolab/ui-molecules';

const structure = [
  {
    name: 'Capitalization',
    type: SuggestionCategoryType.CAPITALIZATION,
    structure: [
      {
        type: StructureSectionType.GROUP,
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Use sentence case for headlines and headings.',
            ruleId: 'HEADLINES_SENTENCE_CASE',
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'CAPS_COLON',
            dropdownSources: [['proper noun', 'proper noun or starts a complete sentence']],
            template: "Don't capitalize the first word after a colon unless it's a {0}",
            valuesConfig: {
              CAPS_COLON: {
                selected: ['proper noun'],
              },
              CAPS_COLON_SENTENCE: {
                selected: ['proper noun or starts a complete sentence'],
              },
            },
          },
          {
            type: StructureSectionType.RULE,
            description: "Don't use ALLCAPS unless you are writing an acronym.",
            ruleId: 'ALLCAPS',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Capitalize titles preceding names, but use lowercase after names.',
            example: [
              'Our Director of Marketing, Jane Doe, is excited to meet you. John Doe, director of marketing, will be joining us.',
            ],
            ruleId: 'EXACT_TITLE_CAPS',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Use all lowercase when writing out an email address or website URL.',
            ruleId: 'EMAIL_URL_LOWER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Capitalize the word "room" when followed by the room number.',
            ruleId: 'AP_ROOM',
            example: ['The panel will be held in Room 315.'],
          },
          {
            type: StructureSectionType.RULE,
            description:
              'Outside of quotations, always abbreviate Doctor, Governor, Representative and Senator before names.',
            ruleId: 'AP_DR_GOV_REP_SEN',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Avoid capitalizing words like "federal" or "state" unless the word is part of a formal name.',
            ruleId: 'AP_FED_STATE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Capitalize the word "Internet".',
            ruleId: 'CHI_INTERNET',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Capitalize the word "Web".',
            ruleId: 'CHI_WEB',
          },
        ],
      },
    ],
  },
  {
    name: 'Dates and numbers',
    type: SuggestionCategoryType.DATE_TIME,
    structure: [
      {
        type: StructureSectionType.GROUP,
        heading: 'Currencies and money',
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'MONEY_C_SYM_AMT_SPACES',
            dropdownSources: [
              ['symbol-amount', 'currency code-amount'],
              ['with', 'without'],
            ],
            template: 'For currencies, use the {0} format {1} spaces in between.',
            valuesConfig: {
              MONEY_C_SYM_AMT_SPACES: {
                selected: ['symbol-amount', 'with'],
                example: ['We spent $ 10,000.'],
              },
              MONEY_C_SYM_AMT_NO_SPACES: {
                selected: ['symbol-amount', 'without'],
                example: ['We spent $10,000.'],
              },
              MONEY_C_CODE_SPACES: {
                selected: ['currency code-amount', 'with'],
                example: ['We spent USD 10,000.'],
              },
              MONEY_C_CODE_NO_SPACES: {
                selected: ['currency code-amount', 'without'],
                example: ['We spent USD10,000.'],
              },
            },
          } as StructureSubsectionDropdown,
          {
            type: StructureSectionType.RULE,
            description: "Don't use periods in currency abbreviations.",
            ruleId: 'MONEY_C_CODE_NO_PERIODS',
            example: ['USD, not U.S.D.'],
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'LARGE_NUMBERS_NO_ABBR',
            dropdownSources: [
              [
                'Write out million, billion, and trillion in full',
                'Abbreviate “million”, “billion”, and “trillion” as “M”, “B”, and “T”',
                'Abbreviate “million”, “billion”, and “trillion” as “m”, “b”, and “t”',
              ],
            ],
            template: '{0}.',
            valuesConfig: {
              LARGE_NUMBERS_NO_ABBR: {
                selected: ['Write out million, billion, and trillion in full'],
                example: ['The total cost is $5 million.'],
              },
              LARGE_NUMBERS_CAPS: {
                selected: ['Abbreviate “million”, “billion”, and “trillion” as “M”, “B”, and “T”'],
                example: ['The total cost is $5M.'],
              },
              LARGE_NUMBERS_LOWERCASE: {
                selected: ['Abbreviate “million”, “billion”, and “trillion” as “m”, “b”, and “t”'],
                example: ['The total cost is $5m.'],
              },
            },
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'TH_COMMA_DEC_PERIOD',
            dropdownSources: [
              [
                'commas to separate millions and thousands and decimal points to separate units and subunits',
                'use periods to separate millions and thousands and decimal commas to separate units and subunits',
              ],
            ],
            template: 'When writing out a monetary value, use {0}.',
            valuesConfig: {
              TH_COMMA_DEC_PERIOD: {
                selected: [
                  'commas to separate millions and thousands and decimal points to separate units and subunits',
                ],
                example: ['The invoice was for $1,000.50.'],
              },
              TH_PERIOD_DEC_COMMA: {
                selected: [
                  'use periods to separate millions and thousands and decimal commas to separate units and subunits',
                ],
                example: ['The invoice was for €1.000,50.'],
              },
            },
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Dates, months, and years',
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'SHORT_DATE_D_M_Y',
            dropdownSources: [['DD/MM/YYYY', 'MM/DD/YYYY']],
            template: 'For short dates, use {0} (without leading zeros).',
            valuesConfig: {
              SHORT_DATE_D_M_Y: {
                selected: ['DD/MM/YYYY'],
                example: ['It was on 31/12/1905.'],
              },
              SHORT_DATE_M_D_Y: {
                selected: ['MM/DD/YYYY'],
                example: ['It was on 12/31/1905.'],
              },
            },
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'LONG_DATE_D_M_Y',
            dropdownSources: [['DD Month YYYY', 'Month DD, YYYY']],
            template: 'For long dates, use {0} (without leading zeros).',
            valuesConfig: {
              LONG_DATE_D_M_Y: {
                selected: ['DD Month YYYY'],
                example: ['It was on 31 December 1905.'],
              },
              LONG_DATE_M_D_Y: {
                selected: ['Month DD, YYYY'],
                example: ['It was on December 31, 1905.'],
              },
            },
          },

          {
            type: StructureSectionType.RULE,
            description: 'When including the day of the week in a date, place a comma after the day.',
            example: ['Friday, December 25'],
            ruleId: 'DAY_DATE_COMMA',
          },

          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'DAY_SHORT_ABBREVIATIONS',
            dropdownSources: [['the shortest possible abbreviation', 'longer abbreviations']],
            template: 'To abbreviate days of the week, use {0}.',
            valuesConfig: {
              DAY_SHORT_ABBREVIATIONS: {
                selected: ['the shortest possible abbreviation'],
                example: ['Su, M, Tu, W, Th, F, Sa'],
              },
              DAY_LONGER_ABBREVIATIONS: {
                selected: ['longer abbreviations'],
                example: ['Mon, Tues, Weds, Thurs, Fri, Sat, Sun'],
              },
            },
          },

          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'DATE_YES_ORDINAL',
            dropdownSources: [['Use', "Don't use"]],
            template: '{0} ordinal suffixes on dates.',
            valuesConfig: {
              DATE_YES_ORDINAL: {
                selected: ['Use'],
                example: ['December 1st, January 3rd, October 5th'],
              },
              DATE_NO_ORDINAL: {
                selected: ["Don't use"],
                example: ['December 1, January 3, October 5'],
              },
            },
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Numbers, fractions, and units',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Spell out a number when it begins a sentence.',
            example: ['Twelve days are left until the end of the quarter.'],
            ruleId: 'SENT_START_NUM_SPELL_OUT',
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'SPELL_OUT_TO_NINE',
            template: 'Always spell out numbers {0}.',
            dropdownSources: [['0-9', '0-10']],
            valuesConfig: {
              SPELL_OUT_TO_NINE: {
                selected: ['0-9'],
              },
              SPELL_OUT_TO_TEN: {
                selected: ['0-10'],
              },
            },
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'TH_SEP_COMMA',
            dropdownSources: [['commas', 'periods']],
            template:
              'Use {0} to separate thousands and millions in large numbers (and periods to separate units from subunits).',
            valuesConfig: {
              TH_SEP_COMMA: {
                selected: ['commas'],
                example: ['Our company has 2,000 employees.'],
              },
              TH_SEP_PERIOD: {
                selected: ['periods'],
                example: ['Our company has 2.000 employees.'],
              },
            },
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'TEL_NUM_PARENTH_HYPHEN',
            template: 'For telephone numbers, use {0}.',
            dropdownSources: [
              [
                'parentheses for area codes, and a hyphen between the final two sets of numbers',
                'hyphens between each set of numbers',
                'periods between each set of numbers',
              ],
            ],
            valuesConfig: {
              TEL_NUM_PARENTH_HYPHEN: {
                selected: ['parentheses for area codes, and a hyphen between the final two sets of numbers'],
                example: ['Reach us at (415) 400-5299.'],
              },
              TEL_NUM_HYPHENS: {
                selected: ['hyphens between each set of numbers'],
                example: ['Reach us at 415-400-5299.'],
              },
              TEL_NUM_PERIODS: {
                selected: ['periods between each set of numbers'],
                example: ['Reach us at 415.400.5299.'],
              },
            },
          },
          {
            type: StructureSectionType.RULE,
            description: 'Always use a country code with phone numbers.',
            ruleId: 'US_TEL_COUNTRY_CODE',
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'MEAS_UNITS_TWO',
            dropdownSources: [
              ['leave', "don't leave"],
              ['uppercase', 'lowercase'],
            ],
            template:
              "For units of measure, {0} a space between between the number and the unit, use {1} letters, and don't use punctuation.",
            valuesConfig: {
              MEAS_UNITS_TWO: {
                selected: ['leave', 'uppercase'],
                example: ['It weighs 25 KG.'],
              },
              MEAS_UNITS_THREE: {
                selected: ["don't leave", 'uppercase'],
                example: ['It weighs 25KG.'],
              },
              MEAS_UNITS_FIVE: {
                selected: ['leave', 'lowercase'],
                example: ['It weighs 25 kg.'],
              },
              MEAS_UNITS_SIX: {
                selected: ["don't leave", 'lowercase'],
                example: ['It weighs 25kg.'],
              },
            },
          },
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'LEADING_ZERO',
            template: 'When writing a decimal, {0} a leading zero before the decimal point.',
            dropdownSources: [['include', "don't include"]],
            valuesConfig: {
              LEADING_ZERO: {
                selected: ['include'],
              },
              NO_LEADING_ZERO: {
                selected: ["don't include"],
              },
            },
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Times and timezones',
        items: [
          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'AMPM_UPPER_SPACES_PERIODS_ONE',
            dropdownSources: [
              ['uppercase', 'lowercase'],
              ['include', "don't include"],
              ['use', "don't use"],
            ],
            template: 'Write A.M. and P.M. in {0}, {1} a space after the hour, and {2} periods between the letters.',
            valuesConfig: {
              AMPM_UPPER_SPACES_PERIODS_ONE: {
                selected: ['uppercase', 'include', 'use'],
                example: ['It’s at 9 P.M. tomorrow.'],
              },
              AMPM_UPPER_SPACES_NO_PERIODS_TWO: {
                selected: ['uppercase', 'include', "don't use"],
                example: ['It’s at 9 PM tomorrow.'],
              },
              AMPM_UPPER_NO_SPACES_NO_PERIODS_THREE: {
                selected: ['uppercase', "don't include", "don't use"],
                example: ['It’s at 9PM tomorrow.'],
              },
              AMPM_LOWER_SPACES_PERIODS_FOUR: {
                selected: ['lowercase', 'include', 'use'],
                example: ['It’s at 9 p.m. tomorrow.'],
              },
              AMPM_LOWER_SPACES_NO_PERIODS_FIVE: {
                selected: ['lowercase', 'include', "don't use"],
                example: ['It’s at 9 pm tomorrow.'],
              },
              AMPM_LOWER_NO_SPACES_NO_PERIODS_SIX: {
                selected: ['lowercase', "don't include", "don't use"],
                example: ['It’s at 9pm tomorrow.'],
              },
            },
          },

          {
            type: StructureSectionType.DROPDOWNS,
            defaultValue: 'HOUR_YES_MINUTES',
            template: 'For times on the hour, {0} minutes.',
            dropdownSources: [['include', "don't include"]],
            valuesConfig: {
              HOUR_YES_MINUTES: {
                selected: ['include'],
                example: ['7:00 PM, not 7 PM'],
              },
              HOUR_NO_MINUTES: {
                selected: ["don't include"],
                example: ['7 PM, not 7:00 PM'],
              },
            },
          },

          {
            type: StructureSectionType.RULE,
            description: 'Spell out the time zone when not being used in conjunction with a time.',
            example: ['All the states in the Central time zone observe daylight savings time.'],
            ruleId: 'TIME_ZONE_SPELL',
          },

          {
            type: StructureSectionType.RULE,
            description: 'Spell out noon and midnight.',
            example: ['Join our webinar tomorrow at noon PST.'],
            ruleId: 'NOON_MIDNIGHT',
          },
        ],
      },
    ],
  },
  {
    name: 'Social media',
    type: SuggestionCategoryType.SOCIAL,
    structure: [
      {
        type: StructureSectionType.GROUP,
        items: [
          {
            type: StructureSectionType.RULE,
            description: "Don't use 'at' before the @ symbol. ",
            example: ['Follow us @Writer.'],
            ruleId: 'DOUBLE_AT',
          },
          {
            type: StructureSectionType.RULE,
            description: "Don't use the word 'hashtag' followed by the # symbol. ",
            example: ['Follow #contentai for best practices.'],
            ruleId: 'DOUBLE_HASHTAG',
          },
        ],
      },
    ],
  },
];

export default structure as StructureSection[];
