import React, { Reducer, useCallback, useEffect, useReducer } from 'react';

import cx from 'classnames';

import { ApplicationUserType, IUserProfile, openNewTab, preventEventDefault } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';

import { snackbarMessages } from '@web/component-library';

import { createServiceAccount, deleteServiceAccount } from '../../../../services/request/serviceAccount';
import { findUserInOrganization } from '../../../../services/request/user';
import { getLogger } from '../../../../utils/logger';
import ConfirmServiceAccountRemoveModal from '../modals/ConfirmServiceAccountRemoveModal';
import CreateServiceAccountModal from '../modals/CreateServiceAccountModal';
import ServiceAccountsList from './ServiceAccountsList';

import styles from '../styles.module.css';

const LOG = getLogger('ServiceAccountSection');
const serviceAccountDocLocation = 'https://dev.writer.com/docs';
const onClickLearnMoreServiceAccount = (e: React.MouseEvent) => {
  preventEventDefault(e);
  openNewTab(serviceAccountDocLocation);
};

enum AccountActionType {
  setCreateServiceAccountModalOpen = 'createServiceAccountModalOpen',
  setDeleteConfirmServiceAccountModalOpen = 'setDeleteConfirmServiceAccountModalOpen',
  setDeleteServiceAccountInstance = 'setDeleteServiceAccountName',
  setServiceAccounts = 'setServiceAccounts',
}

type TServiceAccountSectionState = {
  createServiceAccountModalOpen: boolean;
  confirmDeleteServiceAccountModalOpen: boolean;
  serviceAccounts: IUserProfile[];
  deleteServiceAccountInstance: IUserProfile | undefined;
};

const serviceAccountSectionInitialState: TServiceAccountSectionState = {
  createServiceAccountModalOpen: false,
  confirmDeleteServiceAccountModalOpen: false,
  serviceAccounts: [],
  deleteServiceAccountInstance: undefined,
};

type TServiceAccountSectionAction = {
  type: AccountActionType;
  payload?: { [key: string]: any };
};

const reducer = (state: TServiceAccountSectionState, action: TServiceAccountSectionAction) => ({
  ...state,
  ...action.payload,
});

interface IServiceAccountSectionProps {
  organizationId?: number;
  forceAIStudioAPIKey?: boolean;
  onGoToAiStudio: () => void;
}

export const ServiceAccountSection: React.FC<IServiceAccountSectionProps> = ({
  organizationId,
  forceAIStudioAPIKey,
  onGoToAiStudio,
}) => {
  const [state, dispatch] = useReducer<Reducer<TServiceAccountSectionState, TServiceAccountSectionAction>>(
    reducer,
    serviceAccountSectionInitialState,
  );
  useEffect(() => {
    loadServiceAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId]);

  const { enqueueSuccessSnackbar, enqueueErrorSnackbar, enqueueDeleteSnackbar } = useCustomSnackbar();
  const loadServiceAccounts = () => {
    findUserInOrganization(organizationId, { userType: ApplicationUserType.APPLICATION })
      .then(({ result: userDetails }) => {
        dispatch({
          type: AccountActionType.setServiceAccounts,
          payload: {
            serviceAccounts: userDetails.map(({ user: userProfile }) => userProfile),
          },
        });
      })
      .catch(err => LOG.error(err));
  };
  const _handleCreateServiceAccountButtonClick = useCallback(() => {
    dispatch({
      type: AccountActionType.setCreateServiceAccountModalOpen,
      payload: {
        createServiceAccountModalOpen: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.createServiceAccountModalOpen]);
  const _handleConfirmDeleteServiceAccount = (profile: IUserProfile) => {
    dispatch({
      type: AccountActionType.setDeleteConfirmServiceAccountModalOpen,
      payload: {
        confirmDeleteServiceAccountModalOpen: true,
        deleteServiceAccountInstance: profile,
      },
    });
  };
  const _handleCreateServiceAccountButtonCloseModalClick = useCallback(() => {
    dispatch({
      type: AccountActionType.setCreateServiceAccountModalOpen,
      payload: {
        createServiceAccountModalOpen: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.createServiceAccountModalOpen]);
  const _handleCreateServiceAccount = useCallback(
    async (serviceAccountName: string) => {
      try {
        await createServiceAccount(organizationId, serviceAccountName);
        await loadServiceAccounts();
        enqueueSuccessSnackbar(snackbarMessages.serviceAccount.createSuccess);
      } catch (e) {
        if (e instanceof Error) {
          LOG.error(e.message);

          // TODO: cast to Backend error type
          (e as any).response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
        }
      } finally {
        _handleCreateServiceAccountButtonCloseModalClick();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_handleCreateServiceAccountButtonCloseModalClick, organizationId, enqueueSuccessSnackbar],
  );
  const _handleRemoveServiceAccount = useCallback(
    async () => {
      try {
        dispatch({
          type: AccountActionType.setDeleteConfirmServiceAccountModalOpen,
          payload: {
            confirmDeleteServiceAccountModalOpen: false,
          },
        });

        if (state.deleteServiceAccountInstance) {
          await deleteServiceAccount(organizationId, state.deleteServiceAccountInstance.id);
          enqueueDeleteSnackbar(snackbarMessages.serviceAccount.deleteSuccess);
        }
      } catch (e) {
        if (e instanceof Error) {
          LOG.error(e.message);

          // TODO: cast to Backend error type
          (e as any).response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
        }
      } finally {
        loadServiceAccounts();
        dispatch({
          type: AccountActionType.setDeleteServiceAccountInstance,
          payload: {
            deleteServiceAccountInstance: undefined,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, state.deleteServiceAccountInstance],
  );

  const _handleCancelDeleteServiceAccount = () => {
    dispatch({
      type: AccountActionType.setDeleteConfirmServiceAccountModalOpen,
      payload: {
        confirmDeleteServiceAccountModalOpen: false,
      },
    });
  };

  const descriptionEls = forceAIStudioAPIKey ? (
    <Text color={TextColor.GREY2} variant={TextSize.M}>
      You can no longer generate keys for the legacy API. For help with an existing key or legacy API, contact
      support@writer.com. To access our new API platform, go to AI Studio.
      <Button
        type={ButtonTypes.LINK}
        className={styles.btnLink}
        navTo={serviceAccountDocLocation}
        onClick={onClickLearnMoreServiceAccount}
      >
        <Text color={TextColor.GREY2} variant={TextSize.M}>
          Learn more.
        </Text>
      </Button>
    </Text>
  ) : (
    <Text color={TextColor.GREY2} variant={TextSize.M}>
      Create a service account to access the Writer API.
      <Button
        type={ButtonTypes.LINK}
        className={styles.btnLink}
        navTo={serviceAccountDocLocation}
        onClick={onClickLearnMoreServiceAccount}
      >
        <Text color={TextColor.GREY2} variant={TextSize.M}>
          Learn more.
        </Text>
      </Button>
    </Text>
  );

  return (
    <>
      <div className={cx(styles.containerForm, styles.containerFormWide)}>
        <Heading variant={HeadingVariant.H3} className={styles.containerFormHeader} bold>
          Service account
        </Heading>
        {descriptionEls}
        <Button
          className={styles.cratedAccountButton}
          type={ButtonTypes.SECONDARY}
          size={SizeTypes.SMALL}
          content={forceAIStudioAPIKey ? 'AI Studio' : 'Create service account'}
          onClick={forceAIStudioAPIKey ? onGoToAiStudio : _handleCreateServiceAccountButtonClick}
        />
      </div>
      <ServiceAccountsList
        accountsList={state.serviceAccounts}
        organizationId={organizationId}
        onAccountRemove={_handleConfirmDeleteServiceAccount}
      />
      <CreateServiceAccountModal
        open={state.createServiceAccountModalOpen}
        onCancel={_handleCreateServiceAccountButtonCloseModalClick}
        onCreateServiceAccount={_handleCreateServiceAccount}
      />
      <ConfirmServiceAccountRemoveModal
        open={state.confirmDeleteServiceAccountModalOpen}
        onConfirm={_handleRemoveServiceAccount}
        onCancel={_handleCancelDeleteServiceAccount}
      />
    </>
  );
};

export default ServiceAccountSection;
