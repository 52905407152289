import React from 'react';

import { noop } from '@writercolab/common-utils';
import {
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { OAuthAppsTypes, TOAuthAppsResponse, TOAuthAppsTypes } from '@web/types';
import { format, isToday } from 'date-fns';

import { OAuthAppsModalType, TOAuthAppsModalManager } from '../../pages/OAuthAppsPage/OAuthAppsPageModel.ui';
import { IBodyCellProps, IRowProps } from '../BaseTable/BaseTable';

import styles from './OAuthAppsTable.module.css';

export type TOAuthAppsTablesConfig = {
  id: typeof TOAuthAppsTableColumnId.type;
};

export const TOAuthAppsTableColumnId = new Enum('connector', 'applicationName', 'createdBy', 'dateAdded', 'actions');
export const TOAuthAppsTableActionId = new Enum('edit', 'delete');

export const OAuthAppsTableColumnsConfig: TOAuthAppsTablesConfig[] = [
  {
    id: TOAuthAppsTableColumnId.enum.connector,
  },
  {
    id: TOAuthAppsTableColumnId.enum.applicationName,
  },
  {
    id: TOAuthAppsTableColumnId.enum.createdBy,
  },
  {
    id: TOAuthAppsTableColumnId.enum.dateAdded,
  },
  {
    id: TOAuthAppsTableColumnId.enum.actions,
  },
];

interface IRowGenerator {
  data: TOAuthAppsResponse[];
  columnsConfig: TOAuthAppsTablesConfig[];
  modalsManager: TOAuthAppsModalManager;
}

const ConnectorCell = ({ type }: { type?: TOAuthAppsTypes }) =>
  type ? (
    <div className={styles.fileWrapperCell}>
      {OAuthAppsTypes.match(
        type,
        {
          gdrive: () => (
            <div className={styles.connectorCell}>
              <Icon name={IconVariant.GDRIVE} />
              <Text variant={TextSize.M} color={TextColor.GREY2}>
                Google Drive
              </Text>
            </div>
          ),
          sharepoint: () => (
            <div className={styles.connectorCell}>
              <Icon name={IconVariant.SHAREPOINT} />
              <Text variant={TextSize.M} color={TextColor.GREY2}>
                SharePoint
              </Text>
            </div>
          ),
          notion: () => (
            <div className={styles.connectorCell}>
              <Icon name={IconVariant.NOTION} />
              <Text variant={TextSize.M} color={TextColor.GREY2}>
                Notion
              </Text>
            </div>
          ),
          confluence: () => (
            <div className={styles.connectorCell}>
              <Icon name={IconVariant.CONFLUENCE} />
              <Text variant={TextSize.M} color={TextColor.GREY2}>
                Confluence
              </Text>
            </div>
          ),
        },
        null,
      )}
    </div>
  ) : (
    <>-</>
  );

const TextCell = ({ text }: { text?: string | null }) => (
  <div className={styles.textWrapperCell}>
    <Text variant={TextSize.M} ellipsisOverflow color={TextColor.GREY2}>
      {text}
    </Text>
  </div>
);

const ActionsCell = ({
  modalsManager,
  connectorId,
}: {
  modalsManager: TOAuthAppsModalManager;
  connectorId: string;
}) => (
  <Dropdown
    triggerClassName={styles.actionTrigger}
    trigger={<Icon name={IconVariant.MORE_HORIZ} />}
    itemsType={ItemsTypes.ACTION}
    options={[
      {
        id: TOAuthAppsTableActionId.enum.edit,
        name: 'Edit',
        icon: <Icon name={IconVariant.EDITOR} />,
        action: () => {
          modalsManager.showModal(OAuthAppsModalType.enum.viewer, {
            connectorId,
          });
        },
      },
      {
        id: TOAuthAppsTableActionId.enum.delete,
        name: 'Delete',
        icon: <Icon name={IconVariant.TRASH} />,
        action: () => {
          modalsManager.showModal(OAuthAppsModalType.enum.delete, {
            connectorId,
          });
        },
      },
    ]}
    onPrimaryOptionClickAction={() => {}}
    placement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

export const generateTableBody = ({ data, columnsConfig, modalsManager }: IRowGenerator): IRowProps[] =>
  data?.map(item => {
    const { name, type, createdBy, id, createdAt } = item;
    const generateTableCell = (cellType: string, component: React.ReactNode): IBodyCellProps => ({
      id: `cell-${cellType}-${id}`,
      component,
    });

    const tableCells = columnsConfig
      .map(column =>
        TOAuthAppsTableColumnId.match(
          column.id,
          {
            connector: () =>
              generateTableCell(
                TOAuthAppsTableColumnId.enum.connector,
                <ConnectorCell type={type as TOAuthAppsTypes} />,
              ),
            applicationName: () =>
              generateTableCell(TOAuthAppsTableColumnId.enum.applicationName, <TextCell text={name} />),
            createdBy: () =>
              generateTableCell(
                TOAuthAppsTableColumnId.enum.createdBy,
                <TextCell text={`${createdBy?.firstName} ${createdBy?.lastName}`} />,
              ),
            dateAdded: () => {
              const date = new Date(createdAt);

              return generateTableCell(
                TOAuthAppsTableColumnId.enum.dateAdded,
                <TextCell text={format(date, isToday(date) ? 'h:mmaaa' : 'MMMM d, yyyy')} />,
              );
            },

            actions: () =>
              generateTableCell(
                TOAuthAppsTableColumnId.enum.actions,
                <ActionsCell modalsManager={modalsManager} connectorId={id} />,
              ),
          },
          null,
        ),
      )
      .filter(Boolean) as IBodyCellProps[];

    const row: IRowProps<any> = {
      id: `row-${id}`,
      data: item,
      action: () => noop,
      cells: tableCells,
    };

    return row;
  });
