import type { IGtmActivityParams } from '@writercolab/common-utils';
import type { components } from '@writercolab/network';
import type { IStorage } from '@writercolab/storage';
import { StorageService } from '@writercolab/storage';

import { TReportPageKey } from '../components/pages/ReportPage/utils';

import { ISeoBlogDraftUsed, IStoredDraft, IStoredDraftFormValues, TPromptAutoSubmitPayload } from '@web/types';
import { OnboardingLocalStorageKey } from '@web/utils';
import { getLogger } from 'utils/logger';

import type { TTrackableUtmQueryParamsList } from '../utils/utmUtils';

const LOCAL_STORAGE_EVENT = 'storage';

const logger = getLogger('createLocalStorage');

function createLocalStorage() {
  logger.debug('createLocalStorage');

  const storage: IStorage<void, 'sync'> = {
    getItem: key => localStorage.getItem(key),
    setItem: (key, value) => localStorage.setItem(key, value),
    removeItem: key => localStorage.removeItem(key),
    subscribe(fn) {
      function callback(storageEvent: StorageEvent) {
        fn(storageEvent.key, storageEvent.newValue, storageEvent.oldValue);
      }

      window.addEventListener(LOCAL_STORAGE_EVENT, callback);

      return () => window.removeEventListener(LOCAL_STORAGE_EVENT, callback);
    },
  };

  return () => storage;
}

export const clearReportLocalStorage = () => {
  appLocalStorage[TReportPageKey.enum.aiusage].remove();
  appLocalStorage[TReportPageKey.enum['admin-audit-log']].remove();
  appLocalStorage[TReportPageKey.enum['user-activity']].remove();
};

export const appLocalStorage = StorageService.build(createLocalStorage(), (t: StorageService<void, 'sync'>) => ({
  consoleApplicationPreviewValues: t.object<
    Record<string, components['schemas']['template_model_MagicRequestInput'][]>
  >('consoleApplicationPreviewValues', 'Keeps application preview values in writer console'),
  editorApplicationPreviewValues: t.object<Record<string, components['schemas']['template_model_MagicRequestInput'][]>>(
    'editorApplicationPreviewValues',
    'Keeps application preview values in editor',
  ),
  chatMessageDocumentBootstrapContent: t.string(
    'chatMessageDocumentBootstrapContent',
    'Sets content for document to be booted with',
  ),
  pendingGtmEvents: t.array<IGtmActivityParams>('pendingGtmEvents', 'Keeps GTM events between pages load'),
  utmData: t.object<TTrackableUtmQueryParamsList>('utmData', 'Keeps UTM params'),

  // Reports Pages
  [TReportPageKey.enum.aiusage]: t.object<Record<string, string>>(
    'aiUsageReportingConfig',
    'Stores the last reporting config',
  ),
  [TReportPageKey.enum['admin-audit-log']]: t.object<Record<string, string>>(
    'adminAuditLogConfig',
    'Stores the last Admin Audit Log config',
  ),
  [TReportPageKey.enum['user-activity']]: t.object<Record<string, any>>(
    'userActivityReportConfig',
    'Stores User Activity Report configuration',
  ),
  lastDraft: t.object<IStoredDraft & { replace?: boolean }>('lastDraft', 'Stores the last draft'),
  lastDraftValues: t.object<IStoredDraftFormValues>('lastDraftValues', 'Stores the last draft values'),
  seoBlogDraftUsed: t.object<ISeoBlogDraftUsed[]>('seoBlogDraftUsed', 'Stores the used drafts for seo blog'),
  // onboarding v3 keys
  onboardingOrgId: t.string(OnboardingLocalStorageKey.OnboardingOrgId, 'Stores the current onboarding org id'),
  onboardingOgName: t.string(OnboardingLocalStorageKey.OnboardingOrgName, 'Stores the current onboarding org name'),
  onboardingStep: t.string(OnboardingLocalStorageKey.OnboardingStep, 'Stores the current onboarding step'),
  skipOnboardingOrgCreation: t.boolean(
    OnboardingLocalStorageKey.OnboardingSkipOrgCreation,
    'Stores if user skipped org creation',
  ),
  onboardingState: t.string(OnboardingLocalStorageKey.OnboardingState, 'Stores the current onboarding state'),
  seatsInUseNoticeBannerHidden: t.boolean(
    'seatsInUseNoticeBannerHidden',
    'Stores if the seats in use notice banner is hidden',
  ),
  chatPagePromptAutoSubmit: t.object<TPromptAutoSubmitPayload>(
    'chatPagePromptAutoSubmit',
    'Stores the chat page prompt auto submit state',
  ),
}));

export type TAppLocalStorage = typeof appLocalStorage;
