import React, { useMemo } from 'react';

import { generateQueryStringFromParams } from '../../../utils/navigationUtils';

interface IJotformFrameProps extends BaseComponentsProps {
  fromId: string;
  title?: string;
  frameStyles?: React.CSSProperties;
  frameAllowPermissions?: string[];
  frameFormAttributes?: Record<string, string>;
}

const JOTFORM_ROOT_URL = 'https://form.jotform.com';
const FRAME_PERMISSION_ITEM_SEPARATOR = ';';

const frameDefaultPermissions = ['geolocation', 'microphone', 'camera'];
const frameDefaultStyles = {
  width: '90%',
  height: 1050,
  border: 'none',
  margin: '-110px 5% 0 5%',
  overflow: 'hidden',
};

const genFrameSrc = (formId: string, attrs?: Record<string, string>) => {
  let src = `${JOTFORM_ROOT_URL}/${formId}`;

  if (attrs) {
    src += generateQueryStringFromParams(attrs);
  }

  return src;
};

const getFrameId = (formId: string) => `JotFormIFrame-${formId}`;

export const JotformFrame: React.FC<IJotformFrameProps> = ({
  className,
  fromId,
  title,
  frameStyles,
  frameAllowPermissions,
  frameFormAttributes,
}) => {
  const frameId = useMemo(() => getFrameId(fromId), [fromId]);
  const formSrc = useMemo(() => genFrameSrc(fromId, frameFormAttributes), [fromId, frameFormAttributes]);
  const framePermissions = useMemo(
    () => [...frameDefaultPermissions, frameAllowPermissions].join(FRAME_PERMISSION_ITEM_SEPARATOR),
    [frameAllowPermissions],
  );

  return (
    <div className={className}>
      <iframe
        id={frameId}
        src={formSrc}
        title={title}
        allow={framePermissions}
        style={{
          ...frameDefaultStyles,
          ...frameStyles,
        }}
        scrolling="no"
        frameBorder="0"
      />
    </div>
  );
};

export default JotformFrame;
