import type { DropdownOption } from '@writercolab/ui-atoms';
import isEmpty from 'lodash/isEmpty';

export const generateDropdownPlaceholder = (options: DropdownOption[]): string => {
  let placeholder = 'Select';

  const activeOptions = options.filter(({ active }) => active);

  if (!isEmpty(activeOptions)) {
    placeholder = activeOptions.map(({ name }) => name).join(', ');
  }

  return placeholder;
};
