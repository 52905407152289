import { Navigate } from 'react-router';

import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';

export const ChatAskPage = () => {
  const {
    appState: { teamId, organizationId },
  } = useAppState();

  return <Navigate to={ROUTE.toChatApp(organizationId, teamId, 'ask')} replace />;
};
