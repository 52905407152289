import React from 'react';

import { ISuggestionExampleConfig } from '@writercolab/common-utils';
import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import { ExampleBlockHeader } from './generic/ExampleBlockHeader';
import ExampleBlockList from './generic/ExampleBlockList';

import commonStyles from '../styles.module.css';
import styles from './styles.module.css';

interface SplitInfinitivesExampleProps {}

const exampleConfig: ISuggestionExampleConfig[] = [
  {
    id: '1',
    title: 'Example',
    contents: [
      {
        id: '1',
        content: (
          <div>
            <Text variant={TextSize.L} inline className={styles.exampleBlockInlineListItemDouble}>
              Our department lead decided{' '}
            </Text>
            <Text
              variant={TextSize.L}
              highlightColor={HighlightColor.RED}
              inline
              className={styles.exampleBlockInlineListItemDouble}
            >
              to
            </Text>
            <br />
            <Text
              variant={TextSize.L}
              highlightColor={HighlightColor.RED}
              inline
              className={styles.exampleBlockInlineListItemDouble}
            >
              thoroughly revamp our budget plan
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              and{' '}
            </Text>
            <br />
            <Text variant={TextSize.L} inline>
              expand our team.
            </Text>
          </div>
        ),
      },
      {
        id: '2',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              Our department lead decided{' '}
            </Text>
            <Text
              variant={TextSize.L}
              highlightColor={HighlightColor.GREEN}
              inline
              className={styles.exampleBlockInlineListItemDouble}
            >
              to{' '}
            </Text>
            <br />
            <Text
              variant={TextSize.L}
              highlightColor={HighlightColor.GREEN}
              inline
              className={styles.exampleBlockInlineListItemDouble}
            >
              revamp our budget plan thoroughly
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              and
            </Text>
            <br />
            <Text variant={TextSize.L} inline>
              {' '}
              expand our team.
            </Text>
          </div>
        ),
      },
    ],
  },
];

export const SplitInfinitivesExample: React.FC<SplitInfinitivesExampleProps> = () => (
  <div className={commonStyles.tooltipContent}>
    {exampleConfig.map(example => (
      <div className={styles.exampleBlockContainer} key={example.id}>
        <ExampleBlockHeader key={example.id}>{example.title}</ExampleBlockHeader>
        <ExampleBlockList blocks={example.contents} />
      </div>
    ))}
  </div>
);

export default SplitInfinitivesExample;
