import type { ButtonTypes } from '@writercolab/ui-atoms';

export enum DocumentInfoPanelButtonStateID {
  EXPLORE_LIBRARY = 'library',
  OPEN_TEMPLATE = 'openTemplate',
}

export interface IDocumentInfoPanelButtonState {
  id: DocumentInfoPanelButtonStateID;
  type: ButtonTypes;
  content: string;
}
