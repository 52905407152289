import { action, computed, makeObservable, observable } from 'mobx';

import { PromisedError, PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, components } from '@writercolab/network';

import type { TKnowledgeGraphFilterSection } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import type { IConfluenceSpace } from 'models/confluence.api';
import { ConfluenceApiModel } from 'models/confluence.api';

interface IKnowledgeGraphConnectorUIModelProps {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId?: number;
  connectors: () => components['schemas']['media_dto_BriefConnectorResponse'][];
  writerConnectors: () => components['schemas']['media_dto_BriefConnectorResponse'][];
  graphId: () => string;
  isConnector: () => boolean;
  isAssistantSubscriptionActive: () => boolean | undefined;
  aiStudioMode: boolean;
}

export class KnowledgeGraphConnectorUIModel {
  connectorId: string | undefined;
  connectorType: string | undefined;
  isAuthModalOpen = false;
  isOAuthPopupOpen = false;
  isSyncModalOpen = false;

  constructor(private opts: IKnowledgeGraphConnectorUIModelProps) {
    makeObservable(this, {
      connectorId: observable,
      connectorType: observable,
      isAuthModalOpen: observable,
      isOAuthPopupOpen: observable,
      isSyncModalOpen: observable,

      setConnectorId: action,
      setConnectorType: action,
      openAuthModal: action,
      closeAuthModal: action,
      openOAuthPopup: action,
      closeOAuthPopup: action,
      openSyncModal: action,
      closeSyncModal: action,
      updateConnectionFilters: action,

      orgId: computed,
      isLoading: computed,
      connectorName: computed,
      connectorAuthUrl: computed,
      connection: computed.struct,
      isNotionConnected: computed,
      isConfluenceConnected: computed,
      canUserSelectFiles: computed,
      connectionSections: computed.struct,
      connectionFileSections: computed.struct,
      connectionFolderSections: computed.struct,
      connectionFolderCount: computed,
      credentials: computed.struct,
      credentialsWithError: computed.struct,
      isCredentialsReady: computed,
      isOrgSettingsLoading: computed,
      isWriterOauthAppsEnabled: computed,
      isAssistantSubscriptionActive: computed,
    });
  }

  confluenceApiModel = new ConfluenceApiModel();

  $connectorAuthUrl: PromisedModel<components['schemas']['dto_ValueDto_java_lang_String'] | undefined> =
    new PromisedModel({
      name: '$connectorAuthUrl',
      load: async () => {
        if (!this.connectorId || !this.connectorType) {
          return undefined;
        }

        const { data } = await this.opts.request.get(
          '/api/media/organization/{organizationId}/graph/{graphId}/connector/{connectorId}/authorize',
          {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                graphId: this.opts.graphId(),
                connectorId: this.connectorId,
              },
              query: {
                redirectUri: `${window.location.origin}/connector/${this.connectorType}`,
              },
            },
          },
        );

        return data;
      },
    });

  $connection: PromisedModel<components['schemas']['media_dto_BriefGraphConnectionResponse'] | undefined> =
    new PromisedModel({
      name: '$connection',
      load: async () => {
        if (!this.opts.isConnector()) {
          return undefined;
        }

        const { data } = await this.opts.request.get(
          '/api/media/organization/{organizationId}/graph/{graphId}/connection',
          {
            params: {
              path: {
                organizationId: this.opts.organizationId,
                graphId: this.opts.graphId(),
              },
            },
          },
        );

        return data;
      },
    });

  $credentials: PromisedModel<components['schemas']['media_dto_GetCredential'] | undefined> = new PromisedModel({
    name: '$credentials',
    load: async () => {
      const connectorId = this.$connection.value?.connectorId;
      const connectionId = this.$connection.value?.id;

      if (!this.opts.isConnector() || !connectorId || !connectionId || this.$connection.status === 'pending') {
        return undefined;
      }

      const { data } = await this.opts.request.get(
        '/api/media/organization/{organizationId}/graph/{graphId}/connection/credential',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              graphId: this.opts.graphId(),
            },
          },
        },
      );

      return data;
    },
  });

  $orgSettings: PromisedModel<components['schemas']['organization_model_OrganizationSettings']> = new PromisedModel({
    name: '$orgSettings',
    load: async () => {
      const { data } = await this.opts.request.get('/api/organization/v2/{organizationId}/settings', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
          },
        },
      });

      return data;
    },
  });

  createConnection = async (authCode: string) => {
    const { data } = await this.opts.request.post(
      '/api/media/organization/{organizationId}/graph/{graphId}/connection',
      {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            graphId: this.opts.graphId(),
          },
        },
        body: {
          authCode,
          redirectUri: `${window.location.origin}/connector/${this.connectorType}`,
          connectorId: this.connectorId || '',
        },
      },
    );

    this.$connection.reload();

    return data;
  };

  updateConnectionFilters = (id: string, sections: TKnowledgeGraphFilterSection[]) => {
    this.opts.request
      .put('/api/media/organization/{organizationId}/graph/{graphId}/connection', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            graphId: this.opts.graphId(),
          },
        },
        body: {
          sectionFilters: [
            {
              id,
              sections,
            },
          ],
        },
      })
      .then(response => {
        if (response.data) {
          this.$connection.reload();
        }
      });
  };

  get orgId() {
    return this.opts.organizationId;
  }

  get aiStudioMode() {
    return this.opts.aiStudioMode;
  }

  get isLoading() {
    return this.$connection.status === 'pending';
  }

  get isConfluenceLoading() {
    return this.confluenceApiModel.isLoading;
  }

  get connectors() {
    return this.opts.connectors();
  }

  get connectorsGroups(): string[] {
    const connectors = this.opts.connectors().filter(({ organizationId }) => organizationId !== null);

    return [...new Set(connectors.map(({ type }) => type))];
  }

  get writerConnectors() {
    return this.opts.writerConnectors();
  }

  get connectorName(): string | undefined {
    return this.$connection.value?.connector?.name || this.connectorType;
  }

  get connectorAuthUrl(): components['schemas']['dto_ValueDto_java_lang_String']['value'] | undefined {
    return (this.$connectorAuthUrl.status === 'fulfilled' && this.$connectorAuthUrl.value?.value) || undefined;
  }

  get connection(): components['schemas']['media_dto_BriefGraphConnectionResponse'] | undefined | PromisedError {
    const res = this.$connection.valueWithError;

    return res;
  }

  get isNotionConnected(): boolean {
    return this.$connection.value?.connectorType === 'notion';
  }

  get isConfluenceConnected(): boolean {
    return this.$connection.value?.connectorType === 'confluence';
  }

  get canUserSelectFiles(): boolean {
    return (
      !(this.credentialsWithError instanceof PromisedError) &&
      isEmpty(this.connectionSections) &&
      !this.isNotionConnected &&
      !this.isConfluenceConnected
    );
  }

  get connectionSections(): TKnowledgeGraphFilterSection[] {
    return this.$connection.value?.sectionFilters?.[0]?.sections || [];
  }

  get connectionFileSections(): TKnowledgeGraphFilterSection[] {
    return this.$connection.value?.sectionFilters?.[0]?.sections?.filter(section => section.type === 'document') || [];
  }

  get connectionFolderSections(): TKnowledgeGraphFilterSection[] {
    return (
      (!this.isConfluenceConnected &&
        this.$connection.value?.sectionFilters?.[0]?.sections?.filter(section => section.type === 'folder')) ||
      []
    );
  }

  get connectionSpaceSections(): IConfluenceSpace[] {
    if (this.isConfluenceConnected && this.confluenceApiModel.spacesDetailed) {
      const selectedSpaces =
        this.$connection.value?.sectionFilters?.[0]?.sections?.filter(section => section.type === 'folder') || [];

      return this.confluenceApiModel.spacesDetailed.map(space => ({
        ...space,
        selected: selectedSpaces.some(selectedSpace => selectedSpace.id === space.id) || false,
      }));
    }

    return [];
  }

  get connectionFolderCount(): number {
    return (
      this.$connection.value?.sectionFilters?.[0]?.sections?.filter(section => section.type === 'folder').length || 0
    );
  }

  get credentials(): components['schemas']['media_dto_GetCredential'] | undefined {
    return this.$credentials.value;
  }

  get credentialsWithError(): components['schemas']['media_dto_GetCredential'] | undefined | PromisedError {
    return this.$credentials.valueWithError;
  }

  get isCredentialsReady(): boolean {
    return this.$credentials.status === 'fulfilled' && this.$credentials.value !== undefined;
  }

  get isOrgSettingsLoading(): boolean {
    return this.$orgSettings.status === 'pending';
  }

  get isWriterOauthAppsEnabled(): boolean {
    return this.$orgSettings.value?.useWriterOauthApps || false;
  }

  get isAssistantSubscriptionActive() {
    return this.opts.isAssistantSubscriptionActive();
  }

  setConnectorId = (id: string | undefined) => {
    this.connectorId = id;
  };

  setConnectorType = (type: string | undefined) => {
    this.connectorType = type;
  };

  openAuthModal = () => {
    this.isAuthModalOpen = true;
  };

  closeAuthModal = () => {
    this.isAuthModalOpen = false;
  };

  openOAuthPopup = () => {
    this.isOAuthPopupOpen = true;
  };

  closeOAuthPopup = () => {
    this.isOAuthPopupOpen = false;
  };

  openSyncModal = () => {
    this.isSyncModalOpen = true;
  };

  closeSyncModal = () => {
    this.isSyncModalOpen = false;
  };

  refreshCredentials = async () => {
    this.$credentials.reload();

    await this.$credentials.promise;
  };
}
