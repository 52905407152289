import React, { useCallback, useEffect, useReducer } from 'react';

import cx from 'classnames';

import {
  IOrganizationsDeleteRequest,
  IUserProfile,
  createDeleteOrganizationRequest,
  getDeleteOrganizationRequest,
  reCreateDeleteOrganizationRequest,
  removeDeleteOrganizationRequest,
} from '@writercolab/common-utils';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { snackbarMessages } from '@web/component-library';
import { observer } from 'mobx-react-lite';

import { getLogger } from '../../../utils/logger';
import DeleteAccountModal from './modals/DeleteAccountModal';
import DeleteAccountModalInfo from './modals/DeleteAccountModalInfo';
import CreateDeleteRequest from './parts/CreateDeleteRequest';
import { DeleteRequestExist } from './parts/DeleteRequestExist';

import styles from '../../pages/AccountPage/styles.module.css';

const LOG = getLogger('DeleteOrganization');

interface IDeleteOrganizationProds extends BaseComponentsProps {
  orgName: string;
  subscriptionModel: AiAssistantSubscriptionModel;
  profile: IUserProfile | undefined;
  orgId?: number;
}

type TDeleteOrganizationState = {
  loading: false;
  deleteOrgModalVisible: boolean;
  deleteOrgModalInfoVisible: boolean;
  deleteOrgRequest: null | IOrganizationsDeleteRequest;
};

enum TDeleteOrganizationActionType {
  SetData = 'setData',
}

type TDeleteOrganizationAction = {
  type: TDeleteOrganizationActionType;
  payload?: any;
};

const initialState: TDeleteOrganizationState = {
  deleteOrgRequest: null,
  deleteOrgModalVisible: false,
  deleteOrgModalInfoVisible: false,
  loading: false,
};

const reducer = (
  state: TDeleteOrganizationState = initialState,
  action: TDeleteOrganizationAction,
): TDeleteOrganizationState => ({ ...state, ...action.payload });

export const DeleteOrganization: React.FC<IDeleteOrganizationProds> = observer(
  ({ className, orgName, orgId, profile, subscriptionModel }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { enqueueSuccessSnackbar, enqueueDeleteSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

    const _handleDeleteOrgModalOpen = () => {
      dispatch({
        type: TDeleteOrganizationActionType.SetData,
        payload: {
          deleteOrgModalVisible: true,
        },
      });
    };
    const _handleDeleteOrgModalClose = () => {
      dispatch({
        type: TDeleteOrganizationActionType.SetData,
        payload: {
          deleteOrgModalVisible: false,
        },
      });
    };
    const _handleDeleteOrgModalInfoClose = () => {
      dispatch({
        type: TDeleteOrganizationActionType.SetData,
        payload: {
          deleteOrgModalInfoVisible: false,
        },
      });
    };
    const _handleCreateDeleteOrgRequest = useCallback(async () => {
      try {
        const deleteOrgRequest = await createDeleteOrganizationRequest(Number(orgId));
        dispatch({
          type: TDeleteOrganizationActionType.SetData,
          payload: {
            deleteOrgModalInfoVisible: true,
            deleteOrgRequest,
          },
        });
      } catch (e) {
        LOG.error(e);
        (e as any).response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
      } finally {
        dispatch({
          type: TDeleteOrganizationActionType.SetData,
          payload: {
            deleteOrgModalVisible: false,
          },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);
    const _handleDeleteOrgRequest = useCallback(async () => {
      dispatch({
        type: TDeleteOrganizationActionType.SetData,
        payload: { loading: true },
      });

      try {
        await removeDeleteOrganizationRequest(Number(orgId));
        dispatch({
          type: TDeleteOrganizationActionType.SetData,
          payload: {
            deleteOrgRequest: null,
          },
        });

        enqueueDeleteSnackbar(snackbarMessages.deleteOrgRequest.deleted);
      } catch (e) {
        LOG.error(e);
        (e as any).response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
      } finally {
        dispatch({
          type: TDeleteOrganizationActionType.SetData,
          payload: { loading: false },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);
    const _handleResendDeleteOrgRequest = useCallback(async () => {
      dispatch({
        type: TDeleteOrganizationActionType.SetData,
        payload: { loading: true },
      });

      try {
        const request = await reCreateDeleteOrganizationRequest(Number(orgId));
        dispatch({
          type: TDeleteOrganizationActionType.SetData,
          payload: request,
        });
        enqueueSuccessSnackbar(snackbarMessages.deleteOrgRequest.reCreated);
      } catch (e) {
        LOG.error(e);
        (e as any).response.data.errors.forEach(err => enqueueErrorSnackbar(err.description));
      } finally {
        dispatch({
          type: TDeleteOrganizationActionType.SetData,
          payload: { loading: false },
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    useEffect(() => {
      dispatch({
        type: TDeleteOrganizationActionType.SetData,
        payload: { orgRequestLoading: true },
      });
      getDeleteOrganizationRequest(Number(orgId))
        .then(deleteOrgRequest => {
          if (deleteOrgRequest) {
            dispatch({
              type: TDeleteOrganizationActionType.SetData,
              payload: {
                deleteOrgRequest,
              },
            });
          }
        })
        .catch(e => {
          LOG.error(e);
        })
        .finally(() => {
          dispatch({
            type: TDeleteOrganizationActionType.SetData,
            payload: { orgRequestLoading: false },
          });
        });
    }, [orgId]);

    return (
      <div className={cx(styles.deleteOrgContainer, className)}>
        {state.deleteOrgRequest ? (
          <DeleteRequestExist
            orgName={orgName}
            loading={state.loading}
            handleDeleteRequest={_handleDeleteOrgRequest}
            handleResendRequest={_handleResendDeleteOrgRequest}
          />
        ) : (
          <CreateDeleteRequest orgName={orgName} handleDeleteOrgModalOpen={_handleDeleteOrgModalOpen} />
        )}
        <DeleteAccountModal
          subscriptionModel={subscriptionModel}
          userProfile={profile}
          isOpen={state.deleteOrgModalVisible}
          handleClose={_handleDeleteOrgModalClose}
          onSubmit={_handleCreateDeleteOrgRequest}
        />
        <DeleteAccountModalInfo
          userEmail={profile?.email}
          isOpen={state.deleteOrgModalInfoVisible}
          handleClose={_handleDeleteOrgModalInfoClose}
        />
      </div>
    );
  },
);

export default DeleteOrganization;
