export function easeOutQuad(t: number, b: number, c: number, d: number) {
   
  return -c * (t /= d) * (t - 2) + b;
}

export function createAnimator(easing = easeOutQuad) {
  let counterVersion = 0;
  let startTime = -1;

  const run = (animationTime: number, fn: (persent: number) => void) => {
    startTime = -1;
    counterVersion++;
    let version = counterVersion;

    const loop = (time: number) => {
      if (version !== counterVersion) {
        return;
      }

      if (startTime === -1) {
        startTime = time;
      }

      const diff = time - startTime;

      const persentLinear = Math.min(diff, animationTime) / animationTime;
      const persent = easing(persentLinear, 0, 1, 1);

      fn(persent);

      if (diff <= animationTime) {
        // continue animation
        window.requestAnimationFrame(loop);
      }
    };

    window.requestAnimationFrame(loop);

    return () => {
      version = -1;
    };
  };

  return { run };
}
