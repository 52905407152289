import type { DropdownOption, IconVariant } from '@writercolab/ui-atoms';
import type { InputTypes } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

export const AUTO_GENERATE_CONTENT_USER_ID = '3';
export const QUESTIONNAIRE_TEXT_INPUT_WORDS_LIMIT = 100;
export const CUSTOM_INPUT_VALUE_PREFIX = 'other:';

export enum QuestionnaireBlock {
  USAGE = 'usage',
  DEPARTMENT = 'department',
}

export interface IQuestionnaireOption extends DropdownOption {
  id: string;
  title: string;
  description?: string;
  icon?: IconVariant;
  editable?: boolean;
  value?: string | boolean | null;
  editableInputType?: InputTypes;
  editablePlaceholder?: string;
  reset?: boolean;
  selected?: boolean;
  valid?: boolean;
}

interface IQuestionnaireBlockConfig {
  options: IQuestionnaireOption[];
}

export type TQuestionnaireOptionBlocksConfig = { [key in QuestionnaireBlock]?: IQuestionnaireBlockConfig };

export const TQuestionnaireVisibilityState = new Enum('full', 'hidden', 'partial', 'preload');
