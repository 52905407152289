import type { FC, ReactNode } from 'react';

import cx from 'classnames';

import { IconsCommonBulb, IconsCommonCertificate } from '@writercolab/assets';
import { cn } from '@writercolab/utils';

import { observer } from 'mobx-react-lite';

import { Card } from '../../generic/Card';

import styles from './AcademyHomeWidget.module.css';

const ItemCard: FC<{
  title: string;
  children: ReactNode;
}> = ({ title, children }) => (
  <Card withShadow={false} className={styles.item}>
    <div className={styles.itemTitle}>
      <p className="font-medium text-lg">{title}</p>
    </div>
    {children}
  </Card>
);

const ItemRow: FC<{
  icon: ReactNode;
  title: string;
  onClick: () => void;
}> = ({ icon, title, onClick }) => (
  <div className={cx(styles.cardContent, styles.clickable)} onClick={onClick}>
    <div className={styles.cardContentIcon}>{icon}</div>
    <p className={cn(styles.cardContentTitleText, 'text-base')}>{title}</p>
  </div>
);

interface IAcademyHomeWidgetProps {
  showAiStudioCertifications?: boolean;
  onOpenLink: (url: string, type: 'certification' | 'tip') => void;
}

export const AcademyHomeWidget = observer<IAcademyHomeWidgetProps>(({ showAiStudioCertifications, onOpenLink }) => (
  <div className={styles.container}>
    <ItemCard title="Certifications">
      <div className={styles.items}>
        <ItemRow
          icon={<IconsCommonCertificate width="30px" height="30px" />}
          title="Certification: Become a Writer power user"
          onClick={() =>
            onOpenLink(
              'https://academy.writer.com/learn/learning-path/writer-power-user-certification-4b4s',
              'certification',
            )
          }
        />
        {showAiStudioCertifications && (
          <ItemRow
            icon={<IconsCommonCertificate width="30px" height="30px" />}
            title="Certification: Build no-code apps in AI Studio"
            onClick={() =>
              onOpenLink(
                'https://academy.writer.com/learn/learning-path/ai-studio-no-code-certification',
                'certification',
              )
            }
          />
        )}
      </div>
    </ItemCard>
    <ItemCard title="Quick tips & courses">
      <div className={styles.items}>
        <ItemRow
          icon={<IconsCommonBulb width={30} height={30} />}
          title="Generative AI 101"
          onClick={() => onOpenLink('https://academy.writer.com/courses/generative-ai-101-20', 'tip')}
        />
        <ItemRow
          icon={<IconsCommonBulb width={30} height={30} />}
          title="What LLMs can and can't do"
          onClick={() => onOpenLink('https://academy.writer.com/courses/what-llms-can-and-cant-do-20', 'tip')}
        />
        <ItemRow
          icon={<IconsCommonBulb width={30} height={30} />}
          title="Write in your company’s voice"
          onClick={() => onOpenLink('https://academy.writer.com/courses/write-in-your-companys-voice-20', 'tip')}
        />
      </div>
    </ItemCard>
  </div>
));
