import type { ReactNode } from 'react';
import type React from 'react';

import { LoadingPage } from '@web/component-library';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';

import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';

interface PrivateRouteProps {
  children: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = observer(({ children }) => {
  const { appModel } = useAppState();

  if (!appModel.isAuthenticated) {
    return <LoadingPage />;
  }

  return appModel.isAuthenticated ? <>{children}</> : <Navigate to={ROUTE.loginExternal} />;
});

export default PrivateRoute;
