import React from 'react';

import { isDateTodays, noop } from '@writercolab/common-utils';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import { IUserProfile, TOrgTeamUserActivityParams } from '@writercolab/types';
import {
  Dropdown,
  type DropdownOption,
  DropdownPlacement,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { ISort, SortingHeader } from '@writercolab/ui-molecules';

import {
  OrganizationDocumentAction,
  TOrganizationDocumentsExtraArgs,
  type TOrganizationsDocument,
  type TOrganizationsDocumentsScope,
  TOrganizationsDocumentsSort,
  type TOrganizationsTableDocument,
} from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { formatDate } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import { ArrowDownToLine, FileText, Trash2, Users } from 'lucide-react';

import { EMPTY_DOCUMENT_PLACEHOLDER, EMPTY_PLACEHOLDER } from '../../../services/config/constants';
import { type IBodyCellProps, type IRowProps } from '../BaseTable/BaseTable';

import styles from './DocumentsTable.module.css';

export const getDocumentsUrlParams = (): TOrganizationDocumentsExtraArgs => {
  const searchParams = new URLSearchParams(document.location.search);
  const searchParam = searchParams.get('search');
  const sortFieldParam = searchParams.get('sortField') as TOrganizationDocumentsExtraArgs['sortField'];
  const scopeParam = searchParams.get('scope') as TOrganizationDocumentsExtraArgs['scope'];

  const search = searchParam ?? '';
  const sortField = sortFieldParam ?? undefined;
  const scope = scopeParam ?? undefined;

  return {
    search,
    sortField,
    scope,
  };
};

export const toTableDocument = (document: TOrganizationsDocument): TOrganizationsTableDocument =>
  ({
    ...document,
    id: String(document.id),
  }) as TOrganizationsTableDocument;

const generateTableCell = (id: string, component: React.ReactNode): IBodyCellProps => ({
  id: `cell-${id}`,
  component,
});

const TableTitleCell: React.FC<{ title: string }> = ({ title }) => {
  const hasTitle = !isEmpty(title);

  return (
    <div className={styles.titleCell}>
      <FileText size={16} strokeWidth={1} className={styles.titleIcon} />
      <Text variant={TextSize.L} color={TextColor.GREY} ellipsisOverflow italic={!hasTitle}>
        {hasTitle ? title : EMPTY_DOCUMENT_PLACEHOLDER}
      </Text>
    </div>
  );
};

const TableTextCell: React.FC<{ text: string }> = ({ text }) => (
  <Text variant={TextSize.L} color={TextColor.GREY}>
    {text}
  </Text>
);

const DateCell: React.FC<{
  date?: string | null;
}> = React.memo(({ date }) => {
  const newDate = date ? new Date(date) : '';

  if (!date) {
    return <Text color={TextColor.BLACK}>{EMPTY_PLACEHOLDER}</Text>;
  }

  const formattedDate = isDateTodays(newDate as string)
    ? formatDate(newDate, 'h:mmaaa')
    : formatDate(newDate, 'MMMM d, yyyy, h:mmaaa');

  return (
    <Text variant={TextSize.L} color={TextColor.BLACK}>
      {formattedDate}
    </Text>
  );
});

export const generateTableHeaders = ({
  scopeFilterOptions,
  handleOwnerFilterChange,
  scopeFilterDisabled,
  sortedByCreatedDate,
  sortingOptions,
  sortField,
  handleSortingChange,
}: {
  scopeFilterOptions: DropdownOption<string>[];
  handleOwnerFilterChange: (id: typeof TOrganizationsDocumentsScope.type) => void;
  scopeFilterDisabled: boolean;
  sortedByCreatedDate: boolean;
  sortingOptions: DropdownOption<string>[];
  sortField: typeof TOrganizationsDocumentsSort.type;
  handleSortingChange: (selectedSort: ISort) => void;
}) => [
  {
    component: (
      <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.columnHeader} upperCase medium>
        Doc name
      </Text>
    ),
  },
  {
    component: scopeFilterDisabled ? null : (
      <Dropdown
        options={scopeFilterOptions}
        trigger={
          <div className={styles.filterTrigger}>
            <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.columnHeader} upperCase medium>
              {scopeFilterOptions.find(s => s.active)?.name}
            </Text>
            <Icon name={IconVariant.DROP_DOWN_ARROW} />
          </div>
        }
        onPrimaryOptionClickAction={id => handleOwnerFilterChange(id as typeof TOrganizationsDocumentsScope.type)}
        placement={DropdownPlacement.BOTTOM_RIGHT}
        dropdownItemClassName={styles.dropdownItem}
      />
    ),
  },
  {
    component: (
      <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.columnHeader} upperCase medium>
        {sortedByCreatedDate ? 'Last updated' : 'Last opened by me'}
      </Text>
    ),
  },
  {
    component: (
      <SortingHeader
        isFillOut
        menuClassName={styles.sortingHeaderMenu}
        header={<></>}
        title={
          <Text variant={TextSize.XXS} medium className={styles.sortBy}>
            SORT BY
          </Text>
        }
        headerIcon={<Icon name={IconVariant.SORT} width={18} height={18} />}
        defaultActiveSortId={sortField}
        placement="right"
        options={sortingOptions.map(option => ({
          id: option.id,
          name: option.name,
        }))}
        onSelect={handleSortingChange}
      />
    ),
  },
];

const generateDocumentRow = (
  index,
  document: TOrganizationsTableDocument,
  onDocumentAction: (docId: string, action: typeof OrganizationDocumentAction.type) => void,
  scopeFilterDisabled: boolean,
  showCreateDocumentDate: boolean,
  setSelectedDocument: (document: TOrganizationsDocument) => void,
  access: {
    user: IUserProfile | undefined;
    assistantSubscription: AiAssistantSubscriptionModel | undefined;
  },
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>,
  isLastRow: boolean,
): IRowProps => {
  const actionDate = showCreateDocumentDate ? document.creationTime : document.openedByMeTime;
  const hideShareDocument = access.user?.id !== document.createdUserId || access.assistantSubscription?.isFree;
  const hideDeleteDocument = access.user?.id !== document.createdUserId;

  return {
    id: `${document.id}-${index}`,
    cells: [
      generateTableCell(document.id, <TableTitleCell title={document.title} />),
      generateTableCell(
        document.id,
        scopeFilterDisabled ? null : (
          <TableTextCell text={document.createdUser?.fullName || document.createdUser?.email || EMPTY_PLACEHOLDER} />
        ),
      ),
      generateTableCell(document.id, <DateCell date={actionDate} />),
      generateTableCell(
        document.id,
        <Dropdown
          triggerClassName={styles.rowActions}
          dropDownContainerClassName={styles.rowActionBottom}
          dropdownItemClassName={styles.dropdownItem}
          trigger={<Icon name={IconVariant.MORE_HORIZ} />}
          itemsType={ItemsTypes.ACTION}
          placement={isLastRow ? DropdownPlacement.TOP_LEFT : DropdownPlacement.BOTTOM_LEFT}
          onPrimaryOptionClickAction={noop}
          onTriggerClickCallback={() => {
            analyticsService.track(AnalyticsActivity.myWorkItemMenuClicked, {
              option: 'docs',
            });

            setSelectedDocument(document);
          }}
          options={[
            ...(!hideShareDocument
              ? [
                  {
                    id: OrganizationDocumentAction.enum.share,
                    icon: <Users size={16} strokeWidth={1} />,
                    name: 'Share',
                    action: () => onDocumentAction(document.id, OrganizationDocumentAction.enum.share),
                  },
                ]
              : []),
            {
              id: OrganizationDocumentAction.enum.share,
              icon: <ArrowDownToLine size={16} strokeWidth={1} />,
              name: 'Download',
              action: () => onDocumentAction(document.id, OrganizationDocumentAction.enum.download),
            },
            ...(!hideDeleteDocument
              ? [
                  {
                    id: OrganizationDocumentAction.enum.delete,
                    name: 'Delete',
                    warning: true,
                    icon: <Trash2 size={16} strokeWidth={1} />,
                    action: () => onDocumentAction(document.id, OrganizationDocumentAction.enum.delete),
                  },
                ]
              : []),
          ]}
        />,
      ),
    ],
    data: {
      id: document.id,
    },
  };
};

export const generateDocumentsTableData = (
  documents: TOrganizationsTableDocument[],
  onDocumentAction: (id: string, action: typeof OrganizationDocumentAction.type) => void,
  scopeFilterDisabled: boolean,
  showCreateDocumentDate: boolean,
  setSelectedDocument: (document: TOrganizationsDocument) => void,
  access: {
    user: IUserProfile | undefined;
    assistantSubscription: AiAssistantSubscriptionModel | undefined;
  },
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>,
): IRowProps[] => {
  const recordsCount = documents.length;
  const recordIndexFn = (id: number) => documents.findIndex(doc => doc.id === id);

  return documents.map((document, index) => {
    const posInArray = recordIndexFn(document.id);
    const isLastRow = recordsCount > 20 && posInArray >= recordsCount - 5;

    return generateDocumentRow(
      index,
      document,
      onDocumentAction,
      scopeFilterDisabled,
      showCreateDocumentDate,
      setSelectedDocument,
      access,
      analyticsService,
      isLastRow,
    );
  });
};
