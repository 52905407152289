import React, { FC, useCallback } from 'react';

import cx from 'classnames';

import { ActionIcon, HoverColor } from '@writercolab/ui-molecules';

import { AnalyticsActivity } from '../../../constants/analytics';
import { useAppState } from '../../../state';
import { GlobalNavSelection, INavAction } from './index';

import styles from './styles.module.css';

interface ActionWrapperProps extends INavAction {
  currentSelection?: GlobalNavSelection;
  primaryLinksBlockHighlighted: boolean;
  actionKey: GlobalNavSelection;
}

export const ActionWrapper: FC<ActionWrapperProps> = ({
  actionKey,
  label,
  icon,
  iconActive,
  iconVariant,
  iconVariantActive,
  trailingIcon,
  onClick,
  indicated,
  navTo,
  textMedium,
  isSubPrimary,
  currentSelection,
  primaryLinksBlockHighlighted,
}) => {
  const { appModel } = useAppState();
  const { analyticsService } = appModel;
  const isActive = currentSelection === actionKey;

  const handleOnClick = useCallback(() => {
    if (onClick) {
      analyticsService.track(AnalyticsActivity.globalNavMenuItemClicked, { menu_item: label });
      onClick();
    }
  }, [analyticsService, label, onClick]);

  return (
    <ActionIcon
      id={`top-nav-${actionKey}`}
      className={cx(styles.navItem, {
        [styles.navItemSubPrimary]: isSubPrimary,
        [styles.navItemActive]: isActive,
      })}
      label={label}
      navTo={navTo}
      medium={textMedium}
      hoverable
      hoverColor={primaryLinksBlockHighlighted && !isActive ? HoverColor.DARK_BLUE : HoverColor.BLUE}
      active={isActive}
      icon={isActive ? iconActive : icon}
      iconName={isActive ? iconVariantActive : iconVariant}
      trailingIcon={trailingIcon}
      onClick={handleOnClick}
      indicated={indicated}
      upperCase={false}
    />
  );
};
