import { observable, runInAction } from 'mobx';
import qs from 'qs';

import { PaginatedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';

import type {
  TPaginatedTemplatesApp,
  TPaginatedTemplatesAppArgs,
  TPaginatedTemplatesAppExtraArgs,
  TTemplatesAppQuery,
  TTemplatesAppResponse,
  TTemplatesAppSortField,
  TTemplatesAppSortOrder,
} from '@web/types';
import { getLogger } from 'utils/logger';

const LOG = getLogger('TemplatesApp.api');

interface TemplatesAppApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number | undefined;
  teamId?: number;
}

const DEFAULT_PAGE_SIZE = 50;
export class TemplatesAppApiModel {
  private $isLoading = observable.box(false);
  private $isCreateAppLoading = observable.box(false);

  public pagination: PaginatedModel<
    TPaginatedTemplatesApp,
    TTemplatesAppResponse,
    TPaginatedTemplatesAppArgs,
    TPaginatedTemplatesAppExtraArgs
  >;

  constructor(private opts: TemplatesAppApiModelOptions) {
    const urlParams = new URLSearchParams(document.location.search);
    this.pagination = new PaginatedModel<
      TPaginatedTemplatesApp,
      TTemplatesAppResponse,
      TPaginatedTemplatesAppArgs,
      TPaginatedTemplatesAppExtraArgs
    >({
      argsExtra: {
        search: urlParams?.get('search') ?? undefined,
        sortField: (urlParams?.get('sortField') as TTemplatesAppSortField) ?? 'name',
        sortOrder: (urlParams?.get('sortOrder') as TTemplatesAppSortOrder) ?? 'asc',
      },
      argsDefault: {
        offset: 0,
        limit: DEFAULT_PAGE_SIZE,
      },
      extractMeta: obj => {
        const offset = (obj.pagination.offset ?? 0) + (obj.result?.length ?? 0);

        if (offset >= obj.totalCount) {
          return this.pagination.args;
        }

        return {
          offset,
          limit: DEFAULT_PAGE_SIZE,
        };
      },
      extract: obj => obj.result ?? [],
      load: async ({ args, extra }) => {
        if (!this.opts.organizationId) {
          throw new Error('OrganizationId is not defined');
        }

        const query: TTemplatesAppQuery = {
          ...args,
          ...extra,
        };

        runInAction(() => {
          this.$isLoading.set(true);
        });

        const { data } = await this.opts.request.get('/api/template/template', {
          params: {
            query,
          },
          querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
        });

        runInAction(() => {
          this.$isLoading.set(false);
        });

        return data;
      },
    });
  }

  getById = async (templateId: string) => {
    if (!templateId) {
      throw new Error('TemplateId is not defined');
    }

    const res = await this.opts.request.get('/api/template/template/{templateId}', {
      params: {
        path: {
          templateId,
        },
      },
    });

    return res.data;
  };

  getPublicTemplateById = async (templateId: string) => {
    try {
      const { data } = await this.opts.request.get('/api/public/template/{templateId}', {
        params: {
          path: {
            templateId,
          },
        },
      });

      return data;
    } catch (e) {
      LOG.error('Failed to get template', {
        error: e,
        templateId,
      });

      throw e;
    }
  };

  get data(): TTemplatesAppResponse[] {
    return this.pagination.value ?? [];
  }

  get isLoading() {
    return this.$isLoading.get();
  }

  refresh = async () => {
    await this.pagination.reload();
    await this.pagination.promise;
  };
}
