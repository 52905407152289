import { computed, makeObservable } from 'mobx';

import type { ITeam } from '@writercolab/common-utils';
import { getMyTeams } from '@writercolab/common-utils';
import { PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';

import type { ITeamQueryParams, TPaginatedTeamUsers } from '@web/types';
import { getLogger } from 'utils/logger';

import type { PermissionModel } from './permission';

interface ITeamsModelParams {
  request: RequestServiceInitialize['api'];
  organizationId: () => number | undefined;
  permissionsModel?: () => PermissionModel | null | undefined;
  teamId?: () => number | undefined;
}

const LOG = getLogger('teams.api');

export class TeamsModel {
  constructor(private opts: ITeamsModelParams) {
    makeObservable(this, {
      teams: computed,
      administratedTeams: computed,
    });
  }

  $teams = new PromisedModel({
    name: '$teams',
    load: async () => {
      const orgId = this.opts.organizationId();

      if (!orgId) {
        return undefined;
      }

      return getMyTeams(orgId);
    },
  });

  get teams(): ITeam[] | undefined {
    return this.$teams.value;
  }

  get administratedTeams() {
    const permissionsModel = this.opts.permissionsModel?.();

    if (!permissionsModel) {
      return undefined;
    }

    if (this.teams && permissionsModel?.isOrganizationAdmin) {
      return this.teams;
    }

    return this.teams?.filter(team => permissionsModel?.isTeamAdminOf(team.id));
  }

  findTeamMembers = async (payload: ITeamQueryParams): Promise<TPaginatedTeamUsers> => {
    try {
      const { data } = await this.opts.request.get('/api/user/v2/organization/{organizationId}/team/{teamId}', {
        params: {
          path: {
            organizationId: Number(this.opts.organizationId),
            teamId: Number(this.opts.teamId),
          },
          query: payload,
        },
      });

      const flags = {};

      // Removes duplicate users sent from BE (existing issues from 4+ years ago?)
      const filteredResult = data.result.filter(({ user }) => {
        if (flags[user.id]) return false;

        flags[user.id] = true;

        return true;
      });

      return { ...data, result: filteredResult };
    } catch (e) {
      LOG.error('Failed to find team members', e);

      throw e;
    }
  };
}
