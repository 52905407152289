import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';

import type { LogoutParams } from '@writercolab/common-utils';
import {
  LEGACY_ORG_COOKIE,
  LocalStorageKey,
  LocalStorageService,
  SearchQueryParam,
  SharedQueryParam,
  delay,
  extractCurrentOrg,
  getUserLastVisitOrgId,
  invalidateToken,
  setUserLastVisitOrgId,
  unsetAuthCookie,
  unsetCookie,
} from '@writercolab/common-utils';

import {
  TRIGGER_LOGOUT_IN_TABS,
  useLocalStorage,
  useLocalStorageListener,
  useOrganizations,
} from '@web/component-library';
import type { WithChildren } from '@web/types';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';
import { getLogger } from 'utils/logger';

import { useAppState } from '.';
import useQuery from '../hooks/useQuery';
import { useRouteMatch } from '../hooks/useRouteMatch';
import { clearReportLocalStorage } from '../models/localStorage';
import { REACT_RELATIVE_ROUTE, ROUTE, WRITER_CONSOLE_ROUTES } from '../services/config/routes';
import {
  extractEncodedPath,
  goToAiStudioSignIn,
  goToHome,
  goToHrefWithReload,
  goToSignIn,
} from '../utils/navigationUtils';
import { TQueryParamBooleanStd, redirectLocation } from '../utils/queryParamUtils';
import { concatenateStrings } from '../utils/stringUtils';
import { isInternalUrl } from '../utils/urlUtils';
import { TActionType } from './types';

const LOG = getLogger('useComputeRoutes');

interface IAppSessionContext {
  signOutUser: (params?: LogoutParams) => void;
  fetchOrganizations: () => void;
  onOrganizationChange: (id: string) => void;
}

const AppSessionContext = createContext<IAppSessionContext>({} as IAppSessionContext);

export const AppSessionProvider: React.FC<WithChildren> = observer(({ children }) => {
  const { appState, dispatchAppState, appModel } = useAppState();
  const navigate = useNavigate();
  const [, setTriggerLogout] = useLocalStorage<boolean | undefined>(TRIGGER_LOGOUT_IN_TABS, false);
  const [, setTriggerLogoutParams] = useLocalStorage<LogoutParams | undefined>(
    LocalStorageKey.triggerLogoutInAllTabsParams,
    undefined,
  );
  const location = useLocation();
  const query = useQuery();
  const { organizations, unconfirmedOrganizations, fetchOrganizations } = useOrganizations();
  const isRouteMatch = useRouteMatch();
  const [lastAccessedTeam, setLastAccessedTeam] = useLocalStorage(LocalStorageKey.usersLastTeam, {});

  const { organization } = appState;
  const {
    isAuthenticated,
    authenticationError,
    isUnverifiedEmail,
    permissionsModel,
    aiStudioSubscription,
    assistantSubscription,
    teamsModel: { teams },
  } = appModel;
  const autoJoinHideJoinOrg = query.get(SearchQueryParam.createOrganization);
  const noTeams = teams?.length === 0;
  const noOrganizations = organizations?.length === 0;

  const handleOrgId = useCallback(() => {
    let orgId = 0;

    if (!organizations || !appModel.user) {
      return undefined;
    }

    const route = isRouteMatch(ROUTE.orgBasePath) || isRouteMatch(WRITER_CONSOLE_ROUTES.home);
    const isOrgBasedRoute = !!route;

    if (isOrgBasedRoute && route.params.orgId) {
      orgId = +route.params.orgId;
    }

    if (!orgId) {
      orgId = getUserLastVisitOrgId(appModel.user.id, organizations) || extractCurrentOrg(organizations).id;
    }

    if (
      !orgId ||
      !permissionsModel?.hasAccessToOrg(orgId) ||
      (isOrgBasedRoute && orgId !== Number(route?.params.orgId))
    ) {
      dispatchAppState({ type: TActionType.SetIsInvalidOrg, payload: true });
      dispatchAppState({ type: TActionType.SetAppLoading, payload: false });

      return undefined;
    }

    const currentOrganization = organizations?.find(org => org.id === +orgId);

    if (!currentOrganization) {
      LOG.error('Could not find current organization');

      return undefined;
    }

    dispatchAppState({ type: TActionType.SetIsInvalidOrg, payload: false });
    dispatchAppState({ type: TActionType.OrganizationId, payload: orgId }); // TODO: remove
    dispatchAppState({ type: TActionType.Organization, payload: currentOrganization });
    setUserLastVisitOrgId(appModel.user.id, orgId, organizations);

    return orgId;
  }, [organizations, appModel.user, isRouteMatch, permissionsModel, dispatchAppState]);

  const handleTeamId = useCallback(
    (orgId: number) => {
      let teamId: number | undefined;

      if (!permissionsModel) {
        return;
      }

      if (!teams?.length) {
        dispatchAppState({ type: TActionType.TeamId, payload: undefined });

        return;
      }

      const route = isRouteMatch(ROUTE.teamBasePath);
      const isTeamRoute = !!route;

      if (isTeamRoute && route.params.teamId) {
        teamId = +route.params.teamId;
      }

      if (!teamId || !permissionsModel?.hasAccessToTeam(teamId)) {
        teamId = lastAccessedTeam[orgId];
      }

      if (!teamId) {
        teamId = teams?.[0]?.id;
      }

      if (isTeamRoute && teamId !== Number(route?.params.teamId)) {
        navigate(ROUTE.toHome(orgId, teamId));

        return;
      }

      if (!teamId || !permissionsModel?.hasAccessToTeam(teamId)) {
        dispatchAppState({ type: TActionType.SetInvalidTeam, payload: true });
        dispatchAppState({ type: TActionType.SetAppLoading, payload: false });

        return;
      }

      const team = teams?.find(team => team.id === teamId);

      if (!team) {
        dispatchAppState({ type: TActionType.TeamId, payload: undefined });
        LOG.error('Could not find current team');

        return;
      }

      dispatchAppState({ type: TActionType.SetInvalidTeam, payload: false });
      dispatchAppState({ type: TActionType.TeamId, payload: teamId });
      dispatchAppState({ type: TActionType.SetAppLoading, payload: false });
      dispatchAppState({ type: TActionType.SetCurrentTeam, payload: team });
      setLastAccessedTeam(prevVal => (teamId ? { ...prevVal, [orgId]: +teamId } : prevVal));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teams, dispatchAppState, permissionsModel, isRouteMatch],
  );

  const authenticateUser = useCallback(() => {
    setTriggerLogoutParams(undefined);
    setTriggerLogout(undefined); // this removes the key from localstorage
  }, [setTriggerLogout, setTriggerLogoutParams]);

  const signOutUser = useCallback(
    async (params?: LogoutParams) => {
      try {
        await invalidateToken();
      } catch (e) {
        LOG.warn('No token to invalidate.', e);
      }

      unsetAuthCookie();
      unsetCookie(LEGACY_ORG_COOKIE.name);
      await delay(500);

      setTriggerLogoutParams(params);
      setTriggerLogout(true);
      await appModel.refreshPermissions();
      const urlParams = new URLSearchParams(location.search);
      urlParams.delete(SharedQueryParam.REDIRECT_TO);
      const urlParamsString = urlParams.toString();

      history.replaceState('', '', `?${urlParamsString}`);
      // add this flag to session storage to prevent polluting redirectTo params
      window.sessionStorage.setItem('logout_action', '1');
      window.localStorage.clear(); // clear local storage to prevent any issues with stale data

      delay(500).then(() => {
        if (isRouteMatch(WRITER_CONSOLE_ROUTES.home)) {
          return goToAiStudioSignIn();
        } else {
          return goToSignIn(undefined, { [SharedQueryParam.INVALID_SSO_TOKEN]: true });
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search, setTriggerLogout, setTriggerLogoutParams],
  );

  useLocalStorageListener(TRIGGER_LOGOUT_IN_TABS, storageEvent => {
    if (storageEvent.newValue && isAuthenticated) {
      delay(500).then(() =>
        goToSignIn(undefined, LocalStorageService.getItem<LogoutParams>(LocalStorageKey.triggerLogoutInAllTabsParams)),
      );
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      authenticateUser();
    } else if (authenticationError?.response.status === 401) {
      LOG.warn('Token is not valid. Redirect to login.');

      if (isRouteMatch(WRITER_CONSOLE_ROUTES.home)) {
        goToAiStudioSignIn();

        return;
      }

      const afterLoginRedirectUrl = extractEncodedPath(window.location.href);

      goToSignIn(afterLoginRedirectUrl);
    }
  }, [authenticationError?.response.status, authenticateUser, isAuthenticated, isRouteMatch]);

  useEffect(() => {
    if (organizations?.length && permissionsModel) {
      handleOrgId();
    }
  }, [organizations, permissionsModel, handleOrgId]);

  useEffect(() => {
    if (permissionsModel && !organizations) {
      fetchOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, permissionsModel]);

  useEffect(() => {
    if (organization?.id) {
      handleTeamId(organization.id);
    }
  }, [organization?.id, handleTeamId]);

  useEffect(() => {
    if (isUnverifiedEmail && organization) {
      goToHome(navigate, organization.id);
    }
  }, [isUnverifiedEmail, navigate, organization]);

  useEffect(() => {
    if (noOrganizations) {
      LOG.debug('No organizations found. Redirecting to root.');

      const redirectTo = redirectLocation(location) || ROUTE.root;
      const queryParams = new URLSearchParams(
        isInternalUrl(redirectTo)
          ? {
              [SharedQueryParam.REDIRECT_TO]: redirectTo,
            }
          : {},
      );

      // add query param to hide join org button
      if (autoJoinHideJoinOrg) {
        LOG.debug('Hiding join org button');
        queryParams.set(SearchQueryParam.createOrganization, TQueryParamBooleanStd.enum.true);
      }

      const locationRedirect = concatenateStrings('?', ROUTE.root, queryParams.toString());

      LOG.debug('Redirecting to:', locationRedirect);

      navigate(locationRedirect);
    } else if (noTeams && organization && !isRouteMatch(REACT_RELATIVE_ROUTE.authApp)) {
      if (aiStudioSubscription?.isSubscriptionActive === true) {
        const redirectTo = redirectLocation(location);

        // allow redirect to auth app for ai studio users
        if (redirectTo && redirectTo.startsWith(REACT_RELATIVE_ROUTE.authApp) && isInternalUrl(redirectTo)) {
          goToHrefWithReload(redirectTo);

          return;
        }

        if (isRouteMatch(REACT_RELATIVE_ROUTE.authApp)) {
          return;
        }

        navigate(ROUTE.toAiStudioHome(`${organization?.id}`));
      } else {
        goToHome(navigate, organization?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTeams, noOrganizations, organization, autoJoinHideJoinOrg]);

  useEffect(() => {
    if (organizations && unconfirmedOrganizations) {
      dispatchAppState({ type: TActionType.SetOrganizations, payload: organizations });
      dispatchAppState({ type: TActionType.SetUnconfirmedOrganizations, payload: unconfirmedOrganizations });
    }
  }, [dispatchAppState, organizations, unconfirmedOrganizations]);

  useEffect(() => {
    if (assistantSubscription.isModelReady) {
      dispatchAppState({
        type: TActionType.SetIsInvalidSubscription,
        payload:
          assistantSubscription.isCancelled || assistantSubscription.isTrialExpired || assistantSubscription.isExpired,
      });
    }
  }, [
    dispatchAppState,
    assistantSubscription.isCancelled,
    assistantSubscription.isExpired,
    assistantSubscription.isModelReady,
    assistantSubscription.isTrialExpired,
  ]);

  const onOrganizationChange = useCallback(
    (id: string) => {
      clearReportLocalStorage();
      goToHome(navigate, +id, true);
    },
    [navigate],
  );

  return (
    <AppSessionContext.Provider
      value={useMemo(
        () => ({ signOutUser, onOrganizationChange, fetchOrganizations }),
        [fetchOrganizations, onOrganizationChange, signOutUser],
      )}
    >
      {children}
    </AppSessionContext.Provider>
  );
});

export function useAppSessionContext() {
  const context = useContext(AppSessionContext);

  if (!context) {
    throw new Error('useAppSessionContext must be used within the AppSessionProvider');
  }

  return context;
}

export default AppSessionProvider;
