import type React from 'react';
import { useMemo } from 'react';

import type { BaseComponentsProps, IIdentityProvider, IScimConfig, IUserProfile } from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import { Heading, HeadingVariant, IdpButton, Switcher, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import { generateDropdownPlaceholder } from '@web/component-library';
import isEmpty from 'lodash/isEmpty';

import DomainDiscoverabilityPreferencesContextProvider from '../../../context/domainDiscoverabilityPreferencesContext';
import DomainDiscoverabilityPreferences from '../../organisms/DomainDiscoverabilityPreferences/DomainDiscoverabilityPreferences';
import IdentityProvider from '../IdentityProvider';
import SectionHeading from '../SectionHeading/SectionHeading';

import styles from './styles.module.css';

export enum ISwitcherTypes {
  PASSWORD = 'allowDirectPasswordLogin',
}

interface IIdentityProviderSettingsProps extends BaseComponentsProps {
  orgId: number | undefined;
  provider: IIdentityProvider;
  teams: DropdownOption[];
  scimTeams: DropdownOption[];
  isValidating: boolean;
  scimConfig: IScimConfig | null;
  showScimSection?: boolean;
  showScimSetupButton?: boolean;
  handleScimInit: () => void;
  handleEditProvider: () => void;
  handleEditScim: () => void;
  handleRemoveScim: () => void;
  handleRemoveProvider: () => void;
  handleSwitchers: (value: boolean, type: ISwitcherTypes) => void;
  userProfile: IUserProfile | undefined;
  handleTeamScimTeamChange: (value: number) => void;
}

export const IdentityProviderSettings: React.FC<IIdentityProviderSettingsProps> = ({
  provider,
  teams,
  orgId,
  scimTeams,
  isValidating,
  showScimSection,
  showScimSetupButton,
  handleScimInit,
  handleEditScim,
  handleRemoveScim,
  handleEditProvider,
  handleRemoveProvider,
  handleSwitchers,
  userProfile,
  handleTeamScimTeamChange,
}) => {
  const scimTeamsPlaceholder = useMemo<string>(() => generateDropdownPlaceholder(scimTeams), [scimTeams]);

  return (
    <div className={styles.wrapper}>
      <Heading variant={HeadingVariant.H3} bold>
        SAML SSO settings
      </Heading>
      <div className={styles.identityWrapper}>
        <IdentityProvider
          imageUrl={provider.idpType.imageUrl}
          handleEditProvider={handleEditProvider}
          handleRemoveProvider={handleRemoveProvider}
          text={
            provider.idpType.imageUrl ? (
              ''
            ) : (
              <Text variant={TextSize.XXXXL} color={TextColor.GREY2} bold>
                SAML SSO
              </Text>
            )
          }
        />
      </div>
      <div className={styles.sectionContainer}>
        <DomainDiscoverabilityPreferencesContextProvider
          orgId={orgId}
          teams={teams}
          userProfile={userProfile}
          ssoPasswordEnabled={!isEmpty(provider.idpType) && provider.allowDirectPasswordLogin}
          ssoSamlEnabled={!isEmpty(provider.idpType)}
        >
          <DomainDiscoverabilityPreferences showSamlCopy />
        </DomainDiscoverabilityPreferencesContextProvider>
      </div>
      <div className={styles.sectionContainer}>
        <SectionHeading
          heading="Sign in with password"
          description="Allow users from your organization to create their own password and sign in without SSO."
        />
        <div className={styles.sectionContainerBodySwitcher}>
          <Text variant={TextSize.L} bold>
            Allow password-only sign in
          </Text>
          <Switcher
            className={styles.bodySwitcher}
            id="allowProviderUserPasswordCreation"
            size={18}
            checked={provider.allowDirectPasswordLogin}
            disabled={isValidating}
            onChange={(value: boolean) => handleSwitchers(value, ISwitcherTypes.PASSWORD)}
          />
          <div className={styles.allowSection}>
            <div className={styles.allowSectionSwitcherZone}></div>
          </div>
        </div>
      </div>
      {showScimSection && (
        <div className={styles.scimSection}>
          <Heading variant={HeadingVariant.H3} bold>
            SCIM
          </Heading>
          <div className={styles.identityWrapper}>
            {showScimSetupButton ? (
              <IdpButton
                text={
                  <Text variant={TextSize.XXXXL} color={TextColor.GREY2} bold>
                    <div style={{ textAlign: 'center' }}>Set up</div>
                    <div>SCIM</div>
                  </Text>
                }
                onClick={handleScimInit}
              />
            ) : (
              <>
                <IdentityProvider
                  imageUrl={provider.idpType.imageUrl}
                  text={
                    <Text variant={TextSize.XXXXL} color={TextColor.GREY2} bold>
                      SCIM
                    </Text>
                  }
                  handleEditProvider={handleEditScim}
                  handleRemoveProvider={handleRemoveScim}
                />
                <div className={styles.allowSection}>
                  <div className={styles.allowSectionSwitcherZone}>
                    <Text variant={TextSize.L} bold>
                      Default user permissions
                    </Text>
                  </div>
                  {!isEmpty(scimTeams) && (
                    <InputGroup
                      className={styles.teamsDropdown}
                      id="teams"
                      label="default team"
                      inputType={InputTypes.MULTI_SELECT}
                      value={scimTeamsPlaceholder}
                      disabled={isValidating}
                      dropdownOptions={scimTeams}
                      handleChangeInput={(teamId: string) => handleTeamScimTeamChange(parseInt(teamId, 10))}
                      useCheckbox
                      hideOnCLick={false}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default IdentityProviderSettings;
