import { Enum } from '@writercolab/utils';

export const TWriterExtensionId = new Enum(
  'CHROME',
  'WORD',
  'OUTLOOK',
  'FIGMA',
  'CONTENTFUL',
  'EDGE',
  'MAC',
  'WINDOWS',
  'SLACK',
);

export const SLACK_APP_INSTALL_URL = 'https://app.writer.com/api/public/slack-assistant/install';
