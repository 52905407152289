import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import qs, { type ParsedQs } from 'qs';

import { SharedQueryParam } from '@writercolab/common-utils';
import {
  AvatarSize,
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  UserAvatar,
  useCustomSnackbar,
} from '@writercolab/ui-atoms';

import { LoadingPage } from '@web/component-library';
import type { IInitExtensionRequestParams } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';

import AccessAppsBottomBg from '../../../assets/backgrounds/accessAppsBottomBg.svg?react';
import SlackLogo from '../../../assets/logo/logoSlack.svg?react';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { REACT_RELATIVE_ROUTE } from '../../../services/config/routes';
import {
  getApplicationData,
  implicitlyRedirectApplication,
  initApplicationData,
} from '../../../services/request/extensions';
import { useAppState } from '../../../state';
import { goToHrefWithReload, goToSignIn } from '../../../utils/navigationUtils';
import { tryUrlDecode } from '../../../utils/urlUtils';
import Wordmark, { WordmarkVariant } from '../../generic/Wordmark';

import styles from './styles.module.css';

enum APP_ID {
  FIGMA = 'bde08624-492c-4652-9e6c-304494914c04',
  DESKTOP = '50d5c99d-b803-48c4-b204-9ce5e21aa16b',
  WORD = '6abd97ac-35a1-11ed-a261-0242ac120002',
  OUTLOOK = 'e2c95793-e93f-4847-bef6-08b6cefcb0b2',
  MACOS = '3ebfb1ed-a9ad-451a-ab89-9f3d67cca7ea',
  CONTENTFUL = 'f0834e99-9771-4e93-8dbd-dfd050637b7a',
  SLACK = 'acf2094c-fe8d-47fe-89dc-bc19e4d59cb8',
}

const APP_ADDITIONAL_REDIRECTS = [
  {
    id: APP_ID.SLACK,
    url: 'slack://',
  },
];

const UserInfo = ({ userAvatar, userFullName, userEmail }) => (
  <div className={styles.userInfo}>
    <div className={styles.icon}>
      <UserAvatar avatarPath={userAvatar || ''} size={AvatarSize.XL} fullName={userFullName} />
    </div>
    <div>
      <Text className={styles.email}>{userEmail}</Text>
    </div>
  </div>
);

export const AppsAccessPage: React.FC = observer(() => {
  usePageTitle('Desktop App');

  const { enqueueErrorSnackbar } = useCustomSnackbar();
  const location = useLocation();
  const { appModel, appState } = useAppState();
  const [isLoading, setIsLoading] = useState(true);
  const [appName, setAppName] = useState<string | null>(null);
  const {
    client_id: clientIdQueryParam,
    code_challenge: codeChallengeQueryParam,
    code_challenge_method: codeChallengeMethodQueryParam,
    write_key: writeKeyQueryParam,
    redirect_url: redirectUrlQueryParam,
  } = qs.parse(location.search.slice(1));
  const _queryParamValid = (paramValue: string): boolean => !!(paramValue && `${paramValue}`.length > 0);
  const applicationId = clientIdQueryParam as APP_ID;
  const isSlackApp = applicationId === APP_ID.SLACK;

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.signupViewedAppConsentPage, {});
  }, [appModel.analyticsService]);

  useEffect(() => {
    if (appModel.isAuthenticated === false) {
      const redirectUrlParams: string[] = [];

      if (_queryParamValid(clientIdQueryParam as string)) {
        redirectUrlParams.push(`${SharedQueryParam.CLIENT_ID}=${clientIdQueryParam}`);
      }

      if (_queryParamValid(codeChallengeQueryParam as string)) {
        redirectUrlParams.push(`${SharedQueryParam.CODE_CHALLENGE}=${codeChallengeQueryParam}`);
      }

      if (_queryParamValid(codeChallengeMethodQueryParam as string)) {
        redirectUrlParams.push(`${SharedQueryParam.CODE_CHALLENGE_METHOD}=${codeChallengeMethodQueryParam}`);
      }

      if (_queryParamValid(writeKeyQueryParam as string)) {
        redirectUrlParams.push(`${SharedQueryParam.WRITE_KEY}=${writeKeyQueryParam}`);
      }

      if (_queryParamValid(redirectUrlQueryParam as string)) {
        redirectUrlParams.push(`${SharedQueryParam.REDIRECT_URL}=${tryUrlDecode(redirectUrlQueryParam as ParsedQs)}`);
      }

      goToSignIn(encodeURIComponent(`${REACT_RELATIVE_ROUTE.authApp}?${redirectUrlParams.join('&')}`));
    }
  }, [
    appModel.isAuthenticated,
    clientIdQueryParam,
    codeChallengeQueryParam,
    codeChallengeMethodQueryParam,
    writeKeyQueryParam,
    redirectUrlQueryParam,
  ]);

  const triggerErrorMessage = useCallback(
    (err: any) => {
      if (err.response?.data?.errors && err.response.data.errors[0] && err.response.data.errors[0]?.description) {
        enqueueErrorSnackbar(err.response.data.errors[0]?.description);
      } else if (err.response?.data) {
        enqueueErrorSnackbar(err.response.data);
      } else {
        enqueueErrorSnackbar(err.message);
      }
    },
    [enqueueErrorSnackbar],
  );

  const initAppAndGetRedirectUrl = useCallback(() => {
    const initApplicationParams: Partial<IInitExtensionRequestParams> = {};
    setIsLoading(true);

    if (_queryParamValid(clientIdQueryParam as string)) {
      initApplicationParams.clientId = `${clientIdQueryParam}`;
    }

    if (_queryParamValid(codeChallengeQueryParam as string)) {
      initApplicationParams.codeChallenge = `${codeChallengeQueryParam}`;
    }

    if (_queryParamValid(codeChallengeMethodQueryParam as string)) {
      initApplicationParams.codeChallengeMethod = `${codeChallengeMethodQueryParam}`;
    }

    if (_queryParamValid(writeKeyQueryParam as string)) {
      initApplicationParams.writeKey = `${writeKeyQueryParam}`;
    }

    if (_queryParamValid(redirectUrlQueryParam as string)) {
      initApplicationParams.redirectUrl = `${tryUrlDecode(redirectUrlQueryParam as string)}`;
    }

    return initApplicationData(initApplicationParams)
      .then(res => res?.redirect_url)
      .catch(triggerErrorMessage)
      .finally(() => setIsLoading(false));
  }, [
    clientIdQueryParam,
    codeChallengeQueryParam,
    codeChallengeMethodQueryParam,
    writeKeyQueryParam,
    redirectUrlQueryParam,
    triggerErrorMessage,
  ]);

  const redirectToAuthUrl = useCallback(async () => {
    const redirectUrl = await initAppAndGetRedirectUrl();
    const additionalRedirectUrl = APP_ADDITIONAL_REDIRECTS.find(({ id }) => id === applicationId)?.url;

    if (!redirectUrl) {
      return;
    }

    if (additionalRedirectUrl) {
      try {
        await implicitlyRedirectApplication(redirectUrl);
        goToHrefWithReload(additionalRedirectUrl);
      } catch (e: any) {
        triggerErrorMessage(e);
      }
    } else {
      goToHrefWithReload(redirectUrl);
    }
  }, [applicationId, triggerErrorMessage, initAppAndGetRedirectUrl]);

  useEffect(() => {
    if (
      appModel.isAuthenticated &&
      !appState.isInvalidSubscription &&
      !appModel.isUnverifiedEmail &&
      appState.organizations?.length !== 0
    ) {
      getApplicationData(clientIdQueryParam as string)
        .then(({ name, consent, buffer }) => {
          setAppName(name);

          if (consent.auto || buffer.enabled) {
            redirectToAuthUrl();
          }
        })
        .catch(triggerErrorMessage)
        .finally(() => setIsLoading(false));
    }
  }, [
    appModel.isAuthenticated,
    appState.isInvalidSubscription,
    appModel.isUnverifiedEmail,
    appState.organizations,
    clientIdQueryParam,
    redirectToAuthUrl,
    triggerErrorMessage,
  ]);

  const onAllowAccessButtonClick = useCallback(async () => {
    appModel.analyticsService.track(AnalyticsActivity.signupClickedAllowAccessOnAppConsent, {});

    await redirectToAuthUrl();
  }, [appModel.analyticsService, redirectToAuthUrl]);

  const heading = useMemo(() => {
    switch (applicationId) {
      case APP_ID.DESKTOP:
      case APP_ID.MACOS:
        return appName && <span>{appName} wants to access your Writer account</span>;
      case APP_ID.OUTLOOK:
      case APP_ID.WORD:
        return appName && <span>{appName} is accessing your Writer account</span>;
      case APP_ID.FIGMA:
      case APP_ID.CONTENTFUL:
        return (
          appName && (
            <span>
              {appName} is accessing <br /> your Writer account
            </span>
          )
        );
      case APP_ID.SLACK:
        return (
          appName && (
            <span>
              {appName} wants to access your <br /> Writer account
            </span>
          )
        );

      default:
        return '';
    }
  }, [appName, applicationId]);

  const description = useMemo(() => {
    switch (applicationId) {
      case APP_ID.DESKTOP:
      case APP_ID.MACOS:
        return (
          <>
            <Text>You should see a prompt to open {appName}.</Text>
            <Text>If not, click below to open the app.</Text>
          </>
        );
      case APP_ID.FIGMA:
        return <Text>You can close this window and head back to Figma.</Text>;
      case APP_ID.OUTLOOK:
        return <Text>You can close this window and head back to Outlook.</Text>;
      case APP_ID.WORD:
        return <Text>You can close this window and head back to Word.</Text>;
      case APP_ID.CONTENTFUL:
        return <Text>You can close this window and head back to Contentful.</Text>;
      case APP_ID.SLACK:
        return <Text>Grant access below and open the app.</Text>;
      default:
        return '';
    }
  }, [appName, applicationId]);

  const actionButton = useMemo(() => {
    switch (applicationId) {
      case APP_ID.DESKTOP:
      case APP_ID.MACOS:
        return (
          <Button
            size={SizeTypes.XXXL}
            type={ButtonTypes.PRIMARY}
            className={styles.accessButton}
            onClick={onAllowAccessButtonClick}
            isLoading={isLoading}
          >
            Allow access and open Writer
          </Button>
        );
      case APP_ID.SLACK:
        return (
          <Button
            size={SizeTypes.XXXL}
            type={ButtonTypes.PRIMARY}
            className={styles.accessButton}
            onClick={onAllowAccessButtonClick}
            isLoading={isLoading}
          >
            Allow access
          </Button>
        );
      case APP_ID.FIGMA:
      case APP_ID.OUTLOOK:
      case APP_ID.WORD:
      default:
        return null;
    }
  }, [onAllowAccessButtonClick, applicationId, isLoading]);

  if (isLoading && !appName) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        <div className={styles.containerLogo}>
          {isSlackApp && (
            <>
              <SlackLogo className={styles.logoSlack} />
              <Icon className={styles.iconSwitch} name={IconVariant.SORT} width={20} height={20} />
            </>
          )}
          <Wordmark className={styles.logoWriter} variant={WordmarkVariant.TRANSPARENT} />
        </div>
        <div className={styles.containerHeading}>
          <Heading bold variant={HeadingVariant.H2} className={styles.heading}>
            {heading}
          </Heading>
        </div>
        {appState.userProfile && (
          <div className={styles.containerUserInfo}>
            <UserInfo
              userAvatar={appState.userProfile.avatar}
              userFullName={appState.userProfile.fullName}
              userEmail={appState.userProfile.email}
            />
          </div>
        )}
        <div className={styles.containerDescription}>{description}</div>
        <div>{actionButton}</div>
        <div className={styles.containterBackground}>
          <AccessAppsBottomBg />
        </div>
      </div>
    </div>
  );
});

export default AppsAccessPage;
