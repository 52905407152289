import type React from 'react';
import { useEffect, useState } from 'react';

import cx from 'classnames';

import type { BaseComponentsProps } from '@writercolab/common-utils';
import {
  AnalyticsDraftPlace,
  BUILDER_TEMPLATE_ID,
  TAssetToDelete,
  getDateFormatMMMYYYY,
  getFullDateFormatWithTime,
  noop,
  openNewTab,
} from '@writercolab/common-utils';
import { getDeletionBannerInfo } from '@writercolab/data-retention-utils';
import type { TRetentionConfig } from '@writercolab/models';
import { ALLOWED_QUILL_FORMATS } from '@writercolab/quill-delta-utils';
import { ReactQuill } from '@writercolab/react-quill';
import {
  Button,
  ButtonTypes,
  DataRetentionDeletionBanner,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';

import type { IDraftFeedbackValues } from '@web/component-library';
import { DraftFeedback } from '@web/component-library';
import { observer } from 'mobx-react-lite';
import { useAppState } from 'state';
import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';

import { useDraftsContext } from '../../../context/draftsContext';
import { getEventClipsFromInputs } from '../../../utils/draftsUtils';
import TitleWithDescriptionRow from '../../generic/TitleWithDescriptionRow';
import DraftInputs from '../../molecules/DraftInputs';
import DraftOutputs from '../../molecules/DraftOutputs';

import styles from './styles.module.css';
import { AnalyticsActivity } from 'constants/analytics';

const handleDataRetentionLearnMoreClick = () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL);

interface IEditDraftSlideoutProps extends BaseComponentsProps {
  isPreviewMode?: boolean;
  dataRetentionPreferences?: TRetentionConfig | null;
}

export const EditDraftPanel: React.FC<IEditDraftSlideoutProps> = observer(
  ({ className, isPreviewMode = false, dataRetentionPreferences }) => {
    const [isInputsOpen, setIsInputsOpen] = useState(false);
    const {
      state,
      onRateDraft,
      onDraftCopy,
      onDeleteDraft,
      handleExpandDraft,
      openDraftInNewDocument,
      goToDocumentDrafts,
      downloadMediaFile,
      downloadMediaFileTranscript,
    } = useDraftsContext();
    const {
      appModel: { analyticsService, commandsModel },
    } = useAppState();
    const { openedDraft, isLoadingOpenedDraft, isDeletingDraft, templateInputs, voiceName } = state;
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

    const { shouldShowDataDeletionBanner, readableDeletionDate } = getDeletionBannerInfo(
      openedDraft?.creationTime,
      TAssetToDelete.enum.Draft,
      dataRetentionPreferences,
    );

    const access = {
      isSeoBlogBuilder: openedDraft?.template.id === BUILDER_TEMPLATE_ID.SEO_BLOG || false,
      isEventTakeaways: openedDraft?.template.id === BUILDER_TEMPLATE_ID.EVENT_TAKEAWAYS || false,
    };

    const toggleFeedback = () => setIsFeedbackOpen(!isFeedbackOpen);

    const handleSubmitFeedback = (values: IDraftFeedbackValues) => {
      if (openedDraft) {
        analyticsService.track(AnalyticsActivity.rateDraft, {
          draft_view: isPreviewMode ? AnalyticsDraftPlace.PREVIEW_POPUP : AnalyticsDraftPlace.PANEL,
          template_name: openedDraft.template.name,
        });

        onRateDraft(openedDraft.id, `${openedDraft.document?.id}`, values);
        setIsFeedbackOpen(false);
      }
    };

    const handleDelete = () => {
      analyticsService.track(AnalyticsActivity.deleteDraft, {
        draft_view: isPreviewMode ? AnalyticsDraftPlace.PREVIEW_POPUP : AnalyticsDraftPlace.PANEL,
        template_name: openedDraft?.template?.name || '',
      });

      onDeleteDraft();
    };

    const handleCopyDraftContent = () => {
      analyticsService.track(AnalyticsActivity.copyDraft, {
        draft_view: isPreviewMode ? AnalyticsDraftPlace.PREVIEW_POPUP : AnalyticsDraftPlace.PANEL,
        template_name: openedDraft?.template?.name || '',
      });

      onDraftCopy();
    };

    const handleUseInNewDoc = () => {
      analyticsService.track(AnalyticsActivity.useDraftInNewDoc, {
        draft_view: isPreviewMode ? AnalyticsDraftPlace.PREVIEW_POPUP : AnalyticsDraftPlace.PANEL,
        template_name: openedDraft?.template?.name || '',
      });

      openDraftInNewDocument();
    };

    const toggleInputs = (isOpen: boolean) => {
      setIsInputsOpen(isOpen);

      if (isOpen) {
        analyticsService.track(AnalyticsActivity.showDraftInputs, {
          draft_view: isPreviewMode ? AnalyticsDraftPlace.PREVIEW_POPUP : AnalyticsDraftPlace.PANEL,
          template_name: openedDraft?.template?.name || '',
        });
      }
    };

    const handleGoToDocument = () => {
      goToDocumentDrafts();
    };

    useEffect(() => {
      if (isPreviewMode) {
        analyticsService.track(AnalyticsActivity.previewedDraft, {
          draft_view: AnalyticsDraftPlace.PREVIEW_POPUP,
          template_name: openedDraft?.template?.name || '',
        });
      } else {
        analyticsService.track(AnalyticsActivity.viewedDraft, {});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openedDraft?.id]);

    const draftTemplateName = openedDraft?.template?.name || '';
    const draftDocumentDate = `${openedDraft?.document?.title} ${getDateFormatMMMYYYY(
      openedDraft?.document?.creationTime,
    )}`;
    const documentDateIcon = (
      <span className={styles.goToDoc} onClick={handleGoToDocument}>
        <Icon name={IconVariant.OPEN_LINK} />
      </span>
    );
    const openedDraftTemplateName = getFullDateFormatWithTime(openedDraft?.creationTime || '');

    const draftRewriteOption = commandsModel.rewritePrompts.find(prompt => prompt.id === openedDraft?.reWritePromptId);

    return (
      <div
        className={cx(styles.draftContainer, className, {
          [styles.isDraftPreview]: isPreviewMode,
        })}
      >
        {isLoadingOpenedDraft || isDeletingDraft ? (
          <ThreeDotsLoader />
        ) : (
          <>
            <div className={styles.buttonGroup}>
              <Tooltip
                title="Delete draft"
                placement={isPreviewMode ? 'top' : 'bottom'}
                tooltipTitleClassName={styles.semiBold}
              >
                <Button onClick={handleDelete} icon={<Icon name={IconVariant.TRASH} />} type={ButtonTypes.GRAY} round />
              </Tooltip>

              <Tooltip
                tooltipTitleClassName={styles.semiBold}
                title="Rate this draft"
                placement={isPreviewMode ? 'top' : 'bottom'}
              >
                <Button
                  onClick={toggleFeedback}
                  icon={<Icon name={IconVariant.FEEDBACK} />}
                  type={ButtonTypes.GRAY}
                  round
                />
              </Tooltip>

              <Tooltip
                tooltipTitleClassName={styles.semiBold}
                title="Copy text"
                placement={isPreviewMode ? 'top' : 'bottom'}
              >
                <Button
                  icon={<Icon name={IconVariant.COPY_PASTE} />}
                  type={ButtonTypes.GRAY}
                  onClick={handleCopyDraftContent}
                  round
                />
              </Tooltip>

              {!isPreviewMode && (
                <div className={styles.expandWrapper}>
                  <Tooltip tooltipTitleClassName={styles.semiBold} title="Preview" placement="bottom">
                    <Button
                      className={styles.expandButton}
                      icon={<Icon name={IconVariant.EXPAND_ALL_SCREEN} width={13} height={13} />}
                      type={ButtonTypes.GRAY}
                      onClick={handleExpandDraft}
                      round
                    />
                  </Tooltip>
                </div>
              )}

              {!access.isSeoBlogBuilder && (
                <Tooltip
                  tooltipTitleClassName={styles.semiBold}
                  title="Send draft and input to new doc"
                  placement={isPreviewMode ? 'top' : 'bottom'}
                >
                  <Button
                    className={styles.useDocButton}
                    icon={<Icon name={IconVariant.USE_IN_NEW_DOC} />}
                    type={ButtonTypes.PRIMARY}
                    onClick={handleUseInNewDoc}
                  >
                    <Text variant={TextSize.L} color={TextColor.WHITE} bold>
                      Use in new doc
                    </Text>
                  </Button>
                </Tooltip>
              )}
            </div>

            <div
              className={cx(styles.titleGroup, {
                [styles.isDraftPreview]: isPreviewMode,
              })}
            >
              <TitleWithDescriptionRow title="App" description={draftTemplateName} />
              {openedDraft?.document && (
                <TitleWithDescriptionRow title="Document" description={draftDocumentDate} icon={documentDateIcon} />
              )}
              <TitleWithDescriptionRow title="Generated" description={openedDraftTemplateName} />
              {shouldShowDataDeletionBanner && readableDeletionDate && (
                <DataRetentionDeletionBanner
                  asset={TAssetToDelete.enum.Draft}
                  readableDate={readableDeletionDate}
                  className={styles.banner}
                  onLearnMoreClick={handleDataRetentionLearnMoreClick}
                />
              )}
              <DraftInputs
                isInputsOpen={isInputsOpen}
                inputs={openedDraft?.inputs || []}
                media={openedDraft?.media}
                templateInputs={templateInputs}
                onToggle={toggleInputs}
                downloadMediaFile={downloadMediaFile}
                voiceName={voiceName}
                rewriteOptionName={draftRewriteOption?.name ?? null}
              />
            </div>

            <div className={styles.bodyGroup}>
              {openedDraft?.title && (
                <Heading className={styles.heading} variant={HeadingVariant.H3}>
                  {openedDraft.title}
                </Heading>
              )}
              <div className={cx(styles.containerEditor, 'writer-editor')}>
                <ReactQuill
                  className={styles.body}
                  placeholder=""
                  theme="snow"
                  defaultValue={openedDraft?.body}
                  readOnly
                  onChange={noop}
                  formats={ALLOWED_QUILL_FORMATS}
                  modules={{
                    toolbar: false,
                  }}
                />
              </div>
            </div>

            {openedDraft?.media && (
              <DraftOutputs
                inputMedia={openedDraft.media}
                downloadMediaFileTranscript={downloadMediaFileTranscript}
                eventClips={getEventClipsFromInputs(openedDraft.inputs)}
              />
            )}
            <div className={cx(styles.fadeOut, { [styles.isDraftPreview]: isPreviewMode })} />
            {isFeedbackOpen && (
              <DraftFeedback
                className={cx(styles.draftFeedback, { [styles.isDraftPreview]: isPreviewMode })}
                onClose={toggleFeedback}
                onSubmit={handleSubmitFeedback}
              />
            )}
          </>
        )}
      </div>
    );
  },
);

export default EditDraftPanel;
