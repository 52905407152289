import { useEffect, useState } from 'react';

export interface WindowDimensions {
  width: number;
  height: number;
}

export function useWindowDimensions() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return { width, height } as WindowDimensions;
}

export default useWindowDimensions;
