import React, { useReducer, useState } from 'react';

import { BaseComponentsProps, IIdentityProvider, IIdentityProviderName, IIdpMetaData } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

// STEPS
import StepOne from './components/StepOne';
import StepTwo from './components/StepTwo';

import { Buffer } from 'buffer';

import { STEPS_AMOUNT } from './utils/constants';
import { stepValidatorWithValidatingMethods } from './utils/stepValidator';

import styles from './styles.module.css';

export type TUpdateProviderValue = string | string[] | IIdentityProviderName | IIdpMetaData | null;
interface ISsoSetupManagerProps extends BaseComponentsProps {
  provider: IIdentityProvider;
  identityProviders: IIdentityProviderName[];
  isEditingMode: boolean;
  isValidating: boolean;
  handleSubmit: (provider) => void;
  handleExit: () => void;
}

export const SsoSetupManager: React.FC<ISsoSetupManagerProps> = ({
  provider,
  identityProviders,
  isEditingMode,
  isValidating,
  handleSubmit,
  handleExit,
}) => {
  const decodedMetadata = Buffer.from(provider.idpMetaDataConfigBase64, 'base64').toString('ascii');
  const [step, setStep] = useState(1);
  const [isNextStepAvailable, setIsNextStepAvailable] = useState(true);
  const [xmlString, setXmlString] = useState(decodedMetadata || '');
  const [editedProvider, setEditedProvider] = useReducer((state, newState) => ({ ...state, ...newState }), provider);

  // You can add async validation methods for each step here
  const stepValidator = stepValidatorWithValidatingMethods();

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = async () => {
    if (step === STEPS_AMOUNT) {
      await handleSubmit(editedProvider);
    } else {
      const isValid = await stepValidator[step](editedProvider);

      if (isValid) {
        setStep(step + 1);
      }
    }
  };

  const updateProviderField = (field: string, value: TUpdateProviderValue) => {
    setEditedProvider({ [field]: value });
  };

  const updateProviderFields = updatedFields => {
    setEditedProvider(updatedFields);
  };

  const getStepElement = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            step={step}
            updateProvider={updateProviderField}
            idp={editedProvider.idpType.name}
            identityProviders={identityProviders}
            setIsNextStepAvailable={setIsNextStepAvailable}
          />
        );
      case 2:
        return (
          <StepTwo
            step={step}
            updateProvider={updateProviderFields}
            provider={editedProvider}
            setIsNextStepAvailable={setIsNextStepAvailable}
            xmlString={xmlString}
            setXmlString={setXmlString}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.providerHeader}>
        <div className={styles.stepContainer}>
          <Heading variant={HeadingVariant.H3} bold>
            SSO setup guide
          </Heading>
          <Text variant={TextSize.XS} upperCase className={styles.stepsAmount}>
            {step} of {STEPS_AMOUNT}
          </Text>
        </div>

        <div className={styles.buttonControls}>
          {isEditingMode && (
            <Button onClick={handleExit} size={SizeTypes.MEDIUM}>
              <Icon name={IconVariant.CLOSE} width={12} height={12} />
              Exit setup
            </Button>
          )}

          {step > 1 && (
            <Button onClick={handleBack} size={SizeTypes.MEDIUM}>
              Back
            </Button>
          )}

          {step <= STEPS_AMOUNT && (
            <Button
              onClick={handleNext}
              type={ButtonTypes.PRIMARY}
              isLoading={isValidating}
              disabled={!isNextStepAvailable}
            >
              {step === STEPS_AMOUNT ? 'Done' : 'Next'}
            </Button>
          )}
        </div>
      </div>

      <div className={styles.stepWrapper}>{identityProviders.length > 0 && getStepElement()}</div>
    </div>
  );
};

export default SsoSetupManager;
