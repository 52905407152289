import { ParsedQs } from 'qs';

import { WRITER_ACADEMY_DOMAIN_NAME } from '../constants/LearningCenter';
import { getLogger } from './logger';

const LOG = getLogger('urlUtils');

export const isInternalUrl = (url: string): boolean => {
  const currentLocationHref = window.location.href;
  const _url = url.trim();

  if (url.startsWith('//')) {
    _url.replace(/^\/\//, '/').trim();
  }

  if (!isSafeUrl(_url)) {
    return false;
  }

  if (isExternalUrl(_url)) {
    return false;
  }

  if (startsWithRelativePath(_url)) {
    return true;
  }

  return isSameHostname(_url, currentLocationHref);
};

export const isWhitelistedDomainUrl = (url: string, whitelist: string[] = []): boolean => {
  const _url = new URL(url).hostname;

  return whitelist.some(domain => _url.includes(domain));
};

export const isWriterAcademyUrl = (url: string): boolean => url.includes(WRITER_ACADEMY_DOMAIN_NAME);

export const isExternalUrl = (url: string): boolean => {
  try {
    const inputUrl = new URL(url, window.location.href);

    return inputUrl.hostname !== window.location.hostname;
  } catch (error) {
    LOG.error('Invalid URL:', error);

    return false;
  }
};

export const startsWithRelativePath = (url: string): boolean => url.startsWith('/') || url.startsWith('//');

const isSameHostname = (url: string, base: string): boolean => {
  try {
    const inputUrl = new URL(url, base);
    const currentLocation = new URL(base);

    return inputUrl.hostname === currentLocation.hostname;
  } catch (error) {
    LOG.error('Invalid URL:', error);

    return false;
  }
};

const isSafeUrl = (urlString: string): boolean => {
  try {
    const url = new URL(urlString, window.location.href);

    const unsafePatterns: RegExp[] = [
      /^javascript:/i,
      /<script.*?>.*?<\/script>/i,
      /<[^>]+>/i,
      /%3Cscript/i,
      /%3E/i,
      /%3C/i,
      /&#[xX]([0-9A-Fa-f]+);?/i,
      /&#([0-9]+);?/i,
      /eval\(/i,
      /expression\(/i,
      /onload=|onerror=|onmouseover=|onclick=/i,
      /document\.|window\.|location\./i,
      /alert\(/i,
      /console\.log\(/i,
      /data:text\/html;base64,/i,
      /%6A%61%76%61%73%63%72%69%70%74%3A/i,
      /<svg[\s\S]*?>[\s\S]*?<\/svg>/i,
      /<iframe[\s\S]*?<\/iframe>/i,
      /<object[\s\S]*?<\/object>/i,
      /on\w+=/i,
      /vbscript:/i,
      /file:/i,
      /blob:/i,
      /srcdoc=/i,
      /base64,[\s\S]*?/i,
      /<style[\s\S]*?<\/style>/i,
    ];

    const decodedPathname = decodeURIComponent(url.pathname);

    return !unsafePatterns.some(pattern => pattern.test(decodedPathname));
  } catch (err) {
    LOG.error('invalid url:', err);

    return false;
  }
};

export const tryUrlDecode = (url: string | ParsedQs | string[] | ParsedQs[] | undefined): string => {
  try {
    return decodeURIComponent(`${url}`);
  } catch (err) {
    LOG.error('Error decoding URL:', err);

    return String(url);
  }
};
