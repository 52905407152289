import { type ChangeEvent, useMemo, useState } from 'react';

import cx from 'classnames';

import { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Modal, RadioGroup, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { TextareaField } from 'components/organisms/TextareaField/TextareaField';

import { TKnowledgeGraphUrl } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphWebConnectorUIModel } from '../../KnowledgeGraphWebConnectorModel.ui';
import { KG_URLS_LIMIT, KG_URLS_OPTIONS, getUrlsLength, validateUrls } from '../../utils';

import styles from './AddUrlModal.module.css';

interface IAddUrlModalProps {
  model: KnowledgeGraphWebConnectorUIModel;
}

export const AddUrlModal = observer<IAddUrlModalProps>(({ model }) => {
  const { analyticsService, modalsManager, urls, isLoading, validUrlsCount, excludeUrlsCount, onAddUrl, setIsLoading } =
    model;
  const [type, setType] = useState<typeof TKnowledgeGraphUrl.type>(TKnowledgeGraphUrl.enum.SubPages);
  const urlsField = useMemo(
    () =>
      FieldModel.build({
        init: () => '',
        autotouched: true,
        validation: value => {
          const validationMessage = validateUrls({
            urls: value,
            prevUrls: urls,
            prevUrlsCount: validUrlsCount + excludeUrlsCount,
          });

          if (validationMessage) {
            analyticsService.track(AnalyticsActivity.kgWebUrlInputErrorViewed, {
              urls: value,
              error: validationMessage,
              url_count: getUrlsLength(value) + validUrlsCount + excludeUrlsCount,
            });
          }

          return validationMessage;
        },
      }),
    [urls, validUrlsCount, excludeUrlsCount, analyticsService],
  );
  const hasQueryParam = !!urlsField.value?.includes('?');

  return (
    <Modal
      open
      title="Add a URL"
      className={styles.content}
      modalTitleClassName={styles.title}
      disableBackdropClick
      handleClose={() => {
        analyticsService.track(AnalyticsActivity.kgWebAddUrlModalClosed, {
          urls: urlsField.value,
          type,
        });
        setIsLoading(false);
        modalsManager.hideModal();
      }}
    >
      <>
        <ul className={styles.description}>
          <li>URLs must be public and indexible</li>
          <li>Cannot contain dynamic search parameters</li>
        </ul>

        <Text variant={TextSize.XL} color={TextColor.BLACK}>
          Website URLs
        </Text>
        <TextareaField
          className={cx(styles.textarea, { [styles.textareaWarning]: hasQueryParam && !urlsField.error })}
          field={urlsField}
          placeholder="https://"
        />
        <Text variant={TextSize.M} color={TextColor.GREY3}>
          {hasQueryParam
            ? 'Note: Dynamic pages behind internal search engines can’t be scraped'
            : 'Tip: you can include multiple URLs separated by commas.'}
        </Text>

        <RadioGroup
          className={styles.radioGroup}
          options={KG_URLS_OPTIONS}
          name="url-type"
          currentValue={type}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setType(e.target.value as typeof TKnowledgeGraphUrl.type)}
          column
        />
        <Text variant={TextSize.M} color={TextColor.GREY3}>
          Once added, you can exclude specific subpages from domains.
        </Text>

        <div className={styles.controls}>
          <Text variant={TextSize.M} color={TextColor.BLACK}>
            {getUrlsLength(urlsField.value) + validUrlsCount + excludeUrlsCount}/{KG_URLS_LIMIT} URLs (
            {getUrlsLength(urlsField.value) + validUrlsCount} included · {excludeUrlsCount} excluded)
          </Text>
          <Button
            isLoading={isLoading}
            disabled={!!urlsField.error}
            size={SizeTypes.MEDIUM}
            type={ButtonTypes.PRIMARY}
            onClick={() => {
              if (urlsField.value) {
                onAddUrl(urlsField.value, type, getUrlsLength(urlsField.value) + validUrlsCount + excludeUrlsCount);
              }
            }}
          >
            Add
          </Button>
        </div>
      </>
    </Modal>
  );
});
