export const STEPS_AMOUNT = 2;

export const setupIDPManagerTextConstants = {
  descriptionStepOne: `Select your Identity Provider (IdP)`,
  descriptionStepTwo: `Add your email domains`,
  infoTextStepTwo: `Confirm the email domain that you have set up with your IdP (e.g. “acme.com”). Only users using this email domain will be able to log in via SAML SSO. You can add more than one email domain.`,
  description: (name: string) => `Set up the connection between Writer and ${name}`,
  stepADescription: (name: string) => `Copy/paste from Writer into ${name}`,
  stepBDescription: (name: string) => `Upload your SAML metadata XML from ${name}`,
  spUrlInfoText: `The SP (Service Provider) SSO URL is provided by Writer. It is sometimes called the ACS (Assertion Consumer Service) URL. The URL tells your IdP where to send its SAML Response after a user is authenticated.`,
  spEntityInfoText: `The SP (Service Provider) SSO URL is provided by Writer. It is also known as an Audience URI.`,
  samlMetadataXMLInfoText: `This XML file is provided by Writer. Some IdPs may be able to accept this XML, for easy setup from your identity provider side.`,
  idpIssuerInfoText: `This value is provided by your Identity Provider. Depending on your IP, this may called "IdP Issuer" or "IdP Entity ID".`,
  idpIssuerDescription: `IdP Issuer (Entity ID)`,
  idpSsoUrlInfoText: `This value is provided by your IdP. It is the URL that Writer will invoke to redirect users to your Identity Provider.`,
  idpSsoUrlDescription: `IdP SSO URL`,
  spMetadataDescription: `SP metadata XML`,
  idpMetadataDescription: `IDP metadata XML`,
  autoPopulatedText: `Autopopulates when XML is uploaded`,
};
