import React from 'react';

import { Button, ButtonTypes, Heading, HeadingVariant, Icon, IconVariant, SizeTypes } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IOnboardingShowcaseModal {
  title: string;
  duration?: string;
  url: string;
  counter?: string;
  icon?: string;
  ctaButtonLabel?: string;
  onClickCtaButton?: () => void;
  onClose: () => void;
  onClickPreviousVideo?: () => void;
  onClickNextVideo?: () => void;
  isPreviousVideoEnabled?: boolean;
  isNextVideoEnabled?: boolean;
}

const OnboardingShowcaseModal: React.FC<IOnboardingShowcaseModal> = ({
  title,
  duration,
  url,
  counter,
  icon,
  ctaButtonLabel,
  onClickCtaButton,
  onClose,
  onClickPreviousVideo,
  onClickNextVideo,
  isPreviousVideoEnabled,
  isNextVideoEnabled,
}) => (
  <div className={styles.outerContainer}>
    <div className={styles.container}>
      <header>
        <div className={styles.counterContainer}>
          {counter && <span className={styles.counter}>{counter} watched</span>}
          <Button
            round
            type={ButtonTypes.BLACK}
            className={styles.closeButton}
            onClick={onClose}
            icon={<Icon name={IconVariant.CLOSE} width={32} height={32} />}
          />
        </div>
        <Heading variant={HeadingVariant.H2} medium className={styles.heading}>
          {icon && <Icon name={IconVariant[icon]} width={28} height={28} />}
          {title}
          {duration && <span className={styles.duration}>{duration}</span>}
        </Heading>
      </header>

      <iframe
        className={styles.player}
        width="960"
        height="540"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>

      <footer>
        <Button
          type={ButtonTypes.BLACK}
          size={SizeTypes.LARGE}
          className={styles.nextVideoButton}
          onClick={isNextVideoEnabled ? onClickNextVideo : onClose}
          icon={<Icon name={IconVariant.BACK_ARROW} width={14} height={14} />}
          content={isNextVideoEnabled ? 'Next video' : 'Back to Home'}
        />
        {isPreviousVideoEnabled && (
          <Button
            type={ButtonTypes.SECONDARY}
            size={SizeTypes.LARGE}
            className={styles.previousVideoButton}
            onClick={onClickPreviousVideo}
            icon={<Icon name={IconVariant.BACK_ARROW} width={14} height={14} />}
            content="Previous video"
          />
        )}
        {ctaButtonLabel && (
          <Button
            type={ButtonTypes.SECONDARY}
            size={SizeTypes.LARGE}
            className={styles.ctaButton}
            onClick={onClickCtaButton}
            icon={<Icon name={IconVariant.BACK_ARROW} width={14} height={14} />}
            content={ctaButtonLabel}
          />
        )}
      </footer>
    </div>
  </div>
);

export default OnboardingShowcaseModal;
