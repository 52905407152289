import { ITeam, ITeamAdmins, ITeamDetails, UserTeamRole } from '@writercolab/common-utils';
import { components } from '@writercolab/network';
import { AVATAR_COLOR } from '@writercolab/ui-atoms';
import { AutocompleteOption, IAutocompleteValues } from '@writercolab/ui-molecules';
import { getStaticItem } from '@writercolab/utils';

import {
  IAdminsOptionsList,
  IMappedTeamDetails,
  ITeamsManagedByProps,
  MAX_TEAM_SIZE,
  TEAM_NAME_DUPLICATE_ERROR_MESSAGE,
} from '@web/types';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

import { concatenateStrings } from './stringUtils';

export const getTeamColor = (teamId: number) => getStaticItem(Object.keys(AVATAR_COLOR), teamId);

const getRoleName = (role?: UserTeamRole): string => {
  let roleName;

  switch (role) {
    case UserTeamRole.ORG_ADMIN:
      roleName = 'org admin';
      break;
    case UserTeamRole.ADMIN:
      roleName = 'team admin';
      break;
    default:
      roleName = 'member';
  }

  return roleName;
};

const getRoleColor = (role?: UserTeamRole): string => {
  let color;

  switch (role) {
    case UserTeamRole.ORG_ADMIN:
      color = 'var(--writer-green-3)';
      break;
    case UserTeamRole.ADMIN:
      color = 'var(--writer-blue-3)';
      break;
    default:
      color = 'var(--classic-black)';
  }

  return color;
};
export const teamDetailsMapper = (team: ITeamDetails): IMappedTeamDetails => {
  const color = getTeamColor(team.id);
  const managedByProps = {} as ITeamsManagedByProps;

  if (team.styleguide?.teamId && team.styleguide.teamId !== team.id) {
    managedByProps.suggestionsManagedBy = {
      id: String(team.styleguide.teamId),
      name: team.styleguide.teamName,
      color: AVATAR_COLOR[getTeamColor(team.styleguide?.teamId)],
    };
  }

  if (team.termBank?.teamId && team.termBank.teamId !== team.id) {
    managedByProps.termsManagedBy = {
      id: String(team.termBank.teamId),
      name: team.termBank.teamName,
      color: AVATAR_COLOR[getTeamColor(team.termBank?.teamId)],
    };
  }

  if (team.snippet?.teamId && team.snippet.teamId !== team.id) {
    managedByProps.snippetsManagedBy = {
      id: String(team.snippet.teamId),
      name: team.snippet.teamName,
      color: AVATAR_COLOR[getTeamColor(team.snippet?.teamId)],
    };
  }

  return {
    ...team,
    ...managedByProps,
    color: AVATAR_COLOR[color],
  };
};
export const teamsDetailsArrayMapper = (teams: ITeamDetails[]): IMappedTeamDetails[] =>
  teams.map(team => teamDetailsMapper(team));

export const teamAdminsMapper = (admins: ITeamAdmins[], currentUsersId?: number): IAutocompleteValues[] =>
  orderBy(
    admins,
    ['role', user => !!user.fullName, user => user.fullName?.toLowerCase(), user => user.email?.toLowerCase()],
    ['asc', 'desc', 'asc', 'asc'],
  ).map(i => ({
    name: i.fullName.trim(),
    subText: i.email,
    id: i.id,
    updateDisabled: i.role === UserTeamRole.ORG_ADMIN || i.id === currentUsersId,
    label: getRoleName(i.role),
    labelColor: getRoleColor(i.role),
    isCurrentUser: i.id === currentUsersId,
  }));

export const teamDropdownOptionsMapper = (teams: ITeam[], activeTeamId: number) =>
  teams.map(team => ({
    id: `${team.id}`,
    name: team.name,
    active: team.id === activeTeamId,
  }));

export const calcMaxTeamSize = (subscriptionQuantity?: number): number =>
  Math.max(MAX_TEAM_SIZE, subscriptionQuantity || 0);

/**
 * Maps an array of teammates to an array of autocomplete options.
 * Each option contains an id, name, and subText field.
 * The name field is set to the teammate's full name if available,
 * otherwise, it falls back to the teammate's email.
 * The subText field contains the teammate's email if the full name is available,
 * otherwise, it's an empty string.
 *
 * @param teammates - An array of teammate data objects as defined by the com_qordoba_user_dto_UserAndTeamsResponse schema.
 * @returns An array of AutocompleteOption objects suitable for use in an autocomplete component.
 */
export const mapTeammatesListToAutocompleteOptions = (
  teammates: components['schemas']['com_qordoba_user_dto_UserAndTeamsResponse'][],
): AutocompleteOption[] =>
  teammates.map(({ user }) => {
    const { name, subText } = mapUserNameOption(user.firstName, user.lastName, user.email);

    return {
      id: user.id,
      name,
      subText,
    };
  });

/**
 * Constructs an object containing a user's name and email, formatted for display.
 *
 * This function takes the user's first name, last name (which can be null or undefined),
 * and email. It concatenates the first and last name, falling back to the email if
 * the name is not provided. The resulting object contains two properties:
 * - name: the user's full name, or email if the name is not provided.
 * - subText: the user's email if the name is provided, otherwise an empty string.
 *
 * @param {string} firstName - The user's first name.
 * @param {string | null | undefined} lastName - The user's last name.
 * @param {string | null | undefined} email - The user's email address.
 * @returns {{ name: string; subText: string }} - An object containing the formatted name and email.
 */
export const mapUserNameOption = (
  firstName: string,
  lastName: string | null | undefined,
  email: string | null | undefined,
): Pick<AutocompleteOption | IAdminsOptionsList, 'name' | 'subText'> => {
  const userFullName = concatenateStrings(' ', firstName, lastName || '');
  const userEmail = email || '';
  const isFullNameEmpty = isEmpty(userFullName);

  return {
    name: isFullNameEmpty ? userEmail : userFullName,
    subText: !isFullNameEmpty ? userEmail : '',
  };
};

export const isTeamNameDuplicateError = (e: unknown): boolean => {
  if (!(e instanceof Error)) {
    return false;
  }

  return e.message.toUpperCase() === TEAM_NAME_DUPLICATE_ERROR_MESSAGE;
};
