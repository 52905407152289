import type { components } from '@writercolab/network';
import type { RangeStatic, Sources } from 'quill';
import Quill, { Module } from 'quill';
import Delta from 'quill-delta';
import type { Registry as RegistryType } from 'parchment';
import debounce from 'lodash/debounce';
import isString from 'lodash/isString';
import uniq from 'lodash/uniq';
import {
  hasInsertOrDelete,
  isJustOneInsert,
  normalizeAndCleanDelta,
  QL_SNIPPET_HIGHLIGHT_FORMAT_NAME,
  TRIGGER_SNIPPET_EVENT,
} from '@writercolab/quill-delta-utils';
import {
  QUILL_EDITOR_CHANGE_EVENT,
  QUILL_SELECTION_CHANGE_EVENT,
  QUILL_TEXT_CHANGE_EVENT,
} from '@writercolab/react-quill';
import { SnippetFormat } from '../formats/snippet';

interface SnippetMatches {
  startIndex: number;
  shortcut: string;
  matchedText: string;
}

interface ISnippetsDetectorOptions {
  onSearchSnippet: (
    shortcuts: string[],
  ) => Promise<components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'][]>;
}

export class SnippetsDetector extends Module<ISnippetsDetectorOptions> {
  // cache for snippets, possible values:
  // undefined - snippet wasn't requested from BE
  // null - snippet was requested but doesn't exist
  // TSnippet - snippet was requested and does exist
  private snippetsCache: Record<
    string,
    components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'] | null
  > = {};

  // snippet matches that pending request from BE
  private pendingRequest: SnippetMatches[] = [];

  private currentRange: RangeStatic = { index: 0, length: 0 };

  private registry: RegistryType;

  constructor(quill: Quill, options: ISnippetsDetectorOptions) {
    super(quill, options);

    if (!options.onSearchSnippet) {
      throw new Error('onSearchSnippet should be provided.');
    }

    const { Registry } = Quill.import('parchment');

    this.registry = Registry as unknown as RegistryType;

    // Handler that looks for Quill editor changes
    this.quill.on(QUILL_EDITOR_CHANGE_EVENT, (type: string, deltaOrRange: Delta | RangeStatic, _, source: Sources) => {
      if (type === QUILL_SELECTION_CHANGE_EVENT) {
        this.currentRange = deltaOrRange as RangeStatic;
      }

      if (type === QUILL_TEXT_CHANGE_EVENT) {
        const delta = deltaOrRange as Delta;

        if (!delta.ops) {
          return;
        }

        // regular user operations
        if (source === 'user') {
          this.checkIfSnippetWasEdited(delta);
          this.onTextChangeDebounced();

          if (isJustOneInsert(delta)) {
            const { insert } = delta.ops.find(op => op.insert)!;

            if (insert === ' ' || insert === '\n') {
              this.onTextChangeDebounced.cancel();
              this.onTextChange();
            }
          }
        }

        // 'silent' is used for initial text init and for copy paste
        if (source === 'silent') {
          let start = 0; // calculate the total

          for (let i = 0; i < delta.ops.length; i++) {
            const op = delta.ops[i];

            if (!op) {
              continue;
            }

            if (op.retain && typeof op.retain === 'number') {
              start += op.retain;
            }

            if (isString(op.insert)) {
              this.detectAndHighlightSnippets(op.insert, start);
              start += op.insert.length;
            }
          }
        }
      }
    });
  }

  getSnippetMeta = (
    element: HTMLElement,
  ):
    | {
        snippet: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'];
        length: number;
        from: number;
      }
    | undefined => {
    const blot = this.registry.find(element);

    if (blot) {
      const from = blot.offset(this.quill.scroll);
      const until = from + blot.length();
      const length = until - from;
      const { snippet } = blot as unknown as {
        snippet: components['schemas']['com_qordoba_terminology_model_SnippetV2_scala_Tuple2'];
      };

      return {
        snippet,
        from,
        length,
      };
    }

    return undefined;
  };

  insertSnippet = (element: HTMLElement) => {
    const snippetData = this.getSnippetMeta(element);

    if (snippetData) {
      const editor = this.quill;
      const { from, length, snippet } = snippetData;

      let applyDelta = new Delta().retain(from);

      // transform snippet html to delta
      const snippetInsertDelta = editor.clipboard.convert({ html: snippet.snippet });

      editor.formatText(from, length, QL_SNIPPET_HIGHLIGHT_FORMAT_NAME, false, 'api');

      // prepare full delta to insert
      applyDelta = normalizeAndCleanDelta(applyDelta.concat(snippetInsertDelta).delete(length));
      editor.updateContents(applyDelta, 'user');

      editor.setSelection(applyDelta.transformPosition(from), 0);
    }
  };

  private checkIfSnippetWasEdited(delta: Delta) {
    if (hasInsertOrDelete(delta)) {
      const editor = this.quill;
      const range = editor.getSelection();

      if (range) {
        const format = editor.getFormat(range);

        if (format[QL_SNIPPET_HIGHLIGHT_FORMAT_NAME]) {
          const [leaf] = editor.getLeaf(range.index);

          if (!leaf) {
            return;
          }

          const offset = leaf.offset(editor.scroll);

          if (leaf.domNode.textContent) {
            editor.formatText(offset, leaf.domNode.textContent.length, QL_SNIPPET_HIGHLIGHT_FORMAT_NAME, false, 'api');
          }
        }
      }
    }
  }

  private onTextChange() {
    const selection = this.quill.getSelection();

    if (!selection) {
      return;
    }

    const [line, offset] = this.quill.getLine(selection.index);
    const text = line?.domNode.textContent;
    const lineStart = selection.index - offset;

    if (text) {
      this.detectAndHighlightSnippets(text, lineStart);
    }
  }

  private onTextChangeDebounced = debounce(this.onTextChange, 400);

  private detectAndHighlightSnippets = async (text: string, lineStart: number) => {
    if (isString(text)) {
      const matches: SnippetMatches[] = [];
      const pattern = /w\.[\w-]+/g; // snippets pattern e.g. w.test

      if (text.match(pattern)) {
        let match: RegExpExecArray | null = null;

        while ((match = pattern.exec(text))) {
          const matchedText = match![0];
          const shortcut = matchedText.replace('w.', '');
          const startIndex = lineStart + match!.index;
          matches.push({
            startIndex,
            shortcut,
            matchedText,
          });
        }
      }

      if (!matches.length) {
        return;
      }

      const toBeRequested: SnippetMatches[] = matches.filter(
        ({ shortcut }) => typeof this.snippetsCache[shortcut] === 'undefined',
      );

      this.doHighlightByMatches(matches);

      if (toBeRequested.length) {
        this.addSnippetsToPendingRequest(toBeRequested);
        this.requestSnippets();
      }
    }
  };

  private addSnippetsToPendingRequest(snippetsMatch: SnippetMatches[]) {
    this.pendingRequest = [...this.pendingRequest, ...snippetsMatch];
  }

  private doHighlightByMatches(matches: SnippetMatches[]) {
    matches.forEach(match => {
      const { startIndex, matchedText, shortcut } = match;

      if (this.snippetsCache[shortcut]) {
        this.quill.formatText(
          startIndex,
          matchedText.length,
          QL_SNIPPET_HIGHLIGHT_FORMAT_NAME,
          this.snippetsCache[shortcut],
          'api',
        );

        if (startIndex + matchedText.length === this.currentRange.index) {
          const [leaf] = this.quill.getLeaf(startIndex + 1);

          if (!leaf) {
            return;
          }

          if (leaf.parent instanceof SnippetFormat) {
            this.quill.root.dispatchEvent(
              new CustomEvent(TRIGGER_SNIPPET_EVENT, { detail: { element: leaf.parent.domNode } }),
            );
          }
        }
      } else if (this.snippetsCache[shortcut] === null) {
        // do nothing, snippet was requested from BE but doesn't exist
      }
    });
  }

  private requestSnippets = async () => {
    if (!this.options.onSearchSnippet) {
      return;
    }

    const toBeRequested = [...this.pendingRequest];
    this.pendingRequest = [];
    const shortcuts = uniq(toBeRequested.map(({ shortcut }) => shortcut));

    if (!shortcuts.length) {
      return;
    }

    try {
      const snippets = await this.options.onSearchSnippet(shortcuts);
      // fill the cache
      snippets.forEach(snippet => {
        if (snippet.shortcut) {
          this.snippetsCache[snippet.shortcut] = snippet;
        }
      });

      // if after request we still don't have snippet in cache
      // mark this snippet as non-exist using null value
      shortcuts.forEach(shortcut => {
        if (!this.snippetsCache[shortcut]) {
          this.snippetsCache[shortcut] = null;
        }
      });

      this.doHighlightByMatches(toBeRequested);
    } catch {
      // if BE responds with error, put snippets back to pending array
      this.addSnippetsToPendingRequest(toBeRequested);
    }
  };
}
