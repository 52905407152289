import React, { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import type { TSnippet } from '@writercolab/common-utils';
import {
  LocalStorageKey,
  LocalStorageService,
  SortFieldTypes,
  SortOrders,
  TermsSortingTypes,
  convertToEllipsis,
  getDateTime,
} from '@writercolab/common-utils';
import { sanitize } from '@writercolab/text-utils';
import { SNIPPET_SHORTCUT_PREFIX } from '@writercolab/types';
import { DropdownPlacement, Icon, IconVariant, TagColor, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import type { ITableColumnItem, RenderAsTextProps } from '@writercolab/ui-molecules';
import {
  BaseTable,
  DropdownHeader,
  EducationalBanner,
  EducationalBannerLocation,
  FilterHeader,
  TextHeader,
  renderAsTag,
  renderAsText,
} from '@writercolab/ui-molecules';

import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';

import { SnippetsFilterKey, useSnippetsContext } from '../../../../context/snippetsContext';
import { TermsAccessEnum, TermsClipMode } from '../../../../context/termsContext';
import { useAppState } from '../../../../state';
import { TActionType } from '../../../../state/types';

import termsTableStyles from '../../termsAndSnippetsShared/TermsTable.module.css';
import snippetTableStyles from './styles.module.css';

const MAX_SNIPPETS_AMOUNT_FOR_EDUCATIONAL_MODULE = 20;

interface ISnippetsTable extends BaseComponentsProps {
  terms: TSnippet[];
}

const styles = { ...termsTableStyles, ...snippetTableStyles };

const generateSnippetColumnData = (id, snippet, description = '') => {
  const _strippedHtmlSnippet = snippet.replace(/<[^>]*>/gi, ' ').trim();
  const _markupSnippet = encodedHtml => ({
    __html: convertToEllipsis(sanitize(encodedHtml), 400) || '',
  });

  return (
    <div className={styles.snippetCellContainer} data-value={`${_strippedHtmlSnippet} ${description}`}>
      <Text variant={TextSize.XL} dangerouslySetInnerHTML={_markupSnippet(snippet)} />
      <Text variant={TextSize.L} color={TextColor.GREY2} className={styles.snippetCellDescription}>
        {description && convertToEllipsis(description, 75)}
      </Text>
    </div>
  );
};

const ModificationTimeHeader = ({ onPrimaryOptionClickAction, sortField, sortOrder }) => (
  <DropdownHeader
    triggerText={<TextHeader headerText="Added" />}
    options={[
      {
        name: 'Sort Newest > Oldest',
        id: TermsSortingTypes.ADDED_DATE_ASC,
        icon: <Icon name={IconVariant.SORT_ASC} />,
        active: sortField === SortFieldTypes.MODIFICATION_TIME && sortOrder === SortOrders.DESC,
      },
      {
        name: 'Sort Oldest > Newest',
        id: TermsSortingTypes.ADDED_DATE_DESC,
        icon: <Icon name={IconVariant.SORT_DESC} />,
        active: sortField === SortFieldTypes.MODIFICATION_TIME && sortOrder === SortOrders.ASC,
      },
    ]}
    onPrimaryOptionClickAction={onPrimaryOptionClickAction}
    isActive={sortField === SortFieldTypes.MODIFICATION_TIME}
    sortDirection={sortOrder}
    dropdownPlacement={DropdownPlacement.BOTTOM_RIGHT}
  />
);

export const SnippetsTable: React.FC<ISnippetsTable> = ({ terms }) => {
  const { appState, dispatchAppState, appModel } = useAppState();
  const [educationalBannerVisible, setEducationalBannerVisible] = useState(false);
  const {
    snippetsContext,
    handleSortingChange,
    handleOpenEditTerm,
    handleMultiSelect,
    handleOpenNewTermPopup,
    handleFilterChange,
  } = useSnippetsContext();
  const { isLoading, debouncedSearchTerm, sortField, sortOrder, isAddTermPopupOpen, filters } = snippetsContext;

  const access = {
    disableOperations: snippetsContext.termsAccess !== TermsAccessEnum.ADMIN,
  };

  const tagsFilters = filters[SnippetsFilterKey.TAGS];
  const columns: ITableColumnItem[] = useMemo(
    () => [
      {
        Header: <TextHeader headerText="Shortcut" />,
        className: cx(styles.shortcutHeader),
        accessor: 'shortcut',
        minWidth: 200,
        Cell: ({ cell: { value } }) =>
          value && (
            <span className={styles.shortcutContainer}>
              <Icon name={IconVariant.SNIPPETS_FILLED} className={styles.shortcutIcon} />
              <span className={styles.shortcut}>{`${SNIPPET_SHORTCUT_PREFIX}${value}` || ''}</span>
            </span>
          ),
      },
      {
        Header: <TextHeader headerText="Snippet" />,
        className: cx(styles.termHeaderNowrap),
        accessor: 'snippet',
        Cell: ({ cell: { value } }) => <div className={styles.snipperText}>{renderAsText(value || '')}</div>,
        colSpan: 5,
      },
      {
        Header: tagsFilters.length > 0 && (
          <FilterHeader
            header={
              <Text variant={TextSize.M} color={TextColor.GREY2}>
                Tags
              </Text>
            }
            title={
              <Text variant={TextSize.XXS} smallCaps>
                Filter by {SnippetsFilterKey.TAGS}
              </Text>
            }
            options={tagsFilters}
            onChange={updatedFilters => handleFilterChange(SnippetsFilterKey.TAGS, updatedFilters)}
          />
        ),
        accessor: 'tags',
        className: cx(styles.termHeaderTags),
        cellClassName: cx(styles.clipModeCell),
        Cell({ row, cell: { value } }) {
          return !isEmpty(value) ? (
            <div className={styles.tagsCellContainer}>
              {value.map(t => renderAsTag(t.tag, TagColor.LIGHT_BLUE, `${row.original.id}-${row.id}-tag-${t.id}`))}
            </div>
          ) : null;
        },
      },
      {
        Header: (
          <ModificationTimeHeader
            onPrimaryOptionClickAction={handleSortingChange}
            sortField={sortField}
            sortOrder={sortOrder}
          />
        ),
        colSpan: 2,
        accessor: 'modificationTime',
        className: cx(styles.snippetModificationHeader, styles.termHeaderNowrap),
        disableMatchFound: true,
        Cell(e) {
          const cellProps: RenderAsTextProps = {
            className: cx(styles.snippetModificationInfo),
            size: TextSize.S,
          };

          if (e.row?.original?.modifiedUser?.fullName) {
            cellProps.tooltipText = e.row.original.modifiedUser.fullName;
          }

          return renderAsText(e.value, cellProps);
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortField, sortOrder, handleFilterChange, filters],
  );

  const emptyTableContent = useMemo(
    () => (debouncedSearchTerm.length ? <span>No search results</span> : null),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm, access.disableOperations],
  );

  const tableData = React.useMemo(
    () =>
      terms.map(term => {
        const _updatedTimeStamp = getDateTime(term.creationTime, true);
        const _updatedSnippet = generateSnippetColumnData(term.id, term.snippet, term.description || '');

        return { ...term, modificationTime: _updatedTimeStamp, snippet: _updatedSnippet };
      }),
    [terms],
  );

  const onEducationalBannerClose = () => {
    const educationalSnippetsModuleVisible = LocalStorageService.getItem<string[] | null>(
      LocalStorageKey.educationalSnippetsModuleVisible,
      [],
    );

    if (!educationalSnippetsModuleVisible?.includes(`${appState.organizationId}${appState.userProfile?.id}`)) {
      educationalSnippetsModuleVisible?.push(`${appState.organizationId}${appState.userProfile?.id}`);
      LocalStorageService.setItem(LocalStorageKey.educationalSnippetsModuleVisible, educationalSnippetsModuleVisible);
    }

    setEducationalBannerVisible(false);
    dispatchAppState({ type: TActionType.SetEducationalSnippetsState, payload: false });
  };

  useEffect(() => {
    if (isLoading) {
      setEducationalBannerVisible(false);
    } else if (tableData.length >= 0) {
      if (tableData.length >= MAX_SNIPPETS_AMOUNT_FOR_EDUCATIONAL_MODULE) {
        setEducationalBannerVisible(false);
        dispatchAppState({ type: TActionType.SetEducationalSnippetsState, payload: false });
      } else if (isEmpty(tableData)) {
        setEducationalBannerVisible(true);
      } else {
        setEducationalBannerVisible(appState.isEducationalSnippetsWindowVisible);
      }
    }
  }, [tableData, appState.isEducationalSnippetsWindowVisible, isLoading, dispatchAppState]);

  const _handleOpenNewTermPopup = () => {
    appModel.analyticsService.track(AnalyticsActivity.educationalModulesSnippetsClickedAddTerm, {});
    handleOpenNewTermPopup();
  };

  const _handleOpenPopup = () =>
    appModel.analyticsService.track(AnalyticsActivity.educationalModulesSnippetsClickedQuickStartVideo, {});

  return (
    <div className={cx(styles.container)}>
      <BaseTable
        tableData={tableData}
        tableColumns={columns}
        className={cx(styles.termTable, {
          [styles.wrapTextEnabled]: snippetsContext.clipMode === TermsClipMode.WRAP_TEXT,
        })}
        containerClassName={styles.tableMainContainer}
        emptyTableContent={emptyTableContent}
        isLoading={isLoading}
        searchTerm={debouncedSearchTerm.replace(SNIPPET_SHORTCUT_PREFIX, '')}
        tableRowClassName={cx(styles.termTableRow, styles.snippet)}
        onRowClick={handleOpenEditTerm}
        onMultiSelect={handleMultiSelect}
        disableOperations={access.disableOperations}
        multiSelectAllMonitor={isAddTermPopupOpen}
      >
        {educationalBannerVisible && (
          <div className={styles.bannerContainer}>
            <EducationalBanner
              location={EducationalBannerLocation.SNIPPETS}
              onPrimaryActionClick={_handleOpenNewTermPopup}
              onSecondaryActionClick={_handleOpenPopup}
              onCloseClick={onEducationalBannerClose}
              closeButtonVisible={tableData.length > 0}
              bannerClassName={styles.educationalBanner}
            />
          </div>
        )}
      </BaseTable>
    </div>
  );
};
