import { type RequestServiceInitialize } from '@writercolab/network';

import { getLogger } from 'utils/logger';

const LOG = getLogger('media.api');

interface IMediaApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
}

export class MediaApiModel {
  constructor(private readonly opts: IMediaApiModelOptions) {
    this.opts = opts;
  }

  getByFileId = async (fileId: string) => {
    try {
      const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/file/{fileId}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            fileId,
          },
        },
      });

      return data;
    } catch (e) {
      LOG.error('Failed to get file details', {
        error: e,
        fileId,
      });

      throw e;
    }
  };

  dowloadByFileId = async (fileId: string) => {
    try {
      const { data } = await this.opts.request.get('/api/media/organization/{organizationId}/file/{fileId}/download', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            fileId,
          },
        },
        parseAs: 'blob',
      });

      return data;
    } catch (e) {
      LOG.error('Failed to download file', {
        error: e,
        fileId,
      });

      throw e;
    }
  };

  dowloadDiarizedByFileId = async (fileId: string) => {
    try {
      const { data } = await this.opts.request.get(
        '/api/media/organization/{organizationId}/file/{fileId}/download/diarized',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              fileId,
            },
          },
          parseAs: 'blob',
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to download diarized file', {
        error: e,
        fileId,
      });

      throw e;
    }
  };
}
