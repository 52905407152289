import type { UserTeamRole } from '@writercolab/common-utils';
import type { components } from '@writercolab/network';
import type { AutocompleteOption } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

export interface IAddTeammateForm {
  orgId?: number;
  teamId?: number;
  role: UserTeamRole;
  invitees: IAddPeopleOption[];
}

export interface IAddPeopleOption {
  name: string;
  subText: string;
  id: number;
}

export type TOrganizationPeoplePaginated =
  components['schemas']['model_PaginatedResult_com_qordoba_user_dto_UserAndTeamsResponse'];

export type TOrganizationPeoplePaginatedResponse = components['schemas']['com_qordoba_user_dto_UserAndTeamsResponse'];
export type TOrganizationPeoplePaginatedArgs = {
  offset?: number;
  limit?: number;
};

export const TOrganizationPeopleSortField = new Enum(
  'id',
  'name',
  'creationTime',
  'deleted',
  'modificationTime',
  'email',
  'lastSeen',
);

export const TOrganizationPeopleExportFileFormat = new Enum('csv', 'xlsx');
export const TOrganizationPeopleSortDirection = new Enum('asc', 'desc');
export const TOrganizationPersonActionId = new Enum(
  'makeAdmin',
  'makeMember',
  'remove',
  'delete',
  'setAccessType',
  'reSendInvitation',
);

export const TOrganizationPersonFilter = new Enum('consoleRoles', 'search');
export const TOrganizationRole = new Enum('admin', 'member', 'organization-admin');
export type TOrganizationRoleShorthand = Exclude<typeof TOrganizationRole.type, 'organization-admin'>;
export const TOrganizationPersonAccessType = new Enum('View', 'Draft', 'FullAccess');
export const TOrganizationPersonTypeArg = new Enum('individual', 'application');
export const TDeleteOrganizationUserDocumentsFlow = new Enum('delete', 'shared_documents', 'owned_documents');

export type TOrganizationPersonStatus = components['schemas']['user_model_UserStatus'];
export type TOrganizationAdmin = components['schemas']['com_qordoba_user_model_UserDetailsResponse'];

export type TOrganizationPeopleQuery = {
  offset?: number;
  limit?: number;
};

export type TOrganizationPeoplePaginatedArgsExtra = {
  search?: string;
  sortField?: typeof TOrganizationPeopleSortField.type;
  sortOrder?: typeof TOrganizationPeopleSortDirection.type;
  role?: typeof TOrganizationRole.type;
  userType?: typeof TOrganizationPersonTypeArg.type;
  userStatus?: TOrganizationPersonStatus[];
  excludeTeamId?: number;
  billingGroupIds?: number[];
  hasBillingGroup?: boolean;
  hasConsoleRole?: boolean;
  consoleRoles?: (typeof TOrganizationPersonAccessType.type)[];
};

export type TInviteOption = AutocompleteOption & {
  id: string;
};
export type TOrganizationUser = components['schemas']['com_qordoba_user_model_UserDetailsResponse'];
