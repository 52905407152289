import type React from 'react';
import { useMemo } from 'react';

import cx from 'classnames';

import {
  AvatarSize,
  Dropdown,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  ItemsTypes,
  Text,
  TextSize,
  Tooltip,
  UserAvatar,
} from '@writercolab/ui-atoms';
import { ListLimiter } from '@writercolab/ui-molecules';

import type { IMappedTeamDetails } from '@web/types';
import { Link } from 'react-router';

import { ROUTE } from '../../../services/config/routes';
import { teamAdminsMapper } from '../../../utils/teamUtils';
import type { IEditTeamEditForm } from '../../pages/TeamsPage/modals/EditTeamModal';
import TeamAvatar from '../TeamAvatar';
import TeamStats from '../TeamStats';

import styles from './styles.module.css';

interface ITeamStatsSectionProps extends BaseComponentsProps {
  team: IMappedTeamDetails;
  onChange?: (type, teamId: IMappedTeamDetails['id'], teamObj: IEditTeamEditForm) => void;
  adminTitle?: string;
  compact?: boolean;
  orgId?: string;
  disabled?: boolean;
  disableDeleteTeamOption?: boolean;
  currentUsersId?: number;
}

const DropdownTrigger = ({ className = '' }) => (
  <Icon className={className} name={IconVariant.ELLIPSES} style={{ marginLeft: '10px' }} />
);

export const TeamStatsSection: React.FC<ITeamStatsSectionProps> = ({
  team,
  onChange,
  adminTitle,
  compact,
  orgId,
  disabled,
  disableDeleteTeamOption,
  currentUsersId,
}) => {
  const teamActionOptions = [
    {
      id: 'editForm',
      name: 'Edit details',
    },
  ];

  if (!disableDeleteTeamOption) {
    teamActionOptions.push({
      id: 'deleteForm',
      name: 'Delete team',
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mappedAdmins = useMemo(() => teamAdminsMapper(team.admins, currentUsersId), [team]);

  const onEditTeamDetailsClick = type => {
    if (onChange)
      onChange(type, team.id, {
        name: team.name,
        admins: mappedAdmins,
        anotherTeamsSuggestions: team.suggestionsManagedBy,
        anotherTeamsTerms: team.termsManagedBy,
        anotherTeamsSnippets: team.snippetsManagedBy,
        avatar: <TeamAvatar team={team} />,
      });
  };

  return (
    <section
      className={cx(styles.teamSection, {
        [styles.teamSectionCompact]: compact,
      })}
    >
      {!compact && (
        <div className={styles.teamHeader}>
          <Heading variant={compact ? HeadingVariant.H2 : HeadingVariant.H3} bold>
            {disabled ? (
              team.name
            ) : (
              <Link className={styles.teamHeaderLink} to={ROUTE.toTeamUsers(orgId, team.id)}>
                {team.name}
              </Link>
            )}
          </Heading>
          {onChange &&
            (disabled ? (
              <Tooltip title="Only team or org admins can edit team details" tooltipWidth={150}>
                <span>
                  <DropdownTrigger className={styles.disabledDropdownTrigger} />
                </span>
              </Tooltip>
            ) : (
              <Dropdown
                trigger={<DropdownTrigger />}
                itemsType={ItemsTypes.ACTION}
                options={teamActionOptions}
                onPrimaryOptionClickAction={onEditTeamDetailsClick}
              />
            ))}
        </div>
      )}
      <div className={styles.teamInfoLine}>
        <div className={styles.adminsHolder}>
          <Text className={styles.adminTitle} smallCaps={compact} variant={TextSize.XXS}>
            {adminTitle || 'Admins'}
          </Text>
          <ListLimiter className={styles.adminsList} options={mappedAdmins} />
        </div>
        {team.suggestionsManagedBy && (
          <div className={styles.managedByHolder}>
            <Text className={styles.managedByTitle} variant={TextSize.XS}>
              Suggestions managed by
            </Text>
            <Tooltip title={team.suggestionsManagedBy.name}>
              <UserAvatar
                className={styles.managedByAvatar}
                abbreviation={team.suggestionsManagedBy.name}
                customColor={team.suggestionsManagedBy.color}
                size={AvatarSize.XS}
              />
            </Tooltip>
          </div>
        )}
        {team.termsManagedBy && (
          <div className={styles.managedByHolder}>
            <Text className={styles.managedByTitle} variant={TextSize.XS}>
              Terms managed by
            </Text>
            <Tooltip title={team.termsManagedBy.name}>
              <UserAvatar
                className={styles.managedByAvatar}
                abbreviation={team.termsManagedBy.name}
                customColor={team.termsManagedBy.color}
                size={AvatarSize.XS}
              />
            </Tooltip>
          </div>
        )}
      </div>
      <TeamStats
        className={styles.statsContainer}
        stats={{
          users: team.userCount,
          snippets: team.snippetCount,
          terms: team.termCount,
          activeUsers: team.activeUsersCount,
          suggestionsAccepted: team.acceptedSuggestionsCount,
          wordsChecked: team.wordsCheckedCount,
        }}
      />
    </section>
  );
};

export default TeamStatsSection;
