import React from 'react';

import { Icon, IconVariant } from '@writercolab/ui-atoms';
import { ListDropdown, ListDropdownOption } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

interface ISuggestionOptionSelect {
  index: number;
  options: ListDropdownOption[];
  onUpdateDropdownValue: (id: number, value: string) => void;
}

const SuggestionOptionSelect: React.FC<ISuggestionOptionSelect> = ({ options, index, onUpdateDropdownValue }) => {
  const onChangeValue = (value: string) => onUpdateDropdownValue(index, value);

  return (
    <ListDropdown
      className={styles.suggestionOptionSelect}
      dropDownContainerClassName={styles.suggestionOptionSelectContainer}
      placeholderBold
      onSelect={onChangeValue}
      options={options}
      truncated
      multiLineOptions
      shortenTriggerText={40}
      triggerIcon={<Icon name={IconVariant.DROP_DOWN_ARROW} />}
    />
  );
};

export default SuggestionOptionSelect;
