import React from 'react';

import { Heading, HeadingVariant } from '@writercolab/ui-atoms';
import { ContentPreviewCard } from '@writercolab/ui-molecules';

import { TLearningCenterCanduId, TLearningCenterVideoId, TVideoTag } from '@web/types';
import { ILearningCenterVideo } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';

import { NoSearchResultsBanner } from '../../generic/NoSearchResultsBanner';

import styles from './LearningCenterVideos.module.css';

interface ILearningCenterVideosProps {
  videos?: ILearningCenterVideo[];
  onVideoClick?: (id: number, route: typeof TLearningCenterVideoId.type) => void;
}

export const LearningCenterVideos: React.FC<ILearningCenterVideosProps> = ({ videos, onVideoClick }) => (
  <div className={styles.container}>
    {!videos || isEmpty(videos) ? (
      <div>
        <NoSearchResultsBanner className={styles.noResultsWrapper} />
      </div>
    ) : (
      <div className={styles.contentPreviewContainer} id={TLearningCenterCanduId.enum.CANDU_LEARNING_CENTER_VIDEOS}>
        <Heading variant={HeadingVariant.H3} className={styles.subtitle}>
          Learn about apps
        </Heading>
        <div className={styles.cardContainer}>
          {videos
            .filter(video => video.tags.includes(TVideoTag.enum.cowrite))
            .map(video => (
              <ContentPreviewCard
                key={video.id}
                title={video.title}
                description={video.description}
                duration={video.duration}
                cover={video.cover}
                articleTitle={video.articleTitle}
                articleLink={video.articleLink}
                onClick={() => onVideoClick?.(video.id, video.route)}
                isViewed={video.viewed}
              />
            ))}
        </div>
      </div>
    )}
  </div>
);
