import React from 'react';

import { HighlightColor } from '@writercolab/ui-atoms';

import ExampleBlockHeader from './generic/ExampleBlockHeader';
import ExampleBlockHighlight from './generic/ExampleBlockHighligh';

import commonStyles from '../styles.module.css';

interface PassiveVoiceExampleProps {}

export const PassiveVoiceExample: React.FC<PassiveVoiceExampleProps> = () => (
  <div className={commonStyles.tooltipContent}>
    <ExampleBlockHeader>Example</ExampleBlockHeader>
    <ExampleBlockHighlight highlightColor={HighlightColor.RED}>
      Instructions will be given to you by the team leader.
    </ExampleBlockHighlight>
    <ExampleBlockHighlight highlightColor={HighlightColor.GREEN}>
      The team leader will give you instructions.
    </ExampleBlockHighlight>
  </div>
);
export default PassiveVoiceExample;
