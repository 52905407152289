import React from 'react';

import cx from 'classnames';

import { STATIC_VOICE_OPTIONS } from '@writercolab/types';
import {
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  Logo,
  LogoVariant,
  Switcher,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  TooltipAlignment,
} from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { IHeadCellProps, IRowProps } from 'components/organisms/BaseTable/BaseTable';

import { TVoice, TVoiceType } from '@web/types';

import styles from './VoiceTable.module.css';

export const TVoiceTableActions = new Enum('view', 'editDetails', 'editCalibration', 'setAsDefault', 'delete');

export const VOICE_TABLE_DROPDOWN_OPTIONS = [
  {
    id: TVoiceTableActions.enum.view,
    name: 'View voice details',
    icon: <Icon name={IconVariant.EYE} width={16} height={16} />,
  },
  {
    id: TVoiceTableActions.enum.editDetails,
    name: 'Edit voice details',
    icon: <Icon name={IconVariant.EDITOR} width={16} height={16} />,
  },
  {
    id: TVoiceTableActions.enum.editCalibration,
    name: 'Edit voice calibration',
    icon: <Icon name={IconVariant.VOICE_SETUP} width={16} height={16} />,
  },
  {
    id: TVoiceTableActions.enum.setAsDefault,
    name: 'Make default voice',
    icon: <Icon name={IconVariant.VOICE_TUNE} width={16} height={16} />,
  },
  {
    id: TVoiceTableActions.enum.delete,
    className: styles.deleteOption,
    name: 'Delete voice',
    icon: <Icon name={IconVariant.TRASH} width={16} height={16} />,
  },
];

export const generateVoiceTableHeaders = (aiStudioMode: boolean): IHeadCellProps[] => {
  const headers: IHeadCellProps[] = [
    {
      component: (
        <Text color={TextColor.GREY2} variant={TextSize.L}>
          Name
        </Text>
      ),
    },
    {
      component: (
        <Text color={TextColor.GREY2} variant={TextSize.L}>
          Type
        </Text>
      ),
    },
    {
      component: (
        <Text color={TextColor.GREY2} variant={TextSize.L}>
          Added by
        </Text>
      ),
    },
    {
      component: (
        <Text color={TextColor.GREY2} variant={TextSize.L}>
          Date modified
        </Text>
      ),
    },
    ...(aiStudioMode
      ? []
      : [
          {
            component: (
              <Text color={TextColor.GREY2} variant={TextSize.L}>
                Show in rewrite
                <Tooltip
                  title="When enabled, your team will see this voice as an option under rewrite"
                  placement="bottom"
                  tooltipWidth={180}
                >
                  <span className={styles.iconInfo}>
                    <Icon name={IconVariant.INFO_OUTLINED} />
                  </span>
                </Tooltip>
              </Text>
            ),
          },
        ]),
    {},
  ];

  return headers;
};

interface IVoiceTableBodyOptions {
  voices: TVoice[];
  viewOnly: boolean;
  aiStudioMode: boolean;
  onClickShowInRewrite: (id: string, visible: boolean) => void;
  handleDropdownClick: (actionId: typeof TVoiceTableActions.type, id: string) => void;
  handleCellClick: (id: string) => void;
  checkIsVoiceEditable?: (id?: number) => boolean;
}

export const generateVoiceTableBody = ({
  voices,
  viewOnly,
  aiStudioMode,
  onClickShowInRewrite,
  handleDropdownClick,
  handleCellClick,
  checkIsVoiceEditable,
}: IVoiceTableBodyOptions): IRowProps[] => {
  const rows = voices.map(voice => {
    const isCellClickable = viewOnly
      ? voice.type !== TVoiceType.enum.Manual
      : checkIsVoiceEditable?.(voice.createdByUser?.id);

    const row: IRowProps = {
      id: `voice-row-${voice.id}`,
      data: voice,
      action: () => {
        isCellClickable && handleCellClick(voice.id);
      },
      cells: [
        {
          id: `name-${voice.id}`,
          component: (
            <Text variant={TextSize.XL} className={styles.nameContainer}>
              <Icon className={styles.iconVoice} name={IconVariant.VOICE_TUNE} width={18} height={18} />{' '}
              <span className={cx(styles.name, { [styles.nameShort]: voice.default })}>{voice.name}</span>
              {voice.default && <span className={styles.label}>Default</span>}
            </Text>
          ),
        },
        {
          id: `type-${voice.id}`,
          component: (
            <Tooltip
              title={
                voice.type === TVoiceType.enum.Manual
                  ? 'Calibrated by manual trait and perspective selection'
                  : 'Calibrated by analyzing example text'
              }
              placement="right"
              align={TooltipAlignment.LEFT}
              tooltipWidth={voice.type === TVoiceType.enum.Manual ? 160 : 145}
            >
              <span className={styles.iconType}>
                <Icon
                  name={voice.type === TVoiceType.enum.Manual ? IconVariant.VOICE_SETUP : IconVariant.VOICE_STAR}
                  width={18}
                  height={18}
                />
              </span>
            </Tooltip>
          ),
        },
        {
          id: `createdBy-${voice.id}`,
          component: (
            <Text variant={TextSize.M} color={TextColor.GREY2}>
              {voice.createdByUser?.firstName} {voice.createdByUser?.lastName}
            </Text>
          ),
        },
        {
          id: `dateModified-${voice.id}`,
          component: (
            <Text variant={TextSize.M} color={TextColor.GREY2}>
              {new Date(voice.modificationTime).toLocaleString([], {
                dateStyle: 'short',
                timeStyle: 'short',
                hour12: true,
              })}
            </Text>
          ),
        },
        ...(aiStudioMode
          ? []
          : [
              {
                id: `showInRewrite-${voice.id}`,
                component: (
                  <div onClick={e => e.stopPropagation()}>
                    <Switcher
                      id={`showInRewrite-${voice.id}`}
                      size={14}
                      className={styles.switcher}
                      disabled={viewOnly}
                      checked={voice.rewrite}
                      onChange={visible => onClickShowInRewrite(voice.id, visible)}
                    />
                    <Text variant={TextSize.M} color={TextColor.GREY2}></Text>
                  </div>
                ),
              },
            ]),
        {
          id: `dropdown-${voice.id}`,
          component: (
            <div onClick={e => e.stopPropagation()}>
              {isCellClickable && (
                <Dropdown
                  trigger={<Icon className={styles.iconEllipsis} name={IconVariant.ELLIPSES} width={18} height={18} />}
                  placement={DropdownPlacement.BOTTOM_RIGHT}
                  options={VOICE_TABLE_DROPDOWN_OPTIONS.filter(option => {
                    if (viewOnly && option.id !== TVoiceTableActions.enum.view) {
                      return false;
                    } else if (!viewOnly && option.id === TVoiceTableActions.enum.view) {
                      return false;
                    } else if (aiStudioMode && option.id === TVoiceTableActions.enum.setAsDefault) {
                      return false;
                    } else if (voice.default) {
                      return option.id !== TVoiceTableActions.enum.setAsDefault;
                    } else {
                      return true;
                    }
                  })}
                  onPrimaryOptionClickAction={actionId =>
                    handleDropdownClick(actionId as typeof TVoiceTableActions.type, voice.id)
                  }
                />
              )}
            </div>
          ),
        },
      ],
    };

    return row;
  });

  if (!aiStudioMode) {
    const isWriterVoiceDefault = !voices.some(voice => voice.default);
    rows.push({
      id: STATIC_VOICE_OPTIONS.DEFAULT,
      data: null,
      action: () => null,
      cells: [
        {
          id: 'writerVoiceName',
          component: (
            <Text variant={TextSize.XL} className={styles.nameContainer}>
              <Logo variant={LogoVariant.BLACK} className={styles.writerLogo} />{' '}
              <span className={styles.name}>Writer voice</span>
              {isWriterVoiceDefault && <span className={styles.label}>Default</span>}
            </Text>
          ),
        },
        {
          id: '-',
          component: null,
        },
        {
          id: '-',
          component: null,
        },
        {
          id: '-',
          component: null,
        },
        {
          id: '-',
          component: null,
        },
        {
          id: 'writerVoiceDropdown',
          component: (
            <div onClick={e => e.stopPropagation()}>
              {!viewOnly && (
                <Dropdown
                  trigger={<Icon className={styles.iconEllipsis} name={IconVariant.ELLIPSES} width={18} height={18} />}
                  placement={DropdownPlacement.BOTTOM_RIGHT}
                  options={VOICE_TABLE_DROPDOWN_OPTIONS.filter(
                    option => option.id === TVoiceTableActions.enum.setAsDefault,
                  )}
                  onPrimaryOptionClickAction={actionId =>
                    handleDropdownClick(actionId as typeof TVoiceTableActions.type, STATIC_VOICE_OPTIONS.DEFAULT)
                  }
                />
              )}
            </div>
          ),
        },
      ],
    });
  }

  return rows;
};
