import React from 'react';

import { Icon, IconVariant } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { TAppDecorationId } from '@web/types';
import isEmpty from 'lodash/isEmpty';

interface IApplicationLibraryIconProps {
  icon: string;
  variant?: typeof TApplicationLibraryIconVariant.type;
}

const appIconsListH = TAppDecorationId.partialHash({
  social: {
    id: TAppDecorationId.enum.social,
    icon: IconVariant.CONSOLE_APP_SOCIAL,
  },
  messaging: {
    id: TAppDecorationId.enum.messaging,
    icon: IconVariant.CONSOLE_APP_MESSAGING,
  },
  ads: {
    id: TAppDecorationId.enum.ads,
    icon: IconVariant.CONSOLE_APP_ADS,
  },
  blogs: {
    id: TAppDecorationId.enum.blogs,
    icon: IconVariant.CONSOLE_APP_BLOGS,
  },
  caseStudies: {
    id: TAppDecorationId.enum.caseStudies,
    icon: IconVariant.CONSOLE_APP_CASE_STUDIES,
  },
  lists: {
    id: TAppDecorationId.enum.lists,
    icon: IconVariant.CONSOLE_APP_LISTS,
  },
  errors: {
    id: TAppDecorationId.enum.errors,
    icon: IconVariant.CONSOLE_APP_ERRORS,
  },
  faqs: {
    id: TAppDecorationId.enum.faqs,
    icon: IconVariant.CONSOLE_APP_FAQ,
  },
  summaries: {
    id: TAppDecorationId.enum.summaries,
    icon: IconVariant.CONSOLE_APP_SUMMARIES,
  },
  supportArticles: {
    id: TAppDecorationId.enum.supportArticles,
    icon: IconVariant.CONSOLE_APP_SUPPORT_ARTICLES,
  },
  emails: {
    id: TAppDecorationId.enum.emails,
    icon: IconVariant.CONSOLE_APP_EMAILS,
  },
  shortform: {
    id: TAppDecorationId.enum.shortform,
    icon: IconVariant.CONSOLE_APP_SHORT_FORM,
  },
  longform: {
    id: TAppDecorationId.enum.longform,
    icon: IconVariant.CONSOLE_APP_LONG_FORM,
  },
});

export const TApplicationLibraryIconVariant = new Enum('small', 'medium');
const SMALL_ICON_SIZE = 24;
const MEDIUM_ICON_SIZE = 40;

export const ApplicationLibraryIcon: React.FC<IApplicationLibraryIconProps> = ({
  icon,
  variant = TApplicationLibraryIconVariant.enum.small,
}) => {
  if (!TAppDecorationId.is(icon) || isEmpty(icon)) {
    return null;
  }

  const appIconSize = TApplicationLibraryIconVariant.match(
    variant,
    {
      [TApplicationLibraryIconVariant.enum.small]: () => SMALL_ICON_SIZE,
      [TApplicationLibraryIconVariant.enum.medium]: () => MEDIUM_ICON_SIZE,
    },
    TApplicationLibraryIconVariant.enum.small,
  );
  const appIcon = appIconsListH[icon];

  return appIcon ? <Icon name={appIcon.icon} height={appIconSize} width={appIconSize} /> : null;
};
