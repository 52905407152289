import { useState } from 'react';

import { Heading, HeadingVariant, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';
import { UploadZone } from '@writercolab/ui-molecules';

import first from 'lodash/first';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import Papa from 'papaparse';

import { AcceptableColumnType, ImportItemType } from '../../types';
import { getFormattedHeaderCells } from '../../utils';
import { ACCEPTABLE_COLUMNS } from '../PreviewImportedDataStep/consts';

import styles from './styles.module.css';

const SUPPORTED_FILE_EXTENSIONS = ['.csv', '.xlsx'];
const FILE_SIZE_LIMIT = 15 * 1024 * 1024;

const errorMessages = {
  size: 'Your file is too big to upload. Max file size is 15MB.',
  extension: 'Your file couldn’t be parsed. Doublecheck your file and make sure it’s saved as a csv or xlsx. ',
  empty: 'Your file is empty. Doublecheck it and try again...',
};

type TPapaParseResult = string[][];

interface IUploadFileStepProps {
  onReadComplete: (data: TPapaParseResult) => void;
  type: ImportItemType;
}

export const UploadFileStep = ({ onReadComplete, type }: IUploadFileStepProps) => {
  const [fileName, setFileName] = useState('');
  const [fileImportError, setFileImportError] = useState('');
  const [importing, setImporting] = useState(false);

  const onFileSelected = (files?: File[]) => {
    const file = first(files);

    if (file) {
      setFileName(file.name);
      const fileExtension = file.name.split('.').pop();
      const isSupportedExtension = fileExtension && SUPPORTED_FILE_EXTENSIONS.includes(`.${fileExtension}`);
      const sizeExceedLimit = file.size > FILE_SIZE_LIMIT;

      if (sizeExceedLimit) {
        setFileImportError(errorMessages.size);
      } else if (!isSupportedExtension) {
        setFileImportError(errorMessages.extension);
      } else {
        setImporting(true);

        if (fileExtension === 'xlsx') {
          const reader = new FileReader();
          reader.onload = async e => {
            const { read, utils } = await import('xlsx');

            const data = e.target?.result;
            const workbook = read(data, {
              type: 'binary',
            });
            const firstSheetKey = Object.keys(workbook.Sheets)[0];
            const firstSheet = workbook.Sheets[firstSheetKey];
            const results = utils.sheet_to_json<string[]>(firstSheet, {
              header: 1,
            });

            if (isEmpty(results)) {
              setImporting(false);
              setFileImportError(errorMessages.empty);

              return;
            }

            const headerRow = head(results);
            const headerRowFormatted = getFormattedHeaderCells(headerRow || []);
            const matchedColumns = headerRowFormatted.map(headCell =>
              ACCEPTABLE_COLUMNS[type].find((acceptableColumn: AcceptableColumnType) =>
                acceptableColumn.names.includes(headCell),
              ),
            );
            const columnsWithHtml = matchedColumns.map(headCell => headCell?.htmlContentSupport);

            if (columnsWithHtml.filter(columnWithHtml => columnWithHtml).length) {
              const richDataCells = Object.entries(firstSheet).map(([cellKey, cellData]) => ({
                decodedCellKey: utils.decode_cell(cellKey),
                cellData,
              }));

              columnsWithHtml.forEach((columnWithHtml, columnIndex) => {
                if (columnWithHtml) {
                  results.forEach((row, rowIndex) => {
                    if (rowIndex > 0) {
                      const matchedRichDataCell = richDataCells.find(decodedCell => {
                        const { c, r } = decodedCell.decodedCellKey;

                        return c === columnIndex && r === rowIndex;
                      });

                      if (matchedRichDataCell) {
                        results[rowIndex][columnIndex] = matchedRichDataCell.cellData.h;
                      }
                    }
                  });
                }
              });
            }

            setImporting(false);
            onReadComplete(results);
          };
          reader.readAsBinaryString(file);
        } else {
          Papa.parse(file, {
            skipEmptyLines: true,
            complete(results: { data: TPapaParseResult }) {
              if (results.data.length && results.data[0].length) {
                onReadComplete(results.data);
              } else {
                setFileImportError(errorMessages.empty);
              }

              setImporting(false);
            },
          });
        }
      }
    }
  };

  return (
    <div className={styles.uploadStep}>
      {fileImportError && (
        <div className={styles.error}>
          <Heading variant={HeadingVariant.H2} bold className={styles.headerTitle}>
            Uh oh...
          </Heading>
          <div className={styles.errorWrapper}>
            <Icon name={IconVariant.ALERT} />
            <div className={styles.errorInfo}>
              <Heading variant={HeadingVariant.H5}>{fileName}</Heading>
              <Text variant={TextSize.M} bold className={styles.errorDetails}>
                {fileImportError}
              </Text>
            </div>
          </div>
        </div>
      )}
      <UploadZone
        headingVariant={HeadingVariant.H2}
        subtitleSize={TextSize.XXL}
        isUploading={importing}
        className={styles.uploadWrapper}
        extensions={SUPPORTED_FILE_EXTENSIONS}
        onFileSelected={onFileSelected}
      />
    </div>
  );
};
