import type React from 'react';

import cx from 'classnames';

import { IconVariant, Tag, TagColor, Text, TextSize } from '@writercolab/ui-atoms';
import { TemplateGuide, UtilIcon } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

interface ICoWriteHeader {
  onCloseClick?: () => void;
  onAllTemplatesClick?: () => void;
  onSupportClick?: () => void;
  onPromptLibraryClick?: () => void;
  title: string;
  beta?: boolean;
  isSeoBlog?: boolean;
  guideUrl?: string;
  description?: string;
  category?: any | null;
}

export const CoWriteHeader: React.FC<ICoWriteHeader> = ({
  onCloseClick,
  onAllTemplatesClick,
  onSupportClick,
  onPromptLibraryClick,
  title,
  beta,
  guideUrl = '',
  description = '',
  category,
  isSeoBlog = false,
}) => (
  <>
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <div className={cx(styles.headerContainerTitle, { [styles.seoBlog]: isSeoBlog })}>
          {category && (
            <Text
              color={category.tagTextColor}
              variant={TextSize.S}
              style={{ backgroundColor: category.tagColor }}
              className={styles.cardTag}
            >
              {category.name}
            </Text>
          )}

          <div className={styles.headerContainerUtils}>
            {onPromptLibraryClick && (
              <div className={cx(styles.headerContainerUtil, styles.clickable)} onClick={onPromptLibraryClick}>
                <div className={styles.headerContainerUtilTitle}>
                  <Text caps className={styles.headerContainerUtilTitleSmall}>
                    Writer prompt library
                  </Text>
                </div>

                <div className={styles.headerSupportIcon}>
                  <UtilIcon variant={IconVariant.LIGHT_BULB} circle tooltipContent="Prompt library" />
                </div>
              </div>
            )}
            {onSupportClick && !onPromptLibraryClick && (
              <div className={cx(styles.headerContainerUtil, styles.clickable)} onClick={onSupportClick}>
                <div className={styles.headerContainerUtilTitle}>
                  <Text caps className={styles.headerContainerUtilTitleSmall}>
                    Help
                  </Text>
                </div>

                <div className={styles.headerSupportIcon}>
                  <UtilIcon variant={IconVariant.LIFEBUOY} circle tooltipContent="Go to Learning Center" />
                </div>
              </div>
            )}
            {onAllTemplatesClick && (
              <div className={cx(styles.headerContainerUtil, styles.clickable)} onClick={onAllTemplatesClick}>
                {!onSupportClick && (
                  <div className={styles.headerContainerUtilTitle}>
                    <Text caps className={styles.headerContainerUtilTitleSmall}>
                      All apps
                    </Text>
                  </div>
                )}

                <div className={styles.headerContainerUtilIcon}>
                  <UtilIcon variant={IconVariant.ALL_SETTINGS} circle tooltipContent="App library" />
                </div>
              </div>
            )}

            {onCloseClick && (
              <div className={cx(styles.headerContainerUtil, styles.clickable)} onClick={onCloseClick}>
                <UtilIcon variant={IconVariant.CLOSE} circle tooltipContent="Close" />
              </div>
            )}
          </div>
        </div>

        <div className={cx(styles.headerTitle, { [styles.seoBlog]: isSeoBlog })}>
          <Text variant={TextSize.XXXXL} className={styles.title}>
            {title}
          </Text>
          {beta && (
            <Tag color={TagColor.LIGHT_GREEN} className={styles.betaTag}>
              <Text caps>Beta</Text>
            </Tag>
          )}
        </div>
      </div>
    </div>
    <TemplateGuide className={styles.guide} url={guideUrl} description={description} />
  </>
);
