import type React from 'react';

import cx from 'classnames';

import { Button, Heading } from '@writercolab/fe.wds';

import { THomeWidgetsState, TWidgetEditAppearance } from '@web/types';
import { observer } from 'mobx-react-lite';
import type { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';

import { Card } from '../../generic/Card';

import styles from './WidgetCard.module.css';

interface IWidgetCardProps {
  className?: string;
  children?: React.ReactNode;
  mode?: typeof THomeWidgetsState.type;
  model?: AbstractHomeWidgetModel;
  title?: string;
  withBorder?: boolean;
  withShadow?: boolean;
  passThrough?: boolean;
}

export const WidgetCard: React.FC<IWidgetCardProps> = observer(
  ({
    className,
    mode = THomeWidgetsState.enum.view,
    model,
    passThrough = false,
    withBorder = true,
    withShadow = true,
    children,
    title,
  }) => {
    const isInEditMode = mode === THomeWidgetsState.enum.adminEdit;
    const isReadOnlyAppearance =
      isInEditMode && (model?.editAppearance === TWidgetEditAppearance.enum.readonly || !model?.isVisible);
    const isEditAppearance = isInEditMode && model?.editAppearance === TWidgetEditAppearance.enum.edit;

    if (!model?.isVisible && !isInEditMode) {
      return null;
    }

    return (
      <Card
        highlight={isEditAppearance}
        withBorder={withBorder}
        withShadow={withShadow}
        className={cx(styles.container, className, {
          [styles.passThrough]: passThrough,
          [styles.editing]: isEditAppearance,
        })}
      >
        {(title || isEditAppearance) && (
          <div className={styles.heading}>
            <Heading size="h3" className={cx(styles.headingTitle, { [styles.readonly]: isReadOnlyAppearance })}>
              {title}
            </Heading>
            {isEditAppearance && (
              <Button
                className={styles.editButton}
                variant="tertiary"
                size="sm"
                onClick={() => model.editVisibility(!model.isVisible)}
              >
                {model.isVisible ? 'Hide widget' : 'Show widget'}
              </Button>
            )}
          </div>
        )}

        <div
          className={cx(styles.childrenContainer, {
            [styles.readonly]: isReadOnlyAppearance,
          })}
        >
          {children}
        </div>
      </Card>
    );
  },
);

WidgetCard.displayName = 'WidgetCard';
