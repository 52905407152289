import React from 'react';

import cx from 'classnames';

import { BillingProduct } from '@writercolab/common-utils';
import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import teamPlan from '../../../../assets/backgrounds/teamPlan.png';

import styles from './styles.module.css';

interface ITrialPopup {
  open: boolean;
  isSubmitting: boolean;
  type: BillingProduct;
  onStartTrial?: (type: BillingProduct) => void;
  onCancel?: () => void;
}

export const TrialPopup: React.FC<ITrialPopup> = ({ open, type, onStartTrial, onCancel, isSubmitting }) => {
  const onStartMyTrialClick = () => {
    onStartTrial && onStartTrial(type);
  };

  return (
    <Modal open={open} title="Start a 14-day trial" className={styles.modalContainer} handleClose={onCancel}>
      <div>
        <div>
          <div className={styles.logo}>
            {type === BillingProduct.TEAM && <img src={teamPlan} alt="team plan logo" width={356} />}
            {type === BillingProduct.PRO && (
              <div className={styles.teamPlanLogo}>
                <Text variant={TextSize.XXXXL} caps>
                  PRO PLAN
                </Text>
              </div>
            )}
          </div>
          <Text variant={TextSize.L} bold>
            {type === BillingProduct.TEAM
              ? 'Use the Team trial to explore features like:'
              : 'Use the Pro trial to explore features like:'}
          </Text>
          {type === BillingProduct.TEAM && (
            <ul className={styles.featuresList}>
              {[
                'Styleguides',
                'Team snippets',
                'Reporting',
                'Roles and permissions',
                'And of course, using Writer with your team',
              ].map(feature => (
                <li>
                  <Text variant={TextSize.L}>{feature}</Text>
                </li>
              ))}
            </ul>
          )}
          {type === BillingProduct.PRO && (
            <ul className={styles.featuresList}>
              {['Clarity', 'Delivery', 'Inclusivity', 'Writing style configuration', 'Snippets', 'Plagiarism'].map(
                feature => (
                  <li>
                    <Text variant={TextSize.L}>{feature}</Text>
                  </li>
                ),
              )}
            </ul>
          )}
        </div>
        <div className={cx(styles.actionButtonsHolder)}>
          <Button className={styles.action} type={ButtonTypes.BORDERED} onClick={onCancel}>
            Cancel
          </Button>
          <Button
            className={styles.action}
            type={ButtonTypes.PRIMARY}
            onClick={onStartMyTrialClick}
            isLoading={isSubmitting}
            content="Start my trial"
          />
        </div>
      </div>
    </Modal>
  );
};

export default TrialPopup;
