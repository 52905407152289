import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useReducer } from 'react';

import type { IGetSuggestionsRequestParams, IGetSuggestionsSearchResponse } from '@writercolab/common-utils';
import { TIME_TO_GET_DOMAINS, getMagicLinksSuggestions, getMagicLinksWebsites } from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import type { ActionMap } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { useAppState } from '../state';
import type { TAppState } from '../state/types';
import { getLogger } from '../utils/logger';

const LOG = getLogger('magicLinksContext');

interface IMagicLinksContext {
  state: IMagicLinksState;
  featureEnabled: boolean;
  isTeamAdmin: boolean;
  subscriptionisLoaded: boolean;
  updateParams: (params: IGetSuggestionsRequestParams) => void;
  clearLinks: () => void;
  onFetchSuggestions: (isMore?: boolean) => Promise<void>;
  onCloseModal: () => void;
  updateCustomUrl: (customUrl: string) => void;
  toggleLoading: (isLoading: boolean) => void;
  onFetchMagicLinksDomains: () => void;
  onLinkSelected: (url: string) => void;
}

const MagicLinksContext = createContext<IMagicLinksContext>({} as IMagicLinksContext);

interface IMagicLinksContextProvider {
  children?: ReactNode;
  organizationId: number | undefined;
  teamId: TAppState['teamId'];
  onLinkSelected: (link: string) => void;
  onClose: () => void;
}

interface IMagicLinksState {
  isLoading: boolean;
  isDomainsLoading: boolean;
  customUrl: string;
  amountOfMagicDomains: number;
  params: IGetSuggestionsRequestParams;
  magicLinksList: IGetSuggestionsSearchResponse;
}

enum TMagicLinksActionType {
  SetIsLoading = 'setIsLoading',
  SetIsLoadingDomains = 'setIsLoadingDomains',
  SetMagicLinksList = 'setMagicLinksList',
  SetParams = 'setParams',
  SetCustomUrl = 'setCustomUrl',
  SetMagicDomains = 'setMagicDomains',
  Reset = 'reset',
  SetClearLinks = 'setClearLinks',
}

type TCoWritePayload = {
  [TMagicLinksActionType.SetIsLoading]: boolean;
  [TMagicLinksActionType.SetIsLoadingDomains]: boolean;
  [TMagicLinksActionType.SetCustomUrl]: string;
  [TMagicLinksActionType.SetMagicDomains]: number;
  [TMagicLinksActionType.SetMagicLinksList]: IGetSuggestionsSearchResponse;
  [TMagicLinksActionType.SetParams]: IGetSuggestionsRequestParams;
  [TMagicLinksActionType.Reset]: IMagicLinksState;
  [TMagicLinksActionType.SetClearLinks]: boolean;
};

type TMagicLinksActions = ActionMap<TCoWritePayload>[keyof ActionMap<TCoWritePayload>];

const magicLinksReducer = (state: IMagicLinksState, action: TMagicLinksActions) => {
  let newState: IMagicLinksState;

  switch (action.type) {
    case TMagicLinksActionType.SetIsLoading:
      newState = { ...state, isLoading: action.payload };
      break;
    case TMagicLinksActionType.SetMagicLinksList:
      newState = { ...state, magicLinksList: action.payload };
      break;
    case TMagicLinksActionType.SetParams:
      newState = { ...state, params: action.payload };
      break;
    case TMagicLinksActionType.SetCustomUrl:
      newState = { ...state, customUrl: action.payload };
      break;
    case TMagicLinksActionType.SetMagicDomains:
      newState = { ...state, amountOfMagicDomains: action.payload };
      break;
    case TMagicLinksActionType.Reset:
      newState = { ...action.payload };
      break;
    case TMagicLinksActionType.SetIsLoadingDomains:
      newState = { ...state, isDomainsLoading: action.payload };
      break;
    case TMagicLinksActionType.SetClearLinks:
      newState = {
        ...state,
        params: initialMagicLinksState.params,
        magicLinksList: initialMagicLinksState.magicLinksList,
      };
      break;
    default:
      newState = { ...state };
  }

  return newState;
};

const initialMagicLinksState: IMagicLinksState = {
  isLoading: false,
  isDomainsLoading: false,
  customUrl: '',
  params: {
    limit: 10,
    offset: 1,
    text: '',
  },
  magicLinksList: {
    result: [],
    pagination: {
      offset: 1,
      limit: 10,
    },
    totalCount: 0,
  } as IGetSuggestionsSearchResponse,
  amountOfMagicDomains: 0,
};

const MagicLinksContextProvider: React.FC<IMagicLinksContextProvider> = observer(
  ({ organizationId, teamId, onClose, onLinkSelected, children }) => {
    const {
      appModel: {
        permissionsModel,
        assistantSubscription: { access, isModelReady },
      },
    } = useAppState();
    const { enqueueErrorSnackbar } = useCustomSnackbar();
    const [state, dispatchMagicLinksState] = useReducer(magicLinksReducer, initialMagicLinksState);
    const { params } = state;
    const featureEnabled = !!access?.magicLinks;
    const subscriptionisLoaded = isModelReady;

    const onCloseModal = () => {
      onClose();
      clearLinks();
    };

    const toggleLoading = useCallback((state: boolean) => {
      dispatchMagicLinksState({
        type: TMagicLinksActionType.SetIsLoading,
        payload: state,
      });
    }, []);

    const updateParams = (params: IGetSuggestionsRequestParams) => {
      dispatchMagicLinksState({
        type: TMagicLinksActionType.SetParams,
        payload: params,
      });
    };

    const clearLinks = () => {
      dispatchMagicLinksState({
        type: TMagicLinksActionType.SetClearLinks,
        payload: true,
      });
    };

    const updateCustomUrl = (customUrl: string) => {
      dispatchMagicLinksState({
        type: TMagicLinksActionType.SetCustomUrl,
        payload: customUrl,
      });
    };

    const onFetchSuggestions = async (isMore?: boolean): Promise<void> => {
      toggleLoading(true);

      try {
        const { result, pagination, totalCount } = await getMagicLinksSuggestions({
          orgId: `${organizationId}`,
          teamId: `${teamId}`,
          params,
        });

        if (result.length === 0) {
          return;
        }

        const resultWithMore = isMore ? [...state.magicLinksList.result, ...result] : result;

        const magicLinksList = {
          result: resultWithMore,
          pagination,
          totalCount,
        };

        dispatchMagicLinksState({
          type: TMagicLinksActionType.SetMagicLinksList,
          payload: magicLinksList,
        });
      } catch (error: any) {
        LOG.error(error);
        enqueueErrorSnackbar(error?.response?.data?.errors?.[0]?.description || 'Something went wrong.');
      } finally {
        toggleLoading(false);
      }
    };

    const refetchDomains = async () => {
      setTimeout(async () => {
        const domainsInterval = await getMagicLinksWebsites({ orgId: `${organizationId}`, teamId: `${teamId}` });

        if (isEmpty(domainsInterval.websites)) {
          refetchDomains();
        } else {
          dispatchMagicLinksState({
            type: TMagicLinksActionType.SetMagicDomains,
            payload: domainsInterval.websites.length,
          });
        }
      }, TIME_TO_GET_DOMAINS);
    };

    const onFetchMagicLinksDomains = async () => {
      dispatchMagicLinksState({
        type: TMagicLinksActionType.SetIsLoadingDomains,
        payload: true,
      });

      try {
        const magicDomains = featureEnabled
          ? await getMagicLinksWebsites({ orgId: `${organizationId}`, teamId: `${teamId}` })
          : { websites: [] };

        if (magicDomains.websites.length < 1 && featureEnabled) {
          refetchDomains();
        } else {
          dispatchMagicLinksState({
            type: TMagicLinksActionType.SetMagicDomains,
            payload: magicDomains.websites.length,
          });
        }
      } catch {
        enqueueErrorSnackbar('Error loading domains');

        dispatchMagicLinksState({
          type: TMagicLinksActionType.SetMagicDomains,
          payload: 0,
        });
      } finally {
        dispatchMagicLinksState({
          type: TMagicLinksActionType.SetIsLoadingDomains,
          payload: false,
        });
      }
    };

    const contextValue = {
      state,
      isTeamAdmin: !!permissionsModel?.isTeamAdmin,
      subscriptionisLoaded,
      onFetchSuggestions,
      onFetchMagicLinksDomains,
      onCloseModal,
      updateParams,
      clearLinks,
      updateCustomUrl,
      toggleLoading,
      onLinkSelected,
      featureEnabled,
    };

    return <MagicLinksContext.Provider value={contextValue}>{children}</MagicLinksContext.Provider>;
  },
);

export function useMagicLinksContext() {
  const context = useContext(MagicLinksContext);

  if (!context) {
    throw new Error('useMagicLinksContext must be used within the MagicLinksContextProvider');
  }

  return context;
}

export default MagicLinksContextProvider;
