import React, { useCallback, useEffect } from 'react';

import cx from 'classnames';

import { openRequestForm } from '@writercolab/common-utils';
import { Button, ButtonTypes, Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { useAppState } from 'state';

import customRulesImg from '../../../../assets/backgrounds/customRules.png';
import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { openContactSalesPage } from '../../../../utils/navigationUtils';
import SuggestionPageHeader from '../SuggestionPageHeader';

import styles from '../styles.module.css';

export const CustomRulesPage = observer(() => {
  usePageTitle('Custom rules');
  const { isSaving, isReadOnly, isTeamNameShown, lastUpdated, lastUpdatedBy, teamName } = useSuggestionsContext();

  const {
    appModel: { assistantSubscription, analyticsService },
  } = useAppState();

  useEffect(() => {
    analyticsService.track(AnalyticsActivity.suggestionsCustomRulesViewed, {});
  }, [analyticsService]);

  const _handleOpenRequestForm = useCallback(() => {
    analyticsService.track(AnalyticsActivity.clickedContactSales, {
      clicked_from: 'custom_rules',
    });

    openRequestForm();
  }, [analyticsService]);

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Custom rules"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      {!assistantSubscription.isEnterprise && (
        <div className={cx(styles.description, styles.customRulesDescription)}>
          <Icon name={IconVariant.LOCK_LINE} className={styles.customRulesDescriptionIcon} />
          <Text variant={TextSize.XXXL} upperCase>
            Enterprise plan only
          </Text>
          <Button
            type={ButtonTypes.PRIMARY}
            content={
              <Text variant={TextSize.XL} color={TextColor.WHITE} className={styles.customRulesDescriptionBtnText}>
                Contact sales
              </Text>
            }
            className={styles.customRulesDescriptionBtn}
            onClick={openContactSalesPage}
          />
        </div>
      )}
      <div className={styles.customRulesTipContainer}>
        <Text variant={TextSize.XL} className={styles.customRulesTip}>
          <div>Need a custom rule?</div>
          <div>You can request a rule and the Writer team will create it for you!</div>
        </Text>
        {assistantSubscription.isEnterprise && (
          <Button
            type={ButtonTypes.PRIMARY}
            content="Contact support"
            className={styles.customRulesDescriptionBtn}
            onClick={_handleOpenRequestForm}
          />
        )}
      </div>
      <img src={customRulesImg} alt="tags cloud" width={798} className={styles.customRulesImg} />
    </div>
  );
});
