import {
  DocumentHighlightEntry,
  DocumentHighlightType,
  DocumentHighlights,
  DocumentHighlightsExtended,
  HIGHLIGHTS_SORTING_ORDER,
  hashCode,
} from '@writercolab/common-utils';

import last from 'lodash/last';

const sortDocumentHighlights = (highlights: DocumentHighlights[]): DocumentHighlights[] => {
  const _highlights: DocumentHighlights[] = [];

  HIGHLIGHTS_SORTING_ORDER.forEach((type: DocumentHighlightType) => {
    const highlight = highlights.find((highlight: DocumentHighlights) => highlight.type === type);

    if (highlight) {
      _highlights.push(highlight);
    }
  });

  return _highlights;
};

export const extendDocumentHighlights = (
  highlights: DocumentHighlights[],
  documentHash: string,
): DocumentHighlightsExtended[] =>
  sortDocumentHighlights(highlights).map((highlight: DocumentHighlights) =>
    extendDocumentHighlight(highlight, documentHash),
  );

const extendDocumentHighlight = (highlight: DocumentHighlights, documentHash: string): DocumentHighlightsExtended => ({
  ...highlight,
  id: hashCode(JSON.stringify(highlight)).toString(),
  loading: false,
  error: false,
  upToDate: last<DocumentHighlightEntry>(highlight.entry)?.hash === documentHash,
  entry: highlight.entry.map((entry: DocumentHighlightEntry) => ({
    ...entry,
    id: hashCode(`${entry.text}${entry.userId}${entry.hash}`).toString(),
    type: highlight.type,
    loading: false,
  })),
});
