import { action, computed, makeObservable, observable } from 'mobx';

import { FieldModel } from '@writercolab/mobx';
import type { components } from '@writercolab/network';
import type { TOrgTeamUserActivityParams } from '@writercolab/types';
import { Icon, IconVariant } from '@writercolab/ui-atoms';

import type {  NormalizedState, TPrompt, TPromptTag } from '@web/types';
import { ContentType, PromptCategories, TSyntheticPromptLibraryTags } from '@web/types';
import { DEFAULT_MENU_ITEM_COLOR, categoryOrder, contentTypeTagMap, contentTypeTags } from 'constants/PromptLibrary';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import type { PromptLibraryApiModel } from 'models/promptLibrary.api';

import { DEFAULT_SEARCH_DEBOUNCE_DELAY_MS } from '../../../services/config/constants';
import { normalizeByProperty } from '../../../utils/stateUtils';

import styles from './PromptLibraryUser.module.css';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

const defaultNormalizedState = {
  byId: {},
  allIds: [],
};

interface IPromptLibraryModelOptions {
  api: PromptLibraryApiModel;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
}

export class PromptLibraryUserUIModel {
  activeCategory = '';
  activeContentTypeCategory = '';
  searchQuery = '';

  searchInputField: FieldModel<string>;

  constructor(private opts: IPromptLibraryModelOptions) {
    this.searchInputField = FieldModel.build({
      init: () => '',
    });

    makeObservable(this, {
      activeCategory: observable,
      activeContentTypeCategory: observable,
      searchQuery: observable,

      setSearchQuery: action.bound,
      setActiveCategory: action.bound,
      setActiveContentTypeCategory: action.bound,

      prompts: computed,
      promptTags: computed,
      normalizedTagMap: computed,
      tagHierarchyMap: computed,
      tagNameMap: computed,
      loading: computed,
    });
  }

  static getContentTypeIcon(contentType: typeof ContentType.type) {
    const name = ContentType.match(
      contentType,
      {
        ads: () => IconVariant.ADS_CONTENT_TYPE,
        blogs: () => IconVariant.BLOG_CONTENT_TYPE,
        caseStudies: () => IconVariant.CASE_STUDY_CONTENT_TYPE,
        defaultLongform: () => IconVariant.DEFAULT_LONGFORM_CONTENT_TYPE,
        defaultShortform: () => IconVariant.DEFAULT_SHORTFORM_CONTENT_TYPE,
        general: () => IconVariant.DEFAULT_SHORTFORM_CONTENT_TYPE,
        emails: () => IconVariant.EMAIL_CONTENT_TYPE,
        errors: () => IconVariant.ERROR_MESSAGE_CONTENT_TYPE,
        faqs: () => IconVariant.FAQ_CONTENT_TYPE,
        lists: () => IconVariant.LIST_CONTENT_TYPE,
        messaging: () => IconVariant.MESSAGE_BUBBLE_CONTENT_TYPE,
        social: () => IconVariant.SOCIAL_CONTENT_TYPE,
        summaries: () => IconVariant.SUMMARY_CONTENT_TYPE,
        supportArticle: () => IconVariant.SUPPORT_ARTICLE_CONTENT_TYPE,
        translations: () => IconVariant.DEFAULT_LONGFORM_CONTENT_TYPE,
      },
      IconVariant.ADS_CONTENT_TYPE,
    );

    return <Icon name={name || IconVariant.DEFAULT_LONGFORM_CONTENT_TYPE} height={40} width={40} />;
  }

  static mapCategoryToIcon = (category: typeof PromptCategories.type) => {
    const item = PromptCategories.match<{ color: string; iconName?: IconVariant }, string>(
      category,
      {
        advertising: () => ({ color: '#AAEBFF', iconName: undefined }),
        'external-comms': () => ({ color: '#E3FC49', iconName: undefined }),
        general: () => ({ color: '#FD9EFF', iconName: undefined }),
        marketing: () => ({ color: '#AA83FF', iconName: undefined }),
        support: () => ({ color: '#E4E9FF', iconName: undefined }),
        product: () => ({ color: '#476EF9', iconName: undefined }),
        recruiting: () => ({ color: '#AAEBFF', iconName: undefined }),
        sales: () => ({ color: '#8BFF97', iconName: undefined }),
        design: () => ({ color: '#FFD29D', iconName: undefined }),
        brainstorming: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.LIGHT_BULB }),
        generating: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.COMMANDS_WAND }),
        summarizing: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.SUMMARY }),
        rewriting: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.COMMANDS_STAR }),
        translating: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.GLOBE }),
        translations: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.GLOBE }),
        ads: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.ADS_CONTENT_TYPE_BOLD }),
        blogs: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.BLOG_CONTENT_TYPE_BOLD }),
        emails: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.EMAIL_CONTENT_TYPE_BOLD }),
        social: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.ADS_CONTENT_TYPE_BOLD }),
        shortform: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.DOC_WITH_TWO_LINES }),
        longform: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.DOC_WITH_FIVE_LINES }),
        healthcare: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.CROSS_IN_CIRCLE }),
        'financial-services': () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.ABSTRACT_DOLLAR_SIGN }),
        retail: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.ECOMMERCE }),
        technology: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.TECH }),
        analyzing: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.CRYSTAL_BALL }),
        transforming: () => ({ color: DEFAULT_MENU_ITEM_COLOR, iconName: IconVariant.TRANSFORM }),
      },
      IconVariant.ADS_CONTENT_TYPE,
    );

    if (item?.iconName) {
      return <Icon className={styles.categoryIcon} name={item.iconName} height={18} width={18} />;
    } else {
      return (
        <div className={styles.circleWrapper}>
          <div className={styles.colorCircle} style={{ backgroundColor: item?.color || DEFAULT_MENU_ITEM_COLOR }}></div>
        </div>
      );
    }
  };

  setActiveCategory(value: string) {
    this.activeCategory = value;
  }

  setActiveContentTypeCategory(value: string) {
    this.activeContentTypeCategory = value;
  }

  setSearchQuery(value: string) {
    this.searchQuery = value;
    this.opts.analyticsService.track(AnalyticsActivity.searchedPromptLibrary, {});
  }

  get analyticsService() {
    return this.opts.analyticsService;
  }

  get categories(): string[] {
    if (this.normalizedTagMap.allIds.length === 0 && isEmpty(this.tagHierarchyMap)) {
      return [];
    }

    const topLevelTags = [
      // add fake top level tag
      TSyntheticPromptLibraryTags.enum.contentType,
      ...this.normalizedTagMap.allIds.filter(tag => !this.normalizedTagMap.byId[tag].parentId),
    ];
    const sortedTopLevelTags = topLevelTags.sort(
      (a: string, b: string) => categoryOrder.indexOf(a) - categoryOrder.indexOf(b),
    );

    return sortedTopLevelTags;
  }

  get normalizedTagMap(): NormalizedState<TPromptTag> {
    if (this.promptTags) {
      const result = normalizeByProperty(this.promptTags, 'id');

      contentTypeTags.forEach(contentTypeGroup => {
        result.byId[contentTypeGroup] = {
          id: contentTypeGroup,
          parentId: TSyntheticPromptLibraryTags.enum.contentType,
        } as components['schemas']['content_generation_model_PromptTag'];
      });

      return result;
    }

    return defaultNormalizedState;
  }

  get tagHierarchyMap(): Record<string, string[]> | undefined {
    if (this.normalizedTagMap) {
      const result = this.normalizedTagMap.allIds.reduce(
        (acc, tag) => {
          const tagPayload = this.normalizedTagMap?.byId[tag];

          if (tagPayload?.parentId) {
            acc[tagPayload.parentId] = acc[tagPayload.parentId]
              ? [...acc[tagPayload.parentId], tagPayload.id]
              : [tagPayload.id];
          }

          return acc;
        },
        {} as Record<string, string[]>,
      );
      result.contentType = contentTypeTags;

      return result;
    }

    return {};
  }

  get tagNameMap(): Record<string, string> {
    let map = {};

    if (this.promptTags) {
      this.promptTags.forEach(promptTag => {
        map[promptTag.id] = promptTag.name;
      });
      map = {
        ...map,
        ...contentTypeTagMap,
        contentType: 'Content Type',
      };
    }

    return map;
  }

  get promptGroupByTagMap(): Record<string, TPrompt[]> {
    const promptGroupByTagMap = {};

    this.prompts?.forEach((prompt: components['schemas']['content_generation_model_Prompt']) => {
      const { contentType } = prompt;
      promptGroupByTagMap[contentType] = promptGroupByTagMap[contentType]
        ? [...promptGroupByTagMap[contentType], prompt]
        : [prompt];

      if (prompt.tagIds) {
        prompt.tagIds.forEach(tagId => {
          promptGroupByTagMap[tagId] = promptGroupByTagMap[tagId] ? [...promptGroupByTagMap[tagId], prompt] : [prompt];
        });
      }
    });

    return promptGroupByTagMap;
  }

  get loading(): boolean {
    return !this.opts.api.isLoaded && this.prompts.length === 0;
  }

  get prompts(): TPrompt[] {
    const { prompts } = this.opts.api;

    const lowerCaseQuery = this.searchQuery.toLowerCase();

    const filteredItems = prompts.filter(prompt => {
      let header = '';

      if (prompt.header) {
        header = prompt.header.toLowerCase();
      }

      const promptText = prompt.prompt.toLowerCase();
      const { keywords } = prompt;

      return (
        header.includes(lowerCaseQuery) ||
        promptText.includes(lowerCaseQuery) ||
        (keywords &&
          !!keywords
            .filter(keyword => keyword)
            .some(keyword => lowerCaseQuery.includes(keyword.toLowerCase()) || keyword.includes(lowerCaseQuery)))
      );
    });

    return filteredItems?.map(prompt => ({
      ...prompt,
      contentType: ContentType.enum[prompt.contentType],
    }));
  }

  get promptTags(): components['schemas']['content_generation_model_PromptTag'][] | null | undefined {
    return this.opts.api.promptTags;
  }

  setSearchPhrase = (phrase: string) => {
    this.searchInputField.value = phrase;
    this.opts.analyticsService.track(AnalyticsActivity.searchedPromptLibrary, {});
    this.debouncedSearch();
  };

  resetSearchPhrase = () => {
    this.searchInputField.value = '';
    this.setSearchQuery('');
  };

  debouncedSearch = debounce(
    action(() => this.setSearchQuery(this.searchInputField.value || '')),
    DEFAULT_SEARCH_DEBOUNCE_DELAY_MS,
  );
}
