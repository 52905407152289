import { joinFileExtensions } from '@writercolab/common-utils';
import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export type TKnowledgeGraphFilesResponse = components['schemas']['media_dto_KnowledgeFileResponse'];
export type TPaginatedTKnowledgeGraphFilesResponse =
  components['schemas']['model_PaginatedResult_media_dto_KnowledgeFileResponse'];
export type TKnowledgeGraphWebConnectorResponse = components['schemas']['media_dto_WebConnectorResponse'];
export type TKnowledgeGraphFilterSection = components['schemas']['media_model_SectionFilter_SectionFilterSection'];

export const TKnowledgeGraph = new Enum('Api', 'Upload', 'Connector', 'WebConnector');
export const TKnowledgeGraphUrl = new Enum('SinglePage', 'SubPages', 'Exclude');
export const TKnowledgeGraphUrlStatus = new Enum('Success', 'Validating', 'Error');
export const TKnowledgeGraphUrlError = new Enum(
  'InvalidUrl',
  'NotSearchable',
  'NotFound',
  'PaywallOrLoginPage',
  'UnexpectedError',
);
export const TKnowledgeGraphUrlAction = new Enum('exclude', 'edit', 'delete');
export const TKnowledgeGraphUrlModal = new Enum('addUrl', 'editUrl', 'deleteUrl', 'excludeUrl', 'editExcludeUrl');

export const TKnowledgeGraphDropdownAction = new Enum('rename', 'delete');
export const KnowledgeGraphFilesStatus = new Enum('queued', 'parsing', 'indexing', 'processing', 'completed', 'error');
export const KnowledgeGraphFilesAccess = new Enum('private', 'shared');
export const KnowledgeGraphFilesSortField = new Enum('creationTime', 'size', 'status', 'name');
export const KnowledgeGraphFilesSortOrder = new Enum('asc', 'desc');

export type TKnowledgeGraphFilesAccess = typeof KnowledgeGraphFilesAccess.type;
export type TKnowledgeGraphFilesSortField = typeof KnowledgeGraphFilesSortField.type;
export type TKnowledgeGraphFilesSortOrder = typeof KnowledgeGraphFilesSortOrder.type;
export type TKnowledgeGraphFilesStatus = typeof KnowledgeGraphFilesStatus.type;

export interface TKnowledgeGraphFilesQuery
  extends TPaginatedKnowledgeGraphFilesArgs,
    TPaginatedKnowledgeGraphFilesExtraArgs {}

export type TPaginatedKnowledgeGraphFilesArgs = {
  limit?: number;
  offset?: number;
};

export type TPaginatedKnowledgeGraphFilesExtraArgs = {
  search?: string;
  id?: string;
  access?: TKnowledgeGraphFilesAccess;
  templateId?: string;
  status?: TKnowledgeGraphFilesStatus[];
  sortField?: TKnowledgeGraphFilesSortField;
  sortOrder?: TKnowledgeGraphFilesSortOrder;
  graphId?: string;
};

export type TKnowledgeGraphFilesInfo = components['schemas']['media_model_KnowledgeFileInfo'];

export const KnowledgeGraphFilesViewType = new Enum('Upload', 'Connector');
export const KnowledgeGraphFilesViewAnalyticsType = new Enum('Upload', 'DataConnector');

export const KnowledgeGraphFilesError = new Enum(
  'parsing',
  'indexing',
  'storage',
  'unauthorized',
  'too-large',
  'invalid',
  'unsupported',
  'unexpected',
);

export const KG_SUPPORTED_FILE_EXTENSIONS = [
  'pdf',
  'txt',
  'xlsx',
  'csv',
  'html',
  'pptx',
  'docx',
  'html',
  'srt',
  'ppt',
  'xls',
  'doc',
];
export const KG_SUPPORTED_MIME_TYPES = [
  'text/plain',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'image/jpeg',
  'image/png',
  'application/octet-stream',
  'text/html',
  'application/pdf',
  'application/x-subrip',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.google-apps.document',
  'application/vnd.google-apps.spreadsheet',
  'application/vnd.google-apps.presentation',
];
export const KG_SUPPORTED_FILE_EXTENSIONS_JOIN = joinFileExtensions(KG_SUPPORTED_FILE_EXTENSIONS);
export const KG_VISIBLE_FILE_EXTENSIONS = 7;
export const KG_MAX_FILE_SIZE = 150; // MB

export type TKnowledgeGraphPaginatedResponse =
  components['schemas']['model_PaginatedResult_media_dto_KnowledgeGraphResponse'];
export type TKnowledgeGraphResponse = components['schemas']['media_dto_KnowledgeGraphResponse'];
export type TKnowledgeGraphPaginatedArgs = {
  limit?: number;
  offset?: number;
};

export type TKnowledgeGraphPaginatedExtraArgs = {
  search?: string;
  teamId?: number;
  sortField?: components['schemas']['media_model_KnowledgeGraphSortField'];
  sortOrder?: 'asc' | 'desc';
};
export type TKnowledgeGraphPaginatedQuery = TKnowledgeGraphPaginatedArgs & TKnowledgeGraphPaginatedExtraArgs;
