import React from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import { Text, TextColor } from '@writercolab/ui-atoms';

import { openContactSalesPage } from '../../../utils/navigationUtils';

import styles from '../../pages/PeoplePage/styles.module.css';

interface ITeamSizeLimitReachedNotificationProps {
  teamSizeLimit: number;
}

export const TeamSizeLimitReachedNotification: React.FC<ITeamSizeLimitReachedNotificationProps> = ({
  teamSizeLimit,
}) => (
  <Text color={TextColor.WHITE}>
    Interested in more than {teamSizeLimit} {wordPluralize(teamSizeLimit, 'user')}?<br />
    <span className={styles.clickable} onClick={openContactSalesPage}>
      Contact sales
    </span>{' '}
    to upgrade to an Enterprise plan.
  </Text>
);
