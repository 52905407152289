import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './DeleteKnowledgeGraphModal.module.css';

interface IDeleteKnowledgeGraphModalProps {
  connectorName?: string;
  onClickClose: () => void;
  onClickSubmit: () => void;
}

export const DeleteKnowledgeGraphModal: React.FC<IDeleteKnowledgeGraphModalProps> = ({
  connectorName,
  onClickClose,
  onClickSubmit,
}) => (
  <Modal
    open
    title={`Delete ${connectorName ? `${connectorName} connector` : 'graph'}`}
    className={styles.modal}
    titleClassName={styles.title}
    handleClose={onClickClose}
  >
    <div className={styles.container}>
      <div className={styles.description}>
        {connectorName ? (
          <>
            <Text variant={TextSize.M}>
              We’ll permanently delete this connector, all files synced, and this Knowledge Graph.
            </Text>
            <Text variant={TextSize.M}>Are you sure you want to delete this connector?</Text>
          </>
        ) : (
          <Text variant={TextSize.M}>Are you sure you want to delete this graph?</Text>
        )}
      </div>
      <div className={styles.controls}>
        <Button
          className={styles.submitButton}
          type={ButtonTypes.DEFAULT}
          content="Never mind"
          onClick={onClickClose}
        />
        <Button
          className={styles.submitButton}
          type={ButtonTypes.PRIMARY}
          danger
          content="Yes, delete"
          onClick={onClickSubmit}
        />
      </div>
    </div>
  </Modal>
);
