import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { deleteTeam, getTeams } from '@writercolab/common-utils';
import { TSubscriptionLimitType } from '@writercolab/models';
import { useCustomSnackbar } from '@writercolab/ui-atoms';
import type { IAutocompleteValues } from '@writercolab/ui-molecules';

import type { IEditTeamEditForm } from '../components/pages/TeamsPage/modals/EditTeamModal';

import { snackbarMessages } from '@web/component-library';
import type { IMappedTeamDetails } from '@web/types';
import requestService from 'services/request/requestService';

import type { CreateTeamApiModel } from '../models/createTeam.api';
import { useAppState } from '../state';
import { extractBackendResponseErrorMessage } from '../utils/backendErrorUtils';
import { getLogger } from '../utils/logger';
import { isTeamNameDuplicateError, teamsDetailsArrayMapper } from '../utils/teamUtils';

const LOG = getLogger('teamsContext');

export type TCreatTeamState = {
  name: string;
  admins: IAutocompleteValues[];
  copyTermsFrom: string | null;
  copySuggestionsFrom: string | null;
  copySnippetsFrom: string | null;
};

interface ITeamsContext {
  teams: IMappedTeamDetails[];
  isLoading?: boolean;
  handleUpdateTeam: (teamId, state: IEditTeamEditForm) => void;
  handleCreateTeam: (state: TCreatTeamState) => Promise<void>;
  handleDeleteTeam: ({ id, name }) => void;
}

const TeamsContext = createContext<ITeamsContext>({} as ITeamsContext);

const TeamsContextProvider: FC<{ createTeamModel: CreateTeamApiModel; children?: ReactNode }> = ({
  createTeamModel,
  children,
}) => {
  const { appState, loadApplicationStats, refreshTeamsList, refreshPermissions } = useAppState();
  const [teams, setTeams] = useState<IMappedTeamDetails[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueBasicSnackbar, enqueueDeleteSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const orgId = appState.organizationId || 0;

  const getFullList = useCallback(async () => {
    setIsLoading(true);

    if (!orgId) {
      setIsLoading(false);

      return;
    }

    try {
      const teams = await getTeams(orgId);
      setTeams(teamsDetailsArrayMapper(teams));
    } catch (error) {
      LOG.error('Failed to fetch teams:', error);
    } finally {
      setIsLoading(false);
    }
  }, [orgId]);

  useEffect(() => {
    getFullList();
  }, [appState.organizationId, getFullList]);

  const handleUpdateTeam = async (teamId, state: IEditTeamEditForm) => {
    const { name, admins, anotherTeamsSuggestions, anotherTeamsTerms, anotherTeamsSnippets } = state;
    const formData = {
      name,
      adminIds: admins.map(admin => admin.id),
      anotherTeamsSuggestions: anotherTeamsSuggestions?.id || teamId,
      anotherTeamsTerms: anotherTeamsTerms?.id || teamId,
      anotherTeamsSnippets: anotherTeamsSnippets?.id || teamId,
    };
    setIsLoading(true);
    try {
      await requestService.api.put('/api/organization/v2/{organizationId}/team/{teamId}', {
        params: {
          path: {
            organizationId: orgId,
            teamId: teamId,
          },
        },
        body: {
          adminIds: formData.adminIds,
          name: formData.name,
          snippetTeamId: formData.anotherTeamsSnippets,
          styleguideTeamId: formData.anotherTeamsSuggestions,
          terminologyTeamId: formData.anotherTeamsTerms,
        },
      });

      getFullList();
      enqueueBasicSnackbar(snackbarMessages.team.updateSuccess(state.avatar, state.name));
      refreshTeamsList();
      refreshPermissions();
    } catch (e) {
      if (e instanceof Error) {
        enqueueErrorSnackbar(snackbarMessages.team.updateError(e.message));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateTeam = async (team: {
    name: string;
    admins: IAutocompleteValues[];
    copyTermsFrom: null | string;
    copySuggestionsFrom: null | string;
    copySnippetsFrom: null | string;
  }) => {
    try {
      setIsLoading(true);

      const { name, admins, copyTermsFrom, copySuggestionsFrom, copySnippetsFrom } = team;
      const adminIds = admins?.map((admin: any) => admin.id as number);
      const copyTermsFromTeamId = copyTermsFrom ? parseInt(copyTermsFrom, 10) : undefined;
      const copyStyleguideFromTeamId = copySuggestionsFrom ? parseInt(copySuggestionsFrom, 10) : undefined;
      const copySnippetsFromTeamId = copySnippetsFrom ? parseInt(copySnippetsFrom, 10) : undefined;

      const createdTeam = await createTeamModel.create({
        name,
        adminIds,
        copyTermsFromTeamId,
        copyStyleguideFromTeamId,
        copySnippetsFromTeamId,
      });

      await loadApplicationStats(TSubscriptionLimitType.enum.team);
      refreshTeamsList();
      refreshPermissions();
      getFullList();
      enqueueBasicSnackbar(snackbarMessages.team.createSuccess(createdTeam.name));
    } catch (e) {
      LOG.error(e);
      const message = isTeamNameDuplicateError(e)
        ? 'This Team name is already in use. Use a unique Team name and try again'
        : extractBackendResponseErrorMessage(e);
      enqueueErrorSnackbar(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteTeam = async ({ id, name }) => {
    setIsLoading(true);
    try {
      await deleteTeam(orgId, id);
      await loadApplicationStats(TSubscriptionLimitType.enum.team);
      refreshTeamsList();
      refreshPermissions();
      getFullList();
      enqueueDeleteSnackbar(snackbarMessages.team.deleteSuccess(name));
    } catch (e: any) {
      enqueueErrorSnackbar(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TeamsContext.Provider
      value={{
        teams,
        isLoading,
        handleUpdateTeam,
        handleCreateTeam,
        handleDeleteTeam,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export function useTeamsContext() {
  const context = useContext(TeamsContext);

  if (!context) {
    throw new Error('useTeamsContext must be used within the TeamsContextProvider');
  }

  return context;
}

export default TeamsContextProvider;
