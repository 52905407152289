import type React from 'react';
import { useEffect, useMemo } from 'react';

import cx from 'classnames';

import type {
  ISuggestionMetaInclusivity,
  ISuggestionMetaSensitivity,
  ISuggestionSection,
} from '@writercolab/common-utils';
import { SuggestionCategoryType, findSuggestionSection } from '@writercolab/common-utils';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import type { Lens } from 'ramda';
import { lensProp, set } from 'ramda';
import { useAppState } from 'state';

import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import SuggestionCategory from '../SuggestionCategory';
import SuggestionPageHeader from '../SuggestionPageHeader';
import SuggestionSimpleRule from '../SuggestionRule/SuggestionSimpleRule';
import SuggestionRuleGroup from '../SuggestionRuleGroup';
import AgeExample from '../examples/AgeExample';
import DisabilityExample from '../examples/DisabilityExample';
import GenderInclusiveNounsExampleExample from '../examples/GenderInclusiveNounsExample';
import GenderInclusivePronounsExample from '../examples/GenderInclusivePronounsExample';
import GenderIdentityExample from '../examples/GenderIndentityExample';
import RaceEthnicityExample from '../examples/RaceEthnicityExample';
import SexualOrientationExample from '../examples/SexualOrientationExample';
import SubstanceUseExample from '../examples/SubstanceUseExample';
import ExampleTooltip from '../examples/generic/ExampleTooltip';

import styles from '../styles.module.css';

const useGenderInclusivePronounsLens = lensProp<ISuggestionMetaInclusivity, 'useGenderInclusivePronouns'>(
  'useGenderInclusivePronouns',
);
const useGenderInclusiveNounsLens = lensProp<ISuggestionMetaInclusivity, 'useGenderInclusiveNouns'>(
  'useGenderInclusiveNouns',
);

const disabilityLens = lensProp<ISuggestionMetaSensitivity, 'disability'>('disability');
const ageLens = lensProp<ISuggestionMetaSensitivity, 'age'>('age');
const genderIdentityLens = lensProp<ISuggestionMetaSensitivity, 'genderIdentity'>('genderIdentity');
const raceEthnicityNationalityLens = lensProp<ISuggestionMetaSensitivity, 'raceEthnicityNationality'>(
  'raceEthnicityNationality',
);
const sexualOrientationLens = lensProp<ISuggestionMetaSensitivity, 'sexualOrientation'>('sexualOrientation');
const substanceUseLens = lensProp<ISuggestionMetaSensitivity, 'substanceUse'>('substanceUse');

export const InclusivityPage: React.FC = () => {
  usePageTitle('Inclusivity');
  const { appModel } = useAppState();
  const {
    suggestionSettings,
    isSaving,
    isReadOnly,
    isTeamNameShown,
    lastUpdated,
    lastUpdatedBy,
    setSectionEnabled,
    teamName,
    updateSectionMeta,
  } = useSuggestionsContext();

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.suggestionsInclusivityViewed, {});
  }, [appModel.analyticsService]);

  const inclusivity: ISuggestionSection<ISuggestionMetaInclusivity> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.GENDER_INCLUSIVITY, suggestionSettings)!,
    [suggestionSettings],
  );

  const sensitivity: ISuggestionSection<ISuggestionMetaSensitivity> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.SENSITIVITY, suggestionSettings)!,
    [suggestionSettings],
  );

  const onUpdateInclusivityRule = (lens: Lens<ISuggestionMetaInclusivity, boolean>, checked: boolean) => {
    const newInclusivityMeta = set(lens, checked, inclusivity.meta);
    updateSectionMeta(SuggestionCategoryType.GENDER_INCLUSIVITY, newInclusivityMeta);
  };

  const onUpdateSensitivityRule = (lens: Lens<ISuggestionMetaSensitivity, boolean>, checked: boolean) => {
    const newInclusivityMeta = set(lens, checked, sensitivity.meta);
    updateSectionMeta(SuggestionCategoryType.SENSITIVITY, newInclusivityMeta);
  };

  const access = {
    inclusivityDisabled: !inclusivity.enabled || isReadOnly,
    sensitivityDisabled: !sensitivity.enabled || isReadOnly,
  };

  return (
    <div className={cx(styles.suggestionSection, styles.suggestionSectionExtraPadding)}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Inclusivity"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      <div className={styles.description}>
        <div className={styles.descriptionItemShort}>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            How content is delivered — the voice and tone in which it comes across — can make a big difference in
            achieving the goal of the message.
          </Text>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            When a switch is on, we’ll let you know if your content has issues in that category.
          </Text>
        </div>
      </div>

      <SuggestionCategory
        readOnly={isReadOnly}
        name="Gender inclusivity"
        type={SuggestionCategoryType.GENDER_INCLUSIVITY}
        description="Gender inclusivity flags content that can be replaced with gender-inclusive alternatives."
        halfLimitedWidth
        hideBottomBorder
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
        className={styles.suggestionCategoryGenderInclusivity}
      >
        <SuggestionRuleGroup>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleGenderInclusivePronouns, styles.simpleRule)}
            readOnly={access.inclusivityDisabled}
            isChecked={inclusivity.meta.useGenderInclusivePronouns}
            onUpdateRuleValue={(checked: boolean) => onUpdateInclusivityRule(useGenderInclusivePronounsLens, checked)}
          >
            <ExampleTooltip example={<GenderInclusivePronounsExample />} title="Use gender-inclusive pronouns" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.simpleRule)}
            readOnly={access.inclusivityDisabled}
            isChecked={inclusivity.meta.useGenderInclusiveNouns}
            onUpdateRuleValue={(checked: boolean) => onUpdateInclusivityRule(useGenderInclusiveNounsLens, checked)}
          >
            <ExampleTooltip example={<GenderInclusiveNounsExampleExample />} title="Use gender-inclusive nouns" />
          </SuggestionSimpleRule>
        </SuggestionRuleGroup>
      </SuggestionCategory>
      <SuggestionCategory
        readOnly={isReadOnly}
        name="Sensitivity"
        type={SuggestionCategoryType.SENSITIVITY}
        description="Sensitivity flags phrases and words that your audience may consider insensitive, outdated, or disrespectful, and offers suggested alternatives that are more inclusive."
        halfLimitedWidth
        hideBottomBorder
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
        className={styles.suggestionCategorySensitivity}
      >
        <SuggestionRuleGroup>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleInclusivityRule, styles.simpleRule)}
            readOnly={access.sensitivityDisabled}
            isChecked={sensitivity.meta.disability}
            onUpdateRuleValue={(checked: boolean) => onUpdateSensitivityRule(disabilityLens, checked)}
          >
            <ExampleTooltip example={<DisabilityExample />} title="Disability" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleInclusivityRule, styles.simpleRule)}
            readOnly={access.sensitivityDisabled}
            isChecked={sensitivity.meta.age}
            onUpdateRuleValue={(checked: boolean) => onUpdateSensitivityRule(ageLens, checked)}
          >
            <ExampleTooltip example={<AgeExample />} title="Age" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleInclusivityRule, styles.simpleRule)}
            readOnly={access.sensitivityDisabled}
            isChecked={sensitivity.meta.genderIdentity}
            onUpdateRuleValue={(checked: boolean) => onUpdateSensitivityRule(genderIdentityLens, checked)}
          >
            <ExampleTooltip example={<GenderIdentityExample />} title="Gender identity" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleInclusivityRule, styles.simpleRule)}
            readOnly={access.sensitivityDisabled}
            isChecked={sensitivity.meta.raceEthnicityNationality}
            onUpdateRuleValue={(checked: boolean) => onUpdateSensitivityRule(raceEthnicityNationalityLens, checked)}
          >
            <ExampleTooltip example={<RaceEthnicityExample />} title="Race, ethnicity, and nationality" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleInclusivityRule, styles.simpleRule)}
            readOnly={access.sensitivityDisabled}
            isChecked={sensitivity.meta.sexualOrientation}
            onUpdateRuleValue={(checked: boolean) => onUpdateSensitivityRule(sexualOrientationLens, checked)}
          >
            <ExampleTooltip example={<SexualOrientationExample />} title="Sexual orientation" />
          </SuggestionSimpleRule>
          <SuggestionSimpleRule
            className={cx(styles.inputRuleContainer, styles.suggestionRuleInclusivityRule, styles.simpleRule)}
            readOnly={access.sensitivityDisabled}
            isChecked={sensitivity.meta.substanceUse}
            onUpdateRuleValue={(checked: boolean) => onUpdateSensitivityRule(substanceUseLens, checked)}
          >
            <ExampleTooltip example={<SubstanceUseExample />} title="Substance use" />
          </SuggestionSimpleRule>
        </SuggestionRuleGroup>
      </SuggestionCategory>
    </div>
  );
};

export default InclusivityPage;
