/* eslint-disable no-nested-ternary */
import { FontsModule } from '@web/web-quill-editor';
import type {
  ExternalHyperlink as DExternalHyperlink,
  Paragraph as DParagraph,
  TextRun as DTextRun,
  Document as DocxDocument,
  ILevelsOptions,
  INumberingOptions,
  IParagraphStyleOptions,
} from 'docx';
import type Delta from 'quill-delta';
import type { Paragraph as QParagraph, TextRun as QTextRun } from 'quilljs-parser';
import { getCSSColorHex } from 'utils/colorUtils';

interface NumberedList {
  reference: string;
  levels: ILevelsOptions[];
}

const paragraphStyles: IParagraphStyleOptions[] = [
  {
    id: 'blockquote',
    name: 'Block Quote',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      italics: true,
    },
    paragraph: {
      indent: { left: 540 },
    },
  },
];

const characterStyles: IParagraphStyleOptions[] = [
  {
    id: 'codeblock',
    name: 'Code Block',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      size: 24,
      font: 'Courier New',
      color: '353535',
    },
  },
];

// main public function to generate docx document
export async function generateWord(delta: Delta, filename = 'example') {
  const {
    AlignmentType,
    Document,
    ExternalHyperlink,
    HeadingLevel,
    ImageRun,
    Packer,
    Paragraph,
    TextRun,
    UnderlineType,
    LevelFormat,
  } = await import('docx');

  const { saveAs } = await import('file-saver');

  const { parseQuillDelta } = await import('quilljs-parser');

  const customNumberedLevels: ILevelsOptions[] = [
    {
      level: 0,
      format: LevelFormat.DECIMAL,
      text: '%1.',
      alignment: AlignmentType.LEFT,
      style: {
        paragraph: {
          indent: { left: 720, hanging: 360 },
        },
      },
    },
    {
      level: 1,
      format: LevelFormat.LOWER_LETTER,
      text: '%2.',
      alignment: AlignmentType.LEFT,
      style: {
        paragraph: {
          indent: { left: 1440, hanging: 360 },
        },
      },
    },
    {
      level: 2,
      format: LevelFormat.LOWER_ROMAN,
      text: '%3.',
      alignment: AlignmentType.LEFT,
      style: {
        paragraph: {
          indent: { left: 2160, hanging: 360 },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.DECIMAL,
      text: '%4.',
      alignment: AlignmentType.LEFT,
      style: {
        paragraph: {
          indent: { left: 2880, hanging: 360 },
        },
      },
    },
    {
      level: 4,
      format: LevelFormat.LOWER_LETTER,
      text: '%5.',
      alignment: AlignmentType.LEFT,
      style: {
        paragraph: {
          indent: { left: 3600, hanging: 360 },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.LOWER_ROMAN,
      text: '%5.',
      alignment: AlignmentType.LEFT,
      style: {
        paragraph: {
          indent: { left: 4320, hanging: 360 },
        },
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let linkTracker = 0;
  let numberedTracker = -1;
  const customBullets = false;
  const levels = customNumberedLevels;

  // export the appropriate object based on configuration
  async function exportDoc(doc: DocxDocument, filename: string) {
    return Packer.toBlob(doc).then(blob => {
      saveAs(blob, `${filename}.docx`);
    });
  }

  // build docx numbering object from quill numbered lists
  function buildNumbering(numberOfLists: number): INumberingOptions {
    const config: NumberedList[] = [];
    let numberTracker = 0;

    // create a new docx numbering object for each quill numbered list
    while (numberTracker < numberOfLists) {
      const newList = {
        reference: `numbered_${numberTracker}`,
        levels,
      };
      config.push(newList);
      numberTracker++;
    }

    return {
      config,
    };
  }

  // generate a section as an array of paragraphs
  function buildSection(quillParagraphs: QParagraph[]): DParagraph[] {
    let quillParagraphTracker = 0;
    // create a container to hold the docx paragraphs
    const paragraphs: DParagraph[] = [];
    // build a docx paragraph from each delta paragraph
    quillParagraphs.forEach(paragraph => {
      // if embed video or image
      if (paragraph.embed?.image) {
        paragraphs.push(
          new Paragraph({
            children: [
              new ImageRun({
                data: paragraph.embed.image,
                transformation: {
                  width: 50,
                  height: 50,
                },
              }),
            ],
          }),
        );
      } else if (paragraph.embed?.video) {
        const run = buildVideo(paragraph.embed.video);
        paragraphs.push(new Paragraph({ children: [run] }));
        // if text runs
      } else if (paragraph.textRuns) {
        // handle ordered list tracking
        if (quillParagraphTracker > 0 && paragraph.attributes?.list === 'ordered') {
          if (quillParagraphs[quillParagraphTracker - 1].attributes?.list !== 'ordered') {
            numberedTracker++;
          }
        }

        paragraphs.push(buildParagraph(paragraph));
      }

      quillParagraphTracker++;
    });

    return paragraphs;
  }

  // generate a paragraph as an array of text runs
  function buildParagraph(paragraph: QParagraph): DParagraph {
    // container to hold docx text runs
    const textRuns: (DTextRun | DExternalHyperlink)[] = [];
    // build a docx run from each delta run
    paragraph.textRuns?.forEach(run => {
      // if formula
      if ((run as { formula: string }).formula) {
        textRuns.push(buildFormula((run as { formula: string }).formula));
        // if text
      } else if ((run as QTextRun).text) {
        textRuns.push(buildTextRun(run as QTextRun));
      }
    });

    const docxParagraph = new Paragraph({
      children: textRuns,
      heading:
        paragraph.attributes?.header === 1
          ? HeadingLevel.HEADING_1
          : paragraph.attributes?.header === 2
            ? HeadingLevel.HEADING_2
            : paragraph.attributes?.header === 3
              ? HeadingLevel.HEADING_3
              : undefined,

      bullet:
        paragraph.attributes?.list === 'bullet' && !customBullets
          ? {
              level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
            }
          : undefined,

      numbering:
        paragraph.attributes?.list === 'ordered'
          ? {
              reference: `numbered_${numberedTracker}`,
              level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
            }
          : paragraph.attributes?.list === 'bullet' && customBullets
            ? {
                reference: 'customBullets',
                level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
              }
            : undefined,

      alignment:
        paragraph.attributes?.align === 'left'
          ? AlignmentType.LEFT
          : paragraph.attributes?.align === 'center'
            ? AlignmentType.CENTER
            : paragraph.attributes?.align === 'right'
              ? AlignmentType.RIGHT
              : paragraph.attributes?.align === 'justify'
                ? AlignmentType.JUSTIFIED
                : undefined,

      style: paragraph.attributes?.blockquote ? 'blockquote' : undefined,
    });

    return docxParagraph;
  }

  // generate a docx text run from quill text run
  function buildTextRun(run: QTextRun): DTextRun | DExternalHyperlink {
    const font = FontsModule.fontsList.find(font => font.name === run.attributes?.font);

    const textRun = new TextRun({
      font: font?.title || 'Calibri',
      text: run.text,
      bold: !!run.attributes?.bold,
      italics: !!run.attributes?.italic,
      subScript: run.attributes?.script === 'sub',
      superScript: run.attributes?.script === 'super',
      strike: !!run.attributes?.strike,
      underline: run.attributes?.underline ? { type: UnderlineType.SINGLE, color: 'auto' } : undefined,
      color: getCSSColorHex(run.attributes?.color),
      size:
        run.attributes?.size === 'huge'
          ? 36
          : run.attributes?.size === 'large'
            ? 32
            : run.attributes?.size === 'small'
              ? 20
              : undefined,
      highlight: run.attributes?.background ? 'yellow' : undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      style: run.attributes?.link ? 'Hyperlink' : (run.attributes as any)?.code ? 'codeblock' : undefined,
    });

    if (run.attributes?.link) {
      linkTracker++;

      return new ExternalHyperlink({
        children: [textRun],
        link: run.attributes.link,
      });
    } else {
      return textRun;
    }
  }

  // build a formula
  function buildFormula(formula: string) {
    return new TextRun({
      text: formula,
    });
  }

  // build a video
  function buildVideo(video: string) {
    return new TextRun({
      text: video,
    });
  }

  const parsedDelta = parseQuillDelta(delta);
  const numbering = parsedDelta.setup.numberedLists > 0 ? buildNumbering(parsedDelta.setup.numberedLists) : undefined;

  const sections = buildSection(parsedDelta.paragraphs);

  const doc = new Document({
    styles: {
      paragraphStyles,
      characterStyles,
    },
    numbering,
    sections: [
      {
        children: sections,
      },
    ],
  });

  // return the appropriate export object based on configuration
  return exportDoc(doc, filename);
}
