import { useCallback, useEffect } from 'react';

import { collectCookieUtmParams, resendConfirmationEmail } from '@writercolab/common-utils';
import { useCustomSnackbar } from '@writercolab/ui-atoms';

import { snackbarMessages } from '@web/component-library';

export const useEmailConfirmation = (isUnverifiedEmail: boolean) => {
  const { enqueueBasicSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const onResendEmailClick = useCallback(async () => {
    try {
      const utmParams = collectCookieUtmParams();
      await resendConfirmationEmail(utmParams);
      enqueueBasicSnackbar(snackbarMessages.user.resendConfirmationEmail);
    } catch {
      enqueueErrorSnackbar(snackbarMessages.user.resendConfirmationEmailError);
    }
  }, [enqueueBasicSnackbar, enqueueErrorSnackbar]);

  useEffect(() => {
    if (isUnverifiedEmail) {
      onResendEmailClick();
    }
  }, [isUnverifiedEmail, onResendEmailClick]);

  return {
    onResendEmailClick,
  };
};
