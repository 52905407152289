import { Button, ButtonTypes, Modal, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { TKnowledgeGraphUrl, TKnowledgeGraphUrlModal } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphWebConnectorUIModel } from '../../KnowledgeGraphWebConnectorModel.ui';

import styles from './DeleteUrlModal.module.css';
import { getLogger } from 'utils/logger';

const LOG = getLogger('DeleteUrlModal');

interface IDeleteUrlModalProps {
  model: KnowledgeGraphWebConnectorUIModel;
}

export const DeleteUrlModal = observer<IDeleteUrlModalProps>(({ model }) => {
  const {
    analyticsService,
    modalsManager,
    urls,
    isLoading,
    validUrlsCount,
    excludeUrlsCount,
    onDeleteUrl,
    onDeleteExcludeUrl,
  } = model;

  const handleRemoveUrl = () => {
    const modalState = modalsManager.getModalState(TKnowledgeGraphUrlModal.enum.deleteUrl);

    if (modalState) {
      const url = urls.find(url => url.id === modalState.id);

      try {
        TKnowledgeGraphUrl.match(
          modalState.type,
          {
            SinglePage: () => onDeleteUrl(modalState.id),
            SubPages: () => onDeleteUrl(modalState.id),
            Exclude: () => onDeleteExcludeUrl(modalState.id, modalState.url),
          },
          null,
        );
        analyticsService.track(AnalyticsActivity.kgWebUrlRemoved, {
          url: modalState.url,
          has_exclude_urls: url && 'excludeUrls' in url ? url.excludeUrls.length > 0 : false,
          url_count: validUrlsCount + excludeUrlsCount - 1,
        });
      } catch (e) {
        LOG.error('Error removing URL:', e);
      }
    }
  };

  return (
    <Modal
      open
      title="Delete URL?"
      className={styles.modal}
      modalTitleClassName={styles.title}
      handleClose={() => modalsManager.hideModal()}
    >
      <div>
        <Text color={TextColor.GREY2} variant={TextSize.XXXL} medium>
          This will delete the URL from the Knowledge Graph.
        </Text>
        <div className={styles.controls}>
          <Button
            disabled={isLoading}
            type={ButtonTypes.DEFAULT}
            content="Cancel"
            onClick={() => modalsManager.hideModal()}
          />
          <Button isLoading={isLoading} type={ButtonTypes.PRIMARY} danger content="Remove" onClick={handleRemoveUrl} />
        </div>
      </div>
    </Modal>
  );
});
