import { convertMarkdownToHtml, sanitize } from '@writercolab/text-utils';
import { PLUGIN_NAME } from '@writercolab/tiptap-extensions';

import { Content } from '@tiptap/core';

export const processLlmOutputBeforeInsert = (originalHtml: string): string => {
  const html = convertMarkdownToHtml(originalHtml, { mangle: false, headerIds: false });
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const lists = doc.querySelectorAll('ul, ol');

  lists.forEach(list => {
    list.innerHTML = list.innerHTML.replace(/\n/g, '');
  });

  // Replace the rest of the new lines with <br/> for Quill
  return sanitize(doc.body.innerHTML).replace(/\n/g, '<br/>');
};

/**
 * Regular expression to validate an email format.
 * It expects at least one character before and after the "@" sign and
 * at least two characters after the "." character.
 */
export const validEmailRegex: RegExp = /(.+)@(.+){2,}\.(.+){2,}/;

export const MATCH_CURLY_BRACES_REGEX = /{|}/g;

export const parseToProseMirrorSchema = (str: string): Content => {
  if (!str) {
    return {};
  }

  const content: Record<string, unknown>[] = [];
  const paragraphs = str.split('\n');

  paragraphs.forEach(paragraph => {
    const variables = paragraph.match(/\{(.*?)}/g);
    const splittedWithDelimiter = paragraph.split(/({[^}]+})/g);

    const result = {
      type: 'paragraph',
      content: splittedWithDelimiter
        .filter(s => s)
        .map(chunk => {
          if (variables?.includes(chunk)) {
            const pristineVar = chunk.replace(MATCH_CURLY_BRACES_REGEX, '');

            return {
              type: PLUGIN_NAME,
              attrs: {
                value: pristineVar,
              },
            };
          } else {
            return { type: 'text', text: chunk };
          }
        }),
    };
    content.push(result);
  });

  return {
    type: 'doc',
    content,
  };
};
