import type React from 'react';
import { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import type { TOrgTeamUserActivityParams } from '@writercolab/common-utils';
import {
  openChromeExtensionStore,
  openContentfulExtensionStore,
  openEdgeAddonsStore,
  openFigmaExtensionStore,
  openMacExtensionStore,
  openNewTab,
  openOutlookExtensionStore,
  openWordExtensionStore,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Logo,
  SizeTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import { SLACK_APP_INSTALL_URL, TWriterExtensionId } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import WriterForChromeImg from '../../../assets/icons/chromeLogo.svg?react';
import BlackLogo from '../../../assets/logo/logoBlack.svg?react';
import { WINDOWS_ELECTRON_APP_DOWNLOAD_URL } from '../../../constants/extensions';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';

import styles from './ExtensionsPage.module.css';

interface AppContainer {
  id: typeof TWriterExtensionId.type;
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  cta: (installed: boolean) => React.ReactNode;
}

const apps = (analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>): AppContainer[] => [
  {
    id: TWriterExtensionId.enum.CHROME,
    icon: (
      <div className={styles.chromeIconContainer}>
        <WriterForChromeImg className={styles.chromeIcon} />
        <Logo className={styles.chromeIconWriterLogo} />
      </div>
    ),
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallChromeExt, {});
          openChromeExtensionStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <Heading variant={HeadingVariant.H3} bold>
        Writer for Chrome
      </Heading>
    ),
    description: (
      <Text variant={TextSize.L}>
        Make sure you’re on brand and on message, everywhere you write online (including Google Docs).
      </Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.WORD,
    icon: <Icon name={IconVariant.WRITER_FOR_WORD} width={62} height={48} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallWordExt, {});
          openWordExtensionStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <Heading variant={HeadingVariant.H3} bold>
        Writer for Word
      </Heading>
    ),
    description: (
      <Text variant={TextSize.L}>
        Harness the power of Writer while you write with Microsoft Office, on desktop or online.
      </Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.OUTLOOK,
    icon: <Icon name={IconVariant.WRITER_FOR_OUTLOOK} width={62} height={48} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallOutlookExt, {});
          openOutlookExtensionStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <Heading variant={HeadingVariant.H3} bold>
        Writer for Outlook
      </Heading>
    ),
    description: (
      <Text variant={TextSize.L}>
        Harness the power of Writer while you write with Microsoft Outlook, on desktop or online.
      </Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.FIGMA,
    icon: (
      <div className={styles.containerIconLogo}>
        <Icon name={IconVariant.WRITER_FOR_FIGMA} width={62} height={48} />
        <Logo className={styles.containerLogoSmall} />
      </div>
    ),
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallFigmaExt, {});
          openFigmaExtensionStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <Heading variant={HeadingVariant.H3} bold>
        Writer for Figma
      </Heading>
    ),
    description: (
      <Text variant={TextSize.L}>
        Make sure your designs are error-free with Writer for Figma, on desktop or online.
      </Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.CONTENTFUL,
    icon: <Icon name={IconVariant.WRITER_FOR_CONTENTFUL} width={62} height={48} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallContentfulExt, {});
          openContentfulExtensionStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <Heading variant={HeadingVariant.H3} bold>
        Writer for Contentful
      </Heading>
    ),
    description: (
      <Text variant={TextSize.L}>
        Ensure consistent and on-brand content across all channels with Writer for Contentful.
      </Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.EDGE,
    icon: <Icon name={IconVariant.WRITER_FOR_EDGE} width={55} height={48} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallEdgeExt, {});
          openEdgeAddonsStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <div className={styles.textWithBetaContainer}>
        <Heading variant={HeadingVariant.H3} bold>
          Writer for Edge
        </Heading>
      </div>
    ),
    description: (
      <Text variant={TextSize.L}>
        Make sure you’re on brand and on message, everywhere you write online (including Google Docs).
      </Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.MAC,
    icon: <Icon name={IconVariant.WRITER_FOR_MAC} width={55} height={48} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallMacExt, {});
          openMacExtensionStore();
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <div className={styles.textWithBetaContainer}>
        <Heading variant={HeadingVariant.H3} bold>
          Writer for Mac
        </Heading>
      </div>
    ),
    description: (
      <Text variant={TextSize.L}>Generate content wherever you’re writing with Writer’s Mac desktop app.</Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.WINDOWS,
    icon: <BlackLogo width={41} height={41} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => {
          analyticsService.track(AnalyticsActivity.clickedInstallWinExt, {});
          openNewTab(WINDOWS_ELECTRON_APP_DOWNLOAD_URL);
        }}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <div className={styles.textWithBetaContainer}>
        <Heading variant={HeadingVariant.H3} bold>
          Writer for Windows
        </Heading>
      </div>
    ),
    description: (
      <Text variant={TextSize.L}>Generate content wherever you're writing with Writer's desktop app for Windows.</Text>
    ),
  },
  {
    id: TWriterExtensionId.enum.SLACK,
    icon: <Icon name={IconVariant.SLACK} width={44} height={48} />,
    cta: (installed: boolean) => (
      <Button
        type={ButtonTypes.PRIMARY}
        size={SizeTypes.LARGE}
        onClick={() => openNewTab(SLACK_APP_INSTALL_URL)}
        content={installed ? 'Installed' : 'Install'}
        className={styles.containerItemCtaButton}
      />
    ),
    title: (
      <div className={styles.textWithBetaContainer}>
        <Heading variant={HeadingVariant.H3} bold>
          Writer for Slack
        </Heading>
      </div>
    ),
    description: (
      <Text variant={TextSize.L}>
        Access Writer chat apps and generate content directly on Slack. Usage costs apply.
      </Text>
    ),
  },
];

const AppItem: React.FC<{
  container: AppContainer;
  chromeExtensionInstalled: boolean;
}> = ({ container, chromeExtensionInstalled }) => {
  let installed: boolean;

  switch (container.id) {
    case TWriterExtensionId.enum.CHROME:
      installed = chromeExtensionInstalled;
      break;
    default:
      installed = false;
  }

  return (
    <div className={styles.containerItem}>
      <div className={styles.containerItemIcon}>{container.icon}</div>
      <div className={styles.containerItemHeading}>{container.title}</div>
      <div className={styles.containerItemDescription}>{container.description}</div>
      <div
        className={cx(styles.containerItemCta, {
          [styles.containerItemCtaInstalled]: installed,
        })}
      >
        {container.cta(installed)}
        {installed && (
          <div className={styles.containerCheck}>
            <Icon name={IconVariant.CHECK} width={22} height={22} />
          </div>
        )}
      </div>
    </div>
  );
};

export const ExtensionsPage = observer(() => {
  usePageTitle('Extensions');
  const { appState, appModel } = useAppState();
  const [isChromeExtInstalled, setIsChromeExtInstalled] = useState(appState.isChromeExtInstalled);
  const availableExtensionsList = appModel.featureFlags.get('availableExtensionsList', TWriterExtensionId.list);

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.appsViewed, {});
    setIsChromeExtInstalled(appState.isChromeExtInstalled);
  }, [appState.isChromeExtInstalled, appModel.analyticsService]);

  const extensions = useMemo(
    () => apps(appModel.analyticsService).filter(app => availableExtensionsList.includes(app.id)),
    [appModel.analyticsService, availableExtensionsList],
  );

  return (
    <div className={styles.container}>
      <div className={styles.containerContent}>
        <Heading variant={HeadingVariant.H2} className={styles.heading} upperCase>
          Extensions
        </Heading>
        <div className={styles.cardContainerGrid}>
          {extensions.map(app => (
            <AppItem key={app.id} container={app} chromeExtensionInstalled={isChromeExtInstalled} />
          ))}
        </div>
      </div>
    </div>
  );
});
