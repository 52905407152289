/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useRef } from 'react';

import { type TGetDraftsResponse } from '@writercolab/types';
import { type IFilterOptionsFilter } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import type { TDraftWithInputsResponse, TOutputsQueryParams } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import differenceBy from 'lodash/differenceBy';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useAppState } from 'state';
import { sortByNameProperty } from 'utils/arrayUtils';

import { BaseTable, type IRowProps } from '../BaseTable/BaseTable';
import { Empty } from './Empty';
import { type OutputsTableUiModel } from './OutputsTableModel.ui';
import { TableSkeleton } from './Skeleton';
import { generateTableBody, generateTableHeaders, getOutputsUrlParams } from './utils';

import styles from './OutputsTable.module.css';

export const OutputsTableActionType = new Enum('onUseInNewDoc', 'onCopy', 'onDelete');

export interface IOutputsTableProps {
  model: OutputsTableUiModel;
  onRowClick: (output: IRowProps<TGetDraftsResponse>) => void;
  actions: {
    [OutputsTableActionType.enum.onUseInNewDoc]: (output: TDraftWithInputsResponse) => void;
    [OutputsTableActionType.enum.onCopy]: (output: TDraftWithInputsResponse) => void;
    [OutputsTableActionType.enum.onDelete]: (output: TDraftWithInputsResponse) => void;
  };
  onFilterChange: (filter: TOutputsQueryParams) => void;
}

export const OUTPUTS_TABLE_ID = 'container-ouputs-table';
const GRID_TEMPLATE_COLUMNS = 'minmax(400px,1fr) minmax(150px,250px) minmax(200px,300px) 46px';

export const OutputsTable = observer<IOutputsTableProps>(({ model, onRowClick, actions, onFilterChange }) => {
  const { appModel } = useAppState();
  const prevSelectedApps = useRef<IFilterOptionsFilter[]>([]);
  const { isLoading, hasMore, loadMore } = model;

  const urlParams = getOutputsUrlParams();
  const outputId = urlParams.outputId;
  const { templateIds: selectedTemplateIds } = urlParams;

  const tableData = useMemo(
    () => generateTableBody(model, actions, appModel.analyticsService, outputId),
    [model.outputs, model.selectedOutput, actions, outputId],
  );

  // Used only to provide a selected app to analytics
  const getSelectedAppFromFilter = (selectedApps: IFilterOptionsFilter[]) => {
    const previousApps = prevSelectedApps.current;

    const [addedApp] = differenceBy(selectedApps, previousApps, app => `${app.id}-${app.isSelected}`);
    const [removedApp] = differenceBy(previousApps, selectedApps, app => `${app.id}-${app.isSelected}`);

    return addedApp || removedApp;
  };

  const handleAppsFilterChange = useCallback(
    (apps: IFilterOptionsFilter[]) => {
      const selectedApps = apps.filter(({ isSelected }) => isSelected);
      const selectedAppsIds = selectedApps.map(({ id }) => id);
      const selectedApp = getSelectedAppFromFilter(selectedApps);

      prevSelectedApps.current = selectedApps;

      appModel.analyticsService.track(AnalyticsActivity.myWorkTabFiltered, {
        origin: 'outputs',
        option: 'app',
        app_id: selectedApp?.id,
        app_name: selectedApp?.name,
      });

      onFilterChange?.({
        templateIds: selectedApps.length ? selectedAppsIds : [],
      });
    },
    [onFilterChange, appModel.analyticsService],
  );

  const tableHeaders = useMemo(() => {
    // Puts selected apps at the top of the filter list, and others below
    const filteredApps = [
      ...model.applications
        .map(app => ({
          id: app.id,
          name: app.name,
          isSelected: selectedTemplateIds.includes(app.id),
        }))
        .sort((a, b) => {
          if (a.isSelected && !b.isSelected) {
            return -1;
          }

          if (!a.isSelected && b.isSelected) {
            return 1;
          }

          return sortByNameProperty(a, b);
        }),
    ];

    return generateTableHeaders({
      model,
      filteredApps,
      handleAppsFilterChange,
    });
  }, [model, handleAppsFilterChange, selectedTemplateIds]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: hasMore,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 100px 0px',
  });

  return (
    <div className={styles.container}>
      <div className={styles.scrollableContainer} ref={rootRef} id={OUTPUTS_TABLE_ID}>
        <BaseTable
          headers={tableHeaders}
          data={tableData}
          loading={isLoading}
          headerRowClassName={styles.headerRow}
          bodyRowClassName={styles.bodyRow}
          gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
          customLoading={<TableSkeleton rowNumber={15} columnNumber={3} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />}
          customEmpty={<Empty model={model} />}
          emptyContainerClassName={styles.emptyContainer}
          isEnableSticky={false}
          onRowClick={(row: IRowProps<TGetDraftsResponse>) => onRowClick(row)}
        />
        {model.hasMore && (
          <div className={styles.loaderWrapper} ref={sentryRef}>
            <TableSkeleton rowNumber={3} columnNumber={3} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />
          </div>
        )}
      </div>
    </div>
  );
});
