import React from 'react';
import { observer } from 'mobx-react-lite';
import { EditorEditHyperlinkPicker, EditorHyperLinkPicker } from '@writercolab/ui-molecules';
import type { EditorToolbarModel } from './UIEditorToolbarModel';

interface EditorToolbarPickersProps {
  model: EditorToolbarModel;
  element: HTMLElement;
}

const EditorToolbarPickers: React.FC<EditorToolbarPickersProps> = observer(({ model, element }) => {
  if (!model.selectionRects) {
    return null;
  }

  return (
    <>
      <EditorHyperLinkPicker
        isOpen={model.showLinkModal}
        selectionRects={model.selectionRects}
        onApplyClick={model.onApplyLinkFormat}
        onClose={model.onCloseLinkFormat}
        elementBoundary={element}
      />
      <EditorEditHyperlinkPicker
        onClose={model.onCloseLinkEditFormat}
        isOpen={model.showPreviewLinkModal}
        selectionRects={model.selectionRects}
        elementBoundary={element}
        sourceText={model.selectionLinkSource}
        anchorText={model.selectionLinkAnchor}
        onClearClick={model.onClickRemoveLink}
        onOpenClick={model.onClickOpenLink}
        onApply={model.onLinkChangeApply}
      />
    </>
  );
});

export default EditorToolbarPickers;
