import React, { useCallback, useMemo, useState } from 'react';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { DropdownValuesConfig, ListDropdownOption } from '@writercolab/ui-molecules';

import isEqual from 'lodash/isEqual';

import SuggestionOptionSelect from '../SuggestionOptionSelect';
import SuggestionSimpleRule from './SuggestionSimpleRule';

import styles from './styles.module.css';

interface ISuggestionRuleProps {
  className?: string;
  readOnly?: boolean;
  defaultValue: string;
  valuesConfig: Record<string, DropdownValuesConfig>;
  template: string;
  currentValue?: string;
  dropdownOptions: string[][];
  onUpdateRuleValue: (newValue?: string, oldValue?: string) => void;
}

const SuggestionRule: React.FC<ISuggestionRuleProps> = ({
  className,
  valuesConfig,
  template,
  dropdownOptions,
  currentValue,
  readOnly,
  defaultValue,
  onUpdateRuleValue,
}) => {
  const [valueIsWrong, setValueIsWrong] = useState(false);

  const onCheckboxClick = () => {
    const isChecked = !!currentValue;

    if (isChecked) {
      onUpdateRuleValue(currentValue ? undefined : currentValue, currentValue);
    } else {
      onUpdateDropdownValue(-1, '');
    }
  };

  const currentValueConfig = useMemo(
    () => valuesConfig[currentValue || defaultValue],
    [valuesConfig, currentValue, defaultValue],
  );

  const listDropdownOptions = useMemo(
    () =>
      dropdownOptions.map((options, index) =>
        options.map(
          option =>
            ({
              id: option,
              name: option,
              active: currentValueConfig?.selected[index] === option,
            }) as ListDropdownOption,
        ),
      ),
    [currentValueConfig, dropdownOptions],
  );

  const onUpdateDropdownValue = useCallback(
    (updatedIndex: number, value: string) => {
      const selected = listDropdownOptions.map((options, index) => {
        if (updatedIndex === index) {
          return value;
        }

        return options.find(option => option.active)?.name;
      });

      const newValue = Object.keys(valuesConfig).find(value => isEqual(valuesConfig[value].selected, selected));

      setValueIsWrong(!newValue);

      if (newValue) {
        onUpdateRuleValue(newValue, currentValue);
      }
    },
    [listDropdownOptions, currentValue, valuesConfig, onUpdateRuleValue],
  );

  const children = useMemo(() => {
    const chunks = template.split(/{\d}/g);
    const selectedValue = listDropdownOptions.map(options => options.find(option => option.active)?.name);
    const unSelectedValue = listDropdownOptions.map(options => options.find(option => !option.active)?.name);

    let dropdownIndex = 0;
    // eslint-disable-next-line no-undef
    const result = [] as JSX.Element[];

    for (let i = 0; i < chunks.length; i += 1) {
      let text = chunks[i];

      if (text.includes('{option}')) {
        text = text.replace('{option}', String(selectedValue));
      }

      if (text.includes('{unSelectedOption}')) {
        text = text.replace('{unSelectedOption}', String(unSelectedValue));
      }

      result.push(<>{text}</>);

      if (i !== chunks.length - 1) {
        result.push(
          <span onClick={e => e.preventDefault()}>
            <SuggestionOptionSelect
              index={dropdownIndex}
              options={listDropdownOptions[dropdownIndex]}
              onUpdateDropdownValue={onUpdateDropdownValue}
            />
          </span>,
        );
        dropdownIndex += 1;
      }
    }

    return result;
  }, [template, listDropdownOptions, onUpdateDropdownValue]);

  return (
    <SuggestionSimpleRule
      className={className}
      example={currentValueConfig?.example}
      exampleLabel={currentValueConfig?.exampleLabel}
      isChecked={!!currentValue}
      readOnly={readOnly}
      onUpdateRuleValue={onCheckboxClick}
    >
      {children}
      {valueIsWrong && (
        <div className={styles.ruleError}>
          <Text variant={TextSize.L} color={TextColor.ORANGE}>
            This combo is not supported. Please update your selection.
          </Text>
        </div>
      )}
    </SuggestionSimpleRule>
  );
};

export default SuggestionRule;
