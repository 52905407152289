import React, { useEffect, useRef } from 'react';

import cx from 'classnames';

import {
  CONTACT_SALES_LINK,
  ContentEditorPageMode,
  ContentEditorQueryParams,
  MediaFileTabs,
  TAssetToDelete,
  wordPluralize,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  DataRetentionPolicyBanner,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Modal,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputGroup, InputTypes, TemplateGuide, UtilIcon } from '@writercolab/ui-molecules';

import { DraftFeedback, useInView, useScrollIntoView } from '@web/component-library';
import {
  EApplicationType,
  contentGenerationJobInputFields,
  recapsRecordingType,
  recapsRecordingTypeDescription,
  recapsRecordingTypeTitle,
} from '@web/types';
import { useDataRetentionBanner } from 'hooks/useDataRetentionBanner';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { AnalyticsActivity, MODE_NAME_MAP } from '../../../constants/analytics';
import { RecapsModalType, useRecapsContext } from '../../../context/recapsContext';
import useQuery from '../../../hooks/useQuery';
import { useAppState } from '../../../state';
import { openContactSalesPage } from '../../../utils/navigationUtils';
import TemplateQuotaBanner, { QUOTA_BANNER_TYPE } from '../../molecules/TemplateQuotaBanner/TemplateQuotaBanner';
import TranscriptLimit from '../../molecules/TranscriptLimit';
import EditDraftPanel from '../EditDraftPanel';
import { RecapsFileUploader } from '../RecapsFileUploader';
import { DraftCard } from '../Template/DraftCard';

import styles from './RecapsPanel.module.css';

export const RecapsPanel = observer(({ onGenerateRecaps }: { onGenerateRecaps?: () => Promise<void> }) => {
  const { uploadModel, context, modalsManager, onClose, onShowTemplates, onGenerate, onChangeRecordingType, drafts } =
    useRecapsContext();
  const {
    appModel: { assistantSubscription: subscriptionModel, dataRetentionModel, analyticsService },
  } = useAppState();
  const query = useQuery();

  const { shouldShowPolicyBanner, ...bannerProps } = useDataRetentionBanner({
    asset: TAssetToDelete.enum.UploadedFile,
  });

  const draftsContainerRef = useRef(null);
  const scrollDraftsIntoView = useScrollIntoView(draftsContainerRef, { block: 'end', inline: 'nearest' });
  const isDraftsContainerInView = useInView(draftsContainerRef, {
    threshold: 0.2,
  });

  const handleScrollDraftsIntoView = () => scrollDraftsIntoView();

  const recordingTypeDropdownOptions = Object.keys(recapsRecordingType).map(key => ({
    id: recapsRecordingType[key],
    name: recapsRecordingTypeTitle[key],
    description: recapsRecordingTypeDescription[key],
  }));

  const handleGenerate = async () => {
    analyticsService.track(AnalyticsActivity.appGenerated, {
      app_id: query.get(ContentEditorQueryParams.currentTemplateId) ?? '',
      app_name: MODE_NAME_MAP[query.get(ContentEditorQueryParams.mode) as ContentEditorPageMode] ?? '',
      app_type: EApplicationType.enum.generation,
      built_by_writer: true,
    });
    await onGenerateRecaps?.();
    onGenerate();
  };

  useEffect(() => {
    if (drafts.list.find(d => d.id === drafts.highlightedDraftId)) {
      scrollDraftsIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafts.list, drafts.highlightedDraftId]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Icon className={styles.headerIcon} name={IconVariant.MICROPHONE} width={36} height={36} />
        <div className={styles.headerAllTemplates} onClick={onShowTemplates}>
          <Text caps className={styles.headerAllTemplatesLabel}>
            All apps
          </Text>
          <UtilIcon variant={IconVariant.ALL_SETTINGS} circle tooltipContent="App library" />
        </div>
        {onClose && (
          <div className={styles.headerClose}>
            <UtilIcon onClick={onClose} variant={IconVariant.CLOSE} circle tooltipContent="Close" />
          </div>
        )}
      </div>

      <Heading variant={HeadingVariant.H2} bold className={styles.heading}>
        Recaps
      </Heading>
      <TemplateGuide
        url="https://support.writer.com/article/203-cowrite-template-guide-event-takeaways"
        description="Upload a recording, and we'll summarize and surface important takeaways."
      />
      {context.isCowriteLimitReached && (
        <TemplateQuotaBanner
          className={styles.quotaBanner}
          wordsLimit={subscriptionModel.limits?.coWrite?.limit || 0}
          type={QUOTA_BANNER_TYPE.TEMPLATE}
          onContactSales={openContactSalesPage}
        />
      )}

      <Heading className={styles.label} variant={HeadingVariant.H6}>
        Media
      </Heading>

      {shouldShowPolicyBanner && uploadModel.activeTab === MediaFileTabs.enum.SELECT_EXISTING && (
        <DataRetentionPolicyBanner className={styles.banner} {...bannerProps} />
      )}
      {!context.isCowriteLimitReached && context.isTranscriptLimitReached ? (
        <TranscriptLimit
          className={styles.rateLimit}
          url={CONTACT_SALES_LINK}
          isEnterprise={subscriptionModel.isEnterprise}
          resetDate={context.transcriptLimitResetDate}
          isVisible={context.isTranscriptLimitReached}
        />
      ) : (
        <Text className={styles.subLabel} variant={TextSize.XL}>
          Select a recording
        </Text>
      )}
      {!uploadModel.uploadedFile && context.hasError && (
        <Text className={styles.mediaFileManagerError} medium variant={TextSize.XS}>
          Media is required
        </Text>
      )}

      <RecapsFileUploader model={uploadModel} isError={context.hasError} />

      <Heading className={styles.label} variant={HeadingVariant.H6}>
        Recording type
      </Heading>
      <InputGroup
        id={contentGenerationJobInputFields.RECORDING_TYPE}
        name={contentGenerationJobInputFields.RECORDING_TYPE}
        className={styles.inputSelect}
        inputType={InputTypes.SELECT}
        dropdownOptions={recordingTypeDropdownOptions}
        value={recordingTypeDropdownOptions.find(option => option.id === context.recordingType)!.name}
        label=""
        handleChangeInput={onChangeRecordingType}
        disableHelperText
      />

      <div className={styles.drafts} ref={draftsContainerRef}>
        {!isEmpty(drafts.list) && (
          <>
            <Heading className={styles.draftsHeading} variant={HeadingVariant.H2} medium>
              Outputs <span className={styles.draftsCount}>{drafts.list.length}</span>
            </Heading>
            {drafts.list.map(draft => (
              <DraftCard
                key={`template-draft-${draft.id}`}
                draft={draft}
                isHighlighted={draft.id === drafts.highlightedDraftId}
                highlightTooltipText="Your Recap is ready!"
                onDraftRate={() => modalsManager.showModal(RecapsModalType.enum.rateDraft, draft)}
                onDraftDelete={drafts.deleteDraft}
                onDraftExpand={drafts.expandDraft}
                onDraftExport={drafts.exportDraft}
                onDraftCopy={() => drafts.copyDraft(draft)}
                onGoToDraft={drafts.goToDraft}
              />
            ))}
          </>
        )}
      </div>

      <Modal
        open={modalsManager.isModalVisible(RecapsModalType.enum.rateDraft)}
        className={styles.rateDraftModal}
        handleClose={() => modalsManager.hideModal()}
      >
        <DraftFeedback
          onClose={() => modalsManager.hideModal()}
          onSubmit={input => drafts.rateDraft(input.rate, input.feedback)}
        />
      </Modal>

      <footer className={styles.footer}>
        {!isEmpty(drafts.list) && !isDraftsContainerInView && (
          <div className={styles.draftsScrollTo} onClick={handleScrollDraftsIntoView}>
            <span className={styles.draftsScrollToIconWrap}>
              <Icon name={IconVariant.ARROW_DOWN_ALT} />
            </span>
            {drafts.list.length} {wordPluralize(drafts.list.length, 'Draft', 's')}
          </div>
        )}
        <Button
          onClick={handleGenerate}
          className={cx(styles.submitButton, {
            [styles.submitButtonLoading]: context.isGeneratingContent,
          })}
          disabled={context.isCowriteLimitReached}
          type={ButtonTypes.PRIMARY}
          content={`${context.isGeneratingContent ? 'Generating' : 'Generate'} Recaps`}
        />
      </footer>

      <Modal open={drafts.isExpandedDraft} handleClose={drafts.closeExpandedDraft} className={styles.isDraftPreviews}>
        <EditDraftPanel isPreviewMode dataRetentionPreferences={dataRetentionModel?.retentionPreferences} />
      </Modal>
    </div>
  );
});

export default RecapsPanel;
