import { type IDocumentShareStatusPayload } from '@writercolab/common-utils';
import { PaginatedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, operations } from '@writercolab/network';

import type { TOrganizationDocumentsExtraArgs, TOrganizationsDocument, TOrganizationsDocuments } from '@web/types';
import {
  DEFAULT_PAGE_SIZE,
  TApiRequestSortDirection,
  TOrganizationsDocumentsScope,
  TOrganizationsDocumentsSort,
} from '@web/types';

import { getLogger } from '../utils/logger';

const LOG = getLogger('ConsoleApplicationsApi');

interface IOrganizationDocumentsApiOpts {
  organizationId: number;
  teamId: number;
  request: RequestServiceInitialize['api'];
}

export class OrganizationDocumentsApi {
  // eslint-disable-next-line no-useless-constructor, no-empty-function
  constructor(private readonly opts: IOrganizationDocumentsApiOpts) {}

  pagination: PaginatedModel<
    TOrganizationsDocuments | null,
    TOrganizationsDocument,
    { limit: number; offset: number },
    TOrganizationDocumentsExtraArgs
  > = new PaginatedModel<
    TOrganizationsDocuments | null,
    TOrganizationsDocument,
    { limit: number; offset: number },
    {
      search?: string;
      sortField?: typeof TOrganizationsDocumentsSort.type;
      sortOrder?: typeof TApiRequestSortDirection.type;
      scope?: typeof TOrganizationsDocumentsScope.type;
      offset?: number;
      limit?: number;
    }
  >({
    argsExtra: {
      search: undefined,
      sortField: TOrganizationsDocumentsSort.enum.openedByMeTime,
      sortOrder: TApiRequestSortDirection.enum.desc,
      scope: TOrganizationsDocumentsScope.enum.all,
    },
    extractMeta: obj => {
      const offset = (obj?.pagination.offset ?? 0) + (obj?.result?.length ?? 0);

      if (obj && offset >= obj.totalCount) {
        return this.pagination.args;
      }

      return {
        offset,
        limit: DEFAULT_PAGE_SIZE,
      };
    },
    extract: obj => obj?.result ?? [],
    argsDefault: {
      offset: 0,
      limit: DEFAULT_PAGE_SIZE,
    },
    load: async ({ args: { limit, offset }, extra: { search, sortField, sortOrder, scope } }) => {
      const query: Record<string, unknown> = {
        limit,
        offset,
        search,
        sortField: sortField ?? TOrganizationsDocumentsSort.enum.openedByMeTime,
        sortOrder: sortOrder ?? TApiRequestSortDirection.enum.desc,
        scope: scope ?? TOrganizationsDocumentsScope.enum.all,
      };

      let documents: TOrganizationsDocuments | null = null;

      try {
        const data = await this.getDocuments(query);

        documents = data;
      } catch (e) {
        LOG.error('Error loading documents', e);
      }

      return documents;
    },
  });

  getDocuments = async (query: operations['findDocumentInTeam']['parameters']['query']) => {
    const { data } = await this.opts.request.get('/api/organization/v2/{organizationId}/team/{teamId}/document', {
      params: {
        path: {
          organizationId: this.opts.organizationId,
          teamId: this.opts.teamId,
        },
        query,
      },
    });

    return data;
  };

  createDocument = async (organizationId: number, teamId: number, documentTitle: string) => {
    try {
      const { data } = await this.opts.request.post('/api/organization/v2/{organizationId}/team/{teamId}/document', {
        params: {
          path: {
            organizationId,
            teamId,
          },
        },
        body: { title: documentTitle },
      });

      return data;
    } catch (e) {
      LOG.error('Error creating document', e);

      return null;
    }
  };

  deleteDocument = async (documentId: number) => {
    try {
      await this.opts.request.delete('/api/organization/v2/{organizationId}/team/{teamId}/document/{documentId}', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            teamId: this.opts.teamId,
            documentId,
          },
        },
      });
    } catch (e) {
      LOG.error('Error deleting document', e);
    }
  };

  getDocumentShareStatus = async (documentId: number) => {
    try {
      const { data } = await this.opts.request.get(
        '/api/organization/v2/{organizationId}/team/{teamId}/document/{documentId}/sharing',
        {
          params: {
            path: {
              organizationId: Number(this.opts.organizationId),
              teamId: Number(this.opts.teamId),
              documentId,
            },
          },
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to get document share status', e);
      throw e;
    }
  };

  updateDocumentShareStatus = async (documentId: number, shareStatus: IDocumentShareStatusPayload) => {
    try {
      const { data } = await this.opts.request.put(
        '/api/organization/v2/{organizationId}/team/{teamId}/document/{documentId}/sharing',
        {
          params: {
            path: {
              organizationId: Number(this.opts.organizationId),
              teamId: Number(this.opts.teamId),
              documentId,
            },
          },
          body: shareStatus,
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to uodate document share status', e);
      throw e;
    }
  };

  getDocumentContent = async (documentId: string) => {
    try {
      const { data } = await this.opts.request.get(
        '/api/content/organization/{organizationId}/team/{teamId}/document/{documentId}/data',
        {
          params: {
            path: {
              organizationId: Number(this.opts.organizationId),
              teamId: Number(this.opts.teamId),
              documentId,
            },
          },
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to get document content', e);
      throw e;
    }
  };
}
