import React, { useCallback, useState } from 'react';

import cx from 'classnames';

import { BaseComponentsProps, isValidUrlOrDomain } from '@writercolab/common-utils';
import { Button, ButtonTypes, Text } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import { getLogger } from '../../../utils/logger';

import styles from './styles.module.css';

const LOG = getLogger('AddDomainsContent');

interface IAddDomainsSlideoutProps extends BaseComponentsProps {
  handleAddWebsite: (websiteUrl: string) => Promise<void>;
}

export const AddDomainsContent: React.FC<IAddDomainsSlideoutProps> = ({ className, handleAddWebsite }) => {
  const [value, setValue] = useState('');
  const [website, setWebsite] = useState('https://');
  const [isValid, setIsValid] = useState<null | boolean>(null);
  const [isLoading, setIsLoading] = useState(false);

  const _onChange = (e: any) => {
    setValue(e.target.value);

    if (isValidUrlOrDomain(e.target.value)) {
      setWebsite(e.target.value);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const _onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await handleAddWebsite(website);
    } catch (err) {
      LOG.error(err);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [website]);

  return (
    <div className={cx(className, styles.addDomainsWrapper)}>
      <Text className={styles.slideHeader}>Add a new website</Text>
      <div className={styles.addDomainsFormContainer}>
        <InputGroup
          id="website"
          name="website"
          label="website"
          inputType={InputTypes.INPUT}
          value={value}
          handleChangeInput={_onChange}
          errorText={isValid === false ? 'Invalid Url' : ''}
          disableHelperText
          placeholder="https://"
          autoFocus
        />
        <div className={styles.addDomainsSubmitContainer}>
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            isLoading={isLoading}
            content="Add"
            onClick={_onSubmit}
            disabled={!isValid}
          />
        </div>
      </div>
    </div>
  );
};

export default AddDomainsContent;
