import { TKnowledgeGraphUrl } from '@web/types';
import type { RequestServiceInitialize } from '@writercolab/network';

export interface KnowledgeGraphWebConnectorApiModelOptions {
  request: RequestServiceInitialize['api'];
}

export class KnowledgeGraphWebConnectorApiModel {
  constructor(private opts: KnowledgeGraphWebConnectorApiModelOptions) {}

  getUrls = async (organizationId: number, knowledgeGraphId: string) => {
    const { data } = await this.opts.request.get(
      '/api/media/organization/{organizationId}/graph/{knowledgeGraphId}/web-connector',
      {
        params: {
          path: {
            organizationId,
            knowledgeGraphId,
          },
        },
      },
    );

    return data;
  };

  addUrl = async (
    organizationId: number,
    knowledgeGraphId: string,
    type: typeof TKnowledgeGraphUrl.type,
    urls: string[],
  ) => {
    const { data } = await this.opts.request.post(
      '/api/media/organization/{organizationId}/graph/{knowledgeGraphId}/web-connector/url',
      {
        params: {
          path: {
            organizationId,
            knowledgeGraphId,
          },
        },
        body: urls.map(url => ({
          type,
          url,
          excludeUrls: [],
        })) as [any, ...any[]],
      },
    );

    return data;
  };

  deleteUrl = async (organizationId: number, knowledgeGraphId: string, urlId: string) => {
    const { data } = await this.opts.request.delete(
      '/api/media/organization/{organizationId}/graph/{knowledgeGraphId}/web-connector/url/{urlId}',
      {
        params: {
          path: {
            organizationId,
            knowledgeGraphId,
            urlId,
          },
        },
      },
    );

    return data;
  };

  updateUrl = async ({
    organizationId,
    knowledgeGraphId,
    urlId,
    type,
    url,
    excludeUrls,
  }: {
    organizationId: number;
    knowledgeGraphId: string;
    urlId: string;
    type: typeof TKnowledgeGraphUrl.type;
    url: string;
    excludeUrls: string[];
  }) => {
    const { data } = await this.opts.request.put(
      '/api/media/organization/{organizationId}/graph/{knowledgeGraphId}/web-connector/url/{urlId}',
      {
        params: {
          path: {
            organizationId,
            knowledgeGraphId,
            urlId,
          },
        },
        body: {
          type,
          url,
          excludeUrls,
        },
      },
    );

    return data;
  };
}
