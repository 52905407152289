import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
import { liteClient as algoliaSearch } from 'algoliasearch/lite';
import {
  Configure,
  connectRefinementList,
  connectSearchBox,
  connectStateResults,
  connectInfiniteHits,
  InstantSearch,
} from 'react-instantsearch-dom';

import { CloseIcon } from '@writercolab/ui-molecules';
import {
  Heading,
  HeadingVariant,
  SearchBar,
  NavTabs,
  Text,
  TextColor,
  TextSize,
  Icon,
  IconVariant,
  Button,
  ButtonTypes,
  DotLoader,
} from '@writercolab/ui-atoms';

import type { ResultCounts } from '@web/types';
import { HitType } from '@web/types';
import styles from './styles.module.css';
import SearchHit from './SearchHit';
import { useHandleOutsideMouseDown } from '../../../hooks/useHandleOutsideMouseDown';

/*
 * Custom refinement list navigation using NavTabs
 * https://www.algolia.com/doc/api-reference/widgets/refinement-list/react
 * */
const SearchTabs = connectRefinementList(({ refine, items }) => {
  const results: ResultCounts = items.reduce(
    (acc, { label, count }) => ({
      ...acc,
      [label]: count,
      total: acc.total + count,
    }),
    {
      total: 0,
      [HitType.HTML]: 0,
      [HitType.TERM]: 0,
      [HitType.SNIPPET]: 0,
    },
  );

  const tabs = useMemo(
    () => [
      { id: 'all', title: 'All', value: '*', count: results.total },
      {
        id: 'pages',
        title: 'Pages',
        value: HitType.HTML,
        count: results[HitType.HTML],
        disabled: results[HitType.HTML] === 0,
      },
      {
        id: 'terms',
        title: 'Terms',
        value: HitType.TERM,
        count: results[HitType.TERM],
        disabled: results[HitType.TERM] === 0,
      },
      {
        id: 'snippets',
        title: 'Snippets',
        value: HitType.SNIPPET,
        count: results[HitType.SNIPPET],
        disabled: results[HitType.SNIPPET] === 0,
      },
    ],
    [results],
  );

   
  return <NavTabs onChange={tab => refine([(tab as any).value])} tabs={tabs} />;
});

const SearchHits = connectInfiniteHits(({ hits, refineNext, hasMore }) => {
  const sentinelRef = useRef(null);

  const handleSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  useEffect(() => {
    let observer: IntersectionObserver;

    if (sentinelRef.current) {
      observer = new IntersectionObserver(handleSentinelIntersection);
      observer.observe(sentinelRef.current);
    }

    return () => observer?.disconnect();
  });

  return (
    <div className="ais-InfiniteHits">
      <ul className="ais-InfiniteHits-list">
        {hits.map(hit => (
          <li key={hit.objectID} className="ais-InfiniteHits-item">
            <SearchHit hit={hit} />
          </li>
        ))}

        {hasMore && (
          <li className={cx('.ais-InfiniteHits-sentinel', styles.hitsSentinel)} ref={sentinelRef}>
            <DotLoader />
          </li>
        )}
      </ul>
    </div>
  );
});

/*
 * Custom Search Results
 * https://www.algolia.com/doc/api-reference/widgets/state-results/react
 * */
const EmptySearchMessage = ({ children }: { children: ReactNode }) => (
  <div className={styles.emptyState}>
    <Text variant={TextSize.XXXXL} color={TextColor.GREY2} bold>
      {children}
    </Text>
  </div>
);

const SearchResults = connectStateResults(({ searchState, searchResults }) => {
  if (!searchState?.query) {
    return <EmptySearchMessage>Start typing to search</EmptySearchMessage>;
  }

  if (searchResults?.nbHits === 0) {
    return <EmptySearchMessage>No results found for "{searchState.query}"</EmptySearchMessage>;
  }

  return (
    <>
      <section className={styles.tabs}>
        <SearchTabs attribute="type" />
      </section>

      <section className={styles.hits}>
        <SearchHits />
      </section>
    </>
  );
});

/*
 * Custom SearchBox
 * https://www.algolia.com/doc/api-reference/widgets/search-box/react/#connector
 * */
const SearchBox = connectSearchBox(({ currentRefinement, refine }) => {
  const searchRef: React.MutableRefObject<HTMLInputElement | null> = useRef(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    refine(event.currentTarget.value);

  const handleClearInput = () => {
    refine('');

    if (searchRef && searchRef.current) {
      searchRef?.current.focus();
    }
  };

  return (
    <div className={styles.searchBox}>
      <SearchBar
        ref={searchRef}
        autoFocus
        value={currentRefinement}
        onChange={handleChange}
        handleClearInput={handleClearInput}
      />
    </div>
  );
});

export interface GlobalSearchRenderProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export interface GlobalSearchConfig {
  appId: string;
  apiKey: string;
  indexName: string;
  attributesToSnippet?: string[];
  attributesToHighlight?: string[];
}

export interface GlobalSearchProps {
  title: string;
  config: GlobalSearchConfig;
  children?: (props: GlobalSearchRenderProps) => JSX.Element;
  className?: string;
}

/*
 * GlobalSearch component using Algolia API
 * https://www.algolia.com/doc/
 * https://www.algolia.com/doc/api-reference/widgets/instantsearch/react/
 * */
export function GlobalSearch({ title, children, className, config }: GlobalSearchProps) {
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { appId, apiKey, indexName, attributesToHighlight, attributesToSnippet } = config;
  const searchClient = useMemo(() => algoliaSearch(appId, apiKey), [appId, apiKey]);

  useHandleOutsideMouseDown(searchContainerRef, () => {
    setIsOpen(false);
  });

  return (
    <>
      {isOpen && (
        <div ref={searchContainerRef} className={cx(styles.container, className)}>
          <InstantSearch indexName={indexName} searchClient={searchClient}>
            <Configure
              snippetEllipsisText="..."
              attributesToHighlight={attributesToHighlight}
              attributesToSnippet={attributesToSnippet}
            />

            <header className={styles.header}>
              <Heading variant={HeadingVariant.H2}>{title}</Heading>
              <SearchBox />

              <div className={styles.closeButton}>
                <CloseIcon onClick={() => setIsOpen(false)} iconSize={14} containerSize={20} />
              </div>
            </header>

            <main className={styles.main}>
              <SearchResults />
            </main>
          </InstantSearch>
        </div>
      )}

      {children ? (
        children({ isOpen, setIsOpen })
      ) : (
        <Button className={styles.searchButton} type={ButtonTypes.TRANSPARENT} onClick={() => setIsOpen(true)}>
          Search
          <Icon name={IconVariant.SEARCH} />
        </Button>
      )}
    </>
  );
}

export default GlobalSearch;
