import type {
  DocsRequestTypes,
  ICreateDocument,
  IDocument,
  IDocumentShareStatus,
  IDocumentShareStatusPayload,
  RequestQueryParams,
} from '@writercolab/common-utils';
import { Request } from '@writercolab/common-utils';

import type { ListResponse } from './_types';

export const getDocumentsList = async (
  orgId: number | undefined,
  teamId: number | undefined,
  type: DocsRequestTypes,
  params?: RequestQueryParams,
): Promise<ListResponse<IDocument>> => {
  let requestUrl;

  if (teamId) {
    requestUrl = `/organization/v2/${orgId}/team/${teamId}/document`;
  } else {
    requestUrl = `/organization/v2/${orgId}/document`;
  }

  const { data } = await Request.getAxiosInstance().get(requestUrl, {
    params: {
      ...params,
      ...{
        scope: type,
      },
    },
  });

  return data;
};

export const getDocumentById = async (
  orgId: number | undefined,
  teamId: number | undefined,
  docId: number | undefined,
): Promise<IDocument> => {
  const { data } = await Request.getAxiosInstance().get(`/organization/v2/${orgId}/team/${teamId}/document/${docId}`);

  return data || {};
};

export const getDocumentShareStatus = async (
  orgId: number | undefined,
  teamId: number | undefined,
  docId: number | undefined,
): Promise<IDocumentShareStatus> => {
  const { data } = await Request.getAxiosInstance().get(
    `/organization/v2/${orgId}/team/${teamId}/document/${docId}/sharing`,
  );

  return data || {};
};

export const updateDocumentShareStatus = async (
  orgId: number | undefined,
  teamId: number | undefined,
  documentId: number | undefined,
  shareStatus: IDocumentShareStatusPayload,
): Promise<IDocumentShareStatus> => {
  const { data } = await Request.getAxiosInstance().put(
    `/organization/v2/${orgId}/team/${teamId}/document/${documentId}/sharing`,
    shareStatus,
  );

  return data;
};

export const createDocument = async (
  orgId: number | undefined,
  teamId: number,
  documentTitle: string,
): Promise<ICreateDocument> => {
  const { data } = await Request.getAxiosInstance().post(`/organization/v2/${orgId}/team/${teamId}/document`, {
    title: documentTitle,
  });

  return data || [];
};

export const updateDocument = async (
  orgId: number | undefined,
  teamId: number | undefined,
  documentId: number | undefined,
  updatedObject: any,
): Promise<IDocument> => {
  const { data } = await Request.getAxiosInstance().put(
    `/organization/v2/${orgId}/team/${teamId}/document/${documentId}`,
    updatedObject,
  );

  return data as IDocument;
};

export const deleteDocument = async (
  orgId: number | undefined,
  teamId: number | undefined,
  documentId: number | undefined,
): Promise<IDocument[]> => {
  const { data } = await Request.getAxiosInstance().delete(
    `/organization/v2/${orgId}/team/${teamId}/document/${documentId}`,
  );

  return data.result || [];
};
