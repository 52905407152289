import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';

import type { IUserProfile } from '@writercolab/common-utils';
import {
  UserAuthType,
  validateEmail,
  validateName,
  validateNotEmpty,
  validatePassword,
} from '@writercolab/common-utils';
import { MfaChallengeType } from '@writercolab/types';
import {
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Label,
  MuiFormControl,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';
import { FilterOptions, InputGroup, InputTypes, MfaVerificationModal } from '@writercolab/ui-molecules';

import { Trigger } from '../UserActivityReportPage/components/Trigger/Trigger';

import { useScrollIntoView } from '@web/component-library';
import { DEFAULT_TEXT_PLACEHOLDER, UserSettingDepartment } from '@web/types';
import { isCustomDepartment } from 'constants/Profile';
import { AnalyticsActivity } from 'constants/analytics';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { ChallengeStep, PasswordChallengeType, useProfileContext } from '../../../context/profileContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { getLogger } from '../../../utils/logger';
import { formatQuestionerCustomInputValue } from '../../../utils/questionnaireUtils';
import { validatePhoneNumber } from '../../../utils/validateUtils';
import UserDepartmentDropdown from '../../molecules/UserDepartmentDropdown/UserDepartmentDropdown';
import { VerifyPasswordModal } from '../../molecules/VerifyPasswordModal';
import { DeleteOrganization } from '../../organisms/DeleteOrganization';
import { MfaChallengesList } from '../../organisms/MfaChallengesList';
import {
  ActionButtonsGroup,
  ConfirmPasswordDescription,
  GoogleAndPasswordPlaceholder,
  PasswordLockHeader,
  SamlPlaceholder,
  SocialPlaceholder,
  SsoAndPasswordPlaceholder,
  ValidFieldIndicator,
} from './parts';
import { Avatar } from './parts/Avatar';
import { CreatePasswordButton } from './parts/CreatePasswordButton';
import { DeviceManagementSection } from './parts/DeviceManagementSection';
import { ResetPasswordButton } from './parts/ResetPasswordButton';

import styles from './styles.module.css';

enum UserProfileFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
  PHONE = 'phone',
}

interface IValidationIssue {
  valid: boolean | null;
  message?: string | null;
}

interface IUserProfileValidation {
  [UserProfileFields.FIRST_NAME]: IValidationIssue | null;
  [UserProfileFields.LAST_NAME]: IValidationIssue | null;
  [UserProfileFields.EMAIL]: IValidationIssue | null;
  [UserProfileFields.NEW_PASSWORD]: IValidationIssue | null;
  [UserProfileFields.CONFIRM_PASSWORD]: IValidationIssue | null;
  [UserProfileFields.PHONE]: IValidationIssue | null;
}

type UserProfileValidationFields = {
  [k in UserProfileFields]?: IValidationIssue | null;
};

const LOG = getLogger('ProfilePage');

export const ProfilePage: React.FC = observer(() => {
  usePageTitle('Profile');

  const {
    appState: { organization },
    appModel: { assistantSubscription: subscriptionModel, user: appStateUserProfile, setUser, analyticsService },
  } = useAppState();

  const phoneInputRef = useRef<HTMLInputElement>(null);
  const scrollToPhoneInput = useScrollIntoView(phoneInputRef, { block: 'center' });

  const [profileValidation, setProfileValidation] = useState<IUserProfileValidation>({
    [UserProfileFields.FIRST_NAME]: { valid: null },
    [UserProfileFields.LAST_NAME]: { valid: null },
    [UserProfileFields.EMAIL]: { valid: null },
    [UserProfileFields.NEW_PASSWORD]: { valid: null },
    [UserProfileFields.CONFIRM_PASSWORD]: { valid: null },
    [UserProfileFields.PHONE]: { valid: null },
  });

  const [canSaveProfile, setCanSaveProfile] = useState(false);
  const [canSavePassword, setCanSavePassword] = useState(false);
  const [canSaveEmail, setCanSaveEmail] = useState(false);
  const [canSavePhone, setCanSavePhone] = useState(false);

  const [newPasswordFieldFocused, setNewPasswordFieldFocused] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const fieldIsValid = useCallback(
    (field: UserProfileFields) => profileValidation[field]?.valid === true,
    [profileValidation],
  );

  const inputErrorText = useCallback(
    (field: UserProfileFields): string | undefined =>
      !profileValidation?.[field]?.valid ? profileValidation[field]?.message || '' : undefined,
    [profileValidation],
  );

  const inputValidationIndicator = useCallback(
    (field: UserProfileFields) => (profileValidation[field]?.valid === true ? <ValidFieldIndicator /> : null),
    [profileValidation],
  );

  const setValidationFields = useCallback((fields: Partial<IUserProfileValidation>) => {
    setProfileValidation(prevState => ({
      ...prevState,
      ...fields,
    }));
  }, []);

  const updateNewPassword = useCallback(
    (field: UserProfileFields, value: string) => {
      if (field === UserProfileFields.NEW_PASSWORD) {
        setNewPassword(value);
      } else if (field === UserProfileFields.CONFIRM_PASSWORD) {
        setConfirmPassword(value);
      }

      let valid = false;
      let message = '';

      if (field === UserProfileFields.NEW_PASSWORD) {
        valid = validatePassword(value);
        message = 'password is not secure';
      } else if (field === UserProfileFields.CONFIRM_PASSWORD) {
        valid = validateNotEmpty(value) && newPassword === value;
        message = "password doesn't match";
      }

      setValidationFields({
        [field]: {
          valid,
          message,
        },
      });
    },
    [newPassword, setValidationFields],
  );

  const cancelPasswordEditing = useCallback(() => {
    setNewPassword('');
    setConfirmPassword('');
    setValidationFields({
      [UserProfileFields.NEW_PASSWORD]: { valid: null },
      [UserProfileFields.CONFIRM_PASSWORD]: { valid: null },
    });
  }, [setValidationFields]);

  useEffect(() => {
    const isNewPasswordValid = validatePassword(newPassword);
    const isConfirmPasswordValid = validateNotEmpty(confirmPassword) && newPassword === confirmPassword;
    setCanSavePassword(isNewPasswordValid && isConfirmPasswordValid);
  }, [newPassword, confirmPassword]);

  const {
    initialUser,
    profileSaving,
    passwordSaving,
    emailSaving,
    phoneSaving,
    mfaLoading,
    resetPasswordLoading,
    createPasswordLoading,
    avatarLoading,
    timezones,
    mfaSettings,
    passwordChallengeCallback,
    challengeError,
    challengeStep,
    availableMfaChallenges,
    passwordChallengeType,
    mfaResendSuccess,
    mfaResendCodeBlockReason,
    mfaResendLimitReached,
    userDepartment,
    initialUserDepartment,
    userDepartmentList,
    uploadAvatar,
    requestPasswordCreate,
    requestPasswordReset,
    updateProfile,
    cancelProfileEditing,
    updateEmail,
    cancelEmailEditing,
    updatePhone,
    cancelPhoneEditing,
    updatePassword,
    onCloseMfaModal,
    onResendMfaCode,
    onChangeMfaChallenge,
    onVerifyMfaCode,
    closePasswordModal,
    onReorderChallenges,
    switchMfaSettings,
    onChangeDepartment,
  } = useProfileContext();

  const { allowDirectPasswordLogin, authType, hasSaml, hasPassword } = appStateUserProfile!;
  const isSocialAuth = UserAuthType.enum.Social === authType;
  const isSamlAuth = UserAuthType.enum.Saml === authType && hasSaml;

  const access = useMemo(
    () => ({
      isSocialAuth,
      isSamlAuth,
      hasNoPassword: !hasPassword && allowDirectPasswordLogin,
      mfaFlowDisabledByOrgConfiguration: !allowDirectPasswordLogin && hasSaml,
      editPasswordAvailable: hasPassword && allowDirectPasswordLogin,
      resetPasswordAvailable: hasPassword && !isSamlAuth && allowDirectPasswordLogin,
      showSamlPlaceholder: hasSaml && !allowDirectPasswordLogin,
      showSocialPlaceholder: isSocialAuth && !allowDirectPasswordLogin,
      socialEditPasswordAvailable: allowDirectPasswordLogin && isSocialAuth && hasPassword,
      canDeleteOrg: subscriptionModel.isFree,
      passwordChallengeVisible: challengeStep === ChallengeStep.PASSWORD,
      showMfaModal: challengeStep === ChallengeStep.MFA,
    }),
    [isSocialAuth, isSamlAuth, hasPassword, allowDirectPasswordLogin, hasSaml, subscriptionModel.isFree, challengeStep],
  );

  const mfaSentTo = first(availableMfaChallenges)?.hint;

  const updateUserFormData = useCallback(
    (data: Partial<IUserProfile>) => {
      setUser({
        ...appStateUserProfile,
        ...data,
      });
    },
    [appStateUserProfile, setUser],
  );

  const onUploadAvatar = useCallback(
    async e => {
      const avatar = await uploadAvatar(e.target.files[0]);
      updateUserFormData({ avatar });
    },
    [updateUserFormData, uploadAvatar],
  );

  const onAddPhoneNumber = useCallback(() => {
    scrollToPhoneInput();
    setTimeout(() => phoneInputRef.current?.focus(), 500);
  }, [scrollToPhoneInput]);

  const _cancelPhoneEditing = () => {
    setValidationFields({
      [UserProfileFields.PHONE]: {
        valid: null,
      },
    });
    cancelPhoneEditing();
  };

  useEffect(() => {
    if (appStateUserProfile) {
      analyticsService.track(AnalyticsActivity.profileViewed, {});
    }
  }, [appStateUserProfile, analyticsService]);

  const basicFieldsEdited =
    appStateUserProfile?.firstName !== initialUser?.firstName ||
    appStateUserProfile?.lastName !== initialUser?.lastName ||
    appStateUserProfile?.timezone !== initialUser?.timezone ||
    userDepartment !== initialUserDepartment;
  const emailEdited = appStateUserProfile?.email !== initialUser?.email;
  const phoneNumberEdited = useMemo(
    () => appStateUserProfile?.phone !== initialUser?.phone,
    [initialUser?.phone, appStateUserProfile?.phone],
  );

  const _handleDepartmentChange = useCallback(
    (departmentId: string, customDepartmentValue: boolean | string) => {
      const department = userDepartmentList.find(department => department.id === departmentId)?.name;

      if (department && isCustomDepartment(department)) {
        if (!isEmpty(customDepartmentValue)) {
          onChangeDepartment(formatQuestionerCustomInputValue(customDepartmentValue as string));
        } else {
          onChangeDepartment(UserSettingDepartment.OTHER);
        }
      } else if (department) {
        onChangeDepartment(department);
      }
    },
    [onChangeDepartment, userDepartmentList],
  );

  useEffect(() => {
    if (!appStateUserProfile) {
      return;
    }

    const { email = '', firstName, lastName, phone = '' } = appStateUserProfile;

    const emailValid = validateEmail(email || '');
    const firstNameValid = validateName(firstName);
    const lastNameValid = validateName(lastName || '');
    const phoneValid = validatePhoneNumber(phone || '');

    let validationFields: UserProfileValidationFields = {
      [UserProfileFields.FIRST_NAME]: {
        valid: firstNameValid,
        message: 'this field is required',
      },
      [UserProfileFields.LAST_NAME]: {
        valid: lastNameValid,
        message: 'this field is required',
      },
      [UserProfileFields.EMAIL]: {
        valid: emailValid,
        message: 'enter a valid email address',
      },
    };

    setCanSaveProfile(basicFieldsEdited && firstNameValid && lastNameValid);
    setCanSaveEmail(emailEdited && emailValid);

    if (phoneNumberEdited) {
      const isMfaPhoneEnabled = mfaSettings.includes(MfaChallengeType.PHONE);
      const hasPhone = Boolean(phone);

      if (isMfaPhoneEnabled && !hasPhone) {
        validationFields = {
          ...validationFields,
          [UserProfileFields.PHONE]: {
            valid: false,
            message: 'required — to remove, disable mfa with sms',
          },
        };
        setCanSavePhone(false);
      } else if (!isMfaPhoneEnabled && hasPhone && !phoneValid) {
        validationFields = {
          ...validationFields,
          [UserProfileFields.PHONE]: {
            valid: false,
            message: 'enter a valid phone number',
          },
        };
        setCanSavePhone(false);
      } else {
        validationFields = {
          ...validationFields,
          [UserProfileFields.PHONE]: {
            valid: true,
          },
        };
        setCanSavePhone(true);
      }
    }

    setValidationFields(validationFields);
  }, [
    basicFieldsEdited,
    emailEdited,
    phoneNumberEdited,
    setValidationFields,
    appStateUserProfile,
    mfaSettings,
    userDepartment,
  ]);

  const handleVerifyPassword = useCallback(
    async (password: string) => {
      try {
        if (passwordChallengeCallback) {
          await passwordChallengeCallback(password);
          setCanSavePassword(false);
        }
      } catch (e) {
        LOG.error('verify password error', e);
        setValidationFields({
          [UserProfileFields.NEW_PASSWORD]: {
            valid: false,
            message: 'password is incorrect',
          },
        });
      }
    },
    [passwordChallengeCallback, setValidationFields],
  );

  const handleUpdatePassword = useCallback(() => {
    if (newPassword && confirmPassword) {
      updatePassword(newPassword, confirmPassword);
    }
  }, [confirmPassword, newPassword, updatePassword]);

  const handleUpdateProfile = useCallback(() => {
    if (appStateUserProfile) {
      updateProfile();
    }
  }, [appStateUserProfile, updateProfile]);

  const handleUpdateEmail = useCallback(() => {
    if (appStateUserProfile?.email) {
      updateEmail();
    }
  }, [appStateUserProfile?.email, updateEmail]);

  const handleCancelProfileEditing = useCallback(() => {
    setValidationFields({
      [UserProfileFields.FIRST_NAME]: { valid: null },
      [UserProfileFields.LAST_NAME]: { valid: null },
      [UserProfileFields.EMAIL]: { valid: null },
      [UserProfileFields.PHONE]: { valid: null },
    });
    cancelProfileEditing();
  }, [cancelProfileEditing, setValidationFields]);

  const handleCancelEmailEditing = useCallback(() => {
    setValidationFields({
      [UserProfileFields.EMAIL]: { valid: null },
    });
    cancelEmailEditing();
  }, [cancelEmailEditing, setValidationFields]);

  return (
    <div className={styles.container}>
      <div className={styles.contentCard}>
        <div className={styles.header}>
          <Heading variant={HeadingVariant.H2} className={styles.containerTitle} upperCase>
            My Profile
          </Heading>
        </div>
        <div className={cx(styles.containerRow, styles.containerRowAvatar)}>
          <div className={styles.inputItem}>
            <Avatar
              onUploadUserAvatar={onUploadAvatar}
              userAvatar={appStateUserProfile?.avatar}
              userFullName={appStateUserProfile?.fullName}
              isLoading={avatarLoading}
            />
          </div>
        </div>

        <>
          <div className={cx(styles.containerRow, styles.containerRowName)}>
            <InputGroup
              id="firstName"
              name="firstName"
              label="First name"
              value={appStateUserProfile?.firstName}
              errorText={inputErrorText(UserProfileFields.FIRST_NAME)}
              className={cx(styles.inputGroup, styles.inputRightPadding)}
              onChange={e => updateUserFormData({ firstName: e.target.value })}
            />
            <InputGroup
              id="lastName"
              name="lastName"
              label="Last name"
              value={appStateUserProfile?.lastName || ''}
              onChange={e => updateUserFormData({ lastName: e.target.value })}
              errorText={inputErrorText(UserProfileFields.LAST_NAME)}
              className={cx(styles.inputGroup)}
            />
          </div>
          <div className={cx(styles.containerRow, styles.containerRowInfo)}>
            <InputGroup
              className={cx(styles.inputGroup, styles.inputRightPadding)}
              id="timezone"
              name="timezone"
              label="Timezone"
              inputType={InputTypes.CUSTOM}
            >
              <FilterOptions
                placement="center"
                menuWidth="100%"
                isSingleSelect
                isAutoClose
                isShowClearAll={false}
                isSearchable
                className={styles.dropdownContainer}
                renderTrigger={({ onClick }) => (
                  <Trigger
                    testId="timezone-filter"
                    className={styles.dropdownTrigger}
                    title={appStateUserProfile?.timezone ?? DEFAULT_TEXT_PLACEHOLDER}
                    onClick={onClick}
                  />
                )}
                options={timezones || []}
                onChange={option => {
                  updateUserFormData({ timezone: option.id });
                }}
              />
            </InputGroup>
            <UserDepartmentDropdown
              className={styles.departmentContainer}
              onChange={_handleDepartmentChange}
              isLoading={profileSaving}
              userDepartmentList={userDepartmentList}
            />
          </div>

          {appStateUserProfile?.billingGroupName && (
            <div className={cx(styles.containerRowTagsWrapper)}>
              <div className={styles.titleWrapper}>
                <Text variant={TextSize.XS} upperCase medium className={styles.tagTitle}>
                  Billing group
                </Text>
                <Tooltip
                  title={
                    <Text variant={TextSize.S} color={TextColor.WHITE} textAlign="left">
                      Billing groups are used by your org
                      <br />
                      admins to assign and map users to
                      <br />
                      internal budgets. If this is incorrect,
                      <br />
                      contact your org admin.{' '}
                      <a
                        className={styles.tooltipLink}
                        href="https://support.writer.com/article/247-billing-groups"
                        target="_blank"
                      >
                        Learn more.
                      </a>
                    </Text>
                  }
                  placement="right"
                >
                  <span>
                    <Icon name={IconVariant.INFO_OUTLINED} width={18} height={18} />
                  </span>
                </Tooltip>
              </div>
              <div className={styles.tagWrapper}>
                <div className={styles.tag}>{appStateUserProfile.billingGroupName}</div>
              </div>
            </div>
          )}

          <div className={cx(styles.containerRow)}>
            <ActionButtonsGroup
              saveAvailable={canSaveProfile}
              onClickSave={handleUpdateProfile}
              onClickCancel={handleCancelProfileEditing}
              loading={profileSaving}
              cancelAvailable={basicFieldsEdited}
            />
          </div>
        </>

        <div className={styles.containerBlock}>
          <Heading variant={HeadingVariant.H3} className={cx(styles.containerRowTitle)}>
            Contact info
          </Heading>
          <div className={cx(styles.containerRow, styles.containerRowName)}>
            <div style={{ flex: 1 }}>
              <InputGroup
                id={UserProfileFields.EMAIL}
                name={UserProfileFields.EMAIL}
                label={UserProfileFields.EMAIL}
                value={appStateUserProfile?.[UserProfileFields.EMAIL] || ''}
                onChange={e => updateUserFormData({ [UserProfileFields.EMAIL]: e.target.value })}
                errorText={inputErrorText(UserProfileFields.EMAIL)}
                className={cx(styles.inputGroup, styles.inputRightPadding)}
              />
              <div className={cx(styles.containerRow)}>
                <ActionButtonsGroup
                  saveAvailable={canSaveEmail && emailEdited}
                  onClickSave={handleUpdateEmail}
                  onClickCancel={handleCancelEmailEditing}
                  loading={emailSaving}
                  cancelAvailable={emailEdited}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <MuiFormControl className={cx(styles.phoneControl)}>
                <Label htmlFor={UserProfileFields.PHONE} errorText={inputErrorText(UserProfileFields.PHONE)}>
                  {UserProfileFields.PHONE}
                </Label>
                <PhoneInput
                  dropdownClass={styles.countriesListDropdown}
                  containerClass={styles.phoneInputContainer}
                  isValid={!inputErrorText(UserProfileFields.PHONE)}
                  inputProps={{ id: UserProfileFields.PHONE, name: UserProfileFields.PHONE, ref: phoneInputRef }}
                  country="us"
                  placeholder="+1 (555) 123-4567"
                  value={appStateUserProfile?.[UserProfileFields.PHONE]}
                  onChange={phone => updateUserFormData({ [UserProfileFields.PHONE]: phone ? `+${phone}` : '' })}
                />
              </MuiFormControl>
              <div className={cx(styles.containerRow)}>
                <ActionButtonsGroup
                  saveAvailable={canSavePhone && phoneNumberEdited}
                  onClickSave={updatePhone}
                  onClickCancel={_cancelPhoneEditing}
                  loading={phoneSaving}
                  cancelAvailable={phoneNumberEdited}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerBlock}>
          {access.showSamlPlaceholder && <SamlPlaceholder />}
          {access.showSocialPlaceholder && <SocialPlaceholder />}
          {access.hasNoPassword && (
            <>
              <PasswordLockHeader />
              {(access.isSamlAuth || access.isSocialAuth) && <SsoAndPasswordPlaceholder />}
              <CreatePasswordButton
                createPasswordLoading={createPasswordLoading}
                onCreatePassword={requestPasswordCreate}
              />
            </>
          )}
          {access.editPasswordAvailable && (
            <>
              <Heading variant={HeadingVariant.H3} className={cx(styles.containerRowTitle)}>
                Password
              </Heading>
              {access.socialEditPasswordAvailable && <GoogleAndPasswordPlaceholder />}
              <div className={cx(styles.containerRow)}>
                <Tooltip
                  title={<ConfirmPasswordDescription />}
                  open={!fieldIsValid(UserProfileFields.NEW_PASSWORD) && newPasswordFieldFocused}
                  placement="top"
                >
                  <InputGroup
                    id="newPassword"
                    name="newPassword"
                    type="password"
                    label="New Password"
                    placeholder="password"
                    className={cx(styles.inputGroup, styles.inputRightMargin, styles.inputDiscPlaceHolder, {
                      [styles.inputGroupPasswordInvalid]: !fieldIsValid(UserProfileFields.NEW_PASSWORD),
                      [styles.inputGroupPasswordValid]: fieldIsValid(UserProfileFields.NEW_PASSWORD),
                    })}
                    value={newPassword}
                    onFocus={() => setNewPasswordFieldFocused(true)}
                    onBlur={() => setNewPasswordFieldFocused(false)}
                    onChange={e => updateNewPassword(UserProfileFields.NEW_PASSWORD, e.target.value)}
                    errorText={inputErrorText(UserProfileFields.NEW_PASSWORD)}
                    indicator={inputValidationIndicator(UserProfileFields.NEW_PASSWORD)}
                    tooltipContainerClass={styles.passwordDescriptionTooltip}
                  />
                </Tooltip>
                <InputGroup
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  label="Confirm password"
                  placeholder="password"
                  className={cx(styles.inputGroup, styles.inputGroupPasswordConfirmation, styles.inputDiscPlaceHolder, {
                    [styles.inputGroupPasswordInvalid]: !fieldIsValid(UserProfileFields.CONFIRM_PASSWORD),
                    [styles.inputGroupPasswordValid]: fieldIsValid(UserProfileFields.CONFIRM_PASSWORD),
                  })}
                  value={confirmPassword}
                  onChange={e => updateNewPassword(UserProfileFields.CONFIRM_PASSWORD, e.target.value)}
                  errorText={inputErrorText(UserProfileFields.CONFIRM_PASSWORD)}
                  indicator={inputValidationIndicator(UserProfileFields.CONFIRM_PASSWORD)}
                />
              </div>
              <div className={cx(styles.containerRow)}>
                <ActionButtonsGroup
                  saveAvailable={canSavePassword}
                  onClickSave={handleUpdatePassword}
                  onClickCancel={cancelPasswordEditing}
                  loading={passwordSaving}
                  cancelAvailable={newPassword.length || confirmPassword.length}
                />
              </div>
            </>
          )}
          {access.resetPasswordAvailable && (
            <ResetPasswordButton onResetPassword={requestPasswordReset} isLoading={resetPasswordLoading} />
          )}
        </div>
        {access.canDeleteOrg && appStateUserProfile && organization?.id && (
          <DeleteOrganization
            orgName={organization?.name || ''}
            profile={appStateUserProfile}
            subscriptionModel={subscriptionModel}
            orgId={organization?.id}
          />
        )}
        <DeviceManagementSection />
        <MfaVerificationModal
          isOpen={access.showMfaModal}
          sentTo={mfaSentTo || ''}
          types={availableMfaChallenges.map(challenge => challenge.method)}
          error={challengeError}
          isLoading={mfaLoading}
          onClose={onCloseMfaModal}
          onResendCode={onResendMfaCode}
          onChangeChallenge={onChangeMfaChallenge}
          onVerifyCode={onVerifyMfaCode}
          sendMfaCodeSuccessful={mfaResendSuccess}
          sendMfaCodeBlockReason={mfaResendCodeBlockReason}
          sendMfaCodeLimitReached={mfaResendLimitReached}
        />
        {access.passwordChallengeVisible && (
          <VerifyPasswordModal
            isOpen
            onClose={closePasswordModal}
            onVerifyPassword={handleVerifyPassword}
            errorMessage={challengeError}
            description={
              passwordChallengeType === PasswordChallengeType.RESET_PASSWORD ? (
                <Text variant={TextSize.L}>
                  To update your password, we need
                  <br />
                  to verify your current password.
                </Text>
              ) : (
                <Text variant={TextSize.L}>
                  To keep you secure, we need to
                  <br /> verify your password.
                </Text>
              )
            }
          />
        )}
        <MfaChallengesList
          className={cx(styles.containerRowColumn, styles.containerRowColumnSection)}
          phone={initialUser?.phone ?? ''}
          enabledMfas={mfaSettings}
          noPassword={access.hasNoPassword}
          onReorderChallenges={onReorderChallenges}
          switchMfaSettings={switchMfaSettings}
          onAddPhoneNumber={onAddPhoneNumber}
          onCreatePassword={requestPasswordCreate}
          mfaSetupPossible={!access.mfaFlowDisabledByOrgConfiguration}
        />
      </div>
    </div>
  );
});

export default ProfilePage;
