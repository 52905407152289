import React from 'react';

import { Heading, HeadingColor, HeadingVariant } from '@writercolab/ui-atoms';

import ActionButton from './ActionButton';

import styles from '../styles.module.css';

interface IBlogDraftsEmptyProps {
  onActionClick: () => void;
}

export const BlogDraftsEmpty: React.FC<IBlogDraftsEmptyProps> = ({ onActionClick }) => (
  <div className={styles.containerDraftEmpty}>
    <div className={styles.containerDraftEmptyTitle}>
      <Heading color={HeadingColor.GREY2} variant={HeadingVariant.H2}>
        No outputs available
      </Heading>
    </div>
    <div>
      <ActionButton
        className={styles.containerDraftEmptyActionButton}
        content="Back to key points"
        leftIconSymbol="&lsaquo;"
        onClick={onActionClick}
      />
    </div>
  </div>
);

export default BlogDraftsEmpty;
