import React from 'react';

import cx from 'classnames';

import {
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { FilterHeader } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import { TTeamPromptActionId, TTeamPromptBrief, TTeamPromptFilter, TTeamPromptSortField } from '@web/types';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { BaseTable, IRowProps } from '../BaseTable/BaseTable';
import { TeamPromptsTableUiModel } from './TeamPromptsTable.ui';
import { generateTableBody } from './utils';

import styles from './TeamPromptsTable.module.css';

export const TTeamPromptsTableVariant = new Enum('default', 'extended');

interface ITeamPromptsTableProps {
  variant?: typeof TTeamPromptsTableVariant.type;
  className?: string;
  tableClassName?: string;
  model: TeamPromptsTableUiModel;
  actionEnabled?: boolean;
  headersVisible?: boolean;
  showActionsDropdown?: boolean;
  showCopyAction?: boolean;
  showUseAction?: boolean;
  rowClickEnabled?: boolean;
  emptyState?: React.ReactNode;
  onPromptActionClick: (actionId: typeof TTeamPromptActionId.type, promptId: string) => void;
}

export const TeamPromptsTable = observer<ITeamPromptsTableProps>(
  ({
    className,
    variant = TTeamPromptsTableVariant.enum.default,
    model,
    onPromptActionClick,
    headersVisible = true,
    showActionsDropdown = true,
    showCopyAction = false,
    showUseAction = false,
    rowClickEnabled = true,
    emptyState,
  }) => {
    const { hasNext, loading, loadMore, showLoader } = model;

    const tableHeaders = headersVisible
      ? [
          {
            component: (
              <div className={styles.filterPromptHeader}>
                <Text variant={TextSize.L} color={TextColor.GREY}>
                  Prompt
                </Text>
              </div>
            ),
          },
          {
            component: model.tagsFiltersOptions?.length > 0 && (
              <FilterHeader
                className={cx(styles.filterHeader, styles.filterHeaderTags)}
                header={
                  <Text variant={TextSize.M} color={TextColor.GREY2}>
                    Tags
                  </Text>
                }
                title={
                  <Text variant={TextSize.XXS} smallCaps>
                    Filter by tags
                  </Text>
                }
                options={model.tagsFiltersOptions}
                onChange={updatedFilters => {
                  model.applyFilter(TTeamPromptFilter.enum.tag, updatedFilters);
                }}
              />
            ),
          },
          {
            component: (
              <Text variant={TextSize.L} color={TextColor.GREY}>
                Last edited
              </Text>
            ),
          },
          {
            component: (
              <Dropdown
                options={model.sortingOptions}
                trigger={
                  <div className={styles.sortActionTriggerContainer}>
                    <Icon name={IconVariant.SORT} width={18} height={18} />
                  </div>
                }
                onPrimaryOptionClickAction={id => {
                  if (TTeamPromptSortField.is(id)) {
                    model.setSortingOption(id as typeof TTeamPromptSortField.type);
                  }
                }}
                placement={DropdownPlacement.BOTTOM_RIGHT}
              />
            ),
          },
        ]
      : [];

    const extendedTable = variant === TTeamPromptsTableVariant.enum.extended;
    const tableData = generateTableBody(model, onPromptActionClick, {
      showActionsDropdown,
      showCopyAction,
      showUseAction,
      showCreator: extendedTable,
      showLargeIcon: extendedTable,
      showInlineTags: extendedTable,
      rowClickEnabled: !extendedTable,
      showCreateDateColumn: !extendedTable,
      titleVariant: extendedTable ? TextSize.L : TextSize.XL,
      truncatePrompt: !extendedTable,
    });

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading,
      hasNextPage: hasNext,
      onLoadMore: loadMore,
      rootMargin: '0px 0px 400px 0px',
    });

    return (
      <div className={cx(styles.container, className)}>
        <div className={styles.scrollableContainer} ref={rootRef}>
          <BaseTable
            headers={tableHeaders}
            data={tableData}
            loading={showLoader}
            isEnableSticky={false}
            gridTemplateColumns={
              variant === TTeamPromptsTableVariant.enum.default
                ? `minmax(500px,1fr) minmax(150px,1fr) minmax(100px,1fr) minmax(40px,1fr)`
                : 'minmax(600px,1fr) minmax(120px,1fr) minmax(4px,1fr) minmax(2px,1fr)'
            }
            customEmpty={emptyState}
            onRowClick={(row: IRowProps<TTeamPromptBrief>) =>
              rowClickEnabled && onPromptActionClick(TTeamPromptActionId.enum.edit, String(row.id))
            }
          />
          <div ref={sentryRef} />
          <div className={styles.loaderWrapper}>
            {hasNext && <SkeletonLoader type={SkeletonLoaderType.ISSUE_CARD} className={styles.loader} />}
          </div>
        </div>
      </div>
    );
  },
);
