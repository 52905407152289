import React, { useState } from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Heading, HeadingVariant, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import freePlan from '../../../../assets/backgrounds/freePlan.png';

import styles from './styles.module.css';

interface IActivateFreePopup {
  onCancel?: () => void;
  open: boolean;
  onActivateFreeClick?: () => void;
}

export const ActivateFreePopup: React.FC<IActivateFreePopup> = ({ open, onActivateFreeClick, onCancel }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onButtonClick = () => {
    setIsSubmitting(true);
    onActivateFreeClick && onActivateFreeClick();
  };

  return (
    <Modal open={open} className={styles.modalContainer} handleClose={onCancel}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={freePlan} alt="free plan" />
        </div>
        <Heading variant={HeadingVariant.H2} className={styles.content}>
          Activate your Free plan
        </Heading>
        <Text variant={TextSize.L} className={cx(styles.content, styles.textCenter)}>
          Your trial has ended but we now offer a Free plan. Activate Free to continue using Writer.{' '}
        </Text>
        <div className={styles.actionButtonsHolder}>
          <Button
            className={styles.action}
            type={ButtonTypes.PRIMARY}
            onClick={onButtonClick}
            isLoading={isSubmitting}
            content="Activate Free"
          />
        </div>
      </div>
    </Modal>
  );
};

export default ActivateFreePopup;
