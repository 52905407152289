import { action, computed, makeObservable, observable } from 'mobx';

import { DEBOUNCE_TIME_ON_TYPING } from '@writercolab/common-utils';
import { FieldModel, ReactiveQueue } from '@writercolab/mobx';
import type { TNotificationQueueItem } from '@writercolab/types';

import type { TTeamPromptBrief, TTeamPromptEditActionDto } from '@web/types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import type { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { getLogger } from '../../../utils/logger';
import { TeamPromptsTableUiModel } from '../../organisms/TeamPromptsTable';

const LOG = getLogger('ConsolePageUIModel');

interface ITeamPromptsPageUiModelOpts {
  teamPromptsApiModel: TeamPromptsApiModel;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

export class TeamPromptsPageUiModel {
  searchPhrase: FieldModel<string>;
  teamPromptsTableUiModel: TeamPromptsTableUiModel;

  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;

  constructor(private readonly opts: ITeamPromptsPageUiModelOpts) {
    this.notificationQueue = this.opts.notificationQueue || new ReactiveQueue();
    this.searchPhrase = FieldModel.build({ init: '', autotouched: true });
    this.teamPromptsTableUiModel = new TeamPromptsTableUiModel({
      teamPromptsApiModel: this.opts.teamPromptsApiModel,
    });

    makeObservable(this, {
      prompts: computed.struct,
      teamPromptsApiModel: computed.struct,
      empty: computed.struct,

      setSearchPhrase: action,

      searchPhrase: observable,
    });
  }

  get teamPromptsApiModel() {
    return this.opts.teamPromptsApiModel;
  }

  get prompts(): TTeamPromptBrief[] {
    return this.opts.teamPromptsApiModel.pagination.value ?? [];
  }

  get empty() {
    return (
      !this.teamPromptsTableUiModel.loading &&
      !this.teamPromptsTableUiModel.hasPrompts &&
      this.searchPhrase.value === '' &&
      isEmpty(this.opts.teamPromptsApiModel.pagination.extra.tagIds)
    );
  }

  setSearchPhrase = (phrase: string) => {
    this.searchPhrase.value = phrase;
    LOG.debug('Search phrase set to:', this.searchPhrase.value);

    if (isEmpty(this.searchPhrase.value)) {
      this.teamPromptsApiModel.pagination.setExtra({
        search: '',
      });
    } else {
      this.debouncedSearch();
    }
  };

  resetSearch = () => {
    this.searchPhrase.value = '';
    LOG.debug('Search phrase reset');
    this.debouncedSearch();
  };

  private debouncedSearch = debounce(() => {
    this.opts.teamPromptsApiModel.pagination.setExtra({
      search: this.searchPhrase.value,
    });
  }, DEBOUNCE_TIME_ON_TYPING);

  addTeamPrompt = async (prompt: TTeamPromptEditActionDto) => {
    LOG.debug('adding team prompt:', prompt);
    await this.opts.teamPromptsApiModel.create(prompt);
    this.teamPromptsApiModel.pagination.reload();
  };

  editTeamPrompt = async (prompt: TTeamPromptEditActionDto) => {
    LOG.debug('editing team prompt:', prompt);
    await this.opts.teamPromptsApiModel.edit(prompt.id as string, prompt);
    this.teamPromptsApiModel.pagination.reload();
  };

  toggleFavorite = async (promptId: string) => {
    LOG.debug('toggling favorite for prompt:', promptId);
    await this.opts.teamPromptsApiModel.toggleFavorite(promptId);
    this.teamPromptsApiModel.pagination.reload();
  };

  duplicate = async (promptId: string) => {
    await this.opts.teamPromptsApiModel.duplicate(promptId);
    this.teamPromptsApiModel.pagination.reload();
  };
}
