import React, { ReactNode } from 'react';

import cx from 'classnames';

import { Textarea as WDSTextarea } from '@writercolab/fe.wds';
import { FieldModel } from '@writercolab/mobx';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { Textarea } from '@writercolab/ui-molecules';
import { cn } from '@writercolab/utils';

import { observer } from 'mobx-react-lite';

import styles from './TextareaField.module.css';

interface ITextareaFieldProps {
  children?: ReactNode;
  field: FieldModel<string>;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  showError?: boolean;

  /**
   * Preview new WDS input field.
   */
  preview?: boolean;

  /**
   * When preview is true, set the label of the textarea.
   */
  label?: string;

  /**
   * When preview is true, set the tooltip of the textarea.
   */
  labelTooltip?: string;
}

export const TextareaField = observer<ITextareaFieldProps>(
  ({ className, field, placeholder, children, disabled, showError = true, preview = false, label, labelTooltip }) => {
    const hasError = !!field.error && !disabled && field.touched;

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      field.value = e.target.value;
    };

    const commonProps = {
      className,
      placeholder,
      disabled,
      value: field.value,
      onChange: handleChange,
    } satisfies React.TextareaHTMLAttributes<HTMLTextAreaElement>;

    if (preview) {
      return (
        <WDSTextarea
          label={label}
          tooltip={labelTooltip}
          error={field.touched && field.error ? field.error : undefined}
          auto
          tooltipProps={{
            className: 'max-w-[220px]',
          }}
          {...commonProps}
          className={cn(commonProps.className, 'resize-y min-h-[75px]')}
        />
      );
    }

    return (
      <div className={styles.container}>
        {showError && hasError && (
          <div
            data-testid="textarea-field-error"
            className={cx(styles.errorContainer, {
              [styles.noLabel]: !children,
            })}
          >
            <Text variant={TextSize.XS} caps color={TextColor.ORANGE}>
              {field.error}
            </Text>
          </div>
        )}
        {children && <div>{children}</div>}
        <Textarea
          error={!!field.error && field.touched}
          aria-describedby={hasError ? 'textarea-error' : undefined}
          {...commonProps}
        />
      </div>
    );
  },
);
