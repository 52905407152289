import React, { ReactNode } from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IErrorContainerProps {
  children?: ReactNode;
  className?: string;
  onCloseClick?: () => void;
}

export const ErrorContainer: React.FC<IErrorContainerProps> = ({ children, onCloseClick, className }) => (
  <div className={cx(styles.containerLock, className)}>
    {onCloseClick && (
      <Button
        onClick={onCloseClick}
        type={ButtonTypes.TRANSPARENT}
        className={cx(styles.headerButtonRound, styles.clickable)}
      >
        <Icon name={IconVariant.CLOSE} width={14} height={14} />
      </Button>
    )}
    {children}
  </div>
);

export default ErrorContainer;
