import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';

import { DeleteConfirmationModal } from '../../molecules/DeleteConfirmationModal/DeleteConfirmationModal';
import { OAuthAppsViewer } from '../../organisms/OAuthAppsViewer';
import type { OAuthAppsPageUIModel } from './OAuthAppsPageModel.ui';

import styles from './OAuthAppsPage.module.css';

interface IOAuthAppsModalContainerProps {
  model: OAuthAppsPageUIModel;
}
export const OAuthAppsModalContainer = observer(({ model }: IOAuthAppsModalContainerProps) => {
  return (
    <>
      {model.isShowViewerModal && (
        <Modal
          open
          handleClose={model.closeModal}
          style={{
            minWidth: '500px',
            minHeight: '200px',
          }}
        >
          <OAuthAppsViewer model={model.oauthAppsViewerModel} />
        </Modal>
      )}

      <DeleteConfirmationModal
        modalClassName={styles.deletePromptModal}
        modalTitle="Delete OAuth application"
        bodyContent={
          <Text className={styles.deleteModalParagraph} variant={TextSize.XL} color={TextColor.BLACK}>
            Are you sure you want to delete this OAuth
            <br />
            application? This may impact live syncs between <br />
            your data source and Writer.
          </Text>
        }
        footerClassName={styles.deleteModalFooter}
        isOpen={model.isShowDeleteModal}
        onClose={model.closeModal}
        closeButton={
          <Button
            className={styles.submitButton}
            type={ButtonTypes.DEFAULT}
            content="Never mind"
            onClick={model.closeModal}
          />
        }
        submitButton={
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Delete"
            isLoading={model.api.isLoading}
            disabled={model.api.isLoading}
            onClick={model.handleDelete}
          />
        }
      />
    </>
  );
});
