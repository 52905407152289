import React from 'react';

import { Heading, HeadingVariant, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import { beaconArticleSidebarIdByType, capitalizedLabelByType } from '../consts';
import { AutoMatchedColumnsInfoType, ImportItemType, StepsContentType } from '../types';

import styles from './styles.module.css';

interface IStepInfoProps {
  currentStep?: number;
  greyed?: boolean;
  autoMatchedColumnsInfo: AutoMatchedColumnsInfoType;
  type: ImportItemType;
  onClickLearnMore: (step: number) => void;
}

const stepsContent: StepsContentType[] = [
  {
    title: 'Upload your file',
    description: (type: ImportItemType) => {
      const mapByType = {
        [ImportItemType.TERMS]: 'terms',
        [ImportItemType.SNIPPETS]: 'snippets',
        [ImportItemType.BILLING_GROUP]: 'billing',
      };

      return (
        <>
          <Heading variant={HeadingVariant.H3} bold>
            Guidelines
          </Heading>
          <ul className={styles.guidelinesList}>
            <li>
              <Text variant={TextSize.L}>We accept CSV and Excel files</Text>
            </li>
            <li>
              <Text variant={TextSize.L}>
                Start with our template (
                <a
                  className={styles.link}
                  href={`https://img.writer.com/app-files/Writer_${mapByType[type]}_import_template.csv`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CSV
                </a>{' '}
                |{' '}
                <a
                  className={styles.link}
                  href={`https://img.writer.com/app-files/Writer_${mapByType[type]}_import_template.xlsx`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  XLSX
                </a>
                )
              </Text>
            </li>
            <li>
              <Text variant={TextSize.L}>Save your file with UTF-8 encoding</Text>
            </li>
            <li>
              <Text variant={TextSize.L}>Max file size = 5MB</Text>
            </li>
          </ul>
        </>
      );
    },
  },
  {
    title: 'Match up your columns',
    description: (type: ImportItemType, autoMatchedColumnsInfo: AutoMatchedColumnsInfoType) => (
      <Text variant={TextSize.M} className={styles.matchDescription}>
        We were able to auto-match{' '}
        <Text variant={TextSize.M} bold inline>
          {autoMatchedColumnsInfo.matched} of {autoMatchedColumnsInfo.total} columns
        </Text>{' '}
        from your file. Match up the remaining columns to continue.
      </Text>
    ),
  },
  {
    title: 'Your import summary',
  },
];

export const StepInfo = ({
  currentStep = 0,
  greyed,
  autoMatchedColumnsInfo,
  type,
  onClickLearnMore,
}: IStepInfoProps) => {
  const stepData = stepsContent[currentStep];
  const importingTypeLabel = capitalizedLabelByType[type];
  const beaconArticleSidebarId = beaconArticleSidebarIdByType[type];

  return (
    <div className={`${styles.container} ${greyed ? styles.greyed : ''}`}>
      <Text variant={TextSize.XL} className={styles.title}>
        Step {currentStep + 1} of {stepsContent.length}
      </Text>
      <Heading variant={HeadingVariant.H1} className={styles.stepTitle}>
        {stepData.title}
      </Heading>
      {stepData.description && stepData.description(type, autoMatchedColumnsInfo)}
      <button
        className={styles.helpCard}
        type="button"
        data-beacon-article-sidebar={beaconArticleSidebarId}
        onClick={() => onClickLearnMore(currentStep + 1)}
      >
        <Icon name={IconVariant.LIFEBUOY} width={32} height={32} className={styles.helpIcon} />
        <div className={styles.helpInfo}>
          <Text variant={TextSize.XL} bold>
            Learn more:
          </Text>
          <Text variant={TextSize.L}>Importing {importingTypeLabel}</Text>
        </div>
      </button>
    </div>
  );
};
