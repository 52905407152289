import type React from 'react';

import { Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './SessionsTable.module.css';

interface ITableTriggerProps {
  title?: string;
  onClick: () => void;
}

export const TableFilterHeader: React.FC<ITableTriggerProps> = ({ title, onClick }) => (
  <div className={styles.tableFilterHeader} onClick={onClick}>
    <Text variant={TextSize.L} color={TextColor.GREY2} medium upperCase className={styles.columnHeader}>
      {title}
      <Icon className={styles.icon} name={IconVariant.FILTER} width={16} height={16} />
    </Text>
  </div>
);
