import React from 'react';

import { ISuggestionExampleContents } from '@writercolab/common-utils';

import ExampleBlock from './ExampleBlock';

import styles from '../styles.module.css';

interface ExampleBlockListProps {
  blocks: ISuggestionExampleContents[];
}

export const ExampleBlockList: React.FC<ExampleBlockListProps> = ({ blocks }) => (
  <div className={styles.exampleBlockContainer}>
    {blocks.map(block => (
      <ExampleBlock key={block.id}>{block.content}</ExampleBlock>
    ))}
  </div>
);

export default ExampleBlockList;
