import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IEmptyLinkProps {
  isLoading: boolean;
}

interface ISkeletonLinksProps {
  times: number;
}

const SkeletonLinks = ({ times }: ISkeletonLinksProps) => (
  <>
    {[...Array(times).keys()].map((_, i) => (
      <div key={i} className={cx(styles.linkWrapper, styles.empty)}>
        <Icon name={IconVariant.ARROW_RIGHT_ALT} width={20} height={20} />

        <div className={styles.link}>
          <SkeletonLoader type={SkeletonLoaderType.MAGIC_LINKS} repeat={1} />
        </div>
      </div>
    ))}
  </>
);

export const EmptyLink = ({ isLoading }: IEmptyLinkProps) => (
  <>
    {isLoading ? (
      <SkeletonLinks times={4} />
    ) : (
      <div className={cx(styles.linkWrapper, styles.empty)}>
        <Icon name={IconVariant.ARROW_RIGHT_ALT} width={20} height={20} />
        <div className={cx(styles.link, styles.zeroState)}>
          <div className={cx(styles.magicLine, styles.magicLineOne)} />
          <div className={cx(styles.magicLine)} />
          <div className={cx(styles.magicLine)} />
          <div className={cx(styles.magicLine)} />
          <div className={cx(styles.magicLine, styles.magicLineTwo)} />
        </div>
      </div>
    )}
  </>
);

export default EmptyLink;
