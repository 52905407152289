import React from 'react';

import cx from 'classnames';

import { SkeletonLoader, SkeletonLoaderType } from '@writercolab/ui-atoms';
import { generateRandomString } from '@writercolab/utils';

import styles from '../styles.module.css';

interface KeyPointLoaderProps {
  count?: number;
  type?: KeyPointLoaderType;
}

export enum KeyPointLoaderType {
  SINGLE = 'single',
  MULTI_LINE = 'multi-line',
}

const SingleLoader = ({ key }) => (
  <div key={`loader-${key}`} className={cx(styles.headlineSectionTitle, styles.headlineSingleLoader)}>
    <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.headlineSectionTitleLoader} />
  </div>
);

const MultiLineLoader = ({ key }) => (
  <div key={`loader-${key}`} className={styles.headlineSectionContainer}>
    <div className={styles.headlineSectionTitle}>
      <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.headlineSectionTitleLoader} />
    </div>
    <div className={styles.headlineSectionKeyPoints}>
      <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.headlineSectionTitleLoader} />
    </div>
    <div className={styles.headlineSectionKeyPoints}>
      <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.headlineSectionTitleLoader} />
    </div>
    <div className={styles.headlineSectionKeyPoints}>
      <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.headlineSectionTitleLoader} />
    </div>
    <div className={styles.headlineSectionKeyPoints}>
      <SkeletonLoader type={SkeletonLoaderType.DEFAULT} className={styles.headlineSectionTitleLoader} />
    </div>
  </div>
);

export const KeyPointLoader: React.FC<KeyPointLoaderProps> = ({ count = 1, type = KeyPointLoaderType.MULTI_LINE }) => (
  <div
    key={`keyPointLoader-${generateRandomString()}`}
    className={cx(styles.headlineSectionInputContainer, styles.headlineSectionInputContainerLoader)}
  >
    <div className={styles.headlineSectionInput}>
      <>
        {Array(count)
          .fill(0)
          .map(() => (
            <>
              {type === KeyPointLoaderType.SINGLE ? (
                <SingleLoader key={`loader-${generateRandomString()}`} />
              ) : (
                <MultiLineLoader key={`loader-${generateRandomString()}`} />
              )}
            </>
          ))}
      </>
    </div>
  </div>
);

export default KeyPointLoader;
