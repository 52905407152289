import { action, computed, makeObservable, observable, runInAction, toJS } from 'mobx';

import { PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import { ApplicationsApiModel, ModalsManager } from '@writercolab/models';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';
import { ChatSessionsApiModel } from '@writercolab/ui-chat-apps';
import { Enum } from '@writercolab/utils';

import { AcademyHomeWidgetModelUi } from 'components/organisms/AcademyHomeWidget';
import { AiStudioHomeWidgetModelUi } from 'components/organisms/AiStudioHomeWidget';
import { AppsHomeWidgetModelUi } from 'components/organisms/AppsHomeWidget';
import { ExtensionsHomeWidgetUiModel } from 'components/organisms/ExtensionsHomeWidget';
import { RecentWorkHomeWidgetModelUi } from 'components/organisms/RecentWorkHomeWidget';
import { ShortcutsHomeWidgetModelUi } from 'components/organisms/ShortcutsHomeWidget';

import type { IHomeWidgetConfig, TWriterExtensionId } from '@web/types';
import {
  ASK_WRITER_APP_ID,
  BLOG_BUILDER_APP_ID,
  IMAGE_ANALYZER_APP_ID,
  RECAPS_APP_ID,
  THomeWidgetsState,
  TWidgetType,
} from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import isEqual from 'lodash/isEqual';
import type { AppModel } from 'models/app';
import type { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';
import { LearningCenterModel } from 'models/learningCenter';
import { OrganizationDocumentsApi } from 'models/organizationDocuments.api';
import requestService from 'services/request/requestService';
import {
  getDefaultTeamWidgetConfigMap,
  getDefaultUserWidgetConfigMap,
  mergeWidgetConfigs,
  widgetConfigArrToMap,
} from 'utils/homePageUtils';

import { ChatAppHomeWidgetModelUi } from '../../organisms/ChatAppHomeWidget';
import { PromptLibraryModalModel } from '../../organisms/PromptLibraryModal';

export interface IHomePageUIModelParams {
  appModel: AppModel;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
}

export const HomePageModalType = new Enum('promptLibrary');

export class HomePageUIModel {
  notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  private readonly widgets: Record<typeof TWidgetType.type, AbstractHomeWidgetModel | null>;
  private readonly $userConfigEdits = observable.object({} as Record<typeof TWidgetType.type, IHomeWidgetConfig>);
  private readonly $teamConfigEdits = observable.object({} as Record<typeof TWidgetType.type, IHomeWidgetConfig>);
  readonly sessionsApiModel: ChatSessionsApiModel;
  modalsManager = new ModalsManager<typeof HomePageModalType.type>();

  readonly consoleApplicationsApiModel: ApplicationsApiModel;
  readonly learningCenterModel: LearningCenterModel;
  readonly promptLibraryModalModel: PromptLibraryModalModel;
  readonly documentApi: OrganizationDocumentsApi;
  currentMode: typeof THomeWidgetsState.type = THomeWidgetsState.enum.view;

  private readonly $hiddenApps = computed(() => {
    const assistantSubscription = this.params.appModel.assistantSubscription;
    const publicAppsAvailable = this.params.appModel.featureFlags.get('publicAppsAvailable', false);
    const imageAnalysisAvailable = this.params.appModel.featureFlags.get('imageAnalysisAvailable', false);

    const allApps = [
      {
        id: ASK_WRITER_APP_ID,
        name: 'Ask Writer',
        visible: publicAppsAvailable && assistantSubscription.access?.askWriter && !assistantSubscription.isFree,
      },
      {
        id: BLOG_BUILDER_APP_ID,
        name: 'Blog builder',
        visible: publicAppsAvailable && assistantSubscription.access?.seoBlogBuilder && !assistantSubscription.isFree,
      },
      {
        id: RECAPS_APP_ID,
        name: 'Recaps builder',
        visible: publicAppsAvailable && assistantSubscription.access?.eventTakeaways && !assistantSubscription.isFree,
      },
      {
        id: IMAGE_ANALYZER_APP_ID,
        name: 'Image analyzer',
        visible: publicAppsAvailable && imageAnalysisAvailable && !assistantSubscription.isFree,
      },
    ];

    return new Set(allApps.filter(app => !app.visible).map(app => app.id));
  });

  constructor(private params: IHomePageUIModelParams) {
    const { appModel } = params;
    const organizationId = Number(appModel.organizationId);
    const teamId = Number(appModel.teamId);
    this.notificationQueue = params.notificationQueue || new ReactiveQueue<TNotificationQueueItem>();

    this.sessionsApiModel = new ChatSessionsApiModel({
      request: requestService.api,
      organizationId,
      teamId,
      getApplicationId: () => undefined,
      getActiveSessionId: () => undefined,
      getApplicationVersionId: () => undefined,
    });

    this.documentApi = new OrganizationDocumentsApi({
      request: requestService.api,
      organizationId: organizationId,
      teamId: teamId,
    });

    this.widgets = TWidgetType.hash<AbstractHomeWidgetModel | null>({
      featuredChatApp: new ChatAppHomeWidgetModelUi({
        featureFlags: () => appModel.featureFlags,
        assistantSubscription: () => appModel.assistantSubscription,
        analyticsService: appModel.analyticsService,
        aiStudioBalance: () => appModel.aiStudioBalance,
        organizationId: () => organizationId,
        teamId: () => teamId,
        config: () => this.getWidgetConfig(TWidgetType.enum.featuredChatApp),
      }),

      shortcuts: new ShortcutsHomeWidgetModelUi({
        hiddenApps: () => this.$hiddenApps,
        analyticsService: appModel.analyticsService,
        organizationId: () => organizationId,
        teamId: () => teamId,
        featureFlags: () => appModel.featureFlags,
        assistantSubscription: () => appModel.assistantSubscription,
        config: () => this.getWidgetConfig(TWidgetType.enum.shortcuts),
        lockedApps: () =>
          this.currentMode === THomeWidgetsState.enum.adminEdit
            ? []
            : this.findWidgetTeamConfig(TWidgetType.enum.shortcuts)?.data.appIds || [],
      }),

      recentWork: new RecentWorkHomeWidgetModelUi({
        analyticsService: appModel.analyticsService,
        request: requestService.api,
        organizationId: () => organizationId,
        assistantSubscription: () => appModel.assistantSubscription,
        teamId: () => teamId,
        config: () => this.getWidgetConfig(TWidgetType.enum.recentWork),
      }),

      apps: new AppsHomeWidgetModelUi({
        hiddenApps: () => this.$hiddenApps,
        analyticsService: appModel.analyticsService,
        requestService: requestService.api,
        organizationId: () => organizationId,
        teamId: () => teamId,
        featureFlags: () => appModel.featureFlags,
        assistantSubscription: () => appModel.assistantSubscription,
        isTeamAdmin: () => appModel.permissionsModel?.isTeamAdmin ?? false,
        config: () => this.getWidgetConfig(TWidgetType.enum.apps),
      }),

      aiStudio: new AiStudioHomeWidgetModelUi({
        analyticsService: appModel.analyticsService,
        request: requestService.api,
        organizationId,
        config: () => this.getWidgetConfig(TWidgetType.enum.aiStudio),
      }),

      writerAiAcademy: new AcademyHomeWidgetModelUi({
        analyticsService: appModel.analyticsService,
        config: () => this.getWidgetConfig(TWidgetType.enum.writerAiAcademy),
      }),

      extensions: new ExtensionsHomeWidgetUiModel({
        analyticsService: appModel.analyticsService,
        config: () => this.getWidgetConfig(TWidgetType.enum.extensions),
      }),
    });

    this.consoleApplicationsApiModel = new ApplicationsApiModel({
      request: requestService.api,
      organizationId,
    });

    this.learningCenterModel = new LearningCenterModel({
      request: requestService.api,
      organizationId,
      isEnrolledInWriterAcademy: !!appModel.userSettings.value?.academyEnrolledAt,
      refreshUserSettings: () => appModel.refreshUserSettings(),
    });

    this.promptLibraryModalModel = new PromptLibraryModalModel({
      requestService: requestService.api,
      analyticsService: appModel.analyticsService,
      organizationId,
      teamId,
    });

    makeObservable(this, {
      currentMode: observable,

      teamNameInHeader: computed,
      shortcutsWidgetModel: computed,
      widgetsConfig: computed,
      userConfig: computed,
      teamConfig: computed,

      appsWidgetModel: computed,
      recentWorkWidgetModel: computed,
      aiStudioWidgetModel: computed,
      academyWidgetModel: computed,
      extensionsWidgetModel: computed,

      isPromptLibraryModalOpen: computed,

      switchToAdminEdit: action,
      switchToViewMode: action,
      hideUserExtension: action,
      changeChatApp: action,
      saveUserConfig: action,
      saveTeamConfig: action,
    });
  }

  private readonly $teamConfig = new PromisedModel(async () => {
    const { organizationId, teamId } = this.params.appModel;

    if (!organizationId || !teamId) {
      return undefined;
    }

    return requestService.api
      .get('/api/organization/v2/{organizationId}/team/{teamId}/widget', {
        params: {
          path: {
            organizationId,
            teamId,
          },
        },
      })
      .then(res => res.data.widgets as IHomeWidgetConfig[]);
  });

  private readonly $userConfig = new PromisedModel(async () => {
    const { organizationId, teamId } = this.params.appModel;

    if (!organizationId || !teamId) {
      return undefined;
    }

    return requestService.api
      .get('/api/organization/v2/{organizationId}/team/{teamId}/my/widget', {
        params: {
          path: {
            organizationId,
            teamId,
          },
        },
      })
      .then(res => res.data.widgets as IHomeWidgetConfig[]);
  });

  get userConfig() {
    // take default
    const defaultConfig = getDefaultUserWidgetConfigMap();
    // apply BE data if available
    const userConfigMap = widgetConfigArrToMap(this.$userConfig.value || []);
    // apply user edits if available
    const edits = this.$userConfigEdits;

    return (Object.keys(defaultConfig) as Array<typeof TWidgetType.type>).reduce(
      (acc, type) => {
        const config = defaultConfig[type];
        const userConfig = userConfigMap[type];
        const edit = edits[type];
        acc[type] = edit || userConfig || config;

        return acc;
      },
      {} as Record<typeof TWidgetType.type, IHomeWidgetConfig>,
    );
  }

  get teamConfig() {
    // take default
    const defaultConfig = getDefaultTeamWidgetConfigMap();
    // apply BE data if available
    const teamConfigMap = widgetConfigArrToMap(this.$teamConfig.value || []);
    // apply team edits if available
    const edits = this.$teamConfigEdits;

    return (Object.keys(defaultConfig) as Array<typeof TWidgetType.type>).reduce(
      (acc, type) => {
        const config = defaultConfig[type];
        const teamConfig = teamConfigMap[type];
        const edit = edits[type];
        acc[type] = edit || teamConfig || config;

        return acc;
      },
      {} as Record<typeof TWidgetType.type, IHomeWidgetConfig>,
    );
  }

  get widgetsConfig() {
    return mergeWidgetConfigs(this.teamConfig, this.userConfig);
  }

  get teamNameInHeader() {
    const { appModel } = this.params;

    if (!appModel.assistantSubscription.isMultiTeam || !appModel.teamsModel.teams) {
      return '';
    }

    const _activeTeam = appModel.teamsModel.teams?.find(team => team.id === appModel.teamId);

    return _activeTeam?.name || '';
  }

  get isPromptLibraryModalOpen() {
    return this.modalsManager.isModalVisible(HomePageModalType.enum.promptLibrary);
  }

  setPromptLibraryModalOpen = (isOpen: boolean) => {
    if (isOpen) {
      this.modalsManager.showModal(HomePageModalType.enum.promptLibrary);
    } else {
      this.modalsManager.hideModal(HomePageModalType.enum.promptLibrary);
    }
  };

  switchToAdminEdit = () => {
    this.currentMode = THomeWidgetsState.enum.adminEdit;

    TWidgetType.list.forEach(type => {
      const widget = this.widgets[type];
      widget?.onStartEditing(THomeWidgetsState.enum.adminEdit);
    });

    this.params.appModel.analyticsService.track(AnalyticsActivity.customizeHomePageButtonClicked, {});
  };

  switchToViewMode = () => {
    this.currentMode = THomeWidgetsState.enum.view;

    TWidgetType.list.forEach(type => {
      const widget = this.widgets[type];
      widget?.onStopEditing();
    });

    this.params.appModel.analyticsService.track(AnalyticsActivity.doneCustomizeHomePageButtonClicked, {});
  };

  addUserShortcut = (appId: string) => {
    const config = this.findWidgetUserConfig(TWidgetType.enum.shortcuts);

    const newConfig = config
      ? {
          ...config,
          data: {
            appIds: [...(config?.data.appIds || []), appId],
          },
        }
      : {
          id: '',
          type: TWidgetType.enum.shortcuts,
          visible: true,
          data: {
            appIds: [appId],
          },
        };

    this.saveUserConfig(newConfig);
    this.params.appModel.analyticsService.track(AnalyticsActivity.addNewShortcutAppSelected, {
      app_id: appId,
      edit_flow: 'user',
    });
  };

  removeUserShortcut = (appId: string) => {
    const config = this.findWidgetUserConfig(TWidgetType.enum.shortcuts);

    if (!config) {
      return;
    }

    const newConfig = {
      ...config,
      data: {
        appIds: config.data.appIds.filter(id => id !== appId),
      },
    };

    this.saveUserConfig(newConfig);
    this.params.appModel.analyticsService.track(AnalyticsActivity.appShortcutRemoved, {
      app_id: appId,
      edit_flow: 'user',
    });
  };

  hideUserExtension = (extensionId: typeof TWriterExtensionId.type) => {
    const config = this.findWidgetUserConfig(TWidgetType.enum.extensions);

    const newConfig = config
      ? {
          ...config,
          data: {
            names: [...(config?.data.names || []), extensionId],
          },
        }
      : {
          id: '',
          type: TWidgetType.enum.extensions,
          visible: true,
          data: {
            names: [extensionId],
          },
        };

    this.saveUserConfig(newConfig);
  };

  changeChatApp = (appId: string) => {
    const config = this.findWidgetUserConfig(TWidgetType.enum.featuredChatApp);

    const newConfig = config
      ? {
          ...config,
          data: {
            appId,
          },
        }
      : {
          id: '',
          type: TWidgetType.enum.featuredChatApp,
          visible: true,
          data: {
            appId,
          },
        };

    this.saveUserConfig(newConfig);
  };

  saveWidget = async (widgetId: typeof TWidgetType.type) => {
    const widget = this.widgets[widgetId];

    if (!widget) {
      return;
    }

    if (this.currentMode === THomeWidgetsState.enum.view) {
      await this.saveUserConfig(widget.configForSave);
    } else {
      await this.saveTeamConfig(widget.configForSave);
    }
  };

  bulkSaveWidgets = async () => {
    TWidgetType.list.forEach(this.saveWidget);

    this.switchToViewMode();
  };

  saveUserConfig = async (config: IHomeWidgetConfig) => {
    const { organizationId, teamId } = this.params.appModel;

    if (!organizationId || !teamId) {
      return;
    }

    const existingConfig = this.userConfig[config.type];

    // save user
    const prevEdit = this.$userConfigEdits[config.type];
    this.$userConfigEdits[config.type] = {
      ...existingConfig,
      ...config,
    };

    try {
      if (!existingConfig?.id) {
        const createdConfig = await requestService.api
          .post('/api/organization/v2/{organizationId}/team/{teamId}/my/widget', {
            params: {
              path: {
                organizationId,
                teamId,
              },
            },
            body: config,
          })
          .then(res => res.data as IHomeWidgetConfig);

        runInAction(() => {
          this.$userConfigEdits[config.type] = createdConfig;
        });
      } else if (!this.compareConfigs(existingConfig, config)) {
        await requestService.api.put('/api/organization/v2/{organizationId}/team/{teamId}/my/widget', {
          params: {
            path: {
              organizationId,
              teamId,
            },
          },
          body: config,
        });
      }
    } catch {
      runInAction(() => {
        this.$userConfigEdits[config.type] = prevEdit;
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Failed to save user config',
      });
    }
  };

  saveTeamConfig = async (config: IHomeWidgetConfig) => {
    const { organizationId, teamId } = this.params.appModel;

    if (!organizationId || !teamId) {
      return;
    }

    const existingConfig = this.teamConfig[config.type];

    // save team
    const prevEdit = this.$teamConfigEdits[config.type];
    this.$teamConfigEdits[config.type] = {
      ...existingConfig,
      ...config,
    };

    try {
      if (!existingConfig?.id) {
        const createdConfig = await requestService.api
          .post('/api/organization/v2/{organizationId}/team/{teamId}/widget', {
            params: {
              path: {
                organizationId,
                teamId,
              },
            },
            body: config,
          })
          .then(res => res.data as IHomeWidgetConfig);

        runInAction(() => {
          this.$teamConfigEdits[config.type] = createdConfig;
        });
      } else if (!this.compareConfigs(existingConfig, config)) {
        await requestService.api.put('/api/organization/v2/{organizationId}/team/{teamId}/widget', {
          params: {
            path: {
              organizationId,
              teamId,
            },
          },
          body: config,
        });
      }
    } catch {
      runInAction(() => {
        this.$teamConfigEdits[config.type] = prevEdit;
      });

      this.notificationQueue.enqueue({
        type: NotificationQueueItemType.enum.error,
        message: 'Failed to save team config',
      });
    }
  };

  private compareConfigs = (a: IHomeWidgetConfig, b: IHomeWidgetConfig) => {
    const aData = toJS(a.data);
    const bData = toJS(b.data);

    return a.type === b.type && isEqual(aData, bData) && a.visible === b.visible;
  };

  private getWidgetModel<T extends AbstractHomeWidgetModel>(widgetId: typeof TWidgetType.type): T {
    return this.widgets[widgetId] as T;
  }

  private findWidgetTeamConfig = <T extends IHomeWidgetConfig['type']>(widgetId: T) => {
    const config = this.teamConfig[widgetId];

    if (!config) {
      return undefined;
    }

    return config as Extract<IHomeWidgetConfig, { type: T }>;
  };

  private findWidgetUserConfig = <T extends IHomeWidgetConfig['type']>(widgetId: T) => {
    const config = this.userConfig[widgetId];

    if (!config) {
      return undefined;
    }

    return config as Extract<IHomeWidgetConfig, { type: T }>;
  };

  private getWidgetConfig = <T extends IHomeWidgetConfig['type']>(widgetId: T) => {
    const config =
      this.currentMode === THomeWidgetsState.enum.adminEdit
        ? this.findWidgetTeamConfig(widgetId)
        : this.widgetsConfig[widgetId];

    if (!config) {
      throw new Error(`Widget config not found for ${widgetId}`);
    }

    return config as Extract<IHomeWidgetConfig, { type: T }>;
  };

  get shortcutsWidgetModel() {
    return this.getWidgetModel<ShortcutsHomeWidgetModelUi>(TWidgetType.enum.shortcuts);
  }

  get appsWidgetModel() {
    return this.getWidgetModel<AppsHomeWidgetModelUi>(TWidgetType.enum.apps);
  }

  get recentWorkWidgetModel() {
    return this.getWidgetModel<RecentWorkHomeWidgetModelUi>(TWidgetType.enum.recentWork);
  }

  get aiStudioWidgetModel() {
    return this.getWidgetModel<AiStudioHomeWidgetModelUi>(TWidgetType.enum.aiStudio);
  }

  get academyWidgetModel() {
    return this.getWidgetModel<AcademyHomeWidgetModelUi>(TWidgetType.enum.writerAiAcademy);
  }

  get extensionsWidgetModel() {
    return this.getWidgetModel<ExtensionsHomeWidgetUiModel>(TWidgetType.enum.extensions);
  }

  get featuredChatAppModel() {
    return this.getWidgetModel<ChatAppHomeWidgetModelUi>(TWidgetType.enum.featuredChatApp);
  }

  get hasAccessToHomePageCustomize() {
    return this.params.appModel.permissionsModel?.isTeamAdmin && !this.params.appModel.assistantSubscription.isFree;
  }
}
