import { action, computed, makeObservable, observable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';

import isEmpty from 'lodash/isEmpty';

export interface IConfluenceSpace {
  id: number;
  key: string;
  name: string;
  selected?: boolean;
  totalSize?: number;
}

export class ConfluenceApiModel {
  token: string | undefined = undefined;

  constructor() {
    makeObservable(this, {
      token: observable,
      setToken: action,
      headers: computed,
      isLoading: computed,
      cloudId: computed,
      spaces: computed.struct,
      spacesDetailed: computed.struct,
    });
  }

  $resources: PromisedModel<{ id: string }[]> = new PromisedModel({
    name: '$resources',
    load: async () => {
      if (!this.headers) {
        return undefined;
      }

      const response = await fetch('https://api.atlassian.com/oauth/token/accessible-resources', {
        headers: this.headers,
      });

      const json = await response.json();

      return json;
    },
  });

  $spaces: PromisedModel<IConfluenceSpace[] | undefined> = new PromisedModel({
    name: '$spaces',
    load: async () => {
      if (!this.headers || !this.cloudId) {
        return undefined;
      }

      const response = await fetch(
        `https://api.atlassian.com/ex/confluence/${this.cloudId}/api/v2/spaces?status=current&type=global&limit=250`,
        {
          headers: this.headers,
        },
      );

      const json = await response.json();

      return json.results;
    },
  });

  $spacesDetailed: PromisedModel<IConfluenceSpace[] | undefined> = new PromisedModel({
    name: '$spacesDetailed',
    load: async () => {
      if (!this.headers || !this.cloudId || !this.spaces || isEmpty(this.spaces)) {
        return undefined;
      }

      // Confluence API doesn't support getting multiple spaces details in one request, therefore requesting each space separately
      const promises = this.spaces.map(space =>
        fetch(
          `https://api.atlassian.com/ex/confluence/${this.cloudId}/rest/api/search?limit=0&cql=space%3D${space.key}%20and%20type%3Dpage`,
          {
            headers: this.headers,
          },
        ).then(response => response.json()),
      );

      const spacesDetails = await Promise.all(promises);
      const spacesDetailed = this.spaces.map((space, i) => ({
        ...space,
        totalSize: spacesDetails[i]?.totalSize,
      }));

      return spacesDetailed;
    },
  });

  setToken = (token: string | undefined) => {
    this.token = token;
  };

  get headers() {
    if (!this.token) {
      return undefined;
    }

    return {
      Authorization: `Bearer ${this.token}`,
      Accept: 'application/json',
    };
  }

  get isLoading() {
    return (
      this.$resources.status === 'pending' ||
      this.$spaces.status === 'pending' ||
      this.$spacesDetailed.status === 'pending'
    );
  }

  get cloudId() {
    return this.$resources.value?.[0]?.id;
  }

  get spaces() {
    return this.$spaces.value;
  }

  get spacesDetailed() {
    if (!this.$spacesDetailed.value || this.$spacesDetailed.status === 'pending') {
      return this.spaces;
    }

    return this.$spacesDetailed.value;
  }
}
