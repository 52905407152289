import React from 'react';

interface ISpinnerProps {
  progress: number;
  size: number;
  circleWidth: number;
  progressWidth: number;
  circleColor: string;
  progressColor: string;
}

export const Spinner = ({ progress, size, circleWidth, progressWidth, circleColor, progressColor }: ISpinnerProps) => {
  const viewBox = `-${size / 8} -${size / 8} ${size + size / 4} ${size + size / 4}`;
  const radius = size / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - progress) / 100) * circumference;

  return (
    <svg
      width={size}
      height={size}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(-90deg)' }}
    >
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke={circleColor}
        strokeWidth={circleWidth}
        strokeDasharray={`${circumference}px`}
        strokeDashoffset="0"
      />
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        stroke={progressColor}
        strokeWidth={progressWidth}
        strokeLinecap="round"
        strokeDashoffset={`${progressOffset}px`}
        fill="transparent"
        strokeDasharray={`${circumference}px`}
      />
    </svg>
  );
};
