import React from 'react';

import cx from 'classnames';

import { DocumentHighlightType } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  TooltipAlignment,
} from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

import { headerConfig } from '../constants';

import styles from '../styles.module.css';

interface IHighlightHeaderProps {
  type: DocumentHighlightType;
  tooltipText?: string | undefined;
  locked?: boolean;
  indicate?: boolean;
  tooltipWrapperClassname?: string;
  onReloadClick?: (type: DocumentHighlightType) => void;
}

const TooltipContent = ({ text }) => (
  <Text color={TextColor.WHITE} variant={TextSize.M}>
    {text}
  </Text>
);

export const HighlightHeader: React.FC<IHighlightHeaderProps> = ({
  type,
  onReloadClick,
  tooltipText,
  locked,
  indicate,
  tooltipWrapperClassname,
}) => {
  const tooltipVisible = !isEmpty(tooltipText);

  return (
    <div className={styles.containerHighlightHeader}>
      {headerConfig[type]?.icon && (
        <Icon
          name={headerConfig[type].icon!}
          width={18}
          height={18}
          className={styles.headerContainerButtonIconSocial}
        />
      )}
      <Text variant={TextSize.XL} medium>
        {headerConfig[type]?.title}
      </Text>
      {onReloadClick && (
        <Tooltip
          tooltipWrapperClassname={cx(styles.containerHighlightHeaderTooltip, tooltipWrapperClassname)}
          align={TooltipAlignment.LEFT}
          title={tooltipVisible ? <TooltipContent text={tooltipText} /> : ''}
          placement="right"
          disabled={!tooltipVisible}
        >
          <div>
            <Button
              onClick={() => !locked && onReloadClick(type)}
              type={ButtonTypes.TRANSPARENT}
              className={cx(styles.headerContainerButtonShort, styles.headerContainerButtonIconCircle, {
                [styles.headerContainerButtonDisabled]: locked,
                [styles.headerContainerButtonIndicated]: indicate && !locked,
              })}
            >
              <Icon name={IconVariant.REFRESH} width={14} height={14} />
            </Button>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default HighlightHeader;
