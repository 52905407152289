import React from 'react';

import cx from 'classnames';

import { MediaFileTabs } from '@writercolab/types';
import { TabList } from '@writercolab/ui-molecules';
import {
  SelectExistingFilePanel,
  UploadFilePanel,
  UploadTranscriptPanel,
  UploadUrlPanel,
} from '@writercolab/ui-organisms';

import { observer } from 'mobx-react-lite';

import { RecapsFileUploaderModel } from './RecapsFileUploaderModel';

import styles from './RecapsFileUploader.module.css';

interface IRecapsFileUploader {
  model: RecapsFileUploaderModel;
  inputId?: string;
  isError?: boolean;
}

export const RecapsFileUploader: React.FC<IRecapsFileUploader> = observer(
  ({ model, isError, inputId = 'input-upload' }) => (
    <div className={styles.container}>
      <TabList
        className={cx(styles.tabs, { [styles.containerError]: isError })}
        activeTab={model.activeTab}
        onChange={tab => model.setActiveTab(MediaFileTabs.get(tab, MediaFileTabs.enum.UPLOAD))}
        tabs={[
          {
            label: 'Add URL',
            id: MediaFileTabs.enum.URL,
            content: (
              <UploadUrlPanel
                model={model.url}
                title="Add a YouTube or Vimeo URL (>5 min long)"
                subtitle="Paste a link to a YouTube or Vimeo video"
                placeholder="Add a URL"
                inputId={inputId}
              />
            ),
          },
          {
            label: 'Upload new file',
            id: MediaFileTabs.enum.UPLOAD,
            content: (
              <UploadFilePanel
                model={model.file}
                title="Upload any media file (>5 minutes long)"
                subtitle="Supported files include mp3, mp4, srt, etc., up to 2048MB"
                inputId={inputId}
              />
            ),
          },
          {
            label: 'Paste text',
            id: MediaFileTabs.enum.PASTE_TRANSCRIPT,
            content: (
              <UploadTranscriptPanel
                model={model.transcript}
                title="Paste text"
                subtitle="Up to 20K words"
                placeholder="Copy and paste in your full text here"
                inputId={inputId}
              />
            ),
          },
          {
            label: 'Select existing',
            id: MediaFileTabs.enum.SELECT_EXISTING,
            content: (
              <SelectExistingFilePanel
                model={model.existingFiles}
                title="Select from existing media"
                inputId={inputId}
              />
            ),
          },
        ]}
      />
    </div>
  ),
);
