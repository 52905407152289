import { IFeatureClient } from '@writercolab/feature-flags';

export class OfflineFeatureFlagsClient<TData extends Record<string, unknown>> implements IFeatureClient<TData> {
  constructor(private data: TData) {
    this.data = data;
  }

  subscribe(update: (data: TData) => void): () => void {
    update(this.data);

    return () => {};
  }
}
