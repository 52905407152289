import type React from 'react';
import { useEffect } from 'react';

import type { ITeam } from '@writercolab/common-utils';
import { openNewTab } from '@writercolab/common-utils';

import head from 'lodash/head';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router';

import { usePageTitle } from '../../../hooks/usePageTitle';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';

export const OnboardingPage: React.FC = observer(() => {
  usePageTitle('Onboarding');
  const {
    appModel: {
      teamsModel: { teams },
    },
  } = useAppState();
  const { orgId } = useParams<Record<string, string>>();

  const onGoToEditor = () => {
    const team = head(teams) as ITeam;
    openNewTab(ROUTE.toEditorPage(orgId, team.id, -1));
  };

  useEffect(() => {
    if (teams && teams.length > 0) {
      onGoToEditor();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams, orgId]);

  return null;
});

export default OnboardingPage;
