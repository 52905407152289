import cx from 'classnames';

import type { ModalsManager } from '@writercolab/models';
import type { components } from '@writercolab/network';
import type { TOrgTeamUserActivityParams } from '@writercolab/types';
import {
  DotLoader,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  TooltipAlignment,
} from '@writercolab/ui-atoms';

import type { IBodyCellProps, IRowProps } from 'components/organisms/BaseTable/BaseTable';

import {
  TKnowledgeGraphUrl,
  TKnowledgeGraphUrlAction,
  TKnowledgeGraphUrlError,
  TKnowledgeGraphUrlModal,
  TKnowledgeGraphUrlStatus,
} from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { CircleAlert, CloudOff } from 'lucide-react';

import type { IKnowledgeGraphWebConnectorModalState } from './KnowledgeGraphWebConnectorModel.ui';

import styles from './KnowledgeGraphWebConnector.module.css';

export const KG_URLS_REFRESH_TIMEOUT = 5000;
export const KG_URLS_LIMIT = 20;
export const KG_URLS_GRID_COLUMNS = '1fr 135px 150px 40px';
export const KG_URL_VISIBLE_CHARACTERS = 50;
export const KG_URL_DOMAIN_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/i;
export const KG_URL_SIMPLE_DOMAIN_REGEX =
  /^(?!:\/\/)([a-zA-Z0-9]+\.)?[a-zA-Z0-9][a-zA-Z0-9-]+\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/i;
export const KG_URLS_OPTIONS = [
  {
    value: TKnowledgeGraphUrl.enum.SubPages,
    text: 'Include all subpages',
  },
  {
    value: TKnowledgeGraphUrl.enum.SinglePage,
    text: 'Include single page(s) only',
  },
];
export const KG_URLS_ACTION_OPTIONS = [
  {
    id: TKnowledgeGraphUrlAction.enum.exclude,
    name: 'Exclude a subpage',
  },
  {
    id: TKnowledgeGraphUrlAction.enum.edit,
    name: 'Edit',
  },
  {
    id: TKnowledgeGraphUrlAction.enum.delete,
    className: styles.deleteOption,
    name: 'Delete',
  },
];

const HeaderCell = ({ value }) => (
  <Text variant={TextSize.L} color={TextColor.GREY2}>
    {value}
  </Text>
);

const StatusCell = ({
  type,
  status,
  url,
  error,
  onErrorHover,
}: {
  type: typeof TKnowledgeGraphUrl.type;
  status: typeof TKnowledgeGraphUrlStatus.type;
  url: string;
  error?: typeof TKnowledgeGraphUrlError.type;
  onErrorHover?: () => void;
}) => (
  <Text
    className={cx(styles.statusCell, { [styles.exceptionStatusCell]: type === TKnowledgeGraphUrl.enum.Exclude })}
    variant={TextSize.XL}
    color={TextColor.BLACK}
  >
    {getStatusIcon({ type, status, error, isDynamic: url.includes('?'), onErrorHover })}
    <span className={cx({ [styles.statusCellTextError]: status === TKnowledgeGraphUrlStatus.enum.Error })}>{url}</span>
  </Text>
);

const TextCell = ({ value }) => (
  <Text variant={TextSize.M} color={TextColor.GREY2}>
    {value}
  </Text>
);

const ActionsCell = ({
  id,
  type,
  url,
  excludeUrl,
  error,
  isExcludeActionEnabled,
  analyticsService,
  modalsManager,
}: {
  id: string;
  type: typeof TKnowledgeGraphUrl.type;
  url: string;
  excludeUrl?: string;
  error?: typeof TKnowledgeGraphUrlError.type;
  isExcludeActionEnabled: boolean;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  modalsManager: ModalsManager<typeof TKnowledgeGraphUrlModal.type, IKnowledgeGraphWebConnectorModalState>;
}) => (
  <div className={styles.actionsCell} onClick={e => e.stopPropagation()}>
    <Dropdown
      testId="kg-url-table-actions"
      trigger={<Icon className={styles.iconEllipsis} name={IconVariant.ELLIPSES} width={18} height={18} />}
      placement={DropdownPlacement.BOTTOM_RIGHT}
      options={KG_URLS_ACTION_OPTIONS.filter(option => {
        if (option.id === TKnowledgeGraphUrlAction.enum.exclude) {
          return TKnowledgeGraphUrl.match(
            type,
            {
              SinglePage: () => false,
              SubPages: () => isExcludeActionEnabled && !error,
              Exclude: () => false,
            },
            null,
          );
        }

        return true;
      })}
      onTriggerClickCallback={() => {
        analyticsService.track(AnalyticsActivity.kgWebUrlOptionClicked, {
          url: type === TKnowledgeGraphUrl.enum.Exclude ? excludeUrl : url,
        });
      }}
      onPrimaryOptionClickAction={actionId => {
        analyticsService.track(AnalyticsActivity.kgWebUrlOptionSelected, {
          url: type === TKnowledgeGraphUrl.enum.Exclude ? excludeUrl : url,
          option: actionId,
        });
        TKnowledgeGraphUrlAction.match(
          actionId,
          {
            edit: () => {
              analyticsService.track(AnalyticsActivity.kgWebEditUrlModalOpened, {
                url: excludeUrl && type === TKnowledgeGraphUrl.enum.Exclude ? excludeUrl : url,
              });
              modalsManager.showModal(
                type === TKnowledgeGraphUrl.enum.Exclude
                  ? TKnowledgeGraphUrlModal.enum.editExcludeUrl
                  : TKnowledgeGraphUrlModal.enum.editUrl,
                {
                  id,
                  type,
                  url,
                  excludeUrl,
                  error,
                },
              );
            },
            exclude: () => {
              analyticsService.track(AnalyticsActivity.kgWebExcludeSpecificSubpagesModalOpened, {
                url,
              });
              modalsManager.showModal(TKnowledgeGraphUrlModal.enum.excludeUrl, {
                id,
                type,
                url,
              });
            },
            delete: () =>
              modalsManager.showModal(TKnowledgeGraphUrlModal.enum.deleteUrl, {
                id,
                type,
                url: excludeUrl && type === TKnowledgeGraphUrl.enum.Exclude ? excludeUrl : url,
              }),
          },
          null,
        );
      }}
    />
  </div>
);

export const getStatusIcon = ({
  type,
  status,
  error,
  isDynamic,
  onErrorHover,
}: {
  type: typeof TKnowledgeGraphUrl.type;
  status: typeof TKnowledgeGraphUrlStatus.type;
  error?: typeof TKnowledgeGraphUrlError.type;
  isDynamic?: boolean;
  onErrorHover?: () => void;
}) => {
  if (type === TKnowledgeGraphUrl.enum.Exclude) {
    return <CloudOff data-testid="kg-url-status-exclude" className={styles.statusIcon} size="18" />;
  }

  return TKnowledgeGraphUrlStatus.match(
    status,
    {
      Success: () => (
        <Tooltip
          disabled={!isDynamic}
          title="Contains dynamic parameters"
          placement="top"
          align={TooltipAlignment.LEFT}
        >
          <div
            data-testid={isDynamic ? 'kg-url-status-dynamic' : 'kg-url-status-success'}
            className={cx(styles.statusIcon, { [styles.statusIconAlert]: isDynamic })}
          >
            <Icon name={IconVariant.GREEN1_CHECKMARK} width={18} height={18} />
          </div>
        </Tooltip>
      ),
      Validating: () => (
        <div className={styles.loader} data-testid="kg-url-status-validating">
          <DotLoader className={styles.loaderIcon} />
        </div>
      ),
      Error: () => (
        <Tooltip
          disabled={!error}
          title={error && <div data-testid="kg-url-error-tooltip">{getErrorLabel(error)}</div>}
          placement="top"
          align={TooltipAlignment.LEFT}
        >
          <div data-testid="kg-url-status-error" className={styles.statusIconError} onMouseEnter={onErrorHover}>
            <CircleAlert size={20} />
          </div>
        </Tooltip>
      ),
    },
    null,
  );
};

export const getScopeLabel = (type: typeof TKnowledgeGraphUrl.type): string =>
  TKnowledgeGraphUrl.match(
    type,
    {
      SinglePage: () => 'Single page',
      SubPages: () => 'All subpages',
      Exclude: () => 'Exclude all',
    },
    null,
  );

export const getErrorLabel = (errorType: typeof TKnowledgeGraphUrlError.type): string =>
  TKnowledgeGraphUrlError.match(
    errorType,
    {
      InvalidUrl: () => 'Invalid URL',
      NotSearchable: () => 'URL not found',
      NotFound: () => 'URL not found',
      PaywallOrLoginPage: () => 'Unable to access',
      UnexpectedError: () => 'Something went wrong',
    },
    null,
  );

export const generateTableHeaders = () => {
  return [
    {
      component: <HeaderCell value="URL" />,
    },
    {
      component: <HeaderCell value="Scope" />,
    },
    {
      component: <HeaderCell value="Last edited by" />,
    },
    {
      component: <div />,
    },
  ];
};

export const generateTableCell = (id: string, component: React.ReactNode): IBodyCellProps => ({
  id,
  component,
  className: styles.cell,
});

export const generateTableBody = ({
  urls,
  isExcludeActionEnabled,
  analyticsService,
  modalsManager,
}: {
  urls: components['schemas']['media_dto_WebConnectorResponse_UrlResponse'][];
  isExcludeActionEnabled: boolean;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  modalsManager: ModalsManager<typeof TKnowledgeGraphUrlModal.type, IKnowledgeGraphWebConnectorModalState>;
}): IRowProps[] => {
  let rows: IRowProps[] = [];

  urls.forEach(item => {
    const { id, type, status, url, lastEditedBy } = item;
    const _type = TKnowledgeGraphUrl.get(type, TKnowledgeGraphUrl.enum.SinglePage);
    const _status = TKnowledgeGraphUrlStatus.get(status.type, TKnowledgeGraphUrlStatus.enum.Validating);
    const _username = `${lastEditedBy?.firstName ?? ''} ${lastEditedBy?.lastName ?? ''}`;
    const _error =
      'reason' in status
        ? TKnowledgeGraphUrlError.get(status.reason, TKnowledgeGraphUrlError.enum.UnexpectedError)
        : undefined;

    rows = [
      ...rows,
      {
        id: `row-${id}`,
        className: type === TKnowledgeGraphUrl.enum.Exclude ? styles.exceptionRow : styles.row,
        data: item,
        cells: [
          generateTableCell(
            'status',
            <StatusCell
              type={_type}
              status={_status}
              url={url}
              error={_error}
              onErrorHover={() =>
                analyticsService.track(AnalyticsActivity.kgWebUrlErrorTooltipViewed, {
                  url,
                  type,
                  error: _error,
                })
              }
            />,
          ),
          generateTableCell('scope', <TextCell value={getScopeLabel(_type)} />),
          generateTableCell('lastEditedBy', <TextCell value={_username} />),
          generateTableCell(
            'actions',
            <ActionsCell
              id={id}
              url={url}
              type={_type}
              error={_error}
              isExcludeActionEnabled={isExcludeActionEnabled}
              analyticsService={analyticsService}
              modalsManager={modalsManager}
            />,
          ),
        ],
      },
    ];

    // Add Exclude URLs as separate table rows
    if ('excludeUrls' in item) {
      item.excludeUrls.forEach((excludeUrl, i) => {
        rows = [
          ...rows,
          {
            id: `row-${id}-${i}`,
            className: styles.exceptionRow,
            data: excludeUrl,
            cells: [
              generateTableCell(
                'status',
                <StatusCell
                  type={TKnowledgeGraphUrl.enum.Exclude}
                  status={TKnowledgeGraphUrlStatus.enum.Success}
                  url={excludeUrl}
                />,
              ),
              generateTableCell('scope', <TextCell value={getScopeLabel(TKnowledgeGraphUrl.enum.Exclude)} />),
              generateTableCell('lastEditedBy', <div />),
              generateTableCell(
                'actions',
                <ActionsCell
                  id={id}
                  url={url}
                  excludeUrl={excludeUrl}
                  type={TKnowledgeGraphUrl.enum.Exclude}
                  isExcludeActionEnabled={false}
                  analyticsService={analyticsService}
                  modalsManager={modalsManager}
                />,
              ),
            ],
          },
        ];
      });
    }
  });

  return rows;
};

export const validateUrls = ({
  urls,
  prevUrls,
  prevUrlsCount,
}: {
  urls?: string;
  prevUrls: components['schemas']['media_dto_WebConnectorResponse_UrlResponse'][];
  prevUrlsCount: number;
}): string | undefined => {
  if (!urls?.trim()) {
    return 'Enter a valid URL';
  }

  const trimmedUrls = urls.replace(/[\n\r\t]/gm, '').replaceAll(' ', '');
  const splittedUrls = trimmedUrls.split(',').filter(str => str !== '');

  if (prevUrls.some(url => splittedUrls.includes(url.url))) {
    return 'Duplicate URL';
  }

  if (prevUrlsCount + splittedUrls.length > KG_URLS_LIMIT) {
    return `Too many URLs (max ${KG_URLS_LIMIT})`;
  }

  if (!splittedUrls.every(url => KG_URL_DOMAIN_REGEX.test(url) || KG_URL_SIMPLE_DOMAIN_REGEX.test(url))) {
    return 'Enter a valid URL';
  }

  return undefined;
};

export const validateUrlWithDomain = (url: string): string | undefined =>
  KG_URL_DOMAIN_REGEX.test(url) ? undefined : 'Enter a valid URL';

export const formatUrlsWithProtocols = (urls: string): string[] => {
  const trimmedUrls = urls.replace(/[\n\r\t]/gm, '').replaceAll(' ', '');
  const splittedUrls = trimmedUrls.split(',').filter(str => str !== '');
  const formattedUrls = splittedUrls.map(url => {
    if (KG_URL_SIMPLE_DOMAIN_REGEX.test(url)) {
      return `https://${url}`;
    }

    return url;
  });

  return formattedUrls;
};

export const getUrlsLength = (urls?: string): number => {
  if (!urls) {
    return 0;
  }

  const formattedUrls = urls
    .replace(/[\n\r\t]/gm, '')
    .replaceAll(' ', '')
    .split(',')
    .filter(str => str !== '');

  return formattedUrls.filter(url => KG_URL_DOMAIN_REGEX.test(url) || KG_URL_SIMPLE_DOMAIN_REGEX.test(url)).length;
};

export const truncateModalUrl = (url: string): string =>
  url.length > KG_URL_VISIBLE_CHARACTERS ? `${url.substring(0, KG_URL_VISIBLE_CHARACTERS)}...` : url;
