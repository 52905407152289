import React from 'react';

import cx from 'classnames';

import { Enum } from '@writercolab/utils';

import styles from './RoundedContainer.module.css';

export const TRoundedContainerSize = new Enum('S', 'M');
export const TRoundedContainerBackgroundColor = new Enum('white', 'green2');

interface IRoundedContainerProps extends BaseComponentsProps {
  className?: string;
  variant?: typeof TRoundedContainerSize.type;
  color?: typeof TRoundedContainerBackgroundColor.type;
  hasShadow?: boolean;
  disabled?: boolean;
}

export const RoundedContainer: React.FC<IRoundedContainerProps> = ({
  className,
  children,
  variant = TRoundedContainerSize.enum.S,
  color = TRoundedContainerBackgroundColor.enum.white,
  hasShadow = true,
  disabled = false,
}) => (
  <div
    className={cx(className, styles.container, {
      [styles.sizeS]: variant === TRoundedContainerSize.enum.S,
      [styles.sizeM]: variant === TRoundedContainerSize.enum.M,
      [styles.bgWhite]: color === TRoundedContainerBackgroundColor.enum.white,
      [styles.bgGreen2]: color === TRoundedContainerBackgroundColor.enum.green2,
      [styles.shadow]: hasShadow,
      [styles.disabled]: disabled,
    })}
  >
    {children}
  </div>
);
