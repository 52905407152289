import React from 'react';

import { Unreachable, UnreachableType } from '@writercolab/ui-molecules';

import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';

import styles from './styles.module.css';

export const Error404Page = () => {
  const { appState } = useAppState();
  const _orgId = appState.organizationId || appState.organizations?.[0]?.id;

  return (
    <div className={styles.container}>
      <Unreachable type={UnreachableType.enum.error404page} dashboardRouteHref={ROUTE.toHome(_orgId)} />
    </div>
  );
};

export default Error404Page;
