import type React from 'react';
import { useEffect, useReducer } from 'react';

import type { IUserProfile } from '@writercolab/common-utils';
import { SharedQueryParam, isChromeBrowser } from '@writercolab/common-utils';

import { AnalyticsActivity } from 'constants/analytics';
import head from 'lodash/head';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';

import useExtensionChrome from '../../../hooks/useExtensionChrome';
import { useAppState } from '../../../state';
import { TActionType } from '../../../state/types';
import { removeQueryParam } from '../../../utils/navigationUtils';
import BeaconIcon from '../../molecules/BeaconIcon';
import type { IStepConfig } from './common/common';
import {
  FIRST_EMPTY_NAME_TITLE,
  FIRST_STEP_ID,
  IOnboardingTutorialFlow,
  TUTORIAL_DOC_TIP_HIDE_DELAY,
  TUTORIAL_DOC_TIP_SHOW_DELAY,
  TUTORIAL_TIP_SHOW_DELAY,
} from './common/common';
import { StepWindow } from './parts/StepWindow';
import { teamAdminSteps } from './steps/teamAdminSteps';
import { teamMemberSteps } from './steps/teamMemberSteps';

import styles from './styles.module.css';

interface IOnboardingTutorialProps {
  visible: boolean;
  userProfile: IUserProfile;
  onFinishAction?: () => void;
  onSkipAction?: () => void;
}

enum TTutorialActionType {
  SetVisible = 'setVisible',
  SetFlow = 'setFlow',
  SetSteps = 'setSteps',
  SetCurrentStep = 'setCurrentStep',
  SetCurrentStepNumber = 'setCurrentStepNumber',
}

type TTutorialState = {
  visible: boolean;
  flow: IOnboardingTutorialFlow | null;
  steps: IStepConfig[] | null;
  currentStepNumber: number;
  currentStepsConfig: IStepConfig | null;
};

type TTutorialAction = {
  type: TTutorialActionType;
  payload?: any;
};

const initialTutorialState: TTutorialState = {
  visible: true,
  flow: null,
  steps: null,
  currentStepNumber: 1,
  currentStepsConfig: null,
};

const reducer = (state: TTutorialState = initialTutorialState, action: TTutorialAction): TTutorialState => {
  let newState;

  switch (action.type) {
    case TTutorialActionType.SetVisible:
      newState = { ...state, visible: action.payload };
      break;
    case TTutorialActionType.SetFlow:
      newState = { ...state, flow: action.payload };
      break;
    case TTutorialActionType.SetSteps:
      newState = { ...state, steps: action.payload };
      break;
    case TTutorialActionType.SetCurrentStepNumber:
      newState = { ...state, currentStepNumber: action.payload };
      break;
    case TTutorialActionType.SetCurrentStep:
      newState = { ...state, currentStepsConfig: action.payload };
      break;
    default:
      return state;
  }

  return { ...newState };
};

const Navigator: React.FC<{
  arrowStyle: () => React.CSSProperties;
}> = ({ arrowStyle }) => {
  const style = arrowStyle();

  return (
    <div className={styles.containerNavigator} style={style}>
      <BeaconIcon />
    </div>
  );
};

export const OnboardingTutorial: React.FC<IOnboardingTutorialProps> = observer(
  ({ visible, userProfile, onFinishAction, onSkipAction }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isChromeExtensionInstalled = useExtensionChrome();
    const { dispatchAppState, appModel } = useAppState();
    const [state, dispatch] = useReducer(reducer, initialTutorialState);
    const queryParams = new URLSearchParams(location.search);

    const { teamId, permissionsModel, assistantSubscription, analyticsService } = appModel;

    const setActiveStep = (number: number, config: IStepConfig) => {
      const _activeStepConfig = {
        ...config,
      };

      if (config.id === FIRST_STEP_ID) {
        if (userProfile.firstName && userProfile.firstName.length > 0) {
          _activeStepConfig.title = config.title.replace('%userName%', userProfile.firstName);
        } else {
          _activeStepConfig.title = FIRST_EMPTY_NAME_TITLE;
        }
      }

      _activeStepConfig.onActivate && _activeStepConfig.onActivate();

      dispatch({
        type: TTutorialActionType.SetCurrentStepNumber,
        payload: number,
      });
      dispatch({
        type: TTutorialActionType.SetCurrentStep,
        payload: _activeStepConfig,
      });
    };

    useEffect(() => {
      const activeSteps = permissionsModel?.isTeamAdminOf(Number(teamId)) ? teamAdminSteps : teamMemberSteps;

      if (visible) {
        dispatchAppState({ type: TActionType.SetChromeWindowState, payload: false });
      }

      dispatch({
        type: TTutorialActionType.SetVisible,
        payload: visible,
      });
      dispatch({
        type: TTutorialActionType.SetFlow,
        payload: assistantSubscription.isTeam ? IOnboardingTutorialFlow.TEAM : IOnboardingTutorialFlow.PRO,
      });
      dispatch({
        type: TTutorialActionType.SetSteps,
        payload: activeSteps,
      });

      visible && setActiveStep(FIRST_STEP_ID, head(activeSteps) as IStepConfig);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, assistantSubscription.isTeam, userProfile, teamId]);

    if (!state.visible || !state.steps || !state.currentStepsConfig) {
      return null;
    }

    const onPrevButtonClick = () => {
      if (state.steps) {
        const stepNumber = state.currentStepNumber - 1;
        const stepConfig = state.steps.find(s => s.id === stepNumber);

        if (stepNumber === 0) {
          dispatch({
            type: TTutorialActionType.SetVisible,
            payload: false,
          });
          dispatchAppState({ type: TActionType.SetTutorialTipVisible, payload: true });

          const chromeWindowVisible = isChromeBrowser() ? !isChromeExtensionInstalled : false;
          dispatchAppState({ type: TActionType.SetChromeWindowState, payload: chromeWindowVisible });

          setTimeout(
            () => dispatchAppState({ type: TActionType.SetTutorialTipVisible, payload: false }),
            TUTORIAL_TIP_SHOW_DELAY,
          );

          removeQueryParam(navigate, queryParams, SharedQueryParam.TUTORIAL);
          analyticsService.track(AnalyticsActivity.tutorialSkipped, {});
          onSkipAction && onSkipAction();
        }

        if (stepConfig) {
          setActiveStep(stepNumber, stepConfig);
        }
      }
    };

    const onNextButtonClick = () => {
      if (state.steps) {
        const stepNumber = state.currentStepNumber + 1;
        const stepConfig = state.steps.find(s => s.id === stepNumber);
        const lastStep = stepNumber > state.steps.length;

        if (lastStep) {
          dispatch({
            type: TTutorialActionType.SetVisible,
            payload: false,
          });

          setTimeout(() => {
            dispatchAppState({ type: TActionType.SetTutorialDocBeaconVisible, payload: true });
          }, TUTORIAL_DOC_TIP_SHOW_DELAY);

          setTimeout(() => {
            dispatchAppState({ type: TActionType.SetTutorialDocBeaconVisible, payload: false });
          }, TUTORIAL_DOC_TIP_SHOW_DELAY + TUTORIAL_DOC_TIP_HIDE_DELAY);

          analyticsService.track(AnalyticsActivity.tutorialCompleted, {});
          onFinishAction && onFinishAction();
        }

        if (stepConfig) {
          setActiveStep(stepNumber, stepConfig);
        }
      }
    };

    const onClose = (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();

      dispatch({
        type: TTutorialActionType.SetVisible,
        payload: false,
      });
      const chromeWindowVisible = isChromeBrowser() ? !isChromeExtensionInstalled : false;

      dispatchAppState({ type: TActionType.SetTutorialTipVisible, payload: true });
      dispatchAppState({ type: TActionType.SetChromeWindowState, payload: chromeWindowVisible });

      setTimeout(
        () => dispatchAppState({ type: TActionType.SetTutorialTipVisible, payload: false }),
        TUTORIAL_DOC_TIP_SHOW_DELAY,
      );

      analyticsService.track(AnalyticsActivity.tutorialExited, {});
      removeQueryParam(navigate, queryParams, SharedQueryParam.TUTORIAL);
      onSkipAction && onSkipAction();
    };

    return (
      <div className={styles.container}>
        <div className={styles.containerPreloader}>
          <div className={styles.headingImgContainerHello} />
          <div className={styles.headingImgContainerSnippets} />
          <div className={styles.headingImgContainerSuggestions} />
          <div className={styles.headingImgContainerTerms} />
          <div className={styles.headingImgContainerApps} />
          <div className={styles.headingImgContainerStyleguide} />
          <div className={styles.headingImgContainerDocs} />
        </div>
        {state.currentStepsConfig.navigatorArrowStyle && (
          <Navigator arrowStyle={state.currentStepsConfig.navigatorArrowStyle} />
        )}
        {state.currentStepsConfig && (
          <StepWindow
            onClose={onClose}
            flow={state.flow}
            stepsConfig={state.steps}
            currentStepsConfig={state.currentStepsConfig}
            onNextButtonClick={onNextButtonClick}
            onPrevButtonClick={onPrevButtonClick}
          />
        )}
      </div>
    );
  },
);

export default OnboardingTutorial;
