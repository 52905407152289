import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './ResetEmptyFilterData.module.css';

interface IResetEmptyFilterDataProps {
  className?: string;
  onClearFilter?: () => void;
  text?: string;
  actionText?: string;
}

export const ResetEmptyFilterData: React.FC<IResetEmptyFilterDataProps> = ({
  className,
  onClearFilter,
  text = 'No users found matching your filters',
  actionText = 'Clear filters',
}) => (
  <div className={cx(className, styles.emptyContainer)}>
    <div className={styles.emptyHeroTextContainer}>
      <Text variant={TextSize.XXXL} color={TextColor.GREY2}>
        {text}
      </Text>
    </div>
    <div className={cx(styles.emptyContainerButton, styles.clickable)} onClick={onClearFilter}>
      <div className={styles.emptyIconContainer}>
        <Icon name={IconVariant.FILTER} width={32} height={32} className={styles.emptyIcon} />
      </div>
      <div className={styles.emptyTextContainer}>
        <Text variant={TextSize.XXL} color={TextColor.GREY2}>
          {actionText}
        </Text>
      </div>
    </div>
  </div>
);
