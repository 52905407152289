import React, { ChangeEvent, DragEvent } from 'react';

import cn from 'classnames';

import { Heading, HeadingVariant, Text, TextSize } from '@writercolab/ui-atoms';

import { KG_MAX_FILE_SIZE, KG_SUPPORTED_FILE_EXTENSIONS } from '@web/types';

import { validateFiles } from '../../../utils/mediaFilesUtils';

import styles from './DndArea.module.css';

interface IDndAreaProps {
  title?: string;
  description?: string;
  onFileUpload: (files: FileList) => void;
  onFileSizeError: (fileName: string) => void;
  onFileTypeError: (fileName: string) => void;
  onDragLeaveDropzone: (e: DragEvent<HTMLDivElement>) => void;
  className?: string;
}

export const DndArea = ({
  onFileUpload,
  onFileSizeError,
  onFileTypeError,
  onDragLeaveDropzone,
  title,
  description,
  className,
}: IDndAreaProps) => {
  const _preventDefaults = (e: DragEvent<HTMLDivElement> | ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const _handleFileDrop = (e: DragEvent<HTMLDivElement>): void => {
    if (e.dataTransfer.files) {
      validateFiles({
        files: e.dataTransfer.files,
        onSuccess: onFileUpload,
        onFileSizeError,
        onFileTypeError,
        fileSizeLimit: KG_MAX_FILE_SIZE,
        allowedFileExtensions: KG_SUPPORTED_FILE_EXTENSIONS,
      });
    }
  };

  return (
    <div
      className={cn(className, styles.dropzone)}
      onDragOver={_preventDefaults}
      onDragEnd={_preventDefaults}
      onDragLeave={onDragLeaveDropzone}
      onDrop={_handleFileDrop}
    >
      {title && (
        <Heading className={styles.dropzoneHeading} variant={HeadingVariant.H1} upperCase>
          {title}
        </Heading>
      )}

      {description && <Text variant={TextSize.XXXXL}>{description}</Text>}
    </div>
  );
};
