import React from 'react';

import { ISuggestionExampleConfig } from '@writercolab/common-utils';
import { HighlightColor, Text, TextSize } from '@writercolab/ui-atoms';

import { ExampleBlockHeader } from './generic/ExampleBlockHeader';
import ExampleBlockList from './generic/ExampleBlockList';

import commonStyles from '../styles.module.css';
import styles from './styles.module.css';

interface GenderInclusivePronounsExampleProps {}

const exampleConfig: ISuggestionExampleConfig[] = [
  {
    id: '1',
    title: 'Example',
    contents: [
      {
        id: '1',
        content: (
          <div>
            <Text variant={TextSize.L} inline>
              Just remember, when you submit a ticket to an on-call engineer,{' '}
            </Text>
            <Text variant={TextSize.L} highlightColor={HighlightColor.RED} inline>
              he'll
            </Text>
            <Text variant={TextSize.L} inline>
              {' '}
              want to review the customer's account metadata first.
            </Text>
          </div>
        ),
      },
      {
        id: '2',
        content: (
          <div>
            <Text variant={TextSize.L}>
              Just remember, when you submit a ticket to an on-call engineer,{' '}
              <Text variant={TextSize.L} highlightColor={HighlightColor.GREEN} inline>
                they'll
              </Text>{' '}
              want to review the customer's account metadata first.
            </Text>
          </div>
        ),
      },
    ],
  },
];

export const GenderInclusivePronounsExample: React.FC<GenderInclusivePronounsExampleProps> = () => (
  <div className={commonStyles.tooltipContent}>
    {exampleConfig.map(example => (
      <div className={styles.exampleBlockContainer} key={example.id}>
        <ExampleBlockHeader key={example.id}>{example.title}</ExampleBlockHeader>
        <ExampleBlockList blocks={example.contents} />
      </div>
    ))}
  </div>
);

export default GenderInclusivePronounsExample;
