import type React from 'react';

import cx from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  LabelledTags,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { FREE_SUBSCRIPTION_TERMS_LIMIT } from '@web/types';

import snippetsZeroState from '../../../assets/terms/snippetsZeroState.png';
import termsZeroState from '../../../assets/terms/termsZeroState.png';
import { headerTooltipText } from './common';

import headerStyles from './TermsHeader.module.css';
import tableStyles from './TermsTable.module.css';

/** HEADER COMPONENTS */
export const DeleteButton: React.FC<{
  className?: string;
  testId?: string;
  numOfSelectedTerms: number;
  onClick: () => void;
}> = ({ className, testId, numOfSelectedTerms, onClick }) => (
  <>
    <Text variant={TextSize.XXS} extraSmallCaps medium className={headerStyles.textNowrap}>
      {numOfSelectedTerms} selected
    </Text>
    <Tooltip title={headerTooltipText.deleteBtn}>
      <Button
        testId={testId}
        className={cx(className, headerStyles.deleteBtn)}
        type={ButtonTypes.GRAY}
        size={SizeTypes.MEDIUM}
        round
        icon={<Icon name={IconVariant.TRASH} />}
        onClick={onClick}
      />
    </Tooltip>
  </>
);

export const DownloadButton: React.FC<{
  className?: string;
  testId?: string;
  isLoading?: boolean;
}> = ({ className, testId, isLoading }) => (
  <Tooltip title={headerTooltipText.downloadBtn}>
    <Button
      className={className}
      testId={testId}
      type={ButtonTypes.GRAY}
      size={SizeTypes.MEDIUM}
      round
      icon={<Icon name={IconVariant.DOWNLOAD} />}
      isLoading={isLoading}
    />
  </Tooltip>
);

export const ImportButton: React.FC<{
  className?: string;
  testId?: string;
  disabled?: boolean;
  onClick: () => void;
  buttonTooltipText: string | React.ReactNode;
  iconVariant?: IconVariant;
  loading?: boolean;
}> = ({
  className,
  testId,
  onClick,
  disabled = false,
  buttonTooltipText,
  iconVariant = IconVariant.DOWNLOAD,
  loading,
}) => (
  <Tooltip title={buttonTooltipText}>
    <Button
      testId={testId}
      className={className}
      type={ButtonTypes.GRAY}
      size={SizeTypes.MEDIUM}
      round
      icon={<Icon name={iconVariant} />}
      onClick={onClick}
      disabled={disabled}
      isLoading={loading}
    />
  </Tooltip>
);

export const CreateTermContainer = ({
  showViewOnlyTag,
  viewOnlyTagTooltip,
  handleOpenNewTermPopup,
  title = 'New term',
  disabled,
  buttonTooltipText,
  buttonTooltipEnabled,
}) =>
  showViewOnlyTag ? (
    <Tooltip title={viewOnlyTagTooltip} tooltipWidth={178}>
      <LabelledTags bgColor="var(--classic-grey-1)" className={headerStyles.viewOnlyTag}>
        VIEW ONLY
      </LabelledTags>
    </Tooltip>
  ) : (
    <Tooltip title={buttonTooltipText} disabled={buttonTooltipEnabled}>
      <Button
        type={ButtonTypes.PRIMARY}
        className={headerStyles.newButton}
        size={SizeTypes.MEDIUM}
        icon={<Icon name={IconVariant.ADD} />}
        onClick={handleOpenNewTermPopup}
        content={title}
        disabled={disabled}
      />
    </Tooltip>
  );

/** TABLE COMPONENTS */
export const ZeroState = ({ onClick, disableOperations, isSnippets = false }) => (
  <div className={tableStyles.zeroStateContainer}>
    {!disableOperations && (
      <>
        <Text variant={TextSize.XXXL} bold color={TextColor.GREY2}>
          Add your first {isSnippets ? 'snippets' : 'terms'} to get started
        </Text>
        <Button type={ButtonTypes.PRIMARY} size={SizeTypes.XS} onClick={onClick}>
          New
        </Button>
      </>
    )}
    {isSnippets ? (
      <img src={snippetsZeroState} alt="snippets zero state" width={280} />
    ) : (
      <img src={termsZeroState} alt="term zero state" width={280} />
    )}
  </div>
);

/** PAGE COMPONENTS */
export const TermsCounter = ({ count, title = 'term' }) => (
  <Text smallCaps variant={TextSize.XS}>
    {count} {wordPluralize(count, title, 's')}
  </Text>
);

export const TagsFiltersContainer = ({ filtersAmount, onClose }) => (
  <Tooltip title="Filtered by tags" tooltipWidth={125}>
    <div className={headerStyles.filterWrapper}>
      <div className={headerStyles.closeIcon} onClick={onClose}>
        <Icon name={IconVariant.CLOSE} width={8} height={8} />
      </div>

      <div className={headerStyles.icon}>
        <Icon name={IconVariant.FILTER} width={15} height={15} />
      </div>

      <Text variant={TextSize.XS} smallCaps>
        {filtersAmount} {wordPluralize(filtersAmount, 'filter', 's')}
      </Text>
    </div>
  </Tooltip>
);

export const FreeSubscriptionTermsLimitReached = ({ onCtaClick, variant }) => (
  <Text variant={TextSize.L} color={TextColor.WHITE}>
    You’ve reached your limit of {FREE_SUBSCRIPTION_TERMS_LIMIT} {wordPluralize(FREE_SUBSCRIPTION_TERMS_LIMIT, variant)}
    . To get more,{' '}
    <span onClick={onCtaClick} className={headerStyles.clickable}>
      upgrade to Writer Team
    </span>
    .
  </Text>
);
