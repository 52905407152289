import { action, computed, makeObservable, observable } from 'mobx';

import { TApplicationUserType } from '@writercolab/common-utils';
import { PromisedModel } from '@writercolab/mobx';
import { RequestServiceInitialize, components } from '@writercolab/network';
import { getLogger } from '@writercolab/utils';

import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';

import { DEFAULT_PAGINATION_LIMIT } from '../services/request/_types';

interface IPeopleModelOpts {
  request: RequestServiceInitialize['api'];
  orgId: number | undefined;
}

type TPeopleListResponse =
  | components['schemas']['model_PaginatedResult_com_qordoba_user_dto_UserAndTeamsResponse']
  | null;

const LOG = getLogger('PeopleModel');

export class PeopleModel {
  private readonly $people: PromisedModel<any>;
  public loading = false;
  public offset = 0;
  public searchQuery = '';
  public limit = DEFAULT_PAGINATION_LIMIT;

  constructor(private opt: IPeopleModelOpts) {
    this.$people = new PromisedModel({
      name: '$people',
      load: async () => {
        let people: TPeopleListResponse = null;
        this.loading = true;
        try {
          const { data } = await this.opt.request.get('/api/user/v2/organization/{organizationId}', {
            params: {
              path: {
                organizationId: Number(this.opt.orgId),
              },
              query: {
                search: this.searchQuery,
                offset: this.offset,
                limit: this.limit,
                userType: TApplicationUserType.enum.individual,
              },
            },
          });

          people = data;
        } catch (e) {
          LOG.error(e);
        } finally {
          this.loading = false;
        }

        return people;
      },
    });

    makeObservable(this, {
      loading: observable,

      people: computed.struct,

      fetch: action.bound,
    });
  }

  search(query: string) {
    this.searchQuery = trim(query);
    this.$people.reload();
  }

  fetch() {
    this.$people.reload();
  }

  get people(): components['schemas']['com_qordoba_user_dto_UserAndTeamsResponse'][] {
    const people = this.$people.value?.result;

    if (Array.isArray(people) && !isEmpty(people)) {
      return people;
    } else {
      return [];
    }
  }
}
