import React, { useEffect, useMemo } from 'react';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import analytics from 'services/analytics/analyticsService';

import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import SuggestionCategoryWithRules from '../SuggestionCategoryWithRules';
import SuggestionPageHeader from '../SuggestionPageHeader';
import structure from '../structure/punctuation';

import styles from '../styles.module.css';

export const PunctuationPage: React.FC = () => {
  usePageTitle('Punctuation');
  const {
    lastUpdated,
    lastUpdatedBy,
    suggestionSettings,
    isSaving,
    isReadOnly,
    isTeamNameShown,
    updateSectionMeta,
    setSectionEnabled,
    teamName,
  } = useSuggestionsContext();

  useEffect(() => {
    analytics.track(AnalyticsActivity.suggestionsPunctuationViewed, {});
  }, []);

  const result = useMemo(
    () =>
      structure.map(section => (
        <SuggestionCategoryWithRules
          key={`${section.type}${section.name}`}
          readOnly={isReadOnly}
          type={section.type}
          name={section.name}
          tag={section.tag}
          description={section.description}
          structure={section.structure}
          suggestionSettings={suggestionSettings}
          setSectionEnabled={setSectionEnabled}
          updateSectionMeta={updateSectionMeta}
        />
      )),
    [setSectionEnabled, updateSectionMeta, suggestionSettings, isReadOnly],
  );

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Punctuation"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      <div className={styles.description}>
        <div className={styles.descriptionItemShortest}>
          <Text color={TextColor.GREY} variant={TextSize.XL}>
            When a switch is on, we’ll let you know if your content has issues in that category.
          </Text>
        </div>
      </div>
      {result}
    </div>
  );
};

export default PunctuationPage;
