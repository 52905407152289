import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { BillingInformationFormElement, IPaymentMethodAddress } from '@writercolab/common-utils';
import { Input } from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';
import { usePlacesWidget } from 'react-google-autocomplete';

import {
  IGoogleAddressComponent,
  extractAddressLine,
  extractCity,
  extractCountryCode,
  extractStateCode,
  extractZipCode,
} from '../../../../../utils/googleAddressUtils';
import { getLogger } from '../../../../../utils/logger';
import {
  DEFAULT_COUNTRY_CODE,
  getCountriesListForDropdown,
  getStatesListForDropdown,
} from '../../../../../utils/mockUtils';
import { CheckoutDropdown } from '../CheckoutDropdown';

import styles from './styles.module.css';

const LOG = getLogger('BillingAddress');

const { VITE_GOOGLE_MAPS_KEY } = import.meta.env;

interface BillingAddressProps {
  billingAddress?: IPaymentMethodAddress;
  onBillingInfoChange: (e, element: BillingInformationFormElement) => void;
  onSetBillingAddress: (addressData) => void;
  billingAddressValidation: { [key: string]: boolean };
  showZip?: boolean;
  showStates?: boolean;
}

export const BillingAddress: React.FC<BillingAddressProps> = observer(
  ({
    onBillingInfoChange,
    billingAddress,
    billingAddressValidation,
    showZip = true,
    showStates = true,
    onSetBillingAddress,
  }) => {
    const countriesList = useMemo(
      () => getCountriesListForDropdown(billingAddress?.country),
      [billingAddress?.country],
    );
    const statesList = useMemo(() => getStatesListForDropdown(billingAddress?.state), [billingAddress?.state]);

    const [address, setAddress] = useState<IPaymentMethodAddress | null>(billingAddress || null);

    useEffect(() => {
      onSetBillingAddress({
        ...billingAddress,
        ...address,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    useEffect(() => {
      if (billingAddress && !address) {
        setAddress(billingAddress);
      }
    }, [billingAddress, address]);

    const _handleAddressSelect = useCallback(
      (addressComponents: IGoogleAddressComponent[]) => {
        LOG.info('handleAddressSelect', addressComponents);

        const zipCode = extractZipCode(addressComponents, '');
        const country = extractCountryCode(addressComponents);
        const city = extractCity(addressComponents, '');
        const state = extractStateCode(addressComponents);
        const addressLine = extractAddressLine(addressComponents);

        setAddress({
          [BillingInformationFormElement.COUNTRY]: country as string,
          [BillingInformationFormElement.STATE]: state as string,
          [BillingInformationFormElement.POSTAL_CODE]: zipCode as string,
          [BillingInformationFormElement.CITY]: city as string,
          [BillingInformationFormElement.ADDRESS_LINE1]: addressLine,
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [address],
    );

    const { ref } = usePlacesWidget({
      apiKey: VITE_GOOGLE_MAPS_KEY,
      onPlaceSelected: place => _handleAddressSelect(place.address_components),
      language: 'en',
      options: {
        fields: ['address_components'],
        strictBounds: true,
        types: ['address'],
        componentRestrictions: { country: billingAddress?.country || DEFAULT_COUNTRY_CODE },
      },
    });

    const _handleChooseCountry = useCallback(
      (id: string) => {
        onBillingInfoChange(id, BillingInformationFormElement.COUNTRY);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [billingAddress],
    );
    const _handleChooseState = useCallback(
      (id: string) => onBillingInfoChange(id, BillingInformationFormElement.STATE),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [billingAddress],
    );

    return (
      <div className={styles.container}>
        <div className={styles.containerForm}>
          <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
            <Input
              className={cx(styles.cardNativeInput, styles.topInputBorders, {
                [styles.cardInputInvalid]: billingAddressValidation[BillingInformationFormElement.NAME],
              })}
              classNameFocused={styles.cardNativeInputFocused}
              placeholder="Name"
              onChange={e => setAddress({ ...address, [BillingInformationFormElement.NAME]: e.target.value })}
              value={address?.[BillingInformationFormElement.NAME]}
            />
          </div>
          <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
            <CheckoutDropdown onOptionClickAction={_handleChooseCountry} options={countriesList} />
          </div>
          <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
            <Input
              className={cx(styles.cardNativeInput, styles.topLeftRightBorders, {
                [styles.cardInputInvalid]: billingAddressValidation[BillingInformationFormElement.ADDRESS_LINE1],
              })}
              placeholder="Address line 1"
              classNameFocused={styles.cardNativeInputFocused}
              onChange={e => setAddress({ [BillingInformationFormElement.ADDRESS_LINE1]: e.target.value })}
              value={address?.[BillingInformationFormElement.ADDRESS_LINE1]}
              inputRef={ref}
            />
          </div>
          <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
            <Input
              className={cx(styles.cardNativeInput, styles.topLeftRightBorders)}
              placeholder="Address line 2"
              classNameFocused={styles.cardNativeInputFocused}
              onChange={e => setAddress({ ...address, [BillingInformationFormElement.ADDRESS_LINE2]: e.target.value })}
              value={address?.[BillingInformationFormElement.ADDRESS_LINE2]}
            />
          </div>
          <div className={cx(styles.containerFormRowInline)}>
            <div className={cx(styles.containerFormRowShort)}>
              <Input
                className={cx(styles.cardNativeInput, styles.topLeftRightBorders, {
                  [styles.bottomLeftBorder]: !showStates,
                  [styles.cardInputInvalid]: billingAddressValidation[BillingInformationFormElement.CITY],
                })}
                placeholder="City"
                classNameFocused={styles.cardNativeInputFocused}
                onChange={e => setAddress({ ...address, [BillingInformationFormElement.CITY]: e.target.value })}
                value={address?.[BillingInformationFormElement.CITY]}
              />
            </div>
            <div className={cx(styles.containerFormRowShort)}>
              <Input
                className={cx(styles.cardNativeInput, styles.topLeftRightBorders, {
                  [styles.bottomRightBorder]: !showStates,
                  [styles.cardInputInvalid]: billingAddressValidation[BillingInformationFormElement.POSTAL_CODE],
                })}
                placeholder={showZip ? 'ZIP' : 'Postal Code'}
                classNameFocused={styles.cardNativeInputFocused}
                onChange={e => setAddress({ ...address, [BillingInformationFormElement.POSTAL_CODE]: e.target.value })}
                value={address?.[BillingInformationFormElement.POSTAL_CODE]}
              />
            </div>
          </div>
          {showStates && (
            <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
              <CheckoutDropdown
                onOptionClickAction={_handleChooseState}
                options={statesList}
                className={styles.bottomBorders}
              />
            </div>
          )}
        </div>
      </div>
    );
  },
);

export default BillingAddress;
