import React from 'react';

import { Button, ButtonTypes, Text, TextSize } from '@writercolab/ui-atoms';

import contactSales from '../../../assets/backgrounds/contactSales.png';
import { openContactSalesPage } from '../../../utils/navigationUtils';

import styles from './styles.module.css';

export const ContactSalesInfographic = () => (
  <div className={styles.contactContainer}>
    <img src={contactSales} alt="contact sales images" height={151} />
    <Text variant={TextSize.S} bold className={styles.contactText}>
      Manage multiple teams with Writer for Enterprise
    </Text>
    <Button type={ButtonTypes.SECONDARY} onClick={openContactSalesPage}>
      Contact sales
    </Button>
  </div>
);

export default ContactSalesInfographic;
