import qs from 'qs';

import type { SignupType } from '@writercolab/common-utils';
import { Enum } from '@writercolab/utils';

import { OrgJoinNewUserStatus } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import type { Location } from 'react-router';

import { REACT_RELATIVE_ROUTE } from '../services/config/routes';

export const TExtensionAppAuthQueryParams = new Enum(
  'client_id',
  'code_challenge',
  'code_challenge_method',
  'redirect_url',
  'write_key',
);
export const TQueryParamBooleanStd = new Enum('true', 'false');

export const isUserActionQueryParamValid = (userAction: string): boolean =>
  Object.values(OrgJoinNewUserStatus)
    .map(s => `${s}`)
    .includes(userAction);

export const redirectLocation = (location: Location): string | null => {
  const { redirectTo, redirectUrl } = qs.parse(location.search.slice(1), {
    charset: 'utf-8',
    charsetSentinel: true,
  });
  let targetRedirectUrl = (redirectTo || redirectUrl) as string | null;

  if (targetRedirectUrl && isAppAuthRoute(String(targetRedirectUrl))) {
    targetRedirectUrl = prepareExtensionAuthRedirectUrl(location);
  }

  return targetRedirectUrl || null;
};

export const prepareExtensionAuthRedirectUrl = (location: Location): string => {
  // eslint-disable-next-line camelcase
  const {
    redirectTo,
    redirectUrl,
    code_challenge: codeChallenge,
    code_challenge_method: codeChallengeMethod,
    redirect_url: queryRedirectUrl,
    write_key: writeKey,
  } = qs.parse(location.search.slice(1), {
    charset: 'utf-8',
    charsetSentinel: true,
  });

  const targetRedirectUrl = (redirectTo || redirectUrl) as string | null;
  const searchParams = new URLSearchParams(String(targetRedirectUrl));

  if (codeChallenge && codeChallengeMethod) {
    searchParams.append(TExtensionAppAuthQueryParams.enum.code_challenge, String(codeChallenge));
    searchParams.append(TExtensionAppAuthQueryParams.enum.code_challenge_method, String(codeChallengeMethod));
  }

  if (queryRedirectUrl) {
    searchParams.append(TExtensionAppAuthQueryParams.enum.redirect_url, String(queryRedirectUrl));
  }

  if (writeKey) {
    searchParams.append(TExtensionAppAuthQueryParams.enum.write_key, String(writeKey));
  }

  return decodeURIComponent(searchParams.toString());
};

export const isAppAuthRoute = (url: string): boolean => url.includes(REACT_RELATIVE_ROUTE.authApp);

export const isRemoveAccountParamExist = (location: Location): boolean => {
  const { delete_token: deleteToken } = qs.parse(location.search.slice(1));

  return Boolean(deleteToken && !isEmpty(deleteToken));
};

export const isFirstSignIn = (location: Location): boolean => {
  const { firstSignIn, registered } = qs.parse(location.search.slice(1));

  return firstSignIn === TQueryParamBooleanStd.enum.true || registered === TQueryParamBooleanStd.enum.true;
};

export const marketingConsentState = (location: Location): boolean => {
  const { emailMarketingConsent } = qs.parse(location.search.slice(1));

  return !!(
    emailMarketingConsent &&
    emailMarketingConsent !== 'null' &&
    emailMarketingConsent === TQueryParamBooleanStd.enum.true
  );
};

export const isInvitedSignup = (location: Location): boolean => {
  const { invited } = qs.parse(location.search.slice(1));

  return invited === TQueryParamBooleanStd.enum.true;
};

export const getSignupType = (location: Location): SignupType | null => {
  const { signupType } = qs.parse(location.search.slice(1));

  return signupType ? (signupType as SignupType) : null;
};

export const ensureQueryParamIsArray = (value: any) => {
  if (Array.isArray(value)) {
    return value;
  }

  if (value) {
    return [value];
  }

  return [];
};
