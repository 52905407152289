import type React from 'react';

import { Button, ButtonTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { type ErrorBoundaryProps, type FallbackProps, ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useRouteError } from 'react-router';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { reportRenderError } from 'utils/datadogUtils';

import sadCharacter from '../../../assets/backgrounds/sadCharacter.png';

import styles from './ErrorBoundary.module.css';

export const ErrorBoundaryFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => (
  <div className={styles.container}>
    <Text variant={TextSize.XXL} bold>
      Oops! Something went wrong.
    </Text>
    <img src={sadCharacter} alt="Error Illustration" width={120} />
    <Text variant={TextSize.XXL}>An error occurred:</Text>
    {error && (
      <>
        <Text variant={TextSize.XXXXL} color={TextColor.ORANGE}>
          [{error.message}]
        </Text>
        <SyntaxHighlighter language="javascript" style={docco} className={styles.stackTrace}>
          {error.stack}
        </SyntaxHighlighter>
        <a
          href={`https://stackoverflow.com/search?q=${encodeURIComponent(error.message)}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Search for this error on Stack Overflow
        </a>
      </>
    )}
    <Button className={styles.resetButton} onClick={resetErrorBoundary} type={ButtonTypes.PRIMARY}>
      Reset State
    </Button>
  </div>
);

// Main ErrorBoundary component
export const ErrorBoundary: React.FC<Partial<ErrorBoundaryProps>> = ({
  children,
  FallbackComponent,
  onReset,
  onError,
  resetKeys,
}) => {
  const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
    // Default error logging
    reportRenderError({ name: 'ReactErrorBoundary', error, info: errorInfo });

    if (onError) {
      onError(error, errorInfo);
    }
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={FallbackComponent ?? ErrorBoundaryFallback}
      onError={handleError}
      onReset={onReset}
      resetKeys={resetKeys}
    >
      {children}
    </ReactErrorBoundary>
  );
};

export const ErrorRouteComponent: React.FC = () => {
  const error = useRouteError();

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: 'red' }}>
      <h1>Oops! An error occurred</h1>
      {error instanceof Error ? (
        <>
          <h2>Error Message:</h2>
          <p>{error.message}</p>
          <h3>Stack Trace:</h3>
          <pre style={{ backgroundColor: '#f8f8f8', padding: '10px' }}>{error.stack}</pre>
        </>
      ) : (
        <p>An unknown error occurred.</p>
      )}
    </div>
  );
};
