import type { ReactNode } from 'react';
import type React from 'react';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';

import type { ITeam, IUserProfile } from '@writercolab/common-utils';
import { type DropdownOption, useCustomSnackbar } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { snackbarMessages } from '@web/component-library';
import type { ActionMap, OrgDomain, OrgDomainId } from '@web/types';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import {
  addOrgDomainFromEmail,
  getOrgDomains,
  updateOrgDomain,
  validateOrgDomainFromEmail,
} from '../services/request/AutoJoinOrg';
import requestService from '../services/request/requestService';
import { useAppState } from '../state';
import { getDomainNameFromEmail } from '../utils/emailUtils';
import { getLogger } from '../utils/logger';

const LOG = getLogger('domainDiscoverabilityPreferencesContext');

type DomainDiscoverabilityPreferencesState = {
  isListLoading: boolean;
  teams: ITeam[];
  orgDomains: OrgDomain[];
  accessTypeOptions: DropdownOption[];
  teamsOptions: DropdownOption[];
  profileEmailDomain: string;
  profileEmailDomainValid: boolean;
  showSsoPageBanner: boolean;
  access: Record<domainDiscoverabilityAccess, boolean>;
};

interface IDomainDiscoverabilityPreferencesContext {
  context: DomainDiscoverabilityPreferencesState;
  onDomainJoinRequiresApprovalChange: (domainId: OrgDomainId, isApprovalRequired: boolean) => void;
  onDomainAccessTypeChange: (domainId: OrgDomainId, type: typeof TAccessTypeOptionId.type) => void;
  onDomainTeamChange: (domainId: OrgDomainId, teamIds: string[]) => void;
  onDeleteDomain: (domainId: OrgDomainId) => void;
  enableProfileEmailDomain: () => void;
}

enum TDomainDiscoverabilityPreferencesType {
  SetIsListLoading = 'isListLoading',
  SetTeamsOptions = 'setTeams',
  SetOrgDomains = 'setOrgDomains',
  SetShowSsoPageBanner = 'setShowSsoPageBanner',
  SetProfileEmailDomain = 'setProfileEmailDomain',
  SetProfileEmailDomainValid = 'setProfileEmailDomainValid',
  SetAccess = 'setAccess',
}

interface IDomainDiscoverabilityPreferencesProvider {
  children?: ReactNode;
  orgId: number | undefined;
  teams?: DropdownOption[];
  userProfile: IUserProfile | undefined;
  showSsoPageBanner?: boolean;
  showTrialBanner?: boolean;
  ssoPasswordEnabled?: boolean;
  ssoSamlEnabled?: boolean;
}

type DomainDiscoverabilityPreferencesPayload = {
  [TDomainDiscoverabilityPreferencesType.SetTeamsOptions]: DropdownOption[];
  [TDomainDiscoverabilityPreferencesType.SetOrgDomains]: OrgDomain[];
  [TDomainDiscoverabilityPreferencesType.SetProfileEmailDomain]: string;
  [TDomainDiscoverabilityPreferencesType.SetIsListLoading]: boolean;
  [TDomainDiscoverabilityPreferencesType.SetProfileEmailDomainValid]: boolean;
  [TDomainDiscoverabilityPreferencesType.SetShowSsoPageBanner]: boolean;
  [TDomainDiscoverabilityPreferencesType.SetShowSsoPageBanner]: boolean;
  [TDomainDiscoverabilityPreferencesType.SetAccess]: Record<domainDiscoverabilityAccess, boolean>;
};

type TDomainDiscoverabilityPreferencesActions =
  ActionMap<DomainDiscoverabilityPreferencesPayload>[keyof ActionMap<DomainDiscoverabilityPreferencesPayload>];

const DomainDiscoverabilityPreferencesContext = createContext<IDomainDiscoverabilityPreferencesContext>(
  {} as IDomainDiscoverabilityPreferencesContext,
);

const domainDiscoverabilityPreferencesReducer = (
  state: DomainDiscoverabilityPreferencesState,
  action: TDomainDiscoverabilityPreferencesActions,
) => {
  let newState: DomainDiscoverabilityPreferencesState;

  switch (action.type) {
    case TDomainDiscoverabilityPreferencesType.SetIsListLoading:
      newState = { ...state, isListLoading: action.payload };
      break;
    case TDomainDiscoverabilityPreferencesType.SetTeamsOptions:
      newState = { ...state, teamsOptions: action.payload };
      break;
    case TDomainDiscoverabilityPreferencesType.SetOrgDomains:
      newState = { ...state, orgDomains: action.payload };
      break;
    case TDomainDiscoverabilityPreferencesType.SetProfileEmailDomain:
      newState = { ...state, profileEmailDomain: action.payload };
      break;
    case TDomainDiscoverabilityPreferencesType.SetProfileEmailDomainValid:
      newState = { ...state, profileEmailDomainValid: action.payload };
      break;
    case TDomainDiscoverabilityPreferencesType.SetShowSsoPageBanner:
      newState = { ...state, showSsoPageBanner: action.payload };
      break;
    case TDomainDiscoverabilityPreferencesType.SetAccess:
      newState = { ...state, access: action.payload };
      break;
    default:
      newState = { ...state };
  }

  return newState;
};

export const TAccessTypeOptionId = new Enum('allow', 'disallow');

export enum domainDiscoverabilityAccess {
  CAN_ENABLE_PROFILE_DOMAIN = 'canEnableProfileDomain',
  CAN_SHOW_DOMAINS_LIST = 'canShowDomainsList',
  ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION = 'alreadyTakenByAnotherOrganization',
  SHOW_SSO_PAGE_BANNER = 'showSsoPageBanner',
  SHOW_LOCK_TRIAL_BANNER = 'showLockTrialBanner',
  SHOW_ACCESS_FOR_PASSWORD_USERS = 'showAccessForPasswordUsers',
  SHOW_ORG_ACCESS = 'showOrgAccess',
}

type CreateAccessPayload = { [k in domainDiscoverabilityAccess]?: boolean };

const mapDomains = (domainsList: OrgDomain[], affectedDomain: OrgDomain): OrgDomain[] =>
  domainsList.map(d => (d.id === affectedDomain.id ? affectedDomain : d));

const initialDomainDiscoverabilityPreferencesState: DomainDiscoverabilityPreferencesState = {
  isListLoading: true,
  showSsoPageBanner: false,
  teams: [],
  accessTypeOptions: [
    {
      id: TAccessTypeOptionId.enum.allow,
      name: 'Allow immediate access',
    },
    {
      id: TAccessTypeOptionId.enum.disallow,
      name: 'Require org admin approval',
    },
  ],
  teamsOptions: [],
  orgDomains: [],
  profileEmailDomain: '',
  profileEmailDomainValid: false,
  access: {
    [domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]: false,
    [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: false,
    [domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION]: false,
    [domainDiscoverabilityAccess.SHOW_SSO_PAGE_BANNER]: false,
    [domainDiscoverabilityAccess.SHOW_LOCK_TRIAL_BANNER]: false,
    [domainDiscoverabilityAccess.SHOW_ACCESS_FOR_PASSWORD_USERS]: false,
    [domainDiscoverabilityAccess.SHOW_ORG_ACCESS]: false,
  },
};

const DomainDiscoverabilityPreferencesContextProvider: React.FC<IDomainDiscoverabilityPreferencesProvider> = observer(
  ({ orgId, teams, userProfile, showSsoPageBanner, ssoPasswordEnabled, ssoSamlEnabled, showTrialBanner, children }) => {
    const { appState, appModel } = useAppState();
    const { enqueueErrorSnackbar, enqueueDeleteSnackbar } = useCustomSnackbar();
    const [context, dispatch] = useReducer(
      domainDiscoverabilityPreferencesReducer,
      initialDomainDiscoverabilityPreferencesState,
    );
    const access = {
      canShowDomainsList: context.access[domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST],
    };

    const createAccessPayload = (payload: CreateAccessPayload): Record<domainDiscoverabilityAccess, boolean> => ({
      ...context.access,
      ...payload,
    });

    useEffect(() => {
      let access: Record<domainDiscoverabilityAccess, boolean>;

      if (showSsoPageBanner) {
        dispatch({
          type: TDomainDiscoverabilityPreferencesType.SetAccess,
          payload: createAccessPayload({
            ...context.access,
            [domainDiscoverabilityAccess.SHOW_SSO_PAGE_BANNER]: showSsoPageBanner,
            [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: false,
          }),
        });

        return;
      }

      if (showTrialBanner) {
        dispatch({
          type: TDomainDiscoverabilityPreferencesType.SetAccess,
          payload: createAccessPayload({
            ...context.access,
            [domainDiscoverabilityAccess.SHOW_LOCK_TRIAL_BANNER]: showTrialBanner,
            [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: false,
          }),
        });

        return;
      }

      if (ssoSamlEnabled) {
        access = createAccessPayload({
          [domainDiscoverabilityAccess.SHOW_ACCESS_FOR_PASSWORD_USERS]: ssoPasswordEnabled,
          [domainDiscoverabilityAccess.SHOW_ORG_ACCESS]: ssoPasswordEnabled,
        });
      } else {
        access = createAccessPayload({
          [domainDiscoverabilityAccess.SHOW_ACCESS_FOR_PASSWORD_USERS]: false,
          [domainDiscoverabilityAccess.SHOW_ORG_ACCESS]: true,
          [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: true,
        });
      }

      dispatch({
        type: TDomainDiscoverabilityPreferencesType.SetAccess,
        payload: access,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [access.canShowDomainsList, showTrialBanner, ssoPasswordEnabled, showSsoPageBanner, ssoSamlEnabled]);

    useEffect(() => {
      dispatch({
        type: TDomainDiscoverabilityPreferencesType.SetAccess,
        payload: createAccessPayload({
          [domainDiscoverabilityAccess.SHOW_LOCK_TRIAL_BANNER]: appModel.assistantSubscription.isTrial,
          [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: false,
        }),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appModel.assistantSubscription.isTrial]);

    useEffect(() => {
      if (teams && teams.length) {
        dispatch({
          type: TDomainDiscoverabilityPreferencesType.SetTeamsOptions,
          payload: teams.map(t => ({ id: `${t.id}`, name: t.name })),
        });
      }
    }, [teams]);

    useEffect(() => {
      if (userProfile && userProfile.email) {
        dispatch({
          type: TDomainDiscoverabilityPreferencesType.SetProfileEmailDomain,
          payload: getDomainNameFromEmail(userProfile.email),
        });
      }
    }, [userProfile]);

    useEffect(() => {
      if (orgId) {
        fetchOrgDomains();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    const validateEmailDomain = useCallback(async () => {
      if (orgId) {
        await validateOrgDomainFromEmail(orgId);
      }
    }, [orgId]);

    useEffect(() => {
      if (appState.userProfile?.email?.length && !context.isListLoading) {
        const userOrgDomain = getDomainNameFromEmail(appState.userProfile.email);
        const isUserOrgDomainExist = context.orgDomains.find(d => d.domain === userOrgDomain);

        if (isUserOrgDomainExist) {
          // show domains list with banner and ability to add new domain
          dispatch({
            type: TDomainDiscoverabilityPreferencesType.SetAccess,
            payload: createAccessPayload({
              [domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]: false,
              [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: true,
              [domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION]: false,
            }),
          });
        } else {
          validateEmailDomain()
            .then(() => {
              if (context.orgDomains.length) {
                // show domains list with banner and ability to add new domain
                dispatch({
                  type: TDomainDiscoverabilityPreferencesType.SetAccess,
                  payload: createAccessPayload({
                    [domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]: true,
                    [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: !!context.orgDomains.length,
                    [domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION]: false,
                  }),
                });
              } else {
                // show domains list without banner and ability to add new domain
                dispatch({
                  type: TDomainDiscoverabilityPreferencesType.SetAccess,
                  payload: createAccessPayload({
                    [domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]: true,
                    [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: true,
                    [domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION]: false,
                  }),
                });
              }
            })
            .catch(() => {
              if (context.orgDomains.length) {
                // show domains list with banner
                dispatch({
                  type: TDomainDiscoverabilityPreferencesType.SetAccess,
                  payload: createAccessPayload({
                    [domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]: false,
                    [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: true,
                    [domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION]: true,
                  }),
                });
              } else {
                // show only banner that say that domain is already taken by another organization
                dispatch({
                  type: TDomainDiscoverabilityPreferencesType.SetAccess,
                  payload: createAccessPayload({
                    [domainDiscoverabilityAccess.CAN_ENABLE_PROFILE_DOMAIN]: false,
                    [domainDiscoverabilityAccess.CAN_SHOW_DOMAINS_LIST]: false,
                    [domainDiscoverabilityAccess.ALREADY_TAKEN_BY_ANOTHER_ORGANIZATION]: true,
                  }),
                });
              }
            });
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.orgDomains, appState.userProfile, orgId, context.isListLoading]);

    const fetchOrgDomains = useCallback(async () => {
      if (!appModel.assistantSubscription.isActive) {
        LOG.info('Subscription is not active');

        return;
      }

      dispatch({
        type: TDomainDiscoverabilityPreferencesType.SetIsListLoading,
        payload: true,
      });

      try {
        const orgDomains = await getOrgDomains(orgId);
        dispatch({
          type: TDomainDiscoverabilityPreferencesType.SetOrgDomains,
          payload: orgDomains,
        });
      } catch (err) {
        LOG.error(err);
        enqueueErrorSnackbar('Something went wrong while fetching domains');
      } finally {
        dispatch({
          type: TDomainDiscoverabilityPreferencesType.SetIsListLoading,
          payload: false,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId, appModel.assistantSubscription.isActive]);

    const onDomainJoinRequiresApprovalChange = useCallback(
      async (affectedDomainId: OrgDomainId, allowJoin: boolean) => {
        const domain = context.orgDomains.find(d => d.id === affectedDomainId);

        if (domain) {
          if (isEmpty(domain.joinTeamIds) && allowJoin) {
            const suggestedTeamId = first(context.teamsOptions)?.id;

            if (suggestedTeamId) {
              domain.joinTeamIds = [+suggestedTeamId];
            }
          }

          domain.allowJoin = allowJoin;
          dispatch({
            type: TDomainDiscoverabilityPreferencesType.SetOrgDomains,
            payload: mapDomains(context.orgDomains, domain),
          });

          try {
            await updateOrgDomain(orgId, domain.id, { ...domain });
          } catch (err) {
            LOG.error(err);
            enqueueErrorSnackbar('Something went wrong while updating domain');
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [orgId, context.orgDomains],
    );

    const onDomainAccessTypeChange = useCallback(
      async (affectedDomainId: OrgDomainId, accessType: typeof TAccessTypeOptionId.type) => {
        const domain = context.orgDomains.find(d => d.id === affectedDomainId);

        if (domain) {
          domain.joinRequiresApproval = accessType !== TAccessTypeOptionId.enum.allow;

          dispatch({
            type: TDomainDiscoverabilityPreferencesType.SetOrgDomains,
            payload: mapDomains(context.orgDomains, domain),
          });

          try {
            await updateOrgDomain(orgId, domain.id, { ...domain });
          } catch (err) {
            LOG.error(err);
            enqueueErrorSnackbar('Something went wrong while updating domain');
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [context.orgDomains, orgId],
    );

    const enableProfileEmailDomain = useCallback(async () => {
      try {
        await validateOrgDomainFromEmail(orgId);
        await addOrgDomainFromEmail(orgId);
        await fetchOrgDomains();
      } catch (err: any) {
        if (err?.response?.data?.errors[0]?.description) {
          enqueueErrorSnackbar(snackbarMessages.user.inviteError(err.response.data.errors[0].description));
        }

        LOG.error(err);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgId]);

    const onDeleteDomain = useCallback(
      async (affectedDomainId: OrgDomainId) => {
        const domain = context.orgDomains.find(domain => domain.id === affectedDomainId);

        if (!domain) {
          LOG.error('Domain not found');

          return;
        }

        try {
          await requestService.api.delete('/api/organization/v2/{organizationId}/domain/{domainId}', {
            params: {
              path: {
                organizationId: parseInt(`${orgId}`, 10),
                domainId: parseInt(`${domain.id}`, 10),
              },
            },
          });

          await fetchOrgDomains();

          enqueueDeleteSnackbar(snackbarMessages.domainDiscoverability.deleteSuccess(domain.domain));
        } catch (err) {
          LOG.error(err);
          enqueueErrorSnackbar(snackbarMessages.domainDiscoverability.deleteError);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [context.orgDomains, orgId],
    );

    const onDomainTeamChange = useCallback(
      async (affectedDomainId: OrgDomainId, teamIds: string[]) => {
        const domain = context.orgDomains.find(domain => domain.id === affectedDomainId);

        if (!domain) {
          return;
        }

        try {
          domain.joinTeamIds = teamIds.map(id => +id);
          const _domain = await updateOrgDomain(orgId, affectedDomainId, { ...domain });
          dispatch({
            type: TDomainDiscoverabilityPreferencesType.SetOrgDomains,
            payload: mapDomains(context.orgDomains, _domain),
          });
        } catch (err) {
          LOG.error(err);
          enqueueErrorSnackbar('Something went wrong while updating domain');
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [context.orgDomains, orgId],
    );

    return (
      <DomainDiscoverabilityPreferencesContext.Provider
        value={{
          context,
          onDeleteDomain,
          onDomainJoinRequiresApprovalChange,
          onDomainAccessTypeChange,
          onDomainTeamChange,
          enableProfileEmailDomain,
        }}
      >
        {children}
      </DomainDiscoverabilityPreferencesContext.Provider>
    );
  },
);

export function useDomainDiscoverabilityPreferencesContext() {
  const context = useContext(DomainDiscoverabilityPreferencesContext);

  if (!context) {
    throw new Error(
      'useDomainDiscoverabilityPreferencesContext must be used within the DomainDiscoverabilityPreferencesContextProvider constructor',
    );
  }

  return context;
}

export default DomainDiscoverabilityPreferencesContextProvider;
