import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Dropdown,
  type DropdownOption,
  Icon,
  IconVariant,
  Modal,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';
import { IAutocompleteValues, InputGroup, InputTypes } from '@writercolab/ui-molecules';

import { IAdminsOptionsList, IMappedTeamDetails } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { TCreatTeamState } from '../../../../context/teamsContext';
import { findUserInOrganization } from '../../../../services/request/user';
import { sortByNameProperty } from '../../../../utils/arrayUtils';
import { extractPlaceholder } from '../../../../utils/dropdownUtils';
import DropdownTrigger from '../../../molecules/DropdownTrigger';

import styles from './styles.module.css';

export interface ICreateTeamForm {
  name: IMappedTeamDetails['name'];
  admins: IAutocompleteValues[];
}

interface ICreateTeamProps {
  isOpen: boolean;
  changeModalState: () => void;
  onSubmit: (state: TCreatTeamState) => Promise<void>;
  values?: TCreatTeamState;
  orgId?: number;
  teamsList: IMappedTeamDetails[];
}

const initState: TCreatTeamState = {
  name: '',
  admins: [],
  copyTermsFrom: null,
  copySuggestionsFrom: null,
  copySnippetsFrom: null,
};

const reducer = (state: TCreatTeamState, { field, value }) => ({
  ...state,
  [field]: value,
});

const CopyFromTeamDropdown: React.FC<{
  title: string;
  icon: IconVariant;
  options: DropdownOption[];
  onChange: (id: DropdownOption['id']) => void;
}> = ({ title, icon, options, onChange }) => (
  <div className={styles.copyFromContainer}>
    <div className={styles.copyFromTextInputLabel}>
      <Icon name={icon} />
      <Text variant={TextSize.XS} extraSmallCaps medium className={styles.copyFromLabelDescription}>
        {title}
      </Text>
    </div>
    <div className={styles.copyFromDropdownContainer}>
      <Dropdown
        dropDownContainerClassName={styles.teamsDropdown}
        onPrimaryOptionClickAction={onChange}
        trigger={
          <DropdownTrigger
            placeholder={extractPlaceholder(options, 'Select a team to copy from')}
            triggerSize={TextSize.S}
          />
        }
        options={options}
      />
    </div>
  </div>
);

const mappedTeamToDropdownOption = (team: IMappedTeamDetails): DropdownOption => ({
  id: `${team.id}`,
  name: team.name,
  active: false,
});

const formatTeamList = (teams: IMappedTeamDetails[]): DropdownOption[] =>
  teams.map(mappedTeamToDropdownOption).sort(sortByNameProperty);

const TEAM_NAME_MAX_LENGTH = 35;

export const CreatTeamModal: React.FC<ICreateTeamProps> = ({
  isOpen,
  changeModalState,
  onSubmit,
  values = initState,
  orgId,
  teamsList,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, values);
  const [adminOptions, setAdminOptions] = useState<IAdminsOptionsList[]>([]);
  const [teamNameChanged, setTeamNameChanged] = useState(false);
  const [teamAdminsChanged, setTeamAdminsChanged] = useState(false);
  const [copyTermsTeams, setCopyTermsTeams] = useState<DropdownOption[]>([]);
  const [copySuggestionsTeams, setCopySuggestionsTeams] = useState<DropdownOption[]>([]);
  const [copySnippetsTeams, setCopySnippetsTeams] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (isEmpty(teamsList)) {
      return;
    }

    setCopyTermsTeams(formatTeamList(teamsList));
    setCopySuggestionsTeams(formatTeamList(teamsList));
    setCopySnippetsTeams(formatTeamList(teamsList));
  }, [teamsList]);

  useEffect(() => {
    if (isOpen) {
      setCopyTermsTeams(formatTeamList(teamsList).map(option => ({ ...option, active: false })));
      setCopySuggestionsTeams(formatTeamList(teamsList).map(option => ({ ...option, active: false })));
      setCopySnippetsTeams(formatTeamList(teamsList).map(option => ({ ...option, active: false })));
    }
  }, [isOpen, teamsList]);

  const onChangeInput = e => {
    setTeamNameChanged(true);
    dispatch({ field: e.target.name, value: e.target.value });
  };

  const onChangeAdmins = useCallback(values => {
    setTeamAdminsChanged(true);
    dispatch({ field: 'admins', value: values });
  }, []);

  const setCopyFromHandler =
    (
      fn: React.Dispatch<React.SetStateAction<DropdownOption[]>>,
      field: keyof Pick<TCreatTeamState, 'copyTermsFrom' | 'copySuggestionsFrom' | 'copySnippetsFrom'>,
    ) =>
    (id: DropdownOption['id']) => {
      fn(prevState => prevState.map(option => ({ ...option, active: option.id === id })));
      dispatch({ field, value: id });
    };

  const onCopyTermsTeamsChange = setCopyFromHandler(setCopyTermsTeams, 'copyTermsFrom');
  const onCopySuggestionsTeamsChange = setCopyFromHandler(setCopySuggestionsTeams, 'copySuggestionsFrom');
  const onCopySnippetsTeamsChange = setCopyFromHandler(setCopySnippetsTeams, 'copySnippetsFrom');

  const onInputChangeAdmins = useCallback(
    values => {
      findUserInOrganization(orgId, { search: values, offset: 0, limit: 50 }).then(({ result }) => {
        const adminList: IAdminsOptionsList[] = result.map(({ user }) => ({
          name: user.fullName,
          subText: user.email || '',
          id: user.id,
        }));
        setAdminOptions(adminList);
      });
    },
    [orgId],
  );

  const { name, admins } = state;

  let teamNameError;
  const teamAdminsError = admins.length === 0 ? 'required field' : undefined;

  if (name.length === 0) {
    teamNameError = 'required field';
  }

  if (name.length > TEAM_NAME_MAX_LENGTH) {
    teamNameError = `${TEAM_NAME_MAX_LENGTH} ${wordPluralize(TEAM_NAME_MAX_LENGTH, 'character')} limit`;
  }

  if (teamsList.some(team => team.name.toLowerCase() === name.toLowerCase())) {
    teamNameError = 'team name already in use';
  }

  const handleCreateTeam = async () => {
    setLoading(true);
    await onSubmit(state);
    setLoading(false);
    changeModalState();
    setTeamNameChanged(false);
    setTeamAdminsChanged(false);
  };

  useEffect(() => {
    Object.keys(initState).map(i => dispatch({ field: i, value: values[i] }));
  }, [values]);

  return (
    <Modal open={isOpen} handleClose={changeModalState} title="Create a team" style={{ width: '410px' }} {...props}>
      <form className={styles.styledForm}>
        <InputGroup
          value={name}
          name="name"
          inputType={InputTypes.INPUT}
          handleChangeInput={onChangeInput}
          errorText={teamNameChanged ? teamNameError : undefined}
          id="name"
          label="Team name"
        />
        <InputGroup
          className={styles.styledInputGroup}
          name="admins"
          inputType={InputTypes.AUTOCOMPLETE}
          handleChangeInput={onChangeAdmins}
          onInputChange={onInputChangeAdmins}
          autocompleteOptions={adminOptions}
          defaultValue={admins}
          errorText={teamAdminsChanged ? teamAdminsError : undefined}
          prefilledTagsTooltip="Org admins are auto-added to each team"
          id="admins"
          label="Team admins"
          placeholder="Type a name to add"
          style={{ minHeight: '70px' }}
        />
        <div className={styles.copyFromContainer}>
          <div className={styles.copyFromTextContainer}>
            <Text variant={TextSize.S}>Copy suggestions settings, terms, or snippets over from an existing team</Text>
          </div>
          <div className={styles.copyFromTextInputs}>
            <CopyFromTeamDropdown
              title="suggestions"
              icon={IconVariant.SUGGESTIONS}
              options={copySuggestionsTeams}
              onChange={onCopySuggestionsTeamsChange}
            />
            <CopyFromTeamDropdown
              title="terms"
              icon={IconVariant.TERMS}
              options={copyTermsTeams}
              onChange={onCopyTermsTeamsChange}
            />
            <CopyFromTeamDropdown
              title="snippets"
              icon={IconVariant.SNIPPETS_UNFILLED}
              options={copySnippetsTeams}
              onChange={onCopySnippetsTeamsChange}
            />
          </div>
        </div>
        <Button
          className={styles.submitButton}
          type={ButtonTypes.PRIMARY}
          content="Create team"
          disabled={!!teamAdminsError || !!teamNameError}
          onClick={handleCreateTeam}
          isLoading={loading}
        />
      </form>
    </Modal>
  );
};

export default CreatTeamModal;
