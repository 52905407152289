import { useEffect, useState } from 'react';

import { getCookieValue, setCookieValue } from '@writercolab/common-utils';

import { COOKIE_CONSENT_VALUE_NAME } from '../services/config/constants';
import { TQueryParamBooleanStd } from '../utils/queryParamUtils';

const COOKIE_CONSENT_VALUE_EXPIRE_DAYS = 365;

export default function useCookieConsent(): {
  cookieConsented: boolean;
  acceptCookieConsent: () => void;
} {
  const [cookieConsented, setCookieConsented] = useState(false);

  const acceptCookieConsent = () => {
    setCookieValue(COOKIE_CONSENT_VALUE_NAME, TQueryParamBooleanStd.enum.true, COOKIE_CONSENT_VALUE_EXPIRE_DAYS);
    setCookieConsented(true);
  };

  useEffect(() => {
    const cookieConsent = getCookieValue(COOKIE_CONSENT_VALUE_NAME);
    setCookieConsented(cookieConsent === TQueryParamBooleanStd.enum.true);
  }, []);

  return {
    cookieConsented,
    acceptCookieConsent,
  };
}
