import { type RequestServiceInitialize } from '@writercolab/network';

import type { ITeamQueryParams, TPaginatedTeamUsers } from '@web/types';
import { getLogger } from 'utils/logger';

const LOG = getLogger('team.api');

export interface ITeamApiModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number;
}

export class TeamApiModel {
  constructor(private readonly opts: ITeamApiModelOptions) {
    this.opts = opts;
  }

  findTeamMembers = async (payload: ITeamQueryParams): Promise<TPaginatedTeamUsers> => {
    try {
      const { data } = await this.opts.request.get('/api/user/v2/organization/{organizationId}/team/{teamId}', {
        params: {
          path: {
            organizationId: Number(this.opts.organizationId),
            teamId: Number(this.opts.teamId),
          },
          query: payload,
        },
      });

      const flags = {};

      // Removes duplicate users sent from BE (existing issues from 4+ years ago?)
      const filteredResult = data.result.filter(({ user }) => {
        if (flags[user.id]) return false;

        flags[user.id] = true;

        return true;
      });

      return { ...data, result: filteredResult };
    } catch (e) {
      LOG.error('Failed to find team members', e);

      throw e;
    }
  };
}
