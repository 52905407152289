import { useMemo } from 'react';

import { APP_ENV } from 'services/config/constants';
import { useAppState } from 'state';

/**
 * Imperative access to get the onboarding flag
 * with sensible defaults. Use this function within MobX models.
 * Use `useOnboardingFF` hook in React components.
 */
export function getOnboardingFF(featureFlags: ReturnType<typeof useAppState>['appModel']['featureFlags']) {
  const isDev = import.meta.env.VITE_ENV === APP_ENV.LOCAL;
  const isTest = import.meta.env.VITE_ENV === APP_ENV.TEST;

  const onboardingRefreshFlags = featureFlags.get('onboardingRefresh', {
    disableLegacyQuestionnaire: isDev || isTest,
    useV3Questionnaire: isDev || isTest,
    disableAISLogin: isDev,
    disableLandingPagesLogin: false,
    disableLegacyDiscovery: isDev || isTest,
  });

  const onboardingV2 = featureFlags.get('onboardingV2', false);

  return {
    ...onboardingRefreshFlags,
    onboardingV2,
  };
}

export function useOnboardingFF() {
  const {
    appModel: { featureFlags },
  } = useAppState();

  return useMemo(() => getOnboardingFF(featureFlags), [featureFlags]);
}
