import { action, computed, makeObservable, observable } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import { NotificationQueueItemType, TNotificationQueueItem } from '@writercolab/types';

import { TAppDecorationId, TPrompt, TPromptsLibraryTabsId, TTeamPromptEditActionDto } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

import { TeamPromptsApiModel } from '../../../models/teamPrompts.api';
import { PromptLibraryTeamUIModel } from '../PromptLibraryTeam';
import { PromptLibraryUserUIModel } from '../PromptLibraryUser';
import { TeamPromptPanelUiModel } from '../TeamPromptPanel';

interface IPromptLibraryModelOptions {
  analyticsService: IWebAppAnalyticsTrack;
  notificationQueue?: ReactiveQueue<TNotificationQueueItem>;
  promptLibraryUserUIModel: PromptLibraryUserUIModel;
  promptLibraryTeamUIModel: PromptLibraryTeamUIModel;
  teamPromptsApiModel: TeamPromptsApiModel;
}

export class PromptLibraryUIModel {
  createTeamPromptVisible = false;
  teamPromptFormUiModel: TeamPromptPanelUiModel | undefined = undefined;
  activeTab: typeof TPromptsLibraryTabsId.type = TPromptsLibraryTabsId.enum.team;

  constructor(private readonly opts: IPromptLibraryModelOptions) {
    makeObservable(this, {
      activeTab: observable,
      createTeamPromptVisible: observable,
      setActiveTab: action,

      promptLibraryUserUIModel: computed,
      promptLibraryTeamUIModel: computed,
      teamPromptsApiModel: computed,
      teamPromptFormUIModel: computed,
      addingTeamPrompt: computed,
      hasTeamPrompts: computed,
      teamPromptsCount: computed,

      setCreateTeamPromptVisible: action,
    });
  }

  get promptLibraryUserUIModel() {
    return this.opts.promptLibraryUserUIModel;
  }

  get promptLibraryTeamUIModel() {
    return this.opts.promptLibraryTeamUIModel;
  }

  get teamPromptsApiModel() {
    return this.opts.teamPromptsApiModel;
  }

  get teamPromptFormUIModel() {
    return this.teamPromptFormUiModel;
  }

  get analyticsService() {
    return this.opts.analyticsService;
  }

  setActiveTab(activeTab: typeof TPromptsLibraryTabsId.type) {
    this.activeTab = TPromptsLibraryTabsId.get(activeTab, TPromptsLibraryTabsId.enum.user);
  }

  setCreateTeamPromptVisible = (value: boolean, prompt?: TPrompt) => {
    if (prompt) {
      this.teamPromptFormUiModel = new TeamPromptPanelUiModel({
        teamPromptsApiModel: this.opts.teamPromptsApiModel,
      });
      const icon = TAppDecorationId.get(prompt.contentType, TAppDecorationId.enum.longform);
      this.teamPromptFormUiModel.formBody.form.prompt.set(prompt.prompt);
      this.teamPromptFormUiModel.formBody.form.icon.set(icon);
    } else {
      this.teamPromptFormUiModel = undefined;
    }

    this.opts.analyticsService.track(AnalyticsActivity.saveTeamPromptsWp, {});
    this.createTeamPromptVisible = value;
  };

  addTeamPrompt = async (teamPromptActionDto: TTeamPromptEditActionDto) => {
    await this.opts.teamPromptsApiModel.create(teamPromptActionDto, false);
    this.opts?.notificationQueue?.enqueue({
      type: NotificationQueueItemType.enum.success,
      message: 'Added to team prompts',
    });
    this.opts.teamPromptsApiModel.pagination.reload();
  };

  get addingTeamPrompt() {
    return this.opts.teamPromptsApiModel.loading;
  }

  get teamPromptsCount() {
    return this.opts.teamPromptsApiModel.pagination.value?.length || 0;
  }

  get hasTeamPrompts() {
    return this.teamPromptsCount > 0;
  }
}
