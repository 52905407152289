import { computed, makeObservable } from 'mobx';

import {
  IconsExtensionsChrome,
  IconsExtensionsContentful,
  IconsExtensionsEdge,
  IconsExtensionsFigma,
  IconsExtensionsMac,
  IconsExtensionsOutlook,
  IconsExtensionsSlack,
  IconsExtensionsWindows,
  IconsExtensionsWord,
} from '@writercolab/assets';
import { TOrgTeamUserActivityParams } from '@writercolab/types';

import type { DragEndEvent } from '@dnd-kit/core';
import type { TWidgetConfig } from '@web/types';
import { TWidgetEditAppearance, TWidgetType, TWriterExtensionId } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import difference from 'lodash/difference';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';

import { WINDOWS_ELECTRON_APP_DOWNLOAD_URL } from '../../../constants/extensions';

export const HOME_EXTENSIONS_CONFIG = [
  {
    id: TWriterExtensionId.enum.CHROME,
    title: 'Writer for Chrome',
    icon: <IconsExtensionsChrome />,
    link: 'https://chromewebstore.google.com/detail/writer/hngnkmianenpifegfoggnkamjnffiobn?hl=en&pli=1',
  },
  {
    id: TWriterExtensionId.enum.EDGE,
    title: 'Writer for Edge',
    icon: <IconsExtensionsEdge />,
    link: 'https://microsoftedge.microsoft.com/addons/detail/writer/inecepkbpmbehfbdfodaihoeenfecoel',
  },
  {
    id: TWriterExtensionId.enum.MAC,
    title: 'Writer for Mac',
    icon: <IconsExtensionsMac />,
    link: 'https://download-mac.writer.com/Writer.dmg',
  },
  {
    id: TWriterExtensionId.enum.WINDOWS,
    title: 'Writer for Windows',
    icon: <IconsExtensionsWindows />,
    link: WINDOWS_ELECTRON_APP_DOWNLOAD_URL,
  },
  {
    id: TWriterExtensionId.enum.FIGMA,
    title: 'Writer for Figma',
    icon: <IconsExtensionsFigma />,
    link: 'https://www.figma.com/community/plugin/1010580776466011580/writer-for-figma',
  },
  {
    id: TWriterExtensionId.enum.SLACK,
    title: 'Writer for Slack',
    icon: <IconsExtensionsSlack />,
    link: 'https://app.writer.com/api/public/slack-assistant/install',
  },
  {
    id: TWriterExtensionId.enum.WORD,
    title: 'Writer for Word',
    icon: <IconsExtensionsWord />,
    link: 'https://appsource.microsoft.com/en-us/product/office/WA200001548',
  },
  {
    id: TWriterExtensionId.enum.OUTLOOK,
    title: 'Writer for Outlook',
    icon: <IconsExtensionsOutlook />,
    link: 'https://appsource.microsoft.com/en-us/product/office/WA200002646',
  },
  {
    id: TWriterExtensionId.enum.CONTENTFUL,
    title: 'Writer for Contentful',
    icon: <IconsExtensionsContentful />,
    link: 'https://www.contentful.com/marketplace/writer/',
  },
];

interface IExtensionsHomeWidgetModelParams {
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  config: () => TWidgetConfig<typeof TWidgetType.enum.extensions>;
}

export class ExtensionsHomeWidgetUiModel extends AbstractHomeWidgetModel<typeof TWidgetType.enum.extensions> {
  editAppearance = TWidgetEditAppearance.enum.edit;

  constructor(params: IExtensionsHomeWidgetModelParams) {
    super(TWidgetType.enum.extensions, () => params.config(), params.analyticsService);

    makeObservable(this, {
      extensionIds: computed.struct,
    });
  }

  get extensionIds() {
    return this.currentConfig.data.names.filter(n => TWriterExtensionId.is(n));
  }

  get configForSave() {
    if (this.isAdminEdit) {
      return this.currentConfig;
    }

    return {
      ...this.currentConfig,
      data: {
        names: difference(TWriterExtensionId.list, this.extensionIds),
      },
    };
  }

  handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && over.id !== active.id) {
      const indexActive = this.extensionIds.findIndex(id => id === active.id);
      const indexOver = this.extensionIds.findIndex(id => id === over.id);

      const names = this.extensionIds
        .toSpliced(indexActive, 1)
        .toSpliced(indexOver, 0, active.id as typeof TWriterExtensionId.type);

      this.editData({ names });
    }
  };

  toggleExtensionState = (id: typeof TWriterExtensionId.type) => {
    if (this.extensionIds.includes(id)) {
      const names = this.extensionIds.filter(extId => extId !== id);
      this.editData({ names });
      this.analyticsService.track(AnalyticsActivity.extensionToggled, { extension: id, option: 'Off' });
    } else {
      const names = this.extensionIds.concat(id);
      this.editData({ names });
      this.analyticsService.track(AnalyticsActivity.extensionToggled, { extension: id, option: 'On' });
    }
  };
}
