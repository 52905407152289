import type React from 'react';
import { useCallback, useEffect, useReducer } from 'react';

import cx from 'classnames';

import type { ApiErrorResponse, ISnippetCreate, ISnippetTag, ITerm } from '@writercolab/common-utils';
import { SnippetErrorKeys } from '@writercolab/common-utils';
import { ReactQuill } from '@writercolab/react-quill';
import { Button, ButtonTypes, Icon, IconVariant, Label, Modal, SizeTypes, Tooltip } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes, TagsGroup } from '@writercolab/ui-molecules';

import { CustomException } from '@web/component-library';

import { getLogger } from '../../../utils/logger';
import './_quill.css';

import styles from './styles.module.css';

const LOG = getLogger('AddSnippets');

enum TAddSnippetsAction {
  DEFAULT,
  RESET,
  RESET_ERRORS,
  OPEN_EXISTING,
  TAGS,
}

interface IAddSnippetsModal {
  isOpen: boolean;
  title?: string;
  defaultTags: string[];
  isEditing?: boolean;
  value?: ISnippetCreate;
  changeModalState: (refreshList?: boolean) => void;
  onSubmit: (terms: ISnippetCreate[]) => Promise<ITerm[]>;
  onRemoveSnippetClick?: (snippet) => void;
}

interface TAddSnippetsState {
  id: string;
  shortcut: string;
  snippet: string;
  description: string;
  tags: ISnippetTag[];
  isLoading: boolean;
  shortcutError?: string;
  snippetError?: string;
  descriptionError?: string;
  tagsError?: string;
}

const initErrorState = {
  shortcutError: '',
  snippetError: '',
  descriptionError: '',
  tagsError: '',
};

const initState: TAddSnippetsState = {
  id: '',
  shortcut: '',
  snippet: '',
  description: '',
  tags: [],
  isLoading: false,
  ...initErrorState,
};

type TSnippetsStateAction = {
  field?: keyof TAddSnippetsState;
  value?: any; // todo: check if we can pass a good value
  action?: TAddSnippetsAction;
};

const createSnippetMapper = (state: TAddSnippetsState): ISnippetCreate[] => [
  {
    id: state.id,
    // remove empty shortcut
    ...(state.shortcut?.length && { shortcut: state.shortcut.trim() }),
    // remove empty description
    ...(state.description?.length && { description: state.description }),
    snippet: state.snippet,
    tags: state.tags.map(tag => ({ tag: tag.tag.trim() })),
  },
];

const reducer = (state: TAddSnippetsState, { field, value, action }: TSnippetsStateAction) => {
  switch (action) {
    case TAddSnippetsAction.RESET:
      return initState;
    case TAddSnippetsAction.RESET_ERRORS:
      return {
        ...state,
        ...initErrorState,
      };
    case TAddSnippetsAction.OPEN_EXISTING:
      return value;
    case TAddSnippetsAction.DEFAULT:
    default:
  }

  return {
    ...state,
    ...(field && { [field]: value }),
  };
};

const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'link', 'emoji'];

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [],
    ['bold', 'italic', 'underline', 'strike', 'link'],
    [],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [],
    ['clean'],
  ],
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true,
  },
};

export const AddSnippetsModal: React.FC<IAddSnippetsModal> = ({
  isOpen,
  changeModalState,
  title,
  value,
  onSubmit,
  isEditing,
  defaultTags,
  onRemoveSnippetClick,
  ...props
}) => {
  const [state, dispatch] = useReducer<React.Reducer<TAddSnippetsState, TSnippetsStateAction>>(reducer, initState);

  useEffect(() => {
    dispatch({ action: TAddSnippetsAction.OPEN_EXISTING, value: value || initState });
  }, [value, isOpen]);

  const onChangeShortcut = useCallback(e => {
    dispatch({ field: 'shortcut', value: e.target.value });
  }, []);

  const onChangeDescription = useCallback(e => {
    dispatch({ field: 'description', value: e.target.value });
  }, []);

  const onChangeSnippet = useCallback(value => {
    dispatch({ field: 'snippet', value });
  }, []);

  const onTags = useCallback(values => {
    dispatch({ field: 'tags', value: values.map(v => ({ tag: v.name || v })) });
  }, []);

  const validate = () =>
    new Promise<void>((resolve, reject) => {
      if (state.snippet.length === 0) {
        reject(new CustomException('this is a required field', SnippetErrorKeys.EMPTY_TERM_NAME));
      }

      resolve();
    });

  const onCancelButtonClick = () => {
    changeModalState();
  };

  const onAddButtonClick = async e => {
    e.preventDefault();
    dispatch({ field: 'isLoading', value: true });
    dispatch({ action: TAddSnippetsAction.RESET_ERRORS });
    try {
      LOG.debug('Before Save (state.snippet): ', state.snippet);
      await validate();
      await onSubmit(createSnippetMapper(state));
      dispatch({ action: TAddSnippetsAction.RESET });
      changeModalState(true);
    } catch (e: any) {
      if (e.message && e.messageKey && !e.response?.data) {
        dispatch({ field: 'snippetError', value: e.message });

        return;
      }

      const responseData: ApiErrorResponse = e.response?.data;

      responseData?.errors?.forEach(({ description, key, userMessage }) => {
        switch (key) {
          case SnippetErrorKeys.SNIPPET_REGEX:
            dispatch({ field: 'shortcutError', value: 'Shortcut must not have symbols or spaces' });
            break;
          case SnippetErrorKeys.SNIPPET_SHORTCUT:
            dispatch({ field: 'shortcutError', value: description });
            break;
          case SnippetErrorKeys.SNIPPET_DESCRIPTION:
            dispatch({ field: 'descriptionError', value: description });
            break;
          case SnippetErrorKeys.SNIPPET_SNIPPET:
            dispatch({ field: 'snippetError', value: description });
            break;
          case SnippetErrorKeys.SNIPPET_TAGS:
            dispatch({ field: 'tagsError', value: description });
            break;

          default:
            // eslint-disable-next-line no-case-declarations
            const shortcutError = userMessage || e.message;

            if (shortcutError) {
              dispatch({ field: 'shortcutError', value: shortcutError });
            }
        }
      });
    } finally {
      dispatch({ field: 'isLoading', value: false });
    }
  };

  const handleRemoveSnippetClick = () => {
    onRemoveSnippetClick?.([value]);
  };

  return (
    <Modal
      open={isOpen}
      handleClose={onCancelButtonClick}
      title={title || (isEditing ? 'Edit snippet' : 'Add a snippet')}
      modalContainerClassName={styles.modalMainContainer}
      className={styles.modalContentContainer}
      {...props}
      disableBackdropClick
    >
      <form className={styles.styledForm} onSubmit={onAddButtonClick}>
        <div className={cx(styles.scrollableContent)}>
          {/* SHORTCUT */}
          <InputGroup
            id="shortcut"
            name="shortcut"
            placeholder="shortcut"
            className={styles.shortcut}
            value={state.shortcut}
            inputType={InputTypes.INPUT}
            handleChangeInput={onChangeShortcut}
            errorText={state.shortcutError}
            label="Shortcut"
            autoFocus
            labelTooltipContent="This is the shortcut you’ll use to insert a snippet when writing. Shortcuts always start with a w. and should not have spaces or special characters."
            labelTooltipContainerClass={styles.labelTooltipContainer}
          />

          {/* DESCRIPTION */}
          <InputGroup
            id="description"
            name="description"
            placeholder="eg. Our formal mission statement about the company."
            value={state.description}
            inputType={InputTypes.INPUT}
            handleChangeInput={onChangeDescription}
            errorText={state.descriptionError}
            label="Description"
          />

          {/* SNIPPET */}
          <div className={styles.snippetsBody}>
            <Label className={styles.labelWithIcon} htmlFor="snippet" errorText={state.snippetError}>
              Snippet
              <Tooltip
                title={
                  <div className={styles.labelTooltipContainer}>
                    This is the text that you'd like to automatically insert using your specified shortcut.
                  </div>
                }
                placement="right"
              >
                <div className={cx(styles.snippetFieldInfoIcon)}>
                  <Icon name={IconVariant.INFO_OUTLINED} />
                </div>
              </Tooltip>
            </Label>
            <div className={styles.formControlInput} data-text-editor="writer-snippet-link-input">
              <ReactQuill
                className="writer-input-rte"
                placeholder="e.g. Our mission is to enhance the lives of our customers by creating and enabling unsurpassed vacation and leisure experiences."
                theme="snow"
                defaultValue={value?.snippet}
                onChange={onChangeSnippet}
                formats={formats}
                modules={modules}
                bounds={`[data-text-editor="writer-snippet-link-input"]`}
              />
            </div>
          </div>

          {/* TAGS */}
          <div className={styles.tagsContainer}>
            <TagsGroup
              error={state.tagsError}
              initialTags={defaultTags.map(t => ({ name: t }))}
              tags={state.tags.map(t => ({ name: t.tag }))}
              onUpdate={onTags}
            />
          </div>

          {/* FOOTER */}
          <div className={styles.actionContainer}>
            {isEditing && (
              <Button
                type={ButtonTypes.LINK}
                icon={<Icon name={IconVariant.TRASH} />}
                content="Remove snippet"
                size={SizeTypes.XS}
                className={styles.removeButton}
                onClick={handleRemoveSnippetClick}
              />
            )}
            <div className={styles.submitAndCancelContainer}>
              <Button className={cx(styles.submitButton, styles.cancelButton)} onClick={onCancelButtonClick}>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                className={styles.submitButton}
                type={ButtonTypes.PRIMARY}
                onClick={onAddButtonClick}
                isLoading={state.isLoading}
                content={isEditing ? 'Save' : 'Add snippet'}
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddSnippetsModal;
