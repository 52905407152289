import { useCallback, useEffect, useState } from 'react';

const computeEffectiveTop = (el: HTMLElement) => {
  const { marginTop, paddingTop } = window.getComputedStyle(el);

  return parseFloat(marginTop) + parseFloat(paddingTop);
};

const shouldStick = (el: Element, scrollableRectTop: number) => {
  const effectiveTop = computeEffectiveTop(el as HTMLElement);
  const elementTop = el.getBoundingClientRect().top;

  // if the element is above the scrollable area
  if (effectiveTop < 0) {
    return elementTop <= scrollableRectTop - effectiveTop;
  } else {
    // if the element is below the scrollable area
    return elementTop - effectiveTop <= scrollableRectTop;
  }
};

export const useSticky = (stickyQuery: string, scrollableQuery: string | null = null, isActive: boolean) => {
  const [currentStickyElement, setCurrentStickyElement] = useState<Element | null>(null);

  const getScrollableElement = useCallback(
    () => (scrollableQuery ? (document.querySelector(scrollableQuery) as HTMLElement) : window),
    [scrollableQuery],
  );

  const onScroll = useCallback(() => {
    const stickyElements = Array.from(document.querySelectorAll(stickyQuery));
    const scrollableElement = getScrollableElement();
    const scrollableRect = (
      scrollableElement === window ? { top: 0 } : (scrollableElement as HTMLElement).getBoundingClientRect()
    ) as DOMRect;

    let newStickyElement: Element | null = null;

    stickyElements.forEach((el: Element) => {
      if (shouldStick(el, scrollableRect.top)) {
        newStickyElement = el;
      }
    });

    if (newStickyElement !== currentStickyElement) {
      if (currentStickyElement) {
        const prevEl = currentStickyElement as HTMLElement;
        prevEl.style.position = '';
        prevEl.style.top = '';
        prevEl.style.zIndex = '';
      }

      if (newStickyElement) {
        const newEl = newStickyElement as HTMLElement;
        newEl.style.position = 'sticky';
        newEl.style.top = '0';
        newEl.style.zIndex = '999';
      }

      setCurrentStickyElement(newStickyElement);
    }
  }, [currentStickyElement, stickyQuery, getScrollableElement]);

  useEffect(() => {
    // Only set up the event listener if isActive is true
    if (isActive) {
      const scrollableElement = getScrollableElement();

      if (scrollableElement) {
        (scrollableElement as HTMLElement | Window).addEventListener('scroll', onScroll);
      }

      // Run the logic after mounting if active
      onScroll();

      // Return a cleanup function that will remove the event listener
      return () => {
        if (scrollableElement) {
          (scrollableElement as HTMLElement | Window).removeEventListener('scroll', onScroll);
        }
      };
    } else {
      // Return a no-op function when isActive is false
      return () => {};
    }
  }, [onScroll, getScrollableElement, isActive]);

  return currentStickyElement;
};
