import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import type { TEventQueueItem } from '@writercolab/types';
import type { DropdownOption } from '@writercolab/ui-atoms';

import type { TOrganizationsDocument } from '@web/types';
import {
  OrganizationDocumentAction,
  OrganizationDocumentFilterOptions,
  OrganizationDocumentSortOptions,
  TApiRequestSortDirection,
  TOrganizationTableAction,
  TOrganizationsDocumentsScope,
  TOrganizationsDocumentsSort,
} from '@web/types';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import type { OrganizationDocumentsApi } from '../../../models/organizationDocuments.api';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY_MS } from '../../../services/config/constants';

interface IDocumentsTableUiModelOpts {
  organizationDocumentsApi: OrganizationDocumentsApi;
  organizationId: () => number;
  teamId: () => number;
  scopeFilterDisabled: boolean;
}

type TOrganizationTableActionParams = {
  organizationId: number;
  teamId: number;
  documentId: number;
};

export class DocsTableUiModel {
  searchPhrase = '';
  deleteConfirmationModalVisible = false;
  scopeFilterDisabled = false;
  tableActionsQueue = new ReactiveQueue<
    TEventQueueItem<typeof TOrganizationTableAction.type, TOrganizationTableActionParams>
  >();

  private documentId: string | null = null;

  constructor(private readonly opts: IDocumentsTableUiModelOpts) {
    this.scopeFilterDisabled = opts.scopeFilterDisabled;

    makeAutoObservable(this, {
      searchPhrase: observable,
      scopeFilterDisabled: observable,
      deleteConfirmationModalVisible: observable,

      hasNext: computed.struct,
      loading: computed.struct,
      documents: computed.struct,
      showLoader: computed.struct,
      organizationId: computed.struct,
      teamId: computed.struct,
      scopeFilterOptions: computed.struct,
      sortingOptions: computed.struct,
      empty: computed.struct,
      sortedByCreatedDate: computed.struct,
      filtersEnabled: computed.struct,
      filtersEnabledCount: computed.struct,

      onSortChange: action.bound,
      onScopeFilterChange: action.bound,
      onSearchChange: action.bound,
      onClearSearch: action.bound,
      onDocumentAction: action.bound,
      setDeleteConfirmationModalVisible: action.bound,
    });
  }

  loadMore = async () => {
    await this.opts.organizationDocumentsApi.pagination.next();
  };

  get documents(): TOrganizationsDocument[] {
    return this.opts.organizationDocumentsApi.pagination.value ?? [];
  }

  get empty(): boolean {
    return isEmpty(this.documents) && isEmpty(this.searchPhrase) && !this.loading;
  }

  get hasNext(): boolean {
    return this.opts.organizationDocumentsApi.pagination.hasNext;
  }

  get loading(): boolean {
    return this.opts.organizationDocumentsApi.pagination.status === 'pending';
  }

  get showLoader(): boolean {
    return this.loading && isEmpty(this.documents);
  }

  get organizationId(): number {
    return this.opts.organizationId();
  }

  get sortedByCreatedDate(): boolean {
    return this.sortingOptions.find(s => s.active)?.id === TOrganizationsDocumentsSort.enum.modificationTime;
  }

  get teamId(): number {
    return this.opts.teamId();
  }

  get filters(): {
    search?: string;
    sortField?: typeof TOrganizationsDocumentsSort.type;
    sortOrder?: typeof TApiRequestSortDirection.type;
    scope?: typeof TOrganizationsDocumentsScope.type;
    offset?: number;
    limit?: number;
  } {
    return this.opts.organizationDocumentsApi.pagination.extra;
  }

  get filtersEnabled(): boolean {
    return (
      this.opts.organizationDocumentsApi.pagination.extra.scope === TOrganizationsDocumentsScope.enum.mine ||
      !isEmpty(this.opts.organizationDocumentsApi.pagination.extra.search)
    );
  }

  get filtersEnabledCount(): number {
    let count = 0;

    if (this.opts.organizationDocumentsApi.pagination.extra.scope === TOrganizationsDocumentsScope.enum.mine) {
      count += 1;
    }

    if (!isEmpty(this.opts.organizationDocumentsApi.pagination.extra.search)) {
      count += 1;
    }

    return count;
  }

  get sortField(): typeof TOrganizationsDocumentsSort.type {
    return this.filters.sortField as typeof TOrganizationsDocumentsSort.type;
  }

  get scopeFilterOptions(): DropdownOption[] {
    return OrganizationDocumentFilterOptions.map(option => ({
      ...option,
      active: this.filters.scope === option.id,
    }));
  }

  get sortingOptions(): DropdownOption[] {
    return OrganizationDocumentSortOptions.map(option => ({
      ...option,
      active: this.sortField === option.id,
    }));
  }

  onSearchChange(search: string) {
    this.searchPhrase = search;
    this.debouncedOnSearchChange();
  }

  openDocument = (documentId: string | number) => {
    this.tableActionsQueue.enqueue({
      type: TOrganizationTableAction.enum.openDocument,
      params: {
        organizationId: this.organizationId,
        teamId: this.teamId,
        documentId: documentId as number,
      },
    });
  };

  debouncedOnSearchChange = debounce(() => {
    this.opts.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      search: this.searchPhrase,
    });
  }, DEFAULT_SEARCH_DEBOUNCE_DELAY_MS);

  onClearSearch() {
    this.searchPhrase = '';
    this.opts.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      search: undefined,
    });
  }

  onClearFilters = () => {
    this.searchPhrase = '';
    this.opts.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      scope: TOrganizationsDocumentsScope.enum.all,
      search: undefined,
    });
  };

  onSortChange(sortField: typeof TOrganizationsDocumentsSort.type) {
    let sortOrder: typeof TApiRequestSortDirection.type = TApiRequestSortDirection.enum.asc;

    if (
      sortField === TOrganizationsDocumentsSort.enum.modificationTime ||
      sortField === TOrganizationsDocumentsSort.enum.openedByMeTime
    ) {
      sortOrder = TApiRequestSortDirection.enum.desc;
    }

    this.opts.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      sortField,
      sortOrder,
    });
  }

  async onDocumentAction(id: string, action: typeof OrganizationDocumentAction.type) {
    if (action === OrganizationDocumentAction.enum.delete) {
      this.documentId = id;
      this.setDeleteConfirmationModalVisible(true);
    }
  }

  deleteDocument = async () => {
    if (!this.documentId) {
      return;
    }

    await this.opts.organizationDocumentsApi.deleteDocument(parseInt(this.documentId, 10));
    this.opts.organizationDocumentsApi.pagination.reload();
    runInAction(() => {
      this.setDeleteConfirmationModalVisible(false);
    });
  };

  setDeleteConfirmationModalVisible(visible: boolean) {
    this.deleteConfirmationModalVisible = visible;

    if (!visible) {
      this.documentId = null;
    }
  }

  onScopeFilterChange(scope: typeof TOrganizationsDocumentsScope.type) {
    this.opts.organizationDocumentsApi.pagination.setExtra({
      ...this.filters,
      scope,
    });
  }
}
