import React, { useMemo } from 'react';

import cx from 'classnames';

import {
  BaseComponentsProps,
  ISuggestionMetaRules,
  ISuggestionSection,
  ISuggestionSettings,
  findSuggestionSection,
} from '@writercolab/common-utils';
import {
  Heading,
  HeadingVariant,
  Switcher,
  Tag,
  TagColor,
  Text,
  TextSize,
  Tooltip,
  TooltipAlignment,
  TooltipBackgroundColor,
} from '@writercolab/ui-atoms';
import { StructureSection } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

export interface ISuggestionCategoryProps extends BaseComponentsProps {
  readOnly?: boolean;
  type: StructureSection['type'];
  name: StructureSection['name'];
  tag?: StructureSection['tag'];
  description?: StructureSection['description'];
  smallBlockHeight?: boolean;
  isDraggable?: boolean;
  suggestionSettings: ISuggestionSettings;
  tooltipWrapperClassname?: string;
  setSectionEnabled: (category: string, enabled: boolean) => void;
  tooltipContent?: React.ReactNode;
  halfLimitedWidth?: boolean;
  hideBottomBorder?: boolean;
  children?: React.ReactNode;
}

const SuggestionCategory: React.FC<ISuggestionCategoryProps> = ({
  readOnly,
  className,
  type,
  name,
  tag,
  description,
  suggestionSettings,
  setSectionEnabled,
  tooltipContent,
  halfLimitedWidth,
  hideBottomBorder,
  tooltipWrapperClassname,
  children,
  smallBlockHeight = false,
  isDraggable = false,
}) => {
  const elClass = cx(styles.suggestionCategory, className, { [styles.hideBottomBorder]: hideBottomBorder });
  const tooltipStyles = {
    tooltip: {
      minWidth: '400px',
      top: '-37px',
      padding: '15px 15px 0',
      background: isDraggable ? 'inherit' : 'white',
      boxShadow: '0 3px 19px rgba(191, 203, 255, 0.37795)',
    },
    arrow: {
      color: '#000',
    },
  };

  const sectionData: ISuggestionSection<ISuggestionMetaRules> = useMemo(
    () => findSuggestionSection(type, suggestionSettings)!,
    [suggestionSettings, type],
  );
  const onSwitcherChange = () => setSectionEnabled(sectionData.category, !sectionData.enabled);

  return (
    <div className={elClass}>
      <div className={styles.switcherContainer}>
        <Switcher
          className={styles.switcher}
          id={sectionData?.category}
          checked={sectionData?.enabled}
          disabled={readOnly}
          size={20}
          onChange={onSwitcherChange}
        />
        <div className={cx({ [styles.limitedWidthLabel]: halfLimitedWidth })}>
          <Tooltip
            placement="right-start"
            title={tooltipContent || ''}
            tooltipWrapperClassname={cx(styles.labelTooltip, tooltipWrapperClassname)}
            disabled={!tooltipContent}
            tooltipBackgroundColor={TooltipBackgroundColor.WHITE}
            align={TooltipAlignment.LEFT}
            arrowStyles={styles.labelTooltipArrow}
            componentsProps={{
              arrow: { sx: tooltipStyles.arrow },
              tooltip: { sx: tooltipStyles.tooltip },
            }}
          >
            <div className={cx({ [styles.smallBlockHeight]: smallBlockHeight })}>
              <div className={styles.switcherLabel}>
                <Heading variant={HeadingVariant.H3} className={styles.labelText} bold>
                  {name}
                </Heading>
                {tag && (
                  <Tag color={TagColor.GREEN} upperCase className={styles.switcherLabelTag}>
                    {tag}
                  </Tag>
                )}
              </div>
              <Text className={styles.description} variant={TextSize.L}>
                {description}
              </Text>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className={styles.suggestionSubcategories}>{children}</div>
    </div>
  );
};

export default SuggestionCategory;
