import React from 'react';

import { BaseComponentsProps } from '@writercolab/common-utils';
import { Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface ISubstepDescriptionProps extends BaseComponentsProps {
  description: string;
  letter: string;
}

export const SubstepDescription = ({ description, letter }: ISubstepDescriptionProps) => (
  <div className={styles.substepDescription}>
    <Text variant={TextSize.XL} bold>
      {letter}.
    </Text>

    <Text variant={TextSize.XL} bold>
      {description}
    </Text>
  </div>
);

export default SubstepDescription;
