import { useEffect, useCallback } from 'react';

export const useKeypress = (targetKey: string, callback: () => void) => {
  const downHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        callback();
      }
    },
    [callback, targetKey],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [downHandler]);
};

export default useKeypress;
