import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import { Text, TextSize, TextColor, Icon, IconVariant } from '@writercolab/ui-atoms';
import React from 'react';
import { observer } from 'mobx-react-lite';
import styles from './EditorToolbarColorPanel.module.css';
import type { EditorToolbarModel } from '../EditorToolbar/UIEditorToolbarModel';

interface IEditorToolbarColorPanelProps {
  onFormatClick: (format: QUILL_FORMAT, value: string) => void;
  onCleanColors: () => void;
  model: EditorToolbarModel;
}

export const EditorToolbarColorPanel: React.FC<IEditorToolbarColorPanelProps> = observer(
  ({ onFormatClick, onCleanColors, model }) => (
    <div className={styles.colorPanel}>
      {model.formatsMap[QUILL_FORMAT.COLOR] && (
        <div className={styles.options}>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#C4554D')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#C4554D' }} />
          </div>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#CC772F')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#CC772F' }} />
          </div>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#C29243')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#C29243' }} />
          </div>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#548164')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#548164' }} />
          </div>

          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#487DA5')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#487DA5' }} />
          </div>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#8A68AB')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#8A68AB' }} />
          </div>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#B35588')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#B35588' }} />
          </div>
          <div onClick={() => onFormatClick(QUILL_FORMAT.COLOR, '#787774')} className={styles.option}>
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} style={{ color: '#787774' }} />
          </div>
        </div>
      )}
      {model.formatsMap[QUILL_FORMAT.BACKGROUND] && (
        <div className={styles.options}>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#FBEBEB')}
            className={styles.option}
            style={{ backgroundColor: '#FBEBEB' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#FBEFE3')}
            className={styles.option}
            style={{ backgroundColor: '#FBEFE3' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#FDF8E9')}
            className={styles.option}
            style={{ backgroundColor: '#FDF8E9' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#F0FAF2')}
            className={styles.option}
            style={{ backgroundColor: '#F0FAF2' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#ECF4FD')}
            className={styles.option}
            style={{ backgroundColor: '#ECF4FD' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#F3ECF8')}
            className={styles.option}
            style={{ backgroundColor: '#F3ECF8' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#F9F1F7')}
            className={styles.option}
            style={{ backgroundColor: '#F9F1F7' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
          <div
            onClick={() => onFormatClick(QUILL_FORMAT.BACKGROUND, '#F6F6F6')}
            className={styles.option}
            style={{ backgroundColor: '#F6F6F6' }}
          >
            <Icon name={IconVariant.EDITOR_FORMAT_TYPOGRAPHY} />
          </div>
        </div>
      )}
      <div className={styles.clear} onClick={onCleanColors}>
        <Text variant={TextSize.M} color={TextColor.GREY}>
          Clear
        </Text>
      </div>
    </div>
  ),
);

export default EditorToolbarColorPanel;
