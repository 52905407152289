import { ContentGenerationJobStatus } from '@writercolab/types';

import type { IRecapJob, TContentGenerationJobStatus } from '@web/types';
import { BLOG_BUILDER_APP_ID, RECAPS_APP_ID, contentGenerationJobInputFields } from '@web/types';

import { getLogger } from './logger';

const LOG = getLogger('recapsUtils');

export const JOBS_IN_PROGRESS_STATUSES: TContentGenerationJobStatus[] = [
  ContentGenerationJobStatus.enum.transcript,
  ContentGenerationJobStatus.enum.generating,
];

export const getFieldValue = (fields, name: string) => fields.find(field => field.name === name)?.value;
export const getQueueJobFieldValue = (
  job: IRecapJob,
  fieldName: contentGenerationJobInputFields,
): string | null | string[] => {
  let value = null;

  if (isRecapsQueueJobType(job)) {
    value = job.inputs?.inputs?.find(field => field.name === fieldName)?.value || null;
  } else if (isBlogBuilderQueueJobType(job)) {
    value = job.inputs[fieldName] || null;
  } else {
    LOG.error(`Unknown queue job type for ID: ${job.templateId}`);
  }

  return value;
};

export const isRecapsQueueJobType = (job: IRecapJob): boolean => job.templateId === RECAPS_APP_ID;
export const isBlogBuilderQueueJobType = (job: IRecapJob): boolean => job.templateId === BLOG_BUILDER_APP_ID;

export const getQueueJobContentItemName = (job: IRecapJob): string => {
  let name = job.id;

  if (isRecapsQueueJobType(job)) {
    const value = getQueueJobFieldValue(job, contentGenerationJobInputFields.EVENT_NAME);

    if (Array.isArray(value)) {
      name = value.join('');
    } else {
      name = value || job.id;
    }
  } else if (isBlogBuilderQueueJobType(job)) {
    name = (getQueueJobFieldValue(job, contentGenerationJobInputFields.EVENT_TITLE) as string) || job.id;
  }

  return name;
};
