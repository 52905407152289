import { useCallback, useEffect, useMemo, useState } from 'react';

import type { ICreateSnippetWithFails, ISnippetCreate, TSnippet } from '@writercolab/common-utils';
import { DeleteTermsModal, PerPageSelector, Unreachable, UnreachableType } from '@writercolab/ui-molecules';

import { FREE_SUBSCRIPTION_TERMS_LIMIT } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import { useSnippetsContext } from '../../../context/snippetsContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import AddSnippetsModal from '../../organisms/AddSnippets';
import { ImporterPopup } from '../../organisms/ImporterPopup';
import { ImportItemType } from '../../organisms/ImporterPopup/types';
import { TermsCounter } from '../termsAndSnippetsShared/commonComponents';
import SnippetsFooter from './SnippetsFooter';
import SnippetsHeader from './SnippetsHeader';
import { SnippetsTable } from './SnippetsTable';

import styles from '../termsAndSnippetsShared/TermsPage.module.css';

export const SnippetsPage = observer(() => {
  usePageTitle('Snippets');

  const {
    snippetsContext,
    handleOffsetChange,
    handleCloseTermPopup,
    handleSnippetSubmit,
    handleDeleteSnippets,
    handleToggleModal,
    handleMultiSelect,
    handleBulkSnippetsSubmit,
    handleCloseImportSnippetsPopup,
    handleResetSelected,
  } = useSnippetsContext();
  const { appState, appModel } = useAppState();
  const { snippetsList, termsTotalCount, limit, tags, showError404, isLoading, isImportSnippetsPopupOpen } =
    snippetsContext;
  const [tableFooterVisible, setTableFooterVisible] = useState(false);
  const { isFree, isEnterprise } = appModel.assistantSubscription;
  const addTermsLimitReached = useMemo<boolean>(
    () =>
      !!(isFree && snippetsContext.termsTotalCount && snippetsContext.termsTotalCount >= FREE_SUBSCRIPTION_TERMS_LIMIT),
    [snippetsContext.termsTotalCount, isFree],
  );

  useEffect(() => {
    appModel.analyticsService.track(AnalyticsActivity.snippetsViewed, {});
  }, [appModel.analyticsService]);

  useEffect(() => {
    if (!appState.isEducationalSnippetsWindowVisible) {
      setTableFooterVisible(true);
    }
  }, [appState.isEducationalSnippetsWindowVisible]);

  const handleRemoveSnippetClick = useCallback(
    (snippet: TSnippet[]) => {
      handleMultiSelect(snippet);
      handleToggleModal(true);
    },
    [handleMultiSelect, handleToggleModal],
  );

  const handleDeleteTermsModalChange = useCallback(
    state => {
      handleToggleModal(state);
      if (snippetsContext.isAddTermPopupOpen && !state) {
        handleResetSelected();
      }
    },
    [handleToggleModal, snippetsContext.isAddTermPopupOpen, handleResetSelected],
  );

  const handleDeleteTermsModalSubmit = useCallback(() => {
    handleDeleteSnippets();
    handleResetSelected();
  }, [handleDeleteSnippets, handleResetSelected]);

  return (
    <div className={styles.termPageContainer}>
      <>
        <div className={styles.contentWrapper}>
          {!isLoading && showError404 ? (
            <Unreachable type={UnreachableType.enum.error404page} dashboardRouteHref={ROUTE.toHome(1)} />
          ) : (
            <div className={styles.container}>
              <SnippetsHeader
                isTeamNameShown={isEnterprise}
                orgId={appState.organizationId}
                addNewItemsDisabled={addTermsLimitReached}
              />
              <div className={styles.containerTable}>
                <SnippetsTable terms={snippetsList} />
              </div>
            </div>
          )}
        </div>
        {tableFooterVisible && (
          <div className={styles.containerFooter}>
            <SnippetsFooter
              leftContainer={<TermsCounter count={termsTotalCount} title="snippet" />}
              rightContainer={<PerPageSelector onSelect={handleOffsetChange} activeValue={limit} />}
            />
          </div>
        )}

        <AddSnippetsModal
          isOpen={snippetsContext.isAddTermPopupOpen}
          changeModalState={handleCloseTermPopup}
          onSubmit={handleSnippetSubmit as any}
          value={snippetsContext.openedTerm}
          isEditing={!!snippetsContext.openedTerm}
          defaultTags={tags}
          onRemoveSnippetClick={handleRemoveSnippetClick}
        />
        <DeleteTermsModal
          isOpen={snippetsContext.isDeletePopupOpen}
          numOfTerms={snippetsContext.selectedTerms.length}
          changeModalState={handleDeleteTermsModalChange}
          onSubmit={handleDeleteTermsModalSubmit}
          isSnippet
        />
        <ImporterPopup<ISnippetCreate, ICreateSnippetWithFails>
          open={isImportSnippetsPopupOpen}
          onClose={handleCloseImportSnippetsPopup}
          onSubmit={handleBulkSnippetsSubmit}
          type={ImportItemType.SNIPPETS}
        />
      </>
    </div>
  );
});
