import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IKnowledgeGraphRemoveModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const KnowledgeGraphRemoveModal: React.FC<IKnowledgeGraphRemoveModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  ...props
}) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      handleClose={onClose}
      title="Delete file"
      titleClassName={styles.title}
      style={{ width: '353px' }}
      {...props}
    >
      <div className={styles.styledDeleteContent}>
        <Text variant={TextSize.M}>Are you sure you want to delete this file?</Text>
        <div className={styles.controlButtons}>
          <Button className={styles.submitButton} type={ButtonTypes.DEFAULT} content="Never mind" onClick={onClose} />
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Delete forever"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};
