import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IDeleteTeamProps {
  isOpen: boolean;
  changeModalState: () => void;
  onSubmit: (state) => void;
  teamId: number;
  teamName: string;
}

export const DeleteTeamModal: React.FC<IDeleteTeamProps> = ({
  isOpen,
  changeModalState,
  teamId,
  onSubmit,
  teamName,
  ...props
}) => {
  const handleDeleteTeam = () => {
    onSubmit({ id: teamId, name: teamName });
    changeModalState();
  };

  return (
    <Modal open={isOpen} handleClose={changeModalState} title="Delete team" style={{ width: '335px' }} {...props}>
      <div className={styles.styledDeleteContent}>
        <Text variant={TextSize.M} bold>
          Are you sure you want to delete {teamName}?
        </Text>
        <Text variant={TextSize.M}>
          Team docs, suggestion settings, terms, and snippets will be deleted permanently. If any other team is using
          this team&apos;s suggestions or terms, they will be reverted to using their own.
        </Text>
        <div className={styles.controlButtons}>
          <Button
            className={styles.submitButton}
            type={ButtonTypes.DEFAULT}
            content="Never mind"
            onClick={changeModalState}
          />
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Yes, delete"
            onClick={handleDeleteTeam}
          />
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTeamModal;
