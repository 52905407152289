import { SNIPPET_SHORTCUT_PREFIX } from '@writercolab/types';

import type { TermColumnLabel } from '../../consts';
import { INTERNAL_REFERENCE_PREFIX, TERM_COLUMN_LABELS } from '../../consts';
import type { ColumnWithIndexType, MistakeForApiType, RawDataForConversionType, TagForApiType } from '../../types';
import { getValidAnswerVariant } from '../../utils';

const getTableNonHeaderRows = (tableData: string[][], amount = 10): string[][] => tableData.slice(1, amount + 1);

export const getColumnValues = (
  tableData: string[][],
  columnIndex,
  columnSupportHtml = false,
  amount = 10,
): string[] => {
  const parser = new DOMParser();

  return getTableNonHeaderRows(tableData, amount).map(row => {
    if (columnSupportHtml) {
      return parser.parseFromString(row[columnIndex], 'text/html').documentElement.textContent || '';
    }

    return row[columnIndex];
  });
};

export const getStringWithoutDelimiterAndDigit = (value: string): string => value.replace(/_[0-9]+/g, '');

export const trimValue = (value: string): string => value.trim();

const calculateValuePostfix = (value: string = ''): string => {
  const normalizedValue = value.replaceAll(' ', '_').toLowerCase();
  const itemPostfix = String(normalizedValue).match(/[0-9]+/g)?.[0];

  return itemPostfix || '';
};

const findRelatedColumn = (column: ColumnWithIndexType, label: TermColumnLabel, itemPostfix: string): boolean => {
  const postfix = calculateValuePostfix(column.columnInfo.initialName);

  return column.columnInfo.acceptableColumn?.label === label && postfix === itemPostfix;
};

export const convertMistakesForApi = (
  value: string,
  reference: string,
  rawData: RawDataForConversionType,
): MistakeForApiType => {
  const itemPostfix = calculateValuePostfix(rawData.currentColumn?.columnInfo.initialName);

  const relatedPartOfSpeechColumn = rawData.columnsWithData?.find(column =>
    findRelatedColumn(column, TERM_COLUMN_LABELS.CommonMistakePos, itemPostfix),
  );
  const pos = relatedPartOfSpeechColumn
    ? getValidAnswerVariant(
        rawData.currentRow?.[relatedPartOfSpeechColumn.index],
        relatedPartOfSpeechColumn.columnInfo.acceptableColumn,
      )
    : null;

  const relatedCaseSensitiveColumn = rawData.columnsWithData?.find(column =>
    findRelatedColumn(column, TERM_COLUMN_LABELS.CommonMistakeCs, itemPostfix),
  );

  const caseSensitive = relatedCaseSensitiveColumn
    ? getValidAnswerVariant(
        rawData.currentRow?.[relatedCaseSensitiveColumn.index],
        relatedCaseSensitiveColumn.columnInfo.acceptableColumn,
      )
    : true;

  const mistakeModel: MistakeForApiType = {
    mistake: value.trim(),
    caseSensitive: caseSensitive ?? true,
    reference,
  };

  if (pos) {
    mistakeModel.pos = pos;
  }

  return mistakeModel;
};

export const convertTagsForApi = (value: string): TagForApiType[] =>
  value.split(',').map(t => ({
    tag: t.trim(),
  }));

export const convertHighlightForApi = (
  initialValue: string | boolean,
  reference: string,
  rawData: RawDataForConversionType,
): string | boolean | undefined => {
  const relatedTypeColumn = rawData.columnsWithData?.find(
    column => column.columnInfo.acceptableColumn?.label === TERM_COLUMN_LABELS.Type,
  );
  const isBannedType =
    relatedTypeColumn &&
    getValidAnswerVariant(
      rawData.currentRow?.[relatedTypeColumn.index],
      relatedTypeColumn.columnInfo.acceptableColumn,
    ) === 'banned';

  const isUseCarefully =
    relatedTypeColumn &&
    getValidAnswerVariant(
      rawData.currentRow?.[relatedTypeColumn.index],
      relatedTypeColumn.columnInfo.acceptableColumn,
    ) === 'useCarefully';

  if (isBannedType || isUseCarefully) {
    const columnLabel = isBannedType
      ? TERM_COLUMN_LABELS.EnableDontUseSuggestions
      : TERM_COLUMN_LABELS.EnableUseCarefullySuggestions;
    const relatedColumn = rawData.columnsWithData?.find(
      column => column.columnInfo.acceptableColumn?.label === columnLabel,
    );

    return relatedColumn
      ? getValidAnswerVariant(rawData.currentRow?.[relatedColumn.index], relatedColumn.columnInfo.acceptableColumn)
      : initialValue;
  }

  return initialValue;
};

export const formatSnippetShortcutForApi = (shortcut: string): string => {
  const trimmedShortcut = trimValue(shortcut);

  return trimmedShortcut.startsWith(SNIPPET_SHORTCUT_PREFIX)
    ? trimmedShortcut.replace(SNIPPET_SHORTCUT_PREFIX, '')
    : trimmedShortcut;
};

export const validateReferenceForApi = (providedReference: string, internalReference: string): string =>
  providedReference.startsWith(INTERNAL_REFERENCE_PREFIX) || providedReference.includes('.')
    ? internalReference
    : providedReference;
