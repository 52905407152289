import type React from 'react';

import type { IDocument } from '@writercolab/common-utils';
import { Text, TextColor, TextSize, WTimeAgo } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IDocumentEditInfoProps {
  documentData: IDocument;
}

export const DocumentEditInfo: React.FC<IDocumentEditInfoProps> = ({ documentData }) => (
  <div className={styles.documentEditInfo}>
    <div className={styles.documentEditInfoEditorName}>
      <Text variant={TextSize.S}>By {documentData.modifiedUser?.fullName}</Text>
    </div>
    <Text variant={TextSize.S} color={TextColor.GREY2}>
      Last saved <WTimeAgo date={documentData.modificationTime} />
    </Text>
  </div>
);

export default DocumentEditInfo;
