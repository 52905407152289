import type { IOrganization, ITeam, IUserProfile, IUserSalesProfile } from '@writercolab/common-utils';

import type { ActionMap } from '@web/types';

export interface TAppState {
  organizations?: IOrganization[];
  unconfirmedOrganizations?: IOrganization[];
  organizationId?: number;
  organization: IOrganization | null;
  isInvalidOrg: boolean;
  teamId?: number;
  team?: ITeam | null;
  isInvalidTeam: boolean;
  documentId: number;
  isAppLoading: boolean;
  pendingAuthentication: boolean;
  /**
   * Deprecated, please use `user` from `appModel`
   */
  userProfile?: IUserProfile;
  userSalesProfile: IUserSalesProfile;
  isInvalidSubscription?: boolean;
  isTutorialDocBeaconVisible: boolean;
  isTutorialTipVisible: boolean;
  isTutorialVisible: boolean;
  currentRoute: string;
  site: {
    title: string;
  };
  isChromeExtInstalled: boolean;
  isChromeExtensionWindowVisible: boolean;
  isChromeAnnouncementVisible: boolean;
  isEducationalTermsWindowVisible: boolean;
  isEducationalSnippetsWindowVisible: boolean;
  isNotificationBarVisible: boolean;
}

export type Actions = ActionMap<TPayload>[keyof ActionMap<TPayload>];

export enum TActionType {
  SetPageTitle = 'Global/SetPageTitle',
  SetAppLoading = 'Global/SetAppLoading',
  SetIsChromeExtensionInstalled = 'Global/SetIsChromeExtensionInstalled',
  SetIsInvalidOrg = 'Org/SetIsInvalidOrg',
  OrganizationId = 'Org/OrganizationId', // TODO: Remove
  Organization = 'Org/Organization',
  SetOrganizations = 'Org/SetOrganizations',
  SetUnconfirmedOrganizations = 'Org/SetUnconfirmedOrganizations',
  TeamId = 'Team/TeamId', // TODO: Remove
  DocumentId = 'Doc/CurrentDocumentId',
  SetCurrentTeam = 'Team/SetCurrentTeam',
  SetInvalidTeam = 'Team/SetInvalidTeam',
  SetUserSalesProfile = 'User/SetUserSalesProfile',
  SetQuestionnaireGeneralUseCaseVisible = 'User/SetQuestionnaireGeneralUseCaseVisible',
  SetTutorialVisible = 'User/SetTutorialVisible',
  SetTutorialDocBeaconVisible = 'User/SetTutorialDocBeaconVisible',
  SetTutorialTipVisible = 'User/SetTutorialTipVisible',
  SetIsInvalidSubscription = 'Subscription/SetIsInvalidSubscription',
  SetChromeWindowState = 'Extension/SetChromeWindowState',
  SetEducationalTermsState = 'Extension/SetEducationalTermsState',
  SetEducationalSnippetsState = 'Extension/SetEducationalSnippetsState',
  SetChromeAnnouncementState = 'Extension/SetChromeAnnouncementState',
  SetNotificationBarState = 'Global/NotificationBar',
}

type TPayload = {
  [TActionType.SetPageTitle]: string;
  [TActionType.SetAppLoading]: boolean;
  [TActionType.SetIsChromeExtensionInstalled]: boolean;
  [TActionType.SetIsInvalidOrg]: TAppState['isInvalidOrg'];
  [TActionType.OrganizationId]: number | undefined; // TODO: Remove
  [TActionType.Organization]: TAppState['organization'];
  [TActionType.SetOrganizations]: TAppState['organizations'];
  [TActionType.SetUnconfirmedOrganizations]: TAppState['unconfirmedOrganizations'];
  [TActionType.TeamId]: TAppState['teamId']; // TODO: Remove
  [TActionType.DocumentId]: TAppState['documentId'];
  [TActionType.SetInvalidTeam]: TAppState['isInvalidTeam'];
  [TActionType.SetCurrentTeam]: TAppState['team'];
  [TActionType.SetUserSalesProfile]: TAppState['userSalesProfile'];
  [TActionType.SetIsInvalidSubscription]: TAppState['isInvalidSubscription'];
  [TActionType.SetTutorialVisible]: TAppState['isTutorialVisible'];
  [TActionType.SetTutorialTipVisible]: TAppState['isTutorialTipVisible'];
  [TActionType.SetTutorialDocBeaconVisible]: TAppState['isTutorialDocBeaconVisible'];
  [TActionType.SetChromeWindowState]: TAppState['isChromeExtensionWindowVisible'];
  [TActionType.SetChromeAnnouncementState]: TAppState['isChromeAnnouncementVisible'];
  [TActionType.SetEducationalTermsState]: TAppState['isEducationalTermsWindowVisible'];
  [TActionType.SetEducationalSnippetsState]: TAppState['isEducationalSnippetsWindowVisible'];
  [TActionType.SetNotificationBarState]: TAppState['isNotificationBarVisible'];
  [TActionType.SetQuestionnaireGeneralUseCaseVisible]: TAppState['isNotificationBarVisible'];
};
