import { computed, makeObservable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import { components } from '@writercolab/network';
import { getLogger } from '@writercolab/utils';

import { KnowledgeGraphsApiModel } from '../../../models/knowledgeGraphs.api';

export const STATUS_SYNC_TIMEOUT = 5000;

interface IGraphStatusUIModel {
  api: KnowledgeGraphsApiModel;
  graphId: () => string;
  teamId?: number;
}

const LOG = getLogger('GraphStatusUIModel');
export class GraphStatusUIModel {
  private api: KnowledgeGraphsApiModel;
  constructor(private opts: IGraphStatusUIModel) {
    this.api = opts.api;

    makeObservable(this, {
      filesStats: computed,
      parsingFilesCount: computed,
      indexingFilesCount: computed,
      errorParsingFilesCount: computed,
      errorTotalFilesCount: computed,
      successTotalFilesCount: computed,
    });
  }

  $filesStats: PromisedModel<components['schemas']['media_model_FileStats']> = new PromisedModel({
    name: '$filesStats',
    load: async () => {
      return this.api.getFileStats(this.opts.graphId(), {
        teamId: this.opts.teamId,
      });
    },
  });

  reloadFilesStats = async () => {
    this.$filesStats.reload();

    return this.$filesStats.promise;
  };

  get filesStats(): components['schemas']['media_model_FileStats'] | undefined {
    return this.$filesStats.value;
  }

  get parsingFilesCount() {
    return this.filesStats?.parsing || 0;
  }

  get indexingFilesCount() {
    return this.filesStats?.indexing || 0;
  }

  get errorParsingFilesCount() {
    return this.filesStats?.parsingFailures || 0;
  }

  get errorTotalFilesCount() {
    return this.filesStats?.totalFailures || 0;
  }

  get successTotalFilesCount() {
    return this.filesStats?.totalSuccesses || 0;
  }

  onRetry = async (fileIds?: [string, ...string[]]) => {
    try {
      await this.api.retryFiles({
        graphId: this.opts.graphId(),
        fileIds,
      });
    } catch (error) {
      LOG.error('onRetry', error);
    }
  };
}
