import { DropdownOption } from '@writercolab/ui-atoms';

import { DEFAULT_TEXT_PLACEHOLDER } from '@web/types';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';

/**
 * Extracts the placeholder text from an array of dropdown options based on the active option.
 * If an active option is found, its name is returned. Otherwise, the provided placeholder text is returned.
 *
 * @param opts - An array of DropdownOption objects representing the dropdown options.
 * @param emptyPlaceholder - Optional. The default placeholder text to use when no active option is found.
 *                      Defaults to DEFAULT_TEXT_PLACEHOLDER if not provided.
 * @returns The extracted placeholder text.
 */
export const extractTriggerPlaceholder = <T extends DropdownOption>(
  opts: T[],
  emptyPlaceholder = DEFAULT_TEXT_PLACEHOLDER,
): string => opts.find(({ active }) => active)?.name || emptyPlaceholder;

/**
 * Marks selected options in an array of dropdown options based on the provided list of selected option IDs.
 * Each option's `active` property is set to `true` if its ID is found in the list of selected option IDs; otherwise, it is set to `false`.
 *
 * @param opts - An array of DropdownOption objects representing the dropdown options.
 * @param selectedOptsIds - An array of strings representing the IDs of the selected options.
 * @returns An updated array of DropdownOption objects with the `active` property indicating whether each option is selected.
 */
export const markSelectedOptionsByIds = <T extends DropdownOption>(opts: T[], selectedOptsIds: string[]): T[] =>
  opts.map(opt => ({
    ...opt,
    active: selectedOptsIds.includes(opt.id),
  }));

/**
 * Extracts a placeholder string from the given dropdown options.
 * If one or more options are active, their names are used to create a comma-separated string.
 * If no options are active, a default placeholder is used.
 *
 * @param opts - An array of dropdown options, each with a 'name' and 'active' property.
 * @param placeholder - An optional default placeholder string. If not provided, a predefined default is used.
 * @returns The extracted placeholder string.
 */
export const extractPlaceholder = (opts: DropdownOption[], placeholder: string = DEFAULT_TEXT_PLACEHOLDER): string => {
  const activeOpts = opts.filter(({ active }) => active);

  if (isEmpty(activeOpts)) {
    return placeholder;
  } else {
    return activeOpts.map(({ name }) => name).join(', ');
  }
};

/**
 * Extracts and returns the icon associated with the first active option from a list of DropdownOptions.
 *
 * @param {DropdownOption[]} opts - An array of DropdownOptions.
 * @returns {React.ReactNode|null} The React node representing the icon of the first active option, or null if no active option is found.
 */
export const extractPlaceholderIcon = <T extends DropdownOption>(opts: T[]): React.ReactNode | null => {
  const activeOpts = opts.filter(({ active }) => active);

  if (isEmpty(activeOpts)) {
    return null;
  } else {
    return head(activeOpts)?.icon || null;
  }
};
