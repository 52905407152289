import React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { UtilIcon, UtilIconColor } from '@writercolab/ui-molecules';

import styles from '../styles.module.css';

interface IDynamicItemEmptyStateProps {
  onDeleteClick?: () => void;
  onReloadClick?: () => void;
  position?: DynamicItemEmptyStatePosition;
  loading?: boolean;
}

export enum DynamicItemEmptyStatePosition {
  HEADLINE = 'headline',
  KEYPOINTS = 'keypoints',
}

export const DynamicItemEmptyState: React.FC<IDynamicItemEmptyStateProps> = ({
  onDeleteClick,
  onReloadClick,
  loading,
  position = DynamicItemEmptyStatePosition.HEADLINE,
}) => (
  <div className={styles.dynamicItemEmptyStateContainer}>
    <div className={styles.dynamicItemEmptyStateLoaderContainer}>
      <SkeletonLoader
        type={SkeletonLoaderType.DEFAULT}
        className={cx(styles.dynamicItemEmptyStateLoader, {
          [styles.dynamicItemEmptyStateLoaderDisabled]: !loading,
        })}
      />
    </div>
    <div className={styles.dynamicItemEmptyStateDescription}>
      {position === DynamicItemEmptyStatePosition.HEADLINE ? (
        <>
          <Text variant={TextSize.L} bold>
            Hmm...got nothing!
          </Text>
          <Text variant={TextSize.L}>Try regenerating, or go back and use a different title</Text>
        </>
      ) : (
        <>
          <Text variant={TextSize.L} bold>
            Hmm...we couldn’t come up with key points
          </Text>
          <Text variant={TextSize.L}>Try regenerating, or remove this section</Text>
        </>
      )}
    </div>
    <div className={styles.dynamicItemEmptyStateAction}>
      {position === DynamicItemEmptyStatePosition.HEADLINE && (
        <Button
          size={SizeTypes.XS}
          type={ButtonTypes.BLACK}
          className={styles.dynamicItemEmptyRegenerateButton}
          onClick={onReloadClick}
        >
          <Icon name={IconVariant.WAND_WHITE} className={styles.generateTitlesButtonIcon} />
          <Text color={TextColor.WHITE} bold>
            Regenerate
          </Text>
        </Button>
      )}
      {position === DynamicItemEmptyStatePosition.KEYPOINTS && (
        <>
          <div className={styles.inlineIcons}>
            <UtilIcon
              variant={IconVariant.REFRESH_WHITE}
              circle
              onClick={onReloadClick}
              width={12}
              height={12}
              color={UtilIconColor.BLACK}
              className={cx(styles.outlineSectionContainerAction, styles.clickable)}
            />
            <UtilIcon
              variant={IconVariant.TRASH_WHITE}
              circle
              onClick={onDeleteClick}
              width={17}
              height={17}
              color={UtilIconColor.BLACK}
              className={cx(styles.outlineSectionContainerAction, styles.clickable)}
            />
          </div>
        </>
      )}
    </div>
  </div>
);

export default DynamicItemEmptyState;
