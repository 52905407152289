import type React from 'react';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import type { EApplicationType } from '@web/types';
import { appTypeColorTagMap, devApps, noCodeApps } from '@web/types';

import styles from './AppTypeBadge.module.css';
import { useAppState } from 'state';

interface IAppTypeBadgeProps {
  type: typeof EApplicationType.type;
}

export const AppTypeBadge: React.FC<IAppTypeBadgeProps> = ({ type }) => {
  const {
    appModel: { featureFlags },
  } = useAppState();

  let appName = Object.entries({ ...noCodeApps, ...devApps }).find(([id]) => id === type)?.[1].name;

  if (featureFlags.get('agentEditor', false) && type === 'framework') {
    appName = 'CUSTOM AGENT';
  }

  return (
    <div className={styles.container}>
      <div
        className={styles.tag}
        style={{
          backgroundColor: appTypeColorTagMap[type],
        }}
      >
        <Text
          variant={TextSize.M}
          color={TextColor.GREY2}
          upperCase
          medium
          style={{
            letterSpacing: '1.3px',
          }}
        >
          {appName}
        </Text>
      </div>
    </div>
  );
};
