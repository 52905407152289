import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextSize, Tooltip, TooltipAlignment } from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';

import styles from './InputLabel.module.css';

interface IInputLabelProps {
  className?: string;
  label: string;
  description?: string;
  required?: boolean;
  textSize?: TextSize;
}

export const InputLabel: React.FC<IInputLabelProps> = observer(
  ({ className, label, description, required, textSize }) => (
    <div className={cx(className, styles.label)}>
      <Text variant={textSize} medium>
        {label}
        {required && <span>*</span>}
      </Text>
      {description && (
        <Tooltip title={description} placement="top" tooltipWidth={150} align={TooltipAlignment.LEFT}>
          <div className={styles.labelIcon}>
            <Icon name={IconVariant.INFO_OUTLINED} width={18} height={18} />
          </div>
        </Tooltip>
      )}
    </div>
  ),
);
