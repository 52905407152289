import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

export const ValidFieldIndicator = () => (
  <div className={styles.validIconContainer}>
    <Icon name={IconVariant.CHECK} className={styles.validIcon} />
  </div>
);

export const ConfirmPasswordDescription = () => (
  <ul className={styles.passwordDescriptionTooltipCasesList}>
    <li className={styles.passwordDescriptionTooltipCase}>Include at least 8 characters</li>
    <li className={styles.passwordDescriptionTooltipCase}>Include one or more uppercase letters</li>
    <li className={styles.passwordDescriptionTooltipCase}>Include one or more numerical digits</li>
  </ul>
);

export const PasswordLockHeader = () => (
  <Heading variant={HeadingVariant.H3} className={cx(styles.containerRowTitle, styles.containerRowTitleWithLock)}>
    Password
    <Icon name={IconVariant.LOCK_LINE} />
  </Heading>
);

const SignupTypeHolder = ({ text, imageClassName }) => (
  <>
    <PasswordLockHeader />
    {text && (
      <Text variant={TextSize.L} className={styles.containerRowTitleDescription}>
        {text}
      </Text>
    )}
    {imageClassName && <div className={imageClassName} />}
  </>
);

export const SamlPlaceholder = () => (
  <SignupTypeHolder
    imageClassName={styles.ssoImageContainer}
    text="Your organization uses SAML SSO and doesn’t allow password sign-in, so you won’t be able to create a password.
          To sign in with Writer, click “Continue with SAML SSO” on the sign-in screen."
  />
);

export const SocialPlaceholder = () => (
  <SignupTypeHolder
    imageClassName={styles.socialImageContainer}
    text="You’re signed up with Google Sign-in. Your organization doesn’t allow password sign-in, so you won’t be able to create a password."
  />
);

export const GoogleAndPasswordPlaceholder = () => (
  <Text
    variant={TextSize.L}
    className={cx(styles.containerRowTitleDescription, styles.containerSocialRowTitleDescription)}
  >
    You’re using Google SSO but you can also sign in via password.
  </Text>
);

export const SsoAndPasswordPlaceholder = () => (
  <Text
    variant={TextSize.L}
    className={cx(styles.containerRowTitleDescription, styles.containerSocialRowTitleDescription)}
  >
    You’re using SSO, so you don’t have a password. Create a password below.
  </Text>
);

export const ActionButtonsGroup = ({ onClickSave, saveAvailable, onClickCancel, cancelAvailable, loading }) => (
  <>
    <Button
      className={styles.saveButton}
      type={ButtonTypes.PRIMARY}
      size={SizeTypes.SMALL}
      content="Save"
      onClick={onClickSave}
      disabled={!saveAvailable}
      isLoading={loading}
    />
    <Button
      className={styles.cancelButton}
      type={ButtonTypes.DEFAULT}
      size={SizeTypes.SMALL}
      content="Cancel"
      onClick={onClickCancel}
      disabled={!cancelAvailable}
      isLoading={loading}
    />
  </>
);
