import { computed, IComputedValue, makeObservable } from 'mobx';

import {
  type RequestQueryParams,
  type TOrgTeamUserActivityParams,
  type TPaginatedConsoleArgs,
} from '@writercolab/common-utils';
import type { FeatureFlags } from '@writercolab/feature-flags';
import { PaginatedModel, PromisedModel } from '@writercolab/mobx';
import type { AiAssistantSubscriptionModel } from '@writercolab/models';
import type { IFilterOptionsFilter } from '@writercolab/ui-molecules';

import type { TBriefApplication, TBriefApplicationsPaginated, TFeatureFlags, TWidgetConfig } from '@web/types';
import {
  TWidgetEditAppearance,
  TWidgetType,
} from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import debounce from 'lodash/debounce';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';
import requestService from 'services/request/requestService';

export const SHORTCUTS_LIMIT = 10;
export const APPS_PAGE_SIZE = 100;

interface IShortcutsHomeWidgetModelParams {
  hiddenApps: () => IComputedValue<Set<string>>;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  organizationId: () => number;
  teamId: () => number;
  featureFlags: () => FeatureFlags<TFeatureFlags>;
  assistantSubscription: () => AiAssistantSubscriptionModel;
  config: () => TWidgetConfig<typeof TWidgetType.enum.shortcuts>;
  lockedApps: () => string[];
}

export class ShortcutsHomeWidgetModelUi extends AbstractHomeWidgetModel<typeof TWidgetType.enum.shortcuts> {
  description = 'Select up to 10 apps to add as shortcuts for your team';
  editAppearance = TWidgetEditAppearance.enum.edit;

  private readonly $appsPaginated: PaginatedModel<
    TBriefApplicationsPaginated | undefined,
    TBriefApplication,
    TPaginatedConsoleArgs,
    RequestQueryParams
  >;

  constructor(private params: IShortcutsHomeWidgetModelParams) {
    super(TWidgetType.enum.shortcuts, () => params.config(), params.analyticsService);

    this.$appsPaginated = new PaginatedModel<
      TBriefApplicationsPaginated | undefined,
      TBriefApplication,
      TPaginatedConsoleArgs,
      RequestQueryParams
    >({
      argsExtra: {
        search: undefined,
      },
      argsDefault: {
        offset: 0,
        limit: APPS_PAGE_SIZE,
      },
      extractMeta: o => {
        if (!o) {
          return this.$appsPaginated.args;
        }

        const offset = (o.pagination.offset ?? 0) + (o.result?.length ?? 0);

        if (offset >= o.totalCount) {
          return this.$appsPaginated.args;
        }

        return {
          offset,
          limit: APPS_PAGE_SIZE,
        };
      },
      extract: o => o?.result ?? [],
      load: async ({ args, extra }) => {
        const { organizationId, teamId } = this;

        if (!organizationId || !teamId) {
          return undefined;
        }

        const { data } = await requestService.api.get(
          '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed',
          {
            params: {
              path: {
                organizationId,
                teamId,
              },
              query: {
                ...args,
                search: extra.search,
                sortField: 'name',
                sortOrder: 'asc',
                allowBeta: false,
              },
            },
          },
        );

        return data;
      },
    });

    makeObservable(this, {
      organizationId: computed,
      teamId: computed,
      isFree: computed,
      access: computed,
      shortcutIds: computed.struct,
      lockedShortcutIds: computed.struct,
      apps: computed.struct,
      isAppsLoading: computed,
      isValid: computed,
    });
  }

  get configForSave() {
    if (this.isAdminEdit) {
      return this.currentConfig;
    }

    return {
      ...this.currentConfig,
      data: {
        appIds: this.currentConfig.data.appIds.filter(id => !this.lockedShortcutIds.includes(id)),
      },
    };
  }

  get organizationId() {
    return this.params.organizationId();
  }

  get teamId() {
    return this.params.teamId();
  }

  get isFree() {
    return this.params.assistantSubscription()?.isFree;
  }

  get access() {
    return this.params.assistantSubscription()?.access;
  }

  get isValid() {
    return this.shortcutIds.length > 0 && this.shortcutIds.length <= SHORTCUTS_LIMIT;
  }

  get shortcutIds() {
    const hiddenAppIds = this.params.hiddenApps().get();

    return this.currentConfig.data.appIds.filter(id => !hiddenAppIds.has(id));
  }

  get lockedShortcutIds() {
    return this.params.lockedApps();
  }

  get apps() {
    return this.$apps.value || [];
  }

  get isAppsLoading() {
    return this.$apps.status === 'pending';
  }

  get isAppsPaginatedLoading() {
    return this.$appsPaginated.status === 'pending';
  }

  get isAppsPaginatedHasNext() {
    return this.$appsPaginated.hasNext;
  }

  get appsPaginated(): IFilterOptionsFilter[] {
    if (!this.$appsPaginated.value) {
      return [];
    }
    const hiddenAppIds = this.params.hiddenApps().get();

    return this.$appsPaginated.value
      .filter(app => !hiddenAppIds.has(app.id))
      .map(app => ({
        isSelected: false,
        id: app.id,
        name: app.name,
        description: app.description || '',
      }));
  }

  replaceEditableShortcutIds = (ids: string[]) => {
    this.editData({ appIds: ids });
  };

  addEditableShortcutId = (id: string) => {
    this.editData({ appIds: [...this.shortcutIds, id] });

    this.params.analyticsService.track(AnalyticsActivity.addNewShortcutAppSelected, {
      app_id: id,
      edit_flow: this.isAdminEdit ? 'team admin' : 'user',
    });
  };

  removeEditableShortcutId = (id: string) => {
    this.editData({ appIds: this.shortcutIds.filter(i => i !== id) });
    this.params.analyticsService.track(AnalyticsActivity.appShortcutRemoved, {
      app_id: id,
      edit_flow: this.isAdminEdit ? 'team admin' : 'user',
    });
  };

  private readonly $apps = new PromisedModel({
    name: '$apps',
    load: async () => {
      const { organizationId, teamId } = this;

      if (!organizationId || !teamId) {
        return undefined;
      }

      const response = await requestService.api.get(
        '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed',
        {
          params: {
            path: {
              organizationId,
              teamId,
            },
            query: {
              appId: this.shortcutIds,
              allowBeta: false,
            },
          },
        },
      );

      return response.data.result;
    },
  });

  loadMoreAppsPaginated = async () => {
    await this.$appsPaginated.next();
  };

  searchAppsPaginated = debounce((search: string) => {
    this.$appsPaginated.setExtra({
      search,
    });
  }, 500);
}
