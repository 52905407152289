import React from 'react';

import { IPaymentMethod } from '@writercolab/common-utils';
import { Modal } from '@writercolab/ui-atoms';

import { Stripe, PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';

import PaymentMethodWrapper from '../CheckoutElements/PaymentMethod/PaymentMethodWrapper';

import styles from './styles.module.css';

interface PaymentMethodPopupProps {
  open: boolean;
  orgId?: number;
  handleClose?: () => void;
  paymentMethod: IPaymentMethod | null;
  stripePromise: Promise<Stripe | null>;
  onSubmitSuccess?: (paymentMethod: StripePaymentMethod) => void;
  isPlanPastDue?: boolean;
}

export const PaymentMethodPopup: React.FC<PaymentMethodPopupProps> = ({
  open,
  handleClose,
  paymentMethod,
  stripePromise,
  onSubmitSuccess,
  isPlanPastDue,
  orgId,
}) => (
  <Modal open={open} title="Payment information" handleClose={handleClose} className={styles.modalBody}>
    <div className={styles.container}>
      <div className={styles.creditCardContainer}>
        {paymentMethod && (
          <PaymentMethodWrapper
            organizationId={orgId}
            paymentMethod={paymentMethod}
            stripePromise={stripePromise}
            onCancel={handleClose}
            onSubmitSuccess={onSubmitSuccess}
            isPlanPastDue={isPlanPastDue}
          />
        )}
      </div>
    </div>
  </Modal>
);

export default PaymentMethodPopup;
