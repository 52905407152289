import React, { useCallback, useMemo } from 'react';

import { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Heading, HeadingVariant, TextSize } from '@writercolab/ui-atoms';
import { Enum } from '@writercolab/utils';

import { InputLabel } from 'components/molecules/InputLabel/InputLabel';

import type { TBillingGroupWithCount } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { observer } from 'mobx-react-lite';

import MoneySvg from '../../../assets/icons/otherIcons/money.svg?react';
import { validEmailRegex } from '../../../utils/textUtils';
import { InputField } from '../InputField/InputField';

import styles from './BillingGroupsEntityPanel.module.css';

export const BillingGroupsPanelTypes = new Enum('create', 'editContact', 'editName');

interface IBillingGroupsEntityPanelProps {
  panelType: typeof BillingGroupsPanelTypes.type;
  selectedGroup?: TBillingGroupWithCount;
  isLoading?: boolean;
  onCreate?: ({ name, contact }: { name: string; contact: string }) => Promise<void>;
  onEdit?: ({ name, groupId, contact }: { name: string; groupId?: number; contact?: string }) => Promise<void>;
  onCancel?: () => void;
}

const titleMap = BillingGroupsPanelTypes.hash({
  create: 'Create a new billing group',
  editContact: 'Edit billing group contact',
  editName: 'Rename billing group',
});

const getTitle = (type: typeof BillingGroupsPanelTypes.type) => titleMap[type];

export const BillingGroupsEntityPanel = observer<IBillingGroupsEntityPanelProps>(
  ({ selectedGroup, panelType, isLoading, onCreate, onEdit, onCancel }) => {
    const billingGroupName = useMemo(
      () =>
        FieldModel.build({
          init: selectedGroup?.name || '',
          validation: value => {
            if (!value || isEmpty(trim(value))) {
              return 'This field is required';
            }

            return undefined;
          },
        }),
      [selectedGroup?.name],
    );

    const billingGroupContact = useMemo(
      () =>
        FieldModel.build({
          init: selectedGroup?.contact || '',
          autotouched: true,
          validation: value => {
            if (!value || isEmpty(trim(value))) {
              return 'This field is required';
            }

            if (!validEmailRegex.test(value)) {
              return 'Not valid email';
            }

            return undefined;
          },
        }),
      [selectedGroup?.contact],
    );

    const handleSubmit = useCallback(() => {
      // Create
      if (panelType === BillingGroupsPanelTypes.enum.create && billingGroupName.value && billingGroupContact.value) {
        onCreate?.({
          name: billingGroupName.value,
          contact: billingGroupContact.value,
        });
      }

      // Edit name
      if (selectedGroup?.id && panelType === BillingGroupsPanelTypes.enum.editName && billingGroupName.value) {
        onEdit?.({
          name: billingGroupName.value,
          groupId: selectedGroup.id,
        });
      }

      // Edit contact
      if (
        selectedGroup?.id &&
        selectedGroup?.name &&
        panelType === BillingGroupsPanelTypes.enum.editContact &&
        billingGroupContact.value
      ) {
        onEdit?.({
          contact: billingGroupContact.value,
          groupId: selectedGroup.id,
          name: selectedGroup.name,
        });
      }
    }, [
      billingGroupContact.value,
      billingGroupName.value,
      onCreate,
      onEdit,
      panelType,
      selectedGroup?.id,
      selectedGroup?.name,
    ]);

    const isValid = useMemo(() => {
      if (panelType === BillingGroupsPanelTypes.enum.editName) {
        return !!(billingGroupName.error || isLoading || billingGroupName.value === selectedGroup?.name);
      } else {
        return !!(
          billingGroupName.error ||
          billingGroupContact.error ||
          isLoading ||
          billingGroupContact.value === selectedGroup?.contact
        );
      }
    }, [
      billingGroupContact.error,
      billingGroupContact.value,
      billingGroupName.error,
      billingGroupName.value,
      isLoading,
      panelType,
      selectedGroup?.contact,
      selectedGroup?.name,
    ]);

    return (
      <form>
        <div className={styles.logoHeadWrapper}>
          <MoneySvg />
        </div>
        <Heading className={styles.heading} variant={HeadingVariant.H2} bold>
          {getTitle(panelType)}
        </Heading>
        <InputField
          disabled={panelType === BillingGroupsPanelTypes.enum.editContact}
          containerClassName={styles.inputGroup}
          field={billingGroupName}
          placeholder="e.g., Marketing"
        >
          <InputLabel textSize={TextSize.XL} label="Billing group name" />
        </InputField>
        {panelType !== BillingGroupsPanelTypes.enum.editName && (
          <InputField
            containerClassName={styles.inputGroup}
            field={billingGroupContact}
            placeholder="e.g., jane@acme.com"
          >
            <InputLabel textSize={TextSize.XL} label="Billing group contact" />
            <p className={styles.inputDescription}>
              Assign an internal contact for this billing group that should be contacted for any questions about budget
              management.
            </p>
          </InputField>
        )}
        <div className={styles.controlButtons}>
          <Button type={ButtonTypes.DEFAULT} content="Cancel" onClick={onCancel} />
          <Button
            type={ButtonTypes.PRIMARY}
            disabled={isValid}
            isLoading={isLoading}
            content={panelType === BillingGroupsPanelTypes.enum.create ? 'Create' : 'Update'}
            onClick={handleSubmit}
          />
        </div>
      </form>
    );
  },
);
