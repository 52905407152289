import React from 'react';

import cx from 'classnames';

import { ICancellationSurveyQuestion } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Modal,
  MuiCheckbox,
  MuiFormControl,
  MuiFormControlLabel,
  MuiFormGroup,
  SizeTypes,
  Text,
  TextSize,
} from '@writercolab/ui-atoms';

import generalStyles from '../styles.module.css';
import styles from './styles.module.css';

interface IOneTimeOfferPopup {
  open: boolean;
  isLoading?: boolean;
  questions: ICancellationSurveyQuestion[];
  comments: string;
  onCommentsChange: (comment: string) => void;
  onChangeQuestionState: (question: ICancellationSurveyQuestion) => void;
  onContinueDowngradeCb?: () => void;
  handleClose?: () => void;
}

export const CancellationSurvey: React.FC<IOneTimeOfferPopup> = ({
  open,
  isLoading,
  questions,
  comments,
  onCommentsChange,
  onChangeQuestionState,
  onContinueDowngradeCb,
  handleClose,
}) => (
  <Modal
    open={open}
    title="Tell us why you're cancelling your plan"
    className={generalStyles.modalContainer}
    handleClose={handleClose}
  >
    <div>
      <div className={cx(generalStyles.content, generalStyles.contentWithRightPadding)}>
        <MuiFormControl component="fieldset">
          <MuiFormGroup>
            {questions.map(question => (
              <MuiFormControlLabel
                key={question.title}
                control={
                  <MuiCheckbox
                    value={question.checked}
                    onChange={() => onChangeQuestionState(question)}
                    name={question.title}
                    color="primary"
                  />
                }
                label={<Text variant={TextSize.L}>{question.title}</Text>}
              />
            ))}
          </MuiFormGroup>
        </MuiFormControl>
        <textarea
          className={styles.comments}
          name="comments"
          value={comments}
          id="cancellation-comments"
          placeholder="Please elaborate here"
          rows={20}
          onChange={e => onCommentsChange(e.target.value)}
        ></textarea>
      </div>
      <div className={generalStyles.actionButtonsHolder}>
        <Button
          type={ButtonTypes.TRANSPARENT}
          size={SizeTypes.XS}
          className={generalStyles.secondaryAction}
          onClick={onContinueDowngradeCb}
          isLoading={isLoading}
          content="Continue with downgrade"
        ></Button>
        <Button
          type={ButtonTypes.BLACK}
          className={generalStyles.mainAction}
          onClick={handleClose}
          isLoading={isLoading}
          content="Keep my current plan"
        ></Button>
      </div>
    </div>
  </Modal>
);

export default CancellationSurvey;
