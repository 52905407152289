import { action, computed, makeObservable, observable } from 'mobx';

import {
  ContentEditorPageMode,
  E_INTEGRATION_TYPE,
  SidebarViewMode,
  TOrgTeamUserActivityParams,
} from '@writercolab/common-utils';
import type { RequestServiceInitialize } from '@writercolab/network';
import type { CommandsModel } from '@writercolab/ui-commands';
import { UISidebarModel } from '@writercolab/ui-sidebar';
import { SidebarDataModel, SidebarModel } from '@writercolab/ui-sidebar-models';

import { PromptLibraryModalModel } from 'components/organisms/PromptLibraryModal';
import { VersionHistoryUIModel } from 'components/organisms/VersionHistory/VersionHistoryModel.ui';
import { ContentEditorModel } from 'components/templates/ContentEditor';

import { IWebAppAnalyticsTrack } from 'constants/analytics';
import type { AppModel } from 'models/app';
import { ContentHistoryApiModel } from 'models/contentHistory.api';
import type { TAppLocalStorage } from 'models/localStorage';
import requestService from 'services/request/requestService';
import config from 'utils/dynamicConfig';

import { EditorApplicationUIModel } from '../../templates/EditorApplication';

interface IContentEditorPageModel {
  appModel: AppModel;
  request: RequestServiceInitialize['api'];
  currentMode: ContentEditorPageMode;
  storage: TAppLocalStorage;
}

export class ContentEditorPageModel {
  commandsModel: CommandsModel;
  uiSidebarModel: UISidebarModel;
  contentEditorModel: ContentEditorModel;
  sidebarDataModel: SidebarDataModel;
  sidebarModel: SidebarModel;
  applicationId?: string;
  currentMode: ContentEditorPageMode;
  promptLibraryModalModel: PromptLibraryModalModel;
  versionHistoryModel: VersionHistoryUIModel;
  editorApplicationModel: EditorApplicationUIModel;

  constructor(private opts: IContentEditorPageModel) {
    this.currentMode = opts.currentMode;

    this.promptLibraryModalModel = new PromptLibraryModalModel({
      requestService: opts.request,
      analyticsService: opts.appModel.analyticsService,
      organizationId: Number(opts.appModel.organizationId),
      teamId: Number(opts.appModel.teamId),
    });

    this.sidebarDataModel = new SidebarDataModel({
      client: this.opts.appModel.dataClient,
    });

    const analyticsForSidebar: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams> =
      opts.appModel.analyticsService.withContext<any, TOrgTeamUserActivityParams>({}, ctx => {
        const { organization_id, team_id, user_id, user_email } = ctx;

        return { organization_id, team_id, user_id, user_email };
      });

    this.sidebarModel = new SidebarModel({
      getCmudict: () => this.opts.appModel.cmudictLib,
      integrationType: E_INTEGRATION_TYPE.enum.DEFAULT,
      appRoot: config.APP_ROOT,
      analytics: analyticsForSidebar,
      requestService,
      sidebarDataModel: this.sidebarDataModel,
      subscriptionModel: this.opts.appModel.assistantSubscription,
      sidebarMode: () => this.sidebarMode,
      organizationId: () => this.opts.appModel.organizationId,
      documentId: () => this.opts.appModel.documentId,
      teamId: () => this.opts.appModel.teamId,
      isTeamAdmin: () => !!this.opts.appModel.permissionsModel?.isTeamAdmin,
      isOrgAdmin: () => !!this.opts.appModel.permissionsModel?.isOrganizationAdmin,
      teamName: () => (this.opts.appModel.assistantSubscription.isMultiTeam && this.opts.appModel.team?.name) || '',
      personaId: () => 94,
      userId: () => this.opts.appModel.user?.id,
      userEmail: () => this.opts.appModel.user?.email || '',
    });

    this.editorApplicationModel = new EditorApplicationUIModel({
      request: this.opts.request,
      subscriptionModel: () => this.opts.appModel.assistantSubscription,
      storage: this.opts.storage,
      applicationId: () => this.applicationId,
      teamId: () => this.opts.appModel.teamId,
      organizationId: () => this.opts.appModel.organizationId,
      documentId: () => this.opts.appModel.documentId,
      voices: () => this.opts.appModel.voiceModel?.voiceList || [],
      retentionPreferences: () => this.opts.appModel.dataRetentionModel?.retentionPreferences,
      organizationName: this.opts.appModel.organization?.name,
    });

    this.uiSidebarModel = new UISidebarModel({
      model: this.sidebarModel,
    });

    this.contentEditorModel = new ContentEditorModel({
      sidebarModel: this.sidebarModel,
    });

    this.commandsModel = this.opts.appModel.commandsModel;

    this.versionHistoryModel = new VersionHistoryUIModel({
      api: () => this.contentHistoryApiModel,
    });

    makeObservable(this, {
      currentMode: observable,
      applicationId: observable,

      coWriteLocked: computed,
      contentHistoryApiModel: computed,
      seoBlogBuilderLocked: computed,
      documentHighlightsLocked: computed,
      claimsLocked: computed,
      eventTakeawaysLocked: computed,
      isLibraryMode: computed,
      isBlogMode: computed,
      isEventTakeawaysMode: computed,
      isCoWriteMode: computed,
      isPureEditorMode: computed,
      isHighlightsMode: computed,
      isMagicLinksMode: computed,
      isClaimsDetectionMode: computed,
      isLockedClaimsMode: computed,
      isStartWithLibraryMode: computed,
      sidebarMode: computed,

      setCurrentMode: action.bound,
      setApplication: action.bound,
    });
  }

  setCurrentMode(mode: ContentEditorPageMode) {
    this.currentMode = mode;
  }

  setApplication(applicationId: string) {
    this.applicationId = applicationId;
  }

  get contentHistoryApiModel() {
    if (!this.opts.appModel.organizationId || !this.opts.appModel.teamId || !this.opts.appModel.documentId) {
      return undefined;
    }

    return new ContentHistoryApiModel({
      request: this.opts.request,
      organizationId: this.opts.appModel.organizationId,
      teamId: this.opts.appModel.teamId,
      documentId: this.opts.appModel.documentId,
    });
  }

  get isRewriteLocked() {
    return !this.opts.appModel.assistantSubscription.access?.reWrite;
  }

  get coWriteLocked() {
    return !this.opts.appModel.assistantSubscription.access?.coWrite;
  }

  get seoBlogBuilderLocked() {
    return !this.opts.appModel.assistantSubscription.access?.seoBlogBuilder;
  }

  get documentHighlightsLocked() {
    return !this.opts.appModel.assistantSubscription.access?.highlight;
  }

  get claimsLocked() {
    return (
      !this.opts.appModel.assistantSubscription.access?.claimDetection ||
      this.opts.appModel.assistantSubscription.isFree
    );
  }

  get eventTakeawaysLocked() {
    return !this.opts.appModel.assistantSubscription.access?.eventTakeaways;
  }

  get isLibraryMode() {
    return (
      this.opts.appModel.assistantSubscription.isModelReady &&
      (this.currentMode === ContentEditorPageMode.START_WITH_LIBRARY ||
        this.currentMode === ContentEditorPageMode.LIBRARY ||
        (this.currentMode === ContentEditorPageMode.BLOG && this.seoBlogBuilderLocked) ||
        (this.isCoWriteMode && this.coWriteLocked) ||
        (this.currentMode === ContentEditorPageMode.EVENT_TAKEAWAYS && this.eventTakeawaysLocked))
    );
  }

  get isBlogMode() {
    return !this.seoBlogBuilderLocked && this.currentMode === ContentEditorPageMode.BLOG;
  }

  get isEventTakeawaysMode() {
    return !this.eventTakeawaysLocked && this.currentMode === ContentEditorPageMode.EVENT_TAKEAWAYS;
  }

  get isCoWriteMode() {
    return this.currentMode === ContentEditorPageMode.CO_WRITE;
  }

  get isPureEditorMode() {
    return this.currentMode === ContentEditorPageMode.PURE_EDITOR;
  }

  get isHighlightsMode() {
    return this.currentMode === ContentEditorPageMode.HIGHLIGHTS;
  }

  get isMagicLinksMode() {
    return this.currentMode === ContentEditorPageMode.MAGIC_LINKS;
  }

  get isClaimsDetectionMode() {
    return this.currentMode === ContentEditorPageMode.CLAIMS_DETECTION;
  }

  get isLockedClaimsMode() {
    return this.claimsLocked && this.isClaimsDetectionMode;
  }

  get isStartWithLibraryMode() {
    return this.currentMode === ContentEditorPageMode.START_WITH_LIBRARY;
  }

  get sidebarMode() {
    if (this.isCoWriteMode) {
      return SidebarViewMode.PREVIEW;
    }

    return this.isPureEditorMode ? SidebarViewMode.OPEN : SidebarViewMode.PREVIEW;
  }
}
