import React, { useCallback } from 'react';
import cx from 'classnames';
import type { IconVariant } from '@writercolab/ui-atoms';
import { Icon, Popover } from '@writercolab/ui-atoms';
import type { QUILL_FORMAT } from '@writercolab/quill-delta-utils';

import styles from './EditorToolbarButton.module.css';

const DEFAULT__EDITOR_TOOLBAR_ICON_SIZE = 20;

interface IEditorToolbarFormatButtonProps {
  format?: QUILL_FORMAT;
  icon: IconVariant;
  iconWidth?: number;
  iconHeight?: number;
  active?: boolean;
  value?: string;
  expandable?: boolean;
  children?: React.ReactNode;
  onClick?: (format: QUILL_FORMAT, value?: string) => void;
}

export const EditorToolbarButton: React.FC<IEditorToolbarFormatButtonProps> = ({
  format,
  value,
  onClick,
  icon,
  active = false,
  expandable = false,
  children,
  iconWidth = DEFAULT__EDITOR_TOOLBAR_ICON_SIZE,
  iconHeight = DEFAULT__EDITOR_TOOLBAR_ICON_SIZE,
}) => {
  const onButtonClick = useCallback(() => format && onClick?.(format, value), [onClick, format, value]);

  return (
    <div className={styles.container}>
      <div
        className={cx(styles.button, styles.buttonShort, {
          [styles.buttonActive]: active,
        })}
        onClick={onButtonClick}
      >
        {expandable ? (
          <Popover trigger={<Icon name={icon} width={iconWidth} height={iconHeight} />}>{children}</Popover>
        ) : (
          <Icon name={icon} width={iconWidth} height={iconHeight} />
        )}
      </div>
    </div>
  );
};

export default EditorToolbarButton;
