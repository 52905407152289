import type React from 'react';
import { useCallback, useMemo } from 'react';

import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import type { IFilterOptionsFilter } from '@writercolab/ui-molecules';
import { FilterOptions } from '@writercolab/ui-molecules';

import { TKnowledgeGraphDropdownAction } from '@web/types';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphPageModel } from '../../pages/KnowledgeGraphPage/KnowledgeGraphPageModel';

import styles from './KnowledgeGraphSelector.module.css';

const SELECT_KNOWLEDGEGRAPH_DROPDOWN_OPTIONS = [
  {
    id: TKnowledgeGraphDropdownAction.enum.rename,
    name: 'Edit graph details',
  },
  {
    id: TKnowledgeGraphDropdownAction.enum.delete,
    name: 'Delete',
  },
];

interface IKnowledgeGraphSelectorProps extends BaseComponentsProps {
  model: KnowledgeGraphPageModel;
  onChange: (filter: IFilterOptionsFilter) => void;
  onDropdownClick: (id: string) => void;
  viewOnly: boolean;
}

const renderKGTrigger =
  (value: string) =>
  ({ onClick }: { isOpen: boolean; onClick: () => void }) => (
    <div className={styles.selectTrigger} onClick={onClick}>
      <Icon name={IconVariant.KNOWLEDGE_GRAPH} width={18} height={18} className={styles.selectedIcon} />
      <Text variant={TextSize.XXXXL} color={TextColor.BLACK} medium ellipsisOverflow>
        {value}
      </Text>
      <span className={styles.arrowIcon}>
        <Icon name={IconVariant.DROP_DOWN_ARROW} />
      </span>
    </div>
  );

export const KnowledgeGraphSelector: React.FC<IKnowledgeGraphSelectorProps> = observer(
  ({ model, onChange, onDropdownClick, viewOnly }) => {
    const handleSearch = useCallback(
      (value: string) => {
        model.updateGraphsFilterExtraArgsDebounced({ search: value });
      },
      [model],
    );

    const filterOptions = useMemo(() => {
      return model.graphs.map(graph => ({
        id: graph.id,
        name: graph.name,
        isSelected: graph.id === model.selectedGraph?.id,
        icon: <Icon name={IconVariant.KNOWLEDGE_GRAPH} />,
      }));
    }, [model.graphs, model.selectedGraph?.id]);

    return (
      <div className={styles.container}>
        <div className={styles.controls}>
          <div className={styles.selectWrapper}>
            <div className={styles.selectLabelWrapper}>
              <Text variant={TextSize.XXS} color={TextColor.GREY} upperCase className={styles.selectLabel}>
                Graph
              </Text>
            </div>

            <FilterOptions
              isAutoClose
              isSearchable
              isShowClearAll={false}
              onSearch={handleSearch}
              renderTrigger={renderKGTrigger(model.selectedGraph?.name ?? '')}
              options={filterOptions}
              isEnableInfiniteScroll
              hasNextPage={model.hasGraphsFilterNextPage}
              isLoading={model.isGraphsLoading}
              onLoadMore={model.loadMoresGraphsFilter}
              onResetSearch={model.onResetGraphsFilterSearch}
              isSingleSelect
              onChange={onChange}
              placement="center"
              testId="kg-select-dropdown"
            />
          </div>

          {!viewOnly && (
            <Dropdown
              containerClassName={styles.dropdown}
              trigger={
                <Button
                  round
                  className={styles.dropdownTrigger}
                  type={ButtonTypes.GRAY}
                  size={SizeTypes.SMALL}
                  icon={<Icon name={IconVariant.ELLIPSES} />}
                />
              }
              options={SELECT_KNOWLEDGEGRAPH_DROPDOWN_OPTIONS}
              placement={DropdownPlacement.BOTTOM_LEFT}
              onPrimaryOptionClickAction={onDropdownClick}
              testId="kg-select-dropdown-actions"
            />
          )}
        </div>
      </div>
    );
  },
);
