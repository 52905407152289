import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

export const TWidgetType = new Enum(
  'featuredChatApp',
  'shortcuts',
  'recentWork',
  'apps',
  'aiStudio',
  'writerAiAcademy',
  'extensions',
);

export const RecentWorkWidgetTabId = new Enum('sessions', 'docs');
export const AppsWidgetTabId = new Enum('custom', 'favorite', 'collection', 'recent');
export const AppsWidgetSortFields = new Enum('name', 'recentlyUsed');
export const THomeWidgetsState = new Enum('view', 'adminEdit');

export type TAiStudioWidgetConfigData = components['schemas']['organization_model_WidgetData_AIStudio'];
export type TAppsWidgetConfigData = components['schemas']['organization_model_WidgetData_Apps'];
export type TExtensionsWidgetConfigData = components['schemas']['organization_model_WidgetData_Extensions'];
export type TFeaturedChatAppWidgetConfigData = components['schemas']['organization_model_WidgetData_FeaturedChatApp'];
export type TRecentWorkWidgetConfigData = components['schemas']['organization_model_WidgetData_RecentWork'];
export type TShortcutsWidgetConfigData = components['schemas']['organization_model_WidgetData_Shortcuts'];
export type TWriterAiAcademyWidgetConfigData = components['schemas']['organization_model_WidgetData_WriterAcademy'];

export const TWidgetEditAppearance = new Enum('edit', 'readonly');

export type IHomeWidgetConfig = {
  id: string;
  visible: boolean;
} & (
  | {
      type: typeof TWidgetType.enum.aiStudio;
      data: TAiStudioWidgetConfigData;
    }
  | {
      type: typeof TWidgetType.enum.apps;
      data: TAppsWidgetConfigData;
    }
  | {
      type: typeof TWidgetType.enum.extensions;
      data: TExtensionsWidgetConfigData;
    }
  | {
      type: typeof TWidgetType.enum.featuredChatApp;
      data: TFeaturedChatAppWidgetConfigData;
    }
  | {
      type: typeof TWidgetType.enum.recentWork;
      data: TRecentWorkWidgetConfigData;
    }
  | {
      type: typeof TWidgetType.enum.shortcuts;
      data: TShortcutsWidgetConfigData;
    }
  | {
      type: typeof TWidgetType.enum.writerAiAcademy;
      data: TWriterAiAcademyWidgetConfigData;
    }
);

export type TWidgetConfig<T extends typeof TWidgetType.type> = Extract<IHomeWidgetConfig, { type: T }>;
