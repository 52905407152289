import React, { useMemo } from 'react';

import { SkeletonLoader, SkeletonLoaderType, Text, TextColor } from '@writercolab/ui-atoms';

import { ResetEmptyFilterData } from 'components/molecules/ResetEmptyFilterData';

import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { BaseTable } from '../../../organisms/BaseTable/BaseTable';
import { BillingGroupPageUIModel } from '../BillingGroupPageModel.ui';
import { generateTableBody } from './utils';

import styles from './BillingGroupTable.module.css';

export const BillingGroupTable = observer(
  ({
    model,
    clearFilter,
    isSearchParamsExist,
  }: {
    model: BillingGroupPageUIModel;
    clearFilter: () => void;
    isSearchParamsExist: boolean;
  }) => {
    const tableData = useMemo(
      () =>
        generateTableBody({
          data: model.data,
          modalsManager: model.modalsManager,
          tableActionsQueue: model.tableActionsQueue,
        }),
      [model.data, model.modalsManager, model.tableActionsQueue],
    );

    const tableHeaders = [
      {
        component: <Text color={TextColor.GREY2}>Billing group name</Text>,
      },
      {
        component: <Text color={TextColor.GREY2}>Users</Text>,
      },
      {
        component: <Text color={TextColor.GREY2}>Billing group contact</Text>,
      },
      {},
    ];

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading: model.isLoading,
      hasNextPage: model.hasNext,
      onLoadMore: model.loadMore,
      rootMargin: '0px 0px 400px 0px',
    });

    return (
      <div className={styles.tableContainer}>
        <div className={styles.tableScrollableContainer} ref={rootRef}>
          <BaseTable
            headers={tableHeaders}
            data={tableData}
            loading={model.showLoader}
            isEnableSticky={false}
            gridTemplateColumns="minmax(270px,1fr) minmax(180px,1fr) minmax(200px,1fr) minmax(50px,1fr)"
            customEmpty={
              isSearchParamsExist && (
                <ResetEmptyFilterData
                  onClearFilter={clearFilter}
                  text="No billing groups found matching your filters"
                />
              )
            }
          />
          {model.hasNext && (
            <div className={styles.loaderWrapper} ref={sentryRef}>
              <SkeletonLoader type={SkeletonLoaderType.ISSUE_CARD} className={styles.loader} />
            </div>
          )}
        </div>
      </div>
    );
  },
);
