import type React from 'react';
import { useMemo, useState } from 'react';

import cx from 'classnames';

import { IconsCommonWand } from '@writercolab/assets';
import type { ITableFilter } from '@writercolab/common-utils';
import {
  TAssetToDelete,
  isDateTodays,
  isDateYesterdays,
  openNewTab,
} from '@writercolab/common-utils';
import type { DropdownOption } from '@writercolab/ui-atoms';
import {
  Button,
  ButtonTypes,
  DataRetentionPolicyBanner,
  Dropdown,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  Modal,
  SearchBar,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Slideout,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { FilterHeader, Unreachable, UnreachableType } from '@writercolab/ui-molecules';

import { AppShortcutButton } from 'components/molecules/AppShortcutButton';

import type { IDraft } from '@web/types';
import { DraftPageParams, DraftsFilterKey, IDraftDropdownOptionKey } from '@web/types';
import { useDataRetentionBanner } from 'hooks/useDataRetentionBanner';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useDebounce } from 'react-use';

import { useDraftsContext } from '../../../context/draftsContext';
import { usePageTitle } from '../../../hooks/usePageTitle';
import useQuery from '../../../hooks/useQuery';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import DraftRowCard from '../../molecules/DraftRowCard';
import PageTitle from '../../molecules/PageTitle';
import EditDraftPanel from '../../organisms/EditDraftPanel';
import { TagsFiltersContainer } from '../termsAndSnippetsShared/commonComponents';

import styles from './styles.module.css';
import { AnalyticsActivity } from 'constants/analytics';

const LabelElement = ({ children }) => (
  <Heading variant={HeadingVariant.H3} bold className={cx('stickyListItem', 'stickyDraftColumn')}>
    {children}
  </Heading>
);

interface IRenderDraftByDateProps {
  list: IDraft[];
  label: string;
  openId: string;
}

const downloadOptions: DropdownOption[] = [
  {
    name: 'Download as CSV',
    id: IDraftDropdownOptionKey.CSV,
  },
  {
    name: 'Download as XLSX',
    id: IDraftDropdownOptionKey.XLSX,
  },
];

export const DraftsPage: React.FC = observer(() => {
  usePageTitle('Drafts');
  const query = useQuery();
  const { appState, appModel } = useAppState();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const {
    state,
    infiniteRefSentry,
    currentTeam,
    selectDrafts,
    handleSearch,
    handleFilterChange,
    handleTagsFilterClose,
    handleFileDownload,
    handleDeleteDrafts,
    handleExpandDraft,
    handleDeleteDraftIdFromQuery,
  } = useDraftsContext();
  const { shouldShowPolicyBanner, ...bannerProps } = useDataRetentionBanner({ asset: TAssetToDelete.enum.Draft });

  const openId = query.get(DraftPageParams.OPEN_ID) as string;

  const { filters, selectedDrafts, isLoading, openedDraft, drafts, isExpandedDraft } = state;
  const isFilterTagsApplied = filters[DraftsFilterKey.TEMPLATE].filter(filter => filter.isSelected);

  useDebounce(() => handleSearch(searchValue), 500, [searchValue]);

  const handleClearInput = () => setSearchValue('');
  const checkSearch = (value: string) => setSearchValue(value);
  const handleDropdownAction = (id: string) => handleFileDownload(id);

  const onStartWithCoWrite = () => {
    appModel.analyticsService.track(AnalyticsActivity.startWithCoWrite, {});
    navigate(ROUTE.toEditorWithCoWrite(appState.organizationId, appState.teamId));
  };

  const onLearnMore = () => openNewTab('https://support.writer.com/article/187-introducing-cowrite');
  const onCloseSlideout = () => handleDeleteDraftIdFromQuery();

  const onFilterChanged = (updatedFilters: ITableFilter[]) => {
    appModel.analyticsService.track(AnalyticsActivity.filteredDrafts, {
      filter: 'apps',
      templateIds: updatedFilters.map(filter => filter.id),
    });

    handleFilterChange(DraftsFilterKey.TEMPLATE, updatedFilters);
  };

  const onDraftCardClicked = (draftId: number) => {
    query.set(DraftPageParams.OPEN_ID, `${draftId}`);
    navigate(ROUTE.toTeamDraftsWithDraftId(appState.organizationId, appState.teamId, query.toString()));
  };

  const renderDraftByDate = ({ list, label, openId }: IRenderDraftByDateProps) => (
    <>
      {!isEmpty(list) && <LabelElement>{label}</LabelElement>}
      <div>
        {list.map((draft: IDraft, i: number) => (
          <DraftRowCard
            key={draft.id}
            openId={openId}
            draft={draft}
            isFirst={i === 0}
            selectedDrafts={selectedDrafts}
            selectDrafts={selectDrafts}
            onCardClicked={onDraftCardClicked}
          />
        ))}
      </div>
    </>
  );

  const renderedDraftsByCreationDate = useMemo(() => {
    const _todayList = drafts.filter(draft => isDateTodays(draft.creationTime));
    const _yesterdaysList = drafts.filter(draft => isDateYesterdays(draft.creationTime));
    const _earlierList = drafts.filter(
      draft => !isDateTodays(draft.creationTime) && !isDateYesterdays(draft.creationTime),
    );

    return (
      <>
        {!isEmpty(_todayList) &&
          renderDraftByDate({
            list: _todayList,
            label: 'Today',
            openId: (openId as string) || '',
          })}
        {!isEmpty(_yesterdaysList) &&
          renderDraftByDate({
            list: _yesterdaysList,
            label: 'Yesterday',
            openId: (openId as string) || '',
          })}
        {renderDraftByDate({
          list: _earlierList,
          label: 'Earlier',
          openId: (openId as string) || '',
        })}
        <div ref={infiniteRefSentry} />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafts, selectedDrafts, openId]);

  const templateFilters = filters[DraftsFilterKey.TEMPLATE];
  const { dataRetentionModel } = appModel;

  return (
    <div className={cx(styles.pageWrapper, styles.stickyDocsHeader, styles.stickyDocsHeaderWithoutButtons)}>
      <section className={styles.draftsWrapper}>
        <div className={styles.contentBar}>
          <div className={styles.headLine}>
            <div className={styles.titleAndSearch}>
              <PageTitle
                title="Drafts"
                description="View and manage all your drafts generated by Writer."
                teamName={currentTeam?.name}
              />

              <div className={styles.newButtons}>
                {!isEmpty(selectedDrafts) && (
                  <>
                    <Text variant={TextSize.XXS} bold color={TextColor.GREY2} smallCaps>
                      {selectedDrafts.length} SELECTED
                    </Text>

                    <Dropdown
                      dropDownContainerClassName={styles.removeContainer}
                      trigger={
                        <Button
                          className={styles.importButton}
                          type={ButtonTypes.GRAY}
                          size={SizeTypes.MEDIUM}
                          round
                          icon={<Icon name={IconVariant.DOWNLOAD} />}
                        />
                      }
                      options={downloadOptions}
                      onPrimaryOptionClickAction={handleDropdownAction}
                    />

                    <Button
                      className={styles.deleteButton}
                      type={ButtonTypes.GRAY}
                      size={SizeTypes.MEDIUM}
                      round
                      icon={<Icon name={IconVariant.TRASH} />}
                      onClick={handleDeleteDrafts}
                    />
                  </>
                )}

                {!isEmpty(isFilterTagsApplied) && (
                  <TagsFiltersContainer filtersAmount={isFilterTagsApplied.length} onClose={handleTagsFilterClose} />
                )}

                <SearchBar
                  id="search"
                  value={searchValue}
                  placeholder="Search"
                  onChange={e => checkSearch(e.target.value)}
                  handleClearInput={handleClearInput}
                  className={styles.searchBar}
                />
              </div>
            </div>
          </div>

          {shouldShowPolicyBanner && <DataRetentionPolicyBanner className={styles.banner} {...bannerProps} />}

          <div className={styles.draftsListColumns}>
            <div className={cx(styles.tableColumn, styles.firstColumn)}>
              <Heading variant={HeadingVariant.H3} bold className={styles.header}>
                Today
              </Heading>
            </div>

            <div className={cx(styles.tableColumn, styles.secondColumn)}>
              {templateFilters.length > 0 && (
                <FilterHeader
                  menuClassName={styles.filterPopup}
                  header={
                    <Text variant={TextSize.M} color={TextColor.GREY2}>
                      App
                    </Text>
                  }
                  title={
                    <Text variant={TextSize.XXS} smallCaps>
                      Filter by {DraftsFilterKey.TEMPLATE}
                    </Text>
                  }
                  options={templateFilters}
                  onChange={onFilterChanged}
                />
              )}
            </div>

            <div className={cx(styles.tableColumn, styles.lastColumn)}>
              <Text color={TextColor.GREY2}>Created</Text>
            </div>
          </div>

          <div>{renderedDraftsByCreationDate}</div>

          {isLoading && <SkeletonLoader type={SkeletonLoaderType.DRAFTS} />}

          {isEmpty(drafts) && !isLoading && (
            <div className={styles.unreachable}>
              {isEmpty(searchValue) ? (
                <div>
                  <Unreachable type={UnreachableType.enum.draftsListZeroState} />

                  <Text variant={TextSize.XXXXL} color={TextColor.GREY2} className={styles.zeroState}>
                    No drafts yet.{' '}
                    <Button type={ButtonTypes.LINK} onClick={onStartWithCoWrite}>
                      Click below
                    </Button>{' '}
                    to start a draft with apps, or learn more{' '}
                    <span className={styles.zeroHere} onClick={onLearnMore}>
                      here
                    </span>
                    .
                  </Text>
                </div>
              ) : (
                <Unreachable type={UnreachableType.enum.draftsListEmpty} />
              )}

              <AppShortcutButton
                className={styles.newDocButton}
                iconClassName={styles.wandDocButtonIcon}
                text="Start with apps"
                onClick={onStartWithCoWrite}
                icon={<IconsCommonWand width="40px" height="40px" />}
              />
            </div>
          )}
        </div>
      </section>

      <Slideout className={styles.slideoutContentContainer} isOpen={!!openedDraft} onClose={onCloseSlideout}>
        <EditDraftPanel
          className={styles.isPreviewSlideout}
          dataRetentionPreferences={dataRetentionModel?.retentionPreferences}
        />
      </Slideout>

      <Modal open={isExpandedDraft} handleClose={handleExpandDraft} className={styles.isDraftPreviews}>
        <EditDraftPanel isPreviewMode dataRetentionPreferences={dataRetentionModel?.retentionPreferences} />
      </Modal>
    </div>
  );
});

export default DraftsPage;
