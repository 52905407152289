import type { ChangeEvent } from 'react';
import React, { useCallback, useState } from 'react';

import cx from 'classnames';
import qs from 'qs';

import { openNewTab } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Dropdown,
  DropdownPlacement,
  Heading,
  HeadingVariant,
  Icon,
  IconVariant,
  ItemsTypes,
  LinkText,
  SearchBar,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import type { TPaginatedBillingGroupExtraArgs } from '@web/types';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router';

import { BillingGroupsPanelTypes } from '../../organisms/BillingGroupsEntityPanel/BillingGroupsEntityPanel';
import { DOWNLOAD_TYPE, headerTooltipText } from '../termsAndSnippetsShared/common';
import { DownloadButton, ImportButton } from '../termsAndSnippetsShared/commonComponents';
import type { BillingGroupPageUIModel } from './BillingGroupPageModel.ui';
import { BillingGroupAction } from './BillingGroupPageModel.ui';
import { BillingGroupTable } from './BillingGroupTable/BillingGroupTable';

import styles from './BillingGroup.module.css';

const downloadDropdownOptions = [
  {
    name: 'Download as csv',
    id: DOWNLOAD_TYPE.CSV,
    icon: <Icon name={IconVariant.SPREADSHEET} width={18} height={18} />,
  },
  {
    name: 'Download as xlsx',
    id: DOWNLOAD_TYPE.XLSX,
    icon: <Icon name={IconVariant.SPREADSHEET} width={18} height={18} />,
  },
];
const handleSupportPageClick = () => openNewTab('https://support.writer.com/article/247-billing-groups');

export const BillingGroupEntry = observer(({ model }: { model: BillingGroupPageUIModel }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(searchParams.get('search') || '');
  const handleOpenImportTermsPopup = useCallback(() => {
    model.modalsManager.showModal(BillingGroupAction.enum.importer);
  }, [model.modalsManager]);

  const updateUrlParams = useCallback(
    (params: TPaginatedBillingGroupExtraArgs) => {
      // Get the current URL search parameters
      const currentParams = qs.parse(searchParams.toString(), { ignoreQueryPrefix: true });

      // Merge the new parameters with the existing ones
      const mergedParams = { ...currentParams, ...params };

      setSearchParams(qs.stringify(mergedParams, { arrayFormat: 'repeat' }));

      // call api with debounce
      model.updateExtraArgsDebounced(mergedParams);
    },
    [model, searchParams, setSearchParams],
  );

  const updateSearchValue = useCallback(
    (value: string) => {
      // update search value in input
      setSearchValue(value);
      // update url params
      // need to remove search param if value is empty
      updateUrlParams({
        search: value,
      });
    },
    [updateUrlParams],
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      updateSearchValue(value);
    },
    [updateSearchValue],
  );

  const clearSearch = useCallback(() => {
    updateSearchValue('');
  }, [updateSearchValue]);

  return (
    <div className={styles.container}>
      <div className={styles.containerWrapper}>
        <div className={styles.containerHeader}>
          <Heading variant={HeadingVariant.H2} upperCase>
            Billing Groups
          </Heading>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            Assign users to billing groups to attribute them to an internal department or budgeting group.{' '}
            <LinkText className={styles.containerTextLink} onClick={handleSupportPageClick}>
              Learn more
            </LinkText>
          </Text>
        </div>

        <div className={styles.controlsContainer}>
          <Dropdown
            trigger={
              <DownloadButton className={cx(styles.controlButton, styles.downloadBtn)} isLoading={model.isLoading} />
            }
            testId="billing-groups-download-dropdown"
            options={downloadDropdownOptions}
            onPrimaryOptionClickAction={model.handleDownloadBillingGroup}
            placement={DropdownPlacement.BOTTOM_RIGHT}
            itemsType={ItemsTypes.ACTION}
          />
          <SearchBar
            placeholder="Search"
            className={styles.searchContainer}
            onChange={handleSearch}
            value={searchValue}
            handleClearInput={clearSearch}
          />
          <ImportButton
            className={styles.importButton}
            onClick={handleOpenImportTermsPopup}
            testId="billing-groups-import-billing-groups"
            buttonTooltipText={
              <Text variant={TextSize.L} color={TextColor.WHITE}>
                {headerTooltipText.assignUsersBtn}
              </Text>
            }
          />
          <Button
            className={styles.newGroupButton}
            type={ButtonTypes.PRIMARY}
            size={SizeTypes.MEDIUM}
            icon={<Icon name={IconVariant.ADD} width={24} height={24} className={cx(styles.uploadButtonIcon, {})} />}
            onClick={() => model.modalsManager.showModal(BillingGroupsPanelTypes.enum.create)}
            content="New group"
          />
        </div>
      </div>
      <BillingGroupTable model={model} clearFilter={clearSearch} isSearchParamsExist={searchParams.size !== 0} />
    </div>
  );
});
