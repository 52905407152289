import type React from 'react';
import { useState } from 'react';

import type { BillingStatus, IPaymentMethod } from '@writercolab/common-utils';
import { BillingProduct, CancelledReason, CheckoutPopupType, convertCentsToDollar } from '@writercolab/common-utils';
import { Logo, LogoVariant, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import { useBillingContext } from '../../../../context/billingContext';
import { useAppState } from '../../../../state';
import BillingInfoPopup, { BillingInfoPopupType } from '../BillingInfoPopup';
import { CheckoutElementsWrapper } from '../CheckoutElements/CheckoutElementsWrapper';
import { CancelledDuePlanHolder } from '../YourBillingPlanWidget/CancelledDuePlanHolder';

import styles from './styles.module.css';

interface CheckoutPopupProps {
  open: boolean;
  onCancel?: () => void;
  onEditPaymentMethod?: () => void;
  type: CheckoutPopupType;
  paymentMethod: IPaymentMethod | null;
  isSubmitting?: boolean;
}

const Element = {
  [CheckoutPopupType.CHECKOUT]: {
    label: 'Checkout',
  },
  [CheckoutPopupType.MANAGE_PLAN]: {
    label: 'Manage your current plan',
  },
};

const footerFinePrint = (
  checkoutPopupType: CheckoutPopupType,
  billingPeriod: string = '',
  amount: number,
  seats: number,
) => {
  const _planPrice = convertCentsToDollar(amount * seats);

  return checkoutPopupType === CheckoutPopupType.CHECKOUT ? (
    <Text className={styles.checkoutContainerFooterText} variant={TextSize.XXS}>
      Your new plan will begin immediately. Each {billingPeriod}, you'll be charged {_planPrice} until you cancel your
      plan.{' '}
      <a
        href="https://support.writer.com/hc/en-us/articles/360051738391-Limits-when-checking-content-with-Writer"
        target="_blank"
      >
        Usage limits
      </a>{' '}
      apply to each plan. See all{' '}
      <a href="https://support.writer.com/hc/en-us" target="_blank">
        Billing FAQs
      </a>{' '}
      here.
    </Text>
  ) : (
    <Text className={styles.checkoutContainerFooterText} variant={TextSize.XXS}>
      Your plan changes will begin immediately. Each {billingPeriod}, you'll be charged {_planPrice} until you cancel
      your plan.{' '}
      <a
        href="https://support.writer.com/hc/en-us/articles/360051738391-Limits-when-checking-content-with-Writer"
        target="_blank"
      >
        Usage limits
      </a>{' '}
      apply to each plan. See all{' '}
      <a href="https://support.writer.com/hc/en-us" target="_blank">
        Billing FAQs
      </a>{' '}
      here.
    </Text>
  );
};

export const CheckoutPopup: React.FC<CheckoutPopupProps> = ({
  open,
  onCancel,
  onEditPaymentMethod,
  type,
  paymentMethod,
  isSubmitting,
}) => {
  const { appModel } = useAppState();
  const { billingContext } = useBillingContext();
  const { checkout } = billingContext;
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const subscription = appModel.assistantSubscription.$subscription.value;

  const _isTrialExpired = subscription?.cancelledReason === CancelledReason.TRIAL_EXPIRED;

  const access = {
    showCancelledDuePlanHolder: subscription?.scheduledToCancelAt || subscription?.canceledAt || _isTrialExpired,
  };

  return (
    <Modal
      open={open}
      title=""
      modalContainerClassName={styles.modalContainer}
      multiModalContainerClassName={styles.multiModalContainer}
      className={styles.modalBody}
      handleClose={onCancel}
    >
      {open ? (
        <div className={styles.checkoutModalContainerBody}>
          <div className={styles.gradientContainer} />
          <div className={styles.logoContainer}>
            <Logo variant={LogoVariant.BLACK} className={styles.containerLogoItem} />
          </div>
          <div className={styles.checkoutContainer}>
            <div className={styles.checkoutContainerHeader}>
              <Text className={styles.checkoutContainerHeaderText} bold>
                {Element[type].label}
              </Text>
              {access.showCancelledDuePlanHolder && (
                <CancelledDuePlanHolder
                  cancelAt={subscription?.scheduledToCancelAt || subscription?.canceledAt}
                  planStatus={subscription?.status as BillingStatus}
                  className={styles.cancelledPlanHolder}
                  billingProduct={subscription?.price?.productName as BillingProduct}
                  onInfoIconClick={() => setIsInfoPopupOpen(!isInfoPopupOpen)}
                  isTrialExpired={_isTrialExpired}
                />
              )}
              <div className={styles.mainCheckoutContainer}>
                <CheckoutElementsWrapper
                  paymentMethod={paymentMethod}
                  isSubmitting={isSubmitting}
                  onEditPaymentMethod={onEditPaymentMethod}
                  onCancel={onCancel}
                />
              </div>
            </div>
            <div className={styles.checkoutContainerFooter}>
              {footerFinePrint(
                checkout.popupType,
                checkout.planSelected?.interval,
                checkout.planSelected?.amount || 1800,
                checkout.seats,
              )}
            </div>
            <div className={styles.checkoutContainerFooterSecurity}>
              <Text className={styles.checkoutContainerFooterSecurityText} caps>
                SECURED BY
              </Text>
              <div className={styles.checkoutContainerFooterSecurityLogo} />
            </div>
          </div>

          <BillingInfoPopup
            type={
              subscription?.price?.productName === BillingProduct.PRO
                ? BillingInfoPopupType.DOWNGRADE_TO_FREE
                : BillingInfoPopupType.ENDING_A_TEAM_PLAN
            }
            open={isInfoPopupOpen}
            handleClose={() => setIsInfoPopupOpen(!isInfoPopupOpen)}
            hideActionButtons
          />
        </div>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default CheckoutPopup;
