import React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import ToolButtonCounter from './ToolButtonCounter';
import ToolButtonStandard from './ToolButtonStandard';

import styles from '../styles.module.css';

interface IToolButtonExpandedProps extends BaseComponentsProps {
  iconVariant: IconVariant;
  buttonType: ButtonTypes;
  isLoading: boolean;
  buttonRound: boolean;
  buttonContent?: string;
  counter?: number;
  onClick: () => void;
  onCloseClick: () => void;
  tooltipText?: string;
}

const ButtonTooltipText = ({ content }) => (
  <Text variant={TextSize.L} bold color={TextColor.WHITE}>
    {content}
  </Text>
);

export const ToolButtonExpanded: React.FC<IToolButtonExpandedProps> = ({
  className,
  onCloseClick,
  iconVariant,
  onClick,
  isLoading,
  buttonRound,
  buttonContent,
  counter,
  buttonType,
  tooltipText,
}) => (
  <div className={cx(styles.containerToolButtonExpandedContainer, styles.clickable)}>
    <Tooltip title={<ButtonTooltipText content={tooltipText} />} placement="bottom">
      <div>
        <div
          className={cx(styles.containerToolButtonExpanded, {
            [styles.containerToolButtonExpandedLoading]: isLoading,
            [styles.containerToolButtonExpandedContainerPink]: buttonType === ButtonTypes.PURPLE,
          })}
        >
          <Button
            className={styles.containerToolButtonClose}
            htmlType="button"
            round
            size={SizeTypes.XXS}
            type={ButtonTypes.BLACK}
            icon={<Icon name={IconVariant.CLOSE_WHITE} width={13} height={13} />}
            onClick={onCloseClick}
          />
          <Text variant={TextSize.M} color={TextColor.BLACK} className={styles.containerToolButtonExpandedText}>
            {buttonContent}
          </Text>
          <ToolButtonStandard
            buttonType={buttonType}
            buttonContent={buttonContent}
            buttonRound={buttonRound}
            iconVariant={iconVariant}
            className={cx(styles.containerToolButtonLoading, className)}
            onClick={onClick}
            isLoading={isLoading}
          />
        </div>
        {!!counter && <ToolButtonCounter number={counter} className={styles.containerToolButtonExpandedCounter} />}
      </div>
    </Tooltip>
  </div>
);
