import { useEffect, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router';

type FilterValue = string | number | string[];

export const useFilterCount = (filterKeys: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterCount, setFilterCount] = useState(0);

  // Compute filterMap using useMemo
  const filterMap = useMemo(() => {
    const newFilterMap: Record<string, FilterValue> = {};
    filterKeys.forEach(key => {
      const values = searchParams.getAll(key);

      if (values.length > 0) {
        newFilterMap[key] = values.length > 1 ? values : values[0];
      }
    });

    return newFilterMap;
  }, [filterKeys, searchParams]);

  // Update filterCount whenever filterMap changes
  useEffect(() => {
    const totalCount = Object.values(filterMap).reduce((acc: number, value) => {
      if (Array.isArray(value)) {
        return acc + value.length;
      } else if (typeof value === 'string' || typeof value === 'number') {
        return acc + 1;
      }

      return acc;
    }, 0);
    setFilterCount(totalCount);
  }, [filterMap]);

  // Method to clear the filters
  const clearFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    filterKeys.forEach(key => {
      newSearchParams.delete(key);
    });
    setSearchParams(newSearchParams);
  };

  // Method to clear the filter count
  const clearFilterCount = () => {
    setFilterCount(0);
  };

  return { filterCount, clearFilters, clearFilterCount, filterMap };
};
