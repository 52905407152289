import React, { useState } from 'react';

import { Button, ButtonTypes, Text, TextSize } from '@writercolab/ui-atoms';

import writerAcademyBackground from '../../../assets/backgrounds/writerAcademy.png';

import styles from './LearningCenterWriterAcademy.module.css';

interface ILearningCenterWriterAcademyProps {
  isLoading: boolean;
  isEnrolled: boolean;
  onEnroll: () => void;
}

export const LearningCenterWriterAcademy: React.FC<ILearningCenterWriterAcademyProps> = ({
  isLoading,
  isEnrolled,
  onEnroll,
}) => {
  const [isEnrollClicked, setIsEnrollClicked] = useState(false);

  const handleEnrollClick = () => {
    if (!isEnrolled) {
      setIsEnrollClicked(true);
    }

    onEnroll();
  };

  return (
    <div className={styles.container}>
      <div className={styles.enrollContainer}>
        <Text variant={TextSize.XL} className={styles.description}>
          Build your AI expertise with free courses, quick tips, and on-demand trainings. Earn certifications to show
          off your new skills!
        </Text>
        <Button
          disabled={isEnrollClicked}
          isLoading={isLoading}
          onClick={handleEnrollClick}
          type={ButtonTypes.PRIMARY}
          content={isEnrolled ? 'Open' : 'Enroll'}
        />
      </div>
      <img src={writerAcademyBackground} alt="Writer Academy" width={750} />
    </div>
  );
};
