import type { TBaseTableSection } from '@web/types';

import {
  isEarlierCondition,
  isEarlierThenYesterdayCondition,
  isThisWeekCondition,
  isTodayCondition,
  isYesterdayCondition,
} from './dateUtils';

export const generateStandardTableSections = <T>(): Record<string, TBaseTableSection<T>> => ({
  today: { title: 'Today', data: [], condition: isTodayCondition },
  thisWeek: { title: 'This Week', data: [], condition: isThisWeekCondition },
  earlier: { title: 'Earlier', data: [], condition: isEarlierCondition },
});

export const generateDocumentsTableSections = <T>(): Record<string, TBaseTableSection<T>> => ({
  today: { title: 'Today', data: [], condition: isTodayCondition },
  yesterday: { title: 'Yesterday', data: [], condition: isYesterdayCondition },
  earlier: { title: 'Earlier', data: [], condition: isEarlierThenYesterdayCondition },
});
