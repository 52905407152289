/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useRef } from 'react';

import { type TGetOrganizationSessionResponse } from '@writercolab/types';
import { IFilterOptionsFilter, ISort } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import type { TGetTeamSessionsQueryParams, TSessionsSort } from '@web/types';
import { observer } from 'mobx-react-lite';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useAppState } from 'state';

import { BaseTable, type IRowProps } from '../BaseTable/BaseTable';
import { DeleteSessionModal } from './DeleteSessionModal';
import { SessionsTableModal } from './SessionsTableModal';
import { type SessionsTableUiModel } from './SessionsTableModel.ui';
import { TableSkeleton } from './Skeleton';
import { generateTableBody, getTableHeaders } from './utils';

import styles from './SessionsTable.module.css';

export const SessionsTableActionType = new Enum('onRenameSession', 'onOpenApp', 'onDelete');

export interface ISessionsTableProps {
  model: SessionsTableUiModel;
  selectedApplicationIds: string[];
  onRowClick: (output: IRowProps<TGetOrganizationSessionResponse>) => void;
  actions: {
    [SessionsTableActionType.enum.onRenameSession]: (sessionData: TGetOrganizationSessionResponse) => void;
    [SessionsTableActionType.enum.onOpenApp]: (sessionData: TGetOrganizationSessionResponse) => void;
    [SessionsTableActionType.enum.onDelete]: (sessionData: TGetOrganizationSessionResponse) => void;
  };
  sortField: typeof TSessionsSort.type;
  handleSortingChange: (selectedSort: ISort) => void;
  onFilterChange: (filter: TGetTeamSessionsQueryParams) => void;
}

export const SESSIONS_TABLE_ID = 'container-sessions-table';
const GRID_TEMPLATE_COLUMNS = 'minmax(400px,1fr) minmax(150px,250px) minmax(160px,200px) minmax(200px,300px) 46px';

export const SessionsTable = observer<ISessionsTableProps>(
  ({ model, selectedApplicationIds, onRowClick, actions, sortField, handleSortingChange, onFilterChange }) => {
    const {
      appModel: { analyticsService },
    } = useAppState();
    const { isLoading, hasMore, loadMore } = model;
    const prevSelectedApps = useRef<IFilterOptionsFilter[]>([]);

    const tableData = useMemo(
      () => generateTableBody(model, actions, analyticsService),
      [model.sessions, model.selectedSessionData, actions],
    );

    const tableHeaders = useMemo(
      () =>
        getTableHeaders(
          model,
          selectedApplicationIds,
          onFilterChange,
          handleSortingChange,
          sortField,
          analyticsService,
          prevSelectedApps,
        ),
      [model.applications, selectedApplicationIds, onFilterChange, handleSortingChange, sortField],
    );

    const [sentryRef, { rootRef }] = useInfiniteScroll({
      loading: isLoading,
      hasNextPage: hasMore,
      onLoadMore: loadMore,
      rootMargin: '0px 0px 100px 0px',
    });

    return (
      <>
        <div className={styles.container}>
          <div className={styles.scrollableContainer} ref={rootRef} id={SESSIONS_TABLE_ID}>
            <BaseTable
              headers={tableHeaders}
              data={tableData}
              loading={isLoading}
              headerRowClassName={styles.headerRow}
              bodyRowClassName={styles.bodyRow}
              gridTemplateColumns={GRID_TEMPLATE_COLUMNS}
              customLoading={
                <TableSkeleton rowNumber={15} columnNumber={4} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />
              }
              isEnableSticky={false}
              onRowClick={(row: IRowProps<TGetOrganizationSessionResponse>) => onRowClick(row)}
            />
            {model.hasMore && (
              <div className={styles.loaderWrapper} ref={sentryRef}>
                <TableSkeleton rowNumber={3} columnNumber={4} gridTemplateColumns={GRID_TEMPLATE_COLUMNS} />
              </div>
            )}
          </div>
        </div>
        <SessionsTableModal model={model} />
        <DeleteSessionModal
          onSubmit={model.deleteSession}
          isOpen={model.isOpenDeleteConfirmationModal}
          onClose={() => model.setIsOpenDeleteConfirmationModal(false)}
        />
      </>
    );
  },
);
