import { getLogger } from './utils/logger';

export * from './formats';

export * from './modules';

// trigger release73

export * from './components/EditorPure';
export * from './components/EditorToolbar/EditorToolbar';
// TODO: fix me
const EDITOR_CORE_VERSION = 1;

export * from './analytics';

getLogger('version').info(`version: ${EDITOR_CORE_VERSION}`);
