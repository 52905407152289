import React from 'react';

import { IPaymentMethod } from '@writercolab/common-utils';
import { Button, ButtonTypes } from '@writercolab/ui-atoms';

import { Elements } from '@stripe/react-stripe-js';

import { useBillingContext } from '../../../../context/billingContext';
import { stripeElementsFont } from '../../../../utils/stripeUtils';
import CheckoutElements from './index';

import styles from './styles.module.css';

interface CheckoutElementsWrapperProps {
  paymentMethod: IPaymentMethod | null;
  isSubmitting?: boolean;
  onEditPaymentMethod?: () => void;
  onCancel?: () => void;
}

export const CheckoutElementsWrapper: React.FC<CheckoutElementsWrapperProps> = ({
  paymentMethod,
  isSubmitting,
  onEditPaymentMethod,
  onCancel,
}) => {
  const { billingContext, stripePromise } = useBillingContext();

  return (
    <Elements stripe={stripePromise} options={{ fonts: stripeElementsFont }}>
      <CheckoutElements
        stripePromise={stripePromise}
        trigger={onClick => (
          <Button
            type={ButtonTypes.PRIMARY}
            onClick={onClick}
            className={styles.checkoutSubmitButton}
            htmlType="submit"
            isLoading={isSubmitting || !billingContext.checkout.planCalculation}
            content={billingContext.checkout.submitButtonLabel}
            disabled={
              billingContext.checkout.submitButtonDisabled ||
              !billingContext.checkout.planCalculation ||
              billingContext.teamSizeLimit.reached
            }
          />
        )}
        onCancel={onCancel}
        paymentMethod={paymentMethod}
        onEditPaymentMethod={onEditPaymentMethod}
      />
    </Elements>
  );
};
