import { Suspense, useMemo } from 'react';

import { TabsList, Tabs as TabsRoot, TabsTrigger } from '@writercolab/fe.wds';
import { Heading, HeadingVariant, Text, TextColor, TextSize, useQueueWorkerNotifications } from '@writercolab/ui-atoms';

import { LoadingPage } from '@web/component-library';
import { observer } from 'mobx-react-lite';
import { NavLink, Navigate, Route, Routes, useLocation } from 'react-router';

import { REACT_RELATIVE_ROUTE, ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import { HeaderActions } from './HeaderActions';
import { MyWorkPageUiModel } from './MyWorkPageModel.ui';
import { DocumentsTab } from './Tabs/Documents/DocumentsTab';
import { OutputsTab } from './Tabs/Outputs/OutputsTab';
import { SessionsTab } from './Tabs/Sessions/SessionsTab';

import styles from './MyWorkPage.module.css';

export const MyWorkPage = observer(() => {
  const location = useLocation();
  const { appModel } = useAppState();
  const { organizationId, teamId } = appModel;
  const { pathname } = location;

  if (!teamId && !organizationId) {
    return <Navigate to={ROUTE.toHome(1)} replace />;
  }

  const withSession = !appModel.assistantSubscription.isFree;

  const model = useMemo(() => {
    return new MyWorkPageUiModel();
  }, []);

  const LIST = useMemo(() => {
    if (!appModel.organizationId || !appModel.teamId) {
      return [];
    }

    const tabs = [
      {
        id: 'docs',
        text: 'Docs',
        to: ROUTE.toDocs(appModel.organizationId, appModel.teamId),
        isActive: pathname.includes(REACT_RELATIVE_ROUTE.docs),
      },
      {
        id: 'outputs',
        text: 'Outputs',
        to: ROUTE.toOutputs(appModel.organizationId, appModel.teamId),
        isActive: pathname.includes(REACT_RELATIVE_ROUTE.outputs),
      },
    ];

    if (withSession) {
      tabs.unshift({
        id: 'sessions',
        text: 'Sessions',
        to: ROUTE.toSessions(appModel.organizationId, appModel.teamId),
        isActive: pathname.includes(REACT_RELATIVE_ROUTE.sessions),
      });
    }

    return tabs;
  }, [appModel.organizationId, appModel.teamId, pathname, withSession]);

  const getActiveTab = useMemo(() => {
    return LIST.find(item => item.isActive)?.id;
  }, [LIST]);

  useQueueWorkerNotifications(model?.notificationQueue);

  return (
    <div className={styles.container}>
      <div className={styles.subHeader}>
        <div className={styles.titleWrapper}>
          {appModel.team && (
            <Text variant={TextSize.XXS} upperCase medium color={TextColor.GREY2} className={styles.teamName}>
              {appModel.team?.name}
            </Text>
          )}
          <Heading variant={HeadingVariant.H2} upperCase>
            My Work
          </Heading>
        </div>
        <HeaderActions model={model} organizationId={organizationId} teamId={teamId} />
      </div>

      {LIST.length > 0 && getActiveTab && (
        <TabsRoot variant="pill" defaultValue={getActiveTab} className={styles.tabRoot}>
          <TabsList className={styles.navMenu}>
            <div className={styles.list}>
              {LIST.map(item => (
                <TabsTrigger key={item.id} value={item.id} asChild>
                  <NavLink className="no-underline text-base" to={item.to}>
                    {item.text}
                  </NavLink>
                </TabsTrigger>
              ))}
            </div>
          </TabsList>
        </TabsRoot>
      )}

      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path={REACT_RELATIVE_ROUTE.docs} element={<DocumentsTab model={model} />} />
          {withSession && <Route path={REACT_RELATIVE_ROUTE.sessions} element={<SessionsTab model={model} />} />}
          <Route path={REACT_RELATIVE_ROUTE.outputs} element={<OutputsTab model={model} />} />
          <Route
            path="*"
            element={<Navigate to={`.${withSession ? REACT_RELATIVE_ROUTE.sessions : REACT_RELATIVE_ROUTE.docs}`} />}
          />
        </Routes>
      </Suspense>
    </div>
  );
});
