import React from 'react';

import cx from 'classnames';

import { FontFace, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface ClaimDetectionButtonCounterProps extends BaseComponentsProps {
  count?: number;
  isHighlighted?: boolean;
}

export const ClaimDetectionButtonCounter: React.FC<ClaimDetectionButtonCounterProps> = ({
  className,
  count,
  isHighlighted,
}) => (
  <>
    {count !== 0 && (
      <div
        className={cx(className, styles.containerCounter, {
          [styles.containerCounterOrange]: isHighlighted,
        })}
      >
        <Text variant={TextSize.XXXS} color={TextColor.WHITE} bold fontFace={FontFace.monospace}>
          {count}
        </Text>
      </div>
    )}
  </>
);

export default ClaimDetectionButtonCounter;
