import React, { ReactNode, Reducer, useCallback, useEffect, useMemo, useReducer } from 'react';

import cx from 'classnames';

import {
  BillingInformationFormElement,
  CreatePaymentMethodIntentResult,
  IBillingAddressValid,
  IPaymentMethod,
  IPaymentMethodAddress,
  StripeErrorCode,
  createSetupIntent,
  creditCardCvcErrorCodes,
  creditCardErrorCodes,
  creditCardExpiryErrorCodes,
  setDefaultPaymentMethod,
  setupIntentErrorCodes,
  validateNotEmpty,
  validatePostalCode,
} from '@writercolab/common-utils';
import { Button, ButtonTypes, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import stripeJs, { StripeElementStyle, StripeError, PaymentMethod as StripePaymentMethod } from '@stripe/stripe-js';
import { DEFAULT_PAST_DUE_ERROR } from '@web/types';

import { formatLast4 } from '../../../../../utils/billingUtils';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_STATE_CODE,
  stateAvailableCountries,
  zipAvailableCountries,
} from '../../../../../utils/mockUtils';
import {
  StripeFormElements,
  formatStripePaymentMethodMonth,
  formatStripePaymentMethodYear,
} from '../../../../../utils/stripeUtils';
import BillingAddress from '../BillingAddress';

import styles from './styles.module.css';

export interface PaymentMethodProps {
  organizationId?: number;
  paymentMethod?: IPaymentMethod;
  extended?: boolean;
  inlineTriggers?: boolean;
  onSubmitSuccess?: (paymentMethod: StripePaymentMethod) => void;
  onCancel?: () => void;
  isEditButtonShown?: boolean;
  onEditButtonClick?: () => void;
  isPlanPastDue?: boolean;
  submitTrigger?: (onClick) => React.DetailedReactHTMLElement<any, any>;
  cancelTrigger?: ReactNode;
}

enum ActionType {
  setCardNumberInputFocus = 'setCardNumberInputFocus',
  setCardNumberInputInvalid = 'setCardNumberInputInvalid',
  setCreditCardInputError = 'setCreditCardInputError',
  setPaymentMethod = 'setPaymentMethod',
  setAddressInputError = 'setAddressInputError',
  setLoading = 'setLoading',
  setPaymentMethodException = 'setPaymentMethodException',
  setCreditCardException = 'setCreditCardException',
  setPaymentMethodValid = 'setPaymentMethodValid',
  setPaymentMethodPlaceholders = 'setPaymentMethodPlaceholders',
}

type TAction = {
  type: ActionType;
  payload?: { [key: string]: any };
};

type TState = {
  isCardNumberElementFocused: boolean;
  isCardExpiryElementFocused: boolean;
  isCardCvcElementFocused: boolean;
  isCardNumberElementInvalid: boolean;
  isCardExpiryElementInvalid: boolean;
  isCardCvcElementInvalid: boolean;
  inputError: StripeError | null;
  loading: boolean;
  paymentMethod: IPaymentMethod | null;
  paymentMethodAddress: IPaymentMethodAddress | null;
  paymentMethodException: Error | null;
  creditCardException: Error | null;
  paymentMethodValid: boolean;
  billingAddressStatesVisible: boolean;
  billingAddressZipCodeVisible: boolean;
  billingAddressValidation: IBillingAddressValid;
  creditCardNumberPlaceholder: string;
  creditCardExpirationPlaceholder: string;
  creditCardCvcPlaceholder: string;
};

const initialState: TState = {
  isCardCvcElementFocused: false,
  isCardCvcElementInvalid: false,
  isCardExpiryElementFocused: false,
  isCardExpiryElementInvalid: false,
  isCardNumberElementFocused: false,
  isCardNumberElementInvalid: false,
  inputError: null,
  loading: false,
  paymentMethod: null,
  paymentMethodAddress: null,
  paymentMethodValid: true,
  paymentMethodException: null,
  creditCardException: null,
  billingAddressStatesVisible: true,
  billingAddressZipCodeVisible: true,
  billingAddressValidation: {},
  creditCardNumberPlaceholder: '1234 1234 1234 1234',
  creditCardExpirationPlaceholder: 'MM/YY',
  creditCardCvcPlaceholder: 'CVC',
};

const defaultCheckoutElementsStyle: StripeElementStyle = {
  base: {
    color: '#424770',
    fontSize: '12px',
    fontFamily: 'Poppins',
    fontSmoothing: 'antialiased',
  },
  invalid: {
    color: '#DC2727',
    iconColor: '#FFC7EE',
  },
};

const _normalizeExceptionMessage = (message): string => {
  let _message = message;

  if (_message.includes('Please choose a different payment method and try again.')) {
    _message = 'There’s a problem with your card. Update it or add a different card.';
  }

  return _message;
};

const ExceptionMessage = ({ exception }) => (
  <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
    <div className={styles.exceptionContainer}>
      <Text variant={TextSize.S}>{_normalizeExceptionMessage(exception.message)}</Text>
    </div>
  </div>
);

const CreditCardExceptionMessage = ({ exception }) => (
  <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
    <div className={styles.exceptionCreditCardContainer}>
      <Text variant={TextSize.S} medium>
        {_normalizeExceptionMessage(exception.message)}
      </Text>
    </div>
  </div>
);

const MIN_POSTAL_CODE_LENGTH = 4;

const reducer = (state: TState, action: TAction) => ({ ...state, ...action.payload });

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  paymentMethod,
  extended = true,
  inlineTriggers = false,
  onSubmitSuccess,
  submitTrigger,
  cancelTrigger,
  onCancel,
  isEditButtonShown,
  onEditButtonClick,
  isPlanPastDue,
  organizationId,
}) => {
  const [state, dispatch] = useReducer<Reducer<TState, TAction>>(reducer, initialState);

  const stripe = useStripe();
  const elements = useElements();

  const onSubmitError = (error: StripeError | Error) => {
    dispatch({
      type: ActionType.setPaymentMethodException,
      payload: { paymentMethodException: error },
    });

    dispatch({
      type: ActionType.setCreditCardInputError,
      payload: { inputError: error },
    });

    dispatch({
      type: ActionType.setLoading,
      payload: { loading: false },
    });
  };

  const onCardSubmitError = (error: StripeError | Error) => {
    dispatch({
      type: ActionType.setCreditCardException,
      payload: { creditCardException: error },
    });

    dispatch({
      type: ActionType.setCreditCardInputError,
      payload: { inputError: error },
    });

    dispatch({
      type: ActionType.setLoading,
      payload: { loading: false },
    });
  };

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();

      if (!(stripe && elements && validateSubmit())) {
        return;
      }

      dispatch({
        type: ActionType.setLoading,
        payload: { loading: true },
      });

      dispatch({
        type: ActionType.setPaymentMethodException,
        payload: { paymentMethodException: null },
      });

      const { paymentMethod: stripePaymentMethod, error: inputError } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement) as stripeJs.StripeCardNumberElement,
        billing_details: {
          name: state.paymentMethod?.billingAddress.name,
          address: {
            state: state.paymentMethod?.billingAddress.state,
            country: state.paymentMethod?.billingAddress.country,
            city: state.paymentMethod?.billingAddress.city,
            line1: state.paymentMethod?.billingAddress.line1,
            line2: state.paymentMethod?.billingAddress.line2,
            postal_code: state.paymentMethod?.billingAddress.postalCode,
          },
        },
      });

      if (inputError) {
        onCardSubmitError(inputError);

        return;
      }

      if (stripePaymentMethod) {
        const { error: setupIntentError, result } = await createSetupIntent(
          `${organizationId}`,
          stripePaymentMethod.id,
        );

        if (setupIntentError) {
          onSubmitError(setupIntentError);

          return;
        }

        if (result) {
          if (result.status === CreatePaymentMethodIntentResult.REQUIRES_ACTION) {
            const { error } = await stripe.confirmCardSetup(result.clientSecret, {
              payment_method: stripePaymentMethod.id,
            });

            if (error) {
              onSubmitError(error);

              return;
            }
          }

          const { error: paymentMethodError } = await setDefaultPaymentMethod(
            `${organizationId}`,
            stripePaymentMethod.id,
          );

          if (paymentMethodError) {
            onSubmitError(paymentMethodError);

            return;
          }

          dispatch({
            type: ActionType.setLoading,
            payload: { loading: false },
          });

          onSubmitSuccess && onSubmitSuccess(stripePaymentMethod);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stripe, elements, state.paymentMethod],
  );

  const handleElementBlur = (element: StripeFormElements) => {
    switch (element) {
      case StripeFormElements.CARD_NUMBER:
        dispatch({
          type: ActionType.setCardNumberInputFocus,
          payload: { isCardNumberElementFocused: false },
        });
        break;
      case StripeFormElements.CARD_EXPIRY:
        dispatch({
          type: ActionType.setCardNumberInputFocus,
          payload: { isCardExpiryElementFocused: false },
        });
        break;
      case StripeFormElements.CARD_CVC:
        dispatch({
          type: ActionType.setCardNumberInputFocus,
          payload: { isCardCvcElementFocused: false },
        });
        break;
      default:
    }
  };

  const handleElementFocus = (element: StripeFormElements) => {
    switch (element) {
      case StripeFormElements.CARD_NUMBER:
        dispatch({
          type: ActionType.setCardNumberInputFocus,
          payload: { isCardNumberElementFocused: true, isCardNumberElementInvalid: false },
        });
        break;
      case StripeFormElements.CARD_EXPIRY:
        dispatch({
          type: ActionType.setCardNumberInputFocus,
          payload: { isCardExpiryElementFocused: true, isCardExpiryElementInvalid: false },
        });
        break;
      case StripeFormElements.CARD_CVC:
        dispatch({
          type: ActionType.setCardNumberInputFocus,
          payload: { isCardCvcElementFocused: true, isCardCvcElementInvalid: false },
        });
        break;
      default:
    }
  };

  const handleElementChange = useMemo(
    () => () => {
      dispatch({
        type: ActionType.setCreditCardException,
        payload: { creditCardException: null },
      });
    },
    [],
  );

  const validateElement = useCallback(
    (element: BillingInformationFormElement, value: string | undefined, verbose = true): boolean => {
      const _value = value || '';
      let valid = true;

      if (
        [
          BillingInformationFormElement.NAME,
          BillingInformationFormElement.ADDRESS_LINE1,
          BillingInformationFormElement.CITY,
        ].includes(element)
      ) {
        valid = validateNotEmpty(_value);
      } else if (BillingInformationFormElement.POSTAL_CODE) {
        if (verbose) {
          valid = validatePostalCode(_value);
        } else if (_value && _value.length > MIN_POSTAL_CODE_LENGTH) {
          valid = validatePostalCode(_value);
        }
      }

      return valid;
    },
    [],
  );

  const validateSubmit = useCallback(() => {
    let paymentMethodValid = false;

    if (state.paymentMethod?.billingAddress) {
      const billingAddressValidation = {
        [BillingInformationFormElement.NAME]: !validateElement(
          BillingInformationFormElement.NAME,
          state.paymentMethod.billingAddress.name,
        ),
        [BillingInformationFormElement.ADDRESS_LINE1]: !validateElement(
          BillingInformationFormElement.ADDRESS_LINE1,
          state.paymentMethod.billingAddress.line1,
        ),
        [BillingInformationFormElement.CITY]: !validateElement(
          BillingInformationFormElement.CITY,
          state.paymentMethod.billingAddress.city,
        ),
        [BillingInformationFormElement.POSTAL_CODE]: !validateElement(
          BillingInformationFormElement.POSTAL_CODE,
          state.paymentMethod.billingAddress.postalCode,
        ),
      };

      paymentMethodValid = Object.keys(billingAddressValidation).every(e => billingAddressValidation[e] === false);

      dispatch({
        type: ActionType.setAddressInputError,
        payload: { billingAddressValidation, paymentMethodValid },
      });
    }

    return paymentMethodValid;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.paymentMethod]);

  const handleSetBillingAddress = useCallback(
    address => {
      if (address) {
        const billingAddressValidation = {};

        if (address[BillingInformationFormElement.POSTAL_CODE]) {
          billingAddressValidation[BillingInformationFormElement.POSTAL_CODE] = !validateElement(
            BillingInformationFormElement.POSTAL_CODE,
            address[BillingInformationFormElement.POSTAL_CODE],
          );
        }

        if (address[BillingInformationFormElement.CITY]) {
          billingAddressValidation[BillingInformationFormElement.CITY] = !validateElement(
            BillingInformationFormElement.CITY,
            address[BillingInformationFormElement.CITY],
          );
        }

        if (address[BillingInformationFormElement.ADDRESS_LINE1]) {
          billingAddressValidation[BillingInformationFormElement.ADDRESS_LINE1] = !validateElement(
            BillingInformationFormElement.ADDRESS_LINE1,
            address[BillingInformationFormElement.ADDRESS_LINE1],
          );
        }

        if (address[BillingInformationFormElement.NAME]) {
          billingAddressValidation[BillingInformationFormElement.NAME] = !validateElement(
            BillingInformationFormElement.NAME,
            address[BillingInformationFormElement.NAME],
          );
        }

        dispatch({
          type: ActionType.setPaymentMethod,
          payload: {
            billingAddressStatesVisible: stateAvailableCountries.includes(
              address[BillingInformationFormElement.COUNTRY] || '',
            ),
            billingAddressZipCodeVisible: zipAvailableCountries.includes(
              address[BillingInformationFormElement.COUNTRY] || '',
            ),
            billingAddressValidation,
            paymentMethod: {
              billingAddress: { ...paymentMethod?.billingAddress, ...address },
            },
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paymentMethod],
  );

  const handleBillingAddressChange = useCallback(
    (value, element: BillingInformationFormElement) => {
      if (state.paymentMethod?.billingAddress) {
        const billingAddress = { ...state.paymentMethod.billingAddress, [element]: value };

        dispatch({
          type: ActionType.setPaymentMethod,
          payload: {
            billingAddressStatesVisible: stateAvailableCountries.includes(billingAddress.country || ''),
            billingAddressZipCodeVisible: zipAvailableCountries.includes(billingAddress.country || ''),
            billingAddressValidation: {
              ...state.billingAddressValidation,
              [element]: !validateElement(element, value, false),
            },
            paymentMethod: {
              ...paymentMethod,
              billingAddress,
            },
          },
        });
      } else {
        dispatch({
          type: ActionType.setPaymentMethod,
          payload: {
            paymentMethod: {
              billingAddress: {
                [BillingInformationFormElement.STATE]: DEFAULT_STATE_CODE,
                [BillingInformationFormElement.COUNTRY]: DEFAULT_COUNTRY_CODE,
              },
            },
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.paymentMethod?.billingAddress],
  );

  const trigger =
    submitTrigger &&
    React.cloneElement(submitTrigger(handleSubmit), {
      isLoading: submitTrigger(handleSubmit).props.isLoading || state.loading,
    });

  useEffect(() => {
    if (paymentMethod) {
      const countryCode = paymentMethod.billingAddress[BillingInformationFormElement.COUNTRY] || DEFAULT_COUNTRY_CODE;
      const stateCode = paymentMethod.billingAddress[BillingInformationFormElement.STATE] || DEFAULT_STATE_CODE;

      // eslint-disable-next-line no-param-reassign
      paymentMethod.billingAddress[BillingInformationFormElement.COUNTRY] = countryCode;
      // eslint-disable-next-line no-param-reassign
      paymentMethod.billingAddress[BillingInformationFormElement.STATE] = stateCode;

      dispatch({
        type: ActionType.setPaymentMethod,
        payload: {
          paymentMethod,
          billingAddressStatesVisible: stateAvailableCountries.includes(countryCode),
          billingAddressZipCodeVisible: zipAvailableCountries.includes(countryCode),
        },
      });

      const creditCardExpirationMonth = formatStripePaymentMethodMonth(paymentMethod.expireMonth);
      const creditCardExpirationYear = formatStripePaymentMethodYear(paymentMethod.expireYear);

      dispatch({
        type: ActionType.setPaymentMethodPlaceholders,
        payload: {
          creditCardNumberPlaceholder: formatLast4(paymentMethod.lastDigits),
          creditCardExpirationPlaceholder: `${creditCardExpirationMonth}/${creditCardExpirationYear}`,
        },
      });
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (state.inputError && state.inputError.code) {
      const stripeErrorCode = state.inputError.code as StripeErrorCode;

      if (creditCardErrorCodes.includes(stripeErrorCode)) {
        dispatch({
          type: ActionType.setCardNumberInputInvalid,
          payload: { isCardNumberElementInvalid: true },
        });
      } else if (creditCardExpiryErrorCodes.includes(stripeErrorCode)) {
        dispatch({
          type: ActionType.setCardNumberInputInvalid,
          payload: { isCardExpiryElementInvalid: true },
        });
      } else if (creditCardCvcErrorCodes.includes(stripeErrorCode)) {
        dispatch({
          type: ActionType.setCardNumberInputInvalid,
          payload: { isCardCvcElementInvalid: true },
        });
      } else if (setupIntentErrorCodes.includes(stripeErrorCode)) {
        dispatch({
          type: ActionType.setCardNumberInputInvalid,
          payload: {
            isCardNumberElementInvalid: true,
            isCardCvcElementInvalid: true,
            isCardExpiryElementInvalid: true,
          },
        });
      }
    }
  }, [state.inputError]);

  useEffect(() => {
    if (isPlanPastDue) {
      dispatch({
        type: ActionType.setCreditCardInputError,
        payload: { inputError: DEFAULT_PAST_DUE_ERROR },
      });
      dispatch({
        type: ActionType.setPaymentMethodException,
        payload: { paymentMethodException: DEFAULT_PAST_DUE_ERROR },
      });
    }
  }, [isPlanPastDue]);

  return (
    <div className={styles.container}>
      {state.paymentMethodException && <ExceptionMessage exception={state.paymentMethodException} />}
      <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
        <div className={styles.containerFormRowTitle}>
          <Text variant={TextSize.S} medium>
            Card information
          </Text>
        </div>
        <div className={styles.containerForm}>
          <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
            <CardNumberElement
              options={{
                style: defaultCheckoutElementsStyle,
                showIcon: true,
                placeholder: state.creditCardNumberPlaceholder,
              }}
              className={cx(styles.cardInput, styles.cardNumberInput, {
                [styles.cardInputFocused]: state.isCardNumberElementFocused,
                [styles.cardInputInvalid]: state.isCardNumberElementInvalid,
              })}
              onChange={() => handleElementChange()}
              onBlur={() => handleElementBlur(StripeFormElements.CARD_NUMBER)}
              onFocus={() => handleElementFocus(StripeFormElements.CARD_NUMBER)}
            />
          </div>
          <div className={cx(styles.containerFormRowInline)}>
            <div className={cx(styles.containerFormRowShort)}>
              <CardExpiryElement
                options={{ style: defaultCheckoutElementsStyle, placeholder: state.creditCardExpirationPlaceholder }}
                className={cx(styles.cardInput, styles.cardExpiryInput, {
                  [styles.cardInputFocused]: state.isCardExpiryElementFocused,
                  [styles.cardInputInvalid]: state.isCardExpiryElementInvalid,
                })}
                onChange={() => handleElementChange()}
                onBlur={() => handleElementBlur(StripeFormElements.CARD_EXPIRY)}
                onFocus={() => handleElementFocus(StripeFormElements.CARD_EXPIRY)}
              />
            </div>
            <div className={cx(styles.containerFormRowShort)}>
              <CardCvcElement
                options={{ style: defaultCheckoutElementsStyle, placeholder: state.creditCardCvcPlaceholder }}
                className={cx(styles.cardInput, styles.cardCvcElementInput, {
                  [styles.cardInputFocused]: state.isCardCvcElementFocused,
                  [styles.cardInputInvalid]: state.isCardCvcElementInvalid,
                })}
                onChange={() => handleElementChange()}
                onBlur={() => handleElementBlur(StripeFormElements.CARD_CVC)}
                onFocus={() => handleElementFocus(StripeFormElements.CARD_CVC)}
              />
            </div>
          </div>
          {state.creditCardException && (
            <div className={cx(styles.containerFormRowInline)}>
              <CreditCardExceptionMessage exception={state.creditCardException} />
            </div>
          )}
        </div>
      </div>
      {extended && (
        <>
          <div className={styles.containerFormRowSeparator} />
          <div className={cx(styles.containerFormRow, styles.containerFormRowLong)}>
            <div className={styles.containerFormRowTitle}>
              <Text variant={TextSize.S} medium>
                Billing address
              </Text>
            </div>
            <div className={styles.containerForm}>
              <BillingAddress
                billingAddress={state.paymentMethod?.billingAddress}
                onBillingInfoChange={handleBillingAddressChange}
                onSetBillingAddress={handleSetBillingAddress}
                billingAddressValidation={state.billingAddressValidation}
                showStates={state.billingAddressStatesVisible}
                showZip={state.billingAddressZipCodeVisible}
              />
            </div>
          </div>
        </>
      )}
      <div className={styles.containerFormRowSeparator} />

      {isEditButtonShown ? (
        <Button type={ButtonTypes.TRANSPARENT_LINK} className={styles.editButton} onClick={onEditButtonClick}>
          edit
        </Button>
      ) : (
        <div className={cx(styles.containerFormActions, { [styles.containerFormActionsInline]: inlineTriggers })}>
          {cancelTrigger && (
            <div className={styles.containerFormActionTrigger} onClick={onCancel}>
              {cancelTrigger}
            </div>
          )}
          {trigger && <div className={styles.containerFormActionTrigger}>{trigger}</div>}
          {!trigger && <Button size={SizeTypes.SMALL} content="Cancel" type={ButtonTypes.DEFAULT} onClick={onCancel} />}
          {!cancelTrigger && (
            <Button
              size={SizeTypes.SMALL}
              content="Update"
              type={ButtonTypes.BLACK}
              onClick={handleSubmit}
              isLoading={state.loading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentMethod;
