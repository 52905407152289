import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface IWordmarkProps extends BaseComponentsProps {
  /** Set wordmark icon style */
  variant?: WordmarkVariant;
  /** Set the handler to handle click event */
  onClick?: (e: React.MouseEvent) => void;
}

export enum WordmarkVariant {
  GREEN = 'green',
  BLUE = 'blue',
  WHITE = 'white',
  GREY = 'grey',
  TRANSPARENT = 'transparent',
}

const WordmarkIcon = ({ variant }) => {
  let wordmark;

  switch (variant) {
    case WordmarkVariant.BLUE:
      wordmark = <Icon name={IconVariant.WORDMARK_BLUE} />;
      break;
    case WordmarkVariant.GREY:
      wordmark = <Icon name={IconVariant.WORDMARK_GREY} />;
      break;
    case WordmarkVariant.GREEN:
      wordmark = <Icon name={IconVariant.WORDMARK_GREEN} />;
      break;
    case WordmarkVariant.TRANSPARENT:
      wordmark = <Icon name={IconVariant.WORDMARK_TRANSPARENT} />;
      break;
    default:
      wordmark = <Icon name={IconVariant.WORDMARK_WHITE} />;
  }

  return <span>{wordmark}</span>;
};

export const Wordmark: React.FC<IWordmarkProps> = ({
  variant = WordmarkVariant.GREEN,
  className,
  style,
  onClick,
  ...props
}) => {
  const elClass: string = cx(styles.container, className);

  return (
    <div className={elClass} style={style} {...props} onClick={onClick}>
      <WordmarkIcon variant={variant} />
    </div>
  );
};

export default Wordmark;
