import React from 'react';

import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface ExampleBlockHeaderProps {
  children: React.ReactNode;
}

export const ExampleBlockHeader: React.FC<ExampleBlockHeaderProps> = ({ children }) => (
  <div className={styles.exampleHeadingBlock}>
    <Text variant={TextSize.XS} extraSmallCaps color={TextColor.GREY2}>
      {children}
    </Text>
  </div>
);

export default ExampleBlockHeader;
