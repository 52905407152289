import { action, computed, makeObservable, observable } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import type { TEventQueueItem, TNotificationQueueItem } from '@writercolab/types';
import { Enum } from '@writercolab/utils';

import debounce from 'lodash/debounce';

export const MyWorkPageEvents = new Enum('clearFilterIndicator', 'searchValue', 'clearSearchInput', 'buttonClick');

export class MyWorkPageUiModel {
  readonly notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
  readonly eventsQueue = new ReactiveQueue<
    TEventQueueItem<
      typeof MyWorkPageEvents.type,
      {
        searchValue?: string;
      }
    >
  >();

  private $filterIndicatorKeys = observable.array<string>([]);

  constructor() {
    makeObservable(this, {
      filterIndicatorKeys: computed,
      setFilterIndicatorKeys: action,
      clearFilterIndicationKeys: action,
      onSetInputValue: action,
      onClearSearchInput: action,
    });
  }

  get filterIndicatorKeys() {
    return this.$filterIndicatorKeys;
  }

  setFilterIndicatorKeys(keys: string[]) {
    this.$filterIndicatorKeys.push(...keys);
  }

  clearFilterIndicationKeys() {
    this.$filterIndicatorKeys.clear();

    this.eventsQueue.enqueue({
      type: MyWorkPageEvents.enum.clearFilterIndicator,
    });
  }

  onSetInputValue = debounce((value: string) => {
    this.eventsQueue.enqueue({
      type: MyWorkPageEvents.enum.searchValue,
      params: {
        searchValue: value,
      },
    });
  }, 500);

  onClearSearchInput = () => {
    this.eventsQueue.enqueue({
      type: MyWorkPageEvents.enum.clearSearchInput,
    });
  };
}
