import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { TLearningCenterCategoryId } from '@web/types';
import { ILearningCenterNavigationCategory } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';

import styles from './LearningCenterNavigation.module.css';

interface ILearningCenterNavigationProps {
  categories: ILearningCenterNavigationCategory[];
  selectedCategoryId: typeof TLearningCenterCategoryId.type;
  onCategoryClick: (category: ILearningCenterNavigationCategory) => void;
}

export const LearningCenterNavigation: React.FC<ILearningCenterNavigationProps> = ({
  categories,
  selectedCategoryId,
  onCategoryClick,
}) => {
  const topCategories = categories.filter(category => !category.inLowerSection);
  const lowerCategories = categories.filter(category => category.inLowerSection);

  return (
    <div className={styles.container}>
      <Text className={styles.heading} variant={TextSize.XS} upperCase>
        Learning center
      </Text>

      <ul className={styles.list}>
        {topCategories.map(item => {
          const isActive = item.id === selectedCategoryId;

          return (
            <li
              key={item.id}
              className={cx(styles.link, {
                [styles.isActive]: isActive,
              })}
              onClick={() => {
                onCategoryClick(item);
              }}
            >
              <Icon className={styles.icon} width={18} height={18} name={item.icon} />
              <Text variant={TextSize.XXL} color={isActive ? TextColor.BLACK : TextColor.GREY}>
                {item.name}
              </Text>
              {item.link && <Icon className={styles.newTabIcon} name={IconVariant.OPEN_LINK} width={12} height={12} />}
            </li>
          );
        })}
      </ul>

      {!isEmpty(lowerCategories) && (
        <>
          <div className={styles.separator} />
          <ul className={styles.list}>
            {lowerCategories.map(item => {
              const isActive = item.id === selectedCategoryId;

              return (
                <li
                  key={item.id}
                  className={cx(styles.link, {
                    [styles.isActive]: isActive,
                  })}
                  onClick={() => {
                    onCategoryClick(item);
                  }}
                >
                  <Icon className={styles.icon} width={18} height={18} name={item.icon} />
                  <Text variant={TextSize.XXL} color={isActive ? TextColor.BLACK : TextColor.GREY}>
                    {item.name}
                  </Text>
                  {item.link && (
                    <Icon className={styles.newTabIcon} name={IconVariant.OPEN_LINK} width={12} height={12} />
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};
