import { Embed } from 'quill';
import { QA_COMMANDS_FORMAT_NAME, QL_COMMANDS_CLASS_PREFIX } from '@writercolab/quill-delta-utils';

export class CommandsFormat extends Embed {
  static override tagName = 'span';
  static override blotName = QA_COMMANDS_FORMAT_NAME;
  static override className = QL_COMMANDS_CLASS_PREFIX;

  static override create(textContent: string): Node {
    if (!textContent) return super.create(false);

    const node = super.create('span');
    node.textContent = textContent;

    return node;
  }

  static override value(node: Node): string | null {
    return node.textContent;
  }
}
