import qs from 'qs';

import { Request } from '@writercolab/common-utils';

import { IDeleteDraftProps, IDraft, IDraftWithInputs, IGetDraftProps, IGetDraftsProps } from '@web/types';

import { ListResponse } from './_types';

export const getTeamDrafts = async ({ orgId, teamId, params }: IGetDraftsProps): Promise<ListResponse<IDraft>> => {
  const { data } = await Request.getAxiosInstance().put(`/template/organization/${orgId}/team/${teamId}/draft/get`, {
    ...params,
  });

  return data;
};

export const downloadCSVTeamDrafts = async ({ orgId, teamId, params }: IGetDraftsProps): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get(`/template/organization/${orgId}/team/${teamId}/draft/csv`, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    responseType: 'blob',
  });

  return data;
};

export const downloadDOCXTeamDrafts = async ({ orgId, teamId, params }: IGetDraftsProps): Promise<Blob> => {
  const { data } = await Request.getAxiosInstance().get(`/template/organization/${orgId}/team/${teamId}/draft/xlsx`, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    responseType: 'blob',
  });

  return data;
};

export const getDraftById = async ({ orgId, teamId, draftId }: IGetDraftProps): Promise<IDraftWithInputs> => {
  const { data } = await Request.getAxiosInstance().get(
    `/template/organization/${orgId}/team/${teamId}/draft/${draftId}`,
  );

  return data;
};

export const deleteDraftsBulk = async ({ orgId, teamId, draftIds }: IDeleteDraftProps): Promise<IDraftWithInputs> => {
  const { data } = await Request.getAxiosInstance().delete(`/template/organization/${orgId}/team/${teamId}/draft`, {
    params: {
      organizationId: orgId,
      teamId,
      draftIds,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};
