export enum BackendErrorCodeKey {
  MFA_REQUIRED = 'fail.input.mfa.required',
  MFA_TOKEN_MISSED = 'fail.input.mfa.token.missed',
  BILLING_INVALID_COUPON = 'fail.badRequest.invalidCoupon',
  MAGICLINKS_WEBSITE_URL_VALIDATION = 'fail.magiclinks.website.url.validation',
  TERM_DUPLICATE_CASE = 'fail.term.duplicate.fixCase',
  TERM_DUPLICATE = 'fail.term.duplicate',
  GENERAL_ERROR = 'fail.general',
  GENERAL_RESOURCE_ERROR = 'fail.resource.generic',
}
