import { IconVariant } from '@writercolab/ui-atoms';

import {
  IQuestionnaireOption,
  QuestionnaireBlock,
  TQuestionnaireOptionBlocksConfig,
  UserSettingDepartment,
} from '@web/types';

import { stringToId } from '../utils/stringUtils';
import { UserSettingDepartments } from './Profile';

export const QUESTIONNAIRE_OPTION_BLOCKS: TQuestionnaireOptionBlocksConfig = {
  [QuestionnaireBlock.USAGE]: {
    options: [
      {
        id: '1',
        icon: IconVariant.ROUND_GREEN_CHECKS,
        title: 'Improve content quality',
        description: 'e.g., errors, readability, etc.',
        selected: false,
        valid: true,
      },
      {
        id: '2',
        icon: IconVariant.PING_BOOK,
        title: 'Write on-brand, consistent content',
        selected: false,
        valid: true,
      },
      {
        id: '3',
        icon: IconVariant.YELLOW_MAGIC_STICK,
        title: 'Automatically generate content with AI',
        selected: false,
        valid: true,
      },
      {
        id: '4',
        title: 'Other',
        editable: true,
        value: '',
        editablePlaceholder: 'How can we help?',
        selected: false,
        valid: true,
      },
    ] as IQuestionnaireOption[],
  },
  [QuestionnaireBlock.DEPARTMENT]: {
    options: UserSettingDepartments.map(d => ({
      id: d.id,
      title: d.name,
      value: '',
      editable: d.id === stringToId(UserSettingDepartment.OTHER),
    })) as IQuestionnaireOption[],
  },
};
