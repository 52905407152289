import React, { useEffect, useMemo, useState } from 'react';

import {
  ISuggestionMetaPlagiarism,
  ISuggestionSection,
  SuggestionCategoryType,
  extractUrl,
  findSuggestionSection,
} from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingColor,
  HeadingVariant,
  Icon,
  IconVariant,
  Input,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import { AnalyticsActivity } from 'constants/analytics';
import { lensProp, set } from 'ramda';
import analytics from 'services/analytics/analyticsService';

import { useSuggestionsContext } from '../../../../context/suggestionsContext';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import SuggestionCategory from '../SuggestionCategory';
import SuggestionPageHeader from '../SuggestionPageHeader';

import styles from '../styles.module.css';

const plagiarismIgnoreList = lensProp<ISuggestionMetaPlagiarism, 'ignore'>('ignore');

export const PlagiarismPage: React.FC = () => {
  usePageTitle('Plagiarism');
  const {
    suggestionSettings,
    isSaving,
    isReadOnly,
    isTeamNameShown,
    lastUpdated,
    lastUpdatedBy,
    setSectionEnabled,
    updateSectionMeta,
    teamName,
  } = useSuggestionsContext();

  useEffect(() => {
    analytics.track(AnalyticsActivity.suggestionsPlagiarismViewed, {});
  }, []);

  const plagiarism: ISuggestionSection<ISuggestionMetaPlagiarism> = useMemo(
    () => findSuggestionSection(SuggestionCategoryType.PLAGIARISM, suggestionSettings)!,
    [suggestionSettings],
  );

  const [ignoreInputValue, setIgnoreInputValue] = useState('');
  const [validationError, setValidationError] = useState(false);

  const access = {
    inputDisabled: !plagiarism.enabled || isReadOnly,
    addUrlDisabled: !ignoreInputValue || !plagiarism.enabled || isReadOnly,
    deleteUrlDisabled: !plagiarism.enabled || isReadOnly,
  };

  const onChangeIgnoreInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIgnoreInputValue(e.target.value);
    setValidationError(false);
  };

  const onUpdateIgnoreList = (ignoreList: string[]) => {
    const newMeta = set(plagiarismIgnoreList, ignoreList, plagiarism.meta);
    updateSectionMeta(SuggestionCategoryType.PLAGIARISM, newMeta);
  };

  const addToIgnoreList = () => {
    const val = ignoreInputValue;

    if (extractUrl(val)) {
      onUpdateIgnoreList([...plagiarism.meta.ignore, val]);
      setIgnoreInputValue('');
    } else {
      setValidationError(true);
    }
  };

  const removeFromIgnoreList = (itemToRemove: string) => {
    const newIgnoreList = plagiarism.meta.ignore.filter(ignoreListItem => ignoreListItem !== itemToRemove);
    onUpdateIgnoreList(newIgnoreList);
  };

  return (
    <div className={styles.suggestionSection}>
      <SuggestionPageHeader
        readOnly={isReadOnly}
        teamName={isTeamNameShown ? teamName : ''}
        categoryName="Plagiarism"
        lastUpdatedTime={lastUpdated}
        lastUpdatedBy={lastUpdatedBy}
        isSaving={isSaving}
      />
      <div className={styles.description}>
        <div className={styles.descriptionItemShortest}>
          <Text color={TextColor.GREY} variant={TextSize.XL}>
            When a switch is on, we’ll let you know if your content has issues in that category.
          </Text>
        </div>
      </div>

      <SuggestionCategory
        readOnly={isReadOnly}
        name="Plagiarism"
        type={SuggestionCategoryType.PLAGIARISM}
        description="We’ll crawl the web and let you know if we detect any cases of plagiarism."
        suggestionSettings={suggestionSettings}
        setSectionEnabled={setSectionEnabled}
        hideBottomBorder
      >
        <Heading variant={HeadingVariant.H4} color={HeadingColor.GREY} className={styles.ignoreUrlsHeading}>
          URLs to ignore
        </Heading>
        <Text className={styles.ignoreUrlsDescription} color={TextColor.GREY}>
          These URLs will be ignored during plagiarism checks
        </Text>
        <div className={styles.ignoreListControls}>
          <Input
            disabled={access.inputDisabled}
            className={styles.ignoreListInput}
            classNameFocused={styles.ignoreListInputFocused}
            onChange={onChangeIgnoreInputValue}
            value={ignoreInputValue}
            error={!!validationError}
          />
          <Button
            disabled={access.addUrlDisabled}
            content="Add to ignore list"
            className={styles.ignoreListAddBtn}
            type={ButtonTypes.PRIMARY}
            onClick={addToIgnoreList}
          />
        </div>
        <div className={styles.ignoreListItems}>
          {plagiarism.meta.ignore.map(ignoreItem => (
            <div className={styles.ignoreListItem} key={ignoreItem}>
              <Text color={TextColor.GREY} variant={TextSize.L}>
                {ignoreItem}
              </Text>
              <Button
                disabled={access.deleteUrlDisabled}
                onClick={() => removeFromIgnoreList(ignoreItem)}
                type={ButtonTypes.TRANSPARENT_HOVER}
                size={SizeTypes.SMALL}
                squared
                content="Button"
                icon={<Icon name={IconVariant.REMOVE} />}
              />
            </div>
          ))}
        </div>
      </SuggestionCategory>
    </div>
  );
};

export default PlagiarismPage;
