import scrollparent from 'scrollparent';

export const scrollToRef = (ref: HTMLElement, withoutPadding?: boolean) => {
  if (ref) {
    const parent = scrollparent(ref);

    if (parent) {
      setTimeout(() => {
        const parentHeight = parent.clientHeight;
        const parentScrolled = parent.scrollTop;
        const elementOffset = ref.offsetTop;
        const elementTop = elementOffset - parentScrolled;
        const elementHeight = ref.clientHeight;

        const isFullyVisible = elementTop >= 0 && elementTop + elementHeight <= parentHeight;

        if (!isFullyVisible) {
          const paddingTop = withoutPadding ? 0 : (parentHeight - elementHeight) / 2;

          parent.scrollTo({
            top: ref.offsetTop - paddingTop,
            behavior: 'smooth',
          });
        }
      }, 300);
    }
  }
};
