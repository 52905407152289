import { action, computed, makeObservable, observable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import { ModalsManager } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import type { TOrgTeamUserActivityParams } from '@writercolab/types';

import type { TKnowledgeGraphUrlError, TKnowledgeGraphUrlModal, TKnowledgeGraphWebConnectorResponse } from '@web/types';
import { TKnowledgeGraphUrl, TKnowledgeGraphUrlStatus } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';

import { formatUrlsWithProtocols } from './utils';
import { KnowledgeGraphWebConnectorApiModel } from './KnowledgeGraphWebConnectorModel.api';

export type IKnowledgeGraphWebConnectorModalState = {
  id: string;
  type: typeof TKnowledgeGraphUrl.type;
  url: string;
  excludeUrl?: string;
  error?: typeof TKnowledgeGraphUrlError.type;
} | null;

interface IKnowledgeGraphWebConnectorUIModelProps {
  request: RequestServiceInitialize['api'];
  analyticsService: IWebAppAnalyticsTrack<any>;
  organizationId: number;
  graphId: () => string;
}

export class KnowledgeGraphWebConnectorUIModel {
  readonly $isLoading = observable.box(false);
  readonly analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
  readonly modalsManager = new ModalsManager<
    typeof TKnowledgeGraphUrlModal.type,
    IKnowledgeGraphWebConnectorModalState
  >();
  readonly knowledgeGraphWebConnectorApiModel: KnowledgeGraphWebConnectorApiModel;

  constructor(private opts: IKnowledgeGraphWebConnectorUIModelProps) {
    this.analyticsService = opts.analyticsService;

    this.knowledgeGraphWebConnectorApiModel = new KnowledgeGraphWebConnectorApiModel({
      request: opts.request,
    });

    makeObservable(this, {
      urls: computed,
      isUrlsLoading: computed,
      validUrlsCount: computed,
      excludeUrlsCount: computed,
      isAnyModalVisible: computed,
      onAddUrl: action,
      onDeleteUrl: action,
      onAddExcludeUrl: action,
      onEditExcludeUrl: action,
      onDeleteExcludeUrl: action,
      setIsLoading: action,
    });
  }

  $urls: PromisedModel<TKnowledgeGraphWebConnectorResponse | undefined> = new PromisedModel({
    name: '$urls',
    load: async () => {
      if (!this.opts.organizationId || !this.opts.graphId()) {
        return undefined;
      }

      const data = await this.knowledgeGraphWebConnectorApiModel.getUrls(this.opts.organizationId, this.opts.graphId());

      return data;
    },
  });

  onAddUrl = async (urls: string, type: typeof TKnowledgeGraphUrl.type, urlsCount: number) => {
    this.setIsLoading(true);
    await this.knowledgeGraphWebConnectorApiModel.addUrl(
      this.opts.organizationId,
      this.opts.graphId(),
      type,
      formatUrlsWithProtocols(urls),
    );
    this.analyticsService.track(AnalyticsActivity.kgWebAddedUrl, {
      urls,
      type,
      url_count: urlsCount,
    });
    this.modalsManager.hideModal();
    this.setIsLoading(false);
    this.reloadUrls();
  };

  onDeleteUrl = async (id: string) => {
    this.setIsLoading(true);
    await this.knowledgeGraphWebConnectorApiModel.deleteUrl(this.opts.organizationId, this.opts.graphId(), id);
    this.modalsManager.hideModal();
    this.setIsLoading(false);
    this.reloadUrls();
  };

  onUpdateUrl = async ({ id, type, url }: { id: string; type: typeof TKnowledgeGraphUrl.type; url: string }) => {
    const previousUrl = this.urls.find(url => url.id === id);

    if (previousUrl) {
      this.setIsLoading(true);
      const excludeUrls =
        'excludeUrls' in previousUrl
          ? previousUrl.excludeUrls.map(excludeUrl => excludeUrl.replace(previousUrl.url, url))
          : [];

      await this.knowledgeGraphWebConnectorApiModel.updateUrl({
        organizationId: this.opts.organizationId,
        knowledgeGraphId: this.opts.graphId(),
        urlId: id,
        type,
        url,
        excludeUrls: TKnowledgeGraphUrl.match(
          type,
          {
            SinglePage: () => [],
            SubPages: () => excludeUrls,
            Exclude: () => excludeUrls,
          },
          [],
        ),
      });
      this.analyticsService.track(AnalyticsActivity.kgWebEditUrlSubmitted, {
        url_old: previousUrl.url,
        url_new: url,
        type,
      });
      this.modalsManager.hideModal();
      this.setIsLoading(false);
      this.reloadUrls();
    }
  };

  onAddExcludeUrl = async (id: string, excludeUrl: string, urlsCount: number) => {
    const url = this.urls.find(url => url.id === id);

    if (url) {
      this.setIsLoading(true);
      await this.knowledgeGraphWebConnectorApiModel.updateUrl({
        organizationId: this.opts.organizationId,
        knowledgeGraphId: this.opts.graphId(),
        urlId: id,
        type: TKnowledgeGraphUrl.get(url.type, TKnowledgeGraphUrl.enum.SubPages),
        url: url.url,
        excludeUrls: 'excludeUrls' in url ? [...url.excludeUrls, excludeUrl] : [excludeUrl],
      });
      this.analyticsService.track(AnalyticsActivity.kgWebExcludeSpecificSubpagesSubmitted, {
        url: url.url,
        exclude_url: excludeUrl,
        url_count: urlsCount,
      });
      this.modalsManager.hideModal();
      this.setIsLoading(false);
      this.reloadUrls();
    }
  };

  onEditExcludeUrl = async (id: string, prevExcludeUrl: string, updatedExcludeUrl: string) => {
    const url = this.urls.find(url => url.id === id);

    if (url) {
      const excludeUrls =
        'excludeUrls' in url
          ? url.excludeUrls.map(excludeUrl => (excludeUrl === prevExcludeUrl ? updatedExcludeUrl : excludeUrl))
          : [];

      this.setIsLoading(true);
      await this.knowledgeGraphWebConnectorApiModel.updateUrl({
        organizationId: this.opts.organizationId,
        knowledgeGraphId: this.opts.graphId(),
        urlId: id,
        type: TKnowledgeGraphUrl.enum.SubPages,
        url: url.url,
        excludeUrls,
      });
      this.analyticsService.track(AnalyticsActivity.kgWebEditUrlSubmitted, {
        url_old: prevExcludeUrl,
        url_new: updatedExcludeUrl,
        type: 'Exclude',
      });
      this.modalsManager.hideModal();
      this.setIsLoading(false);
      this.reloadUrls();
    }
  };

  onDeleteExcludeUrl = async (id: string, excludeUrl: string) => {
    const url = this.urls.find(url => url.id === id);

    if (url) {
      this.setIsLoading(true);
      await this.knowledgeGraphWebConnectorApiModel.updateUrl({
        organizationId: this.opts.organizationId,
        knowledgeGraphId: this.opts.graphId(),
        urlId: id,
        type: TKnowledgeGraphUrl.get(url.type, TKnowledgeGraphUrl.enum.SubPages),
        url: url.url,
        excludeUrls: 'excludeUrls' in url ? [...url.excludeUrls.filter(url => url !== excludeUrl)] : [],
      });
      this.modalsManager.hideModal();
      this.setIsLoading(false);
      this.reloadUrls();
    }
  };

  get isLoading() {
    return this.$isLoading.get();
  }

  get urls() {
    if (this.$urls.value) {
      // Urls with errors should be displayed last
      return this.$urls.value.urls.sort(a => (a.status.type === TKnowledgeGraphUrlStatus.enum.Error ? 1 : -1));
    }

    return [];
  }

  get isUrlsLoading() {
    return this.$urls.status === 'pending';
  }

  get validUrlsCount() {
    const filteredUrls = this.urls.filter(url => url.status.type !== TKnowledgeGraphUrlStatus.enum.Error);

    return filteredUrls.length;
  }

  get excludeUrlsCount() {
    return this.urls.reduce((accumulator, url) => {
      if ('excludeUrls' in url && url.status.type !== TKnowledgeGraphUrlStatus.enum.Error) {
        return accumulator + url.excludeUrls.length;
      }

      return accumulator;
    }, 0);
  }

  reloadUrls = () => {
    this.$urls.reload();
  };

  setIsLoading = (isLoading: boolean) => {
    this.$isLoading.set(isLoading);
  };

  get isAnyModalVisible() {
    return this.modalsManager.getArrayModalState().length > 0;
  }
}
