import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export function useInView(target: RefObject<HTMLElement>, options?: IntersectionObserverInit): boolean {
  const [isInView, setIsInView] = useState(false);
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);

  useEffect(() => {
    const callback = (entries: IntersectionObserverEntry[]) => {
      setIsInView(entries[0]?.isIntersecting ?? false);
    };

    observer?.disconnect();

    if (target.current) {
      const _observer = new IntersectionObserver(callback, options);
      _observer.observe(target.current);
      setObserver(_observer);
    }

    return () => {
      observer?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current, options?.root, options?.rootMargin, options?.threshold]);

  return isInView;
}

export default useInView;
