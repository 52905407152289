import React, { useCallback, useMemo } from 'react';
import type { IIssue } from '@writercolab/common-utils';
import { IssueCardType, IssueCategory, IssueType } from '@writercolab/common-utils';
import { InlineDictionaryCard, InlineIssueCard, getDescription } from '@writercolab/ui-sidebar';
import { ClaimCard } from '../ClaimCard';

interface IInlineSuggestionCard {
  issue: IIssue;
  onClaimResolveClick: (issue: IIssue) => void;
  onClaimDeclineClick: (issue: IIssue) => void;
  onDeleteIssueClick: (issue: IIssue, cardType: IssueCardType) => void;
  onMarkIssueAsWrong: (issue: IIssue, cardType: IssueCardType, comment?: string | null) => void;
  onApplySuggestion?: (replacement: string, issue: IIssue, cardType: IssueCardType) => void;
  canAddTerms?: boolean;
  isInverted?: boolean;
}

const InlineSuggestionCard: React.FC<IInlineSuggestionCard> = ({
  issue,
  isInverted,
  onDeleteIssueClick,
  onMarkIssueAsWrong,
  onApplySuggestion,
  onClaimResolveClick,
  onClaimDeclineClick,
  canAddTerms,
}) => {
  const _onClaimResolveClick = useCallback(() => onClaimResolveClick(issue), [issue, onClaimResolveClick]);
  const _onClaimDeclineClick = useCallback(() => onClaimDeclineClick(issue), [issue, onClaimDeclineClick]);
  const _onMarkIssueAsWrong = useCallback(
    (comment?: string | null) => onMarkIssueAsWrong(issue, IssueCardType.INLINE, comment),
    [issue, onMarkIssueAsWrong],
  );
  const _onDeleteIssueClick = useCallback(
    () => onDeleteIssueClick(issue, IssueCardType.INLINE),
    [issue, onDeleteIssueClick],
  );
  const _onApplySuggestion = useCallback(
    (replacement: string) => Promise.resolve(onApplySuggestion?.(replacement, issue, IssueCardType.INLINE)),
    [issue, onApplySuggestion],
  );

  const { category, description, highlight, suggestions } = issue;
  const _description = getDescription(category, description, highlight, suggestions[0]);

  const inlineCard = useMemo(() => {
    const addTermAvailable =
      ![
        IssueCategory.CommonMistakes,
        IssueCategory.BannedWords,
        IssueCategory.UseCarefully,
        IssueCategory.PassiveVoice,
        IssueCategory.SentenceComplexity,
        IssueCategory.HealthyCommunication,
        IssueCategory.Plagiarism,
      ].includes(issue.category) && canAddTerms;

    if (issue.issueType === IssueType.DICTIONARY) {
      return (
        <InlineDictionaryCard
          highlight={issue.highlight}
          subHighlight={issue.meta?.pos}
          examples={issue.meta?.examples}
          description={_description}
          category={issue.category}
        />
      );
    }

    if ([IssueCategory.Claim, IssueCategory.Quote].includes(issue.category)) {
      return (
        <ClaimCard type={issue.category} onResolveClick={_onClaimResolveClick} onDeclineClick={_onClaimDeclineClick} />
      );
    }

    return (
      <InlineIssueCard
        addTermAvailable={addTermAvailable}
        highlight={issue.highlight}
        subHighlight={issue.meta?.pos}
        examples={issue.meta?.examples}
        description={_description}
        category={issue.category}
        issueType={issue.issueType}
        sidebarCategory={issue.sidebarCategory}
        correction={issue.suggestions}
        onDeleteIssueClick={_onDeleteIssueClick}
        onApplySuggestion={_onApplySuggestion}
        onMarkIssueAsWrong={_onMarkIssueAsWrong}
        isInverted={isInverted}
        fleschKincaidScoreChange={
          issue.meta?.fk_after && issue.meta?.fk_before
            ? { from: issue.meta?.fk_before, to: issue.meta?.fk_after }
            : undefined
        }
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issue, _onDeleteIssueClick, _onApplySuggestion, _onMarkIssueAsWrong, canAddTerms]);

  return inlineCard;
};

export default InlineSuggestionCard;
