import type { RefObject } from 'react';
import { useEffect, useState } from 'react';

export function useScrollIntoView(ref: RefObject<HTMLElement>, options?: ScrollIntoViewOptions) {
  const [shouldScrollIntoView, setShouldScrollIntoView] = useState(false);

  useEffect(() => {
    if (ref.current && shouldScrollIntoView) {
      ref.current!.scrollIntoView({ behavior: 'smooth', ...options });
      setShouldScrollIntoView(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollIntoView]);

  return () => {
    setShouldScrollIntoView(true);
  };
}

export default useScrollIntoView;
