import { Embed } from 'quill';

import { DotLoader } from '@writercolab/ui-atoms';
import { QA_COMMANDS_LOADING_FORMAT_NAME, QL_COMMANDS_LOADING_CLASS_PREFIX } from '@writercolab/quill-delta-utils';
import { type Root, createRoot } from 'react-dom/client';

export class CommandsLoadingFormat extends Embed {
  static override tagName = 'span';
  static override blotName = QA_COMMANDS_LOADING_FORMAT_NAME;
  static override className = QL_COMMANDS_LOADING_CLASS_PREFIX;
  root?: Root;

  static override create(): Node {
    return super.create('span');
  }

  override attach() {
    super.attach();
    this.root = createRoot(this.contentNode);
    this.root.render(<DotLoader compact />);
  }

  override detach() {
    super.detach();
    this.root?.unmount();
  }
}
