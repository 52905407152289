import React, { useState } from 'react';
import cx from 'classnames';
import { RateButton, RateButtonType, RateValue, InputGroup, InputTypes } from '@writercolab/ui-molecules';
import { Button, ButtonTypes, Text, TextSize } from '@writercolab/ui-atoms';
import type { BaseComponentsProps } from '@writercolab/common-utils';
import styles from './styles.module.css';

export interface IDraftFeedbackValues {
  rate: RateValue;
  feedback: string;
}

export interface IDraftFeedbackProps extends BaseComponentsProps {
  onClose: () => void;
  onSubmit: (values: IDraftFeedbackValues) => void;
}

export const DraftFeedback: React.FC<IDraftFeedbackProps> = ({ className, onClose, onSubmit }) => {
  const [rateValue, setRateValue] = useState<RateValue>(RateValue.DEFAULT);
  const [feedbackValue, setFeedbackValue] = useState('');

   
  const handleFeedbackChange = (event: any) => {
    setFeedbackValue(event.target.value);
  };

  const handleSubmit = () => {
    onSubmit({ rate: rateValue, feedback: feedbackValue });
  };

  return (
    <div className={cx(className, styles.content)}>
      <main className={styles.main}>
        <Text bold variant={TextSize.XXL}>
          Got feedback?
        </Text>

        <Text className={styles.description}>
          We’d love to hear what you think about this draft.
          <br />
          Your feedback will be reviewed by our product team.
        </Text>

        <section className={styles.rateSection}>
          <Text bold variant={TextSize.L}>
            Was this draft useful?
          </Text>

          <div className={styles.rateButtons}>
            <RateButton
              isSelected={rateValue === RateValue.UP}
              onClick={setRateValue}
              value={rateValue}
              variant={RateButtonType.UP}
            />
            <RateButton
              isSelected={rateValue === RateValue.DOWN}
              onClick={setRateValue}
              value={rateValue}
              variant={RateButtonType.DOWN}
            />
          </div>
        </section>

        <section className={styles.feedbackSection}>
          <InputGroup
            id="feedback"
            name="feedback"
            label="Feedback"
            inputType={InputTypes.TEXTAREA}
            multiline
            minRows={6}
            maxRows={25}
            placeholder="Add more context for your feedback here"
            value={feedbackValue}
            handleChangeInput={handleFeedbackChange}
          />
        </section>

        <section className={styles.submitButtons}>
          <Button onClick={onClose} type={ButtonTypes.DEFAULT}>
            Cancel
          </Button>

          <Button onClick={handleSubmit} disabled={rateValue === RateValue.DEFAULT} type={ButtonTypes.PRIMARY}>
            Submit
          </Button>
        </section>
      </main>

      <footer className={styles.footer}>
        <Text medium variant={TextSize.XL}>
          Reporting a bug or need help?
        </Text>

        <Text>
          Email our product team at{' '}
          <a href="mailto:feedback@writer.com?subject=Templates Closed Beta feedback" target="_blank">
            feedback@writer.com
          </a>
        </Text>
      </footer>
    </div>
  );
};

export default DraftFeedback;
