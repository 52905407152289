import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';

import { DocumentHighlightType, DocumentHighlightsExtended } from '@writercolab/common-utils';

import { HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT, HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { HIGHLIGHT_AVAILABLE_REGENERATES, highlightMessage } from '../constants';
import DocumentHighlightEntryPagination from './DocumentHighlightEntryPagination';
import HighlightBody from './HighlightBody';
import { HighlightEmptyBody } from './HighlightEmptyBody';
import HighlightErrorBody from './HighlightErrorBody';
import HighlightHeader from './HighlightHeader';
import HighlightLoading from './HighlightLoading';

import styles from '../styles.module.css';

interface IDocumentHighlightProps {
  onCopyClick: (content: string, type: DocumentHighlightType) => void;
  documentWordsCount: number;
  highlight: DocumentHighlightsExtended;
  onReloadClick?: (type: DocumentHighlightType) => void;
}

interface IEntry {
  id: string;
  text: string;
  active: boolean;
  error: boolean;
  type: DocumentHighlightType;
}

export const DocumentHighlight: React.FC<IDocumentHighlightProps> = ({
  highlight,
  onReloadClick,
  onCopyClick,
  documentWordsCount,
}) => {
  const [entries, setEntries] = useState<IEntry[]>(
    highlight.entry.map((entry, index) => ({
      id: `${entry.id}-${index}`,
      text: entry.text,
      active: index === highlight.entry.length - 1,
      type: entry.type,
    })) as IEntry[],
  );
  const [headerTooltipText, setHeaderTooltipText] = useState<string | undefined>();
  const [tooltipWrapperClassname, setTooltipWrapperClassname] = useState<string | undefined>();
  const [refreshLocked, setRefreshLocked] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(highlight.entry.length);
  const [paginationVisible, setPaginationVisible] = useState(false);

  const onNextClick = useCallback(() => {
    const activeEntryIndex = entries.findIndex(i => i.active);
    const nextPageExist = activeEntryIndex + 1 < entries.length;

    if (nextPageExist && activeEntryIndex !== -1) {
      const nextPageIndex = activeEntryIndex + 1;
      setCurrentEntry(nextPageIndex + 1);
      setEntries(
        entries.map((entry, index) => ({
          ...entry,
          active: index === nextPageIndex,
        })),
      );
    }
  }, [entries]);
  const onPreviousClick = useCallback(() => {
    const activeEntryIndex = entries.findIndex(i => i.active);
    const previousPageExist = activeEntryIndex - 1 >= 0;

    if (previousPageExist && activeEntryIndex !== -1) {
      const prevPageIndex = activeEntryIndex - 1;
      setCurrentEntry(activeEntryIndex);
      setEntries(
        entries.map((entry, index) => ({
          ...entry,
          active: index === prevPageIndex,
        })),
      );
    }
  }, [entries]);

  useEffect(() => {
    setPaginationVisible(entries.length > 1);

    if (documentWordsCount <= HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT) {
      setTooltipWrapperClassname(undefined);
      setHeaderTooltipText(highlightMessage.AVAILABLE_MIN_WORDS_COUNT);
      setRefreshLocked(true);
    } else if (documentWordsCount >= HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT) {
      setTooltipWrapperClassname(undefined);
      setHeaderTooltipText(highlightMessage.AVAILABLE_MAX_WORDS_COUNT);
      setRefreshLocked(true);
    } else if (entries.length < HIGHLIGHT_AVAILABLE_REGENERATES) {
      setTooltipWrapperClassname(highlight.upToDate ? styles.containerHighlightHeaderTooltipShort : undefined);
      setHeaderTooltipText(highlight.upToDate ? highlightMessage.REGENERATE : highlightMessage.OUTDATED);
      setRefreshLocked(false);
    } else if (entries.length >= HIGHLIGHT_AVAILABLE_REGENERATES) {
      setHeaderTooltipText(highlightMessage.LIMIT_REACHED);
      setRefreshLocked(true);
      setTooltipWrapperClassname(undefined);
    } else {
      setRefreshLocked(false);
    }
  }, [highlight, documentWordsCount, entries]);

  if (highlight.loading) {
    return <HighlightLoading type={highlight.type} />;
  }

  if (highlight.error) {
    return <HighlightErrorBody type={highlight.type} onReloadClick={onReloadClick} />;
  }

  const _onCopyClick = (content: string, type: DocumentHighlightType) => onCopyClick(content, type);

  return (
    <div className={styles.containerHighlight}>
      <HighlightHeader
        type={highlight.type}
        onReloadClick={onReloadClick}
        tooltipText={headerTooltipText}
        indicate={!highlight.upToDate}
        locked={refreshLocked}
        tooltipWrapperClassname={tooltipWrapperClassname}
      />
      {paginationVisible && (
        <DocumentHighlightEntryPagination
          current={currentEntry}
          count={entries.length}
          onNextClick={onNextClick}
          onPrevClick={onPreviousClick}
        />
      )}
      {entries.map(entry => (
        <div
          key={entry.id}
          className={cx(styles.containerHighlightContent, {
            [styles.containerHighlightContentInvisible]: !entry.active,
          })}
        >
          {isEmpty(entry.text) ? (
            <HighlightEmptyBody key={entry.id} />
          ) : (
            <HighlightBody
              key={entry.id}
              content={entry.text!}
              onCopyClick={content => _onCopyClick(content, entry.type)}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default DocumentHighlight;
