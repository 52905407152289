export const PAGE_TITLE_PREFIX = 'Writer | ';
export const EMPTY_DOCUMENT_PLACEHOLDER = 'Untitled doc';
export const EMPTY_SESSION_PLACEHOLDER = 'Untitled session';
export const EMPTY_PLACEHOLDER = '-';
export const DEFAULT_DOC_FETCH_LIMIT = 30;
export const DEFAULT_PEOPLE_FETCH_LIMIT = 50;
export const DEFAULT_TERMS_FETCH_LIMIT = 100;
export const SHORTENED_TEAM_NAME_LENGTH = 2;
export const DEV_ENV_PREFIX = 'dev';
export const TEST_ENV_PREFIX = 'test';
export const DEFAULT_DRAFTS_FETCH_LIMIT = 30;
export const CHAMELEON_NLG_TOUR_ID = '63b7447c76db950016296f14';
export const DEFAULT_KNOWLEDGE_FILES_LIMIT = 50;
export const DEFAULT_SEARCH_DEBOUNCE_DELAY_MS = 300;
export const PRIVACY_PAGE_URL = 'https://writer.com/privacy/';
export const MFA_RESTART_TIMER = 60; // seconds

export const COOKIE_CONSENT_VALUE_NAME = 'cookie_consent';

export enum APP_ENV {
  TEST = 'TEST',
  PROD = 'PROD',
  LOCAL = 'LOCAL',
}
