import React from 'react';

import { TAssetToDelete } from '@writercolab/types';
import {
  Checkbox,
  Icon,
  IconVariant,
  Text,
  TextColor,
  TextSize,
  Tooltip,
  TooltipAlignment,
} from '@writercolab/ui-atoms';

import {
  DataRetentionPreferencesUIModel,
  readableAssetOptions,
} from 'components/organisms/DataRetentionPreferences/DataRetentionPreferencesModel.ui';

import { observer } from 'mobx-react-lite';

import styles from './DataRetentionPreferences.module.css';

const assetToDeleteTooltips = TAssetToDelete.hash({
  Doc: 'All documents created by users in the Writer editor',
  Draft: 'All drafts generated by Writer, including any in Writer extensions',
  UploadedFile:
    'All files or media uploaded into Writer apps, including Ask Writer, Recaps, image analyzer, and prebuilt or custom apps. This does not include Knowledge Graph files.',
  Session: 'All chat sessions created by users, including in Ask Writer or any prebuilt or custom chat apps',
});

interface IDataRetentionAssetOptionsProps {
  model: DataRetentionPreferencesUIModel;
}

export const DataRetentionAssetOptions: React.FC<IDataRetentionAssetOptionsProps> = observer(({ model }) => {
  return (
    <>
      {model.hasValidationError && (
        <Text variant={TextSize.XS} caps color={TextColor.ORANGE} className={styles.fieldError}>
          Select at least one asset for automatic deletion
        </Text>
      )}
      <fieldset className={styles.fieldset}>
        <legend>
          <Text variant={TextSize.XS} upperCase>
            Assets to delete
          </Text>
        </legend>
        <div className={styles.checkboxes}>
          {TAssetToDelete.list.map(asset => (
            <label className={styles.checkboxOption} key={asset}>
              <Checkbox checked={model.isSelected(asset)} onClick={() => model.toggleValue(asset)} />
              <Text variant={TextSize.XL} color={TextColor.GREY}>
                {readableAssetOptions[asset]}
              </Text>
              <Tooltip
                title={assetToDeleteTooltips[asset]}
                placement="right"
                align={TooltipAlignment.CENTER}
                tooltipWidth={200}
                tooltipTitleClassName={styles.tooltipWrapper}
              >
                <div>
                  <Icon className={styles.tooltipIcon} name={IconVariant.INFO_OUTLINED} width={18} height={18} />
                </div>
              </Tooltip>
            </label>
          ))}
        </div>
      </fieldset>
    </>
  );
});
