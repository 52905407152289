import React from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import { Button, ButtonTypes, LinkText, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';

import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react-lite';

import { mapTeammatesListToAutocompleteOptions } from '../../../utils/teamUtils';
import UIModelInviteTeammates from './UIModelInviteTeammates';

import styles from './InviteTeammates.module.css';

interface IInviteTeammatesProps {
  model: UIModelInviteTeammates;
  showPreventAddUserWarning: boolean;
  showWarningsForTeamUser: boolean;
  onNavigateToPeoplePageClick: () => void;
}

export const InviteTeammates = observer<IInviteTeammatesProps>(
  ({ onNavigateToPeoplePageClick, showPreventAddUserWarning, showWarningsForTeamUser, model }) => {
    return (
      <>
        {showPreventAddUserWarning && (
          <div className={styles.preventAddWarningContainer}>
            {showWarningsForTeamUser ? (
              <Text color={TextColor.GREY} variant={TextSize.L}>
                Add existing users to this team by searching for a name or email below. To invite a user, ask an org
                admin.
              </Text>
            ) : (
              <Text color={TextColor.GREY} variant={TextSize.L}>
                Add existing users to this team by searching for a name or email below. To invite a user, go to{' '}
                <LinkText onClick={() => onNavigateToPeoplePageClick()}>People</LinkText>.
              </Text>
            )}
          </div>
        )}
        <>
          <InputGroup
            name="people"
            inputType={InputTypes.AUTOCOMPLETE}
            handleChangeInput={teammatesList => model.updateTeammatesList(teammatesList)}
            onInputChange={model.search}
            autocompleteOptions={mapTeammatesListToAutocompleteOptions(model.peopleList)}
            placeholder="Type a name or email address to add"
            id="people"
            label="First name"
            style={{ minHeight: 70 }}
            disabled={model.isTeamSizeLimitReached}
            errorText={
              model.isTeamSizeLimitReached
                ? `You’ve reached your max of ${model.teamSizeLimit} ${wordPluralize(model.teamSizeLimit, 'user')}`
                : undefined
            }
            disableHelperText
            defaultValue={model.teammatesList}
          />
          {!model.isLoading && isEmpty(model.peopleList) && (
            <div className={styles.invalidPersonWarningWrapper}>
              {showWarningsForTeamUser ? (
                <Text color={TextColor.ORANGE} variant={TextSize.L}>
                  This user hasn't been invited yet. To invite a user, ask an org admin.
                </Text>
              ) : (
                <Text color={TextColor.ORANGE} variant={TextSize.L}>
                  This user hasn't been invited yet. Go to{' '}
                  <LinkText onClick={() => onNavigateToPeoplePageClick()}>People</LinkText> to invite them.
                </Text>
              )}
            </div>
          )}
          <div className={styles.submitButtonContainer}>
            <Button
              type={ButtonTypes.PRIMARY}
              onClick={() => model.submit()}
              content="Add"
              disabled={!model.isSubmitAvailable}
              isLoading={model.isLoading}
            />
          </div>
        </>
      </>
    );
  },
);
