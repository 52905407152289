import React from 'react';

import { Button, ButtonTypes, Icon, IconVariant, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import { ImportItemType } from '../../types';

import styles from './styles.module.css';

interface IExitImporterModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onCloseImporter: () => void;
  type: ImportItemType;
}

interface IExitImporterDescriptionProps {
  type: ImportItemType;
}

const ExitImporterHeader = () => (
  <Text variant={TextSize.XXL} bold>
    Are you sure you want to leave?
  </Text>
);

const ExitImporterDescription = ({ type }: IExitImporterDescriptionProps) => {
  const descriptionMap = {
    [ImportItemType.TERMS]: 'terms',
    [ImportItemType.SNIPPETS]: 'snippets',
    [ImportItemType.TEAMMATES]: 'teammates',
    [ImportItemType.BILLING_GROUP]: 'billing groups',
  };

  return (
    <Text variant={TextSize.M} bold>
      Your {descriptionMap[type]} haven’t been imported yet.
    </Text>
  );
};

export const ExitImporterModal: React.FC<IExitImporterModalProps> = ({
  isOpen,
  onCloseModal,
  onCloseImporter,
  type,
  ...props
}) => (
  <Modal open={isOpen} title="" className={styles.closeAlertModal} hideCloseButton {...props}>
    <div className={styles.styledAlertContent}>
      <Icon name={IconVariant.ALERT} className={styles.icon} />
      <ExitImporterHeader />
      <ExitImporterDescription type={type} />
      <div className={styles.controlButtons}>
        <Button
          className={styles.submitButton}
          type={ButtonTypes.DEFAULT}
          content="Stay on this page"
          onClick={onCloseModal}
        />
        <Button
          className={styles.submitButton}
          type={ButtonTypes.PRIMARY}
          danger
          content="Leave"
          onClick={onCloseImporter}
        />
      </div>
    </div>
  </Modal>
);
