import React from 'react';

import { DotLoader, Heading, HeadingVariant } from '@writercolab/ui-atoms';

import { ContentType, NormalizedState, TPrompt, TPromptTag } from '@web/types';
import groupBy from 'lodash/groupBy';

import activeVoiceBanner from '../../../assets/backgrounds/activeVoiceBanner.svg';
import { NoSearchResultsBanner } from '../../generic/NoSearchResultsBanner';
import { PromptItem } from '../../molecules/PromptItem';

import styles from './PromptLibraryList.module.css';

interface IPromptLibraryList {
  activeCategory?: string;
  prompts: TPrompt[];
  loading: boolean;
  tagNameMap: Record<string, string>;
  tagHierarchyMap: Record<string, string[]> | undefined;
  normalizedTagMap: NormalizedState<TPromptTag>;
  searchQuery: string;
  promptGroupByTagMap: Record<string, TPrompt[]>;
  getContentTypeIcon: (contentType: typeof ContentType.type) => React.ReactElement;
  onUsePrompt?: (prompt: string, promptName?: string, promptHeader?: string) => void;
  onCopyToClipboard?: (prompt: string, promptName?: string, promptHeader?: string) => void;
  onTeamPromptCreateClick?: (prompt: TPrompt) => void;
}

const headerComparator = (a: TPrompt, b: TPrompt) => {
  if (a.header && b.header) {
    return a.header.localeCompare(b.header);
  }

  return 0;
};

const getCommonHeaderPromptMap = (prompts: TPrompt[]) => {
  const map = {};
  const result = {};

  prompts.forEach(prompt => {
    const { header } = prompt;

    if (!header) {
      return;
    }

    if (map[header]) {
      result[header] = true;
    } else {
      map[header] = true;
    }
  });

  return result;
};

const isItFeatured = (prompt: TPrompt, currentLimit: number): boolean => prompt.featured && currentLimit >= 0;
const organizePromptsByFeature = (prompts: TPrompt[], limit: number) => {
  let currentLimit = limit;
  const promptsByFeature = groupBy(prompts, prompt => {
    const result = isItFeatured(prompt, currentLimit);

    if (result) {
      --currentLimit;
    }

    return result;
  });
  const featuredPrompts = promptsByFeature.true || [];
  const nonFeaturedPrompts = promptsByFeature.false || [];

  return {
    featured: featuredPrompts.sort(headerComparator),
    other: nonFeaturedPrompts.sort(headerComparator),
  };
};

export const PromptLibraryList: React.FC<IPromptLibraryList> = ({
  loading,
  activeCategory,
  prompts,
  normalizedTagMap,
  tagNameMap,
  tagHierarchyMap,
  promptGroupByTagMap,
  searchQuery,
  getContentTypeIcon,
  onUsePrompt,
  onCopyToClipboard,
  onTeamPromptCreateClick,
}) => {
  const renderAllPrompts = (items: TPrompt[]) => {
    const { featured, other } = organizePromptsByFeature(items, 3);
    const commonHeaderPromptMap = getCommonHeaderPromptMap(other);

    return (
      <div>
        {featured.length > 0 && (
          <div className={styles.categoryHeadingPopular}>
            <Heading className={styles.categoryHeading} variant={HeadingVariant.H3}>
              Popular prompts
            </Heading>
            {featured.map(promptPayload => (
              <PromptItem
                key={promptPayload.id}
                heading={promptPayload.header || undefined}
                prompt={promptPayload.prompt}
                textHighlight={searchQuery}
                icon={getContentTypeIcon(promptPayload.contentType)}
                onUsePrompt={
                  onUsePrompt
                    ? () => onUsePrompt?.(promptPayload.prompt, promptPayload.id, promptPayload.header || '')
                    : undefined
                }
                onCopy={() => onCopyToClipboard?.(promptPayload.prompt, promptPayload.id, promptPayload.header || '')}
                onAdd={() => onTeamPromptCreateClick?.(promptPayload)}
                addTeamPromptsVisible={!!onTeamPromptCreateClick}
              />
            ))}
          </div>
        )}
        {other.length > 0 && (
          <div className={styles.bannerWrapper}>
            <a target="_blank" href="https://writer.com/community/" rel="noopener noreferer">
              <img className={styles.activeVoiceBanner} src={activeVoiceBanner} alt="activeVoiceBanner" />
            </a>
          </div>
        )}
        {other.length > 0 && (
          <div>
            <Heading className={styles.categoryHeading} variant={HeadingVariant.H3}>
              All prompts
            </Heading>
            {renderPromptItems(other, commonHeaderPromptMap)}
          </div>
        )}
      </div>
    );
  };

  const renderTopTagBlock = (tag: string) => {
    const tagChildren = tagHierarchyMap?.[tag];
    const items = tagChildren?.map(tag => renderChildTagBlock(tag, promptGroupByTagMap[tag])).filter(item => item);

    return items || null;
  };

  const renderChildTagBlock = (tag: string, items: TPrompt[] | null) => {
    if (!items || items.length === 0) {
      return null;
    }

    const commonHeaderPromptMap = getCommonHeaderPromptMap(items);

    return (
      <div>
        <Heading variant={HeadingVariant.H3} className={styles.categorySubheading}>
          {tagNameMap[tag]}
        </Heading>
        {renderPromptItems(items.sort(headerComparator), commonHeaderPromptMap)}
      </div>
    );
  };

  const renderPromptItems = (items, commonHeaderPromptMap) => {
    const headingMap = {};

    return items.map(promptPayload => {
      if (promptPayload.header) {
        const shouldRenderHeading = commonHeaderPromptMap[promptPayload.header] && !headingMap[promptPayload.header];
        const isSecondaryPrompt = commonHeaderPromptMap[promptPayload.header];
        headingMap[promptPayload.header] = true;

        return renderPromptWithOptionalHeader(promptPayload, shouldRenderHeading, isSecondaryPrompt);
      }

      return [];
    });
  };

  // if there's mulitple prompts with the same header, we only want to render the header once
  const renderPromptWithOptionalHeader = (
    promptPayload: TPrompt,
    shouldRenderHeading: boolean,
    isSecondaryPrompt: boolean,
  ) => (
    <>
      {shouldRenderHeading && (
        <PromptItem
          key={`${promptPayload.id}-heading`}
          heading={promptPayload.header || ''}
          textHighlight={searchQuery}
          prompt={isSecondaryPrompt ? undefined : promptPayload.prompt}
          icon={getContentTypeIcon(promptPayload.contentType)}
        />
      )}
      <PromptItem
        key={promptPayload.id}
        heading={isSecondaryPrompt ? '' : promptPayload.header || ''}
        textHighlight={searchQuery}
        prompt={promptPayload.prompt}
        icon={isSecondaryPrompt ? undefined : getContentTypeIcon(promptPayload.contentType)}
        isShifted={isSecondaryPrompt}
        onUsePrompt={
          onUsePrompt
            ? () => onUsePrompt?.(promptPayload.prompt, promptPayload.id, promptPayload.header || '')
            : undefined
        }
        onCopy={() => onCopyToClipboard?.(promptPayload.prompt, promptPayload.id, promptPayload.header || '')}
        onAdd={() => onTeamPromptCreateClick?.(promptPayload)}
        addTeamPromptsVisible={!!onTeamPromptCreateClick}
      />
    </>
  );

  const renderPrompts = () => {
    const parentId = normalizedTagMap?.byId[activeCategory || '']?.parentId;

    // All
    if (!activeCategory) {
      return renderAllPrompts(prompts);
    }

    // by parent tag
    if (!parentId || (!parentId && activeCategory === 'contentType')) {
      const elements = renderTopTagBlock(activeCategory);

      if (elements) {
        return (
          <>
            <Heading className={styles.categoryHeading} variant={HeadingVariant.H2} upperCase>
              BY {tagNameMap[activeCategory]}
            </Heading>
            <div>{elements}</div>
          </>
        );
      } else {
        return <NoSearchResultsBanner className={styles.noResultsBanner} />;
      }
    }

    // by child tag
    if (parentId || (parentId && parentId === 'contentType')) {
      let items = promptGroupByTagMap[activeCategory];

      if (parentId === 'contentType') {
        items = prompts?.filter(prompt => prompt.contentType === activeCategory) || [];
      }

      const elements: React.ReactElement | null = renderChildTagBlock(activeCategory, items);

      return elements || <NoSearchResultsBanner className={styles.noResultsBanner} />;
    }

    return null;
  };

  return loading ? (
    <>
      <DotLoader />
    </>
  ) : (
    <div className={styles.container}>{renderPrompts()}</div>
  );
};
