import type { components } from '@writercolab/network';
import { Enum } from '@writercolab/utils';

import type { IMfaChallenge } from './Mfa';

export const FORM_INPUT_PASSWORD_MIN_LENGTH = 8;
export const FORM_INPUT_VALUE_MAX_LENGTH = 120;

export type TSignupFormValues = { email: string; password: string; firstName: string; lastName: string; hash?: string };
export type TSigninFormValues = { email: string; password: string };

export type TSignupCheckStatus = components['schemas']['user_model_RegistrationCheck'];
export const TSignupPlan = new Enum('team_monthly', 'free', 'ai_studio_payg');

export type TAuthExceptionExtraPayload = {
  challenges: IMfaChallenge[];
};

export type TAuthException = {
  message: string;
  code: string;
  extra?: TAuthExceptionExtraPayload;
};

export type AuthResponse = {
  data: never | null;
  exception: TAuthException | null;
};

export const TConsoleUserAuthQueryParams = new Enum(
  'org_name',
  'verificationHash',
  'email',
  'firstSignIn',
  'registered',
);

export const TConsoleUserAuthSource = new Enum('Email', 'Google');
