import React from 'react';

import cx from 'classnames';

import { Heading, HeadingVariant, LinkText, Text, TextColor } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface ISectionHeadingProps {
  heading: string;
  description?: string;
  onLearnMoreClick?: () => void;
  inline?: boolean;
}

export const SectionHeading: React.FC<ISectionHeadingProps> = ({ heading, description, onLearnMoreClick, inline }) => (
  <div className={styles.container}>
    <Heading className={styles.containerHeading} variant={HeadingVariant.H3} bold>
      {heading}
    </Heading>
    <div
      className={cx({
        [styles.containerTextInline]: inline,
      })}
    >
      {description && (
        <Text color={TextColor.GREY2} className={styles.containerText}>
          {description}
        </Text>
      )}
      {onLearnMoreClick && (
        <div className={styles.containerTextSimple}>
          <LinkText className={styles.containerTextLink} onClick={onLearnMoreClick}>
            <Text color={TextColor.GREY2}>Learn more</Text>
          </LinkText>
          <Text color={TextColor.GREY2}>.</Text>
        </div>
      )}
    </div>
  </div>
);

export default SectionHeading;
