import { useEffect, useState } from 'react';

import { LocalStorageKey, LocalStorageService } from '@writercolab/common-utils';

import { QUICKSTART_VIDEOS } from '../constants/LearningCenter';

export default function useLearningCenterVideos() {
  const [viewedVideosList, setViewedVideosList] = useState<number[]>([]);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(null);
  const [videosViewed, setVideosViewed] = useState(0);
  const [videosLeftToView, setVideosLeftToView] = useState(0);

  useEffect(() => {
    const previouslyViewedVideos = LocalStorageService.getItem<number[]>(
      LocalStorageKey.onboardingShowcaseViewedVideos,
    );

    if (previouslyViewedVideos) {
      setViewedVideosList(previouslyViewedVideos);
    }
  }, []);

  useEffect(() => {
    setVideosViewed(viewedVideosList.length);
    setVideosLeftToView(QUICKSTART_VIDEOS.length - viewedVideosList.length);

    const previouslyViewedVideos = localStorage.getItem(LocalStorageKey.onboardingShowcaseViewedVideos);

    if (previouslyViewedVideos) {
      const parsedPreviouslyViewedVideos = JSON.parse(previouslyViewedVideos);
      const viewedVideosWithoutDuplicates = [...new Set([...parsedPreviouslyViewedVideos, ...viewedVideosList])];

      LocalStorageService.setItem(LocalStorageKey.onboardingShowcaseViewedVideos, viewedVideosWithoutDuplicates);
    } else {
      LocalStorageService.setItem(LocalStorageKey.onboardingShowcaseViewedVideos, viewedVideosList);
    }
  }, [viewedVideosList]);

  return {
    learningCenterViewedVideosList: viewedVideosList,
    setLearningCenterViewedVideosList: setViewedVideosList,
    showcaseVideosViewed: videosViewed,
    showcaseVideosLeftToView: videosLeftToView,
    setSelectedVideoIndex,
    selectedVideoIndex,
  };
}
