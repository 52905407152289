import React, { ReactElement, isValidElement } from 'react';

import cx from 'classnames';

import { BaseComponentsProps, noop } from '@writercolab/common-utils';
import { ButtonTypes, IconVariant, Text, TextColor, TextSize, Tooltip } from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

// TECHDEBT: WA-2647  Fix cycle dependencies within DocumentInfoPanel molecule
// eslint-disable-next-line import/no-cycle
import { DocumentInfoPanelTooltipSize } from '../index';
import { ToolButtonExpanded } from './ToolButtonExpanded';
import ToolButtonStandard from './ToolButtonStandard';

import styles from '../styles.module.css';

interface IToolButtonProps extends BaseComponentsProps {
  onClick: () => void;
  id?: string;
  iconVariant: IconVariant;
  tooltipText?: string | ReactElement;
  tooltipSize?: DocumentInfoPanelTooltipSize;
  counter?: number;
  disabled?: boolean;
  isLoading?: boolean;
  buttonRound?: boolean;
  buttonContent?: string;
  buttonExpanded?: boolean;
  buttonType?: ButtonTypes;
  containerClassName?: string;
}

const ButtonTooltipText = ({ content }) => (
  <Text variant={TextSize.L} bold color={TextColor.WHITE}>
    {content}
  </Text>
);

export const ToolButton: React.FC<IToolButtonProps> = ({
  className,
  onClick,
  id,
  iconVariant,
  counter,
  tooltipText,
  tooltipSize = DocumentInfoPanelTooltipSize.SMALL,
  isLoading = false,
  buttonRound = false,
  disabled = false,
  buttonExpanded = false,
  buttonContent = '',
  buttonType = ButtonTypes.GRAY,
  containerClassName,
}) => (
  <div className={cx(styles.containerToolButtonContainer, containerClassName)} id={id}>
    <Tooltip
      disabled={isEmpty(tooltipText)}
      tooltipWrapperClassname={cx({
        [styles.tooltipSmall]: tooltipSize === DocumentInfoPanelTooltipSize.SMALL,
        [styles.tooltipMedium]: tooltipSize === DocumentInfoPanelTooltipSize.MEDIUM,
        [styles.tooltipLarge]: tooltipSize === DocumentInfoPanelTooltipSize.LARGE,
      })}
      title={isValidElement(tooltipText) ? tooltipText : <ButtonTooltipText content={tooltipText} />}
      placement="bottom"
    >
      <div
        className={cx({
          [styles.containerToolButtonExpandedContainer]: buttonExpanded,
        })}
      >
        {buttonExpanded ? (
          <ToolButtonExpanded
            buttonContent={buttonContent}
            counter={counter}
            buttonRound={buttonRound}
            iconVariant={iconVariant}
            className={className}
            onClick={onClick}
            isLoading={isLoading}
            onCloseClick={noop}
            buttonType={buttonType}
          />
        ) : (
          <ToolButtonStandard
            disabled={disabled}
            buttonContent={buttonContent}
            counter={counter}
            buttonType={buttonType}
            buttonRound={buttonRound}
            iconVariant={iconVariant}
            className={className}
            onClick={onClick}
            isLoading={isLoading}
          />
        )}
      </div>
    </Tooltip>
  </div>
);

export default ToolButton;
