import React from 'react';

import { Button, ButtonTypes, Text, TextSize } from '@writercolab/ui-atoms';

import developerDocsBackground from '../../../assets/backgrounds/developerDocs.png';

import styles from './LearningCenterDocs.module.css';

interface ILearningCenterDocsProps {
  onClick: () => void;
}

export const LearningCenterDocs: React.FC<ILearningCenterDocsProps> = ({ onClick }) => (
  <div className={styles.container}>
    <div className={styles.descriptionContainer}>
      <Text variant={TextSize.XL} className={styles.description}>
        Read our developer documentation to get started building apps with AI Studio and Framework.
      </Text>
      <Button onClick={onClick} type={ButtonTypes.PRIMARY} content="Go to docs" />
    </div>
    <img src={developerDocsBackground} alt="Developer Docs" width={750} />
  </div>
);
