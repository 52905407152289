import { computed, makeObservable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import { RequestServiceInitialize } from '@writercolab/network';

import { TApplicationTag } from '@web/types';

interface PromptTagsApiModelOptions {
  request: RequestServiceInitialize['api'];
}

export class ConsoleApplicationTagsApiModel {
  private readonly $tags = new PromisedModel({
    name: '$tags',
    load: async () => {
      const { data } = await this.opts.request.get('/api/template/application/tag', {});

      return data.value ?? [];
    },
  });

  constructor(private opts: PromptTagsApiModelOptions) {
    makeObservable(this, {
      list: computed.struct,
    });
  }

  get list(): TApplicationTag[] {
    return this.$tags.value ?? [];
  }
}
