import { History } from 'quill';
import type Delta from 'quill-delta';
import { isDeltaHasTransformations, normalizeAndCleanDelta } from '@writercolab/quill-delta-utils';

export class CustomQuillHistory extends History {
  override record(changeDelta: Delta, oldDelta: Delta) {
    if (changeDelta.ops.length === 0) return;

    this.stack.redo = [];
    let undoDelta = normalizeAndCleanDelta(changeDelta.invert(oldDelta));
    let undoRange = this.currentRange;
    const timestamp = Date.now();

    if (!isDeltaHasTransformations(undoDelta)) {
      return;
    }

    if (
      // @ts-expect-error Fix me later
      this.lastRecorded + this.options.delay > timestamp &&
      this.stack.undo.length > 0
    ) {
      const item = this.stack.undo.pop();

      if (item) {
        undoDelta = undoDelta.compose(item.delta);
        undoRange = item.range;
      }
    } else {
      this.lastRecorded = timestamp;
    }

    if (undoDelta.length() === 0) return;

    this.stack.undo.push({ delta: undoDelta, range: undoRange });

    // @ts-expect-error Fix me later
    if (this.stack.undo.length > this.options.maxStack) {
      this.stack.undo.shift();
    }
  }
}
