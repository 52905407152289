import { useState } from 'react';

import { openNewTab } from '@writercolab/dom';

import { HomeExtensionTile } from 'components/molecules/HomeExtensionTile';

import { TWriterExtensionId } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { ROUTE } from 'services/config/routes';

import { HideExtensionModal } from './ExtensionsDeleteModal';
import { type ExtensionsHomeWidgetUiModel, HOME_EXTENSIONS_CONFIG } from './ExtensionsHomeWidgetModel.ui';

import styles from './ExtensionsHomeWidget.module.css';

interface IExtensionsHomeWidgetProps {
  model: ExtensionsHomeWidgetUiModel;
  organizationId: string;
  onHideExtension: (id: typeof TWriterExtensionId.type) => void;
}

export const ExtensionsHomeWidget = observer<IExtensionsHomeWidgetProps>(
  ({ model, onHideExtension, organizationId }) => {
    const [extensionToHide, setExtensionToHide] = useState<typeof TWriterExtensionId.type | null>(null);

    const onHideExtensionConfirm = () => {
      if (!extensionToHide) {
        return;
      }

      setExtensionToHide(null);
      onHideExtension(TWriterExtensionId.get(extensionToHide, 'CHROME'));
      model.analyticsService.track(AnalyticsActivity.hideExtensionClicked, {
        extension: extensionToHide,
        origin: 'extension_widget',
      });
    };

    return (
      <div className={styles.container}>
        {HOME_EXTENSIONS_CONFIG.map(extension => {
          const isEnabled = model.extensionIds.find(id => id === extension.id);

          if (!isEnabled && !model.isAdminEdit) {
            return null;
          }

          const type = model.isAdminEdit ? (isEnabled ? 'hide' : 'show') : 'install';

          return (
            <HomeExtensionTile
              data-testid={`home-extension-tile-${extension.id}`}
              key={extension.id}
              icon={extension.icon}
              title={extension.title}
              type={type}
              onClickInstall={() => {
                model.analyticsService.track(AnalyticsActivity.installExtensionClicked, {
                  extension: extension.id,
                  origin: 'extension_widget',
                });
                openNewTab(extension.link);
              }}
              onClickHide={!model.isAdminEdit ? () => setExtensionToHide(extension.id) : undefined}
              onToggleVisibility={() => model.toggleExtensionState(extension.id)}
            />
          );
        })}

        <HideExtensionModal
          isOpen={!!extensionToHide}
          onClose={() => setExtensionToHide(null)}
          onSubmit={onHideExtensionConfirm}
          extensionPageUrl={ROUTE.toExtensions(organizationId)}
        />
      </div>
    );
  },
);

ExtensionsHomeWidget.displayName = 'ExtensionsHomeWidget';
