// https://app.qordobadev.com/api/terminology/organization/1/term-bank/1733/term?limit=50&offset=0&sortField=creationTime&sortOrder=desc
import qs from 'qs';

import {
  BatchSubmitFailHandlingTypes,
  ICreateTermsWithFails,
  ITeam,
  ITerm,
  ITermCreateAndUpdate,
  ITermsTagsListResponse,
  Request,
  RequestQueryParams,
  TeamId,
  TermsRequestTypes,
} from '@writercolab/common-utils';

import { ListResponse } from './_types';

const bannedSearchKeywords = [
  'dont use',
  'dont use term',
  'dont use terms',
  "don't use",
  "don't use term",
  "don't use terms",
];
const approvedSearchKeywords = ['approved', 'approved term', 'approved terms'];
const pendingSearchKeywords = ['pending', 'pending term', 'pending terms'];

export const getTermsListRequest = async (
  orgId: number | undefined,
  termBankId: number,
  params: RequestQueryParams,
  optionalParams?: Record<string, string>,
): Promise<ListResponse<ITerm>> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term`;
  const _params = params;

  if (_params.search && _params.search !== '') {
    if (bannedSearchKeywords.includes(_params.search)) {
      _params.type = TermsRequestTypes.BANNED;
    } else if (approvedSearchKeywords.includes(_params.search)) {
      _params.type = TermsRequestTypes.APPROVED;
    } else if (pendingSearchKeywords.includes(_params.search)) {
      _params.type = TermsRequestTypes.PENDING;
    }

    if (_params.type) {
      _params.search = '';
    }
  }

  const { data } = await Request.getAxiosInstance().get<ListResponse<ITerm>>(_requestUrl, {
    params: {
      ..._params,
      ...optionalParams,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return data;
};

export const downloadTermsListAsCsv = async (
  orgId: number | undefined,
  termBankId: number,
  params: RequestQueryParams,
): Promise<Blob> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term/csv`;

  const { data } = await Request.getAxiosInstance().get<Blob>(_requestUrl, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    responseType: 'blob',
  });

  return data;
};

export const downloadTermsListAsXlsx = async (
  orgId: number | undefined,
  termBankId: number,
  params: RequestQueryParams,
): Promise<Blob> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term/xlsx`;

  const { data } = await Request.getAxiosInstance().get<Blob>(_requestUrl, {
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
    responseType: 'blob',
  });

  return data;
};

export const createTermsRequest = async (
  orgId: number | undefined,
  termBankId: number,
  models: ITermCreateAndUpdate[],
  failHandling?: BatchSubmitFailHandlingTypes,
): Promise<ICreateTermsWithFails> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term`;

  const { data } = await Request.getAxiosInstance().post<ICreateTermsWithFails>(_requestUrl, {
    models,
    failHandling,
  });

  return data;
};

export const updateTermsRequest = async (
  orgId: number | undefined,
  termBankId: number,
  models: ITermCreateAndUpdate[],
): Promise<ICreateTermsWithFails> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term`;

  const { data } = await Request.getAxiosInstance().put<ICreateTermsWithFails>(_requestUrl, {
    models,
  });

  return data;
};

export const deleteTerms = async (orgId: number | undefined, termBankId: number, ids: number[]): Promise<ITeam[]> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term`;
  const params = new URLSearchParams();
  ids.forEach(d => params.append('ids', String(d)));
  const { data } = await Request.getAxiosInstance().delete<ITeam[]>(_requestUrl, {
    params,
  });

  return data;
};

export const getTermById = async (orgId: number | undefined, teamId: TeamId, termId: number): Promise<ITerm> => {
  const _requestUrl = `/terminology/organization/${orgId}/team/${teamId}/term/${termId}`;
  const { data } = await Request.getAxiosInstance().get<ITerm>(_requestUrl);

  return data;
};

export const getTermsTagsRequest = async (
  orgId: number | undefined,
  termBankId: number,
): Promise<ITermsTagsListResponse> => {
  const _requestUrl = `/terminology/organization/${orgId}/term-bank/${termBankId}/term/tag`;
  const { data } = await Request.getAxiosInstance().get<ITermsTagsListResponse>(_requestUrl);

  return data;
};
