import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../../UserActivityReportPage.module.css';

interface ITriggerProps extends BaseComponentsProps {
  testId?: string;
  title?: string;
  onClick: () => void;
}

export const Trigger = ({ className, testId, title, onClick }: ITriggerProps) => (
  <div data-testid={testId} className={cx(styles.trigger, className)} onClick={onClick}>
    <Text variant={TextSize.XL} ellipsisOverflow>
      {title}
    </Text>
    <Icon className={styles.triggerIcon} name={IconVariant.ARROW_DOWN} />
  </div>
);
