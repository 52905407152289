import type React from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  Logo,
  LogoVariant,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
  Tooltip,
} from '@writercolab/ui-atoms';

import { useLocation, useNavigate } from 'react-router';

import useStartTeamTrialPopup from '../../../hooks/useStartTeamTrialPopup';
import { ROUTE } from '../../../services/config/routes';
import { useAppState } from '../../../state';
import { TActionType } from '../../../state/types';
import BeaconIcon from '../BeaconIcon';
import ChromeExtWindow from '../ChromeExtWindow';

import styles from './styles.module.css';

const BeaconTooltipContent = () => (
  <div className={styles.beaconLogoTooltipContent}>
    <Logo variant={LogoVariant.WHITE} className={styles.beaconLogo} />
    <Text color={TextColor.WHITE} variant={TextSize.S}>
      Click on this
    </Text>
    <Text color={TextColor.WHITE} variant={TextSize.S}>
      when you're ready
    </Text>
    <Text color={TextColor.WHITE} variant={TextSize.S}>
      go back home.
    </Text>
  </div>
);

interface IEditorLeftSidebarProps extends BaseComponentsProps {
  name?: string;
  activeDocId: number;
  hideChromeExtBanner: boolean;
  onBack?: () => void;
}

export const EditorLeftSidebar: React.FC<IEditorLeftSidebarProps> = ({
  style,
  className,
  activeDocId,
  hideChromeExtBanner,
  onBack,
}) => {
  const {
    dispatchAppState,
    appState: { organizationId, teamId, isTutorialDocBeaconVisible, isChromeExtensionWindowVisible },
  } = useAppState();
  const { isPopupOpen } = useStartTeamTrialPopup(String(activeDocId));
  const navigate = useNavigate();
  const location = useLocation();
  const goBackTo = location.state?.backTo;

  const onBackButtonClick = () => {
    dispatchAppState({ type: TActionType.SetTutorialDocBeaconVisible, payload: false });

    onBack?.();

    if (goBackTo) {
      return navigate(goBackTo);
    }

    return navigate(ROUTE.toHome(organizationId, teamId));
  };

  return (
    <section style={style} className={cx(styles.sidebarContainer, className)}>
      <div className={styles.appHeader}>
        <Button
          className={styles.backButton}
          type={ButtonTypes.TRANSPARENT}
          icon={<Icon name={IconVariant.BACK_ARROW} />}
          size={SizeTypes.SMALL}
          onClick={onBackButtonClick}
          round
        />
        <Tooltip title="Dashboard" placement="right">
          <Logo variant={LogoVariant.BLACK} className={styles.appLogo} onClick={onBackButtonClick} />
        </Tooltip>
        {isTutorialDocBeaconVisible && (
          <div className={styles.beaconContainer}>
            <BeaconIcon
              tooltipContent={<BeaconTooltipContent />}
              beaconClassName={styles.beaconIcon}
              tooltipWidth="128px"
              tooltipOpen
            />
          </div>
        )}
      </div>
      {isChromeExtensionWindowVisible && !isPopupOpen && !hideChromeExtBanner && (
        <ChromeExtWindow sourceLocation="sidebar" />
      )}
    </section>
  );
};
