import { isToday, isWithinInterval, subDays } from 'date-fns';

/**
 * Get the date n days ago.
 *
 * @param {number} days - The number of days.
 * @returns {Date} - The date n days ago.
 */
export const getNDaysAgoDate = (days: number): Date => subDays(new Date(), days);

/**
 * Check if a date is within the past n days.
 *
 * @param {Date} date - The date to check.
 * @param {number} days - The number of days.
 * @returns {boolean} - True if the date is within the past n days, false otherwise.
 */
export const isDateInPastNDays = (date: Date, days: number): boolean => {
  const startDate = getNDaysAgoDate(days);
  const endDate = new Date();

  return isWithinInterval(date, { start: startDate, end: endDate });
};

/**
 * Check if a date is older than n days ago.
 *
 * @param {Date} date - The date to check.
 * @param {number} days - The number of days.
 * @returns {boolean} - True if the date is older than n days ago, false otherwise.
 */
export const isDateOlderThanNDaysAgo = (date: Date, days: number): boolean => date < getNDaysAgoDate(days);

/**
 * Check if the given date is today.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is today, false otherwise.
 */
export const isTodayCondition = (date: Date): boolean => isToday(date);

/**
 * Check if the given date is within the current week.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is within the current week, false otherwise.
 */
export const isThisWeekCondition = (date: Date): boolean => !isToday(date) && isDateInPastNDays(date, 7);

/**
 * Check if the given date is earlier than the current week.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is earlier than the current week, false otherwise.
 */
export const isEarlierCondition = (date: Date): boolean =>
  !isDateInPastNDays(date, 7) && isDateOlderThanNDaysAgo(date, 7);

/**
 * Check if the given date is yesterday.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is earlier than the current week, false otherwise.
 */
export const isYesterdayCondition = (date: Date): boolean => !isTodayCondition(date) && isDateInPastNDays(date, 1);

/**
 * Checks if the given date is earlier than yesterday.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - True if the date is earlier than yesterday, false otherwise.
 */
export const isEarlierThenYesterdayCondition = (date: Date): boolean =>
  !isTodayCondition(date) && isDateOlderThanNDaysAgo(date, 1);
