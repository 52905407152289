import { Button, ButtonTypes, Heading, HeadingVariant, Modal, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { ThreeDotsLoader } from '@writercolab/ui-molecules';

import { observer } from 'mobx-react-lite';
import { TAppModals } from 'models/app';
import { useAppState } from 'state';

import styles from './styles.module.css';

export const AiStudioAccessPendingModal: React.FC = observer(() => {
  const {
    appModel: { modalsManager, orgAdmins },
  } = useAppState();

  return (
    <Modal
      open={modalsManager.isModalVisible(TAppModals.enum.aiStudioContactAdmin)}
      style={{ width: '720px' }}
      handleClose={() => modalsManager.hideModal(TAppModals.enum.aiStudioContactAdmin)}
    >
      <div className={styles.aiStudioContainer}>
        <Heading variant={HeadingVariant.H3} medium>
          Pending AI Studio access
        </Heading>
        <Text className={styles.aiStudioText} variant={TextSize.XL}>
          Your org admin needs to accept the invitation to AI Studio and grant you access before you can start using AI
          Studio. Please reach out to an org admin for access:
        </Text>

        <div className={styles.aiStudioAdminsTable}>
          {orgAdmins.status === 'fulfilled' ? (
            <>
              <div className={styles.aiStudioAdminsTableHeader}>
                <Text color={TextColor.GREY2} className={styles.aiStudioAdminsTableCell} variant={TextSize.M} smallCaps>
                  Name
                </Text>
                <Text color={TextColor.GREY2} className={styles.aiStudioAdminsTableCell} variant={TextSize.M} smallCaps>
                  Email
                </Text>
              </div>
              <div className={styles.aiStudioAdminsTableBody}>
                {orgAdmins.value?.map(admin => (
                  <div key={admin.id} className={styles.aiStudioAdminsTableRow}>
                    <Text className={styles.aiStudioAdminsTableCell} variant={TextSize.M} bold>
                      {admin.fullName}
                    </Text>
                    <Text className={styles.aiStudioAdminsTableCell} variant={TextSize.M}>
                      <a href={`mailto:${admin.email}`} target="_blank">
                        {admin.email}
                      </a>
                    </Text>
                  </div>
                ))}
              </div>{' '}
            </>
          ) : (
            <ThreeDotsLoader />
          )}
        </div>

        <div className={styles.aiStudioButtons}>
          <Button
            type={ButtonTypes.PRIMARY}
            onClick={() => modalsManager.hideModal(TAppModals.enum.aiStudioContactAdmin)}
            content="Return to Writer app"
          />
        </div>
      </div>
    </Modal>
  );
});
