import React, { useEffect } from 'react';

import cx from 'classnames';

import { getDateTime } from '@writercolab/common-utils';
import { sanitize } from '@writercolab/text-utils';
import {
  Button,
  ButtonTypes,
  DotLoader,
  FontFace,
  IconVariant,
  SizeTypes,
  Tag,
  TagColor,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { UtilIcon } from '@writercolab/ui-molecules';

import { SeoBlogSection, TTemplateDraft } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import BlogDraftsEmpty from './BlogDraftsEmpty';

import styles from '../styles.module.css';

export type ITemplateDraftExtended = TTemplateDraft & {
  used: boolean;
};

interface IBlogDraftsProps {
  documentDrafts: TTemplateDraft[];
  loadingDocumentDrafts: boolean;
  fetchDocumentDrafts: () => Promise<void>;
  onDraftUse: (draft: ITemplateDraftExtended) => void;
  onDraftComment: (draftId: string) => void;
  onDraftRemove: (draftId: string) => void;
  onDraftCopy: (draftId: string) => void;
  activeDocumentDraftId: string | null;
  setSection: (section: SeoBlogSection) => void;
}

interface IDraftSection {
  draft: ITemplateDraftExtended;
  onRemoveClick: (draftId: string) => void;
  onCopyClick: (draftId: string) => void;
  onUseDraftClick: (draftId: string) => void;
  onFeedbackClick: (draftId: string) => void;
}

const DraftSection: React.FC<IDraftSection> = ({
  draft,
  onRemoveClick,
  onCopyClick,
  onUseDraftClick,
  onFeedbackClick,
}) => (
  <div className={cx(styles.containerForm, styles.containerFormDrafts)}>
    <div className={cx(styles.containerFormItems, styles.containerFormItemsDrafts)}>
      <div className={styles.draftContainer}>
        <div className={styles.draftActions}>
          {draft.used && (
            <div className={styles.draftTag}>
              <Tag color={TagColor.LIGHT_GREEN} upperCase>
                <Text variant={TextSize.XS} smallCaps>
                  using this output
                </Text>
              </Tag>
            </div>
          )}
          <div>
            <Text variant={TextSize.XS} caps>
              {getDateTime(draft.creationTime)}
            </Text>
          </div>
          <div className={styles.draftActionItems}>
            {!draft.used && (
              <Button
                size={SizeTypes.XS}
                type={ButtonTypes.PRIMARY}
                className={styles.draftActionUseDraft}
                onClick={() => onUseDraftClick(`${draft.id}`)}
              >
                <Text color={TextColor.WHITE} bold>
                  Use this output
                </Text>
              </Button>
            )}
            <UtilIcon
              className={cx(styles.clickable, styles.draftActionIcon)}
              variant={IconVariant.COPY}
              circle
              width={15}
              height={15}
              onClick={() => onCopyClick(`${draft.id}`)}
              tooltipContent="Copy text"
            />
            <UtilIcon
              className={cx(styles.clickable, styles.draftActionIcon, styles.draftActionUseDraftFeedback)}
              variant={IconVariant.FEEDBACK}
              circle
              width={16}
              height={16}
              onClick={() => onFeedbackClick(`${draft.id}`)}
              tooltipContent="Rate this output"
            />
            <UtilIcon
              className={cx(styles.clickable, styles.draftActionIcon)}
              variant={IconVariant.TRASH}
              circle
              width={16}
              height={16}
              onClick={() => onRemoveClick(`${draft.id}`)}
              tooltipContent="Delete output"
            />
          </div>
        </div>
        <div className={styles.draftTitle}>
          <Text variant={TextSize.L} fontFace={FontFace.monospace}>
            Headline
          </Text>
          <div className={styles.draftHeadline}>
            <Text variant={TextSize.XXXXL} bold>
              {draft.title}
            </Text>
          </div>
        </div>
        <div className={styles.draftContent}>
          <div className={styles.body} dangerouslySetInnerHTML={{ __html: sanitize(draft.body) || '' }} />
        </div>
      </div>
    </div>
  </div>
);

export const BlogDrafts: React.FC<IBlogDraftsProps> = ({
  loadingDocumentDrafts,
  documentDrafts,
  fetchDocumentDrafts,
  onDraftUse,
  onDraftComment,
  onDraftRemove,
  onDraftCopy,
  setSection,
  activeDocumentDraftId,
}) => {
  const [drafts, setDrafts] = React.useState<ITemplateDraftExtended[]>([]);

  useEffect(() => {
    fetchDocumentDrafts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setDrafts(
      documentDrafts.map(draft => ({
        ...draft,
        used: !!(activeDocumentDraftId && draft.id === parseInt(activeDocumentDraftId, 10)),
      })),
    );
  }, [documentDrafts, activeDocumentDraftId]);

  const _onClickRemoveDraft = async (draftId: string) => {
    await onDraftRemove(draftId);
  };
  const _onClickCopyDraft = (draftId: string) => onDraftCopy(draftId);
  const _onClickUseDraft = (draftId: string) => {
    // setDrafts(drafts.map(draft => ({ ...draft, used: draft.id === parseInt(draftId, 10) })));
    const draft = drafts.find(draft => draft.id === parseInt(draftId, 10));

    if (draft) {
      onDraftUse(draft);
    }
  };
  const _onClickFeedbackDraft = (draftId: string) => onDraftComment(draftId);
  const _onEmptyStateActionClick = () => setSection(SeoBlogSection.KEYPOINTS);

  if (loadingDocumentDrafts) {
    return <DotLoader />;
  }

  return (
    <div className={styles.containersList}>
      {isEmpty(drafts) ? (
        <BlogDraftsEmpty onActionClick={_onEmptyStateActionClick} />
      ) : (
        drafts.map(draft => (
          <DraftSection
            key={draft.id}
            draft={draft}
            onRemoveClick={_onClickRemoveDraft}
            onCopyClick={_onClickCopyDraft}
            onUseDraftClick={_onClickUseDraft}
            onFeedbackClick={_onClickFeedbackDraft}
          />
        ))
      )}
    </div>
  );
};

export default BlogDrafts;
