import type { ILog } from '@writercolab/utils';

import { APP_ENV } from '../services/config/constants';
import config from './dynamicConfig';

const { VITE_ENV, VITE_DATADOG_APP_ID, VITE_DATADOG_CLIENT_TOKEN, VITE_VERSION } = import.meta.env;

interface IReportRenderErrorArgs {
  name: string;
  error: Error;
  info: React.ErrorInfo;
  hash?: string;
}

const getDatadogClient = async () => {
  if (VITE_ENV === APP_ENV.PROD && !config.SINGLE_TENANCY_CONFIG?.disableDatadog) {
    const { datadogRum } = await import('@datadog/browser-rum');

    return datadogRum;
  }

  return null;
};

export const datadogLogMiddleware: ILog =
  (namespace: string) =>
  (...msg: any[]) => {
    if (
      VITE_ENV === APP_ENV.PROD &&
      !config.SINGLE_TENANCY_CONFIG?.disableDatadog &&
      namespace.toLowerCase().includes('error')
    ) {
      getDatadogClient().then(datadogRum => {
        datadogRum?.addError(msg[0], ...msg);
      });
    }
  };

export const reportRenderError = ({ name, error, info, hash }: IReportRenderErrorArgs): void => {
  const renderError = new Error(error.message);
  renderError.name = name;
  renderError.stack = info.componentStack ?? undefined;
  renderError.cause = error;

  getDatadogClient().then(datadogRum => {
    datadogRum?.addError(renderError, { errorHash: hash });
  });
};

export const initDatadog = async () => {
  const datadogRum = await getDatadogClient();

  datadogRum?.init({
    applicationId: VITE_DATADOG_APP_ID,
    clientToken: VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'frontend',
    env: VITE_ENV?.toLowerCase(),
    version: VITE_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });

  datadogRum?.startSessionReplayRecording();
};

export const setDatadogUser = async ({ userId, orgId, teamId }: { userId: string; orgId: string; teamId?: number }) => {
  const datadogRum = await getDatadogClient();

  datadogRum?.setUser({
    id: userId,
    orgId,
    teamId,
  });
};
