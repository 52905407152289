import { type ChangeEvent, useMemo, useState } from 'react';

import { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Modal, RadioGroup, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { InputField } from 'components/organisms/InputField/InputField';

import { TKnowledgeGraphUrl, TKnowledgeGraphUrlModal } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';

import type { KnowledgeGraphWebConnectorUIModel } from '../../KnowledgeGraphWebConnectorModel.ui';
import { KG_URLS_LIMIT, KG_URLS_OPTIONS, getErrorLabel, validateUrlWithDomain } from '../../utils';

import styles from './EditUrlModal.module.css';

interface IEditUrlModalProps {
  model: KnowledgeGraphWebConnectorUIModel;
}

export const EditUrlModal = observer<IEditUrlModalProps>(({ model }) => {
  const { analyticsService, modalsManager, urls, onUpdateUrl, validUrlsCount, excludeUrlsCount } = model;
  const modalState = modalsManager.getModalState(TKnowledgeGraphUrlModal.enum.editUrl);
  const [type, setType] = useState<typeof TKnowledgeGraphUrl.type>(
    modalState?.type ?? TKnowledgeGraphUrl.enum.SubPages,
  );
  const urlField = useMemo(() => {
    const model = FieldModel.build({
      init: () => modalState?.url,
      autotouched: true,
      validation: value => {
        if (!value) {
          return 'Enter a valid URL';
        }

        if (urls.some(url => value !== modalState?.url && value === url.url)) {
          return 'Duplicate URL';
        }

        if (modalState && modalState.error && modalState.url === value) {
          return getErrorLabel(modalState.error);
        }

        return validateUrlWithDomain(value);
      },
    });
    model.touched = true;

    return model;
  }, [modalState, urls]);

  const handleEditUrl = () => {
    if (modalState && urlField.value) {
      onUpdateUrl({
        id: modalState.id,
        type,
        url: urlField.value,
      });
    }
  };

  return (
    <Modal
      open
      title="Edit URL"
      className={styles.content}
      modalTitleClassName={styles.title}
      disableBackdropClick
      handleClose={() => {
        analyticsService.track(AnalyticsActivity.kgWebEditUrlModalClosed, {
          url: modalState?.url,
        });
        modalsManager.hideModal();
      }}
    >
      <>
        <Text variant={TextSize.XL} color={TextColor.BLACK}>
          Changes will apply to excluded subpage URLs as well.
        </Text>
        <Text className={styles.label} variant={TextSize.XL} color={TextColor.BLACK}>
          Website URL
        </Text>
        <InputField field={urlField} placeholder="https://" />
        <RadioGroup
          className={styles.radioGroup}
          options={KG_URLS_OPTIONS}
          name="url-parse-type"
          currentValue={type}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setType(e.target.value as typeof TKnowledgeGraphUrl.type)}
          column
        />
        <div className={styles.controls}>
          <Text variant={TextSize.M} color={TextColor.BLACK}>
            {validUrlsCount + excludeUrlsCount}/{KG_URLS_LIMIT} URLs ({validUrlsCount} included · {excludeUrlsCount}{' '}
            excluded)
          </Text>
          <Button
            className={styles.addUrlButton}
            disabled={!!urlField.error}
            size={SizeTypes.MEDIUM}
            type={ButtonTypes.PRIMARY}
            onClick={handleEditUrl}
          >
            Save
          </Button>
        </div>
      </>
    </Modal>
  );
});
