import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import {
  Button,
  ButtonTypes,
  Icon,
  IconVariant,
  SizeTypes,
  SkeletonLoader,
  SkeletonLoaderType,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { InputGroup, InputTypes, UtilIcon } from '@writercolab/ui-molecules';

import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DEFAULT_SECTIONS_COUNT, IOutlineSection, MAX_SECTIONS_COUNT, MIN_SECTIONS_COUNT } from '@web/types';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';

import { getLogger } from '../../../../utils/logger';
import { generateOutlineSection } from '../utils/generateUtils';
import DynamicItemEmptyState from './DynamicItemEmptyState';

import styles from '../styles.module.css';

const LOG = getLogger('BlogOutlineForm');

interface IBlogOutlineFormProps {
  headline: string;
  outlineGenerating: boolean;
  onOutlineGenerate: (count: number, currentList: IOutlineSection[], index?: number) => Promise<IOutlineSection[]>;
  outlineGenerated: IOutlineSection[];
  setOutline: (outline: IOutlineSection[], resetKeyPoints?: boolean) => void;
}

const HeadLine = ({ headline }) => (
  <div className={styles.containerHeadlineContainer}>
    <Text variant={TextSize.XL} bold className={styles.containerHeadlineTop}>
      Headline
    </Text>
    <Text variant={TextSize.XXXXL} bold className={styles.containerHeadlineBottom}>
      {headline}
    </Text>
  </div>
);

const OutLine = ({ onOutlineGenerate, loading }) => (
  <div className={styles.containerHeadlineContainer}>
    <Text variant={TextSize.XL} bold className={styles.containerHeadlineTop}>
      Outline
    </Text>
    {!loading && (
      <Button
        size={SizeTypes.XS}
        type={ButtonTypes.BLACK}
        className={styles.generateOutlineButton}
        onClick={onOutlineGenerate}
      >
        <Icon name={IconVariant.WAND_WHITE} className={styles.generateTitlesButtonIcon} />
        <Text color={TextColor.WHITE} bold>
          Generate a new outline
        </Text>
      </Button>
    )}
  </div>
);

const Section = ({ section, onRemoveClick, onReloadClick, onChangeSectionName, removeSectionAvailable, ...props }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const errorMessage = section?.valid === false ? 'this field is required' : undefined;

  return (
    <div className={cx(styles.outlineSectionContainer, styles.clickable)} ref={setNodeRef} style={style}>
      <div className={styles.outlineSectionContainerInputs}>
        {!isEmpty(errorMessage) && (
          <div className={styles.errorWrapper}>
            <Text color={TextColor.ORANGE} extraSmallCaps variant={TextSize.XS}>
              {errorMessage}
            </Text>
          </div>
        )}
        <InputGroup
          label=""
          id={section.id}
          name={section.id}
          value={section.name}
          inputType={InputTypes.TEXTAREA}
          errorText={section?.valid === false ? 'required' : undefined}
          multiline
          rows={1}
          placeholder="Add a blog section by writing a header or brief description"
          handleChangeInput={e => onChangeSectionName(section.id, e.target.value)}
        />
      </div>
      <div className={styles.outlineSectionContainerActions}>
        <UtilIcon
          className={cx(styles.outlineSectionContainerAction, styles.clickable)}
          testId="ai-assistant-blog-reword"
          variant={IconVariant.REFRESH}
          circle
          onClick={() => onReloadClick(section.id)}
          width={20}
          height={20}
          tooltipContent="Reword section"
        />
        {removeSectionAvailable && (
          <UtilIcon
            className={cx(styles.outlineSectionContainerAction, styles.clickable)}
            testId="ai-assistant-blog-remove-section"
            variant={IconVariant.CLOSE}
            circle
            onClick={() => onRemoveClick(section.id)}
            width={20}
            height={20}
            tooltipContent="Remove section"
          />
        )}
        <UtilIcon
          className={cx(styles.outlineSectionContainerAction, styles.clickable)}
          testId="ai-assistant-blog-drag"
          variant={IconVariant.DRAG}
          circle
          width={20}
          height={20}
          tooltipContent="Hold and drag to reorder"
          {...attributes}
          {...listeners}
        />
      </div>
    </div>
  );
};

const SectionLoading = ({ count }) => {
  let counter = 0;

  return (
    <>
      {Array(count)
        .fill(0)
        .map(() => (
          <div
            key={`loader-${++counter}`}
            className={cx(styles.outlineSectionContainer, styles.outlineSectionContainerLoading)}
          >
            <SkeletonLoader
              type={SkeletonLoaderType.DEFAULT}
              className={styles.outlineSectionContainerLoadingContainer}
            />
          </div>
        ))}
    </>
  );
};

const AddNewSectionButton = ({ onAddSectionClick, loading, sectionsCount }) => {
  const buttonText =
    sectionsCount < MAX_SECTIONS_COUNT
      ? 'Add new section'
      : `You’ve reached the limit of ${MAX_SECTIONS_COUNT} sections`;

  return (
    <div className={styles.containerFormItem}>
      <div
        className={cx(styles.containerNewItemButton, styles.clickable, {
          [styles.eventsDisabled]: loading,
          [styles.containerNewItemButtonRichLimit]: sectionsCount >= MAX_SECTIONS_COUNT,
        })}
        onClick={onAddSectionClick}
      >
        <Icon name={IconVariant.ADD} className={styles.containerNewItemButtonIcon} />
        <Text>{buttonText}</Text>
      </div>
    </div>
  );
};

export const BlogOutlineForm: React.FC<IBlogOutlineFormProps> = ({
  headline,
  outlineGenerating,
  onOutlineGenerate,
  outlineGenerated,
  setOutline,
}) => {
  const [sections, setSections] = useState<IOutlineSection[]>([]);
  const [removeSectionAvailable, setRemoveSectionAvailable] = useState(true);
  const [sectionsCount, setSectionsCount] = useState(DEFAULT_SECTIONS_COUNT);

  useEffect(() => {
    if (isEmpty(outlineGenerated)) {
      onOutlineGenerate(DEFAULT_SECTIONS_COUNT, [])
        .then(outline => setSections(outline))
        .catch(e => LOG.error(e));
    } else {
      setSections(outlineGenerated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outlineGenerated]);

  useEffect(() => {
    if (sections.length > 0) {
      setSectionsCount(sections.length);
      setRemoveSectionAvailable(sections.length > MIN_SECTIONS_COUNT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections]);

  const throttleCb = sections => throttle(sections => setOutline(sections, true), 500)(sections);

  const _onAddSectionClick = async () => {
    const _sections = [...sections, generateOutlineSection()];
    setSections(_sections);
    throttleCb(_sections);
  };

  const _onRemoveSectionClick = async (id: string) => {
    const _sections = sections.filter(section => section.id !== id);
    setSections(_sections);
    throttleCb(_sections);
  };

  const _onChangeSectionName = async (id: string, name: string) => {
    const _sections = sections.map(section =>
      section.id === id ? { ...section, name, valid: !isEmpty(name) } : section,
    );
    throttleCb(_sections);
    setSections(_sections);
  };

  const _onOutlineGenerateClick = async () => {
    const sectionsRes = await onOutlineGenerate(sectionsCount, sections);

    setSections(sectionsRes);
  };

  const _onReloadSectionClick = async (id: string) => {
    const sectionIndex = sections.findIndex(section => section.id === id);
    setSections(sections.map(section => (section.id === id ? { ...section, loading: true } : section)));
    const sectionsRes = await onOutlineGenerate(1, sections, sectionIndex);

    setSections(sectionsRes);
  };

  const _handleSectionDragEnd = event => {
    const { active, over } = event;
    const activeIndex = sections.findIndex(section => section.id === active.id);
    const overIndex = sections.findIndex(section => section.id === over.id);
    const sectionsSorted = arrayMove(sections, activeIndex, overIndex);
    setSections(sectionsSorted);
    setOutline(sectionsSorted, true);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <div className={styles.containerForm}>
      <div className={styles.containerFormItems}>
        <div className={styles.containerFormItem}>
          <HeadLine headline={headline} />
        </div>
        <div className={styles.containerFormItem}>
          <OutLine onOutlineGenerate={_onOutlineGenerateClick} loading={outlineGenerating} />
          {!outlineGenerating && isEmpty(sections) && (
            <DynamicItemEmptyState onReloadClick={_onOutlineGenerateClick} loading={outlineGenerating} />
          )}
        </div>
        <div className={cx(styles.containerFormItem, styles.containerFormItemWide)}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={_handleSectionDragEnd}>
            <SortableContext items={sections} strategy={verticalListSortingStrategy}>
              {outlineGenerating ? (
                <SectionLoading count={sectionsCount} />
              ) : (
                sections.map(section =>
                  section.loading ? (
                    <SectionLoading key={section.id} count={1} />
                  ) : (
                    <Section
                      key={section.id}
                      id={section.id}
                      section={section}
                      onReloadClick={() => _onReloadSectionClick(section.id)}
                      onRemoveClick={_onRemoveSectionClick}
                      onChangeSectionName={_onChangeSectionName}
                      removeSectionAvailable={removeSectionAvailable}
                    />
                  ),
                )
              )}
            </SortableContext>
          </DndContext>
        </div>
        <AddNewSectionButton
          sectionsCount={sectionsCount}
          loading={outlineGenerating}
          onAddSectionClick={_onAddSectionClick}
        />
      </div>
    </div>
  );
};

export default BlogOutlineForm;
