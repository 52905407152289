import React, { useEffect, useState } from 'react';

import { ALLOW_LIST_LIMIT } from '@writercolab/common-utils';
import { Button, ButtonTypes, Heading, HeadingVariant, Modal, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { SortButton, StringsEditor } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

interface FleschKincaldAllowlistModalProps {
  isOpen: boolean;
  list: string[];
  onSave: (list: string[]) => void;
  onCancel: () => void;
}

export const FleschKincaldAllowlistModal: React.FC<FleschKincaldAllowlistModalProps> = ({
  isOpen,
  list,
  onSave,
  onCancel,
}) => {
  const [isExceedLimit, setIsExceedLimit] = useState(false);
  const [allowList, setAllowList] = useState(list);

  const onChangeList = (list: string[]) => setAllowList(list);
  const onSaveClick = () => onSave(allowList);
  const onSortList = () => {
    const newList = allowList
      .map(item => item.trim())
      .filter(Boolean)
      .sort();
    setAllowList(newList);
  };
  const validItems = allowList.filter(Boolean).length;

  useEffect(() => {
    if (isOpen) {
      setAllowList(list);
    }
  }, [isOpen, list]);

  useEffect(() => setIsExceedLimit(allowList.length > ALLOW_LIST_LIMIT), [allowList]);

  return (
    <Modal open={isOpen} handleClose={onCancel} style={{ width: '480px' }}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <Heading variant={HeadingVariant.H3} className={styles.header} bold>
            Edit Flesch-Kincaid allowlist
          </Heading>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            Add words you’d like to be ignored by our Flesch-Kincaid readability checker.
          </Text>
        </div>
        <div className={styles.sortIcon}>
          <SortButton onClick={onSortList}></SortButton>
        </div>
        <div className={styles.editor}>
          <StringsEditor list={allowList} onChange={onChangeList} />
        </div>
        <div className={styles.limit}>
          <Text variant={TextSize.XS} color={isExceedLimit ? TextColor.ORANGE : TextColor.GREY}>
            {validItems} / {ALLOW_LIST_LIMIT}
          </Text>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancelButton} type={ButtonTypes.BORDERED} onClick={onCancel} content="Cancel" />
          <Button type={ButtonTypes.PRIMARY} onClick={onSaveClick} content="Save" disabled={isExceedLimit} />
        </div>
      </div>
    </Modal>
  );
};

export default FleschKincaldAllowlistModal;
