import {
  DocumentHighlightType,
  DocumentHighlightsExtended,
  numberWithCommas,
  wordPluralize,
} from '@writercolab/common-utils';
import { IconVariant } from '@writercolab/ui-atoms';

import { HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT, HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT } from '@web/types';

export const HIGHLIGHT_AVAILABLE_REGENERATES = 10;

export const headerConfig: {
  [key in DocumentHighlightType]: {
    title: string;
    icon?: IconVariant;
  };
} = {
  [DocumentHighlightType.META]: {
    title: 'Meta description',
  },
  [DocumentHighlightType.LINKEDIN]: {
    title: 'LinkedIn',
    icon: IconVariant.LINKEDIN,
  },
  [DocumentHighlightType.TWITTER]: {
    title: 'Twitter',
    icon: IconVariant.TWITTER_UNFILLED,
  },
  [DocumentHighlightType.POINTS]: {
    title: 'Summary',
  },
};

export const loadingHighlightsMock = [
  {
    id: '1',
    loading: true,
    entry: [],
    type: DocumentHighlightType.META,
    userId: 1,
    version: 1,
  } as DocumentHighlightsExtended,
  {
    id: '2',
    loading: true,
    entry: [],
    userId: 1,
    version: 1,
    type: DocumentHighlightType.POINTS,
  } as DocumentHighlightsExtended,
  {
    id: '3',
    loading: true,
    entry: [],
    userId: 1,
    version: 1,
    type: DocumentHighlightType.LINKEDIN,
  } as DocumentHighlightsExtended,
  {
    id: '4',
    loading: true,
    entry: [],
    userId: 1,
    version: 1,
    type: DocumentHighlightType.TWITTER,
  } as DocumentHighlightsExtended,
];

export const highlightMessage = {
  REGENERATE: 'Regenerate',
  OUTDATED: 'Looks like your Doc was updated. Regenerate this highlight to reflect the latest changes.',
  AVAILABLE_MIN_WORDS_COUNT: `Your doc must be over ${numberWithCommas(
    HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT,
  )} ${wordPluralize(HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT, 'word')} to generate this highlight.`,
  AVAILABLE_MAX_WORDS_COUNT: `Your doc must be below ${numberWithCommas(
    HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT,
  )} ${wordPluralize(HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT, 'word')} to generate this highlight.`,
  LIMIT_REACHED: `You’ve reached the limit of ${HIGHLIGHT_AVAILABLE_REGENERATES} generated options.`,
  AVAILABLE_MIN_WORDS_COUNT_SIDEBAR: `Highlights are available for docs over ${numberWithCommas(
    HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT,
  )} ${wordPluralize(HIGHLIGHT_AVAILABLE_MIN_WORDS_COUNT, 'word')}`,
  AVAILABLE_MAX_WORDS_COUNT_SIDEBAR: `Highlights are disabled for docs >${numberWithCommas(
    HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT,
  )} ${wordPluralize(HIGHLIGHT_AVAILABLE_MAX_WORDS_COUNT, 'word')}`,
};
