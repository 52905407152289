import type { FieldModel } from '@writercolab/mobx';
import type { components } from '@writercolab/network';
import { Label, Modal, TagColor, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { BetaFeatureLabel, ThreeDotsLoader } from '@writercolab/ui-molecules';

import { TKnowledgeGraph } from '@web/types';
import { observer } from 'mobx-react-lite';

import { InputField } from '../InputField/InputField';

import styles from './AddKnowledgeGraphModal.module.css';

interface IAddKnowledgeGraphModalModal {
  name: FieldModel<string>;
  description: FieldModel<string>;
  isLoading: boolean;
  onClickSubmit: (type: components['schemas']['media_model_SourceType']) => void;
  onClickClose: () => void;
  isConnectorsEnabled?: boolean;
  isWebConnectorsEnabled?: boolean;
}

export const AddKnowledgeGraphModal = observer<IAddKnowledgeGraphModalModal>(
  ({ name, description, isConnectorsEnabled, isWebConnectorsEnabled, isLoading, onClickSubmit, onClickClose }) => (
    <Modal
      open
      title="New graph"
      className={styles.content}
      modalTitleClassName={styles.modalTitle}
      modalContainerClassName={styles.container}
      disableBackdropClick
      handleClose={onClickClose}
    >
      <>
        <InputField className={styles.inputName} field={name} placeholder="Enter a name for your graph">
          <Label>Name*</Label>
        </InputField>
        <InputField
          className={styles.inputDescription}
          field={description}
          placeholder="Describe the data in this graph"
        >
          <Label>Description</Label>
        </InputField>

        {isLoading ? (
          <div className={styles.loader}>
            <ThreeDotsLoader />
          </div>
        ) : (
          <>
            <Text className={styles.label} variant={TextSize.S} color={TextColor.GREY} upperCase bold>
              Select an option
            </Text>
            <button className={styles.card} type="button" onClick={() => onClickSubmit(TKnowledgeGraph.enum.Upload)}>
              <Text className={styles.title} variant={TextSize.XXXXL} color={TextColor.GREY} medium>
                Add via manual file upload
              </Text>
              <Text className={styles.subtitle} variant={TextSize.S} color={TextColor.GREY}>
                Upload files manually from your desktop
              </Text>
            </button>
            <button
              type="button"
              className={styles.card}
              disabled={!isConnectorsEnabled}
              onClick={() => onClickSubmit(TKnowledgeGraph.enum.Connector)}
            >
              <Text className={styles.title} variant={TextSize.XXXXL} color={TextColor.GREY} medium>
                Add via data connector
              </Text>
              <Text className={styles.subtitle} variant={TextSize.S} color={TextColor.GREY}>
                Integrate data from an existing tool
              </Text>
              {!isConnectorsEnabled && <Label className={styles.labelComingSoon}>Coming soon</Label>}
            </button>
            <button
              type="button"
              className={styles.card}
              onClick={() => {
                window.open('https://dev.writer.com/api-guides/knowledge-graph', '_blank');
              }}
            >
              <Text className={styles.title} variant={TextSize.XXXXL} color={TextColor.GREY} medium>
                Add via API
              </Text>
              <Text className={styles.subtitle} variant={TextSize.S} color={TextColor.GREY}>
                Send docs to Writer via API
              </Text>
            </button>
            <button
              type="button"
              className={styles.card}
              disabled={!isWebConnectorsEnabled}
              onClick={() => onClickSubmit(TKnowledgeGraph.enum.WebConnector)}
            >
              <Text className={styles.title} variant={TextSize.XXXXL} color={TextColor.GREY} medium>
                Add websites
              </Text>
              <Text className={styles.subtitle} variant={TextSize.S} color={TextColor.GREY}>
                Create a list of websites to search
              </Text>
              {isWebConnectorsEnabled ? (
                <BetaFeatureLabel className={styles.labelBeta} tagColor={TagColor.LIGHT_GREEN} />
              ) : (
                <Label className={styles.labelComingSoon}>Coming soon</Label>
              )}
            </button>
          </>
        )}
      </>
    </Modal>
  ),
);
