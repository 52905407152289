import { action, computed, makeObservable, observable } from 'mobx';

import { ITableFilter } from '@writercolab/common-utils';

import {
  TTeamPromptBrief,
  TTeamPromptFilter,
  TTeamPromptSortDirection,
  TTeamPromptSortField,
  TeamPromptSortOptions,
} from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { TeamPromptsApiModel } from '../../../models/teamPrompts.api';

interface ITeamPromptsTableProps {
  teamPromptsApiModel: TeamPromptsApiModel;
}

export class TeamPromptsTableUiModel {
  promptId: string | undefined = undefined;
  deleteMode = false;
  editMode = false;

  constructor(private readonly opts: ITeamPromptsTableProps) {
    makeObservable(this, {
      loading: computed.struct,
      hasNext: computed.struct,
      prompts: computed.struct,
      showLoader: computed.struct,
      sortingOptions: computed.struct,
      hasPrompts: computed.struct,
      tagsFiltersOptions: computed.struct,

      setDeleteMode: action,
      setSortingOption: action,
      applyFilter: action,
      setPromptId: action,
      setEditMode: action,

      deleteMode: observable,
      editMode: observable,
    });
  }

  setSortingOption(sortField: typeof TTeamPromptSortField.type) {
    let direction: typeof TTeamPromptSortDirection.type;

    if (sortField === TTeamPromptSortField.enum.createdAt) {
      direction = TTeamPromptSortDirection.enum.desc;
    } else if (sortField === TTeamPromptSortField.enum.title) {
      direction = TTeamPromptSortDirection.enum.asc;
    } else {
      direction = TTeamPromptSortDirection.enum.asc;
    }

    this.opts.teamPromptsApiModel.pagination.setExtra({
      sortField,
      sortOrder: direction,
    });
  }

  applyFilter(type: typeof TTeamPromptFilter.type, filters: ITableFilter[]) {
    if (type === TTeamPromptFilter.enum.tag) {
      const selectedTagIds = filters.filter(f => f.isSelected).map(filter => Number(filter.id));
      this.opts.teamPromptsApiModel.pagination.setExtra({ tagIds: selectedTagIds });
    }
  }

  setEditMode(value: boolean) {
    this.editMode = value;
  }

  get loading() {
    return this.opts.teamPromptsApiModel.pagination.status === 'pending';
  }

  get hasPrompts() {
    return this.opts.teamPromptsApiModel.pagination.value && this.opts.teamPromptsApiModel.pagination.value.length > 0;
  }

  get hasNext(): boolean {
    return this.opts.teamPromptsApiModel.pagination.hasNext ?? false;
  }

  get prompts(): TTeamPromptBrief[] {
    const prompts = this.opts.teamPromptsApiModel.pagination.value;

    return prompts || [];
  }

  get tagsFiltersOptions() {
    return (
      this.opts.teamPromptsApiModel.tagsList.map(
        t =>
          ({
            id: String(t.id),
            name: t.name,
            isSelected: !!this.opts.teamPromptsApiModel.pagination.extra.tagIds?.includes(t.id),
          }) as ITableFilter,
      ) ?? []
    );
  }

  get showLoader() {
    return this.loading && isEmpty(this.prompts);
  }

  get sortingOptions() {
    return TeamPromptSortOptions.map(option => ({
      ...option,
      active: option.id === this.opts.teamPromptsApiModel.pagination.extra.sortField,
    }));
  }

  setDeleteMode(visible: boolean) {
    this.deleteMode = visible;
  }

  setPromptId(promptId?: string) {
    this.promptId = promptId || undefined;
  }

  loadMore = async () => {
    await this.opts.teamPromptsApiModel.pagination.next();
  };

  deletePrompt = async () => {
    if (!this.promptId) {
      return;
    }

    await this.opts.teamPromptsApiModel.delete(this.promptId);
    this.setDeleteMode(false);
    this.opts.teamPromptsApiModel.pagination.reload();
  };
}
