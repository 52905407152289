import React from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Logo, LogoVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

export enum SnackTheme {
  BLUE = 'blue',
  GREEN = 'green',
}

interface SubscribeSnackProps extends BaseComponentsProps {
  alertContent?: string;
  bottomContent?: string;
  topContent?: string;
  withLogo?: boolean;
  theme?: SnackTheme;
  onCloseIconClick?: (event: React.MouseEvent) => void;
}

const themeConfig = {
  [SnackTheme.BLUE]: {
    textColor: TextColor.WHITE,
    logoVariant: LogoVariant.GREEN,
    mainContainerClass: styles.containerBlue,
    containerHeroClass: styles.containerHeroBlue,
  },
  [SnackTheme.GREEN]: {
    textColor: TextColor.BLACK,
    logoVariant: LogoVariant.BLACK,
    mainContainerClass: styles.containerGreen,
    containerHeroClass: styles.containerHeroGreen,
  },
};

export const SubscribeSnack: React.FC<SubscribeSnackProps> = ({
  alertContent,
  topContent,
  bottomContent,
  theme = SnackTheme.BLUE,
  withLogo,
  onCloseIconClick,
  className,
  style,
}) => {
  const currentTheme = themeConfig[theme];

  return (
    <div className={cx(styles.container, currentTheme.mainContainerClass, className)} style={style}>
      <div className={styles.containerLeft}>
        {topContent && (
          <div className={cx(styles.containerHero, currentTheme.containerHeroClass)}>
            <Text variant={TextSize.XL} caps bold>
              {topContent}
            </Text>
          </div>
        )}
        {alertContent && (
          <div className={styles.containerHeroDescription}>
            <Text bold color={currentTheme.textColor}>
              {alertContent}
            </Text>
          </div>
        )}

        {bottomContent && (
          <div className={styles.containerHeroSmall}>
            <Text bold color={currentTheme.textColor} variant={TextSize.XXL}>
              {bottomContent}
            </Text>
          </div>
        )}
      </div>
      {withLogo && (
        <div className={styles.containerRight}>
          <div className={styles.containerLogo}>
            <Logo variant={currentTheme.logoVariant} />
          </div>
        </div>
      )}
      {onCloseIconClick && (
        <div className={styles.closeIcon} onClick={onCloseIconClick}>
          <Icon name={IconVariant.CLOSE} />
        </div>
      )}
    </div>
  );
};

export default SubscribeSnack;
