import React from 'react';

import ClaimButtonExpand from './parts/ClaimButtonExpand';
import ClaimButtonNarrow from './parts/ClaimButtonNarrow';

interface IClaimDetectionButtonProps {
  id?: string;
  count?: number;
  isExpanded?: boolean;
  isLoading?: boolean;
  onToggleClaim?: () => void;
}

export const ClaimDetectionButton: React.FC<IClaimDetectionButtonProps> = ({
  id,
  isExpanded = false,
  isLoading = false,
  count,
  onToggleClaim,
}) => (
  <div id={id}>
    {isExpanded ? (
      <ClaimButtonExpand count={count} onToggleClaim={onToggleClaim} isLoading={isLoading} />
    ) : (
      <ClaimButtonNarrow isLoading={isLoading} count={count} onClick={onToggleClaim} />
    )}
  </div>
);

export default ClaimDetectionButton;
