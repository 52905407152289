import type React from 'react';
import { useEffect } from 'react';

import { LoadingPage } from '@web/component-library';
import { observer } from 'mobx-react-lite';

import { usePageTitle } from '../../../hooks/usePageTitle';
import { useAppState } from '../../../state';
import { useAppSessionContext } from '../../../state/sessionContext';

export const LogoutPage: React.FC = observer(() => {
  usePageTitle('Logout');
  const { appModel } = useAppState();
  const { signOutUser } = useAppSessionContext();

  useEffect(() => {
    if (appModel.isAuthenticated) {
      signOutUser();
    }
  }, [appModel.isAuthenticated, signOutUser]);

  return <LoadingPage />;
});

export default LogoutPage;
