import { Image } from 'quill';

const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

export class ImageFormat extends Image {
  static override formats(domNode: HTMLElement) {
    return ImageFormatAttributesList.reduce(
      (res, attribute) => {
        if (domNode.hasAttribute(attribute)) {
          res[attribute] = domNode.getAttribute(attribute);
        }

        return res;
      },
      {} as Record<string, string | null>,
    );
  }

  override format(name: string, value: string) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
