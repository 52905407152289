import React, { useCallback } from 'react';

import cx from 'classnames';

import { collectCookieUtmParams, resendConfirmationEmail } from '@writercolab/common-utils';
import { Logo, Text, TextColor, TextSize, useCustomSnackbar } from '@writercolab/ui-atoms';
import { EmailConfirmationBanner } from '@writercolab/ui-molecules';

import { snackbarMessages } from '@web/component-library';

import { useAppSessionContext } from '../../../state/sessionContext';

import styles from './styles.module.css';

interface EmailUnconfirmedPageProps {}

export const EmailUnconfirmedPage: React.FC<EmailUnconfirmedPageProps> = () => {
  const { signOutUser } = useAppSessionContext();

  const { enqueueBasicSnackbar, enqueueErrorSnackbar } = useCustomSnackbar();

  const _onResendButtonClick = useCallback(async () => {
    try {
      const utmParams = collectCookieUtmParams();
      await resendConfirmationEmail(utmParams);
      enqueueBasicSnackbar(snackbarMessages.user.resendConfirmationEmail);
    } catch {
      enqueueErrorSnackbar(snackbarMessages.user.resendConfirmationEmailError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.navigationHolder}>
        <Logo />
        <div className={cx(styles.signoutContainer, styles.clickable)}>
          <Text bold variant={TextSize.XL} color={TextColor.GREY2} onClick={() => signOutUser()}>
            Sign out
          </Text>
        </div>
      </div>
      <div className={styles.contentHolder}>
        <div className={styles.bannerContainer}>
          <EmailConfirmationBanner onResendButtonClick={_onResendButtonClick} />
        </div>
      </div>
    </div>
  );
};

export default EmailUnconfirmedPage;
