import React from 'react';

import cx from 'classnames';

import srcCookies from '@writercolab/assets/static/png/cookies.png';
import { openNewTab } from '@writercolab/common-utils';
import { Icon, IconVariant, LinkText, Text, TextSize } from '@writercolab/ui-atoms';

import { PRIVACY_PAGE_URL } from '../../../services/config/constants';

import styles from './CookieConsent.module.css';

interface CookieNotificationProps {
  className?: string;
  onClose?: () => void;
}

export const CookieConsent: React.FC<CookieNotificationProps> = ({ className, onClose }) => (
  <div className={cx(className, styles.container)}>
    <div className={styles.wrapper}>
      <img src={srcCookies} alt="Cookies" width={28} height={29} />
      <Text variant={TextSize.S}>
        By continuing to use this app, you agree to the use of cookies.{' '}
        <LinkText onClick={() => openNewTab(PRIVACY_PAGE_URL)}>More information</LinkText>
      </Text>
      <div className={cx(styles.closeButton, styles.clickable)} onClick={onClose}>
        <Icon name={IconVariant.CLOSE} height={17} width={17} />
      </div>
    </div>
  </div>
);
