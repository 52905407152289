export const MAX_SECTIONS_COUNT = 15;
export const DEFAULT_SECTIONS_COUNT = 5;
export const MIN_SECTIONS_COUNT = 2;

export enum SeoBlogSection {
  TITLE = 'title',
  OUTLINE = 'outline',
  KEYPOINTS = 'keypoints',
  DRAFTS = 'drafts',
}

export interface IOutlineSection {
  id: string;
  name: string;
  loading?: boolean;
  valid?: boolean;
}

export enum SeoBlogUseDraftAction {
  REPLACE = 'replace',
  ATTACH = 'attach',
}

interface IKeyPoint {
  id: string;
  name: string;
  valid?: boolean;
}

export interface IKeyPointsSection {
  id: string;
  name: string;
  keyPoints: IKeyPoint[];
  loading?: boolean;
}
