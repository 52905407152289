import { useEffect } from 'react';

import cx from 'classnames';

import { Button, Heading } from '@writercolab/fe.wds';
import {
  Icon,
  IconVariant,
  SkeletonLoader,
  SkeletonLoaderType,
  useQueueWorkerNotifications,
} from '@writercolab/ui-atoms';
import { FeaturedChatApp } from '@writercolab/ui-chat-apps';
import type { IFilterOptionsFilter } from '@writercolab/ui-molecules';
import { FilterOptions } from '@writercolab/ui-molecules';

import EllipsisTooltip from 'components/molecules/EllipsisTooltip/EllipsisTooltip';

import { ASK_WRITER_APP_ID, TMessageVariable } from '@web/types';
import { AnalyticsActivity } from 'constants/analytics';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';

import { appLocalStorage } from '../../../models/localStorage';
import { ROUTE } from '../../../services/config/routes';
import type { ChatAppHomeWidgetModelUi } from './ChatAppHomeWidgetModel.ui';

import styles from './ChatAppHomeWidget.module.css';

export interface IChatAppHomeWidgetProps {
  model: ChatAppHomeWidgetModelUi;
  onChangeChatApp: (appId: string) => void;
  onClickOpenPromptLibrary: () => void;
}

const renderAppOptionNode = (app: IFilterOptionsFilter) => (
  <div className={styles.appFilterOption}>
    <EllipsisTooltip text={app.name} textClassName={styles.appName} />
    {app.description && <EllipsisTooltip text={app.description} textClassName={cx(styles.appDescription, 'text-sm')} />}
  </div>
);

export const ChatAppHomeWidget = observer<IChatAppHomeWidgetProps>(
  ({ model, onChangeChatApp, onClickOpenPromptLibrary }) => {
    const navigate = useNavigate();

    const {
      featuredChatAppModel,
      chatWidgetInputModel,
      showPromptLibraryButton,
      showKnowledgeGraphModeButton,
      organizationId,
      teamId,
      selectedAppId,
      selectedApplicationName,
      chatApplicationModel,
      isOnlyOneApp,
      isNoAppsAvailable,
      isSubmitDisabled,
      disabledSubmitTooltipText,
      selectedApplication,
    } = model;

    const isApplicationLoading = chatApplicationModel?.isApplicationLoading;

    useQueueWorkerNotifications(model.chatApplicationTemplateUIModel?.notificationQueue);

    useEffect(() => {
      if (!chatWidgetInputModel?.editor) return;

      chatWidgetInputModel?.editor?.on('focus', () => {
        model.analyticsService.track(AnalyticsActivity.chatAppMessageBoxActivated, {
          app_id: selectedAppId,
          origin: 'featured_chat_app_widget',
        });
      });

      return () => {
        chatWidgetInputModel?.editor?.off('focus');
      };
    }, [chatWidgetInputModel?.editor, selectedAppId, model.analyticsService]);

    useEffect(() => {
      // Automatically switch to Ask Writer app if current app cannot be loaded
      // https://writerai.atlassian.net/browse/CORE-833
      if (chatApplicationModel?.isApplicationRejected) {
        onChangeChatApp(ASK_WRITER_APP_ID);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chatApplicationModel?.isApplicationRejected]);

    const handleSubmit = (prompt: string, variables: TMessageVariable[]) => {
      featuredChatAppModel?.createSessionAndAssociateSources().then(session => {
        if (session) {
          appLocalStorage.chatPagePromptAutoSubmit.set({
            submitPrompt: {
              prompt,
              variables,
            },
          });

          navigate(ROUTE.toChatApp(organizationId, teamId, selectedAppId, session.id));
        }
      });

      model.analyticsService.track(AnalyticsActivity.chatAppMessageSubmitted, {
        app_id: selectedAppId,
        origin: 'featured_chat_app_widget',
      });

      model.analyticsService.track(AnalyticsActivity.appOpened, {
        app_id: selectedAppId,
        origin: 'home',
        source: 'featured_chat_app_widget',
        app_name: selectedApplicationName,
        app_type: selectedApplication?.data?.type ?? '',
        built_by_writer: selectedApplication?.writer?.access === 'public',
      });
    };

    // We should hide KG mode button in case KG feature lock is disabled for organization
    const handleOpenKnowledgeGraphClick = showKnowledgeGraphModeButton
      ? () => {
          model.createKnowledgeGraphSession().then(result => {
            if (result) {
              const { session, inputState } = result;

              appLocalStorage.chatPagePromptAutoSubmit.set({ insertPrompt: inputState });

              navigate(ROUTE.toChatApp(organizationId, teamId, selectedAppId, session.id));
            }
          });
        }
      : undefined;

    const handleAppsFilterSearch = (value: string) => model.searchAppsPaginated(value);
    const handleAppsFilterSearchClear = () => model.searchAppsPaginated('');

    const handleChatAppChange = (option: IFilterOptionsFilter) => {
      model.analyticsService.track(AnalyticsActivity.featuredChatAppSelected, {
        old_app_id: selectedAppId,
        new_app_id: option.id,
        edit_flow: model.isAdminEdit ? 'team admin' : 'user',
      });

      if (model.isAdminEdit) {
        model.editData({ appId: option.id });
      } else {
        onChangeChatApp(option.id);
      }

      if (model.appsPaginatedSearch) {
        model.searchAppsPaginated('');
      }
    };

    const handleClickPromptLibraryButton = event => {
      event.currentTarget.blur();
      onClickOpenPromptLibrary();
    };

    if (isNoAppsAvailable) {
      return (
        <div className={styles.container}>
          <Heading size="h2" className={styles.noAppsHeading}>
            No available chat apps to display
          </Heading>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.selectedAppDropdownSection}>
            <FilterOptions
              isSearchable
              isSingleSelect
              isAutoClose
              isEnableInfiniteScroll
              menuWidth="330px"
              renderTrigger={({ onClick }) =>
                isApplicationLoading ? (
                  <SkeletonLoader className={styles.appNameSkeleton} type={SkeletonLoaderType.DEFAULT} />
                ) : (
                  <div
                    onClick={() => {
                      model.analyticsService.track(AnalyticsActivity.featuredChatAppSwitcherDropdownClicked, {
                        edit_flow: model.isAdminEdit ? 'team admin' : 'user',
                      });
                      onClick();
                    }}
                    className={cx(styles.selectedAppTrigger, {
                      [styles.selectionDisabled]: isOnlyOneApp,
                      [styles.withBackground]: model.isAdminEdit,
                    })}
                  >
                    <span className={styles.name}>{selectedApplicationName}</span>
                    <span className={styles.arrow}>
                      <Icon name={IconVariant.DROP_DOWN_ARROW} />
                    </span>
                  </div>
                )
              }
              renderOptionNode={renderAppOptionNode}
              onLoadMore={model.loadMoreAppsPaginated}
              isLoading={model.isAppsPaginatedLoading}
              hasNextPage={model.isAppsPaginatedHasNext}
              options={model.appsPaginated}
              onChange={handleChatAppChange}
              onSearch={handleAppsFilterSearch}
              onResetSearch={handleAppsFilterSearchClear}
            />
          </div>
        </div>

        {featuredChatAppModel && (
          <FeaturedChatApp
            className={styles.widget}
            model={featuredChatAppModel}
            onSubmit={handleSubmit}
            onOpenKnowledgeGraphClick={handleOpenKnowledgeGraphClick}
            isDisabledSubmit={isSubmitDisabled}
            submitButtonTooltipText={disabledSubmitTooltipText}
            isLoading={isApplicationLoading}
            allowVoiceCommands
            autoFocus
          />
        )}

        <div className={styles.footer}>
          {showPromptLibraryButton && (
            <Button size="sm" variant="outline" onClick={handleClickPromptLibraryButton}>
              Browse prompt library
            </Button>
          )}
        </div>
      </div>
    );
  },
);

ChatAppHomeWidget.displayName = 'ChatAppHomeWidget';
