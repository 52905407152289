import React from 'react';

import cx from 'classnames';

import { FontFace, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from '../styles.module.css';

interface IToolButtonCounterProps extends BaseComponentsProps {
  number: number;
}

export const ToolButtonCounter: React.FC<IToolButtonCounterProps> = ({ className, number }) => (
  <div className={cx(className, styles.containerToolButtonCounter)}>
    <Text variant={TextSize.XXXS} color={TextColor.WHITE} bold fontFace={FontFace.monospace}>
      {number}
    </Text>
  </div>
);

export default ToolButtonCounter;
