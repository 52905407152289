import { action, makeObservable, observable } from 'mobx';

import { ReactiveQueue } from '@writercolab/mobx';
import { MediaFilesApiModel } from '@writercolab/models';
import { RequestServiceInitialize } from '@writercolab/network';
import { isYoutubeUrl } from '@writercolab/text-utils';
import { IAbstractFile, MediaFileTabs, TMediaFileTabs, TNotificationQueueItem } from '@writercolab/types';
import {
  SelectExistingFileUIModel,
  UploadFileUIModel,
  UploadTranscriptUIModel,
  UploadUrlUIModel,
} from '@writercolab/ui-organisms';

import {
  MAX_FILE_SIZE,
  SUPPORTED_AUDIO_FILE_EXT,
  SUPPORTED_SUBTITLES_FILE_EXT,
  SUPPORTED_VIDEO_FILE_EXT,
} from '@web/types';
import { isVimeoUrl } from 'utils/mediaFilesUtils';

interface IRecapsFileUploaderModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId: number;
  extensionsOverride?: string[];
}

export class RecapsFileUploaderModel {
  url: UploadUrlUIModel;
  transcript: UploadTranscriptUIModel;
  file: UploadFileUIModel;
  existingFiles: SelectExistingFileUIModel;
  notificationQueue = new ReactiveQueue<TNotificationQueueItem>();
  minWords = 100;
  maxWords = 20000;
  maxFileSize = MAX_FILE_SIZE;
  extensions = [...SUPPORTED_AUDIO_FILE_EXT, ...SUPPORTED_VIDEO_FILE_EXT, ...SUPPORTED_SUBTITLES_FILE_EXT];

  constructor({ request, organizationId, teamId, extensionsOverride }: IRecapsFileUploaderModelOptions) {
    const mediaFilesApiModel = new MediaFilesApiModel({ request, organizationId, teamId, limit: 100 });

    this.transcript = new UploadTranscriptUIModel({
      notificationQueue: this.notificationQueue,
      uploadApiModel: mediaFilesApiModel,
      minWords: this.minWords,
      maxWords: this.maxWords,
    });

    this.file = new UploadFileUIModel({
      notificationQueue: this.notificationQueue,
      uploadApiModel: mediaFilesApiModel,
      maxSize: this.maxFileSize,
      extensions: extensionsOverride || this.extensions,
    });

    this.existingFiles = new SelectExistingFileUIModel(mediaFilesApiModel);
    this.url = new UploadUrlUIModel({
      notificationQueue: this.notificationQueue,
      uploadApiModel: mediaFilesApiModel,
      validationFn: url => (isYoutubeUrl(url || '') || isVimeoUrl(url || '') ? undefined : 'Invalid link'),
    });

    makeObservable(this, {
      uploadedFile: observable,
      activeTab: observable,
      setActiveTab: action.bound,
    });
  }

  uploadedFile: IAbstractFile | null = null;
  activeTab: TMediaFileTabs = MediaFileTabs.enum.URL;

  setActiveTab = (tab: TMediaFileTabs) => {
    this.activeTab = tab;
  };

  async prepareFileForUpload() {
    let uploadedFile: IAbstractFile | null = null;

    if (this.activeTab === MediaFileTabs.enum.URL) {
      uploadedFile = await this.url.uploadUrl();
      uploadedFile && this.url.clear();
    }

    if (this.activeTab === MediaFileTabs.enum.UPLOAD) {
      uploadedFile = this.file.uploadedFile;
      uploadedFile && this.file.clear();
    }

    if (this.activeTab === MediaFileTabs.enum.SELECT_EXISTING) {
      uploadedFile = this.existingFiles.selectedFile;
    }

    if (this.activeTab === MediaFileTabs.enum.PASTE_TRANSCRIPT) {
      uploadedFile = await this.transcript.uploadTranscript();
      uploadedFile && this.transcript.clear();
    }

    if (uploadedFile) {
      this.uploadedFile = uploadedFile;
    }
  }
}
