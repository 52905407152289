import type { components } from '@writercolab/network';
import { E_INTEGRATION_TYPE } from '@writercolab/types';
import type { TWebAnalyticsParams, IWebAppAnalyticsTrack, AnalyticEvents } from '../analytics';

export enum COMMANDS_SCENARIOS {
  FROM_DOC_SELECTION = 'from_doc_selection',
  FOLLOW_UP_MENU = 'follow_up_menu',
  DOC_HAS_CONTENT = 'doc_has_content',
  DOC_HAS_NO_CONTENT = 'doc_has_no_content',
}

export const getCommandsScenario = (
  contentLength: number,
  isAfterSelection?: boolean,
  isFollowup?: boolean,
): COMMANDS_SCENARIOS => {
  if (isAfterSelection) {
    return COMMANDS_SCENARIOS.FROM_DOC_SELECTION;
  } else if (isFollowup) {
    return COMMANDS_SCENARIOS.FOLLOW_UP_MENU;
  } else if (contentLength > 1) {
    return COMMANDS_SCENARIOS.DOC_HAS_CONTENT;
  } else {
    return COMMANDS_SCENARIOS.DOC_HAS_NO_CONTENT;
  }
};

export const trackCommandsActivity = ({
  analytics,
  teamId,
  eventName,
  scenario,
  commandName,
}: {
  analytics: IWebAppAnalyticsTrack<TWebAnalyticsParams>;
  teamId: string;
  eventName: AnalyticEvents;
  scenario: COMMANDS_SCENARIOS;
  commandName?: string;
}) => {
  const properties = {
    integration_type: E_INTEGRATION_TYPE.enum.DEFAULT,
    teamId,
    scenario,
    ...(commandName ? { command_name: commandName } : {}),
  };

   
  analytics.track(eventName, properties as any); // TODO: we need refactoring here
};

export const removeModifyTonePrefix = (
  prompts: components['schemas']['template_dto_ReWritePromptResponse'][],
  prefixSeparator: string,
): components['schemas']['template_dto_ReWritePromptResponse'][] =>
  prompts.map(prompt => {
    if (prompt.name.includes(prefixSeparator)) {
      return {
        ...prompt,
        name: prompt.name.split(prefixSeparator)[1],
      };
    } else {
      return prompt;
    }
  });

export const generateKnowledgeGraphSourceUrl = (root: string, ordId: string, teamId: string, fileId: string) =>
  `${root}organization/${ordId}/team/${teamId}/styleguide/suggestions/knowledge?fileId=${fileId}`;

export const getErrorMessageFromResponse = <
  T extends {
    errors?: {
      description?: string;
    }[];
  },
>(
  response: T,
) => response?.errors?.[0]?.description || '';
