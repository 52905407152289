import { useMemo, useRef } from 'react';

import { ApplicationsApiModel } from '@writercolab/models';
import { Button, ButtonTypes, Icon, IconVariant, Logo, LogoVariant, SizeTypes, Tooltip } from '@writercolab/ui-atoms';

import { LoadingPage } from '@web/component-library';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router';
import { ROUTE } from 'services/config/routes';
import requestService from 'services/request/requestService';
import { useAppState } from 'state';

import { FrameworkApplicationPageUIModel } from './FrameworkApplicationPageModel.ui';

import styles from './FrameworkApplicationPage.module.css';

export const FrameworkApplicationPage = observer(() => {
  const { appModel } = useAppState();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const params = useParams<{ applicationId: string }>();
  const { organizationId, teamId } = appModel;
  const navigate = useNavigate();

  const model = useMemo(() => {
    if (!organizationId || !params.applicationId || !teamId) {
      return undefined;
    }

    return new FrameworkApplicationPageUIModel({
      applicationApi: new ApplicationsApiModel({
        request: requestService.api,
        organizationId,
      }),
      teamId,
      applicationIdOrAlias: params.applicationId,
    });
  }, [organizationId, teamId, params.applicationId]);

  const onBackButtonClick = () => {
    navigate(ROUTE.toHome(organizationId, teamId));
  };

  if (!model?.deploymentUrl) {
    return <LoadingPage />;
  }

  const handleInitIframe = async () => {
    if (organizationId && teamId && params.applicationId) {
      const {
        data: { token },
      } = await requestService.api.post(
        '/api/template/organization/{organizationId}/team/{teamId}/application/{applicationId}/framework/access',
        // @ts-ignore
        {
          params: {
            path: {
              organizationId: organizationId!,
              teamId: teamId!,
              applicationId: params.applicationId!,
            },
          },
        },
      );

      iframeRef.current?.contentWindow?.postMessage({ type: 'auth', token }, '*');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.appHeader}>
        <Button
          className={styles.backButton}
          type={ButtonTypes.TRANSPARENT}
          icon={<Icon name={IconVariant.BACK_ARROW} />}
          size={SizeTypes.SMALL}
          onClick={onBackButtonClick}
          round
        />
        <Tooltip title="Dashboard" placement="right">
          <Logo
            variant={LogoVariant.BLACK}
            className={styles.appLogo}
            onClick={onBackButtonClick}
            navTo={ROUTE.toHome(organizationId, teamId)}
          />
        </Tooltip>
      </div>
      <div className={styles.iframeContainer}>
        <iframe ref={iframeRef} title={model.name} src={model.deploymentUrl} onLoad={handleInitIframe}></iframe>
      </div>
    </div>
  );
});

FrameworkApplicationPage.displayName = 'FrameworkApplicationPage';
