import { Request } from '@writercolab/common-utils';

export const generateRecaps = async (
  organizationId: string,
  teamId: string,
  documentId: string,
  templateId: string,
  inputs: { name: string; value: any[] }[],
): Promise<any[]> => {
  const { data } = await Request.getAxiosInstance().post(
    `/template/organization/${organizationId}/team/${teamId}/document/${documentId}/cowrite/takeaways`,
    {
      templateId,
      inputs,
    },
  );

  return data;
};
