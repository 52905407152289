import { SuggestionCategoryType } from '@writercolab/common-utils';
import { StructureSection, StructureSectionType } from '@writercolab/ui-molecules';

const structure = [
  {
    name: 'Data loss prevention',
    type: SuggestionCategoryType.DATA_LOSS_PREVENTION,
    structure: [
      {
        type: StructureSectionType.GROUP,
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Ethnic group',
            ruleId: 'ETHNIC_GROUP',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Date',
            ruleId: 'DATE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Time',
            ruleId: 'TIME',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Person name',
            ruleId: 'PERSON_NAME',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Age of an individual',
            ruleId: 'AGE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'All basic info types',
            ruleId: 'ALL_BASIC',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Australia medicare number',
            ruleId: 'AUSTRALIA_MEDICARE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Australia tax file number',
            ruleId: 'AUSTRALIA_TAX_FILE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Ontario health insurance number',
            ruleId: 'CANADA_OHIP',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Social security number',
            ruleId: 'US_SOCIAL_SECURITY_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: "Driver's license number",
            ruleId: 'US_DRIVERS_LICENSE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Drug adminstration enforcement(DEA) number',
            ruleId: 'US_DEA_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'ABA routing number',
            ruleId: 'US_BANK_ROUTING_MICR',
          },
          {
            type: StructureSectionType.RULE,
            description: 'National provider identifier(NPI)',
            ruleId: 'US_HEALTHCARE_NPI',
          },
          {
            type: StructureSectionType.RULE,
            description: 'American bankers CUSIP identifier',
            ruleId: 'AMERICAN_BANKERS_CUSIP_ID',
          },
          {
            type: StructureSectionType.RULE,
            description: 'FDA approved prescription drugs',
            ruleId: 'FDA_CODE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'US_PASSPORT',
          },
        ],
      },
    ],
  },
  {
    name: 'Global compliance detection',
    type: SuggestionCategoryType.GLOBAL_COMPLIANCE,
    structure: [
      {
        type: StructureSectionType.GROUP,
        heading: 'United States of America',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Social Security Number',
            ruleId: 'US_SOCIAL_SECURITY_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: "Driver's License Number",
            ruleId: 'US_DRIVERS_LICENSE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Drug Adminstration Enforcement (DEA) Number',
            ruleId: 'US_DEA_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'ABA Routing Number',
            ruleId: 'US_BANK_ROUTING_MICR',
          },
          {
            type: StructureSectionType.RULE,
            description: 'The US National Provider Identifier (NPI)',
            ruleId: 'US_HEALTHCARE_NPI',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'US_PASSPORT',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Taxpayer Identification Number',
            ruleId: 'US_INDIVIDUAL_TAXPAYER_IDENTIFICATION_NUMBER',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'United Kingdom',
        items: [
          {
            type: StructureSectionType.RULE,
            description: "Driver's License Number",
            ruleId: 'UK_DRIVERS_LICENSE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'National Health Service (NHS) Number',
            ruleId: 'UK_NATIONAL_HEALTH_SERVICE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'National Insurance Number (NINO)',
            ruleId: 'UK_NATIONAL_INSURANCE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'UK_PASSPORT',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Taxpayer Identification Number',
            ruleId: 'UK_TAXPAYER_REFERENCE',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Australia',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Medicare Account Number',
            ruleId: 'AUSTRALIA_MEDICARE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'An Australian passport number',
            ruleId: 'AUSTRALIA_PASSPORT',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Tax File Number (TFN)',
            ruleId: 'AUSTRALIA_TAX_FILE_NUMBER',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Brazil',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'CPF number',
            ruleId: 'BRAZIL_CPF_NUMBER',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Canada',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Quebec Health Insurance Number (HIN)',
            ruleId: 'CANADA_QUEBEC_HIN',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Ontario Health Insurance Plan (OHIP)',
            ruleId: 'CANADA_OHIP',
          },
          {
            type: StructureSectionType.RULE,
            description: 'British Columbia Personal Health Number (PHN)',
            ruleId: 'CANADA_BC_PHN',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Social Insurance Number (SIN)',
            ruleId: 'CANADA_SOCIAL_INSURANCE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'CANADA_PASSPORT',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'China',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'CHINA_PASSPORT',
          },
          {
            type: StructureSectionType.RULE,
            description: 'A Chinese resident identification number',
            ruleId: 'CHINA_RESIDENT_ID_NUMBER',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'France',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'National ID Card (CNI)',
            ruleId: 'FRANCE_CNI',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Social Security Number (NIR)',
            ruleId: 'FRANCE_NIR',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'FRANCE_PASSPORT',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Germany',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'GERMANY_PASSPORT',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'India',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Personal Permanent Account Number (PAN)',
            ruleId: 'INDIA_PAN_INDIVIDUAL',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Japan',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'JAPAN_PASSPORT',
          },
          {
            type: StructureSectionType.RULE,
            description: 'National ID number',
            ruleId: 'JAPAN_INDIVIDUAL_NUMBER',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Korea',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'KOREA_PASSPORT',
          },
          {
            type: StructureSectionType.RULE,
            description: 'South Korean Social Security Number',
            ruleId: 'KOREA_RRN',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Mexico',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'National Identification Number (CURP)',
            ruleId: 'MEXICO_CURP_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'MEXICO_PASSPORT',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Netherlands',
        items: [
          {
            type: StructureSectionType.RULE,
            description: "Dutch Burgerservicenummer (BSN), or Citizen's Service Number",
            ruleId: 'NETHERLANDS_BSN_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'A Dutch passport number',
            ruleId: 'NETHERLANDS_PASSPORT',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Spain',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'The Spanish Número de Identificación Fiscal (NIF) ',
            ruleId: 'SPAIN_NIF_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'The Spanish Número de Identificación de Extranjeros (NIE)',
            ruleId: 'SPAIN_NIE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Passport',
            ruleId: 'SPAIN_PASSPORT',
          },
        ],
      },
      {
        type: StructureSectionType.GROUP,
        heading: 'Global',
        items: [
          {
            type: StructureSectionType.RULE,
            description: 'Credit card number',
            ruleId: 'CREDIT_CARD_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Bank account number (IBAN)',
            ruleId: 'IBAN_CODE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Bank account number (SWIFT)',
            ruleId: 'SWIFT_CODE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'ICD 9-CM Lexicon',
            ruleId: 'ICD9_CODE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'ICD 10-CM Lexicon',
            ruleId: 'ICD10_CODE',
          },
          {
            type: StructureSectionType.RULE,
            description: 'International Mobile Equipment Identity (IMEI)',
            ruleId: 'IMEI_HARDWARE_ID',
          },
          {
            type: StructureSectionType.RULE,
            description: 'IP address (IP v4 only)',
            ruleId: 'IP_ADDRESS',
          },
          {
            type: StructureSectionType.RULE,
            description: 'MAC Address',
            ruleId: 'MAC_ADDRESS',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Phone Numbers',
            ruleId: 'PHONE_NUMBER',
          },
          {
            type: StructureSectionType.RULE,
            description: 'Email address',
            ruleId: 'EMAIL_ADDRESS',
          },
        ],
      },
    ],
  },
];

export default structure as StructureSection[];
