export enum StripeFormElements {
  CARD_NUMBER = 'CardNumber',
  CARD_EXPIRY = 'CardExpiry',
  CARD_CVC = 'CardCvc',
}

export const stripeElementsFont = [{ cssSrc: 'https://fonts.googleapis.com/css?family=Poppins' }];

export const formatStripePaymentMethodYear = (y: number | string) => `${y}`.substring(2);
export const formatStripePaymentMethodMonth = (m: number | string) => (Number(m) < 10 ? '0' : '') + m;
