import {
  action,
  comparer,
  computed,
  makeObservable,
  observable,
  onBecomeUnobserved,
  reaction,
  runInAction,
} from 'mobx';

import type { IIssue } from '@writercolab/common-utils';
import { IssueCategory, IssueFlag } from '@writercolab/common-utils';
import { ReactiveQueue, createAtomSubscriber } from '@writercolab/mobx';
import { getShiftFromDelta, shiftAllIssuesOffsets } from '@writercolab/quill-delta-utils';
import type { TNotificationQueueItem } from '@writercolab/types';
import { NotificationQueueItemType } from '@writercolab/types';
import type { SidebarModel } from '@writercolab/ui-sidebar-models';

import type { IEditorForwardRef } from '@web/web-quill-editor';
import Delta from 'quill-delta';

interface IContentEditorModelOpts {
  sidebarModel: SidebarModel;
}
export class ContentEditorModel {
  deltaBuffer: Delta = new Delta();
  isClaimsMode = false;
  notificationQueue = new ReactiveQueue<TNotificationQueueItem>();

  constructor(private opts: IContentEditorModelOpts) {
    makeObservable(this, {
      isClaimsMode: observable,
      claimsCount: computed,
      currentIssues: computed,
      visibleIssues: computed,
      notificationQueue: observable,
    });
  }

  get visibleIssues() {
    let currentIssues = [] as IIssue[];

    if (this.isClaimsMode) {
      currentIssues = this.opts.sidebarModel.issues?.list.filter(ContentEditorModel.isClaimIssue) || [];
    } else {
      currentIssues =
        this.opts.sidebarModel.issues?.currentIssues.filter(issue => !ContentEditorModel.isClaimIssue(issue)) || [];
    }

    if (!this.deltaBufferIsEmpty) {
      const shiftFromDelta = this.deltaBuffer && getShiftFromDelta(this.deltaBuffer);

      currentIssues =
        shiftFromDelta && shiftFromDelta?.shift !== 0
          ? shiftAllIssuesOffsets(currentIssues, shiftFromDelta)
          : currentIssues;
    }

    return currentIssues;
  }

  get claimsCount() {
    return this.isClaimsMode ? this.visibleIssues.length : 0;
  }

  public useContentEditor(
    editorRef: Pick<IEditorForwardRef, 'replaceSuggestion' | 'focusSuggestionHighlight'> | null,
    isClaimsMode: boolean,
    onOpenSidebar: () => void,
  ) {
    onBecomeUnobserved(this.atom, () => {
      this.editorRef = null;
      this.onOpenSidebar = undefined;
    });

    if (this.atom.reportObserved()) {
      this.editorRef = editorRef;
      this.onOpenSidebar = onOpenSidebar;
    }

    if (this.isClaimsMode !== isClaimsMode) {
      // move setup isClaimsMode from the render lifecicle
      Promise.resolve().then(() => {
        runInAction(() => {
          this.isClaimsMode = isClaimsMode;
        });
      });
    }
  }

  private readonly atom = createAtomSubscriber('atom', () => {
    const { eventBus } = this.opts.sidebarModel;

    const cancel = [
      eventBus.on('onApplySuggestionCallback', (replacement: string, aIssue: IIssue) => {
        const issue = this.opts.sidebarModel.issues?.allIssues.find(issue => issue.issueId === aIssue.issueId);

        issue && this.editorRef?.replaceSuggestion(issue, replacement);
      }),
      eventBus.on('onSidebarModeChange', () => {
        this.onOpenSidebar?.();
      }),
      eventBus.on('onFlagSuggestionCallback', (_: IIssue, state: IssueFlag) => {
        if (state === IssueFlag.WRONG) {
          this.notificationQueue.enqueue({
            type: NotificationQueueItemType.enum.success,
            message: 'Thanks! Your feedback has been submitted.',
          });
        }
      }),
      reaction(
        () => this.opts.sidebarModel.issues?.selectedIssueContext,
        selectedIssueContext => {
          // double check that issue really exists and visible
          const issue = selectedIssueContext?.issueId ? this.findIssueById(selectedIssueContext?.issueId) : undefined;

          if (issue) {
            this.editorRef?.focusSuggestionHighlight(
              issue,
              ContentEditorModel.isClaimIssue(issue) && selectedIssueContext?.source !== 'editor',
            );
          }
        },
        {
          delay: 100, // avoid quick changes
          equals: comparer.structural,
        },
      ),
    ];

    return () => cancel.forEach(cb => cb());
  });

  private editorRef: Pick<IEditorForwardRef, 'replaceSuggestion' | 'focusSuggestionHighlight'> | null = null;
  private onOpenSidebar?: () => void;

  get deltaBufferIsEmpty() {
    return !this.deltaBuffer?.ops?.length;
  }

  get currentIssues() {
    return this.opts.sidebarModel.issues?.currentIssues.sort((a, b) => a.from - b.from);
  }

  readonly setDeltaBuffer = action((deltaBuffer: Delta) => {
    this.deltaBuffer = deltaBuffer;
  });

  findIssueById = (issueId: string) => this.visibleIssues.find(issue => issue.issueId === issueId);

  static isClaimIssue = (issue: IIssue) => [IssueCategory.Claim, IssueCategory.Quote].includes(issue.category);
}
