import type { RequestServiceInitialize, components } from '@writercolab/network';
import { STATIC_VOICE_OPTIONS, type TApplicationBriefPaginatedTemplateResponse } from '@writercolab/types';

import type { TGetDeployedAppsParams } from '@web/types';
import { getLogger } from 'utils/logger';

const LOG = getLogger('ApplicationApiModel');

interface IApplicationPreviewOptions {
  request: RequestServiceInitialize['api'];
  organizationId: number;
  teamId?: () => number | undefined;
}

export class ApplicationApiModel {
  constructor(private opts: IApplicationPreviewOptions) {
    this.opts = opts;
  }

  generate = async ({
    applicationId,
    inputs,
    voiceId,
    reWritePromptId,
    outputNames,
  }: {
    applicationId: string;
    inputs: components['schemas']['template_model_MagicRequestInput'][];
    voiceId?: string;
    reWritePromptId?: string;
    outputNames?: [string, ...string[]];
  }) => {
    return this.opts.request
      .post('/api/template/organization/{organizationId}/application/{applicationId}/generate', {
        params: {
          path: {
            organizationId: this.opts.organizationId,
            applicationId,
          },
        },
        body: {
          inputs,
          voiceId: voiceId === STATIC_VOICE_OPTIONS.DEFAULT ? null : voiceId,
          reWritePromptId,
          outputNames,
        },
      })
      .then(res => res.data)
      .catch(err => {
        LOG.error(err);

        throw err;
      });
  };

  getDeployedApps = async (params: TGetDeployedAppsParams): Promise<TApplicationBriefPaginatedTemplateResponse> => {
    if (!this.opts.teamId) {
      throw new Error('TeamId is required for getDeployedApps');
    }

    try {
      const { data } = await this.opts.request.get(
        '/api/template/organization/{organizationId}/team/{teamId}/application/writer-deployed',
        {
          params: {
            path: {
              organizationId: this.opts.organizationId,
              teamId: Number(this.opts.teamId()),
            },
            query: params,
          },
        },
      );

      return data;
    } catch (e) {
      LOG.error('Failed to get deployed apps', e);
      throw e;
    }
  };
}
