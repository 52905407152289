import React from 'react';

import cx from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import { Icon, IconVariant, Text, TextSize, Tooltip } from '@writercolab/ui-atoms';

import styles from './LearningCenterNavSection.module.css';

interface ILearningCenterNavSectionProps extends BaseComponentsProps {
  onClick: () => void;
  videosLeftToWatch?: number;
}

export const LearningCenterNavSection: React.FC<ILearningCenterNavSectionProps> = ({
  className,
  onClick,
  videosLeftToWatch = 0,
}) => (
  <Tooltip
    disabled={!videosLeftToWatch}
    placement="top"
    title={`${videosLeftToWatch} ${wordPluralize(videosLeftToWatch, 'video')} to watch`}
    tooltipWrapperClassname={styles.learningCenterTooltipTop}
    arrowStyles={styles.learningCenterTooltipTopArrow}
  >
    <div className={cx(styles.learningCenter, className)} onClick={onClick}>
      <Icon
        className={cx(styles.learningCenterIcon, {
          [styles.learningCenterIconWithIndicator]: videosLeftToWatch,
        })}
        name={IconVariant.LIGHT_BULB}
        width={18}
        height={18}
      />
      <Text variant={TextSize.XXL}>Learning center</Text>
    </div>
  </Tooltip>
);
