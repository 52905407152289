import React from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';

import { stripeElementsFont } from '../../../../../utils/stripeUtils';
import PaymentMethod, { PaymentMethodProps } from './index';

interface PaymentMethodWrapperProps extends PaymentMethodProps {
  stripePromise: Promise<Stripe | null>;
  organizationId?: number;
}

export const PaymentMethodWrapper: React.FC<PaymentMethodWrapperProps> = ({
  stripePromise,
  paymentMethod,
  onCancel,
  onSubmitSuccess,
  extended,
  inlineTriggers,
  submitTrigger,
  cancelTrigger,
  isEditButtonShown,
  onEditButtonClick,
  isPlanPastDue,
  organizationId,
}) => (
  <Elements stripe={stripePromise} options={{ fonts: stripeElementsFont }}>
    <PaymentMethod
      extended={!isEditButtonShown && extended}
      {...{ paymentMethod, onCancel, onSubmitSuccess, isEditButtonShown, onEditButtonClick, isPlanPastDue }}
      inlineTriggers={inlineTriggers}
      cancelTrigger={cancelTrigger}
      submitTrigger={submitTrigger}
      organizationId={organizationId}
    />
  </Elements>
);

export default PaymentMethodWrapper;
