import React from 'react';

import { wordPluralize } from '@writercolab/common-utils';
import type { FieldModel } from '@writercolab/mobx';
import { Button, ButtonTypes, Label, Modal, SizeTypes, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import { observer } from 'mobx-react-lite';

import { InputField } from '../InputField/InputField';
import { TextareaField } from '../TextareaField/TextareaField';

import styles from './RenameKnowledgeGraphModal.module.css';

interface IRenameKnowledgeGraphModalProps {
  name: FieldModel<string>;
  description: FieldModel<string>;
  descriptionMaxLength: number;
  onClickClose: () => void;
  onClickSubmit: () => void;
}

export const RenameKnowledgeGraphModal = observer<IRenameKnowledgeGraphModalProps>(
  ({ name, description, descriptionMaxLength, onClickClose, onClickSubmit }) => (
    <Modal open title="Edit graph" className={styles.modal} handleClose={onClickClose}>
      <div className={styles.container}>
        <InputField className={styles.inputName} field={name} placeholder="Enter a name for your Graph">
          <Label>Name*</Label>
        </InputField>

        <TextareaField
          className={styles.inputDescription}
          field={description}
          placeholder="Describe the data in this Graph. Users will see this when deciding which Graph to enable.&#10;e.g., Sales enablement materials for Acme Enterprise, including case studies and battle cards"
        >
          <Label>Description</Label>
        </TextareaField>

        <Text className={styles.inputDescriptionCounter} variant={TextSize.XS} color={TextColor.GREY3} upperCase>
          {description.value?.length}/{descriptionMaxLength} {wordPluralize(description.value?.length || 0, 'char')}
        </Text>

        <div className={styles.controls}>
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            size={SizeTypes.MEDIUM}
            content="Update"
            onClick={onClickSubmit}
          />
        </div>
      </div>
    </Modal>
  ),
);
