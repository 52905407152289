import type React from 'react';
import { Suspense } from 'react';

import './styles';

import { IntegrationType, Request } from '@writercolab/common-utils';
import { SnackbarsProvider } from '@writercolab/ui-atoms';

import AppsAccessPage from './components/pages/AppsAccessPage';
import AuthRedirectPage from './components/pages/AuthRedirectPage';
import LogoutPage from './components/pages/LogoutPage';
import { NewUserActionRedirectPage } from './components/pages/NewUserActionRedirectPage';
import OrgDeleteConfirmationPage from './components/pages/OrgDeleteConfirmationPage';
import PrivateRoute from './components/pages/PrivateRoute';
import RedirectPage from './components/pages/RedirectPage';
import { ErrorBoundary, ErrorRouteComponent } from './components/templates/ErrorBoundary';
import { AsyncComponent } from 'components/generic/AsyncComponent';
import { KnowledgeGraphConnectorRedirectPage } from 'components/pages/KnowledgeGraphConnectorRedirectPage';

import { LoadingPage } from '@web/component-library';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router';

import App from './App';
import { REACT_RELATIVE_ROUTE, SHORTCUT_PORTAL_ROUTES, SHORTCUT_ROUTES } from './services/config/routes';
import { AppStateProvider } from './state';
import AppSessionProvider from './state/sessionContext';
import config from './utils/dynamicConfig';
import { getLogger } from './utils/logger';

const { VITE_VERSION } = import.meta.env;
getLogger('App').info(`Version ${import.meta.env.VITE_VERSION}`);

Request.getAxiosInstance().defaults.headers.common['x-client-ver'] = VITE_VERSION!;
Request.getInstance().setIntegrationType(IntegrationType.DEFAULT);
Request.getAxiosInstance().defaults.baseURL = config.API_URL;
Request.getAxiosInstance().defaults.withCredentials = true;

const AuthenticatedRoutes: React.FC = () => (
  <AppStateProvider>
    <AppSessionProvider>
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path={REACT_RELATIVE_ROUTE.emailDeleteAccountConfirmation} element={<OrgDeleteConfirmationPage />} />
          <Route path={REACT_RELATIVE_ROUTE.logout} element={<LogoutPage />} />
          <Route path={REACT_RELATIVE_ROUTE.authRedirect} element={<AuthRedirectPage />} />
          <Route path={REACT_RELATIVE_ROUTE.newUserActionRedirect} element={<NewUserActionRedirectPage />} />
          <Route path={REACT_RELATIVE_ROUTE.authApp} element={<AppsAccessPage />} />
          {Object.keys({ ...SHORTCUT_ROUTES, ...SHORTCUT_PORTAL_ROUTES }).map((path, index) => (
            <Route path={path} element={<RedirectPage />} key={index} />
          ))}
          <Route
            path={`${REACT_RELATIVE_ROUTE.aiStudioRoot}/*`}
            element={
              <PrivateRoute>
                <AsyncComponent component={() => import('AiStudio/AiStudio')} />
              </PrivateRoute>
            }
            errorElement={<ErrorRouteComponent />}
          />
          <Route
            path={`${REACT_RELATIVE_ROUTE.report}/*`}
            element={
              <PrivateRoute>
                <AsyncComponent component={() => import('./components/pages/ReportPage/ReportPage')} />
              </PrivateRoute>
            }
            errorElement={<ErrorRouteComponent />}
          />
          <Route
            path={REACT_RELATIVE_ROUTE.wildcard}
            element={
              <PrivateRoute>
                <App />
              </PrivateRoute>
            }
            errorElement={<ErrorRouteComponent />}
          />
        </Routes>
      </Suspense>
    </AppSessionProvider>
  </AppStateProvider>
);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <SnackbarsProvider>
      <div>
        <BrowserRouter>
          <Suspense fallback={<LoadingPage />}>
            <Routes>
              <Route
                path={REACT_RELATIVE_ROUTE.knowledgeGraphConnectorRedirect}
                element={<KnowledgeGraphConnectorRedirectPage />}
              />
              <Route path={REACT_RELATIVE_ROUTE.wildcard} element={<AuthenticatedRoutes />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </div>
    </SnackbarsProvider>
  </ErrorBoundary>,
);
