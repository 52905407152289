import type React from 'react';
import { useMemo } from 'react';

import cx from 'classnames';

import { IconsCommonAppsGrid, IconsCommonPlusCircled } from '@writercolab/assets';
import { Button, TabsList, Tabs as TabsRoot, TabsTrigger } from '@writercolab/fe.wds';
import { Icon, IconVariant } from '@writercolab/ui-atoms';

import EllipsisTooltip from 'components/molecules/EllipsisTooltip/EllipsisTooltip';

import { RecentWorkWidgetTabId } from '@web/types';
import { observer } from 'mobx-react-lite';
import Skeleton from 'react-loading-skeleton';
import { EMPTY_DOCUMENT_PLACEHOLDER, EMPTY_SESSION_PLACEHOLDER } from 'services/config/constants';

import type { RecentWorkHomeWidgetModelUi } from './RecentWorkHomeWidgetModel.ui';

import styles from './RecentWorkHomeWidget.module.css';

interface RecentWorkWidgetProps {
  model: RecentWorkHomeWidgetModelUi;
  onOpenDocument: (documentId: string) => void;
  onOpenSession: (appId: string, sessionId: string) => void;
  onStartNewSession: () => void;
  onStartNewDocument: () => void;
  onOpenAllApps: () => void;
}

const ListRow: React.FC<{
  italic?: boolean;
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
}> = ({ italic, icon, title, onClick }) => (
  <div className={cx(styles.row, styles.clickable)} onClick={onClick}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.title}>
      <EllipsisTooltip
        text={title}
        textClassName={cx(styles.titleContent, { [styles.italic]: italic })}
        tooltipClassName="z-100"
      />
    </div>
  </div>
);

const DocsList: React.FC<RecentWorkWidgetProps> = observer(props => {
  const { model, onOpenDocument } = props;

  if (model.documents.value?.length === 0) {
    return <DocsEmptyState {...props} />;
  }

  return (
    <div className={styles.listContainer}>
      {model.documents.value?.map(document => (
        <ListRow
          key={document.id}
          italic={!document.title}
          onClick={() => document.id && onOpenDocument(document.id.toString())}
          title={document.title || EMPTY_DOCUMENT_PLACEHOLDER}
          icon={<Icon name={IconVariant.DOCUMENT} width={18} height={18} />}
        />
      ))}
    </div>
  );
});

const SessionsList: React.FC<RecentWorkWidgetProps> = observer(props => {
  const { model, onOpenSession } = props;

  if (model.sessions.value?.length === 0) {
    return <SessionsEmptyState {...props} />;
  }

  return (
    <div className={styles.listContainer}>
      {model.sessions.value?.map(session => (
        <ListRow
          key={session.id}
          italic={!session.title}
          onClick={() => onOpenSession(session.applicationId, session.id)}
          title={session.title || EMPTY_SESSION_PLACEHOLDER}
          icon={<Icon name={IconVariant.CRYSTAL_BALL} width={23} height={23} />}
        />
      ))}
    </div>
  );
});

const SessionsEmptyState: React.FC<RecentWorkWidgetProps> = ({ onStartNewSession }) => (
  <div className={styles.sessionsEmptyState}>
    <p className={cx(styles.sessionsEmptyStateText, 'text-base')}>
      No recent sessions. Start a new session to ask Writer anything
    </p>
    <Button onClick={onStartNewSession} leftIcon={<IconsCommonPlusCircled />}>
      New session
    </Button>
  </div>
);

const DocsEmptyState: React.FC<RecentWorkWidgetProps> = ({ onStartNewDocument, onOpenAllApps }) => (
  <div className={styles.docsEmptyState}>
    <p className={cx(styles.docsEmptyStateText, 'text-base')}>
      No docs found. Try out Writer apps or start writing a new doc
    </p>
    <div className={styles.docsEmptyStateButtons}>
      <Button onClick={onOpenAllApps} leftIcon={<IconsCommonAppsGrid />} variant="secondary">
        App library
      </Button>
      <Button onClick={onStartNewDocument} leftIcon={<IconsCommonPlusCircled />}>
        New doc
      </Button>
    </div>
  </div>
);

const LoadingState: React.FC = () => (
  <div className={styles.listContainer}>
    <Skeleton count={5} height={20} style={{ marginBottom: '32px' }} />
  </div>
);

export const RecentWorkHomeWidget = observer<RecentWorkWidgetProps>(props => {
  const tabs = useMemo(() => {
    if (props.model.hasSessionAccess) {
      return [
        { id: RecentWorkWidgetTabId.enum.sessions, title: 'Sessions' },
        { id: RecentWorkWidgetTabId.enum.docs, title: 'Docs' },
      ];
    }

    return [{ id: RecentWorkWidgetTabId.enum.docs, title: 'Docs' }];
  }, [props.model.hasSessionAccess]);

  const content = RecentWorkWidgetTabId.match(
    props.model.activeTab,
    {
      [RecentWorkWidgetTabId.enum.sessions]: () => <SessionsList {...props} />,
      [RecentWorkWidgetTabId.enum.docs]: () => <DocsList {...props} />,
    },
    null,
  );

  return (
    <div className={styles.container}>
      <TabsRoot
        variant="pill"
        size="sm"
        value={props.model.activeTab}
        onValueChange={tab => {
          props.model.setActiveTab(RecentWorkWidgetTabId.get(tab, RecentWorkWidgetTabId.enum.docs));
        }}
      >
        <TabsList>
          {tabs.map(tab => (
            <TabsTrigger key={tab.id} value={tab.id} className="text-sm">
              {tab.title}
            </TabsTrigger>
          ))}
        </TabsList>
      </TabsRoot>
      {props.model.isLoading ? <LoadingState /> : content}
    </div>
  );
});

RecentWorkHomeWidget.displayName = 'RecentWorkHomeWidget';
