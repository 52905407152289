import React, { FC, useState } from 'react';

import cx from 'classnames';

import { Icon, IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import Select, {
  ActionMeta,
  ControlProps,
  MenuListProps,
  MenuProps,
  OptionProps,
  Options,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
  components,
} from 'react-select';

import styles from './TagSelect.module.css';

export interface IOption {
  value: string | number;
  label: string;
}

interface TagSelectProps {
  className?: string;
  options: Options<IOption>;
  defaultValue?: IOption | null;
  placeholder?: string;
  noOptionsMessage?: string;
  onChange?: (option: IOption | null, actionMeta?: ActionMeta<IOption>) => void;
  required?: boolean;
  error?: boolean;
  isMulti?: boolean;
  containerStyles?: React.CSSProperties;
}

const Tag = ({ text }: { text: string }) => {
  return <div className={styles.tagWrapper}>{text}</div>;
};

const DefaultStateComponent = ({ onClick, isEnable }: { onClick: () => void; isEnable: boolean }) => {
  return (
    <div className={styles.defaultWrapper} onClick={!isEnable ? onClick : undefined}>
      <div className={styles.bigPlusWrapper}>
        <Icon name={IconVariant.PLUS} />
      </div>

      {!isEnable && (
        <Text variant={TextSize.M} color={TextColor.BLACK}>
          {' '}
          Add
        </Text>
      )}
    </div>
  );
};

const SelectedStateComponent = ({ option, onClose }: { option: IOption; onClose: () => void }) => {
  return option ? (
    <div className={styles.selectedWrapper} onClick={onClose}>
      <Text variant={TextSize.XS} color={TextColor.BLACK}>
        {option.label}
      </Text>

      <Icon name={IconVariant.CLOSE} width={12} height={12} />
    </div>
  ) : (
    <></>
  );
};

// Hack for select option with custom checkbox and stop closing menu
// https://github.com/JedWatson/react-select/issues/3525#issuecomment-516167525
const Option: FC<OptionProps<IOption>> = props => {
  const { data, isDisabled, isFocused, isSelected } = props;

  return (
    <components.Option
      {...props}
      className={cx({
        [styles.singleOption]: true,
        [styles.singleOptionSelected]: isSelected,
        [styles.singleOptionFocused]: isFocused,
        [styles.singleOptionDisabled]: isDisabled,
      })}
    >
      <Tag text={data.label} />
      <div className={styles.plusButtonWrapper}>
        <Icon name={IconVariant.PLUS} />
      </div>
    </components.Option>
  );
};

const Menu: FC<MenuProps<IOption>> = props => {
  const { children } = props;

  return (
    <components.Menu {...props} className={styles.menu}>
      {children}
    </components.Menu>
  );
};

const ValueContainer: FC<ValueContainerProps<IOption>> = props => {
  const { children } = props;

  return (
    <components.ValueContainer {...props} className={styles.valueContainer}>
      {children}
    </components.ValueContainer>
  );
};

const SingleValueComponent: FC<SingleValueProps<IOption>> = props => {
  const { children } = props;

  return (
    <components.SingleValue {...props} className={styles.tagWrapper}>
      {children}
    </components.SingleValue>
  );
};

const Control: FC<ControlProps<IOption>> = props => {
  const { children, isDisabled, isFocused } = props;

  return (
    <components.Control
      {...props}
      className={cx({
        [styles.control]: true,
        [styles.controlFocused]: isFocused,
        [styles.controlDisabled]: isDisabled,
      })}
    >
      {children}
    </components.Control>
  );
};

const Placeholder: FC<PlaceholderProps<IOption>> = props => {
  const { children } = props;

  return (
    <components.Placeholder {...props}>
      <Text variant={TextSize.L} color={TextColor.GREY3}>
        {children}
      </Text>
    </components.Placeholder>
  );
};

const MenuList: FC<MenuListProps<IOption>> = props => {
  const { children } = props;

  return (
    <components.MenuList {...props} className={styles.menuList}>
      {children}
    </components.MenuList>
  );
};

export const TagSelect: FC<TagSelectProps> = ({
  className,
  options,
  defaultValue,
  placeholder = 'Add Tag',
  noOptionsMessage = 'No tags found',
  onChange,
  required,
  containerStyles,
}: TagSelectProps) => {
  const [isShow, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOption | null>(defaultValue || null);

  const handleBlur = () => {
    setIsOpen(false);
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleChange = (option: IOption | null, actionMeta: ActionMeta<IOption>) => {
    setSelectedOption(option);
    onChange && onChange(option, actionMeta);
    setIsOpen(false);
  };

  const handleShow = () => {
    setSelectedOption(null);
    onChange && onChange(null);
    setShow(true);
  };

  const handleRemove = () => {
    onChange && onChange(null);
    setSelectedOption(null);
  };

  return (
    <>
      {!selectedOption && (
        <div className={styles.mainContainer}>
          <DefaultStateComponent onClick={handleShow} isEnable={isShow} />
          {isShow && (
            <Select
              menuIsOpen={isOpen}
              onFocus={handleFocus}
              className={className}
              required={required}
              closeMenuOnSelect={false}
              placeholder={placeholder}
              hideSelectedOptions={false}
              noOptionsMessage={() => noOptionsMessage}
              isSearchable
              menuPosition="fixed"
              menuPlacement="auto"
              styles={{
                container(base) {
                  return {
                    ...base,
                    width: '100%',
                    ...containerStyles,
                  };
                },
              }}
              components={{
                Control,
                Menu,
                MenuList,
                ValueContainer,
                SingleValue: SingleValueComponent,
                DropdownIndicator: () => null,
                Option,
                Placeholder,
                ClearIndicator: () => null,
                MultiValueRemove: () => null,
                IndicatorSeparator: () => null,
              }}
              isMulti={false}
              options={options}
              // @ts-ignore
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </div>
      )}
      {selectedOption && <SelectedStateComponent option={selectedOption} onClose={handleRemove} />}
    </>
  );
};
