import { makeObservable } from 'mobx';
import qs from 'qs';

import { PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize } from '@writercolab/network';
import { type TOrgTeamUserActivityParams } from '@writercolab/types';

import type { TApplicationBriefResponse, TWidgetConfig } from '@web/types';
import { TWidgetEditAppearance, TWidgetType } from '@web/types';
import { IWebAppAnalyticsTrack } from 'constants/analytics';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';

interface IAiStudioHomeWidgetModelParams {
  request: RequestServiceInitialize['api'];
  organizationId: number | undefined;
  config: () => TWidgetConfig<typeof TWidgetType.enum.aiStudio>;
  analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
}

export class AiStudioHomeWidgetModelUi extends AbstractHomeWidgetModel<typeof TWidgetType.enum.aiStudio> {
  editAppearance = TWidgetEditAppearance.enum.readonly;

  private readonly appsList = new PromisedModel<TApplicationBriefResponse[] | undefined>(async () => {
    if (!this.params.organizationId) {
      return undefined;
    }

    const { data } = await this.params.request.get('/api/template/organization/{organizationId}/v2/application', {
      params: {
        path: {
          organizationId: this.params.organizationId,
        },
        query: {
          statuses: ['draft'],
          sortField: 'createdAt',
          sortOrder: 'desc',
          limit: 3,
          offset: 0,
        },
      },
      querySerializer: query => qs.stringify(query, { arrayFormat: 'repeat' }),
    });

    return data.result;
  });

  constructor(private params: IAiStudioHomeWidgetModelParams) {
    super(TWidgetType.enum.aiStudio, () => params.config(), params.analyticsService);

    makeObservable(this, {});
  }

  get recentApps() {
    return this.appsList.value;
  }

  isResearchAppAvailable = true;

  get isLoading() {
    return this.appsList.status === 'pending';
  }
}
