import React from 'react';

import { Button, ButtonTypes, Modal, Text, TextSize } from '@writercolab/ui-atoms';

import styles from '../../SsoPage/styles.module.css';

interface IConfirmServiceAccountRemoveProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmServiceAccountRemoveModal: React.FC<IConfirmServiceAccountRemoveProps> = ({
  open,
  onCancel,
  onConfirm,
}) => (
  <Modal open={open} handleClose={onCancel} title="Delete service account" style={{ width: 328 }}>
    <div className={styles.modalBody}>
      <Text variant={TextSize.XL}>Are you sure? Deleting a service account can’t be undone.</Text>
      <div>
        <div className={styles.controlButtons}>
          <Button className={styles.submitButton} type={ButtonTypes.DEFAULT} content="Never mind" onClick={onCancel} />
          <Button
            className={styles.submitButton}
            type={ButtonTypes.PRIMARY}
            danger
            content="Delete"
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  </Modal>
);

export default ConfirmServiceAccountRemoveModal;
