import React from 'react';

import { TDeletionOption, TDeletionOptionType, openNewTab } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  IRadioGroupOption,
  LinkText,
  RadioGroup,
  SizeTypes,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';

import { DataRetentionAssetOptions } from 'components/organisms/DataRetentionPreferences/DataRetentionAssetOptions';
import { DataRetentionConfirmationModal } from 'components/organisms/DataRetentionPreferences/DataRetentionConfirmationModal';
import { DataRetentionPreferencesUIModel } from 'components/organisms/DataRetentionPreferences/DataRetentionPreferencesModel.ui';
import SectionHeading from 'components/organisms/SectionHeading';

import { observer } from 'mobx-react-lite';
import { DATA_RETENTION_SUPPORT_PAGE_URL } from 'utils/dataRetentionUtils';

import styles from './DataRetentionPreferences.module.css';

const deleteOptionsReadable = TDeletionOption.hash({
  0: 'No automatic deletion',
  30: 'Delete after 1 month',
  90: 'Delete after 3 months',
  180: 'Delete after 6 months',
  360: 'Delete after 12 months',
});

const handleSupportPageClick = () => openNewTab(DATA_RETENTION_SUPPORT_PAGE_URL);

interface IDataRetentionPreferencesProps {
  model: DataRetentionPreferencesUIModel;
}

export const DataRetentionPreferences: React.FC<IDataRetentionPreferencesProps> = observer(({ model }) => {
  const selectedRetentionDays = model.dataForm.form.retentionDays.value;
  const { hasValidationError, formTouched } = model;

  const radioOptions: IRadioGroupOption<TDeletionOptionType>[] = TDeletionOption.list.map(deleteOption => ({
    text: (
      <Text variant={TextSize.XL} color={TextColor.GREY}>
        {deleteOptionsReadable[deleteOption]}
      </Text>
    ),
    value: deleteOption,
    additions:
      selectedRetentionDays !== TDeletionOption.enum[0] && selectedRetentionDays === deleteOption ? (
        <DataRetentionAssetOptions model={model} />
      ) : undefined,
  }));

  return (
    <>
      <div className={styles.container}>
        <SectionHeading
          heading="Data retention"
          description={
            <>
              By default, Writer keeps individual users' assets available to that user until the user is deleted or the
              organization is deleted. <br />
              <br />
              You can automate the deletion of individual user assets in your organization by selecting a custom
              deletion period below. Assets created prior to your deletion period will be deleted automatically.{' '}
              <LinkText className={styles.containerTextLink} onClick={handleSupportPageClick}>
                Learn more
              </LinkText>
              .
            </>
          }
          descriptionTextColor={TextColor.GREY}
          descriptionTextVariant={TextSize.XL}
          descriptionContainerClassName={styles.description}
        />
        <Text variant={TextSize.XL} bold>
          Automatic deletion schedule
        </Text>
        <form className={styles.form}>
          <RadioGroup
            name="data-retention-policy"
            className={styles.dataRententionOptions}
            options={radioOptions}
            onChange={e => model.dataForm.form.retentionDays.set(TDeletionOption.get(e.target.value, '0'))}
            currentValue={selectedRetentionDays}
          />
          <div className={styles.formActions}>
            <Button
              className={styles.saveButton}
              type={ButtonTypes.PRIMARY}
              size={SizeTypes.SMALL}
              content="Save"
              onClick={() => model.onPreviewDeletedAssetCounts()}
              disabled={!formTouched || hasValidationError}
              isLoading={model.isSaving}
            />
            <Button
              type={ButtonTypes.DEFAULT}
              size={SizeTypes.SMALL}
              content="Cancel"
              onClick={() => model.resetForm()}
              disabled={!formTouched || hasValidationError}
            />
          </div>
        </form>
      </div>
      {model.confirmationModalOpen && (
        <DataRetentionConfirmationModal
          onClose={() => model.setConfirmationModalOpen(false)}
          onCancel={() => model.cancelChanges()}
          onContinue={() => model.onUpdateDataRetentionPreferences()}
          retentionDays={selectedRetentionDays as TDeletionOptionType}
          deletionCounts={model.deletionCounts}
          isLoading={model.isSaving}
        />
      )}
    </>
  );
});
