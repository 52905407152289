import { useEffect, useState } from 'react';

import type { IUserSalesProfile } from '@writercolab/common-utils';

import { getUserSalesProfile } from '../services/request/user';
import { useAppState } from '../state';
import { TActionType } from '../state/types';

export default function useSalesProfile(): IUserSalesProfile | undefined {
  const [userSalesProfile, setUserSalesProfile] = useState<IUserSalesProfile>();
  const { dispatchAppState } = useAppState();

  useEffect(() => {
    getUserSalesProfile().then((userSalesProfile: IUserSalesProfile) => {
      setUserSalesProfile(userSalesProfile);
      dispatchAppState({ type: TActionType.SetUserSalesProfile, payload: userSalesProfile });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userSalesProfile;
}
