import { openNewTab } from '@writercolab/common-utils';
import { IconVariant, Text, TextColor, TextSize } from '@writercolab/ui-atoms';
import { CreateDocumentButton, Unreachable, UnreachableType } from '@writercolab/ui-molecules';

import { AnalyticsActivity } from 'constants/analytics';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router';
import { ROUTE } from 'services/config/routes';
import { useAppState } from 'state';

import { OutputsTableUiModel } from './OutputsTableModel.ui';
import { getOutputsUrlParams } from './utils';

import styles from './OutputsTable.module.css';

export const Empty = ({ model }: { model: OutputsTableUiModel }) => {
  const { appState, appModel } = useAppState();
  const navigate = useNavigate();
  const { search, templateIds } = getOutputsUrlParams();

  const onStartWithCoWrite = () => {
    appModel.analyticsService.track(AnalyticsActivity.startWithCoWrite, {});
    navigate(ROUTE.toEditorWithCoWrite(appState.organizationId, appState.teamId));
  };

  const onLearnMore = () => {
    openNewTab('https://support.writer.com/article/187-introducing-cowrite');
  };

  if (model.isLoading) return null;

  if (isEmpty(model.outputs) && isEmpty(search) && isEmpty(templateIds)) {
    return (
      <div className={styles.unreachable}>
        <div className={styles.emptyOutputs}>
          <Unreachable type={UnreachableType.enum.draftsListZeroState} />
          <Text variant={TextSize.XXXL} color={TextColor.GREY2} className={styles.zeroState}>
            There are no outputs yet. Start an output with apps, or learn more{' '}
            <span className={styles.zeroHere} onClick={onLearnMore}>
              here
            </span>
            .
          </Text>

          <CreateDocumentButton
            className={styles.newDocButton}
            iconClassName={styles.wandDocButtonIcon}
            text="Start with apps"
            onClick={onStartWithCoWrite}
            iconVariant={IconVariant.WAND}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.unreachable}>
      <Unreachable type={UnreachableType.enum.noResultsEmpty} />
    </div>
  );
};
