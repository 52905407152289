export type HitComponentProps<T> = { hit: T };

export enum HitType {
  HTML = 'html',
  TERM = 'terminology',
  SNIPPET = 'snippet',
}

export type ResultCounts = Record<HitType | 'total', number>;

export interface IHit {
  objectID: string;
  type: HitType;
  url: string;
}

export interface IPageContentHit extends IHit {
  title: string;
  section: string;
  content: {
    html: string;
  };
}

export interface ITermHit extends IHit {
  content: {
    term: string;
    type: string;
    description: string;
    pos: string;
  };
}

export interface ISnippetHit extends IHit {
  content: {
    snippet: string;
    shortcut: string;
    description: string;
  };
}
