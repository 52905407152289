import { computed, makeObservable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import type { RequestServiceInitialize, components } from '@writercolab/network';

import type { TVoice } from '@web/types';

interface IVoiceParams {
  name: string;
  description: string;
  type: 'Manual' | 'Analyze';
  sampleText?: string;
  profile?: string;
  overview?: string;
  settings?: components['schemas']['content_generation_model_VoiceSettings'];
  rewrite?: boolean;
}

interface IVoiceModelOptions {
  request: RequestServiceInitialize['api'];
  organizationId: () => number | undefined;
  teamId?: () => number | undefined;
  isVoiceEnabled: () => boolean;
}

export class VoiceApiModel {
  constructor(private opts: IVoiceModelOptions) {
    makeObservable(this, {
      orgId: computed,
      teamId: computed,
      voiceList: computed.struct,
      voiceCount: computed,
      isVoiceListLoading: computed,
      appStudioVoiceList: computed.struct,
      appStudioVoiceCount: computed,
      isAppStudioVoiceListLoading: computed,
      voiceExamplesList: computed.struct,
    });
  }

  get orgId() {
    return this.opts.organizationId();
  }

  get teamId() {
    return this.opts.teamId?.();
  }

  $voiceList: PromisedModel<TVoice[] | null | undefined> = new PromisedModel({
    name: '$voiceList',
    load: async () => {
      if (!this.orgId || !this.opts.isVoiceEnabled()) {
        return null;
      }

      const { data } = await this.opts.request.get('/api/generation/organization/{organizationId}/voice', {
        params: {
          path: {
            organizationId: this.orgId,
          },
          query: {
            teamId: this.teamId,
            limit: 100,
            offset: 0,
          },
        },
      });

      return data.result
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) => Number(b.default) - Number(a.default)); // Default one should always be on top
    },
  });

  $appStudioVoiceList: PromisedModel<
    components['schemas']['content_generation_dto_VoiceListResponse'][] | null | undefined
  > = new PromisedModel({
    name: '$appStudioVoiceList',
    load: async () => {
      if (!this.orgId) {
        return null;
      }

      const { data } = await this.opts.request.get('/api/generation/organization/{organizationId}/voice', {
        params: {
          path: {
            organizationId: this.orgId,
          },
          query: {
            appStudio: true,
            limit: 100,
            offset: 0,
          },
        },
      });

      return data.result.sort((a, b) => a.name.localeCompare(b.name));
    },
  });

  $voiceExamplesList: PromisedModel<components['schemas']['content_generation_model_VoiceExample'][] | null> =
    new PromisedModel({
      name: '$voiceExamplesList',
      load: async () => {
        if (!this.orgId) {
          return null;
        }

        const { data } = await this.opts.request.get('/api/generation/organization/{organizationId}/voice/example', {
          params: {
            path: {
              organizationId: this.orgId,
            },
          },
        });

        return data.result;
      },
    });

  addVoice = async (params: IVoiceParams) => {
    const { data } = await this.opts.request.post('/api/generation/organization/{organizationId}/voice', {
      params: {
        path: {
          organizationId: Number(this.orgId),
        },
      },
      body: {
        teamId: this.teamId,
        languageCode: 'en-us',
        gradeLevel: 5,
        ...params,
      },
    });

    return data;
  };

  updateVoice = async (id: string, params: IVoiceParams) => {
    const { data } = await this.opts.request.put('/api/generation/organization/{organizationId}/voice/{id}', {
      params: {
        path: {
          organizationId: Number(this.orgId),
          id,
        },
      },
      body: {
        teamId: this.teamId,
        languageCode: 'en-us',
        gradeLevel: 5,
        ...params,
      },
    });

    return data;
  };

  deleteVoice = async (id: string) => {
    const { data } = await this.opts.request.delete('/api/generation/organization/{organizationId}/voice/{id}', {
      params: {
        path: {
          organizationId: Number(this.orgId),
          id,
        },
      },
    });

    return data;
  };

  setVoiceAsDefault = async (id: string) => {
    const { data } = await this.opts.request.put('/api/generation/organization/{organizationId}/voice/{id}/default', {
      params: {
        path: {
          organizationId: Number(this.orgId),
          id,
        },
      },
    });

    return data;
  };

  removeTeamDefaultVoice = async () => {
    const { data } = await this.opts.request.delete(
      '/api/generation/organization/{organizationId}/team/{teamId}/voice/default',
      {
        params: {
          path: {
            organizationId: Number(this.orgId),
            teamId: Number(this.teamId),
          },
        },
      },
    );

    return data;
  };

  analyzeVoice = async (content: string) => {
    const { data } = await this.opts.request.post('/api/generation/organization/{organizationId}/voice/analyze', {
      params: {
        path: {
          organizationId: Number(this.orgId),
        },
      },
      body: {
        content,
      },
    });

    return data;
  };

  getVoiceDetails = async (id: string) => {
    const { data } = await this.opts.request.get('/api/generation/organization/{organizationId}/voice/{id}', {
      params: {
        path: {
          organizationId: Number(this.orgId),
          id,
        },
      },
    });

    return data;
  };

  getVoicePreviews = async (
    traits: { name: string }[],
    pronouns: boolean | null | undefined,
    secondPerson: boolean | null | undefined,
    firstPerson: boolean | null | undefined,
  ) => {
    const { data } = await this.opts.request.post('/api/generation/organization/{organizationId}/voice/preview', {
      params: {
        path: {
          organizationId: Number(this.orgId),
        },
      },
      body: {
        settings: {
          traits,
          pronouns,
          secondPerson,
          firstPerson,
        },
      },
    });

    return data;
  };

  reloadVoiceList = () => {
    this.$voiceList.reload();

    return this.$voiceList.promise;
  };

  reloadAppStudioVoiceList = () => {
    this.$appStudioVoiceList.reload();

    return this.$appStudioVoiceList.promise;
  };

  get voiceList() {
    return this.$voiceList.value ?? [];
  }

  get voiceCount() {
    return this.voiceList.length;
  }

  get isVoiceListLoading() {
    return this.$voiceList.status === 'pending';
  }

  get appStudioVoiceList() {
    return this.$appStudioVoiceList.value ?? [];
  }

  get appStudioVoiceCount() {
    return this.appStudioVoiceList.length;
  }

  get isAppStudioVoiceListLoading() {
    return this.$appStudioVoiceList.status === 'pending';
  }

  get voiceExamplesList() {
    return this.$voiceExamplesList.value ?? [];
  }
}
