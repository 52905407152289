import React from 'react';

import { Icon, IconVariant, Modal, Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './ViewVoiceModal.module.css';

export interface IViewVoiceModalProps {
  open: boolean;
  name: string;
  description: string;
  overview: string;
  profile: string;
  onClickClose: () => void;
}

export const ViewVoiceModal: React.FC<IViewVoiceModalProps> = ({
  open,
  name,
  description,
  overview,
  profile,
  onClickClose,
}) => (
  <Modal
    open={open}
    hideCloseButton
    title={name}
    className={styles.content}
    modalTitleClassName={styles.title}
    multiModalContainerClassName={styles.container}
    handleClose={onClickClose}
  >
    <>
      <div className={styles.closeButton} onClick={onClickClose}>
        <Icon name={IconVariant.CLOSE_WHITE} height={18} width={18} />
      </div>
      {description && (
        <>
          <Text variant={TextSize.XL} color={TextColor.GREY} bold>
            When to use
          </Text>
          <Text className={styles.description} variant={TextSize.L} color={TextColor.GREY}>
            {description}
          </Text>
        </>
      )}

      {overview && (
        <>
          <Text className={styles.overviewLabel} variant={TextSize.S} color={TextColor.GREY} medium upperCase>
            Overview
          </Text>
          <Text className={styles.overview} variant={TextSize.XL} color={TextColor.GREY} bold>
            {overview}
          </Text>
        </>
      )}

      {profile && (
        <>
          <Text className={styles.profileLabel} variant={TextSize.S} color={TextColor.GREY} medium upperCase>
            Detailed profile
          </Text>
          <Text className={styles.profile} variant={TextSize.XL} color={TextColor.GREY}>
            {profile}
          </Text>
        </>
      )}
    </>
  </Modal>
);
