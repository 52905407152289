import React from 'react';

import { openNewTab } from '@writercolab/common-utils';
import { Heading, HeadingVariant, Icon, IconVariant, Text, TextColor, TextSize, Tooltip } from '@writercolab/ui-atoms';

import { IDraftMedia } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { getEventClipUrl } from '../../../utils/draftsUtils';

import styles from './styles.module.css';

interface IDraftOutputsProps {
  inputMedia: IDraftMedia;
  downloadMediaFileTranscript: (fileId: string, filename: string) => Promise<void>;
  eventClips?: string[];
}

const DraftOutputs = ({ inputMedia, downloadMediaFileTranscript, eventClips }: IDraftOutputsProps) => (
  <div className={styles.outputsContainer}>
    <Heading variant={HeadingVariant.H5} className={styles.outputsHeader}>
      Assets
    </Heading>
    <div className={styles.outputsAssets}>
      <Text
        className={styles.outputsAsset}
        variant={TextSize.M}
        onClick={() => downloadMediaFileTranscript(inputMedia.fileId, 'transcript.docx')}
      >
        <span className={styles.outputsIconDownload}>
          <Icon name={IconVariant.DOWNLOAD} />
        </span>
        Recording transcript
        <Tooltip
          placement="top"
          title={
            <Text className={styles.outputsTooltip} medium color={TextColor.WHITE} variant={TextSize.S}>
              Download transcript
            </Text>
          }
        >
          <div className={styles.outputsIconInfo}>
            <Icon name={IconVariant.INFO_OUTLINED} />
          </div>
        </Tooltip>
      </Text>
    </div>

    {!isEmpty(eventClips) && (
      <>
        <Heading variant={HeadingVariant.H5} className={styles.outputsHeader}>
          Event clips
        </Heading>
        <div className={styles.outputsAssets}>
          {eventClips?.map(value => {
            const clip = JSON.parse(value);

            return (
              <Text
                key={clip.playbackId}
                className={styles.outputsAsset}
                variant={TextSize.M}
                onClick={() => openNewTab(getEventClipUrl(clip.playbackId, clip.maxResolution, clip.label, true))}
              >
                <span className={styles.outputsIconDownload}>
                  <Icon name={IconVariant.DOWNLOAD} />
                </span>
                {clip.label}
              </Text>
            );
          })}
        </div>
      </>
    )}
  </div>
);

export default DraftOutputs;
