import React from 'react';
import { IconVariant } from '@writercolab/ui-atoms';
import { QUILL_FORMAT } from '@writercolab/quill-delta-utils';
import styles from './EditorToolbarAlignPanel.module.css';
import EditorToolbarButton from '../EditorToolbarButton/EditorToolbarButton';

interface IEditorToolbarAlignPanelProps {
  currentAlign: 'left' | 'center' | 'right';
  onFormatClick: (format: QUILL_FORMAT, value: string) => void;
}

export const EditorToolbarAlignPanel: React.FC<IEditorToolbarAlignPanelProps> = ({ currentAlign, onFormatClick }) => (
  <div className={styles.alignPanel}>
    <EditorToolbarButton
      format={QUILL_FORMAT.ALIGN}
      icon={IconVariant.EDITOR_FORMAT_ALIGN_LEFT}
      onClick={() => onFormatClick(QUILL_FORMAT.ALIGN, 'left')}
      active={currentAlign === 'left'}
    />
    <EditorToolbarButton
      format={QUILL_FORMAT.ALIGN}
      icon={IconVariant.EDITOR_FORMAT_ALIGN_CENTER}
      onClick={() => onFormatClick(QUILL_FORMAT.ALIGN, 'center')}
      active={currentAlign === 'center'}
    />
    <EditorToolbarButton
      format={QUILL_FORMAT.ALIGN}
      icon={IconVariant.EDITOR_FORMAT_ALIGN_RIGHT}
      onClick={() => onFormatClick(QUILL_FORMAT.ALIGN, 'right')}
      active={currentAlign === 'right'}
    />
  </div>
);

export default EditorToolbarAlignPanel;
