import React, { useEffect, useState } from 'react';

import { ALLOW_LIST_LIMIT } from '@writercolab/common-utils';
import {
  Button,
  ButtonTypes,
  Heading,
  HeadingVariant,
  Modal,
  Switcher,
  Text,
  TextColor,
  TextSize,
} from '@writercolab/ui-atoms';
import { Collapsed, SortButton, StringsEditor } from '@writercolab/ui-molecules';

import styles from './styles.module.css';

interface CommonAcronymsAllowlistModalProps {
  isOpen: boolean;
  defaultListEnabled: boolean;
  customListEnabled: boolean;
  list: string[];
  commonList: string[];
  onSave: (list: string[], defaultListEnabled: boolean, customListEnabled: boolean) => void;
  onCancel: () => void;
}

export const CommonAcronymsAllowlistModal: React.FC<CommonAcronymsAllowlistModalProps> = ({
  isOpen,
  list,
  defaultListEnabled,
  customListEnabled,
  commonList,
  onSave,
  onCancel,
}) => {
  const [isExceedLimit, setIsExceedLimit] = useState(false);
  const [allowList, setAllowList] = useState(list);
  const [defaultListSwitched, setDefaultListSwitched] = useState(defaultListEnabled);
  const [customListSwitched, setCustomListSwitched] = useState(customListEnabled);

  const onChangeList = (list: string[]) => setAllowList(list);
  const onSaveClick = () => onSave(allowList, defaultListSwitched, customListSwitched);
  const onSortList = () => {
    const newList = allowList
      .map(item => item.trim())
      .filter(Boolean)
      .sort();
    setAllowList(newList);
  };
  const validItems = allowList.filter(Boolean).length;

  useEffect(() => {
    if (isOpen) {
      setAllowList(list);
      setDefaultListSwitched(defaultListEnabled);
      setCustomListSwitched(customListEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, list]);

  useEffect(() => setIsExceedLimit(validItems > ALLOW_LIST_LIMIT), [validItems]);

  return (
    <Modal open={isOpen} handleClose={onCancel} style={{ width: '480px' }}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <Heading variant={HeadingVariant.H3} className={styles.header} bold>
            Edit common acronyms allowlist
          </Heading>
          <Text variant={TextSize.XL} color={TextColor.GREY}>
            Update the common acronyms that you’d like to be ignored by our “Introduce acronyms upon first mention”
            rule.
          </Text>
        </div>

        <div className={styles.collapsedGroup}>
          <Switcher
            id="default-list"
            size={20}
            checked={defaultListSwitched}
            onChange={value => setDefaultListSwitched(value)}
          />
          <Collapsed label="Writer default" defaultOpen={validItems === 0} className={styles.collapsedSection}>
            <StringsEditor className={styles.editor} list={commonList} onChange={() => {}} readOnly />
          </Collapsed>
        </div>

        <div className={styles.collapsedGroup}>
          <Switcher
            id="custom-list"
            size={20}
            checked={customListSwitched}
            onChange={value => setCustomListSwitched(value)}
          />
          <SortButton className={styles.sortIcon} onClick={onSortList}></SortButton>

          <Collapsed
            label={`Add my own ${validItems === 0 ? '' : validItems}`}
            defaultOpen={validItems > 0}
            className={styles.collapsedSection}
          >
            <StringsEditor className={styles.editor} list={allowList} onChange={onChangeList} />
            <div className={styles.limit}>
              <Text variant={TextSize.XS} color={isExceedLimit ? TextColor.ORANGE : TextColor.GREY}>
                {validItems} / {ALLOW_LIST_LIMIT}
              </Text>
            </div>
          </Collapsed>
        </div>
        <div className={styles.footer}>
          <Button className={styles.cancelButton} type={ButtonTypes.BORDERED} onClick={onCancel} content="Cancel" />
          <Button type={ButtonTypes.PRIMARY} onClick={onSaveClick} content="Save" disabled={isExceedLimit} />
        </div>
      </div>
    </Modal>
  );
};
