import React from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Icon, IconVariant, Text, TextSize } from '@writercolab/ui-atoms';

import styles from './FloatingToolbar.module.css';

interface IFloatingToolbarProps {
  isLoading: boolean;
  buttonText: string;
  onClick: () => void;
  title: string;
  subtitle?: string;
  iconName?: IconVariant;
  className?: string;
}

export const FloatingToolbar: React.FC<IFloatingToolbarProps> = ({
  className,
  isLoading,
  onClick,
  buttonText,
  iconName,
  title,
  subtitle,
}) => {
  return (
    <div className={cx(className, styles.floatingToolbar)}>
      <div className={styles.leftContent}>
        {iconName && <Icon name={iconName} width={24} height={24} />}
        <div className={styles.textContent}>
          <Text variant={TextSize.XL} bold className={styles.title}>
            {title}
          </Text>
          <Text variant={TextSize.M}>{subtitle}</Text>
        </div>
      </div>
      <Button content={buttonText} isLoading={isLoading} type={ButtonTypes.BLACK} onClick={onClick} />
    </div>
  );
};
