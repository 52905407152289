import React from 'react';

import cx from 'classnames';

import { BaseComponentsProps } from '@writercolab/common-utils';
import { Button, ButtonTypes, Icon, IconVariant, SizeTypes } from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

import ToolButtonCounter from './ToolButtonCounter';

import styles from '../styles.module.css';

interface IButtonStandardProps extends BaseComponentsProps {
  iconVariant: IconVariant;
  buttonType: ButtonTypes;
  onClick: () => void;
  isLoading: boolean;
  buttonRound: boolean;
  disabled?: boolean;
  buttonContent?: string;
  counter?: number;
}

export const ToolButtonStandard: React.FC<IButtonStandardProps> = ({
  className,
  iconVariant,
  buttonType,
  onClick,
  isLoading,
  buttonRound,
  buttonContent,
  counter,
  disabled = false,
}) => (
  <div className={styles.toolButton}>
    {!!counter && <ToolButtonCounter number={counter} />}
    <Button
      className={cx(className, styles.containerToolButton, {
        [styles.toolButtonSquared]: !buttonRound,
        [styles.toolButtonSquaredContent]: !buttonRound && !isEmpty(buttonContent),
        [styles.toolButtonSquaredLoading]: isLoading,
        [styles.toolButtonSquaredLoadingGreen]: isLoading && buttonType === ButtonTypes.GREEN,
        [styles.toolButtonSquaredLoadingPink]: isLoading && buttonType === ButtonTypes.PINK,
        [styles.toolButtonSquaredLoadingMauve]: isLoading && buttonType === ButtonTypes.MAUVE,
      })}
      disabled={disabled}
      htmlType="button"
      round={buttonRound}
      content={buttonContent}
      size={SizeTypes.MEDIUM}
      type={buttonType}
      icon={<Icon name={iconVariant} />}
      onClick={onClick}
    />
  </div>
);

export default ToolButtonStandard;
