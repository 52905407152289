import { action, computed, makeObservable, observable } from 'mobx';

import { PromisedModel } from '@writercolab/mobx';
import { AiAssistantSubscriptionModel } from '@writercolab/models';
import type { RequestServiceInitialize } from '@writercolab/network';
import { TOrgTeamUserActivityParams } from '@writercolab/types';

import type { TWidgetConfig } from '@web/types';
import { RecentWorkWidgetTabId, TWidgetEditAppearance, TWidgetType } from '@web/types';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import { AbstractHomeWidgetModel } from 'models/bases/AbstractHomeWidgetModel';
import { OrganizationDocumentsApi } from 'models/organizationDocuments.api';
import { SessionsApiModel } from 'models/sessions.api';

const PAGE_SIZE = 5;

export class RecentWorkHomeWidgetModelUi extends AbstractHomeWidgetModel<typeof TWidgetType.enum.recentWork> {
  _activeTab: typeof RecentWorkWidgetTabId.type = RecentWorkWidgetTabId.enum.sessions;
  editAppearance = TWidgetEditAppearance.enum.readonly;
  private readonly documentsApi: OrganizationDocumentsApi;
  private readonly sessionsApi: SessionsApiModel;

  readonly sessions = new PromisedModel(async () => {
    const organizationId = this.params.organizationId();

    if (!organizationId) {
      return undefined;
    }

    const data = await this.sessionsApi.getTeamSessions({
      offset: 0,
      limit: PAGE_SIZE,
      sortField: 'modificationTime',
      origin: 'web',
    });

    return data.result.map(session => session.session);
  });

  readonly documents = new PromisedModel(async () => {
    const organizationId = this.params.organizationId();
    const teamId = this.params.teamId();

    if (!organizationId || !teamId) {
      return undefined;
    }

    const data = await this.documentsApi.getDocuments({
      offset: 0,
      limit: PAGE_SIZE,
      sortField: 'openedByMeTime',
    });

    return data.result;
  });

  constructor(
    private params: {
      analyticsService: IWebAppAnalyticsTrack<TOrgTeamUserActivityParams>;
      request: RequestServiceInitialize['api'];
      organizationId: () => number;
      assistantSubscription: () => AiAssistantSubscriptionModel;
      teamId: () => number;
      config: () => TWidgetConfig<typeof TWidgetType.enum.recentWork>;
    },
  ) {
    super(TWidgetType.enum.recentWork, () => params.config(), params.analyticsService);

    this.documentsApi = new OrganizationDocumentsApi({
      organizationId: params.organizationId(),
      teamId: params.teamId(),
      request: params.request,
    });

    this.sessionsApi = new SessionsApiModel({
      request: params.request,
      organizationId: params.organizationId(),
      teamId: params.teamId(),
    });

    makeObservable(this, {
      _activeTab: observable,
      activeTab: computed,
      hasSessionAccess: computed,
      setActiveTab: action,
    });
  }

  get isLoading() {
    return this.documents.status === 'pending' || this.sessions.status === 'pending';
  }

  setActiveTab = (tab: typeof RecentWorkWidgetTabId.type) => {
    this._activeTab = tab;
    this.analyticsService.track(AnalyticsActivity.recentWorkWidgetTabSelected, { option: tab });
  };

  get hasSessionAccess() {
    return !this.params.assistantSubscription()?.isFree;
  }

  get activeTab() {
    return this.params.assistantSubscription()?.isFree ? RecentWorkWidgetTabId.enum.docs : this._activeTab;
  }
}
