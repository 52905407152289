import React from 'react';

import { Text, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface BillingContactProps {
  onEditClick?: () => void;
  contactEmail: string;
}

export const BillingContact: React.FC<BillingContactProps> = ({ onEditClick, contactEmail }) => {
  const handleEditClick = () => {
    onEditClick && onEditClick();
  };

  return (
    <div className={styles.container}>
      <div className={styles.contactContainer}>
        <Text variant={TextSize.M}>{contactEmail}</Text>
      </div>
      <div className={styles.contactContainerEdit}>
        <div className={styles.contactContainerEditTrigger} onClick={handleEditClick}>
          <Text variant={TextSize.S}>Edit</Text>
        </div>
      </div>
    </div>
  );
};

export default BillingContact;
