import { useEffect, useState } from 'react';

import { IUserProfile } from '@writercolab/types';

import chameleon from '@chamaeleonidae/chmln';
// eslint-disable-next-line no-restricted-imports
import analytics from 'services/analytics/analyticsService';
import { getPageAnalyticsProperties } from 'utils/analyticsUtils';
import dynamicConfig from 'utils/dynamicConfig';

import type { TAppState } from '../state/types';

export const useAnalytics = ({
  appState,
  userProfile,
  isAuthenticated,
}: {
  appState: TAppState;
  userProfile: IUserProfile | undefined;
  isAuthenticated: boolean | undefined;
}) => {
  const {
    site: { title },
    organizationId,
  } = appState;
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const properties = getPageAnalyticsProperties();

    if (organizationId && isAuthenticated) {
      analytics.page({
        name: properties.page_name,
        organizationId,
        properties,
      });
    }
  }, [title, organizationId, isAuthenticated]);

  useEffect(() => {
    if (organizationId && isAuthenticated) {
      analytics.identify(organizationId, {});

      setIsReady(true);
    }
  }, [organizationId, isAuthenticated]);

  useEffect(() => {
    if (isReady && !dynamicConfig.SINGLE_TENANCY_CONFIG?.disableChameleon) {
      chameleon.init('S6dr31v4MO4wuteztVhxysLkoA9HsZ6HnDaOTDLqNXHYZq-1P02ek-E3BR4Zgv4pMwdoYO', {
        fastUrl: 'https://fast.chameleon.io/',
      });

      chameleon.identify(`segment-prefix-${userProfile?.id}`, {
        email: userProfile?.email,
        name: userProfile?.fullName,
      });
    }
  }, [isReady, userProfile]);

  return { isReady };
};
