import { BILLING_COLUMN_LABELS, REFERENCE_COLUMN_NAME, SNIPPETS_COLUMN_LABELS, TERM_COLUMN_LABELS } from '../../consts';
import { AcceptableColumnType, ImportItemType } from '../../types';
import {
  convertHighlightForApi,
  convertMistakesForApi,
  convertTagsForApi,
  formatSnippetShortcutForApi,
  trimValue,
  validateReferenceForApi,
} from './utils';

const BOOLEAN_ACCEPTED_VALUES = [
  {
    label: 'Yes',
    acceptedValues: ['yes', 'y', 'true', 't'],
    booleanValue: true,
  },
  {
    label: 'No',
    acceptedValues: ['no', 'n', 'false', 'f'],
    booleanValue: false,
  },
];

const PART_OF_SPEECH_ACCEPTED_VALUES = [
  {
    label: 'Noun',
    acceptedValues: ['noun', 'n'],
    keyForApi: 'noun',
  },
  {
    label: 'Verb',
    acceptedValues: ['verb', 'v'],
    keyForApi: 'verb',
  },
  {
    label: 'Adverb',
    acceptedValues: ['adverb', 'adv'],
    keyForApi: 'adverb',
  },
  {
    label: 'Adjective',
    acceptedValues: ['adjective', 'adj'],
    keyForApi: 'adjective',
  },
  {
    label: 'None',
    acceptedValues: ['none', 'null', 'n/a', ''],
    keyForApi: '',
  },
];

const TERMS_ACCEPTABLE_COLUMNS: AcceptableColumnType[] = [
  {
    names: ['reference', 'references'],
    label: TERM_COLUMN_LABELS.Reference,
    apiFieldKey: REFERENCE_COLUMN_NAME,
    convertForApi: validateReferenceForApi,
  },
  {
    names: ['term', 'terms'],
    label: TERM_COLUMN_LABELS.Term,
    apiFieldKey: 'term',
    convertForApi: trimValue,
    required: true,
  },
  {
    names: ['type'],
    label: TERM_COLUMN_LABELS.Type,
    apiFieldKey: 'type',
    required: true,
    variants: [
      {
        label: 'Approved',
        acceptedValues: ['approved', 'approved_term'],
        keyForApi: 'approved',
      },
      {
        label: 'Don’t use',
        acceptedValues: ['banned', 'dont_use', 'dont_use_term'],
        keyForApi: 'banned',
      },
      {
        label: 'Pending',
        acceptedValues: ['pending', 'pending_term'],
        keyForApi: 'pending',
      },
      {
        label: 'UseCarefully',
        acceptedValues: ['useCarefully', 'usecarefully', 'use carefully', 'use_carefully', 'use-carefully'],
        keyForApi: 'useCarefully',
      },
    ],
  },
  {
    names: ['part_of_speech'],
    apiFieldKey: 'pos',
    label: TERM_COLUMN_LABELS.PartOfSpeech,
    variants: PART_OF_SPEECH_ACCEPTED_VALUES,
    defaultValue: '',
  },
  {
    names: ['case_sensitive'],
    apiFieldKey: 'caseSensitive',
    label: TERM_COLUMN_LABELS.CaseSensitive,
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
  {
    names: ['case_sensitive_sentence_start', 'capitalize_at_sentence_start'],
    label: TERM_COLUMN_LABELS.CapitalizeAtSentenceStart,
    apiFieldKey: 'approvedTermExtension.capitalize',
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
  {
    names: [
      'case_sensitive_autocorrect',
      'autocorrect_wrong_capitalizations',
      'autocorrect_wrong_caps',
      'correct_wrong_caps',
      'correct_wrong_capitalizations',
    ],
    label: TERM_COLUMN_LABELS.AutocorrectWrongCapitalizations,
    apiFieldKey: 'approvedTermExtension.fixCase',
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
  {
    names: ['description'],
    apiFieldKey: 'description',
    label: TERM_COLUMN_LABELS.Description,
  },
  {
    names: ['common_mistake', 'commonmistake', 'common_mistakes', 'commonmistakes'],
    label: TERM_COLUMN_LABELS.CommonMistake,
    apiFieldKey: 'mistakes',
    convertForApi: convertMistakesForApi,
    supportedMultiColumn: true,
  },
  {
    names: [
      'common_mistake_pos',
      'commonmistake_pos',
      'mistake_pos',
      'common_mistake_part_of_speech',
      'commonmistake_part_of_speech',
      'mistake_part_of_speech',
    ],
    label: TERM_COLUMN_LABELS.CommonMistakePos,
    variants: PART_OF_SPEECH_ACCEPTED_VALUES,
    supportedMultiColumn: true,
    defaultValue: '',
  },
  {
    names: [
      'common_mistake_cs',
      'commonmistake_cs',
      'mistake_cs',
      'common_mistake_case_sensitivity',
      'commonmistake_case_sensitivity',
      'mistake_case_sensitivity',
    ],
    label: TERM_COLUMN_LABELS.CommonMistakeCs,
    variants: BOOLEAN_ACCEPTED_VALUES,
    supportedMultiColumn: true,
    defaultValue: true,
  },
  {
    names: ['replace_with', 'replacewith'],
    label: TERM_COLUMN_LABELS.ReplaceWith,
    apiFieldKey: 'linkedTerms',
    convertForApi: (value: string) => ({
      [REFERENCE_COLUMN_NAME]: value,
    }),
    supportedMultiColumn: true,
  },
  {
    names: ['tags', 'tag'],
    label: TERM_COLUMN_LABELS.Tags,
    apiFieldKey: 'tags',
    convertForApi: convertTagsForApi,
  },
  {
    names: ['do_example', 'good_example'],
    label: TERM_COLUMN_LABELS.DoExample,
    apiFieldKey: 'examples',
    convertForApi: (value: string) => ({
      example: value,
      type: 'good',
    }),
    supportedMultiColumn: true,
  },
  {
    names: ['dont_example', 'bad_example'],
    label: TERM_COLUMN_LABELS.DontExample,
    apiFieldKey: 'examples',
    convertForApi: (value: string) => ({
      example: value,
      type: 'bad',
    }),
    supportedMultiColumn: true,
  },
  {
    names: [
      'dont_use_enable_suggestions',
      'dont_use_term_enable_suggestions',
      'enable_dont_use_suggestions',
      'enable_dont_use_suggestion',
    ],
    label: TERM_COLUMN_LABELS.EnableDontUseSuggestions,
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
  {
    names: [
      'use_carefully_enable_suggestions',
      'use_carefully_term_enable_suggestions',
      'enable_use_carefully_suggestions',
      'enable_dont_use_suggestion',
    ],
    label: TERM_COLUMN_LABELS.EnableUseCarefullySuggestions,
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
  {
    names: [
      'approved_enable_highlighting',
      'approved_term_enable_highlighting',
      'enable_approved_term_highlighting',
      'enable_approved_terms_highlighting',
    ],
    label: TERM_COLUMN_LABELS.EnableApprovedTermHighlighting,
    apiFieldKey: 'highlight',
    convertForApi: convertHighlightForApi,
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
  {
    names: [
      'enable_common_mistake_corrections',
      'enable_common_mistakes_corrections',
      'enable_common_mistake_correction',
    ],
    label: TERM_COLUMN_LABELS.EnableCommonMistakeCorrections,
    apiFieldKey: 'approvedTermExtension.fixCommonMistakes',
    variants: BOOLEAN_ACCEPTED_VALUES,
    defaultValue: true,
  },
];

const SNIPPETS_ACCEPTABLE_COLUMNS: AcceptableColumnType[] = [
  {
    names: ['reference', 'references'],
    label: SNIPPETS_COLUMN_LABELS.Reference,
    apiFieldKey: REFERENCE_COLUMN_NAME,
    convertForApi: validateReferenceForApi,
  },
  {
    names: ['snippet', 'snippets', 'snippet text'],
    label: SNIPPETS_COLUMN_LABELS.Snippet,
    apiFieldKey: 'snippet',
    required: true,
    htmlContentSupport: true,
    wide: true,
  },
  {
    names: ['description'],
    apiFieldKey: 'description',
    label: SNIPPETS_COLUMN_LABELS.Description,
  },
  {
    names: ['shortcut', 'shortcuts', 'snippet shortcut', 'snippet shortcuts'],
    apiFieldKey: 'shortcut',
    label: SNIPPETS_COLUMN_LABELS.Shortcut,
    convertForApi: formatSnippetShortcutForApi,
  },
  {
    names: ['tags', 'tag'],
    label: SNIPPETS_COLUMN_LABELS.Tags,
    apiFieldKey: 'tags',
    convertForApi: convertTagsForApi,
  },
];

const BILLING_ACCEPTABLE_COLUMNS: AcceptableColumnType[] = [
  {
    names: [
      'group',
      'groups',

      'billing_group',
      'billing_groups',

      'billing-group',
      'billing-groups',

      'billingGroup',
      'billingGroups',
    ],
    label: BILLING_COLUMN_LABELS.Group,
    apiFieldKey: 'group',
    required: true,
  },
  {
    names: ['email', 'emails', 'user_email', 'user_emails', 'user-email', 'user-emails', 'userEmail', 'userEmails'],
    label: BILLING_COLUMN_LABELS.UserEmail,
    apiFieldKey: 'email',
    required: true,
  },
  {
    names: [
      'contact',
      'contacts',
      'billing_group_contact',
      'billing_group_contacts',
      'billing-group-contact',
      'billing-group-contacts',
      'billingGroupContact',
      'billingGroupContacts',

      'group_contact',
      'group_contacts',
      'groupContact',
      'groupContacts',
      'contact_email',
      'contact_emails',
    ],
    label: BILLING_COLUMN_LABELS.Contact,
    apiFieldKey: 'contact',
  },
];

export const ACCEPTABLE_COLUMNS = {
  [ImportItemType.TERMS]: TERMS_ACCEPTABLE_COLUMNS,
  [ImportItemType.SNIPPETS]: SNIPPETS_ACCEPTABLE_COLUMNS,
  [ImportItemType.BILLING_GROUP]: BILLING_ACCEPTABLE_COLUMNS,
};
