import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';

import { Dropdown, DropdownPlacement } from '@writercolab/ui-atoms';
import { InputGroup, InputTypes } from '@writercolab/ui-molecules';
import { Enum } from '@writercolab/utils';

import { DropdownOptionUserSettingDepartment } from '@web/types';
import { isCustomDepartment } from 'constants/Profile';
import isEmpty from 'lodash/isEmpty';

import { extractTriggerPlaceholder, markSelectedOptionsByIds } from '../../../utils/dropdownUtils';
import {
  QUESTIONNAIRE_CUSTOM_INPUT_INVALID_ERROR,
  isCustomValue,
  isQuestionerTextInputValid,
} from '../../../utils/questionnaireUtils';
import DropdownTrigger from '../DropdownTrigger';

import styles from './UserDepartmentDropdown.module.css';

export const TUserDepartmentActionType = new Enum('click', 'type');

interface IUserDepartmentDropdownProps extends BaseComponentsProps {
  emptyText?: string;
  onChange?: (optId: string, value: boolean | string, type: typeof TUserDepartmentActionType.type) => void;
  dropdownPlacement?: DropdownPlacement;
  inputLabel?: string;
  isLoading?: boolean;
  userDepartmentList: DropdownOptionUserSettingDepartment[];
}

const DEFAULT_PLACEHOLDER = 'Select';
const DEFAULT_LABEL = 'department';

export const UserDepartmentDropdown: React.FC<IUserDepartmentDropdownProps> = ({
  className,
  onChange,
  isLoading,
  userDepartmentList,
  emptyText = DEFAULT_PLACEHOLDER,
  dropdownPlacement = DropdownPlacement.BOTTOM_LEFT,
  inputLabel = DEFAULT_LABEL,
}) => {
  const [customInputVisible, setCustomInputVisible] = useState(false);
  const [optSelected, setOptSelected] = useState<DropdownOptionUserSettingDepartment | null>(null);
  const [opts, setOpts] = useState(userDepartmentList);
  const [placeholder, setPlaceholder] = useState(emptyText);

  useEffect(() => {
    setOpts(userDepartmentList);
  }, [userDepartmentList]);

  const _handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!optSelected) {
        return;
      }

      const newOptSelected = {
        ...optSelected,
        value: e.target.value,
        valid: isQuestionerTextInputValid(e.target.value),
      };

      setOptSelected(newOptSelected);
      onChange?.(newOptSelected.id, e.target.value, TUserDepartmentActionType.enum.type);
    },
    [onChange, optSelected],
  );

  const inputPlaceholder = useMemo(() => {
    const currentDepartmentName = userDepartmentList.find(item => item.active)?.name;

    return currentDepartmentName && isCustomValue(currentDepartmentName)
      ? currentDepartmentName
      : extractTriggerPlaceholder(opts, DEFAULT_PLACEHOLDER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opts]);

  const _handleDropdownChange = useCallback(
    (optId: string) => {
      setOpts(markSelectedOptionsByIds(opts, [optId]));

      const selectedOption = opts.find(opt => opt.id === optId);

      if (selectedOption) {
        setCustomInputVisible(isCustomDepartment(selectedOption.name));
        setOptSelected(selectedOption);
        onChange?.(optId, selectedOption.value, TUserDepartmentActionType.enum.click);
      }
    },
    [onChange, opts],
  );

  useEffect(() => {
    if (isLoading) {
      setCustomInputVisible(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (isEmpty(inputPlaceholder)) {
      return;
    }

    setPlaceholder(inputPlaceholder);
  }, [inputPlaceholder]);

  return (
    <div className={cx(styles.container, className)}>
      <InputGroup
        className={styles.inputGroup}
        id="input"
        name="input"
        label={inputLabel}
        inputType={InputTypes.CUSTOM}
        disabled={isLoading}
        disableHelperText
      >
        <Dropdown
          dropDownContainerClassName={styles.dropdown}
          trigger={<DropdownTrigger placeholder={placeholder} />}
          options={opts}
          placement={dropdownPlacement}
          onPrimaryOptionClickAction={_handleDropdownChange}
        />
      </InputGroup>
      {customInputVisible && (
        <div>
          <InputGroup
            id="other"
            name="other"
            autoFocus
            value={optSelected?.value as string}
            label=""
            errorText={optSelected?.valid ? undefined : QUESTIONNAIRE_CUSTOM_INPUT_INVALID_ERROR}
            placeholder="e.g., Operations"
            handleChangeInput={_handleInputChange}
            disableHelperText
            disabled={isLoading}
          />
        </div>
      )}
    </div>
  );
};

export default UserDepartmentDropdown;
