import { HTTP_STATUS_BAD_REQUEST, HTTP_STATUS_NOT_FOUND } from './responseStatusCodeUtils';

/**
 * This function checks if a given error is a 404 (Not Found) HTTP response.
 *
 * @param {any} err - The error object to inspect.
 * @returns {boolean} Returns true if the error response status is 404, false otherwise.
 */
export const isResourceNotFound = (err: any): boolean => err?.response?.status === HTTP_STATUS_NOT_FOUND;

/**
 * This function checks if a given error is a 400 (Bad Request) HTTP response.
 *
 * @param {any} err - The error object to inspect.
 * @returns {boolean} Returns true if the error response status is 400, false otherwise.
 */
export const isBadRequest = (err: any): boolean => err?.response?.status === HTTP_STATUS_BAD_REQUEST;
