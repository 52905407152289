import React, { useEffect, useState } from 'react';

import cx from 'classnames';

import { Dropdown, DropdownOption, IconVariant, Switcher, Text, TextSize, Tooltip } from '@writercolab/ui-atoms';
import { UtilIcon } from '@writercolab/ui-molecules';

import { DEFAULT_TEXT_PLACEHOLDER, OrgDomain, OrgDomainId } from '@web/types';
import isEmpty from 'lodash/isEmpty';

import { TAccessTypeOptionId } from '../../../context/domainDiscoverabilityPreferencesContext';
import { extractTriggerPlaceholder } from '../../../utils/dropdownUtils';
import DropdownTrigger from '../DropdownTrigger';

import styles from './styles.module.css';

interface IDomainDiscoverabilityTableRowProps {
  domain: OrgDomain;
  onDeleteDomain: (domainId: OrgDomainId) => void;
  onAccessTypeOptionChanged: (domainId: OrgDomainId, accessType: typeof TAccessTypeOptionId.type) => void;
  accessTypeOptions: DropdownOption[];
  teamsOptions: DropdownOption[];
  onTeamOptionChanged: (domainId: OrgDomainId, teamSelectedIds: string[]) => void;
  showOrgAccess: boolean;
  onJoinRequiresApprovalChange: (domainId: OrgDomainId, joinRequiresApproval: boolean) => void;
}

const MAX_DOMAIN_LENGTH = 24;

export const DomainDiscoverabilityTableRow: React.FC<IDomainDiscoverabilityTableRowProps> = ({
  domain,
  onDeleteDomain,
  onAccessTypeOptionChanged,
  accessTypeOptions,
  teamsOptions,
  onTeamOptionChanged,
  showOrgAccess,
  onJoinRequiresApprovalChange,
}) => {
  const [teamsOptionsList, setTeamOptionsList] = useState<DropdownOption[]>(teamsOptions);
  const [teamOptionsPlaceholder, setTeamOptionsPlaceholder] = useState<string>(DEFAULT_TEXT_PLACEHOLDER);
  const _accessTypeOptions = accessTypeOptions.map(accessType => {
    const active =
      (accessType.id === TAccessTypeOptionId.enum.allow && !domain.joinRequiresApproval) ||
      (accessType.id === TAccessTypeOptionId.enum.disallow && domain.joinRequiresApproval);

    return { ...accessType, active };
  });

  const _accessTypeTriggerPlaceholder = extractTriggerPlaceholder(_accessTypeOptions);

  useEffect(() => {
    let _teamsOptions = teamsOptions;

    _teamsOptions = _teamsOptions.map(team => ({
      ...team,
      active: domain.joinTeamIds.includes(+team.id),
    }));
    const _hasTeamSelected = _teamsOptions.some(team => team.active);

    if (!isEmpty(_teamsOptions) && !_hasTeamSelected) {
      _teamsOptions[0].active = true;
    }

    setTeamOptionsPlaceholder(extractTriggerPlaceholder(_teamsOptions));
    setTeamOptionsList(_teamsOptions);
  }, [domain, teamsOptions]);

  const _onTeamOptionChanged = (domainId: OrgDomainId, teamSelectedId: string) => {
    const _teamsOptions = teamsOptionsList.map(team => ({
      ...team,
      active: team.id === teamSelectedId ? !team.active : team.active,
    }));

    setTeamOptionsList(_teamsOptions);
    onTeamOptionChanged(
      domainId,
      _teamsOptions.filter(({ active }) => active).map(({ id }) => id),
    );
  };

  return (
    <div className={styles.tableGridRow}>
      <div className={styles.containerTableRowItem}>
        <Tooltip title={domain.domain} disabled={domain.domain.length < MAX_DOMAIN_LENGTH}>
          <Text variant={TextSize.L} ellipsisOverflow>
            {domain.domain}
          </Text>
        </Tooltip>
      </div>
      <div className={styles.containerTableRowItem}>
        <Switcher
          id={`switcher-${domain.id}`}
          checked={domain.allowJoin}
          size={20}
          onChange={() => onJoinRequiresApprovalChange(domain.id, !domain.allowJoin)}
        />
      </div>
      {showOrgAccess && (
        <div className={cx(styles.containerTableRowItem, styles.containerTableRowItemExpanded)}>
          <Dropdown
            dropDownContainerClassName={styles.dropdownContainer}
            triggerClassName={styles.dropdownTrigger}
            trigger={
              <DropdownTrigger
                placeholder={_accessTypeTriggerPlaceholder}
                className={styles.dropdownTriggerContainer}
              />
            }
            options={_accessTypeOptions}
            onPrimaryOptionClickAction={selectedAccessType =>
              onAccessTypeOptionChanged(domain.id, selectedAccessType as typeof TAccessTypeOptionId.type)
            }
          />
        </div>
      )}
      <div
        className={cx(styles.containerTableRowItem, styles.containerTableRowItemExpanded, styles.containerTableRowTeam)}
      >
        <Dropdown
          dropDownContainerClassName={styles.dropdownContainer}
          triggerClassName={styles.dropdownTrigger}
          trigger={<DropdownTrigger placeholder={teamOptionsPlaceholder} />}
          options={teamsOptionsList}
          onPrimaryOptionClickAction={id => _onTeamOptionChanged(domain.id, id)}
          useCheckbox
          hideOnCLick={false}
        />
      </div>
      <div className={styles.containerTableRowItem}>
        <div
          className={cx(styles.dropButtonContainer, {
            [styles.dropButtonContainerLeftAligned]: !showOrgAccess,
          })}
        >
          <UtilIcon
            className={cx(styles.dropButton, styles.clickable)}
            onClick={() => onDeleteDomain(domain.id)}
            variant={IconVariant.CLOSE}
            tooltipContent="Remove domain"
            circleSmall
            width={16}
            height={16}
          />
        </div>
      </div>
    </div>
  );
};
