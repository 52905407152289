import { computed, makeObservable, observable } from 'mobx';

import { PromisedModel, ReactiveQueue } from '@writercolab/mobx';
import { AiStudioAccountModel, ApplicationsApiModel } from '@writercolab/models';
import type { RequestServiceInitialize, components } from '@writercolab/network';
import type { TDeployedResearchApplication, TEventQueueItem, TNotificationQueueItem } from '@writercolab/types';
import { ResearchAssistantApiModel, ResearchAssistantTemplateUIModel } from '@writercolab/ui-research-assistant';

import type { TApplicationExpand } from '@web/types';
import { EApplicationType } from '@web/types';
import { IWebAppAnalyticsTrack } from 'constants/analytics';

interface ResearchAssistantUIModelOptions {
  // TODO: get back to this guy
  // subscriptionModel: AiAssistantSubscriptionModel;
  request: RequestServiceInitialize['api'];
  appId: string;
  organizationId: number;
  teamId: number;
  analyticsService: IWebAppAnalyticsTrack;
}

export class ResearchAssistantPageUIModel {
  readonly notificationQueue: ReactiveQueue<TNotificationQueueItem>;
  readonly eventQueue = new ReactiveQueue<TEventQueueItem<string, string>>();
  readonly applicationApi: ApplicationsApiModel;

  applicationIdOrAlias?: string;

  readonly $application = new PromisedModel({
    name: 'application',
    load: async () => {
      if (!this.applicationIdOrAlias) {
        return undefined;
      }

      return this.applicationApi.getApplicationForPreview(this.opts.teamId, this.applicationIdOrAlias);
    },
  });

  researchAssistantUIModel: ResearchAssistantTemplateUIModel;
  researchAssistantApiModel: ResearchAssistantApiModel;
  aiStudioAccountModel: AiStudioAccountModel;

  constructor(private opts: ResearchAssistantUIModelOptions) {
    this.notificationQueue = new ReactiveQueue();

    this.applicationIdOrAlias = opts.appId;

    this.applicationApi = new ApplicationsApiModel({
      request: opts.request,
      organizationId: opts.organizationId,
    });

    this.researchAssistantApiModel = new ResearchAssistantApiModel({
      request: this.opts.request,
      organizationId: this.opts.organizationId,
      teamId: this.opts.teamId,
      getApplication: () => this.application as unknown as TDeployedResearchApplication,
    });

    this.aiStudioAccountModel = new AiStudioAccountModel({
      api: this.opts.request,
      organizationId: () => this.opts.organizationId,
    });

    this.researchAssistantUIModel = new ResearchAssistantTemplateUIModel({
      notificationQueue: this.notificationQueue,
      researchAssistantApiModel: this.researchAssistantApiModel,
      aiStudioAccountModel: this.aiStudioAccountModel,
      getApplication: () => this.application as unknown as TDeployedResearchApplication,
    });

    makeObservable(this, {
      applicationIdOrAlias: observable,

      applicationId: computed,
      application: computed,
    });
  }

  get applicationId() {
    return this.application?.id;
  }

  private $activeSessionId = observable.box<string | null>(null);

  get application() {
    if (ResearchAssistantPageUIModel.isResearchAssistantApplicationData(this.$application.value)) {
      return this.$application.value;
    }

    return undefined;
  }

  static isResearchAssistantApplicationData = (
    application?: Pick<TApplicationExpand, 'type' | 'data'>,
  ): application is {
    type: typeof EApplicationType.enum.chat;
    data: components['schemas']['template_model_BriefApplicationData_BriefResearchApplicationData'];
  } => {
    return application?.type === EApplicationType.enum.research;
  };
}
