import type React from 'react';
import { useEffect, useState } from 'react';

import cx from 'classnames';

import type { IUserProfile } from '@writercolab/common-utils';
import { SharedQueryParam, UserAccountStatus } from '@writercolab/common-utils';
import { Logo, Text, TextColor, TextSize, useCustomSnackbar } from '@writercolab/ui-atoms';

import { LoadingPage, snackbarMessages, useOrganizations } from '@web/component-library';
import type { IDiscoverOrganizationsResponse } from '@web/types';
import { DEFAULT_USER_ORG_NAME, TOrganizationJoinType } from '@web/types';
import { SharedOnboardingRoutes } from '@web/utils';
import { AnalyticsActivity, IWebAppAnalyticsTrack } from 'constants/analytics';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import uniqBy from 'lodash/uniqBy';
import { appLocalStorage } from 'models/localStorage';
import { useLocation, useNavigate } from 'react-router';
import { useEffectOnce } from 'react-use';
import { useOnboardingFF } from 'utils/onboardingUtils';

import useQuery from '../../../hooks/useQuery';
import { ROUTE } from '../../../services/config/routes';
import {
  createOrganization,
  discoverOrganizations,
  getOrganizationAdmins,
  requestJoinOrganization,
} from '../../../services/request/user';
import { useAppState } from '../../../state';
import { useAppSessionContext } from '../../../state/sessionContext';
import { extractBackendResponseErrorMessage } from '../../../utils/backendErrorUtils';
import { getLogger } from '../../../utils/logger';
import { goToHome, goToHrefWithReload } from '../../../utils/navigationUtils';
import { TQueryParamBooleanStd, redirectLocation } from '../../../utils/queryParamUtils';
import { isResourceNotFound } from '../../../utils/requestUtils';
import type { QueryParams } from '../../../utils/routesUtils';
import WelcomeBanner from '../../generic/WelcomeBanner';
import OrganizationJoinRequest from '../../molecules/OrganizationJoinRequest';
import OrganizationJoinRequested from '../../molecules/OrganizationJoinRequested';

import styles from './AutoJoin.module.css';

const LOG = getLogger('AutoJoin');

interface AutoJoinProps {
  analytics: IWebAppAnalyticsTrack;
  hideJoinOrg?: boolean;
  hideCreateOrg?: boolean;
}

export interface IExistingOrg extends IDiscoverOrganizationsResponse {}

/**
 * @deprecated remove one onboarding is complete
 */
export const AutoJoin: React.FC<AutoJoinProps> = ({ analytics, hideJoinOrg = false, hideCreateOrg = false }) => {
  const {
    refreshPermissions,
    appState: { unconfirmedOrganizations, userProfile },
  } = useAppState();

  const query = useQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const flag = useOnboardingFF();

  const redirectToQueryParam = query.get(SharedQueryParam.REDIRECT_TO);
  const { fetchingOrganizations } = useOrganizations();
  const { signOutUser } = useAppSessionContext();

  // undefined - loading, null - no org, IExistingOrg - org exists
  const [existingOrg, setExistingOrg] = useState<IExistingOrg | undefined | null>();
  const [admins, setAdmins] = useState<IUserProfile[]>([]);
  const [joinRequested, setJoinRequested] = useState(false);
  const [directJoinEnabled, setDirectJoinEnabled] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isCreatingOrg, setIsCreatingOrg] = useState(false);

  const { enqueueErrorSnackbar } = useCustomSnackbar();

  const getOrgAdmins = async (orgId: number | undefined): Promise<IUserProfile[]> => {
    const orgAdmins = await getOrganizationAdmins(orgId);
    const signedUpAdmins = orgAdmins.filter(admin => admin.accountStatus === UserAccountStatus.SIGNED_UP);

    return uniqBy(signedUpAdmins, 'email');
  };

  useEffectOnce(() => {
    analytics.track(AnalyticsActivity.viewJoinOrCreateOrgPage, {});
  });

  useEffect(() => {
    const initAutoJoin = async () => {
      if (unconfirmedOrganizations) {
        try {
          const discoveredOrg = await discoverOrganizations();
          const alreadyRequested = unconfirmedOrganizations.some(org => org.id === discoveredOrg.organizationId);
          setJoinRequested(alreadyRequested);

          if (flag.disableLegacyDiscovery) {
            appLocalStorage.onboardingOrgId.set(String(discoveredOrg.organizationId));
            appLocalStorage.onboardingOgName.set(discoveredOrg.organizationName);

            const step = alreadyRequested
              ? SharedOnboardingRoutes.PendingApproval
              : flag.onboardingV2
                ? SharedOnboardingRoutes.JoinCreateOrg
                : SharedOnboardingRoutes.JoinOrg;

            appLocalStorage.onboardingStep.set(step);
            window.location.replace(step);

            return;
          }

          if (alreadyRequested) {
            setAdmins(await getOrgAdmins(discoveredOrg.organizationId));
          }

          setDirectJoinEnabled(!discoveredOrg.joinRequiresApproval);
          setExistingOrg(discoveredOrg);
        } catch (e: unknown) {
          LOG.error('Error while discovering organizations', e);

          if (e && typeof e === 'object' && 'message' in e && isResourceNotFound(e)) {
            enqueueErrorSnackbar(e.message as string);
          }

          setExistingOrg(null);
        }
      }
    };

    initAutoJoin();
  }, [enqueueErrorSnackbar, unconfirmedOrganizations, flag]);

  const onRequestJoinOrg = async () => {
    if (!existingOrg || !userProfile) {
      return;
    }

    const { organizationId, joinRequiresApproval } = existingOrg;

    setIsJoining(true);

    try {
      await requestJoinOrganization(organizationId);
      await analytics.track(AnalyticsActivity.autoJoinSelectJoinOrg, {
        access_type: joinRequiresApproval
          ? TOrganizationJoinType.enum.request_to_join
          : TOrganizationJoinType.enum.immediate,
        auth_method: userProfile.authType,
      });
      await refreshPermissions();

      if (!joinRequiresApproval) {
        setAdmins(await getOrgAdmins(organizationId));
        setJoinRequested(true);
      } else {
        const redirectTo = redirectLocation(location);

        if (redirectTo) {
          goToHrefWithReload(redirectTo);

          return;
        }
      }

      goToHome(navigate, organizationId, true);
    } catch (err) {
      LOG.error('Error while requesting to join organization', err);
      enqueueErrorSnackbar(
        extractBackendResponseErrorMessage(err) || snackbarMessages.user.requestJoinOrganizationError,
      );
    } finally {
      setIsJoining(false);
    }
  };

  const onCreateMyOrg = async () => {
    setIsCreatingOrg(true);
    try {
      await createOrganization(userProfile?.fullName || DEFAULT_USER_ORG_NAME);
      await analytics.track(AnalyticsActivity.autoJoinCreatedOwnOrg, {});
      let routeParams: QueryParams = {
        onboarding: TQueryParamBooleanStd.enum.true,
        firstSignIn: TQueryParamBooleanStd.enum.true,
      };

      if (redirectToQueryParam) {
        routeParams = { ...routeParams, redirectTo: redirectToQueryParam };
      }

      goToHrefWithReload(ROUTE.toAuthRedirect(routeParams));
    } catch (err) {
      LOG.error('Error while creating organization', err);
      enqueueErrorSnackbar(snackbarMessages.user.createOrganizationError);
    } finally {
      setIsCreatingOrg(false);
    }
  };

  if (isUndefined(existingOrg)) {
    return <LoadingPage />;
  }

  const getContent = () => {
    if (isNull(existingOrg)) {
      return <WelcomeBanner onCreateMyOrg={onCreateMyOrg} isCreatingOrg={isCreatingOrg} />;
    }

    if (joinRequested && !fetchingOrganizations) {
      return <OrganizationJoinRequest organizationName={existingOrg.organizationName} organizationAdmins={admins} />;
    }

    return (
      <OrganizationJoinRequested
        isJoining={isJoining}
        isCreatingOrg={isCreatingOrg}
        onRequestJoinOrg={onRequestJoinOrg}
        onCreateMyOrg={onCreateMyOrg}
        hideJoinOrg={hideJoinOrg}
        hideCreateOrg={hideCreateOrg}
        existingOrg={existingOrg}
        showDirectJoinButton={directJoinEnabled}
      />
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.navigationHolder}>
        <Logo />
        <div className={cx(styles.signoutContainer, styles.clickable)}>
          <Text bold variant={TextSize.XL} color={TextColor.GREY2} onClick={() => signOutUser()}>
            Sign out
          </Text>
        </div>
      </div>
      <div className={styles.contentHolder}>{getContent()}</div>
    </div>
  );
};

export default AutoJoin;
