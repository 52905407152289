import React, { useMemo } from 'react';

import cx from 'classnames';

import { wordPluralize } from '@writercolab/common-utils';
import { Button, ButtonTypes, Icon, IconVariant, SizeTypes, Tooltip } from '@writercolab/ui-atoms';

import isEmpty from 'lodash/isEmpty';

import ClaimDetectionButtonCounter from './ClaimDetectionButtonCounter';
import ClaimTooltipText from './ClaimTooltipText';

import styles from '../styles.module.css';

interface IClaimButtonNarrowProps {
  isLoading: boolean;
  count?: number;
  onClick?: () => void;
}

export const ClaimButtonNarrow: React.FC<IClaimButtonNarrowProps> = ({ isLoading, count, onClick }) => {
  const tooltipText = useMemo(() => {
    const _text = count && count > 0 ? `${count} ${wordPluralize(count, 'claim')} found` : 'Claim detection';

    return _text ? <ClaimTooltipText content={_text} /> : '';
  }, [count]);

  return (
    <div className={cx(styles.container, styles.clickable)}>
      <Tooltip
        title={tooltipText}
        disabled={isEmpty(tooltipText)}
        placement="bottom"
        tooltipWrapperClassname={styles.containerTooltipShort}
      >
        <div>
          <Button
            round
            size={SizeTypes.MEDIUM}
            type={isLoading ? ButtonTypes.PURPLE_3 : ButtonTypes.GRAY}
            icon={<Icon name={IconVariant.CLAIM} />}
            onClick={onClick}
          />
          <ClaimDetectionButtonCounter
            count={count}
            isHighlighted={!isLoading}
            className={styles.containerCounterNarrow}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default ClaimButtonNarrow;
