import React from 'react';

import cx from 'classnames';

import { BaseComponentsProps } from '@writercolab/common-utils';
import { Text, TextColor, TextSize } from '@writercolab/ui-atoms';

import styles from './styles.module.css';

interface ITitleWithDescriptionRowProps extends BaseComponentsProps {
  title: string;
  description: string;
  icon?: React.ReactNode;
}

const TitleWithDescriptionRow = ({ className, title, description, icon }: ITitleWithDescriptionRowProps) => (
  <div className={cx(className, styles.titleRow)}>
    <Text className={styles.title} variant={TextSize.XS} color={TextColor.GREY} smallCaps>
      {title}
    </Text>
    <Text variant={TextSize.L} color={TextColor.GREY2} medium>
      {description}
      {icon}
    </Text>
  </div>
);

export default TitleWithDescriptionRow;
