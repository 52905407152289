import React, { useMemo, useState } from 'react';

import cx from 'classnames';

import { Button, ButtonTypes, Input, Modal, SizeTypes, Text, TextSize } from '@writercolab/ui-atoms';

import generalStyles from '../styles.module.css';
import styles from './styles.module.css';

interface ICancellationConfirm {
  open: boolean;
  isLoading?: boolean;
  onConfirm?: () => void;
  handleClose?: () => void;
}

const confirmationPhrase = "I'd like to cancel";

export const CancellationConfirm: React.FC<ICancellationConfirm> = ({ open, isLoading, onConfirm, handleClose }) => {
  const [inputValue, setInputValue] = useState('');
  const isValid = useMemo(() => inputValue.trim() === confirmationPhrase, [inputValue]);

  return (
    <Modal
      open={open}
      title="Last step! Please confirm your decision to cancel"
      className={cx(generalStyles.modalContainer)}
      handleClose={handleClose}
    >
      <div>
        <div className={cx(generalStyles.content, generalStyles.contentWithRightPadding)}>
          <Text variant={TextSize.M}>Type "{confirmationPhrase}" below to confirm your cancellation.</Text>
          <Input
            className={styles.input}
            value={inputValue}
            placeholder="Type here"
            onChange={e => setInputValue(e.target.value)}
          />
        </div>
        <div className={generalStyles.actionButtonsHolder}>
          <Button
            type={ButtonTypes.TRANSPARENT}
            size={SizeTypes.XS}
            className={generalStyles.secondaryAction}
            onClick={handleClose}
            content="Keep my plan"
            isLoading={isLoading}
          ></Button>
          <Button
            type={ButtonTypes.BLACK}
            className={generalStyles.mainAction}
            onClick={onConfirm}
            disabled={!isValid}
            content="Confirm cancellation"
            isLoading={isLoading}
          ></Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancellationConfirm;
